import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { generatingCouponCode, savePromotion, savePromotionCoupon, generatingCouponExchange } from "./PromotionService";
import { Fieldset } from "primereact/fieldset";
import moment from "moment";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { APP_FEP, PROMOTION_COUPON_TYPE, PRODUCT_CLASSIFICATION } from "../../constants";
import Select from "react-dropdown-select";
import { getListProducts } from "../product/ProductServices";
import { RadioButton } from "primereact/radiobutton";

export const GeneratingCouponExchangeForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("Generating Coupon Exchange")
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [startDateValue, setStartDateValue] = useState(null)
    const [endDateValue, setEndDateValue] = useState(null)
    const [minDate, setMinDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)
    const [products, setProducts] = useState([])
    const [productOpts, setProductOpts] = useState([])
    const [variantOpts, setVariantOpts] = useState([])
    const [hasVariant, setHasVariant] = useState(false)
    const [errors, setErrors] = useState({})
    
    useEffect(() => {
        loadProducts()
    }, [])

    useEffect(() => {
        if(frmData.productId){
            const product = products.find(p => p.id===frmData.productId)

            if(product){
                if(product.classification===PRODUCT_CLASSIFICATION.variable.value){
                    setHasVariant(true)
                    setVariantOpts(product.variants.map(v => ({value: v.id, label: v.variantName})))
                }else{
                    setHasVariant(false)
                    setVariantOpts([])
                }
            }else{
                setFrmData({...frmData, productId: null})
            }
        }
    }, [frmData.productId])

    useImperativeHandle(ref, () => ({
        openForm(){
            setHeader('Generating Product Exchange Coupon')
            setFrmData({
                app: APP_FEP,
                productId: null,
                variantId: null,
                multipleTimesUse: false,
                manualCode: false,
                code: '',
                quantity: 1,
                validFrom: '',
                validTo: ''
            })
            setStartDateValue('')
            setMinDate('')
            setEndDateValue('')
            setMaxDate('')
            setVisible(true)
        },
        closeForm(){
            closeForm()
        }
    }))

    const loadProducts = () => {
        getListProducts({app: APP_FEP, classifications: [PRODUCT_CLASSIFICATION.simple.value, PRODUCT_CLASSIFICATION.variable.value], active: true}, false)
        .then(res=> {
            setProducts(res?res:[])
            setProductOpts(res && res.length>0 && res.map(p => ({value: p.id, label: p.name})))
        })
    }

    const handleGenerating = () => {
        generatingCouponExchange(frmData)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable)
                    props.refreshTable()
                closeForm()
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }
        });
    }

    const onStartDateChange = (e) => {
        let tmpMaxDate = maxDate ? moment(maxDate) : '';
        let minDateToCompare = moment(e.value).isValid() ? moment(e.value) : '';
        let flag = (minDateToCompare && tmpMaxDate && minDateToCompare.isAfter(tmpMaxDate));

        setFrmData({...frmData,
            validFrom: minDateToCompare?minDateToCompare.format('YYYY-MM-DD HH:mm'):'',
            validTo: !flag ? frmData.validTo : ''
        })
        setStartDateValue(e.value)
        setMinDate(moment(e.value).isValid() ? e.value : '')
        setEndDateValue(!flag ? endDateValue : '')
        setMaxDate(!flag ? maxDate : '')
    }

    const onEndDateChange = (e) => {
        let tmpMinDate = minDate ? moment(minDate) : '';
        let maxDateToCompare = moment(e.value).isValid() ? moment(e.value) : '';
        let flag = (maxDateToCompare && tmpMinDate && maxDateToCompare.isBefore(tmpMinDate));

        setFrmData({...frmData,
            validFrom: !flag ? frmData.validFrom : '',
            validTo: maxDateToCompare?maxDateToCompare.format('YYYY-MM-DD HH:mm'):''
        })
        setStartDateValue(!flag ? startDateValue : '')
        setMinDate(!flag ? minDate :'')
        setEndDateValue(e.value)
        setMaxDate(moment(e.value).isValid() ? e.value : '')
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setErrors({})
    }

    return(
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid">
                <div className="p-col-12">
                    <Fieldset legend="Product Setting">
                        <div className="p-grid p-fluid">
                            
                            <div className="p-col-12">
                                <RadioButton inputId="rdAutoGenerate" value={false} checked={!frmData.manualCode} onChange={(e) => setFrmData({...frmData, manualCode: e.value})} />
                                <label htmlFor="rdAutoGenerate">Auto generate code</label>
                                <RadioButton inputId="rdManualInput" className="p-ml-3" value={true} checked={frmData.manualCode} onChange={(e) => setFrmData({...frmData, manualCode: e.value})} />
                                <label htmlFor="rdManualInput">Manual input code</label>
                            </div>
                            {frmData.manualCode && 
                                <div className="p-col-12">
                                    <label className="p-label">* Code</label>
                                    <InputText value={frmData.code} keyfilter={/[^\s]/} onChange={(e) => setFrmData({...frmData, code: e.target.value})} />
                                    <div className="p-form-error">{errors.code}</div>
                                </div>
                            }
                            <div className="p-col-12">
                                <label className="p-label">* Product</label>
                                <Dropdown value={frmData.productId} options={productOpts} filter={true} onChange={(e) => setFrmData({...frmData, productId: e.value})} />
                                <div className="p-form-error">{errors.product}</div>
                            </div>

                            {hasVariant && 
                            <div className="p-col-12">
                                <label className="p-label">* Variant</label>
                                <Dropdown value={frmData.variantId} options={variantOpts} onChange={(e) => setFrmData({...frmData, variantId: e.value})} />
                                <div className="p-form-error">{errors.variant}</div>
                            </div>
                            }
                            <div className="p-col-12">
                                <label className="p-mr-3">Coupon type:</label>
                                <RadioButton inputId="rdOneTimeUse" value={false} checked={!frmData.multipleTimesUse} onChange={(e) => setFrmData({...frmData, multipleTimesUse: e.value})} />
                                <label htmlFor="rdOneTimeUse">One time use</label>
                                <RadioButton inputId="rdMultipleTimeUse" className="p-ml-3" value={true} checked={frmData.multipleTimesUse} onChange={(e) => setFrmData({...frmData, multipleTimesUse: e.value})} />
                                <label htmlFor="rdMultipleTimeUse">Multiple time use</label>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* {!frmData.multipleTimesUse?"Quantity to generating":"Limit quantity"}</label>
                                <InputText value={frmData.quantity} keyfilter={/^[0-9]*$/} onChange={(e) => setFrmData({...frmData, quantity: e.target.value})} />
                                <div className="p-form-error">{errors.quantity}</div>
                            </div>
                        </div>
                    </Fieldset>
                    <Fieldset legend="Limit Setting">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <label>Valid from</label>
                                <MaskedCalendar value={startDateValue} onChange={e => onStartDateChange(e)} showIcon={true} showTime={true} hourFormat="12" />
                                <div className="p-form-error">{errors.validFrom}</div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label>Valid to</label>
                                <MaskedCalendar value={endDateValue} onChange={e => onEndDateChange(e)} showIcon={true} showTime={true} hourFormat="12" />
                                <div className="p-form-error">{errors.validTo}</div>
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={closeForm}/>
                    <Button label="Submit & Generate" icon="pi pi-check" onClick={() => handleGenerating()}/>
                </div>
            </div>
        </Sidebar>
    )
})
