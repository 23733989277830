import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showNotification, showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { Dropdown } from 'primereact/dropdown';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { SplitButton } from 'primereact/splitbutton';
import { APP_FEP, TABLE_PAGING_LENGTH, TAXONOMY_TYPE, ASSET_STATUS } from '../../constants';
import { getPageAssets, removeAsset, transferAsset } from './AssetServices';
import { ConfirmNotification } from '../../core/components/ConfirmNotification';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AssetForm } from './AssetForm';
import { Fieldset } from 'primereact/fieldset';
import { getListTaxons } from '../taxon/TaxonServices';
import { getListInventories } from '../inventory/InventoryService';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { TransferAssetForm } from './TransferAssetForm';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';


export const AssetListView = () => {
	const match = useRouteMatch();
	const history = useHistory();

	const assetForm = useRef(null);
	const transferAssetForm = useRef(null);

	const [dataList, setDataList] = useState([]);
	const [filter, setFilter] = useState({
		app: APP_FEP,
		name: '',
		branchIds: [],
		inventoryId: null,
		categoryId: null,
	});
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: '',
		sortOrder: -1,
	});

	const [isLoadTable, setLoadTable] = useState(true);
	const [branches, setBranchs] = useState([]);
	const [inventories, setInventories] = useState([]);
	const [categories, setCategories] = useState([]);
	const [bulkAction, setBulkAction] = useState(null);
	const [selectedAssets, setSelectedAsses] = useState([]);

	const isRead = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_DETAIL_R]);
	const isWrite = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_MGT_W]);
	const isDelete = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_MGT_D]);

	useEffect(() => {
		loadBranches();
		loadCategories();
	}, []);

	useEffect(() => {
		if (isLoadTable) loadPageAsset();
	}, [isLoadTable]);

	useEffect(() => {
		if (filter.branchIds && filter.branchIds.length > 0) {
			loadInventories(filter.branchIds[0]);
		} else {
			setInventories([]);
		}
	}, [filter.branchIds]);

	const loadBranches = () => {
		getListTaxons({ type: TAXONOMY_TYPE.branch.value }, true).then((res) => setBranchs(res));
	};

	const loadInventories = (branchId) => {
		getListInventories({ app: APP_FEP, branchId: branchId }, true).then((res) => setInventories(res));
	};

	const loadCategories = () => {
		getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.asset.value] }, true).then((res) => setCategories(res));
	};

	const loadPageAsset = () => {
		showloading();
		getPageAssets(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				stoploading();
				setDataList(res.content);
				setPageable({ ...pageable, total: res.totalElements, loading: false, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
				setLoadTable(false);
				setSelectedAsses([]);
			})
			.finally(stoploading());
	};

	const onPage = (e) => {
		setPageable({ ...pageable, loading: true, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, loading: true, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const onRemoveAsset = (id) => {
		showConfirmNotify({
			accept: () => handleRemoveAsset(id),
		});
	};

	const handleRemoveAsset = (id) => {
		removeAsset(id).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Successfully Deleted');
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	const actionTemplate = (rowData) => {
		let htmlArr = [];
		if (isWrite) {
			htmlArr.push(<Button label='Edit' className='p-button-primary p-margin-bottom-5' onClick={() => assetForm.current.openForm(rowData)} />);
			htmlArr.push(<Button label='Clone' className='p-button-warning p-margin-bottom-5' onClick={() => assetForm.current.openForm(rowData, true)} />);
		}
		if (isDelete) {
			htmlArr.push(<Button label='Remove' className='p-button-danger p-margin-bottom-5' onClick={() => onRemoveAsset(rowData.id)} />);
		}
		return htmlArr;

		// let dropActions = [
		// 	{ label: 'Edit Facility', icon: 'pi-md-pencil', command: () => assetForm.current.openForm(rowData) },
		// 	{ label: 'Clone Facility', icon: 'pi-md-content-copy', command: () => assetForm.current.openForm(rowData, true) },
		// 	{ label: 'Delete Facility', icon: 'pi-md-trash', command: () => deleteAsset(rowData.id) },
		// ];
		// return <SplitButton label='View' icon='pi pi-search' className='p-button-constrast p-l' model={dropActions} onClick={() => history.push(`/assets-mgt/${rowData.id}`)} />;
	};

	const onAddOrEdit = (data) => {
		assetForm.current.openForm(data);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const onFilterChange = (property, value) => {
		setFilter({ ...filter, [property]: value });
		setLoadTable(true);
	};

	const applyFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setLoadTable(true);
	};

	const clearFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setFilter({
			app: APP_FEP,
			name: '',
			branchIds: [],
			inventoryId: null,
			categoryId: null,
		});
		setLoadTable(true);
	};

	const tblHeader = (
		<TableHeader
			title='Facilities'
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			actionTemplate={isWrite && <Button className='p-button-success' label='Create Facility' icon='pi pi-plus' onClick={() => onAddOrEdit()} />}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
		/>
	);

	const onApplyBulkAction = () => {
		let assetIds = selectedAssets.map((p) => p.id);
		if (bulkAction === 'transfer') {
			transferAssetForm.current.openForm(assetIds);
		}
	};

	const BULK_ACTIONS = [{ label: 'Transfer', value: 'transfer' }];

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>Facilities</BreadcrumbsItem>

			<AssetForm ref={assetForm} reloadTable={() => loadPageAsset()} />

			<TransferAssetForm ref={transferAssetForm} reloadTable={() => loadPageAsset()} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1 className='p-w-bold p-m-0'>Facilities</h1>
				</div>

				<Fieldset legend='Filter'>
					<div className='p-grid p-fluid'>
						<div className='p-col-12 p-md-6'>
							<div className='p-inputgroup'>
								<span className='p-inputgroup-addon'>Name/Code:</span>
								<InputText value={filter.name} onChange={(e) => setFilter({ ...filter, name: e.target.value })} />
							</div>
						</div>
						<div className='p-col-12 p-md-6'>
							<div className='p-inputgroup'>
								<span className='p-inputgroup-addon'>Branch:</span>
								<Dropdown
									value={filter.branchIds && filter.branchIds.length > 0 ? filter.branchIds[0] : null}
									options={branches}
									showClear
									onChange={(e) => onFilterChange('branchIds', e.value ? [e.value] : [])}
								/>
							</div>
						</div>
						<div className='p-col-12 p-md-6'>
							<div className='p-inputgroup'>
								<span className='p-inputgroup-addon'>Category:</span>
								<Dropdown value={filter.categoryId} options={categories} showClear onChange={(e) => onFilterChange('categoryId', e.value)} />
							</div>
						</div>
						<div className='p-col-12 p-md-6'>
							<div className='p-inputgroup'>
								<span className='p-inputgroup-addon'>Inventory:</span>
								<Dropdown value={filter.inventoryId} options={inventories} showClear onChange={(e) => onFilterChange('inventoryId', e.value)} />
							</div>
						</div>
						<div className='p-col-12 p-c'>
							<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={() => applyFilter()} style={{ width: 'auto' }} />
							<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' onClick={() => clearFilter()} style={{ width: 'auto' }} />
						</div>
					</div>
				</Fieldset>
			</div>
			{isWrite && (
				<div className='p-col-12'>
					<Panel>
						<div className='p-grid p-fluid'>
							<div className='p-col-12 p-md-4'>
								<div className='p-inputgroup p-ai-end'>
									<span className='p-inputgroup-addon'>Bulk Action:</span>
									<Dropdown value={bulkAction} options={BULK_ACTIONS} showClear onChange={(e) => setBulkAction(e.value)} placeholder='Select action' />
									<Button label='Apply' disabled={selectedAssets.length === 0 || !bulkAction} onClick={() => onApplyBulkAction()} />
								</div>
							</div>
						</div>
					</Panel>
				</div>
			)}
			<div className='p-col-12'>
				<DataTable
					emptyMessage="There's no record found"
					header={tblHeader}
					lazy={true}
					paginator={true}
					value={dataList}
					first={pageable.page * pageable.rows}
					onPage={onPage}
					onSort={onSort}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					responsive={true}
					selection={selectedAssets}
					onSelectionChange={(e) => setSelectedAsses(e.value)}
				>
					{isWrite && <Column selectionMode='multiple' className='p-c' headerStyle={{ width: '4em' }} />}
					<Column
						field='id'
						header='Id'
						body={(rowData) => {
							if (isRead) {
								return (
									<Button
										label={rowData.id}
										icon='pi pi-search'
										className='p-button-info'
										tooltip='View'
										tooltipOptions={{ position: 'top' }}
										onClick={() => history.push(`/assets-mgt/${rowData.id}`)}
									/>
								);
							}
							return rowData.id;
						}}
					/>
					<Column field='code' header='Code' />
					<Column field='name' header='Name' />
					<Column header='Category' body={(rowData) => rowData.category && rowData.category.name} />
					<Column header='Branch' body={(rowData) => rowData.branch && rowData.branch.name} />
					<Column header='Original Inventory' body={(rowData) => rowData.inventory && rowData.inventory.name} />
					<Column header='Current Inventory' body={(rowData) => rowData.currentInventory && rowData.currentInventory.name} />
					{/* <Column header="Status" field="status" headerStyle={{width: '8em'}} bodyClassName="p-c" body={(rowData) => <span className={classNames('status', {'active': !rowData.status || rowData.status===ASSET_STATUS.in_stock.value, 'inactive': rowData.status===ASSET_STATUS.out_stock.value})}>{ASSET_STATUS[rowData.status]?ASSET_STATUS[rowData.status].label:ASSET_STATUS.in_stock.label}</span>} /> */}
					<Column
						header='Active'
						field='active'
						headerStyle={{ width: '8em' }}
						bodyClassName='p-c'
						body={(rowData) => <span className={classNames('status', { active: rowData.active, inactive: !rowData.active })}>{rowData.active ? 'Active' : 'Inactive'}</span>}
					/>
					{(isWrite || isDelete) && <Column header='Actions' body={actionTemplate} className='tc-actions' headerStyle={{ width: '12em' }} bodyStyle={{ overlay: 'visible' }} />}
				</DataTable>
			</div>
		</div>
	);
};
