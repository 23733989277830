import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

export class FieldWrapper extends Component {

    constructor(props) {
        super(props);
    }

    resetChange = () => {
        if(this.props.resetChange){
            if(this.props.originValue)
                this.props.resetChange(this.props.keyField, this.props.originValue);
            else this.props.resetChange(this.props.keyField);
        }
    }


    render() {
        return (
            <div style={{ position: "relative" , overflow: this.props.addStyle}} className={this.props.col + (this.props.highlight ? " input-highlight" : "")}>
                {this.props.keyField && this.props.highlight ? <Button tooltip="Undo Change" tooltipOptions={{position: "top"}} icon="pi-md-undo" className="p-button-warning cancel-button" onClick={this.resetChange}/> : ""}
                {this.props.children}
            </div>
        )
    }
}