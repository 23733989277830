import CKEditor from 'ckeditor4-react';
import { Button } from "primereact/button";
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { SplitButton } from "primereact/splitbutton";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ATTRIBUTE_GROUP_TYPE } from "../../constants";
import { getListAttributeSets } from "../attribute/AttributeServices";
import { OrderList } from "primereact/orderlist";
CKEditor.editorUrl = "/assets/ckeditor/ckeditor.js";


export const ApplicationContentForm = forwardRef((props, ref) => {

    const [data, setData] = useState({})
    const [tmpComponent, setTmpComponent] = useState({})
    const [customSets, setCustomSets] = useState([])
    const [personalSets, setPersonalSets] = useState([])
    const [waiverSets, setWaiverSets] = useState([])
    const [formHeader, setFormHeader] = useState('Step')
    const [visible, setVisible] = useState(false);
    const [errors, setErrors] = useState({});

    useImperativeHandle(ref, ()=>({
        openForm(contentData){
            setData({
                title: contentData?contentData.title:'',
                components: contentData&&contentData.components?contentData.components.map((c, index) => {return {...c, idx: (c.idx||c.idx===0?c.idx:index)}}):[]
            })
            setVisible(true)
            setErrors({})
            loadAttributeSets(ATTRIBUTE_GROUP_TYPE.personal.value)
            loadAttributeSets(ATTRIBUTE_GROUP_TYPE.custom_field.value)
            loadAttributeSets(ATTRIBUTE_GROUP_TYPE.waiver.value)
        }   
    }))

    const handleSaveForm = () => {
        if(!data.title || !data.title.trim()){
            setErrors({titel: 'Field is required'})
            return 
        }
        props.onSubmit(data)
        onHide()
    }

    const loadAttributeSets = (type) => {
        getListAttributeSets(type)
        .then(res => {
            switch(type){
                case ATTRIBUTE_GROUP_TYPE.personal.value:
                    setPersonalSets(res)
                    break;
                case ATTRIBUTE_GROUP_TYPE.custom_field.value:
                    setCustomSets(res)
                    break;
                case ATTRIBUTE_GROUP_TYPE.waiver.value:
                    setWaiverSets(res)
                    break;
                default: break;
            }
        });
    }

    const onSubmitComponent = () => {
        let tmpComponentArr = data.components?[...data.components]:[]
        let item = {
            idx: tmpComponent.index,
            type: tmpComponent.type,
            content: tmpComponent.content,
            personalSetId: tmpComponent.personalSetId,
            customSetId: tmpComponent.customSetId,
            waiverSetId: tmpComponent.waiverSetId
        }
        if(!tmpComponent.index && tmpComponent.index!==0){
            tmpComponentArr.push(item)
        }else{
            tmpComponentArr.splice(tmpComponent.index, 1, item)
        }
        setData({...data, components: tmpComponentArr})
        setTmpComponent({
            type: null,
            content: '',
            personalSetId: null,
            customSetId: null,
        })
    }

    const onAddOrEditComponent = (idx, type, component) => {
        setTmpComponent({
            type: component?component.type:type,
            content: component?component.content:'',
            personalSetId: component?component.personalSetId:null,
            customSetId: component?component.customSetId:null,
            waiverSetId: component?component.waiverSetId:null,
            index: idx||idx===0?idx:(data.components?data.components.length:0)
        })
    }

    const onRemoveComponent = (idx) => {
        let tmpComponentArr = [...data.components]
        tmpComponentArr.splice(idx, 1)
        setData({...data, components: tmpComponentArr})
    }

    const onCancelComponent = () => {
        setTmpComponent({
            type: null,
            content: '',
            personalSetId: null,
            customSetId: null,
        })
    }

    const onContentChange = (components) =>{
        let tmpArr = components.map((c, index) => {return { ...c, idx: index}})
        setData({...data, components: tmpArr})
    }

    const contentComponentTemplate = (component) => {
        return (
            <div className="p-grid">
                <div className="p-col-12 p-lg-6">{COMPONENTT_TYPE[component.type] && COMPONENTT_TYPE[component.type].label}</div>
                {(component.idx===undefined || ((component.idx || component.idx===0) && component.idx!==tmpComponent.index)) &&
                    <div className="p-col-12 p-lg-6 p-r">
                        <Button className="p-button-info" label="Edit" icon="pi pi-pencil" onClick={() => onAddOrEditComponent(component.idx, component.type, component)} style={{width: 'auto'}} />
                        <Button className="p-button-danger" label="Remove" icon="pi pi-times" onClick={() => onRemoveComponent(component.idx)} style={{width: 'auto'}} />
                    </div>
                }
            </div>
        )
    }

    const onHide = () => {
        setTmpComponent({})
        setVisible(false)
    }

    const COMPONENTT_TYPE = {
        free_text: {value: 'free_text', label: 'Free Text', command:()=> onAddOrEditComponent(null, COMPONENTT_TYPE.free_text.value)},
        input_field: {value: 'input_field', label: 'Input Field',  command:()=> onAddOrEditComponent(null, COMPONENTT_TYPE.input_field.value)}
    }

    return (
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-lg" position="right" blockScroll={true} dismissable={false} showCloseIcon={false} onHide={()=>onHide()}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{formHeader}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={onHide}/>
            </div>
            
            <div className="p-sidebar-line p-mb-3"></div>
            
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <label>* Title</label>
                    <InputText value={data.title} onChange={e => setData({...data, title: e.target.value})} />
                    <div className="p-form-error">{errors.title}</div>
                </div>
                <div className="p-col-12 p-r">
                    <SplitButton className="p-l" label="Add Component" icon="pi pi-plus" model={Object.values(COMPONENTT_TYPE)} style={{width: 'auto'}} />
                </div>
                {(()=>{
                    if(tmpComponent.type){
                        return <div className="p-col-12">
                                <Fieldset legend={tmpComponent.type?COMPONENTT_TYPE[tmpComponent.type].label:''}>
                                    <div className="p-grid">
                                    {tmpComponent.type === COMPONENTT_TYPE.free_text.value &&
                                        <div className="p-col-12">
                                            <label>Content</label>
                                            <CKEditor data={tmpComponent.content} onChange={(e) => setTmpComponent({...tmpComponent, content: e.editor.getData() })} />
                                        </div>
                                    }
                                    {tmpComponent.type === COMPONENTT_TYPE.input_field.value &&
                                        <React.Fragment>
                                            <div className="p-col-12">
                                                <label>Personal Set</label>
                                                <Dropdown value={tmpComponent.personalSetId} options={personalSets} showClear onChange={e => setTmpComponent({...tmpComponent, personalSetId: e.value})} />
                                            </div>
                                            <div className="p-col-12">
                                                <label>Custom Set</label>
                                                <Dropdown value={tmpComponent.customSetId} options={customSets} showClear onChange={e => setTmpComponent({...tmpComponent, customSetId: e.value})} />
                                            </div>
                                            <div className="p-col-12">
                                                <label>Waiver Set</label>
                                                <Dropdown value={tmpComponent.waiverSetId} options={waiverSets} showClear onChange={e => setTmpComponent({...tmpComponent, waiverSetId: e.value})} />
                                            </div>
                                        </React.Fragment>
                                    }
                                    <div className="p-col-12 p-r">
                                        <Button label="Cancel" onClick={() => onCancelComponent()} style={{width: 'auto'}} />
                                        <Button label="Submit" onClick={() => onSubmitComponent()} style={{width: 'auto'}} />
                                    </div>
                                </div>    
                            </Fieldset>
                        </div>
                    }
                })()}
                {data.components && data.components.length>0 &&
                    <div className="p-col-12">
                        <Fieldset legend="Contents">
                            <OrderList value={data.components} itemTemplate={contentComponentTemplate} onChange={(e) => onContentChange(e.value)} responsive={true} listStyle={{height: 'auto'}}></OrderList>

                            {/* <div className="p-grid">
                                {data.components.map((component, index) => {
                                    return <div key={index} className="p-col-12">
                                            <div className="p-grid">
                                                <div className="p-col-12 p-lg-6">{COMPONENTT_TYPE[component.type] && COMPONENTT_TYPE[component.type].label}</div>
                                                {index!==tmpComponent.index &&
                                                    <div className="p-col-12 p-lg-6 p-r">
                                                        <Button className="p-button-warning" icon="pi pi-pencil" onClick={() => onAddOrEditComponent(index, component.type, component)} />
                                                        <Button className="p-button-danger" icon="pi pi-times" onClick={() => onRemoveComponent(index)} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                })}
                            </div> */}
                        </Fieldset>
                    </div>
                }
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={onHide}/>
                    <Button label="Save" icon="pi pi-check" onClick={() => handleSaveForm()}/>
                </div>
            </div>
        </Sidebar>
    )
}) 

