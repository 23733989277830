export const COMPLETE_FORM_REQUESTING = '[FORM_WORKFLOW] COMPLETE_FORM_REQUESTING';
export const COMPLETE_FORM_REQUEST_SUCCESS = '[FORM_WORKFLOW] COMPLETE_FORM_REQUEST_SUCCESS';
export const COMPLETE_FORM_REQUEST_FAILED = '[FORM_WORKFLOW] COMPLETE_FORM_REQUEST_FAILED';

export const START_PROCESSING_FORM = '[FORM_WORKFLOW] START_PROCESSING_FORM';
export const TOGGLE_WORKFLOW = '[FORM_WORKFLOW] TOGGLE_WORKFLOW';

export const MARK_STEP_DONE_REQUESTING = '[FORM_WORKFLOW] MARK_STEP_DONE_REQUESTING';
export const MARK_STEP_DONE_REQUEST_SUCCESS = '[FORM_WORKFLOW] MARK_STEP_DONE_REQUEST_SUCCESS';
export const MARK_STEP_DONE_REQUEST_FAILED = '[FORM_WORKFLOW] MARK_STEP_DONE_REQUEST_FAILED';

export const MARK_CHECKPOINT_DONE_REQUESTING = '[FORM_WORKFLOW] MARK_CHECKPOINT_DONE_REQUESTING';
export const MARK_CHECKPOINT_DONE_REQUEST_SUCCESS = '[FORM_WORKFLOW] MARK_CHECKPOINT_DONE_REQUEST_SUCCESS';
export const MARK_CHECKPOINT_DONE_REQUEST_FAILED = '[FORM_WORKFLOW] MARK_CHECKPOINT_DONE_REQUEST_FAILED';
