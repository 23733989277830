import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

export const EventSessionVideo = (props) => {
    const defaultVideo = {videoUrl: '', videoChatUrl:''}
    const [frmVisible, setFrmVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [dataIdx, setDataIdx] = useState()
    const [errors, setErrors] = useState({})
    const value = props.value?props.value:[]
    
    useEffect(()=>{
        if(props.errors && Object.keys(props.errors).length>0){
            setErrors(props.errors)
        }
    }, [props.errors])
    
    const addOrEditItem = (idx) => {
        if(idx===undefined){
            setFrmData({...defaultVideo})
        }else{
            setFrmData({...value[idx]})
        }

        setDataIdx(idx)
        setFrmVisible(true)
        setErrors({})
    }

    const closeForm = () => {
        setFrmVisible(false)
        setFrmData({})
        setDataIdx(null)
    }

    const removeItem = (idx) => {
        if(idx!==undefined){
            let lstValue = [...value];
            lstValue.splice(idx, 1);
            if(idx===dataIdx && frmVisible){
                closeForm()
            }
            props.onChange(lstValue);
        }
    }

    const submitItem = () => {
        let frmErrors = {}

        if(!frmData.name)
            frmErrors.name = 'Field is required!'
        if(!frmData.externalLink)
            frmErrors.externalLink = 'Field is required!'

        if(Object.keys(frmErrors).length>0){
            setErrors(frmErrors)
            return
        }

        let lstValue = [...value];
        
        if(dataIdx===undefined){
            lstValue.push(frmData);
        }else{
            lstValue.splice(dataIdx, 1, frmData);
        }
        
        closeForm()
        props.onChange(lstValue);
    }

    return (
        <div className="p-grid">
            {!frmVisible && 
            <div className="p-col-12">
                <Button label='Add video' className="btn-text-sm" onClick={() => addOrEditItem()}/>
            </div>
            }
            {frmVisible && 
            <div className="p-col-12">
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12">
                        <label className="p-label">* Name</label>
                        <InputText value={frmData.name} onChange={(e) => setFrmData({...frmData, name: e.target.value})}/>
                        <div className="p-form-error">{errors.name}</div>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">* Video url</label>
                        <InputText value={frmData.externalLink} onChange={(e) => setFrmData({...frmData, externalLink: e.target.value})}/>
                        <div className="p-form-error">{errors.externalLink}</div>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Video chat url</label>
                        <InputText value={frmData.externalChatLink} onChange={(e) => setFrmData({...frmData, externalChatLink: e.target.value})}/>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Description</label>
                        <InputTextarea rows={3} value={frmData.description} onChange={(e) => setFrmData({...frmData, description: e.target.value})}/>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r">
                        <Button label="Submit" className="btn-text-sm p-button-success" onClick={() => submitItem()}/>
                        <Button label="Cancel" className="btn-text-sm p-button-default" onClick={() => closeForm()}/>
                    </div>
                </div>
            </div>
            }
            <div className="p-col-12">
                {value && value.length>0 && value.map((val, idx) => {
                    return (
                        <div key={"price_"+idx} className="p-margin-bottom-10">
                            <Fieldset className="p-fieldset-no-legend p-fieldset-padding-sm p-padding-bottom-0">
                                <div className="p-grid">
                                    <div className="p-col-10">
                                    <label className="p-display-block">Name: {val.name}</label>
                                    <label className="p-display-block">Video Url: {val.externalLink}</label>
                                    {val.externalChatLink &&
                                        <label className="p-display-block">Video Chat Url: {val.externalChatLink}</label>
                                    }
                                    {val.description &&
                                        <label className="p-display-block">Description: {val.description}</label>
                                    }
                                    </div>
                                    <div className="p-col-2 p-r">
                                        <Button icon="pi pi-pencil" className="btn-xs p-button-info" onClick={() => addOrEditItem(idx)}/>
                                        <Button icon="pi pi-times" className="btn-xs p-button-danger" onClick={() => removeItem(idx)}/>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>                           
                    );
                })} 
            </div>
        </div>
    )
}