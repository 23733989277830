import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import {DataTable} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import { useRouteMatch, useHistory } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";
import { getPageMemberships } from "./MembershipServices";
import classNames from "classnames";
import { MembershipForm } from "./MembershipForm";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { APP_FEP, DATETIME_FORMAT_DISPLAY, IDENTITY_DOCUMENT_TYPE, MEMBERSHIP_CARD_STATUS, MEMBERSHIP_STATUS, PRODUCT_PARTICIPANT_TYPE, PRODUCT_TYPE } from "../../constants";
import { hasRole, isScpAdmin } from "../../core/security/auth";
import { ROLES_CONFIG } from "../../roles";
import { ExtendMembershipForm } from "./ExtendMembershipForm";
import { Dropdown } from "primereact/dropdown";
import { getListProducts } from "../../pim/product/ProductServices";
import { showWarnNotify } from "../../core/service/NotificationService";

const VERIFIED_OPTION = [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'}
]

export const MembershipListView_ViewOnly_Ext = (props) => {
    const match = useRouteMatch()
    const history = useHistory()
    const [dataList, setDataList] = useState([])
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: -1
    })
    const [filter, setFilter] = useState({
        statuses: [MEMBERSHIP_STATUS.Pending.value, MEMBERSHIP_STATUS.Active.value, MEMBERSHIP_STATUS.Expired.value, MEMBERSHIP_STATUS.Block.value],
        participantTypes: [PRODUCT_PARTICIPANT_TYPE.adult.value, PRODUCT_PARTICIPANT_TYPE.youth.value],
    })
    const [isLoadTable, setLoadTable] = useState(false);
    const [products, setProducts] = useState([])
    const frmMembership = useRef(null)
    const frmExtendMembership = useRef(null)

    useEffect(()=>{
        if(isLoadTable)
            loadPageMembership()
    },[isLoadTable])

    useEffect(()=>{
        loadListProduct()
    },[])

    const loadPageMembership = () => {
        if((!filter.templateIds || filter.templateIds.length===0) && !filter.memberCardCode && !filter.ownerName && !filter.ownerLastName 
            && !filter.identityDocumentNumber && (!filter.isVerified && filter.isVerified!==false)
        ){
            setDataList([])
            setPageable({...pageable,
                total: 0,
                page: 0,
                rows: 10
            })
            setLoadTable(false)

            showWarnNotify('Please fill in the field for processing!', 'Warning Message')
            return
        }
        getPageMemberships(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const loadListProduct = () =>{
        let tmpFilter = {
            app: APP_FEP, 
            types: [PRODUCT_TYPE.membership.value], participantTypes: [PRODUCT_PARTICIPANT_TYPE.adult.value, PRODUCT_PARTICIPANT_TYPE.youth.value],
        }

        getListProducts(tmpFilter)
        .then( res => {
            if(res) {
                setProducts(res)
            }
        })
    }

    const tblHeader = () => {
        return (
            <TableHeader title="Memberships" 
                pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}         
                changePageLength={(e) => onChangePageLength(e)}
                refresh={() => setLoadTable(true)}
            />
        )
    }

    const clearFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setFilter({...filter,
            name: '',
            ownerName: '',
            ownerLastName: '',
            statuses: [MEMBERSHIP_STATUS.Pending.value, MEMBERSHIP_STATUS.Active.value, MEMBERSHIP_STATUS.Expired.value, MEMBERSHIP_STATUS.Block.value],
            identityDocumentNumber: '',
            templateIds: [],
            isVerified: null,
            memberCardCode: '',
        })
        setDataList([])
    }

    const onKeyPrressEnter = (e) => {
        if (e.key === 'Enter') {
            setLoadTable(true)
        }
    }

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Memberships</BreadcrumbsItem>

            <MembershipForm ref={frmMembership}
                participantTypes={[PRODUCT_PARTICIPANT_TYPE.adult.value, PRODUCT_PARTICIPANT_TYPE.youth.value]}
                refreshTable={() => setLoadTable(true)}
            />

            <ExtendMembershipForm ref={frmExtendMembership} 
                reloadTable={()=> setLoadTable(true)}
            />

            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1 className="p-w-bold p-m-0">Memberships</h1>
                    <Fieldset legend="Filters">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    {/* <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Membership Category:</span>
                                            <MultiSelect value={filter.templateIds?filter.templateIds:[]} options={products} filter onChange={e => setFilter({...filter, templateIds: e.value})} />
                                        </div>
                                    </div> */}
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Card Code:</span>
                                            <InputText value={filter.memberCardCode??''} onKeyUp={onKeyPrressEnter} onChange={e => setFilter({...filter, memberCardCode: e.target.value})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Owner First Name:</span>
                                            <InputText value={filter.ownerName} onKeyUp={onKeyPrressEnter} onChange={e => setFilter({...filter, ownerName: e.target.value})} />
                                        </div>    
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Last Name:</span>
                                            <InputText value={filter.ownerLastName} onKeyUp={onKeyPrressEnter} onChange={e => setFilter({...filter, ownerLastName: e.target.value})} />
                                        </div>  
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Work Permit/Passport:</span>
                                            <InputText value={filter.identityDocumentNumber} onKeyUp={onKeyPrressEnter} onChange={e => setFilter({...filter, identityDocumentNumber: e.target.value, identityDocumentTypes: e.target.value?[IDENTITY_DOCUMENT_TYPE.work_permit.value, IDENTITY_DOCUMENT_TYPE.passport.value]:[]})} />
                                        </div>        
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Verified:</span>
                                            <Dropdown value={filter.isVerified} options={VERIFIED_OPTION} showClear onChange={e => setFilter({...filter, isVerified: e.value})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => loadPageMembership()} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={() => clearFilter()} />
                            </div>
                        </div>
                    </Fieldset>
                </div>

                <DataTable emptyMessage="There's no record found" header={tblHeader()} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column header="ID" field="id" bodyClassName="p-c" sortable={true}
                        body={rowData => (isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_EXT_SEARCH_DETAIL_R])) 
                            ? <Button label={rowData.id} icon="pi pi-search" className="p-button-info" tooltip='View membership' tooltipOptions={{position: "top"}} onClick={() => history.push(`/memberships/ext-search/${rowData.id}`)}/>
                            : rowData.id
                        }
                    />
                    <Column header="Owner" field="user.firstName" bodyClassName="p-nowrap"  sortable={true}
                        body={(rowData) => rowData.owner && 
                            <React.Fragment>
                                <div>{rowData.owner.name}</div>
                            </React.Fragment> 
                        }
                    />
                    <Column field="name" header="Membership" sortable={true} />
                    <Column header="Description" 
                        body={rowData => {
                            let tmpWorkPermit = rowData.owner&&rowData.owner.workPermit?rowData.owner.workPermit:null
                            let tmpPassport = rowData.owner&&rowData.owner.passport?rowData.owner.passport:null
                            let isShowDocument = rowData.subscriptionInfo?.price>0
                            return <div style={{display: 'inline-block'}}>
                                <div className="p-mb-2">Type: <b>{rowData.type}</b></div>
                                <div className="p-mb-2">Status: <span className={classNames('status', 'ms-'+rowData.status)}>{rowData.status}</span></div>
                                {isShowDocument && 
                                    <React.Fragment>
                                        <hr />
                                        {tmpWorkPermit && <div className="p-mb-1"><span title="Work permit number">WP</span>: {tmpWorkPermit.docNumber}</div>}
                                        {tmpPassport && <div  className="p-mb-1"><span title="Passport number">PP</span>: {tmpPassport.docNumber}</div>}
                                        <div>
                                            <span className={classNames('status', {'ms-Active': rowData.verified, 'ms-Block': !rowData.verified })}>{!rowData.verified?'NOT YET':'VERIFIED'}</span>
                                        </div>        
                                    </React.Fragment>
                                }
                            </div>
                        }}
                    />
                    <Column header="Card"
                        body={(rowData) => {
                            if(rowData.card)
                                return <React.Fragment>
                                    <div>{rowData.card?.code}</div>
                                    <div className="p-mt-1">[{MEMBERSHIP_CARD_STATUS[rowData.card.status].label}]</div>
                                </React.Fragment>
                        }}
                    />
                    <Column field="joinDate" header="Join Date" bodyClassName="p-r" body={(rowData) => rowData.joinDate && moment(rowData.joinDate).format(DATETIME_FORMAT_DISPLAY)} sortable={true}/>
                    <Column field="end" header="End Date" bodyClassName="p-r" body={(rowData) => rowData.joinDate && moment(rowData.end).format(DATETIME_FORMAT_DISPLAY)} sortable={true}/>
                </DataTable>
            </div>
        </div>
    )
}
