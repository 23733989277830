import React, { forwardRef, useState, useEffect, useImperativeHandle, useRef } from "react";
import moment, { HTML5_FMT } from 'moment'
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { Fieldset } from "primereact/fieldset";
import { showloading, stoploading } from "../../core/service/LoadingService";
import { MEMBERSHIP_TYPE, APP_FEP, PRODUCT_TYPE, PRODUCT_PRICE_UNIT, PRODUCT_SECONDARY_TYPE, DATE_FORMAT_DISPLAY, PRODUCT_PARTICIPANT_TYPE, IDENTITY_DOCUMENT_TYPE } from "../../constants";
import { getListProducts } from "../../pim/product/ProductServices";
import AsyncSelect from 'react-select/async';
import { getAllUsers, getUser } from "../contact/UserServices";
import { ContactForm } from "../contact/ContactForm";
import SelectOption from "../../core/components/contact/SelectionOption";
import { createMembership, removeMembership, updateMembership } from "./MembershipServices";
import { showSuccessNotify, showErrorNotify } from "../../core/service/NotificationService";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputMask } from 'primereact/inputmask';
import { moneyFormat } from "../../core/service/CommonService";
import { getListIdentityDocuments } from "../contact/identity-document/components/IdentityDocumentServices";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";

const MS_PERIOD_TYPES = [
    PRODUCT_PRICE_UNIT.day,
    PRODUCT_PRICE_UNIT.month,
    PRODUCT_PRICE_UNIT.year
]

const USER_TYPE = {
    owner: 'owner',
    broker: 'broker'
}

export const MembershipForm = forwardRef((props, ref) => {
    const history = useHistory()
    const [header, setHeader] = useState('Add new Membership')
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [errors, setErrors] = useState({})

    const ddlUsers = useRef(null)
    const ddlBrokers = useRef(null)
    const frmUser = useRef(null)

    const [msProducts, setMsProducts] = useState([])
    const [msProductOptions, setMsProductOptions] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [workPermitDocument, setWorkPermitDocument] = useState({})
    const [passportDocument, setPassportDocument] = useState({})
    const [finDocument, setFinDocument] = useState({})
    const [selectedBroker, setSelectedBroker] = useState(null)
    const [userType, setUserType] = useState(null)

    useImperativeHandle(ref, () => ({
        openForm(data){
            setHeader((data?'Edit':'Add new') + ' Membership')
            setFrmData(popularFormData(data))
            setVisible(true)
            if(data&&data.id){
                let tmpSelectedMsProduct = msProducts.find(msProduct => msProduct.id===data.templateId)
                setSelectedProduct(tmpSelectedMsProduct)

                let tmpUser = data.owner
                if(tmpUser){
                    setSelectedUser({value: tmpUser.id, label: tmpUser.name, email: tmpUser.email, phone: tmpUser.phone})
                    loadUserIdentityDocuments(tmpUser.id)
                }

                let tmpBroker = data.broker
                if(tmpBroker){
                    setSelectedBroker({value: tmpBroker.id, label: tmpBroker.name, email: tmpBroker.email, phone: tmpBroker.phone})
                }
            }
        }
    }))
    
    useEffect(() => {
        loadAllMembershipProducts()
    }, [])

    const loadAllMembershipProducts = () => {
        const msFilter = {
            app: APP_FEP,
            types: [PRODUCT_TYPE.membership.value],
            participantTypes: props.participantTypes?props.participantTypes:[]
        }

        getListProducts(msFilter, false)
        .then(res => {
            setMsProducts(res)

            if(res && res.length>0){
                const tmpOptions = res.map(p => ({value: p.id, label: p.name}))
                tmpOptions.unshift({value: null, label: 'Select a membership type'})
                setMsProductOptions(tmpOptions)
            }
        })
    }

    const loadUsers = (searchTerm, callback) => {
        getAllUsers(searchTerm)
        .then(res => {
            callback (res) 
        });
    }

    const onCreateContactSuccessful = (e) => {
        if(userType===USER_TYPE.owner){
            ddlUsers.current.focus()
            setSelectedUser({value: e.id, label: e.name})
            setFrmData({...frmData, userId: e.id})
            setWorkPermitDocument({})
            setPassportDocument({})    
        }else if(userType===USER_TYPE.broker){
            ddlBrokers.current.focus()
            setSelectedBroker({value: e.id, label: e.name, email: e.email, phone: e.phone})
            setFrmData({...frmData, brokerId: e.id})
        }
    }

    const loadUserIdentityDocuments = async (userId) => {
        const documents = await getListIdentityDocuments({userId: userId, types: [IDENTITY_DOCUMENT_TYPE.work_permit.value, IDENTITY_DOCUMENT_TYPE.passport.value, IDENTITY_DOCUMENT_TYPE.fin.value], active: true});

        let workPermit = documents?documents.find(d => d.docType===IDENTITY_DOCUMENT_TYPE.work_permit.value):null
        let passport = documents?documents.find(d => d.docType===IDENTITY_DOCUMENT_TYPE.passport.value):null
        let fin = documents?documents.find(d => d.docType===IDENTITY_DOCUMENT_TYPE.fin.value):null

        if(workPermit && workPermit.expiredDate)
            workPermit.expiredDateValue = moment(workPermit.expiredDate).format(DATE_FORMAT_DISPLAY)
        
        if(passport && passport.expiredDate)
            passport.expiredDateValue = moment(workPermit.expiredDate).format('MM/YYYY')
            
        setWorkPermitDocument(workPermit?workPermit:{})
        setPassportDocument(passport?passport:{})
        setFinDocument(fin?fin:{})
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setWorkPermitDocument({})
        setPassportDocument({})
        setFinDocument({})
        setSelectedProduct(null)
        setSelectedUser(null)
        setErrors({})
    }

    const popularFormData = (e) => {
        let tmpSubscription = e?.subscriptionInfo
        return {
            id: e?.id,
            userId: e?.ownerId,
            brokerId: e?.brokerId,
            msProductId: e?.templateId,
            type: e?.type,
            price: tmpSubscription?tmpSubscription.price:0,
            period: tmpSubscription?tmpSubscription.period:1,
            periodType: tmpSubscription?.periodType,
            maxQuantity: e&&e.rules&&e.rules.length>0?e.rules[0].quantity:1,
            startDate: e&&e.start?e.start:'',
            startDateValue: e&&e.start?moment(e.start).toDate():moment().toDate()
        }
    }

    const onMembershipTypeChange = (val) => {
        let product = null
        let msPrice = null
        
        if(val){
            product = getSelectedMembership(val)
            if(product && !product.free && product.prices.length>0){
                msPrice = product.prices[0]
            }
        }

        setSelectedProduct(product)
        setFrmData({
            ...frmData, 
            msProductId: val,
            type: product?product.secondaryType===PRODUCT_SECONDARY_TYPE.ms_group.value?MEMBERSHIP_TYPE.Group.value:MEMBERSHIP_TYPE.Individual.value:null,
            free: product?product.free:false,
            price: msPrice?msPrice.price:0,
            period: msPrice?msPrice.quantity:1,
            periodType: msPrice?msPrice.unit:PRODUCT_PRICE_UNIT.year.value,
            maxQuantity: product && product.secondaryType===PRODUCT_SECONDARY_TYPE.ms_group.value ? (product.slot?product.slot:1) : 1
        })
    }

    const getSelectedMembership = (msId) => {
        const tmpMembership = msProducts.find(p => p.id===msId)
        return tmpMembership?tmpMembership:null
    }

    const onMembershipDateChange = (e) => {
        setFrmData({...frmData, startDateValue: e})
    }

    const onMembershipDateBlur = (e, key) => {
        if(!frmData.startDateValue || !moment(frmData.startDateValue).isValid())
            setFrmData({...frmData, startDateValue: null})
    }

    const onUserChange = (val) => {
        if(val){
            setSelectedUser(val)
            loadUserIdentityDocuments(val.value)
        } 
        else{
            setSelectedUser(null)
            setWorkPermitDocument({})
            setPassportDocument({})
            setFinDocument({})
        }
        setFrmData({...frmData, userId: val?val.value:null})
    }

    const onBrokerChange = (val) => {
        setSelectedBroker(val)
        setFrmData({...frmData, brokerId: val?val.value:null})
    }

    const onExpiredDateChange = (val, type) => {
        switch(type){
            case IDENTITY_DOCUMENT_TYPE.work_permit.value:
                let workPermit = {...workPermitDocument}
                workPermit.expiredDate = val?(moment(val, DATE_FORMAT_DISPLAY).isValid()?moment(val, DATE_FORMAT_DISPLAY).format('YYYY-MM-DD'):'Invalid date'):null
                workPermit.expiredDateValue = val
                setWorkPermitDocument(workPermit)
                break;
            case IDENTITY_DOCUMENT_TYPE.passport.value:
                let passport = {...passportDocument}
                passport.expiredDate = val?(moment(val, 'MM/YYYY').isValid()?moment(val, 'MM/YYYY').format('YYYY-MM-DD'):null):null
                passport.expiredDateValue = val
                setPassportDocument(passport)
                break;
            default:
                break;
        }
    }

    const onAddUser = (actionByUserType) => {
        setUserType(actionByUserType)
        frmUser.current.openForm()
    }

    const handleSaveMembership = async () => {
        showloading()

        let tmpData = {...frmData}
        tmpData.startDate = tmpData.startDateValue && moment(tmpData.startDateValue).isValid() ? moment(tmpData.startDateValue).format(HTML5_FMT.DATE) : ''
        tmpData.workPermitDoc = {...workPermitDocument}
        tmpData.passportDoc = {...passportDocument}
        tmpData.finDoc = {...finDocument}

        try{
            let resMembership
            if(!frmData.id){
                resMembership = await createMembership(tmpData)
            }else{
                resMembership = await updateMembership(tmpData)
            }
            if(!resMembership.errorCode){
                // if(props.refreshTable)
                //     props.refreshTable();
                // closeForm()
                if(frmData.id){
                    closeForm()
                }
                showSuccessNotify('Membership has been created');
                history.push(`/memberships/${resMembership.id}`)
            }else{
                if(resMembership.errorCode===400) setErrors(resMembership.errorObj)
                showErrorNotify(resMembership.errorMessage)
            }
        }catch{
            showErrorNotify('System error!');
        }
        stoploading()
    }

    const Option = props => SelectOption(props)

    return (
        <React.Fragment>
            <ContactForm ref={frmUser}
                contact={(e) => onCreateContactSuccessful(e) }
            />

            <Sidebar visible={visible} position="right" className="p-sidebar-md"  style={{ overflowY: "auto"}} blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
                <div className="p-d-flex p-justify-between ">
                    <h2 className="p-margin-top-10">{header}</h2>
                    <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
                </div>
                
                <div className="p-sidebar-line p-mb-3"></div>
                
                <div className="p-grid">
                    <div className="p-col-12">
                        <Fieldset legend="Membership">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <label className="p-label">Membership Category:</label>
                                    <Dropdown value={frmData.msProductId} options={msProductOptions} showClear={true} onChange={(e) => onMembershipTypeChange(e.value)} />
                                    <div className="p-form-error">{errors.msProduct}</div>
                                </div>
                                {selectedProduct && 
                                <div className="p-col-12">
                                    <label className="p-label">Start at</label>
                                    <MaskedCalendar value={frmData.startDateValue} showIcon={true} onChange={(e) => onMembershipDateChange(e.value)} onBlur={(e) => onMembershipDateBlur(e.target.value)} dateFormat="dd/mm/yy"/>
                                    <span className="p-form-error">{errors.startDate}</span>
                                </div>
                                }
                            </div>

                            {selectedProduct && 
                            <div className="p-grid p-fluid form-group p-mt-2">
                                <div className="p-col-12">
                                    <label className="p-label">Type: <span span className="text-uppercase p-pl-3">{frmData.type && MEMBERSHIP_TYPE[frmData.type] && MEMBERSHIP_TYPE[frmData.type].label}</span></label>
                                </div>
                                {selectedProduct.free ? 
                                <React.Fragment>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">Period</label>
                                        <InputText keyfilter={/^[0-9]*$/} value={frmData.period} onChange={(e) => setFrmData({...frmData, period: e.target.value})}/>
                                        <div className="p-form-error">{errors.period}</div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">Period Type</label>
                                        <Dropdown value={frmData.periodType} options={MS_PERIOD_TYPES} onChange={(e) => setFrmData({...frmData, periodType: e.value})}/>
                                        <div className="p-form-error">{errors.periodType}</div>
                                    </div>
                                </React.Fragment>
                                :
                                <div className="p-col-12">
                                    <label className="p-label">Price: <span className="text-uppercase p-pl-3">{moneyFormat(frmData.price)} / {frmData.period} {frmData.periodType}{frmData.period>1?'s':''}</span></label>
                                </div>
                                }
                            </div>
                            }

                            {selectedProduct && selectedProduct.secondaryType===PRODUCT_SECONDARY_TYPE.ms_group.value &&
                            <div className="p-grid p-fluid form-group p-mt-2">
                                <div className="p-col-6">
                                    <label className="p-label">Max Member Q.ty:</label>
                                    <InputText keyfilter={/^[0-9]*$/} value={frmData.maxQuantity} onChange={(e) => setFrmData({...frmData, maxQuantity: e.target.value})}/>
                                    <div className="p-form-error">{errors.maxQuantity}</div>
                                </div>
                            </div>
                            }
                        </Fieldset>
                        {selectedProduct?.participantType === PRODUCT_PARTICIPANT_TYPE.student.value &&
                            <Fieldset legend="Agency">
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-9">
                                                <AsyncSelect ref={ddlBrokers}
                                                    loadOptions={loadUsers} onChange={(e) => onBrokerChange(e)}
                                                    placeholder='Type your search'
                                                    components={{Option}}
                                                    value={selectedBroker}
                                                    isClearable
                                                />
                                            </div>
                                            <div className="p-col-3">
                                                <Button label="New User" icon="pi-md-person-add" className="p-button-info" onClick={(e) => onAddUser(USER_TYPE.broker)}/>
                                            </div>
                                        </div>
                                        <div className="p-form-error">{errors.brokerId}</div>
                                    </div>

                                    {/* {selectedBroker && selectedBroker.value &&
                                    <React.Fragment>
                                        <div className="p-fieldset-line p-py-0"></div>
                                        <div className="p-col-12">
                                            <Fieldset legend="Agency information">
                                                <div className="p-grid form-group">
                                                    <div className="p-col-4">Name:</div>
                                                    <div className="p-col-8"><strong>{selectedBroker.name}</strong></div>
                                                    <div className="p-col-4">Email:</div>
                                                    <div className="p-col-8"><strong>{selectedBroker.email}</strong></div>
                                                    <div className="p-col-4">Phone:</div>
                                                    <div className="p-col-8"><strong>{selectedBroker.phone}</strong></div>
                                                </div>
                                            </Fieldset>
                                        </div>
                                    </React.Fragment>
                                    } */}
                                </div>
                            </Fieldset>
                        }
                        <Fieldset legend="Owner">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-9">
                                            <AsyncSelect ref={ddlUsers}
                                                loadOptions={loadUsers} onChange={(e) => onUserChange(e)}
                                                placeholder='Type your search'
                                                components={{Option}}
                                                value={selectedUser}
                                                isClearable
                                            />
                                        </div>
                                        <div className="p-col-3">
                                            <Button label="New User" icon="pi-md-person-add" className="p-button-info" onClick={(e) => onAddUser(USER_TYPE.owner)}/>
                                        </div>
                                    </div>
                                    <div className="p-form-error">{errors.userId}</div>
                                </div>

                                {selectedUser && selectedUser.value?
                                <React.Fragment>
                                    <div className="p-fieldset-line p-py-0"></div>
                                    <div className="p-col-12">
                                        <Fieldset legend="Owner information">
                                            <div className="p-grid form-group">
                                                <div className="p-col-4">Name:</div>
                                                <div className="p-col-8"><strong>{selectedUser.label}</strong></div>
                                                <div className="p-col-4">Email:</div>
                                                <div className="p-col-8"><strong>{selectedUser.email}</strong></div>
                                                <div className="p-col-4">Phone:</div>
                                                <div className="p-col-8"><strong>{selectedUser.phone}</strong></div>
                                            </div>
                                        </Fieldset>
                                    </div>
                                    <div className="p-col-12">
                                    {selectedUser && selectedUser.value
                                        && selectedProduct && selectedProduct.requiredIdentificationDocument && 
                                            <Fieldset legend="Document">
                                                <div className="p-grid p-fluid form-group">
                                                    {selectedProduct.participantType===PRODUCT_PARTICIPANT_TYPE.student.value
                                                        ? <div className="p-col-12">
                                                            <label className="p-label">FIN Number</label>
                                                            <InputText value={finDocument?.docNumber??''} onChange={(e) => setFinDocument({...finDocument, docNumber: e.target.value})}/>
                                                            <div className="p-form-error">{errors.finNumber}</div>
                                                        </div>
                                                        : <React.Fragment>
                                                            <div className="p-col-12 p-md-6">
                                                                <label className="p-label">Work Permit Number</label>
                                                                <InputText value={workPermitDocument?.docNumber??''} onChange={(e) => setWorkPermitDocument({...workPermitDocument, docNumber: e.target.value})} placeholder="Eg. 0 12345678, 0 1234567-"/>
                                                                <div className="p-form-error">{errors.workPermitNumber}</div>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label className="p-label">Expired Date</label>
                                                                <InputMask mask="99/99/9999" value={workPermitDocument.expiredDateValue} slotChar="DD/MM/YYYY" placeholder="DD/MM/YYYY" onChange={(e) => onExpiredDateChange(e.value, IDENTITY_DOCUMENT_TYPE.work_permit.value)}></InputMask>
                                                                <div className="p-form-error">{errors.workPermitExpiredDate}</div>
                                                            </div>       
                                                        </React.Fragment>
                                                    }
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">Passport Number</label>
                                                        <InputText value={passportDocument?.docNumber??''} onChange={(e) => setPassportDocument({...passportDocument, docNumber: e.target.value})}/>
                                                        <div className="p-form-error">{errors.passportNumber}</div>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">Expired Date</label>
                                                        <InputMask mask="99/9999" value={passportDocument.expiredDateValue} slotChar="MM/YYYY" placeholder="MM/YYYY" onChange={(e) => onExpiredDateChange(e.value, IDENTITY_DOCUMENT_TYPE.passport.value)}></InputMask>
                                                        <div className="p-form-error">{errors.passportExpiredDate}</div>
                                                    </div>
                                                    {selectedProduct.participantType!==PRODUCT_PARTICIPANT_TYPE.student.value &&
                                                        <div className="p-col-12">
                                                            <label className="p-label">FIN Number</label>
                                                            <InputText value={finDocument?.docNumber??''} onChange={(e) => setFinDocument({...finDocument, docNumber: e.target.value})}/>
                                                            <div className="p-form-error">{errors.finNumber}</div>
                                                        </div>
                                                    }
                                                </div>
                                            </Fieldset>
                                        }
                                    </div>
                                </React.Fragment>
                                :''}
                            </div>
                        </Fieldset>
                        
                    </div>
                </div>

                <div className="p-sidebar-line p-my-3"></div>
                
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => closeForm()} />
                        <Button label="Save" icon="pi pi-save" onClick={() => handleSaveMembership()} />
                    </div>
                </div>
            </Sidebar>
        </React.Fragment>
    )
})
