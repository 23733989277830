import React, { Component } from "react";
import moment from "moment";
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { TaskServices } from "./TaskServices";
import { showNotification } from '../../core/service/NotificationService';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Sidebar } from "primereact/sidebar";
import { Fieldset } from "primereact/fieldset";
import Avatar from 'react-avatar';
import { UserServices } from "../contact/UserServices";
import AsyncSelect from 'react-select/async';
import SelectOption from "../../core/components/contact/SelectionOption.js";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { getPriorityLevelUse } from "../../scp/tenant-configuration/ConfigruationServices";
import ToDoTask from "../../core/components/to-do-task/ToDoTask";
import { NOTE_STATUSES } from "../../constants";

export default class TaskList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Task Variables Declare
            formTaskViewDialog: {
                visible: false
            },
            formTaskActionDialog: {
                header: 'New Task',
                visible: false
            },

            arrTaskFilter: [],

            task: {
                id: '',
                assign: null,
                objectType: "contact",
                objectId: 25,
                title: '',
                content: '',
                status: 'create',
                priority: 2,
                notification: false,
                todoId: this.props.todoId
            },

            tasks: [],

            taskActivity: {},
            taskActivities: [],

            errors: {},

            assignUser: null
        }
        this.taskServices = new TaskServices();
        this.userServices = new UserServices();
    }

    componentDidMount() {
        this.getListNotes();
        this.loadDepartments();
        this.getPrioriyLevelUse();
    }

    handleSaveTask = () => {
        this.taskServices.saveTask(this.state.task)
            .then(res => {
                if (!res.errorCode) {
                    let arr = [...this.state.arrTasks];
                    if (arr.indexOf(arr.find(x => x.id === res.id)) !== -1)
                        arr.splice(arr.indexOf(arr.find(x => x.id === res.id)), 1, res)
                    else
                        arr.unshift(res);
                    this.setState({
                        arrTasks: arr
                    });
                    this.hideFormTaskActionDialog();
                    this.props.popularTasks();
                    showNotification('success', 'Success Message', 'Task has been assigned');
                    this.popularTaskForm();
                    showNotification('success', 'Success Message', 'Action Submitted');
                    this.setState({
                        assignUser: null
                    });
                } else {
                    showNotification('error', 'Error Message', 'Cannot perform action');
                    if (res.errorObj) this.setState({ errors: res.errorObj });
                }
            })
    }

    getListNotes = () => {
        this.taskServices.getListNotes(this.state.task.objectId, this.state.task.objectType)
            .then(res => {
                if (!res.errorCode) {
                    if (res) {
                        this.setState({ arrTasks: res });
                    }
                }
            })
    }

    getNote = (id) => {
        this.taskServices.getNote(id)
            .then(res => {
                if (!res.errorCode) {
                    this.popularTaskForm(res);
                } else {
                    showNotification('error', 'Error Message', res.errorMessage);
                }
            })
    }

    loadDepartments = () => {
        let deptUsers = localStorage.getItem('depts');
        if (deptUsers && deptUsers !== 'undefined') {
            let deptsData = JSON.parse(deptUsers);
            let tempArrDepts = [];
            if (deptsData && deptsData.length > 0) {
                deptsData.map(dept => {
                    tempArrDepts.push({ label: dept.name, value: dept.id })
                });
            }
            this.setState({ departments: tempArrDepts });
        }
    }

    getPrioriyLevelUse = () => {
        getPriorityLevelUse()
            .then(res => {
                if (!res.errorCode) {
                    this.setState({ priorityLevelUse: res });
                }
            })
    }

    showFormTaskActionDialog = () => {
        this.setState({
            formTaskActionDialog: {
                ...this.state.formTaskActionDialog,
                visible: true
            }
        })
    }

    hideFormTaskActionDialog = () => {
        this.setState({
            formTaskActionDialog: {
                ...this.state.formTaskActionDialog,
                visible: false
            }
        })
    }

    onAddOrEdit = (id) => {
        if (!id) {
            this.popularTaskForm();
        } else {
            this.getNote(id);
            this.showFormTaskActionDialog();
        }
    }

    // onChangeCkh = (e) => {
    //     let termArr = [...this.state.arrNoteChks];
    //     if (e.checked) {
    //         if (termArr.indexOf(e.value) === -1)
    //             termArr.push(e.value)
    //     } else {
    //         if (termArr.indexOf(e.value) !== -1)
    //             termArr.splice(termArr.indexOf(e.value), 1)
    //     }
    //     this.setState({
    //         arrNoteChks: termArr
    //     })
    // }

    onDueDateChange = (e) => {
        this.setState({
            task: {
                ...this.state.task,
                dueDate: e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '',
                dueDateValue: e.value,
            }
        });
    }

    // onFilter = (e) => {
    //     let tempArr = [...this.state.arrNoteFilter];
    //     if (e.checked) {
    //         if (!tempArr.includes(e.value)) {
    //             tempArr.push(e.value)
    //         }
    //     } else {
    //         tempArr.splice(tempArr.indexOf(e.value), 1);
    //     }
    //     this.setState({ arrNoteFilter: tempArr });
    // }

    onTaskFilter = (e) => {
        let tempArr = [...this.state.arrTaskFilter];
        if (e.checked) {
            if (!tempArr.includes(e.value)) {
                tempArr.push(e.value)
            }
        } else {
            tempArr.splice(tempArr.indexOf(e.value), 1);
        }
        this.setState({ arrTaskFilter: tempArr });
    }

    //#region note activity
    handleSaveNoteActivity = () => {
        this.taskServices.saveNoteActivity(this.state.task.id, this.state.taskActivity)
            .then(res => {
                if (!res.errorCode) {
                    let arr = [...this.state.arrTasks];
                    if (arr.indexOf(arr.find(x => x.id === this.state.task.id)) !== -1) {
                        let noteObj = {
                            ...this.state.task,
                            status: this.state.taskActivity.status
                        }
                        arr.splice(arr.indexOf(arr.find(x => x.id === this.state.task.id)), 1, noteObj);
                    }
                    this.setState({
                        task: {
                            ...this.state.task,
                            status: this.state.taskActivity.status
                        },
                        taskActivity: {
                            status: null,
                            message: ''
                        },
                        arrTasks: arr
                    });
                    this.getListNoteActivities(this.state.task.id);
                    this.hideFormViewDialog();
                    this.props.popularTasks();
                    showNotification('success', 'Success Message', 'Action Submitted');
                } else {
                    showNotification('error', 'Error Message', 'Cannot perform action');
                    if (res.errorObj) this.setState({ errors: res.errorObj });
                }
            })
    }

    getListNoteActivities = (id) => {
        this.taskServices.getListNoteActivities(id)
            .then(res => {
                if (!res.errorCode) {
                    if (res) {
                        this.setState({ taskActivities: res });
                    }
                }
            })
    }


    viewFormDialog = (id) => {
        this.getNote(id);
        this.getListNoteActivities(id);
        this.showFormViewDialog();
    }

    showFormViewDialog = () => {
        this.setState({
            formTaskViewDialog: {
                ...this.state.formTaskViewDialog,
                visible: true
            }
        });
    }

    hideFormViewDialog = () => {
        this.setState({
            formTaskViewDialog: {
                ...this.state.formTaskViewDialog,
                visible: false
            }
        });
    }

    loadUserOptions = (searchTerm, callback) => {
        this.userServices.getAllUsers(searchTerm, 'relationship', this.props.uId)
            .then(res => {
                callback(res)
            });
    }


    // #endregion
    // popularForm = (data) => {
    //     this.setState({
    //         note: {
    //             ...this.state.task,
    //             id: data ? data.id : '',
    //             departmentId: data ? data.departmentId : '',
    //             title: data ? data.title : '',
    //             category: data ? data.category : 'general',
    //             content: data ? data.content : '',
    //             status: data ? data.status : 0,
    //             priority: data ? data.priority : 2,
    //             notification: data ? data.notification : false,
    //             dueDate: data ? data.dueDate : '',
    //             dueDateValue: data && data.dueDate ? moment(data.dueDate, 'YYYY-MM-DD hh:mm A').toDate() : '',
    //             date_dueDateValue: data && data.dueDate ? moment(data.dueDate, moment.HTML5_FMT.DATE).toDate() : '',
    //             time_dueDateValue: data && data.dueDate ? moment(data.dueDate, 'YYYY-MM-DD HH:mm').toDate() : '',
    //         },
    //         errors: {}
    //     }, () => {
    //         this.setState({
    //             formActionDialog: {
    //                 ...this.state.formActionDialog,
    //                 header: data ? 'Edit Note' : 'New Note'
    //             }
    //         })

    //     })
    // }

    popularTaskForm = (data) => {
        this.setState({
            task: {
                ...this.state.task,
                id: data ? data.id : '',
                assign: data ? data.assign : '',
                departmentId: data ? data.departmentId : '',
                title: data ? data.title : '',
                category: data ? data.category : 'general',
                content: data ? data.content : '',
                status: data ? data.status : 0,
                priority: data ? data.priority : 2,
                notification: data ? data.notification : false,
                todoId: this.props.todoId,
                dueDate: data ? data.dueDate : '',
                dueDateValue: data && data.dueDate ? moment(data.dueDate, 'YYYY-MM-DD hh:mm A').toDate() : '',
                date_dueDateValue: data && data.dueDate ? moment(data.dueDate, moment.HTML5_FMT.DATE).toDate() : '',
                time_dueDateValue: data && data.dueDate ? moment(data.dueDate, 'YYYY-MM-DD HH:mm').toDate() : '',
            },
            errors: {}
        }, () => {
            this.setState({
                formActionDialog: {
                    ...this.state.formActionDialog,
                    header: 'New Task',
                    visible: true
                }
            })
        })
    }

    render() {

        let taskMenus = [
            {
                label: 'Add Task', icon: 'pi-md-plus',
                command: () => {
                    this.popularTaskForm();
                    this.setState({
                        formTaskActionDialog: {
                            header: 'New Task',
                            visible: false
                        },
                    });
                    this.showFormTaskActionDialog();
                }
            }
        ];

        let lstPriority = [
            { label: 'Low', value: 1 },
            { label: 'Normal', value: 2 },
            { label: 'Medium', value: 3 },
            { label: 'High', value: 4 },
            { label: 'Critical', value: 5 },
        ];
        if (!this.state.priorityLevelUse || (this.state.priorityLevelUse && this.state.priorityLevelUse === 3)) {
            lstPriority = lstPriority.filter(priority => (priority.value !== 1 && priority.value !== 5));
        }

        let lstCategories = [
            { label: 'General', value: 'general' },
            { label: 'Email', value: 'email' },
            { label: 'Call', value: 'call' },
            { label: 'Meeting', value: 'meeting' },
        ];

        let lstActivityStatus = NOTE_STATUSES.filter(x => x.value !== this.state.task.status);

        let myTasks = this.props.myTasks;

        let dept = this.state.departments;

        const Option = props => SelectOption(props)

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-9">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <label>Category:</label>
                                </div>
                                <div className="p-col-12">
                                    <span className="p-margin-right-15" style={{ whiteSpace: "nowrap" }}>
                                        <Checkbox inputId="cbGeneral" value="general" onChange={(e) => this.onTaskFilter(e)} checked={this.state.arrTaskFilter.includes('general')} />
                                        <label htmlFor="cbGeneral">General</label>
                                    </span>
                                    <span className="p-margin-right-15" style={{ whiteSpace: "nowrap" }}>
                                        <Checkbox inputId="cbEmail" value="email" onChange={(e) => this.onTaskFilter(e)} checked={this.state.arrTaskFilter.includes('email')} />
                                        <label htmlFor="cbEmail">Email</label>
                                    </span>
                                    <span className="p-margin-right-15" style={{ whiteSpace: "nowrap" }}>
                                        <Checkbox inputId="cbCall" value="call" onChange={(e) => this.onTaskFilter(e)} checked={this.state.arrTaskFilter.includes('call')} />
                                        <label htmlFor="cbCall">Call</label>
                                    </span>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        <Checkbox inputId="cbMeeting" value="meeting" onChange={(e) => this.onTaskFilter(e)} checked={this.state.arrTaskFilter.includes('meeting')} />
                                        <label htmlFor="cbMeeting">Meeting</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={"p-col-12 p-r p-md-3"}>
                            {this.props.isTodoTask == true ? <Button icon="pi-md-arrow-back" className="p-button-primary" onClick={(e) => this.props.onShowList()} /> : ''}
                            <Button icon="pi-md-menu" className="secondary-btn" tooltip="Add Task" tooltipOptions={{ position: 'top' }} onClick={(event) => this.menu.toggle(event)} />
                        </div>
                    </div>
                    <Menu ref={el => this.menu = el} popup={true} model={taskMenus}></Menu>
                    {myTasks && myTasks.length > 0 ?
                        <React.Fragment>
                            <ul className="p-padding-none" style={{ listStyle: 'none' }}>
                                {this.state.arrTaskFilter.length === 0 ?
                                    myTasks.map((task, index) => { 
                                        let departmentSymbol = dept ? dept.find(dept => dept.value == task.departmentId): ''; 
                                        return (
                                            <ToDoTask deptName={departmentSymbol ? departmentSymbol.label : ''} viewFormDialog={(e) => this.viewFormDialog(task.id)} onAddOrEdit={(e) => this.onAddOrEdit(task.id)} myTask={task} key={index}/>
                                        )
                                    }) :
                                    myTasks.map((task, index) => {
                                        let departmentSymbol = dept ? dept.find(dept => dept.value == task.departmentId): ''; 
                                        return this.state.arrTaskFilter.includes(task.category) ?
                                            <ToDoTask deptName={departmentSymbol ? departmentSymbol.label : ''} viewFormDialog={(e) => this.viewFormDialog(task.id)} onAddOrEdit={(e) => this.onAddOrEdit(task.id)} myTask={task} key={index}/>
                                            : ""
                                    })
                                }
                            </ul>
                        </React.Fragment>
                        :
                        ""
                    }
                    <Sidebar visible={this.state.formTaskActionDialog.visible} style={{ overflowY: 'auto', width: '25em' }} position="right" blockScroll={true} onHide={this.hideFormTaskActionDialog}>
                        <h2>{this.state.formTaskActionDialog.header}</h2>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                <label className="p-label">* Title</label>
                                <InputText value={this.state.task.title} onChange={(e) => this.setState({ task: { ...this.state.task, title: e.target.value } })} maxLength="50" />
                                <div className="p-form-error">{this.state.errors.title}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Content</label>
                                <InputTextarea rows={3} cols={30} value={this.state.task.content} onChange={(e) => this.setState({ task: { ...this.state.task, content: e.target.value } })} />
                            </div>

                            <div className="p-col-12">
                                <label className="p-label">Assign</label>
                                <AsyncSelect
                                    loadOptions={this.loadUserOptions}
                                    value={this.state.assignUser}
                                    onChange={(e) => this.setState({ task: { ...this.state.task, assign: e ? e.value : '' }, assignUser: e })}
                                    placeholder='Type your search'
                                    components={{ Option }} isClearable
                                />
                            </div>

                            <div className="p-col-12">
                                <label>Department</label>
                                <Dropdown options={this.state.departments} value={this.state.task.departmentId} onChange={(e) => this.setState({ task: { ...this.state.task, departmentId: e.value } })} />
                            </div>
                            <div className="p-col-12">
                                <label>Category</label>
                                <Dropdown options={lstCategories} value={this.state.task.category} onChange={(e) => this.setState({ task: { ...this.state.task, category: e.value } })} />
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Priority</label>
                                <Dropdown value={this.state.task.priority} options={lstPriority} onChange={(e) => this.setState({ task: { ...this.state.task, priority: e.value } })} style={{ width: '100%' }}
                                    itemTemplate={(option) => {
                                        let className = 'p-priority p-priority-' + option.value;
                                        return (
                                            <div>
                                                <span className={className}></span>
                                                <span className="p-margin-left-10">{option.label}</span>
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                            <div className="p-col-12">
                                <Checkbox value={this.state.task.notification} onChange={(e) => this.setState({ task: { ...this.state.task, notification: e.checked } })} checked={this.state.task.notification} />
                                <label>Notification?</label>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Due Date</label>
                                <MaskedCalendar value={this.state.task.dueDateValue} showIcon={true} showTime={true} hourFormat="12" hideOnDateTimeSelect={true} breakDateTime={true} onChange={(e) => this.onDueDateChange(e)} dateFormat="mm-dd-yy" />
                                <div className="p-form-error">{this.state.errors.dueDate}</div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                                <Button label="Save" icon="pi pi-check" onClick={this.handleSaveTask} />
                                <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={(e) => this.hideFormTaskActionDialog()} />
                            </div>
                        </div>
                    </Sidebar>
                    {/* <i>({NOTE_STATUSES.find(x => x.value === this.state.task.status).label})</i> */}
                    <Sidebar visible={this.state.formTaskViewDialog.visible} position="right" blockScroll={true} onHide={this.hideFormViewDialog} style={{ overflowY: 'auto', width: '50em' }} >
                        <h2>{this.state.task.title} </h2>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                <span>{this.state.task.content}</span>
                            </div>
                        </div>
                        <Fieldset legend="Comment">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <label className="p-label">* Status</label>
                                    <Dropdown value={this.state.taskActivity.status} options={lstActivityStatus} onChange={(e) => this.setState({ taskActivity: { ...this.state.taskActivity, status: e.value } })} style={{ width: '100%' }} />
                                    <div className="p-form-error">{this.state.errors.status}</div>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Message</label>
                                    <InputTextarea rows={5} cols={30} value={this.state.taskActivity.message} onChange={(e) => this.setState({ taskActivity: { ...this.state.taskActivity, message: e.target.value } })} />
                                </div>
                            </div>
                            <div className="p-grid form-group">
                                <div className="p-col-12" style={{ textAlign: 'right' }}>
                                    <Button label="Save" icon="pi pi-check" onClick={this.handleSaveNoteActivity} />
                                </div>
                            </div>
                        </Fieldset>
                        <br />
                        <div className="p-grid form-group">
                            <div className="p-col-12">
                                <ul className="p-margin-none p-padding-none" style={{ listStyle: 'none', maxHeight: 450, overflow: 'auto' }}>
                                    {this.state.taskActivities.map((item, index) => {
                                        return (
                                            <li key={index} style={{ padding: 5 }}>
                                                {item.user.avatar ?
                                                    <img src={item.user.avatar} width={30} style={{ float: 'left' }} />
                                                    : <Avatar className="topbar-profile-avatar" maxInitials={2} round={true} textSizeRatio={2} size={30} name={item.user.fistname + " " + item.user.lastName} style={{ float: 'left' }} />
                                                }
                                                <div style={{ marginLeft: 40, minHeight: 30 }}>
                                                    <div>({item.statusChange})</div>
                                                    <div><i>{moment(item.created).format('MM-DD-YYYY HH:mm:ss')}</i></div>
                                                    <div className="p-padding-5-0">{item.message}</div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <hr />
                        <div className="p-grid">
                            <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                                <Button label="Close" icon="pi pi-times" className="p-button-secondary" onClick={(e) => this.hideFormViewDialog()} />
                            </div>
                        </div>
                    </Sidebar>
                </div>
            </div>
        );
    }
}