import axios from "axios"
import { API_BASE_URL } from "../../constants";
import querystring from "querystring";

export class CampaignServices {
    
    getPageCampaign(page, size, sortField, sortOrder){
        const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
        const sort = sortField ? `${sortField},${order}`: "";
        return axios.get(`${API_BASE_URL}/scp/api/campaigns/page`,{
            params: {page, size, sort}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    getListCampaign(active){
        return axios.get(`${API_BASE_URL}/scp/api/campaigns/list`,{
            params: {active}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    getCampaign(id){
        return axios.get(`${API_BASE_URL}/scp/api/campaigns/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveCampaign(data){
        return axios.post(`${API_BASE_URL}/scp/api/campaigns`, data)
        .then(res => res.data).catch(error => console.log(error));
    }

    actionToPrograms(id, ids){
        return axios.post(`${API_BASE_URL}/scp/api/campaigns/${id}/action/programs`,
            querystring.stringify({ids: ids})
        ).then(res => res.data).catch(error => console.log(error));
    }
}