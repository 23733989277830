import {
  COMPLETE_FORM_REQUESTING,
  START_PROCESSING_FORM,
  TOGGLE_WORKFLOW, 
  MARK_STEP_DONE_REQUESTING,
  MARK_CHECKPOINT_DONE_REQUESTING
} from '../constants/workflow'

export const completeForm = (id, groups) => ({
  type: COMPLETE_FORM_REQUESTING, id, groups
});

export const startProcessingForm = (formInstanceId) => ({
  type: START_PROCESSING_FORM, formInstanceId
})

export const toggleWorkflow = () => ({
  type: TOGGLE_WORKFLOW, showWorkflow: false
})

export const markStepDone = (formInstanceId, step) => ({
  type: MARK_STEP_DONE_REQUESTING, formInstanceId, step
})

export const markCheckpointDone = (formInstanceId, checkpointId) => ({
  type: MARK_CHECKPOINT_DONE_REQUESTING, formInstanceId, checkpointId
})