import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Dropdown} from 'primereact/dropdown';
import {GLAccountServices} from './GLAccountServices';
import {GL_TYPES} from '../../../constants';
import { showNotification } from '../../../core/service/NotificationService';
import { Sidebar } from 'primereact/sidebar';
import { replaceSpaceAndUpperCase, titleCaseText } from "../../../core/service/CommonService";

export class GLAccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: {},
            accountList: [],
            errors: {}
        }
        this.accountServices = new GLAccountServices();
    }

    popularFormData = (data) => {
        this.setState({
            account: {
                id: data ? data.id : '',
                lastId: data ? data.id : '',
                parentId: data ? data.parentId : '',
                name: data ? data.name : '',
                type: data ? data.type : null,
                active: data ? data.active : true
            },
            errors: {}
        });
    }

    getAccountList = (excludedId) =>{
        this.accountServices.getAccountList(excludedId, '').then(res => {
            res.unshift({value:'', label: 'No parent'});
            this.setState({accountList: res});
        });
    }

    handleSaveAccount = () => {
        this.accountServices.saveAccount(this.state.account).then(res => {
            if (!res.errorCode) {
                this.props.reloadTable();
                showNotification('success', 'Success Message', 'Action submitted');
                this.props.hideFormDialog();
            } else {
                if (res.errorCode === 400) {
                    this.setState({errors: res.errorObj})
                }
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        });
    }

    replaceSpaceAndUpperCase = (data) => {
        let str = data.toUpperCase().replace(/ /g,"_");
        return str
    }

    render() {

        return (
            <Sidebar visible={this.props.visible} style={{ overflowY: "auto", width: "20%" }} position="right" onHide={this.props.hideFormDialog}>
                <h2>{this.props.header}</h2>
                <div className="p-grid p-fluid">
                        {!this.state.account.lastId?
                        <div className="p-col-12">
                            <label>* Code</label>
                            <InputText keyfilter={/[^\s]/} value={this.state.account.id} disable={this.state.account.lastId?true:false} onChange={(e) => this.setState({account: {...this.state.account, id:replaceSpaceAndUpperCase(e.target.value)}})} />
                            <div className="p-form-error">{this.state.errors.id} </div>
                        </div>
                        :
                        <div className="p-col-12">
                            <label>Code: </label>
                            <label className="p-w-bold">{this.state.account.id}</label>
                        </div>
                    }
                    <div className="p-col-12">
                        <label className="p-label">Parent</label>
                        <Dropdown code="parent" value={this.state.account.parentId} options={this.state.accountList} filter={true} onChange={(e) => this.setState({account: {...this.state.account, parentId: e.value}})} style={{ width: '100%' }} placeholder="Select Parent" />
                        <div className="p-form-error">{this.state.errors.parentId} </div>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">* Name</label>
                        <InputText code="name" value={this.state.account.name} onChange={(e) => this.setState({account: {...this.state.account, name:titleCaseText(e.target.value)}})} />
                        <div className="p-form-error">{this.state.errors.name} </div>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">* Type</label>
                        <Dropdown value={this.state.account.type} options={GL_TYPES} onChange={(e) => this.setState({account: {...this.state.account, type: e.value}})} style={{ width: '100%' }} placeholder="Select Type"/>
                        <div className="p-form-error">{this.state.errors.type} </div>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Submit" icon="pi pi-check" onClick={this.handleSaveAccount} />
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.props.hideFormDialog} />
                    </div>
                </div>
            </Sidebar>          
        );
    }
}