import React, { useState, useEffect } from "react";
import {DataTable} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import { useRouteMatch } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";
import { getPageWishList, sendEmailByWishList } from "./WishListService";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { DATETIME_FORMAT_DISPLAY, DATE_FORMAT_DISPLAY, OBJECT_TYPE, TIME_FORMAT_DISPLAY } from "../../constants";
import { Dropdown } from 'primereact/dropdown';
import { showErrorNotify, showSuccessNotify } from "../../core/service/NotificationService";


const OBJECT_WISH_LIST_TYPE = {
  product_session: {value: OBJECT_TYPE.product_session, label: 'Activity'},
  product: {value: OBJECT_TYPE.product, label: 'Facility'}
}

export const WishListListView = () => {
  const match = useRouteMatch()

  const [dataList, setDataList] = useState([])
  const [pageable, setPageable] = useState({        
      page: 0,
      rows: 10,
      total: 0,
      sortField: '',
      sortOrder: -1
  })
  const[filter, setFilter] = useState({
    startDate: moment(new Date()).format(moment.HTML5_FMT.DATE),
    startDateValue: new Date(),
    minDate: new Date()
  })
  const [isLoadTable, setLoadTable] = useState(true)

  useEffect(()=>{
    if(isLoadTable)
      loadPageBlockContents()
  },[isLoadTable])

  const loadPageBlockContents = () => {
    getPageWishList(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
    .then(res => {
      setDataList(res.content)
      setPageable({...pageable,
          total: res.totalElements,
          page: res.pageable.pageNumber,
          rows: res.pageable.pageSize
      })
    })
    .finally(setLoadTable(false))
  };

  const onPage = (e) =>{
    setPageable({...pageable,
      page: e.page
    })
    setLoadTable(true);
  }

  const onSort = (e) =>{
    setPageable({...pageable,
      sortField: e.sortField,
      sortOrder: e.sortOrder
    })
    setLoadTable(true);
  }

  const onChangePageLength = (e) => {
    setPageable({...pageable,
      rows: e,
      page: 0
    })
    setLoadTable(true);
  };

  const tblHeader = (
    <TableHeader title="Wait List" 
      pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
      changePageLength={(e) => onChangePageLength(e)}
      refresh={() => setLoadTable(true)}
    />
  );

  const handleSendEmail = (id) => {
    sendEmailByWishList(id)
    .then(res => {
      if(!res.errorCode){
        showSuccessNotify('Email sent successfully!')
      }else{
        showErrorNotify(res.errorMessage)
      }
    })
  }

  const onDateFromChange = (e) => {
    let maxDate = filter.maxDate ? moment(filter.maxDate) : '';
    let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
    let isCurrentValueAfterMaxDate = (currentValue && maxDate && currentValue.isAfter(maxDate));

    setFilter({
        ...filter,
        startDate: currentValue?currentValue.format(moment.HTML5_FMT.DATE):'',
        startDateValue: e.value,
        minDate: moment(e.value).isValid() ? e.value : '',
        endDate: !isCurrentValueAfterMaxDate ? filter.endDate : '',
        endDateValue: !isCurrentValueAfterMaxDate ? filter.endDateValue : '',
        maxDate: !isCurrentValueAfterMaxDate ? filter.maxDate : ''
    })
}

const onDateToChange = (e) => {
    let minDate = filter.minDate ? moment(filter.minDate) : '';
    let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
    let isCurrentValueBeforeMinDate = (currentValue && minDate && currentValue.isBefore(minDate));

    setFilter({
        ...filter,
        startDate: !isCurrentValueBeforeMinDate ? filter.startDate : '',
        startDateValue: !isCurrentValueBeforeMinDate ? filter.startDateValue : '',
        minDate: !isCurrentValueBeforeMinDate ? filter.minDate :'',
        endDate: currentValue?currentValue.format(moment.HTML5_FMT.DATE):'',
        endDateValue: e.value,
        maxDate: moment(e.value).isValid() ? e.value : ''
    })
}

  const onKeyPrressEnter = (e) => {
    if (e.key === 'Enter') {
      setLoadTable(true)
    }
  }

  const clearFilter = () => {
    setPageable({...pageable,
        page: 0
    })
    setFilter({
        nameUser: '',
        objectName: '',
        objectTypes: [],
        startDate: moment(new Date()).format(moment.HTML5_FMT.DATE),
        startDateValue: new Date(),
        minDate: new Date(),
        endDate: '',
        endDateValue: null,
        maxDate: null
    })
    setLoadTable(true)
}

  return (
    <div className="p-grid">
      <BreadcrumbsItem to={match.url}>Wait List</BreadcrumbsItem>

      <div className="p-col-12">
        <div className="card card-w-title">
            <h1 className="p-w-bold p-m-0">Wait List</h1>
              <Fieldset legend="Filters">
              <div className="p-grid p-fluid">
                <div className="p-col-12 p-md-6">
                  <div className="p-grid">
                    <div className="p-col-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">User Name/Email:</span>
                        <InputText value={filter.nameUser??''} onKeyUp={onKeyPrressEnter} onChange={e => setFilter({...filter, nameUser: e.target.value})} />
                      </div>
                    </div>
                    <div className="p-col-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Type:</span>
                        <Dropdown value={filter.objectTypes&&filter.objectTypes.length>0?filter.objectTypes[0]:null} options={Object.values(OBJECT_WISH_LIST_TYPE)} showClear onChange={e => setFilter({...filter, objectTypes: e.value?[e.value]:[]})} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-col-12 p-md-6">
                  <div className="p-grid">
                    <div className="p-col-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Activity/Facility Name:</span>
                        <InputText value={filter.objectName??''} onKeyUp={onKeyPrressEnter} onChange={e => setFilter({...filter, objectName: e.target.value})} />
                      </div>
                    </div>
                    <div className="p-col-12">
                      <div className="p-grid">
                        <div className="p-col-12 p-md-6">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">From:</span>
                            <MaskedCalendar value={filter.startDateValue} minDate={filter.maxDate} onChange={(e) => onDateFromChange(e)} showIcon={true} dateFormat="dd/mm/yy" />
                          </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">To:</span>
                            <MaskedCalendar value={filter.endDateValue} minDate={filter.minDate} onChange={(e) => onDateToChange(e)} showIcon={true} dateFormat="dd/mm/yy" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid">
                  <div className="p-col-12 p-c">
                      <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => setLoadTable(true)} />
                      <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={() => clearFilter()} />
                  </div>
              </div>
          </Fieldset>
        </div>

        <DataTable emptyMessage="There's no record found" header={tblHeader} 
            value={dataList} lazy={true} loading={isLoadTable}
            paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
            sortField={pageable.sortField} sortOrder={pageable.sortOrder}
            onPage={onPage} onSort={onSort}
            responsive={true}
        >
            <Column field="id" header="ID" headerStyle={{width: '12em'}} sortable />
            <Column field="user.firstName" header="User" sortable
              body={rowData => {
                return <div>
                  <div>{rowData.user?.name}</div>
                  <div className="p-margin-top-5">[{rowData.user.email}]</div>
                  {rowData.user?.phone && <div className="p-margin-top-5">[{rowData.user.phone}]</div>}
                </div>
              }} 
            />
            <Column field="objectName" header="Name" sortable body={rowData => rowData.objectName??'NA'} />
            <Column field="startDate" header="Description" sortable
              body={rowData => {
                if(rowData.objectType===OBJECT_TYPE.product_session){
                  return <div>
                    <div>Date: {rowData.startDate===rowData.endDate?moment(rowData.startDate).format(DATE_FORMAT_DISPLAY):(moment(rowData.startDate).format(DATE_FORMAT_DISPLAY)+'-'+moment(rowData.endDate).format(DATE_FORMAT_DISPLAY))}</div>
                    <div className="p-margin-top-5">Time: {moment(rowData.startTime, 'HH:mm').format(TIME_FORMAT_DISPLAY)} - {moment(rowData.endTime, 'HH:mm').format(TIME_FORMAT_DISPLAY)}</div>
                  </div>  
                }
              }} 
            />
            <Column field="createdAt" header="Created" sortable body={rowData => rowData.createdAt && moment(rowData.createdAt).format(DATETIME_FORMAT_DISPLAY)} />
            <Column body={rowData => <Button label="Resend email" onClick={() => handleSendEmail(rowData.id)} />} />
        </DataTable>
      </div>
    </div>
  )
}