import axios from "axios";
import { MOCK_BASE_URL, API_BASE_URL, SCP_PATH } from "../../constants";

// const BASE_URL = MOCK_BASE_URL + SCP_PATH + "/membership/type";
const BASE_URL = API_BASE_URL + SCP_PATH + "/membership-mgt/type";

export function getPageMembershipTypes(parentId, page, size, sortField, sortOrder) {
  const order = sortOrder === 0 ? "asc" : sortOrder === -1 ? "desc" : "";
  const sort = sortField ? `${sortField},${order}` : "";
  return (
    axios
      .post(`${BASE_URL}/page`, 
        {
          parentId: parentId,
          page: page,
          size: size,
          sort: sort,
        },
      )
      .then((res) => res.data)
      .catch((error) => console.log(error))
  );
}

export function saveMembershipType(data) {
  return (data.id ? axios.put(`${BASE_URL}/update`, data) : axios.post(`${BASE_URL}/create`, data))
    .then((res) => res.data)
    .catch((error) => console.log(error));
}

export function removeMembershipType(id) {
  return axios
    .delete(`${BASE_URL}/${id}`)
    .then((res) => res.data)
    .catch((error) => console.log(error));
}
