import React, {forwardRef, useImperativeHandle, useState} from 'react';
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { Sidebar } from 'primereact/sidebar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { getProductAvailabilities } from '../../pim/product/ProductServices';
import { getParticipantTransfer } from './ParticipantServices';
import moment from 'moment';
import { Fieldset } from 'primereact/fieldset';
import { transferParticipantSchedule } from '../../pim/order/OrderService';

export const ParticipantTransferForm = forwardRef((props, ref) => {

    const [participant, setParticipant] = useState({})
    const [formHeader] = useState('Transfer Participant')
    const [visible, setVisible] = useState(false)
    const [errors, setErrors] = useState({})
    const [availabilityId, setAvailabilityId] = useState(null)
    const [confirmVisible, setConfirmVisible] = useState(false)
    const [mapAvailability, setAvailability] = useState({})
    const [availabilityDate, setAvailabilityDate] = useState(null)
    const [participants, setParticipants] = useState([])
    const [orderItem, setOrderItem] = useState({})

    useImperativeHandle(ref, () => ({
        openForm(participantId){
            getParticipantTransfer(participantId)
            .then(res=>{
                if(!res.errorCode){
                    let tmpParticipant = res.participant?res.participant:{}

                    setParticipant(tmpParticipant)
                    setParticipants(res.participants.filter(p => p.id!==tmpParticipant.id))
                    setOrderItem(res.orderItem?res.orderItem:{})
                    setAvailabilityDate(null)
                    setAvailabilityId(null)
                    setVisible(true)
                    setErrors({})
                    if(tmpParticipant.refId)
                        loadProductAvailabilities(tmpParticipant.refId)
                    showSuccessNotify('success', 'Success Message', 'Action submitted');
                }else{
                    showErrorNotify('error', 'Error Message', 'Cannot perform action');
                }
            })
        }
    }))

    const handleSave = () => {
        setConfirmVisible(false)
        setErrors({})
        transferParticipantSchedule(participant.id, availabilityId)
        .then(res => {
            if(!res.errorCode){
                props.refreshTable();
                onHide();
                showSuccessNotify('Action submitted');
            }else{
                showErrorNotify('Cannot perform action');
                if(res.errorCode===400) setErrors(res.errorObj);
            }
        })
    }

    const loadProductAvailabilities = (productId) => {
        getProductAvailabilities(productId, {availableByCurrent: true}, false)
        .then(res =>{
            let tmpMap = {}
            res&&res.forEach(item =>  {
                let tmpStartDate = moment(item.startDate).format('MM/DD/YYYY')
                let tmpEndDate = moment(item.endDate).format('MM/DD/YYYY')
                let tmpStartTime = moment(item.startTime, moment.HTML5_FMT.TIME).format('hh:mm A')
                let tmpEndTime = moment(item.endTime, moment.HTML5_FMT.TIME).format('hh:mm A')

                let tmpDate = tmpStartDate===tmpEndDate?tmpStartDate:(tmpStartDate+' - '+tmpEndDate)
                let tmpItem = {label: (tmpStartTime+' - '+tmpEndTime), value: item.id}

                if(!tmpMap[tmpDate]){
                    tmpMap[tmpDate] = []
                }
                tmpMap[tmpDate].push(tmpItem)
            })
            setAvailability(tmpMap)
        })
    }

    const onDateOptionChange = (e) => {
        setAvailabilityDate(e.value)
        setAvailabilityId(mapAvailability[e.value][0].value)
    }

    const renderDateOption = (dateData) => {
        return dateData?Object.keys(dateData).map(e => ({value: e, label: e})):[]
    }

    const onHide = () => {
        setVisible(false)
    }

    return(
        <React.Fragment>
            <Dialog visible={confirmVisible} contentStyle={styles.dialogContent} responsive={true}
                    modal={true} dismissableMask={true} showHeader={false} onHide={() => setConfirmVisible(false)}
                    footer={<React.Fragment>
                        <Button label="Submit" icon="pi-md-check" onClick={() => handleSave()} />
                        <Button label="Cancel" className="p-button-secondary" onClick={() => setConfirmVisible(false)} />
                    </React.Fragment>}
                >
                    Are you sure you want to transfer participant!
                </Dialog>
            <Sidebar visible={visible} dismissable={false} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={onHide}>
                <h2>{formHeader}</h2>

                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <Fieldset legend="Current Information">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    Name: <span className="p-w-bold">{participant.name}</span>
                                </div>
                                <div className="p-col-12">
                                    {(()=>{
                                        if(participant.schedule){
                                            let tmpStartDate = moment(participant.schedule.start).format('MM/DD/YYYY')
                                            let tmpEndDate = moment(participant.schedule.end).format('MM/DD/YYYY')
                                            let tmpStartTime = moment(participant.schedule.start, moment.HTML5_FMT.TIME).format('hh:mm A')
                                            let tmpEndTime = moment(participant.schedule.end, moment.HTML5_FMT.TIME).format('hh:mm A')
                                            return <React.Fragment>
                                                Schedule: <span className="p-w-bold">{tmpStartDate===tmpEndDate?tmpStartDate:(tmpStartDate+' - '+tmpEndDate)} {tmpStartTime+' - '+tmpEndTime}</span>
                                            </React.Fragment>
                                        }
                                    })()}
                                </div>
                                <div className="p-col-12">
                                    Product: <span className="p-w-bold">{orderItem.productName}</span>
                                </div>
                                {participants.length > 0 &&
                                    <React.Fragment>
                                        <div className="p-col-12">
                                            <div className="p-margin-bottom-10">Members with the same order item:</div>
                                            {participants.forEach(part => {
                                                if(part.id !== participant.id)
                                                    return <div className="p-margin-bottom-5">_ {part.name}</div>
                                            })}
                                        </div>
                                        <div className="p-col-12">
                                            <span className="p-form-error">(*) Action taken against members with the same item ordered</span>
                                        </div>        
                                    </React.Fragment>
                                }
                            </div>
                        </Fieldset>
                    </div>
                    <div className="p-col-12">
                        <Fieldset legend="Transfer To">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">* Date</label>
                                    <Dropdown value={availabilityDate} options={renderDateOption(mapAvailability)} onChange={(e) => onDateOptionChange(e)} />        
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">* Time</label>
                                    <Dropdown value={availabilityId} options={availabilityDate?mapAvailability[availabilityDate]:[]} onChange={(e) => setAvailabilityId(e.value)} />        
                                </div>
                            </div>
                            <div className="p-form-error">{errors.availabilityId}</div>    
                        </Fieldset>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Submit" icon="pi pi-check" onClick={() => setConfirmVisible(true)}/>
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={onHide}/>
                    </div>
                </div>
            </Sidebar>
        </React.Fragment>
    )
})

const styles = {
    dialogContent: {
        maxHeight: '50px',
        minHeight: '50px',
        overflow: 'hidden',
    }
}