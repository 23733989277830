import React, { useRef, useState } from "react";
import { Steps } from "primereact/steps";
import "./styles.scss";
import MergeDuplicateStep1 from "./MergeDuplicateStep1";
import MergeDuplicateStep3 from "./MergeDuplicateStep3";
import MergeDuplicateStep2 from "./MergeDuplicateStep2";
import _ from "lodash";
// const steps = [
//   {label: 'Choose duplicate'},
//   {label: 'Select information'},
//   {label: 'Done'},
// ];
const steps = [{ label: "" }, { label: "" }, { label: "" }];
const MergeDuplicateProcess = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDuplicate, setSelectedDuplicate] = useState([]);
  const [mainUser, setMainUser] = useState(null);

  const goToNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const backToPreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSelectedDuplicate = (e) => {
    if (
      e.value.length === 1 &&
      !_.find(selectedDuplicate, { id: e.value[0].id })
    ) {
      console.log("id", e.value[0].id);
      setSelectedDuplicate([...selectedDuplicate, ...e.value]);
    } else {
      setSelectedDuplicate(e.value);
    }
  };

  return (
    <div>
      <Steps model={steps} activeIndex={activeStep} className="p-mb-5" />
      {activeStep === 0 && (
        <MergeDuplicateStep1
          goToNextStep={goToNextStep}
          setMainUser={setMainUser}
          handleSelectedDuplicate={handleSelectedDuplicate}
          selectedDuplicate={selectedDuplicate}
          mainUser = { mainUser}
        />
      )}
      {activeStep === 1 && (
        <MergeDuplicateStep2
          goToNextStep={goToNextStep}
          backToPreviousStep={backToPreviousStep}
          selectedDuplicate={selectedDuplicate}
          mainUser={mainUser}
        />
      )}
      {activeStep === 2 && <MergeDuplicateStep3
        mainUser={mainUser}
      />}
    </div>
  );
};

export default MergeDuplicateProcess;
