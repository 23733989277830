import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { TodoServices } from './TodoServices';
import { CSSTransition } from 'react-transition-group';
import './Todo.css';
import TaskList from '../task/TaskList';
import { Menu } from 'primereact/menu';
import TodoForm from './TodoForm';


export default class TodoList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTodoDetails: false,
            showTodoList: true,
            isTodoTask: true,
            arrTodo: [],
            tasks: [],
            todoId: '',
            todoData: {},
            formTodoDialog: {
                header: 'New Todo',
                visible: false
            },
        }

        this.todoServices = new TodoServices();
    }

    componentDidMount() {
        this.getTodoList();
    }


    getTodoList = () => {
        let id = localStorage.getItem('user_id');
        this.todoServices.getTodoList(id)
            .then(res => {
                this.setState({
                    arrTodo: res
                });
            });
    }

    onShowDetails = (data, id) => {
        this.setState({
            tasks: data,
            todoId: id,
            showTodoDetails: true,
            showTodoList: false
        });
    }


    onShowList = () => {
        this.setState({
            showTodoDetails: false,
        });
    }

    showFormTodoDialog = () => {
        this.setState({
            formTodoDialog: {
                ...this.state.formTodoDialog,
                visible: true
            }
        })
    }

    hideFormTodoDialog = () => {
        this.setState({
            formTodoDialog: {
                ...this.state.formTodoDialog,
                visible: false
            }
        })
    }

    render() {

        let taskMenus = [
            {
                label: 'Add Todo', icon: 'pi-md-plus',
                command: () => {
                    this.setState({
                        formTodoDialog: {
                            ...this.state.formTodoDialog,
                            header: "New Todo"
                        },
                        todoData: {
                            id: '',
                            departmentId:'',
                            ownerId: localStorage.getItem('user_id'),
                        },
                    });
                    this.showFormTodoDialog();
                }
            }
        ];

        return (
            <div className="p-grid">
                <div className="p-col-12" >
                    {this.state.showTodoList && (
                        <React.Fragment>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-9">
                                </div>
                                <div className="p-col-12 p-r p-md-3">
                                    <Button icon="pi-md-menu" className="secondary-btn" tooltip="Add Todo" tooltipOptions={{ position: 'top' }} onClick={(event) => this.menu.toggle(event)} />
                                </div>
                            </div>
                            <Menu ref={el => this.menu = el} popup={true} model={taskMenus}></Menu>
                            <TodoForm popularTodo={() => this.getTodoList()} data={this.state.todoData} formTodoDialog={this.state.formTodoDialog} hideFormTodoDialog={this.hideFormTodoDialog} />
                            <ul className="p-padding-none" style={{ listStyle: 'none' }}>
                                {this.state.arrTodo.map((item, index) => {
                                    let className = 'p-margin-bottom-10 p-padding-left-10'
                                    return (
                                        <li key={index} className={className} style={{ borderBottom: '1px solid #d0c9c9', }}>
                                            <div className="p-grid">
                                                <div className="p-col-9 p-padding-top-10">
                                                    <span>Title: {item.name}</span>
                                                </div>
                                                <div className="p-col-3" style={{ textAlign: 'right' }}>
                                                    <Button className="p-button-primary" icon="pi-md-arrow-forward" onClick={(e) => this.onShowDetails(item.tasks, item.id)} />
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </React.Fragment>
                    )}
                    <CSSTransition
                        in={this.state.showTodoDetails}
                        timeout={300}
                        classNames="todo"
                        unmountOnExit
                        onExited={() => this.setState({ showTodoList: true })}
                    >
                        <TaskList isTodoTask={this.state.isTodoTask} todoId={this.state.todoId} myTasks={this.state.tasks} onShowList={() => this.onShowList()}></TaskList>
                    </CSSTransition>
                </div>
            </div>
        )
    }
}