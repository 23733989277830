import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Fieldset } from 'primereact/fieldset';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Button } from 'primereact/button';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { getTablePageReport, moneyFormat } from '../../core/service/CommonService';
import { getMembership, verifyMembershipDocument, updateMembershipStatus, changeMembershipCardStatus, updateCompanyForMembership } from './MembershipServices';
import classNames from 'classnames';
import { TabView, TabPanel } from 'primereact/tabview';
import { MEMBERSHIP_STATUS, PRODUCT_PRICE_UNIT, MEMBERSHIP_TYPE, MEMBERSHIP_CARD_STATUS, GROUP_USER_TYPE, IDENTITY_DOCUMENT_TYPE, ORDER_STATE, APP_FEP, PAYMENT_STATE, DATE_FORMAT_DISPLAY, DATETIME_FORMAT_DISPLAY, MEMBERSHIP_PARTICIPANT_TYPE, PRODUCT_PARTICIPANT_TYPE } from '../../constants';
import { getListIdentityDocuments } from '../contact/identity-document/components/IdentityDocumentServices';
import { MembershipMemberListView } from './MembershipMemberListView';
import { MembershipCardForm } from './MembershipCardForm';
import { changeGroupMemberOwner, createGroupByUser, deleteGroupMember, getGroup, getListGroupByUsers, getListGroups, getPageMembers } from '../group/GroupServices';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { MemberForm } from '../group/MemberForm';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GroupForm } from '../group/GroupForm';
import { IdentityDocumentForm } from '../contact/identity-document/components/IdentityDocumentForm';
import { getOrderDetails } from '../../pim/order/OrderService';
import { OrderOrInvoicePaymentActionForm } from '../../pim/invoice/OrderOrInvoicePaymentActionForm';
import { getListInvoices } from '../../pim/invoice/InvoiceServices';
import { ExtendMembershipForm } from './ExtendMembershipForm';
import { getProduct } from '../../pim/product/ProductServices';
import { MembershipForm } from './MembershipForm';

export const MembershipDetailViewOnly = (props) => {
    const [membership, setMembership] = useState(null);
    const [subscriptionInfo, setSubscriptionInfo] = useState(null)
    const [owner, setOwner] = useState(null)
    const [workPermitDocument, setWorkPermitDocument] = useState({})
    const [passportDocument, setPassportDocument] = useState({})
    const [finDocument, setFINDocument] = useState({})
    const [member, setMember] = useState(null)
    const [activeCard, setActiveCard] = useState(null)
    const [tmpCompanyId, setTmpCompanyId] = useState(null)
    const [currentCompany, setCurrenCompany] = useState({})
    const [companies, setCompanies] = useState([])
    const [groupMembers, setGroupMembers] = useState([])
    const [pageableGroupMember, setPageableGroupMember] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: 'id',
        sortOrder: -1
    })
    const [isLoadTableGroupMember, setLoadTableGroupMember] = useState(false)
    const [errors, setErrors] = useState({})
    const [order, setOrder] = useState({})
    const [invoice, setInvoice] = useState({})
    const [msProduct, setMsProduct] = useState({})


    const frmMembershipCard = useRef(null)
    const frmCompanyMember= useRef(null)
    const frmGroup= useRef(null)
    const frmIdentityDocument= useRef(null)
    const paymentActionForm= useRef(null)
    const frmExtendMembership= useRef(null)
    const frmMembership = useRef(null)

    const viewType = props.extra1

    useEffect(() =>{
        loadMembership();
        loadCompanies()
    },[])

    useEffect(() =>{
        loadMembership();
    },[props.match.params.id])

    useEffect(() =>{
        if(isLoadTableGroupMember)
            loadPageCompanyMembers()
    },[isLoadTableGroupMember])

    const loadMembership = async () => {
        showloading();

        const msRes = await getMembership(props.match.params.id)
        
        if(!msRes.errorCode){
            setMembership(msRes)
            setSubscriptionInfo(msRes.subscriptionInfo)
            setOwner(msRes.owner)
            if(msRes.type===MEMBERSHIP_TYPE.Individual.value){
                setMember(msRes.members[0])
                setActiveCard(msRes.members[0].activeCard)
            }
            if(msRes.owner){
                loadUserIdentityDocuments(msRes.owner.id)
            }
            if(msRes.orderId){
                loadOrder(msRes.orderId)
                loadListInvoices(msRes.orderId)
            }
            if(msRes.templateId){
                loadMembershipProduct(msRes.templateId)
            }
            if(msRes.groupId){
                loadCompany(msRes.groupId)
            }else{
                setTmpCompanyId(null)
                setCurrenCompany(null)
            }
        }else{
            showErrorNotify('No result');
        }
        stoploading()
    }

    const loadUserIdentityDocuments = async (userId) => {
        const documents = await getListIdentityDocuments({userId: userId, types: [IDENTITY_DOCUMENT_TYPE.work_permit.value, IDENTITY_DOCUMENT_TYPE.passport.value, IDENTITY_DOCUMENT_TYPE.fin.value], active: true});

        if(documents && documents.length>0){
            let workPermit = documents.find(d => d.docType===IDENTITY_DOCUMENT_TYPE.work_permit.value)
            let passport = documents.find(d => d.docType===IDENTITY_DOCUMENT_TYPE.passport.value)
            let fin = documents.find(d => d.docType===IDENTITY_DOCUMENT_TYPE.fin.value)

            setWorkPermitDocument(workPermit?workPermit:{})
            setPassportDocument(passport?passport:{})
            setFINDocument(fin?fin:{})
        }
    }

    const loadOrder = (orderId) => {
        getOrderDetails(orderId)
        .then(res => {
            if (!res.errorCode) {
                setOrder(res)
            }
        });
    }

    const loadListInvoices = (orderId) => {
        getListInvoices({app: APP_FEP, orderIds: [orderId]})
        .then(res => {
            if (!res.errorCode) {
                let tmpInvoices = res?res.filter(i => !i.orderItem&&i.status===PAYMENT_STATE.awaiting.value):[]

                setInvoice(tmpInvoices.length>0?tmpInvoices[0]:null)
            }
        });
    }

    const loadMembershipProduct = (productId) => {
        getProduct(productId).then(res => setMsProduct(res))
    }

    const loadCompanies = () => {
        getListGroups().then(res => setCompanies(res))
    }

    const loadCompany = (companyId) => {
        getGroup(companyId)
        .then(res=>{
            if(!res.errorCode){
                setTmpCompanyId(res.id)
                setCurrenCompany(res)
                setLoadTableGroupMember(true)
            }else{
                showErrorNotify(res.errorMessage)
            }
            
        })
    }

    const handleUpdateStatus = (status) => {
        updateMembershipStatus(membership.id, status)
        .then(res => {
            if(!res.errorCode){
                setMembership(res)
                showSuccessNotify("Membership has been update!")
            }else{
                showErrorNotify(res.errorMessage);
            }
        })
    }

    const handleVerifiedDocument = () => {
        verifyMembershipDocument(membership.id)
        .then(res => {
            if(!res.errorCode){
                setMembership(res)
                showSuccessNotify("Membership has been verified!")
            }else{
                showErrorNotify(res.errorMessage);
            }
        })
    }

    const changeMemberCardStatus = (id, status) => {
        showConfirmNotify({
            accept: () => handleChangeCardStatus(id, status)
        })
    }

    const handleChangeCardStatus = (id, status) => {
        showloading()

        changeMembershipCardStatus(id, status)
        .then(res => {
            if (!res.errorCode) {
                setActiveCard(res)
                showSuccessNotify('Action submitted!');
            } else {
                showErrorNotify('Cannot perform action');
            }

            stoploading()
        });
    }

    const onUpdateIdentityDocument = (type, identityDocumentData) => {
        frmIdentityDocument.current.openForm( type, identityDocumentData)
    }

    const onRefreshIdentityDocument = (identityDocumentData) => {
        if(identityDocumentData){
            if(identityDocumentData.docType===IDENTITY_DOCUMENT_TYPE.work_permit.value){
                setWorkPermitDocument(identityDocumentData)
            }else if(identityDocumentData.docType===IDENTITY_DOCUMENT_TYPE.passport.value){
                setPassportDocument(identityDocumentData)
            }else if(identityDocumentData.docType===IDENTITY_DOCUMENT_TYPE.fin.value){
                setFINDocument(identityDocumentData)
            }
        }
    }

    const onOrderAction = (action) => {
        paymentActionForm.current.openForm("order", action, order, invoice)
    }

    const onRefreshOrder = (orderData) => {
        setOrder(orderData)
        loadListInvoices(orderData.id)
        loadMembership()
    }

    const onAddOrEditMembershipCard = (memberId, activeCard) => {
        frmMembershipCard.current.openForm(membership.id, memberId, activeCard)
    }

    const onExtendMembership = () => {
        frmExtendMembership.current.openForm(membership)
    }

    //#region Company
    const handleCreatCompany = () => {
        createGroupByUser(membership.owner.id, GROUP_USER_TYPE.company.value)
        .then(res => {
            if (!res.errorCode) {
                setCurrenCompany(res)
                setLoadTableGroupMember(true)
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify(res.errorMessage);
            }
        });
    }

    const handleUpdateCompanyForMembership = () => {
        updateCompanyForMembership(membership.id, tmpCompanyId)
        .then(res => {
            if (!res.errorCode) {
                loadCompany(tmpCompanyId)
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify(res.errorMessage);
            }
        });
    }

    const loadPageCompanyMembers = () => {
        getPageMembers(currentCompany.id, pageableGroupMember.page, pageableGroupMember.rows, pageableGroupMember.sortField, pageableGroupMember.sortOrder)
            .then(res => {
                setGroupMembers(res.content)
                setPageableGroupMember({...pageableGroupMember,
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                })
                setLoadTableGroupMember(false)
            });
    }

    const onMemberPage = (e) => {
        setPageableGroupMember({...pageableGroupMember,
            page: e.page
        })
        setLoadTableGroupMember(true)
    }

    const handleRemoveGroupMember = (id) => {
        deleteGroupMember(currentCompany.id, id)
        .then(res => {
            if (!res.errorCode) {
                setLoadTableGroupMember(true)
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify(res.errorMessage);
            }
        });
    }

    const handelChangeOwnerGroup = (id) => {
        changeGroupMemberOwner(currentCompany.id, id)
        .then(res => {
            if (!res.errorCode) {
                setLoadTableGroupMember(true)
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify(res.errorMessage);
            }
        });
    }

    const addOrEditMember = (data) => {
        let tmpData = data ? data : {};
        tmpData.groupId = currentCompany.id;
        frmCompanyMember.current.popularFormData(tmpData);
    }

    const onRemoveGroupMember = (id) => {
        showConfirmNotify({
            accept:()=>handleRemoveGroupMember(id)
        })
    }

    const onChangePageLengthCompanyMember = (e) => {
        setPageableGroupMember({...pageableGroupMember,
            rows: e,
            page: 0
        })
        setLoadTableGroupMember(true);
    };

    const actionSetOwnerTemplate = (rowData) => {
        return <span>{rowData.owner ? 'YES' : 'NO'}</span>
    }

    const actionMemberTemplate = (rowData) => {
        return !rowData.owner && rowData.user.id!==membership.owner.id && 
                <Button label="Remove" icon="pi pi-trash" className="p-button-danger" onClick={() => onRemoveGroupMember(rowData.id)} />
    }

    const tblCompanyMemberHeader = (
        <TableHeader title="Members" 
            pageReport={getTablePageReport(pageableGroupMember.page, pageableGroupMember.rows, pageableGroupMember.total)}
            changePageLength={(e) => onChangePageLengthCompanyMember(e)}
            refresh={() => setLoadTableGroupMember(true)}
        />
    );
    //#endregion

    return (
        (membership??null) && (subscriptionInfo??null) && (owner??null) && // return undefined as error, so return empty or null
        <div className="p-grid">
            {membership.participantType===MEMBERSHIP_PARTICIPANT_TYPE.student.value?
                <React.Fragment>
                    <BreadcrumbsItem to={`/student-memberships`}>Student Memberships</BreadcrumbsItem>
                    <BreadcrumbsItem to={`/view-student-memberships/${props.match.params.id}`}>#{props.match.params.id}</BreadcrumbsItem>
                </React.Fragment>
                : <React.Fragment>
                    {viewType==='ext' 
                        ? <React.Fragment>
                            <BreadcrumbsItem to={`/memberships/ext-search`}>Memberships</BreadcrumbsItem>
                            <BreadcrumbsItem to={`/memberships/ext-search/${props.match.params.id}`}>#{props.match.params.id}</BreadcrumbsItem>
                        </React.Fragment>
                        : <React.Fragment>
                            <BreadcrumbsItem to={`/memberships/search`}>Memberships</BreadcrumbsItem>
                            <BreadcrumbsItem to={`/memberships/search/${props.match.params.id}`}>#{props.match.params.id}</BreadcrumbsItem>        
                        </React.Fragment>
                    }
                    
                </React.Fragment>
            }

            <MembershipCardForm ref={frmMembershipCard}
                refreshData={(e) => setActiveCard(e)}
            />

            <IdentityDocumentForm ref={frmIdentityDocument} 
                userId={membership.owner?membership.owner.id:null}
                refreshData={(identityDocumentData) => onRefreshIdentityDocument(identityDocumentData)}
            />
            
            <OrderOrInvoicePaymentActionForm ref={paymentActionForm} 
                order={data => onRefreshOrder(data)}
            />

            <ExtendMembershipForm ref={frmExtendMembership} 
                reloadData={(data)=> setMembership(data)}
            />

            <MembershipForm ref={frmMembership}
                participantTypes={membership.participantType===MEMBERSHIP_PARTICIPANT_TYPE.student.value?[PRODUCT_PARTICIPANT_TYPE.student.value]:[PRODUCT_PARTICIPANT_TYPE.adult.value, PRODUCT_PARTICIPANT_TYPE.youth.value]}
                refreshData={() => loadMembership()}
            />

            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1> 
                        {membership.participantType===MEMBERSHIP_PARTICIPANT_TYPE.student.value?'Student Membership Details':'Membership Details'}
                        <div className="p-toolbar-group-right">
                            <Link to={`/${membership.participantType===MEMBERSHIP_PARTICIPANT_TYPE.student.value
                                    ?'student-memberships'
                                    :'memberships'
                                    }/${viewType==='ext' ? 'ext-search' : 'search'}`}
                            >
                                <Button icon="pi pi-bars" className="p-button-text" tooltip="Return" tooltipOptions={{ position: 'top' }} ></Button>
                            </Link>
                        </div>
                    </h1>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-3">
                            <label>Owner: <span className="p-w-bold">{owner.username} - {owner.name}</span></label>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label>Type: <span className="p-w-bold">{membership.type}</span></label>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label>Status: <span className={classNames('status', 'ms-'+membership.status)}>{membership.status}</span></label>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <label>Verification: <span className={classNames('status', {'ms-Active': membership.verified, 'ms-Block': !membership.verified })}>{!membership.verified?'NOT YET':'VERIFIED'}</span></label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-col-12">
                <TabView>
                    <TabPanel header="Information">
                        <div className="p-grid">
                            <div className={`p-col-12 ${msProduct.requiredIdentificationDocument?'p-md-4':'p-md-6'}`}>
                                <Fieldset legend="Membership">
                                    <div className="info-text">
                                        <div className="title">{membership.participantType===MEMBERSHIP_PARTICIPANT_TYPE.student.value?'Category':'Membership Category'}:</div>
                                        <div className="content">{membership.name}</div>
                                    </div>
                                    <div className="info-text">
                                        <div className="title">{membership.participantType===MEMBERSHIP_PARTICIPANT_TYPE.student.value?'Type':'Membership Type'}:</div>
                                        <div className="content">{membership.type}</div>
                                    </div>
                                    <div className="info-text"><div className="title">Price:</div><div className="content">{moneyFormat(subscriptionInfo.price)}</div></div>
                                    <div className="info-text"><div className="title">Subscription:</div><div className="content">{subscriptionInfo.period} {PRODUCT_PRICE_UNIT[subscriptionInfo.periodType].label}{subscriptionInfo.period>1?'s':''}</div></div>                                
                                    {membership.status!==MEMBERSHIP_STATUS.Pending.value && 
                                    <React.Fragment>
                                        <div className="info-text"><div className="title">Times:</div><div className="content">{subscriptionInfo.times}</div></div>
                                        <div className="info-text"><div className="title">Join Date:</div><div className="content">{moment(membership.joinDate).format(DATETIME_FORMAT_DISPLAY) }</div></div>
                                        <div className="info-text"><div className="title">Expired Date:</div><div className="content">{moment(membership.end).format(DATETIME_FORMAT_DISPLAY) }</div></div>
                                    </React.Fragment>
                                    }
                                    {(msProduct.participantType===PRODUCT_PARTICIPANT_TYPE.student.value) &&
                                        <div className="info-text"><div className="title">Agency:</div><div className="content">{membership?.broker?.name}</div></div>
                                    }
                                    
                                    {/* {membership.status===MEMBERSHIP_STATUS.Pending.value && membership.orderId &&
                                    <React.Fragment>
                                        <div className="p-c p-size-16 p-w-bold p-mb-3">This membership need to completed payment first to make active</div>
                                        <div className="p-c">
                                            <Link to={`/orders/${membership.orderId}`} target="_blank">
                                                <Button icon="pi pi-angle-double-right" label="Go to completing payment" className="p-button-success"/>
                                            </Link>
                                        </div>
                                    </React.Fragment>
                                    } */}
                                </Fieldset>
                                {membership.orderId &&
                                    order.orderState!==ORDER_STATE.canceled.value && order.paymentState!==PAYMENT_STATE.completed.value &&
                                    <Fieldset legend="Payment">
                                        {membership.status===MEMBERSHIP_STATUS.Pending.value &&
                                            <div className="p-size-16 p-w-bold p-mb-3">This membership need to completed payment first to make active</div>
                                        }
                                        <div className="info-text"><div className="title">Order No.#:</div>
                                            <div className="content">
                                                <Link to={`/orders/${membership.orderId}`} target="_blank">
                                                    <Button icon="pi pi-angle-double-right" label={order.orderNumber} className="p-button-success btn-text-sm"/>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="info-text"><div className="title"><strong>Order total:</strong></div><div className="content"><strong>{moneyFormat(order.total)}</strong></div></div>
                                        {order.paymentState!==PAYMENT_STATE.pending.value &&
                                            <React.Fragment>
                                                <div className="info-text"><div className="title"><strong>Paid total:</strong></div><div className="content"><strong>{moneyFormat(order.paidTotal)}</strong></div></div>
                                            </React.Fragment>
                                        }

                                        <div className="p-hr p-p-0 p-mb-3"></div>

                                        <div class="p-messages p-component p-messages-warn p-messages-enter-done">
                                            <div class="p-messages-wrapper p-p-size-16">
                                                <span class="p-messages-icon pi pi-exclamation-triangle"></span>
                                                <span class="p-messages-summary">Order need to pay remain: {moneyFormat(order.total - order.paidTotal)}</span>
                                            </div>
                                        </div>
                                    </Fieldset>
                                }
                            </div>
                            {msProduct.requiredIdentificationDocument &&
                                <div className="p-col-12 p-md-4">
                                    <Fieldset legend="Documents">
                                        {msProduct.participantType!==PRODUCT_PARTICIPANT_TYPE.student.value
                                            ? <React.Fragment>
                                                <div className="info-text"><div className="title">Work permit number:</div><div className="content">{workPermitDocument.docNumber?workPermitDocument.docNumber:'N/A'}</div></div>
                                                <div className="info-text"><div className="title">Work permit expired:</div><div className="content">{workPermitDocument.expiredDate?moment(workPermitDocument.expiredDate).format(DATE_FORMAT_DISPLAY):'N/A'}</div></div>
                                            </React.Fragment>
                                            : <React.Fragment>
                                                <div className="info-text"><div className="title">FIN number:</div><div className="content">{finDocument.docNumber??'N/A'}</div></div>
                                            </React.Fragment>
                                        }
                                        
                                        <div className="p-hr p-p-0 p-mb-2"></div>
                                        <div className="info-text"><div className="title">Passport number:</div><div className="content">{passportDocument.docNumber?passportDocument.docNumber:'N/A'}</div></div>
                                        <div className="info-text"><div className="title">Passport expired:</div><div className="content">{passportDocument.expiredDate?moment(passportDocument.expiredDate).format('MM/YYYY'):'N/A'}</div></div>
                                        {msProduct.participantType!==PRODUCT_PARTICIPANT_TYPE.student.value &&
                                            <React.Fragment>
                                                <div className="p-hr p-p-0 p-mb-2"></div>
                                                <div className="info-text"><div className="title">FIN number:</div><div className="content">{finDocument.docNumber?finDocument.docNumber:'N/A'}</div></div>
                                            </React.Fragment>
                                        }
                                        {!membership.verified && 
                                        <React.Fragment>
                                            <div className="p-hr p-p-0 p-mb-3"></div>
                                            <div className="p-grid">
                                                <div className='p-col-12'>
                                                    <div class="p-messages p-component p-messages-warn p-messages-enter-done">
                                                        <div class="p-messages-wrapper p-p-size-16">
                                                            <span class="p-messages-icon pi pi-exclamation-triangle"></span>
                                                            <span class="p-messages-summary">Document need to be verified</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </React.Fragment>
                                        }
                                    </Fieldset>
                                </div>
                            }
                            <div className={`p-col-12 ${msProduct.requiredIdentificationDocument?'p-md-4':'p-md-6'}`}>
                                <Fieldset legend="AccountID">
                                    <div className="info-text"><div className="title">Name:</div><div className="content">{owner.name}</div></div>
                                    <div className="info-text"><div className="title">User name:</div><div className="content">{owner.username}</div></div>
                                    <div className="info-text"><div className="title">Email:</div><div className="content">{owner.email}</div></div>
                                    <div className="info-text"><div className="title">Phone:</div><div className="content">{owner.phone}</div></div>
                                    <div className="info-text"><div className="title">Birthday:</div><div className="content">{owner.birthday && moment(owner.birthday).format(DATE_FORMAT_DISPLAY)}</div></div>
                                    <div className="info-text"><div className="title">Gender:</div><div className="content">{owner.gender}</div></div>
                                    <div className="info-text"><div className="title">Nationality:</div><div className="content">{owner.nationalityName}</div></div>
                                    <div className="info-text"><div className="title">Address:</div><div className="content">{owner.primaryAddress && [owner.primaryAddress.fullAddress, owner.primaryAddress.city, owner.primaryAddress.stateName, owner.primaryAddress.zipCode, owner.primaryAddress.countryName].filter(item => item).join(', ')}</div></div>
                                </Fieldset>
                                {membership.type===MEMBERSHIP_TYPE.Individual.value &&  
                                <Fieldset legend="Member Card">
                                    {(()=> {
                                        if(activeCard){
                                            return (
                                                <React.Fragment>
                                                    <div className="p-mb-1">Code: {activeCard.code}</div>
                                                    <div className="p-mb-1">Number: {activeCard.number}</div>
                                                    <div className="p-mb-2">Status: <span className="status">{MEMBERSHIP_CARD_STATUS[activeCard.status].label}</span></div>
                                                </React.Fragment>
                                            )
                                        }else{
                                            return <div className="p-mb-1">Card: N/A</div>
                                        }
                                    })()}
                                </Fieldset>
                                }
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel header={membership.type===MEMBERSHIP_TYPE.Group.value?"Members":""} headerClassName={membership.type===MEMBERSHIP_TYPE.Individual.value?"p-hide":""} disabled={membership.type===MEMBERSHIP_TYPE.Individual.value}>
                        <MembershipMemberListView membershipId={membership.id} isViewOnly={true} />
                    </TabPanel>
                    <TabPanel header="Company">
                        <GroupForm ref={frmGroup} refreshData={(groupData) => setCurrenCompany(groupData)}/>
                        <MemberForm ref={frmCompanyMember} 
                            refreshTable={() => setLoadTableGroupMember(true)}
                        />
                        
                        <Fieldset legend="Company Information">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label className="p-label">Company: {currentCompany?currentCompany.name:'N/A'}</label>
                                </div>
                            </div>   
                        </Fieldset>
                        {currentCompany && currentCompany.id &&
                            <Fieldset legend="Members">
                                <div className="p-grid">
                                    <div className="p-col-12" >
                                        <DataTable lazy={true} paginator={true} value={groupMembers}
                                            first={pageableGroupMember.page * pageableGroupMember.rows}
                                            rows={pageableGroupMember.rows} totalRecords={pageableGroupMember.total}
                                            onPage={onMemberPage} header={tblCompanyMemberHeader}
                                            selectionMode="single" responsive={true}
                                        >
                                            <Column header="Name" field="user.name" />
                                            <Column header="Email" field="user.email" />
                                            <Column header="Owner" body={actionSetOwnerTemplate} headerStyle={{ width: '10em' }} />
                                        </DataTable>
                                    </div>
                                </div>
                            </Fieldset>
                        }
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )
}
