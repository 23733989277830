import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable} from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { MenuForm } from "./MenuForm";
import { MenuItemForm } from './MenuItemForm';
import { showSuccessNotify, showErrorNotify } from "../../core/service/NotificationService";
import { getPageMenus, removeMenu, removeMenuItem } from "./MenuServices";
import { useRouteMatch } from "react-router-dom";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { SplitButton } from "primereact/splitbutton";

export const Menus = () => {
    const match = useRouteMatch()

    const [dataList, setDataList] = useState([])
    const [menuExpandedRows, setMenuExpandedRows] = useState()
    const [menuItemExpandedKeys, setMenuItemExpandedKeys] = useState()
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: 'id',
        sortOrder: -1
    })
    
    const [isLoadTable, setLoadTable] = useState(true);

    const frmMenu = useRef(null)
    const frmMenuItem = useRef(null)

    useEffect(()=>{
        if(isLoadTable)
            loadPageMenus()
    },[isLoadTable])

    const loadPageMenus = () => {
        getPageMenus(pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const tblMenuHeader = (
        <TableHeader title="Menus" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={
                <Button className="p-button-success" label="Create Menu" icon="pi-md-plus" onClick={() => frmMenu.current.openForm(null)}/>}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    );

    const rowMenuExpansionTemplate = (data) => {
        let tblMenuItem = <TableHeader title={"List items of " + data.title} titleClassname="p-size-16"
            actionTemplate={<Button icon="pi pi-plus" label="Add Item" className="p-button-constrast" onClick={() => frmMenuItem.current.openForm(data.id)}/>}
            showLength={false} showRefresh={false}
            />

        return(
            <TreeTable value={data.nodes} header={tblMenuItem} 
                expandedKeys={menuItemExpandedKeys}
                onToggle={e => setMenuItemExpandedKeys(e.value)}
                >
                <Column field="title" header="Title" expander/>
                <Column field="weight" header="Weight" />
                <Column field="enabled" header="Active" body={rowData => rowData.data.enabled?'Yes':'No'} />
                <Column header="" bodyClassName="tc-actions" bodyStyle={{textAlign: 'right', minWidth: '5%' }}
                    body={(rowData) => 
                        <React.Fragment>
                            <Button icon="pi pi-pencil" className="p-button-info btn-sm" onClick={() => frmMenuItem.current.openForm(data.id, rowData.data)}/>
                            <Button icon="pi pi-times" className="p-button-danger btn-sm" onClick={() => deleteMenuItem(data.id, rowData.data.id)}/>
                        </React.Fragment>
                    }
                />
            </TreeTable>
        )
    }

    const deleteMenu = (id) => {
        removeMenu(id).then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }

    const deleteMenuItem = (menuId, id) => {
        removeMenuItem(menuId, id).then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Menus</BreadcrumbsItem>

            <MenuForm ref={frmMenu} refreshTable={() => setLoadTable(true)}/>
            <MenuItemForm ref={frmMenuItem} refreshTable={() => setLoadTable(true)}/>

            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1 className="p-w-bold p-m-0">Menus</h1>
                </div>

                <DataTable emptyMessage="There's no operating booking order found" header={tblMenuHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                    expandedRows={menuExpandedRows} dataKey="id"
                    onRowToggle={(e) => setMenuExpandedRows(e.data)}
                    rowExpansionTemplate={rowMenuExpansionTemplate}
                >
                    <Column expander style={{ width: '3.5em' }} />
                    <Column field="title" header="Title" />
                    <Column style={{textAlign: 'right', width: '13em'}} bodyClassName="tc-actions"
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Remove', icon: 'pi pi-times', command: () => deleteMenu(rowData.id)}
                            ]
                            return(
                                <SplitButton label="Edit" model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => frmMenu.current.openForm(rowData)}></SplitButton>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    )
}