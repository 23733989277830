import React, { forwardRef, useState, useEffect, useImperativeHandle, useRef } from "react";
import moment, { HTML5_FMT } from 'moment';
import classNames from 'classnames';
import {SelectButton} from 'primereact/selectbutton';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import {showErrorNotify, showSuccessNotify} from '../../core/service/NotificationService';
import { Sidebar } from "primereact/sidebar";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { Fieldset } from "primereact/fieldset";
import Select from 'react-dropdown-select';
import { PRODUCT_CLASSIFICATION, APP_FEP, PRODUCT_TYPE, TAXONOMY_TYPE, PRODUCT_IMAGE_TYPE, PRICE_APPLY_TYPE, PRODUCT_PRICE_UNIT, UPLOAD_TYPE, PRODUCT_SECONDARY_TYPE, PRODUCT_PARTICIPANT_TYPE, MAX_FILE_SIZE } from "../../constants";
import { titleCaseText, replaceSpaceAndUpperCase } from "../../core/service/CommonService";
import { saveProduct, getProduct } from "../product/ProductServices";
import { getListTaxons } from "../taxon/TaxonServices";
import { ProductPrices } from "../product/ProductPrice";
import CKEditor from 'ckeditor4-react';
import { getTaxes } from "../tax/TaxSerivce";
import { showloading, stoploading } from "../../core/service/LoadingService";
import { Chips } from "primereact/chips";
import { Checkbox } from "primereact/checkbox";

CKEditor.editorUrl = "/assets/ckeditor/ckeditor.js";

const PRODUCT_MENU_ITEMS = {
    basic: {label: 'Basic Information', value: 'basic'},
    content: {label: 'Content', value: 'content'},
    variant: {label: 'Variant', value: 'variant'},
    inventory: {label: 'Inventory', value: 'inventory'},
    availability: {label: 'Session', value: 'availability'},
    associated: {label: 'Associated Products', value: 'associated'},
    asset: {label: 'Related Facility', value: 'asset'}
}; 

const MEMBERSHIP_TYPES = [
    PRODUCT_SECONDARY_TYPE.ms_individual,
    PRODUCT_SECONDARY_TYPE.ms_group
]

export const MembershipProductForm = forwardRef((props, ref) => {
    const inputUpload = useRef(null)

    const [header, setHeader] = useState('Add Membership Product')
    const [visible, setVisible] = useState(false)
    const [menuActive, setMenuActive] = useState(PRODUCT_MENU_ITEMS.basic.value)
    const [productClass, setProductClass] = useState(null)
    const [productId, setProductId] = useState(null)
    const [frmData, setFrmData] = useState({application: APP_FEP, tags: [], rentalLimit: {}})
    const [taxes, setTaxes] = useState([])
    const [branches, setBranches] = useState([])
    const [categories, setCategories] = useState([])
    const [errors, setErrors] = useState({prices: {}})
    const [isLoadData, setLoadData] = useState(false)
    const [appConfig, setAppConfig] = useState(null)

    useImperativeHandle(ref, () => ({
        openForm(c, e){
            setHeader((e?'Edit':'Add') + ' Membership Product')
            setProductId(e)
            setProductClass(c)
            setMenuActive(PRODUCT_MENU_ITEMS.basic.value)
            setLoadData(true)
        }
    }))

    useEffect(() => {
        let appConfig = localStorage.getItem('appConfig')
        appConfig = appConfig && appConfig!=='undefined' && appConfig!=='null' ? JSON.parse(appConfig) : {}
        setAppConfig(appConfig)

        loadAllTaxes()
        loadAllBranchs()
        loadAllCategories()
    }, [])

    const loadAllTaxes = () => {
        getTaxes().then(res => setTaxes(res))
    }

    const loadAllBranchs = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.branch.value}).then(res => setBranches(res))
    }

    const loadAllCategories = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.membership.value]}).then(res => setCategories(res))
    }

    useEffect(() => {
        if(isLoadData){
            async function loadProduct(){
                if(productId){
                    const res = await getProduct(productId);

                    if(!res.errorCode){
                        setFrmData(popularFormData(res))

                        setErrors({prices: {}})
                        setVisible(true)
                    }else{
                        showErrorNotify(res.errorMessage)
                        setVisible(false)
                    }
                } else {
                    setFrmData(popularFormData(null))
                    setErrors({prices: {}})
                    setVisible(true)
                }
            }

            loadProduct()
        }
    }, [isLoadData])

    const closeForm = () => {
        setVisible(false)
        setLoadData(false)
        setProductId(null)
        setFrmData(popularFormData())
        setErrors({prices: {}})
    }

    const popularFormData = (e) => {        
        const data = {
            id: e?e.id:'',
            code: e?e.code:'',
            name: e?e.name:'',
            title: e?e.title:'',
            application: APP_FEP,
            classification: e?e.classification:productClass,
            type: PRODUCT_TYPE.membership.value,
            secondaryType: e?e.secondaryType:PRODUCT_SECONDARY_TYPE.ms_individual.value,
            participantType: e&&e.participantType?e.participantType:(props.participantType?props.participantType:PRODUCT_PARTICIPANT_TYPE.adult.value),
            description: e?e.description:'',
            content: e?e.content:null,
            teaserImage: e&&e.teaserImage&&Object.keys(e.teaserImage).length>0?e.teaserImage:null,
            teaserImageUrl: e&&e.teaserImage?(e.teaserImage.fileName?'':e.teaserImage.url):'',
            galleryImages: e&&e.galleryImages?e.galleryImages:[],
            videos: e&&e.videos?e.videos:[],
            tags: e&&e.tags?e.tags:[],
            applyTax: e? e.applyTax : false,
            taxId: e?e.taxId:appConfig.defaultTax,
            weight: e?e.weight:0,
            active: e?e.active:true,
            show: e?e.show:false,
            allowViewDetails:e?e.allowViewDetails:false,
            loginRequired: e?e.loginRequired:true,
            sellExtraAsCoupon: e?e.sellExtraAsCoupon:false,
            recommended: e?e.recommended:false,
            free: e?e.free:false,
            prices: e&&e.prices?e.prices:[{applyFor: PRICE_APPLY_TYPE.normal.value, price: '', unit: PRODUCT_PRICE_UNIT.month.value, quantity: 1}],
            subscription: e?e.subscription:true,
            branchId: e?e.branchId:branches.length===1?branches[0].value:null,
            branchName: e?e.branchName:null,
            departmentId: e?e.departmentId:null,
            categoryIds: e?e.categoryIds:[],
            locationId: e?e.locationId:null,
            variants: e?e.variants:[],
            slot: e?e.slot:1,
            useNotification: e&&e.useNotification,
            numberDaysNotifyBeforeExpirationDate: e&&e.numberDaysNotifyBeforeExpirationDate?e.numberDaysNotifyBeforeExpirationDate:[],
            requiredIdentificationDocument: e&&e.requiredIdentificationDocument
        }

        return data
    }

    const onChangeCategory = (values) => {
        let arrValues = [];
        if (values)
            arrValues = values.map(e => (e.value))

        setFrmData({...frmData, categoryIds: arrValues})
    }

    const handleSaveProduct = () => {
        showloading()

        let data = {...frmData}
        let images = []

        if(data.teaserImageUrl || frmData.teaserImageFile){
            if(frmData.uploadType===UPLOAD_TYPE.upload_file.value && frmData.teaserImageFile){
                let fileSize = frmData.teaserImageFile.size
                if(fileSize > MAX_FILE_SIZE){
                    setErrors({image: 'Size not allowed (maximum: 100MB)'})
                    showErrorNotify('Cannot perform action')
                    stoploading()
                    return
                }
            }
            images.push(frmData.teaserImage = {
                type: PRODUCT_IMAGE_TYPE.teaser,
                uploadType: frmData.uploadType,
                url: !frmData.uploadType||frmData.uploadType===UPLOAD_TYPE.external_url.value?frmData.teaserImageUrl:'',
                file: frmData.uploadType===UPLOAD_TYPE.upload_file.value?frmData.teaserImageFile:null
            })
        }
        data.images = images.concat(data.galleryImages)
        
        saveProduct(data)
        .then(res => {
            if(!res.errorCode){
                setFrmData(popularFormData(res))

                setProductId(res.id)
                setErrors({prices: {}})
                
                if(props.refreshTable)
                    props.refreshTable();

                if(inputUpload && inputUpload.current){
                    inputUpload.current.value=''
                }

                showSuccessNotify('Product saved!')
            }else{
                showErrorNotify(res.errorMessage);
                if(res.errorCode===400)
                    setErrors(res.errorObj);
            }
        })
        .catch(()=>showErrorNotify("System error!"))
        .finally(()=> stoploading())
    }

    const onInputNumberDaysToNotice = (value) => {
        setErrors({numberDaysNotifyBeforeExpirationDate: ''})
        let tmpArr = frmData.numberDaysNotifyBeforeExpirationDate?[...frmData.numberDaysNotifyBeforeExpirationDate]:[]
        if(value){
            let tmpValue = parseFloat(value)
            let isInt = !isNaN(value) && (tmpValue|0)===tmpValue
            if(!isInt){
                setErrors({...errors, numberDaysNotifyBeforeExpirationDate: 'Wrong format!'})
                return
            }
            if(!tmpArr.includes(value)){
                tmpArr.push(value)
            }
        }
        setFrmData({...frmData, numberDaysNotifyBeforeExpirationDate: tmpArr})
    }

    const onRemoveNumberDaysToNotice = (e) => {
        let tmpArr = frmData.numberDaysNotifyBeforeExpirationDate?[...frmData.numberDaysNotifyBeforeExpirationDate]:[]
        let tmpValue = e.value[0]
        tmpArr.splice(tmpArr.indexOf(tmpValue), 1)
        setFrmData({...frmData, numberDaysNotifyBeforeExpirationDate: tmpArr})
    }

    const renderProductMenuItems = () => {
        const c = frmData.classification;
        const t = frmData.type;

        let items = [PRODUCT_MENU_ITEMS.basic, PRODUCT_MENU_ITEMS.content];

        if((c===PRODUCT_CLASSIFICATION.simple.value && frmData.variants.length>0)){
            items.push(PRODUCT_MENU_ITEMS.inventory)
        }

        if(c===PRODUCT_CLASSIFICATION.variable.value)
            items.push(PRODUCT_MENU_ITEMS.variant)

        if(c!==PRODUCT_CLASSIFICATION.combo.value && c!==PRODUCT_CLASSIFICATION.configuration.value && t===PRODUCT_TYPE.program.value)
            items.push(PRODUCT_MENU_ITEMS.availability)
            
        // if((c===PRODUCT_CLASSIFICATION.combo.value || c===PRODUCT_CLASSIFICATION.configuration.value || ((c===PRODUCT_CLASSIFICATION.simple.value || c===PRODUCT_CLASSIFICATION.variable.value) && t===PRODUCT_TYPE.program.value)))
        //     items.push(PRODUCT_MENU_ITEMS.associated)

        if((c===PRODUCT_CLASSIFICATION.simple.value || c===PRODUCT_CLASSIFICATION.variable.value) && t===PRODUCT_TYPE.rental.value)
            items.push(PRODUCT_MENU_ITEMS.asset)

        return items
    }

    return (
        <Sidebar visible={visible} position="right" className="p-sidebar-lg"  style={{ overflowY: "auto"}} blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>
            
            <div className="p-sidebar-line p-mb-3"></div>
            
            <div className="p-grid">
                <div className="p-col-12">
                    {/* {frmData.id && 
                    <React.Fragment>
                        {menuActive!==PRODUCT_MENU_ITEMS.basic.value &&
                        <React.Fragment>
                            <div>Product: <strong>{frmData.code} - {frmData.name}</strong></div>
                            {branches.length>1 &&
                            <div className="p-mt-2 p-mb-3">Branch: <strong>{frmData.branchName}</strong></div>
                            }
                        </React.Fragment>
                        }
                        <SelectButton value={menuActive} className="p-margin-10-0" options={renderProductMenuItems()} onChange={(e) => e&&e.value&&setMenuActive(e.value)}></SelectButton>
                        <div className="p-hr p-margin-bottom-20"></div>
                    </React.Fragment>
                    } */}

                    <div className={classNames({'p-hide': menuActive!==PRODUCT_MENU_ITEMS.basic.value})}>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Basic Information">
                                    <div className="p-grid p-fluid form-group">
                                        {!frmData.id?
                                        <div className="p-col-12">
                                            <label className="p-label">* Membership Type</label>
                                            <Dropdown value={frmData.secondaryType} options={MEMBERSHIP_TYPES} onChange={(e) => setFrmData({...frmData, secondaryType: e.value})} placeholder="Select a membership type"/>
                                            <span className="p-form-error">{errors.secondaryType}</span>
                                        </div>
                                        :
                                        <div className="p-col-12">
                                            <div className="p-mb-2">Membership Type: <strong>{PRODUCT_SECONDARY_TYPE[frmData.secondaryType].label}</strong></div>
                                            {branches.length>1 && 
                                            <div>Branch: <strong>{frmData.branchName}</strong></div>
                                            }
                                            <hr/>
                                        </div>
                                        }
                                    </div>
                                    <div className="p-grid p-fluid form-group">
                                        {!frmData.id && branches.length>1 && 
                                        <div className="p-col-12">
                                            <label className="p-label">* Branch</label>
                                            <Dropdown value={frmData.branchId} options={branches} onChange={(e) => setFrmData({...frmData, branchId: e.value})} placeholder="Select branch"/>
                                            <span className="p-form-error">{errors.branch}</span>
                                        </div>
                                        }
                                        <div className="p-col-12 p-md-4">
                                            <label className="p-label">* Code</label>
                                            <InputText value={frmData.code} keyfilter={/[^\s]/} onChange={(e) => setFrmData({...frmData, code: replaceSpaceAndUpperCase(e.target.value)})} />
                                            <span className="p-form-error">{errors.code}</span>
                                        </div>
                                        <div className="p-col-12 p-md-8">
                                            <label className="p-label">* Name</label>
                                            <InputText value={frmData.name} onChange={(e) => setFrmData({...frmData, name: titleCaseText(e.target.value)})} />
                                            <span className="p-form-error">{errors.name}</span>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Teaser image url</label>
                                            <div className="p-inputgroup">
                                                {((!frmData.uploadType && (!frmData.teaserImage || !frmData.teaserImage.fileName)) || frmData.uploadType===UPLOAD_TYPE.external_url.value) ?
                                                    <React.Fragment>
                                                        <InputText value={frmData.teaserImageUrl} onChange={(e) => setFrmData({...frmData, teaserImageUrl: e.target.value, uploadType: UPLOAD_TYPE.external_url.value})} />
                                                        <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.upload_file.value})} />
                                                    </React.Fragment>
                                                    : <React.Fragment>
                                                        {frmData.teaserImage && frmData.teaserImage.fileName &&
                                                            <span className="p-inputgroup-addon"><img src={frmData.teaserImage.url} alt={frmData.teaserImage.fileName} style={{width: 25, height: 25}} /></span>
                                                        }
                                                        <input ref={inputUpload} className="p-inputtext p-component" type="file" accept="image/png, image/jpg, image/jpeg" onChange={(e) => setFrmData({...frmData, teaserImageFile: e.target.files[0], uploadType: UPLOAD_TYPE.upload_file.value})} />
                                                        <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.external_url.value, teaserImageFile: null})} />
                                                    </React.Fragment> 
                                                }
                                            </div>
                                            <div className="p-form-error">{errors.image}</div>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Description</label>
                                            <InputTextarea value={frmData.description} rows="5" onChange={(e) => setFrmData({...frmData, description: e.target.value})} />
                                            <span className="p-form-error">{errors.description}</span>
                                        </div>
                                    </div>
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12 p-md-4">
                                            <label className="p-label">Weight (for order)</label>
                                            <InputText value={frmData.weight} keyfilter="int" onChange={(e) => setFrmData({...frmData, weight: e.target.value })} />
                                        </div>
                                        <div className="p-col-12 p-md-8">
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-12" style={{paddingTop: '27px'}}>
                                                    <InputSwitch checked={frmData.show} onChange={(e) => setFrmData({...frmData, show: e.value })} />
                                                    <label className="p-margin-left-10">Show in store as product?</label>
                                                </div>
                                                <div className="p-col-12">
                                                    <InputSwitch checked={frmData.requiredIdentificationDocument} onChange={(e) => setFrmData({...frmData, requiredIdentificationDocument: e.value })} />
                                                    <label className="p-margin-left-10">Required identification documents?</label>
                                                </div>
                                                {/* <div className="p-col-12">
                                                    <InputSwitch checked={frmData.allowViewDetails} onChange={(e) => setFrmData({...frmData, allowViewDetails: e.value })} />
                                                    <label className="p-margin-left-10">Allow view details?</label>
                                                </div> */}
                                                {/* <div className="p-col-12">
                                                    <InputSwitch checked={frmData.recommended} onChange={(e) => setFrmData({...frmData, recommended: e.value })} />
                                                    <label className="p-margin-left-10">Is recommended?</label>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </Fieldset>
                                <Fieldset legend="Hierarchy">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <label className="p-label">{frmData.show&&'* '}Category</label>
                                            <Select multi
                                                values={categories && frmData.categoryIds && frmData.categoryIds.length>0 ? categories.filter(x1 => frmData.categoryIds.some(x2 => x2 === x1.value)) : []}
                                                options={categories} onChange={(values) => onChangeCategory(values)}
                                                style={{ width: '100%' }} placeholder="Not use"
                                                noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No Data</span>) }}
                                            />
                                            <span className="p-form-error">{errors.category}</span>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Price Setting">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <InputSwitch checked={frmData.free} onChange={(e) => setFrmData({...frmData, free: e.value})} />
                                            <label className="p-margin-left-10">Free?</label>
                                        </div>
                                    </div>
                                    {!frmData.free && 
                                    <ProductPrices value={frmData.prices} type={frmData.type} errors={errors.prices?errors.prices:{}} onChange={(e) => setFrmData({...frmData, prices: e})}/>
                                    }
                                </Fieldset>

                                {!frmData.free &&
                                <Fieldset legend="Tax Setting">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <InputSwitch checked={frmData.applyTax} onChange={(e) => setFrmData({...frmData, applyTax: e.value})} />
                                            <label className="p-margin-left-10">Apply tax?</label>
                                        </div>
                                        {frmData.applyTax && 
                                        <div className="p-col-12">
                                            <label className="p-label">* Tax apply</label>
                                            <Dropdown value={frmData.taxId} options={taxes} onChange={(e) => setFrmData({...frmData, taxId: e.value})}/>
                                            <div className="p-form-error">{errors.taxId}</div>
                                        </div>
                                        }
                                    </div>
                                </Fieldset>
                                }

                                {frmData.secondaryType===PRODUCT_SECONDARY_TYPE.ms_group.value && 
                                <Fieldset legend="Members slot">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <label className="p-label">Number of member Slot</label>
                                            <InputText value={frmData.slot} keyfilter="num" onChange={(e) => setFrmData({...frmData, slot: e.target.value})}></InputText>
                                            <div className="p-form-error">{errors.slot}</div>
                                        </div>
                                    </div>
                                </Fieldset>
                                }

                                <Fieldset legend="Notification">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12">
                                            <InputSwitch inputId="chkUseNotification" checked={frmData.useNotification} onChange={(e) => setFrmData({...frmData, useNotification: e.value})} />
                                            <label htmlFor="chkUseNotification" className="p-margin-left-10">Use Notification?</label>
                                        </div>
                                        {frmData.useNotification &&
                                            <div className="p-col-12">
                                                <label className="p-label">Number of days notify before the expiration date</label>
                                                <Chips value={frmData.numberDaysNotifyBeforeExpirationDate?frmData.numberDaysNotifyBeforeExpirationDate:[]} allowDuplicate={false} 
                                                    onAdd={e => onInputNumberDaysToNotice(e.value)} onRemove={e => onRemoveNumberDaysToNotice(e)}
                                                    itemTemplate={e => <span>{e} day(s)</span>}
                                                />
                                                <div className="p-form-error">{errors.numberDaysNotifyBeforeExpirationDate}</div>
                                            </div>
                                        }
                                    </div>
                                </Fieldset>
                            </div>
                        </div>

                        <div className="p-sidebar-line p-my-3"></div>

                        <div className="p-grid">
                            <div className="p-col-12 p-r">
                                <Button label="Save Information" icon="pi pi-save" iconPos="left" style={{ 'float': 'right' }} onClick={() => handleSaveProduct()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Sidebar>
    )
})
