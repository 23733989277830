import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App.js';
import AuthRoute from './core/security/AuthRoute.jsx';
import ThroughProvider from 'react-through/lib/ThroughProvider';
import store from './store';

export const Main = () => {
    return (
        <ThroughProvider>
            <Provider store={store}>
                <Switch>
                    {/* <Route path="/login" component={Login}/> */}
                    {/* <Route path="/reset-password" component={ResetPassword}/> */}
                    {/* <Route path="/confirm_change_pass" component={ResetPasswordLink}/> */}
                    {/* <Route path="/recover-username" component={RecoverUsername}/> */}

                    <AuthRoute path="/" component={App} />
                </Switch>
            </Provider>
        </ThroughProvider>
    );
}
