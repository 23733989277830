import React, { useEffect, useRef, useState }  from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { getPageInventories, removeInventory } from './InventoryService';
import InventoryForm from './InventoryForm';
import { APP_FEP, TABLE_PAGING_LENGTH } from '../../constants';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import classNames from 'classnames';

const InventoryListView = (props) => {
    const deleteNotify = useRef(null);
    const frmInventory = useRef(null);

    const [data, setData] = useState({});
    const [filter, setFilter] = useState({application: APP_FEP});
    const [pageable, setPageable] = useState({
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: -1
    })
    const [isLoadTable, setLoadTable] = useState(true);

    useEffect(() => {
        if(isLoadTable)
            loadPageInventories();
    }, [isLoadTable])

    const loadPageInventories = () => {
        getPageInventories(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            if(res){
                setData(res.content)
                setPageable({
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                })
                setLoadTable(false)
            }
        })
    }

    const onPage = (e) =>{
        setPageable({
            ...pageable,
            loading:true,
            page: e.page
        })
        setLoadTable(true)
    }

    const onSort = (e) =>{
        setPageable({
            ...pageable,
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true)
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable, 
            rows: e,
            page: 0
        })
        setLoadTable(true)
    };

    const actionTemplate = (rowData) => {
        let dropActions = [
            {label: 'Edit', icon: 'pi-md-pencil', command: (e) => frmInventory.current.openForm(rowData)},
            {label: 'Delete', icon: 'pi-md-trash', command: (e) => deleteInventory(rowData.id)}
        ];

        return (
            <SplitButton label="View" icon="pi pi-search" className="p-button-constrast p-l" model={dropActions} onClick={() => props.history.push(`/inventories/${rowData.id}`)}/>
        );
    };

    const onAddOrEdit = (data) => {
        frmInventory.current.openForm(data);
    }

    const deleteInventory = (id) => {
        showConfirmNotify({
            accept: () => handleDeleteInventory(id)
        })
    }

    const handleDeleteInventory = (id) => {
        removeInventory(id)
        .then(data => {
            if(!data.errorCode) {
                showSuccessNotify("Action Submitted");
                setLoadTable(true);
            } else {
                showErrorNotify(data.errorMessage, "Error");
            }
        })
    }

    const tblHeader = (
        <TableHeader title="Inventories" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={<Button className="p-button-success" label="Create Inventory" icon="pi-md-plus" onClick={() => onAddOrEdit()}/>}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    )

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={window.location.href}>
                Inventory
            </BreadcrumbsItem>

            <InventoryForm ref={frmInventory}
                reloadTable={() => setLoadTable(true)}
            />
            
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1 className="p-w-bold p-m-0">Inventories</h1>
                </div>

                <DataTable lazy={true} paginator={true} value={data} loading={pageable.loading}
                    first={pageable.page * pageable.rows}
                    header={tblHeader} onPage={onPage} onSort={onSort}
                    rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    responsive={true}
                >
                    <Column field="code" header="Code" />
                    <Column field="name" header="Name" />
                    <Column header="Branch" body={(rowData) => rowData.branch && rowData.branch.name}/>
                    <Column field="active" header="Active" style={{width: '10em'}} bodyClassName="p-c" body={(rowData) => <span className={classNames('status', {'active': rowData.active, 'inactive': !rowData.active})}>{rowData.active?'Active':'Inactive'}</span>} />
                    <Column header="Actions" body={actionTemplate} className="tc-actions" style={{width: '12em'}} />
                </DataTable>
                
            </div>
        </div>
    )
}

export default InventoryListView;