import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import moment from 'moment';
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { showNotification } from '../../core/service/NotificationService';
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { titleCaseText, getDefaultCountry } from "../../core/service/CommonService";
import { APP_FEP, ASSET_TYPE, ATTRIBUTE_GROUP_TYPE, FIELD_DIRECTION_TYPE, FIELD_DISPLAY_TYPE, FIELD_EXPRESSION_TYPE, ATTRIBUTE_FIELD_TYPE, TAXONOMY_TYPE } from "../../constants";
import { getListTaxons } from "../taxon/TaxonServices";
import { saveAsset, getCustomFields } from "./AssetServices";
import { getListInventories } from "../inventory/InventoryService";
import { InputMask } from "primereact/inputmask";
import { MultiSelect } from "primereact/multiselect";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import PhoneInput from "react-phone-number-input";

export const AssetForm = forwardRef((props, ref) => {

    const [frmData, setFrmData] = useState({app: APP_FEP});
    const [visible, setVisible] = useState(false)
    const [formHeader, setFormHeader] = useState('')
    const [inventory, setInventory] = useState(null)
    const [branchs, setBranchs] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [categories, setCategories] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [fieldValues, setFieldValues] = useState([]);
    const defaultCountry = getDefaultCountry()

    const [errors, setErrors] = useState({});

    useEffect(() => {
        loadBranches();
        loadCategories();
    },[])

    useEffect(() => {
        if(frmData.branchId)
            loadInventories(frmData.branchId);
        else
            setInventories([])
    }, [frmData.branchId])

    useImperativeHandle(ref, () => ({
        openForm(data, isClone, inventory){
            setVisible(true);
            setFormHeader(data?isClone?'Clone Facility':'Edit Facility':'Add Facility')
            popularForm(data, inventory, isClone)

            if(inventory) 
                setInventory(inventory)
            else
                setInventory(null)

            setErrors({});
            loadCustomFields(data&&data.category?data.category.id:null, data?data.id:null)
        }    
    }))
    
    const popularForm = (e, inventory, isClone=false) => {
        setFrmData({...frmData,
            id: e&&!isClone?e.id:null,
            type: e ? e.type : ASSET_TYPE.facility.value,
            branchId: e?(e.branch?e.branch.id:null):inventory?inventory.branchId:null,
            inventoryId: e?(e.inventory?e.inventory.id:null):inventory?inventory.id:null,
            categoryId: e&&e.category ? e.category.id : null,
            code: e ? e.code : '',
            name: e ? e.name : '',
            slot: e ? (e.slot?e.slot:(e.category&&e.category.slot?e.category.slot:'1')) : '1',
            weight: e ? e.weight : 0,
            active: e ? e.active : true,
        })
    }

    const loadBranches = () => {
        getListTaxons({type: TAXONOMY_TYPE.branch.value}, true).then(res => setBranchs(res));
    }

    const loadInventories = (branchId) => {
        getListInventories({app: APP_FEP, branchId: branchId}, true)
        .then(res => {
            setInventories(res)

            if(frmData.inventoryId && res.length>0 && !res.find(x => x.value===frmData.inventoryId))
                setFrmData({...frmData, inventoryId: null})
        })
    }

    const loadCategories = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.asset.value]}, true)
        .then(res => setCategories(res))
    }

    const loadCustomFields = (categoryId, assetId) => {
        if(!categoryId){
            setCustomFields([])
            setFieldValues([])
        }else{
            getCustomFields(categoryId, assetId)
            .then(res => {
                let tmpCustomFields = res.customFields?res.customFields:[]
                let mapFieldValues = res.mapFieldValues?res.mapFieldValues:{}
                let tmpFieldValues = [];

                tmpCustomFields.forEach(el => {
                    let itemValue = mapFieldValues[el.field.id];
                    let partAnswerObj = itemValue&&itemValue.storeType==='boolean'?JSON.parse(itemValue.value):{}
                    let item = {
                        id: itemValue?itemValue.id:'',
                        setType: ATTRIBUTE_GROUP_TYPE.custom_field.value,
                        fieldId: el.field.id,
                        fieldName: el.field.name,
                        storeType: el.field.config.storeType,
                        value: itemValue?itemValue.value:'',
                        valueArrays: itemValue && itemValue.valueArrays?itemValue.valueArrays:[],
                        phoneValue: itemValue && itemValue.phoneObj ? (itemValue.phoneObj.countryCode!=='US' ? ('+' + itemValue.phoneObj.dialCode) : '') + itemValue.phoneObj.phoneNumber: '',
                        phoneCodeValue: itemValue && itemValue.phoneObj ? itemValue.phoneObj.countryCode.toLowerCase() : '',
                        dateValue: el.field.type===ATTRIBUTE_FIELD_TYPE.date.value && itemValue && itemValue.value?moment(itemValue.value, 'YYYY-MM-DD').toDate():'',
                        flagAnswer: itemValue?(partAnswerObj.accepted==='yes'?true:false):(el.defaultValue==='true'?true:false),
                    }

                    tmpFieldValues.push(item);
                });

                setFieldValues(tmpFieldValues);
                setCustomFields(tmpCustomFields);
            })
        }
    }

    const handleSave= () => {
        setErrors({});
        let assetData = {...frmData};
        assetData.fieldValues = [...fieldValues]
        saveAsset(assetData)
        .then(res => {
            if (!res.errorCode) {
                if(props.reloadTable)
                    props.reloadTable();
                if(props.refresh)
                    props.refresh(res)
                hideForm();
                showNotification('success', 'Success Message', 'Action submitted');
            } else {
                if (res.errorCode === 400)  setErrors(res.errorObj);
                showNotification('error', 'Error Message', 'Cannot perform action');
            } 
        });
    }

    const onCategoryChange = (e) => {
        setFrmData({...frmData,
            categoryId: e.value
        })
        loadCustomFields(e.value, frmData.id)
    }

    const onFieldChange = (idx, value, fieldId, type, displayType, storeType) => {
        let tmpFieldValues = [...fieldValues];

        switch(type){
            case ATTRIBUTE_FIELD_TYPE.date.value:
                tmpFieldValues[idx].value = value?moment(value).format(moment.HTML5_FMT.DATE):'';
                tmpFieldValues[idx].dateValue = value;
                break;
            case ATTRIBUTE_FIELD_TYPE.select.value:
                switch(displayType){
                    case FIELD_DISPLAY_TYPE.dropdown:
                        if(storeType!=='array'){
                            tmpFieldValues[idx].value = value;
                        }else{
                            tmpFieldValues[idx].valueArrays = value;
                        }
                        break;
                    case FIELD_DISPLAY_TYPE.checkbox:
                            let selectedValues = [...tmpFieldValues[idx].valueArrays];
        
                            if(value.checked)
                                selectedValues.push(value.value);
                            else
                                selectedValues.splice(selectedValues.indexOf(value.value), 1);
                    
                            tmpFieldValues[idx].valueArrays = selectedValues;
                        break;
                    case FIELD_DISPLAY_TYPE.radio:
                        tmpFieldValues[idx].value = value;
                        break;
                    default:
                        break;
                }
                break;
            case ATTRIBUTE_FIELD_TYPE.yes_no.value:
                tmpFieldValues[idx].value = value+'';
                tmpFieldValues[idx].flagAnswer = value;
                break;
            case ATTRIBUTE_FIELD_TYPE.waiver.value:
                if(displayType===FIELD_DISPLAY_TYPE.checkbox){
                    tmpFieldValues[idx].flagAnswer = value;
                    tmpFieldValues[idx].value = '';
                }else if(displayType===FIELD_DISPLAY_TYPE.textbox){
                    tmpFieldValues[idx].value = value;
                }
                else if(displayType===FIELD_DISPLAY_TYPE.file){
                    tmpFieldValues[idx].fieldFile = {[fieldId]: value};
                }
                break;
            default:
                tmpFieldValues[idx].value = value;
                break;
        }

        setFieldValues(tmpFieldValues);
    }

    const hideForm = () => {
        setVisible(false);
    }

    return (
        <Sidebar dismissable={true} visible={visible} style={{ overflowY: "auto", }} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} onHide={hideForm} >
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{formHeader}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={hideForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid">
                <div className="p-col-12">
                    <Fieldset legend="Hierachy">
                        <div className="p-grid p-fluid form-group">
                            {inventory?
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label className="p-label dark">Branch: {inventory.branch.name}</label>
                                </div> 
                                <div className="p-col-12">
                                    <label className="p-label dark">Inventory: {inventory.name}</label>
                                </div>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label className="p-label">* Branch</label>
                                    <Dropdown options={branchs} value={frmData.branchId} onChange={(e) => setFrmData({...frmData, branchId: e.value})} placeholder="Select a branch"/>
                                    <div className="p-form-error">{errors.branchId}</div>
                                </div> 
                                <div className="p-col-12">
                                    <label className="p-label">* Inventory</label>
                                    <Dropdown options={inventories} value={frmData.inventoryId} onChange={(e) => setFrmData({...frmData, inventoryId: e.value})} placeholder="Select a inventory"/>
                                    <div className="p-form-error">{errors.inventoryId}</div>
                                </div>
                            </React.Fragment>
                            }
                        </div>
                    </Fieldset>
                    <Fieldset legend="Basic Information">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <label className="p-label">* Type</label>
                                <Dropdown options={Object.values(ASSET_TYPE)} value={frmData.type} onChange={(e) => setFrmData({...frmData, type: e.value})} />
                                <div className="p-form-error">{errors.type}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* Category</label>
                                <Dropdown options={categories} value={frmData.categoryId} onChange={(e) => onCategoryChange(e)} placeholder="Select a Category"/>
                                <div className="p-form-error">{errors.categoryId}</div>
                            </div> 
                            <div className="p-col-12">
                                <label className="p-label">* Code</label>
                                <InputText value={frmData.code} keyfilter={/[^\s]/} onChange={(e) => setFrmData({ ...frmData, code: e.target.value })} />
                                <div className="p-form-error">{errors.code}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* Name</label>
                                <InputText value={frmData.name} onChange={(e) => setFrmData({ ...frmData, name: titleCaseText(e.target.value) })} />
                                <div className="p-form-error">{errors.name}</div>
                            </div>
                            {frmData.type===ASSET_TYPE.equipment.value &&
                                <div className="p-col-12">
                                    <label className="p-label">Slot</label>
                                    <InputText value={frmData.slot} keyfilter="pnum" onChange={(e) => setFrmData({...frmData, slot: e.target.value})}/>
                                    <div className="p-form-error">{errors.slot}</div>
                                </div> 
                            }
                            <div className="p-col-12">
                                <InputSwitch inputId="ckActive" checked={frmData.active} onChange={(e) => setFrmData({ ...frmData, active: e.value })} />
                                <label htmlFor="ckActive" className="p-ml-2">Active?</label>
                            </div>
                        </div>
                    </Fieldset>
                </div>
                {customFields && Object.keys(customFields).length > 0 &&
                    <div className="p-col-12">
                        <Fieldset legend="Additional">
                            { customFields.map((el, idx) => {
                                let field = el.field;
                                let config = field.config;

                                if(field.type!==ATTRIBUTE_FIELD_TYPE.yes_no.value){
                                    return <div key={idx} className="p-grid p-fluid">
                                        <div className="p-col-12">
                                            <label className="p-label">{el.required?'* ':''}{field.label}</label>
                                        </div>
                                        <div className="p-col-12">
                                            {(() => {
                                                switch(field.type){
                                                    case ATTRIBUTE_FIELD_TYPE.text.value:
                                                        if(config.expressionValidationType === FIELD_EXPRESSION_TYPE.maskString){
                                                            return <InputMask value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.target.value)} mask={config.expressionValidationInput}></InputMask>;
                                                        }else{
                                                            return <InputText value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.target.value)} minLength={config.minLength?config.minLength:''} maxLength={config.maxLength?config.maxLength:''}/>;
                                                        }
                                                    case ATTRIBUTE_FIELD_TYPE.email.value:
                                                        return <InputText value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.target.value)} keyfilter="email"/>;
                                                    case ATTRIBUTE_FIELD_TYPE.number.value:
                                                        return <InputText value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.target.value)} keyfilter="int"/>;
                                                    case ATTRIBUTE_FIELD_TYPE.phone.value:
                                                        return <PhoneInput value={fieldValues[idx]?fieldValues[idx].value:''} defaultCountry={config.defaultCountry?config.defaultCountry:defaultCountry} onChange={(e) => onFieldChange(idx, e, field)} className="p-component p-inputtext p-phoneinput" />
                                                    case ATTRIBUTE_FIELD_TYPE.select.value:
                                                        let valueList = config.useCustomList?config.customList.values:config.dataList;

                                                        if(config.displayType===FIELD_DISPLAY_TYPE.dropdown){
                                                            let dataList = [];

                                                            valueList.forEach(el => {
                                                                dataList.push({value: el, label: el})
                                                            });

                                                            if(config.storeType!=='array'){
                                                                if(config.useNoneLabel){
                                                                    dataList.unshift({value: '', label: config.noneLabel});
                                                                }
                                                                
                                                                return <Dropdown value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} options={dataList} style={{width: '100%'}}/>
                                                            }else{
                                                                return <MultiSelect value={fieldValues[idx]?fieldValues[idx].valueArrays:[]} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} options={dataList} style={{width: '100%'}}/>
                                                            }
                                                        }else{
                                                            return (
                                                                <div className="p-grid">
                                                                    {config.directionType === FIELD_DIRECTION_TYPE.horizontal &&
                                                                        <React.Fragment>
                                                                            {config.displayType===FIELD_DISPLAY_TYPE.radio && config.useNoneLabel &&
                                                                                <div className="p-col-12">
                                                                                    <RadioButton inputId={"rb_"+field.id+"_none"} value="" onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].value===''}></RadioButton>
                                                                                    <label htmlFor={"rb_"+field.id+"_none"} className="p-checkbox-label p-margin-left-5">{config.noneLabel}</label>
                                                                                </div>
                                                                            }
                                                                            {valueList.map((v, i) => {
                                                                                    return (
                                                                                        <div key={i} className="p-col-12">
                                                                                            {config.displayType===FIELD_DISPLAY_TYPE.checkbox &&
                                                                                                <React.Fragment>
                                                                                                    <Checkbox inputId={"cb_"+field.id+"_"+i} value={v} onChange={(e) => onFieldChange(idx, e , field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].valueArrays.includes(v)}></Checkbox>
                                                                                                    <label htmlFor={"cb_"+field.id+"_"+i} className="p-checkbox-label p-margin-left-5">{v}</label>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                            {config.displayType===FIELD_DISPLAY_TYPE.radio &&
                                                                                                <React.Fragment>
                                                                                                    <RadioButton inputId={"cb_"+field.id+"_"+i} value={v} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].value===v}></RadioButton>
                                                                                                    <label htmlFor={"cb_"+field.id+"_"+i} className="p-checkbox-label p-margin-left-5">{v}</label>
                                                                                                </React.Fragment>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })    
                                                                            }
                                                                        </React.Fragment>
                                                                    }
                                                                    {config.directionType === FIELD_DIRECTION_TYPE.vertical &&
                                                                        <div className="p-col-12">
                                                                            {config.displayType===FIELD_DISPLAY_TYPE.radio && config.useNoneLabel &&
                                                                                <React.Fragment>
                                                                                    <RadioButton inputId={"rb_"+field.id+"_none"} value="" onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].value===''}></RadioButton>
                                                                                    <label htmlFor={"rb_"+field.id+"_none"} className="p-checkbox-label p-margin-right-20">{config.noneLabel}</label>
                                                                                </React.Fragment>
                                                                            }
                                                                            {valueList.map((v, i) => {
                                                                                return (
                                                                                    <React.Fragment key={i}>
                                                                                        {config.displayType===FIELD_DISPLAY_TYPE.checkbox &&
                                                                                            <React.Fragment>
                                                                                                <Checkbox inputId={"cb_"+field.id+"_"+i} value={v} onChange={(e) => onFieldChange(idx, e , field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].valueArrays.includes(v)}></Checkbox>
                                                                                                <label htmlFor={"cb_"+field.id+"_"+i} className="p-checkbox-label p-margin-right-20">{v}</label>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                        {config.displayType===FIELD_DISPLAY_TYPE.radio &&
                                                                                            <React.Fragment>
                                                                                                <RadioButton inputId={"rb_"+field.id+"_"+i} value={v} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type, config.displayType, config.storeType)} checked={fieldValues[idx]&&fieldValues[idx].value===v}></RadioButton>
                                                                                                <label htmlFor={"rb_"+field.id+"_"+i} className="p-checkbox-label p-margin-right-20">{v}</label>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                )
                                                                            })}        
                                                                        </div>
                                                                    }
                                                                </div>
                                                            );
                                                        }
                                                    case ATTRIBUTE_FIELD_TYPE.textarea.value:
                                                        return <InputTextarea value={fieldValues[idx]?fieldValues[idx].value:''} onChange={(e) => onFieldChange(idx, e.target.value)} rows={config.rows} autoResize={true}/>;
                                                    case ATTRIBUTE_FIELD_TYPE.date.value:
                                                        return <Calendar value={fieldValues[idx]?fieldValues[idx].dateValue:''} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type)} showIcon={true} dateFormat="mm-dd-yy" mask="99-99-9999" />
                                                    case ATTRIBUTE_FIELD_TYPE.file.value:
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            })()}
                                            <div className="p-form-error">{errors["fieldValues_"+field.id]}</div>
                                        </div>
                                    </div>
                                }else{
                                    return <div key={idx} className="p-grid p-fluid">
                                        <div className="p-col-12 ">
                                            {(() => {
                                                if(config.displayType===FIELD_DISPLAY_TYPE.radio){
                                                    return (
                                                        <React.Fragment>
                                                            <label className="p-label p-margin-botton-5">{field.label}</label>
                                                            <div className="p-grid p-margin-top-0">
                                                                <div className="p-col-12">
                                                                    <RadioButton inputId={"rb_0_"+field.id} value={true} onChange={(e) => onFieldChange(idx, e.value, field.id, field.type)} checked={fieldValues[idx]&&fieldValues[idx].flagAnswer}/>
                                                                    <label htmlFor={"rb_0_"+field.id} className="p-checkbox-label p-margin-right-10">Yes</label>
                                                                    <RadioButton inputId={"rb_1_"+field.id} value={false} onChange={(e) => onFieldChange(idx, e.value, field.id, field.value)} checked={fieldValues[idx]&&!fieldValues[idx].flagAnswer}/>
                                                                    <label htmlFor={"rb_1_"+field.id} className="p-checkbox-label p-margin-left-5">No</label>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                }else{
                                                    return (
                                                        <React.Fragment>
                                                            <Checkbox inputId={"cb_"+field.id} onChange={(e) => onFieldChange(idx, e.checked, field.id, field.type)} checked={fieldValues[idx]&&fieldValues[idx].flagAnswer}></Checkbox>
                                                            <label htmlFor={"cb_"+field.id} className="p-checkbox-label p-margin-left-5">{field.label}</label>
                                                        </React.Fragment>
                                                    );
                                                }
                                            })()}
                                            {config.content &&
                                                <div className="p-margin-top-10" style={{fontSize: '13px'}}>{config.content}</div>
                                            }
                                        </div>
                                    </div>
                                }
                            })}
                        </Fieldset>
                    </div>
                }
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={hideForm}/>
                    <Button label="Save" icon="pi pi-save" onClick={() => handleSave()}/>
                </div>
            </div>
        </Sidebar>
    );
})
