import { takeLatest, call, put } from 'redux-saga/effects'
import {
  FORM_REQUESTING,
  FORM_REQUEST_SUCCESS,
  FORM_REQUEST_FAILED
} from '../constants/form'
import { getFormList } from '../services/form';

function* getFormListFlow({category}) {
  try {
    let data = yield call(getFormList, category);
    yield put({
      type: FORM_REQUEST_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: FORM_REQUEST_FAILED,
      error
    })
  }
}

function* formDetaileWatcher() {
  yield takeLatest(FORM_REQUESTING, getFormListFlow);
}

export default formDetaileWatcher;
