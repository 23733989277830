const maxValueMonth = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
const formatOrder = ['yy', 'hh', 'mm', 'dd', 'HH', 'MM', 'SS', "A"]
const aArray = ['a', 'p', 'A', 'P']
const mArray = ['m', 'M']
export default function createAutoCorrectedDatePipe(dateFormat = 'mm dd yy', {
    minYear = 1,
    maxYear = 9999
} = {}) {
    const dateFormatArray = dateFormat
        .split(/[^dmyHMSA]+/)
        // .sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b))
    const maxValue = {'dd': 31, 'mm': 12, 'yy': maxYear, 'hh': 12, 'HH': 23, 'MM': 59, 'SS': 59}
    const minValue = {'dd': 1, 'mm': 1, 'yy': minYear, 'hh': 0, 'HH': 0, 'MM': 0, 'SS': 0}
    return function(conformedValue) {
        const indexesOfPipedChars = []
        
        const conformedValueArr = conformedValue.split('')

        let arrDateFormat = dateFormat.split(' ') // [mm-dd-yy, HH:mm, A]
        let arrConformValue = conformedValue.split(' ')
        let isInvalid = false;

        arrDateFormat.forEach((format, index) => {
            let formatArr = format.split(/[^dmyHMSA]+/)

            // // Check first digit
            formatArr.forEach((mask, idx) => {
                let position = dateFormat.indexOf(mask)
                if(index > 0 && arrDateFormat.length > 2){
                    position = (mask==='mm' ? (dateFormat.indexOf('HH')+3) : dateFormat.indexOf(mask)) + 2
                }
                
                if(mask==='A'){
                    let stram = conformedValue.substr(position, 1).replace('_', '').trim();
                    if(stram && aArray.indexOf(stram) !== -1){
                        conformedValueArr[position] = stram.toUpperCase();
                        conformedValueArr[position + 1] = "M";
                    }else{
                        conformedValueArr[position] = "_";
                        conformedValueArr[position + 1] = "_";
                    }
                    indexesOfPipedChars.push(position)
                }else{
                    let monthValue = conformedValue.substr(dateFormat.indexOf('mm'), 2).replace(/[_]/g, '')
                    let yearValue = conformedValue.substr(dateFormat.indexOf('yy'), 4).replace(/[_]/g, '')
                    let month = parseInt(monthValue, 10)
                    let year = parseInt(yearValue, 10)
                    let positionDay = dateFormat.indexOf('dd')
                    let maxFirstDigit = parseInt(maxValue[mask].toString().substr(0, 1), 10)
                    if(mask === 'dd'){
                        maxFirstDigit = monthValue && month<=12 ? parseInt(maxValueMonth[month].toString().substr(0, 1), 10) : parseInt(maxValue[mask].toString().substr(0, 1), 10)
                    }

                    if(mask==='HH'){
                        maxFirstDigit = parseInt(maxValue['hh'].toString().substr(0, 1), 10)
                    }
                    if(mask==='mm'){
                        if(format.indexOf('HH')!==-1){ // minute
                            maxFirstDigit = parseInt(maxValue['MM'].toString().substr(0, 1), 10)
                        }else{
                            if(monthValue && yearValue){
                                if(month === 2 && !isLeapYear(year)){
                                    // if (parseInt(conformedValue.substr(positionDay,2), 10) > 28) {
                                    //     conformedValueArr[positionDay + 1] = ''
                                    //     conformedValueArr[positionDay] = '2'
                                    //     indexesOfPipedChars.push(positionDay)
                                    // }
                                }
                            }
                        }
                    }
                    if(mask === 'yy'){
                        if(monthValue && yearValue){
                            if(month === 2 && !isLeapYear(year)){
                                // if (parseInt(conformedValue.substr(positionDay,2)) > 28) {
                                //     conformedValueArr[positionDay + 1] = ''
                                //     conformedValueArr[positionDay] = '2'
                                //     indexesOfPipedChars.push(positionDay)
                                // }
                            }
                        }
                    }
                    let positionValue = position
                    if(mask !== 'yy' && formatArr.indexOf('yy')!==-1 && formatArr.indexOf('yy') !== (formatArr.length - 1)){
                        positionValue += 2
                    }
                    if (parseInt(conformedValueArr[positionValue], 10) > maxFirstDigit) {
                        conformedValueArr[positionValue + 1] = conformedValueArr[positionValue]
                        conformedValueArr[positionValue] = 0
                        indexesOfPipedChars.push(positionValue)
                    }
                }
            })
        })

        // Check for invalid
        if(dateFormat.indexOf('dd')!==-1 && dateFormat.indexOf('yy')!==-1 &&
            dateFormat.indexOf('HH')===-1 
        ){
            isInvalid = validDate(arrDateFormat[0], arrConformValue[0])
        }else if(dateFormat.indexOf('dd')===-1 && dateFormat.indexOf('yy')===-1 &&
            dateFormat.indexOf('HH')!==-1 
        ){
            isInvalid = validTime(arrDateFormat[0] + ' ' + arrDateFormat[1], arrConformValue[0] + ' ' +arrConformValue[1])
        }else{
            isInvalid = validDate(arrDateFormat[0], arrConformValue[0]) || validTime(arrDateFormat[1] + ' ' + arrDateFormat[2], arrConformValue[1] + ' ' +arrConformValue[2])
        }

        if (isInvalid) {
            return false
        }

        return {
            value: conformedValueArr.join(''),
            indexesOfPipedChars
        }
    }

    function validDate(dateFormatString, conformedValue){
        let dateFormatArr = dateFormatString.split(/[^dmyHMSA]+/)
        let month = 0
        let year = 0
        let valid = dateFormatArr.some((format) => {
            let position = dateFormat.indexOf(format)
            let length = (format==='yy') ? 4 : format.length 
            let textValue = conformedValue.substr(position, length).replace(/\D/g, '')
            let value = parseInt(textValue, 10)

            if (format === 'yy' && (minYear !== 1 || maxYear !== 9999)) {
                let scopedMaxValue = parseInt(maxValue[format].toString().substring(0, textValue.length), 10)
                let scopedMinValue = parseInt(minValue[format].toString().substring(0, textValue.length), 10)
                return value < scopedMinValue || value > scopedMaxValue
            }
            if(format === 'mm'){
                month = value || 0
            }
            let maxValueForFormat = maxValue[format]
            if(format === 'dd'){
                let yearValue = conformedValue.substr(dateFormat.indexOf('yy'), 4)
                if(yearValue) year = parseInt(yearValue)
                if(month === 2 || month === 4 || month === 6 || month === 9 || month === 11){
                    if(month===2 && !isLeapYear(year)){
                        maxValueForFormat = 28
                    }else{
                        maxValueForFormat = maxValueMonth[month]
                    }
                }
            }
            
            return value > maxValueForFormat || (textValue.length === length && value < minValue[format])
        })
        return valid
    }

    function validTime(timeFormatString, conformedValue){
        let timeFormatArr = timeFormatString.split(/[^dmyHMSA]+/)
        let valid = timeFormatArr.some((format) => {
            let position = timeFormatString.indexOf(format)
            let length = format.length 
            let textValue = conformedValue.substr(position, length).replace(/\D/g, '')
            let value = parseInt(textValue, 10)

            if(format === 'HH'){
                if(timeFormatArr.indexOf('A') === -1){
                    return value < minValue[format] || value > maxValue[format]
                }else {
                    return value < minValue['hh'] || value > maxValue['hh']
                }
            } 
            if(format === 'mm'){ 
                return value < minValue['MM'] || value > maxValue['MM']
            }
            // if(format === 'A'){
            //     let valueText_A_P = conformedValue.substr(position, 1).replace('_','').trim();
            //     let valueText_M = conformedValue.substr(position+1, 1).replace('_','').trim();
            //     if(!valueText_A_P && !valueText_M) return false;
            //     else if(valueText_A_P && valueText_M){
            //         if(aArray.indexOf(valueText_A_P) !== -1 && valueText_M !== -1) return false
            //     }else{
            //         if(valueText_A_P && aArray.indexOf(valueText_A_P) !== -1) return false
            //         else if(valueText_M && valueText_M === 'M') return false
            //     }
            //     return false;
            // }
        })
        return valid
    }

    function isLeapYear (value) {
        let isLeapYear = false;
        let numberFirst = parseInt(value.toString().substr(0,2));
        let numberLast = parseInt(value.toString().substr(2));
        if(value){
            if(value%4 === 0 && numberFirst%4 === 0 && numberLast%4 === 0){
                isLeapYear = true;
            }
        }
        return isLeapYear;
    }
}