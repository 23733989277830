import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Fieldset } from 'primereact/fieldset';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Button } from 'primereact/button';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { getTablePageReport, getTimeZone, moneyFormat } from '../../core/service/CommonService';
import { getMembership, verifyMembershipDocument, updateMembershipStatus, changeMembershipCardStatus, updateCompanyForMembership } from './MembershipServices';
import classNames from 'classnames';
import { TabView, TabPanel } from 'primereact/tabview';
import {
	MEMBERSHIP_STATUS,
	PRODUCT_PRICE_UNIT,
	MEMBERSHIP_TYPE,
	MEMBERSHIP_CARD_STATUS,
	GROUP_USER_TYPE,
	IDENTITY_DOCUMENT_TYPE,
	ORDER_STATE,
	APP_FEP,
	PAYMENT_STATE,
	DATE_FORMAT_DISPLAY,
	DATETIME_FORMAT_DISPLAY,
	MEMBERSHIP_PARTICIPANT_TYPE,
	PRODUCT_PARTICIPANT_TYPE,
} from '../../constants';
import { getListIdentityDocuments } from '../contact/identity-document/components/IdentityDocumentServices';
import { MembershipMemberListView } from './MembershipMemberListView';
import { MembershipCardForm } from './MembershipCardForm';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';
import { changeGroupMemberOwner, createGroupByUser, deleteGroupMember, getGroup, getListGroupByUsers, getListGroups, getPageMembers } from '../group/GroupServices';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { MemberForm } from '../group/MemberForm';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GroupForm } from '../group/GroupForm';
import { IdentityDocumentForm } from '../contact/identity-document/components/IdentityDocumentForm';
import { getOrderDetails } from '../../pim/order/OrderService';
import { OrderOrInvoicePaymentActionForm } from '../../pim/invoice/OrderOrInvoicePaymentActionForm';
import { getListInvoices } from '../../pim/invoice/InvoiceServices';
import { ExtendMembershipForm } from './ExtendMembershipForm';
import { getProduct } from '../../pim/product/ProductServices';
import { Dropdown } from 'primereact/dropdown';
import { MembershipForm } from './MembershipForm';


export const MembershipDetailView = (props) => {
	const [membership, setMembership] = useState(null);
	const [subscriptionInfo, setSubscriptionInfo] = useState(null);
	const [owner, setOwner] = useState(null);
	const [workPermitDocument, setWorkPermitDocument] = useState({});
	const [passportDocument, setPassportDocument] = useState({});
	const [finDocument, setFINDocument] = useState({});
	const [member, setMember] = useState(null);
	const [activeCard, setActiveCard] = useState(null);
	const [tmpCompanyId, setTmpCompanyId] = useState(null);
	const [currentCompany, setCurrentCompany] = useState({});
	const [companies, setCompanies] = useState([]);
	const [groupMembers, setGroupMembers] = useState([]);
	const [pageableGroupMember, setPageableGroupMember] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'id',
		sortOrder: -1,
	});
	const [isLoadTableGroupMember, setLoadTableGroupMember] = useState(false);
	const [errors, setErrors] = useState({});
	const [order, setOrder] = useState(null);
	const [invoice, setInvoice] = useState(null);
	const [msProduct, setMsProduct] = useState({});
	const [isApproachingExpirationDate, setApproachingExpirationDate] = useState(false);

	const frmMembershipCard = useRef(null);
	const frmCompanyMember = useRef(null);
	const frmGroup = useRef(null);
	const frmIdentityDocument = useRef(null);
	const paymentActionForm = useRef(null);
	const frmExtendMembership = useRef(null);
	const frmMembership = useRef(null);

	useEffect(() => {
		// loadMembership();
		loadCompanies();
	}, []);

	useEffect(() => {
		loadMembership();
	}, [props.match.params.id]);

	useEffect(() => {
		if (isLoadTableGroupMember) loadPageCompanyMembers();
	}, [isLoadTableGroupMember]);

	const loadMembership = async () => {
		showloading();

		const msRes = await getMembership(props.match.params.id);

		if (!msRes.errorCode) {
			const msTemplate = msRes.template;

			setMembership(msRes);
			setSubscriptionInfo(msRes.subscriptionInfo);
			setMsProduct(msTemplate);

			setOwner(msRes.owner);
			if (msRes.type === MEMBERSHIP_TYPE.Individual.value) {
				setMember(msRes.members[0]);
				setActiveCard(msRes.members[0]?.activeCard);
			}

			if (msRes.owner) {
				loadUserIdentityDocuments(msRes.owner.id);
			}

			if (msRes.orderId) {
				loadOrder(msRes.orderId);
				loadListInvoices(msRes.orderId);
			}

			if (msRes.groupId) {
				loadCompany(msRes.groupId);
			} else {
				setTmpCompanyId(null);
				setCurrentCompany(null);
			}

			if (msRes.status !== MEMBERSHIP_STATUS.Pending.value) {
				//get list of days setting, default 30 days if doesn't exist
				const tmpArrDayNotify = msTemplate?.useNotification && msTemplate?.numberDaysNotifyBeforeExpirationDate ? [...msTemplate.numberDaysNotifyBeforeExpirationDate] : [30];

				const maxDayNotify = tmpArrDayNotify.sort(function (a, b) {
					return parseInt(b) - parseInt(a);
				})[0];

				const timezone = getTimeZone();
				const nowMoment = moment.tz(timezone);
				const endMoment = moment(msRes.end);

				const diffNowWithEnd = endMoment.diff(nowMoment, 'days');

				setApproachingExpirationDate(diffNowWithEnd <= maxDayNotify);
			}
		} else {
			showErrorNotify('No result');
		}
		stoploading();
	};

	const loadUserIdentityDocuments = async (userId) => {
		const documents = await getListIdentityDocuments({
			userId: userId,
			types: [IDENTITY_DOCUMENT_TYPE.work_permit.value, IDENTITY_DOCUMENT_TYPE.passport.value, IDENTITY_DOCUMENT_TYPE.fin.value],
			active: true,
		});

		if (documents && documents.length > 0) {
			let workPermit = documents.find((d) => d.docType === IDENTITY_DOCUMENT_TYPE.work_permit.value);
			let passport = documents.find((d) => d.docType === IDENTITY_DOCUMENT_TYPE.passport.value);
			let fin = documents.find((d) => d.docType === IDENTITY_DOCUMENT_TYPE.fin.value);

			setWorkPermitDocument(workPermit ? workPermit : {});
			setPassportDocument(passport ? passport : {});
			setFINDocument(fin ? fin : {});
		}
	};

	const loadOrder = (orderId) => {
		getOrderDetails(orderId).then((res) => {
			if (!res.errorCode) {
				setOrder(res);
			}
		});
	};

	const loadListInvoices = (orderId) => {
		getListInvoices({ app: APP_FEP, orderIds: [orderId] }).then((res) => {
			if (!res.errorCode) {
				let tmpInvoices = res ? res.filter((i) => !i.orderItem && i.status === PAYMENT_STATE.awaiting.value) : [];

				setInvoice(tmpInvoices.length > 0 ? tmpInvoices[0] : null);
			}
		});
	};

	const loadCompanies = () => {
		getListGroups().then((res) => setCompanies(res));
	};

	const loadCompany = (companyId) => {
		getGroup(companyId).then((res) => {
			if (!res.errorCode) {
				setTmpCompanyId(res.id);
				setCurrentCompany(res);
				setLoadTableGroupMember(true);
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const handleUpdateStatus = (status) => {
		updateMembershipStatus(membership.id, status).then((res) => {
			if (!res.errorCode) {
				setMembership(res);
				showSuccessNotify('Membership has been update!');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const handleVerifiedDocument = () => {
		verifyMembershipDocument(membership.id).then((res) => {
			if (!res.errorCode) {
				setMembership(res);
				showSuccessNotify('Membership has been verified!');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const changeMemberCardStatus = (id, status) => {
		showConfirmNotify({
			accept: () => handleChangeCardStatus(id, status),
		});
	};

	const handleChangeCardStatus = (id, status) => {
		showloading();

		changeMembershipCardStatus(id, status).then((res) => {
			if (!res.errorCode) {
				setActiveCard(res);
				showSuccessNotify('Action submitted!');
			} else {
				showErrorNotify('Cannot perform action');
			}

			stoploading();
		});
	};

	const onUpdateIdentityDocument = (type, identityDocumentData) => {
		frmIdentityDocument.current.openForm(type, identityDocumentData);
	};

	const onRefreshIdentityDocument = (identityDocumentData) => {
		if (identityDocumentData) {
			if (identityDocumentData.docType === IDENTITY_DOCUMENT_TYPE.work_permit.value) {
				setWorkPermitDocument(identityDocumentData);
			} else if (identityDocumentData.docType === IDENTITY_DOCUMENT_TYPE.passport.value) {
				setPassportDocument(identityDocumentData);
			} else if (identityDocumentData.docType === IDENTITY_DOCUMENT_TYPE.fin.value) {
				setFINDocument(identityDocumentData);
			}
		}
	};

	const onOrderAction = (action) => {
		paymentActionForm.current.openForm('order', action, order, invoice);
	};

	const onRefreshOrder = (orderData) => {
		setOrder(orderData);
		loadListInvoices(orderData.id);
		loadMembership();
	};

	const onAddOrEditMembershipCard = (memberId, activeCard) => {
		frmMembershipCard.current.openForm(membership.id, memberId, activeCard);
	};

	const onExtendMembership = () => {
		frmExtendMembership.current.openForm(membership);
	};

	//#region Company
	const handleUpdateCompanyForMembership = () => {
		updateCompanyForMembership(membership.id, tmpCompanyId).then((res) => {
			if (!res.errorCode) {
				loadCompany(tmpCompanyId);
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const loadPageCompanyMembers = () => {
		getPageMembers(currentCompany.id, pageableGroupMember.page, pageableGroupMember.rows, pageableGroupMember.sortField, pageableGroupMember.sortOrder).then((res) => {
			setGroupMembers(res.content);
			setPageableGroupMember({
				...pageableGroupMember,
				total: res.totalElements,
				page: res.pageable.pageNumber,
				rows: res.pageable.pageSize,
			});
			setLoadTableGroupMember(false);
		});
	};

	const onMemberPage = (e) => {
		setPageableGroupMember({
			...pageableGroupMember,
			page: e.page,
		});
		setLoadTableGroupMember(true);
	};

	const handleRemoveGroupMember = (id) => {
		deleteGroupMember(currentCompany.id, id).then((res) => {
			if (!res.errorCode) {
				setLoadTableGroupMember(true);
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const handelChangeOwnerGroup = (id) => {
		changeGroupMemberOwner(currentCompany.id, id).then((res) => {
			if (!res.errorCode) {
				setLoadTableGroupMember(true);
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const addOrEditMember = (data) => {
		let tmpData = data ? data : {};
		tmpData.groupId = currentCompany.id;
		frmCompanyMember.current.popularFormData(tmpData);
	};

	const onRemoveGroupMember = (id) => {
		showConfirmNotify({
			accept: () => handleRemoveGroupMember(id),
		});
	};

	const onChangePageLengthCompanyMember = (e) => {
		setPageableGroupMember({
			...pageableGroupMember,
			rows: e,
			page: 0,
		});
		setLoadTableGroupMember(true);
	};

	const actionSetOwnerTemplate = (rowData) => {
		return rowData.owner ? <span>YES</span> : <Button label='Set Owner' className='p-button-warning' onClick={() => handelChangeOwnerGroup(rowData.id)} />;
	};

	const actionMemberTemplate = (rowData) => {
		return !rowData.owner && rowData.user.id !== membership.owner.id && <Button label='Remove' icon='pi pi-trash' className='p-button-danger' onClick={() => onRemoveGroupMember(rowData.id)} />;
	};

	const getMsTemplatePrice = () => {
		if (msProduct && msProduct.prices?.length > 0) {
			const msPrice = msProduct.prices[0];

			return `${moneyFormat(msPrice.price)}/${msPrice.quantity} ${msPrice.unit}${msPrice.quantity > 1 ? 's' : ''}`;
		}

		return '';
	};

	const tblCompanyMemberHeader = (
		<TableHeader
			title='Members'
			pageReport={getTablePageReport(pageableGroupMember.page, pageableGroupMember.rows, pageableGroupMember.total)}
			actionTemplate={<Button className='p-button-success' label='Add Member' icon='pi-md-plus' onClick={() => addOrEditMember()} />}
			changePageLength={(e) => onChangePageLengthCompanyMember(e)}
			refresh={() => setLoadTableGroupMember(true)}
		/>
	);
	//#endregion

	return (
		(membership ?? null) &&
		(subscriptionInfo ?? null) &&
		(owner ?? null) && ( // return undefined as error, so return empty or null
			<div className='p-grid'>
				{membership.participantType === MEMBERSHIP_PARTICIPANT_TYPE.student.value ? (
					<>
						<BreadcrumbsItem to={`/student-memberships`}>Student Memberships</BreadcrumbsItem>
						<BreadcrumbsItem to={`/student-memberships/${props.match.params.id}`}>#{props.match.params.id}</BreadcrumbsItem>
					</>
				) : (
					<>
						<BreadcrumbsItem to={`/memberships`}>Memberships</BreadcrumbsItem>
						<BreadcrumbsItem to={`/memberships/${props.match.params.id}`}>#{props.match.params.id}</BreadcrumbsItem>
					</>
				)}

				<MembershipCardForm ref={frmMembershipCard} refreshData={(e) => setActiveCard(e)} />

				<IdentityDocumentForm
					ref={frmIdentityDocument}
					userId={membership.owner ? membership.owner.id : null}
					refreshData={(identityDocumentData) => onRefreshIdentityDocument(identityDocumentData)}
				/>

				<OrderOrInvoicePaymentActionForm ref={paymentActionForm} order={(data) => onRefreshOrder(data)} />

				<ExtendMembershipForm ref={frmExtendMembership} reloadData={() => loadMembership()} />

				<MembershipForm
					ref={frmMembership}
					participantTypes={
						membership.participantType === MEMBERSHIP_PARTICIPANT_TYPE.student.value
							? [PRODUCT_PARTICIPANT_TYPE.student.value]
							: [PRODUCT_PARTICIPANT_TYPE.adult.value, PRODUCT_PARTICIPANT_TYPE.youth.value]
					}
					refreshData={() => loadMembership()}
				/>

				<div className='p-col-12'>
					<div className='card card-w-title'>
						<h1>
							{membership.participantType === MEMBERSHIP_PARTICIPANT_TYPE.student.value ? 'Student Membership Details' : 'Membership Details'}
							<div className='p-toolbar-group-right'>
								<Link to={`/${membership.participantType === MEMBERSHIP_PARTICIPANT_TYPE.student.value ? 'student-memberships' : 'memberships'}`}>
									<Button icon='pi pi-bars' className='p-button-text' tooltip='Return' tooltipOptions={{ position: 'top' }}></Button>
								</Link>
							</div>
						</h1>
						<div className='p-grid'>
							<div className='p-col-12 p-md-3'>
								<label>
									Owner:{' '}
									<span className='p-w-bold'>
										{owner.username} - {owner.name}
									</span>
								</label>
							</div>
							<div className='p-col-12 p-md-3'>
								<label>
									Type: <span className='p-w-bold'>{membership.type}</span>
								</label>
							</div>
							<div className='p-col-12 p-md-3'>
								<label>
									Status: <span className={classNames('status', 'ms-' + membership.status)}>{membership.status}</span>
								</label>
							</div>
							<div className='p-col-12 p-md-3'>
								<label>
									Verification: <span className={classNames('status', { 'ms-Active': membership.verified, 'ms-Block': !membership.verified })}>{!membership.verified ? 'NOT YET' : 'VERIFIED'}</span>
								</label>
							</div>
						</div>
					</div>
				</div>

				<div className='p-col-12'>
					<TabView>
						<TabPanel header='Information'>
							<div className='p-grid'>
								<div className={`p-col-12 ${msProduct.requiredIdentificationDocument ? 'p-md-4' : 'p-md-6'}`}>
									<Fieldset legend='Membership'>
										<div className='info-text'>
											<div className='title'>{membership.participantType === MEMBERSHIP_PARTICIPANT_TYPE.student.value ? 'Category' : 'Membership Category'}:</div>
											<div className='content'>{membership.name}</div>
										</div>
										<div className='info-text'>
											<div className='title'>{membership.participantType === MEMBERSHIP_PARTICIPANT_TYPE.student.value ? 'Type' : 'Membership Type'}:</div>
											<div className='content'>{membership.type}</div>
										</div>
										<div className='info-text'>
											<div className='title'>Last Price:</div>
											<div className='content'>{moneyFormat(subscriptionInfo.price)}</div>
										</div>
										<div className='info-text'>
											<div className='title'>Subscription:</div>
											<div className='content'>
												{subscriptionInfo.period} {PRODUCT_PRICE_UNIT[subscriptionInfo.periodType].label}
												{subscriptionInfo.period > 1 ? 's' : ''}
											</div>
										</div>
										{membership.status !== MEMBERSHIP_STATUS.Pending.value && (
											<React.Fragment>
												<div className='info-text'>
													<div className='title'>Times:</div>
													<div className='content'>{subscriptionInfo.times}</div>
												</div>
												<div className='info-text'>
													<div className='title'>Join Date:</div>
													<div className='content'>{moment(membership.joinDate).format(DATETIME_FORMAT_DISPLAY)}</div>
												</div>
												<div className='info-text'>
													<div className='title'>Expired Date:</div>
													<div className='content'>{moment(membership.end).format(DATETIME_FORMAT_DISPLAY)}</div>
												</div>
											</React.Fragment>
										)}
										{msProduct.participantType === PRODUCT_PARTICIPANT_TYPE.student.value && (
											<div className='info-text'>
												<div className='title'>Agency:</div>
												<div className='content'>{membership?.broker?.name}</div>
											</div>
										)}

										<div className='p-hr p-p-0 p-mb-3'></div>
										<div className='p-c'>
											{(isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_DETAI_W, ROLES_CONFIG.MEMBERSHIP_STUDENT_DETAI_W])) && (
												<Button label='Edit' className='p-button-info' onClick={() => frmMembership.current.openForm(membership)} />
											)}
											{(isScpAdmin() ||
												hasRole([ROLES_CONFIG.MEMBERSHIP_DETAI_R, ROLES_CONFIG.MEMBERSHIP_DETAI_W, ROLES_CONFIG.MEMBERSHIP_STUDENT_DETAI_R, ROLES_CONFIG.MEMBERSHIP_STUDENT_DETAI_W])) &&
												![MEMBERSHIP_STATUS.Canceled.value, MEMBERSHIP_STATUS.Pending.value].includes(membership.status) && (
													<React.Fragment>
														{membership.status === MEMBERSHIP_STATUS.Active.value && (
															<Button label='Block' className='p-button-danger p-ml-3' onClick={() => handleUpdateStatus(MEMBERSHIP_STATUS.Block.value)} />
														)}
														{membership.status === MEMBERSHIP_STATUS.Block.value && (
															<Button label='Unblock' className='p-button-success p-ml-3' onClick={() => handleUpdateStatus(MEMBERSHIP_STATUS.Active.value)} />
														)}

														{(membership.status === MEMBERSHIP_STATUS.Expired.value || (membership.status === MEMBERSHIP_STATUS.Active.value && isApproachingExpirationDate)) && !invoice && (
															<Button label={`Renew (${getMsTemplatePrice()})`} className='p-button-success p-ml-3' onClick={() => onExtendMembership()} />
														)}
													</React.Fragment>
												)}
										</div>
									</Fieldset>
									{!!order && !!invoice && invoice?.status === PAYMENT_STATE.awaiting.value && (
										<Fieldset legend='Payment'>
											<div className='p-size-16 p-w-bold p-mb-3'>This membership need to completed payment first to make active</div>
											<div className='info-text'>
												<div className='title'>Order No.#:</div>
												<div className='content'>
													<Link to={`/orders/${membership.orderId}`} target='_blank'>
														<Button icon='pi pi-angle-double-right' label={order.orderNumber} className='p-button-success btn-text-sm' />
													</Link>
												</div>
											</div>
											<div className='info-text'>
												<div className='title'>
													<strong>Order total:</strong>
												</div>
												<div className='content'>
													<strong>{moneyFormat(order.total)}</strong>
												</div>
											</div>
											{order.paymentState !== PAYMENT_STATE.pending.value && (
												<React.Fragment>
													<div className='info-text'>
														<div className='title'>
															<strong>Paid total:</strong>
														</div>
														<div className='content'>
															<strong>{moneyFormat(order.paidTotal)}</strong>
														</div>
													</div>
												</React.Fragment>
											)}

											<div className='p-hr p-p-0 p-mb-3'></div>

											<div className='p-messages p-component p-messages-warn p-messages-enter-done'>
												<div className='p-messages-wrapper p-p-size-16'>
													<span className='p-messages-icon pi pi-exclamation-triangle'></span>
													<span className='p-messages-summary'>Order need to pay remain: {moneyFormat(order.total - order.paidTotal)}</span>
												</div>
											</div>
											<div className='p-c'>
												<Button label='Complete Order' icon='pi-md-check' onClick={() => onOrderAction('complete')} className='p-button-success p-margin-right-10 p-margin-bottom-10' />
											</div>
										</Fieldset>
									)}
								</div>
								{msProduct.requiredIdentificationDocument && (
									<div className='p-col-12 p-md-4'>
										<Fieldset legend='Documents'>
											{msProduct.participantType !== PRODUCT_PARTICIPANT_TYPE.student.value ? (
												<React.Fragment>
													<div className='info-text'>
														<div className='title'>Work permit number:</div>
														<div className='content'>{workPermitDocument.docNumber ? workPermitDocument.docNumber : 'N/A'}</div>
													</div>
													<div className='info-text'>
														<div className='title'>Work permit expired:</div>
														<div className='content'>{workPermitDocument.expiredDate ? moment(workPermitDocument.expiredDate).format(DATE_FORMAT_DISPLAY) : 'N/A'}</div>
													</div>
													<div className='info-text'>
														<div className='title'>Front of work permit:</div>
														<div className='content'>
															{workPermitDocument.images && workPermitDocument.images.length > 0 && workPermitDocument.images[0] ? (
																<div>
																	<div>
																		<a href={workPermitDocument.images[0].fullUrl} target='_blank' rel='noopener noreferrer'>
																			View image
																		</a>
																	</div>
																	<img src={workPermitDocument.images[0].fullUrl} alt={workPermitDocument.images[0].fileName} style={{ maxWidth: '100%', maxHeight: 150 }} />
																</div>
															) : (
																'N/A'
															)}
														</div>
													</div>
													<div className='info-text'>
														<div className='title'>Back of work permit:</div>
														<div className='content'>
															{workPermitDocument.images && workPermitDocument.images.length > 0 && workPermitDocument.images[1] ? (
																<div>
																	<div>
																		<a href={workPermitDocument.images[1].fullUrl} target='_blank' rel='noopener noreferrer'>
																			View image
																		</a>
																	</div>
																	<img src={workPermitDocument.images[1].fullUrl} alt={workPermitDocument.images[1].fileName} style={{ maxWidth: '100%', maxHeight: 150 }} />
																</div>
															) : (
																'N/A'
															)}
														</div>
													</div>
													<div className='info-text'>
														<div className='title'></div>
														<div className='content'>
															<Button label='Update' className='p-button-info btn-text-sm' onClick={() => onUpdateIdentityDocument(IDENTITY_DOCUMENT_TYPE.work_permit.value, workPermitDocument)} />
														</div>
													</div>
												</React.Fragment>
											) : (
												<React.Fragment>
													<div className='info-text'>
														<div className='title'>FIN number:</div>
														<div className='content'>{finDocument.docNumber ?? 'N/A'}</div>
													</div>
													<div className='info-text'>
														<div className='title'></div>
														<div className='content'>
															<Button label='Update' className='p-button-info btn-text-sm' onClick={() => onUpdateIdentityDocument(IDENTITY_DOCUMENT_TYPE.fin.value, finDocument)} />
														</div>
													</div>
												</React.Fragment>
											)}

											<div className='p-hr p-p-0 p-mb-2'></div>
											<div className='info-text'>
												<div className='title'>Passport number:</div>
												<div className='content'>{passportDocument.docNumber ? passportDocument.docNumber : 'N/A'}</div>
											</div>
											<div className='info-text'>
												<div className='title'>Passport expired:</div>
												<div className='content'>{passportDocument.expiredDate ? moment(passportDocument.expiredDate).format('MM/YYYY') : 'N/A'}</div>
											</div>
											<div className='info-text'>
												<div className='title'></div>
												<div className='content'>
													<Button label='Update' className='p-button-info btn-text-sm' onClick={() => onUpdateIdentityDocument(IDENTITY_DOCUMENT_TYPE.passport.value, passportDocument)} />
												</div>
											</div>
											{msProduct.participantType !== PRODUCT_PARTICIPANT_TYPE.student.value && (
												<React.Fragment>
													<div className='p-hr p-p-0 p-mb-2'></div>
													<div className='info-text'>
														<div className='title'>FIN number:</div>
														<div className='content'>{finDocument.docNumber ? finDocument.docNumber : 'N/A'}</div>
													</div>
													<div className='info-text'>
														<div className='title'></div>
														<div className='content'>
															<Button label='Update' className='p-button-info btn-text-sm' onClick={() => onUpdateIdentityDocument(IDENTITY_DOCUMENT_TYPE.fin.value, finDocument)} />
														</div>
													</div>
												</React.Fragment>
											)}
											{!membership.verified && (
												<React.Fragment>
													<div className='p-hr p-p-0 p-mb-3'></div>
													<div className='p-grid'>
														<div className='p-col-12'>
															<div className='p-messages p-component p-messages-warn p-messages-enter-done'>
																<div className='p-messages-wrapper p-p-size-16'>
																	<span className='p-messages-icon pi pi-exclamation-triangle'></span>
																	<span className='p-messages-summary'>Document need to be verified</span>
																</div>
															</div>
														</div>
														<div className='p-col-12 p-c'>
															<Button icon='pi pi-check' className='p-button-success' label='Verify Document' onClick={() => handleVerifiedDocument()} />
														</div>
													</div>
												</React.Fragment>
											)}
										</Fieldset>
									</div>
								)}
								<div className={`p-col-12 ${msProduct.requiredIdentificationDocument ? 'p-md-4' : 'p-md-6'}`}>
									<Fieldset legend='AccountID'>
										<div className='info-text'>
											<div className='title'>Name:</div>
											<div className='content'>{owner.name}</div>
										</div>
										<div className='info-text'>
											<div className='title'>User name:</div>
											<div className='content'>{owner.username}</div>
										</div>
										<div className='info-text'>
											<div className='title'>Email:</div>
											<div className='content'>{owner.email}</div>
										</div>
										<div className='info-text'>
											<div className='title'>Phone:</div>
											<div className='content'>{owner.phone}</div>
										</div>
										<div className='info-text'>
											<div className='title'>Birthday:</div>
											<div className='content'>{owner.birthday && moment(owner.birthday).format(DATE_FORMAT_DISPLAY)}</div>
										</div>
										<div className='info-text'>
											<div className='title'>Gender:</div>
											<div className='content'>{owner.gender}</div>
										</div>
										<div className='info-text'>
											<div className='title'>Nationality:</div>
											<div className='content'>{owner.nationalityName}</div>
										</div>
										<div className='info-text'>
											<div className='title'>Address:</div>
											<div className='content'>
												{owner.primaryAddress &&
													[owner.primaryAddress.fullAddress, owner.primaryAddress.city, owner.primaryAddress.stateName, owner.primaryAddress.zipCode, owner.primaryAddress.countryName]
														.filter((item) => item)
														.join(', ')}
											</div>
										</div>
									</Fieldset>
									{membership.type === MEMBERSHIP_TYPE.Individual.value && (
										<Fieldset legend='Member Card'>
											{(() => {
												if (activeCard) {
													return (
														<React.Fragment>
															<div className='p-mb-1'>Code: {activeCard.code}</div>
															<div className='p-mb-1'>Number: {activeCard.number}</div>
															<div className='p-mb-2'>
																Status: <span className='status'>{MEMBERSHIP_CARD_STATUS[activeCard.status].label}</span>
															</div>
															{activeCard.status !== MEMBERSHIP_CARD_STATUS.cancel.value ? (
																<div className='p-mt-1'>
																	<Button
																		icon='pi pi-pencil'
																		label='Edit'
																		tooltip='Edit Member Card'
																		tooltipOptions={{ position: 'top' }}
																		className='p-button-info btn-text-sm p-mr-2'
																		onClick={() => onAddOrEditMembershipCard(member.id, activeCard)}
																	/>
																	<Button
																		icon='pi pi-times'
																		label='Cancel'
																		tooltip='Cancel Member Card'
																		tooltipOptions={{ position: 'top' }}
																		className='p-button-danger btn-text-sm p-mr-2'
																		onClick={() => changeMemberCardStatus(activeCard.id, MEMBERSHIP_CARD_STATUS.cancel.value)}
																	/>
																	{(() => {
																		if (activeCard.status === MEMBERSHIP_CARD_STATUS.not_printed.value)
																			return (
																				<Button
																					icon='pi pi-print'
																					label='Print'
																					tooltip='Print Member Card'
																					tooltipOptions={{ position: 'top' }}
																					className='p-button-warning btn-text-sm'
																					onClick={() => changeMemberCardStatus(activeCard.id, MEMBERSHIP_CARD_STATUS.printed.value)}
																				/>
																			);
																		else if (activeCard.status === MEMBERSHIP_CARD_STATUS.printed.value)
																			return (
																				<Button
																					icon='pi pi-send'
																					label='Assign'
																					tooltip='Assign Member Card'
																					tooltipOptions={{ position: 'top' }}
																					className='p-button-warning btn-text-sm'
																					onClick={() => changeMemberCardStatus(activeCard.id, MEMBERSHIP_CARD_STATUS.assigned.value)}
																				/>
																			);
																	})()}
																</div>
															) : (
																<div className='p-mt-1'>
																	<Button
																		icon='pi pi-plus'
																		label='Create another Card'
																		tooltip='Create another Card'
																		tooltipOptions={{ position: 'top' }}
																		className='p-button-info btn-text-sm'
																		onClick={() => onAddOrEditMembershipCard(member.id, null)}
																	/>
																</div>
															)}
														</React.Fragment>
													);
												} else {
													if (membership.status === MEMBERSHIP_STATUS.Active.value) {
														return (
															<div>
																<Button
																	icon='pi pi-plus'
																	label='Add Member Card'
																	tooltip='Create Member Card'
																	tooltipOptions={{ position: 'top' }}
																	className='p-button-info btn-text-sm'
																	onClick={() => onAddOrEditMembershipCard(member.id, null)}
																/>
															</div>
														);
													}
												}
											})()}
										</Fieldset>
									)}
								</div>
							</div>
						</TabPanel>
						<TabPanel
							header={membership.type === MEMBERSHIP_TYPE.Group.value ? 'Members' : ''}
							headerClassName={membership.type === MEMBERSHIP_TYPE.Individual.value ? 'p-hide' : ''}
							disabled={membership.type === MEMBERSHIP_TYPE.Individual.value}
						>
							<MembershipMemberListView membershipId={membership.id} />
						</TabPanel>
						<TabPanel header='Company'>
							<GroupForm ref={frmGroup} refreshData={(groupData) => setCurrentCompany(groupData)} />
							<MemberForm ref={frmCompanyMember} refreshTable={() => setLoadTableGroupMember(true)} />

							<Fieldset legend='Company Information'>
								<div className='p-grid p-fluid'>
									<div className='p-col-12 p-lg-4 p-md-6'>
										<label className='p-label'>Company: </label>
										<div className='p-inputgroup'>
											<Dropdown value={tmpCompanyId} options={companies} onChange={(e) => setTmpCompanyId(e.value)} />
											<Button label='Save' onClick={() => handleUpdateCompanyForMembership()} />
										</div>
									</div>
								</div>
							</Fieldset>
							{currentCompany && currentCompany.id && (
								<Fieldset legend='Members'>
									<div className='p-grid'>
										<div className='p-col-12'>
											<DataTable
												lazy={true}
												paginator={true}
												value={groupMembers}
												first={pageableGroupMember.page * pageableGroupMember.rows}
												rows={pageableGroupMember.rows}
												totalRecords={pageableGroupMember.total}
												onPage={onMemberPage}
												header={tblCompanyMemberHeader}
												selectionMode='single'
												responsive={true}
											>
												<Column header='Name' field='user.name' />
												<Column header='Email' field='user.email' />
												<Column header='Owner' body={actionSetOwnerTemplate} headerStyle={{ width: '10em' }} />
												<Column header='Actions' body={actionMemberTemplate} headerStyle={{ textAlign: 'center', width: '10em' }} />
											</DataTable>
										</div>
									</div>
								</Fieldset>
							)}
						</TabPanel>
					</TabView>
				</div>
			</div>
		)
	);
};
