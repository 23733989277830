import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import React, { forwardRef, useRef, useState } from 'react';
import { useImperativeHandle } from 'react';
import { ATTRIBUTE_GROUP_TYPE } from '../../constants';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { getListAttributeSets } from '../../scp/attribute/AttributeServices';
import { CustomFieldForm } from './CustomFieldForm';

export const ProductCustomFieldForm = forwardRef((props, ref) => {
    const customFieldForm = useRef(null);
    const deleteNotify = useRef(null);

    const [customSetId, setCustomSetId] = useState(null)
    const [customSetName, setCustomSetName] = useState('')
    const [numOfFieldDisplay, setNumOfFieldDisplay] = useState(1)
    const [customFieldSets, setCustomFieldSets] = useState([])
    const [dataList, setDataList] = useState([]);
    const [pageable, setPageable] = useState({
        page: 0, total: 0, rows: 10, sortField: '', sortOrder: 0
    })
    const [visible, setVisible] = useState(false)
    
    useImperativeHandle(ref, () => ({
        popularFormData: (data) => popularFormData(data),
        clearSetId: () => setCustomSetId(null)
    }));

    const popularFormData = (data) => {
        setDataList(data.customFields)
        setCustomSetName(data.customSetName?data.customSetName:'')
        setNumOfFieldDisplay(data.numOfFieldDisplay?data.numOfFieldDisplay:1)
        setVisible(true);
        setPageable({...pageable, page: 0})
        loadAllAttributeSet(ATTRIBUTE_GROUP_TYPE.custom_field.value)
    }

    
    const loadAllAttributeSet = (type) => {
        getListAttributeSets(type)
        .then(res => {
            switch (type) {
                case ATTRIBUTE_GROUP_TYPE.custom_field.value:
                    setCustomFieldSets(res)
                    break;
                default: break;
            }
        });
    }

    const onCustomSetChange = (e) => {
        let tmpItem = customFieldSets.find(item => item.value===e.value)
        if(tmpItem){
            setCustomSetName(tmpItem.label)
        }
        setCustomSetId(e.value)
    }

    const changeFieldSet = () => {
        if(props.onChangeFieldSet) {
            props.onChangeFieldSet(customSetId, customSetName, numOfFieldDisplay)
        }
    }

    const closeForm = () => {
        setDataList([]);
        setVisible(false);
        setPageable({...pageable, page: 0})
    }

    const actionTemplateSetField = (rowData, column) => {
        return (
            <div>
                <Button icon="pi-md-edit" className="p-button-primary" onClick={(e) => onAddOrEditField(rowData)} />
                <Button icon="pi-md-close" className="p-button-danger" onClick={(e) => deleteNotify.current.delete(rowData)} />
            </div>
        );
    }

    const handleDeleteSetField = (rowData) => {
        let tmpArr = dataList.filter(x => x.name !== rowData.name);

        if(props.onCustomFieldChange) {
            props.onCustomFieldChange(tmpArr, true);
        }

        setDataList(tmpArr);
    }

    const onAddOrEditField = (rowData) => {
        customFieldForm.current.popularFormData(rowData);
    }

    const handleFieldUpdate = (field) => {
        let data = {
            ...field.attributeField,
            ...field
        }

        delete data.attributeField;

        let customProperties = dataList.filter(x => x.name !== data.name)
        customProperties.push(data)
        
        if(props.onCustomFieldChange) {
            props.onCustomFieldChange(customProperties);
        }

        setDataList(customProperties);

    }   

    const reloadTable = (res) => {
        if(props.reloadTable) {
            props.reloadTable(res);
        }
    }

    const header = 
        <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
            <Button icon="pi pi-plus" label={"Add new field"} className="p-button-constrast" style={{ float: "left" }} onClick={(e) => onAddOrEditField()} />
            <h3 className="fle-datatable-header-title">Current Custom Fields</h3>
        </div>

    const numOfFieldDisplayOptions = [
        {value: 1, label: '1'},
        {value: 2, label: '2'},
        {value: 3, label: '3'},
    ]

    return(
        <React.Fragment>
            <DeleteNotification ref={deleteNotify}
                accessDelete={(e) => handleDeleteSetField(e)}
            />
            <CustomFieldForm ref={customFieldForm} reloadTable={(res) => reloadTable(res)} productId={props.productId} onUpdateField={(field) => handleFieldUpdate(field)}/>
            <Sidebar visible={visible} position="right"  className="p-sidebar-lg p-sidebar-md" style={{ overflowY: "auto"}} onHide={closeForm}>
                <div className="p-grid p-margin-top-30">
                    <div className="p-col-12 p-grid">
                        <div className="p-col-10">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label className="p-label">Reset by Attribute Group</label>
                                    <Dropdown style={{width: '100%'}} value={customSetId} options={customFieldSets} onChange={(e) => onCustomSetChange(e)} placeholder="Choose another field set" />
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">Group Name (For display, leave blank will not display)</label>
                                    <InputText value={customSetName} onChange={e => setCustomSetName(e.target.value)} />
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">Num of field display (per row)</label>
                                    <Dropdown value={numOfFieldDisplay} options={numOfFieldDisplayOptions} onChange={(e) => setNumOfFieldDisplay(e.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-2">
                            <Button className="p-col-12" label="Apply" onClick={() => changeFieldSet()}></Button>
                        </div>
                    </div>
                    <div className="p-col-12">
                        <DataTable value={dataList} header={header} responsive={true}>
                            <Column header="Field Name" body={(rowData) => {return(`${rowData.label} (${rowData.name})`)}} />
                            <Column header="Field Type" body={(rowData) => rowData.type} />
                            <Column header="Weight" field="weight" />
                            <Column header="Required" body={(rowData) => rowData.required ? 'Yes' : 'No'} />
                            <Column header="" body={actionTemplateSetField} style={{textAlign:'right',width:'8em'}}/>
                        </DataTable>
                    </div>
                    <div className="p-col-12">
                        <Button label="Close" onClick={closeForm}></Button>
                    </div>
                </div>
        </Sidebar>
        </React.Fragment>
    )
})