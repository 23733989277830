import axios from 'axios';
import { createBrowserHistory } from 'history';
import { whitelist } from '../security/config';
import { logOut } from '../security/auth';
import { refreshToken } from '../../scp/login/AuthServices';
import { API_BASE_URL, TENANT_ID, USER_ID, USER_NAME } from '../../constants';
import { showNotification } from '../service/NotificationService';
import KcUserService from '../../service/KcUserService';

const history = createBrowserHistory();
let isRefreshingToken = false;
let subscribers = [];

//Insert TOKEN To Request
axios.interceptors.request.use((config) => {
        const basePath = getBasePath(config.url)
        config.headers.Authorization = `Bearer ${KcUserService.getToken()}`
        if(TENANT_ID) config.headers.tenantId = TENANT_ID
        if(USER_ID) config.headers.userId = USER_ID
        if(USER_NAME) config.headers.userName = USER_NAME
        return config
    }, function (error) {
        return Promise.reject(error)
    }
);

//General Process All Response
// axios.interceptors.response.use((response) => {
//         return response;
//     }, function (error) {
//         let res = error.response;
//         if (res) {
//             let responseURL = res.request.responseURL;
//             switch(res.status) {
//                 case 500:
//                     if(responseURL.indexOf('api/programs'))
//                         showNotification('error', 'Error Message', res.data.errorMessage);
//                     else history.push('/500');
//                     break;
//                 case 400:
//                     return res;
//                 // case 404:
//                 //     history.push('/404');
//                 //     break;
//                 case 401:
//                     // try to refresh token then try again one more time
//                     const originalRequest = error.config;
//                     if (!isRefreshingToken) {
//                         isRefreshingToken = true;
//                         return refreshToken().then((res) => {
//                             error.config.headers.Authentication = `Bearer ${res.access_token}` // set new token
//                             isRefreshingToken = false;
//                             onAccessTokenFetched(res.access_token);
//                             return axios.request(error.config); // retry request
//                         }).catch(err => {
//                             if (err.response.status === 401) {
//                                 // logOut();
//                                 // history.push('/login');
//                                 // isRefreshingToken = false;

//                                 KcUserService.doLogout()
//                                 // localStorage.setItem('access_token', KcUserService.getToken())
//                             }
//                         });
//                     } else {
//                         subscribers.push(accessToken => {
//                             originalRequest.headers.Authorization = 'Bearer ' + accessToken;
//                             axios.request(originalRequest);
//                         });
//                     }
//                     break;
//                 default:
//             }
//         }
//         return Promise.reject(error);
//     }
// );

// function onAccessTokenFetched(accessToken) {
//     subscribers.forEach(callback => callback(accessToken));
//     subscribers = [];
// }

function getBasePath(url) {
    let urlPath = url.replace(API_BASE_URL, '');
    if (urlPath.startsWith('/')) {
        urlPath = urlPath.slice(1);
    }
    const parts = urlPath.split('/');
    return parts.length > 0 ? parts[0] : '';
}