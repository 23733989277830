import {
  ANSWER_REQUESTING,
  ANSWER_REQUEST_SUCCESS,
  ANSWER_REQUEST_FAILED,
  ANSWER_DETAIL_REQUESTING,
  ANSWER_DETAIL_REQUEST_SUCCESS,
  ANSWER_DETAIL_REQUEST_FAILED
} from '../constants/answer';

const initialState = {
  data: [],
  requesting: false,
  success: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ANSWER_REQUESTING:
      return {
        data: [],
        requesting: true,
        success: false,
        error: null
      };
    case ANSWER_REQUEST_SUCCESS:
      return {
        data: action.data,
        requesting: false,
        success: true,
        error: null
      };
    case ANSWER_REQUEST_FAILED:
      return {
        data: [],
        requesting: false,
        success: false,
        error: action.error
      };
    case ANSWER_DETAIL_REQUESTING:
      return {
        data: [],
        requesting: true,
        success: false,
        error: null
      };
    case ANSWER_DETAIL_REQUEST_SUCCESS:
      return {
        data: action.data,
        requesting: false,
        success: true,
        error: null
      };
    case ANSWER_DETAIL_REQUEST_FAILED:
      return {
        data: [],
        requesting: false,
        success: false,
        error: action.error
      };
    default:
      return state;
  }
};