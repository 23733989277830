import axios from "axios";
import { FORM_BASE_URL } from '../../../constants';

export async function getAnswers(formId) {
  const res = await axios.get(`${FORM_BASE_URL}/forms/${formId}/form-instances`);
  return res.data;
}

export async function getAnswer(id) {
  const res = await axios.get(`${FORM_BASE_URL}/form-instances/${id}`);
  return res.data;
}

export async function getWorkflowOfFormIntance(id) {
  const res = await axios.get(`${FORM_BASE_URL}/form-instances/${id}/workflow/`);
  return res.data;
}

export async function completeForm(id) {
  const res = await axios.put(`${FORM_BASE_URL}/form-instances/${id}/completed`);
  return res.data;
}

export async function markStepDone(id, step) {
  const res = await axios.put(`${FORM_BASE_URL}/form-instances/${id}/workflow/${step}/done`);
  return res.data;
}

export async function markCheckpointDone(formInstanceId, checkpointId) {
  const res = await axios.put(`${FORM_BASE_URL}/form-instances/${formInstanceId}/checkpoint/${checkpointId}`);
  return res.data;
}

export async function exchange(request) {
  const res = await axios.request(request);
  return res.data;
}