import React from "react";
import moment from "moment";
import { components } from 'react-select';
import Avatar from 'react-avatar';
import { USER_RELATIONSHIP_TYPES } from "../../../constants";

function SelectOption(props){
    return (
        <components.Option {...props} >
            <div style={{position: 'relative', minHeight: 60}}>
                {props.data.avatar ? 
                    <img src={props.data.avatar} width={60} style={{position: 'absolute', top: 1}} />
                    : <Avatar maxInitials={2} round={true} textSizeRatio={2} size={60} name={props.data.name} style={{margin: 0, position: 'absolute', top: 1}} />
                }
                <div className="p-grid" style={{marginLeft: 80}}>
                    <div className="p-col-12 p-padding-0-5">{props.data.label}</div>
                    <div className="p-col-12 p-padding-0-5">{props.data.email}</div>
                    <div className="p-col-12 p-padding-0-5">{props.data.birthday ? moment(props.data.birthday).format('MM-DD-YYYY') : ''}</div>
                    {props.data.relation ?
                        <div>{USER_RELATIONSHIP_TYPES.find(relation => relation.value === props.data.relation).label}</div>
                        : ''
                    }
                    {/* {props.data.membershipId ? 
                        <>
                            <div className="p-col-12 p-md-7 p-padding-0-5">Membership Type: {props.data.membershipType}</div>
                            <div className="p-col-12 p-md-5 p-padding-0-5">Status: {props.data.membershipStatus}</div>
                            <div className="p-col-12 p-md-7 p-padding-0-5">Membership Start: {props.data.membershipStart ? moment(props.data.membershipStart).format('MM-DD-YYYY') : ''}</div>
                            <div className="p-col-12 p-md-5 p-padding-0-5">End: {props.data.membershipEnd  ? moment(props.data.membershipEnd).format('MM-DD-YYYY') : ''}</div>
                        </>
                        : ''
                    } */}
                </div>
            </div>
        </components.Option>
    );
}

export default SelectOption