import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useRef, useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { APP_FEP, ASSET_STATUS, TAXONOMY_TYPE } from '../../constants';
import { getTablePageReport } from '../../core/service/CommonService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { AssetSchedules } from '../asset-schedule/AssetSchedules';
import { AssetForm } from '../asset/AssetForm';
import { getPageAssets, removeAsset } from '../asset/AssetServices';
import { getInventory } from './InventoryService';
import InventoryForm from './InventoryForm';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import classNames from 'classnames';
import { getListTaxons } from '../taxon/TaxonServices';

export const InventoryDetailView = () => {
    const match = useRouteMatch()
    const history = useHistory()

    const frmInventory = useRef(null)
    const frmAsset = useRef(null)

    const [inventory, setInventory] = useState({})

    const [filter, setFilter] = useState({ application: APP_FEP})
    const [categories, setCategories] = useState([])
    const [assets, setAssets] = useState([])
    const [pageable, setPageable] = useState({
        page: 0, total: 0, rows: 10, sortField: '', sortOrder: -1
    });
    const [isLoadAssetTable, setLoadAssetTable] = useState(false)

    useEffect(() => {
        loadInventory();
    }, [match.params.id])

    useEffect(() => {
        if(isLoadAssetTable)
            loadPageAssets();
    }, [isLoadAssetTable])

    const loadInventory = () => {
        getInventory(match.params.id)
        .then(res => {
            if(!res.errorCode) {
                setInventory(res);
                setFilter({
                    ...filter,
                    inventoryId: res ? res.id : ''
                });
                setLoadAssetTable(true)
                loadCategories()
            }
        })
    }

    const loadPageAssets = () => {
        showloading()
        getPageAssets(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            stoploading();
            setAssets(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                loading: false,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadAssetTable(false)
        }).finally(stoploading())
    };

    const loadCategories = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.asset.value]}, true)
        .then(res => setCategories(res))
    }

    const onPage = (e) =>{
        setPageable({...pageable,
            loading:true,
            page: e.page
        })
        setLoadAssetTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadAssetTable(true);
    }

    const deleteAsset = (id) => {
        showConfirmNotify({
            accept: () => handleRemoveAsset(id)
        })
    }

    const handleRemoveAsset = (id) => {
        removeAsset(id).then(res => {
            if (!res.errorCode) {
                setLoadAssetTable(true);
                showSuccessNotify('Successfully Deleted');
            } else {
                showErrorNotify('Cannot perform action');
            }
        });
    }

    const actionTemplate = (rowData) => {
        let dropActions = [
            { label: 'Edit Asset', icon: 'pi-md-pencil',
                command: () => frmAsset.current.openForm(rowData)
            },
            { label: 'Clone Asset', icon: 'pi-md-content-copy',
                command: () => frmAsset.current.openForm(rowData, true, inventory)
            },
            { label: 'Delete Asset', icon: 'pi-md-trash',
                command: () => deleteAsset(rowData.id)
            }
        ];
        return (
            <SplitButton label="View" icon="pi pi-search" className="p-button-constrast p-l" model={dropActions} onClick={() => history.push(`/assets-mgt/${rowData.id}`)}/>
        );
    };

    const onAddOrEdit = (data) => {
        frmAsset.current.openForm(data, false, inventory)
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadAssetTable(true);
    };

    const applyFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setLoadAssetTable(true)
    }

    const clearFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setFilter({
            app: APP_FEP,
            name: '',
            inventoryId: match.params.id,
            categoryId: null
        })
        setLoadAssetTable(true)
    }

    const tblHeader = (
        <TableHeader title="Facilities" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={
                <Button className="p-button-success" label="Create Facility" icon="pi pi-plus" onClick={() => onAddOrEdit()}/>}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadAssetTable(true)}
            />
    );


    return(
        <div className="p-grid">
            <div>
                <BreadcrumbsItem to={`/inventories`}>Inventories</BreadcrumbsItem>
                <BreadcrumbsItem to={match.url}>{inventory.name}</BreadcrumbsItem>
            </div>       

            <InventoryForm ref={frmInventory}
                refresh={(e) => setInventory(e)}
            />

            <AssetForm ref={frmAsset}
                reloadTable={() => setLoadAssetTable(true)}
            />
            
            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1>
                        {inventory.code} - {inventory.name}
                        <div className="p-toolbar-group-right">
                            <Link to={`/inventories`}>
                                <Button icon="pi pi-list" className="p-button-icon" tooltip="Back To List" tooltipOptions={{position: 'top'}}></Button>
                            </Link>
                            <Button icon="pi pi-pencil" className="p-button-icon" tooltip="Edit Inventory" tooltipOptions={{position: 'top'}} onClick={() => frmInventory.current.openForm(inventory)}></Button>
                        </div>
                    </h1>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-3">Branch: {inventory.branch && inventory.branch.name}</div>
                        <div className="p-col-12 p-md-3">Status: <span className={classNames('status', {'active': inventory.active, 'inactive': !inventory.active})}>{inventory.active?'Active':'Inactive'}</span></div>
                    </div>
                </div>
            </div>
            <div className="p-col-12">
                <TabView>
                    <TabPanel header={'Assets'} leftIcon={'pi pi-sitemap'}>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <Fieldset legend="Filters">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Name:</span>
                                                <InputText value={filter.name} onChange={(e) => setFilter({...filter, name: e.target.value})} />
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            <div className="p-inputgroup">
                                                <span className="p-inputgroup-addon">Category:</span>
                                                <Dropdown value={filter.categoryId} options={categories} showClear onChange={(e) => setFilter({...filter, categoryId: e.value})} />
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-c">
                                            <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => applyFilter()} style={{width: 'auto'}} />
                                            <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={()=>clearFilter()} style={{width: 'auto'}} />
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>

                            <div className="p-col-12">
                                <DataTable emptyMessage="There's no record found" header={tblHeader} lazy={true} paginator={true}
                                    value={assets}
                                    first={pageable.page * pageable.rows}
                                    onPage={onPage} onSort={onSort}
                                    rows={pageable.rows} totalRecords={pageable.total}
                                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                                    responsive={true}
                                >
                                    <Column field="code" header="Code" />
                                    <Column field="name" header="Name" />
                                    <Column header="Category" body={rowData => rowData.category && rowData.category.name} />
                                    <Column header="Current Inventory" body={rowData => rowData.currentInventory && rowData.currentInventory.name} />
                                    <Column header="Status" field="status" headerStyle={{width: '8em'}} bodyClassName="p-c" body={(rowData) => <span className={classNames('status', {'active': !rowData.status || rowData.status===ASSET_STATUS.in_stock.value, 'inactive': rowData.status===ASSET_STATUS.out_stock.value})}>{ASSET_STATUS[rowData.status]?ASSET_STATUS[rowData.status].label:ASSET_STATUS.in_stock.label}</span>} />
                                    <Column field="active" header="Active" headerStyle={{width: '8em'}} bodyClassName="p-c" body={(rowData) => <span className={classNames('status', {'active': rowData.active, 'inactive': !rowData.active})}>{rowData.active?'Active':'Inactive'}</span>} />
                                    <Column header="Actions" body={actionTemplate} className='tc-actions' headerStyle={{width: '12em'}} bodyStyle={{ overlay: 'visible' }} />
                                </DataTable>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel header="Schedules" leftIcon="pi pi-calendar" >
                        <AssetSchedules branchId={inventory.branch?inventory.branch.id:null} inventoryId={match.params.id} refresh={() => setLoadAssetTable(true)}/>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )                                                       
}