import axios from "axios";
import { API_BASE_URL, SCP_PATH } from "../../constants";
const SERVICE_BASE_URL = API_BASE_URL + SCP_PATH; 

export function getPageDoors(page, size, sortField, sortOrder){
  const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
  const sort = sortField ? `${sortField},${order}`: "";
  return axios.get(`${SERVICE_BASE_URL}/doors/page`,{
      params: {page, size, sort}
  })
  .then(res => res.data).catch(error => console.log(error));
}

export function getListDoors(filter, simple=true) {
  return axios.get(`${SERVICE_BASE_URL}/doors/list`, {params: {...filter, simple}}
  ).then(res => res.data).catch(error => console.log(error));
}

export function saveDoor(data){
  return axios.post(`${SERVICE_BASE_URL}/doors`, data).then(res => res.data).catch(error => console.log(error));
}

export function removeDoor(id){
  return axios.delete(`${SERVICE_BASE_URL}/doors/${id}`).then(res => res.data).catch(error => console.log(error));
}