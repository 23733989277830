import {
  FORM_REQUESTING,
  FORM_REQUEST_SUCCESS,
  FORM_REQUEST_FAILED
} from '../constants/form';

const initialState = {
  data: [],
  requesting: false,
  success: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FORM_REQUESTING:
      return {
        data: [],
        requesting: true,
        success: false,
        error: null
      };
    case FORM_REQUEST_SUCCESS:
      return {
        data: action.data,
        requesting: false,
        success: true,
        error: null
      };
    case FORM_REQUEST_FAILED:
      return {
        data: [],
        requesting: false,
        success: false,
        error: action.error
      };
    default:
      return state;
  }
};

