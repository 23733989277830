import axios from "axios";
import { FORM_BASE_URL } from '../../../constants';

export async function getFormList(category) {
  const res = await axios.get(`${FORM_BASE_URL}/forms`, {
    params: {
      category: category
    }
  });
  return res.data;
}
