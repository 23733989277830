import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { AttendanceListViewForm } from './AttendanceListViewForm';
import { getListParticipants } from '../../constituent-management/participant/ParticipantServices';
import moment from 'moment';
import { DATE_FORMAT_DISPLAY, TIME_FORMAT_DISPLAY, TIME_SERIES_REPEAT_TYPE } from '../../constants';

export const ParticipantAttendanceForm = forwardRef((props, ref) => {
	const [header, setHeader] = useState('View Participant');
	const [visible, setVisible] = useState(false);
	const [user, setUser] = useState({});
	const [joinedCourses, setJoinedCourses] = useState([]);

	const attendanceListViewForm = useRef(null);

	useImperativeHandle(ref, () => ({
		async openForm(data) {
			setUser(data.user);
			setJoinedCourses(data.joinedCourses);
			setVisible(true);
		},
		closeForm() {
			closeForm();
		},
	}));

	const closeForm = () => {
		setVisible(false);
	};

	const reloadParticipants = async () => {
		const filter = {
			userId: user.id,
			isCourseProgram: true,
			statuses: ['completed'],
		};

		const participants = await getListParticipants(filter, true);

		setJoinedCourses(participants);
	};

	const tblHeader = <TableHeader title='Courses' showLength={false} showRefresh={false} />;

	return (
		<React.Fragment>
			<AttendanceListViewForm
				ref={attendanceListViewForm}
				onRefresh={() => {
					reloadParticipants();
					if (props.onRefresh) props.onRefresh();
				}}
				userId={user?.id}
			/>

			<Sidebar visible={visible} style={{ overflowY: 'auto' }} className='p-sidebar-lg p-sidebar-md' position='right' blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
				<div className='p-d-flex p-justify-between '>
					<h2 className='p-margin-top-10'>{header}</h2>
					<Button label='' icon='pi pi-times' className='p-button-secondary' onClick={closeForm} />
				</div>

				<div className='p-sidebar-line p-mb-3'></div>

				<div className='p-grid'>
					<div className='p-col-12'>
						<Fieldset legend='User Information'>
							<div className='p-grid '>
								<div className='p-col-12'>Name: {user.name}</div>
								<div className='p-col-12'>Email: {user.email}</div>
								<div className='p-col-12'>Phone: {user.phone}</div>
							</div>
						</Fieldset>
					</div>
					<div className='p-col-12'>
						<DataTable emptyMessage="There's no record found" header={tblHeader} value={joinedCourses} responsive={true}>
							<Column header='Course' body={(rowData) => rowData.refName} />
							<Column
								header='Session'
								body={(rowData) => {
									let tmpTimeArr = [];
									if (rowData.availability) {
										for (const tmpSchedule of rowData.availability.objSchedules) {
											let tmpItem = { startTime: tmpSchedule.startTime, endTime: tmpSchedule.endTime };
											if (!tmpTimeArr.some((tmp) => tmp.startTime === tmpSchedule.startTime && tmp.endTime === tmpSchedule.endTime)) {
												tmpTimeArr.push(tmpItem);
											}
											tmpTimeArr.sort(function (a, b) {
												if (a.startTime < b.startTime) return -1;
												else return 1;
											});
										}
									}

									return (
										<React.Fragment>
											<div>{rowData.availability?.name}</div>
											<div className='p-margin-top-5'>
												{rowData.availability && (
													<React.Fragment>
														{rowData.availability.startDate === rowData.availability.endDate
															? moment(rowData.availability.startDate).format(DATE_FORMAT_DISPLAY)
															: `${moment(rowData.availability.startDate).format(DATE_FORMAT_DISPLAY)} - ${moment(rowData.availability.endDate).format(DATE_FORMAT_DISPLAY)}`}
													</React.Fragment>
												)}
											</div>
											{rowData.availability?.repeatType === TIME_SERIES_REPEAT_TYPE.weekDay && (
												<div className='p-margin-top-5'>{rowData.availability.schedules.map((d) => d.toUpperCase()).join(', ')}</div>
											)}
											{tmpTimeArr.length > 0 && (
												<div className='p-margin-top-5'>
													{tmpTimeArr
														.map((tmpTime) => `${moment(tmpTime.startTime, 'HH:mm').format(TIME_FORMAT_DISPLAY)} - ${moment(tmpTime.endTime, 'HH:mm').format(TIME_FORMAT_DISPLAY)}`)
														.join(', ')}
												</div>
											)}
										</React.Fragment>
									);
								}}
							/>
							<Column header='Status' style={{ width: '10em' }} body={(rowData) => (rowData.completedParticipation ? 'Completed' : 'Incomplete')} />
							<Column
								header=''
								style={{ width: '12em', textAlign: 'right' }}
								body={(rowData) => {
									return <Button label='View Attendance' className='btn-text-sm' onClick={() => attendanceListViewForm.current.openForm(rowData)} />;
								}}
							/>
						</DataTable>
					</div>
				</div>

				<div className='p-sidebar-line p-my-3'></div>

				<div className='p-grid'>
					<div className='p-col-12 p-d-flex p-justify-between'>
						<Button label='Close' icon='pi-md-close' className='p-button-secondary' onClick={closeForm} />
					</div>
				</div>
			</Sidebar>
		</React.Fragment>
	);
});
