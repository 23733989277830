import React, { useEffect, useRef, useState } from 'react';
import moment from "moment";
import { Link, useRouteMatch } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Button } from 'primereact/button';
import { getPageUploads } from '../tool-util/upload/UploadServices';
import { getApplicationByAlias, importSupportCaseApplicationRecord } from './ApplicationService';
import { dowloadFileImportErrors } from '../tool-util/download/DownloadServices';
import { OBJECT_TYPE } from '../../constants';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';

const fileDownload = require('js-file-download');

export const ImportSupportCaseApplication = (props) =>{
    const match = useRouteMatch()

    const [dataList, setDataList] = useState([])
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: -1
    })
    const [supportCaseApplication, setSupportCaseApplication] = useState({})
    const [file, setFile] = useState(null)
    const [errors, setErrors] = useState({})
    
    const [isLoadTable, setLoadTable] = useState(false);

    const inputUpload = useRef(null)

    useEffect(()=>{
        loadSupportCaseApplication()
    },[])

    useEffect(()=>{
        if(isLoadTable)
            loadPageUpload()
    },[isLoadTable])

    const loadSupportCaseApplication = () => {
        getApplicationByAlias('support-case-application')
        .then(res => {
            if(!res.errorCode){
                setSupportCaseApplication(res?res:{})
                setLoadTable(true)
            }else{
                showErrorNotify("No Support Case application")
            }
        })
    }

    const loadPageUpload = () => {
        if(!supportCaseApplication.id){
            showErrorNotify("No Support Case application")
            return
        }
        getPageUploads({type: OBJECT_TYPE.form_record, objectId: supportCaseApplication.id}, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const handleUpload = () => {
        if(!supportCaseApplication || Object.keys(supportCaseApplication).length===0){
            showErrorNotify('No Support Case Application')
            return
        }
        setErrors({})

        showloading();

        importSupportCaseApplicationRecord(file)
        .then(res => {
            stoploading();
            if(!res.errorCode){
                if(inputUpload && inputUpload.current){
                    inputUpload.current.value=''
                }
                setFile(null)
                setLoadTable(true)
                showSuccessNotify('Action Submitted');
            }else{
                showErrorNotify('Cannot perform action');
                if(res.errorCode===400) setErrors(res.errorObj);
            }
        }).finally(() => stoploading())
    }

    const handleDownloadFileError = (data) => {
        dowloadFileImportErrors(data.id, OBJECT_TYPE.form_record)
        .then(res => {
            if(!res){
                showErrorNotify('No File Found')          
            } else {
                fileDownload(res, data.path);
            }
        });
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const tblHeader = (
        <TableHeader title="Import Support Case" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
        />
    );

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Import Support Case</BreadcrumbsItem>

            <div className="p-col-12">
                <div className="p-padding-10-0">
                    <input ref={inputUpload} type="file" className="p-margin-bottom-10"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e) => setFile(e.target.files[0])}
                    />
                    <Button label="Import" icon="pi pi-upload" onClick={()=> handleUpload()} />
                    <div className="p-form-error">{errors.file}</div>
                </div>

                <DataTable emptyMessage="There's no record found" header={tblHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column header="Creator" 
                        body={(rowData) => {
                            return(
                                <Link to={`/constituent-mgt/contacts/${rowData.user.id}`} className="nav-link">
                                    {rowData.user.name}
                                </Link>
                            );
                        }}
                    />
                    <Column field="rows" header="No.Rows" />
                    <Column field="rowErrors" header="No.Error" />
                    <Column header="Import On" body={(rowData) => <span>{moment(rowData.created).format("DD/MM/YYYY hh:mm A")}</span>} />
                    <Column header="Download Error" headerStyle={{width: '12em'}} 
                        body={(rowData) => {
                            return <Button icon="pi pi-download" onClick={() => handleDownloadFileError(rowData)} />
                        }}
                    />
                </DataTable>
            </div>
        </div>
    )
} 