import React, { forwardRef, useState, useImperativeHandle } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Sidebar } from "primereact/sidebar";
import {
  showSuccessNotify,
  showErrorNotify,
} from "../../core/service/NotificationService";
import { saveMembershipCategory } from "./MembershipCategoryService";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

let schema = yup.object().shape({
  code: yup.string().required("Please input code!"),
  name: yup.string().required("Please input name!"),
  description: yup.string(),
});

export const MembershipCategoryForm = forwardRef((props, ref) => {
  const [header, setHeader] = useState("New Membership Category");
  const [visible, setVisible] = useState(false);

  const {
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // console.log(errors);

  useImperativeHandle(ref, () => ({
    openForm(data) {
      setHeader((data ? "Edit" : "New") + " Membership Category");
      setVisible(true);
      initFormData(data);
    },
    closeForm() {
      closeForm();
    },
  }));

  const closeForm = () => {
    setVisible(false);
    reset({});
    clearErrors();
  };

  const initFormData = (data) => {
    const formatData = {
      id: data ? data.id : null,
      name: data?.name ? data.name : "",
      code: data?.code ? data.code : "",
      description: data?.description ? data.description : "",
    };
    reset(formatData);
  };

  const handleSaveMembershipCategory = (data) => {
    saveMembershipCategory(data).then((res) => {
      if (!res.errorCode) {
        if (props.refreshTable) props.refreshTable();

        closeForm();
        showSuccessNotify("Save membership category successfully!");
      } else {
        showErrorNotify("Cannot perform action");
      }
    });
    // console.log(data);
  };

  return (
    <Sidebar
      visible={visible}
      style={{ overflowY: "auto" }}
      className="p-sidebar-md"
      position="right"
      blockScroll={true}
      showCloseIcon={false}
      dismissable={true}
      onHide={closeForm}
    >
      <div className="p-d-flex p-justify-between ">
        <h2 className="p-margin-top-10">{header}</h2>
        <Button
          label=""
          icon="pi pi-times"
          className="p-button-secondary"
          onClick={closeForm}
        />
      </div>

      <div className="p-sidebar-line p-mb-3"></div>

      <form
        className="p-grid p-fluid form-group"
        onSubmit={handleSubmit(handleSaveMembershipCategory)}
      >
        <div className="p-col-12">
          <label className="p-label">* Code</label>
          <Controller
            name="code"
            control={control}
            defaultValue=""
            render={({ field }) => <InputText {...field} />}
          />
          <span className="p-form-error">{errors.code?.message}</span>
        </div>
        <div className="p-col-12">
          <label className="p-label">* Name</label>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => <InputText {...field} />}
          />
          <span className="p-form-error">{errors.name?.message}</span>
        </div>
        <div className="p-col-12">
          <label className="p-label">Description</label>
          <Controller
            name="description"
            control={control}
            defaultValue=""
            render={({ field }) => <InputTextarea {...field} rows={4} />}
          />
          <span className="p-form-error">{errors.description?.message}</span>
        </div>

        <div className="p-col-12 p-sidebar-line p-my-3"></div>

        <div className="p-col-12 p-d-flex p-justify-end">
          <Button label="Save" style={{ width: "6rem" }} type="submit"
            className="blue-btn"/>
          <Button
            label="Cancel"
            type="button"
            onClick={closeForm}
            style={{ width: "6rem"}}
            className="yellow-btn"
          />
        </div>
      </form>
    </Sidebar>
  );
});
