import moment from 'moment';
import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { APPLICATION_RECORD_STATUS, APPLICATION_TYPE, DATETIME_FORMAT_DISPLAY } from '../../constants';
import { ParticipantServices } from '../../constituent-management/participant/ParticipantServices';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { getTablePageReport } from '../../core/service/CommonService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showConfirmNotify, showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import ApplicationRecordForm from './ApplicationRecordForm';
import { deleteApplicationRecord, exportApplicationRecords, getApplications, getPageApplicationRecord } from './ApplicationService';

const fileDownload = require('js-file-download');
export default class ApplicationRecordListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            dataList: [],
            index: 0,
            page: 0,
            rows: 10,
            total: 0,
            sortField: 'id',
            sortOrder: -1,
            activeIndex: 0,
            loading: true,
            filter: {applicationId: props.location&&props.location.state?props.location.state.applicationId:null},

            applicationOptions: []
        };

        this.participantService = new ParticipantServices();
    }

    componentDidMount() {
        if(this.props.location.state) {
            this.setFilter()
        }

        this.loadPageApplicationRecords();
        this.loadApplicationOptions();
    }

    loadPageApplicationRecords = () => {
        this.setState({loading: true})
        getPageApplicationRecord(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
        .then(res => {
            this.setState({
                dataList: res.content,
                total: res.totalElements,
                loading: false,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            });
        })
    }

    loadApplicationOptions = () => {
        getApplications(null, true)
        .then(data => {
            this.setState({
                applicationOptions: data
            })
        })
    }

    handleRemove = (id) => {
        deleteApplicationRecord(id)
        .then(res=> {
            if(!res.errorCode){
                this.loadPageApplicationRecords()
                showSuccessNotify('Action submitted')
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    handleExport = () => {
        let suf = moment().format('YYYYMMDDHHmmss');
        let fileName = 'rpt_application_record_' + suf + '.xlsx';

        exportApplicationRecords(this.state.filter)
        .then(res=>{
            if (!res.errorCode) {
                fileDownload(res, fileName);
            } else {
                showErrorNotify(res.errorMessage);
            }
        })
    }

    setFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                applicationIds: [this.props.location.state.applicationId]
            }
        })
    }

    onChangePageLength = (e) => {
        this.setState({
            page: 0,
            rows: e
        }, () => this.loadPageApplicationRecords())
    }

    actionTemplate = (rowData) => {
        let items = [{label: 'Remove', icon: 'pi pi-trash', command: () => this.onRemoveRecord(rowData.id)}]
        return (
            <SplitButton label="View" icon="pi pi-search" className="p-button-constrast" model={items} onClick={() => this.applicationRecordForm.openForm(rowData.id)}/>
        );
    }; 
    
    onKeyPressEnter = (e) => {
        if(e.key === 'Enter'){
            this.loadPageApplicationRecords()
        }
    }

    applyFilter = () => {
        this.setState({
            page: 0
        }, () => this.loadPageApplicationRecords())
    }

    clearFilter = () => {
        this.setState({
            filter: {
                name: '',
                code: '',
                type: null,
                applicationIds: []
            }
        }, () => this.loadPageApplicationRecords())
    }

    onPage = (e) =>{
        this.setState({
            page: e.page
        }, () => this.loadPageApplicationRecords())
    }

    onSort = (e) => {
        this.setState({
            sortField: e.sortField,
            sortOrder: e.sortOrder
        }, () => this.loadPageApplicationRecords())
    }

    onRemoveRecord = (id) => {
        showConfirmNotify({
            accept: () => this.handleRemove(id)
        })
    }

    render() {
        let tblHeader = (
            <TableHeader title="Application Records" 
                pageReport={getTablePageReport(this.state.page, this.state.rows, this.state.total)}
                changePageLength={(e) => this.onChangePageLength(e)}
                refresh={() => this.loadPageApplicationRecords()}
                actionTemplate={<Button className="p-button-constrast" label="Export" icon="pi pi-download" disabled={this.state.dataList.length===0} onClick={() => this.handleExport()} />}
            />
        );
        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Application Records</BreadcrumbsItem>
                
                <ApplicationRecordForm ref={el => this.applicationRecordForm = el}
                    refreshTable={()=>this.loadPageApplicationRecords()}
                />

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <Fieldset legend="Filter" >
                            <div className="p-grid p-fluid">
                                {/* <div className="p-col-12 p-md-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Code: </span>
                                        <InputText value={this.state.filter.code} onChange={(e) => {this.setState({ filter: { ...this.state.filter, code: e.target.value } })}} />
                                    </div>
                                </div> */}
                                {/* <div className="p-col-12 p-md-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Type: </span>
                                        <Dropdown value={this.state.filter.type} options={APPLICATION_OPTIONS} onChange={(e) => {this.setState({ filter: { ...this.state.filter, type: e.value } })}} />
                                    </div>
                                </div> */}
                                <div className="p-col-12 p-md-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Application: </span>
                                        <Dropdown value={this.state.filter.applicationIds&&this.state.filter.applicationIds.length>0?this.state.filter.applicationIds[0]:null} options={this.state.applicationOptions} showClear onChange={(e) => {this.setState({ filter: { ...this.state.filter, applicationIds: [e.value] } })}} />
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Name: </span>
                                        <InputText value={this.state.filter.name} onChange={(e) => {this.setState({ filter: { ...this.state.filter, name: e.target.value } })}} placeholder="(name of the person who made the log)" />
                                    </div>
                                </div>
                            </div>
                            <div className="p-grid p-margin-top-10">
                                <div className="p-col-12" style={{ "textAlign": "center" }}>
                                    <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={this.applyFilter} />
                                    <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={this.clearFilter} />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                </div>

                <div className="p-col-12">
                    <DataTable  lazy={true} header={tblHeader} paginator={true}
                        value={this.state.dataList} loading={this.state.loading}
                        first={this.state.page * this.state.rows}
                        onPage={this.onPage} onSort={this.onSort}
                        rows={this.state.rows} totalRecords={this.state.total}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        responsive={true}
                    >
                        <Column field="id" header="ID" />
                        <Column field="application.name" header="Application" />
                        <Column header="Log by" body={(rowData) => rowData.user ? rowData.user.name : 'N/A'} />
                        <Column header="Description" 
                            body={rowData => {
                                if(rowData.application && rowData.application.ratingGroup){
                                    return 'Number of votes: ' + rowData.ratingNumber
                                }
                            }}
                        />
                        <Column field="status" header="Status" 
                            body={(rowData) => {
                                if(rowData.application&&(rowData.application.type===APPLICATION_TYPE.approval.value || rowData.application.type===APPLICATION_TYPE.answer.value)){
                                    return APPLICATION_RECORD_STATUS[rowData.status]?APPLICATION_RECORD_STATUS[rowData.status].label:APPLICATION_RECORD_STATUS.pending.label
                                }
                                return ''
                            }}
                        />
                        <Column field="date" header="Log on" body={(rowData) => rowData.date ? moment(rowData.date).format(DATETIME_FORMAT_DISPLAY) : 'No Date'}/>
                        <Column header="Actions" body={this.actionTemplate} className='tc-actions' style={{ textAlign: 'center', width: '12em', overlay: 'visible' }} />
                    </DataTable>
                    
                </div>
            </div>
        );
    }
}
