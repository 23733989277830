import axios from 'axios';
import {API_BASE_URL} from "../../../constants";

const CONFIG_FORMDATA_HEADER = {
    headers: { 'Content-Type': 'multipart/form-data' }
}
export class UploadServices {
    uploadFileCourse(file){ 
        const formData = new FormData();
		formData.append('file', file);	
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        return axios.post(`${API_BASE_URL}/scp/api/upload/courses`, formData, config).then(res => res).catch(error => console.log(error));
    }
}

export function getPageUploads(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.get(`${API_BASE_URL}/scp/api/upload/page`, {
        params: {...filter, page, size, sort}
    }).then(res => {
        return res.data
    }).catch(error => console.log(error));
}

export function uploadFileContact(data){
    const formData = new FormData();
    formData.append('file', data);

    return axios.post(`${API_BASE_URL}/scp/api/upload/contact`, formData, CONFIG_FORMDATA_HEADER)
    .then(res => res.data).catch(error => console.log(error));
}

export function uploadFileVolunteerApplication(data){
    const formData = new FormData();
    formData.append('file', data);

    return axios.post(`${API_BASE_URL}/scp/api/upload/application/volunteer`, formData, CONFIG_FORMDATA_HEADER)
    .then(res => res.data).catch(error => console.log(error));
}