import axios from 'axios';
import { API_BASE_URL, PIM_BASE_URL, PIM_PATH, SCP_PATH } from '../constants';

const PIM_AUDIT_LOG_URL = PIM_BASE_URL + PIM_PATH + '/audit-logs';
const SCP_AUDIT_LOG_URL = API_BASE_URL + SCP_PATH + '/audit-logs';

export class AuditLogServices {
	getPageAuditLogs(id, type, size = 10) {
		return axios
			.get(`${API_BASE_URL}/scp/api/audit/logs/${id}`, { params: { type, size } })
			.then((res) => res.data)
			.catch((error) => console.log(error));
	}

	getChangeDetails(id, version, type) {
		return axios
			.get(`${API_BASE_URL}/scp/api/audit/logs/${id}/version/${version}`, { params: { type } })
			.then((res) => res.data)
			.catch((error) => console.log(error));
	}
}

export function pimGetPageChangeLogs(objectType, filter, page, size, sortField, sortOrder) {
	const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
	const sort = sortField ? `${sortField},${order}` : '';
	return axios
		.post(`${PIM_AUDIT_LOG_URL}/page`, filter, {
			params: { page, size, sort, type: objectType },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function pimGetListChangeLogsByObject(objectId, objectType, size = 10) {
	return axios
		.get(`${PIM_AUDIT_LOG_URL}/${objectId}`, { params: { type: objectType, size } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function pimGetChangeLogDetails(objectId, objectType, version) {
	return axios
		.get(`${PIM_AUDIT_LOG_URL}/${objectId}/version/${version}`, { params: { type: objectType } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function scpGetListChangeLogs(objectType, filter, size = 100, isExtend = false) {
	return axios
		.post(`${SCP_AUDIT_LOG_URL}/list`, filter, {
			params: { type: objectType, size, isExtend },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function scpExportListChangeLog(objectType, filter) {
	return axios
		.post(`${SCP_AUDIT_LOG_URL}/export`, filter, { params: { type: objectType }, responseType: 'arraybuffer' })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function scpExportAllChangeLog(filter) {
	return axios
		.post(`${SCP_AUDIT_LOG_URL}/export-all`, filter, { responseType: 'arraybuffer' })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}
