import axios from 'axios';
import { CONFIGURATION_BASE_URL } from '../../constants';
import { converObjetToFormData } from '../../core/service/CommonService';
const configFormDataHeader = {
    headers: { 'Content-Type': 'multipart/form-data' }
}

export const loadPublicConfig = async() => {
    const configs = await axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/configs/public/fep`);
    const data = configs?configs.data:null;

    if(data){
        localStorage.setItem('appConfig',JSON.stringify(data));
        localStorage.setItem('pricingMode', data.pricingMode);
        localStorage.setItem('currency', data.currency);
        localStorage.setItem('timezone', data.timezone);
        localStorage.setItem('googleCaptchaKey', data.googleCaptchaKey);
        localStorage.setItem('useDonation', data.useDonation);
        localStorage.setItem('defaultCountry', data.defaultCountry);

        if(data.branchHours)
            localStorage.setItem('branchHours', JSON.stringify(data.branchHours));
        if(data.dayOffs&&data.dayOffs.length>0)
            localStorage.setItem('dayOffs', JSON.stringify(data.dayOffs));

        localStorage.setItem('timeBlockUnit', data.timeBlockUnit);
        localStorage.setItem('timeBlockValue', data.timeBlockValue);
    }

    return data;
}

export function getPaymentConfig(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/configs/public/fep/get-payment-config`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getConfig(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/scp`)
    .then(res => res.data).catch(error => console.log(error));
}

export function saveScpConfig(data, type){
    if(type==='pageConfig'){
        let formData = new FormData()

        const pageConfig = data.pageConfig

        for (var key in pageConfig) {
            converObjetToFormData(formData, key, pageConfig[key])
        }

        return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/scp/save-page-config`, formData, configFormDataHeader)
        .then(res => res.data).catch(error => console.log(error));
    }

    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/scp/save-config/${type}`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function getTimezones(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/timezone/list`)
    .then(res => res.data).catch(error => console.log(error));
}

//#region Printer
export function getAvailablePrinters(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/resources/printers`)
        .then(res => res.data)
}

export function printPDFDocument(docId, printerName){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/resources/print/user-doc`, {
        params: {docId, printerName}
    }).then(res => res)
}

//#region system
export function saveSystemConfig(data){
    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/system`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function saveTenantGeneralConfig(data){
    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/general`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function saveTenantPageSettingConfig(data){
    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/page-layout`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function saveTenantPaymentConfig(data){
    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/payment`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function getListGrades(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/grade/values`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getListClassificationValues(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/classification/values`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getListNewsletters(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/communication-preferences/values`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getPriorityLevelUse(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/priority-level`)
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion 

//#region program
export function saveProgramConfig(data){
    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/program`, data)
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion 

//#region operating hours
export function saveOperatingHourConfig(branchId, data){
    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/scp/operating-hours`, data, {params: {branchId}})
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion 

//#region document
export function saveDocumentConfig(data){
    return axios.post(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/document`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function getListDocumentType(){
    return axios.get(`${CONFIGURATION_BASE_URL}/configuration/tenant/config/document/type/values`)
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion 
