import axios from "axios";
import {API_BASE_URL} from "../../../constants";

export class RoleServices{

    getPageRoles(page, size, sortField, sortOrder, deptId){
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/acl/roles/page`, {
            params: {deptId, page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }

    getRole(id){
        return axios.get(`${API_BASE_URL}/scp/api/acl/roles/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }

    saveRole(form){
        return axios.post(`${API_BASE_URL}/scp/api/acl/roles`, form)
            .then(res => res.data).catch(error => console.log(error))
    }

    updateInfo(id, form){
        return axios.put(`${API_BASE_URL}/scp/api/acl/roles/${id}`, form)
            .then(res => res.data).catch(error => console.log(error))
    }

    removeRole(id){
        return axios.delete(`${API_BASE_URL}/scp/api/acl/roles/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }
    getListSimpoleRoles(deptId){
        return axios.get(`${API_BASE_URL}/scp/api/acl/roles/list`,{ params: {deptId}})
        .then(res => res.data).catch(error => console.log(error));
    }

    getPermissions(){
        return axios.get(`${API_BASE_URL}/scp/api/acl/roles/permissions`)
            .then(res => res.data).catch(error => console.log(error));
    }

    savePermissions(id, form){
        return axios.post(`${API_BASE_URL}/scp/api/acl/roles/${id}/permissions`, form)
            .then(res => res.data).catch(error => console.log(error))
    }

    getPermissionGroups(type){
        return axios.get(`${API_BASE_URL}/scp/api/acl/roles/groups`, {params: {type: type}})
            .then(res => res.data).catch(error => console.log(error));
    }

    getTreePermissions() {
        return axios.get(`${API_BASE_URL}/scp/api/access/permissions/tree`).then(res => res.data).catch(error => console.log(error));
    }

}