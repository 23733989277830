import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { generatingCoupon, getListPromotionRules } from './PromotionService';
import { Fieldset } from 'primereact/fieldset';
import moment from 'moment';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { APP_FEP, PROMOTION_COUPON_TYPE, PRODUCT_CLASSIFICATION } from '../../constants';
import Select from 'react-dropdown-select';
import { getListProducts } from '../product/ProductServices';

export const GeneratingCouponForm = forwardRef((props, ref) => {
	const [header, setHeader] = useState('Generate Coupons');
	const [visible, setVisible] = useState(false);
	const [frmData, setFrmData] = useState({});
	const [startDateValue, setStartDateValue] = useState(null);
	const [endDateValue, setEndDateValue] = useState(null);
	const [minDate, setMinDate] = useState(null);
	const [maxDate, setMaxDate] = useState(null);
	const [products, setProducts] = useState([]);
	const [rules, setRules] = useState([]);
	const [errors, setErrors] = useState({});

	useImperativeHandle(ref, () => ({
		openForm(type) {
			if (type === PROMOTION_COUPON_TYPE.coupon.value) {
				loadProducts();
			} else if (type === PROMOTION_COUPON_TYPE.voucher.value) {
				loadPromotionRules();
			}

			setFrmData({ promotionId: props.promotionId, type: type, active: true });
			setVisible(true);
		},
		closeForm() {
			closeForm();
		},
	}));

	const loadProducts = () => {
		getListProducts({ app: APP_FEP, classifications: [PRODUCT_CLASSIFICATION.simple.value, PRODUCT_CLASSIFICATION.variable.value], active: true }, false).then((res) => {
			setProducts(res ? res : []);
		});
	};

	const loadPromotionRules = () => {
		getListPromotionRules({ promotionId: props.promotionId }).then((res) => setRules(res ?? []));
	};

	const handleGenerating = () => {
		setErrors({});
		generatingCoupon(frmData).then((res) => {
			if (!res.errorCode) {
				if (props.onRefresh) {
					props.onRefresh();
				}
				showSuccessNotify('Action submitted');
				closeForm();
			} else {
				showErrorNotify('Cannot perform action');
				if (res.errorCode === 400) setErrors(res.errorObj);
			}
		});
	};

	const onStartDateChange = (e) => {
		let tmpMaxDate = maxDate ? moment(maxDate) : '';
		let minDateToCompare = moment(e.value).isValid() ? moment(e.value) : '';
		let flag = minDateToCompare && tmpMaxDate && minDateToCompare.isAfter(tmpMaxDate);

		setFrmData({ ...frmData, validFrom: minDateToCompare ? minDateToCompare.format('YYYY-MM-DD HH:mm') : '', validTo: !flag ? frmData.validTo : '' });
		setStartDateValue(e.value);
		setMinDate(moment(e.value).isValid() ? e.value : '');
		setEndDateValue(!flag ? endDateValue : '');
		setMaxDate(!flag ? maxDate : '');
	};

	const onEndDateChange = (e) => {
		let tmpMinDate = minDate ? moment(minDate) : '';
		let maxDateToCompare = moment(e.value).isValid() ? moment(e.value) : '';
		let flag = maxDateToCompare && tmpMinDate && maxDateToCompare.isBefore(tmpMinDate);

		setFrmData({ ...frmData, validFrom: !flag ? frmData.validFrom : '', validTo: maxDateToCompare ? maxDateToCompare.format('YYYY-MM-DD HH:mm') : '' });
		setStartDateValue(!flag ? startDateValue : '');
		setMinDate(!flag ? minDate : '');
		setEndDateValue(e.value);
		setMaxDate(moment(e.value).isValid() ? e.value : '');
	};

	const onChangeProduct = (values) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		setFrmData({ ...frmData, refProducts: arrValues });
	};

	const closeForm = () => {
		setVisible(false);
		setFrmData({});
		setErrors({});
		setStartDateValue('');
		setMinDate('');
		setEndDateValue('');
		setMaxDate('');
	};

	return (
		<Sidebar visible={visible} style={{ overflowY: 'auto' }} className='p-sidebar-md' position='right' blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
			<div className='p-d-flex p-justify-between '>
				<h2 className='p-margin-top-10'>{header}</h2>
				<Button label='' icon='pi pi-times' className='p-button-secondary' onClick={closeForm} />
			</div>

			<div className='p-sidebar-line p-mb-3'></div>

			<div className='p-grid'>
				<div className='p-col-12'>
					<Fieldset legend='Information'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12'>
								<label className='p-label'>* Quantity to generating</label>
								<InputText value={frmData.quantity ?? ''} keyfilter='pnum' onChange={(e) => setFrmData({ ...frmData, quantity: e.target.value })} />
								<div className='p-form-error'>{errors.quantity}</div>
							</div>
							{(() => {
								if (frmData.type === PROMOTION_COUPON_TYPE.voucher.value) {
									return (
										<div className='p-col-12'>
											<label className='p-label'>* Promotion</label>
											<Dropdown value={frmData.promotionRuleId} options={rules} onChange={(e) => setFrmData({ ...frmData, promotionRuleId: e.value })} />
											<div className='p-form-error'>{errors.rule}</div>
										</div>
									);
								} else if (!frmData.type || frmData.type === PROMOTION_COUPON_TYPE.coupon.value) {
									return (
										<div className='p-col-12'>
											<label className='p-label'>* Product</label>
											<Select
												multi
												values={products && frmData.refProducts && frmData.refProducts.length > 0 ? products.filter((x1) => frmData.refProducts.some((x2) => x2 === x1.value)) : []}
												options={products}
												onChange={(values) => onChangeProduct(values)}
												style={{ width: '100%' }}
												placeholder='Not use'
												noDataRenderer={() => {
													return <span className='p-c p-padding-10-0'>No Data</span>;
												}}
											/>
											<div className='p-form-error'>{errors.refProducts}</div>
										</div>
									);
								}
							})()}
						</div>
					</Fieldset>
					<Fieldset legend='Usage Setting'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12 p-md-6'>
								<label>Start Date</label>
								<MaskedCalendar value={startDateValue} maxDate={maxDate} onChange={(e) => onStartDateChange(e)} showIcon={true} showTime={true} hourFormat='12' />
								<div className='p-form-error'>{errors.validFrom}</div>
							</div>
							<div className='p-col-12 p-md-6'>
								<label>End Date</label>
								<MaskedCalendar value={endDateValue} minDate={minDate} onChange={(e) => onEndDateChange(e)} showIcon={true} showTime={true} hourFormat='12' />
								<div className='p-form-error'>{errors.validTo}</div>
							</div>
							<div className='p-col-12'>
								<label className='p-label'>Usage limit</label>
								<InputText value={frmData.usageLimit ?? ''} keyfilter='pnum' onChange={(e) => setFrmData({ ...frmData, usageLimit: e.target.value })} />
								<div>
									<i>Note: 0 or empty is unlimit!</i>
								</div>
								<div className='p-form-error'>{errors.usageLimit}</div>
							</div>
							<div className='p-col-12'>
								<label className='p-label'>Active?</label>
								<InputSwitch checked={frmData.active} onChange={(e) => setFrmData({ ...frmData, active: e.value })} />
							</div>
						</div>
					</Fieldset>
				</div>
			</div>

			<div className='p-sidebar-line p-my-3'></div>

			<div className='p-grid'>
				<div className='p-col-12 p-d-flex p-justify-between'>
					<Button label='Cancel' icon='pi-md-close' className='p-button-secondary' onClick={closeForm} />
					<Button label='Submit & Generate' icon='pi pi-check' onClick={() => handleGenerating()} />
				</div>
			</div>
		</Sidebar>
	);
});
