import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../../constants';

const BASE_URL = API_BASE_URL + SCP_PATH + '/memberships';

const data = {
  content: [
    {
      createdAt: '2021-05-06T11:05:20.000+0000',
      updatedAt: '2021-08-26T08:49:47.000+0000',
      id: 1526,
      tenant: 26,
      application: 'fep',
      name: 'UMI ANIROH SAHLI',
      membershipType: 'TYPE_PM01',
      workPermitNumber: '001992252',
      status: 'not_print',
    },
    {
      createdAt: '2021-05-06T11:05:20.000+0000',
      updatedAt: '2021-08-26T08:49:47.000+0000',
      id: 1527,
      tenant: 26,
      application: 'fep',
      name: 'UMI ANIROH SAHLI',
      membershipType: 'TYPE_PM01',
      workPermitNumber: '001992252',
      status: 'processing',
    },
    {
      createdAt: '2021-05-06T11:05:20.000+0000',
      updatedAt: '2021-08-26T08:49:47.000+0000',
      id: 1528,
      tenant: 26,
      application: 'fep',
      name: 'UMI ANIROH SAHLI',
      membershipType: 'TYPE_PM01',
      workPermitNumber: '001992252',
      status: 'printed',
    },
    {
      createdAt: '2021-05-06T11:05:20.000+0000',
      updatedAt: '2021-08-26T08:49:47.000+0000',
      id: 1529,
      tenant: 26,
      application: 'fep',
      name: 'UMI ANIROH SAHLI',
      membershipType: 'TYPE_PM01',
      workPermitNumber: '001992252',
      status: 'cancel',
    },
  ],
  pageable: {
    sort: { sorted: false, unsorted: true, empty: true },
    pageNumber: 0,
    pageSize: 10,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  totalPages: 1,
  totalElements: 4,
  last: false,
  first: true,
  sort: { sorted: false, unsorted: true, empty: true },
  numberOfElements: 10,
  size: 10,
  number: 0,
  empty: false,
};

export function getPageMembershipIssue(page, size, sortField, sortOrder, filter) {
  const order = sortOrder === 0 ? 'asc' : sortOrder === -1 ? 'desc' : '';
  return (
    axios
      .post(`${BASE_URL}/issue/page`, {
        page: page,
        size: size,
        sort: order,
        name: filter.name,
        membershipTypeId: filter.membershipType,
        docNumber: filter.workPermitNumber,
        status: filter.status
      })
      .then((res) => res.data)
      .catch((error) => console.log(error))
  );
}

export function getSimpleMembershipType() {
  return (
    axios
      .get(`${API_BASE_URL + SCP_PATH}/membership-mgt/type/simple`, )
      .then((res) => res.data)
      .catch((error) => console.log(error))
  );
}

export function updateMembershipStatus(id, status) {
  return (
    axios
      .put(`${BASE_URL}/${id}/status?status=${status}`)
      .then((res) => res.data)
      .catch((error) => console.log(error))
  );
}
