import axios from "axios";
import { API_BASE_URL } from "../../constants";

export class DashboardServices {

    getPriceTransactionStatistic(){
        return axios.get(`${API_BASE_URL}/scp/api/dashboard/transaction/statistic`)
        .then(res => res.data).catch(error => console.log(error));
    }

    getNewOrders(){
        return axios.get(`${API_BASE_URL}/scp/api/dashboard/new_orders`)
        .then(res => res.data).catch(error => console.log(error));
    }

    departmentTracking(deptId){
        return axios.get(`${API_BASE_URL}/scp/api/dashboard/department/tracking`, {params:{dept: deptId}})
        .then(res => res.data).catch(error => console.log(error));
    }

    getStatistic(filter){
        return axios.post(`${API_BASE_URL}/scp/api/dashboard/statistic`, filter)
        .then(res => res.data).catch(error => console.log(error));
    }
}