import React, { Component } from 'react';
import classNames from "classnames";
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { showNotification } from '../../core/service/NotificationService';
import { removeFAQ, saveFAQ } from './EventService';
import {InputTextarea} from 'primereact/inputtextarea';
import {Checkbox} from 'primereact/checkbox';

export class FAQForm extends Component {

    constructor(props) {
        super(props);
        this.state ={
            data:{},
            formHeader: 'FAQ',
            visible: false,
            errors: {}
        }
    }

    popularFormData = (data) => {
        this.setState({
            data: {
                id: data?data.id:'',
                event: data?data.event:null,
                eventSession: data?data.eventSession:null,
                sessionId: data&&data.eventSession&&data.eventSession.id?data.eventSession.id: null,
                questioner: data?data.questioner: '',
                question: data?data.question:'',
                answerer: data?data.answerer:'',
                answer: data?data.answer: '',
                status:data?data.status: 'viewed',
                showOnline: data?data.showOnline:false
            },
            visible: true,
            errors: {}
        })
    }

    handleSave = () => {
        this.setState({ errors: {} });
        saveFAQ(this.state.data)
        .then(res => {
            if(!res.errorCode){
                if(this.props.refreshData)
                    this.props.refreshData();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj})
            }
        })
    }

    removeFAQ = () => {
        removeFAQ(this.state.data.id)
        .then(res => {
            if(!res.errorCode){
                if(this.props.refreshData)
                    this.props.refreshData();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    onHide = () => {
        this.setState({visible: false})
    }

    onAnswerChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                answer: e.target.value
            }
        })
    }

    onQuestionChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                question: e.target.value
            }
        })
    }

    onQuestionerChange = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                questioner: e.target.value
            }
        })
    }

    render() {
        const header = "Answer Question Form";
        return (
            <Sidebar visible={this.state.visible} className="p-sidebar-md" position="right" onHide={() => this.onHide()} blockScroll={true} style={{overflowY: 'auto'}}>
                <h2>{header}</h2>

                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <label className="p-label">Event : {this.state.data.event && this.state.data.event.name}</label>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Session Name : {this.state.data.eventSession && this.state.data.eventSession.title}</label>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Questioner : {this.state.data.questioner}</label>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Question : </label>
                        <p>{this.state.data.question}</p>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Your answer</label>
                        <InputTextarea value={this.state.data.answer} onChange={(e) => this.onAnswerChange(e)} rows={5}/>
                    </div>
                    {/* <div>
                        <label className="p-label">Status</label>
                        <Dropdown options={OPTIONS} value={this.state.data.status} onChange={(e) => this.setState({data: {...this.state.data, status: e.value}})} />
                    </div> */}
                    <div className="p-col-12">
                        <Checkbox checked={this.state.data.showOnline} onChange={() => this.setState({data: {...this.state.data, showOnline:!this.state.data.showOnline}})}/>
                        <label>Show Online ?</label>
                    </div>
                </div>
                <div className="p-grid p-margin-top-30">
                    {this.state.data.id &&
                        <div className="p-col-12 p-md-4">
                            <Button label="Remove" icon="pi-md-trash" className="p-button-danger" onClick={() => this.removeFAQ()}/>
                        </div>
                    }
                    <div className={classNames("p-col-12 p-r", {"p-md-8": this.state.data.id})}>
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => this.onHide()}/>
                        <Button label="Submit" icon="pi pi-check" onClick={() => this.handleSave()}/>
                    </div>
                </div>
            </Sidebar>
        );
    }
}