import React, {useState, useEffect} from "react";
import moment from 'moment';
import { DataTable } from "primereact/datatable";
import { useRouteMatch, useHistory} from "react-router-dom";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { APP_FEP, DATETIME_FORMAT_DISPLAY, INVOICE_PURPOSE, PAYMENT_STATE } from "../../constants";
import { exportInvoices, getPageInvoices, removeInvoice, sendInvoiceMailRemind } from "../invoice/InvoiceServices";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { moneyFormat, getTablePageReport } from "../../core/service/CommonService";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from "../../core/service/NotificationService";
import { SplitButton } from "primereact/splitbutton";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";

const fileDownload = require('js-file-download');

export const DonationListView = () => {
    const match = useRouteMatch()
    const history = useHistory()

    const [filter, setFilter] = useState({
        app: APP_FEP, 
        statuses: [PAYMENT_STATE.completed.value],
        purposes: [INVOICE_PURPOSE.donation_charge.value]
    });

    const [dataList, setDataList] = useState([]);
    const [pageable, setPageable] = useState({
        page: 0,
        rows: 10,
        total: 0,
        sortField: 'id',
        sortOrder: -1
    })
    const [isLoadTable, setLoadTable] = useState(true);

    useEffect(()=>{
        if(isLoadTable)
            loadPageInvoices();
    },[isLoadTable])

    const loadPageInvoices = () =>{
        showloading();
        getPageInvoices(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            if(res){
                setDataList(res.content)
                setPageable({...pageable,
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                })
                setLoadTable(false);
            }
        })
        .finally(() => {
            stoploading()
        })
    }

    const onPage = (e) =>{
        setPageable({ ...pageable,
            loading: true,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({ ...pageable,
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable, 
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const clearInvoiceFilter = () => {
        setFilter({...filter,
            id: '',
            customer: '',
            dateCompletedFrom: '',
            dateCompletedFromValue: null,
            dateCompletedTo: '',
            dateCompletedToValue: null
        })
        setLoadTable(true);
    }

    const sendRemindEmail = (id, reminded) => {
        showConfirmNotify({
            message: reminded?'This invoice had already send to customer. Are you sure you want to proceed?':'',
            accept: () => handleSendInvoiceRemindEmail(id)
        })
    }

    const handleSendInvoiceRemindEmail = (id) => {
        sendInvoiceMailRemind(id)
        .then(res => {
            if(!res.errorCode){
                loadPageInvoices()
                showSuccessNotify('Email sent successfully!');
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const handleRemoveInvoice = (id) => {
        removeInvoice(id)
        .then(res => {
            if(!res.errorCode){
                loadPageInvoices()
                showSuccessNotify('Action Submitted');
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const onRemoveInvoice = (id) => {
        showConfirmNotify({
            accept: () => handleRemoveInvoice(id)
        })
    }

    const handleExport = () => {
        let suf = moment().format('YYYYMMDDHHmmss');
        let fileName = 'rpt_donation_' + suf + '.xlsx';

        exportInvoices(filter)
        .then(res=>{
            if (!res.errorCode) {
                fileDownload(res, fileName);
            } else {
                showErrorNotify(res.errorMessage);
            }
        })
    }

    const onDateFromChange = (e) => {
        let maxDate = filter.maxDate ? moment(filter.maxDate) : '';
        let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
        let isCurrentValueAfterMaxDate = (currentValue && maxDate && currentValue.isAfter(maxDate));

        setFilter({
            ...filter,
            dateCompletedFrom: currentValue?currentValue.format(moment.HTML5_FMT.DATE):'',
            dateCompletedFromValue: e.value,
            minDate: moment(e.value).isValid() ? e.value : '',
            dateCompletedTo: !isCurrentValueAfterMaxDate ? filter.dateCompletedTo : '',
            dateCompletedToValue: !isCurrentValueAfterMaxDate ? filter.dateCompletedToValue : '',
            maxDate: !isCurrentValueAfterMaxDate ? filter.maxDate : ''
        })
    }

    const onDateToChange = (e) => {
        let minDate = filter.minDate ? moment(filter.minDate) : '';
        let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
        let isCurrentValueBeforeMinDate = (currentValue && minDate && currentValue.isBefore(minDate));

        setFilter({
            ...filter,
            dateCompletedFrom: !isCurrentValueBeforeMinDate ? filter.dateCompletedFrom : '',
            dateCompletedFromValue: !isCurrentValueBeforeMinDate ? filter.dateCompletedFromValue : '',
            minDate: !isCurrentValueBeforeMinDate ? filter.minDate :'',
            dateCompletedTo: currentValue?currentValue.format(moment.HTML5_FMT.DATE):'',
            dateCompletedToValue: e.value,
            maxDate: moment(e.value).isValid() ? e.value : ''
        })
    }
    
    const tblHeader = (
        <TableHeader title={"Donations"} 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            actionTemplate={<Button label="Export" icon="pi pi-download" disabled={dataList.length===0} onClick={()=> handleExport()} />}
        />
    );

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Donations</BreadcrumbsItem>

            <div className="p-col-12">
                <Fieldset legend="Filter">
                    <div className="p-grid p-fluid p-mt-1">
                        <div className="p-col-12 p-md-6">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon p-text-bold">Donation Number:</span>
                                        <InputText value={filter.id} keyfilter={/^[0-9]*$/} onChange={e => setFilter({...filter, id: e.target.value})} onKeyUp={e => e&&e.key==='Enter' && setLoadTable(true)}/>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon p-text-bold">Payer Name:</span>
                                        <InputText value={filter.customer} onChange={e => setFilter({...filter, customer: e.target.value})} onKeyUp={e => e&&e.key==='Enter' && setLoadTable(true)} placeholder="Name/email/phone"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">From:</span>
                                        <MaskedCalendar value={filter.dateCompletedFromValue} minDate={filter.maxDate} onChange={(e) => onDateFromChange(e)} showIcon={true} dateFormat="dd-mm-yy" />
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">To:</span>
                                        <MaskedCalendar value={filter.dateCompletedToValue} minDate={filter.minDate} onChange={(e) => onDateToChange(e)} showIcon={true} dateFormat="dd-mm-yy" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-c">
                            <Button label="Search" icon="pi pi-filter" iconPos="left" style={{width: 'auto'}} onClick={() => setLoadTable(true)} />
                            <Button label="Clear" icon="pi pi-trash" className="p-button-warning p-ml-2" iconPos="left" style={{width: 'auto'}}  onClick={() => clearInvoiceFilter()} />
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div className="p-col-12">
                <DataTable emptyMessage="There's no order" value={dataList} loading={isLoadTable}
                    lazy={true} paginator={true} 
                    first={pageable.page * pageable.rows}
                    header={tblHeader} onPage={onPage} onSort={onSort}
                    rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    responsive={true}
                >
                    <Column header="No. #" field="id" sortable={true}  headerStyle={{width: '10em'}}
                        body={(rowData) => <Button label={rowData.id} icon="pi pi-search" className="p-button-info" tooltip="View Donation" tooltipOptions={{position: "top"}} onClick={() => history.push(`/donations/${rowData.id}`)}/> }/>
                    <Column header="Payer" field="payorName" style={{width: '15%'}}
                        body={(rowData) => {
                            let tmpDonateInfo = rowData.donateInfo
                            let isAnonymous = tmpDonateInfo && tmpDonateInfo.isAnonymous
                            let isPersonal = tmpDonateInfo && tmpDonateInfo.isPersonal
                            if(!tmpDonateInfo) return ''
                            if(isAnonymous) return '[Anonymous]'
                            return <React.Fragment>
                                {isPersonal ?
                                    <React.Fragment>
                                        <div>{rowData.payorName??''} </div>
                                        <div className="p-margin-top-5">[{rowData.payorEmail}]</div>
                                        <div className="p-margin-top-5">[{rowData.payorPhone}]</div>
                                        <div className="p-margin-top-5">NRIC/FIN: {tmpDonateInfo.nricFin}</div>
                                        <div className="p-margin-top-5">Address: {tmpDonateInfo.address}</div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div>Company Name: {tmpDonateInfo.companyName??''}</div>
                                        {<div className="p-margin-top-5">Contact Person: {rowData.payorPhone}</div>}
                                        {<div className="p-margin-top-5">Contact Number: {tmpDonateInfo.companyNumber}</div>}
                                        {<div className="p-margin-top-5">Email: {rowData.payorEmail}</div>}
                                        {<div className="p-margin-top-5">UEN Number: {tmpDonateInfo.uenNumber}</div>}
                                        {<div className="p-margin-top-5">Address: {tmpDonateInfo.address}</div>}
                                    </React.Fragment>
                                }
                                </React.Fragment>}
                        }
                    />
                    <Column header="Reason" field="description" sortable={false} style={{width: '25%'}} 
                        body={rowData => {
                            return (
                                <React.Fragment>
                                    <div>{rowData.description}</div>
                                    {rowData.note && 
                                    <div className="p-size-12 p-mt-1">- {rowData.note}</div>
                                    }
                                </React.Fragment>
                            )
                        }}
                    />
                    <Column header="Amount" field="checkout" bodyClassName="p-r" body={(rowData) => moneyFormat(rowData.checkout)} sortable={true} />
                    <Column header="Completed At" field="paymentCompletedAt" sortable={true} headerStyle={{width: '12em'}}
                        bodyClassName="p-c" body={(rowData) => rowData.paymentCompletedAt&&(moment(rowData.paymentCompletedAt).format(DATETIME_FORMAT_DISPLAY))}
                    />
                    <Column header="Email reminder" bodyClassName="p-c"
                        body={rowData => {
                            let tmpDonateInfo = rowData.donateInfo
                            let isAnonymous = tmpDonateInfo && tmpDonateInfo.isAnonymous
                            if(!isAnonymous) return rowData.remind?'YES':'NO'
                        }}
                    />
                    <Column headerStyle={{width: '14em'}}  bodyClassName="tc-actions"
                        body={rowData => {
                            let tmpDonateInfo = rowData.donateInfo
                            let isAnonymous = tmpDonateInfo && tmpDonateInfo.isAnonymous
                            let items = [{label: 'Remove', icon: 'pi pi-trash', command:() => onRemoveInvoice(rowData.id)}]

                            if(isAnonymous) return <Button label="Remove" icon="pi pi-trash" className="p-button-danger" model={items} onClick={()=> onRemoveInvoice(rowData.id)} />

                            return <SplitButton label="Resend email" icon="pi pi-envelope" model={items} onClick={()=> sendRemindEmail(rowData.id, rowData.remind)} />
                        }}
                    />
                </DataTable>
            </div>
        </div>
    );
}
