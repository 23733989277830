import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment'
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';
import { APP_FEP, TAXONOMY_TYPE, TIME_UNIT_TYPE } from '../../constants';
import { getListTaxons} from '../../pim/taxon/TaxonServices';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { BranchOperatingHoursForm } from './BranchOperatingHoursForm';
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { saveScpConfig } from './ConfigruationServices';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';

const weekday = {
    'mon': 'mon',
    'tue': 'tue',
    'wed': 'wed',
    'thu': 'thu',
    'fri': 'fri',
    'sat': 'sat',
    'sun': 'sun'
}

export const TenantOperatingHour = (props) => {
    const branchHoursForm = useRef(null)

    const [branchs, setBranchs] = useState([])
    const [branchHours, setBranchHours] = useState({})
    const [timeBlockUnit, setTimeBlockUnit] = useState(TIME_UNIT_TYPE.min.value)
    const [timeBlockValue, setTimeBlockValue] = useState('0')
    const [tmpOperatingHourObj, setTmpOperatingHourObj] = useState({})
    const [dayOffs, setDayOffs] = useState([])
    const [tmpDayOffObj, setTmpDayOffObj] = useState({})
    const [errors, setErrors] = useState({})

    useEffect(()=>{
        loadBranchs()
    }, [])

    useEffect(()=>{
        setBranchHours(props.data.branchHours)
    }, [props.data.operatingHours])

    useEffect(()=>{
        setDayOffs(props.data.dayOffs)
    }, [props.data.dayOffs])

    useEffect(()=>{
        setTimeBlockUnit(props.data.timeBlockUnit)
    }, [props.data.timeBlockUnit])

    useEffect(()=>{
        setTimeBlockValue(props.data.timeBlockValue)
    }, [props.data.timeBlockValue])

    const handleSave = () => {
        let tmpData = {}
        tmpData.branchHours = branchHours
        tmpData.dayOffs = dayOffs
        tmpData.timeBlockUnit = timeBlockUnit
        tmpData.timeBlockValue = timeBlockValue

        setErrors({})

        saveScpConfig(tmpData, 'operatingHours')
        .then((res) => {
            if(!res.errorCode){
                showSuccessNotify('Action Submitted');
                props.onSave(res);
            }else{
                showErrorNotify(res.errorMessage);
                if(res.errorCode===400) setErrors(res.errorObj)
            }
        })
    }

    const loadBranchs = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.branch.value}, false)
        .then(res => {
            setBranchs(res)
        })
    }

    //#region OPERATING HOURS
    const onAddOrEditOperatingHour = (branchId, day, timeData, index) => {
        setTmpOperatingHourObj({branchId: branchId, day: day, index: index})
        branchHoursForm.current.openForm(branchId, day, timeData)
    }

    const onSaveOperatingHour = (timeData) => {
        let day = tmpOperatingHourObj.day;
        let index = tmpOperatingHourObj.index;
        let tmpBranchId = timeData.branchId;
        let value = {s: timeData.start, e: timeData.end};

        let tmpData = branchHours[tmpBranchId]?{...branchHours[tmpBranchId]}:{operatingHours: {}}

        for (const dayOfWeek of timeData.weekdays) {
            if(timeData.overwrite){
                tmpData.operatingHours[dayOfWeek]  = [value]
            }else{
                let listValue = branchHours[tmpBranchId]&&branchHours[tmpBranchId].operatingHours[dayOfWeek] ? [...branchHours[tmpBranchId].operatingHours[dayOfWeek]] : [];
            
                let errors={}; // validation times on form time range
                let sValue = moment(timeData.start, moment.HTML5_FMT.TIME);
                let eValue = moment(timeData.end, moment.HTML5_FMT.TIME);
                listValue.map((item, idx) => {
                    if(idx !== index)
                    {
                        let start = moment(item.s, moment.HTML5_FMT.TIME);
                        let end = moment(item.e, moment.HTML5_FMT.TIME);
                        if(sValue.isSameOrBefore(start) && eValue.isSameOrAfter(end))
                        {
                            errors.start = 'Value not allowed'; 
                            errors.end = 'Value not allowed';
                        } 
                        else if(sValue.isSameOrBefore(start) && eValue.isSameOrAfter(start) && eValue.isSameOrBefore(end))
                        {
                            errors.end = 'Value not allowed';
                        } 
                        else if(sValue.isSameOrAfter(start) && sValue.isSameOrBefore(end) && eValue.isSameOrAfter(end))
                        {
                            errors.start = 'Value not allowed';
                        } 
                        else 
                        {
                            if(sValue.isAfter(start) && sValue.isBefore(end))
                            {
                                errors.start = 'Value not allowed';
                            }
                            if(eValue.isAfter(start) && eValue.isBefore(end))
                            {
                                errors.end = 'Value not allowed';
                            }
                        }
                    }
                })
                if(Object.keys(errors).length > 0){ // show error message
                    branchHoursForm.current.onError(errors);
                    return false;
                }
                if(index || index === 0){
                    listValue.splice(index, 1, value);
                }else{
                    listValue.push(value);
                }
    
                tmpData.operatingHours[dayOfWeek] = listValue
            }
        }
        
        setBranchHours({...branchHours,
            [tmpBranchId]: tmpData
        })
        setTmpOperatingHourObj({})
    }

    const onRemoveOperatingHour = (branchId, day, index) => {
        let listValue = [...branchHours[branchId].operatingHours[day]]
        listValue.splice(index, 1)
        setBranchHours({...branchHours,
            [branchId]: {...branchHours[branchId],
                operatingHours: {...branchHours[branchId].operatingHours,
                    [day]: listValue
                }
            }
        })
    }

    const headerOperatingHoursTemplate = (day) => {
        let textHeader = '';
        switch(day){
            case weekday.mon: textHeader = 'Mon'; break;
            case weekday.tue: textHeader = 'Tue'; break;
            case weekday.wed: textHeader = 'Wed'; break;
            case weekday.thu: textHeader = 'Thu'; break;
            case weekday.fri: textHeader = 'Fri'; break;
            case weekday.sat: textHeader = 'Sat'; break;
            case weekday.sun: textHeader = 'Sun'; break;
            default: break;
        }

        return (
            <div className="p-margin-top-5">
                <div className="p-margin-top-5" style={{display: 'inline-block'}}>{textHeader}</div>
                <Button icon="pi pi-plus" className="p-margin-left-5 btn-sm p-float-right" tooltip="Add Time" tooltipOptions={{position: 'top'}} onClick={(e) => onAddOrEditOperatingHour(null, day)} />
            </div>
        ) 
    }

    const columnOperatingHourDataTemplate = (rowData, column, day) => {
        let dayOfWeek = branchHours[rowData.id]&&branchHours[rowData.id].operatingHours&&branchHours[rowData.id].operatingHours[day]?branchHours[rowData.id].operatingHours[day]:[];
        
        return(
            <div className="p-grid p-fluid">
                {dayOfWeek.map((item, idx) => {
                    return (
                        <React.Fragment key={day+'_'+idx}>
                            <div className="p-col-12 p-md-12 p-sm-12 p-padding-left-0 p-padding-right-0">
                                <div className="p-margin-top-5">{moment(item.s, 'HH:mm').format('hh:mm A')} - {moment(item.e, 'HH:mm').format('hh:mm A')}</div>
                            </div>
                            <div className="p-col-4 p-md-4 p-sm-12 p-padding-left-0 p-padding-right-0">
                                <Button icon="pi pi-pencil" className="p-button-warning btn-xs" tooltip="Edit Time" tooltipOptions={{position: 'top'}} onClick={() => onAddOrEditOperatingHour(rowData.id, day, item, idx)} />
                                <Button icon="pi pi-trash" className="p-button-danger btn-xs" tooltip="Remove Time" tooltipOptions={{position: 'top'}} onClick={() => onRemoveOperatingHour(rowData.id, day, idx)} />
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }
    //#endregion

    //#region DATE OFF PLAN
    const onEditTimeDayOff = (item) => {
        let temp = {...tmpDayOffObj};
        let index = dayOffs.indexOf(item);
        temp = {
            id: item.id,
            s:item.start, sValue: moment(item.start, 'YYYY-MM-DD HH:mm').toDate(), min : moment(item.start, 'YYYY-MM-DD HH:mm').toDate(),
            e:item.end, eValue: moment(item.end, 'YYYY-MM-DD HH:mm').toDate(), max: moment(item.end, 'YYYY-MM-DD HH:mm').toDate(),
            index: index
        }
        setTmpDayOffObj(temp)
    }

    const onChangeTimeDayOff = (e, property) => {
        let flag = e.value && moment(e.value).isValid();
        let start, startValue, minDate;
        let end, endValue, maxDate;
        if(property === 'start'){
            start = flag ? moment(e.value).format('YYYY-MM-DD HH:mm') : '';
            startValue = e.value;
            minDate = flag ? e.value : '';
            end = tmpDayOffObj.e;
            endValue = tmpDayOffObj.eValue;
            maxDate = tmpDayOffObj.max;
        }else if(property === 'end'){
            start = tmpDayOffObj.s;
            startValue = tmpDayOffObj.sValue;
            minDate = tmpDayOffObj.min;
            end = flag ? moment(e.value).format('YYYY-MM-DD HH:mm') : '';
            endValue = e.value;
            maxDate = flag ? e.value : ''
        }
        setTmpDayOffObj({...tmpDayOffObj,
            s: start, sValue: startValue, min: minDate,
            e: end, eValue: endValue, max: maxDate
        })
    }

    const onCancelDayOff = () => {
        setTmpDayOffObj({
            s: '', sValue: '', min: '',
            e: '', eValue: '', max: ''
        })
    }

    const onSaveDayOff = () => {
        let errors={};
        if(!tmpDayOffObj.s){
            errors.start = 'Required'
        }
        if(!tmpDayOffObj.e){
            errors.end = 'Required'
        }
        if(Object.keys(errors).length>0){
            setErrors(...errors);
            return;
        }
        
        let index = tmpDayOffObj.index;
        let listValue = [...dayOffs]
        let itemToAdd = {id: tmpDayOffObj.id, start: tmpDayOffObj.s, end: tmpDayOffObj.e};

        if(tmpDayOffObj.index || tmpDayOffObj.index===0){
            listValue.splice(index, 1, itemToAdd);
        }else{
            listValue.push(itemToAdd);
        }
        setDayOffs(listValue)
        setTmpDayOffObj({
            s: '', sValue: '', min: '',
            e: '', eValue: '', max: ''
        })
        setErrors({})
    }

    const onRemoveDayOff = async (item) => {
        let listValue = [...dayOffs];
        let index = listValue.indexOf(item);
        if(item.id){
            // await removeSimpleEvent(item.id).then(res => {
            //     if(res.errorCode) 
            //         notify.showErrorNotify(trans('res.mgt.errors.error_cannot_perform_action'));
            // })
        }
        listValue.splice(index, 1);
        setDayOffs(listValue)
        setTmpDayOffObj({
            s: '', sValue: '', min: '',
            e: '', eValue: '', max: ''
        })
    }

    const timeDayOffTemplate = (index) => {
        let now = moment(new Date(), moment.HTML5_FMT.DATE);
        now.set({h: 0, m: 0, s: 0});
        let minDate = now.toDate();
        if(tmpDayOffObj.min) minDate = tmpDayOffObj.min;
        return(
            <div className="p-grid">
                <div className="p-col-12 p-md-5">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Start Date:</span>
                        <MaskedCalendar value={tmpDayOffObj.sValue} maxDate={tmpDayOffObj.max} minDate={now.toDate()} onChange={(e) => onChangeTimeDayOff(e, 'start')} showIcon={true} showTime={true} hourFormat="12" />
                        </div>
                    <div className="p-form-error">{errors.start}</div>
                </div>
                <div className="p-col-12 p-md-5">
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">End Date:</span>
                        <MaskedCalendar value={tmpDayOffObj.eValue} minDate={minDate} onChange={(e) => onChangeTimeDayOff(e, 'end')} showIcon={true} showTime={true} hourFormat="12" />
                    </div>
                    <div className="p-form-error">{errors.end}</div>
                </div>
                <div className="p-col-12 p-md-2 p-r">
                    <Button className="p-button-success btn-custom-default btn-sm" disabled={!(tmpDayOffObj.s || tmpDayOffObj.e)} icon="pi pi-check" tooltip="Save" tooltipOptions={{position: 'top'}} onClick={() => onSaveDayOff()} />
                    {(tmpDayOffObj.s || tmpDayOffObj.e) && 
                        <React.Fragment>
                            <Button className="p-button-danger btn-custom-default btn-sm" icon="pi pi-times" tooltip="Cancel" tooltipOptions={{position: 'top'}} onClick={() => onCancelDayOff()} />
                        </React.Fragment>
                    }
                </div> 
            </div>
        )
    }
    //#endregion
    
    const tblHeader = ( 
        <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
            <Button className="p-button-constrast p-float-left" label="Add Time" icon="pi pi-plus" onClick={(e) => onAddOrEditOperatingHour()} style={{width: 'auto'}} />
        </div>
    )

    return(
        <div className="p-grid">
            <BranchOperatingHoursForm ref={branchHoursForm} 
                onSave={(data) => onSaveOperatingHour(data)}
            />
            
            <div className="p-col-12">
                <Fieldset className="filter-panel input-panel" legend="Operating Hours">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <DataTable value={branchs} header={tblHeader} responsive={true}>
                                <Column body={rowData => rowData.name} bodyStyle={{verticalAlign: 'initial', overflow: 'visible'}} />
                                <Column header={headerOperatingHoursTemplate(weekday.mon)} field='mon' body={(rowData, column) => columnOperatingHourDataTemplate(rowData, column, weekday.mon)} bodyStyle={{verticalAlign: 'initial', overflow: 'visible'}} />
                                <Column header={headerOperatingHoursTemplate(weekday.tue)} field='tue' body={(rowData, column) => columnOperatingHourDataTemplate(rowData, column, weekday.tue)} bodyStyle={{verticalAlign: 'initial', overflow: 'visible'}} />
                                <Column header={headerOperatingHoursTemplate(weekday.wed)} field='wed' body={(rowData, column) => columnOperatingHourDataTemplate(rowData, column, weekday.wed)} bodyStyle={{verticalAlign: 'initial', overflow: 'visible'}} />
                                <Column header={headerOperatingHoursTemplate(weekday.thu)} field='thu' body={(rowData, column) => columnOperatingHourDataTemplate(rowData, column, weekday.thu)} bodyStyle={{verticalAlign: 'initial', overflow: 'visible'}} />
                                <Column header={headerOperatingHoursTemplate(weekday.fri)} field='fri' body={(rowData, column) => columnOperatingHourDataTemplate(rowData, column, weekday.fri)} bodyStyle={{verticalAlign: 'initial', overflow: 'visible'}} />
                                <Column header={headerOperatingHoursTemplate(weekday.sat)} field='sat' body={(rowData, column) => columnOperatingHourDataTemplate(rowData, column, weekday.sat)} bodyStyle={{verticalAlign: 'initial', overflow: 'visible'}} />
                                <Column header={headerOperatingHoursTemplate(weekday.sun)} field='sun' body={(rowData, column) => columnOperatingHourDataTemplate(rowData, column, weekday.sun)} bodyStyle={{verticalAlign: 'initial', overflow: 'visible'}} />
                            </DataTable>
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div className="p-col-12">
                <Fieldset legend="Time Block Setting">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-lg-3 p-md-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Unit type:</span>
                                <Dropdown value={timeBlockUnit} options={Object.values(TIME_UNIT_TYPE)} onChange={e => setTimeBlockUnit(e.value)} />
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-3 p-md-6">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">Value:</span>
                                <InputText value={timeBlockValue} keyfilter="pnum" onChange={e => setTimeBlockValue(e.target.value)} tooltip={timeBlockUnit?('Value must be less than or equal to '+ (timeBlockUnit===TIME_UNIT_TYPE.min.value?'60':(timeBlockUnit===TIME_UNIT_TYPE.hr.value?'24':''))):''} />
                            </div>
                            <div className="p-form-error">{errors.timeBlockValue}</div>
                        </div>
                    </div>    
                </Fieldset>
            </div>
            <div className="p-col-12">
                <Fieldset className="filter-panel input-panel" legend="Off Date Plan">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-6">
                            {timeDayOffTemplate()}
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    <DataTable responsive={true} value={dayOffs} >
                                        <Column field="start" header="Start Date" body={rowData => moment(rowData.start).format('MM-DD-YYYY hh:mm A')} />
                                        <Column field="end" header="End Date" body={rowData => moment(rowData.end).format('MM-DD-YYYY hh:mm A')} />
                                        <Column header='' bodyClassName="p-r" headerStyle={{width: '10em'}}
                                            body={(rowData, column) => {
                                                if(column.rowIndex !== tmpDayOffObj.index)
                                                    return (
                                                        <React.Fragment>
                                                            <Button icon="pi pi-pencil" className="p-button-warning btn-custom-default btn-sm" tooltip="Edit" tooltipOptions={{position: 'top'}} onClick={() => onEditTimeDayOff(rowData)} />
                                                            <Button icon="pi-md-trash" className="p-button-danger btn-custom-default btn-sm" tooltip="Remove" tooltipOptions={{position: 'top'}} onClick={() => onRemoveDayOff(rowData)} />
                                                        </React.Fragment>
                                                    )
                                            }} 
                                        />
                                    </DataTable>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div className="p-col-12 p-mt-3 p-r">
                <Button className="p-padding-left-30 p-padding-right-30" label="Save" onClick={()=> handleSave()} style={{width: 'auto'}} />
            </div>
        </div>
    )
}