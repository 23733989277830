import React, { Component } from "react";
import classNames from 'classnames';
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { showNotification } from '../../core/service/NotificationService';
import { InputText } from "primereact/inputtext";
import { PAYMENT_GATEWAY, PAYMENT_GATEWAYS, PAYMENT_USE_MODE, PAYMENT_USE_MODES } from "../../constants";
import { removeTenantPayment, saveTenantPayment } from "./TenantPaymentService";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import {SelectButton} from 'primereact/selectbutton';

export class TenantPaymentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {settings: {}},
            errors: {}
        }
    }

    popularFormData = (data) => {
        this.setState({
            data: {
                id: data ? data.id : '',
                name: data ? data.name : '',
                gateway: data ? data.gateway : null,
                active: data ? data.active : false,
                settings: data && data.settings ? data.settings : {},
                useMode: data ? data.useMode : PAYMENT_USE_MODE.TEST
            },
            visible: true,
            formHeader: data ? 'Edit Payment' : 'Add Payment',
            errors: {}
        });
    }

    handleSaveForm = () => {
        this.setState({errors: {} });
        saveTenantPayment(this.state.data)
        .then(res => {
            if (!res.errorCode) {
                if (this.props.refreshData)
                    this.props.refreshData();
                this.onHide();
                showNotification('success', 'Success Message', 'Action Submitted');
            } else {
                showNotification('error', 'Error Message', 'Cannot perform action');
                if (res.errorCode === 400) this.setState({ errors: res.errorObj })
            }
        })

    }

    parseMessage = (property) => {
        let message = '';
        if(property){
            message = property;
            if(property.message) message = property.message;
        }
        return message;
    }

    onHide = () => {
        this.setState({
            visible: false,
            data: {settings: {}},
            errors: {}
        });
    }

    onGateWayChange = (e) => {
        let settings = {
            publicKey: this.state.data.settings.publicKey,
            secretKey: this.state.data.settings.secretKey
        };

        if(e.value===PAYMENT_GATEWAY.SQUARE){
            settings.merchantKey = this.state.data.settings.merchantKey;
            settings.useApplePay = false;
            settings.useGooglePay = false;
            settings.useMasterpass = false;
        }

        this.setState({
            data: {
                ...this.state.data,
                gateway: e.value,
                settings: settings
            }
        });
    }

    generalMerchantKeyLabel = (gateway) => {
        switch (gateway){
            case PAYMENT_GATEWAY.SQUARE:
                return 'Application ID';
            default:
                return 'Merchant Key';
        }
    }

    generalSecretKeyLabel = (gateway) => {
        switch (gateway){
            case PAYMENT_GATEWAY.STRIPE:
                return 'Secret Key';
            case PAYMENT_GATEWAY.SQUARE:
                return 'Access Token';
            case PAYMENT_GATEWAY.PAYPAL:
                return 'Client Secret';
            case PAYMENT_GATEWAY.MIDTRANS:
                return 'Server Key';
            case PAYMENT_GATEWAY.HITPAY:
                return 'Api Key';
            default:
                return 'Secret Key';
        }
    }

    generalPublicKeyLabel = (gateway) => {
        switch (gateway){
            case PAYMENT_GATEWAY.STRIPE:
                return 'Publishable Key';
            case PAYMENT_GATEWAY.SQUARE:
                return 'Location ID';
            case PAYMENT_GATEWAY.PAYPAL:
                return 'Client ID';
            case PAYMENT_GATEWAY.MIDTRANS:
                return 'Client Key';
            case PAYMENT_GATEWAY.HITPAY:
                return 'Salt';
            default:
                return 'Public Key';
        }
    }

    renderPaymentSettings = (gateway) => {
        switch(gateway){
            case PAYMENT_GATEWAY.STRIPE:
                return (
                    <React.Fragment>
                        <div className="p-col-12">
                            <label className="p-label">* Publishable Key</label>
                            <InputText value={this.state.data.settings && this.state.data.settings.publishableKey} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, publishableKey: e.target.value } } })} />
                            <div className="p-form-error"> {this.parseMessage(this.state.errors.publishableKey)} </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">* Secret Key</label>
                            <InputText value={this.state.data.settings && this.state.data.settings.secretKey} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, secretKey: e.target.value } } })} />
                            <div className="p-form-error"> {this.parseMessage(this.state.errors.secretKey)} </div>
                        </div>
                    </React.Fragment>
                )
            case PAYMENT_GATEWAY.SQUARE:
                return (
                    <React.Fragment>
                        <div className="p-col-12">
                            <label className="p-label">* Application ID</label>
                            <InputText value={this.state.data.settings && this.state.data.settings.applicationId} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, applicationId: e.target.value } } })} />
                            <div className="p-form-error"> {this.parseMessage(this.state.errors.applicationId)} </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">* Location ID</label>
                            <InputText value={this.state.data.settings && this.state.data.settings.locationId} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, locationId: e.target.value } } })} />
                            <div className="p-form-error"> {this.parseMessage(this.state.errors.locationId)} </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">* Access Token</label>
                            <InputText value={this.state.data.settings && this.state.data.settings.accessToken} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, accessToken: e.target.value } } })} />
                            <div className="p-form-error"> {this.parseMessage(this.state.errors.accessToken)} </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Apple Pay?</label>
                            <InputSwitch className="p-margin-5-0" onLabel="Yes" offLabel="No" checked={this.state.data.settings && this.state.data.settings.isUseApplePay} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, isUseApplePay: e.value } } })} />
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Google Pay?</label>
                            <InputSwitch className="p-margin-5-0" onLabel="Yes" offLabel="No" checked={this.state.data.settings && this.state.data.settings.isUseGooglePay} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, isUseGooglePay: e.value } } })} />
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Masterpass?</label>
                            <InputSwitch className="p-margin-5-0" onLabel="Yes" offLabel="No" checked={this.state.data.settings && this.state.data.settings.isUseMasterpass} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, isUseMasterpass: e.value } } })} />
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Allow using square online?</label>
                            <InputSwitch className="p-margin-5-0" onLabel="Yes" offLabel="No" checked={this.state.data.settings && this.state.data.settings.isAllowUsingSquareOnline} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, isAllowUsingSquareOnline: e.value } } })} />
                        </div>
                    </React.Fragment>
                )
                case PAYMENT_GATEWAY.PAYPAL:
                    return (
                        <React.Fragment>
                            <div className="p-col-12">
                                <label className="p-label">* Client ID</label>
                                <InputText value={this.state.data.settings && this.state.data.settings.clientId} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, clientId: e.target.value } } })} />
                                <div className="p-form-error"> {this.parseMessage(this.state.errors.clientId)} </div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* Client Secret</label>
                                <InputText value={this.state.data.settings && this.state.data.settings.clientSecret} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, clientSecret: e.target.value } } })} />
                                <div className="p-form-error"> {this.parseMessage(this.state.errors.clientSecret)} </div>
                            </div>
                        </React.Fragment>
                    )
                case PAYMENT_GATEWAY.MIDTRANS:
                    return (
                        <React.Fragment>
                            <div className="p-col-12">
                                <label className="p-label">* Client Key</label>
                                <InputText value={this.state.data.settings && this.state.data.settings.clientKey} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, clientKey: e.target.value } } })} />
                                <div className="p-form-error"> {this.parseMessage(this.state.errors.clientKey)} </div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* Server Key</label>
                                <InputText value={this.state.data.settings && this.state.data.settings.serverKey} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, serverKey: e.target.value } } })} />
                                <div className="p-form-error"> {this.parseMessage(this.state.errors.serverKey)} </div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Payment Notification URL</label>
                                <div className="p-margin-top-5 p-margin-bottom-15 p-w-bold" style={{color: 'red'}}>https://kraken-gw.fromlabs.com/api/orders/webhook/snap/notification</div>
                                <span className="webhook-url-notify">Notice: set the Payment Notification URL in your Midtrans Account at Settings - Configuration with provided URL.</span>
                            </div>
                        </React.Fragment>
                    )
                case PAYMENT_GATEWAY.HITPAY:
                    return (
                        <React.Fragment>
                            <div className="p-col-12">
                                <label className="p-label">* Salt</label>
                                <InputText value={this.state.data.settings && this.state.data.settings.salt} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, salt: e.target.value } } })} />
                                <div className="p-form-error"> {this.parseMessage(this.state.errors.salt)} </div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* API Key</label>
                                <InputText value={this.state.data.settings && this.state.data.settings.apiKey} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, apiKey: e.target.value } } })} />
                                <div className="p-form-error"> {this.parseMessage(this.state.errors.apiKey)} </div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* Webhook URL</label>
                                <InputText value={this.state.data.settings && this.state.data.settings.webHookUrl} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, webHookUrl: e.target.value } } })} />
                                <span className="webhook-url-notify">Notice: WebHook Url where payment server do POST request after a payment If done</span>
                                <div className="p-form-error"> {this.parseMessage(this.state.errors.webHookUrl)} </div>
                            </div>
                            {/* <div className="p-col-12">
                                <label className="p-label">* Redirect URL</label>
                                <InputText value={this.state.data.settings && this.state.data.settings.redirectUrl} onChange={(e) => this.setState({ data: { ...this.state.data, settings: { ...this.state.data.settings, redirectUrl: e.target.value } } })} />
                                <span className="webhook-url-notify">Notice: Redirect Url where payment server redirect the user after a payment</span>
                                <div className="p-form-error"> {this.parseMessage(this.state.errors.redirectUrl)} </div>
                            </div> */}
                        </React.Fragment>
                    )
            default:
                return ('')
        }
    }

    render() {
        return (
            <div>
                <Sidebar visible={this.state.visible} style={{ overflowY: 'auto',}} className="p-sidebar-md" position="right" blockScroll={true} dismissable={false} showCloseIcon={false} onHide={this.onHide}>
                    <div className="p-d-flex p-justify-between ">
                        <h2 className="p-margin-top-10">{this.state.formHeader}</h2>
                        <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={this.onHide}/>
                    </div>

                    <div className="p-sidebar-line p-mb-3"></div>

                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label className="p-label">{!this.state.data.id?'*':''} Provider</label>
                            {!this.state.data.id ?
                                <React.Fragment>
                                    <Dropdown value={this.state.data.gateway} options={PAYMENT_GATEWAYS} onChange={(e) => this.onGateWayChange(e)} />
                                    <div className="p-form-error"> {this.parseMessage(this.state.errors.gateway)} </div>
                                    {this.state.data.gateway === PAYMENT_GATEWAY.MIDTRANS && 
                                        <div className="p-margin-top-5 p-margin-bottom-15 p-w-bold" style={{color: 'red'}}>Notice: This gateway only can use for currency IDR and no decimal places in price!</div>
                                    }
                                </React.Fragment>
                                : PAYMENT_GATEWAYS.find(p => p.value===this.state.data.gateway)?PAYMENT_GATEWAYS.find( p => p.value===this.state.data.gateway).label:this.state.data.gateway
                            }
                            
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">* Name</label>
                            <InputText value={this.state.data.name} onChange={(e) => this.setState({ data: { ...this.state.data, name: e.target.value } })} />
                            <div className="p-form-error"> {this.parseMessage(this.state.errors.name)} </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Environment</label>
                            <SelectButton value={this.state.data.useMode} options={PAYMENT_USE_MODES} onChange={(e) => this.setState({ data: {...this.state.data, useMode: e.value?e.value:this.state.data.useMode }})}></SelectButton>
                        </div>
                        
                        {this.state.data.gateway &&
                            this.renderPaymentSettings(this.state.data.gateway)
                        }
                    </div>

                    <div className="p-sidebar-line p-my-3"></div>

                    <div className="p-grid">
                        <div className="p-col-12 p-d-flex p-justify-between">
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                            <Button label="Submit" icon="pi pi-save" onClick={() => this.handleSaveForm()}/>
                        </div>
                    </div>
                </Sidebar>
            </div>
        );
    }
}
