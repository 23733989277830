import { createContext } from "react";
import { PRICING_MODE } from "../../constants";

export const OrderContext = createContext({
  mapBranches: null,
  orderNumber: null,
  order: null,
  invoice: null,
  currency: 'USD',
  pricingMode: PRICING_MODE.normal,
  stepProcess: 0,
  processCheckoutLoading: false,
  processOrderError: '',
  addOrUpdateCartItem: () => {},
});