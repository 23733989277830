import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport, moneyFormat } from '../../core/service/CommonService';
import { exportMembershipList, getPageMemberships, removeMembership } from './MembershipServices';
import classNames from 'classnames';
import { MembershipForm } from './MembershipForm';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { MultiSelect } from 'primereact/multiselect';
import { APP_FEP, DATETIME_FORMAT_DISPLAY, IDENTITY_DOCUMENT_TYPE, MEMBERSHIP_CARD_STATUS, MEMBERSHIP_STATUS, PAYMENT_STATE, PRODUCT_PARTICIPANT_TYPE, PRODUCT_TYPE } from '../../constants';
import { updateMembershipStatus } from './MembershipServices';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';
import { SplitButton } from 'primereact/splitbutton';
import { ExtendMembershipForm } from './ExtendMembershipForm';
import { Dropdown } from 'primereact/dropdown';
import { getListProducts } from '../../pim/product/ProductServices';
import { showloading, stoploading } from '../../core/service/LoadingService';

const fileDownload = require('js-file-download');

const ORDER_PAYMENT_STATE = {
	pending: { value: PAYMENT_STATE.pending.value, label: 'Pending' },
	awaiting: { value: PAYMENT_STATE.awaiting.value, label: 'Partial' },
	completed: { value: PAYMENT_STATE.completed.value, label: 'Completed' },
};

const VERIFIED_OPTION = [
	{ value: true, label: 'Yes' },
	{ value: false, label: 'No' },
];

const MEMBERSHIP_STATUS_OPTION = [
	{ value: 'Pending', label: 'Pending' },
	{ value: 'Active', label: 'Active' },
	{ value: 'Block', label: 'Block' },
	{ value: 'Expired', label: 'Expired' },
];

const MEMBERSHIP_REMOVED_OPTION = [
	{ value: false, label: 'No' },
	{ value: true, label: 'Yes' },
];

export const MembershipListView = () => {
	const match = useRouteMatch();
	const history = useHistory();
	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'id',
		sortOrder: -1,
	});
	const [filter, setFilter] = useState({
		statuses: [MEMBERSHIP_STATUS.Pending.value, MEMBERSHIP_STATUS.Active.value, MEMBERSHIP_STATUS.Expired.value, MEMBERSHIP_STATUS.Block.value],
		participantTypes: [PRODUCT_PARTICIPANT_TYPE.adult.value, PRODUCT_PARTICIPANT_TYPE.youth.value],
		deleted: false,
	});
	const [isLoadTable, setLoadTable] = useState(true);
	const [products, setProducts] = useState([]);

	const frmMembership = useRef(null);
	const frmExtendMembership = useRef(null);

	useEffect(() => {
		if (isLoadTable) loadPageMembership();
	}, [isLoadTable]);

	useEffect(() => {
		loadListProduct();
	}, []);

	const handleExport = () => {
		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = 'rpt_membership_' + suf + '.xlsx';

		showloading();
		exportMembershipList(filter)
			.then((res) => {
				if (!res.errorCode) {
					fileDownload(res, fileName);
				} else {
					showErrorNotify(res.errorMessage);
				}
			})
			.finally(() => stoploading());
	};

	const loadPageMembership = () => {
		getPageMemberships(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder).then((res) => {
			setDataList(res.content);
			setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
			setLoadTable(false);
		});
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const loadListProduct = () => {
		getListProducts({ app: APP_FEP, types: [PRODUCT_TYPE.membership.value], participantTypes: [PRODUCT_PARTICIPANT_TYPE.adult.value, PRODUCT_PARTICIPANT_TYPE.youth.value] }).then((res) => {
			if (res) {
				setProducts(res);
			}
		});
	};

	const tblHeader = () => {
		return (
			<TableHeader
				title='Memberships'
				pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
				actionTemplate={
					<React.Fragment>
						{(isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_MGT_W])) && (
							<Button className='p-button-success' label='Create' icon='pi-md-plus' onClick={() => frmMembership.current.openForm(null)} />
						)}
						{(isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_MGT_R])) && <Button className='p-button-success' label='Export' icon='pi pi-download' onClick={() => handleExport()} />}
					</React.Fragment>
				}
				changePageLength={(e) => onChangePageLength(e)}
				refresh={() => setLoadTable(true)}
			/>
		);
	};

	const onDateFromChange = (e) => {
		let maxDate = filter.maxDate ? moment(filter.maxDate) : '';
		let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
		let isCurrentValueAfterMaxDate = currentValue && maxDate && currentValue.isAfter(maxDate);

		setFilter({
			...filter,
			startDateFrom: currentValue ? currentValue.format(moment.HTML5_FMT.DATE) : '',
			dateFromValue: e.value,
			minDate: moment(e.value).isValid() ? e.value : '',
			startDateTo: !isCurrentValueAfterMaxDate ? filter.startDateTo : '',
			dateToValue: !isCurrentValueAfterMaxDate ? filter.dateToValue : '',
			maxDate: !isCurrentValueAfterMaxDate ? filter.maxDate : '',
		});
	};

	const onDateToChange = (e) => {
		let minDate = filter.minDate ? moment(filter.minDate) : '';
		let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
		let isCurrentValueBeforeMinDate = currentValue && minDate && currentValue.isBefore(minDate);

		setFilter({
			...filter,
			startDateFrom: !isCurrentValueBeforeMinDate ? filter.startDateFrom : '',
			dateFromValue: !isCurrentValueBeforeMinDate ? filter.dateFromValue : '',
			minDate: !isCurrentValueBeforeMinDate ? filter.minDate : '',
			startDateTo: currentValue ? currentValue.format(moment.HTML5_FMT.DATE) : '',
			dateToValue: e.value,
			maxDate: moment(e.value).isValid() ? e.value : '',
		});
	};

	const clearFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setFilter({
			...filter,
			name: '',
			ownerName: '',
			ownerLastName: '',
			startDateFrom: '',
			dateFromValue: '',
			minDate: null,
			startDateTo: '',
			dateToValue: '',
			maxDate: null,
			statuses: [MEMBERSHIP_STATUS.Pending.value, MEMBERSHIP_STATUS.Active.value, MEMBERSHIP_STATUS.Expired.value, MEMBERSHIP_STATUS.Block.value],
			identityDocumentNumber: '',
			orderPaymentStates: [],
			templateIds: [],
			isVerified: null,
			memberCardCode: '',
			deleted: false,
		});
		setLoadTable(true);
	};

	const handleUpdateStatus = (status, membershipId) => {
		updateMembershipStatus(membershipId, status).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Membership has been update!');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const handleRemoveMembership = (membershipId, isRemoveMembershipAndUserOwner = false) => {
		removeMembership(membershipId, isRemoveMembershipAndUserOwner).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Membership has been removed!');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const onRemoveMembership = (id) => {
		showConfirmNotify({
			modelActions: [
				{ label: 'Remove Membership and Owner', icon: 'pi large pi-times', command: () => handleRemoveMembership(id, true) },
				{ label: 'Remove', icon: 'pi large pi-times', command: () => handleRemoveMembership(id) },
			],
			message: 'Are you sure want to remove this membership!',
		});
	};

	const onExtendMembershipUsage = (membership) => {
		frmExtendMembership.current.openForm(membership);
	};

	const onKeyPrressEnter = (e) => {
		if (e.key === 'Enter') {
			setLoadTable(true);
		}
	};

	const actionTemplate = (membership) => {
		return (
			<React.Fragment>
				<div className='p-c'>
					{(isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_MGT_W])) && <Button label='Edit' className='p-button-info p-m-1' onClick={() => frmMembership.current.openForm(membership)} />}
					{(isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_MGT_R, ROLES_CONFIG.MEMBERSHIP_MGT_W])) && (
						<React.Fragment>
							{![MEMBERSHIP_STATUS.Canceled.value, MEMBERSHIP_STATUS.Pending.value].includes(membership.status) && (
								<React.Fragment>
									{membership.status === MEMBERSHIP_STATUS.Active.value && (
										<Button label='Block' className='p-button-warning p-m-1' onClick={() => handleUpdateStatus(MEMBERSHIP_STATUS.Block.value, membership.id)} />
									)}
									{membership.status === MEMBERSHIP_STATUS.Block.value && (
										<Button label='Unblock' className='p-button-success p-m-1' onClick={() => handleUpdateStatus(MEMBERSHIP_STATUS.Active.value, membership.id)} />
									)}
									{/* {membership.status!==MEMBERSHIP_STATUS.Block.value && 
                                    <Button label="Renew" className="p-button-success p-mr-1" onClick={() => onExtendMembershipUsage(membership)}/>
                                } */}
								</React.Fragment>
							)}
						</React.Fragment>
					)}
				</div>
				<div className='p-c'>
					{(isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_MGT_D])) && <Button label='Remove' className='p-button-danger p-m-1' onClick={() => onRemoveMembership(membership.id)} />}
				</div>
			</React.Fragment>
		);
	};
	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>Memberships</BreadcrumbsItem>

			<MembershipForm ref={frmMembership} participantTypes={[PRODUCT_PARTICIPANT_TYPE.adult.value, PRODUCT_PARTICIPANT_TYPE.youth.value]} refreshTable={() => setLoadTable(true)} />

			<ExtendMembershipForm ref={frmExtendMembership} reloadTable={() => setLoadTable(true)} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1 className='p-w-bold p-m-0'>Memberships</h1>
					<Fieldset legend='Filters'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12 p-md-6'>
								<div className='p-grid'>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Membership Category:</span>
											<MultiSelect value={filter.templateIds ? filter.templateIds : []} options={products} onChange={(e) => setFilter({ ...filter, templateIds: e.value })} />
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-grid'>
											<div className='p-col-12 p-md-7'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Owner First Name/Email:</span>
													<InputText value={filter.ownerName} onKeyUp={onKeyPrressEnter} onChange={(e) => setFilter({ ...filter, ownerName: e.target.value })} />
												</div>
											</div>
											<div className='p-col-12 p-md-5'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Last Name:</span>
													<InputText value={filter.ownerLastName} onKeyUp={onKeyPrressEnter} onChange={(e) => setFilter({ ...filter, ownerLastName: e.target.value })} />
												</div>
											</div>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-grid'>
											<div className='p-col-12 p-md-6 p-padding-bottom-0 p-padding-top-0'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Work Permit/Passport:</span>
													<InputText
														value={filter.identityDocumentNumber}
														onKeyUp={onKeyPrressEnter}
														onChange={(e) =>
															setFilter({
																...filter,
																identityDocumentNumber: e.target.value,
																identityDocumentTypes: e.target.value ? [IDENTITY_DOCUMENT_TYPE.work_permit.value, IDENTITY_DOCUMENT_TYPE.passport.value] : [],
															})
														}
													/>
												</div>
											</div>
											<div className='p-col-12 p-md-6 p-padding-bottom-0 p-padding-top-0'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Verified:</span>
													<Dropdown value={filter.isVerified} options={VERIFIED_OPTION} showClear onChange={(e) => setFilter({ ...filter, isVerified: e.value })} />
												</div>
											</div>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Card Code:</span>
											<InputText value={filter.memberCardCode ?? ''} onKeyUp={onKeyPrressEnter} onChange={(e) => setFilter({ ...filter, memberCardCode: e.target.value })} />
										</div>
									</div>
								</div>
							</div>
							<div className='p-col-12 p-md-6'>
								<div className='p-grid'>
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Start Date From:</span>
											<MaskedCalendar value={filter.dateFromValue} maxDate={filter.maxDate} onChange={(e) => onDateFromChange(e)} showIcon={true} dateFormat='dd/mm/yy' />
										</div>
									</div>
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Start Date To:</span>
											<MaskedCalendar value={filter.dateToValue} minDate={filter.minDate} onChange={(e) => onDateToChange(e)} showIcon={true} dateFormat='dd/mm/yy' />
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-grid'>
											<div className='p-col-12 p-md-6'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Status:</span>
													<MultiSelect value={filter.statuses ?? []} options={MEMBERSHIP_STATUS_OPTION} onChange={(e) => setFilter({ ...filter, statuses: e.value })} />
												</div>
											</div>
											<div className='p-col-12 p-md-6'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Deleted:</span>
													<Dropdown value={filter.deleted} options={MEMBERSHIP_REMOVED_OPTION} onChange={(e) => setFilter({ ...filter, deleted: e.value })} />
												</div>
											</div>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Payment Status:</span>
											<Dropdown
												value={filter.orderPaymentStates && filter.orderPaymentStates.length > 0 ? filter.orderPaymentStates[0] : null}
												options={Object.values(ORDER_PAYMENT_STATE)}
												showClear
												onChange={(e) => setFilter({ ...filter, orderPaymentStates: e.value ? [e.value] : [] })}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='p-grid'>
							<div className='p-col-12 p-c'>
								<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={() => loadPageMembership()} />
								<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' onClick={() => clearFilter()} />
							</div>
						</div>
					</Fieldset>
				</div>

				<DataTable
					emptyMessage="There's no record found"
					header={tblHeader()}
					value={dataList}
					lazy={true}
					loading={isLoadTable}
					paginator={true}
					first={pageable.page * pageable.rows}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					onPage={onPage}
					onSort={onSort}
					responsive={true}
				>
					<Column
						header='ID'
						field='id'
						bodyClassName='p-c'
						sortable={true}
						body={(rowData) =>
							isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_DETAI_R]) ? (
								<Button
									label={rowData.id}
									icon='pi pi-search'
									className='p-button-info'
									tooltip='View membership'
									tooltipOptions={{ position: 'top' }}
									onClick={() => history.push(`/memberships/${rowData.id}`)}
								/>
							) : (
								rowData.id
							)
						}
					/>
					<Column
						header='Owner'
						field='user.firstName'
						bodyClassName='p-nowrap'
						sortable={true}
						body={(rowData) =>
							rowData.owner && (
								<React.Fragment>
									<div>{rowData.owner.name}</div>
									<div className='p-mt-1'>[{rowData.owner.email}]</div>
									{rowData.owner.phone && <div className='p-mt-1'>{rowData.owner.phone}</div>}
								</React.Fragment>
							)
						}
					/>
					<Column field='name' header='Membership' sortable={true} />
					{/* <Column field="type" header="Type"  bodyClassName="p-c" body={(rowData) => <span className="status">{rowData.type}</span>}/>
                    <Column field="status" header="Status" bodyClassName="p-c" body={(rowData) => <span className={classNames('status', 'ms-'+rowData.status)}>{rowData.status}</span>}/>
                    <Column field="verified" header="Verification" bodyClassName="p-c"
                        body={(rowData) => {
                            let tmpWorkPermit = rowData.owner&&rowData.owner.workPermit?rowData.owner.workPermit:null
                            let tmpPassport = rowData.owner&&rowData.owner.passport?rowData.owner.passport:null
                            return(
                                <React.Fragment>
                                    {tmpWorkPermit && <div className="p-mb-1"><span title="Work permit number">WP</span>: {tmpWorkPermit.docNumber}</div>}
                                    {tmpPassport && <div  className="p-mb-1"><span title="Passport number">PP</span>: {tmpPassport.docNumber}</div>}
                                    <div>
                                        <span className={classNames('status', {'ms-Active': rowData.verified, 'ms-Block': !rowData.verified })}>{!rowData.verified?'NOT YET':'VERIFIED'}</span>
                                    </div>
                                </React.Fragment>
                            )}
                        }
                    /> */}
					<Column
						header='Description'
						body={(rowData) => {
							let tmpWorkPermit = rowData.owner && rowData.owner.workPermit ? rowData.owner.workPermit : null;
							let tmpPassport = rowData.owner && rowData.owner.passport ? rowData.owner.passport : null;
							let isShowDocument = rowData.subscriptionInfo?.price > 0;
							return (
								<div style={{ display: 'inline-block' }}>
									<div className='p-mb-2'>
										Type: <b>{rowData.type}</b>
									</div>
									<div className='p-mb-2'>
										Status: <span className={classNames('status', 'ms-' + rowData.status)}>{rowData.status}</span>
									</div>
									{isShowDocument && (
										<React.Fragment>
											<hr />
											{tmpWorkPermit && (
												<div className='p-mb-1'>
													<span title='Work permit number'>WP</span>: {tmpWorkPermit.docNumber}
												</div>
											)}
											{tmpPassport && (
												<div className='p-mb-1'>
													<span title='Passport number'>PP</span>: {tmpPassport.docNumber}
												</div>
											)}
											<div>
												<span className={classNames('status', { 'ms-Active': rowData.verified, 'ms-Block': !rowData.verified })}>{!rowData.verified ? 'NOT YET' : 'VERIFIED'}</span>
											</div>
										</React.Fragment>
									)}
								</div>
							);
						}}
					/>
					<Column
						header='Card'
						body={(rowData) => {
							if (rowData.card)
								return (
									<React.Fragment>
										<div>{rowData.card?.code}</div>
										<div className='p-mt-1'>[{MEMBERSHIP_CARD_STATUS[rowData.card.status].label}]</div>
									</React.Fragment>
								);
						}}
					/>
					<Column field='joinDate' header='Join Date' bodyClassName='p-r' body={(rowData) => rowData.joinDate && moment(rowData.joinDate).format(DATETIME_FORMAT_DISPLAY)} sortable={true} />
					{/* <Column field="start" header="Start" bodyClassName="p-r" body={(rowData) => rowData.start && moment(rowData.start).format(DATETIME_FORMAT_DISPLAY)} sortable={true}/> */}
					<Column field='end' header='End Date' bodyClassName='p-r' body={(rowData) => rowData.joinDate && moment(rowData.end).format(DATETIME_FORMAT_DISPLAY)} sortable={true} />
					<Column bodyClassName='tc-actions p-r' body={(rowData) => actionTemplate(rowData)} />
				</DataTable>
			</div>
		</div>
	);
};
