import React, { forwardRef, useState, useEffect, useImperativeHandle, useRef } from 'react';
import moment, { HTML5_FMT } from 'moment';
import classNames from 'classnames';
import { SelectButton } from 'primereact/selectbutton';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { showConfirmNotify, showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { Sidebar } from 'primereact/sidebar';
import { Spinner } from 'primereact/spinner';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import { RadioButton } from 'primereact/radiobutton';
import Select from 'react-dropdown-select';
import {
	PRODUCT_CLASSIFICATION,
	APP_FEP,
	PRODUCT_TYPE,
	TAXONOMY_TYPE,
	HR_RESOURCE_TYPE,
	ATTRIBUTE_GROUP_TYPE,
	PRODUCT_IMAGE_TYPE,
	PRICE_APPLY_TYPE,
	GALLERY_TYPE,
	PRODUCT_PRICE_UNIT,
	PRODUCT_OPTION_TYPE,
	VARIANT_OPTION_TYPE,
	TIME_SERIES_REPEAT_TYPE,
	DEPOSIT_TYPE,
	UPLOAD_TYPE,
	EVENT_SESSION_TYPE,
	MAX_FILE_SIZE,
} from '../../constants';
import { titleCaseText, replaceSpaceAndUpperCase, getCurrentUserId } from '../../core/service/CommonService';
import { saveProduct, getProduct, saveProductContent, saveProductAsset, changeProductSetField, getListProducts, removeProductTeaseImage } from './ProductServices';
import { getListDepartments } from '../../scp/departments/DepartmentServices';
import { getListTaxons } from '../taxon/TaxonServices';
import { checkVendorRecommendDiploma, getListHRs } from '../../crm/human_resource/HRServices';
import { getListAttributeSets } from '../../scp/attribute/AttributeServices';
import { ProductPrices } from './ProductPrice';
import CKEditor from 'ckeditor4-react';
import { ProductGallery } from './ProductGallery';
import { ProductAvailability } from './ProductAvailability';
import { ProductVariant } from './ProductVariant';
import { ProductAssociated } from './ProductAssociation';
import { getListAssets } from '../asset/AssetServices';
import { Chips } from 'primereact/chips';
import { getListProductOptions } from '../product_option/ProductOptionServices';
import { ProductCustomFieldForm } from './ProductCustomFieldForm';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { getTaxes } from '../tax/TaxSerivce';
import { getApplications } from '../../scp/application/ApplicationService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { isScpAdmin } from '../../core/security/auth';

CKEditor.editorUrl = '/assets/ckeditor/ckeditor.js';

const CREATE_TYPE = {
	manually: 0,
	autoDaily: 1,
};

const PRODUCT_MENU_ITEMS = {
	basic: { label: 'Basic Information', value: 'basic' },
	content: { label: 'Content', value: 'content' },
	variant: { label: 'Variant', value: 'variant' },
	inventory: { label: 'Inventory', value: 'inventory' },
	availability: { label: 'Session', value: 'availability' },
	associated: { label: 'Associated Products', value: 'associated' },
	asset: { label: 'Related Facility', value: 'asset' },
};

const DEFAULT_TIME_SERIES_DATA = {
	id: null,
	repeatType: TIME_SERIES_REPEAT_TYPE.daily,
	repeatValues: [],
	dateRange: false,
	startDate: null,
	endDate: null,
	startTime: null,
	endTime: null,
	minQty: null,
	maxQty: null,
};

const PRODUCT_TYPES = [
	PRODUCT_TYPE.program,
	PRODUCT_TYPE.rental,
	//PRODUCT_TYPE.membership,
	//PRODUCT_TYPE.session,
	//PRODUCT_TYPE.ticket,
	//PRODUCT_TYPE.addon,
	//PRODUCT_TYPE.donation
];

export const ProductForm = forwardRef((props, ref) => {
	const isCourse = props.isCourse;
	const productCustomProperty = useRef(null);
	const inputUpload = useRef(null);

	const [header, setHeader] = useState('Add Product Option');
	const [visible, setVisible] = useState(false);
	const [menuActive, setMenuActive] = useState(PRODUCT_MENU_ITEMS.basic.value);
	const [productClass, setProductClass] = useState(null);
	const [productId, setProductId] = useState(null);
	const [frmData, setFrmData] = useState({ application: APP_FEP, tags: [], rentalLimit: {} });
	const [timeSeriesData, setTimeSeriesData] = useState(DEFAULT_TIME_SERIES_DATA);
	const [taxes, setTaxes] = useState([]);
	const [branches, setBranches] = useState([]);
	const [departments, setDeparments] = useState([]);
	const [categories, setCategories] = useState([]);
	const [locations, setLocations] = useState([]);
	const [variantOptions, setVariantOptions] = useState([]);
	const [assetCategories, setAssetCategories] = useState([]);
	const [assets, setAssets] = useState([]);
	const [marshallers, setMarshallers] = useState([]);
	const [presenters, setPresenters] = useState([]);
	const [vendors, setVendors] = useState([]);
	const [customFieldSets, setCustomFieldSets] = useState([]);
	const [waiverFieldSets, setWaiverFieldSets] = useState([]);
	const [errors, setErrors] = useState({ prices: {} });
	const [isLoadData, setLoadData] = useState(false);
	const [applications, setApplications] = useState([]);
	const [appConfig, setAppConfig] = useState(null);

	const [tickets, setTickets] = useState([]);
	const [membershipProducts, setMembershipProducts] = useState([]);

	useImperativeHandle(ref, () => ({
		openForm(c, e) {
			setHeader((e ? 'Edit' : 'Add') + ' ' + (isCourse ? (c === PRODUCT_CLASSIFICATION.combo.value ? 'Programme' : 'Training Course') : PRODUCT_CLASSIFICATION[c].label) + ' Product');
			setProductId(e);
			setProductClass(c);
			setMenuActive(PRODUCT_MENU_ITEMS.basic.value);
			setLoadData(true);
			if (props.departmentId || props.vendorIds) {
				let tmpFrmData = { ...frmData };
				if (props.departmentId) {
					tmpFrmData.departmentId = props.departmentId;
				}
				if (props.vendorIds) {
					tmpFrmData.vendorId = props.vendorIds.length > 0 ? props.vendorIds[0] : null;
				}
				setFrmData(tmpFrmData);
			}
		},
	}));

	useEffect(() => {
		let appConfig = localStorage.getItem('appConfig');
		appConfig = appConfig && appConfig !== 'undefined' && appConfig !== 'null' ? JSON.parse(appConfig) : {};
		setAppConfig(appConfig);

		loadAllTaxes();
		loadAllBranchs();
		loadApplications();
		loadMembershipProducts();
		loadAllDepartments();
	}, []);

	useEffect(() => {
		if ([PRODUCT_CLASSIFICATION.simple.value, PRODUCT_CLASSIFICATION.variable.value, PRODUCT_CLASSIFICATION.combo.value].includes(frmData.classification)) {
			if (frmData.type === PRODUCT_TYPE.program.value) {
				loadAllLocations();
				loadAllAttributeSet(ATTRIBUTE_GROUP_TYPE.custom_field.value);
				loadAllAttributeSet(ATTRIBUTE_GROUP_TYPE.waiver.value);
			}

			if (frmData.type === PRODUCT_TYPE.program.value || frmData.type === PRODUCT_TYPE.session.value || frmData.type === PRODUCT_TYPE.rental.value || isCourse) {
				loadAllHRs(HR_RESOURCE_TYPE.marshaller.value);
				loadAllHRs(HR_RESOURCE_TYPE.vendor.value);

				if (frmData.type !== PRODUCT_TYPE.rental.value) {
					if (isCourse) {
						loadAllHRs(HR_RESOURCE_TYPE.trainer.value);
					} else {
						loadAllHRs(HR_RESOURCE_TYPE.speaker.value);
					}
				}

				if (frmData.type === PRODUCT_TYPE.program.value) setTimeSeriesData(DEFAULT_TIME_SERIES_DATA);
			}

			if (!frmData.id) {
				if (frmData.type === PRODUCT_TYPE.program.value) {
					setTimeSeriesData(DEFAULT_TIME_SERIES_DATA);
				} else {
					if (frmData.type === PRODUCT_TYPE.session.value) {
						setTimeSeriesData({
							...timeSeriesData,
							id: null,
							createType: CREATE_TYPE.manually,
							repeatType: TIME_SERIES_REPEAT_TYPE.daily,
						});
					}
				}
			}
		}
	}, [frmData.type, isCourse]);

	useEffect(() => {
		if (frmData.classification === PRODUCT_CLASSIFICATION.variable.value) loadAllProductOptions();
	}, [frmData.classification]);

	useEffect(() => {
		loadAllCategories(frmData.departmentId);
	}, [frmData.departmentId]);

	useEffect(() => {
		loadAllAssetByCategory(frmData.assetCategoryId);
	}, [frmData.assetCategoryId, frmData.assetAcrossBranches]);

	useEffect(() => {
		if (frmData.branchId && frmData.sellByTicket) {
			const ticketFilter = {
				app: APP_FEP,
				branchIds: [frmData.branchId],
				types: [PRODUCT_TYPE.ticket.value],
			};

			loadAllProductTickets(ticketFilter);
		} else {
			setTickets([]);
			setFrmData({ ...frmData, relatedTickets: [] });
		}
	}, [frmData.branchId, frmData.sellByTicket]);

	const loadAllProductTickets = (filter) => {
		getListProducts(filter).then((res) => {
			if (frmData.relatedTickets && frmData.relatedTickets.length > 0) {
				let tmpRelatedTickets = frmData.relatedTickets.filter((x1) => res.some((x2) => x2.value === x1));

				setFrmData({ ...frmData, relatedTickets: tmpRelatedTickets });
			}

			setTickets(res);
		});
	};

	const loadAllTaxes = () => {
		getTaxes().then((res) => setTaxes(res));
	};

	const loadAllBranchs = () => {
		getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.branch.value }).then((res) => setBranches(res));
	};

	const loadAllDepartments = () => {
		getListDepartments({ externalRef: false }).then((res) => setDeparments(res));
	};

	const loadAllCategories = (deptId) => {
		if (!deptId) {
			setCategories([]);
			return;
		}

		getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.product.value], departmentIds: [deptId] }).then((res) => setCategories(res));
	};

	const loadAllAssetCategories = () => {
		getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.asset.value] }).then((res) => setAssetCategories(res));
	};

	const loadAllLocations = () => {
		getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.location.value }).then((res) => setLocations(res));
	};

	const loadAllHRs = (type) => {
		let tmpFilter = { type: type };

		if (!isScpAdmin() && props.vendorIds) {
			if (type === HR_RESOURCE_TYPE.vendor.value) {
				tmpFilter.includeIds = props.vendorIds;
			} else if (type === HR_RESOURCE_TYPE.trainer.value) {
				tmpFilter.parentIds = props.vendorIds;
			}
		}
		getListHRs(tmpFilter).then((res) => {
			switch (type) {
				case HR_RESOURCE_TYPE.marshaller.value:
					setMarshallers(res);
					break;
				case HR_RESOURCE_TYPE.vendor.value:
					setVendors(res);
					break;
				case HR_RESOURCE_TYPE.trainer.value:
				case HR_RESOURCE_TYPE.speaker.value:
					setPresenters(res);
					break;
				default:
					break;
			}
		});
	};

	const loadAllProductOptions = () => {
		getListProductOptions({ app: APP_FEP, type: PRODUCT_OPTION_TYPE.variant.value }).then((res) => {
			setVariantOptions(res);
		});
	};

	const loadAllAttributeSet = (type) => {
		getListAttributeSets(type).then((res) => {
			switch (type) {
				case ATTRIBUTE_GROUP_TYPE.custom_field.value:
					setCustomFieldSets(res);
					break;
				case ATTRIBUTE_GROUP_TYPE.waiver.value:
					setWaiverFieldSets(res);
					break;
				default:
					break;
			}
		});
	};

	const loadAllAssetByCategory = (categoryId) => {
		if (!categoryId) {
			setAssets([]);
			return;
		}

		let branchIds = [frmData.branchId];

		if (frmData.assetAcrossBranches && frmData.assetAcrossBranches.length > 0) branchIds = branchIds.concat(frmData.assetAcrossBranches);

		getListAssets({ app: APP_FEP, branchIds: branchIds, categoryId: categoryId }).then((res) => {
			if (frmData.assetExcludedIds && frmData.assetExcludedIds.length > 0) {
				let tmpExcludedIds = frmData.assetExcludedIds.filter((x1) => res.some((x2) => x2.value === x1));

				setFrmData({ ...frmData, assetExcludedIds: tmpExcludedIds });
			}

			setAssets(res);
		});
	};

	const loadApplications = () => {
		getApplications(null).then((data) => {
			setApplications(data ? data : []);
		});
	};

	const loadMembershipProducts = () => {
		getListProducts({ app: APP_FEP, types: [PRODUCT_TYPE.membership.value] }).then((res) => {
			let tmp = res ? res : [];
			setMembershipProducts(tmp);
		});
	};

	useEffect(() => {
		if (isLoadData) {
			async function loadProduct() {
				if (productId) {
					const res = await getProduct(productId);

					if (!res.errorCode) {
						setFrmData(popularFormData(res));
						if (res.type && res.type === PRODUCT_TYPE.rental.value) loadAllAssetCategories();

						if (res.type === PRODUCT_TYPE.session.value) {
							setTimeSeriesData(popularAvailabilityData(res.availabilities && res.availabilities.length > 0 ? res.availabilities[0] : null));
						}

						setErrors({ prices: {} });
						setVisible(true);
					} else {
						showErrorNotify(res.errorMessage);
						setVisible(false);
					}
				} else {
					setFrmData(popularFormData(null));
					setAssetCategories([]);
					setErrors({ prices: {} });
					setVisible(true);
				}
			}

			loadProduct();
		}
	}, [isLoadData]);

	const popularAvailabilityData = (e) => {
		return {
			id: e ? e.id : null,
			createType: e ? e.createType : CREATE_TYPE.manually,
			repeatType: e ? e.repeatType : TIME_SERIES_REPEAT_TYPE.daily,
			repeatValues: e ? e.repeatValues : [],
			dateRange: e && e.startDate !== e.endDate ? true : false,
			startDate: e ? e.startDate : null,
			endDate: e ? e.endDate : null,
			startTime: e ? e.startTime : null,
			endTime: e ? e.endTime : null,
			startDateValue: e && e.startDate ? moment(e.startDate, HTML5_FMT.DATE).toDate() : null,
			endDateValue: e && e.endDate ? moment(e.endDate, HTML5_FMT.DATE).toDate() : null,
			startTimeValue: e && e.startTime ? moment(e.startTime, HTML5_FMT.TIME).toDate() : null,
			endTimeValue: e && e.endTime ? moment(e.endTime, HTML5_FMT.TIME).toDate() : null,
			minQty: e && e.minQty ? e.minQty : '',
			maxQty: e && e.maxQty ? e.maxQty : '',
		};
	};

	const closeForm = () => {
		setVisible(false);
		setLoadData(false);
		setProductId(null);
		setFrmData(popularFormData());
		setTimeSeriesData(DEFAULT_TIME_SERIES_DATA);
		setErrors({ prices: {} });
	};

	const getApplicationLogDefault = (productType) => {
		let tmpApplicationLogs = [];
		if (productType) {
			if (appConfig.programConfig && appConfig.programConfig.applicationLogs && appConfig.programConfig.applicationLogs[productType]) {
				tmpApplicationLogs = appConfig.programConfig.applicationLogs[productType];
			}
		}
		return tmpApplicationLogs;
	};

	const popularFormData = (e) => {
		const data = {
			id: e ? e.id : '',
			code: e ? e.code : '',
			name: e ? e.name : '',
			title: e ? e.title : '',
			application: APP_FEP,
			classification: e ? e.classification : productClass,
			type: e ? e.type : productClass === PRODUCT_CLASSIFICATION.simple.value || productClass === PRODUCT_CLASSIFICATION.variable.value || isCourse ? PRODUCT_TYPE.program.value : null,
			sessionType: e && e.id ? null : EVENT_SESSION_TYPE.live.value,
			description: e ? e.description : '',
			content: e ? e.content : null,
			teaserImage: e && e.teaserImage && Object.keys(e.teaserImage).length > 0 ? e.teaserImage : null,
			teaserImageUrl: e && e.teaserImage ? (e.teaserImage.fileName ? '' : e.teaserImage.url) : '',
			galleryImages: e && e.galleryImages ? e.galleryImages : [],
			videos: e && e.videos ? e.videos : [],
			marshallerId: e && e.marshaller ? e.marshaller.id : null,
			vendorId: e && e.vendor ? e.vendor.id : props.vendorIds && props.vendorIds.length > 0 ? props.vendorIds[0] : null,
			presenterIds: e && e.presenters ? e.presenters.map((p) => p.id) : [],
			showPresenter: e ? e.showPresenter : false,
			tags: e && e.tags ? e.tags : [],
			applyTax: e ? e.applyTax : false,
			taxId: e ? e.taxId : appConfig.defaultTax,
			weight: e ? e.weight : 0,
			active: e ? e.active : true,
			show: e ? e.show : false,
			displayOnPanel: e && e.displayOnPanel,
			allowViewDetails: e ? e.allowViewDetails : false,
			courseProgram: e ? e.courseProgram : isCourse ? true : false,
			sellByTicket: e ? e.sellByTicket : false,
			relatedTicketIds: e ? e.relatedTicketIds : [],
			loginRequired: e ? e.loginRequired : false,
			sellExtraAsCoupon: e ? e.sellExtraAsCoupon : false,
			recommended: e ? e.recommended : false,
			free: e ? e.free : false,
			pricedBySelected: e ? e.pricedBySelected : false,
			prices: e && e.prices ? e.prices : [{ applyFor: PRICE_APPLY_TYPE.normal.value, price: '', unit: PRODUCT_PRICE_UNIT.day.value, quantity: 1 }],
			allowDeposit: e ? e.allowDeposit : false,
			depositType: e && e.allowDeposit ? e.depositType : null,
			allowPayFull: e ? e.allowPayFull : true,
			subscription: e ? e.subscription : false,
			rentalLimit: e && e.rentalLimit ? e.rentalLimit : { minUnit: PRODUCT_PRICE_UNIT.hr.value, maxUnit: PRODUCT_PRICE_UNIT.hr.value, availableWeekdays: [] },
			depositAmount: e ? e.depositAmount : '',
			branchId: e ? e.branchId : branches.length === 1 ? branches[0].value : null,
			branchName: e ? e.branchName : null,
			departmentId: e ? e.departmentId : props.departmentId,
			categoryIds: e ? e.categoryIds : [],
			locationId: e ? e.locationId : null,
			productCustomProperty: e ? e.productCustomProperty : null,
			waiverFieldSetId: e ? e.waiverFieldSetId : null,
			useApplicationLog: e && e.useApplicationLog,
			applicationLogs: e && e.applicationLogs ? e.applicationLogs : getApplicationLogDefault(e ? e.type : PRODUCT_TYPE.program.value),
			optionSelect: e && e.options ? e.options.map((o) => o.id) : [],
			options: e ? e.options : [],
			pricedByVariant: e ? e.pricedByVariant : false,
			variantOptionType:
				e && e.classification === PRODUCT_CLASSIFICATION.variable.value ? e.variantOptionType : productClass === PRODUCT_CLASSIFICATION.variable.value ? VARIANT_OPTION_TYPE.option.value : null,
			variantOptionSelect: e && e.classification === PRODUCT_CLASSIFICATION.variable.value && e.variantOptionType === VARIANT_OPTION_TYPE.option.value && e.options ? e.options.map((o) => o.id) : [],
			variantSelectionType: e ? e.variantSelectionType : 0,
			variants: e ? e.variants : [],
			associatedGroups: e ? e.associatedGroups : [],
			assetCategoryId: e ? e.assetCategoryId : null,
			assetAcrossBranches: e && e.assetAcrossBranches ? e.assetAcrossBranches : [],
			assetExcludedIds: e && e.assetExcludedIds ? e.assetExcludedIds : [],
			slot: e && e.slot ? e.slot : '',
			requiredMembershipCard: e && e.requiredMembershipCard,
		};

		return data;
	};

	const onTypeChange = (e) => {
		let isFree = frmData.free;
		if ([PRODUCT_TYPE.rental.value, PRODUCT_TYPE.donation.value].includes(e.value)) isFree = false;

		let tmpApplicationLogs = getApplicationLogDefault(e.value);
		if (frmData.id) {
			tmpApplicationLogs = frmData.applicationLogs;
		}
		setFrmData({ ...frmData, type: e.value, free: isFree, applicationLogs: tmpApplicationLogs });
	};

	const onChangeAssetAcrossBranch = (values) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		setFrmData({ ...frmData, assetAcrossBranches: arrValues });
	};

	const onChangePresenter = (values) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		setFrmData({ ...frmData, presenterIds: arrValues });
	};

	const onDepartmentChange = (e) => {
		setFrmData({ ...frmData, departmentId: e, categoryIds: [] });
	};

	const onChangeCategory = (values) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		setFrmData({ ...frmData, categoryIds: arrValues });
	};

	const onChangeRelatedTickets = (values) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		setFrmData({ ...frmData, relatedTicketIds: arrValues });
	};

	const onRentalLimitWeekDaysChange = (e) => {
		let selectedValues = [...frmData.rentalLimit.availableWeekdays];

		if (e.checked) selectedValues.push(e.value);
		else selectedValues.splice(selectedValues.indexOf(e.value), 1);

		setFrmData({ ...frmData, rentalLimit: { ...frmData.rentalLimit, availableWeekdays: selectedValues } });
	};

	const onWeekDaysChange = (e) => {
		let selectedValues = [...timeSeriesData.repeatValues];

		if (e.checked) selectedValues.push(e.value);
		else selectedValues.splice(selectedValues.indexOf(e.value), 1);

		setTimeSeriesData({ ...timeSeriesData, repeatValues: selectedValues });
	};

	const onDateChange = (e, key) => {
		switch (key) {
			case 'start':
				setTimeSeriesData({ ...timeSeriesData, startDateValue: e });
				break;
			case 'end':
				setTimeSeriesData({ ...timeSeriesData, endDateValue: e });
				break;
			default:
				break;
		}
	};

	const onDateBlur = (e, key) => {
		switch (key) {
			case 'start':
				if (!timeSeriesData.startDateValue || !moment(timeSeriesData.startDateValue).isValid()) setTimeSeriesData({ ...timeSeriesData, startDateValue: null });
				break;
			case 'end':
				if (!timeSeriesData.endDateValue || !moment(timeSeriesData.endDateValue).isValid()) setTimeSeriesData({ ...timeSeriesData, endDateValue: null });
				break;
			default:
				break;
		}
	};

	const onTimeChange = (e, key) => {
		switch (key) {
			case 'start':
				setTimeSeriesData({ ...timeSeriesData, startTimeValue: e });
				break;
			case 'end':
				setTimeSeriesData({ ...timeSeriesData, endTimeValue: e });
				break;
			default:
				break;
		}
	};

	const onTimeBlur = (key) => {
		switch (key) {
			case 'start':
				if (!timeSeriesData.startTimeValue || !moment(timeSeriesData.startTimeValue).isValid()) setTimeSeriesData({ ...timeSeriesData, startTimeValue: null });
				break;
			case 'end':
				if (!timeSeriesData.endTimeValue || !moment(timeSeriesData.endTimeValue).isValid()) setTimeSeriesData({ ...timeSeriesData, endTimeValue: null });
				break;
			default:
				break;
		}
	};

	const onChangeVariantOptions = (values, type) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		if (type === 'variant') setFrmData({ ...frmData, variantOptionSelect: arrValues });
		else setFrmData({ ...frmData, propertyOptionSelect: arrValues });
	};

	const onChangeAssetExclude = (values) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		setFrmData({ ...frmData, assetExcludedIds: arrValues });
	};

	const onChangeApplicationLog = (values) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		setFrmData({ ...frmData, applicationLogs: arrValues });
	};

	const handleSaveProduct = async () => {
		showloading();

		if (isCourse && frmData.classification === PRODUCT_CLASSIFICATION.combo.value && frmData.recommended && frmData.vendorId) {
			// console.log(frmData.vendorId)
			let CheckVendorRecommendDiploma = await checkVendorRecommendDiploma(frmData.id, frmData.vendorId);
			stoploading();
			if (CheckVendorRecommendDiploma.errorCode) {
				showErrorNotify(CheckVendorRecommendDiploma.errorMessage);
				return;
			}
		}

		let data = { ...frmData };
		let images = [];

		if (data.teaserImageUrl || frmData.teaserImageFile) {
			if (frmData.uploadType === UPLOAD_TYPE.upload_file.value && frmData.teaserImageFile) {
				let fileSize = frmData.teaserImageFile.size;
				if (fileSize > MAX_FILE_SIZE) {
					setErrors({ image: 'Size not allowed (maximum: 100MB)' });
					showErrorNotify('Cannot perform action');
					stoploading();
					return;
				}
			}
			images.push(
				(frmData.teaserImage = {
					type: PRODUCT_IMAGE_TYPE.teaser,
					uploadType: frmData.uploadType,
					url: !frmData.uploadType || frmData.uploadType === UPLOAD_TYPE.external_url.value ? frmData.teaserImageUrl : '',
					file: frmData.uploadType === UPLOAD_TYPE.upload_file.value ? frmData.teaserImageFile : null,
				})
			);
		}
		data.images = images.concat(data.galleryImages);

		if ((!frmData.id && frmData.type === PRODUCT_TYPE.program.value) || frmData.type === PRODUCT_TYPE.session.value) {
			let tmpTimeSeriesData = { ...timeSeriesData };
			tmpTimeSeriesData.startDate = tmpTimeSeriesData.startDateValue && moment(tmpTimeSeriesData.startDateValue).isValid() ? moment(tmpTimeSeriesData.startDateValue).format(HTML5_FMT.DATE) : '';
			tmpTimeSeriesData.endDate = tmpTimeSeriesData.endDateValue && moment(tmpTimeSeriesData.endDateValue).isValid() ? moment(tmpTimeSeriesData.endDateValue).format(HTML5_FMT.DATE) : '';
			tmpTimeSeriesData.startTime = tmpTimeSeriesData.startTimeValue && moment(tmpTimeSeriesData.startTimeValue).isValid() ? moment(tmpTimeSeriesData.startTimeValue).format(HTML5_FMT.TIME) : '';
			tmpTimeSeriesData.endTime = tmpTimeSeriesData.endTimeValue && moment(tmpTimeSeriesData.endTimeValue).isValid() ? moment(tmpTimeSeriesData.endTimeValue).format(HTML5_FMT.TIME) : '';
			data.timeSeries = tmpTimeSeriesData;
		}

		saveProduct(data)
			.then((res) => {
				if (!res.errorCode) {
					setFrmData(popularFormData(res));

					if (res.type === PRODUCT_TYPE.session.value) {
						setTimeSeriesData(popularAvailabilityData(res.availabilities && res.availabilities.length > 0 ? res.availabilities[0] : null));
					}

					if (!data.id && data.type === PRODUCT_TYPE.rental.value) loadAllAssetCategories();

					setProductId(res.id);
					setErrors({ prices: {} });

					if (props.refreshTable) props.refreshTable();

					if (inputUpload && inputUpload.current) {
						inputUpload.current.value = '';
					}

					showSuccessNotify('Product saved!');
				} else {
					showErrorNotify(res.errorMessage);
					if (res.errorCode === 400) setErrors(res.errorObj);
				}
			})
			.catch(() => showErrorNotify('System error!'))
			.finally(() => stoploading());
	};

	const handleSaveProductContent = () => {
		showloading();

		let data = { ...frmData };

		if (frmData.galleryImages) {
			let totalFileSize = 0;
			for (const galleryImage of frmData.galleryImages) {
				if (galleryImage.file) {
					totalFileSize += galleryImage.file.size;
				}
			}
			if (totalFileSize > MAX_FILE_SIZE) {
				setErrors({ image: 'Total file size not allowed (maximum: 100MB)' });
				return;
			}
		}

		let images = [];

		if (frmData.teaserImageUrl) {
			images.push(
				(frmData.teaserImage = {
					type: PRODUCT_IMAGE_TYPE.teaser,
					url: frmData.teaserImageUrl,
				})
			);
		}
		if (frmData.galleryImages) data.images = images.concat(frmData.galleryImages);

		saveProductContent(data)
			.then((res) => {
				if (!res.errorCode) {
					setFrmData(popularFormData(res));
					if (props.refreshTable) props.refreshTable();
					showSuccessNotify('Product saved!');
				} else {
					showErrorNotify(res.errorMessage);
					if (res.errorCode === 400) setErrors(res.errorObj);
				}
			})
			.finally(() => stoploading());
	};

	const handleSaveProductAsset = () => {
		showloading();

		saveProductAsset(frmData).then((res) => {
			if (!res.errorCode) {
				showSuccessNotify('Product saved!');
			} else {
				showErrorNotify(res.errorMessage);
				if (res.errorCode === 400) setErrors(res.errorObj);
			}

			stoploading();
		});
	};

	const renderProductMenuItems = () => {
		const c = frmData.classification;
		const t = frmData.type;

		let items = [PRODUCT_MENU_ITEMS.basic, PRODUCT_MENU_ITEMS.content];

		if (c === PRODUCT_CLASSIFICATION.simple.value && frmData.variants.length > 0) {
			items.push(PRODUCT_MENU_ITEMS.inventory);
		}

		if (c === PRODUCT_CLASSIFICATION.variable.value) items.push(PRODUCT_MENU_ITEMS.variant);

		if (c !== PRODUCT_CLASSIFICATION.combo.value && c !== PRODUCT_CLASSIFICATION.configuration.value && t === PRODUCT_TYPE.program.value) items.push(PRODUCT_MENU_ITEMS.availability);

		// if((c===PRODUCT_CLASSIFICATION.combo.value || c===PRODUCT_CLASSIFICATION.configuration.value || ((c===PRODUCT_CLASSIFICATION.simple.value || c===PRODUCT_CLASSIFICATION.variable.value) && t===PRODUCT_TYPE.program.value)))
		if (c === PRODUCT_CLASSIFICATION.combo.value || c === PRODUCT_CLASSIFICATION.configuration.value) items.push(PRODUCT_MENU_ITEMS.associated);

		if ((c === PRODUCT_CLASSIFICATION.simple.value || c === PRODUCT_CLASSIFICATION.variable.value) && t === PRODUCT_TYPE.rental.value) items.push(PRODUCT_MENU_ITEMS.asset);

		return items;
	};

	const viewProductCustomFields = () => {
		productCustomProperty.current.popularFormData(frmData.productCustomProperty);
	};

	const onCustomFieldChange = (customFields, isRemoved) => {
		let data = {
			...frmData,
			productCustomProperty: {
				...frmData.productCustomProperty,
				customFields: customFields,
			},
		};

		saveProduct(data).then((res) => {
			if (!res.errorCode) {
				if (props.refreshTable) props.refreshTable();

				setFrmData(popularFormData({ ...frmData, productCustomProperty: res.productCustomProperty }));
				setProductId(res.id);
				setErrors({ prices: {} });

				productCustomProperty.current.popularFormData(res.productCustomProperty.customFields);

				if (isRemoved) {
					showSuccessNotify('Field removed!');
				} else {
					showSuccessNotify('Field updated!');
				}
			} else {
				showErrorNotify(res.errorMessage);
				if (res.errorCode === 400) setErrors(res.errorObj);
			}
		});
	};

	const onCreateTypeChange = (e) => {
		setTimeSeriesData({
			...timeSeriesData,
			createType: e,
			dateRange: e === CREATE_TYPE.autoDaily ? true : false,
		});
	};

	const handleChangeSetField = (customSetId, customSetName, numOfFieldDisplay) => {
		changeProductSetField(frmData.id, customSetId, customSetName, numOfFieldDisplay).then((data) => {
			if (!data.errorCode) {
				if (props.refreshTable) props.refreshTable();

				setFrmData(popularFormData({ ...frmData, productCustomProperty: data }));
				setErrors({ prices: {} });

				productCustomProperty.current.popularFormData(data);
				productCustomProperty.current.clearSetId();
				showSuccessNotify('Field set changed!');
			} else {
				showErrorNotify(data.errorMessage);
				if (data.errorCode === 400) setErrors(data.errorObj);
			}
		});
	};

	const handleRemoveTeaseImage = (productId) => {
		removeProductTeaseImage(productId).then((res) => {
			if (!res.errorCode) {
				setFrmData({ ...frmData, teaserImage: null, teaserImageUrl: null });
				showSuccessNotify('Action submitted!');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const onRemoveProductTeaseImage = (productId) => {
		showConfirmNotify({
			accept: () => handleRemoveTeaseImage(productId),
			message: 'Are you sure to remove this image? This action cannot be undo.',
		});
	};

	const reloadTable = (res) => {
		if (props.refreshTable) {
			props.refreshTable();
		}

		setFrmData(popularFormData(res));

		productCustomProperty.current.popularFormData(res.productCustomProperty.customFields);
	};

	return (
		<React.Fragment>
			<ProductCustomFieldForm
				ref={productCustomProperty}
				productId={frmData.id}
				reloadTable={(res) => reloadTable(res)}
				onCustomFieldChange={(customFields, isRemoved) => onCustomFieldChange(customFields, isRemoved)}
				onChangeFieldSet={(customSetId, customSetName, numOfFieldDisplay) => handleChangeSetField(customSetId, customSetName, numOfFieldDisplay)}
			/>

			<Sidebar visible={visible} position='right' className='p-sidebar-lg' style={{ overflowY: 'auto' }} blockScroll={true} showCloseIcon={false} dismissable={true} onHide={() => closeForm()}>
				<div className='p-d-flex p-justify-between '>
					<h2 className='p-margin-top-10'>{header}</h2>
					<Button label='' icon='pi pi-times' className='p-button-secondary' onClick={() => closeForm()} />
				</div>

				<div className='p-sidebar-line p-mb-3'></div>

				<div className='p-grid'>
					<div className='p-col-12'>
						{frmData.id && (
							<React.Fragment>
								{menuActive !== PRODUCT_MENU_ITEMS.basic.value && (
									<React.Fragment>
										<div>
											Product:{' '}
											<strong>
												{frmData.code} - {frmData.name}
											</strong>
										</div>
										{branches.length > 1 && (
											<div className='p-mt-2 p-mb-3'>
												Branch: <strong>{frmData.branchName}</strong>
											</div>
										)}
									</React.Fragment>
								)}
								<SelectButton value={menuActive} className='p-margin-10-0' options={renderProductMenuItems()} onChange={(e) => e && e.value && setMenuActive(e.value)}></SelectButton>
								<div className='p-hr p-margin-bottom-20'></div>
							</React.Fragment>
						)}

						<div className={classNames({ 'p-hide': menuActive !== PRODUCT_MENU_ITEMS.basic.value })}>
							<div className='p-grid'>
								<div className='p-col-12 p-md-6'>
									<Fieldset legend='Basic Information'>
										<div className='p-grid p-fluid form-group'>
											{!isCourse && [PRODUCT_CLASSIFICATION.simple.value, PRODUCT_CLASSIFICATION.variable.value].includes(frmData.classification) && (
												<div className='p-col-12'>
													{!frmData.id ? (
														<React.Fragment>
															<label className='p-label'>* Type</label>
															<Dropdown value={frmData.type} options={PRODUCT_TYPES} onChange={(e) => onTypeChange(e)} placeholder='Select a product type' />
															<span className='p-form-error'>{errors.type}</span>
														</React.Fragment>
													) : (
														<div>
															Type: <strong>{PRODUCT_TYPE[frmData.type].label}</strong>
														</div>
													)}
												</div>
											)}

											{branches.length > 1 && (
												<div className='p-col-12'>
													{!frmData.id ? (
														<React.Fragment>
															<label className='p-label'>* Branch</label>
															<Dropdown value={frmData.branchId} options={branches} onChange={(e) => setFrmData({ ...frmData, branchId: e.value })} placeholder='Select branch' />
															<span className='p-form-error'>{errors.branch}</span>
														</React.Fragment>
													) : (
														<div>
															Branch: <strong>{frmData.branchName}</strong>
														</div>
													)}
												</div>
											)}

											{frmData.id && ((!isCourse && [PRODUCT_CLASSIFICATION.simple.value, PRODUCT_CLASSIFICATION.variable.value].includes(frmData.classification)) || branches.length > 1) && (
												<div className='p-col-12'>
													<div className='p-hr p-py-0'></div>
												</div>
											)}
										</div>
										<div className='p-grid p-fluid form-group p-mt-0'>
											<div className='p-col-12 p-md-4'>
												<label className='p-label'>* Code</label>
												<InputText value={frmData.code} keyfilter={/[^\s]/} onChange={(e) => setFrmData({ ...frmData, code: replaceSpaceAndUpperCase(e.target.value) })} />
												<span className='p-form-error'>{errors.code}</span>
											</div>
											<div className='p-col-12 p-md-8'>
												<label className='p-label'>* Name</label>
												<InputText value={frmData.name} onChange={(e) => setFrmData({ ...frmData, name: titleCaseText(e.target.value) })} />
												<span className='p-form-error'>{errors.name}</span>
											</div>
											{frmData.type === PRODUCT_TYPE.session.value && (
												<React.Fragment>
													<div className='p-col-12'>
														<label className='p-label'>Topic</label>
														<InputText value={frmData.title} onChange={(e) => setFrmData({ ...frmData, title: e.target.value })} />
													</div>

													{!frmData.id && (
														<div className='p-col-12'>
															<label className='p-label'>* Session Type</label>
															<Dropdown value={frmData.sessionType} options={Object.values(EVENT_SESSION_TYPE)} onChange={(e) => setFrmData({ ...frmData, sessionType: e.value })} />
															<span className='p-form-error'>{errors.sessionType}</span>
														</div>
													)}
												</React.Fragment>
											)}
											<div className='p-col-12'>
												<label className='p-label'>Teaser image url</label>
												<div className='p-inputgroup'>
													{(!frmData.uploadType && (!frmData.teaserImage || !frmData.teaserImage.fileName)) || frmData.uploadType === UPLOAD_TYPE.external_url.value ? (
														<React.Fragment>
															<InputText value={frmData.teaserImageUrl} onChange={(e) => setFrmData({ ...frmData, teaserImageUrl: e.target.value, uploadType: UPLOAD_TYPE.external_url.value })} />
															<Button
																tooltip='Switch to upload'
																icon='pi pi-upload'
																style={{ borderRadius: 0 }}
																onClick={() => setFrmData({ ...frmData, uploadType: UPLOAD_TYPE.upload_file.value })}
															/>
														</React.Fragment>
													) : (
														<React.Fragment>
															{frmData.teaserImage && frmData.teaserImage.fileName && (
																<span className='p-inputgroup-addon'>
																	<img src={frmData.teaserImage.url} fileName={frmData.teaserImage.fileName} alt='' style={{ width: 25, height: 25 }} />
																</span>
															)}
															<input
																ref={inputUpload}
																className='p-inputtext p-component'
																type='file'
																accept='image/png, image/jpg, image/jpeg'
																onChange={(e) => setFrmData({ ...frmData, teaserImageFile: e.target.files[0], uploadType: UPLOAD_TYPE.upload_file.value })}
															/>
															<span className='p-inputgroup-addon'>
																{productId && frmData?.teaserImage?.fileName && (
																	<Button className='p-margin-right-10' tooltip='Remove Image' icon='pi pi-times' style={{ borderRadius: 0 }} onClick={() => onRemoveProductTeaseImage(productId)} />
																)}
																<Button
																	tooltip='Switch to url'
																	icon='pi pi-external-link'
																	style={{ borderRadius: 0 }}
																	onClick={() => setFrmData({ ...frmData, uploadType: UPLOAD_TYPE.external_url.value, teaserImageFile: null })}
																/>
															</span>
														</React.Fragment>
													)}
												</div>
												<div className='p-form-error'>{errors.image}</div>
											</div>
											<div className='p-col-12'>
												<label className='p-label'>Description</label>
												<InputTextarea value={frmData.description} rows='5' onChange={(e) => setFrmData({ ...frmData, description: e.target.value })} />
												<span className='p-form-error'>{errors.description}</span>
											</div>
											{/* {[PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value, PRODUCT_TYPE.session.value].includes(frmData.type) && 
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <label className="p-label">Marshaller</label>
                                                    <Dropdown value={frmData.marshallerId} options={marshallers} onChange={(e) => setFrmData({...frmData, marshallerId: e.value})} placeholder="Select marshaller"/>
                                                </div>
                                                {[PRODUCT_TYPE.program.value, PRODUCT_TYPE.session.value].includes(frmData.type) && 
                                                <div className="p-col-12">
                                                    <React.Fragment>
                                                        <label className="p-label">{frmData.type===PRODUCT_TYPE.program.value?'Trainers':'Speakers'}</label>
                                                        <div className="p-inputgroup">
                                                            <div className="p-dropdown p-component">
                                                            <Select multi
                                                                values={presenters && frmData.presenterIds && frmData.presenterIds.length>0 ? presenters.filter(x1 => frmData.presenterIds.some(x2 => x2 === x1.value)) : []}
                                                                options={presenters} onChange={(values) => onChangePresenter(values)}
                                                                style={{ width: '100%' }} placeholder="Select speaker"
                                                                noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No Data</span>) }}
                                                            />
                                                            </div>
                                                            <span className="p-inputgroup-addon">
                                                                <Checkbox inputId="ckShowPresenter" checked={frmData.showPresenter} onChange={e => setFrmData({...frmData, showPresenter: e.checked})} />
                                                                <label htmlFor="ckShowPresenter">Is show?</label>
                                                            </span>
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                                }
                                            </React.Fragment>
                                            } */}
										</div>
										<div className='p-grid p-fluid form-group'>
											<div className='p-col-12 p-md-4'>
												<label className='p-label'>Weight (for order)</label>
												<InputText value={frmData.weight} keyfilter='int' onChange={(e) => setFrmData({ ...frmData, weight: e.target.value })} />
											</div>
											<div className='p-col-12 p-md-8'>
												<div className='p-grid p-fluid form-group'>
													{/* <div className="p-col-12" style={{paddingTop: '27px'}}>
                                                        <InputSwitch checked={frmData.active} onChange={(e) => setFrmData({...frmData, active: e.value })} />
                                                        <label className="p-margin-left-10">Active?</label>
                                                    </div> */}
													{/* {frmData.type===PRODUCT_TYPE.program.value && 
                                                    <div className="p-col-12">
                                                        <InputSwitch checked={frmData.courseProgram} onChange={(e) => setFrmData({...frmData, courseProgram: e.value })} />
                                                        <label className="p-margin-left-10">Is course training?</label>
                                                    </div>
                                                    } */}
													<div className='p-col-12'>
														<InputSwitch checked={frmData.show} onChange={(e) => setFrmData({ ...frmData, show: e.value })} />
														<label className='p-margin-left-10'>Show in store as product?</label>
													</div>
													<div className='p-col-12'>
														<InputSwitch checked={frmData.displayOnPanel} onChange={(e) => setFrmData({ ...frmData, displayOnPanel: e.value })} />
														<label className='p-margin-left-10'>Show on display panel?</label>
													</div>
													{/* <div className="p-col-12">
                                                        <InputSwitch checked={frmData.allowViewDetails} onChange={(e) => setFrmData({...frmData, allowViewDetails: e.value })} />
                                                        <label className="p-margin-left-10">Allow view details?</label>
                                                    </div> */}
													{/* <div className="p-col-12">
                                                        <InputSwitch checked={frmData.loginRequired} onChange={(e) => setFrmData({...frmData, loginRequired: e.value })} />
                                                        <label className="p-margin-left-10">Require login to buy?</label>
                                                    </div>
                                                    {frmData.type===PRODUCT_TYPE.ticket.value &&
                                                    <div className="p-col-12">
                                                        <InputSwitch checked={frmData.sellExtraAsCoupon} onChange={(e) => setFrmData({...frmData, sellExtraAsCoupon: e.value })} />
                                                        <label className="p-margin-left-10">Allow buy as coupon exchange?</label>
                                                    </div>
                                                    } */}
													{frmData.courseProgram && (
														<div className='p-col-12'>
															<InputSwitch checked={frmData.recommended} onChange={(e) => setFrmData({ ...frmData, recommended: e.value })} />
															<label className='p-margin-left-10'>Is recommended?</label>
														</div>
													)}
													{frmData.classification === PRODUCT_CLASSIFICATION.simple.value &&
														(frmData.type === PRODUCT_TYPE.program.value || frmData.type === PRODUCT_TYPE.rental.value) &&
														!frmData.courseProgram && (
															<div className='p-col-12'>
																<InputSwitch checked={frmData.requiredMembershipCard} onChange={(e) => setFrmData({ ...frmData, requiredMembershipCard: e.value })} />
																<label className='p-margin-left-10'>Required membership card to book?</label>
															</div>
														)}
												</div>
											</div>
										</div>
									</Fieldset>
									<Fieldset legend='Hierarchy'>
										<div className='p-grid p-fluid form-group'>
											{!props.departmentId && (
												<div className='p-col-12'>
													<label className='p-label'>{frmData.show && '* '}Activity Type</label>
													<Dropdown
														value={frmData.departmentId}
														options={departments}
														onChange={(e) => onDepartmentChange(e.value)}
														filter={true}
														style={{ width: '100%' }}
														placeholder='Select an activity type'
													/>
													<span className='p-form-error'>{errors.department}</span>
												</div>
											)}
											<div className='p-col-12'>
												<label className='p-label'>
													{frmData.show && '* '}Category <span className='p-form-error'>(value depended on selected activity type)</span>
												</label>
												<Select
													multi
													values={categories && frmData.categoryIds && frmData.categoryIds.length > 0 ? categories.filter((x1) => frmData.categoryIds.some((x2) => x2 === x1.value)) : []}
													options={categories}
													onChange={(values) => onChangeCategory(values)}
													style={{ width: '100%' }}
													placeholder='Not use'
													noDataRenderer={() => {
														return <span className='p-c p-padding-10-0'>No Data</span>;
													}}
												/>
												<span className='p-form-error'>{errors.category}</span>
											</div>
											{frmData.classification === PRODUCT_CLASSIFICATION.simple.value && frmData.type === PRODUCT_TYPE.program.value && (
												<div className='p-col-12'>
													<label className='p-label'>Venue</label>
													<Dropdown
														value={frmData.locationId}
														options={locations}
														onChange={(e) => setFrmData({ ...frmData, locationId: e.value })}
														filter={true}
														style={{ width: '100%' }}
														placeholder='Select a venue'
													/>
												</div>
											)}
										</div>
									</Fieldset>
								</div>
								<div className='p-col-12 p-md-6'>
									<Fieldset legend='Price Setting'>
										<div className='p-grid p-fluid form-group'>
											{frmData.type !== PRODUCT_TYPE.donation.value && (
												<div className='p-col-12'>
													<InputSwitch checked={frmData.free} onChange={(e) => setFrmData({ ...frmData, free: e.value })} />
													<label className='p-margin-left-10'>Free?</label>
												</div>
											)}
											{!frmData.free && (
												<React.Fragment>
													{[PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(frmData.classification) && (
														<div className='p-col-12'>
															<InputSwitch checked={frmData.pricedBySelected} onChange={(e) => setFrmData({ ...frmData, pricedBySelected: e.value })} />
															<label className='p-margin-left-10'>Priced by selected product?</label>
														</div>
													)}

													{frmData.type === PRODUCT_TYPE.session.value && (
														<React.Fragment>
															<div className='p-col-12'>
																<InputSwitch checked={frmData.sellByTicket} onChange={(e) => setFrmData({ ...frmData, sellByTicket: e.value })} />
																<label className='p-margin-left-10'>Sell by ticket?</label>
															</div>

															{frmData.sellByTicket && (
																<div className='p-col-12'>
																	<label className='p-label'>* Related tickets</label>
																	<Select
																		multi
																		values={
																			tickets && frmData.relatedTicketIds && frmData.relatedTicketIds.length > 0 ? tickets.filter((x1) => frmData.relatedTicketIds.some((x2) => x2 === x1.value)) : []
																		}
																		options={tickets}
																		onChange={(values) => onChangeRelatedTickets(values)}
																		style={{ width: '100%' }}
																		placeholder='Not use'
																		noDataRenderer={() => {
																			return <span className='p-c p-padding-10-0'>No Data</span>;
																		}}
																	/>
																	<span className='p-form-error'>{errors.relatedTickets}</span>
																</div>
															)}
														</React.Fragment>
													)}

													{frmData.classification === PRODUCT_CLASSIFICATION.variable.value && (
														<div className='p-col-12'>
															<Checkbox inputId='cbPricedByVariant' checked={frmData.pricedByVariant} onChange={(e) => setFrmData({ ...frmData, pricedByVariant: e.checked })} />
															<label htmlFor='cbPricedByVariant'>Priced by variant?</label>
														</div>
													)}
												</React.Fragment>
											)}
											{/* {frmData.type===PRODUCT_TYPE.rental.value && 
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <InputSwitch checked={frmData.subscription} onChange={(e) => setFrmData({...frmData, subscription: e.value})} />
                                                    <label className="p-margin-left-10">Is subscription rental?</label>
                                                </div>
                                            </React.Fragment>
                                            } */}
										</div>
										{!frmData.free &&
											(((frmData.classification === PRODUCT_CLASSIFICATION.simple.value || (frmData.classification === PRODUCT_CLASSIFICATION.variable.value && !frmData.pricedByVariant)) &&
												!frmData.sellByTicket) ||
												([PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(frmData.classification) && !frmData.pricedBySelected)) && (
												<ProductPrices
													value={frmData.prices}
													classification={frmData.classification}
													type={frmData.type}
													membershipProducts={membershipProducts}
													errors={errors.prices ? errors.prices : {}}
													onChange={(e) => setFrmData({ ...frmData, prices: e })}
												/>
											)}
									</Fieldset>

									{!frmData.free &&
										([PRODUCT_CLASSIFICATION.simple.value, PRODUCT_CLASSIFICATION.variable.value].includes(frmData.classification) ||
											([PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(frmData.classification) && !frmData.pricedBySelected)) && (
											<Fieldset legend='Tax Setting'>
												<div className='p-grid p-fluid form-group'>
													<div className='p-col-12'>
														<InputSwitch checked={frmData.applyTax} onChange={(e) => setFrmData({ ...frmData, applyTax: e.value })} />
														<label className='p-margin-left-10'>Apply tax?</label>
													</div>
													{frmData.applyTax && (
														<div className='p-col-12'>
															<label className='p-label'>* Tax apply</label>
															<Dropdown value={frmData.taxId} options={taxes} onChange={(e) => setFrmData({ ...frmData, taxId: e.value })} />
															<div className='p-form-error'>{errors.taxId}</div>
														</div>
													)}
												</div>
											</Fieldset>
										)}

									{/* {!frmData.free && [PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value].includes(frmData.type) &&
                                    <Fieldset legend="Deposit Setting">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <InputSwitch checked={frmData.allowDeposit} onChange={(e) => setFrmData({...frmData, allowDeposit: e.value, depositType: e.value?DEPOSIT_TYPE.installment.value:null})} />
                                                <label className="p-margin-left-10">Allow deposit?</label>
                                            </div>
                                            {frmData.allowDeposit && 
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <label className="p-label inline">Deposit type:</label>
                                                    <RadioButton inputId="rbDepositType_0" value={DEPOSIT_TYPE.installment.value} className="p-margin-left-20" onChange={(e) => setFrmData({...frmData, depositType: e.value})} checked={frmData.depositType === DEPOSIT_TYPE.installment.value} />
                                                    <label htmlFor="rbDepositType_0" className="p-radiobutton-label">{DEPOSIT_TYPE.installment.label}</label>
                                                    <RadioButton inputId="rbDepositType_1" value={DEPOSIT_TYPE.pledge.value} className="p-margin-left-20" onChange={(e) => setFrmData({...frmData, depositType: e.value})} checked={frmData.depositType === DEPOSIT_TYPE.pledge.value} />
                                                    <label htmlFor="rbDepositType_1" className="p-radiobutton-label">{DEPOSIT_TYPE.pledge.label}</label>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">* Deposit amount</label>
                                                    <InputText type="text" keyfilter="int" value={frmData.depositAmount} onChange={(e) => setFrmData({...frmData, depositAmount: Number(e.target.value)})} />
                                                    <div className="p-form-error">{errors.depositAmount}</div>
                                                </div>
                                                {frmData.depositType===DEPOSIT_TYPE.installment.value && 
                                                <div className="p-col-12">
                                                    <InputSwitch inputId="cbAllowPayFull" checked={frmData.allowPayFull} onChange={(e) => setFrmData({...frmData, allowPayFull: e.value})} />
                                                    <label htmlFor="cbAllowPayFull" className="p-margin-left-10">Allow pay full? (Use in case deposit enabled)</label>
                                                </div>
                                                }
                                            </React.Fragment>
                                            }
                                        </div>
                                    </Fieldset>
                                    } */}

									{(frmData.classification === PRODUCT_CLASSIFICATION.combo.value || frmData.type === PRODUCT_TYPE.program.value) && isCourse && (
										<Fieldset legend='HR Setting'>
											<div className='p-grid p-fluid form-group'>
												<div className='p-col-12'>
													<label className='p-label'>* Vendor</label>
													<Dropdown value={frmData.vendorId} options={vendors} onChange={(e) => setFrmData({ ...frmData, vendorId: e.value })} placeholder='Select vendor' />
													<div className='p-form-error'>{errors.vendorId}</div>
												</div>
											</div>
										</Fieldset>
									)}

									{frmData.classification === PRODUCT_CLASSIFICATION.variable.value && (
										<Fieldset legend='Variant Setting'>
											<div className='p-grid p-fluid form-group'>
												<div className='p-col-12'>
													<span style={{ color: 'red', fontSize: '12px', fontStyle: 'Italic' }}>Note: Some setting marked with (!) can't be changed after inventory variant has been created!</span>
												</div>
												<div className='p-col-12'>
													<label className='p-label inline p-margin-bottom-5'>Variant options type (!):</label>
													<RadioButton
														inputId='rbVariantOptBy0'
														name='variantOptionType'
														disabled={frmData.variants.length > 0}
														className='p-margin-left-20'
														value={VARIANT_OPTION_TYPE.option.value}
														onChange={(e) => setFrmData({ ...frmData, variantOptionType: e.value })}
														checked={frmData.variantOptionType === VARIANT_OPTION_TYPE.option.value}
													/>
													<label htmlFor='rbVariantOptBy0' className='p-margin-right-15'>
														{VARIANT_OPTION_TYPE.option.label}
													</label>
													<RadioButton
														inputId='rbVarianOpttBy1'
														name='variantOptionType'
														disabled={frmData.variants.length > 0}
														value={VARIANT_OPTION_TYPE.custom.value}
														onChange={(e) => setFrmData({ ...frmData, variantOptionType: e.value })}
														checked={frmData.variantOptionType === VARIANT_OPTION_TYPE.custom.value}
													/>
													<label htmlFor='rbVariantOptBy1' className='p-margin-right-15'>
														{VARIANT_OPTION_TYPE.custom.label}
													</label>
												</div>
												{frmData.variantOptionType === VARIANT_OPTION_TYPE.option.value && (
													<div className='p-col-12'>
														<label className='p-label'>* Variant options (!)</label>
														<Select
															multi
															disabled={frmData.variants.length > 0}
															values={
																variantOptions && frmData.variantOptionSelect && frmData.variantOptionSelect.length > 0
																	? variantOptions.filter((x1) => frmData.variantOptionSelect.some((x2) => x2 === x1.value))
																	: []
															}
															options={variantOptions}
															onChange={(values) => onChangeVariantOptions(values, 'variant')}
															style={{ width: '100%' }}
															placeholder='Select product options'
															noDataRenderer={() => {
																return <span className='p-c p-padding-10-0'>No data</span>;
															}}
														/>
														<div className='p-form-error'>{errors.variantOption}</div>
													</div>
												)}
											</div>
										</Fieldset>
									)}

									{frmData.type === PRODUCT_TYPE.rental.value && (
										<Fieldset legend='Rental Limit Setting'>
											<div className='p-grid p-fluid form-group'>
												<div className='p-col-12'>
													<span style={{ color: 'red', fontSize: '12px', fontStyle: 'Italic' }}>Note: Leave quantity blank as unlimited</span>
												</div>
												<div className='p-col-12 p-md-6'>
													<div className='p-grid p-fluid'>
														<div className='p-col-6'>
															<label className='p-label'>Min q.ty</label>
															<InputText
																value={frmData.rentalLimit.minQty}
																keyfilter='int'
																onChange={(e) => setFrmData({ ...frmData, rentalLimit: { ...frmData.rentalLimit, minQty: e.target.value } })}
															/>
														</div>
														<div className='p-col-6'>
															<label className='p-label'>Min unit</label>
															<Dropdown
																value={frmData.rentalLimit.minUnit}
																options={Object.values(PRODUCT_PRICE_UNIT)}
																onChange={(e) => setFrmData({ ...frmData, rentalLimit: { ...frmData.rentalLimit, minUnit: e.value } })}
															/>{' '}
														</div>
													</div>
													<div className='p-form-error'>{errors.rentalLimitMin}</div>
												</div>
												<div className='p-col-12 p-md-6'>
													<div className='p-grid p-fluid'>
														<div className='p-col-6'>
															<label className='p-label'>Max q.ty</label>
															<InputText
																value={frmData.rentalLimit.maxQty}
																keyfilter='int'
																onChange={(e) => setFrmData({ ...frmData, rentalLimit: { ...frmData.rentalLimit, maxQty: e.target.value } })}
															/>
														</div>
														<div className='p-col-6'>
															<label className='p-label'>Max unit</label>
															<Dropdown
																value={frmData.rentalLimit.maxUnit}
																options={Object.values(PRODUCT_PRICE_UNIT)}
																onChange={(e) => setFrmData({ ...frmData, rentalLimit: { ...frmData.rentalLimit, maxUnit: e.value } })}
															/>{' '}
														</div>
													</div>
													<div className='p-form-error'>{errors.rentalLimitMax}</div>
												</div>
												<div className='p-col-12'>
													<label className='p-label p-mb-2'>Booking available on (weekdays):</label>
													<Checkbox inputId='cbMon' value='mon' onChange={onRentalLimitWeekDaysChange} checked={frmData.rentalLimit.availableWeekdays.includes('mon')}></Checkbox>
													<label htmlFor='cbMon' className='p-checkbox-label p-margin-right-15'>
														Mon
													</label>
													<Checkbox inputId='cbTue' value='tue' onChange={onRentalLimitWeekDaysChange} checked={frmData.rentalLimit.availableWeekdays.includes('tue')}></Checkbox>
													<label htmlFor='cbTue' className='p-checkbox-label p-margin-right-15'>
														Tue
													</label>
													<Checkbox inputId='cbWed' value='wed' onChange={onRentalLimitWeekDaysChange} checked={frmData.rentalLimit.availableWeekdays.includes('wed')}></Checkbox>
													<label htmlFor='cbWed' className='p-checkbox-label p-margin-right-15'>
														Wed
													</label>
													<Checkbox inputId='cbThu' value='thu' onChange={onRentalLimitWeekDaysChange} checked={frmData.rentalLimit.availableWeekdays.includes('thu')}></Checkbox>
													<label htmlFor='cbThu' className='p-checkbox-label p-margin-right-15'>
														Thu
													</label>
													<Checkbox inputId='cbFri' value='fri' onChange={onRentalLimitWeekDaysChange} checked={frmData.rentalLimit.availableWeekdays.includes('fri')}></Checkbox>
													<label htmlFor='cbFri' className='p-checkbox-label p-margin-right-15'>
														Fri
													</label>
													<Checkbox inputId='cbSat' value='sat' onChange={onRentalLimitWeekDaysChange} checked={frmData.rentalLimit.availableWeekdays.includes('sat')}></Checkbox>
													<label htmlFor='cbSat' className='p-checkbox-label p-margin-right-15'>
														Sat
													</label>
													<Checkbox inputId='cbSun' value='sun' onChange={onRentalLimitWeekDaysChange} checked={frmData.rentalLimit.availableWeekdays.includes('sun')}></Checkbox>
													<label htmlFor='cbSun' className='p-checkbox-label'>
														Sun
													</label>
												</div>
											</div>
										</Fieldset>
									)}

									{frmData.type === PRODUCT_TYPE.rental.value && (
										<Fieldset legend='Slot setting'>
											<div className='p-grid p-fluid form-group'>
												<div className='p-col-12'>
													<label className='p-label'>Slot</label>
													<InputText value={frmData.slot} keyfilter='num' onChange={(e) => setFrmData({ ...frmData, slot: e.target.value })}></InputText>
													<div className='p-form-error'>{errors.slot}</div>
												</div>
											</div>
										</Fieldset>
									)}

									{/* {frmData.type!==PRODUCT_TYPE.donation.value && 
                                    <Fieldset legend="Tags">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <label className="p-label">(hit Enter add a tag)</label>
                                                <Chips placeholder="Type your tag here" value={frmData.tags} onChange={(e) => setFrmData({...frmData, tags: e.value})}></Chips>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    } */}

									{/* {[PRODUCT_TYPE.program.value, PRODUCT_TYPE.donation.value].includes(frmData.type) && 
                                    <Fieldset legend="Custom field setting">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <label className="p-label">Custom Field Set</label>
                                                {frmData.productCustomProperty && frmData.productCustomProperty.customFields ?
                                                    <Button label="Manage Custom Fields" icon="pi pi-search" onClick={() => viewProductCustomFields()}></Button>
                                                :
                                                    <Dropdown value={frmData.productCustomProperty && frmData.productCustomProperty.customSetId} options={customFieldSets} showClear={true} onChange={(e) => setFrmData({...frmData, productCustomProperty: {...frmData.productCustomProperty, customSetId: e.value}})} style={{ width: '100%' }} placeholder="Not use"/>
                                                }
                                            </div>
                                            {frmData.type===PRODUCT_TYPE.program.value && 
                                            <div className="p-col-12">
                                                <label className="p-label">Waiver Field Set</label>
                                                <Dropdown value={frmData.waiverFieldSetId} options={waiverFieldSets} showClear={true} onChange={(e) => setFrmData({...frmData, waiverFieldSetId: e.value})} style={{ width: '100%' }} placeholder="Not use"/>
                                            </div>
                                            }
                                        </div>
                                    </Fieldset>
                                    } */}

									{/* {[PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value].includes(frmData.type) && 
                                    <Fieldset legend="Required Application Log">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <InputSwitch checked={frmData.useApplicationLog} onChange={(e) => setFrmData({...frmData, useApplicationLog: e.value })} />
                                                <label className="p-margin-left-10">Use Application Log?</label>
                                            </div>
                                            {frmData.useApplicationLog &&
                                                <div className="p-col-12">
                                                    <label className="p-label">Application Log</label>
                                                    <Select multi
                                                        values={applications && frmData.applicationLogs && frmData.applicationLogs.length>0 ? applications.filter(x1 => frmData.applicationLogs.some(x2 => x2 === x1.value)) : []}
                                                        options={applications} onChange={(values) => onChangeApplicationLog(values)}
                                                        style={{ width: '100%' }} placeholder="Not use"
                                                        noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No Data</span>) }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </Fieldset>
                                    } */}

									{/* {((!frmData.id && frmData.type===PRODUCT_TYPE.program.value) || frmData.type===PRODUCT_TYPE.session.value) && 
                                    <Fieldset legend={frmData.type===PRODUCT_TYPE.session.value?"Session schedule":"Time series setup"}>
                                        <div className="p-grid p-fluid form-group">
                                            {frmData.type===PRODUCT_TYPE.program.value && 
                                            <div className="p-col-12">
                                                <label className="p-w-bold p-display-inline-block" style={{width: '80px'}}>Creating by:</label>
                                                <RadioButton value={CREATE_TYPE.manually} className="p-margin-left-20" onChange={(e) => onCreateTypeChange(e.value)} checked={timeSeriesData.createType === CREATE_TYPE.manually} />
                                                <label className="p-radiobutton-label">Manual</label>
                                                <RadioButton value={CREATE_TYPE.autoDaily} className="p-margin-left-20" onChange={(e) => onCreateTypeChange(e.value)} checked={timeSeriesData.createType === CREATE_TYPE.autoDaily} />
                                                <label className="p-radiobutton-label">Auto create daily</label>
                                            </div>
                                            }
                                            
                                            {timeSeriesData.createType===CREATE_TYPE.autoDaily && 
                                            <React.Fragment>
                                                <div className="p-col-12">
                                                    <label className="p-w-bold p-display-inline-block" style={{width: '80px'}}>Repeat on:</label>
                                                    <RadioButton value={TIME_SERIES_REPEAT_TYPE.daily} className="p-margin-left-20" onChange={(e) => setTimeSeriesData({...timeSeriesData, repeatType: e.value})} checked={timeSeriesData.repeatType === TIME_SERIES_REPEAT_TYPE.daily} />
                                                    <label className="p-radiobutton-label">Every day</label>
                                                    <RadioButton value={TIME_SERIES_REPEAT_TYPE.weekDay} className="p-margin-left-20" onChange={(e) => setTimeSeriesData({...timeSeriesData, repeatType: e.value})} checked={timeSeriesData.repeatType === TIME_SERIES_REPEAT_TYPE.weekDay} />
                                                    <label className="p-radiobutton-label">Specific day in week</label>
                                                </div>
                                                {timeSeriesData.repeatType===TIME_SERIES_REPEAT_TYPE.weekDay && 
                                                <div className="p-col-12">
                                                    <label className="p-w-bold p-display-inline-block" style={{width: '80px'}}>Day repeat:</label>
                                                    <Checkbox inputId="cbMon" className="p-margin-left-20" value="mon" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('mon')}></Checkbox>
                                                    <label htmlFor="cbMon" className="p-checkbox-label p-margin-right-15">Mon</label>
                                                    <Checkbox inputId="cbTue" value="tue" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('tue')}></Checkbox>
                                                    <label htmlFor="cbTue" className="p-checkbox-label p-margin-right-15">Tue</label>
                                                    <Checkbox inputId="cbWed" value="wed" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('wed')}></Checkbox>
                                                    <label htmlFor="cbWed" className="p-checkbox-label p-margin-right-15">Wed</label>
                                                    <Checkbox inputId="cbThu" value="thu" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('thu')}></Checkbox>
                                                    <label htmlFor="cbThu" className="p-checkbox-label p-margin-right-15">Thu</label>
                                                    <Checkbox inputId="cbFri" value="fri" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('fri')}></Checkbox>
                                                    <label htmlFor="cbFri" className="p-checkbox-label p-margin-right-15">Fri</label>
                                                    <Checkbox inputId="cbSat" value="sat" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('sat')}></Checkbox>
                                                    <label htmlFor="cbSat" className="p-checkbox-label p-margin-right-15">Sat</label>
                                                    <Checkbox inputId="cbSun" value="sun" onChange={onWeekDaysChange} checked={timeSeriesData.repeatValues.includes('sun')}></Checkbox>
                                                    <label htmlFor="cbSun" className="p-checkbox-label">Sun</label>
                                                    <div className="p-form-error" style={{paddingLeft: '100px'}}>{errors.timeSeries && errors.timeSeries.repeatValues}</div>
                                                </div>
                                                }
                                                </React.Fragment>
                                            }

                                            {((!frmData.id && timeSeriesData.createType===CREATE_TYPE.manually) || frmData.type===PRODUCT_TYPE.session.value) &&
                                            <div className="p-col-12">
                                                <Checkbox inputId="cbIsRange" checked={timeSeriesData.dateRange} onChange={(e) => setTimeSeriesData({...timeSeriesData, dateRange: e.checked})} />
                                                <label htmlFor="cbIsRange" className="p-margin-left-10">Is date range?</label>
                                            </div>
                                            }

                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-6">
                                                        <label className="p-label">* {timeSeriesData.dateRange?'Start Date':'Date'}</label>
                                                        <MaskedCalendar value={timeSeriesData.startDateValue} showIcon={true} onChange={(e) => onDateChange(e.value, 'start')} onBlur={(e) => onDateBlur(e.target.value, 'start')} dateFormat="mm-dd-yy" />
                                                        <span className="p-form-error">{errors.timeSeries && errors.timeSeries.startDate}</span>
                                                    </div>
                                                    {timeSeriesData.dateRange && 
                                                    <div className="p-col-6">
                                                        <label className="p-label">* End Date</label>
                                                        <MaskedCalendar value={timeSeriesData.endDateValue} showIcon={true} onChange={(e) => onDateChange(e.value, 'end')} onBlur={(e) => onDateBlur(e.target.value, 'end')} dateFormat="mm-dd-yy" />
                                                        <span className="p-form-error">{errors.timeSeries && errors.timeSeries.endDate}</span>
                                                    </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-6">
                                                        <label className="p-label">* Start Time</label>
                                                        <MaskedCalendar value={timeSeriesData.startTimeValue} showIcon={true} onChange={(e) => onTimeChange(e.value, 'start')} onBlur={(e) => onTimeBlur(e.target.value, 'start')} hourFormat="12" timeOnly={true}/>
                                                        <span className="p-form-error">{errors.timeSeries && errors.timeSeries.startTime}</span>
                                                    </div>
                                                    <div className="p-col-6">
                                                        <label className="p-label">* End Time</label>
                                                        <MaskedCalendar value={timeSeriesData.endTimeValue} showIcon={true} onChange={(e) => onTimeChange(e.value, 'end')} onBlur={(e) => onTimeBlur(e.target.value, 'end')} hourFormat="12" timeOnly={true}/>
                                                        <span className="p-form-error">{errors.timeSeries && errors.timeSeries.endTime}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            {frmData.type===PRODUCT_TYPE.program.value && 
                                            <div className="p-col-12">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-6">
                                                        <label className="p-label">Min q.ty</label>
                                                        <InputText value={timeSeriesData.minQty} keyfilter="int" onChange={(e) => setTimeSeriesData({...timeSeriesData, minQty: e.target.value})}/>
                                                        <span className="p-form-error">{errors.minQty}</span>
                                                    </div>
                                                    <div className="p-col-6">
                                                        <label className="p-label">Max q.ty (leave blank as unlimited)</label>
                                                        <InputText value={timeSeriesData.maxQty} keyfilter="int"  showIcon={true} onChange={(e) => setTimeSeriesData({...timeSeriesData, maxQty: e.target.value})}/>
                                                        <span className="p-form-error">{errors.maxQty}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                            
                                        </div>
                                    </Fieldset>
                                    } */}

									{!frmData.id &&
										frmData.classification === PRODUCT_CLASSIFICATION.simple.value &&
										[PRODUCT_TYPE.ticket.value, PRODUCT_TYPE.addon.value, PRODUCT_TYPE.session.value].includes(frmData.type) && (
											<Fieldset legend='Inventory Setting'>
												<div className='p-grid p-fluid form-group'>
													<div className='p-col-12'>
														<Checkbox checked={frmData.stockTracking} onChange={(e) => setFrmData({ ...frmData, stockTracking: e.checked })} />
														Inventory stock tracking?
													</div>
													{frmData.stockTracking && (
														<div className='p-col-12'>
															<label className='p-label'>* Q.ty on hand</label>
															<Spinner value={frmData.stockInputQty} step={1} onChange={(e) => setFrmData({ ...frmData, stockInputQty: e.target.value })} />
															<span className='p-form-error'>{errors.stockQuantity}</span>
														</div>
													)}
												</div>
											</Fieldset>
										)}

									{/* {frmData.type!==PRODUCT_TYPE.rental.value && frmData.type!==PRODUCT_TYPE.membership.value && frmData.type!==PRODUCT_TYPE.donation.value && 
                                    <React.Fragment>
                                        {!frmData.id?
                                            <Fieldset legend="Inventory Setting">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <span style={{color: 'red', fontSize: '12px', fontStyle: 'Italic'}}>Note: Some setting marked with (!) can't be changed after product has been created!</span>
                                                    </div>
                                                    {(()=>{
                                                        if(frmData.classification){
                                                            switch (frmData.classification) {
                                                                case PRODUCT_CLASSIFICATION.simple.value:
                                                                    return <React.Fragment>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">Variant options (!)</label>
                                                                            <Select multi
                                                                                values={productOptions && frmData.optionSelect && frmData.optionSelect.length>0 ? productOptions.filter(x1 => frmData.optionSelect.some(x2 => x2 === x1.value)) : []}
                                                                                options={productOptions} onChange={(values) => this.onChangeSelect(values)}
                                                                                style={{ width: '100%' }} placeholder="Select product options"
                                                                                noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No data</span>) }}
                                                                            />
                                                                            <span className="p-form-error">{errors.optionSelect}</span>
                                                                        </div>
                                                                        <div className="p-col-12">
                                                                            <Checkbox checked={frmData.stockTracking} onChange={(e) => setFrmData({...frmData, stockTracking: e.checked})} />
                                                                            Inventory stock tracking?
                                                                        </div>
                                                                        {frmData.stockTracking &&
                                                                            <div className="p-col-12">
                                                                                <label className="p-label">* Q.ty on hand</label>
                                                                                <Spinner value={frmData.stockInputQty} step={1} onChange={(e) => setFrmData({...frmData, stockInputQty: e.target.value })} />
                                                                                <span className="p-form-error">{errors.quantity}</span>
                                                                            </div>
                                                                        }
                                                                    </React.Fragment>
                                                                case PRODUCT_CLASSIFICATION.variable.value:
                                                                    return <React.Fragment>
                                                                        
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">* Variant options (!)</label>
                                                                            <Select
                                                                                values={productOptions && frmData.optionSelect && frmData.optionSelect.length>0 ? productOptions.filter(x1 => frmData.optionSelect.some(x2 => x2 === x1.value)) : []}
                                                                                options={productOptions} onChange={(values) => this.onChangeSelect(values)}
                                                                                style={{ width: '100%' }} placeholder="Select product options"
                                                                                noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No data</span>) }}
                                                                            />
                                                                            <span className="p-form-error">{errors.optionSelect}</span>
                                                                        </div>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label p-margin-bottom-5">Variant selection method</label>
                                                                            <RadioButton inputId="rbVariantSelectionMethod0" name="variantSelectionType" value={0} onChange={(e) => setFrmData({...frmData, variantSelectionType: e.value})} checked={frmData.variantSelectionType===0} />
                                                                            <label htmlFor="rbVariantSelectionMethod0" className="p-margin-right-15">By select</label>
                                                                            <RadioButton inputId="rbVariantSelectionMethod1" name="variantSelectionType" value={1} onChange={(e) => setFrmData({...frmData, variantSelectionType: e.value})} checked={frmData.variantSelectionType===1} />
                                                                            <label htmlFor="rbVariantSelectionMethod1" className="p-margin-right-15">By matching</label>
                                                                        </div>
                                                                    </React.Fragment>
                                                                default: break;
                                                            }
                                                        }
                                                    })()}
                                                </div>
                                            </Fieldset>
                                            :
                                            <Fieldset legend="Inventory Information">
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <label className="p-label p-margin-bottom-5">Variant type: {this.renderProductClassification()}</label>
                                                    </div>
                                                    {frmData.options&&frmData.options.length>0 &&
                                                        <div className="p-col-12">
                                                            <label className="p-label p-margin-bottom-5">Variable options: </label>
                                                            <div>{frmData.options.map((e, i) => {return ((i>0?', ':'')+e.name) })}</div>
                                                        </div>
                                                    }
                                                    {(()=>{
                                                        if(frmData.classification){
                                                            switch (frmData.classification) {
                                                                case PRODUCT_CLASSIFICATION.simple.value:
                                                                    return <React.Fragment>
                                                                        <div className="p-col-12">
                                                                            <Checkbox checked={frmData.stockTracking} onChange={(e) => setFrmData({...frmData, stockTracking: e.checked})} />
                                                                            Inventory stock tracking?
                                                                        </div>
                                                                        {frmData.stockTracking &&
                                                                            <div className="p-col-12">
                                                                                <label className="p-label">* Stock input q.ty</label>
                                                                                <Spinner value={frmData.stockInputQty} step={1} onChange={(e) => setFrmData({...frmData, stockInputQty: e.target.value })} />
                                                                                <span className="p-form-error">{errors.quantity}</span>
                                                                            </div>
                                                                        }
                                                                        <div className="p-col-12">
                                                                            <Fieldset legend="Stock details">
                                                                                <div className="p-grid">
                                                                                    {frmData.stockTracking &&
                                                                                        <React.Fragment>
                                                                                            <div className="p-col-6">
                                                                                                <label className="p-label p-margin-bottom-5">Total q.ty: {frmData.variants[0].onHand + frmData.variants[0].onHold + frmData.variants[0].onSold}</label>
                                                                                            </div>
                                                                                            <div className="p-col-6">
                                                                                                <label className="p-label p-margin-bottom-5">Q.ty on hand: {frmData.variants[0].onHand}</label>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                    <div className="p-col-6">
                                                                                        <label className="p-label p-margin-bottom-5">Q.ty on hold: {frmData.variants[0].onHold}</label>
                                                                                    </div>
                                                                                    <div className="p-col-6">
                                                                                        <label className="p-label p-margin-bottom-5">Q.ty on sold: {frmData.variants[0].onSold}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </Fieldset>
                                                                        </div>
                                                                    </React.Fragment>
                                                                case PRODUCT_CLASSIFICATION.variable.value:
                                                                    return <React.Fragment>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label p-margin-bottom-5">Variant selection method</label>
                                                                            <RadioButton inputId="rbVariantSelectionMethod0" name="variantSelectionType" value={0} onChange={(e) => setFrmData({...frmData, variantSelectionType: e.value})} checked={frmData.variantSelectionType==0} />
                                                                            <label htmlFor="rbVariantSelectionMethod0" className="p-margin-right-15">By select</label>
                                                                            <RadioButton inputId="rbVariantSelectionMethod1" name="variantSelectionType" value={1} onChange={(e) => setFrmData({...frmData, variantSelectionType: e.value})} checked={frmData.variantSelectionType==1} />
                                                                            <label htmlFor="rbVariantSelectionMethod1" className="p-margin-right-15">By matching</label>
                                                                        </div>
                                                                    </React.Fragment>
                                                                default: break;
                                                            }
                                                        }
                                                    })()}
                                                </div>
                                            </Fieldset>
                                        }
                                    </React.Fragment>
                                    } */}
								</div>
							</div>

							<div className='p-sidebar-line p-my-3'></div>

							<div className='p-grid'>
								<div className='p-col-12 p-r'>
									<Button label='Save Information' icon='pi pi-save' iconPos='left' style={{ float: 'right' }} onClick={() => handleSaveProduct()} />
								</div>
							</div>
						</div>

						{frmData.id && (
							<React.Fragment>
								<div className={classNames({ 'p-hide': menuActive !== PRODUCT_MENU_ITEMS.content.value })}>
									<div className='p-grid'>
										<div className='p-col-12'>
											<Fieldset legend='Gallery Images'>
												<ProductGallery value={frmData.galleryImages} type={GALLERY_TYPE.image} errors={errors} onChange={(e) => setFrmData({ ...frmData, galleryImages: e })} />
											</Fieldset>
											<Fieldset legend='Videos'>
												<ProductGallery value={frmData.videos} type={GALLERY_TYPE.video} onChange={(e) => setFrmData({ ...frmData, videos: e })} />
											</Fieldset>
											<Fieldset legend='Long content'>
												<div className='p-grid p-fluid'>
													<div className='p-col-12'>
														<CKEditor data={frmData.content} rows={10} onChange={(e) => setFrmData({ ...frmData, content: e.editor.getData() })} config={{ height: '300px' }} />
													</div>
												</div>
											</Fieldset>
										</div>
									</div>

									<div className='p-sidebar-line p-my-3'></div>

									<div className='p-grid'>
										<div className='p-col-12 p-r'>
											<Button label='Save Content' icon='pi pi-save' iconPos='left' style={{ float: 'right' }} onClick={() => handleSaveProductContent()} />
										</div>
									</div>
								</div>

								{[PRODUCT_TYPE.program.value, PRODUCT_TYPE.session.value].includes(frmData.type) && (
									<div className={classNames({ 'p-hide': menuActive !== PRODUCT_MENU_ITEMS.availability.value })}>
										<ProductAvailability
											productId={productId}
											branchId={frmData.branchId}
											type={frmData.type}
											isCourseProgram={isCourse ? true : false}
											membershipProducts={membershipProducts}
											presenters={presenters}
											loadTable={productId ? true : false}
										/>
									</div>
								)}

								{(frmData.classification === PRODUCT_CLASSIFICATION.variable.value ||
									(frmData.classification === PRODUCT_CLASSIFICATION.simple.value &&
										[PRODUCT_TYPE.ticket.value, PRODUCT_TYPE.session.value, frmData.type === PRODUCT_TYPE.addon.value].includes(frmData.type))) && (
									<div className={classNames({ 'p-hide': menuActive !== PRODUCT_MENU_ITEMS.inventory.value && menuActive !== PRODUCT_MENU_ITEMS.variant.value })}>
										<ProductVariant
											value={frmData.variants}
											variantOptions={frmData.options}
											productId={productId}
											type={frmData.type}
											classification={frmData.classification}
											pricedByVariant={frmData.pricedByVariant}
											variantOptionType={frmData.variantOptionType}
										/>
									</div>
								)}

								{/* {(frmData.classification===PRODUCT_CLASSIFICATION.combo.value || frmData.classification===PRODUCT_CLASSIFICATION.configuration.value || ((frmData.classification===PRODUCT_CLASSIFICATION.simple.value || frmData.classification===PRODUCT_CLASSIFICATION.variable.value) && frmData.type===PRODUCT_TYPE.program.value)) &&  */}
								{(frmData.classification === PRODUCT_CLASSIFICATION.combo.value || frmData.classification === PRODUCT_CLASSIFICATION.configuration.value) && (
									<div className={classNames({ 'p-hide': menuActive !== PRODUCT_MENU_ITEMS.associated.value })}>
										<ProductAssociated
											value={frmData.associatedGroups}
											productId={productId}
											type={frmData.type}
											classification={frmData.classification}
											branchId={frmData.branchId}
											vendorId={frmData.vendorId}
											isCourse={isCourse}
											pricedByChild={frmData.pricedBySelected}
										/>
									</div>
								)}

								{frmData.type === PRODUCT_TYPE.rental.value && (
									<div className={classNames({ 'p-hide': menuActive !== PRODUCT_MENU_ITEMS.asset.value })}>
										<Fieldset legend='Related facility setup'>
											<div className='p-grid p-fluid form-group'>
												<div className='p-col-12'>
													<label className='p-label'>* Facility Category</label>
													<Dropdown
														value={frmData.assetCategoryId}
														options={assetCategories}
														onChange={(e) => setFrmData({ ...frmData, assetCategoryId: e.value })}
														placeholder='Select related asset category'
													/>
													<span className='p-form-error'>{errors.assetCategory}</span>
												</div>
												{branches.length > 1 && (
													<div className='p-col-12'>
														<label className='p-label'>Facility Across Branch</label>
														<Select
															multi
															values={
																branches && frmData.assetAcrossBranches && frmData.assetAcrossBranches.length > 0
																	? branches.filter((x1) => frmData.assetAcrossBranches.some((x2) => x2 === x1.value))
																	: []
															}
															options={branches.filter((br) => br.value !== frmData.branchId)}
															onChange={(values) => onChangeAssetAcrossBranch(values)}
															style={{ width: '100%' }}
															placeholder='Select branch to across facility'
															noDataRenderer={() => {
																return <span className='p-c p-padding-10-0'>No across</span>;
															}}
														/>
													</div>
												)}
												<div className='p-col-12'>
													<label className='p-label'>Exclude facility</label>
													<Select
														multi
														values={assets && frmData.assetExcludedIds && frmData.assetExcludedIds.length > 0 ? assets.filter((x1) => frmData.assetExcludedIds.some((x2) => x2 === x1.value)) : []}
														options={assets}
														onChange={(values) => onChangeAssetExclude(values)}
														style={{ width: '100%' }}
														placeholder='Not exclude'
														noDataRenderer={() => {
															return <span className='p-c p-padding-10-0'>No Data</span>;
														}}
													/>
												</div>
											</div>

											<div className='p-grid'>
												<div className='p-col-12 p-r'>
													<Button label='Save Related Facility' icon='pi pi-save' iconPos='left' style={{ float: 'right' }} onClick={() => handleSaveProductAsset()} />
												</div>
											</div>
										</Fieldset>
									</div>
								)}
							</React.Fragment>
						)}
					</div>
				</div>
			</Sidebar>
		</React.Fragment>
	);
});
