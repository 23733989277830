import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { showSuccessNotify, showErrorNotify } from '../core/service/NotificationService';
import CKEditor from 'ckeditor4-react';
import { showloading, stoploading } from "../core/service/LoadingService";
import { saveDiploma } from "./DiplomaServices";
import { InputTextarea } from "primereact/inputtextarea";
import { Fieldset } from "primereact/fieldset";
import Select from 'react-dropdown-select';
import { getListProducts } from "../pim/product/ProductServices";
import { APP_FEP, MAX_FILE_SIZE, PRODUCT_TYPE, UPLOAD_TYPE } from "../constants";

CKEditor.editorUrl = "/assets/ckeditor/ckeditor.js";

export const DiplomaForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("New Content")
    const [visible, setVisible] = useState(false)
    const [products, setProducts] = useState([])
    const [frmData, setFrmData] = useState({})
    const [errors, setErrors] = useState({})

    const inputUpload = useRef(null)
    
    useImperativeHandle(ref, () => ({
        openForm(e){
            setHeader(e?'Edit Programme':'New Programme')
            setFrmData(popularFormData(e))
            loadProducts()
            setVisible(true)
        },
        closeForm(){
            closeForm()
        }
    }))

    const popularFormData = (data) => {
        return {
            id: data ? data.id : '',
            name: data && data.name ? data.name : '',
            description: data && data.description ? data.description : '',
            imageInfo: data&&data.imageInfo&&Object.keys(data.imageInfo).length>0?data.imageInfo:null,
            uploadType: data&&data.imageInfo&&data.imageInfo.fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value,
            externalImageLink: data&&data.imageInfo?(data.imageInfo.fileName?'':data.imageInfo.url):'',
            productIds: data&&data.courses&&data.courses.length>0 ? data.courses.map(c => c.productId) : []
        }
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setErrors({})
    }

    const handleSave = () => {
        showloading()

        if(frmData.file && frmData.uploadType===UPLOAD_TYPE.upload_file.value){
            let fileSize = frmData.file.size
            if(fileSize > MAX_FILE_SIZE){
                setErrors({image: 'Size not allowed (maximum: 100MB)'})
                showErrorNotify('Cannot perform action');
                stoploading()
                return
            }
        }
        setErrors({})

        saveDiploma(frmData)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable)
                    props.refreshTable()
                closeForm()
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }
        }).finally(()=> stoploading())
    }

    const loadProducts = () => {
        getListProducts({app: APP_FEP, types: [PRODUCT_TYPE.program.value], isCourse: true}).then(res => setProducts(res?res:[]))
    }

    const onChangeProduct = (values) => {
        let arrValues = [];
        if (values)
            arrValues = values.map(e => (e.value))

        setFrmData({...frmData, productIds: arrValues})
    }

    return(
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid p-fluid form-group">
                <div className="p-col-12">
                    <Fieldset legend="Basic Information">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <label className="p-label">Name</label>
                                <InputText value={frmData.name} onChange={(e) => setFrmData({...frmData, name: e.target.value})} />
                                <div className="p-form-error">{errors.name}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Image</label>
                                <div className="p-inputgroup">
                                    {(!frmData.uploadType || frmData.uploadType===UPLOAD_TYPE.external_url.value) ?
                                        <React.Fragment>
                                            <InputText value={frmData.externalImageLink} onChange={(e) => setFrmData({...frmData, externalImageLink: e.target.value})} placeholder="Image link" />
                                            <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.upload_file.value})} />
                                        </React.Fragment>
                                        :<React.Fragment>
                                            {frmData.imageInfo && frmData.imageInfo.fileName &&
                                                <span className="p-inputgroup-addon"><img src={frmData.imageInfo.url} alt={frmData.imageInfo.fileName} style={{width: 25, height: 25}} /></span>
                                            }
                                            <input ref={inputUpload} className="p-inputtext p-component" type="file" accept="image/png, image/jpg, image/jpeg" onChange={(e) => setFrmData({...frmData, file: e.target.files[0]})} />
                                            <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.external_url.value, file: null})} />
                                        </React.Fragment> 
                                    }
                                </div>
                                <div className="p-form-error">{errors.image}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Description</label>
                                <div className="p-form-error">{errors.content}</div>
                                <InputTextarea rows={5} value={frmData.description} onChange={(e) => setFrmData({...frmData, description: e.target.value})} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <Fieldset legend="Courses">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <label className="p-label"></label>
                                <Select multi
                                    values={products && frmData.productIds && frmData.productIds.length>0 ? products.filter(x1 => frmData.productIds.some(x2 => x2 === x1.value)) : []}
                                    options={products} onChange={(values) => onChangeProduct(values)}
                                    style={{ width: '100%' }} placeholder="Not use"
                                    noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No Data</span>) }}
                                />
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={closeForm}/>
                    <Button label="Submit" icon="pi pi-check" onClick={() => handleSave()}/>
                </div>
            </div>
        </Sidebar>
    )
})
