import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { SplitButton } from 'primereact/splitbutton';
import React, { Component } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { withNamespaces } from 'react-i18next';
import { getTablePageReport } from "../../core/service/CommonService";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { CampaignActionForm } from './CampaignActionForm';
import { CampaignForm } from './CampaignForm';
import { CampaignServices } from './CampaignServices';

class CampaignListView extends Component {
    constructor(props){
        super(props);
        this.state = {
            dataList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: 'id',
            sortOrder: -1,
            filter: {
                id: '',
                code: '',
                name: ''
            }
        }
        this.campaignServices = new CampaignServices();
    }

    componentDidMount() {
        showloading();
        this.getPageCampaign(this.state.index, this.state.rows, this.state.sortField, this.state.sortOrder);
    }


    getPageCampaign = (page, size, sortField, sortOrder) => {
        this.campaignServices.getPageCampaign(page, size, sortField, sortOrder)
        .then(res => {
            stoploading();
            this.setState({
                dataList: res.content,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize,
                total: res.totalElements
            });
        });
    }

    onPage = (e) =>{
        this.getPageCampaign(e.page, this.state.rows, this.state.sortField, this.state.sortOrder)
    }

    onSort = (e) =>{
        this.setState({
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        this.getPageCampaign(this.state.index, this.state.rows, e.sortField, e.sortOrder);
    }

    applyFilter = () => {
        this.getPageCampaign(this.state.index, this.state.rows);
    }

    clearFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                id: '',
                code: '',
                name: '',
            }
        }, () => { this.applyFilter() });
    }

    onChangePageLength = l => {
        this.setState({
            rows: l,
            page: 0
        }, () => {
            this.applyFilter();
        })
    }

    actionTemplate = (rowData, column) => {
        let dropActions = [
            {
                label: 'Enable Courses',
                icon: 'pi pi-cog',
                command: (e) => {
                    this.actionCampaignForm.action(rowData);
                }
            }
        ];
        return <SplitButton label="Manage" className="p-button-constrast" model={dropActions} tooltip='Edit Campaign' tooltipOptions={{position: "top"}} onClick={(e)=> this.campaignForm.action(rowData)}/>
    }

    activeTemplate = (rowData, column) => {
        return (
            <span className={classNames('status', {'active': rowData.active, 'inactive': !rowData.active})}>{rowData.active ? 'Active' : 'Inactive'}</span>
        );
    }

    render() {
        const { t } = this.props;
        const trans = (path) => t(path, { framework: "react-i18next" });

        let tableLengthOptions = [
            {
                label: '5',
                command: (e) => {
                    this.onChangePageLength(5);
                }
            },
            {
                label: '10',
                command: (e) => {
                    this.onChangePageLength(10);
                }
            },
            {
                label: '25',
                command: (e) => {
                    this.onChangePageLength(25);
                }
            },
            {
                label: '50',
                command: (e) => {
                    this.onChangePageLength(50);
                }
            },
            {
                label: '100',
                command: (e) => {
                    this.onChangePageLength(100);
                }
            }
        ];

        let tableHeader = (
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <div style={{ 'float': 'left' }} >
                <Button icon="pi pi-plus" label="Create Campaign" className="p-button-constrast" style={{'float':'left'}} onClick={(e) => this.campaignForm.action()}/>
                </div>
                <div style={{ 'float': 'right' }}>
                    <SplitButton className="table-control-length p-button-constrast" label="" icon="pi pi-refresh"
                            onClick={() => this.getPageCampaign()} model={tableLengthOptions}>
                    </SplitButton>
                    {/* Show: <Dropdown className="p-datatable-length-selector" value={this.state.rows} options={TABLE_PAGING_LENGTH} onChange={(e) => this.onChangePageLength(e)} style={{ minWidth: "0" }} /> */}
                    {/* <Button className="p-margin-left-10" icon="pi pi-refresh" onClick={() => this.getPagePrograms(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)}/> */}
                </div>
                <h3 className="fle-datatable-header-title">{getTablePageReport(this.state.page, this.state.rows, this.state.total)}</h3>
            </div>
            
        );
        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Campaigns</BreadcrumbsItem>

                <CampaignForm ref={el => this.campaignForm = el}
                    refreshTable={(e) => this.getPageCampaign(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)}
                />

                <CampaignActionForm ref={el => this.actionCampaignForm=el} />

                <div className="p-col-12 datatableCampagin">
                    <DataTable
                        lazy={true} paginator={true} value={this.state.dataList}
                        first={this.state.page * this.state.rows}
                        rows={this.state.rows} totalRecords={this.state.total}
                        onPage={this.onPage} header={tableHeader} onSort={this.onSort}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        selectionMode="single" responsive={true}>
                        <Column field="id" header={ trans('campaigns.table_header.id')} sortable={true}/>
                        <Column field="code" header={ trans('campaigns.table_header.code')} sortable={true}/>
                        <Column field="name" header={ trans('campaigns.table_header.name')} sortable={true}/>
                        <Column className='status' field="active" header="Status" body={this.activeTemplate}/>
                        <Column className="tc-actions" header="" body={this.actionTemplate} style={{width:'12em', overlay: 'visible'}}/>
                    </DataTable>
                </div>
            </div>
        );
    }
}

export default withNamespaces('message')(CampaignListView);