import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { extendMembershipUsage } from './MembershipServices';
import { PAYMENT_GATEWAY, PAYMENT_METHOD, PAYMENT_MODE, PRODUCT_PRICE_UNIT, PRODUCT_SECONDARY_TYPE } from '../../constants';
import { moneyFormat } from '../../core/service/CommonService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { applyPromotionCoupon, cancelFrontDeskOrder, cancelPromotionCoupon, completeFrontDeskOrderCheckout, createFrontDeskOrder, saveCartItem } from '../../frontdesk/booking/BookingServices';
import { completeInvoice } from '../../pim/invoice/InvoiceServices';
import { Fieldset } from 'primereact/fieldset';

export const ExtendMembershipForm = forwardRef((props, ref) => {
	const [visible, setVisible] = useState(false);
	const [membership, setMembership] = useState(null);
	const [msProduct, setMsProduct] = useState(null);

	const [order, setOrder] = useState(null);
	const [msItem, setMsItem] = useState(null);

	const [appliedCoupon, setAppliedCoupon] = useState(null);
	const [couponCode, setCouponCode] = useState('');
	const [couponErr, setCouponErr] = useState(null);

	const [note, setNote] = useState(null);
	const [method, setMethod] = useState(PAYMENT_METHOD.cash.value);

	const [isLoading, setLoading] = useState(false);
	const [processing, setProcessing] = useState(false);

	useImperativeHandle(ref, () => ({
		async openForm(data) {
			if (data) {
				setVisible(true);
				setLoading(true);

				const { owner, template } = data;
				const msPrice = template.prices[0];

				setMsProduct(template);

				const frmOrderData = {
					id: owner.id,
					firstName: owner.firstName,
					lastName: owner.lastName,
					email: owner.email,
					phone: owner.phone,
				};

				let flagErr = false;

				let resOrder = await createFrontDeskOrder(frmOrderData);

				if (resOrder && resOrder.errorCode) {
					showErrorNotify('Fail to renew membership!');
					flagErr = true;
				}

				if (!flagErr) {
					const frmItemData = {
						app: 'fep',
						productId: template.id,
						orderNumber: resOrder.orderNumber,
						quantity: 1,
						subscription: true,
						subscriptionPeriod: msPrice,
					};

					resOrder = await saveCartItem(frmItemData);

					if (resOrder && resOrder.errorCode) {
						showErrorNotify(resOrder.errorCode === 500 ? resOrder.errorMessage : 'Fail to renew membership!');
						flagErr = true;
					}
				}

				if (flagErr) {
					onClose();
				} else {
					setOrder(resOrder.order);
					setMsItem(resOrder.orderItem);
					setLoading(false);
				}
			}
		},
		closeForm() {
			onClose();
		},
	}));

	const handleExtendMembershipUsage = async () => {
		setProcessing(true);

		try {
			const resCheckout = await completeFrontDeskOrderCheckout(order.orderNumber, PAYMENT_MODE.pre_pay.value, PAYMENT_METHOD.cash.value, PAYMENT_GATEWAY.ONSITE, note);

			if (resCheckout && !resCheckout.errorCode) {
				showSuccessNotify('Membership has been renew');

				if (props.reloadData) props.reloadData();

				onClose(true);
			} else {
				showErrorNotify('Fail to renew membership. Please try again later!');
			}
		} catch (error) {
			showErrorNotify('Fail to renew membership. Please try again later!');
		}

		setProcessing(false);
	};

	const onClose = (isComplete) => {
		setVisible(false);

		if (!isComplete && !!order) {
			cancelFrontDeskOrder(order.orderNumber);
		}

		setLoading(false);
		setProcessing(false);
		setMsProduct(null);
		setOrder(null);
		setMsItem(null);
		setAppliedCoupon(null);
		setCouponCode(null);
		setCouponErr(null);
		setNote(null);
	};

	const getMsTemplatePrice = () => {
		if (msProduct && msProduct.prices?.length > 0) {
			const msPrice = msProduct.prices[0];

			return `${moneyFormat(msPrice.price)}/${msPrice.quantity} ${msPrice.unit}${msPrice.quantity > 1 ? 's' : ''}`;
		}

		return '';
	};

	const handleApplyCouponCode = async () => {
		try {
			setCouponErr(null);
			const res = await applyPromotionCoupon(order.orderNumber, couponCode);

			if (res && !res.errorCode) {
				setCouponCode(null);
				setOrder(res);

				const couponAdjust = res.adjustments?.find((adjust) => adjust.coupon);
				setAppliedCoupon(couponAdjust);

				showSuccessNotify('Coupon code has been applied!');
			} else {
				if (res.errorCode === 400) {
					setCouponErr(res.errorObj?.coupon);
					showErrorNotify('Fail to apply coupon!');
				} else {
					showErrorNotify(res.errorMessage);
				}
			}
		} catch (err) {
			showErrorNotify(err?.message ?? 'Fail to apply coupon. Please try again later!');
		}
	};

	const handleCancelCouponCode = async () => {
		try {
			const res = await cancelPromotionCoupon(order.orderNumber, appliedCoupon.coupon.code);

			if (res && !res?.errorCode) {
				setOrder(res);
				setAppliedCoupon(null);
				showSuccessNotify('Coupon code has been canceled!');
			} else {
				showErrorNotify(res.errorMessage);
			}
		} catch (err) {
			showErrorNotify(err?.message ?? 'Fail to cancel coupon. Please try again later!');
		}
	};

	const dialogActionFooter = (
		<div>
			{processing ? <Button label='Submit' icon='pi pi-spin pi-spinner' disabled={true} /> : <Button label='Submit renew' icon='pi pi-check' onClick={() => handleExtendMembershipUsage()} />}
			<Button label='Cancel' icon='pi-md-close' onClick={() => onClose()} />
		</div>
	);

	return (
		<Dialog header='Renew Membership' contentStyle={{ minWidth: '450px', maxWidth: '700px', display: 'block' }} footer={dialogActionFooter} visible={visible} modal={true} onHide={() => onClose()}>
			<div className='p-grid'>
				{isLoading ? (
					<div className='p-col-12 p-c'>
						<ProgressSpinner strokeWidth='4' style={{ height: '75px' }} className='p-margin-top-30' />
						<div className='p-margin-20-0'>Loading... please wait</div>
					</div>
				) : (
					order && (
						<React.Fragment>
							{msItem && (
								<div className='p-col-12'>
									<Fieldset legend='Membership'>
										<div className='info-text'>
											<div className='title'>Membership Category:</div>
											<div className='content'>{msProduct.name}</div>
										</div>
										<div className='info-text'>
											<div className='title'>Membership Type:</div>
											<div className='content'>{PRODUCT_SECONDARY_TYPE[msProduct.secondaryType]?.label}</div>
										</div>

										{msProduct.prices?.length > 0 && (
											<div className='info-text'>
												<div className='title'>Price:</div>
												<div className='content'>{getMsTemplatePrice()}</div>
											</div>
										)}
									</Fieldset>
								</div>
							)}
							<div className='p-col-12'>
								<Fieldset legend={appliedCoupon ? 'Coupon Applied' : 'Apply Coupon'}>
									{appliedCoupon ? (
										<div className='p-d-flex p-justify-between p-ai-center'>
											<div>
												<span className='p-text-bold'>{appliedCoupon.coupon.code}</span> - {appliedCoupon.rule.nameDisplay}
											</div>
											<Button label='Cancel Coupon' className='p-button-danger btn-text-sm' onClick={() => handleCancelCouponCode()} />
										</div>
									) : (
										<div className='p-fluid'>
											<div className='p-inputgroup'>
												<InputText
													value={couponCode || ''}
													onChange={(e) => {
														setCouponCode(e.target.value);
														setCouponErr(null);
													}}
													onKeyUp={(e) => e.key === 'Enter' && handleApplyCouponCode()}
													placeholder='Enter coupon code to apply discount'
												/>
												<Button label='Apply' className='p-button-success' disabled={!couponCode} onClick={() => handleApplyCouponCode()} />
											</div>
											{couponErr && <div className='form-error'>{couponErr}</div>}
										</div>
									)}
								</Fieldset>
							</div>

							<div className='p-col-12'>
								<Fieldset legend='Price'>
									{order.itemsTotal !== order.total && (
										<React.Fragment>
											<div className='info-text'>
												<div className='title'>Sub Total:</div>
												<div className='content'>{moneyFormat(order.itemsTotal, order.currency)}</div>
											</div>
											{(() => {
												if (order.adjustTotal !== 0) {
													return (
														<div className='info-text'>
															<div className='title'>Discount:</div>
															<div className='content'>-{moneyFormat(Math.abs(order.adjustTotal), order.currency)}</div>
														</div>
													);
												}
											})()}
											{order.mapTaxes &&
												Object.keys(order.mapTaxes).length > 0 &&
												Object.values(order.mapTaxes).map((t) => {
													return (
														<div className='info-text'>
															<div className='title'>
																{t.name}
																<i>({t.rate}%)</i>:
															</div>
															<div className='content'>{moneyFormat(t.total, order.currency)}</div>
														</div>
													);
												})}
										</React.Fragment>
									)}

									<div className='info-text p-size-16'>
										<div className='title'>Total:</div>
										<div className='content p-text-bold'>{moneyFormat(order.checkoutTotal, order.currency)}</div>
									</div>
								</Fieldset>
							</div>

							<div className='p-col-12 p-fluid'>
								<label className='p-label'>Note:</label>
								<InputTextarea rows={5} placeholder='Enter a note' value={note || ''} onChange={(e) => setNote(e.target.value)} />
							</div>
						</React.Fragment>
					)
				)}
			</div>
		</Dialog>
	);
});
