import Keycloak from 'keycloak-js'
import { API_BASE_URL } from '../constants';
import { getUserSession } from '../constituent-management/contact/UserServices';
import { ROLES_CONFIG } from '../roles';
import { getListPermissionByUser } from '../scp/access-management/user/UserAccessServices';
const _kc = new Keycloak(`${API_BASE_URL}/tenant/keycloak.json?ui=fep-ui`)
//const _kc = new Keycloak(`keycloak.json`)

const initKeycloak = (onAuthenticatedCallback) => {
    _kc.init({
        onLoad: 'login-required',
    }).then(async (authenticated) => {
        if (authenticated) {
            // fetch(`${API_BASE_URL}/acl/permissions-of-roles`)
            //     .then(res => res.json())
            //     .then(perms => {
            //         // _permissions = perms
            //         localStorage.setItem("isSuperAdmin", 'YES')
            //         onAuthenticatedCallback()
            //     })
            //     .catch(error => {
            //         _permissions = []
            //         onAuthenticatedCallback()
            //     })

            let tmpUserInfo = await getUserSession()
            if(!tmpUserInfo.errorCode){
                let tmpPermissions = await getListPermissionByUser(tmpUserInfo.id)

                localStorage.setItem("user_id", tmpUserInfo.id)
                localStorage.setItem("fullname", tmpUserInfo.name)
                if(tmpUserInfo.authorities){
                    if(tmpUserInfo.authorities.includes('SUPER_ADMIN')){
                        localStorage.setItem("isSuperAdmin", 'YES')
                        tmpPermissions.push(ROLES_CONFIG.SUPER_ADMIN)
                    }else{
                        localStorage.removeItem("isSuperAdmin")
                    }
                    if(tmpUserInfo.authorities.includes('SCP_ADMIN')){
                        localStorage.setItem("isScpAdmin", 'YES')
                        tmpPermissions.push(ROLES_CONFIG.ADMIN)
                    }else{
                        localStorage.removeItem("isScpAdmin")
                    }
                }
                
                _permissions = tmpPermissions?tmpPermissions:[]
                onAuthenticatedCallback()
            }else{
                onAuthenticatedCallback()
            }
        } else {
            doLogin()
        }
    })
};

let _permissions = [];

const doLogin = _kc.login

const doLogout = () => {
    localStorage.removeItem('fullname');
    localStorage.removeItem('user_id');

    _kc.logout()
} 

const isAuthenticated = () => _kc.authenticated

const getToken = () => _kc.token

const getParseToken = () => _kc.tokenParsed

const updateToken = (successCallback) => {
    _kc.updateToken(5).then(successCallback).catch(doLogin);
}

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role))

const hasPermission = (permissions) => {
    return !permissions || permissions.length===0 || permissions.some((permission) => _permissions.includes(permission))
}

// const hasPermission = (permissions) => true

const KcUserService = {
    initKeycloak,
    doLogin,
    doLogout,
    isAuthenticated,
    getToken,
    updateToken,
    hasRole,
    hasPermission,
    getParseToken
}

export default KcUserService
