import React, { Component } from 'react';
import moment from "moment";
import { Link } from "react-router-dom";
import { getPageUploads, uploadFileContact, UploadServices } from '../../scp/tool-util/upload/UploadServices';
import { showNotification } from '../../core/service/NotificationService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { OBJECT_TYPE, TABLE_PAGING_LENGTH } from '../../constants';
import { dowloadFileImportErrors } from '../../scp/tool-util/download/DownloadServices';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { getTablePageReport } from '../../core/service/CommonService';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { Button } from 'primereact/button';
import { importMemberships } from './MembershipServices';


export default class ImportMembership extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: 0,
            file: null,
            loading: true,
            errors: {}
        }
        this.uploadServices = new UploadServices();
    }

    componentDidMount(){
        showloading();
        this.loadPageImportBatchs();
    }

    handleUpload = () => {
        this.setState({ errors: {}});
        showloading();
        importMemberships(this.state.file)
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    errors: res.errors ? res.errors : {}
                });
                this.loadPageImportBatchs();
                showNotification('success', 'Success Message', 'Action Submitted');
                this.resetData();
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj});
            }
        }).finally(()=>stoploading())
    }

    loadPageImportBatchs = () =>{
        getPageUploads({type: OBJECT_TYPE.membership}, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
        .then(res => {
            stoploading();
            this.setState({
                dataList: res.content,
                total: res.totalElements,
                loading: false,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            });
        })
    }

    onPage = (e) =>{
        this.setState({
            loading: true,
            page: e.page
        }, () => {
            this.loadPageImportBatchs();
        });
    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        }, () => {
            this.loadPageImportBatchs();
        });
    }

    onChangePageLength = (e) => {
        this.setState({
            rows: e.value,
            page: 0
        }, () => {
            this.loadPageImportBatchs();
        });
    }

    downloadFileError = (data) => {
        var fileDownload = require('js-file-download');
        dowloadFileImportErrors(data.id, OBJECT_TYPE.contact)
        .then(res => {
            if(!res){
                showNotification('error', 'Error Message', 'No File Found')          
            } else {
                fileDownload(res, data.path);
            }
        });
    }

    resetData = () => {
        this.setState({
            file: null
        })
        this.file.value = '';
    }

    render() {
        let header = (
            <TableHeader title="Import Membership" 
                pageReport={getTablePageReport(this.state.page, this.state.rows, this.state.total)}
                changePageLength={(e) => this.onChangePageLength(e)}
                refresh={() => this.loadPageImportBatchs()}
            />
        );

        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Import Membership</BreadcrumbsItem>

                <div className="p-col-12">
                    <input ref={el => this.file = el} type="file" className="p-margin-bottom-10"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={(e) => this.setState({file: e.target.files[0]})}
                    />
                    <Button label="Import" icon="pi pi-upload" onClick={()=> this.handleUpload()} />
                    <div className="p-form-error">{this.state.errors.file}</div>
                </div>
                {/* <div className="p-col-12">
                    <ul className="p-padding-none p-margin-none" style={{listStyleType: "none"}} >
                        {Object.keys(this.state.errors).length !== 0 ? 
                            Object.entries(this.state.errors).map(([key, value], index) => {
                                return (
                                    <li key={index} className="p-padding-5-0">
                                        <span className="p-form-error">{value}</span>
                                    </li>
                                );
                            })
                            : ''
                        }
                    </ul>
                </div> */}
                <div className="p-col-12">
                    <DataTable lazy={true} paginator={true} value={this.state.dataList}
                        first={this.state.page * this.state.rows}
                        header={header} onPage={this.onPage} onSort={this.onSort}
                        rows={this.state.rows} totalRecords={this.state.total}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        responsive={true}
                    >
                        <Column header="Creator" 
                            body={(rowData) => {
                                return(
                                    <Link to={`/constituent-mgt/contacts/${rowData.user.id}`} className="nav-link">
                                        {rowData.user.name}
                                    </Link>
                                );
                            }}
                        />
                        {/* <Column field="type" header="Type" /> */}
                        <Column field="rows" header="No.Rows" />
                        <Column field="rowErrors" header="No.Error" />
                        <Column field="created" header="Import On" sortable
                            body={(rowData) => {
                                return (<span>{moment(rowData.created).format("DD/MM/YYYY hh:mm A")}</span>);
                            }}
                        />
                        <Column header="Download Error" style={{width: '12em'}} 
                            body={(rowData) => {
                                return(
                                    <button className="p-button p-component" onClick={() => this.downloadFileError(rowData)}>
                                        <i className="pi pi-download" style={{fontSize: "1.5em"}}></i>
                                    </button>
                                );
                            }}
                        />
                    </DataTable>
                </div>
            </div>
        );
    }
}