import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { notificationEvent } from '../../service/NotificationService';

export const ConfirmDialog = () => {
	const [visible, setVisible] = useState(false);
	const [dialogContent, setDialogContent] = useState({ accept: () => {} });

	useEffect(() => {
		notificationEvent.on('CONFIRM_NOTIFY', showConfirm);

		// returned function will be called on component unmount
		return () => {
			notificationEvent.removeListener('CONFIRM_NOTIFY', showConfirm);
		};
	}, []);

	const showConfirm = (dialogContent) => {
		setVisible(true);
		setDialogContent(dialogContent);
	};

	const accept = () => {
		dialogContent.accept();
		close();
	};

	const onActionOnlick = (item) => {
		if (item.command) item.command();
		close();
	};

	const close = () => {
		setVisible(false);
		setDialogContent({});
	};

	const footer = (
		<div>
			{dialogContent.modelActions ? (
				dialogContent.modelActions.map((item) => {
					return <Button label={item.label} className={item.className} icon={item.icon} onClick={() => onActionOnlick(item)} />;
				})
			) : (
				<Button label={dialogContent.acceptLabel ? dialogContent.acceptLabel : 'Yes'} icon='pi large pi-check' onClick={accept} />
			)}
			<Button label={dialogContent.rejectLabel ? dialogContent.rejectLabel : 'No'} icon='pi large pi-times' className='p-button-secondary' onClick={close} />
		</div>
	);

	return (
		<Dialog header={dialogContent.header ? dialogContent.header : 'Confirmation'} footer={footer} visible={visible} responsive={true} modal={true} dismissableMask={true} onHide={close}>
			<i className={classNames('p-confirm-dialog-icon', dialogContent.iconClassName ? dialogContent.iconClassName : 'pi pi-exclamation-triangle')} />
			<span className='p-confirm-dialog-message'>{dialogContent.message ? dialogContent.message : 'Are you sure you want to proceed'}</span>
		</Dialog>
	);
};
