import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { useRouteMatch } from 'react-router-dom';
import { SplitButton } from 'primereact/splitbutton';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport, isInValidDateTime } from '../../core/service/CommonService';
import { APP_FEP, PROMOTION_TYPE } from '../../constants';
import { getPagePromotions, removePromotion, savePromotionPriority } from './PromotionService';
import { PromotionForm } from './PromotionForm';

export const PromotionListView = (props) => {
	const match = useRouteMatch();

	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'priority',
		sortOrder: 1,
	});
	const [filter, setFilter] = useState({ app: APP_FEP });

	const [isLoadTable, setLoadTable] = useState(true);

	const frmPromotion = useRef(null);

	useEffect(() => {
		if (isLoadTable) loadPagePromotions();
	}, [isLoadTable]);

	const loadPagePromotions = () => {
		getPagePromotions(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder).then((res) => {
			setDataList(res.content);
			setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
			setLoadTable(false);
		});
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const tblHeader = (
		<TableHeader
			title='Campaign List'
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			actionTemplate={<Button className='p-button-success' label='Create Campaign' icon='pi-md-plus' onClick={() => onAddOrEditPromotion(null)} />}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
		/>
	);

	const onAddOrEditPromotion = (data) => {
		let tmpDefaultPriority = dataList.length > 0 ? dataList[dataList.length - 1].priority : 0;
		frmPromotion.current.openForm(data, tmpDefaultPriority + 1);
	};

	const onRemove = (id) => {
		showConfirmNotify({
			accept: () => handelDeletePromotion(id),
		});
	};

	const handelDeletePromotion = (id) => {
		removePromotion(id).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Action Submitted');
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	const handleUpdatePriority = (e) => {
		let arrayId = [];
		e.value.map((item) => {
			arrayId.push(item.id);
		});
		savePromotionPriority(arrayId).then((res) => {
			setLoadTable(true);
		});
	};

	const isValidStatus = (data) => {
		return data.active && isInValidDateTime(data.startAt, data.endAt, true);
	};

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>Campaign List</BreadcrumbsItem>

			<PromotionForm ref={frmPromotion} refreshTable={() => setLoadTable(true)} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1 className='p-w-bold p-m-0'>Campaign List</h1>
				</div>

				<DataTable
					emptyMessage="There's no record found"
					header={tblHeader}
					value={dataList}
					lazy={true}
					loading={isLoadTable}
					paginator={true}
					first={pageable.page * pageable.rows}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					onPage={onPage}
					onSort={onSort}
					responsive={true}
					reorderableColumns
					onRowReorder={handleUpdatePriority}
				>
					<Column rowReorder style={{ width: '4em' }} />
					<Column field='id' header={'ID'} sortable={true} />
					<Column field='name' header={'Name'} sortable={true} />
					<Column field='allowCoupon' header={'Allow Coupon'} body={(rowData) => (rowData.allowCoupon ? 'Yes' : 'No')} />
					<Column field='type' header={'Type'} body={(rowData) => (PROMOTION_TYPE[rowData.type] ? PROMOTION_TYPE[rowData.type].label : '')} />
					<Column field='valid' header={'Status'} body={(rowData) => (isValidStatus(rowData) ? 'Valid' : 'Invalid')} />
					<Column field='priority' header={'Priority'} sortable={true} />
					<Column
						style={{ textAlign: 'right', width: '13em' }}
						bodyClassName='tc-actions'
						body={(rowData) => {
							const actionItems = [
								{ label: 'Edit', icon: 'pi pi-pencil', command: () => onAddOrEditPromotion(rowData) },
								{ label: 'Remove', icon: 'pi pi-trash', command: () => onRemove(rowData.id) },
							];
							return (
								<SplitButton className='p-button-constrast p-l' label='View' icon='pi pi-search' model={actionItems} onClick={() => props.history.push(`/promotions/${rowData.id}`)}></SplitButton>
							);
						}}
					/>
				</DataTable>
			</div>
		</div>
	);
};
