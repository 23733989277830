import React, { Component } from 'react';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import {Fieldset} from "primereact/fieldset";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import { SimpleEventServices } from "./SimpleEventService";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { DeleteNotification } from "../../core/components/DeleteNotification";
import SimpleEventScheduleForm from "./SimpleEventScheduleForm";
import { SimpleCalendarServices } from '../simple-calendar/SimpleCalendarServices';
import { showNotification } from '../../core/service/NotificationService';
import { SplitButton } from 'primereact/splitbutton';
import { getTablePageReport } from "../../core/service/CommonService";

export default class SimpleEventList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventId: 0,
            id : 0,
            simpleEventList: {},
            loading: true,
            filter: {
                viewType: "all",
                title: '',
                start: '',
                end: '',
                calendarId: '',
            },
            index: 0,
            page: 0,
            rows: 10,
            total: 0,
            sortField: "id",
            sortOrder: -1,
            formVisible: false,
            formHeader: "Add Event"
        };

        this.SimpleEventServices = new SimpleEventServices();
        this.SimpleCalendarServices = new SimpleCalendarServices();
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.getSimpleEvents();
        this.getListSimpleCalendar();
    }

    resize = () => {
        this.setState({changeScreenSize: window.innerWidth <= 600});
    }

    getListSimpleCalendar = () => {
        this.SimpleCalendarServices.getListSimpleCalendars()
        .then(res => {
            this.setState({
                lstCalendars: res ? res : []
            })
        })
    }

    getSimpleEvents = () => {
        this.SimpleEventServices.getPageSimpleEvents(this.state.filter,
            this.state.page,
            this.state.rows,
            this.state.sortField,
            this.state.sortOrder)
            .then(res => {
                this.setState({
                    simpleEventList: res.content,
                    total: res.totalElements,
                    loading:false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                });
            })
    };

    getOneSimpleEvent = () => {
        this.SimpleEventServices.getOneSimpleEvent(this.props.id)
            .then(res => {
                this.setState({
                    simpleEventList: {
                        id: res.id,
                        title: res.title,
                        startDate: new Date(res.start),
                        endDate: new Date(res.end),
                        calendarId: res.calendarId,
                        allDay: res.allDay,
                        busy: res.busy
                    },
                    errors: {}
                });
            });
    }

    handleKeyPress = e => {
        if (e.key === "Enter") {
            this.applyFilter();
        }
    };

    actionTemplate = (rowData, column) => {
        let dropActions = [
            {
                label: 'Delete Event',
                icon: 'pi-md-close',
                command: (e) => {
                    this.deleteNotify.delete(rowData.id);
                }
            }
        ];
        return <SplitButton label="Edit" className="p-button-constrast" model={dropActions} tooltip={'Edit Event'} tooltipOptions={{position: "top"}} onClick={(e) => this.addOrEditSimpleEvent(rowData.id)}/>
    };

    onPage = e => {
        this.setState(
            {
                loading: true,
                page: e.page
            },
            () => {
                this.getSimpleEvents();
            }
        );
    };

    onSort = e => {
        this.setState(
            {
                loading: true,
                sortField: e.sortField,
                sortOrder: e.sortOrder
            },
            () => {
                this.getSimpleEvents();
            }
        );
    };

    applyFilter = () => {
        this.setState(
            {
                loading: true,
                page: 0
            },
            () => {
                this.getSimpleEvents();
            }
        );
    }

    clearFilter = () => {
        this.setState({
            filter: {...this.state.filter,
                title: '',
                start: '',
                end: '',
                calendarId: '',
            }
        }, () => {this.applyFilter()});
    }

    onChangePageLength = l => {
        this.setState({
            rows: l,
            page: 0
        }, () => {
            this.applyFilter();
        })
    }

    addOrEditSimpleEvent = (id) => {
        this.setState({
            eventId: id,
            formVisible: true,
            formHeader: id ? "Edit Event" : "Add Event"
        });
    };

    removeSimpleEvent = (id) => {
        this.SimpleEventServices.removeSimpleEvent(id).then(res => {
            if (res.status.code === 200) {
                this.getSimpleEvents();
                showNotification('success', 'Success Message', 'Successfully Deleted');
            } else {
                showNotification('error', 'Error Message', res.status.message);
            }
        });
    }

    render() {
        let tableLengthOptions = [
            {
                label: '5',
                command: (e) => {
                    this.onChangePageLength(5);
                }
            },
            {
                label: '10',
                command: (e) => {
                    this.onChangePageLength(10);
                }
            },
            {
                label: '25',
                command: (e) => {
                    this.onChangePageLength(25);
                }
            },
            {
                label: '50',
                command: (e) => {
                    this.onChangePageLength(50);
                }
            },
            {
                label: '100',
                command: (e) => {
                    this.onChangePageLength(100);
                }
            }
        ];

        let tblHeader = (
            <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
                <Button
                    icon="pi pi-plus"
                    label="Create Event"
                    className="p-button-constrast"
                    style={{ float: "left" }}
                    onClick={(e) => this.addOrEditSimpleEvent(this.state.id)}
                />
                <div style={{ 'float': 'right' }}>
                    <SplitButton className="table-control-length p-button-constrast" label="" icon="pi pi-refresh"
                            onClick={() => this.getListSimpleCalendar()} model={tableLengthOptions}>
                    </SplitButton>
                </div>
                <h3 className="fle-datatable-header-title">{getTablePageReport(this.state.page, this.state.rows, this.state.total)}</h3>
            </div>
            
        );

        let scrollable
        let responsive
        if(this.state.changeScreenSize) {
            scrollable = false
            responsive = true
        } else {
            scrollable = true
            responsive = false
        }

        return (
            <div className="p-grid">
                <SimpleEventScheduleForm formHeader={this.state.formHeader}
                    visible={this.state.formVisible}
                    hideForm={() => this.setState({ formVisible: false })}
                    reloadTable={() => this.getSimpleEvents()}
                    onSuccess={(e) => showNotification('success', 'Success Message', 'Action submitted')}
                    onError={(e) => showNotification('error', 'Error Message', 'Cannot perform action')}
                    eventId={this.state.eventId}

                />

                <DeleteNotification ref={el => this.deleteNotify = el}
                    accessDelete={(e) => this.removeSimpleEvent(e)}
                />

                <div className="p-col-12">
                    <div className="card card-w-title">
                    <Fieldset legend="Program Filters">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-12">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-1">
                                            <label>Title</label>
                                        </div>
                                        <div className="p-col-12 p-md-11">
                                            <InputText value={this.state.filter.title} onKeyUp={this.handleKeyPress} onChange={(e) => this.setState({filter: {...this.state.filter, title: e.target.value}})}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <label>Start Date</label>
                                        </div>
                                        <div className="p-col-12 p-md-10">
                                        <MaskedCalendar value={this.state.filter.start} showIcon={true} onChange={(e) => this.setState({ filter: { ...this.state.filter, start: e.target.value } })} dateFormat="mm-dd-yy" />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <label>End Date</label>
                                        </div>
                                        <div className="p-col-12 p-md-10">
                                        <MaskedCalendar value={this.state.filter.end} showIcon={true} onChange={(e) => this.setState({ filter: { ...this.state.filter, end: e.target.value } })} dateFormat="mm-dd-yy" />
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-2">
                                            <label>Calendar</label>
                                        </div>
                                        <div className="p-col-12 p-md-10">
                                        <Dropdown value={this.state.filter.calendarId} options={this.state.lstCalendars} showIcon={true} onChange={(e) => this.setState({ filter: { ...this.state.filter, calendarId: e.target.value } })}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12" style={{"textAlign": "center"}}>
                                    <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={this.applyFilter} />
                                    <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={this.clearFilter} />
                                </div>
                            </div>
                        </Fieldset>
                        <h1 style={{ textTransform: 'capitalize' }}>{this.props.type}</h1>
                        <DataTable
                            value={this.state.simpleEventList}
                            lazy={true}
                            header={tblHeader}
                            paginator={true}
                            first={this.state.page * this.state.rows}
                            onPage={this.onPage}
                            onSort={this.onSort}
                            rows={this.state.rows}
                            totalRecords={this.state.total}
                            sortField={this.state.sortField}
                            sortOrder={this.state.sortOrder}
                            responsive={responsive}
                            scrollable={scrollable}
                        >
                            <Column field="id" header="ID" sortable={true} style={{ width: "12em" }} />
                            <Column field="title" header="Title" sortable={true} style={{ width: "20em" }} />
                            <Column className="dateStart" field="start" header="Start Date" sortable={true} style={{ width: "16em" }} />
                            <Column className="dateEnd" field="end" header="End Date" sortable={true} style={{ width: "16em" }} />
                            <Column
                                field=""
                                className="tc-actions"
                                body={this.actionTemplate}
                                style={{ width: "10em" }}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}