import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../../constants';
import { converObjetToFormData } from '../../core/service/CommonService';

const configFormDataHeader = {
    headers: { 'Content-Type': 'multipart/form-data' }
}
const DEPARMENT_SERVICE_URL = API_BASE_URL + SCP_PATH + '/departments';
export class DepartmentServices {
    getPageDepartments(page, size, sortField, sortOrder) {
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.post(`${DEPARMENT_SERVICE_URL}/page`, {}, {
            params: {page, size, sort}
        }).then(res => res.data).catch(error => console.log(error));
    }

    saveDepartment(data){
        let formData = new FormData()
        for (var key in data) {
            converObjetToFormData(formData, key, data[key])
        }
        return axios.post(`${DEPARMENT_SERVICE_URL}/save`, formData, configFormDataHeader).then(res => res.data).catch(error => console.log(error));
    }

    removeDepartment(id){
        return axios.delete(`${DEPARMENT_SERVICE_URL}/${id}`).then(res => res.data).catch(error => console.log(error));
    }
    
    getDepartment(id) {
        return axios.get(`${DEPARMENT_SERVICE_URL}/${id}`)
        .then(res => res.data)
        .catch(error => console.log(error));
    }

    saveDepartmentInfomation(data){
        let formData = new FormData()
        for (var key in data) {
            converObjetToFormData(formData, key, data[key])
        }
        return axios.post(`${DEPARMENT_SERVICE_URL}/save-information`, formData, configFormDataHeader).then(res => res.data).catch(error => console.log(error));
    }

    saveDepartmentTalents(data){
        return axios.post(`${DEPARMENT_SERVICE_URL}/save-talents`, data).then(res => res.data).catch(error => console.log(error));
    }

    getDepartmentTalentsLevels(id){
        return axios.get(`${DEPARMENT_SERVICE_URL}/${id}/talents/levels`)
        .then(res => {return res.data}).catch(error => console.log(error));
    }

    getPageMemberDepartment(id, page, size, sortField, sortOrder){
        const order = sortOrder ===1 ? "asc" : sortOrder === -1 ? "desc" : "";
        const sort = sortField ? `${sortField},${order}`: "";
        return axios.get(`${DEPARMENT_SERVICE_URL}/${id}/members/page`,{
            params: {page, size, sort}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    saveDepartmentMember(id, data){
        return axios.post(`${DEPARMENT_SERVICE_URL}/${id}/members`,null, {params: {...data}}).then(res => res.data);
    }

    deleteDepartmentMember(departmentId, id){
        return axios.delete(`${DEPARMENT_SERVICE_URL}/${departmentId}/members/${id}`).then(res => res.data);
    }

    getListSimpleDepartmentMember(id){
        return axios.get(`${DEPARMENT_SERVICE_URL}/${id}/members/list`).then(res => res.data).catch(error => console.log(error));
    }
}

export function getListDepartments(filter={}, simple=true){
    return axios.post(`${DEPARMENT_SERVICE_URL}/list`, filter, {params: {simple}}).then(res => res.data).catch(error => console.log(error));
}

export function getPageDepartments(page, size, sortField, sortOrder) {
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.post(`${DEPARMENT_SERVICE_URL}/page`, {}, {
        params: {page, size, sort}
    }).then(res => res.data).catch(error => console.log(error));
}

export function removeDepartment(id){
    return axios.delete(`${DEPARMENT_SERVICE_URL}/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function saveDepartment(data){
    let formData = new FormData()
    for (var key in data) {
        converObjetToFormData(formData, key, data[key])
    }
    return axios.post(`${DEPARMENT_SERVICE_URL}/save`, formData, configFormDataHeader).then(res => res.data).catch(error => console.log(error));
}

export function getDeparmentByCode(code){
    return axios.get(`${DEPARMENT_SERVICE_URL}/get-by-code`, {params: {code}}).then(res => res.data).catch(error => console.log(error));
}