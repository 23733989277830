import React, { Component } from 'react';
// import ThroughProvider from 'react-through/lib/ThroughProvider';
import classNames from 'classnames';
import { AppTopbar } from './core/layout/AppTopbar';
import { AppFooter } from './core/layout/AppFooter';
import { AppMenu } from './core/layout/AppMenu';
import { AppBreadcrumb } from './core/layout/AppBreadcrumb';
import { Route, Switch, Redirect } from 'react-router-dom';
import DomHandler from 'primereact/components/utils/DomHandler';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import { withRouter } from 'react-router';
import './ripple.js';
import './App.css';
import './App.scss';
import '@fullcalendar/core/main.css';

// import Programs from "./crm/program/Programs";
// import ProgramDetails from "./crm/program/ProgramDetails";
// import {ProgramCourseSetupDetails} from "./crm/program/ProgramCourseSetupDetails";
import { MembershipTemplates } from './crm/membership/MembershipTemplates';
import { MembershipTemplateDetails } from './crm/membership/MembershipTemplateDetails';
import { MembershipTemplateLevelDetails } from './crm/membership/MembershipTemplateLevelDetails';
// import { ProgramAssessment } from './crm/program/ProgramAssessment';
// import { ProgramAssessmentDetails } from './crm/program/ProgramAssessmentDetails';
// import ProgramCopy from './crm/program/ProgramCopy';
import UsageFindTracking from './crm/program/UsageFindTracking';

import { PromotionListView } from './pim/promotion/PromotionListView';
import { PromotionDetails } from './pim/promotion/PromotionDetails';
import { createBrowserHistory } from 'history';
import AccessDenied from './errors/access-denied/AccessDenied';
import NotFound from './errors/not-found/NotFound';
import ErrorPage from './errors/error-page/ErrorPage';
import { Participant } from './constituent-management/participant/Participant';
import { ParticipantDetails } from './constituent-management/participant/ParticipantDetails';
// import { Budgets } from './crm/budget/Budgets';
import LoadingOverlay from './core/components/LoadingOverlay';
import lazyLoad from './core/components/lazyLoad';
import { Transactions } from './scp/accounting/transaction/Transactions';
import { TransactionDetails } from './scp/accounting/transaction/TransactionDetails';
import { Orders } from './pim/order/Orders';
import { OrderDetails } from './pim/order/OrderDetails';

import { GLAccounts } from './scp/accounting/gl_accounts/GLAccounts';
import { Reports } from './scp/report/Reports';
import { CRMReports } from './scp/report/CRMReports';

import { ROLES_CONFIG } from './roles';
import { Configuration } from './scp/tenant-configuration/Configuration';
import { DoorControl } from './scp/tenant-configuration/administrator/Doorcontrol';
import SimpleEventManagement from './scp/event-management/SimpleEventView';

import { WebErrorListView } from './web-errors/WebErrorListView';
import WebErrorDetailView from './web-errors/WebErrorDetailView';
import 'react-tippy/dist/tippy.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import {
	TAXONOMY_TYPE,
	PROMOTION_COUPON_TYPE,
	PRODUCT_TYPE,
	PRODUCT_PARTICIPANT_TYPE,
	TRAINING_COURSE_ACTIVITY_CODE,
	SOCIAL_SUPPORT_ACTIVITY_CODE,
	CLUBHOUSE_ACTIVITY_CODE,
	TRAINING_COURSE_DIPLOMA_CODE,
	PRODUCT_CLASSIFICATION,
	OBJECT_TYPE,
} from './constants';
// import loadExtraUserData from './core/service/UserService';
// import SockJS from 'sockjs-client';
// import { Client } from '@stomp/stompjs';
import { ProgramCourseManageDetails } from './crm/program/ProgramCourseManageDetails';
import PermissionRoute from './core/security/PermissionRoute';
import UserProfile from './constituent-management/user-profile/UserProfile';
import { NoteServices } from './constituent-management/note/NoteServices';
import WorkflowProgress from './form/workflow-progress/WorkflowProgress';
import ConnectedAppListView from './scp/connected-application/ConnectedAppListView';
import AuthRoute from './core/security/AuthRoute';

import { Dropdown } from 'primereact/dropdown';
import { Dashboard3 } from './scp/dashboard/Dashboard3';
import { LiveAccountListView } from './scp/event-session/LiveAccountListView';
import { FAQListView } from './scp/event/FAQListView';
import { loadPublicConfig } from './scp/tenant-configuration/ConfigruationServices';
import ApplicationListView from './scp/application/ApplicationListView';
import ApplicationRecordListView from './scp/application/ApplicationRecordListView';

import { ProductOptionManagement } from './pim/product_option/ProductOptionManagement';
import { ProductManagement } from './pim/product/ProductManagement';
import { TaxonListView } from './pim/taxon/TaxonListView';
import InventoryListView from './pim/inventory/InventoryListView';
import { InvoiceListView } from './pim/invoice/InvoiceListView';
import { InvoiceDetails } from './pim/invoice/InvoiceDetails';
import { AssetListView } from './pim/asset/AssetListView';
import { AssetDetails } from './pim/asset/AssetDetails';
import { FacilityReport } from './pim/asset/FacilityReport';
import CommingSoon from './errors/comming-soon/CommingSoon';
import { MemberListView } from './constituent-management/membership/MemberListView';
import { InventoryDetailView } from './pim/inventory/InventoryDetailView';
import { AssetReportListView } from './pim/asset-schedule/AssetReportListView';

// import * as webEventService from './core/service/WebEventService';

import { BookingOrders } from './frontdesk/booking/BookingOrders';
import { BookingProcess } from './frontdesk/booking/BookingProcess';
import RoleDetails from './scp/access-management/role/RoleDetails';
import DepartmentDetailsView from './scp/departments/DepartmentDetailsView';
import { DepartmentListView } from './scp/departments/DepartmentListView';

import { CmsManagement } from './cms/CmsManagement';
import CampaignListView from './scp/campaign/CampaignListView';

import 'react-phone-number-input/style.css';
import { OperatingView } from './frontdesk/operating/OperatingView';
import KcUserService from './service/KcUserService';
import { PromotionCouponListView } from './pim/promotion/PromotionCouponListView';
import { EventSessionListView } from './scp/event-session/EventSessionListView';

import ImportMember from './constituent-management/membership/ImportMember';
import MembershipCategoryListView from './crm/membership_new/MembershipCategoryListView';
import MembershipCategoryDetail from './crm/membership_new/MembershipCategoryDetail';
import MembershipIssueListView from './frontdesk/membership-issue/MembershipIssueListView';
import CardMgt from './constituent-management/membership/CardMgt';
import MergeDuplicateProcess from './frontdesk/merge-duplicate/MergeDuplicateProcess';
import { MembershipProductListView } from './pim/membership/MembershipProductListView';
import { MembershipListView } from './constituent-management/membership/MembershipListView';
import { MembershipDetailView } from './constituent-management/membership/MembershipDetailView';
import { VolunteerApplicationRecordListView } from './scp/application/VolunteerApplicationRecordListView';
import { DiplomaLisView } from './diploma/DiplomaListView';
import { DonationListView } from './pim/donation/DonationListView';
import { DonationDetails } from './pim/donation/DonationDetails';
import { SupportCaseApplicationRecordListView } from './scp/application/SupportCaseApplicationRecordListView';
import { AttendanceListView } from './scp/training/AttendanceListView';
import { ProductListView } from './pim/product/ProductListView';
import { StudentMembershipProductListView } from './pim/membership/StudentMembershipProductListView';
import { StudentMembershipListView } from './constituent-management/membership/StudentMembershipListView';
import { ParticipantAttendanceListView } from './scp/training/ParticipantAttendanceListView';
import { TraniningReportListView } from './scp/training/TraniningReportListView';
import { WishListListView } from './scp/wishlist/WishListListView';
import { DoorListView } from './scp/door-control/DoorListView';
import ImportMembership from './constituent-management/membership/ImportMembership';
import { ImportSupportCaseApplication } from './scp/application/ImportSupportCaseApplication';
import { AttendancesCheckin } from './scp/training/AttendancesCheckin';
import { MembershipListViewOnly } from './constituent-management/membership/MembershipListView(View only)';
import { MembershipDetailViewOnly } from './constituent-management/membership/MembershipDetailView(View only)';
import { MembershipListView_ViewOnly_Ext } from './constituent-management/membership/MembershipListView_ViewOnly_Ext.js';
import { AuditLogListView } from './audit/AuditLogListView.js';
import { ImportListView } from './scp/import/ImportListView.js';

const AccessManagement = lazyLoad(() => import('./scp/access-management/AccessManagement'));
const ConstituentManagement = lazyLoad(() => import('./constituent-management/ConstituentManagement'));
const ImportExportManagement = lazyLoad(() => import('./scp/tool-util/upload/ImportExportManagement'));
const AttributeMangement = lazyLoad(() => import('./scp/attribute/AttributeMangement'));
const MailLingManagement = lazyLoad(() => import('./mail-management/MailLingManagement'));
const HRManagement = lazyLoad(() => import('./crm/human_resource/HRManagement'));

class App extends Component {
	constructor() {
		super();
		this.state = {
			selectedApp: 'FAST',
			appMenu: this.createMenu('FAST'),
			appLogo: 'assets/fromlabs/FAST_logo.png',
			activeTopbarItem: null,
			layoutMode: 'static',
			mobileMenuActive: null,
			topbarMenuActive: null,
			currentRoute: null,
			menuActive: false,
			darkMenu: true,
			wsNotifications: [],
			userNotifications: [],
			refreshNotification: false,
		};

		this.notificationCount = 0;

		this._history = createBrowserHistory();
		this.unlisten = this._history.listen((location, action) => {});

		this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
		this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
		this.onTopbarMobileMenuButtonClick = this.onTopbarMobileMenuButtonClick.bind(this);
		this.onWrapperClick = this.onWrapperClick.bind(this);
		this.onSidebarMouseEnter = this.onSidebarMouseEnter.bind(this);
		this.onSidebarMouseLeave = this.onSidebarMouseLeave.bind(this);
		this.onToggleMenuClick = this.onToggleMenuClick.bind(this);
		this.onSidebarClick = this.onSidebarClick.bind(this);
		this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
		this.onMenuItemClick = this.onMenuItemClick.bind(this);

		this.noteServices = new NoteServices();

		this.initApp(this.state.selectedApp);
	}

	componentDidMount() {
		loadPublicConfig();
	}

	// stompHandle = () => {
	//     let appComponent = this;

	//     var wsc = new Client({
	//         brokerURL: WS_BASE_URL + '?access_token=' + `${localStorage.getItem('access_token')}`,
	//         debug: function (str) {
	//             console.log(str);
	//         },
	//         heartbeatIncoming: 4000,
	//         heartbeatOutgoing: 4000
	//     });

	//     //Fallback
	//     if (typeof WebSocket !== 'function') {
	//         wsc.webSocketFactory = function() {
	//             return new SockJS(WS_FALLBACK_BASE_URL + '?access_token=' + `${localStorage.getItem('access_token')}`);
	//         }
	//     }

	//     wsc.onConnect = function (frame) {
	//         wsc.subscribe('/topic/broadcast', function(payload) {
	//             let message = JSON.parse(payload.body);
	//             // showNotification('notify', 'Notify Message', message.body);
	//             // appComponent.setState({
	//             //     wsNotifications: message
	//             // });
	//             // this.handleNotification(message);
	//             // showUserNotification(message.body);
	//         });
	//         wsc.subscribe('/user/queue/notify', function(payload){
	//             let message = JSON.parse(payload.body);
	//             showUserNotification(message);
	//             appComponent.handleLoadNotification();
	//         });

	//         // wsc.publish({destination: "/send-user-msg", body: JSON.stringify({ title: 'user_subscribe', body: 'subscribed' })})
	//     };

	//     wsc.onStompError = function (frame) {
	//         // Will be invoked in case of error encountered at Broker
	//         // Bad login/passcode typically will cause an error
	//         // Complaint brokers will set `message` header with a brief message. Body may contain details.
	//         // Compliant brokers will terminate the connection after any error
	//         console.log('Broker reported error: ' + frame.headers['message']);
	//         console.log('Additional details: ' + frame.body);
	//     };

	//     wsc.activate();

	//     // //Load user extra data
	//     // // this.handleLoadExtraUserData();

	//     //Load Notifications of assigned user in the notification belt icon
	//     this.handleLoadNotification();
	// }

	// handleLoadNotification = () => {
	//     this.noteServices.getMyTasks().then(data => {
	//         this.setState({
	//             userNotifications : data
	//         })
	//     })
	// }

	// componentWillMount() {
	//     notificationEvent.on('USER_NOTIFICATION', this.onUserNotificationReceived);
	// }

	// onUserNotificationReceived = (message) => {
	//     // this.Dashboard2.current.getTasks();
	//     showNotification('info', 'Info Message', 'You have been assigned a new task !');
	// }

	// handleLoadNotification = () => {
	//     this.noteServices.getMyTasks().then(data => {
	//         this.setState({
	//             userNotifications : data
	//         })
	//     })
	// }

	// handleLoadExtraUserData = () => {
	//     loadExtraUserData().then(data => {
	//         this.setState({
	//             userNotifications: data.notifications
	//         });
	//     });
	// }

	// reloadUserNotification = () => {
	//     this.noteServices.getMyTasks().then(data => {
	//         this.setState({
	//             userNotifications : data
	//         })
	//     })
	// }

	handleLogout = (e, redirectTo = '/login') => {
		e.preventDefault();
		KcUserService.doLogout();
		// this.props.history.push(redirectTo)
	};

	onWrapperClick(event) {
		if (!this.menuClick && !this.menuButtonClick && this.state.mobileMenuActive) {
			this.setState({ mobileMenuActive: false });
		}

		if (!this.topbarMenuClick && !this.topbarMenuButtonClick) {
			this.setState({
				activeTopbarItem: null,
				topbarMenuActive: false,
			});
		}

		if (!this.menuClick) {
			if (this.isHorizontal() || this.isOverlay()) {
				this.setState({
					menuActive: false,
				});
			}
		}

		this.menuClick = false;
		this.menuButtonClick = false;
		this.topbarMenuClick = false;
		this.topbarMenuButtonClick = false;
	}

	onTopbarItemClick(event) {
		this.topbarMenuClick = true;
		if (!event.toSearch && this.state.activeTopbarItem === event.item) this.setState({ activeTopbarItem: null });
		else this.setState({ activeTopbarItem: event.item });

		event.originalEvent.preventDefault();
	}

	onMenuButtonClick(event) {
		this.menuButtonClick = true;

		if (this.isMobile()) {
			this.setState({ mobileMenuActive: !this.state.mobileMenuActive });
		}

		event.preventDefault();
	}

	onTopbarMobileMenuButtonClick(event) {
		this.topbarMenuButtonClick = true;
		this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
		event.preventDefault();
	}

	onToggleMenuClick(event) {
		this.setState({ layoutMode: this.state.layoutMode !== 'static' ? 'static' : 'overlay' });
	}

	onSidebarClick(event) {
		this.menuClick = true;
	}

	onSidebarMouseEnter(event) {
		if (this.sidebarTimeout) clearTimeout(this.sidebarTimeout);
		if (this.sidebar) DomHandler.addClass(this.sidebar, 'layout-sidebar-active');
	}

	onSidebarMouseLeave(event) {
		this.sidebarTimeout = setTimeout(() => {
			if (this.sidebar) DomHandler.removeClass(this.sidebar, 'layout-sidebar-active');
		}, 250);
	}

	onRootMenuItemClick(event) {
		this.setState({
			menuActive: !this.state.menuActive,
		});
	}

	onMenuItemClick(event) {
		if (!event.item.items && this.isHorizontal()) {
			this.setState({
				menuActive: false,
			});
		}
	}

	createMenu(app) {
		switch (app) {
			default:
			case 'FEP':
				return [
					{ label: 'Identity Document', icon: 'library_books', to: '/identity-document/list' },
					{
						label: 'Dashboard and Reports',
						icon: 'library_books',
						roles: [ROLES_CONFIG.DAR],
						items: [
							{ label: 'Dashboard Operating', icon: 'home', to: '/', roles: [ROLES_CONFIG.DAR_DASHBOARD] },
							{
								label: 'Reports',
								icon: 'home',
								roles: [ROLES_CONFIG.DAR_RERPORT],
								items: [
									{ label: 'Orders', icon: '', to: '/orders', roles: [ROLES_CONFIG.DAR_RERPORT_ORDER] },
									{ label: 'Invoices-Receipts', icon: '', to: '/invoices', roles: [] },
									{ label: 'Participant', icon: '', to: '/participants' },
									{ label: 'Asset', icon: '', to: '/asset-report' },
									// {label: 'Donations', icon: '', to: '/donations'},
									// {label: 'Transactions', icon: '', to: '/transactions', roles: [ROLES_CONFIG.DAR_RERPORT_TRANSACTION]},
									// {label: 'Predefine Reports', icon: '', to: '/reports', roles: [ROLES_CONFIG.DAR_RERPORT_RUN]},
									// {label: 'Runtime Report', icon: '', to: '/crm-reports', roles: [ROLES_CONFIG.DAR_RERPORT_RUN]},
								],
							},
						],
					},
					{
						label: 'Frontdesk Monitoring',
						icon: 'library_books',
						roles: [ROLES_CONFIG.FRONTDESK],
						items: [
							// {label: 'Scan', icon: '', roles: [ROLES_CONFIG.FD_MEMBER_SCAN], items: [
							//     {label: 'Member Scan', icon: '', to: '/member-scan'},
							// ]},
							// {label: 'Operations', icon: '', to: '/monitoring/operations', roles: [ROLES_CONFIG.FRONTDESK]},
							{ label: 'Product Booking', icon: '', to: '/monitoring/booking', roles: [ROLES_CONFIG.FRONTDESK] },
							{ label: 'Invoices-Receipts', icon: '', to: '/monitoring/invoices', roles: [ROLES_CONFIG.FRONTDESK] },
						],
					},
					{
						label: 'Constituent',
						icon: 'library_books',
						roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_R],
						items: [
							{ label: 'Contacts', icon: 'contacts', to: '/constituent-mgt/contacts', roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_R] },
							// {label: 'Memberships', icon: 'library_books', to: '/constituent-mgt/memberships', roles: [ROLES_CONFIG.CONSTITUENT_MEMBERSHIP]},
							// {label: 'Merge Duplicate', to: '/constituent-mgt/merge-duplicate', roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_MERGE]},
							// {label: 'Household', icon: 'library_books', to: '/constituent-mgt/household', roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_HOUSEHOLD]},
							// {label: 'Companies', icon: 'library_books', to: '/constituent-mgt/company', roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_COMPANY]},
							// {label: 'Teams', icon: 'library_books', to: '/constituent-mgt/teams'},
							// {label: 'Departments', icon: 'library_books', to: '/scp/departments'},
							// {label: 'Interests', icon: '', roles: [ROLES_CONFIG.CONSTITUENT_INTEREST], items: [
							//     {label: 'Interest Mgt.', icon: 'library-books', to: '/constituent-mgt/interests'},
							// ]},
							// {label: 'Skills', icon: '', roles: [ROLES_CONFIG.CONSTITUENT_SKILL], items: [
							//     {label: 'Talent Mgt.', icon: 'library-books', to: '/constituent-mgt/talents'},
							//     // {label: 'Talent Mgt.', icon: 'library-books', to: '/constituent-mgt/talents_tree'},
							// ]},
						],
					},
					// {label: 'Campaigns', icon: 'library_books', to: '/campaigns', roles: [ROLES_CONFIG.CAMPAIGN]},
					{
						label: 'Activities',
						icon: 'library_books',
						roles: [ROLES_CONFIG.PRODUCT],
						items: [
							{ label: 'Activities Mgt.', icon: '', roles: [ROLES_CONFIG.PRODUCT], to: '/products' },
							{ label: 'Activity Options Mgt.', icon: '', roles: [ROLES_CONFIG.PRODUCT], to: '/product-options' },
							// {label: 'Membership', icon: '', roles: [ROLES_CONFIG.PRODUCT_MEMBERSHIP], items: [
							//     {label: 'Membership Type Mgt.', icon: 'library-books', to: '/membership_templates'},
							// ]},
							// {label: 'Donation', icon: '', roles: [ROLES_CONFIG.PRODUCT_DONATION], items: [
							//     {label: 'Donation Level Mgt.', icon: 'library-books', to: '/donation_templates'},
							// ]},
							// {label: 'Programs', icon: 'library_books', roles: [ROLES_CONFIG.PRODUCT_PROGRAM],
							//     items: [
							//         {label: 'Program Mgt.', icon: 'library-books', to: '/programs', roles: [ROLES_CONFIG.PRODUCT_PROGRAM_DETAIL]},
							//         {label: 'Copy Program', icon: 'library-books', to: '/programs-copy', roles: [ROLES_CONFIG.PRODUCT_PROGRAM_COPY]},
							//         {label: 'Fee Assistance', icon: '', to: '/fee_assistance', roles: [ROLES_CONFIG.PRODUCT_PROGRAM_BUDGET]},
							//         // {label: 'Category', icon: 'library-books', to: '/categories', roles: [ROLES_CONFIG.PRODUCT_PROGRAM_CATEGORY]},
							//         {label: 'Usage Find Tracking', icon: 'library-books', to: '/tracking'},
							//         {label: 'Trainer Mgt.', to: '/hr-mgt/trainer'},
							//         {label: 'Instructor Mgt.', to: '/hr-mgt/instructor'},
							//     ]
							// },
							// {label: 'Events', icon: 'library_books',
							//     items: [
							//         {label: 'Event Mgt.', to: '/events'},
							//         {label: 'Live Account Mgt.', to: '/live-accounts'},
							//         {label: 'Speaker Mgt.', to: '/hr-mgt/speaker'},
							//         {label: 'Marshaller Mgt.', to: '/hr-mgt/marshaller'},
							//         {label: 'Time Mgt.', to: '/event-times'},
							//         {label: 'Event Category Mgt.', to: '/event-category'},
							//         {label: 'FAQ Mgt', to:'/faq'},
							//     ]
							// },
							{ label: 'Activity Types', icon: 'library_books', to: '/departments' },
							{ label: 'Activity Categories', icon: 'library-books', to: '/product-categories' },
							// {label: 'Surveys', icon: 'library_books', roles: [ROLES_CONFIG.PRODUCT_SURVEY],
							//     items: [
							//         {label: 'Field Mgt.', icon: '', to: '/field/attribute-fields'},
							//         {label: 'Survey Mgt.', icon: '', to:'/field/surveys'},
							//     ]
							// }
						],
					},
					{
						label: 'Facilities',
						icon: 'library_books',
						roles: [ROLES_CONFIG.PRODUCT],
						items: [
							{ label: 'Facilities Mgt.', icon: 'library-books', to: '/assets-mgt' },
							{ label: 'Facility Categories', icon: 'library-books', to: '/asset-categories' },
							{ label: 'Inventory Mgt', icon: 'library-books', to: '/inventories' },
						],
					},
					{
						label: 'Sessions',
						icon: 'library_books',
						roles: [],
						items: [
							{ label: 'Session Mgt.', icon: 'library-books', to: '/sessions' },
							{ label: 'Live Account Mgt.', to: '/live-accounts' },
						],
					},
					{
						label: 'Properties',
						icon: 'library_books',
						items: [
							{
								label: 'Fields Attribute',
								icon: 'library_books',
								roles: [ROLES_CONFIG.PROPERTIY_FIELDSET],
								items: [
									{ label: 'Field Mgt.', icon: '', to: '/field/attribute-fields' },
									{ label: 'Field Grouping', icon: '', to: '/field/attribute-sets' },
									{ label: 'List Value Mgt.', icon: '', to: '/field/attribute-list' },
								],
							},
							{
								label: 'Applications',
								icon: 'library_books',
								items: [
									{ label: 'Application Mgt', icon: '', to: '/applications' },
									{ label: 'Application Records', icon: '', to: '/applications/records' },
								],
							},
							// {label: 'Calendar', icon: '', to:'/simple-event'},
							// {label: 'Static Assets', icon: 'library-books', to: '/asset-management/documents', roles: [ROLES_CONFIG.PROPERTIY_ASSET]},
							{ label: 'Branches Mgt.', icon: 'library-books', to: '/branches' },
							{ label: 'Venues Mgt.', icon: 'library-books', to: '/locations' },
						],
					},
					{
						label: 'Human Resources',
						icon: 'library_books',
						items: [
							{ label: 'Marshaller Mgt.', to: '/hr-mgt/marshaller' },
							{ label: 'Trainer Mgt.', to: '/hr-mgt/trainer' },
							{ label: 'Speaker Mgt.', to: '/hr-mgt/speaker' },
							// {label: 'Speaker Mgt.', to: '/hr-mgt/speaker'}
						],
					},
					//{label: 'Financial', icon: '', roles: [ROLES_CONFIG.FINANCIAL], items: [
					//    {label: 'Discount', icon: 'library_books', to: '/promotions', roles: [ROLES_CONFIG.FINANCIAL_DISCOUNT]},
					//    {label: 'Coupon Exchange', icon: 'library_books', to: '/exchange-coupons'},
					//     // {label: 'Financial Assistance Budget', icon: '', to: '/fee_assistance', roles: [ROLES_CONFIG.FINANCIAL_BUDGET]},
					//     // {label: 'Income Setting', icon: '', to: '/income-setting'},
					//     // {label: 'Scholarship Budget', icon: '', to: '/fee_assistance'},
					//     // {label: 'Scheduled Payment', icon: '', to: '/fee_assistance'},
					//]},
					// {label: 'Accounting', icon: 'local_atm', roles: [ROLES_CONFIG.ACCOUNTING],
					// items: [
					// {label: 'GL Accounts', icon: '', to: '/gl-accounts', roles: [ROLES_CONFIG.ACCOUNTING_GLA]},
					// {label: 'Payable Accounts', icon: '', to: '/payable-accounts', roles: [ROLES_CONFIG.ACCOUNTING_PA]},
					// ]
					// },
					{
						label: 'CMS',
						icon: 'library_books',
						roles: [],
						items: [
							{ label: 'Dynamic pages', icon: '', roles: [], to: '/cms/dynamic-pages' },
							// {label: 'Predefine pages', icon: '', roles: [], to: '/cms/predefine-page'},
							{ label: 'Menus', icon: '', roles: [], to: '/cms/menus' },
							{ label: 'Sliders', icon: '', to: '/cms/sliders' },
							{ label: 'Block contents', icon: '', to: '/cms/block-contents' },
							{ label: 'Documents', icon: 'library-books', to: '/cms/documents' },
						],
					},
					{
						label: 'System Configuration',
						icon: 'developer_board',
						roles: [ROLES_CONFIG.SYSTEM],
						items: [
							{ label: 'Configurations', icon: 'pi pi-fw pi-cog', to: '/configurations', roles: [ROLES_CONFIG.SYSTEM_CONFIGURATION_R] },
							{ label: 'SMTP Setup', icon: 'mail', to: '/mail-mgt/accounts', roles: [ROLES_CONFIG.SYSTEM_SMTP] },
							{ label: 'e-Mail Templates', icon: '', roles: [ROLES_CONFIG.TEMPLATES_EMAIL], to: '/mail-mgt/templates' },
							// {label: 'Mass e-Mail Tools', icon: 'library_books', to: '/mail-mgt/maillist'},
							// {label: 'Payment Gateways', icon: 'pi pi-fw pi-cog', to: '/payment-gateways'},
							// {label: 'Child Abuse/Offender Setup', icon: 'pi pi-fw pi-cog', to: '/child-abuse-list'}
							// {label: 'Access Control', icon: 'library_books', roles: [ROLES_CONFIG.SYSTEM_ACL,ROLES_CONFIG.SUPER_ADMIN],
							//     items: [
							//         {label: "Users", icon: 'contacts', to: '/access-mgt/users', roles: [ROLES_CONFIG.SYSTEM_ACL_USER,ROLES_CONFIG.SUPER_ADMIN]},
							//         {label: "Roles", icon: 'library_books', to: '/access-mgt/roles', roles: [ROLES_CONFIG.SYSTEM_ACL_ROLE,ROLES_CONFIG.SUPER_ADMIN]},
							//         {label: "Permissions", icon: 'library_books', to: '/access-mgt/permissions', roles: [ROLES_CONFIG.SUPER_ADMIN]},
							//     ]
							// },
							// {label: 'Scanner Setting', icon: 'pi pi-fw pi-cog', to: '/scanner-setting', electronOnly: true},
							{
								label: 'Access Control',
								icon: 'library_books',
								roles: [ROLES_CONFIG.SYSTEM_ACL, ROLES_CONFIG.SUPER_ADMIN],
								items: [
									{ label: 'Users', icon: 'contacts', to: '/access-mgt/users', roles: [ROLES_CONFIG.SYSTEM_ACL_USER, ROLES_CONFIG.SUPER_ADMIN] },
									{ label: 'Roles', icon: 'library_books', to: '/access-mgt/roles', roles: [ROLES_CONFIG.SYSTEM_ACL_ROLE, ROLES_CONFIG.SUPER_ADMIN] },
									{ label: 'Permissions', icon: 'library_books', to: '/access-mgt/permissions', roles: [ROLES_CONFIG.SUPER_ADMIN] },
								],
							},
							// {label: 'Import and Export', icon: 'import_export', roles: [ROLES_CONFIG.SYSTEM_IAE],
							//     items: [
							//         {label: 'Import Data', icon: 'cloud_upload',
							//             items: [
							//                 {label: 'Contacts', icon: 'cloud_upload', to: '/import-export/import/contacts', roles: [ROLES_CONFIG.SYSTEM_IAE_IMPORT_CONTACT]},
							//                 {label: 'Courses', icon: 'cloud_upload', to: '/import-export/import/course', roles: [ROLES_CONFIG.SYSTEM_IAE_IMPORT_COURSE]}
							//             ]
							//         },
							//         // {label: 'Export Data', icon: 'cloud_download', roles: [ROLES_CONFIG.EXPORT_V]}
							//     ]
							// },
							// {label: 'Connected Application', icon: "pi-md-settings-applications", to: '/connected-app', roles: [ROLES_CONFIG.SYSTEM_APPAPI]},
							// {label: 'Web Errors', icon: 'pi pi-info', to: '/web-errors', roles: [ROLES_CONFIG.SUPER_ADMIN]},
						],
					},
				];

			case 'FAST':
				return [
					{ label: 'Dashboard Operating', icon: 'home', to: '/', roles: [] },
					// {label: 'Dashboard and Reports', icon: 'library_books', roles: [ROLES_CONFIG.DAR_DASHBOARD_RERPORT_R],
					//     items: [
					//         {label: 'Dashboard Operating', icon: 'home', to: '/', roles: [ROLES_CONFIG.DAR_DASHBOARD_OPERATING_R]},
					//         {label: 'Reports', icon: 'home', roles: [ROLES_CONFIG.DAR_RERPORT_R],
					//             items: [
					//                 {label: 'Orders', icon: '', to: '/orders', roles: [ROLES_CONFIG.DAR_RERPORT_ORDER_R]},
					//                 {label: 'Invoices-Receipts', icon: '', to: '/invoices', roles: []},
					//                 {label: 'Participant', icon: '', to: '/participants'},
					//                 {label: 'Asset', icon: '', to: '/asset-report'}
					//                 // {label: 'Donations', icon: '', to: '/donations'},
					//                 // {label: 'Transactions', icon: '', to: '/transactions', roles: [ROLES_CONFIG.DAR_RERPORT_TRANSACTION]},
					//                 // {label: 'Predefine Reports', icon: '', to: '/reports', roles: [ROLES_CONFIG.DAR_RERPORT_RUN]},
					//                 // {label: 'Runtime Report', icon: '', to: '/crm-reports', roles: [ROLES_CONFIG.DAR_RERPORT_RUN]},
					//             ]
					//         },
					//     ]
					// },
					{
						label: 'Frontdesk Monitoring',
						icon: 'library_books',
						roles: [ROLES_CONFIG.FRONTDESK_MONITORING_R],
						items: [
							{ label: 'Payment Receipts', icon: '', to: '/monitoring/invoices', roles: [] },
							{ label: 'Membership List', icon: '', to: '/monitoring/membership-issue', roles: [ROLES_CONFIG.FRONTDESK_MEMBERSHIP_R] },
							// {label: 'Facility Booking', icon: '', to: '/monitoring/booking', roles: [ROLES_CONFIG.FRONTDESK_BOOKING_R]}
						],
					},
					{
						label: 'Membership',
						icon: 'library_books',
						roles: [
							ROLES_CONFIG.MEMBERSHIP_MGT_R,
							ROLES_CONFIG.MEMBERSHIP_STUDENT_MGT_R,
							ROLES_CONFIG.MEMBERSHIP_PRODUCT_MGT_R,
							ROLES_CONFIG.MEMBERSHIP_STUDENT_PRODUCT_MGT_R,
							ROLES_CONFIG.MEMBERSHIP_CATEGORY_MGT_R,
							ROLES_CONFIG.MEMBERSHIP_SEARCH_R,
							ROLES_CONFIG.MEMBERSHIP_EXT_SEARCH_R,
							ROLES_CONFIG.MEMBERSHIP_LOG_R,
						],
						items: [
							{
								label: 'Membership Mgt.',
								to: '/memberships',
								roles: [ROLES_CONFIG.MEMBERSHIP_MGT_R],
								// items: [
								//     // {label: 'Members', icon: '', to: '/member', roles: [ROLES_CONFIG.CONSTITUENT_MEMBERSHIP]},
								//     {label: 'Reports', to: "/comming-soon", roles: []},
								//     // {label: 'Import Members', to: "/import-member", roles: [ROLES_CONFIG.CONSTITUENT_MEMBERSHIP]},
								//     // {label: 'Cards Mgt.', to: '/card', roles: [ROLES_CONFIG.CONSTITUENT_MEMBERSHIP]}
								// ]
							},
							{ label: 'Membership Search', to: '/memberships/search', roles: [ROLES_CONFIG.MEMBERSHIP_SEARCH_R] },
							{ label: 'Ext Membership Search', to: '/memberships/ext-search', roles: [ROLES_CONFIG.MEMBERSHIP_EXT_SEARCH_R] },
							{ label: 'Student Mgt.', to: '/student-memberships', roles: [ROLES_CONFIG.MEMBERSHIP_STUDENT_MGT_R] },
							{
								label: 'Category Mgt.',
								icon: 'library_books',
								roles: [ROLES_CONFIG.MEMBERSHIP_PRODUCT_MGT_R, ROLES_CONFIG.MEMBERSHIP_STUDENT_PRODUCT_MGT_R, ROLES_CONFIG.MEMBERSHIP_CATEGORY_MGT_R],
								items: [
									{ label: 'Membership Products', icon: '', to: '/membership-products', roles: [ROLES_CONFIG.MEMBERSHIP_PRODUCT_MGT_R] },
									{ label: 'Student Products', icon: '', to: '/student-products', roles: [ROLES_CONFIG.MEMBERSHIP_STUDENT_PRODUCT_MGT_R] },
									{ label: 'Membership Categories', to: '/membership-categories', roles: [ROLES_CONFIG.MEMBERSHIP_CATEGORY_MGT_R] },
								],
							},
							{ label: 'Import', to: '/import/memberships', roles: [ROLES_CONFIG.MEMBERSHIP_IMPORT_R] },
							{ label: 'Logs', to: '/memberships/logs', roles: [ROLES_CONFIG.MEMBERSHIP_LOG_R] },
						],
					},
					{
						label: 'Contacts',
						icon: 'library_books',
						roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_R, ROLES_CONFIG.CONSTITUENT_COMPANY_R, ROLES_CONFIG.CONSTITUENT_CONTACT_LOG_R],
						items: [
							{ label: 'Account', icon: 'contacts', to: '/constituent-mgt/contacts', roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_R] },
							// {label: 'Households', icon: 'library_books', to: '/constituent-mgt/household', roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_HOUSEHOLD]},
							{ label: 'Companies', to: '/constituent-mgt/company', roles: [ROLES_CONFIG.CONSTITUENT_COMPANY_R] },
							// {label: 'Teams/Groups', icon: 'library_books', to: '/constituent-mgt/teams'},
							// {label: 'Departments', icon: 'library_books', to: '/constituent-mgt/departments'},
							{ label: 'Logs', to: '/constituent-mgt/contacts/logs', roles: [ROLES_CONFIG.CONSTITUENT_CONTACT_LOG_R] },
						],
					},
					{
						label: 'Activites',
						icon: 'library_books',
						roles: [
							// ROLES_CONFIG.PRODUCT_MGT_R,
							ROLES_CONFIG.ACTIVITY_SOCIAL_SUPPORT_MGT_R,
							ROLES_CONFIG.ACTIVITY_CLUBHOUSE_MGT_R,
							ROLES_CONFIG.PRODUCT_OPTION_MGT_R,
							ROLES_CONFIG.DEPARTMENT_MGT_R,
							ROLES_CONFIG.PRODUCT_CATEGORY_MGT_R,
							ROLES_CONFIG.SPEAKER_MGT_R,
							ROLES_CONFIG.WISH_LIST_MGT_R,
						],
						items: [
							{
								label: 'Activites',
								icon: 'library_books',
								roles: [
									ROLES_CONFIG.ACTIVITY_SOCIAL_SUPPORT_MGT_R,
									ROLES_CONFIG.ACTIVITY_CLUBHOUSE_MGT_R,
									ROLES_CONFIG.PRODUCT_OPTION_MGT_R,
									ROLES_CONFIG.DEPARTMENT_MGT_R,
									ROLES_CONFIG.PRODUCT_CATEGORY_MGT_R,
								],
								items: [
									// {label: 'Activity Mgt.', icon: '', roles: [ROLES_CONFIG.PRODUCT_MGT_R], to: '/products'},
									{ label: 'Social Support Activity Mgt.', icon: '', roles: [ROLES_CONFIG.ACTIVITY_SOCIAL_SUPPORT_MGT_R], to: '/activities/social-support' },
									{ label: 'Clubhouse Activity Mgt.', icon: '', roles: [ROLES_CONFIG.ACTIVITY_CLUBHOUSE_MGT_R], to: '/activities/clubhouse' },
									{ label: 'Activity Options Mgt.', icon: '', roles: [ROLES_CONFIG.PRODUCT_OPTION_MGT_R], to: '/product-options' },
									{ label: 'Activity Types', to: '/departments', roles: [ROLES_CONFIG.DEPARTMENT_MGT_R] },
									{ label: 'Activity Categories', to: '/product-categories', roles: [ROLES_CONFIG.PRODUCT_CATEGORY_MGT_R] },
								],
							},
							{ label: 'Trainer Mgt.', to: '/hr-mgt/speaker', roles: [ROLES_CONFIG.SPEAKER_MGT_R] },
							{ label: 'Wait List', to: '/wait-list', roles: [ROLES_CONFIG.WISH_LIST_MGT_R] },
							{ label: 'Service Queues', to: '/comming-soon', roles: [] },
						],
					},
					{
						label: 'Facility',
						icon: 'library_books',
						roles: [ROLES_CONFIG.ASSET_MGT_R, ROLES_CONFIG.ASSET_CATEGORY_MGT_R, ROLES_CONFIG.INVENTORY_MGT_R, ROLES_CONFIG.ASSET_DOOR_R],
						items: [
							{ label: 'Facilities Mgt.', to: '/assets-mgt', roles: [ROLES_CONFIG.ASSET_MGT_R] },
							{ label: 'Facility Categories', to: '/asset-categories', roles: [ROLES_CONFIG.ASSET_CATEGORY_MGT_R] },
							{ label: 'Inventory Mgt', to: '/inventories', roles: [ROLES_CONFIG.INVENTORY_MGT_R] },
							{ label: 'Door Mgt', to: '/doors', roles: [ROLES_CONFIG.ASSET_DOOR_R] },
						],
					},
					{
						label: 'Properties',
						icon: 'library_books',
						roles: [
							ROLES_CONFIG.PROPERTY_ATTRIBUTE_FIELD_MGT_R,
							ROLES_CONFIG.PROPERTY_ATTRIBUTE_GROUP_MGT_R,
							ROLES_CONFIG.PROPERTY_ATTIRBUTE_LIST_VALUE_MGT_R,
							ROLES_CONFIG.PROPERTY_APPLICATION_MGT_R,
							ROLES_CONFIG.PROPERTY_APPLICATION_RECORD_MGT_R,
							ROLES_CONFIG.TAXONOMY_BRANCH_MGT_R,
							ROLES_CONFIG.TAXONOMY_LOCATION_MGT_R,
						],
						items: [
							{
								label: 'Fields Attribute',
								icon: 'library_books',
								roles: [ROLES_CONFIG.PROPERTY_ATTRIBUTE_FIELD_MGT_R, ROLES_CONFIG.PROPERTY_ATTRIBUTE_GROUP_MGT_R, ROLES_CONFIG.PROPERTY_ATTIRBUTE_LIST_VALUE_MGT_R],
								items: [
									{ label: 'Field Mgt.', icon: '', to: '/field/attribute-fields', roles: [ROLES_CONFIG.PROPERTY_ATTRIBUTE_FIELD_MGT_R] },
									{ label: 'Field Grouping', icon: '', to: '/field/attribute-sets', roles: [ROLES_CONFIG.PROPERTY_ATTRIBUTE_GROUP_MGT_R] },
									{ label: 'List Value Mgt.', icon: '', to: '/field/attribute-list', roles: [ROLES_CONFIG.PROPERTY_ATTIRBUTE_LIST_VALUE_MGT_R] },
								],
							},
							{
								label: 'Applications',
								icon: 'library_books',
								roles: [ROLES_CONFIG.PROPERTY_APPLICATION_MGT_R, ROLES_CONFIG.PROPERTY_APPLICATION_RECORD_MGT_R],
								items: [
									{ label: 'Application Mgt', to: '/applications', roles: [ROLES_CONFIG.PROPERTY_APPLICATION_MGT_R] },
									{ label: 'Application Records', to: '/applications/records', roles: [ROLES_CONFIG.PROPERTY_APPLICATION_RECORD_MGT_R] },
								],
							},
							{ label: 'Branch Mgt.', to: '/branches', roles: [ROLES_CONFIG.TAXONOMY_BRANCH_MGT_R] },
							{ label: 'Location Mgt.', to: '/locations', roles: [ROLES_CONFIG.TAXONOMY_LOCATION_MGT_R] },
						],
					},
					{
						label: 'Training',
						icon: 'library_books',
						roles: [
							ROLES_CONFIG.TRAINING_COURSE_MGT_R,
							ROLES_CONFIG.TRAINER_MGT_R,
							ROLES_CONFIG.VENDOR_MGT_R,
							ROLES_CONFIG.TRAINING_DIPLOMA_MGT_R,
							ROLES_CONFIG.TRAINING_PARTICIPANT_MGT_R,
							ROLES_CONFIG.TRAINING_ATTENDANCE_MGT_R,
							ROLES_CONFIG.TRAINING_REPORT_R,
							ROLES_CONFIG.TRAINING_COURSE_LOG_R,
							ROLES_CONFIG.TRAINING_SESSION_LOG_R,
							ROLES_CONFIG.TRAINING_ATTENDANCE_LOG_R,
							ROLES_CONFIG.TRAINING_PARTICIPANT_LOG_R,
							ROLES_CONFIG.TRAINING_IMPORT_PARTICIPANT_R,
						],
						items: [
							{
								label: 'Training Course Mgt.',
								icon: 'library_books',
								roles: [ROLES_CONFIG.TRAINING_COURSE_MGT_R, ROLES_CONFIG.TRAINER_MGT_R, ROLES_CONFIG.VENDOR_MGT_R, ROLES_CONFIG.TRAINING_DIPLOMA_MGT_R],
								items: [
									{ label: 'Course Mgt.', to: '/course-products', roles: [ROLES_CONFIG.TRAINING_COURSE_MGT_R] },
									{ label: 'Programme Mgt.', to: '/programme-products', roles: [ROLES_CONFIG.TRAINING_DIPLOMA_MGT_R] },
									{ label: 'Trainer Mgt.', to: '/hr-mgt/trainer', roles: [ROLES_CONFIG.TRAINER_MGT_R] },
									{ label: 'Vendor Mgt.', to: '/hr-mgt/vendor', roles: [ROLES_CONFIG.VENDOR_MGT_R] },
									// {label: 'Diploma', to: "/diplomas", roles: [ROLES_CONFIG.TRAINING_DIPLOMA_MGT_R]},
								],
							},
							{ label: 'Enrollment', to: '/training/enrollment', roles: [ROLES_CONFIG.TRAINING_PARTICIPANT_MGT_R] },
							{ label: 'Attendances', icon: '', to: '/attendances', roles: [ROLES_CONFIG.TRAINING_ATTENDANCE_MGT_R] },
							{ label: 'Training Report', to: '/training/report', roles: [ROLES_CONFIG.TRAINING_REPORT_R] },
							{
								label: 'Import',
								icon: 'library_books',
								roles: [ROLES_CONFIG.TRAINING_IMPORT_PARTICIPANT_R, ROLES_CONFIG.TRAINING_IMPORT_SESSION_R],
								items: [
									{ label: 'Import Enrollment', to: '/training/enrollment/import', roles: [ROLES_CONFIG.TRAINING_IMPORT_PARTICIPANT_R] },
									{ label: 'Import Session', to: '/training/session/import', roles: [ROLES_CONFIG.TRAINING_IMPORT_SESSION_R] },
								],
							},
							{
								label: 'Logs',
								icon: 'library_books',
								roles: [ROLES_CONFIG.TRAINING_COURSE_LOG_R, ROLES_CONFIG.TRAINING_SESSION_LOG_R, ROLES_CONFIG.TRAINING_ATTENDANCE_LOG_R, ROLES_CONFIG.TRAINING_PARTICIPANT_LOG_R],
								items: [
									{ label: 'Training Logs', to: '/training/logs', roles: [ROLES_CONFIG.TRAINING_COURSE_LOG_R] },
									{ label: 'Session Logs', to: '/training/session/logs', roles: [ROLES_CONFIG.TRAINING_SESSION_LOG_R] },
									{ label: 'Enrollment Logs', to: '/training/enrollment/logs', roles: [ROLES_CONFIG.TRAINING_PARTICIPANT_LOG_R] },
									{ label: 'Attendance Logs', to: '/training/attendance/logs', roles: [ROLES_CONFIG.TRAINING_ATTENDANCE_LOG_R] },
								],
							},

							// {label: 'Interests', icon: '', roles: [],
							//     items: [
							//         {label: 'Interest Mgt.', icon: 'library-books', to: '/constituent-management/interests'},
							//     ]
							// },
							// {label: 'Skills', icon: '', roles: [],
							//     items: [
							//         {label: 'Talent Mgt.', icon: 'library-books', to: '/constituent-management/talents'},
							//     ]
							// },
						],
					},
					{
						label: 'Financials',
						icon: 'library_books',
						roles: [ROLES_CONFIG.FINANCIAL_DISCOUNT_R],
						items: [
							{ label: 'Discount', icon: 'library_books', to: '/promotions', roles: [ROLES_CONFIG.FINANCIAL_DISCOUNT_R] },
							//     { label: 'Dashboard', to: "/comming-soon", roles: []},
							//     { label: 'Contributions', to: "/comming-soon", roles: []},
							//     {label: 'Donations', icon: '', to: '/donations'},
							//     {label: 'Transactions', icon: '', to: '/transactions', roles: [ROLES_CONFIG.DAR_RERPORT_TRANSACTION]},
							//     { label: 'Financials Reports', to: "/comming-soon", roles: []},
							//     { label: 'Membership payments', to: "/comming-soon", roles: []},
						],
					},
					{
						label: 'Care Support',
						icon: 'library_books',
						roles: [ROLES_CONFIG.CARE_SUPPORT_R, ROLES_CONFIG.CARE_SUPPORT_IMPORT_R],
						items: [
							{ label: 'Care Support Mgt.', to: '/care-support', roles: [ROLES_CONFIG.CARE_SUPPORT_R] },
							{ label: 'Import', to: 'import/care-support', roles: [ROLES_CONFIG.CARE_SUPPORT_IMPORT_R] },
						],
					},
					{
						label: 'Volunteers',
						icon: 'library_books',
						roles: [ROLES_CONFIG.VOLUNTEER_MGT_R, ROLES_CONFIG.VOLUNTEER_IMPORT_R],
						items: [
							{ label: 'Volunteer Mgt.', to: '/volunteer-records', roles: [ROLES_CONFIG.VOLUNTEER_MGT_R] },
							{ label: 'Import', to: 'import-export/import/volunteer', roles: [ROLES_CONFIG.VOLUNTEER_IMPORT_R] },
						],
					},
					{
						label: 'Administrator',
						icon: 'library_books',
						roles: [ROLES_CONFIG.ADMIN, ROLES_CONFIG.SUPER_ADMIN, ROLES_CONFIG.SYSTEM_CONFIGURATION_R, ROLES_CONFIG.TEMPLATE_EMAIL_R],
						items: [
							{ label: 'Configurations', to: '/configurations', roles: [ROLES_CONFIG.ADMIN, ROLES_CONFIG.SUPER_ADMIN, ROLES_CONFIG.SYSTEM_CONFIGURATION_R] },
							{
								label: 'Access Control',
								icon: 'library_books',
								roles: [ROLES_CONFIG.ADMIN, ROLES_CONFIG.SUPER_ADMIN],
								items: [
									{ label: 'Users', icon: 'contacts', to: '/access-management/users', roles: [ROLES_CONFIG.ADMIN, ROLES_CONFIG.SUPER_ADMIN] },
									{ label: 'Roles', to: '/access-management/roles', roles: [ROLES_CONFIG.SUPER_ADMIN] },
									{ label: 'Permissions', to: '/access-management/permissions', roles: [ROLES_CONFIG.SUPER_ADMIN] },
									{ label: 'Door Controller', to: '/door-control', roles: [ROLES_CONFIG.SYSTEM_CONFIGURATION_R, ROLES_CONFIG.SUPER_ADMIN] },
								],
							},
							{ label: 'Merge Duplicate', to: '/constituent-management/merge-duplicate', roles: [] },
							{ label: 'SMTP Setup', icon: 'mail', to: '/mail-mgt/accounts', roles: [ROLES_CONFIG.ADMIN, ROLES_CONFIG.SUPER_ADMIN] },
							{ label: 'e-Mail Templates', to: '/mail-mgt/templates', roles: [ROLES_CONFIG.TEMPLATE_EMAIL_R] },
						],
					},
					{
						label: 'Reports',
						icon: 'library_books',
						roles: [
							ROLES_CONFIG.REPORT_ORDER_R,
							ROLES_CONFIG.REPORT_INVOICE_R,
							ROLES_CONFIG.REPORT_PARTICIPANT_R,
							ROLES_CONFIG.ASSET_REPORT_R,
							ROLES_CONFIG.REPORT_PREDEFINE_R,
							ROLES_CONFIG.REPORT_RUN_R,
							ROLES_CONFIG.REPORT_DONATION_R,
							ROLES_CONFIG.REPORT_IMPORT_PARTICIPANT_R,
						],
						items: [
							{ label: 'Orders', icon: '', to: '/orders', roles: [ROLES_CONFIG.REPORT_ORDER_R] },
							{ label: 'Invoices-Receipts', icon: '', to: '/invoices', roles: [ROLES_CONFIG.REPORT_INVOICE_R] },
							{ label: 'Donations', icon: '', to: '/donations', roles: [ROLES_CONFIG.REPORT_DONATION_R] },
							{
								label: 'Participations',
								icon: 'library_books',
								roles: [ROLES_CONFIG.REPORT_PARTICIPANT_R, ROLES_CONFIG.REPORT_IMPORT_PARTICIPANT_R],
								items: [
									{ label: 'Participant Mgt.', to: '/participations', roles: [ROLES_CONFIG.REPORT_PARTICIPANT_R] },
									{ label: 'Import.', to: '/participations/import', roles: [ROLES_CONFIG.REPORT_IMPORT_PARTICIPANT_R] },
								],
							},
							{ label: 'Facility', to: '/facility-report', roles: [ROLES_CONFIG.ASSET_REPORT_R] },
							// {label: 'Asset', icon: '', to: '/asset-report'},
							// {label: 'Donations', icon: '', to: '/donations'},
							// {label: 'Transactions', icon: '', to: '/transactions', roles: [ROLES_CONFIG.DAR_RERPORT_TRANSACTION]},
							{ label: 'Predefine Reports', icon: '', to: '/reports', roles: [ROLES_CONFIG.REPORT_PREDEFINE_R] },
							{ label: 'Runtime Report', icon: '', to: '/crm-reports', roles: [ROLES_CONFIG.REPORT_RUN_R] },
						],
					},
				];
		}
	}

	changeTheme(theme) {
		this.changeStyleSheetUrl('theme-css', theme, 'theme');
	}

	changeLayout(theme) {
		this.changeStyleSheetUrl('layout-css', theme, 'layout');
	}

	changeStyleSheetUrl(id, value, prefix) {
		let element = document.getElementById(id);
		let urlTokens = element.getAttribute('href').split('/');
		urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
		let newURL = urlTokens.join('/');
		element.setAttribute('href', newURL);
	}

	isMobile() {
		return window.innerWidth <= 1024;
	}

	isTablet() {
		let width = window.innerWidth;
		return width <= 1024 && width > 640;
	}

	isDesktop() {
		return window.innerWidth > 1024;
	}

	isOverlay() {
		return this.state.layoutMode === 'overlay';
	}

	isHorizontal() {
		return this.state.layoutMode === 'horizontal';
	}

	componentWillUnmount() {
		this.unlisten();
		// notificationEvent.remove('USER_NOTIFICATION', this.onUserNotificationReceived);
	}

	showNotification = (type, message) => {
		this.notification.show({ severity: type, summary: type === 'success' ? 'Success Message' : 'Error Message', detail: message });
	};

	initApp(app) {
		switch (app) {
			case 'FEP':
			default:
				this.setState({
					selectedApp: app,
					appLogo: 'assets/fromlabs/FAST_logo.png',
					appMenu: this.createMenu(app),
				});
				// this._history.push("/");
				break;
			case 'SNS':
				this.setState({
					selectedApp: app,
					appLogo: 'assets/fromlabs/sns_small_logo.png',
					appMenu: this.createMenu(app),
				});
				this._history.push('/sns');
				break;
		}
	}

	handleChangeApp = (e) => {
		if (e.value !== this.state.selectedApp) {
			this.initApp(e.value);
		}
	};

	render() {
		let wrapperClass = classNames('layout-wrapper', {
			'layout-wrapper-static': this.state.layoutMode === 'static',
			'layout-wrapper-active': this.state.mobileMenuActive,
			'layout-menu-horizontal': this.state.layoutMode === 'horizontal',
		});
		let sidebarClassName = classNames('layout-sidebar', { 'layout-sidebar-dark': this.state.darkMenu });
		const appList = [
			{ label: 'FEP', value: 'FEP' },
			// {label: 'FORM', value: 'FORM'},
		];
		const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);

		return (
			<div className={wrapperClass} onClick={this.onWrapperClick}>
				<div ref={(el) => (this.sidebar = el)} className={sidebarClassName} onClick={this.onSidebarClick} onMouseEnter={this.onSidebarMouseEnter} onMouseLeave={this.onSidebarMouseLeave}>
					<div className='sidebar-logo'>
						<a>
							<img className='app-logo' alt='logo' src={this.state.appLogo} />
							<span className='app-name'> {appList.length > 1 ? <Dropdown style={{ width: '42px' }} options={appList} onChange={this.handleChangeApp}></Dropdown> : ''}</span>
						</a>
						<a className='sidebar-anchor' title='Toggle Menu' onClick={this.onToggleMenuClick}>
							{' '}
						</a>
					</div>

					<ScrollPanel ref={(el) => (this.layoutMenuScroller = el)} style={{ height: '100%' }}>
						<div className='layout-menu-container'>
							<AppMenu
								model={this.state.appMenu}
								onRootMenuItemClick={this.onRootMenuItemClick}
								layoutMode={this.state.layoutMode}
								active={this.state.menuActive}
								onMenuItemClick={this.onMenuItemClick}
							/>
						</div>
					</ScrollPanel>
				</div>
				<div className='layout-main'>
					<AppTopbar
						profileName={localStorage.getItem('fullname')}
						layoutMode={this.state.layoutMode}
						activeTopbarItem={this.state.activeTopbarItem}
						onTopbarItemClick={(e) => this.onTopbarItemClick(e)}
						onMenuButtonClick={this.onMenuButtonClick}
						onTopbarMobileMenuButtonClick={this.onTopbarMobileMenuButtonClick}
						topbarMenuActive={this.state.topbarMenuActive}
						onLogout={this.handleLogout}
						wsNotifications={this.state.wsNotifications}
						userNotifications={this.state.userNotifications}
						reloadUserNotification={this.reloadUserNotification}
					/>

					<AppBreadCrumbWithRouter onLogout={this.handleLogout} />
					<WorkflowProgress />
					<div className='layout-content'>
						<LoadingOverlay />
						<Switch>
							{/* <PermissionRoute path="/" exact component={OperatingView} roles={[ROLES_CONFIG.DAR_DASHBOARD_OPERATING_R]}/> */}
							<AuthRoute path='/' exact component={Dashboard3} />
							<AuthRoute path='/500' component={ErrorPage} />
							<AuthRoute path='/404' component={NotFound} />
							<AuthRoute path='/401' component={AccessDenied} />
							<Route path='/comming-soon' component={CommingSoon} />

							{/* <Route path="/access-mgt" component={AccessManagement} /> */}
							<Route path='/constituent-mgt' component={ConstituentManagement} />
							<Route path='/access-management' component={AccessManagement} />
							<Route path='/hr-mgt' component={HRManagement} />
							<Route path='/cms' component={CmsManagement} />
							<Route path='/mail-mgt' component={MailLingManagement} />

							<Route path='/import-export' component={ImportExportManagement} roles={[ROLES_CONFIG.SYSTEM_IAE_IMPORT, ROLES_CONFIG.EXPORT_V]} />

							{/* <Route path="/monitoring/operations" component={OperatingView} roles={[ROLES_CONFIG.FRONTDESK]}/> */}
							{/* <Route path="/monitoring/booking/:orderNumber" component={BookingProcess} roles={[ROLES_CONFIG.FRONTDESK]}/> */}
							{/* <PermissionRoute path="/monitoring/booking" component={BookingOrders} roles={[ROLES_CONFIG.FRONTDESK_BOOKING_R]}/> */}
							<Route path='/monitoring/invoices' component={InvoiceListView} roles={[ROLES_CONFIG.FRONTDESK]} />
							{/* <Route path="/monitoring/membership-issue" component={MembershipIssueListView} roles={[ROLES_CONFIG.FRONTDESK]}/> */}
							<PermissionRoute path='/monitoring/membership-issue' component={MembershipListView} roles={[ROLES_CONFIG.FRONTDESK_MEMBERSHIP_R]} />
							<Route path='/monitoring/merge-duplicate/:id' component={MergeDuplicateProcess} />

							<Route path='/campaigns' component={CampaignListView} />

							<Route path={`/departments/:dId/role/:id`} name='Role Details View' render={(props) => <RoleDetails {...props} type='department' />} />
							<PermissionRoute path={`/departments/:id`} name='Department Details View' component={DepartmentDetailsView} roles={[ROLES_CONFIG.DEPARTMENT_MGT_R]} />
							<PermissionRoute path={`/departments`} name='Department View' component={DepartmentListView} roles={[ROLES_CONFIG.DEPARTMENT_MGT_R]} />

							<Route path='/membership_templates/:id/levels/:lid' name='Membership Level Details' component={MembershipTemplateLevelDetails} />
							<Route path='/membership_templates/:id' name='Membership Group Details' component={MembershipTemplateDetails} />
							<Route path='/membership_templates' name='Membership Group Management' component={MembershipTemplates} />
							<Route path='/membership/:id' name='Membership Category Detail' component={MembershipCategoryDetail} />
							<Route path='/membership' name='Membership Categories' component={MembershipCategoryListView} />

							{/* <Route path="/programs/:pid/courses/:cid/manage/assessment/:aid" component={ProgramAssessmentDetails}/>
                            <Route path="/programs/:pid/courses/:cid/manage/assessment" component={ProgramAssessment}/>
                            <Route path="/programs/:pid/courses/:cid/manage" name="Course Manage" component={ProgramCourseManageDetails}/>
                            <Route path="/programs/:pid/courses/:cid/setup" name="Course Details" component={ProgramCourseSetupDetails}/>
                            <Route path="/programs/:id" name="Program Details" component={ProgramDetails}/>
                            <Route path="/programs" name="Program Management" component={Programs} roles={[ROLES_CONFIG.PRODUCT_PROGRAM_DETAIL_R]}/>
                            <Route path="/programs-copy" name="Copy Program" component={ProgramCopy} roles={[ROLES_CONFIG.PRODUCT_PROGRAM_DETAIL_R]}/> */}
							{/* <Route path="/fee_assistance" component={Budgets}/> */}

							<PermissionRoute
								path='/membership-products'
								component={MembershipProductListView}
								roles={[ROLES_CONFIG.MEMBERSHIP_PRODUCT_MGT_R]}
								participantType={PRODUCT_PARTICIPANT_TYPE.student.value}
							/>
							<PermissionRoute path='/student-products' component={StudentMembershipProductListView} roles={[ROLES_CONFIG.MEMBERSHIP_STUDENT_PRODUCT_MGT_R]} />
							<PermissionRoute path='/course-products' component={ProductListView} extra1={true} extra2={TRAINING_COURSE_ACTIVITY_CODE} roles={[ROLES_CONFIG.TRAINING_COURSE_MGT_R]} />
							<PermissionRoute path='/programme-products' component={ProductListView} extra1={true} extra2={TRAINING_COURSE_DIPLOMA_CODE} roles={[ROLES_CONFIG.TRAINING_COURSE_MGT_R]} />
							{/* <PermissionRoute path="/products" component={ProductManagement} roles={[ROLES_CONFIG.PRODUCT_MGT_R]}/> */}
							<PermissionRoute path='/activities/social-support' component={ProductListView} extra2={SOCIAL_SUPPORT_ACTIVITY_CODE} roles={[ROLES_CONFIG.ACTIVITY_SOCIAL_SUPPORT_MGT_R]} />
							<PermissionRoute path='/activities/clubhouse' component={ProductListView} extra2={CLUBHOUSE_ACTIVITY_CODE} roles={[ROLES_CONFIG.ACTIVITY_CLUBHOUSE_MGT_R]} />
							<PermissionRoute path='/product-options' component={ProductOptionManagement} roles={[ROLES_CONFIG.PRODUCT_OPTION_MGT_R]} />

							<Route path='/tracking' component={UsageFindTracking} />

							<PermissionRoute
								path='/membership-categories'
								component={TaxonListView}
								type={TAXONOMY_TYPE.category.value}
								secondaryType={TAXONOMY_TYPE.membership.value}
								roles={[ROLES_CONFIG.MEMBERSHIP_CATEGORY_MGT_R]}
							/>
							<PermissionRoute
								path='/product-categories'
								component={TaxonListView}
								type={TAXONOMY_TYPE.category.value}
								secondaryType={TAXONOMY_TYPE.product.value}
								roles={[ROLES_CONFIG.PRODUCT_CATEGORY_MGT_R]}
							/>
							<PermissionRoute
								path='/asset-categories'
								component={TaxonListView}
								type={TAXONOMY_TYPE.category.value}
								secondaryType={TAXONOMY_TYPE.asset.value}
								roles={[ROLES_CONFIG.ASSET_CATEGORY_MGT_R]}
							/>
							<PermissionRoute path='/locations' component={TaxonListView} type={TAXONOMY_TYPE.location.value} roles={[ROLES_CONFIG.TAXONOMY_LOCATION_MGT_R]} />
							<PermissionRoute path='/branches' component={TaxonListView} type={TAXONOMY_TYPE.branch.value} roles={[ROLES_CONFIG.TAXONOMY_BRANCH_MGT_R]} />

							<Route path='/assets-mgt/:id' component={AssetDetails} />
							<PermissionRoute path='/assets-mgt' component={AssetListView} roles={[ROLES_CONFIG.ASSET_MGT_R]} />

							<Route path='/promotions/:id' name='Promotion Details' component={PromotionDetails} />
							<PermissionRoute path='/promotions' name='Promotion Management' component={PromotionListView} roles={[ROLES_CONFIG.FINANCIAL_DISCOUNT_R]} />
							<Route path='/exchange-coupons' render={(props) => <PromotionCouponListView {...props} type={PROMOTION_COUPON_TYPE.coupon_exchange.value} />} />

							<PermissionRoute path='/participations/import' component={ImportListView} type={OBJECT_TYPE.participant} roles={[ROLES_CONFIG.REPORT_IMPORT_PARTICIPANT_R]} />
							<Route path='/participations/:id' component={ParticipantDetails} />
							<PermissionRoute path='/participations' component={Participant} roles={[ROLES_CONFIG.REPORT_PARTICIPANT_R]} />
							<Route path='/field' component={AttributeMangement} />
							{/* <PermissionRoute path="/program-booking/orders" component={RegOrders} roles={[ROLES_CONFIG.FRONTDESK_BOOKING_PROGRAM_R]}/> */}
							{/* <PermissionRoute path="/program-booking/order/:id" component={Registration} roles={[ROLES_CONFIG.FRONTDESK_BOOKING_PROGRAM_R]}/> */}
							<Route path='/invoices/:id' component={InvoiceDetails} />
							<PermissionRoute path='/invoices' component={InvoiceListView} roles={[ROLES_CONFIG.REPORT_INVOICE_R]} />
							<PermissionRoute path='/donations/:id' component={DonationDetails} roles={[ROLES_CONFIG.REPORT_DONATION_R]} />
							<PermissionRoute path='/donations' component={DonationListView} roles={[ROLES_CONFIG.REPORT_DONATION_R]} />

							<Route path='/simple-event' component={SimpleEventManagement} />
							<Route path='/profile' component={UserProfile} />

							<Route path='/transactions/:id' component={TransactionDetails} />
							<PermissionRoute path='/transactions' component={Transactions} roles={[ROLES_CONFIG.DAR_RERPORT_TRANSACTION_R]} />
							<PermissionRoute path='/orders/:id' component={OrderDetails} roles={[ROLES_CONFIG.REPORT_ORDER_R]} />
							<PermissionRoute path='/orders?state=:orderState' component={Orders} roles={[ROLES_CONFIG.DAR_RERPORT_ORDER_R]} />
							<PermissionRoute path='/orders' component={Orders} roles={[ROLES_CONFIG.REPORT_ORDER_R]} />
							{/* <Route path="/gl-accounts" name = "GL Accounts" component={GLAccounts}/> */}
							<PermissionRoute path='/reports' name='Reports' component={Reports} roles={[ROLES_CONFIG.REPORT_PREDEFINE_R]} />
							<PermissionRoute path='/crm-reports' name='CRM Reports' component={CRMReports} roles={[ROLES_CONFIG.REPORT_RUN_R]} />

							<Route path='/web-errors/:id' name='Web Errors' component={WebErrorDetailView} />
							<Route path='/web-errors' name='Web Errors' component={WebErrorListView} />
							<Route path='/connected-app' name='Connected Application' component={ConnectedAppListView} />

							{/* <Route path="/events" component={EventManagement} /> */}
							{/* <Route path="/sessions" component={EventSessionListView} /> */}
							{/* <Route path="/live-accounts" component={LiveAccountListView} /> */}
							{/* <Route path="/event-times" component={EventTimeListView} /> */}
							{/* <Route path="/event-category" component={EventCategoryListView} /> */}
							{/* <Route path="/faq" component={FAQListView} /> */}

							<PermissionRoute path='/applications/records' component={ApplicationRecordListView} roles={[ROLES_CONFIG.PROPERTY_APPLICATION_RECORD_MGT_R]} />
							<PermissionRoute path='/applications' component={ApplicationListView} roles={[ROLES_CONFIG.PROPERTY_APPLICATION_MGT_R]} />

							<Route path='/inventories/:id' component={InventoryDetailView} />
							<PermissionRoute path='/inventories' component={InventoryListView} roles={[ROLES_CONFIG.INVENTORY_MGT_R]} />

							<PermissionRoute path='/configurations' component={Configuration} roles={[ROLES_CONFIG.ADMIN, ROLES_CONFIG.SUPER_ADMIN, ROLES_CONFIG.SYSTEM_CONFIGURATION_R]} />
							<Route path='/door-control' component={DoorControl} />
							<Route path='/asset-report' component={AssetReportListView} />

							<Route path='/member' component={MemberListView} />
							<Route path='/import-member' component={ImportMember} />
							<Route path='/card' component={CardMgt} />

							{/* <Route path="/identity-document" component={IdentityDocument}/> */}
							<PermissionRoute path='/memberships/search/:id' component={MembershipDetailViewOnly} roles={[ROLES_CONFIG.MEMBERSHIP_SEARCH_DETAI_R]} />
							<PermissionRoute path='/memberships/search' component={MembershipListViewOnly} roles={[ROLES_CONFIG.MEMBERSHIP_SEARCH_R]} />
							<PermissionRoute path='/memberships/ext-search/:id' component={MembershipDetailViewOnly} roles={[ROLES_CONFIG.MEMBERSHIP_EXT_SEARCH_DETAIL_R]} extra1='ext' />
							<PermissionRoute path='/memberships/ext-search' component={MembershipListView_ViewOnly_Ext} roles={[ROLES_CONFIG.MEMBERSHIP_EXT_SEARCH_R]} />
							<PermissionRoute path='/memberships/logs' component={AuditLogListView} type={OBJECT_TYPE.membership} roles={[ROLES_CONFIG.MEMBERSHIP_LOG_R]} />
							<PermissionRoute path='/memberships/:id' component={MembershipDetailView} roles={[ROLES_CONFIG.MEMBERSHIP_DETAI_R]} />
							<PermissionRoute path='/memberships' component={MembershipListView} roles={[ROLES_CONFIG.MEMBERSHIP_MGT_R]} />
							<PermissionRoute path='/student-memberships/:id' component={MembershipDetailView} roles={[ROLES_CONFIG.MEMBERSHIP_STUDENT_DETAI_R]} />
							<PermissionRoute path='/student-memberships' component={StudentMembershipListView} roles={[ROLES_CONFIG.MEMBERSHIP_STUDENT_MGT_R]} />
							<PermissionRoute path='/import/memberships' component={ImportMembership} roles={[ROLES_CONFIG.MEMBERSHIP_IMPORT_R]} />

							<PermissionRoute path='/volunteer-records' component={VolunteerApplicationRecordListView} roles={[ROLES_CONFIG.VOLUNTEER_MGT_R]} />
							<PermissionRoute path='/care-support' component={SupportCaseApplicationRecordListView} roles={[ROLES_CONFIG.CARE_SUPPORT_R]} />
							<PermissionRoute path='/import/care-support' component={ImportSupportCaseApplication} roles={[]} />

							<PermissionRoute path={'/diplomas'} component={DiplomaLisView} roles={[ROLES_CONFIG.TRAINING_DIPLOMA_MGT_R]} />
							<PermissionRoute
								path={'/training/attendance/logs'}
								component={AuditLogListView}
								type={OBJECT_TYPE.participant_attendance}
								extra1={true}
								roles={[ROLES_CONFIG.TRAINING_ATTENDANCE_LOG_R]}
							/>
							<PermissionRoute path={'/attendances'} component={AttendancesCheckin} roles={[ROLES_CONFIG.TRAINING_ATTENDANCE_MGT_R]} />
							<PermissionRoute path={'/training/enrollment/logs'} component={AuditLogListView} type={OBJECT_TYPE.participant} extra1={true} roles={[ROLES_CONFIG.TRAINING_PARTICIPANT_LOG_R]} />
							<PermissionRoute path={'/training/enrollment/import'} component={ImportListView} type={OBJECT_TYPE.participant} extra1={true} roles={[ROLES_CONFIG.TRAINING_IMPORT_PARTICIPANT_R]} />
							<PermissionRoute path={'/training/enrollment'} component={ParticipantAttendanceListView} roles={[ROLES_CONFIG.TRAINING_PARTICIPANT_MGT_R]} />
							<PermissionRoute path={'/training/report'} component={TraniningReportListView} roles={[ROLES_CONFIG.TRAINING_REPORT_R]} />
							<PermissionRoute path={'/training/logs'} component={AuditLogListView} type={OBJECT_TYPE.product} extra1={true} roles={[ROLES_CONFIG.TRAINING_COURSE_LOG_R]} />
							<PermissionRoute path={'/training/session/logs'} component={AuditLogListView} type={OBJECT_TYPE.product_session} extra1={true} roles={[ROLES_CONFIG.TRAINING_SESSION_LOG_R]} />
							<PermissionRoute path={'/training/session/import'} component={ImportListView} type={OBJECT_TYPE.product_session} extra1={true} roles={[ROLES_CONFIG.TRAINING_IMPORT_SESSION_R]} />

							<PermissionRoute path='/facility-report' component={FacilityReport} roles={[ROLES_CONFIG.ASSET_REPORT_R]} />
							<PermissionRoute path='/wait-list' component={WishListListView} roles={[ROLES_CONFIG.WISH_LIST_MGT_R]} />

							<PermissionRoute path='/doors' component={DoorListView} roles={[ROLES_CONFIG.ASSET_DOOR_R]} />

							<Redirect from='*' to='/404' />
						</Switch>
					</div>

					<AppFooter />

					{this.state.mobileMenuActive && <div className='layout-main-mask'></div>}
				</div>
			</div>
		);
	}
}

export default App;
