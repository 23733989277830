import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../../constants';
import { converObjetToFormData } from '../../core/service/CommonService';
const configFormDataHeader = {
    headers: { 'Content-Type': 'multipart/form-data' }
}
const SCP_BASE_URL = API_BASE_URL + SCP_PATH;

export function getPageSliders(page, size, sortField, sortOrder) {
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${SCP_BASE_URL}/sliders/page`, {
        params: {
        page: page,
        size: size,
        sort: sort
        }
    }).then(res => res.data).catch(error => console.log(error));
}

export function saveSlider(data) {
    return axios.post(`${SCP_BASE_URL}/sliders`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function removeSlider(id) {
    return axios.delete(`${SCP_BASE_URL}/sliders/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getSliders(simple){
    return axios.get(`${SCP_BASE_URL}/sliders/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}

export function saveSliderItem(id, data) {
    let formData = new FormData()
    for (var key in data) {
        converObjetToFormData(formData, key, data[key])
    }
    return axios.post(`${SCP_BASE_URL}/sliders/${id}/slides`, formData, configFormDataHeader)
    .then(res => res.data).catch(error => console.log(error));
}

export function removeSliderItem(id) {
    return axios.delete(`${SCP_BASE_URL}/sliders/slides/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}