import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import CKEditor from 'ckeditor4-react';
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { saveEventSession, saveEventSessionContent } from "./EventSessionService";
import { Dropdown } from "primereact/dropdown";
import { EVENT_CONFERENCE_TYPE, EVENT_SESSION_TYPE, GALLERY_TYPE, TIME_SERIES_REPEAT_TYPE } from "../../constants";
import { Fieldset } from "primereact/fieldset";
import { SelectButton } from "primereact/selectbutton";
import { ProductGallery } from "../../pim/product/ProductGallery";
import { MeetingForm } from "./MeetingForm";
import { Checkbox } from "primereact/checkbox";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import moment, { HTML5_FMT } from "moment";
import classNames from "classnames";
import { EventSessionVideo } from "./EventSessionVideo";
import { showloading, stoploading } from "../../core/service/LoadingService";
import { EventSessionJoiningCode } from "./EventSessionJoiningCode";
CKEditor.editorUrl = "/assets/ckeditor/ckeditor.js";

const MENU_ITEM = {
    basic: {label: 'Basic Information', value: 'basic'},
    content: {label: 'Content', value: 'content'},
    joining_code: {label: 'Joining Code', value: 'joining_code'},
    zoom_meeting: {label: 'Zoom Meeting', value: 'zoom_meeting'},
};

export const EventSessionForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("New Event Session")
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [menuActive, setMenuActive] = useState(MENU_ITEM.basic.value)
    const [errors, setErrors] = useState({})
    
    useImperativeHandle(ref, () => ({
        openForm(e){
            setHeader(e?'Edit Session':'New Session')
            setVisible(true)
            setFrmData(popularFormData(e))
            setMenuActive(MENU_ITEM.basic.value)
        },
        closeForm(){
            closeForm()
        }
    }))

    const popularFormData = (data) => {
        return {
            id: data ? data.id : '',
            type: data ? data.type : null,
            title: data&&data.title ? data.title : '',
            topic: data&&data.topic ? data.topic : '',
            description: data&&data.description ? data.description : '',
            enabled: data?data.enabled:false,
            externalPlatformUrl: data?data.externalPlatformUrl:'',
            requireJoiningCode: data?data.requireJoiningCode:false,
            videos: data&&data.videos ? data.videos : [],
            conferenceType: data && data.type===EVENT_SESSION_TYPE.live.value ? data.conferenceType : null,
            zoomMeeting: data&&data.zoomMeeting?data.zoomMeeting:{},
            dateRange: data && data.startDate!==data.endDate?true:false,
            startDate: data?data.startDate:null,
            endDate: data?data.endDate:null,
            startTime: data?data.startTime:null,
            endTime: data?data.endTime:null,
            startDateValue: data && data.startDate?moment(data.startDate, HTML5_FMT.DATE).toDate():null,
            endDateValue: data && data.endDate?moment(data.endDate, HTML5_FMT.DATE).toDate():null,
            startTimeValue: data && data.startTime?moment(data.startTime, HTML5_FMT.TIME).toDate():null,
            endTimeValue: data && data.endTime?moment(data.endTime, HTML5_FMT.TIME).toDate():null
        }
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setErrors({})
    }

    const onChangeType = (val) => {
        setFrmData({
            ...frmData,
            type: val,
            conferenceType: val===EVENT_SESSION_TYPE.on_demand.value?null:EVENT_CONFERENCE_TYPE.zoom.value
        })
    }

    const handleSaveInformation = () => {
        showloading()
        setErrors({})

        let tmpData = {...frmData}

        tmpData.startDate = tmpData.startDateValue && moment(tmpData.startDateValue).isValid() ? moment(tmpData.startDateValue).format(HTML5_FMT.DATE) : ''
        tmpData.endDate = tmpData.endDateValue && moment(tmpData.endDateValue).isValid() ? moment(tmpData.endDateValue).format(HTML5_FMT.DATE) : ''
        tmpData.startTime = tmpData.startTimeValue && moment(tmpData.startTimeValue).isValid() ? moment(tmpData.startTimeValue).format(HTML5_FMT.TIME) : ''
        tmpData.endTime = tmpData.endTimeValue && moment(tmpData.endTimeValue).isValid() ? moment(tmpData.endTimeValue).format(HTML5_FMT.TIME) : ''

        saveEventSession(tmpData)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable)
                    props.refreshTable()
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }

            stoploading()
        });
    }

    const handleSaveContent = () => {
        saveEventSessionContent(frmData)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable)
                    props.refreshTable()
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }
        });
    }

    const onDateChange = (e, key) => {
        switch(key){
            case 'start':
                setFrmData({...frmData, startDateValue: e})
                break;
            case 'end':
                setFrmData({...frmData, endDateValue: e})
                break;
            default: break;
        }        
    }

    const onDateBlur = (e, key) => {
        switch(key){
            case 'start':
                if(!frmData.startDateValue || !moment(frmData.startDateValue).isValid())
                    setFrmData({...frmData, startDateValue: null})
                break;
            case 'end':
                if(!frmData.endDateValue || !moment(frmData.endDateValue).isValid())
                    setFrmData({...frmData, endDateValue: null})
                break;
            default: break;
        }
    }

    const onTimeChange = (e, key) => {
        switch(key){
            case 'start':
                setFrmData({...frmData, startTimeValue: e})
                break;
            case 'end':
                setFrmData({...frmData, endTimeValue: e})
                break;
            default: break;
        }
    }

    const onTimeBlur = (key) => {
        switch(key){
            case 'start':
                if(!frmData.startTimeValue || !moment(frmData.startTimeValue).isValid())
                    setFrmData({...frmData, startTimeValue: null})
                break;
            case 'end':
                if(!frmData.endTimeValue || !moment(frmData.endTimeValue).isValid())
                    setFrmData({...frmData, endTimeValue: null})
                break;
            default: break;
        }
    }

    const renderSelectItems = () =>{
        let tmpArr=[
            MENU_ITEM.basic
        ]

        if(frmData.type===EVENT_SESSION_TYPE.live.value || frmData.type===EVENT_SESSION_TYPE.on_demand.value){
            tmpArr.push(MENU_ITEM.content)

            if(frmData.type===EVENT_SESSION_TYPE.live.value)
                tmpArr.push(MENU_ITEM.zoom_meeting)
        }else if(frmData.type===EVENT_SESSION_TYPE.external_platform.value && frmData.requireJoiningCode){
            tmpArr.push(MENU_ITEM.joining_code)
        }

        return tmpArr
    }

    return(
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid">
                <div className="p-col-12">
                    <SelectButton value={menuActive} className="p-margin-10-0" options={renderSelectItems()} onChange={(e) => e&&e.value&&setMenuActive(e.value)}></SelectButton>
                    <div className="p-hr"></div>
                </div>
                <div className="p-col-12">
                    <div className={classNames({'p-hide': menuActive!==MENU_ITEM.basic.value})}>
                        <div className="p-grid">
                            <div className="p-col-12">
                                <Fieldset legend="Basic Information">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <label className="p-label">* Type</label>
                                            <Dropdown value={frmData.type} options={Object.values(EVENT_SESSION_TYPE)} onChange={(e) => onChangeType(e.value)} />
                                            <div className="p-form-error">{errors.type}</div>
                                        </div>
                                        {frmData.type===EVENT_SESSION_TYPE.live.value && 
                                        <div className="p-col-12">
                                            <label>* Conference Type</label>
                                            <Dropdown value={frmData.conferenceType} options={Object.values(EVENT_CONFERENCE_TYPE)} onChange={(e) => setFrmData({...frmData, conferenceType: e.value})}/>
                                            <div className="p-form-error">{errors.conferenceType}</div>
                                        </div>
                                        }
                                        
                                        <div className="p-col-12">
                                            <label className="p-label">* Title</label>
                                            <InputText value={frmData.title} onChange={(e) => setFrmData({...frmData, title: e.target.value})} />
                                            <div className="p-form-error">{errors.title}</div>
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">Topic</label>
                                            <InputText value={frmData.topic} onChange={(e) => setFrmData({...frmData, topic: e.target.value})} />
                                        </div>
                                        
                                        {frmData.type===EVENT_SESSION_TYPE.live.value && 
                                        <div className="p-col-12">
                                            <label className="p-label">Agenda</label>
                                            <InputTextarea value={frmData.description} rows="5" onChange={(e) => setFrmData({...frmData, description: e.target.value})} />
                                        </div>
                                        }

                                        {frmData.type===EVENT_SESSION_TYPE.external_platform.value && 
                                        <React.Fragment>
                                            <div className="p-col-12">
                                                <label className="p-label">* External Platform Url</label>
                                                <InputText value={frmData.externalPlatformUrl} onChange={(e) => setFrmData({...frmData, externalPlatformUrl: e.target.value})} />
                                                <div className="p-form-error">{errors.externalPlatformUrl}</div>
                                            </div>
                                            <div className="p-col-12">
                                            <InputSwitch checked={frmData.requireJoiningCode} onChange={(e) => setFrmData({...frmData, requireJoiningCode: e.value })} />
                                                <label className="p-margin-left-10">Require code to join session?</label>
                                            </div>
                                        </React.Fragment>
                                        }

                                        <div className="p-col-12">
                                            <InputSwitch checked={frmData.enabled} onChange={(e) => setFrmData({...frmData, enabled: e.value })} />
                                            <label className="p-margin-left-10">Active?</label>
                                        </div>
                                    </div>
                                </Fieldset>
                                <Fieldset legend="Schedule">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <Checkbox inputId="cbIsRange" checked={frmData.dateRange} onChange={(e) => setFrmData({...frmData, dateRange: e.checked})} />
                                            <label htmlFor="cbIsRange" className="p-margin-left-10">Is date range?</label>
                                        </div>

                                        <div className="p-col-12">
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-6">
                                                    <label className="p-label">* {frmData.dateRange?'Start Date':'Date'}</label>
                                                    <MaskedCalendar value={frmData.startDateValue} showIcon={true} onChange={(e) => onDateChange(e.value, 'start')} onBlur={(e) => onDateBlur(e.target.value, 'start')} dateFormat="mm-dd-yy" />
                                                    <span className="p-form-error">{errors.startDate}</span>
                                                </div>
                                                {frmData.dateRange && 
                                                <div className="p-col-6">
                                                    <label className="p-label">* End Date</label>
                                                    <MaskedCalendar value={frmData.endDateValue} showIcon={true} onChange={(e) => onDateChange(e.value, 'end')} onBlur={(e) => onDateBlur(e.target.value, 'end')} dateFormat="mm-dd-yy" />
                                                    <span className="p-form-error">{errors.endDate}</span>
                                                </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="p-col-12">
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-6">
                                                    <label className="p-label">* Start Time</label>
                                                    <MaskedCalendar value={frmData.startTimeValue} showIcon={true} onChange={(e) => onTimeChange(e.value, 'start')} onBlur={(e) => onTimeBlur(e.target.value, 'start')} hourFormat="12" timeOnly={true}/>
                                                    <span className="p-form-error">{errors.startTime}</span>
                                                </div>
                                                <div className="p-col-6">
                                                    <label className="p-label">* End Time</label>
                                                    <MaskedCalendar value={frmData.endTimeValue} showIcon={true} onChange={(e) => onTimeChange(e.value, 'end')} onBlur={(e) => onTimeBlur(e.target.value, 'end')} hourFormat="12" timeOnly={true}/>
                                                    <span className="p-form-error">{errors.endTime}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                        </div>
                        <div className="p-sidebar-line p-my-3"></div>
                        <div className="p-grid">
                            <div className="p-col-12 p-d-flex p-justify-between">
                                <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => closeForm()}/>
                                <Button label="Save Information" icon="pi pi-save" onClick={() => handleSaveInformation()}/>
                            </div>
                        </div>
                    </div>
                    {frmData.id && menuActive===MENU_ITEM.content.value &&
                        <React.Fragment>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <Fieldset legend="Videos">
                                        <EventSessionVideo value={frmData.videos} onChange={(e) => setFrmData({...frmData, videos: e})}/>
                                    </Fieldset>
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-r">
                                    <Button label="Save Content" icon="pi pi-save" onClick={() => handleSaveContent()}/>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    {frmData.id && menuActive===MENU_ITEM.joining_code.value && 
                        <EventSessionJoiningCode sessionId={frmData.id}/>
                    }
                    {frmData.id && menuActive===MENU_ITEM.zoom_meeting.value &&
                        <MeetingForm sessionData={frmData} reloadData={() => props.refreshTable()} />
                    }
                </div>
            </div>
        </Sidebar>
    )
})
