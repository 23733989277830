/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { SplitButton } from "primereact/splitbutton";
import React, { useEffect, useRef, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch, useHistory } from "react-router-dom";
import {
  getPageMembershipCategories,
  removeMembershipCategory,
} from "./MembershipCategoryService";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";
import {
  showErrorNotify,
  showSuccessNotify,
} from "../../core/service/NotificationService";
import { MembershipCategoryForm } from "./MembershipCategoryForm";

const MembershipCategoryListView = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [dataList, setDataList] = useState([]);
  const [pageable, setPageable] = useState({
    page: 0,
    rows: 10,
    total: 0,
    sortField: "id",
    sortOrder: -1,
  });
  const [isLoadTable, setLoadTable] = useState(true);

  const frmMembershipCategory = useRef(null);

  useEffect(() => {
    if (isLoadTable) loadPageMembershipCategory();
  }, [isLoadTable]);

  const loadPageMembershipCategory = () => {
    getPageMembershipCategories(
      pageable.page,
      pageable.rows,
      pageable.sortField,
      pageable.sortOrder
    ).then((res) => {
      setDataList(res.content);
      setPageable({
        ...pageable,
        total: res.totalElements,
        page: res.pageable.pageNumber,
        rows: res.pageable.pageSize,
      });
      setLoadTable(false);
    });
  };

  const onPage = (e) => {
    setPageable({ ...pageable, page: e.page });
    setLoadTable(true);
  };

  const onSort = (e) => {
    setPageable({
      ...pageable,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    setLoadTable(true);
  };

  const onChangePageLength = (e) => {
    setPageable({ ...pageable, rows: e, page: 0 });
    setLoadTable(true);
  };

  const deleteCategory = (id) => {
    removeMembershipCategory(id).then((res) => {
      if (!res.errorCode) {
        setLoadTable(true);
        showSuccessNotify("Remove membership category successfully!");
      } else {
        showErrorNotify("Cannot perform action");
      }
    });
  };

  const tblMenuHeader = (
    <TableHeader
      title="Membership Management"
      pageReport={getTablePageReport(
        pageable.page,
        pageable.rows,
        pageable.total
      )}
      actionTemplate={
        <Button
          className="p-button p-component blue-btn"
          label="Create Membership Category"
          icon="pi-md-plus"
          onClick={() => frmMembershipCategory.current.openForm(null)}
        />
      }
      changePageLength={(e) => onChangePageLength(e)}
      refresh={() => setLoadTable(true)}
    />
  );

  return (
    <div className="p-grid">
      <BreadcrumbsItem to={match.url}>Membership Management</BreadcrumbsItem>

      <MembershipCategoryForm
        ref={frmMembershipCategory}
        refreshTable={() => setLoadTable(true)}
      />

      <div className="p-col-12">
        <h1>Membership Management</h1>

        <DataTable
          emptyMessage="There's no operating booking order found"
          header={tblMenuHeader}
          value={dataList}
          lazy={true}
          loading={isLoadTable}
          paginator={true}
          first={pageable.page * pageable.rows}
          rows={pageable.rows}
          totalRecords={pageable.total}
          sortField={pageable.sortField}
          sortOrder={pageable.sortOrder}
          onPage={onPage}
          onSort={onSort}
          responsive={true}
          dataKey="id"
        >
          <Column field="code" header="Code" sortable={true} />
          <Column field="name" header="Name" sortable={true} />
          <Column
            field="action"
            header="Action"
            style={{ width: "13em" }}
            bodyClassName="tc-actions"
            body={(rowData) => {
              const actionItems = [
                {
                  label: "Edit",
                  icon: "pi pi-md-pencil",
                  command: () =>
                    frmMembershipCategory.current.openForm(rowData),
                },
                {
                  label: "Remove",
                  icon: "pi pi-times",
                  command: () => deleteCategory(rowData.id),
                },
              ];
              return (
                <SplitButton
                  label="View"
                  model={actionItems}
                  className="p-button-constrast p-l"
                  icon='pi pi-eye'
                  onClick={() => {
                    history.push(`/membership/${rowData.id}`, { parent: rowData});
                  }}
                />
              );
            }}
          />
        </DataTable>
      </div>
    </div>
  );
};

MembershipCategoryListView.propTypes = {};

export default MembershipCategoryListView;
