/* eslint-disable react-hooks/exhaustive-deps */
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Fieldset } from "primereact/fieldset";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { FieldWrapper } from "../../core/components/FieldWrapper";
import { useHistory, useParams } from "react-router";
import { getUserById } from "./MergeDuplicateService";
import { showErrorNotify } from "../../core/service/NotificationService";
import { Button } from "primereact/button";

const FAKE_DB = [
  {
    id: 1,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Michelle",
      id: 1,
      lastName: "Kim",
      name: "main user",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 1,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 2,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 3,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 2,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Matt",
      id: 2,
      lastName: "Kang",
      name: "string 1",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 4,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 5,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 6,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 3,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Jason",
      id: 1,
      lastName: "Price",
      name: "string 2",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 7,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 8,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 9,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 4,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Michelle",
      id: 1,
      lastName: "Kim",
      name: "string 3",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 1,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 2,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 3,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 5,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Matt",
      id: 2,
      lastName: "Kang",
      name: "string 4",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 4,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 5,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 6,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 6,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Jason",
      id: 1,
      lastName: "Price",
      name: "string 5",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 7,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 8,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 9,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 3,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Jason",
      id: 1,
      lastName: "Price",
      name: "string 6",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 7,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 8,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 9,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 4,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Michelle",
      id: 1,
      lastName: "Kim",
      name: "string 7",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 1,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 2,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 3,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 5,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Matt",
      id: 2,
      lastName: "Kang",
      name: "string 8",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 4,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 5,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 6,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 6,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Jason",
      id: 1,
      lastName: "Price",
      name: "string 9",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 7,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 8,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 9,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
];

const MergeDuplicateStep3 = (mainUser) => {

  const info = mainUser.mainUser
  let { id } = useParams();

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(() => {

  }, []);

  const onTabChange = (e) => {
    setCurrentTabIndex(e.index);
  };

  return (
    <div className="p-grid">
      <BreadcrumbsItem to="/monitoring/invoices">
        Merge duplicate
      </BreadcrumbsItem>
      {/* <BreadcrumbsItem to={match.url}>{user.name}</BreadcrumbsItem> */}

      <div className="p-col-12">
        <TabView activeIndex={currentTabIndex} onTabChange={onTabChange}>
          <TabPanel header="Information">
            <div className="p-grid">
              <div className="p-col-12 p-sm-6">
                <Fieldset legend="Basic Information">
                  <div className="p-grid p-fluid form-group">
                    <FieldWrapper col="p-col-12">
                      <label className="p-label">Username: </label>
                      <div className="p-w-bold">{info.user.username}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12">
                      <label className="p-label">Title: </label>
                      <div className="p-w-bold"></div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12">
                      <label className="p-label">Nickname: </label>
                      <div className="p-w-bold"></div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12">
                      <label className="p-label">First Name: </label>
                      <div className="p-w-bold">{info.user.firstName}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12">
                      <label className="p-label">Middle Name: </label>
                      <div className="p-w-bold">-{info.user.middleName}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12">
                      <label className="p-label">Last Name: </label>
                      <div className="p-w-bold">{info.user.lastName}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12">
                      <label className="p-label">Primary Email: </label>
                      <div className="p-w-bold">{info.user.email}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12">
                      <label className="p-label">Secondary Email: </label>
                      <div className="p-w-bold"></div>
                    </FieldWrapper>
                  </div>
                </Fieldset>
              </div>
              <div className="p-col-12 p-sm-6">
                <div className="p-grid p-dir-col">
                  <div className="p-col-12">
                    <Fieldset legend="Company Information">
                      <div className="p-grid p-fluid form-group">
                        <FieldWrapper col="p-col-12">
                          <label className="p-label">Name: </label>
                          <div className="p-w-bold"></div>
                        </FieldWrapper>
                        <FieldWrapper col="p-col-12">
                          <label className="p-label">Position: </label>
                          <div className="p-w-bold"></div>
                        </FieldWrapper>
                        <FieldWrapper col="p-col-12">
                          <label className="p-label">Start from: </label>
                          <div className="p-w-bold"></div>
                        </FieldWrapper>
                      </div>
                    </Fieldset>
                  </div>
                  <div className="p-col-12">
                    <Fieldset legend="Groups">
                      <div className="p-grid p-fluid form-group">
                        <FieldWrapper col="p-col-12">
                          <label className="p-label">Household: </label>
                          <div className="p-w-bold"></div>
                        </FieldWrapper>
                        <FieldWrapper col="p-col-12">
                          <label className="p-label">Company: </label>
                          <div className="p-w-bold"></div>
                        </FieldWrapper>
                      </div>
                    </Fieldset>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Security">
            <div className="p-grid">
              <div className="p-col-12">
                <Fieldset legend="Authentication Infomation ">
                  <div className="p-grid p-fluid form-group">
                    <FieldWrapper col="p-col-12">
                      <label className="p-label">Username: </label>
                      <div className="p-w-bold">{info.user.username}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">Primary Email: </label>
                      <div className="p-w-bold">{info.user.email}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">Secondary Email: </label>
                      <div className="p-w-bold">-</div>
                    </FieldWrapper>
                  </div>
                </Fieldset>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Relationship">
            <DataTable value={FAKE_DB}>
              <Column field="id" header="ID" headerClassName="headerStyles" />
              <Column
                header="Full Name"
                headerClassName="headerStyles"
                body={(rowData) => rowData.user.firstName}
              />
              <Column
                header="Age"
                headerClassName="headerStyles"
                body={(rowData) => rowData.user.address}
              />
              <Column
                header="Phone"
                headerClassName="headerStyles"
                body={(rowData) => rowData.user.phone}
              />
              <Column header="Relationship" headerClassName="headerStyles" />
              <Column
                header="Gender"
                headerClassName="headerStyles"
                body={(rowData) => rowData.user.gender}
              />
            </DataTable>
          </TabPanel>
          <TabPanel header="Emergency Contact">
            <DataTable value={FAKE_DB.slice(0, 4)}>
              <Column field="id" header="ID" headerClassName="headerStyles" />
              <Column
                header="Full Name"
                headerClassName="headerStyles"
                body={(rowData) => rowData.user.firstName}
              />
              <Column
                header="Age"
                headerClassName="headerStyles"
                body={(rowData) => rowData.user.address}
              />
              <Column
                header="Phone"
                headerClassName="headerStyles"
                body={(rowData) => rowData.user.phone}
              />
              <Column header="Relationship" headerClassName="headerStyles" />
              <Column
                header="Gender"
                headerClassName="headerStyles"
                body={(rowData) => rowData.user.gender}
              />
            </DataTable>
          </TabPanel>
          <TabPanel header="Address">
            <DataTable value={FAKE_DB.slice(0, 4)}>
              <Column field="id" header="No" headerClassName="headerStyles" />
              <Column header="Address 1" headerClassName="headerStyles" />
              <Column header="Address 2" headerClassName="headerStyles" />
              <Column header="City" headerClassName="headerStyles" />
              <Column header="State/Province" headerClassName="headerStyles" />
              <Column header="Country" headerClassName="headerStyles" />
              <Column header="Zip Code" headerClassName="headerStyles" />
              <Column header="Primary" headerClassName="headerStyles" />
            </DataTable>
          </TabPanel>
          <TabPanel header="Identity Document">
            <div className="p-grid">
              <div className="p-col-12">
                <Fieldset legend="Identity Document">
                  <div className="p-grid p-fluid form-group">
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">Passport number: </label>
                      <div className="p-w-bold">{info.identityDocument.docType == "passport" ? info.identityDocument.docNumber : ""}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">Passport Expiary: </label>
                      <div className="p-w-bold">{info.identityDocument.docType == "passport" ? info.identityDocument.expiredDate : ""}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">Work Permit Number: </label>
                      <div className="p-w-bold">{info.identityDocument.docType == "work_permit" ? info.identityDocument.docNumber : ""}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">Work Permit Expiry: </label>
                      <div className="p-w-bold">{info.identityDocument.docType == "work_permit" ? info.identityDocument.expiredDate : ""}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">FIN: </label>
                      <div className="p-w-bold">{info.identityDocument.docType == "fin" ? info.identityDocument.docNumber : ""}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">NRIC Number: </label>
                      <div className="p-w-bold"></div>
                    </FieldWrapper>
                  </div>
                </Fieldset>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <Button
          label="Confirm"
          style={{ width: '6rem' }}
          className="blue-btn"
          onClick={() => window.location.replace('/monitoring/invoices')}
        />
      </div>
    </div>
  );
};

export default MergeDuplicateStep3;

{
  /* <Button
        label="Confirm"
        style={{ width: '6rem' }}
        className="blue-btn"
        onClick={() => history.push('/monitoring/invoices')}
      /> */
}
