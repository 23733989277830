import React, {Component} from 'react';
import moment from 'moment';
import {ReportServices} from './ReportServices';
import { ProgramServices } from '../../crm/program/ProgramServices';
import { GLAccountServices } from '../accounting/gl_accounts/GLAccountServices';
import {TabView,TabPanel} from 'primereact/tabview';
import {Button} from 'primereact/button';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { CampaignServices } from '../campaign/CampaignServices';
import { Sidebar } from 'primereact/sidebar';
import { UserServices } from "../../constituent-management/contact/UserServices";
import AsyncSelect from 'react-select/async';
import SelectOption from "../../core/components/contact/SelectionOption.js";
import { DepartmentServices, getListDepartments } from '../departments/DepartmentServices';
import { showNotification, showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { PickList } from 'primereact/picklist';
import { OrderList } from "primereact/orderlist";
import { ROLES_CONFIG } from '../../roles';
import { hasRole } from "../../core/security/auth";
import { titleCaseText } from "../../core/service/CommonService";
import { PARTICIPANT_STATUS } from '../../constants';
import { getEvents, getEventSessions } from '../event/EventService';

const reportType = {
    order: 'order',
    program_participant: 'program_participant',
    event_participant: 'event_participant',
}

const reportTypes = [
    // {label: 'GL Accounting', value: 'gl_account'},
    // {label: 'Transaction', value: 'transaction'},
    {label: 'Order', value: reportType.order},
    {label: 'Program Participant', value: reportType.program_participant},
    {label: 'Event Participant', value: reportType.event_participant},
];

const lstPaymentStatus = [
    // {value: 'processing', label: 'Processing'},
    {value: 'completed', label: 'Completed'},
    {value: 'pending', label: 'Pending'},
    {value: 'cancel', label: 'Canceled'}
];

const lstOrderPaymentStates = [
    {value: 'pending', label: 'Pending'},
    {value: 'awaiting', label: 'Awaiting Payment'},
    {value: 'completed', label: 'Completed'}
];

const lstParticipantStatus = Object.values(PARTICIPANT_STATUS)

export class Reports extends Component{
    constructor(props){
        super(props);
        this.state = {
            formHeader: 'Report Providing Parameters',
            shareSideBarHeader: 'User Sharing Setting',
            report: {
                departmentIds: [],
                sharedUsers: []
            },
            reportConfig: {},
            lstPrograms: [],
            lstCourses: [],
            rptSourceColumns: [],
            rptTargetColumns : [],
            startDateValue: '',
            endDateValue: '',
            dataList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: 'id',
            sortOrder:-1,
            activeTabIndex: 0,
            glAccountTree: [],
            selectedRptParamData: {},
            rptParamData: {},
            rptParamVisible: false,
            rptParamErrors: {},
            errors: {},
            tmpRptHolder:[],
            departmentList:[],
            shareSideBarVisible: false,
            shareUserList: [],
            shareDisplayList: [],
            selectReportId: null,
            selectedUser:null,
            events: [],
            eventSessions: []
        }

        this.reportServices = new ReportServices();
        this.programServices = new ProgramServices();
        this.accountServices = new GLAccountServices();
        this.campaignServices = new CampaignServices();
        this.userServices = new UserServices();
        this.departmentServices = new DepartmentServices();
    }

    componentDidMount(){
        this.getPageReports();
        this.getListProgram();
        this.loadAllCampaigns();
        this.getListDepartmentByMember();
        this.loadEvents();
    }

    getPageReports = () => {
        this.reportServices.getPageReports(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
        .then(res => {
            this.setState({
                dataList: res.content,
                total: res.totalElements,
                loading: false,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            });
        });
        
    }

    getListDepartmentByMember = () => {
        let deptUsers = localStorage.getItem('depts');
        
        if(deptUsers && deptUsers !== 'undefined'){
            let deptsData = JSON.parse(deptUsers);
            let tempArrDepts = [];
            if(deptsData && deptsData.length > 0){
                deptsData.map(dept => {
                    tempArrDepts.push({label: dept.name, value: dept.id})
                });
            }else{
                getListDepartments()
                .then(res => {
                    tempArrDepts = res;
                });
            }
            
            this.setState({ departmentList: tempArrDepts})
        }
    }

    actionTemplate = (rowData, column) => {
        let currentLogUser = localStorage.getItem('user_id')?parseInt(localStorage.getItem('user_id')):null;
        return (
            <div>
                {rowData.createdBy===currentLogUser && !rowData.sharedUsers.includes(currentLogUser) &&
                    <Button icon="pi pi-share-alt" className="p-button-info"  onClick={() => this.shareSettingOnClick(rowData)}/>
                }
                <Button icon="pi pi-download" className="p-button-success" onClick={() => this.runReport(rowData)}/>
                <Button icon="pi pi-search" className="p-button-warning" onClick={() => this.addOrEditReport(rowData)}/>
                {rowData.createdBy===currentLogUser&&
                    <Button icon="pi-md-close" className="p-button-danger" onClick={() => this.deleteNotify.delete(rowData.id)}/>
                }
            </div>
        );
    }

    onPage = (e) => {
        this.setState({
            loading: true,
            page: e.page
        }, () => {
            this.getPageReports();
        });
    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        }, () => {
            this.getPageReports();
        })
        
    }

    onTypeChange = (e) => {
        this.setState({
            report: {...this.state.report, type: e.value}
        }, () => {
            this.getListReportColumns(e.value);
        });
    }

    handleSaveReport = () => {
        var report = this.state.report;
        var config = this.state.reportConfig;

        let rptTargetColumns = [...this.state.rptTargetColumns];
        let rptSelectedColumns = [];

        rptTargetColumns.map(e => {
            rptSelectedColumns.push(e.value);
        });

        switch(report.type){
            case reportType.order: config.orderColumns = rptSelectedColumns; break;
            case reportType.transaction: config.transColumns = rptSelectedColumns; break;
            case reportType.program_participant: 
            case reportType.event_participant: 
                config.participantColumns = rptSelectedColumns; 
                break;
            default: break;
        }

        report.config = config;

        this.reportServices.saveReport(report)
            .then(res => {
                if(!res.errorCode){
                    this.popularFormData(null);
                    showNotification('success', 'Success Message', 'Action submitted');
                    this.getPageReports(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder);
                    this.setState({activeTabIndex:0});
                } else{
                    if(res.errorCode===400) this.setState({errors: res.errorObj})
                    showNotification('error', 'Error Message', res.errorMessage);
                }
            });
    }

    removeReport = (id) => {
        this.reportServices.removeReport(id).then(res => {
            if(!res.errorCode){
                this.getPageReports(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder);
                showSuccessNotify('Action submitted')
            }else{
                showErrorNotify(res.errorMessage)
            }
        });
    }

    runReport = (data) => {
        var config = data.config;
        switch(config.type){
            case reportType.program_participant:
                if(config && config.refId){
                    this.getListProgramCourse(config.refId);
                }else{
                    this.setState({lstCourses:[]});
                }
                break;
            case reportType.event_participant:
                this.loadEventSessions(config.refId);
            break;
            default: break;
        }
        
        
        this.setState({
            rptParamVisible: true,
            selectedRptParamData: config,
            rptParamData: {
                type: config?config.type:null,
                startDate: config && config.startDate?config.startDate:'',
                endDate: config && config.endDate?config.endDate:'',
                paymentStatus: config && config.paymentStatus?config.paymentStatus:['completed'],
                orderPaymentState: config && config.orderPaymentState?config.orderPaymentState:['completed'],
                refId: config?config.refId:'',
                campaignId: config?config.campaignId:'',
                childRefIds: config && config.childRefIds?config.childRefIds:[],
                participantStatus: config && config.participantStatus?config.participantStatus:['COMPLETED'],
                orderColumns: config?config.orderColumns:[],
                transColumns: config?config.transColumns:[],
                participantColumns: config?config.participantColumns:[],
                creatorId: config?config.creatorId:''
            },
            rptStartDateValue: config && config.startDate?moment(config.startDate, moment.HTML5_FMT.DATE).toDate():'',
            rptEndDateValue: config && config.endDate?moment(config.endDate, moment.HTML5_FMT.DATE).toDate():''
        },()=>{
            if(this.state.rptParamData.creatorId)
                this.displayUserById(this.state.rptParamData.creatorId);
        });
    }

    exportReport = () => {
        var params = this.state.rptParamData;
        var objErrors = {};
        var hasError = false;

        switch(params.type){
            case reportType.order:
                if(params.orderPaymentState.length===0){
                    hasError=true;
                    objErrors.orderPaymentState = 'Please select at least one value!';
                }
                break;
            case reportType.transaction:
                if(params.paymentStatus.length===0){
                    hasError=true;
                    objErrors.paymentStatus = 'Please select at least one value!';
                }
                break;
            case reportType.program_participant:
            case reportType.event_participant:
                if(!params.refId){
                    hasError=true;
                    objErrors.refId = 'Please select value!';
                }
                    
                if(params.childRefIds.length===0){
                    hasError=true;
                    objErrors.childRefIds = 'Please select at least one value!';
                }

                if(params.participantStatus.length===0){
                    hasError=true;
                    objErrors.participantStatus = 'Please select at least one value!';
                }
                break;
            default:
                break;
        }

        if(hasError){
            this.setState({rptParamErrors: objErrors});
            showNotification('error', 'Error Message', 'Bad Request!');
        }else{
            var fileDownload = require('js-file-download');
            var suf = moment().format('YYYYMMDDHHmmss');
            var fileName = 'rpt_' + this.state.rptParamData.type + '_' + suf + '.xlsx';

            this.reportServices.exportReport(this.state.rptParamData).then(res => {
                if(res && res.status===200){
                    showNotification('success', 'Success Message', 'Report was generated!');
                    this.setState({rptParamErrors: {}, rptParamVisible: false});
                    fileDownload(res.data, fileName);
                }else{
                    showNotification('error', 'Error Message', res?res.status.message:'Report generating fail! No result');
                }
            });
        }
    }

    addOrEditReport = (data) => {
        this.popularFormData(data);
        this.setState({activeTabIndex: 1});
    }

    popularFormData = (data) => {
        let config = data?data.config:null;
        if(config && config.refId && config.campaignId){
            this.getListProgramCourse(config.refId, config.campaignId);
        }else{
            this.setState({lstCourses:[]});
        }
        let columns=[];
        if(data){
            switch(data.type){
                case reportType.order: columns = config.orderColumns?config.orderColumns:[]; break;
                case reportType.transaction: columns = config.transColumns?config.transColumns:[]; break;
                case reportType.program_participant: 
                case reportType.event_participant: 
                    columns = config.participantColumns?config.participantColumns:[]; 
                    break;
                default: break;
            }
        }

        this.setState({
            ...this.state,
            report: {
                id: data?data.id:'',
                name: data?data.name:'',
                type: data?data.type:null,
                description: data?data.description:'',
                departmentIds: data?data.departmentIds:[],
                sharedUsers: data?data.sharedUsers:[],
                createdBy: data?data.createdBy:'',
            },
            reportConfig: {
                type: config?config.type:null,
                startDate: config && config.startDate?config.startDate:'',
                endDate: config && config.endDate?config.endDate:'',
                paymentStatus: config && config.paymentStatus?config.paymentStatus:[],
                orderPaymentState: config && config.orderPaymentState?config.orderPaymentState:[],
                refId: config?config.refId:'',
                campaignId: config?config.campaignId:'',
                childRefIds: config && config.childRefIds?config.childRefIds:[],
                participantStatus: config && config.participantStatus?config.participantStatus:[],
                creatorId: config?config.creatorId:'',
            },
            startDateValue: config && config.startDate?moment(config.startDate, moment.HTML5_FMT.DATE).toDate():'',
            endDateValue: config && config.endDate?moment(config.endDate, moment.HTML5_FMT.DATE).toDate():'',
            errors: {}
        },()=>{
            this.getListReportColumns(data?data.type:'', columns);
            if(this.state.reportConfig.creatorId)
                this.getUserById(this.state.reportConfig.creatorId);
            if(this.state.reportConfig.type === reportType.event_participant){
                this.loadEventSessions(this.state.reportConfig.refId);
            }
        });
    }

    getListReportColumns = (type, selectedColumns) => {
        let sources = [];
        let targets = [];

        if(type && type!=='gl_account'){
            this.reportServices.getListReportColumns(type)
            .then(res => {
                if(res.length>0){
                    res.map(e => {
                        if(selectedColumns && selectedColumns.includes(e.value)){
                            targets.push(e);
                        }else{
                            sources.push(e);
                        }
                    });
                }

                this.setState({
                    rptSourceColumns: sources,
                    rptTargetColumns: targets
                });
            });
        }else{
            this.setState({
                rptSourceColumns: [],
                rptTargetColumns: []
            });
        }
    }

    rptColumnTemplate = (e) => {
        return (
            <div className="p-clearfix">
                <label>{e.label}</label>
            </div>
        );
    }

    getListProgram = () => {
        this.programServices.getListAllPrograms().then(res => this.setState({lstPrograms: res}));
    }

    loadAllCampaigns = () => {
        this.campaignServices.getListCampaign().then(res => {this.setState({campaigns: res ? res : []})});
    }

    getListProgramCourse = (progId, campaignId) => {
        this.programServices.getListProgramCourses(progId, campaignId).then(res => {
            this.setState({lstCourses: res})
        });
    }

    onStartDateChange = (e) => {
        this.setState({
            reportConfig: {...this.state.reportConfig, startDate: (e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '')},
            startDateValue: e.value
        });
    }

    onEndDateChange = (e) => {
        this.setState({
            reportConfig: {...this.state.reportConfig, endDate: (e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '')},
            endDateValue: e.value
        });
    }

    onRptStartDateChange = (e) => {
        this.setState({
            rptParamData: {...this.state.rptParamData, startDate: (e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '')},
            rptStartDateValue: e.value
        });
    }

    onRptEndDateChange = (e) => {
        this.setState({
            rptParamData: {...this.state.rptParamData, endDate: (e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '')},
            rptEndDateValue: e.value
        });
    }

    onProgramChange = (e) => {
        this.setState({
            reportConfig: {
                ...this.state.reportConfig,
                refId: e.value,
                campaignId: '',
                childRefIds: []
            }
        },() => {
            if(this.state.reportConfig.refId)
                this.loadAllCampaigns();
        });
    }

    onChangeCampaign = (e) => {
        this.setState({
            reportConfig: {
                ...this.state.reportConfig,
                campaignId: e.value,
                childRefIds: []
            }
        },() => {
            if(this.state.reportConfig.refId){
                this.getListProgramCourse(this.state.reportConfig.refId, e.value);
            }
        });
    }

    onRptParamProgramChange = (e) => {
        this.setState({
            rptParamData: {...this.state.rptParamData, 
                refId: e.value,
                campaignId: '',
                childRefIds: []
            }
        },() => {
            if(this.state.rptParamData.refId)
                this.loadAllCampaigns();
                this.getListProgramCourse(this.state.rptParamData.refId);
        });
    }

    onRptParamCampaignChange = (e) => {
        this.setState({
            rptParamData: {
                ...this.state.rptParamData,
                campaignId: e.value,
                childRefIds: []
            }
        },() => {
            if(this.state.rptParamData.refId){
                this.getListProgramCourse(this.state.rptParamData.refId, e.value);
            }
        });
    }

    onRptCheckBoxChange = (e) => {
        let tmpSelectedColums = [...this.state.rptTargetColumns];

        if(e.checked){
            if(tmpSelectedColums.indexOf(e.value)===-1)
                tmpSelectedColums.push(e.value);
        }else{
            if(tmpSelectedColums.indexOf(e.value)!==-1)
                tmpSelectedColums.splice(tmpSelectedColums.indexOf(e.value), 1);
        }
        
        this.setState({rptTargetColumns: tmpSelectedColums});
    }

    loadListUsers = (searchTerm, callback) => {
        this.userServices.getAllUsers(searchTerm)
        .then(res => {
            let userId = this.state.reportConfig.creatorId;
            let user = res ? res.find(user => user.value == userId) : '';
            this.setState({
                reportConfig:{
                    ...this.state.reportConfig,
                    creatorIdValue: user
                }
            });
            callback (res) 
        });
        
    }

    getUserById (id) {
        this.userServices.getUser(id).then(res =>
            this.setState({
                reportConfig:{
                    ...this.state.reportConfig,
                    creatorIdValue: {value: res.id, label: res.name}
                }
            })
        )
    }

    getShareUserDisplayList (id){
        this.userServices.getUser(id).then(res =>{
            let obj = {value: res.id, label:res.name}
            this.setState({
                shareDisplayList: [...this.state.shareDisplayList, obj]
            });
        });
    }

    displayUserById (id) {
        this.userServices.getUser(id).then(res =>
            this.setState({
                rptParamData:{
                    ...this.state.rptParamData,
                    creatorIdValue: {value: res.id, label: res.name}
                }
            })
        )
    }

    displayCreatorId = (e) => {
        this.setState({
            rptParamData:{
                ...this.state.rptParamData,
                creatorId: e.value,
                creatorIdValue: e
            }
        })
    }

    getCreatorId = (data) => {
        this.setState({
            reportConfig:{
                ...this.state.reportConfig,
                creatorId: data?data.value:'',
                creatorIdValue: data
            }
        })
    }

    shareSettingOnClick = (data) =>{
        let tmpSelectId = data.id
        let userList = data.sharedUsers
        if(userList){
            userList.map(item =>{
                this.getShareUserDisplayList(item)
            })
        }

        this.setState({
            shareSideBarVisible: true,
            selectReportId: tmpSelectId
        });

    }

    handleShareSettingSave = () => {
        let tmpShareUserList = [];
        let data = [...this.state.shareDisplayList];
        if(data && data.length>0){
            data.map(item =>{
                tmpShareUserList.push(item.value);
            });
        }  
        this.reportServices.saveShareUsers(this.state.selectReportId, tmpShareUserList)
        .then(res => {
            if(!res.errorCode){
                showNotification('success', 'Success Message', 'Action submitted!');
                this.setState({
                    shareSideBarVisible:false,
                    shareDisplayList: []
                });
                this.getPageReports();
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        }); 
    }

    shareUserItemTemplate = (val) => {
        return (
            <div className="p-clearfix">
                <div className="p-grid">
                    <div className="p-col-9">
                        <div>Id: {val.value}</div>
                        <div>Username: {val.label}</div>
                        <div>Name: {val.name}</div>
                    </div>
                    <div className="p-col-3 p-r">
                        <Button icon="pi-md-close" className="p-button-danger" onClick={() => this.removeSharedUser(val)} style={{'float':'right'}} />
                    </div>
                </div>
            </div>
        );
    }

    addUserToShareList = () => {
        if(this.state.selectedUser){
            let tmpListHolder = this.state.shareDisplayList;

            tmpListHolder.push(this.state.selectedUser)
            this.setState({
                shareDisplayList: tmpListHolder,
                selectedUser:null
            });
        }else{
            showNotification('error', 'Error Message', 'Please select an employee to shared');
        }
    }

    removeSharedUser = (val) => {
        let lstValues= [...this.state.shareDisplayList];
        lstValues.splice(lstValues.indexOf(val), 1);

        this.setState({shareDisplayList : lstValues});
    }

    getShareUserId = (data) =>{
        let tmpShareUserList = [];
        if(data && data.length>0){
            data.map(item =>{
                tmpShareUserList.push(item.value);
            });
        }

        this.setState({
            shareUserList: tmpShareUserList
        });
    }

    loadEvents = () => {
        getEvents(true).then(res => {this.setState({events: res ? res : []})});
    }

    loadEventSessions = (eventId) => {
        if(eventId)
            getEventSessions(true, eventId).then(res => {this.setState({eventSessions: res ? res : []})});
        else 
            this.setState({ eventSessions: [] });
    }

    onEventChange = (e) => {
        this.setState({
            reportConfig: {...this.state.reportConfig,
                refId: e.value,
                childRefIds: []
            }
        },() => {
            this.loadEventSessions(this.state.reportConfig.refId)
        });
    }

    onRptParamEventChange = (e) => {
        this.setState({
            rptParamData: {...this.state.rptParamData,
                refId: e.value,
                childRefIds: []
            }
        },() => {
            this.loadEventSessions(this.state.rptParamData.refId)
        });
    }

    render(){
        let tblHeader = 
        <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
            <Button disabled={!hasRole([ROLES_CONFIG.REPORT_W])} icon="pi pi-plus" className="p-button-constrast" style={{float: 'left' }} onClick={() => this.addOrEditReport(null)}/>
            <Button icon="pi pi-refresh" style={{float:'right'}} onClick={() => this.getPageReports(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)}/>
        </div>;

        let currentLogUser = localStorage.getItem('user_id')?parseInt(localStorage.getItem('user_id')):null;

        const Option = props => SelectOption(props)


        var rptParamFooter = (
            <div>
                <Button label="Run" icon="pi pi-check" onClick={(e) => this.exportReport()}/>
                <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={(e)=>this.setState({rptParamVisible: false, rptParamErrors:{}})}/>
            </div>
        );

        return (
            <div className="p-grid">
                <DeleteNotification ref={el => this.deleteNotify=el}
                    accessDelete={(e) => this.removeReport(e)}
                />
                <Sidebar dismissable={false} visible={this.state.rptParamVisible} className="p-sidebar-md" style={{overflowY: 'auto'}} position="right" blockScroll={true} onHide={(e)=>this.setState({rptParamVisible:false, rptParamErrors:{}})}>
                {/* <Sidebar header="Report Providing Parameters" footer={rptParamFooter} visible={this.state.rptParamVisible} width="650px" modal={true} onHide={(e)=>this.setState({rptParamVisible:false, rptParamErrors:{}})}> */}
                    <h2>{this.state.formHeader}</h2>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12">
                            <span>Provide parameter to get data you need in reprot</span>
                        </div>
                        {(this.state.rptParamData.type!==reportType.program_participant && this.state.rptParamData.type!==reportType.event_participant)&&
                            <React.Fragment>
                                <div className="p-col-12 p-md-6">
                                    <label>From Date</label>
                                    <Calendar dateFormat="mm-dd-yy" disabled={this.state.selectedRptParamData.startDate?true:false} value={this.state.rptStartDateValue} onChange={this.onRptStartDateChange} showIcon={true}/>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label>To Date</label>
                                    <Calendar dateFormat="mm-dd-yy" disabled={this.state.selectedRptParamData.endDate?true:false} value={this.state.rptEndDateValue} onChange={this.onRptEndDateChange} showIcon={true}/>
                                </div>
                            </React.Fragment>
                        }
                        {this.state.rptParamData.type===reportType.transaction&&
                            <div className="p-col-12">
                                <label className="p-label">Status</label>
                                <MultiSelect disabled={this.state.selectedRptParamData.paymentStatus&&this.state.selectedRptParamData.paymentStatus.length>0?true:false} value={this.state.rptParamData.paymentStatus} options={lstPaymentStatus} onChange={(e) => this.setState({rptParamData: {...this.state.rptParamData, paymentStatus: e.value}})} style={{width: '100%'}}/>
                                <span className="p-form-error">{this.state.rptParamErrors.paymentStatus}</span>
                            </div>
                        }
                        {this.state.rptParamData.type===reportType.order &&
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label className="p-label">Payment State</label>
                                    <MultiSelect disabled={this.state.selectedRptParamData.orderPaymentState&&this.state.selectedRptParamData.orderPaymentState.length>0?true:false} value={this.state.rptParamData.orderPaymentState} options={lstOrderPaymentStates} onChange={(e) => this.setState({rptParamData: {...this.state.rptParamData, orderPaymentState: e.value}})} style={{width: '100%'}}/>
                                    <span className="p-form-error">{this.state.rptParamErrors.orderPaymentState}</span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Employee</label>
                                    <AsyncSelect ref={el => this.select=el}
                                        loadOptions={this.loadListUsers} 
                                        onChange={(e) => this.displayCreatorId(e)}
                                        placeholder='Type your search'
                                        components={{ Option }}
                                        value={this.state.rptParamData.creatorIdValue}
                                        disabled={this.state.selectedRptParamData.creatorIdValue?true:false}
                                        isClearable
                                    />
                                </div>
                            </React.Fragment>
                        }
                        {this.state.rptParamData.type===reportType.program_participant &&
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label className="p-label">* Program</label>
                                    <Dropdown disabled={this.state.selectedRptParamData.refId?true:false} value={this.state.rptParamData.refId} options={this.state.lstPrograms} filter={true} onChange={(e) => this.onRptParamProgramChange(e)} style={{width: '100%'}} placeholder="Please Choose"/>
                                    <span className="p-form-error">{this.state.rptParamErrors.refId}</span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Campaign</label>
                                    <Dropdown disabled={this.state.selectedRptParamData.campaignId?true:false} value={this.state.rptParamData.campaignId} options={this.state.campaigns} showClear onChange={(e) => this.onRptParamCampaignChange(e)} style={{width: "100%"}} />
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">* Course</label>
                                    <MultiSelect disabled={this.state.selectedRptParamData.childRefIds.length>0?true:false} value={this.state.rptParamData.childRefIds} options={this.state.lstCourses} filter={true} onChange={(e) => this.setState({rptParamData: {...this.state.rptParamData, childRefIds: e.value}})} style={{width: '100%'}}/>
                                    <span className="p-form-error">{this.state.rptParamErrors.childRefIds}</span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">* Status</label>
                                    <MultiSelect disabled={this.state.selectedRptParamData.participantStatus.length>0?true:false} value={this.state.rptParamData.participantStatus} options={lstParticipantStatus} onChange={(e) => this.setState({rptParamData: {...this.state.rptParamData, participantStatus: e.value}})} style={{width: '100%'}}/>
                                    <span className="p-form-error">{this.state.rptParamErrors.participantStatus}</span>
                                </div>
                            </React.Fragment>
                        }
                        {this.state.rptParamData.type===reportType.event_participant &&
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label className="p-label">Event</label>
                                    <Dropdown disabled={this.state.selectedRptParamData.refId?true:false}  value={this.state.selectedRptParamData.refId} options={this.state.events} filter={true} filterBy='label,value' showClear={true} onChange={(e) => this.onRptParamEventChange(e)} style={{width: '100%'}} placeholder="Please Choose"/>
                                    <span className="p-form-error">{this.state.rptParamErrors.refId}</span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Event Session</label>
                                    <MultiSelect disabled={this.state.selectedRptParamData.childRefIds.length>0?true:false} value={this.state.rptParamData.childRefIds} options={this.state.eventSessions} filter={true} showClear={true} onChange={(e) => this.setState({rptParamData: {...this.state.rptParamData, childRefIds: e.value}})} style={{width: '100%'}}/>
                                    <span className="p-form-error">{this.state.rptParamErrors.childRefIds}</span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Status</label>
                                    <MultiSelect disabled={this.state.selectedRptParamData.participantStatus.length>0?true:false} value={this.state.rptParamData.participantStatus} options={lstParticipantStatus} onChange={(e) => this.setState({rptParamData: {...this.state.rptParamData, participantStatus: e.value}})} style={{width: '100%'}}/>
                                    <span className="p-form-error">{this.state.rptParamErrors.participantStatus}</span>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            {rptParamFooter}
                        </div>
                    </div>
                </Sidebar>

                <Sidebar dismissable={false} visible={this.state.shareSideBarVisible} className="p-sidebar-md" style={{overflowY: 'auto'}} position="right" blockScroll={true} onHide={(e)=>this.setState({shareSideBarVisible: false})}>
                    <h2>{this.state.shareSideBarHeader}</h2>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <label className="p-label">Select Employee To Share</label>
                            <AsyncSelect ref={el => this.select=el}
                                loadOptions={this.loadListUsers} 
                                onChange={(e) => this.setState( {selectedUser: e})}
                                placeholder='Type your search'
                                components={{ Option }}
                                value={this.state.selectedUser}
                                isClearable
                            />
                            <div className="p-col-12">
                                <Button label="Add Selected Employee" icon="pi pi-plus" iconPos="left" style={{ 'float': 'left' }} onClick={(e) => this.addUserToShareList()} />
                            </div>
                        </div>
                        {this.state.shareDisplayList.length>0?
                        <div className="p-col-12 p-p-margin-top-20">
                            <OrderList value={this.state.shareDisplayList} dragdrop={true} itemTemplate={this.shareUserItemTemplate}
                                responsive={true} header="List of Shared Users" listStyle={{height: '20em'}}
                                onChange={(e) => this.setState({shareDisplayList: e.value})} />
                        </div>
                        :
                        <div className="p-col-12 p-margin-top-20">
                            <strong>There's no user in shared list</strong>
                        </div>
                        }
                    </div>
                    <div className="p-grid" >
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            <Button label="Save" icon="pi pi-check" onClick={(e) => this.handleShareSettingSave()}/>
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={(e)=>this.setState({shareSideBarVisible: false,shareDisplayList: [],selectedUser:null})}/>
                        </div>
                    </div>
                </Sidebar>

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>Predefine Reports</h1>
                    </div>
                </div>

                <div className="p-col-12">
                    <TabView activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.setState({activeTabIndex: e.index})}>
                        <TabPanel header="List" leftIcon="pi pi-list">
                            <div className="p-col-12">
                                <DataTable lazy={true} paginator={true} value={this.state.dataList} header={tblHeader}
                                    first={this.state.page * this.state.rows}
                                    rows={this.state.rows} totalRecords={this.state.total}
                                    onPage={this.onPage} onSort={this.onSort}
                                    sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                                    responsive={true}>
                                    <Column field="id" header="ID" sortable={true} style={{width:'5em'}}/>
                                    <Column field="name" header="Name" sortable={true}/>
                                    <Column field="type" header="Type" body={(rowData) => rowData.typeName} sortable={true}/>
                                    <Column header="" body={this.actionTemplate} style={{textAlign:'center',width:'13em'}}/>
                                </DataTable>
                            </div>
                        </TabPanel>
                        <TabPanel disabled={!hasRole([ROLES_CONFIG.REPORT_W])} header="Add/Update" leftIcon="pi pi-plus" rightIcon="pi pi-pencil">
                            <div className="p-grid">
                                <div className="p-col-6">
                                    <Fieldset legend="Basic Information">
                                        <div className="p-grid p-fluid form-group">
                                            <div className="p-col-12">
                                                <label className="p-label">* Type</label>
                                                <Dropdown value={this.state.report.type} options={reportTypes} onChange={(e) => this.onTypeChange(e)} style={{width: '100%'}} placeholder="Select Report Type"/>
                                                <span className="p-form-error">{this.state.errors.type}</span>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">* Name</label>
                                                <InputText value={this.state.report.name} onChange={(e) => this.setState({report: { ...this.state.report, name: titleCaseText(e.target.value) }})} />
                                                <span className="p-form-error">{this.state.errors.name}</span>
                                            </div>
                                            {(()=>{
                                                if(this.state.report.type){
                                                    switch(this.state.report.type){
                                                        case reportType.order:
                                                        case reportType.program_participant:
                                                            return(
                                                                <div className="p-col-12">
                                                                    <label className="p-label">* Department</label>
                                                                    <MultiSelect value={this.state.report.departmentIds} options={this.state.departmentList} onChange={(e) => this.setState({report: {...this.state.report, departmentIds: e.value}})} showClear={true}/>
                                                                    <span className="p-form-error">{this.state.errors.departmentIds}</span>
                                                                </div>
                                                            )
                                                        default: break;
                                                    }
                                                }
                                            })()}
                                            <div className="p-col-12">
                                                <label className="p-label">Description</label>
                                                <InputTextarea rows={3} cols={100} value={this.state.report.description} placeholder="Input description here" onChange={(e) => this.setState({report: { ...this.state.report, description: e.target.value}})} />
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>
                                {this.state.report.type &&
                                    <div className="p-col-12 p-md-6">
                                        <Fieldset legend="Parameters">
                                            <div className="p-grid p-fluid form-group">
                                                <div className="p-col-12">
                                                    <span>If you leave blank value for parameters, you will need to provide value later when you run the report</span>
                                                </div>
                                                {(this.state.report.type!==reportType.program_participant && this.state.report.type!==reportType.event_participant) &&
                                                    <React.Fragment>
                                                        <div className="p-col-12 p-md-6">
                                                            <label>From Date</label>
                                                            <Calendar dateFormat="yy-mm-dd" value={this.state.startDateValue} onChange={this.onStartDateChange} showIcon={true}/>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <label>To Date</label>
                                                            <Calendar dateFormat="yy-mm-dd" value={this.state.endDateValue} onChange={this.onEndDateChange} showIcon={true}/>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {(()=> {
                                                    if(this.state.report.type){
                                                        switch(this.state.report.type){
                                                            case reportType.transaction:
                                                                return (
                                                                    <div className="p-col-12">
                                                                        <label className="p-label">Status</label>
                                                                        <MultiSelect value={this.state.reportConfig.paymentStatus} options={lstPaymentStatus} onChange={(e) => this.setState({reportConfig: {...this.state.reportConfig, paymentStatus: e.value}})} style={{width: '100%'}}/>
                                                                    </div>
                                                                )
                                                            case reportType.order:
                                                                return (
                                                                    <React.Fragment>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">Payment State</label>
                                                                            <MultiSelect value={this.state.reportConfig.orderPaymentState} options={lstOrderPaymentStates} onChange={(e) => this.setState({reportConfig: {...this.state.reportConfig, orderPaymentState: e.value}})} style={{width: '100%'}}/>
                                                                        </div>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">Employee</label>
                                                                            <AsyncSelect ref={el => this.select=el}
                                                                                loadOptions={this.loadListUsers} 
                                                                                onChange={(e) => this.getCreatorId(e)}
                                                                                placeholder='Type your search'
                                                                                components={{ Option }}
                                                                                value={this.state.reportConfig.creatorIdValue}
                                                                                isClearable
                                                                            />
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            case reportType.program_participant:
                                                                return (
                                                                    <React.Fragment>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">Program</label>
                                                                            <Dropdown value={this.state.reportConfig.refId} options={this.state.lstPrograms} filter={true} filterBy='label,value' showClear={true} onChange={(e) => this.onProgramChange(e)} style={{width: '100%'}} placeholder="Please Choose"/>
                                                                        </div>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">Campaign</label>
                                                                            <Dropdown value={this.state.reportConfig.campaignId} options={this.state.campaigns} filter={true} filterBy='label,value' showClear={true} onChange={(e) => this.onChangeCampaign(e)} style={{width: "100%"}} />
                                                                        </div>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">Course</label>
                                                                            <MultiSelect value={this.state.reportConfig.childRefIds} options={this.state.lstCourses} filter={true} showClear={true} onChange={(e) => this.setState({reportConfig: {...this.state.reportConfig, childRefIds: e.value}})} style={{width: '100%'}}/>
                                                                        </div>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label"> Status</label>
                                                                            <MultiSelect value={this.state.reportConfig.participantStatus} options={lstParticipantStatus} onChange={(e) => this.setState({reportConfig: {...this.state.reportConfig, participantStatus: e.value}})} style={{width: '100%'}}/>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            case reportType.event_participant:
                                                                return (
                                                                    <React.Fragment>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">* Event</label>
                                                                            <Dropdown value={this.state.reportConfig.refId} options={this.state.events} filter={true} filterBy='label,value' showClear={true} onChange={(e) => this.onEventChange(e)} style={{width: '100%'}} placeholder="Please Choose"/>
                                                                            <div className="p-form-error">{this.state.errors.event}</div>
                                                                        </div>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">Event Session</label>
                                                                            <MultiSelect value={this.state.reportConfig.childRefIds} options={this.state.eventSessions} filter={true} showClear={true} onChange={(e) => this.setState({reportConfig: {...this.state.reportConfig, childRefIds: e.value}})} style={{width: '100%'}}/>
                                                                        </div>
                                                                        <div className="p-col-12">
                                                                            <label className="p-label">Status</label>
                                                                            <MultiSelect value={this.state.reportConfig.participantStatus} options={lstParticipantStatus} onChange={(e) => this.setState({reportConfig: {...this.state.reportConfig, participantStatus: e.value}})} style={{width: '100%'}}/>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            default: break;
                                                        }
                                                    }
                                                })()}
                                            </div>
                                        </Fieldset>
                                    </div>
                                }
                                {this.state.report.type && this.state.report.type!=='gl_account'&&
                                    <div className="p-col-12">
                                        <Fieldset legend="Report columns">
                                            <div className="p-grid p-fluid">
                                                <div className="p-col-12">
                                                    <div className="p-form-error">{this.state.errors.rptColumns}</div>
                                                        <PickList source={this.state.rptSourceColumns} target={this.state.rptTargetColumns} onChange={(e) => this.setState({rptSourceColumns: e.source, rptTargetColumns: e.target})} itemTemplate={this.rptColumnTemplate}
                                                            sourceHeader="Available" targetHeader="Selected" responsive={true}
                                                            sourceStyle={{height: '500px'}} targetStyle={{height: '500px'}}/>
                                                    <div className="p-form-error">{this.state.errors.rptColumns}</div>
                                                </div>
                                            </div>
                                        </Fieldset>
                                    </div>
                                }
                                <div className="p-col-12">
                                    {(!this.state.report.id || (this.state.report.id && this.state.report.createdBy===currentLogUser)) &&
                                        <Button label="Save report" icon="pi pi-save" iconPos="left" onClick={() => this.handleSaveReport()} className="p-button-success"/>
                                    }
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        );
    }
}
