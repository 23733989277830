import {
  COMPLETE_FORM_REQUESTING,
  COMPLETE_FORM_REQUEST_SUCCESS,
  COMPLETE_FORM_REQUEST_FAILED,
  MARK_STEP_DONE_REQUESTING,
  MARK_STEP_DONE_REQUEST_SUCCESS,
  MARK_STEP_DONE_REQUEST_FAILED,
  TOGGLE_WORKFLOW,
  MARK_CHECKPOINT_DONE_REQUESTING,
  MARK_CHECKPOINT_DONE_REQUEST_SUCCESS,
  MARK_CHECKPOINT_DONE_REQUEST_FAILED
} from '../constants/workflow';

const initialState = {
  requesting: false,
  success: false,
  error: null,
  showWorkflow: false,
  formInstanceId: null,
  workflow: [],
  activeStep: 0,
  formData: {},
  finishedCheckPoints: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPLETE_FORM_REQUESTING:
      return {...state,
        requesting: true,
        success: false,
        error: null
      };
    case COMPLETE_FORM_REQUEST_SUCCESS:
      return {...state,
        requesting: false,
        success: true,
        error: null
      };
    case COMPLETE_FORM_REQUEST_FAILED:
      return {...state,
        requesting: false,
        success: false,
        error: action.error
      };
    case MARK_STEP_DONE_REQUESTING:
      return {...state,
        requesting: true,
        success: false,
        error: null
      };
    case MARK_STEP_DONE_REQUEST_SUCCESS:
      return {...state,
        requesting: false,
        success: true,
        error: null,
        activeStep: state.activeStep + 1,
        finishedCheckPoints: []
      };
    case MARK_STEP_DONE_REQUEST_FAILED:
      return {...state,
        requesting: false,
        success: false,
        error: action.error
      };
    case TOGGLE_WORKFLOW:
      if (action.showWorkflow) {
        return {
          ...state,
          showWorkflow: true,
          formInstanceId: action.formInstanceId,
          workflow: action.workflow || [],
          activeStep: action.activeStep || 0,
          formData: action.formData,
          finishedCheckPoints: action.finishedCheckPoints || []
        };
      } else {
        return {
          ...state,
          showWorkflow: false,
          formInstanceId: null,
          workflow: [],
          activeStep: 0,
          finishedCheckPoints: []
        }
      };
    case MARK_CHECKPOINT_DONE_REQUESTING:
      return {...state,
        requesting: true,
        success: false,
        error: null
      };
    case MARK_CHECKPOINT_DONE_REQUEST_SUCCESS:
      return {...state,
        requesting: false,
        success: true,
        error: null
      };
    case MARK_CHECKPOINT_DONE_REQUEST_FAILED:
      return {...state,
        requesting: false,
        success: false,
        error: action.error
      };
    default:
      return state;
  }
};