import axios from "axios";
import { API_BASE_URL, SCP_PATH } from "../../constants";

const SCP_BASE_URL = API_BASE_URL + SCP_PATH

export function getPageDocument(page, size, sortField, sortOrder){
    const order = sortOrder ===1 ? "asc" : sortOrder === -1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${SCP_BASE_URL}/documents/page`,{
        params: {page, size, sort}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function updatePublicDocument(id, isPublic){
    return axios.post(`${SCP_BASE_URL}/documents/${id}/update_status`,null, {params: {isPublic: isPublic}})
    .then(res => res.data).catch(error => console.log(error));
}

export function uploadDocument(data){
    const formData = new FormData();
    // formData.append('id', data.id);
    formData.append('file', data.file);
    // formData.append('isPublic', data.isPublic);
    // formData.append('type', data.type);
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    return axios.post(`${SCP_BASE_URL}/documents/upload`, formData, config)
    .then(res => res.data).catch(error => console.log(error));
}

export function deleteDocument(id){
    return axios.delete(`${SCP_BASE_URL}/documents/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}