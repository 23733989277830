import React, {forwardRef, useState, useImperativeHandle, useContext} from "react";
import moment, { HTML5_FMT } from 'moment';
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { InputNumber } from 'primereact/inputnumber';
import { PRODUCT_CLASSIFICATION, PRODUCT_TYPE, PRICE_APPLY_TYPE, APP_FEP, PRODUCT_PRICE_UNIT, TIME_UNIT_TYPE, DEPOSIT_TYPE } from "../../constants";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton"
import { showErrorNotify, showSuccessNotify } from "../../core/service/NotificationService";
import { Calendar } from "primereact/calendar";
import { getProductBookingInfo, saveCartItem } from "./BookingServices";
import { getCurrencySymbol, moneyFormat, getTimeZone } from "../../core/service/CommonService";
import { ToggleButton } from 'primereact/togglebutton';
import { Divider } from "../../core/components/divider/Divider";
import { OrderContext } from "./OrderContext";
import { Fieldset } from "primereact/fieldset";
import classNames from "classnames";

export const ProductBookingForm = forwardRef((props, ref) => {
    const orderContext = useContext(OrderContext);

    const currency = orderContext.currency
    const currencySymbol = getCurrencySymbol(currency)

    const timezone = getTimeZone()

    const [formHeader] = useState('RESERVATION')
    const [visible, setVisible] = useState(false)
    const mapBranches = orderContext.mapBranches
    const [product, setProduct] = useState({})
    const [mapProductDateAvailabilities, setMapProductDateAvailabilities] = useState({})
    const [mapProductAvailabilities, setMapProductAvailabilities] = useState({})
    const [rentalPriceUnits, setRentalPriceUnits] = useState([])
    const [subscriptionPeriods, setSubscriptionPeriods] = useState([])
    const [bookingData, setBookingData] = useState({})
    const [groupProductsSelection, setGroupProductsSelection] = useState([])
    const [errors, setErrors] = useState({})
    
    useImperativeHandle(ref, () => ({
        openProductBookingForm(orderNumber, productId, orderItemId){
            getProductBookingInfo(productId, orderNumber, orderItemId)
            .then(res => {
                if(!res.errorCode){
                    const productData = res.product
                    const orderItem = res.orderItem?res.orderItem:null
                    const mapAssociatedProductSelected = orderItem&&res.mapAssociatedProductSelected
                    
                    let variantId = orderItem?orderItem.variantId:productData.variants.length>0?productData.variants[0].id:null

                    let rentalPriceUnits = []
                    let defaultRentalQty = 1
                    let defaultRentalPriceUnit = null
                    let subscriptionPeriods = []
                    let defaultSubscriptionPeriod = null

                    let productDateAvailabilities = {}
                    let productAvailabilities = {}

                    let defaultAvailabilityDate = null
                    let defaultAvailabilityId = null

                    if(productData.type===PRODUCT_TYPE.rental.value){
                        if(productData.classification===PRODUCT_CLASSIFICATION.variable.value && productData.pricedByVariant && productData.variants.length>0){
                            rentalPriceUnits = productData.variants[0].rentalPriceUnits
                            subscriptionPeriods = productData.variants[0].prices
                        }else{
                            rentalPriceUnits = productData.rentalPriceUnits
                            subscriptionPeriods = productData.prices
                        }
                        
                        if(orderItem && orderItem.rentalInfo){
                            defaultRentalQty = orderItem.rentalInfo.rentalQty
                            defaultRentalPriceUnit = orderItem.rentalInfo.rentalUnit
                        }else{
                            const rentalLimit = productData.rentalLimit;

                            defaultRentalQty = rentalLimit&&rentalLimit.minQty?rentalLimit.minQty:1
                            defaultRentalPriceUnit = rentalLimit&&rentalLimit.minQty?rentalLimit.minUnit:rentalPriceUnits[0]
                        }

                        if(productData.subscription){
                            if(subscriptionPeriods.length>0){
                                defaultSubscriptionPeriod = orderItem?subscriptionPeriods.find(r => r.applyFor===PRICE_APPLY_TYPE.normal.value && r.quantity===orderItem.rentalInfo.rentalQty && r.unit===orderItem.rentalInfo.rentalUnit):subscriptionPeriods[0]
                                if(!defaultSubscriptionPeriod)
                                    defaultSubscriptionPeriod = subscriptionPeriods[0]
                            }
                        }
                    }else if([PRODUCT_TYPE.program.value, PRODUCT_TYPE.session.value].includes(productData.type)){
                        productDateAvailabilities[productData.id] = getMapProductAvailabilities(productData)

                        if(orderItem){
                            const scheduleStart = moment(orderItem.scheduleInfo.start).format('MM/DD/YYYY')
                            const scheduleEnd = moment(orderItem.scheduleInfo.end).format('MM/DD/YYYY')

                            // defaultAvailabilityDate = scheduleStart!==scheduleEnd?(scheduleStart + ' - ' + scheduleEnd):scheduleStart
                            defaultAvailabilityDate = scheduleStart
                        }else{
                            if(productData.branchId){
                                defaultAvailabilityDate = Object.keys(productDateAvailabilities[productData.id]).length>0?Object.keys(productDateAvailabilities[productData.id])[0]:null
                            }
                        }

                        const lstAvailabilities = productDateAvailabilities[productData.id][defaultAvailabilityDate]
                        productAvailabilities[productData.id] = lstAvailabilities?lstAvailabilities:[]
                        defaultAvailabilityId = orderItem?orderItem.productAvailabilityId:lstAvailabilities?lstAvailabilities[0].value:null
                    }

                    let lstGroupProductsSelection = []
                    if(productData && productData.associatedGroups){
                        for (const group of productData.associatedGroups) {
                            let lstSelectedProduct = []

                            if(group.associatedProducts && group.associatedProducts.length > 0){
                                for (const associatedProduct of group.associatedProducts) {
                                    const tmpProduct = associatedProduct.associatedProduct;

                                    const selectedProductItemInfo = mapAssociatedProductSelected&&mapAssociatedProductSelected[tmpProduct.id]

                                    let childAvailabilityDate = null
                                    let childAvailabilityId = null
                                    
                                    if([PRODUCT_TYPE.program.value, PRODUCT_TYPE.session.value].includes(tmpProduct.type)){
                                        productDateAvailabilities[tmpProduct.id] = getMapProductAvailabilities(tmpProduct)
                
                                        if(selectedProductItemInfo){
                                            const scheduleStart = moment(selectedProductItemInfo.scheduleInfo.start).format('MM/DD/YYYY')
                                            const scheduleEnd = moment(selectedProductItemInfo.scheduleInfo.end).format('MM/DD/YYYY')
                
                                            // childAvailabilityDate = scheduleStart!==scheduleEnd?(scheduleStart + ' - ' + scheduleEnd):scheduleStart
                                            childAvailabilityDate = scheduleStart
                                        }else{
                                            childAvailabilityDate = Object.keys(productDateAvailabilities[tmpProduct.id]).length>0?Object.keys(productDateAvailabilities[tmpProduct.id])[0]:null
                                        }
                
                                        const lstAvailabilities = productDateAvailabilities[tmpProduct.id][childAvailabilityDate]
                                        productAvailabilities[tmpProduct.id] = lstAvailabilities?lstAvailabilities:[]
                                        childAvailabilityId = selectedProductItemInfo?selectedProductItemInfo.productAvailabilityId:lstAvailabilities?lstAvailabilities[0].value:null
                                    }

                                    const productItem = {
                                        id: selectedProductItemInfo?selectedProductItemInfo.productId:((group.mandatory && !group.multiSelect && group.associatedProducts.length===1) || associatedProduct.included)?tmpProduct.id:'',
                                        classification: tmpProduct.classification,
                                        type: tmpProduct.type,
                                        variantId: selectedProductItemInfo?selectedProductItemInfo.variantId:tmpProduct.variants.length>0?tmpProduct.variants[0].id:'',
                                        // optValues: selectedProductItemInfo?selectedProductItemInfo.optValues:(tmpProduct.classification===PRODUCT_CLASSIFICATION.variable.value && tmpProduct.variantSelectionType===0?new Array(tmpProduct.options.length):new Array(tmpProduct.options.length)),
                                        quantity: selectedProductItemInfo?selectedProductItemInfo.quantity:(associatedProduct.included || (!associatedProduct.included && !associatedProduct.allowInputQty))?associatedProduct.quantity:1,
                                        availabilityDate: childAvailabilityDate?moment(childAvailabilityDate).toDate():null,
                                        availabilityId: childAvailabilityId
                                    };

                                    lstSelectedProduct.push(productItem)
                                }
                                
                                lstGroupProductsSelection.push({id: group.id, associatedProducts: lstSelectedProduct})
                            }
                        }
                    }

                    setBookingData({
                        app: APP_FEP,
                        productId: productId,
                        orderNumber: orderNumber,
                        orderItemId: orderItemId,
                        variantId: variantId,
                        quantity: orderItem?orderItem.quantity:1,
                        availabilityDate: defaultAvailabilityDate?moment(defaultAvailabilityDate).toDate():null,
                        availabilityId: defaultAvailabilityId,
                        rentalPriceUnit: defaultRentalPriceUnit,
                        rentalQty: defaultRentalQty,
                        subscription: productData.subscription,
                        subscriptionPeriod: defaultSubscriptionPeriod,
                        startDateValue: orderItem&&orderItem.scheduleInfo&&orderItem.scheduleInfo.start?moment(orderItem.scheduleInfo.start).toDate():null,
                        startTimeValue: orderItem&&orderItem.scheduleInfo&&orderItem.scheduleInfo.start?moment(orderItem.scheduleInfo.start).toDate():null,
                        startTime: orderItem&&orderItem.scheduleInfo&&orderItem.scheduleInfo.start?moment(orderItem.scheduleInfo.start).format('HH:mm'):null,
                        isDeposit: orderItem?orderItem.deposit:(productData.allowDeposit&&!productData.allowPayFull?true:false)
                    })
                    
                    setProduct(productData)
                    setMapProductDateAvailabilities(productDateAvailabilities)
                    setMapProductAvailabilities(productAvailabilities)
                    setRentalPriceUnits(rentalPriceUnits)
                    setSubscriptionPeriods(subscriptionPeriods)
                    setGroupProductsSelection(lstGroupProductsSelection)
                    setVisible(true)
                }else{
                    showErrorNotify(res.errorMessage, 'Error Message');
                }
            });
        }
    }))

    const closeDialog = () => {
        setVisible(false)
        setProduct({})
        setMapProductDateAvailabilities({})
        setMapProductAvailabilities({})
        setRentalPriceUnits([])
        setSubscriptionPeriods([])
        setGroupProductsSelection([])
        setErrors({})
    }

    const getMapProductAvailabilities = (prod) => {
        let mapAvailabilities = {}

        if(prod.availabilities.length>0){
            for(const e of prod.availabilities){
                let isRangeDate = e.startDate!==e.endDate
                let tmpStartDate = moment(e.startDate).format('MM/DD/YYYY')
                let tmpEndDate = moment(e.endDate).format('MM/DD/YYYY')
                let tmpStartTime = moment(e.startTime, HTML5_FMT.TIME).format('hh:mm A')
                let tmpEndTime = moment(e.endTime, HTML5_FMT.TIME).format('hh:mm A')

                const strDate = e.startDate!==e.endDate
                    ?(tmpStartDate + ' - ' + tmpEndDate)
                    :tmpStartDate
                
                let availabilityItem = {value: e.id, startDate: e.startDate, endDate: e.endDate, startTime: e.startTime, endTime: e.endTime}
                if(isRangeDate){
                    availabilityItem.label = (tmpStartDate+' '+tmpStartTime) + ' - ' + (tmpEndDate+' '+tmpEndTime)
                }else{
                    availabilityItem.label = tmpStartTime + ' - ' + tmpEndTime
                }

                if(mapAvailabilities[strDate]){
                    mapAvailabilities[strDate].push(availabilityItem)
                }else{
                    mapAvailabilities[strDate]=[availabilityItem]
                }
            }
        }

        return mapAvailabilities
    }

    const onProductVariantChange = (e) => {
        let tmpBookingData = {...bookingData}
        tmpBookingData.variantId = e.value
        
        if(product.type===PRODUCT_TYPE.rental.value){
            const selectedVariant = product.variants.find(v => v.id===e.value);

            if(selectedVariant){
                tmpBookingData.rentalPriceUnit = selectedVariant.rentalPriceUnits[0]
                setRentalPriceUnits(selectedVariant.rentalPriceUnits)
            }
        }

        setBookingData(tmpBookingData)
    }

    const renderAvailabilityDateOptions = (data) => {
        return (
            data?Object.keys(data).map(e => ({value: e, label: e})):[]
        )
    }

    const onAvailabilityDateChange = (prodId, value) => {
        let valueFmt = value?moment(value).format('MM/DD/YYYY'):null

        // const availabilities = value?mapProductDateAvailabilities[prodId][value]:[]
        let availabilities = getListAvailabilityBySelectedDate(prodId, valueFmt)

        setBookingData({
            ...bookingData, 
            availabilityDate: value,
            availabilityId: availabilities.length===1?availabilities[0].value:null
        })

        // setMapProductAvailabilities({
        //     ...mapProductAvailabilities,
        //     [prodId] : availabilities
        // })
    }

    // const renderAvailabilityOptions = (options) => {
    //     return (
    //         options && options.length>0
    //             ?options.map(e => ({
    //                 value: e.id, 
    //                 label: e.startDate!==e.endDate
    //                     ?(moment(e.startDate).format('MM/DD/YYYY') + ' ' + moment(e.startTime, HTML5_FMT.TIME).format('hh:mm A') + ' - ' + moment(e.endDate).format('MM/DD/YYYY') + ' ' + moment(e.endTime, HTML5_FMT.TIME).format('hh:mm A'))
    //                     :(moment(e.startDate).format('MM/DD/YYYY') + ', ' + moment(e.startTime, HTML5_FMT.TIME).format('hh:mm A') + ' - ' + moment(e.endTime, HTML5_FMT.TIME).format('hh:mm A'))
    //                 }))
    //             :[]
    //     )
    // }

    const onAssociationFieldChange = (gIdx, idx, key, eventValue) => {
        let tmpSelecteds = [...groupProductsSelection];
        
        switch(key){
            case 'id':
                const tmpGroup = product.associatedGroups[gIdx]

                if(tmpGroup.multiSelect){
                    tmpSelecteds[gIdx].associatedProducts[idx][key] = eventValue.checked?eventValue.value:'';
                }else{
                    tmpSelecteds[gIdx].associatedProducts.map(o => o.id=null);
                    tmpSelecteds[gIdx].associatedProducts[idx][key] = eventValue;
                }
                break
            case 'availabilityDate':
                const prodId = tmpSelecteds[gIdx].associatedProducts[idx].id
                let valueFmt = eventValue?moment(eventValue).format('MM/DD/YYYY'):null
                // const availabilities = eventValue?mapProductDateAvailabilities[prodId][eventValue]:[]
                const availabilities = getListAvailabilityBySelectedDate(prodId, valueFmt)
                tmpSelecteds[gIdx].associatedProducts[idx][key] = eventValue;
                tmpSelecteds[gIdx].associatedProducts[idx].availabilityId = availabilities.length===1?availabilities[0].value:null

                // setMapProductAvailabilities({
                //     ...mapProductAvailabilities,
                //     [prodId] : availabilities
                // })

                break
            default: 
                tmpSelecteds[gIdx].associatedProducts[idx][key] = eventValue;
                break
        }

        setGroupProductsSelection(tmpSelecteds);
    }

    const handleSaveBooking = () => {
        let frmData = {...bookingData};

        if(product.type===PRODUCT_TYPE.rental.value){
            let isStartDateValid = frmData.startDateValue && moment(frmData.startDateValue).isValid();
            if(isStartDateValid){
                frmData.startDate = moment(frmData.startDateValue).format(HTML5_FMT.DATE)
            }else{
                frmData.startDate = ''
                frmData.startTime = ''
            }
        }

        frmData.groupProducts = groupProductsSelection

        setErrors({})
        
        saveCartItem(frmData)
        .then(res => {
            if(!res.errorCode){
                const order = res.order

                if(props.onSaveSuccess){
                    props.onSaveSuccess(order);
                }

                // let isRequiredPart = false;
                // const orderItem = res.orderItem

                // if(!frmData.orderItemId){
                //     if(!bookingData.orderItemId){
                //         let tmpProduct = orderItem.product
                //         if(tmpProduct.type === PRODUCT_TYPE.program.value){
                //             isRequiredPart = true
                //         }
                //         if(orderItem.childItems){
                //             for (const childItem of orderItem.childItems) {
                //                 let tmpChildProduct = childItem.product
                //                 if(tmpChildProduct.type === PRODUCT_TYPE.program.value){
                //                     isRequiredPart = true
                //                 }
                //             }
                //         }
                //     }
                // }
                showSuccessNotify(frmData.orderItemId?'Order has been updated!':'Product has been added to order')
                closeDialog()
                // if(isRequiredPart){
                //     participantBookingForm.current.openForm(orderItem)
                // }else{
                //     showSuccessNotify('Product has been added to cart!');
                // }
            }else{
                showErrorNotify(res.errorMessage);
                if(res.errorCode===400) setErrors(res.errorObj?res.errorObj:{});
            }
        })
    }

    const renderPriceByType = (priceItem, productType) => {
        let strPrice = currencySymbol + priceItem.price
        if(productType===PRODUCT_TYPE.rental.value){
            strPrice += '/'
            if(priceItem.quantity>1)
                strPrice += priceItem.quantity + ' '
            strPrice += PRODUCT_PRICE_UNIT[priceItem.unit].label + (priceItem.quantity>1?'s':'')
        }
        return strPrice
    }

    const getListAvailabilityBySelectedDate = (productId, strSelectedDate) =>{
        let tmpArr = []
        let tmpProductAvailabilyMap = mapProductDateAvailabilities[productId]

        if(tmpProductAvailabilyMap){
            if(strSelectedDate){
                let selectedDate = moment(strSelectedDate)
                let tmpAvalabilityKeyList = Object.keys(tmpProductAvailabilyMap)
                
                let isContainRangeDateInList = false

                for (const availabilityDateKey of tmpAvalabilityKeyList) {
                    let tmpDateArr = availabilityDateKey.split('-')
                    if(tmpDateArr.length===1){
                        let tmpStart = moment(tmpDateArr[0])
                        if(selectedDate.isSame(tmpStart)){
                            let tmpData = tmpProductAvailabilyMap[availabilityDateKey]?tmpProductAvailabilyMap[availabilityDateKey]:[]
                            for (const timeItem of tmpData) {
                                if(timeItem.startDate!==timeItem.endDate){
                                    isContainRangeDateInList = true
                                }
                            }
                            tmpArr.push(...tmpData)
                        }
                    }else{
                        let tmpStart = moment(tmpDateArr[0])
                        let tmpEnd = tmpDateArr.length===2?moment(tmpDateArr[1]):null
                        
                        if(selectedDate.isSameOrAfter(tmpStart) && (!tmpEnd || (tmpEnd && selectedDate.isSameOrBefore(tmpEnd)))){
                            let tmpData = tmpProductAvailabilyMap[availabilityDateKey]?tmpProductAvailabilyMap[availabilityDateKey]:[]
                            for (const timeItem of tmpData) {
                                if(timeItem.startDate!==timeItem.endDate){
                                    isContainRangeDateInList = true
                                }
                            }
                            tmpArr.push(...tmpData)
                        }
                    }
                }
                if(isContainRangeDateInList){
                    tmpArr.map(timeItem => {
                        let tmpStartDate = moment(timeItem.startDate).format('MM/DD/YYYY')
                        let tmpEndDate = moment(timeItem.endDate).format('MM/DD/YYYY')
                        let tmpStartTime = moment(timeItem.startTime, HTML5_FMT.TIME).format('hh:mm A')
                        let tmpEndTime = moment(timeItem.endTime, HTML5_FMT.TIME).format('hh:mm A')
                        if(tmpStartDate!==tmpEndDate){
                            timeItem.label = (tmpStartDate+' '+tmpStartTime) + ' - ' + (tmpEndDate+' '+tmpEndTime)
                        }else{
                            timeItem.label = tmpStartDate+ ' ' + (tmpStartTime+' - '+tmpEndTime)
                        }
                    })
                }
            }
        }
        return tmpArr
    }

    const getListDateDisabledByDateOff = (product) => {
        let dayOffs = localStorage.getItem('dayOffs') && localStorage.getItem('dayOffs')!=='undefined' && localStorage.getItem('dayOffs')!=='null'
            ? JSON.parse(localStorage.getItem('dayOffs'))
            :[]
        
        let disabledDates = []
        switch(product.type){
            case PRODUCT_TYPE.program.value: break;
            case PRODUCT_TYPE.rental.value: 
                if(product.branchId){
                    let strNow = moment(new Date()).format('MM/DD/YYYY')
                    let listTimeOperatingHours = getListTimeBlockBySelectedDate(product.branchId, strNow)
                    if(listTimeOperatingHours.length === 0){
                        disabledDates.push(new Date())
                    }   
                }
            break;
            default: break;
        }
        
        for (const dayOffItem of dayOffs) {
            let tmpStartDate = moment(dayOffItem.start)
            let tmpEndDate = moment(dayOffItem.end)

            let duration = moment.duration(tmpEndDate.diff(tmpStartDate))
            let days = duration.days()?duration.days():0

            disabledDates.push(tmpStartDate.toDate())

            for(let i=1; i<=days; i++){
                let tmpDate = moment(dayOffItem.start)
                disabledDates.push(tmpDate.add(i, 'd').toDate())
            }   
        }
        return disabledDates
    }

    const getListDayOfWeekDisabledByOperatingHours = (branchId) => {
        let branchHours = localStorage.getItem('branchHours') && localStorage.getItem('branchHours')!=='undefined' && localStorage.getItem('branchHours')!=='null'
        ? JSON.parse(localStorage.getItem('branchHours'))
        :null
        let operatingHours = branchHours&&branchHours[branchId]&&branchHours[branchId].operatingHours ? branchHours[branchId].operatingHours : {}

        let tmpDayDisableList = []
        if(!operatingHours['sun'] || operatingHours['sun'].length===0){
            tmpDayDisableList.push(0)
        }
        if(!operatingHours['mon'] || operatingHours['mon'].length===0){
            tmpDayDisableList.push(1)
        }
        if(!operatingHours['tue'] || operatingHours['tue'].length===0){
            tmpDayDisableList.push(2)
        }
        if(!operatingHours['wed'] || operatingHours['wed'].length===0){
            tmpDayDisableList.push(3)
        }
        if(!operatingHours['thu'] || operatingHours['thu'].length===0){
            tmpDayDisableList.push(4)
        }
        if(!operatingHours['fri'] || operatingHours['fri'].length===0){
            tmpDayDisableList.push(5)
        }
        if(!operatingHours['sat'] || operatingHours['sat'].length===0){
            tmpDayDisableList.push(6)
        }
        return tmpDayDisableList
    }

    const getListTimeBlockBySelectedDate = (branchId, strSelectedDate) => {
        let timeBlockList = []

        if(branchId && strSelectedDate){
            let selectedDate = moment(strSelectedDate)

            let branchHours = localStorage.getItem('branchHours') && localStorage.getItem('branchHours')!=='undefined' && localStorage.getItem('branchHours')!=='null'
                ? JSON.parse(localStorage.getItem('branchHours'))
                :null
            let timeBlockUnit = localStorage.getItem('timeBlockUnit') && localStorage.getItem('timeBlockUnit')!=='undefined' && localStorage.getItem('timeBlockUnit')!=='null'
                ? localStorage.getItem('timeBlockUnit')
                :null
            let timeBlockValue = localStorage.getItem('timeBlockValue') && localStorage.getItem('timeBlockValue')!=='undefined' && localStorage.getItem('timeBlockValue')!=='null'
                ? parseInt(localStorage.getItem('timeBlockValue'))
                :null

            if(timeBlockUnit && (timeBlockValue || timeBlockValue===0)){
                let operatingHours = branchHours&&branchHours[branchId]&&branchHours[branchId].operatingHours ? branchHours[branchId].operatingHours : {}
                let timeListByDay = []

                let dayOfWeekByDate = selectedDate.day();
                switch (dayOfWeekByDate) {
                    case 0:
                        timeListByDay = operatingHours['sun']
                        break;
                    case 1:
                        timeListByDay = operatingHours['mon']
                        break;
                    case 2:
                        timeListByDay = operatingHours['tue']
                        break;
                    case 3:
                        timeListByDay = operatingHours['wed']
                        break;
                    case 4:
                        timeListByDay = operatingHours['thu']
                        break;
                    case 5:
                        timeListByDay = operatingHours['fri']
                        break;
                    case 6:
                        timeListByDay = operatingHours['sat']
                        break;
                    default:break;
                }
                if(timeListByDay){
                    for (const timeItem of timeListByDay) {
                        let strNow = timezone?moment().tz(timezone).format('YYYY-MM-DD HH:mm'):moment().format('YYYY-MM-DD HH:mm')
                        
                        let nowDate = moment(moment(strNow).format('MM/DD/YYYY'))
                        let nowTime = moment(moment(strNow).format('HH:mm'), moment.HTML5_FMT.TIME)

                        let tmpStartTime = moment(timeItem.s, moment.HTML5_FMT.TIME)
                        let tmpEndTime = moment(timeItem.e, moment.HTML5_FMT.TIME)

                        let tmpArr = []
                        let tmpTimeBlock = tmpStartTime
                        if(timeBlockValue!==0){
                            do{
                                if(tmpArr.length === 0){
                                    if(nowDate.isSame(selectedDate)){
                                        if(tmpTimeBlock.isBefore(tmpEndTime) && tmpTimeBlock.isSameOrAfter(nowTime)){
                                            tmpArr.push(tmpTimeBlock.format('HH:mm'))
                                        }
                                    }else{
                                        tmpArr.push(tmpTimeBlock.format('HH:mm'))
                                    }
                                }

                                if(timeBlockUnit === TIME_UNIT_TYPE.min.value){
                                    tmpTimeBlock = tmpTimeBlock.add(timeBlockValue, 'm')
                                }else if(timeBlockUnit === TIME_UNIT_TYPE.hr.value){
                                    tmpTimeBlock = tmpTimeBlock.add(timeBlockValue, 'h')
                                }

                                let tmpTimeBlockWithQty

                                if(product.type===PRODUCT_TYPE.rental.value){
                                    let tmpRentalQty = bookingData.rentalQty
                                    tmpTimeBlockWithQty = moment(tmpTimeBlock.format('HH:mm'), moment.HTML5_FMT.TIME)

                                    if(product.subscription){
                                        if(bookingData.subscriptionPeriod){
                                            switch(bookingData.subscriptionPeriod.unit){
                                                case PRODUCT_PRICE_UNIT.min.value:
                                                    tmpTimeBlockWithQty = tmpTimeBlockWithQty.add(tmpRentalQty, 'm')
                                                break;
                                                case PRODUCT_PRICE_UNIT.hr.value:
                                                    tmpTimeBlockWithQty = tmpTimeBlockWithQty.add(tmpRentalQty, 'h')
                                                break;
                                                default: break;
                                            }    
                                        }
                                    }else{
                                        if(bookingData.rentalPriceUnit){
                                            switch(bookingData.rentalPriceUnit){
                                                case PRODUCT_PRICE_UNIT.min.value:
                                                    tmpTimeBlockWithQty = tmpTimeBlockWithQty.add(tmpRentalQty, 'm')
                                                break;
                                                case PRODUCT_PRICE_UNIT.hr.value:
                                                    tmpTimeBlockWithQty = tmpTimeBlockWithQty.add(tmpRentalQty, 'h')
                                                break;
                                                default: break;
                                            }    
                                        }
                                    }
                                }
                                
                                if(tmpTimeBlock.isBefore(tmpEndTime) && (product.type!==PRODUCT_TYPE.rental.value || (product.type===PRODUCT_TYPE.rental.value && tmpTimeBlockWithQty && tmpTimeBlockWithQty.isSameOrBefore(tmpEndTime)))){
                                    if(nowDate.isSame(selectedDate)){
                                        if(tmpTimeBlock.isBefore(tmpEndTime) && tmpTimeBlock.isSameOrAfter(nowTime)){
                                            tmpArr.push(tmpTimeBlock.format('HH:mm'))
                                        }
                                    }else if(tmpTimeBlock.isBefore(tmpEndTime)){
                                        tmpArr.push(tmpTimeBlock.format('HH:mm'))
                                    }
                                }else{
                                    // tmpArr.push(tmpEndTime.format('HH:mm'))
                                }
                            }while(tmpTimeBlock.isBefore(tmpEndTime))
                            timeBlockList.push(...tmpArr)   
                        }else{
                            if(nowDate.isSame(selectedDate)){
                                if(tmpStartTime.isSameOrAfter(nowTime)){
                                    timeBlockList.push(timeItem.s)
                                }
                            }else{
                                timeBlockList.push(timeItem.s)
                            }
                        }
                    }
                }    
            }
        }
        return timeBlockList
    }

    const renderTimeBlockBySelectedDate = (branchId, selectedDate) => {
        if(branchId){
            let selectedDateFmt = selectedDate?moment(selectedDate).format('MM/DD/YYYY'):null
            let timeList = getListTimeBlockBySelectedDate(branchId, selectedDateFmt)
            let html = []
            for (const timeValue of timeList) {
                let timeValueFmt = moment(timeValue, moment.HTML5_FMT.TIME).format('hh:mm A')
                html.push(<ToggleButton className="p-mr-1 p-mb-1 p-button-secondary btn-text-sm" onLabel={timeValueFmt} onIcon="pi pi-check" offLabel={timeValueFmt} offIcon="pi pi-times" checked={bookingData.startTime===timeValue} onChange={(e) => setBookingData({...bookingData, startTime: timeValue})} style={{width: 'auto'}} />)
            }
            if(selectedDate && timeList.length===0){
                return <div className="p-form-error">No operating hours with select</div>
            }
            return html    
        }
    }

    const onRentalQtyChange = (val) => {
        setBookingData({...bookingData, rentalQty: val, startTime:null})
    }
    
    return(
        <React.Fragment>
            {/* <ParticipantBookingForm ref={participantBookingForm} /> */}

            <Sidebar visible={visible} className="p-sidebar-md" position="right" blockScroll={true} dismissable={true} showCloseIcon={false} onHide={() => closeDialog()} style={{overflowY: 'auto'}} >
                <div className="p-d-flex p-justify-between ">
                    <h2 className="p-margin-top-10">{formHeader}</h2>
                    <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={() => closeDialog()}/>
                </div>

                <div className="p-sidebar-line p-mb-3"></div>
                
                <div className="p-grid">
                    <div className="p-col-12 p-size-16">
                        <p><span style={{display: 'inline-block', width: '100px'}}>Product: </span><strong>{product.name}</strong></p>

                        {(([PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(product.classification) && !product.pricedBySelected) || 
                                (product.classification===PRODUCT_CLASSIFICATION.variable.value && !product.pricedByVariant) || product.classification===PRODUCT_CLASSIFICATION.simple.value) && 
                        <p>
                            <span style={{display: 'inline-block', width: '100px'}}>Price: </span>
                            <strong>
                            {!product.free?
                                product.prices.map((p, pIdx) => {
                                    return (
                                        p.applyFor===PRICE_APPLY_TYPE.normal.value && 
                                        <span className="p-mr-2">{renderPriceByType(p, product.type)}{pIdx+1<product.prices.length?',':''}</span>
                                    )
                                })
                            :
                            'Free'
                            }
                            </strong>
                        </p>
                        }

                        {mapBranches && Object.keys(mapBranches).length>1 &&
                        <p><span style={{display: 'inline-block', width: '100px'}}>Branch: </span><strong>{product.branchName}</strong></p>
                        }
                    </div>
                </div>

                <div className="p-sidebar-line"></div>

                {/* {product.branches && product.branches.length>1 && 
                <div className="p-grid">
                    <div className="p-col-12 p-pb-0">
                        <Divider align="left" type="dashed">
                            <div className="p-d-inline-flex p-ai-center">
                                <b>* Choose a branch</b>
                            </div>
                        </Divider>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <Dropdown value={bookingData.branchId} options={product.branches} onChange={(e) => setBookingData({...bookingData, branchId: e.value})} placeholder="Pick a branch" />
                                <div className="p-form-error">{errors.branch}</div>
                            </div>
                        </div>
                    </div>
                </div>
                } */}

                {[PRODUCT_CLASSIFICATION.simple.value, PRODUCT_CLASSIFICATION.variable.value].includes(product.classification) &&
                <React.Fragment>
                    {product.classification===PRODUCT_CLASSIFICATION.variable.value && product.variants.length>0 &&
                    <div className="p-grid">
                        <div className="p-col-12 p-pb-0">
                            <Divider align="left" type="dashed" className="p-mt-3 p-mb-2">
                                <strong><b>* Choose a variable</b></strong>
                            </Divider>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    {product.variants.map((v, i) => {
                                        return (
                                            <div key={i} className="p-d-flex p-flex-wrap p-ai-center p-mb-2">
                                                <RadioButton inputId={"rbVariant_"+i} className="p-mr-1" value={v.id} onChange={(e) => onProductVariantChange(e)} checked={bookingData.variantId===v.id} />
                                                <label htmlFor={"rbVariant_"+i} className="p-radiobutton-label p-ml-3">
                                                    {v.variantName}
                                                    {!product.free && v.prices.map((p, pIdx) => {
                                                        return (
                                                            p.applyFor===PRICE_APPLY_TYPE.normal.value && 
                                                            <span className="p-tag p-tag-warning p-tag-rounded p-ml-3 p-size-14">{renderPriceByType(p, product.type)}</span>
                                                        )
                                                    })}
                                                </label>
                                            </div>
                                        )
                                    })}
                                    <div className="p-form-error">{errors.variant}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    <div className="p-grid">
                        <div className="p-col-12 p-pb-0">
                            <Divider align="left" type="dashed" className="p-mt-3 p-mb-2">
                                <strong>* How many do you want to {product.type===PRODUCT_TYPE.rental.value?'rent':'buy'}</strong>
                            </Divider>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-md-6">
                                    <InputNumber value={bookingData.quantity} onChange={(e) => setBookingData({...bookingData, quantity: e.value})} min={1} showButtons readonly={true} buttonLayout="horizontal" inputClassName="p-c p-size-18 p-w-bold disabled" decrementButtonClassName="p-button-danger btn-sm" incrementButtonClassName="p-button-success btn-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
                                    <div className="p-form-error">{errors.quantity}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {[PRODUCT_TYPE.program.value, PRODUCT_TYPE.session.value].includes(product.type) && 
                    <div className="p-grid">
                        <div className="p-col-12 p-pb-0">
                            <Divider align="left" type="dashed" className="p-mt-3 p-mb-2">
                                <strong>* Pick a schedule</strong>
                            </Divider>
                            <div className="p-grid p-fluid">
                                <div className="p-col-12 p-pb-0">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* Date:</label>
                                            <Calendar value={bookingData.availabilityDate} showIcon minDate={new Date()} onChange={(e) => onAvailabilityDateChange(product.id, e.value)} dateFormat="mm/dd/yy" mask="99/99/9999" placeholder="mm/dd/yyyy" tooltip="date format is 'mm/dd/yyyy'" tooltipOptions={{position: 'top'}} />
                                            <div className="p-form-error p-pt-1">{errors.availability && !bookingData.availabilityDate ? errors.availability : ''}</div>
                                        </div>
                                        {bookingData.availabilityDate &&
                                            <div className="p-col-12 p-pb-0">
                                                {(()=>{
                                                    let selectedDateFmt = bookingData.availabilityDate?moment(bookingData.availabilityDate).format('MM/DD/YYYY'):null
                                                    let tmpAvalabilities = getListAvailabilityBySelectedDate(product.id, selectedDateFmt)
                                                    let html = []
                                                    for (const availabilityItem of tmpAvalabilities) {
                                                        html.push(<ToggleButton className="p-mr-1 p-mb-1 p-button-secondary btn-text-sm" onLabel={availabilityItem.label} onIcon="pi pi-check" offLabel={availabilityItem.label} offIcon="pi pi-times" checked={bookingData.availabilityId===availabilityItem.value} onChange={(e) => setBookingData({...bookingData, availabilityId: availabilityItem.value})} style={{width: 'auto'}} />)
                                                    }
                                                    if(tmpAvalabilities.length===0){
                                                        return <div className="p-form-error">There's no activity on selected day!</div>
                                                    }else{
                                                        return <React.Fragment>
                                                            <label className="p-label p-mb-2">* Time:</label>
                                                            <div>{html}</div>
                                                            <div className="p-form-error p-pt-1">{errors.availability}</div>
                                                        </React.Fragment>
                                                    }
                                                })()}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {product.type===PRODUCT_TYPE.rental.value && 
                    <div className="p-grid">
                        <div className="p-col-12 p-pb-0">
                            <Divider align="left" type="dashed" className="p-mt-3 p-mb-2">
                                <strong>* {product.subscription?'How do you want to make subscription':'How long do you want to rent?'}</strong>
                            </Divider>
                            <div className="p-grid p-fluid">
                                {!product.subscription?
                                <React.Fragment>
                                    {product.rentalLimit && product.rentalLimit.minQty &&
                                    <div className="p-col-12">
                                        <span className="p-text-italic"><strong>Note: </strong>Minimal require is {product.rentalLimit.minQty} {PRODUCT_PRICE_UNIT[product.rentalLimit.minUnit].label}{product.rentalLimit.minQty>1?'s':''}</span>
                                    </div>
                                    }

                                    {rentalPriceUnits && rentalPriceUnits.length>0 && 
                                    <div className="p-col-12 p-md-8">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"><strong>Rent in:</strong></span>
                                            <InputText value={bookingData.rentalQty} keyfilter="int" onChange={(e) => onRentalQtyChange(e.target.value)} placeholder="unit q.ty"/>
                                            {rentalPriceUnits.length>1?
                                                rentalPriceUnits.map((el,i) => {
                                                    return (
                                                        <span className="p-inputgroup-addon p-mr-2">
                                                            <RadioButton inputId={"rbPriceUnit"+i} name="productPriceUnit" value={el} onChange={(e) => setBookingData({...bookingData, rentalPriceUnit: e.value})} checked={bookingData.rentalPriceUnit===el} />
                                                            <label htmlFor={"rbPriceUnit"+i} className="p-ml-1"><strong>{PRODUCT_PRICE_UNIT[el].label}</strong></label>
                                                        </span>
                                                    )
                                                })
                                            :
                                            <span className="p-inputgroup-addon">
                                                <strong>{PRODUCT_PRICE_UNIT[rentalPriceUnits[0]].label}</strong>
                                            </span>
                                            }
                                        </div>
                                        <div className="p-form-error">{errors.rentalQty}</div>
                                        <div className="p-form-error">{errors.rentalPriceUnit}</div>
                                    </div>
                                    }   
                                </React.Fragment>
                                :
                                subscriptionPeriods.length>0 && 
                                <React.Fragment>
                                    <div className="p-col-12">
                                        <span className="p-text-italic"><strong>Note: </strong>Product will be booked as subscription</span>
                                    </div>

                                    <div className="p-col-12">
                                        <label>Subscription every: </label>
                                        {subscriptionPeriods.length>0 && subscriptionPeriods.map((el, i) => {
                                            return (
                                                <React.Fragment>
                                                    {subscriptionPeriods.length>1 && 
                                                    <RadioButton inputId={"rbSubscriptionPeriod"+i} name="subscriptionPeriod" className="p-ml-4 p-mr-1" value={el} onChange={(e) => setBookingData({...bookingData, subscriptionPeriod: e.value})} checked={bookingData.subscriptionPeriod===el} />
                                                    }
                                                    <label htmlFor={"rbSubscriptionPeriod"+i}><strong>{el.quantity} {PRODUCT_PRICE_UNIT[el.unit].label}{el.quantity>1&&'s'}</strong></label>
                                                </React.Fragment>
                                            )
                                        })}
                                        <div className="p-form-error">{errors.subscriptionPeriod}</div>
                                    </div>
                                </React.Fragment>
                                }
                                
                                <div className="p-col-12 p-pb-0">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <label className="p-label">* Start from:</label>
                                            <Calendar value={bookingData.startDateValue} minDate={new Date()} disabledDates={getListDateDisabledByDateOff(product)} disabledDays={getListDayOfWeekDisabledByOperatingHours(product.branchId?product.branchId:null)} showIcon dateFormat="mm/dd/yy" mask="99/99/9999" tooltip="date format is 'mm/dd/yyyy'" onChange={(e) => setBookingData({...bookingData, startDateValue: e.value, startTime: ''})} placeholder="Date" />
                                            <div className="p-form-error">{errors.startDate}</div>
                                        </div>
                                        {[PRODUCT_PRICE_UNIT.hr.value, PRODUCT_PRICE_UNIT.min.value].includes(bookingData.rentalPriceUnit) && bookingData.startDateValue &&
                                        <div className="p-col-12 p-pb-0">
                                            <label className="p-label p-mb-2">* Time:</label>
                                            <div>{renderTimeBlockBySelectedDate(product.branchId?product.branchId:null, bookingData.startDateValue)} </div>
                                            <div className="p-form-error">{errors.startTime}</div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {(product.allowDeposit && [PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value].includes(product.type)) &&
                    <div className="p-grid">
                        <div className="p-col-12 p-pb-0">
                            <Divider align="left" type="dashed" className="p-mt-3 p-mb-2">
                                <b>Payment</b>
                            </Divider>
                            <div className="p-grid">
                                {product.allowDeposit &&
                                    <div className="p-col-12 p-pb-0">
                                        {product.depositType===DEPOSIT_TYPE.installment.value?
                                            !product.allowPayFull?
                                            <div className="p-d-flex p-ai-center">
                                                <label>- <b>Note: </b>Requires to deposit at </label>
                                                <span severity="info" className="p-size-14 p-ml-2" rounded>{moneyFormat(product.depositAmount)}</span>
                                                <label> as installment</label>
                                            </div>
                                            :
                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <label className="p-label p-mb-2">How do you want to pay:</label>
                                                    <div className="p-d-flex p-ai-center">
                                                        <div className="p-d-flex p-ai-center p-mr-4">
                                                            <RadioButton inputId="rbDeposit_0" className="p-mr-2" value={true} onChange={(e) => setBookingData({...bookingData, isDeposit: e.value})} checked={bookingData.isDeposit} />
                                                            <label htmlFor="rbDeposit_0" className="p-radiobutton-label"><span className="p-tag p-tag-warning p-tag-rounded p-mr-2 p-size-14">Deposit ({moneyFormat(product.depositAmount)})</span></label>
                                                        </div>
                                                        <div className="p-d-flex p-ai-center">
                                                            <RadioButton inputId="rbDeposit_1" className="p-mr-2" value={false} onChange={(e) => setBookingData({...bookingData, isDeposit: e.value})} checked={!bookingData.isDeposit} />
                                                            <label htmlFor="rbDeposit_1" className="p-radiobutton-label"><span className="p-tag p-tag-warning p-tag-rounded p-mr-2 p-size-14">Pay full</span></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        <div className="p-d-flex p-ai-center">
                                            <label>- <b>Note: </b>Requires to deposit at </label>
                                            <span className="p-tag p-tag-warning p-tag-rounded p-text-bold p-mx-2">{moneyFormat(product.depositAmount)}</span>
                                            <label> as pledge</label>
                                        </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    }
                </React.Fragment>
                }

                {product.associatedGroups && product.associatedGroups.length>0 &&
                    product.associatedGroups.map((group, gIdx) => {
                        const lstAssociatedProductSelection = groupProductsSelection[gIdx]&&groupProductsSelection[gIdx].associatedProducts?groupProductsSelection[gIdx].associatedProducts:[]

                        return group.associatedProducts && group.associatedProducts.length>0&&
                            <React.Fragment key={gIdx}>
                                <div className="p-grid p-fluid p-mt-3">
                                    <div className="p-col-12">
                                        <span className="p-w-bold p-size-18">{group.name}</span>
                                        <div className="p-hr"></div>
                                        <div className="p-mt-1 p-form-error">{errors['groupAssociation_'+group.id]}</div>
                                    </div>
                                    <div className="p-col-12 p-py-0">
                                        <ul className="p-list-group">
                                            {group.associatedProducts.map((associatedProduct, pIdx) => {
                                                const tmpProduct = associatedProduct.associatedProduct;
                                                const defaultSelect = group.associatedProducts.length===1 && group.mandatory && !group.multiSelect

                                                return (
                                                    <li className={pIdx+1===group.associatedProducts.length?'p-mb-0':''}>
                                                        <div className="p-grid">
                                                            {!defaultSelect &&
                                                            <div className="p-col-1">
                                                                {group.multiSelect?
                                                                <Checkbox inputId={"associated_"+associatedProduct.id} style={{margin: '0.25rem 0'}} value={tmpProduct.id} onChange={(e) => onAssociationFieldChange(gIdx, pIdx, 'id', e)} checked={lstAssociatedProductSelection[pIdx]&&lstAssociatedProductSelection[pIdx].id?true:false}/>
                                                                :
                                                                <RadioButton inputId={"associated_"+associatedProduct.id} style={{margin: '0.25rem 0'}} value={tmpProduct.id} name={"associatedProduct_"+group.id} onChange={(e) => onAssociationFieldChange(gIdx, pIdx, 'id', e.value)} checked={lstAssociatedProductSelection[pIdx]&&lstAssociatedProductSelection[pIdx].id?true:false}/>
                                                                }
                                                            </div>
                                                            }
                                                            <div className={classNames('p-pb-0', defaultSelect?'p-col-12':'p-col-11')}>
                                                                <div className="p-grid">
                                                                    <div className="p-col-12">
                                                                        <div className="p-d-flex p-flex-wrap p-ai-center">
                                                                            <label htmlFor={"associated_"+associatedProduct.id} className="p-w-bold" style={{margin: '0.25rem 0'}}>
                                                                                {tmpProduct.name} 
                                                                            </label>
                                                                            {tmpProduct.classification===PRODUCT_CLASSIFICATION.simple.value &&
                                                                                <React.Fragment>
                                                                                    {(()=>{
                                                                                        let tmpPrices = []
                                                                                        if(!associatedProduct.free){
                                                                                            if(associatedProduct.priceOverride){
                                                                                                tmpPrices = associatedProduct.prices?associatedProduct.prices:[]
                                                                                            }else{
                                                                                                if(!tmpProduct.free){
                                                                                                    tmpPrices = tmpProduct.prices?tmpProduct.prices:[]
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                        
                                                                                        return tmpPrices.map(priceItem => {
                                                                                            return <span className="p-tag p-tag-warning p-tag-rounded p-text-bold p-ml-2">{renderPriceByType(priceItem, tmpProduct.type)}</span>
                                                                                        })
                                                                                    })()}
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>
                                                                        {defaultSelect&&<div className="p-size-14 p-text-italic p-mb-2">(Default selected)</div> }
                                                                        {associatedProduct.included&&<div className="p-size-12 p-mb-2">(Included {associatedProduct.quantity>1?'[x' + associatedProduct.quantity + ']':''})</div>}
                                                                        {tmpProduct.description&&<div>{tmpProduct.description}</div>}
                                                                    </div>
                                                                </div>

                                                                {lstAssociatedProductSelection[pIdx] && lstAssociatedProductSelection[pIdx].id &&
                                                                    <React.Fragment>
                                                                        {(()=>{
                                                                            if(tmpProduct.classification === PRODUCT_CLASSIFICATION.variable.value){
                                                                                return(
                                                                                    <div className="p-grid">
                                                                                        <div className="p-col-12 p-pb-0">
                                                                                            <Divider align="left" type="dashed" className="p-mt-3 p-mb-2">
                                                                                                <b>* Choose a variable</b>
                                                                                            </Divider>
                                                                                            <div className="p-grid p-fluid">
                                                                                                <div className="p-col-12">
                                                                                                    {tmpProduct.variants.map((v, i) => {
                                                                                                        let tmpPrices = []
                                                                                                        if(!associatedProduct.free){
                                                                                                            if(associatedProduct.priceOverride){
                                                                                                                tmpPrices = associatedProduct.variantPrices&&associatedProduct.variantPrices[v.id]?associatedProduct.variantPrices[v.id]:[]
                                                                                                            }else{
                                                                                                                if(!tmpProduct.free){
                                                                                                                    if(tmpProduct.pricedByVariant){
                                                                                                                        tmpPrices = v.prices?v.prices:[]
                                                                                                                    }else{
                                                                                                                        tmpPrices = tmpProduct.prices?tmpProduct.prices:[]
                                                                                                                    }
                                                                                                                }
                                                                                                            }    
                                                                                                        }

                                                                                                        return (
                                                                                                            <div key={i} className="p-d-flex p-flex-wrap p-ai-center p-mb-2">
                                                                                                                <RadioButton inputId={"rbAssociatecVariant_"+i} className="p-mr-1" value={v.id} onChange={(e) => onAssociationFieldChange(gIdx, pIdx, 'variantId', e.value)} checked={lstAssociatedProductSelection[pIdx].variantId===v.id} />
                                                                                                                <label htmlFor={"rbAssociatecVariant_"+i} className="p-radiobutton-label p-ml-3">
                                                                                                                    {v.variantName}
                                                                                                                    {tmpPrices.length>0 && tmpPrices.map((p, pIdx) => {
                                                                                                                        return (
                                                                                                                            p.applyFor===PRICE_APPLY_TYPE.normal.value && 
                                                                                                                            <span className="p-tag p-tag-warning p-tag-rounded p-ml-3 p-text-bold">{renderPriceByType(p, tmpProduct.type)}</span>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </label>
                                                                                                            </div>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                )
                                                                            }
                                                                        })()}
                                                                        {!group.included && associatedProduct.allowInputQty && associatedProduct.quantity>=0 &&
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-pb-0">
                                                                                <Divider align="left" type="dashed" className="p-mt-3 p-mb-2">
                                                                                    <b>* How many do you want to {tmpProduct.type===PRODUCT_TYPE.rental.value?'rent':'buy'}</b>
                                                                                </Divider>
                                                                                <div className="p-grid p-fluid">
                                                                                    <div className="p-col-12 p-md-6">
                                                                                        <InputNumber value={lstAssociatedProductSelection[pIdx].quantity} min={1} onChange={(e) => onAssociationFieldChange(gIdx, pIdx, 'quantity', e.value)} showButtons readonly={true} buttonLayout="horizontal" inputClassName="p-c p-size-18 p-w-bold disabled" decrementButtonClassName="p-button-danger btn-sm" incrementButtonClassName="p-button-success btn-sm" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
                                                                                        <div className="p-form-error">{errors["prodAssociated_"+ group.id +"_"+tmpProduct.id+"_quantity"]}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                        {[PRODUCT_TYPE.program.value, PRODUCT_TYPE.session.value].includes(tmpProduct.type) && 
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-pb-0">
                                                                                <Divider align="left" type="dashed" className="p-mt-3 p-mb-2">
                                                                                    <b>* Pick a schedule</b>
                                                                                </Divider>
                                                                                <div className="p-grid p-fluid">
                                                                                    <div className="p-col-12 p-pb-0">
                                                                                        <div className="p-grid p-fluid">
                                                                                            <div className="p-col-12 p-md-6">
                                                                                                <label className="p-label">* Date:</label>
                                                                                                <Calendar value={lstAssociatedProductSelection[pIdx].availabilityDate} showIcon minDate={new Date()} onChange={(e) => onAssociationFieldChange(gIdx, pIdx, 'availabilityDate', e.value)} dateFormat="mm/dd/yy" mask="99/99/9999" placeholder="mm/dd/yyyy" tooltip="date format is 'mm/dd/yyyy'" tooltipOptions={{position: 'top'}} />
                                                                                            </div>
                                                                                            <div className="p-col-12 p-pb-0">
                                                                                                <label className="p-label p-mb-1">* Time:</label>
                                                                                                <div>
                                                                                                    {(()=>{
                                                                                                        let selectedDateFmt = lstAssociatedProductSelection[pIdx].availabilityDate?moment(lstAssociatedProductSelection[pIdx].availabilityDate).format('MM/DD/YYYY'):null
                                                                                                        let tmpAvalabilities = getListAvailabilityBySelectedDate(tmpProduct.id, selectedDateFmt)
                                                                                                        let html = []
                                                                                                        for (const availabilityItem of tmpAvalabilities) {
                                                                                                            html.push(<ToggleButton className="p-mr-1 p-mb-1 p-button-secondary btn-text-sm" onLabel={availabilityItem.label} onIcon="pi pi-check" offLabel={availabilityItem.label} offIcon="pi pi-times" checked={lstAssociatedProductSelection[pIdx].availabilityId===availabilityItem.value} onChange={(e) => onAssociationFieldChange(gIdx, pIdx, 'availabilityId', availabilityItem.value)} style={{width: 'auto'}} />)
                                                                                                        }
                                                                                                        if(html.length===0){
                                                                                                            return <ToggleButton className="p-mr-1 p-mb-1 p-button-secondary btn-text-sm" offLabel="No selection" checked={false} style={{width: 'auto'}} />
                                                                                                        }
                                                                                                        return html
                                                                                                    })()}
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="p-form-error p-pt-1">{errors["prodAssociated_"+ group.id +"_"+tmpProduct.id+"_availability"]}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </React.Fragment>
                    })
                }
                
                <div className="p-sidebar-line p-my-3"></div>

                <div className="p-grid">
                    <div className="p-col-12 p-d-flex p-justify-between">
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => closeDialog()}/>
                        <Button label="Submit" icon="pi pi-check" onClick={() => handleSaveBooking()}/>
                    </div>
                </div>
            </Sidebar>     
        </React.Fragment>
    );
})
