import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../../constants';

const SESSION_SERVICE_URL = API_BASE_URL + SCP_PATH + '/event-sessions'

//#region EVENT SESSION
export function getPageEventSessions(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.get(`${SESSION_SERVICE_URL}/page`, {
        params: {...filter, page, size, sort }
    }).then(res => res.data).catch(error => console.log(error));
}

export function getEventSession(id){
    return axios.get(`${SESSION_SERVICE_URL}/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function getEventSessions(simple, eventId){
    return axios.get(`${SESSION_SERVICE_URL}/list`, {params: {simple, eventId}}).then(res => res.data).catch(error => console.log(error));
}

export function saveEventSession(data){
    return axios.post(`${SESSION_SERVICE_URL}`, data).then(res => res.data).catch(error => console.log(error));
}

export function saveEventSessionContent(data){
    return axios.post(`${SESSION_SERVICE_URL}/content`, data).then(res => res.data).catch(error => console.log(error));
}

export function removeEventSession(id){
    return axios.delete(`${SESSION_SERVICE_URL}/${id}`).then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region ONLINE EVENT ZOOM MEETING
export function createZoomMeeting(data) {
    return axios.post(`${SESSION_SERVICE_URL}/zoom-meeting`, data).then(res => res.data).catch(error => console.log(error));
}

export function deleteZoomMeeting(id, meetingId) {
    return axios.delete(`${SESSION_SERVICE_URL}/zoom-meeting/${id}/${meetingId}`).then(res => res.data).catch(error => console.log(error));
}

export function getMeetingParticipants(id) {
    return axios.get(`${SESSION_SERVICE_URL}/zoom-meeting/participant/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion 

//#region EVENT SESSION JOINING CODE
export function getListSessionJoinCode(sessionId){
    return axios.get(`${SESSION_SERVICE_URL}/${sessionId}/joining-codes/list`).then(res => res.data).catch(error => console.log(error));
}

export function saveSessionJoinCode(sessionId, data){
    return axios.post(`${SESSION_SERVICE_URL}/${sessionId}/joining-codes`, data).then(res => res.data).catch(error => console.log(error));
}

export function removeSessionJoinCode(sessionId, id){
    return axios.delete(`${SESSION_SERVICE_URL}/${sessionId}/joining-codes/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function assignJoiningCodeForParticipant(sessionId){
    return axios.post(`${SESSION_SERVICE_URL}/${sessionId}/joining-codes/assign-for-participant`).then(res => res.data).catch(error => console.log(error));
}
//#endregion
