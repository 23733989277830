import React, { Component, useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { Fieldset } from 'primereact/fieldset';

import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { RefundNotification } from './RefundNotification';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { moneyFormat } from '../../core/service/CommonService';
import { convertToPostPay, getOrderDetails } from './OrderService';
import moment from 'moment';
import {
	APP_FEP,
	ORDER_STATE,
	PAYMENT_STATE,
	PRODUCT_CLASSIFICATION,
	PRICING_MODE,
	DEPOSIT_TYPE,
	PAYMENT_MODE,
	DATETIME_FORMAT_DISPLAY,
	TRASACTION_TYPE,
	PAYMENT_STATUS,
	INVOICE_PURPOSE,
	ORDER_ITEM_STATUS,
} from '../../constants';
import { getListTransactions } from '../../scp/accounting/transaction/TransactionServices';
import { getListInvoices } from '../invoice/InvoiceServices';
import { OrderOrInvoicePaymentActionForm } from '../invoice/OrderOrInvoicePaymentActionForm';
import { InvoiceRefundForm } from '../invoice/InvoiceRefundForm';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';

const ObjectType = {
	order: { value: 'order', label: 'Order' },
	invoice: { value: 'invoice', label: 'Invoice' },
};

const ActionType = {
	complete: { value: 'complete', label: 'Complete' },
	cancel: { value: 'cancel', label: 'Cancel' },
};

export const OrderDetails = (props) => {
	const [order, setOrder] = useState(null);
	const [invoices, setInvoices] = useState([]);
	const [transactions, setTransactions] = useState([]);

	const paymentActionForm = useRef(null);
	const refundForm = useRef(null);

	const isWrite = isScpAdmin() || hasRole([ROLES_CONFIG.REPORT_ORDER_W]);

	useEffect(() => {
		loadOrderDetails();
		loadListInvoices();
	}, []);

	const loadOrderDetails = () => {
		getOrderDetails(props.match.params.id).then((res) => {
			if (!res.errorCode) {
				setOrder(res);
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	// handleCompleteOrder = () => {
	//     const params = { reason: this.state.actionReason }

	//     completeOrder(this.state.order.id, params)
	//     .then(res => {
	//         if (!res.errorCode) {
	//             this.setState({order: res});
	//             this.loadListInvoices();
	//             showSuccessNotify('Action submitted!')
	//         } else {
	//             if (res.errorCode === 400) this.setState({ errors: res.errorObj });
	//             showErrorNotify(res.errorMessage)
	//         }
	//     });
	// }

	const openActionDialog = (objType, action, data) => {
		let tmpOrderInvoice;
		if (objType === ObjectType.order.value) {
			if (action === ActionType.complete.value) {
				let orderInvoices = invoices.filter((i) => !i.orderItem && i.status === PAYMENT_STATE.awaiting.value);
				tmpOrderInvoice = orderInvoices.length > 0 ? orderInvoices[0] : null;
			}
		} else {
			tmpOrderInvoice = data;
		}
		paymentActionForm.current.openForm(objType, action, order, tmpOrderInvoice);
	};

	const loadListInvoices = () => {
		getListInvoices({ app: APP_FEP, orderIds: [parseInt(props.match.params.id)] }).then((res) => {
			if (!res.errorCode) {
				setInvoices(res ?? []);

				if (res && res.length > 0) {
					const invoiceIds = res.map((i) => i.id);

					getListTransactions({ app: APP_FEP, invoiceIds: invoiceIds }).then((res) => {
						setTransactions(res ?? []);
					});
				}
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const onChangeToPostPay = (id) => {
		showConfirmNotify({
			message: 'Are you sure to convert this order into Post Pay method?',
			accept: () => convertOrderToPostPay(id),
		});
	};

	const convertOrderToPostPay = (id) => {
		convertToPostPay(id).then((res) => {
			if (!res.errorCode) {
				setOrder(res);
				showSuccessNotify('Action submitted!');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const onRefund = (invoiceData) => {
		refundForm.current.openForm(invoiceData.id, invoiceData.total);
	};

	const onRefreshOrder = (order) => {
		setOrder(order);
		loadListInvoices();
	};

	const moneyFmt = (price) => {
		return moneyFormat(price, order.currency);
	};

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to='/orders'>Orders</BreadcrumbsItem>
			<BreadcrumbsItem to={props.match.url}>Order #{props.match.params.id}</BreadcrumbsItem>

			<OrderOrInvoicePaymentActionForm ref={paymentActionForm} order={(data) => onRefreshOrder(data)} />
			<InvoiceRefundForm ref={refundForm} refreshData={() => loadListInvoices()} />

			{order && (
				<React.Fragment>
					<div className='p-col-12'>
						<div className='card card-w-title'>
							<h1>
								Order Details
								<div className='p-toolbar-group-right'>
									<Link to={`/orders`}>
										<Button icon='pi pi-bars' className='p-button-text' tooltip='Return' tooltipOptions={{ position: 'top' }}></Button>
									</Link>
								</div>
							</h1>
							<div className='p-grid'>
								<div className='p-col-12 p-md-2'>
									<label>
										No. #: <span className='p-w-bold'>{order.orderNumber}</span>
									</label>
								</div>
								<div className='p-col-12 p-md-2'>
									<label>
										Order state: <span className='p-w-bold'>{ORDER_STATE[order.orderState].label}</span>
									</label>
								</div>
								<div className='p-col-12 p-md-3'>
									<label>
										Order status: <span className='p-w-bold'>{PAYMENT_STATE[order.paymentState].label}</span>
									</label>
								</div>
								<div className='p-col-12 p-md-2'>
									<label>
										Payment mode: <span className='p-w-bold'>{PAYMENT_MODE[order.paymentMode].label}</span>
									</label>
								</div>
								<div className='p-col-12 p-md-3'>
									<label>
										Created At: <span className='p-w-bold'>{order.checkoutCompletedAt && moment(order.checkoutCompletedAt).format(DATETIME_FORMAT_DISPLAY)}</span>
									</label>
								</div>
								{order.note && (
									<div className='p-col-12'>
										<label>
											Note: <span>{order.note}</span>
										</label>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className='p-col-12'>
						<div className='card card-w-title'>
							<div className='p-grid'>
								<div className='p-col-12 p-lg-4'>
									<Fieldset legend='Buyer Details'>
										<p>
											<span className='info-title'>Name:</span>
											<span>{order.billName}</span>
										</p>
										<p>
											<span className='info-title'>Email:</span>
											<span>{order.billEmail}</span>
										</p>
										<p>
											<span className='info-title'>Phone:</span>
											<span>{order.billPhone}</span>
										</p>
									</Fieldset>
								</div>
								<div className='p-col-12 p-lg-3'>
									<Fieldset legend='Price Details'>
										<p className='p-size-16'>
											<span className='info-title'>Item total:</span>
											<span className='info-price'>{moneyFmt(order.itemsTotal)}</span>
										</p>
										{order.pricingMode === PRICING_MODE.included_tax.value && (
											<p>
												<span className='info-title'>Item total without tax:</span>
												<span className='info-price'>{moneyFmt(order.itemsTotal - order.taxTotal)}</span>
											</p>
										)}
										{order.mapTaxes &&
											Object.keys(order.mapTaxes).length > 0 &&
											Object.values(order.mapTaxes).map((t) => {
												return (
													<p>
														<span className='info-title'>
															{t.name} <span className='p-text-italic'>({t.rate}%)</span>:
														</span>
														<span className='info-price'>{moneyFmt(t.total)}</span>
													</p>
												);
											})}
										<div className='p-hr p-padding-top-0'></div>
										<p className='p-size-16'>
											<span className='info-title'>Order total:</span>
											<span className='info-price'>{moneyFmt(order.total)}</span>
										</p>
										{order.pledgedDepositTotal !== 0 && (
											<p className='p-size-16'>
												<span className='info-title'>Security deposit:</span>
												<span className='info-price'>{moneyFmt(order.pledgedDepositTotal)}</span>
											</p>
										)}
										{order.paymentState === PAYMENT_STATE.pending.value && (
											<p className='p-size-16'>
												<span className='info-title'>Checkout total:</span>
												<span className='info-price'>{moneyFmt(order.checkoutTotal)}</span>
											</p>
										)}
										<p className='p-size-16'>
											<span className='info-title p-w-bold'>Paid total:</span>
											<span className='info-price p-w-bold'>{moneyFmt(order.paidTotal)}</span>
										</p>
									</Fieldset>
								</div>
								{order.orderState !== ORDER_STATE.cart.value && order.orderState !== ORDER_STATE.canceled.value && (
									<div className='p-col-12 p-lg-5'>
										<Fieldset legend='Order Action'>
											<div className='p-grid'>
												{order.paymentState !== PAYMENT_STATE.completed.value && (
													<div className='p-col-12'>
														<div class='p-messages p-component p-messages-warn p-messages-enter-done'>
															<div class='p-messages-wrapper p-p-size-16'>
																<span class='p-messages-icon pi pi-exclamation-triangle'></span>
																<span class='p-messages-summary'>Order need to pay remain: {moneyFmt(order.total - order.paidTotal)}</span>
															</div>
														</div>
													</div>
												)}
												{isWrite && (
													<div className='p-col-12'>
														{order.paymentState !== PAYMENT_STATE.completed.value && (
															<Button
																label='Complete Order'
																icon='pi-md-check'
																iconPos='left'
																onClick={() => openActionDialog(ObjectType.order.value, ActionType.complete.value, order)}
																className='p-button-success p-margin-right-10 p-margin-bottom-10'
															/>
														)}
														{order.orderState !== ORDER_STATE.canceled.value && ![PAYMENT_STATE.awaiting.value, PAYMENT_STATE.completed.value].includes(order.paymentState) && (
															<Button
																label='Cancel Order'
																icon='pi-md-close'
																iconPos='left'
																onClick={() => openActionDialog(ObjectType.order.value, ActionType.cancel.value, order)}
																className='p-button-danger p-margin-bottom-10'
															/>
														)}
													</div>
												)}
											</div>
										</Fieldset>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className='p-col-12'>
						<div className='card card-w-title'>
							<h1>Order Items</h1>

							<div className='p-datatable p-component'>
								<div className='p-datatable-wrapper'>
									<table style={{ width: '100%' }}>
										<thead className='p-datatable-thead'>
											<tr>
												<th>Product</th>
												<th style={{ width: '5%' }}>Q.ty</th>
												<th style={{ width: '8%' }}>Unit Price</th>
												<th style={{ width: '8%' }}>Discount</th>
												{order.pricingMode === PRICING_MODE.normal.value && <th style={{ width: '8%' }}>Tax</th>}
												<th style={{ width: '9%' }}>Total</th>
												<th style={{ width: '10%' }}>Deposit</th>
												<th style={{ width: '10%' }}>Checkout</th>
												<th style={{ width: '20%' }}>Description</th>
											</tr>
										</thead>
										<tbody className='p-datatable-tbody'>
											{order.items &&
												order.items.map((item) => {
													let tmpProduct = item.product;

													return (
														<React.Fragment key={item.id}>
															<tr key={item.id} className='p-datatable-row' style={{ backgroundColor: '#f0f0f0' }}>
																<td>
																	<div className='p-w-bold'>
																		{item.productName}
																		{item.productVariantName && ' - [' + item.productVariantName + ']'}
																	</div>
																	{item.autoRecurring ? (
																		<div className='p-margin-10-0'>
																			- Subscription in every {item.subscriptionInfo.period} {item.subscriptionInfo.periodType}
																			{item.subscriptionInfo.period > 1 ? 's' : ''}
																		</div>
																	) : (
																		item.rentalInfo && (
																			<div className='p-margin-10-0'>
																				- Rent in {item.rentalInfo.rentalQty} {item.rentalInfo.rentalUnit}
																				{item.rentalInfo.rentalQty > 1 ? 's' : ''}
																			</div>
																		)
																	)}
																	{item.status === ORDER_ITEM_STATUS.canceled && (
																		<div className='p-margin-10-0'>
																			<strong>[Cancelled]</strong> - {item.note}
																		</div>
																	)}
																</td>
																<td className='p-c'>
																	{(![PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) || !item.pricedByChildren) && item.quantity}
																</td>
																<td className='p-r'>
																	{[PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) && item.pricedByChildren
																		? ''
																		: moneyFmt(item.unitPrice)}
																</td>
																<td className='p-r'>{moneyFmt(item.adjustmentTotal)}</td>
																{order.pricingMode === PRICING_MODE.normal.value && (
																	<td className='p-r'>
																		{[PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) && item.pricedByChildren
																			? ''
																			: moneyFmt(item.taxPrice)}
																	</td>
																)}
																<td className='p-r'>{moneyFmt(item.totalFinal)}</td>
																<td className='p-r'>
																	{item.deposit && moneyFmt(item.depositTotal)}
																	{item.deposit && <div className='p-size-12 italic'>(As {DEPOSIT_TYPE[item.depositType].label})</div>}
																</td>
																<td className='p-r'>{moneyFmt(item.checkout)}</td>
																<td>
																	{item.scheduleInfo && (
																		<React.Fragment>
																			<div className='p-mb-1 p-ml-3'>Start: {moment(item.scheduleInfo.start).format(DATETIME_FORMAT_DISPLAY)}</div>
																			<div className='p-mt-2 p-ml-3'>End: {moment(item.scheduleInfo.end).format(DATETIME_FORMAT_DISPLAY)}</div>
																		</React.Fragment>
																	)}
																</td>
															</tr>
															{item.childItems &&
																item.childItems.map((childItem) => {
																	return (
																		<tr key={childItem.id} className='p-datatable-row' style={{ backgroundColor: '#fff' }}>
																			<td className=''>
																				<div className='p-padding-left-30'>
																					{childItem.productName}
																					{childItem.productVariantName && ' - [' + childItem.productVariantName + ']'}
																				</div>
																				{childItem.rentalInfo && (
																					<div className='p-margin-10-0 p-padding-left-30'>
																						- Rent in {childItem.rentalInfo.rentalQty} {childItem.rentalInfo.rentalUnit}
																						{childItem.rentalInfo.rentalQty > 1 ? 's' : ''}
																					</div>
																				)}
																			</td>
																			<td className='p-c'>{childItem.quantity}</td>
																			<td className='p-r'>{moneyFmt(childItem.total)}</td>
																			{order.pricingMode === PRICING_MODE.normal.value && <td className='p-r'>{moneyFmt(childItem.taxPrice)}</td>}
																			<td className='p-r'>{moneyFmt(childItem.totalFinal)}</td>
																			<td className='p-r'></td>
																			<td className='p-r'></td>
																			<td>
																				{childItem.scheduleInfo && (
																					<React.Fragment>
																						<div className='p-mb-1 p-ml-3'>Start: {moment(childItem.scheduleInfo.start).format(DATETIME_FORMAT_DISPLAY)}</div>
																						<div className='p-mt-2 p-ml-3'>End: {moment(childItem.scheduleInfo.end).format(DATETIME_FORMAT_DISPLAY)}</div>
																					</React.Fragment>
																				)}
																			</td>
																		</tr>
																	);
																})}
														</React.Fragment>
													);
												})}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className='p-col-12'>
						<div className='card card-w-title'>
							<h1>Invoices-Receipts</h1>
							<DataTable value={invoices}>
								<Column
									header='#No.'
									field='id'
									bodyClassName='p-c'
									body={(rowData) => {
										if (isScpAdmin() || hasRole([ROLES_CONFIG.INVOICE_DETAIL_R])) {
											return (
												<Link to={`/invoices/${rowData.id}`} className='p-button p-component p-button-info p-button-text-icon-left'>
													<span className='pi pi-search p-c p-button-icon-left'></span> <span className='p-button-text p-c'>{rowData.id}</span>
												</Link>
											);
										}
										return rowData.id;
									}}
								/>
								<Column header='Payor' field='payorName' />
								<Column
									header='Type'
									field='status'
									body={(rowData) => {
										if (rowData.purpose === INVOICE_PURPOSE.refund.value) {
											return 'Refund';
										}
										return rowData.status === PAYMENT_STATE.completed.value ? 'Receipt' : 'Invoice';
									}}
								/>
								<Column header='Total' field='total' bodyClassName='p-r' body={(rowData) => moneyFmt(rowData.total)} />
								<Column header='Amount Due' field='checkout' bodyClassName='p-r' body={(rowData) => (rowData.status !== PAYMENT_STATE.completed.value ? moneyFmt(rowData.checkout) : '')} />
								<Column header='Paid' field='paid' bodyClassName='p-r' body={(rowData) => (rowData.status !== PAYMENT_STATE.completed.value ? moneyFmt(rowData.paid) : moneyFmt(rowData.checkout))} />
								<Column
									header='Created/Completed At'
									bodyClassName='p-r'
									body={(rowData) =>
										rowData.status !== PAYMENT_STATE.completed.value
											? moment(rowData.dateCreated).format(DATETIME_FORMAT_DISPLAY)
											: rowData.paymentCompletedAt && moment(rowData.paymentCompletedAt).format(DATETIME_FORMAT_DISPLAY)
									}
								/>
								<Column
									bodyClassName='p-r'
									body={(rowData) => {
										if (isWrite) {
											if ((rowData.status === PAYMENT_STATE.pending.value || rowData.status === PAYMENT_STATE.awaiting.value) && order.orderState !== ORDER_STATE.canceled.value) {
												if (rowData.purpose === INVOICE_PURPOSE.refund.value) {
													return <Button label='Refund' icon='pi-md-check' className='p-button-success' onClick={() => onRefund(rowData)} />;
												}

												return (
													<Button label='Complete' icon='pi-md-check' className='p-button-success' onClick={() => openActionDialog(ObjectType.invoice.value, ActionType.complete.value, rowData)} />
												);
											}
										}

										if (rowData.status === PAYMENT_STATE.completed.value) {
											return 'Completed';
										}
									}}
								/>
							</DataTable>
						</div>
						{transactions.length > 0 && (
							<div className='card card-w-title'>
								<h1>Transactions</h1>
								<DataTable value={transactions} responsive={true}>
									<Column header='Invoice Ref.#' field='invoiceId' bodyClassName='p-c' style={{ width: '8%' }} />
									<Column header='Gateway' field='gateway' />
									<Column header='Method' field='method' />
									<Column header='Type' field='type' />
									<Column header='Trans Id' field='transId' />
									<Column header='Amount' field='amount' bodyClassName='p-r' body={(rowData) => moneyFmt(rowData.amount)} />
									<Column header='Paid At' field='completedAt' bodyClassName='p-r' body={(rowData) => rowData.completedAt && moment(rowData.completedAt).format(DATETIME_FORMAT_DISPLAY)} />
									{/* <Column bodyClassName="p-r" 
																	body={rowData => {
																			if(rowData.type===TRASACTION_TYPE.refund){
																					if(rowData.status===PAYMENT_STATUS.pending){
																							if(this.state.order.orderState!==ORDER_STATE.canceled.value){
																									return <Button label="Refund" onClick={()=> this.onRefund(rowData)} />
																							}
																					}else if(rowData.status===PAYMENT_STATUS.completed){
																							return <b>Refunded</b>
																					}
																			}
																	}}
															/> */}
								</DataTable>
							</div>
						)}
					</div>
				</React.Fragment>
			)}
		</div>
	);
};
