import React, { Component, useEffect, useRef, useState } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { showNotification, showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { ConfirmNotification } from '../../core/components/ConfirmNotification';
import { APP_FEP, TABLE_PAGING_LENGTH, TAXONOMY_TYPE } from '../../constants';
import { TaxonForm } from './TaxonForm';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { Dropdown } from 'primereact/dropdown';
import { getPageTaxons, getTreeTaxon, removeTaxon } from './TaxonServices';
import { DataTable } from 'primereact/datatable';
import { SplitButton } from 'primereact/splitbutton';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { getListDepartments } from '../../scp/departments/DepartmentServices';
import classNames from 'classnames';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';

export const TaxonListView = (props) => {
	const taxonForm = useRef(null);

	const [mapDepartments, setMapDepartments] = useState({});
	const [type, setType] = useState(props.type);
	const [secondaryType, setSecondaryType] = useState(props.secondaryType);
	const [dataList, setDataList] = useState([]);
	const [filter, setFilter] = useState({ app: APP_FEP, type: props.type, secondaryTypes: props.secondaryType ? [props.secondaryType] : [] });
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'id',
		sortOrder: -1,
	});

	const [isLoadTable, setLoadTable] = useState(true);

	const isAssetWrite = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_CATEGORY_MGT_W]);
	const isAssetDelete = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_CATEGORY_MGT_D]);

	useEffect(() => {
		setType(props.type);
		setFilter({ ...filter, type: props.type, secondaryTypes: props.secondaryType ? [props.secondaryType] : [] });
		setLoadTable(true);
	}, [props.type]);

	useEffect(() => {
		setSecondaryType(props.secondaryType);
		if (props.secondaryType === TAXONOMY_TYPE.product.value) loadDepartments();
		setFilter({ ...filter, type: props.type, secondaryTypes: props.secondaryType ? [props.secondaryType] : [] });
		setLoadTable(true);
	}, [props.secondaryType]);

	useEffect(() => {
		if (isLoadTable) loadPageTaxons();
	}, [isLoadTable]);

	const loadDepartments = () => {
		getListDepartments({ externalRef: false }).then((res) => {
			let mapDept = {};

			if (res && res.length > 0) {
				res.map((o) => {
					mapDept[o.value] = o;
				});
			}

			setMapDepartments(mapDept);
		});
	};

	const loadPageTaxons = () => {
		showloading();
		getPageTaxons(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				if (res) {
					setDataList(res.content);
					setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
					setLoadTable(false);
				}
			})
			.finally(stoploading());
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const deleteTaxon = (id) => {
		showConfirmNotify({
			accept: () => handleDeleteTaxon(id),
		});
	};

	const handleDeleteTaxon = (id) => {
		removeTaxon(id).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	const renderHeaderPageName = () => {
		if (type === TAXONOMY_TYPE.category.value) {
			if (secondaryType === TAXONOMY_TYPE.product.value) return 'Activity Categories';
			else if (secondaryType === TAXONOMY_TYPE.asset.value) return 'Facility Categories';
			else if (secondaryType === TAXONOMY_TYPE.membership.value) return 'Membership Categories';
		}
		return TAXONOMY_TYPE[type] ? TAXONOMY_TYPE[type].label : type;
	};

	const onAddOrEdit = (data) => {
		taxonForm.current.openForm(data, props.type);
	};

	const actionTemplate = (rowData) => {
		if (isWrite() && isDelete()) {
			let items = [{ label: 'Remove', icon: 'pi pi-trash', command: () => deleteTaxon(rowData.id) }];
			return (
				<React.Fragment>
					<SplitButton label='Edit' icon='pi pi-pencil' className='p-button-constrast' model={items} onClick={() => onAddOrEdit(rowData)}></SplitButton>
				</React.Fragment>
			);
		}
		if (isWrite()) {
			return <Button label='Edit' icon='pi pi-pencil' className='p-button-constrast' onClick={() => onAddOrEdit(rowData)}></Button>;
		}
		if (isDelete()) {
			return <Button label='Remove' icon='pi pi-trash' className='p-button-danger' onClick={() => deleteTaxon(rowData.id)}></Button>;
		}
	};

	const tblHeader = () => {
		let headerName = '';
		if (type) {
			switch (type) {
				case TAXONOMY_TYPE.category.value:
					headerName = 'Categories';
					break;
				case TAXONOMY_TYPE.branch.value:
					headerName = 'Branches';
					break;
				case TAXONOMY_TYPE.location.value:
					headerName = 'Venues';
					break;
				default:
					break;
			}
		}

		let flag = isWrite();

		return (
			<TableHeader
				title={headerName}
				actionTemplate={
					flag && <Button icon='pi pi-plus' label={`Create ${TAXONOMY_TYPE[type]?.label ?? ''}`} className='p-button-constrast' style={{ float: 'left' }} onClick={() => onAddOrEdit()} />
				}
				pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
				changePageLength={(e) => onChangePageLength(e)}
				refresh={() => setLoadTable(true)}
			/>
		);
	};

	const isWrite = () => {
		let tmp = isScpAdmin();
		if (type) {
			switch (type) {
				case TAXONOMY_TYPE.category.value:
					switch (secondaryType) {
						case TAXONOMY_TYPE.asset.value:
							tmp = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_CATEGORY_MGT_W]);
							break;
						case TAXONOMY_TYPE.product.value:
							tmp = isScpAdmin() || hasRole([ROLES_CONFIG.PRODUCT_CATEGORY_MGT_W]);
							break;
						case TAXONOMY_TYPE.membership.value:
							tmp = isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_CATEGORY_MGT_W]);
							break;
						default:
							break;
					}
					break;
				case TAXONOMY_TYPE.branch.value:
					tmp = isScpAdmin() || hasRole([ROLES_CONFIG.TAXONOMY_BRANCH_MGT_W]);
					break;
				case TAXONOMY_TYPE.location.value:
					tmp = isScpAdmin() || hasRole([ROLES_CONFIG.TAXONOMY_LOCATION_MGT_D]);
					break;
				default:
					break;
			}
		}
		return tmp;
	};

	const isDelete = () => {
		let tmp = isScpAdmin();
		if (type) {
			switch (type) {
				case TAXONOMY_TYPE.category.value:
					switch (secondaryType) {
						case TAXONOMY_TYPE.asset.value:
							tmp = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_CATEGORY_MGT_D]);
							break;
						case TAXONOMY_TYPE.product.value:
							tmp = isScpAdmin() || hasRole([ROLES_CONFIG.PRODUCT_CATEGORY_MGT_D]);
							break;
						case TAXONOMY_TYPE.membership.value:
							tmp = isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_CATEGORY_MGT_D]);
							break;
						default:
							break;
					}
					break;
				case TAXONOMY_TYPE.branch.value:
					tmp = isScpAdmin() || hasRole([ROLES_CONFIG.TAXONOMY_BRANCH_MGT_D]);
					break;
				case TAXONOMY_TYPE.location.value:
					tmp = isScpAdmin() || hasRole([ROLES_CONFIG.TAXONOMY_LOCATION_MGT_D]);
					break;
				default:
					break;
			}
		}
		return tmp;
	};

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={props.match.url}>{renderHeaderPageName()}</BreadcrumbsItem>

			<TaxonForm ref={taxonForm} secondaryType={props.secondaryType} reloadTable={() => loadPageTaxons()} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1 className='p-w-bold p-m-0'>{renderHeaderPageName()}</h1>
				</div>

				<DataTable
					lazy={true}
					paginator={true}
					value={dataList}
					loading={isLoadTable}
					first={pageable.page * pageable.rows}
					header={tblHeader()}
					onPage={onPage}
					onSort={onSort}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					responsive={true}
				>
					<Column header='ID' field='id' sortable={true} headerStyle={{ width: '8em' }} />
					<Column header='Code' field='code' sortable={true} />
					<Column header='Name' field='name' sortable={true} />
					{type === TAXONOMY_TYPE.category.value && secondaryType === TAXONOMY_TYPE.product.value && <Column header='Parent' field='parentName' />}
					{type === TAXONOMY_TYPE.category.value && secondaryType === TAXONOMY_TYPE.product.value && (
						<Column header='Activity Type' field='departmentId' sortable={true} body={(rowData) => mapDepartments[rowData.departmentId] && mapDepartments[rowData.departmentId].label} />
					)}
					<Column header='Weight' field='weight' headerStyle={{ width: '7em' }} bodyClassName='p-c' />
					{(type === TAXONOMY_TYPE.branch.value || (type === TAXONOMY_TYPE.category.value && secondaryType === TAXONOMY_TYPE.product.value)) && (
						<Column
							header='Active'
							field='active'
							headerStyle={{ width: '7em' }}
							bodyClassName='p-c'
							body={(rowData) => <span className={classNames('status', { active: rowData.active, inactive: !rowData.active })}>{rowData.active ? 'Yes' : 'No'}</span>}
						/>
					)}
					{(isWrite() || isDelete()) && <Column headerStyle={{ width: '10em' }} bodyClassName='tc-actions' body={(rowData) => actionTemplate(rowData)} />}
				</DataTable>
			</div>
		</div>
	);
};
