/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Fieldset } from "primereact/fieldset";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { FieldWrapper } from "../../core/components/FieldWrapper";
import { getDuplicateUser } from "./MergeDuplicateService";

const MergeDuplicateStep1 = ({
  goToNextStep,
  selectedDuplicate,
  handleSelectedDuplicate,
  mainUser,
  setMainUser,
}) => {
  const history = useHistory();
  const { id } = useParams();
  const [duplicateUserList, setDuplicateUserList] = useState([]);
  const [isLoadTable, setIsLoadTable] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  useEffect(() => {
    handleGetDuplicateUser(id);
    setIsLoadTable(false);
  }, []);

  const handleGetDuplicateUser = (id) => {
    getDuplicateUser(id).then((res) => {
      if (!res.errorCode) {
        setMainUser(res.main);
        setDuplicateUserList(res.duplicates);
      } else {
        console.log(res.errorCode);
      }
    });
  };

  return (
    <div>
      <div className="p-grid">
        <div className="p-col-12 p-mb-5">
          <DataTable
            value={duplicateUserList}
            emptyMessage="There are no data"
            responsive={true}
            header={
              <TableHeader
                title="Duplicate user"
                showLength={false}
                showRefresh={false}
              />
            }
            loading={isLoadTable}
            rows={null}
            selectionMode="multiple"
            selection={selectedDuplicate}
            onSelectionChange={handleSelectedDuplicate}
          >
            <Column selectionMode="multiple" style={{ width: '5em', textAlign: 'center' }} />
            <Column field="id" header="Membership ID" />
            <Column field="user.name" header="Name" />
            <Column field="user.email" header="Email" />
            <Column field="identityDocument.docNumber" header="Work permit number" />
            <Column field="passport" header="Passport" />
            <Column
              field="action"
              header="Action"
              style={{ width: "13em" }}
              bodyClassName="tc-actions"
              body={(rowData) => {
                return (
                  <Button
                    label="View"
                    className="p-button-constrast p-l"
                    icon="pi pi-eye"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedUser(rowData);
                    }}
                  />
                );
              }}
            />
          </DataTable>
        </div>
      </div>

      <div className="p-grid p-mb-5">
        <div className="p-col-6 p-col-sm-12">
          <MembershipInfo info={mainUser} title="Main User" />
        </div>
        <div className="p-col-6 p-col-sm-12">
          <MembershipInfo info={selectedUser} title="Duplicated User" />
        </div>
      </div>

      <div className="p-grid">
        <div
          className="p-col-12 p-d-flex"
          style={{ justifyContent: "flex-end" }}
        >
          <Button
            label="Cancel"
            onClick={() => history.push("/monitoring/invoices")}
            style={{ width: "6rem" }}
            className="yellow-btn p-mr-2"
          />
          <Button
            label="Next"
            style={{ width: "6rem" }}
            className="blue-btn"
            onClick={goToNextStep}
          />
        </div>
      </div>
    </div>
  );
};

function MembershipInfo({ title, info }) {
  const userInfo = info
  return (
    <Fieldset legend={title}>
      <div className="p-grid p-fluid form-group">
        {userInfo ? (
          <>
            <FieldWrapper col="p-col-12 p-md-6">
              <label className="p-label">Member ID: </label>
              <div className="p-w-bold">{userInfo?.id}</div>
            </FieldWrapper>
            <FieldWrapper col="p-col-12 p-md-6">
              <label className="p-label">Gender: </label>
              <div className="p-w-bold">{userInfo?.user.gender}</div>
            </FieldWrapper>
            <FieldWrapper col="p-col-12 p-md-6">
              <label className="p-label">First Name: </label>
              <div className="p-w-bold">{userInfo?.user.firstName}</div>
            </FieldWrapper>
            <FieldWrapper col="p-col-12 p-md-6">
              <label className="p-label">Last Name: </label>
              <div className="p-w-bold">{userInfo?.user.lastName}</div>
            </FieldWrapper>
            <FieldWrapper col="p-col-12 p-md-6">
              <label className="p-label">Primary Email: </label>
              <div className="p-w-bold">{userInfo?.user.email}</div>
            </FieldWrapper>
            <FieldWrapper col="p-col-12 p-md-6">
              <label className="p-label">Phone Number: </label>
              <div className="p-w-bold">{userInfo?.user.phone}</div>
            </FieldWrapper>
            <FieldWrapper col="p-col-12 p-md-6">
              <label className="p-label">Nationality: </label>
              <div className="p-w-bold">{userInfo?.user.nationality}</div>
            </FieldWrapper>
            <FieldWrapper col="p-col-12 p-md-6">
              <label className="p-label">Work permit number: </label>
              <div className="p-w-bold">{userInfo?.identityDocument.docNumber}</div>
            </FieldWrapper>
            <FieldWrapper col="p-col-12 p-md-6">
              <label className="p-label">Passport: </label>
              <div className="p-w-bold">{userInfo?.identityDocument.passport}</div>
            </FieldWrapper>
          </>
        ) : (
          <h4 className="p-ml-4">
            Please select a row to view detail information
          </h4>
        )}
      </div>
    </Fieldset>
  );
}

export default MergeDuplicateStep1;
