import React, { Component } from 'react';
import { ConnectedAppServices } from './ConnectedAppServices';
import { Sidebar } from 'primereact/sidebar';
import { showNotification } from '../../core/service/NotificationService';
import {Fieldset} from "primereact/fieldset";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {InputSwitch} from 'primereact/inputswitch';

export default class ConnectedAppForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formHeader: 'New App Token',
            appToken: {},
            errors: [],
            visible: false
        };
        this.connectedAppServices = new ConnectedAppServices();
    }

    openForm = (data) => {
        this.setState({
            appToken: {
                apiKey: data ? data.apiKey : null,
                userId: data ? data.userId : '',
                user: data ? data.user : {},
                active: data ? data.active : true,
            },
            errors: {},
            visible: true,
            formHeader: data ? 'Edit App Token' : 'New App Token'
        });
    };

    handleSaveAppToken = () => {
        this.connectedAppServices.saveAppToken(this.state.appToken)
            .then(res => {
                if (!res.errorCode) {
                    this.props.reloadTable();
                    this.onHide();
                    showNotification('success', 'Success Message', 'Action submitted');
                } else {
                    if(res.errorCode == 400){
                        this.setState({
                            errors: res.errorObj
                        });
                        showNotification('error', 'Error Message', res.errorMessage);
                    }
                }
            });
    };

    onHide = () => {
        this.setState({
            visible: false
        });
    };
    

    render() {
        const footer = (<React.Fragment>
            <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handleSaveAppToken()} />
            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide} />
        </React.Fragment>);

        const asterik = <span style={{ color: "red" }}>*</span>

        return (
            <Sidebar dismissable={false} visible={this.state.visible} style={{ overflowY: 'auto', width: '40em' }} position="right" blockScroll={true} onHide={this.onHide}>
                <h2>{this.state.formHeader}</h2>
                <div className="p-grid">
                    <div className="p-col-12">
                        <Fieldset legend="Token Information">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <label className="p-label">User Id{asterik}</label>
                                    <InputText value={this.state.appToken.userId} keyfilter="num" onChange={(e) => this.setState({ appToken: { ...this.state.appToken, userId: e.target.value } })} />
                                    <span className="p-form-error">{this.state.errors.userId}</span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Active</label>
                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.appToken.active} onChange={(e) => this.setState({ appToken: { ...this.state.appToken, active: e.value }})} />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        {footer}
                    </div>
                </div>
            </Sidebar>
        );
    }
}
