import React, {Component} from 'react';
import { RoleServices } from './RoleServices';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { showNotification } from '../../../core/service/NotificationService';
import { Sidebar } from 'primereact/sidebar';
import { titleCaseText } from "../../../core/service/CommonService";
import { InputSwitch } from 'primereact/inputswitch';

export class RoleForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            role: {
                name: '',
                description: '',
                app: false,
                permissions: []
            },
            visible: false,
            formHeader: 'Add Role',
            errors: {}
        }
        this.roleServices = new RoleServices();
    }

    opernForm = (data) => {
        this.setState({
            role: {
                id: data ? data.id : '',
                departmentId: this.props.deptId,
                name: data ? data.name : '',
                description: data ? data.description : '',
                app: data ? data.app : ''
            },
            visible: true,
            formHeader: data ? 'Edit Role' : 'New Role'
        })
        
    }

    handleSave = (e) => {
        this.setState({errors: {}});

        this.roleServices.saveRole(this.state.role)
        .then(res=>{
            if(!res.errorCode){
                this.props.reloadTable();
                this.onHide();
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode === 400) this.setState({errors: res.errorObj});
            }
        })
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    render(){

        return (
            <Sidebar visible={this.state.visible} style={{ overflowY: "auto", width: "40em" }} position="right" blockScroll={true} onHide={this.onHide} >
                <h2>{this.state.formHeader}</h2>

                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <label>* Name</label>
                        <InputText value={this.state.role.name} onChange={(e)=> this.setState({role: {...this.state.role, name: titleCaseText(e.target.value)}})}/>
                        <div className="p-form-error">{this.state.errors.name}</div>
                    </div>
                    <div className="p-col-12">
                        <label>Description</label>
                        <InputTextarea rows={3} cols={30} value={this.state.role.description} onChange={(e) => this.setState({role: {...this.state.role, description:e.target.value}})} />
                    </div>
                    <div className="p-col-12">
                        <InputSwitch checked={this.state.role.app} onChange={(e) => this.setState({role: {...this.state.role, app: e.value}})} />
                        <label htmlFor="app" className="p-checkbox-label"> <strong>Is App?</strong></label>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Submit" icon="pi pi-check" onClick={this.handleSave}/>
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                    </div>
                </div>
            </Sidebar>
        );
    }
}