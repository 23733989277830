import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
	APP_FEP,
	DATETIME_FORMAT_DISPLAY,
	DATE_FORMAT_DISPLAY,
	HR_RESOURCE_TYPE,
	INVOICE_PURPOSE,
	PAYMENT_GATEWAY,
	PAYMENT_METHOD,
	PAYMENT_STATE,
	PAYMENT_STATUS,
	PRODUCT_TYPE,
} from '../../constants';
import { exportInvoices, getPageInvoices, removeInvoice, sendInvoiceMailRemind } from './InvoiceServices';
import { SelectButton } from 'primereact/selectbutton';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { moneyFormat, getTablePageReport, getCurrentUserId } from '../../core/service/CommonService';
import Membership from '../../frontdesk/membership/Membership';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { showErrorNotify, showSuccessNotify, showConfirmNotify, showWarnNotify } from '../../core/service/NotificationService';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { getListHRs } from '../../crm/human_resource/HRServices';


const fileDownload = require('js-file-download');

const VIEW_TYPE_LIST = {
	invoice: { value: 'invoice', label: 'Invoice' },
	receipt: { value: 'receipt', label: 'Receipt' },
};

const INVOICE_METHOD = {
	cash: { value: 'cash', label: 'Cash' },
	online: { value: 'online', label: 'Online' },
	bank_transfer: { value: 'bank_transfer', label: 'Bank transfer' },
};

const ORDER_TYPE = {
	membership: { value: 'membership', label: 'Membership' },
	booking: { value: 'booking', label: 'Booking' },
	course: { value: 'course', label: 'Course' },
};

export const InvoiceListView = () => {
	const match = useRouteMatch();
	const history = useHistory();

	const [filter, setFilter] = useState({
		app: APP_FEP,
		statuses: [PAYMENT_STATE.awaiting.value],
		id: '',
		orderIdOrNumber: '',
		customer: '',
		purposes: [
			INVOICE_PURPOSE.order_charge.value,
			INVOICE_PURPOSE.asset_charge.value,
			INVOICE_PURPOSE.asset_subscription.value,
			INVOICE_PURPOSE.membership_subscription.value,
			INVOICE_PURPOSE.refund.value,
		],
	});

	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'id',
		sortOrder: -1,
	});
	const [viewType, setViewType] = useState(VIEW_TYPE_LIST.invoice.value);
	const [isLoadTable, setLoadTable] = useState(false);
	const [vendorIds, setVendorIds] = useState([]);

	const isLimitByVendor = !isScpAdmin() && hasRole([ROLES_CONFIG.TRAINING_VIEW_BY_VENDOR_R]);

	useEffect(() => {
		async function limitByVendor() {
			if (isLimitByVendor) {
				let userId = getCurrentUserId();
				if (userId) {
					let resVendors = await getListHRs({ userIds: userId ? [userId] : null, type: HR_RESOURCE_TYPE.vendor.value });
					setVendorIds(resVendors && resVendors.length > 0 ? resVendors.map((v) => v.value) : []);
					setLoadTable(true);
				}
			} else {
				setLoadTable(true);
			}
		}
		limitByVendor();
	}, []);

	useEffect(() => {
		if (isLoadTable) loadPageInvoices();
	}, [isLoadTable]);

	const loadPageInvoices = () => {
		let tmpFilter = { ...filter };
		if (isLimitByVendor) {
			if (!vendorIds || vendorIds.length === 0) {
				setDataList([]);
				setPageable({ ...pageable, total: 0, page: 0, rows: 10 });
				setLoadTable(false);
				showWarnNotify('No vendor');
				return;
			}
			tmpFilter.vendorIds = vendorIds;
		}

		showloading();
		getPageInvoices(tmpFilter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				if (res) {
					setDataList(res.content);
					setPageable({ ...pageable, total: res.totalElements, loading: false, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
				}
			})
			.finally(() => {
				setLoadTable(false);
				stoploading();
			});
	};

	const onPage = (e) => {
		setPageable({ ...pageable, loading: true, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, loading: true, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const onViewTypeChange = (e) => {
		let tmpViewType = e.value ? e.value : viewType;
		setViewType(tmpViewType);
		setFilter({
			...filter,
			statuses: tmpViewType === VIEW_TYPE_LIST.receipt.value ? [PAYMENT_STATE.completed.value] : [PAYMENT_STATE.awaiting.value],
			purposes: [
				INVOICE_PURPOSE.order_charge.value,
				INVOICE_PURPOSE.asset_charge.value,
				INVOICE_PURPOSE.asset_subscription.value,
				INVOICE_PURPOSE.membership_subscription.value,
				INVOICE_PURPOSE.refund.value,
			],
			gateways: [],
			methodSelected: null,
		});
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const applyFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setLoadTable(true);
	};

	const clearFilter = () => {
		setFilter({
			...filter,
			id: '',
			orderIdOrNumber: '',
			customer: '',
			dateCreateFrom: '',
			dateCreateTo: '',
			dateCompletedFrom: '',
			dateCompletedTo: '',
			gateways: [],
			productTypes: [],
			methodSelected: null,
			orderTypeSelected: null,
		});
		setLoadTable(true);
	};

	const sendRemindEmail = (id, reminded) => {
		showConfirmNotify({
			message: reminded ? 'This invoice had already send to customer. Are you sure you want to proceed?' : '',
			accept: () => handleSendInvoiceRemindEmail(id),
		});
	};

	const handleSendInvoiceRemindEmail = (id) => {
		sendInvoiceMailRemind(id).then((res) => {
			if (!res.errorCode) {
				loadPageInvoices();
				showSuccessNotify('Email sent successfully!');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const handleExport = () => {
		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = `rpt__${viewType}_${suf}.xlsx`;

		exportInvoices(filter).then((res) => {
			if (!res.errorCode) {
				fileDownload(res, fileName);
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const onMethodChange = (value) => {
		let tmpArr = [];
		if (value) {
			if (value === INVOICE_METHOD.cash.value) {
				tmpArr = [PAYMENT_METHOD.cash.value];
			} else if (value === INVOICE_METHOD.online.value) {
				tmpArr = [
					PAYMENT_METHOD.card.value,
					PAYMENT_METHOD.paynow_online.value,
					PAYMENT_METHOD.alipay.value,
					PAYMENT_METHOD.wechat.value,
					PAYMENT_METHOD.swipe_card.value,
					PAYMENT_METHOD.user_credit.value,
					PAYMENT_METHOD.credit_card.value,
				];
			} else if (value === INVOICE_METHOD.bank_transfer.value) {
				tmpArr = [PAYMENT_METHOD.bank_transfer.value];
			}
		}
		setFilter({ ...filter, methods: tmpArr, methodSelected: value });
	};

	const onOrderTypeChange = (value) => {
		let tmpArr = [];
		let isCourseProgram = null;
		if (value) {
			switch (value) {
				case ORDER_TYPE.membership.value:
					tmpArr = [PRODUCT_TYPE.membership.value];
					isCourseProgram = false;
					break;
				case ORDER_TYPE.booking.value:
					tmpArr = [PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value];
					isCourseProgram = false;
					break;
				case ORDER_TYPE.course.value:
					tmpArr = [PRODUCT_TYPE.program.value];
					isCourseProgram = true;
					break;
				default:
					break;
			}
		}
		setFilter({ ...filter, productTypes: tmpArr, orderTypeSelected: value, isCourse: isCourseProgram });
	};

	const onKeyPressEnter = (e) => {
		if (e.key === 'Enter') setLoadTable(true);
	};

	const tblHeader = (
		<TableHeader
			title={VIEW_TYPE_LIST[viewType].label + 's'}
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
			actionTemplate={<Button label='Export' icon='pi pi-download' disabled={dataList.length === 0} onClick={() => handleExport()} />}
		/>
	);
	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>Invoices-Receipts</BreadcrumbsItem>

			<div className='p-col-12 p-c'>
				<SelectButton value={viewType} options={Object.values(VIEW_TYPE_LIST)} onChange={(e) => onViewTypeChange(e)} />
			</div>

			<div className='p-col-12'>
				<Fieldset legend='Filter'>
					<div className='p-grid p-fluid p-mt-1'>
						<div className='p-col-12 p-md-6'>
							<div className='p-grid'>
								<div className='p-col-12 p-md-6'>
									<div className='p-inputgroup'>
										<span className='p-inputgroup-addon p-text-bold'>{VIEW_TYPE_LIST[viewType].label} Number:</span>
										<InputText value={filter.id} keyfilter={/^[0-9]*$/} onChange={(e) => setFilter({ ...filter, id: e.target.value })} onKeyUp={onKeyPressEnter} />
									</div>
								</div>
								{(viewType === VIEW_TYPE_LIST.invoice.value || viewType === VIEW_TYPE_LIST.receipt.value) && (
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon p-text-bold'>Order Id/Code:</span>
											<InputText value={filter.orderIdOrNumber} onChange={(e) => setFilter({ ...filter, orderIdOrNumber: e.target.value })} onKeyUp={onKeyPressEnter} />
										</div>
									</div>
								)}
								<div className={classNames('p-col-12', { 'p-md-6': viewType === VIEW_TYPE_LIST.receipt.value })}>
									<div className='p-inputgroup'>
										<span className='p-inputgroup-addon p-text-bold'>Type:</span>
										<Dropdown value={filter.orderTypeSelected} options={Object.values(ORDER_TYPE)} showClear onChange={(e) => onOrderTypeChange(e.value)} />
									</div>
								</div>
								{viewType === VIEW_TYPE_LIST.receipt.value && (
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon p-text-bold'>Method:</span>
											<Dropdown value={filter.methodSelected} options={Object.values(INVOICE_METHOD)} showClear onChange={(e) => onMethodChange(e.value)} />
										</div>
									</div>
								)}
							</div>
						</div>
						<div className='p-col-12 p-md-6'>
							<div className='p-grid'>
								<div className='p-col-12 p-md-6'>
									<div className='p-inputgroup'>
										<span className='p-inputgroup-addon p-text-bold'>From: </span>
										{viewType === VIEW_TYPE_LIST.invoice.value ? (
											<Calendar
												value={filter.dateCreateFrom ? moment(filter.dateCreateFrom).toDate() : null}
												onChange={(e) => setFilter({ ...filter, dateCreateFrom: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : null })}
												onKeyUp={(e) => e && e.key === 'Enter' && setLoadTable(true)}
												showIcon
												dateFormat='dd/mm/yy'
											/>
										) : (
											<Calendar
												value={filter.dateCompletedFrom ? moment(filter.dateCompletedFrom).toDate() : null}
												onChange={(e) => setFilter({ ...filter, dateCompletedFrom: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : null })}
												onKeyUp={(e) => e && e.key === 'Enter' && setLoadTable(true)}
												showIcon
												dateFormat='dd/mm/yy'
											/>
										)}
									</div>
								</div>
								<div className='p-col-12 p-md-6'>
									<div className='p-inputgroup'>
										<span className='p-inputgroup-addon p-text-bold'>To: </span>
										{viewType === VIEW_TYPE_LIST.invoice.value ? (
											<Calendar
												value={filter.dateCreateTo ? moment(filter.dateCreateTo).toDate() : null}
												onChange={(e) => setFilter({ ...filter, dateCreateTo: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : null })}
												onKeyUp={(e) => e && e.key === 'Enter' && setLoadTable(true)}
												showIcon
												dateFormat='dd/mm/yy'
											/>
										) : (
											<Calendar
												value={filter.dateCompletedTo ? moment(filter.dateCompletedTo).toDate() : null}
												onChange={(e) => setFilter({ ...filter, dateCompletedTo: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : null })}
												onKeyUp={(e) => e && e.key === 'Enter' && setLoadTable(true)}
												showIcon
												dateFormat='dd/mm/yy'
											/>
										)}
									</div>
								</div>
								<div className='p-col-12'>
									<div className='p-inputgroup'>
										<span className='p-inputgroup-addon p-text-bold'>Customer:</span>
										<InputText
											value={filter.customer}
											onChange={(e) => setFilter({ ...filter, customer: e.target.value })}
											onKeyUp={(e) => e && e.key === 'Enter' && setLoadTable(true)}
											placeholder='Name/email/phone'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='p-col-12 p-c'>
							<Button label='Find' icon='pi pi-filter' iconPos='left' style={{ width: 'auto' }} onClick={() => applyFilter()} />
							<Button label='Clear' icon='pi pi-trash' className='p-button-secondary p-ml-2' iconPos='left' style={{ width: 'auto' }} onClick={() => clearFilter()} />
						</div>
					</div>
				</Fieldset>
			</div>
			<div className='p-col-12'>
				<DataTable
					emptyMessage="There's no order"
					value={dataList}
					loading={isLoadTable}
					lazy={true}
					paginator={true}
					first={pageable.page * pageable.rows}
					header={tblHeader}
					onPage={onPage}
					onSort={onSort}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					responsive={true}
				>
					<Column
						header='No. #'
						field='id'
						sortable={true}
						headerStyle={{ width: '12em' }}
						bodyStyle={{ overflow: 'visible' }}
						body={(rowData) => {
							if (isScpAdmin() || hasRole([ROLES_CONFIG.INVOICE_DETAIL_R])) {
								let items = [{ label: 'Resend email', icon: 'pi pi-envelope', command: () => sendRemindEmail(rowData.id, rowData.remind) }];
								if (viewType === VIEW_TYPE_LIST.invoice.value) {
									return (
										<SplitButton
											label={rowData.id}
											icon='pi pi-search'
											className='p-button-info'
											tooltip={'View ' + VIEW_TYPE_LIST[viewType].label}
											tooltipOptions={{ position: 'top' }}
											model={items}
											onClick={() => history.push(`/invoices/${rowData.id}`)}
										/>
									);
								}
								return (
									<Button
										label={rowData.id}
										icon='pi pi-search'
										className='p-button-info'
										tooltip={'View ' + VIEW_TYPE_LIST[viewType].label}
										tooltipOptions={{ position: 'top' }}
										onClick={() => history.push(`/invoices/${rowData.id}`)}
									/>
								);
							} else {
								return rowData.id;
							}
						}}
					/>
					<Column
						header='Customer'
						field='payorName'
						sortable={true}
						body={(rowData) => {
							return (
								<div style={{ display: 'inline-block' }}>
									<div>{rowData.payorName}</div>
									{rowData.payorEmail && <div className='p-margin-top-5'>[{rowData.payorEmail}]</div>}
									{rowData.payorPhone && <div className='p-margin-top-5'>[{rowData.payorPhone}]</div>}
								</div>
							);
						}}
					/>
					<Column
						header='Description'
						field='description'
						sortable={false}
						body={(rowData) => {
							let invoiceOrderType = 'Booking';
							if (rowData.purpose === INVOICE_PURPOSE.order_charge.value) {
								if (rowData.order) {
									for (const orderItem of rowData.order.items) {
										let tmpProduct = orderItem.product;
										if (tmpProduct && tmpProduct.type === PRODUCT_TYPE.membership.value) {
											invoiceOrderType = 'Membership';
										}
									}
								}
							}

							return (
								<React.Fragment>
									<div className='p-margin-bottom-5'>{rowData.description}</div>
									{rowData.note && <div className='p-margin-bottom-5'>- {rowData.note}</div>}
									{rowData.status === PAYMENT_STATUS.completed && (
										<div className='p-margin-bottom-5'>Method: {rowData.gateway === PAYMENT_GATEWAY.ONSITE ? PAYMENT_METHOD[rowData.method] && PAYMENT_METHOD[rowData.method].label : 'Online'}</div>
									)}
									{rowData.purpose === INVOICE_PURPOSE.order_charge.value && (
										<div className='p-bold'>
											Type: <strong>{invoiceOrderType}</strong>
										</div>
									)}
									{rowData.purpose === INVOICE_PURPOSE.refund.value && (
										<div className='p-bold'>
											Type: <strong>Refund</strong>
										</div>
									)}
								</React.Fragment>
							);
						}}
					/>
					{/* <Column header="Total" field="total" bodyClassName="p-r" body={rowData => moneyFormat(rowData.total)}/>
                    <Column header={viewType===VIEW_TYPE_LIST.invoice.value?'Amount Due':'Paid'} field="checkout" bodyClassName="p-r" body={(rowData) => moneyFormat(rowData.checkout)} />
                    {viewType===VIEW_TYPE_LIST.invoice.value && 
                        <Column header="Paid" field="paid" bodyClassName="p-r" body={rowData => moneyFormat(rowData.paid)}/>
                    } */}
					<Column
						header='Price'
						body={(rowData) => {
							return (
								<div style={{ display: 'inline-block' }}>
									<div className='p-margin-bottom-5'>Total: {moneyFormat(rowData.total)}</div>
									<div className='p-margin-bottom-5'>
										{viewType === VIEW_TYPE_LIST.invoice.value ? 'Amount Due' : 'Paid'}: {moneyFormat(rowData.checkout)}
									</div>
									{viewType === VIEW_TYPE_LIST.invoice.value && <div>Paid: {moneyFormat(rowData.paid)}</div>}
								</div>
							);
						}}
					/>
					<Column
						header={viewType === VIEW_TYPE_LIST.invoice.value ? 'Due Date' : 'Paid At'}
						sortable={true}
						bodyClassName='p-r'
						body={(rowData) => {
							if (viewType === VIEW_TYPE_LIST.invoice.value) {
								return rowData.paymentDueDate && moment(rowData.paymentDueDate).format(DATE_FORMAT_DISPLAY);
							} else {
								return rowData.paymentCompletedAt && moment(rowData.paymentCompletedAt).format(DATETIME_FORMAT_DISPLAY);
							}
						}}
					/>
					<Column
						header='Created At'
						field='dateCreated'
						sortable={true}
						bodyClassName='p-c'
						body={(rowData) => (rowData.dateCreated ? moment(rowData.dateCreated).format(DATETIME_FORMAT_DISPLAY) : moment(rowData.createdAt).format(DATETIME_FORMAT_DISPLAY))}
					/>
					{viewType === VIEW_TYPE_LIST.invoice.value && <Column header='Email reminder' bodyClassName='p-c' body={(rowData) => (rowData.remind ? 'YES' : 'NO')} />}
					{/* {viewType===VIEW_TYPE_LIST.invoice.value && 
                        <Column headerStyle={{width: '12em'}} body={rowData => <Button label="Resend email" className="p-button-constrast" icon="pi pi-envelope" onClick={()=> sendRemindEmail(rowData.id, rowData.remind)} />}/>
                    } */}
				</DataTable>
			</div>
		</div>
	);
};
