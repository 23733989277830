import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

import {ProductOptionListView} from './ProductOptionListView';

export const ProductOptionManagement = () => {
    const match = useRouteMatch()

    return (
        <Switch>
            <Route path={`${match.url}`} component={ProductOptionListView}/>
            <Redirect from='*' to='/404' />
        </Switch>
    )
}