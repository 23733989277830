import axios from "axios";
import { MAIL_BASE_URL, MAIL_PATH } from "../../constants";

const MAIL_SERVICE_URL = MAIL_BASE_URL + MAIL_PATH;
export class MailTemplateServices {

    //#region mail template
    getMailTemplates(page, size, sortField, sortOrder) {
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${MAIL_SERVICE_URL}/template/email`, {
            params: {
                page,
                size,
                sort
            }
        }).then(res => res.data).catch(error => console.log(error));
    }

    saveTemplate(data) {
        return axios.post(`${MAIL_SERVICE_URL}/template/email`, data).then(res => res.data);
    }

    getMailTemplateById(id) {
        return axios.get(`${MAIL_SERVICE_URL}/template/email/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }

    deleteMailTemplateById(id) {
        return axios.delete(`${MAIL_SERVICE_URL}/template/email/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }

    getListSimpleMailTemplates() {
        return axios.get(`${MAIL_SERVICE_URL}/template/email/list`)
            .then(res => res.data).catch(error => console.log(error));
    }

    changeMailTemplate(id, data) {
        return axios.put(`${MAIL_SERVICE_URL}/template/email/${id}`, data).then(res => res.data);
    }

    sendMailTest(id) {
        return axios.post(`${MAIL_SERVICE_URL}/template/email/send-test?mailTemplateId=${id}`).then(res => res.data)
            .then(res => res.data).catch(error => console.log(error));
    }
    //#endregion

    //#region block template
    getHeaderTemplates(page, size, sortField, sortOrder) {
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${MAIL_SERVICE_URL}/template/block/page`, {
            params: {
                page,
                size,
                sort
            }
        }).then(res => res.data).catch(error => console.log(error));
    }

    saveBlockTemplate(data) {
        return axios.post(`${MAIL_SERVICE_URL}/template/block`, data).then(res => res.data);
    }

    getListSimpleBlockTemplates() {
        return axios.get(`${MAIL_SERVICE_URL}/template/block/list`)
            .then(res => res.data).catch(error => console.log(error));
    }

    deleteBlockTemplateById(id) {
        return axios.delete(`${MAIL_SERVICE_URL}/template/block/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }
    //#endregion
}

export function getMailTemplates() {
    return axios.get(`${MAIL_SERVICE_URL}/template/email/list`)
        .then(res => res.data).catch(error => console.log(error));
}