import React, {Component} from "react";
import { getListOrderByTransaction, getTransaction, saveTransactionStatus } from "./TransactionServices";
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { InputTextarea } from "primereact/inputtextarea";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { hasRole } from "../../../core/security/auth.jsx";
import { ROLES_CONFIG } from "../../../roles.js";
import { showNotification } from "../../../core/service/NotificationService";
import { PAYMENT_STATUS } from "../../../constants";

export class TransactionDetails extends Component{
    constructor(props){
        super(props);
        this.state = {
            transaction:{},
            orderList: [],
            headerTransactionAction: '',
            visibleTransactionAction: false,
            status: '',
            reason: '',
            errors: {}
        }
    }

    componentDidMount(){
        this.getTransactionDetails();
        this.getListOrdersByTrans();
    }

    getTransactionDetails = () => {
        getTransaction(this.props.match.params.id)
        .then(res => {
            if(!res.errorCode){
                this.setState({transaction: res});
            }
        });
    }

    getListOrdersByTrans = () => {
        getListOrderByTransaction(this.props.match.params.id)
        .then(res => {
            this.setState({orderList: res});
        });
    }

    actionOrderTemplate = (rowData, column) =>{
        return <div>
            <Link to={`/orders/${rowData.id}`}>
                <Button icon="pi pi-search" className="p-button-warning" tooltip="View Order" tooltipOptions={{position: 'top'}} ></Button>
            </Link>
            
        </div>;
    }

    onBtnActionClick = (status) => {
        this.setState({
            status: status,
            reason: '',
            errors: {},
            headerTransactionAction: status===PAYMENT_STATUS.completed?'Confirm Transaction':'Cancel Transaction',
            visibleTransactionAction: true,
        });
    }

    saveTransactionStatus = () => {
        let params = {reason: this.state.reason};

        saveTransactionStatus(this.props.match.params.id, this.state.status, params)
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    transaction: res,
                    visibleTransactionAction: false
                });
                showNotification('success', 'Success Message', 'Action submitted');
            }
            else{
                if(res.errorCode === 400) this.setState({errors: res.errorObj});
                showNotification('error', 'Error Message', res.errorMessage);
            }
        });
    }

    render(){
        var footerTransactionAction = (
            <div>
                <Button label="OK" icon="pi pi-check" onClick={(e) => this.saveTransactionStatus()}/>
                <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={(e)=>this.setState({visibleTransactionAction: false})}/>
            </div>
        );

        return (
            <div className="p-grid">
                <BreadcrumbsItem to="/transaction">Transactions</BreadcrumbsItem>
                <BreadcrumbsItem to={this.props.match.url}>Transaction #{this.state.transaction.id}</BreadcrumbsItem>

                <Dialog header={this.state.headerTransactionAction} footer={footerTransactionAction} visible={this.state.visibleTransactionAction} style={{width: "500px"}} modal={true} onHide={(e)=>this.setState({visibleTransactionAction: false})}>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12">
                            <label className="p-label">Reason:</label>
                            <InputTextarea rows="4" value={this.state.reason} onChange={(e) => this.setState({reason: e.target.value})} placeholder="Enter a reason"/>
                            <span className="p-form-error">{this.state.errors.reason}</span>
                        </div>
                    </div>
                </Dialog>

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            Transaction Details
                            <div className="p-toolbar-group-right">
                                <Link to={`/transactions`}>
                                    <Button icon="pi pi-bars" className="p-button-text " tooltip="Return" tooltipOptions={{position: 'top'}}></Button>
                                </Link>
                            </div>
                        </h1>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Basic Information">
                                    <div className="p-grid">
                                        <div className="p-col-4">Payer Email:</div>
                                        <div className="p-col-8 p-w-bold">{this.state.transaction.email}</div>
                                        <div className="p-col-4">GateWay:</div>
                                        <div className="p-col-8 p-w-bold">{this.state.transaction.gateway}</div>
                                        <div className="p-col-4">Channel:</div>
                                        <div className="p-col-8 p-w-bold">{this.state.transaction.channelName}</div>
                                        <div className="p-col-4">Total Amount:</div>
                                        <div className="p-col-8 p-w-bold">{this.state.transaction.currencyShortSign}{this.state.transaction.amount}</div>
                                        <div className="p-col-4">Status:</div>
                                        <div className="p-col-8 p-w-bold">{this.state.transaction.statusName}</div>
                                        <div className="p-col-4">Created At:</div>
                                        <div className="p-col-8 p-w-bold">{this.state.transaction.created}</div>
                                        {/* <div className="p-col-12 p-md-6">
                                            <label>Confirm Code: {this.state.transaction.confirmCode}</label>
                                        </div> */}
                                        {this.state.transaction.notes &&
                                            [<div className="p-col-4">Notes:</div>,
                                            <div className="p-col-8 p-w-bold">{this.state.transaction.notes}</div>]
                                        }
                                    </div>
                                </Fieldset>
                            </div>
                            <div className="p-col-12 p-md-6">
                                {this.state.transaction.status!==PAYMENT_STATUS.canceled &&
                                    <Fieldset legend="Transaction Actions">
                                        <div className="p-grid">
                                            <div className="p-col-12">
                                                {this.state.transaction.status===PAYMENT_STATUS.pending &&
                                                    <Button disabled={!hasRole([ROLES_CONFIG.TRANSACTION_DEAIL_W])} label="Process Transaction" icon="pi pi-check" iconPos="left" onClick={() => this.onBtnActionClick('completed')} className="p-button-success p-margin-right-30"/>
                                                }
                                                <Button disabled={!hasRole([ROLES_CONFIG.TRANSACTION_DEAIL_D])} label="Cancel Order" icon="pi-md-close" iconPos="left" onClick={() => this.onBtnActionClick('cancel')} className="p-button-danger"/>
                                            </div>
                                        </div>
                                    </Fieldset>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>Orders:</h1>
                        <DataTable lazy={true} value={this.state.orderList} responsive={true}>
                            <Column header="ID" field="id"/>
                            <Column header="Code" field="orderNumber"/>
                            <Column header="Amount" field="total" body={(rowData) => {return (rowData.currencyShortSign + ' ' + rowData.total)}} bodyClassName="p-r"/>
                            <Column header="Checkout Amount" field="checkoutTotal" body={(rowData) => {return (rowData.currencyShortSign + ' ' + rowData.checkoutTotal)}} bodyClassName="p-r"/>
                            <Column header="Checkout State" field="checkoutStateName" bodyClassName="p-c"/>
                            <Column header="Payment State" field="paymentStateName" bodyClassName="p-c"/>
                            <Column header="Order State" field="orderStateName" bodyClassName="p-c"/>
                            <Column header="" body={this.actionOrderTemplate} style={{textAlign:'right', width:'6em'}} />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }

}