import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../../constants';
import { ERROR_RESPONSE } from '../../ErrorObject';
let querystring = require('query-string');

const CONFIG_FORMDATA_HEADER = {
	headers: { 'Content-Type': 'multipart/form-data' },
};
const MEMBERSHIP_SERVICE_URL = API_BASE_URL + SCP_PATH + '/memberships';
export class MembershipServices {
	getPageMemberships(page, size, sortField, sortOrder) {
		const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
		const sort = sortField ? `${sortField},${order}` : '';
		return axios
			.get(`${API_BASE_URL}/scp/api/memberships/page`, {
				params: { page, size, sort },
			})
			.then((res) => {
				return res.data;
			})
			.catch((error) => console.log(error));
	}

	getMembership(id) {
		return axios
			.get(`${API_BASE_URL}/scp/api/memberships/${id}`)
			.then((res) => res.data)
			.catch((error) => console.log(error));
	}

	getUserMemberships(userId) {
		return axios
			.get(`${API_BASE_URL}/scp/api/memberships/get_by_user`, { params: { uId: userId } })
			.then((res) => res.data)
			.catch((error) => console.log(error));
	}

	updateStatusMembership(id, status) {
		return axios
			.post(`${API_BASE_URL}/scp/api/memberships/${id}/update_status`, querystring.stringify({ status: status }))
			.then((res) => res.data)
			.catch((error) => console.log(error));
	}

	getMembershipInfoByMember(memberId) {
		return axios
			.get(`${API_BASE_URL}/scp/api/memberships/info/get_by_user`, { params: { uId: memberId } })
			.then((res) => res.data)
			.catch((error) => console.log(error));
	}

	getMembersOfMembership(membershipId, userId) {
		return axios
			.get(`${API_BASE_URL}/scp/api/memberships/${membershipId}/members`, { params: { userId: userId } })
			.then((res) => res.data)
			.catch((error) => console.log(error));
	}

	getMembershipMemberAvailabld(type, id) {
		return axios
			.get(`${API_BASE_URL}/scp/api/memberships/members/available`, { params: { objectType: type, objectId: id } })
			.then((res) => res.data)
			.catch((error) => console.log(error));
	}

	holdingMembership(data) {
		return axios
			.post(`${API_BASE_URL}/scp/api/memberships/${data.id}/update_status`, querystring.stringify({ ...data }))
			.then((res) => res.data)
			.catch((error) => console.log(error));
	}

	saveMemberForMembership(membershipId, data) {
		return axios
			.post(`${API_BASE_URL}/scp/api/memberships/${membershipId}/members`, querystring.stringify({ ...data }))
			.then((res) => res.data)
			.catch((error) => console.log(error));
	}

	removeMembershipMember(id) {
		return axios
			.delete(`${API_BASE_URL}/scp/api/memberships/members/${id}`)
			.then((res) => res.data)
			.catch((error) => console.log(error));
	}
}

export function getPageMemberships(filter, page, size, sortField, sortOrder) {
	const order = sortOrder === 0 ? 'asc' : sortOrder === -1 ? 'desc' : '';
	const sort = sortField ? `${sortField},${order}` : '';
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/page`, filter, {
			params: { page, size, sort },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getMembership(id) {
	return axios
		.get(`${MEMBERSHIP_SERVICE_URL}/${id}`)
		.then((res) => res.data)
		.catch((error) => {
			console.log(error);
			return ERROR_RESPONSE;
		});
}

export function createMembership(data) {
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function updateMembership(data) {
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/update`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function updateMembershipStatus(id, status) {
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/${id}/update-status`, {}, { params: { status } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function extendMembershipUsage(id, price, note) {
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/${id}/manual-extend-usage`, null, { params: { price, note } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function verifyMembershipDocument(id) {
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/${id}/verification`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function printMembershipCard(id) {
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/${id}/print`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removeMembership(id, removeUserOwnerMembership = false) {
	return axios
		.delete(`${MEMBERSHIP_SERVICE_URL}/${id}`, { params: { removeUserOwner: removeUserOwnerMembership } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function updateCompanyForMembership(membershipId, companyId) {
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/${membershipId}/company`, null, { params: { companyId } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getPageMembershipMembers(filter, page, size, sortField, sortOrder) {
	const order = sortOrder === 0 ? 'asc' : sortOrder === -1 ? 'desc' : '';
	const sort = sortField ? `${sortField},${order}` : '';
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/members/page`, filter, {
			params: { page, size, sort },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function addMembershipMember(data) {
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/members`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removeMembershipMember(id) {
	return axios
		.delete(`${MEMBERSHIP_SERVICE_URL}/members/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function saveMembershipCard(data) {
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/cards`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function changeMembershipCardStatus(id, status) {
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/cards/${id}/status/${status}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function exportMembershipList(filter) {
	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/export`, filter, { responseType: 'arraybuffer' })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function importMemberships(data) {
	const formData = new FormData();
	formData.append('file', data);

	return axios
		.post(`${MEMBERSHIP_SERVICE_URL}/import`, formData, CONFIG_FORMDATA_HEADER)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}
