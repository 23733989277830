import React, { Component } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { TodoServices } from './TodoServices';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { showNotification } from '../../core/service/NotificationService';

export default class TodoForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            todoData: this.props.data,
            errors: {}
        }
        this.todoServices = new TodoServices();
    }

    componentDidMount() {
        this.loadDepartments();
    }

    handleSaveTodo = () => {
        this.todoServices.saveTodo(this.state.todoData)
            .then(res => {
                if (!res.errCode) {
                    this.props.popularTodo();
                    this.props.hideFormTodoDialog();
                    showNotification('success', 'Success Message', 'Action Submitted');
                } else {
                    showNotification('error', 'Error Message', res.errorMessage);
                    if (res.errorObj) this.setState({ errors: res.errorObj });
                }
            })
    }

    loadDepartments = () => {
        let deptUsers = localStorage.getItem('depts');
        if (deptUsers && deptUsers !== 'undefined') {
            let deptsData = JSON.parse(deptUsers);
            let tempArrDepts = [];
            if (deptsData && deptsData.length > 0) {
                deptsData.map(dept => {
                    tempArrDepts.push({ label: dept.name, value: dept.id })
                });
            }
            this.setState({ departments: tempArrDepts });
        }
    }

    render() {
        return (
            <Sidebar visible={this.props.formTodoDialog.visible} style={{ overflowY: 'auto', width: '25em' }} position="right" blockScroll={true} onHide={this.props.hideFormTodoDialog}>
                <h2>{this.props.formTodoDialog.header}</h2>
                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <label className="p-label">* Title</label>
                        <InputText value={this.state.todoData.name} onChange={(e) => this.setState({ todoData: { ...this.state.todoData, name: e.target.value } })} maxLength="50" />
                        <div className="p-form-error">{this.state.errors.name}</div>
                    </div>
                    <div className="p-col-12">
                        <label>Department</label>
                        <Dropdown options={this.state.departments} value={this.state.todoData.departmentId} onChange={(e) => this.setState({ todoData: { ...this.state.todoData, departmentId: e.value } })} />
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Save" icon="pi pi-check" onClick={this.handleSaveTodo} />
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={(e) => this.props.hideFormTodoDialog()} />
                    </div>
                </div>
            </Sidebar>
        );
    }
}