import React, { useState, useRef, useEffect } from 'react';
import { FullCalendar } from 'primereact/fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { AssetScheduleForm } from './AssetScheduleForm';
import { getSchedule, getScheduleList } from './SchedulesService';
import { ASSET_SCHEDULE_STATUSES, ASSET_SCHEDULE_STATUS } from '../../constants';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showErrorNotify } from '../../core/service/NotificationService';
import { getListAssets } from '../asset/AssetServices';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';

const today = new Date();

export const AssetSchedules = (props) => {
	const [scheduleEvents, setScheduleEvents] = useState([]);
	const [filter, setFilter] = useState({
		assetId: props.assetId,
		inventoryId: props.inventoryId,
		statuses: [],
	});
	const [assets, setAssets] = useState([]);

	const [isLoadSchedule, setLoadSchedule] = useState(true);

	const frmSchedule = useRef();

	const calendarOptions = {
		plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
		defaultView: 'dayGridMonth',
		defaultDate: today,
		header: {
			left: 'addScheduleButton',
			center: 'title',
			right: 'prev,next dayGridMonth,timeGridWeek,timeGridDay',
		},
		// customButtons: {
		//     addScheduleButton: {
		//         text: 'Add Schedule',
		//         click: () => frmSchedule.current.openForm(null, props.branchId, props.asset, props.inventoryId),
		//     }
		// },
		eventClick: (e) => showSchedule(e),
		editable: true,
	};

	const isWrite = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_DETAIL_W]);

	if (isWrite) {
		calendarOptions.customButtons = {
			addScheduleButton: {
				text: 'Add Schedule',
				click: () => frmSchedule.current.openForm(null, props.branchId, props.asset, props.inventoryId),
			},
		};
	}

	useEffect(() => {
		if (isLoadSchedule) loadSchedules();
	}, [isLoadSchedule]);

	const loadSchedules = () => {
		showloading();
		getScheduleList(filter).then((res) => {
			setScheduleEvents(res ? popularScheduleTime(res) : []);
			setLoadSchedule(false);
			stoploading();
		});
	};

	useEffect(() => {
		if (props.inventoryId) loadAllAssets(props.inventoryId);
		else setAssets([]);
	}, [props.inventoryId]);

	const loadAllAssets = (inventoryId) => {
		getListAssets({ inventoryId: inventoryId ? inventoryId : null }, true).then((res) => {
			setAssets(res);
		});
	};

	const popularScheduleTime = (schedules) => {
		let events = [];

		if (schedules && Array.isArray(schedules)) {
			schedules.forEach((s) => {
				events.push({
					id: s.id,
					title: '[' + ASSET_SCHEDULE_STATUS[s.status].label + '] - ' + (props.inventoryId ? s.asset.name + ' - ' : '') + s.title,
					start: s.startDate + 'T' + s.startTime + ':00',
					end: s.endDate + 'T' + s.endTime + ':00',
				});
			});
		}

		return events;
	};

	const showSchedule = (e) => {
		e.jsEvent.preventDefault();

		getSchedule(e.event.id).then((res) => {
			if (!res.errorCode) {
				frmSchedule.current.openForm(res, props.branchId);
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const clearScheduleFilter = () => {
		setFilter({
			assetId: props.assetId,
			inventoryId: props.inventoryId,
			statuses: [],
		});

		setLoadSchedule(true);
	};

	return (
		<div className='p-grid'>
			<AssetScheduleForm
				ref={frmSchedule}
				reloadSchedule={() => {
					setLoadSchedule(true);
					props.refresh();
				}}
			/>

			<div className='p-col-12'>
				<Fieldset legend='Filters'>
					<div className='p-grid p-fluid p-ai-end'>
						{props.inventoryId && (
							<div className='p-col-12 p-md-4'>
								<div className='p-inputgroup'>
									<span className='p-inputgroup-addon'>Facility:</span>
									<Dropdown
										options={assets}
										value={filter.assetId}
										showClear={true}
										filter={true}
										onChange={(e) => {
											setFilter({ ...filter, assetId: e.value });
											setLoadSchedule(true);
										}}
										placeholder='All'
									/>
								</div>
							</div>
						)}
						<div className='p-col-12 p-md-4'>
							<div className='p-inputgroup'>
								<span className='p-inputgroup-addon'>Schedule Status:</span>
								<Dropdown
									options={ASSET_SCHEDULE_STATUSES}
									value={filter.statuses[0]}
									showClear={true}
									onChange={(e) => {
										setFilter({ ...filter, statuses: e && e.value ? [e.value] : [] });
										setLoadSchedule(true);
									}}
									placeholder='All'
								/>
							</div>
						</div>

						<div className='p-col-12 p-md-4'>
							<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' style={{ width: 'auto' }} onClick={clearScheduleFilter} />
						</div>
					</div>
				</Fieldset>
			</div>

			<div className='p-col-12 p-mt-2'>
				<FullCalendar events={scheduleEvents} options={calendarOptions} />
			</div>
		</div>
	);
};
