import axios from "axios";
import { PIM_BASE_URL, PIM_PATH } from "../../constants";

const PIM_INVENTORY_URL = PIM_BASE_URL + PIM_PATH + "/inventory";

export function getPageInventories(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.post(`${PIM_INVENTORY_URL}/page`, filter, {
        params: {page, size, sort}
    }).then(res => {
        return res.data
    }).catch(error => console.log(error));
}

export function getListInventories(filter, simple){
    return axios.post(`${PIM_INVENTORY_URL}/list`, filter, {params: {isSimple: simple}}).then(res => {
        return res.data
    }).catch(error => console.log(error));
}

export function saveInventory(data) {
    return axios.post(`${PIM_INVENTORY_URL}`, data).then(res => res.data).catch(error => console.log(error));
}

export function getInventory(id) {
    return axios.get(`${PIM_INVENTORY_URL}/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function removeInventory(id){
    return axios.delete(`${PIM_INVENTORY_URL}/${id}`).then(res => res.data).catch(error => console.log(error));
}