import {
  all
} from 'redux-saga/effects'
import answer from './form/answer-list/sagas/answer';
import formList from './form/form-management/sagas/form';
import workflow from './form/answer-list/sagas/form';

export default function* RootSaga() {
  yield all([
    answer(),
    formList(),
    workflow()
  ])
}
