import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { generatingCouponCode, savePromotion, savePromotionCoupon } from './PromotionService';
import { Fieldset } from 'primereact/fieldset';
import moment from 'moment';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { APP_FEP, PROMOTION_COUPON_TYPE } from '../../constants';
import Select from 'react-dropdown-select';
import { getListProducts } from '../product/ProductServices';

export const PromotionCouponForm = forwardRef((props, ref) => {
	const [header, setHeader] = useState('New Promotion');
	const [visible, setVisible] = useState(false);
	const [frmData, setFrmData] = useState({});
	const [startDateValue, setStartDateValue] = useState(null);
	const [endDateValue, setEndDateValue] = useState(null);
	const [minDate, setMinDate] = useState(null);
	const [maxDate, setMaxDate] = useState(null);
	const [rules, setRules] = useState([]);
	const [products, setProducts] = useState([]);
	const [errors, setErrors] = useState({});

	useImperativeHandle(ref, () => ({
		openForm(data, rules, defaultType) {
			setHeader(data ? 'Edit Coupon' : 'New Coupon');
			setRules(
				rules
					? rules.map((r) => {
							return { value: r.id, label: r.name };
					  })
					: []
			);
			setFrmData(popularFormData(data, defaultType));
			setStartDateValue(data && data.validFrom ? moment(data.validFrom, 'YYYY-MM-DD HH:mm').toDate() : '');
			setMinDate(data && data.validFrom ? moment(data.validFrom, 'YYYY-MM-DD HH:mm').toDate() : '');
			setEndDateValue(data && data.validTo ? moment(data.validTo, 'YYYY-MM-DD HH:mm').toDate() : '');
			setMaxDate(data && data.validTo ? moment(data.validTo, 'YYYY-MM-DD HH:mm').toDate() : '');
			setVisible(true);

			if (data && data.type === PROMOTION_COUPON_TYPE.coupon.value) {
				loadProducts();
			} else if (!defaultType || defaultType === PROMOTION_COUPON_TYPE.coupon.value) {
				loadProducts();
			}
		},
		closeForm() {
			closeForm();
		},
	}));

	const popularFormData = (data, defaultType) => {
		return {
			id: data ? data.id : '',
			promoId: props.promoId,
			code: data ? data.code : '',
			type: data ? data.type : defaultType,
			ruleId: data ? data.promotionRuleId : '',
			refProducts: data && data.refProducts ? data.refProducts : [],
			usageLimit: data ? (!data.usageLimit ? 0 : data.usageLimit) : '',
			validFrom: data && data.validFrom ? data.validFrom : '',
			validTo: data && data.validTo ? data.validTo : '',
			active: data ? data.active : true,
		};
	};

	const loadProducts = () => {
		getListProducts({ app: APP_FEP, active: true }).then((res) => setProducts(res ? res : []));
	};

	const handleSave = () => {
		savePromotionCoupon(frmData).then((res) => {
			if (!res.errorCode) {
				if (props.refreshTable) props.refreshTable();
				closeForm();
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify('Cannot perform action');
				if (res.errorCode === 400) setErrors(res.errorObj);
			}
		});
	};

	const handleGeneratingCode = () => {
		generatingCouponCode().then((res) => {
			setFrmData({ ...frmData, code: res ? res : '' });
		});
	};

	const onStartDateChange = (e) => {
		let tmpMaxDate = maxDate ? moment(maxDate) : '';
		let minDateToCompare = moment(e.value).isValid() ? moment(e.value) : '';
		let flag = minDateToCompare && tmpMaxDate && minDateToCompare.isAfter(tmpMaxDate);

		setFrmData({
			...frmData,
			validFrom: minDateToCompare ? minDateToCompare.format('YYYY-MM-DD HH:mm') : '',
			validTo: !flag ? frmData.validTo : '',
		});
		setStartDateValue(e.value);
		setMinDate(moment(e.value).isValid() ? e.value : '');
		setEndDateValue(!flag ? endDateValue : '');
		setMaxDate(!flag ? maxDate : '');
	};

	const onEndDateChange = (e) => {
		let tmpMinDate = minDate ? moment(minDate) : '';
		let maxDateToCompare = moment(e.value).isValid() ? moment(e.value) : '';
		let flag = maxDateToCompare && tmpMinDate && maxDateToCompare.isBefore(tmpMinDate);

		setFrmData({
			...frmData,
			validFrom: !flag ? frmData.validFrom : '',
			validTo: maxDateToCompare ? maxDateToCompare.format('YYYY-MM-DD HH:mm') : '',
		});
		setStartDateValue(!flag ? startDateValue : '');
		setMinDate(!flag ? minDate : '');
		setEndDateValue(e.value);
		setMaxDate(moment(e.value).isValid() ? e.value : '');
	};

	const onChangeProduct = (values) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		setFrmData({ ...frmData, refProducts: arrValues });
	};

	const closeForm = () => {
		setVisible(false);
		setFrmData({});
		setErrors({});
	};

	return (
		<Sidebar visible={visible} style={{ overflowY: 'auto' }} className='p-sidebar-md' position='right' blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
			<div className='p-d-flex p-justify-between '>
				<h2 className='p-margin-top-10'>{header}</h2>
				<Button label='' icon='pi pi-times' className='p-button-secondary' onClick={closeForm} />
			</div>

			<div className='p-sidebar-line p-mb-3'></div>

			<div className='p-grid'>
				<div className='p-col-12'>
					<Fieldset legend='Header Information'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12'>
								<label className='p-label'>* Coupon code</label>
								<div className='p-inputgroup'>
									<InputText value={frmData.code} keyfilter={/[^\s]/} onChange={(e) => setFrmData({ ...frmData, code: e.target.value })} />
									<Button label='Generate Code' className='p-button-info' onClick={() => handleGeneratingCode()} />
								</div>
								<div className='p-form-error'>{errors.code}</div>
							</div>
							{(() => {
								if (frmData.type === PROMOTION_COUPON_TYPE.voucher.value) {
									return (
										<div className='p-col-12'>
											<label className='p-label'>* Promotion</label>
											<Dropdown value={frmData.ruleId} options={rules} onChange={(e) => setFrmData({ ...frmData, ruleId: e.value })} />
											<div className='p-form-error'>{errors.rule}</div>
										</div>
									);
								} else if (!frmData.type || frmData.type === PROMOTION_COUPON_TYPE.coupon.value) {
									return (
										<div className='p-col-12'>
											<label className='p-label'>* Product</label>
											<Select
												multi
												values={products && frmData.refProducts && frmData.refProducts.length > 0 ? products.filter((x1) => frmData.refProducts.some((x2) => x2 === x1.value)) : []}
												options={products}
												onChange={(values) => onChangeProduct(values)}
												style={{ width: '100%' }}
												placeholder='Not use'
												noDataRenderer={() => {
													return <span className='p-c p-padding-10-0'>No Data</span>;
												}}
											/>
											<div className='p-form-error'>{errors.refProducts}</div>
										</div>
									);
								}
							})()}
						</div>
					</Fieldset>
					<Fieldset legend='Usage Setting'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12 p-md-6'>
								<label>Start Date</label>
								<MaskedCalendar value={startDateValue} onChange={(e) => onStartDateChange(e)} showIcon={true} showTime={true} hourFormat='12' />
							</div>
							<div className='p-col-12 p-md-6'>
								<label>End Date</label>
								<MaskedCalendar value={endDateValue} onChange={(e) => onEndDateChange(e)} showIcon={true} showTime={true} hourFormat='12' />
							</div>
							<div className='p-col-12'>
								<label className='p-label'>Usage limit</label>
								<InputText value={frmData.usageLimit} keyfilter='pnum' onChange={(e) => setFrmData({ ...frmData, usageLimit: e.target.value })} />
							</div>
							<div className='p-col-12'>
								<label className='p-label'>Active?</label>
								<InputSwitch checked={frmData.active} onChange={(e) => setFrmData({ ...frmData, active: e.value })} />
							</div>
						</div>
					</Fieldset>
				</div>
			</div>

			<div className='p-sidebar-line p-my-3'></div>

			<div className='p-grid'>
				<div className='p-col-12 p-d-flex p-justify-between'>
					<Button label='Cancel' icon='pi-md-close' className='p-button-secondary' onClick={closeForm} />
					<Button label='Submit' icon='pi pi-check' onClick={() => handleSave()} />
				</div>
			</div>
		</Sidebar>
	);
});
