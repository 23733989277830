import React, { Component } from "react";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import "./Membership.css";
import WpInformationDialog from "./WpInformationDialog";
import PaymentInformationDialog from "./PaymentInformationDialog";
import MembershipService from "./MembershipService";
import { SplitButton } from "primereact/splitbutton";
import { getTablePageReport } from "../../core/service/CommonService";

export default class Membership extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            loading: true,
            page: 0,
            rows: 10,
            total: 0,
            sortField: "",
            sortOrder: 0,
            selectedRow: null,
            filter: {
                name: "",
                docNumber: "",
                cellphone:""
            }
        }
        this.memberService = new MembershipService();
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        showloading();
        this.getPageMemberships();
    }

    resize = () => {
        this.setState({ changeScreenSize: window.innerWidth <= 600 });
    }

    getPageMemberships = () => {
        this.memberService
            .getPageMembership(
                false,
                this.state.filter,
                this.state.page,
                this.state.rows,
                this.state.sortField,
                this.state.sortOrder
            )
            .then(res => {
                stoploading();
                this.setState({
                    dataList: res.content,
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                });
            });
    };

    onRefresh = () =>{
        showloading();
        this.getPageMemberships();
    }

    onPage = e => {
        this.setState(
            {
                loading: true,
                page: e.page
            },
            () => {
                this.getPageMemberships();
            }
        );
    };

    onSort = e => {
        this.setState(
            {
                loading: true,
                sortField: e.sortField,
                sortOrder: e.sortOrder
            },
            () => {
                this.getPageMemberships();
            }
        );
    };

    handleKeyPress = e => {
        if (e.key === "Enter") {
            this.applyFilter();
        }
    };
    
    applyFilter = () => {
        this.setState(
            {
                loading: true,
                page: 0
            },
            () => {
                this.getPageMemberships();
            }
        );
    };

    clearFilter = () => {
        this.setState(
            {
                filter: {
                    ...this.state.filter,
                    name: "",
                    docNumber: "",
                    cellphone: ""
                },
                page: 0
            },
            () => {
                this.applyFilter();
            }
        );
    };

    onChagePageLength = l => {
        this.setState(
            {
                rows: l,
                page: 0
            },
            () => {
                this.applyFilter();
            }
        );
    };

    onVerify = (id) => {
        let tmpDataList = [...this.state.dataList]
        tmpDataList.length > 0 && tmpDataList.map(data => {
            if(data.id === id) {
                data.verified = true
            }
        })

        this.setState({
            dataList: tmpDataList
        })
    }

    onSubmit = (id) => {
        let tmpDataList = [...this.state.dataList]
        tmpDataList.length > 0 && tmpDataList.map(data => {
            if(data.id === id) {
                data.membershipPaymentStatus = "paid"
            }
        })

        this.setState({
            dataList: tmpDataList
        })
    }

    render() {
        let tableLengthOptions = [
            {
                label: '5',
                command: () => {
                    this.onChagePageLength(5);
                }
            },
            {
                label: '10',
                command: () => {
                    this.onChagePageLength(10);
                }
            },
            {
                label: '25',
                command: () => {
                    this.onChagePageLength(25);
                }
            },
            {
                label: '50',
                command: () => {
                    this.onChagePageLength(50);
                }
            },
            {
                label: '100',
                command: () => {
                    this.onChagePageLength(100);
                }
            }
        ];

        let header = (
            <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
                <div style={{ float: "left "}}>
                    <Button icon="pi pi-info" tooltip="These are example data. Any change here will not affect to current system" />
                </div>
                <div style={{ float: "right" }}>
                    <SplitButton className="table-control-length p-button-constrast" label="" icon="pi pi-refresh"
                            onClick={this.onRefresh} model={tableLengthOptions}>
                    </SplitButton>
                </div>
                <h3 className="fle-datatable-header-title">{getTablePageReport(this.state.page, this.state.rows, this.state.total)}</h3>
            </div>
        );

        return (
            <div className="p-grid">

                <WpInformationDialog 
                    ref={el => this.wpForm = el} 
                    onVerify={(id) => this.onVerify(id)}
                />

                <PaymentInformationDialog
                    ref={el => this.paymentForm = el}
                    onSubmit={(id) => this.onSubmit(id)}
                />

                <div className="p-col-12">
                    <Fieldset legend="Filter">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12 p-md-4">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <label className="p-label">Name</label>
                                        <InputText  value={this.state.filter.name} onKeyUp={this.handleKeyPress} onChange={e =>this.setState({filter: { ...this.state.filter, name: e.target.value }})}/>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <label className="p-label">Phone Number</label>
                                        <InputText keyfilter="int"  value={this.state.filter.cellphone} onKeyUp={this.handleKeyPress} onChange={e =>this.setState({filter: { ...this.state.filter, cellphone: e.target.value }})}/>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <label className="p-label">Work Permit Number</label>
                                        <InputText  value={this.state.filter.docNumber} onKeyUp={this.handleKeyPress} onChange={e =>this.setState({filter: { ...this.state.filter, docNumber: e.target.value }})}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" label="Search" onClick={this.applyFilter} />
                                <Button className="p-button-warning" icon="pi pi-trash" label="Clear" onClick={this.clearFilter} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <DataTable 
                        lazy={true} 
                        first={this.state.page * this.state.rows}
                        value={this.state.dataList}
                        responsive={true}
                        header={header}

                        // ---Paginator--- 
                        paginator={true}
                        onPage={this.onPage} onSort={this.onSort}
                        rows={this.state.rows} totalRecords={this.state.total}
                        // ---Paginator--- 

                        onSelectionChange={e => this.setState({ selectedRow: e.value })}
                        rowHover
                        selectionMode="single" 

                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        paginatorPosition="top,bottom"
                    >
                        <Column header="Name" field="user.name" sortable={true} 
                            bodyClassName="text-overflow-none" body={(rowData) => (<strong>{`${rowData.user.firstName} ${rowData.user.lastName}`}</strong>)}
                        />
                        <Column header="Type" sortable={true} body={(rowData) => (<strong>{rowData.membership.name.toUpperCase()}</strong>)}/>
                        <Column header="Verify WP" field="verified" sortable={true} 
                            bodyClassName="text-overflow-none" body={(rowData) => {
                                return (
                                    <Button label={rowData.verified?"Verified":"Verify"} style={{ width: '100%'}} 
                                    disabled={rowData.verified} 
                                    onClick={() => this.wpForm.openDialog(rowData)}/>
                                )
                            }}
                        />
                        <Column header="Actions"
                            bodyClassName="text-overflow-none" body={(rowData) => {
                                return (
                                    <Button
                                        label={rowData.membershipPaymentStatus !== STATUS.PENDING ? "Received" : "Receive Payment"}
                                        onClick={() => this.paymentForm.openDialog(rowData)}
                                        disabled={!rowData.verified || rowData.membershipPaymentStatus !== STATUS.PENDING }
                                        style={{ width: '100%'}}
                                    />
                                )
                            }}
                        />
                        <Column header="Status" 
                            className="p-c" 
                            sortable={true}
                            style={{ textAlign: 'center' }}
                            body={(rowData) => {
                                return <Tag status={rowData.membershipPaymentStatus} />;
                            }}/>
                    </DataTable>
                </div>
            </div>
        )
    }
}

const STATUS = {
    PAID: 'paid',
    PENDING: 'pending'
};

function Tag({ status }) {
    let icon = '';
    let color = '#000';
    let bgColor = '#fff';
    if (status === STATUS.PENDING) {
        color = '#1890FF';
        bgColor = '#E6F7FF';
        icon = 'pi-spin pi-spinner';
    }
    if (status === STATUS.PAID) {
        color = '#77C41A';
        bgColor = '#F6FFED';
        icon = 'pi-check-circle';
    }
    return (
        <div
            className="p-d-flex p-align-center p-justify-center"
            style={{
                border: `1px solid ${color}ba`,
                borderRadius: '8px',
                width: '9em',
                padding: '3px 15px',
                margin: 'auto',
                color: `${color}`,
                background: `${bgColor}`,
            }}
        >
            <i className={`pi ${icon} p-mr-2`}></i>
            <span>{status}</span>
        </div>
    );
}
