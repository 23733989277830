import React, { Component } from "react";
import { WebErrorServices } from "./WebErrorServices";
import { Link } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

export default class WebErrorDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
      
    }
    this.webErrorServices = new WebErrorServices();
  }

  componentDidMount() {
    this.getError();
  }

  getError = () => {
    this.webErrorServices.getWebError(this.props.match.params.id)
      .then(res => {
        if(!res.errorCode){
          this.setState({
            data: res ? res : {}
          });
        }
      });
  }

  render() {
    return (
      <div className="p-grid">
        <BreadcrumbsItem to="/web-errors">Web Errors</BreadcrumbsItem>
        <BreadcrumbsItem to={this.props.match.url}>{this.state.data.request}</BreadcrumbsItem>
        <div className="p-col-12">
          <div className="card card-w-title">
            <h1>
              {/* {this.state.property.name} */}
              <div className="p-toolbar-group-right">
                <Link to={`/web-errors`} className="p-button p-component p-button-icon-only">
                    <span className="pi pi-list p-c p-button-icon-left"><span className="p-button-text p-c"></span></span>
                </Link>
            </div>
            </h1>
          </div>
        </div>
        <div className="p-col-12">
          <TabView >
            <TabPanel header="Information">
              <div className="p-grid p-fluid form-group">
              <div className="p-col-12">
                  <label className="p-label">Code: </label>
                  <div >{this.state.data.code}</div>
                </div>
                <div className="p-col-12 p-md-6">
                  <label className="p-label">Request: </label>
                  <div >{this.state.data.request}</div>
                </div>
                <div className="p-col-12 p-md-6">
                  <label className="p-label">Message: </label>
                  <div >{this.state.data.message}</div>
                </div>
                <div className="p-col-12">
                  <label className="p-label">Errors: </label>
                  <div >{this.state.data.stackTrace}</div>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    );
  }
}
