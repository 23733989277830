import CKEditor from 'ckeditor4-react';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import React from 'react';
import { APPLICATION_RECORD_STATUS, APPLICATION_TYPE, APPLICATION_VALUE_TYPE, ATTRIBUTE_GROUP_TYPE, ATTRIBUTE_VALUE_LIST_TYPE } from '../../constants';
import { showErrorNotify, showNotification, showSuccessNotify } from '../../core/service/NotificationService';
import { isValidEmail } from '../../core/utils/ValidateUtils';
import { getMailTemplates } from '../../mail-management/mail-template/MailTemplateServices';
import { AttributeServices, getListAttributeSets, getListAttributeValueList } from '../attribute/AttributeServices';
import { ApplicationContentForm } from './ApplicationContentForm';
import { deleteApplication, downloadFormTemplateForApplication, getApplications, saveApplication, uploadFormTemplateForApplication } from './ApplicationService';
import { OrderList } from 'primereact/orderlist';
import { showloading, stoploading } from '../../core/service/LoadingService';
import moment from 'moment';
CKEditor.editorUrl = "/assets/ckeditor/ckeditor.js";

const fileDownload = require('js-file-download');

const ACTION_VALUE_TYPE={
    required: 'required',
    joined: 'joined',
    approved: 'approved',
    rejected: 'rejected',
}
export default class ApplicationForm extends React.Component {
    constructor() {
        super();

        this.state = {
            data: {},
            errors: {},
            filter: {},
            formHeader: "New Application",
            fieldSetOptions: [],
            mailTemplates: [],
            applications: [],
            visible: false,
            file: null
        }

        this.attributeServices = new AttributeServices();
    }

    componentDidMount() {
        this.loadFieldSetOptions();
        this.loadMailTemplates()
        this.loadAttributeValueList()
    }

    loadFieldSetOptions = () => {
        getListAttributeSets(ATTRIBUTE_GROUP_TYPE.custom_field.value)
        .then(data => {
            this.setState({
                fieldSetOptions: data
            })
        })
    }

    loadMailTemplates = () => {
        getMailTemplates()
        .then(res => {
            let dataTemplates = res?res:[];
            dataTemplates.unshift({value: '', label: 'Not use'});

            this.setState({
                mailTemplates: dataTemplates
            })
        })
    }

    loadAttributeValueList = () => {
        getListAttributeValueList(ATTRIBUTE_VALUE_LIST_TYPE.normal.value, false)
        .then(res => {
            this.setState({attributeValues: res?res:[]});
        })
    }

    loadApplications = () => {
        getApplications(null, false)
        .then(res => {
            this.setState({applications: res?res:[]});
        })
    }

    popularFormData = (data) => {
        this.setState({
            data: {
                id: data ? data.id : '',
                name: data ? data.name : '',
                type: data&&data.type ? data.type : APPLICATION_TYPE.normal.value,
                description: data ? data.description : '',
                alias : data ? data.alias : '',
                moderator: data&&data.moderator ? data.moderator : [],
                requiredCaptcha: data&&data.requiredCaptcha,
                requiredLogin: data&&data.requiredLogin,
                singleEntry: data&&data.singleEntry,
                promotedForUser: data&&data.promotedForUser,
                fieldSetId: data ? data.fieldSetId : null,
                contents: data&&data.contents?data.contents.map((c, index) => {return { ...c, idx: (c.idx||c.idx===0?c.idx:index)}}):[],
                requiredValues: data&&data.requiredValues ? data.requiredValues : [],
                appliedSetting: data&&data.appliedSetting ? data.appliedSetting : {},
                approvalSetting: data&&data.approvalSetting ? data.approvalSetting : {},
                weight: data&&data.weight ? data.weight : 0,
                contentComplete: data ? data.contentComplete : '',
                ratingGroup: data&&data.ratingGroup ? data.ratingGroup : '',
                formTemplate: data?.formTemplate
            },
            tmpRequiredValue: {},
            isShowRequiredValueForm: false,
            tmpJoinedValue: {},
            isShowJoinedValueForm: false,
            tmpApprovedValue: {},
            isShowApprovedValueForm: false,
            tmpRejectedValue: {},
            isShowRejectedValueForm: false,
            formHeader: data&&data.id?'Edit Application':'New Application',
            visible: true
        })
        this.loadApplications()
    }
    
    hideForm = () => {
        this.setState({
            visible: false,
            data: {},
            errors: {},
            file: null
        })
        if(this.inputUpload && this.inputUpload.current)
            this.inputUpload.current.value=''
    }

    handleSave = () => {
        this.setState({ errors: {} });
        saveApplication(this.state.data)
        .then(res => {
            if(!res.errorCode){
                if(this.props.reloadTable)
                    this.props.reloadTable();
                if(this.props.reloadData)
                    this.props.reloadData(res);
                this.hideForm();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj})
            }
        })
    }

    removeApplication = () => {
        deleteApplication(this.state.data.id)
        .then(res => {
            if(!res.errorCode){
                if(this.props.reloadTable)
                    this.props.reloadTable();
                this.hideForm();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    handleUpload = () => {
        uploadFormTemplateForApplication(this.state.data.id, this.state.file)
        .then(res => {
            if(!res.errorCode){
                this.setState({data: {...this.state.data, formTemplate: res.formTemplate}})
                if(this.inputUpload && this.inputUpload.current)
                    this.inputUpload.current.value=''
                showSuccessNotify("Successful upload!")
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    handleDownload = () => {
        let suf = moment().format('YYYYMMDDHHmmss');
        let fileName = (this.state.data.name.replaceAll(" ", "_"))+ '_' + suf + '.pdf';

        showloading()
        downloadFormTemplateForApplication(this.state.data.id)
        .then(res => {
            if(!res.errorCode){
                fileDownload(res, fileName);
                showSuccessNotify("Download successfully!")
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
        .finally(() => stoploading())
    }

    onAddOrEditContent = (idx, data) => {
        this.appContentForm.openForm(data)
        this.setState({
            idxContent: idx||idx===0?idx:(this.state.data.contents?this.state.data.contents.length:0)
        })
    }

    onSubmitContent = (content) => {
        let tmpContentArr = this.state.data.contents?[...this.state.data.contents]:[]
        let idx = this.state.idxContent
        
        content.idx=idx

        if(!idx && idx!==0){
            tmpContentArr.push(content)
        }else{
            tmpContentArr.splice(idx, 1, content)
        }
        this.setState({
            data:{...this.state.data,
                contents: tmpContentArr
            }
        })
    }

    onRemoveContent = (idx) => {
        let tmpContentArr = this.state.data.contents?[...this.state.data.contents]:[]
        tmpContentArr.splice(idx, 1)
        this.setState({
            data:{...this.state.data,
                contents: tmpContentArr
            }
        })
    }

    onChangeAppliedSetting = (property, value) => {
        this.setState({
            data: {...this.state.data,
                appliedSetting: {...this.state.data.appliedSetting,
                    [property]: value,
                }
            }
        })
    }

    onChangeApprovalSetting = (property, value) => {
        if(property==='attributeListId'){
            this.setState({
                data: {...this.state.data,
                    approvalSetting: {...this.state.data.approvalSetting,
                        [property]: value,
                        requiredClassification: property!=='attributeListId'&&this.state.data.approvalSetting?this.state.data.approvalSetting.requiredClassification:null,
                        receiveClassifiation: property!=='attributeListId'&&this.state.data.approvalSetting?this.state.data.approvalSetting.receiveClassifiation:null,
                    }
                }
            })
        }else{
            this.setState({
                data: {...this.state.data,
                    approvalSetting: {...this.state.data.approvalSetting,
                        [property]: value,
                    }
                }
            })
        }
    }

    onAddEmailInput = (e) => {
        let tmpArr = this.state.data.moderator?[...this.state.data.moderator]:[]
        let isValueValid = isValidEmail(e.value)

        if(!isValueValid){
            this.setState({errors: {moderator: 'wrong email format'}})
            return
        }
        if(tmpArr.includes(e.value)){
            this.setState({errors: {moderator: 'Value is exist'}})
            return
        }
        tmpArr.push(e.value)
        this.setState({
            data: {...this.state.data,
                moderator: tmpArr
            }
        })
        this.setState({errors: {moderator: ''}})
    }

    onRemoveInputEmail = (e) => {
        let tmpArr = this.state.data.moderator?[...this.state.data.moderator]:[]
        let tmpValue = e.value[0]
        tmpArr.splice(tmpArr.indexOf(tmpValue), 1)
        this.setState({
            data: {...this.state.data,
                moderator: tmpArr
            }
        })
    }

    renderOptions = (list) => {
        if(!list) return []
        return list.map(item => {return {value: item.id, label: item.name}})
    }

    renderValueOptions = (attributeListId, excludeValue) => {
        let attributeValues = this.state.attributeValues?[...this.state.attributeValues]:[]
        let tmpAttributeValue = attributeListId?attributeValues.find(item => item.id===attributeListId):null
        
        if(tmpAttributeValue){
            let tmpValueOptions = tmpAttributeValue.values?tmpAttributeValue.values.map(value => {return {value: value, label: value}}):[]
            if(excludeValue){
                return tmpValueOptions.filter(item => item.value!==excludeValue)
            }
            return tmpValueOptions
        }
        return []
    }

    onAddOrEditValue = (type, idx) => {
        let approvalSetting = this.state.data.approvalSetting?this.state.data.approvalSetting:{}
        let tmpData={type: APPLICATION_VALUE_TYPE.user_classification.value, attributeListId: null, values: []}
        switch(type){
            case ACTION_VALUE_TYPE.required:
                if(idx || idx===0) tmpData = {...this.state.data.requiredValues[idx], index: idx}
                
                this.setState({tmpRequiredValue: tmpData, isShowRequiredValueForm: true})
            break;
            case ACTION_VALUE_TYPE.joined:
                if(idx || idx===0) tmpData = {...this.state.data.appliedSetting.joinedValues[idx], index: idx}
                this.setState({tmpJoinedValue: tmpData, isShowJoinedValueForm: true})
            break;
            case ACTION_VALUE_TYPE.approved:
                if(idx || idx===0) tmpData = {...approvalSetting.approvedValues[idx], index: idx}
                this.setState({tmpApprovedValue: tmpData, isShowApprovedValueForm: true})
            break;
            case ACTION_VALUE_TYPE.rejected:
                if(idx || idx===0) tmpData = {...approvalSetting.rejectedValues[idx], index: idx}
                this.setState({tmpRejectedValue: tmpData, isShowRejectedValueForm: true})
            break;
            default: break
        }
    }

    onSubmitValue = (type) => {
        let approvalSetting = this.state.data.approvalSetting?this.state.data.approvalSetting:{}
        switch(type){
            case ACTION_VALUE_TYPE.required:
                let tmpRequiredArr = this.state.data.requiredValues?[...this.state.data.requiredValues]:[]
                let tmpRequiredIdx = this.state.tmpRequiredValue.index
                if(tmpRequiredIdx || tmpRequiredIdx===0){
                    tmpRequiredArr.splice(tmpRequiredIdx, 1, this.state.tmpRequiredValue)
                }else{
                    tmpRequiredArr.push(this.state.tmpRequiredValue)
                }
                this.setState({data: {...this.state.data, requiredValues: tmpRequiredArr}, tmpRequiredValue: {}, isShowRequiredValueForm: false})
            break;
            case ACTION_VALUE_TYPE.joined:
                let tmpJoinedArr = this.state.data.appliedSetting.joinedValues?[...this.state.data.appliedSetting.joinedValues]:[]
                let tmpJoinedIdx = this.state.tmpJoinedValue.index
                if(tmpJoinedIdx || tmpJoinedIdx===0){
                    tmpJoinedArr.splice(tmpJoinedIdx, 1, this.state.tmpJoinedValue)
                }else{
                    tmpJoinedArr.push(this.state.tmpJoinedValue)
                }
                this.setState({data: {...this.state.data, appliedSetting: {...this.state.data.appliedSetting, joinedValues: tmpJoinedArr}}, tmpJoinedValue: {}, isShowJoinedValueForm: false})
            break;
            case ACTION_VALUE_TYPE.approved:
                let tmpApprovedArr = approvalSetting.approvedValues?[...approvalSetting.approvedValues]:[]
                let tmpApprovredIdx = this.state.tmpApprovedValue.index
                if(tmpApprovredIdx || tmpApprovredIdx===0){
                    tmpApprovedArr.splice(tmpApprovredIdx, 1, this.state.tmpApprovedValue)
                }else{
                    tmpApprovedArr.push(this.state.tmpApprovedValue)
                }
                this.setState({data: {...this.state.data, approvalSetting: {...this.state.data.approvalSetting, approvedValues: tmpApprovedArr}}, tmpApprovedValue: {}, isShowApprovedValueForm: false})
            break;
            case ACTION_VALUE_TYPE.rejected:
                let tmpRejectedArr = approvalSetting.rejectedValues?[...approvalSetting.rejectedValues]:[]
                let tmpRejectedIdx = this.state.tmpRejectedValue.index
                if(tmpRejectedIdx || tmpRejectedIdx===0){
                    tmpRejectedArr.splice(tmpRejectedIdx, 1, this.state.tmpRejectedValue)
                }else{
                    tmpRejectedArr.push(this.state.tmpRejectedValue)
                }
                this.setState({data: {...this.state.data, approvalSetting: {...this.state.data.approvalSetting, rejectedValues: tmpRejectedArr}}, tmpRejectedValue: {}, isShowRejectedValueForm: false})
            break;
            default: break
        }    
    }

    onRemoveValue = (type, idx) => {
        let approvalSetting = this.state.data.approvalSetting?this.state.data.approvalSetting:{}
        switch(type){
            case ACTION_VALUE_TYPE.required:
                let tmpRequiredArr = [...this.state.data.requiredValues]
                if(tmpRequiredArr.length===1){
                    tmpRequiredArr = []
                }else{
                    tmpRequiredArr.splice(idx, 1)
                }
                this.setState({data: {...this.state.data, requiredValues: tmpRequiredArr}})
            break;
            case ACTION_VALUE_TYPE.joined:
                let tmpJoinedArr = [...this.state.data.appliedSetting.joinedValues]
                if(tmpJoinedArr.length===1){
                    tmpJoinedArr = []
                }else{
                    tmpJoinedArr.splice(idx, 1)
                }
                this.setState({data: {...this.state.data, appliedSetting: {...this.state.data.appliedSetting, joinedValues: tmpJoinedArr}}})
            break;
            case ACTION_VALUE_TYPE.approved:
                let tmpApprovedArr = [...approvalSetting.approvedValues]
                if(tmpApprovedArr.length===1){
                    tmpApprovedArr = []
                }else{
                    tmpApprovedArr.splice(idx, 1)
                }
                this.setState({data: {...this.state.data, approvalSetting: {...this.state.data.approvalSetting, approvedValues: tmpApprovedArr}}})
            break;
            case ACTION_VALUE_TYPE.rejected:
                let tmpRejectedArr = [...approvalSetting.rejectedValues]
                if(tmpRejectedArr.length===1){
                    tmpRejectedArr = []
                }else{
                    tmpRejectedArr.splice(idx, 1)
                }
                this.setState({data: {...this.state.data, approvalSetting: {...this.state.data.approvalSetting, rejectedValues: tmpRejectedArr}}})
            break;
            default: break
        }    
    }

    onCancelValue = (type) => {
        let tmpData={type: APPLICATION_VALUE_TYPE.user_classification.value, attributeListId: null, values: []}
        switch(type){
            case ACTION_VALUE_TYPE.required:
                this.setState({tmpRequiredValue: tmpData, isShowRequiredValueForm: false})
            break;
            case ACTION_VALUE_TYPE.joined:
                this.setState({tmpJoinedValue: tmpData, isShowJoinedValueForm: false})
            break;
            case ACTION_VALUE_TYPE.approved:
                this.setState({tmpApprovedValue: tmpData, isShowApprovedValueForm: false})
            break;
            case ACTION_VALUE_TYPE.rejected:
                this.setState({tmpRejectedValue: tmpData, isShowRejectedValueForm: false})
            break;
            default: break
        }
    }

    onChangeValueByType = (type, property, value) => {
        switch(type){
            case ACTION_VALUE_TYPE.required:
                if(property==='attributeListId'){
                    this.setState({
                        tmpRequiredValue: {...this.state.tmpRequiredValue,
                            [property]: value,
                            requiredClassification: null,
                            receiveClassifiation: null,
                        }
                    })
                }else{
                    this.setState({tmpRequiredValue: {...this.state.tmpRequiredValue, [property]: value}})
                }
            break;
            case ACTION_VALUE_TYPE.joined:
                if(property==='attributeListId'){
                    this.setState({
                        tmpJoinedValue: {...this.state.tmpJoinedValue,
                            [property]: value,
                            requiredClassification: null,
                            receiveClassifiation: null,
                        }
                    })
                }else{
                    this.setState({tmpJoinedValue: {...this.state.tmpJoinedValue, [property]: value}})
                }
            break;
            case ACTION_VALUE_TYPE.approved:
                if(property==='attributeListId'){
                    this.setState({
                        tmpApprovedValue: {...this.state.tmpApprovedValue,
                            [property]: value,
                            requiredClassification: null,
                            receiveClassifiation: null,
                        }
                    })
                }else{
                    this.setState({tmpApprovedValue: {...this.state.tmpApprovedValue, [property]: value}})
                }
            break;
            case ACTION_VALUE_TYPE.rejected:
                if(property==='attributeListId'){
                    this.setState({
                        tmpRejectedValue: {...this.state.tmpRejectedValue,
                            [property]: value,
                            requiredClassification: null,
                            receiveClassifiation: null,
                        }
                    })
                }else{
                    this.setState({tmpRejectedValue: {...this.state.tmpRejectedValue, [property]: value}})
                }
            break;
            default: break
        }    
    }

    renderValueForm = (type) => {
        let tmpData
        let isShowForm = false
        
        switch(type){
            case ACTION_VALUE_TYPE.required:
                tmpData = this.state.tmpRequiredValue?this.state.tmpRequiredValue:{}
                isShowForm = this.state.isShowRequiredValueForm
                break;
            case ACTION_VALUE_TYPE.joined:
                tmpData = this.state.tmpJoinedValue?this.state.tmpJoinedValue:{}
                isShowForm = this.state.isShowJoinedValueForm
                break;
            case ACTION_VALUE_TYPE.approved:
                tmpData = this.state.tmpApprovedValue?this.state.tmpApprovedValue:{}
                isShowForm = this.state.isShowApprovedValueForm
                break;
            case ACTION_VALUE_TYPE.rejected:
                tmpData = this.state.tmpRejectedValue?this.state.tmpRejectedValue:{}
                isShowForm = this.state.isShowRejectedValueForm
                break;
            default: break
        }

        if(isShowForm)
            return(
                <Fieldset>
                    <div className="p-grid p-fluid">
                        {type===ACTION_VALUE_TYPE.required && 
                            <div className="p-col-12 p-md-4">
                                <label className="p-label">Type</label>
                                <Dropdown value={tmpData.type} options={Object.values(APPLICATION_VALUE_TYPE)} onChange={e => this.onChangeValueByType(type, 'type', e.value)} />
                            </div>
                        }
                        {(()=>{
                            if(tmpData.type){
                                switch(tmpData.type){
                                    case APPLICATION_VALUE_TYPE.user_classification.value:
                                        return <React.Fragment>
                                            <div className={"p-col-12 "+(type===ACTION_VALUE_TYPE.required?"p-md-4":"p-md-6")}>
                                                <label className="p-label">Classification</label>
                                                <Dropdown value={tmpData.attributeListId} options={this.renderOptions(this.state.attributeValues)} onChange={e => this.onChangeValueByType(type, 'attributeListId', e.value)} />
                                            </div>
                                            <div className={"p-col-12 "+(type===ACTION_VALUE_TYPE.required?"p-md-4":"p-md-6")}>
                                                <label className="p-label">Classification Value</label>
                                                {tmpData.attributeListId ?
                                                    <Dropdown value={tmpData.values&&tmpData.values.length>0?tmpData.values[0]:null} options={this.renderValueOptions(tmpData.attributeListId)} onChange={e => this.onChangeValueByType(type, 'values', e.value?[e.value]:[])} />
                                                    :
                                                    <InputText value={tmpData.values&&tmpData.values.length>0?tmpData.values[0]:''} onChange={e => this.onChangeValueByType(type, 'values', e.target.value?[e.target.value]:[])} />
                                                }
                                            </div>    
                                        </React.Fragment>
                                    case APPLICATION_VALUE_TYPE.app_record_status.value:
                                        let tmpApplicationItem = this.state.applications.find(app=>app.id===tmpData.applicationId)
                                        let isUseApproval = tmpApplicationItem && tmpApplicationItem.type===APPLICATION_TYPE.approval.value
                                        
                                        return <React.Fragment>
                                            <div className={"p-col-12 "+(type===ACTION_VALUE_TYPE.required?"p-md-4":"p-md-6")}>
                                                <label className="p-label">Application</label>
                                                <Dropdown value={tmpData.applicationId} options={this.renderOptions(this.state.applications)} onChange={e => this.onChangeValueByType(type, 'applicationId', e.value)} />
                                            </div>
                                            {isUseApproval &&
                                                <div className={"p-col-12 "+(type===ACTION_VALUE_TYPE.required?"p-md-4":"p-md-6")}>
                                                    <label className="p-label">Status</label>
                                                    <Dropdown value={tmpData.status} options={Object.values(APPLICATION_RECORD_STATUS)} onChange={e => this.onChangeValueByType(type, 'status', e.value)} />
                                                </div>
                                            }
                                        </React.Fragment>
                                    default: break;
                                }
                            }
                        })()}
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r">
                            <Button label="Submit" onClick={()=>this.onSubmitValue(type)} style={{width: 'auto'}} />
                            <Button label="Cancel" onClick={() => this.onCancelValue(type)} style={{width: 'auto'}} />
                        </div>
                    </div>          
                </Fieldset>
            )
    }

    onContentChange = (contents) =>{
        let tmpArr = contents.map((c, index) => {return {...c, idx: index}})
        this.setState({
            data:{...this.state.data, 
                contents: tmpArr
            }
        })
    }

    contentTemplate = (content) => {
        return (
            <div className="p-grid">
                <div className="p-col-12 p-lg-6">{content.title}</div>
                <div className="p-col-12 p-lg-6 p-r">
                    <Button className="p-button-info" label="Edit" icon="pi pi-pencil" onClick={() => this.onAddOrEditContent(content.idx, content)} style={{width: 'auto'}} />
                    <Button className="p-button-danger" label="Remove" icon="pi pi-times" onClick={() => this.onRemoveContent(content.idx)} style={{width: 'auto'}} />
                </div>
            </div>
        )
    }

    render() {
        let data = this.state.data
        let appliedSetting = data.appliedSetting?data.appliedSetting:{}
        let approvalSetting = data.approvalSetting?data.approvalSetting:{}
        let tmpRequiredValue = this.state.tmpRequiredValue?this.state.tmpRequiredValue:{}
        let tmpJoinedValue = this.state.tmpJoinedValue?this.state.tmpJoinedValue:{}
        let tmpApprovedValue = this.state.tmpApprovedValue?this.state.tmpApprovedValue:{}
        let tmpRejectedValue = this.state.tmpRejectedValue?this.state.tmpRejectedValue:{}

        return(
            <React.Fragment>
                <ApplicationContentForm ref={el=>this.appContentForm=el} 
                    onSubmit={content => this.onSubmitContent(content)}
                />

                <Sidebar visible={this.state.visible} className="p-sidebar-lg" style={{ overflowY: "auto" }} position="right" blockScroll={true} dismissable={false} showCloseIcon={false} onHide={this.hideForm} >
                    <div className="p-d-flex p-justify-between ">
                        <h2 className="p-margin-top-10">{this.state.formHeader}</h2>
                        <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={this.hideForm}/>
                    </div>
                    
                    <div className="p-sidebar-line p-mb-3"></div>
                    
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <Fieldset legend="Information">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <label className="p-label">* Alias</label>
                                        <InputText value={data.alias} keyfilter={/[^\s]/} onChange={(e) => this.setState({ data: { ...data, alias: e.target.value } })} />
                                        <div className="p-form-error">{this.state.errors.alias}</div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">* Name</label>
                                        <InputText value={data.name} onChange={(e) => this.setState({ data: { ...data, name: e.target.value } })} />
                                        <div className="p-form-error">{this.state.errors.name}</div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">* Type</label>
                                        <Dropdown value={data.type} options={Object.values(APPLICATION_TYPE)} onChange={(e) => this.setState({ data: { ...data, type: e.target.value } })} />
                                        <div className="p-form-error">{this.state.errors.type}</div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">Moderator Email</label>
                                        <Chips value={data.moderator?data.moderator:[]} allowDuplicate={false} onAdd={e=> this.onAddEmailInput( e)} onRemove={e => this.onRemoveInputEmail(e)} placeholder="Enter email" />
                                        <div className="p-form-error">{this.state.errors.moderator}</div>
                                    </div>
                                    <div className="p-col-12">
                                        <Checkbox inputId="ckRequiredCaptcha" checked={data.requiredCaptcha} onChange={e => this.setState({ data: { ...data, requiredCaptcha: e.checked } })}  />
                                        <label htmlFor="ckRequiredCaptcha">Require Captcha?</label>
                                    </div>
                                    <div className="p-col-12">
                                        <Checkbox inputId="ckRequiredLogin" checked={data.requiredLogin} onChange={e => this.setState({ data: { ...data, requiredLogin: e.checked } })}  />
                                        <label htmlFor="ckRequiredLogin">Login required?</label>
                                    </div>
                                    <div className="p-col-12">
                                        <Checkbox inputId="ckSingleEntry" checked={data.singleEntry} onChange={e => this.setState({ data: { ...data, singleEntry: e.checked } })}  />
                                        <label htmlFor="ckSingleEntry">Single Entry?</label>
                                    </div>
                                    <div className="p-col-12">
                                        <Checkbox inputId="ckPromotedForUser" checked={data.promotedForUser} onChange={e => this.setState({ data: { ...data, promotedForUser: e.checked } })}  />
                                        <label htmlFor="ckPromotedForUser">Promoted for user?</label>
                                    </div>
                                    {data?.id &&
                                        <div className="p-col-12">
                                            <lable className="p-label">Form Template (.pdf) <i>(Template is available!)</i></lable>
                                            <div className="p-inputgroup">
                                                <input ref={el => this.inputUpload=el} className="p-component p-inputtext" type="file" accept='application/pdf' onChange={(e) => this.setState({file: e.target.files[0]})} />
                                                {data.formTemplate && <Button label='Download' icon="pi pi-download" onClick={() => this.handleDownload()} />}
                                                <Button label='Upload' icon="pi pi-upload" onClick={() => this.handleUpload()} />
                                            </div>
                                        </div>
                                    }
                                    <div className="p-col-12">
                                        <label className="p-label">Weight (for order)</label>
                                        <InputText value={data.weight} keyfilter="int" onChange={(e) => this.setState({ data: { ...data, weight: e.target.value } })} />
                                        <div className="p-form-error">{this.state.errors.weight}</div>
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">Description</label>
                                        <CKEditor data={data.description} onChange={(e) => this.setState({ data: { ...data, description: e.editor.getData() } })} />
                                    </div>
                                    <div className="p-col-12">
                                        <label className="p-label">Content Complete (display after completion)</label>
                                        <CKEditor data={data.contentComplete} onChange={(e) => this.setState({ data: { ...data, contentComplete: e.editor.getData() } })} />
                                    </div>
                                    {/* <div className="p-col-12">
                                        <label className="p-label">* Type</label>
                                        <Dropdown className="p-col-12" options={APPLICATION_OPTIONS} value={this.state.data.type} onChange={(e) => this.setState({data: {...this.state.data, type: e.value}})} placeholder="Select a type"/>
                                        <div className="p-form-error">{this.state.errors.type}</div>
                                    </div> */}
                                    {/* <div className="p-col-12">
                                        <label className="p-label">* Field Set</label>
                                        <Dropdown className="p-col-12" options={this.state.fieldSetOptions} value={this.state.data.fieldSetId} onChange={(e) => this.setState({data: {...this.state.data, fieldSetId: e.value}})} placeholder="Select a field set"/>
                                        <div className="p-form-error">{this.state.errors.fieldSetId}</div>
                                    </div> */}
                                    <div className="p-col-12">
                                        <Fieldset legend="Step Configuration">
                                            <div className="p-grid">
                                                <div className="p-col-12">
                                                    <Button label="Add new Step" onClick={() => this.onAddOrEditContent()} style={{width: 'auto'}} />
                                                </div>
                                                {data.contents && data.contents.length>0 &&
                                                <React.Fragment>
                                                    <div className="p-col-12"><hr /></div>
                                                    <div className="p-col-12">
                                                        <OrderList value={data.contents?data.contents:[]} itemTemplate={this.contentTemplate} onChange={(e) => this.onContentChange(e.value)} responsive={true} listStyle={{height: 'auto'}}></OrderList>
                                                        {/* {data.contents && data.contents.map((content, index) => {
                                                            return <div className="p-grid">
                                                                <div className="p-col-12 p-lg-6">{content.title}</div>
                                                                <div className="p-col-12 p-lg-6 p-r">
                                                                    <Button className="p-button-warning" icon="pi pi-pencil" onClick={() => this.onAddOrEditContent(index, content)} />
                                                                    <Button className="p-button-danger" icon="pi pi-times" onClick={() => this.onRemoveContent(index)} />
                                                                </div>
                                                            </div>
                                                        })} */}
                                                    </div>
                                                </React.Fragment>
                                                }
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12">
                            <Fieldset legend="Requirement">
                                <div className="p-grid">
                                    {!this.state.isShowRequiredValueForm ?
                                        <div className="p-col-12">
                                            <Button label="Add Value" onClick={() => this.onAddOrEditValue(ACTION_VALUE_TYPE.required)} style={{width: 'auto'}} />
                                        </div>
                                        :
                                        <div className="p-col-12">
                                            {this.renderValueForm(ACTION_VALUE_TYPE.required)}
                                        </div>
                                    }
                                    {data.requiredValues && data.requiredValues.length>0 &&
                                        <div className="p-col-12">
                                            <Fieldset legend="Validation Values">
                                                {data.requiredValues.map((item, idx) => {
                                                    let tmpApplicationItem = item.type===APPLICATION_VALUE_TYPE.app_record_status.value?this.state.applications.find(app=>app.id===item.applicationId):{}
                                                    return (
                                                        <div className="p-grid">
                                                            <div className="p-col-12 p-md-3">Type: {APPLICATION_VALUE_TYPE[item.type]&&APPLICATION_VALUE_TYPE[item.type].label}</div>
                                                            {item.type===APPLICATION_VALUE_TYPE.user_classification.value &&
                                                                <div className="p-col-12 p-md-6">Required classification: {item.values && item.values.join(', ')}</div>
                                                            }
                                                            {item.type===APPLICATION_VALUE_TYPE.app_record_status.value &&
                                                            <React.Fragment>
                                                                <div className={"p-col-12 "+(tmpApplicationItem&&tmpApplicationItem.type===APPLICATION_TYPE.approval.value?'p-md-3':'p-md-6')}>Application: {tmpApplicationItem&&tmpApplicationItem.name}</div>
                                                                {tmpApplicationItem&&tmpApplicationItem.type===APPLICATION_TYPE.approval.value&&
                                                                    <div className="p-col-12 p-md-3">Status: {APPLICATION_RECORD_STATUS[item.status]&&APPLICATION_RECORD_STATUS[item.status].label}</div>
                                                                }
                                                            </React.Fragment>
                                                            }
                                                            {tmpRequiredValue.index!==idx &&
                                                                <div className="p-col-12 p-md-3 p-r">
                                                                    <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.onAddOrEditValue(ACTION_VALUE_TYPE.required, idx)} />
                                                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => this.onRemoveValue(ACTION_VALUE_TYPE.required, idx)} />
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })}   
                                            </Fieldset>
                                        </div>
                                    }
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12">
                            <Fieldset legend="Actions">
                                <div className="p-gird p-fluid">
                                    <div className="p-col-12">
                                        <Fieldset legend={data.type===APPLICATION_TYPE.answer.value?'When Answered':'When applied'}>
                                            <div className="p-grid">
                                                {!this.state.isShowJoinedValueForm ?
                                                        <div className="p-col-12">
                                                            <Button label="Add Value" onClick={() => this.onAddOrEditValue(ACTION_VALUE_TYPE.joined)} style={{width: 'auto'}} />
                                                        </div>
                                                        :
                                                            <div className="p-col-12">
                                                            {this.renderValueForm(ACTION_VALUE_TYPE.joined)}
                                                        </div>
                                                    }
                                                    
                                                    {appliedSetting.joinedValues && appliedSetting.joinedValues.length>0 &&
                                                        <div className="p-col-12">
                                                            <Fieldset legend="Values">
                                                                {appliedSetting.joinedValues.map((item, idx) => {
                                                                    return (
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-md-6">Classification: {item.values && item.values.join(', ')}</div>
                                                                            {tmpJoinedValue.index!==idx &&
                                                                                <div className="p-col-12 p-md-6 p-r">
                                                                                    <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.onAddOrEditValue(ACTION_VALUE_TYPE.joined, idx)} />
                                                                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => this.onRemoveValue(ACTION_VALUE_TYPE.joined, idx)} />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}   
                                                            </Fieldset>
                                                        </div>
                                                    }
                                                <div className="p-col-12">
                                                    <Checkbox inputId="ckSenndEmailToParticippantWhenJoin" checked={appliedSetting.sendEmailToParticipantWhenJoined} onChange={e => this.onChangeAppliedSetting('sendEmailToParticipantWhenJoined', e.checked)} />
                                                    <label htmlFor="ckSenndEmailToParticippantWhenJoin">{data.type===APPLICATION_TYPE.answer.value?'Send email to Customer':'Send email to Participant'}</label>
                                                </div>
                                                {appliedSetting.sendEmailToParticipantWhenJoined &&
                                                    <div className="p-col-12">
                                                        <label className="p-label">Using below template</label>
                                                        <Dropdown value={appliedSetting.mailTemplateToParticipantWhenJoined} options={this.state.mailTemplates} onChange={e => this.onChangeAppliedSetting('mailTemplateToParticipantWhenJoined', e.value)} placeholder="Select template" />
                                                    </div>
                                                }
                                                <div className="p-col-12">
                                                    <Checkbox inputId="ckSenndEmailToModeratorWhenJoin" checked={appliedSetting.sendEmailToModeratorWhenJoined} onChange={e => this.onChangeAppliedSetting('sendEmailToModeratorWhenJoined', e.checked)} />
                                                    <label htmlFor="ckSenndEmailToModeratorWhenJoin">Send email to Moderator</label>
                                                </div>
                                                {appliedSetting.sendEmailToModeratorWhenJoined &&
                                                    <div className="p-col-12">
                                                        <label className="p-label">Using below template</label>
                                                        <Dropdown value={appliedSetting.mailTemplateToModeratorWhenJoined} options={this.state.mailTemplates} onChange={e => this.onChangeAppliedSetting('mailTemplateToModeratorWhenJoined', e.value)} placeholder="Select template" />
                                                    </div>
                                                }
                                            </div>
                                        </Fieldset>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        {data.type===APPLICATION_TYPE.approval.value &&
                            <div className="p-col-12">
                                <Fieldset legend="Approval Actions">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12">
                                            <Fieldset legend="When Approved">
                                                <div className="p-grid">
                                                    {!this.state.isShowApprovedValueForm ?
                                                        <div className="p-col-12">
                                                            <Button label="Add Value" onClick={() => this.onAddOrEditValue(ACTION_VALUE_TYPE.approved)} style={{width: 'auto'}} />
                                                        </div>
                                                        :
                                                            <div className="p-col-12">
                                                            {this.renderValueForm(ACTION_VALUE_TYPE.approved)}
                                                        </div>
                                                    }
                                                    
                                                    {approvalSetting.approvedValues && approvalSetting.approvedValues.length>0 &&
                                                        <div className="p-col-12">
                                                            <Fieldset legend="Assigned Values">
                                                                {approvalSetting.approvedValues.map((item, idx) => {
                                                                    return (
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-md-6">Required classification: {item.values && item.values.join(', ')}</div>
                                                                            {tmpApprovedValue.index!==idx &&
                                                                                <div className="p-col-12 p-md-6 p-r">
                                                                                    <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.onAddOrEditValue(ACTION_VALUE_TYPE.approved, idx)} />
                                                                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => this.onRemoveValue(ACTION_VALUE_TYPE.approved, idx)} />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}   
                                                            </Fieldset>
                                                        </div>
                                                    }
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckSenndEmailToParticippantWhenApproved" checked={approvalSetting.sendEmailToParticipantWhenApproved} onChange={e => this.onChangeApprovalSetting('sendEmailToParticipantWhenApproved', e.checked)} />
                                                        <label htmlFor="ckSenndEmailToParticippantWhenApproved">Send email to Participant</label>
                                                    </div>
                                                    {approvalSetting.sendEmailToParticipantWhenApproved &&
                                                        <div className="p-col-12">
                                                            <label className="p-label">Using below template</label>
                                                            <Dropdown value={approvalSetting.mailTemplateToParticipantWhenApproved} options={this.state.mailTemplates} onChange={e => this.onChangeApprovalSetting('mailTemplateToParticipantWhenApproved', e.value)} placeholder="Select template" />
                                                        </div>
                                                    }
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckSenndEmailToModeratorWhenApproved" checked={approvalSetting.sendEmailToModeratorWhenApproved} onChange={e => this.onChangeApprovalSetting('sendEmailToModeratorWhenApproved', e.checked)} />
                                                        <label htmlFor="ckSenndEmailToModeratorWhenApproved">Send email to Moderator</label>
                                                    </div>
                                                    {approvalSetting.sendEmailToModeratorWhenApproved &&
                                                        <div className="p-col-12">
                                                            <label className="p-label">Using below template</label>
                                                            <Dropdown value={approvalSetting.mailTemplateToModeratorWhenApproved} options={this.state.mailTemplates} onChange={e => this.onChangeApprovalSetting('mailTemplateToModeratorWhenApproved', e.value)} placeholder="Select template" />
                                                        </div>
                                                    }
                                                </div>
                                            </Fieldset>
                                        </div>
                                        <div className="p-col-12">
                                            <Fieldset legend="When Rejected">
                                                <div className="p-grid">
                                                {!this.state.isShowRejectedValueForm ?
                                                        <div className="p-col-12">
                                                            <Button label="Add Value" onClick={() => this.onAddOrEditValue(ACTION_VALUE_TYPE.rejected)} style={{width: 'auto'}} />
                                                        </div>
                                                        :
                                                            <div className="p-col-12">
                                                            {this.renderValueForm(ACTION_VALUE_TYPE.rejected)}
                                                        </div>
                                                    }
                                                    
                                                    {approvalSetting.rejectedValues && approvalSetting.rejectedValues.length>0 &&
                                                        <div className="p-col-12">
                                                            <Fieldset legend="Assigned Values">
                                                                {approvalSetting.rejectedValues.map((item, idx) => {
                                                                    return (
                                                                        <div className="p-grid">
                                                                            <div className="p-col-12 p-md-6">Required classification: {item.values && item.values.join(', ')}</div>
                                                                            {tmpRejectedValue.index!==idx &&
                                                                                <div className="p-col-12 p-md-6 p-r">
                                                                                    <Button icon="pi pi-pencil" className="p-button-warning" onClick={() => this.onAddOrEditValue(ACTION_VALUE_TYPE.rejected, idx)} />
                                                                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => this.onRemoveValue(ACTION_VALUE_TYPE.rejected, idx)} />
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })}   
                                                            </Fieldset>
                                                        </div>
                                                    }
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckSenndEmailToParticippantWhenRejected" checked={approvalSetting.sendEmailToParticipantWhenRejected} onChange={e => this.onChangeApprovalSetting('sendEmailToParticipantWhenRejected', e.checked)} />
                                                        <label htmlFor="ckSenndEmailToParticippantWhenRejected">Send email to Participant</label>
                                                    </div>
                                                    {approvalSetting.sendEmailToParticipantWhenRejected &&
                                                        <div className="p-col-12">
                                                            <label className="p-label">Using below template</label>
                                                            <Dropdown value={approvalSetting.mailTemplateToParticipantWhenRejected} options={this.state.mailTemplates} onChange={e => this.onChangeApprovalSetting('mailTemplateToParticipantWhenRejected', e.value)} placeholder="Select template" />
                                                        </div>
                                                    }
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckSenndEmailToModeratorWhenRejected" checked={approvalSetting.sendEmailToModeratorWhenRejected} onChange={e => this.onChangeApprovalSetting('sendEmailToModeratorWhenRejected', e.checked)} />
                                                        <label htmlFor="ckSenndEmailToModeratorWhenRejected">Send email to Moderator</label>
                                                    </div>
                                                    {approvalSetting.sendEmailToModeratorWhenRejected &&
                                                        <div className="p-col-12">
                                                            <label className="p-label">Using below template</label>
                                                            <Dropdown value={approvalSetting.mailTemplateToModeratorWhenRejected} options={this.state.mailTemplates} onChange={e => this.onChangeApprovalSetting('mailTemplateToModeratorWhenRejected', e.value)} placeholder="Select template" />
                                                        </div>
                                                    }
                                                </div>
                                            </Fieldset>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                        }
                        <div className="p-col-12">
                            <Fieldset legend="Rating">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <label className="p-label">Group rating (can rating by list of field values)</label>
                                        <InputText value={data.ratingGroup} keyfilter={/[^\s]/} onChange={e => this.setState({data: {...data, ratingGroup: e.target.value }})} placeholder="Type attribute field name" />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                    
                    <div className="p-sidebar-line p-my-3"></div>

                    <div className="p-grid">
                        <div className="p-col-12 p-d-flex p-justify-between">
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.hideForm}/>
                            <Button label="Save" icon="pi pi-check" onClick={() => this.handleSave()}/>
                        </div>
                    </div>
                </Sidebar>
            </React.Fragment>
        )
    }
}