import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export class ConfirmNotification extends Component {

    constructor(props) {
        super(props);
        this.state={
            id: '',
            visible: false
        }
    }

    confirm = (id) =>{
        this.setState({
            id: id,
            visible: true
        })
    } 

    actionSubmit = () => {
        this.props.submitConfirm(this.state.id);
        this.closeDialog();
    }

    closeDialog = () =>{
        this.setState({
            id: '',
            visible: false
        })
    }

    render(){
        const footer = (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={this.actionSubmit} />
                <Button label="No" icon="pi-md-close" className="p-button-secondary" onClick={this.closeDialog} />
            </div>
        );
        return (
            <Dialog header={this.props.header ? this.props.header : ''} showHeader={this.props.header ? true : false} footer={footer} visible={this.state.visible} contentStyle={styles.dialogContent} responsive={true} modal={true} dismissableMask={true} onHide={this.closeDialog}>
                <div className="p-grid">
                    <div className="p-col-12 p-margin-15-0">
                        {this.props.message ? this.props.message : ''}
                    </div>
                </div>
            </Dialog>            
        );
    }
}

const styles = {
    dialogContent: {
        maxHeight: '50px',
        minHeight: '50px',
        overflow: 'hidden',
    }
}