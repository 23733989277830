import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { withNamespaces, Trans } from 'react-i18next';

const CommingSoon = (props) => {
    const { t } = props;
    const trans = (path) => t(path, { framework: "react-i18next" });
    return (
        <div className="exception-body">
            <div className="exception-container" style={{ borderRadius: 10, backgroundColor: "white", boxShadow: "1px 3px 1px #9E9E9E"}}>
                <Trans i18nKey="comming_soon.comming_soon">
                    <h1 className="exception-title">Coming Soon</h1>
                </Trans>
                <Trans i18nKey="comming_soon.comming_soon_message">
                    <p>
                    We are working on it.
                    </p>
                </Trans>
                <Link to="/">
                    <Button label={trans("comming_soon.go_to_dashboard")} icon="pi pi-arrow-left" />
                </Link>
            </div>
        </div>
    );
}

export default withNamespaces('message')(CommingSoon)