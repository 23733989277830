import axios from "axios";
import { API_BASE_URL, SCP_PATH, MOCK_BASE_URL } from "../../constants";

const BASE_URL = API_BASE_URL + SCP_PATH + "/memberships/card";

export function getPageMembershipCard(page, size, sortField, sortOrder, filter) {
  const order = sortOrder === 0 ? "asc" : sortOrder === -1 ? "desc" : "";
  return axios
    .post(`${BASE_URL}/page`, {
      page: page,
      size: size,
      sort: order,
      number: filter.number,
      status: filter.status
    })
    .then((res) => res.data)
    .catch((error) => console.log(error));
}

export function saveMembershipCard(data) {
  return axios
    .post(`${BASE_URL}`, data)
    .then((res) => res.data)
    .catch((error) => console.log(error));
}

export function removeMembershipCard(id) {
    return axios
        .delete(`${BASE_URL}/${id}`)
        .then((res) => res.data)
        .catch((error) => console.log(error));
}




