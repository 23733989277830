import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { showNotification } from '../../core/service/NotificationService';
import { removePageContent, savePageContent } from "./PageContentService";
import { InputSwitch } from "primereact/inputswitch";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { getPageStyles } from "../page-style/PageStyleService";
import { CONTENT_TITLE_ALIGNMENT, CONTENT_HERO_DISPLAY, CONTENT_HERO_DISPLAYS, CONTENT_HERO_BLOCK_TYPE, CONTENT_HERO_BLOCK_TYPES, CONTENT_LAYOUT_COLUMN_TYPE } from "../../constants";
import { Checkbox } from "primereact/checkbox";
import {  } from "../slider/SliderServices";
import { RadioButton } from "primereact/radiobutton";
import CKEditor from 'ckeditor4-react';
import { getSliders } from "../slider/SliderServices";
import { ContentSectionForm } from './ContentSectionForm'
import { OrderList } from 'primereact/orderlist';
import { showloading, stoploading } from "../../core/service/LoadingService";

CKEditor.editorUrl = "/assets/ckeditor/ckeditor.js";

export class PageContentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formHeader: "New Page",
            data: {},
            errors: {}
        }
    }

    componentDidMount(){
        this.loadPageStyles();
        this.loadSliders();
    }

    popularFormData = (data) => {
        this.setState({
            formHeader: data ? 'Edit Page' : 'New Page',
            data: {
                id: data ? data.id : '',
                alias: data && data.alias ? data.alias : '',
                pageStyleSettingId: data ? data.pageStyleSettingId : null,
                pageTitle: data && data.pageTitle ? data.pageTitle : '',
                title: data && data.title ? data.title : '',
                titleAlignment: data ? data.titleAlignment : CONTENT_TITLE_ALIGNMENT.left,
                heroDisplay: data ? data.heroDisplay : CONTENT_HERO_DISPLAY.none,
                stretchHeroFullPageWidth: data && data.stretchHeroFullPageWidth,
                heroLayoutColumn: data && data.heroLayoutColumn ? data.heroLayoutColumn : CONTENT_LAYOUT_COLUMN_TYPE.one_col_12,
                leftHeroBlockType: data ? data.leftHeroBlockType : CONTENT_HERO_BLOCK_TYPE.HTML,
                heroLeftContent: data && data.heroLeftContent ? data.heroLeftContent : '',
                heroLeftLink: data && data.heroLeftLink ? data.heroLeftLink : '',
                heroLeftGoToUrl: data && data.heroLeftGoToUrl ? data.heroLeftGoToUrl : '',
                heroLeftSliderId: data && data.heroLeftSlider ? data.heroLeftSlider.id : '',
                rightHeroBlockType: data ? data.rightHeroBlockType : CONTENT_HERO_BLOCK_TYPE.HTML,
                heroRightContent: data && data.heroRightContent ? data.heroRightContent : '',
                heroRightLink: data && data.heroRightLink ? data.heroRightLink : '',
                heroRightGoToUrl: data && data.heroRightGoToUrl ? data.heroRightGoToUrl : '',
                heroRightSliderId: data && data.heroRightSlider ? data.heroRightSlider.id : '',
                status: data && data.status,
                contents: data&&data.contents ? data.contents : []
            },
            visible: true,
            errors: {}
        });
    }

    handleSave = () => {
        showloading()
        this.setState({ errors: {} });
        savePageContent(this.state.data).then(res => {
            if(!res.errorCode){
                if(this.props.refreshTable)
                    this.props.refreshTable();
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                if(res.errorCode===400) this.setState({errors: res.errorObj});
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
            stoploading()
        })
    }

    loadPageStyles = () => {
        getPageStyles(true)
        .then(res => {
            this.setState({ styleSettings: res?res:[]});
        });
    }

    loadSliders = () => {
        getSliders(true)
        .then(res => {
            this.setState({ sliders: res?res:[]});
        });
    }

    onAliasChange = (e) => {
        this.setState({ 
            data: {...this.state.data,
                alias: e.target.value ? e.target.value.replace(/[\s]/, '_') : ''
            }
        })
    }

    onHeroDisplayChange = (e) => {
        let layoutColumn = CONTENT_LAYOUT_COLUMN_TYPE.one_col_12;
        if(e.value === CONTENT_HERO_DISPLAY.both){
            layoutColumn = CONTENT_LAYOUT_COLUMN_TYPE.two_col_6_6;
        }
        this.setState({ 
            data: {...this.state.data,
                heroDisplay: e.value,
                heroLayoutColumn: this.state.data.heroLayoutColumn?this.state.data.heroLayoutColumn:layoutColumn,
                leftHeroBlockType: this.state.data.leftHeroBlockType?this.state.data.leftHeroBlockType:CONTENT_HERO_BLOCK_TYPE.HTML,
                rightHeroBlockType: this.state.data.rightHeroBlockType?this.state.data.rightHeroBlockType:CONTENT_HERO_BLOCK_TYPE.HTML
            }
        })
    }

    heroContentTemplate = (type) => {
        let strHeader="Hero Content";
        let fieldHeroBlockType = 'leftHeroBlockType';
        let fieldBlockContent = 'heroLeftContent';
        // let fieldHeroImageUrl = 'heroLeftLink';
        // let fieldHeroGoToUrl = 'heroLeftGoToUrl';
        let fieldHeroSlider = 'heroLeftSliderId';
        if(type === 'left'){
            strHeader="Hero Left Content";
        }else if(type === 'right'){
            strHeader="Hero Right Content";
            fieldHeroBlockType = 'rightHeroBlockType';
            fieldBlockContent = 'heroRightContent';
            // fieldHeroImageUrl = 'heroRightLink';
            // fieldHeroGoToUrl = 'heroRightGoToUrl';
            fieldHeroSlider = 'heroRightSliderId';
        }
        return(
            <Fieldset legend={strHeader}>
                <div className="p-grid">
                    <div className="p-col-12">
                        <label className="p-label">Hero Block Type</label>
                        <Dropdown value={this.state.data[fieldHeroBlockType]} options={CONTENT_HERO_BLOCK_TYPES} onChange={(e) => this.setState({data: {...this.state.data, [fieldHeroBlockType]: e.value}})} />
                    </div>
                    {this.state.data[fieldHeroBlockType] === CONTENT_HERO_BLOCK_TYPE.HTML &&
                        <div className="p-col-12">
                            <label className="p-label">Block Content</label>
                            <CKEditor data={this.state.data[fieldBlockContent]} onChange={(e) => this.setState({data: {...this.state.data, [fieldBlockContent]: e.editor.getData()}})} />
                        </div>
                    }
                    {/* {this.state.data[fieldHeroBlockType] === CONTENT_HERO_BLOCK_TYPE.IMAGE &&
                        <React.Fragment>
                            <div className="p-col-12">
                                <label className="p-label">Hero Image URL</label>
                                <InputText value={this.state.data[fieldHeroImageUrl]} onChange={(e) => this.setState({data: {...this.state.data, [fieldHeroImageUrl]: e.target.value}})} />
                            </div>    
                            <div className="p-col-12">
                                <label className="p-label">Hero Go To URL</label>
                                <InputText value={this.state.data[fieldHeroGoToUrl]} onChange={(e) => this.setState({data: {...this.state.data, [fieldHeroGoToUrl]: e.target.value}})} />
                            </div>    
                        </React.Fragment>
                    } */}
                    {this.state.data[fieldHeroBlockType]=== CONTENT_HERO_BLOCK_TYPE.SLIDER &&
                        <div className="p-col-12">
                            <label className="p-label">Slider</label>
                            <Dropdown value={this.state.data[fieldHeroSlider]} options={this.state.sliders} onChange={(e) => this.setState({data: {...this.state.data, [fieldHeroSlider]: e.value}})} />
                        </div>    
                    }
                </div>
            </Fieldset>
        )
    }

    layoutColumnTemplate = (numColumn = 2, property) => {
        if(numColumn === 2) 
            return (
                <div className="p-grid">
                    <div className="p-col-12 p-md-4">
                        <RadioButton inputId={"rdCol66"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.two_col_6_6} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.two_col_6_6} />
                        <label htmlFor={"rdCol66"+property}><img src={require("./layout-column/2-col-6-6.jpg")} alt="Column 6-6" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <RadioButton inputId={"rdCol48"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.two_col_4_8} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.two_col_4_8} />
                        <label htmlFor={"rdCol48"+property}><img src={require("./layout-column/2-col-4-8.jpg")} alt="Column 4-8" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <RadioButton inputId={"rdCol84"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.two_col_8_4} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.two_col_8_4} />
                        <label htmlFor={"rdCol84"+property}><img src={require("./layout-column/2-col-8-4.jpg")} alt="Column 8-4" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                </div>
            )
        else if(numColumn === 3)
            return(
                <div className="p-grid">
                    <div className="p-col-12 p-md-3">
                        <RadioButton inputId={"rdCol444"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.three_col_4_4_4} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.three_col_4_4_4} />
                        <label htmlFor={"rdCol444"+property}><img src={require("./layout-column/3-col-4-4-4.jpg")} alt="Column 4-4-4" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                    <div className="p-col-12 p-md-3">
                        <RadioButton inputId={"rdCol336" +property}value={CONTENT_LAYOUT_COLUMN_TYPE.three_col_3_3_6} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.three_col_3_3_6} />
                        <label htmlFor={"rdCol336"+property}><img src={require("./layout-column/3-col-3-3-6.jpg")} alt="Column 3-3-6" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                    <div className="p-col-12 p-md-3">
                        <RadioButton inputId={"rdCol633"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.three_col_6_3_3} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.three_col_6_3_3} />
                        <label htmlFor={"rdCol633"+property}><img src={require("./layout-column/3-col-6-3-3.jpg")} alt="Column 6-3-3" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                    <div className="p-col-12 p-md-3">
                        <RadioButton inputId={"rdCol363"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.three_col_3_6_3} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.three_col_3_6_3} />
                        <label htmlFor={"rdCol363"+property}><img src={require("./layout-column/3-col-6-3-3.jpg")} alt="Column 6-3-3" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                </div>
            )
    }

    onSaveSection = (data) => {
        let tmpArr = this.state.data.contents?[...this.state.data.contents]:[]
        
        if(data.idx || data.idx === 0){
            tmpArr.splice(data.idx, 1, data)
        }else{
            data.idx = tmpArr.length
            tmpArr.push(data)
        }

        this.setState({
            data: {...this.state.data,
                contents: tmpArr
            }
        })
    }

    onRemoveSection = (index) => {
        let tmpArr = [...this.state.data.contents]
        tmpArr.splice(index, 1)
        this.setState({
            data: {...this.state.data,
                contents: tmpArr
            }
        })
    }
    
    contentSectionTemplate = (item) => {
        return (
            <div className="p-clearfix">
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div>{item.name}</div>
                    <div>
                        <Button label="Edit" icon="pi pi-pencil" className="p-button-info btn-text-sm" onClick={() => this.contentSectionForm.popularFormData(item)}/>
                        <Button label="Remove" icon="pi pi-times" className="p-button-danger btn-text-sm" onClick={() => this.onRemoveSection(item.idx)}/>
                    </div>
                </div>
            </div>
        );
    }

    onSectionContentChange = (value) => {
        let lstValue = []

        if(value.length>0){
            value.forEach((item, idx) => {
                if(item){
                    item.idx = idx
                    lstValue.push(item)
                }
            });
        }

        this.setState({data: {...this.state.data, contents: lstValue}})
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    render() {
        const headerSections = (
                <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                    <Button label="Add section" icon="pi pi-plus" className="p-button-constrast btn-text-sm p-float-left" style={{ width: 'auto' }} onClick={() => this.contentSectionForm.popularFormData()} />
                    <h3 className="fle-datatable-header-title p-margin-top-0 p-margin-bottom-0">Content Sections</h3>
                </div>
            )

        return (
            <React.Fragment>
                <ContentSectionForm ref={el => this.contentSectionForm = el} 
                    onSubmit={(data) => this.onSaveSection(data)}
                />

                <Sidebar visible={this.state.visible} style={{overflowY: 'auto'}} className="p-sidebar-lg" position="right" dismissable={false} blockScroll={true} showCloseIcon={false}>
                    <div className="p-d-flex p-justify-between ">
                        <h2 className="p-margin-top-10">{this.state.formHeader}</h2>
                        <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={this.onHide}/>
                    </div>

                    <div className="p-sidebar-line p-mb-3"></div>

                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <Fieldset legend="Page Setting">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">* Name (for manage)</label>
                                        <InputText value={this.state.data.title} onChange={(e) => this.setState({data: {...this.state.data, title: e.target.value}})} />
                                        <div className="p-form-error">{this.state.errors.title}</div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">* Url Alias</label>
                                        <InputText value={this.state.data.alias} onChange={(e) => this.onAliasChange(e)} />
                                        <div className="p-form-error">{this.state.errors.alias}</div>
                                    </div>
                                    {/* <div className="p-col-12 p-md-6">
                                        <label className="p-label">Apply Page Style Setting</label>
                                        <Dropdown value={this.state.data.pageStyleSettingId} options={this.state.styleSettings} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, pageStyleSettingId: e.value}})} />
                                    </div> */}
                                </div>
                            </Fieldset>
                        </div>
                        <div className="p-col-12">
                            <Fieldset legend="Page Hero">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <label className="p-label">Hero Display</label>
                                        <Dropdown value={this.state.data.heroDisplay} options={CONTENT_HERO_DISPLAYS} onChange={(e) => this.onHeroDisplayChange(e)} />
                                    </div>
                                    {this.state.data.heroDisplay && this.state.data.heroDisplay!==CONTENT_HERO_DISPLAY.none && 
                                        <React.Fragment>
                                            <div className="p-col-12">
                                                <Checkbox inputId="ckStretchHeroFullPageWidth" checked={this.state.data.stretchHeroFullPageWidth} onChange={(e) => this.setState({data: {...this.state.data, stretchHeroFullPageWidth: e.checked}})} />
                                                <label htmlFor="ckStretchHeroFullPageWidth">Stretch Hero full page width?</label>
                                            </div>
                                            {(()=>{
                                                if(this.state.data.heroDisplay){
                                                    switch(this.state.data.heroDisplay){
                                                        case CONTENT_HERO_DISPLAY.left:
                                                            return <div className="p-col-12">
                                                                {this.heroContentTemplate()}
                                                            </div>
                                                        case CONTENT_HERO_DISPLAY.both:
                                                            return <React.Fragment>
                                                                <div className="p-col-12">
                                                                    <label className="p-label">Hero Layout Column</label>
                                                                    {this.layoutColumnTemplate(2, 'heroLayoutColumn')}
                                                                </div>
                                                                <div className="p-col-12">
                                                                    {this.heroContentTemplate('left')}
                                                                </div>
                                                                <div className="p-col-12">
                                                                    {this.heroContentTemplate('right')}
                                                                </div>
                                                            </React.Fragment>
                                                        default: break;
                                                    }
                                                }
                                            })()}
                                        </React.Fragment>
                                    }
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Fieldset legend="Main Content">
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-9">
                                        <label className="p-label">Page Title (for display, leave blank if doesn't want to show)</label>
                                        <InputText value={this.state.data.pageTitle} onChange={(e) => this.setState({data: {...this.state.data, pageTitle: e.target.value}})} />
                                    </div>
                                    {/* <div className="p-col-12 p-md-6">
                                        <label className="p-label">Title Alignment</label>
                                        <Dropdown value={this.state.data.titleAlignment} options={CONTENT_TITLE_ALIGNMENTS} onChange={(e) => this.setState({data: {...this.state.data, titleAlignment: e.value}})} />
                                    </div> */}
                                    <div className="p-col-12 p-md-3 p-as-end p-d-flex p-ai-center">
                                        <InputSwitch inputId="ipStatus" checked={this.state.data.status} onChange={e => this.setState({ data: {...this.state.data, status: e.value}  })} />
                                        <label htmlFor="ipStatus" className="p-margin-left-10 p-label inline">Active? </label>
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <Fieldset legend="Sections">
                                            <OrderList header={headerSections} dragdrop={true} responsive={true} listStyle={{height: 'auto'}}
                                                value={this.state.data.contents} itemTemplate={this.contentSectionTemplate}
                                                onChange={(e) => this.onSectionContentChange(e.value)}/>
                                        </Fieldset>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        
                    </div>

                    <div className="p-sidebar-line p-my-3"></div>
                    
                    <div className="p-grid">
                        <div className="p-col-12 p-d-flex p-justify-between">
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => this.onHide()}/>
                            <Button label="Save" icon="pi pi-check" onClick={() => this.handleSave()}/>
                        </div>
                    </div>
                </Sidebar>
            </React.Fragment>
        );
    }
}
