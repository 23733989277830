import React, {Component} from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Spinner } from "primereact/spinner";
import { MembershipTemplateServices } from "./MembershipTemplateServices";
import { showNotification } from "../../core/service/NotificationService";
import { SplitButton } from "primereact/splitbutton";
import { DeleteNotification } from "../../core/components/DeleteNotification";
import { Fieldset } from "primereact/fieldset";

export class MembershipTemplatePriceSettingForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: {},
            templatePrice: {},
            visible: false,
            formVisible: false,
            formHeader: 'Price Setting',
            errors: {}
        }
        this.membershipTemplateServices = new MembershipTemplateServices();
    }

    action = (priceId) => {
        this.setState({
            priceId: priceId,
            data: {
                id: '',
                start: '',
                end: '',
            },
            startValue: '',
            endValue: '',
            visible: true,
            errors: {}
        },() => this.getTemplatePrice())
    }

    handleSaveForm = () => {
        this.setState({ errors: {} });
        this.membershipTemplateServices.saveMembershipTemplatePriceSetting(this.state.priceId, this.state.data).then(res => {
            if(!res.errorCode){
                this.setState({ 
                    data: res,
                    formVisible: false 
                },() => this.getTemplatePrice());
                this.props.refreshTable();
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        })
    }

    getTemplatePrice = () => {
        this.membershipTemplateServices.getMembershipTemplatePrice(this.state.priceId).then(res => {
            if(!res.errorCode){
                this.setState({
                    templatePrice: res,
                    data: {...this.state.data,
                        price: this.state.data.id ? this.state.data.price : res.price
                    }
                })
            }
        })
    }

    removeTemplatePriceSetting = (id) => {
        this.membershipTemplateServices.removeMembershipTemplatePriceSetting(id).then(res => {
            if(!res.errorCode){
                if(this.state.data.id && this.state.data.id === id){
                    this.popularForm()
                }
                this.getTemplatePrice();
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        })
    }

    onMembershipStartChange = (e) => {
        this.setState({
            data: {
                ...this.state.data, 
                start: e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''
            },
            startValue: e.value
        });
    }

    onMembershipEndChange = (e) => {
        this.setState({
            data: {
                ...this.state.data, 
                end: e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : ''
            },
            endValue: e.value
        });
    }

    addOrEdit = (data) => {
        let tempObj = this.state.templatePrice.priceSettings[this.state.templatePrice.priceSettings.length-1];
        let startDefault;
        if(tempObj){
            if(tempObj.end){
                startDefault = moment(tempObj.end);
                startDefault.set({m : (startDefault.minute() + 1)})
            }
        }
        this.popularForm(data, startDefault);
    }

    popularForm = (data, startDefault) => {
        this.setState({
            data: {
                id: data ? data.id : '',
                start: data && data.start ? data.start : (!data && startDefault ? startDefault.format('YYYY-MM-DD HH:mm') : ''),
                end: data ? data.end : '',
                price: data ? data.price : this.state.templatePrice.price,
            },
            formVisible: true,
            startValue: data  && data.start ? moment(data.start).toDate() : (!data && startDefault ? startDefault.toDate() : ''),
            endValue: data && data.end ? moment(data.end).toDate()  : '',
            errors: {}
        })
    }

    onHide = () => {
        this.setState({ visible: false})
    }

    actionTemplate = (rowData, column) => {
        let dropActions = [
            {   label: 'Delete', icon: 'pi pi-times',
                command: (e) => { this.deleteNotify.delete(rowData.id) }
            }
        ];
        return <SplitButton label="Edit" className="p-button-constrast" model={dropActions} tooltip={'Edit'} tooltipOptions={{position: "top"}} onClick={() => this.addOrEdit(rowData)}/>
    }

    render(){
        let tblHeader = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            <Button icon="pi pi-plus" className="p-button-constrast" style={{ 'float': 'left' }} label="Add New" onClick={() => this.addOrEdit(null)} />
            <h3 className="fle-datatable-header-title">Listing</h3>
        </div>;
        return (
            <>
            <DeleteNotification ref={el => this.deleteNotify=el}
                accessDelete={(e) => this.removeTemplatePriceSetting(e)}
            />
            <Sidebar visible={this.state.visible} style={{overflowY: 'auto', width:'50em'}} dismissable={false} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.onHide}>
                <h2>{this.state.formHeader}</h2>
                {this.state.formVisible ? 
                <Fieldset legend="Price Setting Form">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label className="p-label">Start Date</label>
                            <MaskedCalendar value={this.state.startValue} showIcon={true} hourFormat="12" showTime={true} hideOnDateTimeSelect={true} onChange={(e) => this.onMembershipStartChange(e)} />
                            <span className="p-form-error">{this.state.errors.start}</span>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">End Date</label>
                            <MaskedCalendar value={this.state.endValue} showIcon={true} hourFormat="12" showTime={true} hideOnDateTimeSelect={true} onChange={(e) => this.onMembershipEndChange(e)} />
                            <span className="p-form-error">{this.state.errors.end}</span>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Price</label>
                            <Spinner value={this.state.data.price} min={0} step={0.25} onChange={(e) => this.setState({data: {...this.state.data, price: e.target.value}})} />
                            <span className="p-form-error">{this.state.errors.code}</span>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-20 p-line-top">
                            <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handleSaveForm()}/>
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={(e) => this.setState({ formVisible: false})}/>
                        </div>
                    </div>
                </Fieldset>
                :
                ''
                }
                <br />
                <div className="p-grid">
                    <div className="p-col-12">
                        <DataTable header={tblHeader} value={this.state.templatePrice.priceSettings} >
                            <Column field="start" header="Start" sortable={true} body={rowData => rowData.start ? moment(rowData.start).format('MM-DD-YYYY hh:mm A') : ''} />
                            <Column field="end" header="End"sortable={true} body={rowData => rowData.end ? moment(rowData.end).format('MM-DD-YYYY hh:mm A') : ''} />
                            <Column field="price" header="Price"sortable={true} body={rowData => '$'+(rowData.price ? rowData.price : this.state.templatePrice.price) } />
                            <Column header="" className="tc-actions" body={this.actionTemplate} style={{textAlign:'center',width:'10em'}}/>
                        </DataTable>
                    </div>
                </div>
            </Sidebar>
            </>
        );
    }
}