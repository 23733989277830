import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { getAllUsers, getUser } from '../../constituent-management/contact/UserServices';
import { ContactForm } from '../../constituent-management/contact/ContactForm';
import AsyncSelect from 'react-select/async';
import SelectOption from '../../core/components/contact/SelectionOption';
import { Fieldset } from 'primereact/fieldset';
import { getListProducts, getProductAvailabilities } from '../../pim/product/ProductServices';
import { APP_FEP, DATE_FORMAT_DISPLAY, OBJECT_TYPE, PRODUCT_TYPE, TIME_FORMAT_DISPLAY, TIME_SERIES_REPEAT_TYPE } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { checkInAttendance } from './AttendanceService';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import moment from 'moment';
import { getListParticipants } from '../../constituent-management/participant/ParticipantServices';
import { getListSchedules } from '../../pim/object-schedule/ScheduleServices';


export const AttendanceForm = forwardRef((props, ref) => {
	const [header, setHeader] = useState('Tracking Attendee');
	const [visible, setVisible] = useState(false);
	const [frmData, setFrmData] = useState({});
	const [selectedUser, setSelectedUser] = useState(null);

	const [courseProduct, setCourseProduct] = useState(null);

	const [sessions, setSessions] = useState([]);
	const [courseSessions, setCourseSessions] = useState([]);
	const [schedules, setSchedules] = useState([]);

	const [participant, setParticipant] = useState(null);
	const [errors, setErrors] = useState({});

	const ddlUsers = useRef(null);
	const frmUser = useRef(null);

	useImperativeHandle(ref, () => ({
		async openForm(course, refSessionId, refScheduleId) {
			popularFormData(course, refSessionId, refScheduleId);

			if (props.userId) {
				let resUser = await getUser(props.userId);
				setSelectedUser({ value: resUser.id, label: resUser.name, email: resUser.email, phone: resUser.phone });
				loadBookedParticipants(course.id, props.userId);
			}

			setVisible(true);
		},
		closeForm() {
			closeForm();
		},
	}));

	const popularFormData = (course, sessionId, scheduleId, data) => {
		let tmpSessions = [];

		if (course?.availabilities.length > 0) {
			course.availabilities.forEach((s) => {
				tmpSessions.push(s);
			});
		}

		setCourseProduct(course);
		setCourseSessions(tmpSessions);
		setSessions(tmpSessions.map((s) => ({ value: s.id, label: s.name })));

		let tmpSchedules = [];

		if (sessionId) {
			const tmpSession = tmpSessions.find((s) => s.id === sessionId);

			if (tmpSession?.objSchedules.length > 0) {
				tmpSession.objSchedules.forEach((s) => {
					tmpSchedules.push(s);
				});
			}
		}

		setSchedules(tmpSchedules.map((s) => ({ value: s.id, label: renderScheduleLabel(s), date: s.startDate })));

		setFrmData({
			productId: course.id,
			sessionId: sessionId,
			scheduleId: scheduleId,
			userId: props.userId,
		});
	};

	const onSessionChange = (val) => {
		let tmpSchedules = [];

		if (val) {
			const tmpSession = courseSessions.find((s) => s.id === val);

			if (tmpSession?.objSchedules.length > 0) {
				tmpSession.objSchedules.forEach((s) => {
					tmpSchedules.push(s);
				});
			}
		}

		setSchedules(tmpSchedules.map((s) => ({ value: s.id, label: renderScheduleLabel(s), date: s.startDate })));
		setFrmData({
			...frmData,
			sessionId: val,
			scheduleIds: null,
		});
	};

	const renderScheduleLabel = (s) => {
		return (
			moment(s.startDate).format(DATE_FORMAT_DISPLAY) +
			(s.startDate !== s.endDate ? ' -> ' + moment(s.endDate).format(DATE_FORMAT_DISPLAY) : '') +
			' | ' +
			moment(s.startTime, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY) +
			' - ' +
			moment(s.endTime, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY)
		);
	};

	const handleSave = () => {
		setErrors({});

		let checkinDate;

		if (frmData.scheduleId) checkinDate = schedules.find((s) => s.value === frmData.scheduleId)?.date;

		if (!participant || !checkinDate) {
			showErrorNotify('Cannot perform action');
			return;
		}

		checkInAttendance(frmData.productId, frmData.sessionId, frmData.userId, frmData.scheduleId, checkinDate).then((res) => {
			if (!res.errorCode) {
				if (props.onRefresh) props.onRefresh();

				closeForm();
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify('Cannot perform action');
				if (res.errorCode === 400) setErrors(res.errorObj);
			}
		});
	};

	const loadBookedParticipants = (productId, userId) => {
		if (productId) {
			const participantRequest = {
				refIds: [productId],
				userId: userId,
				statuses: ['completed'],
			};

			getListParticipants(participantRequest).then((res) => setParticipant(res && res.length > 0 ? res[0] : null));
		}
	};

	const loadUsers = (searchTerm, callback) => {
		getAllUsers(searchTerm).then((res) => {
			callback(res);
		});
	};

	const onUserChange = (val) => {
		setSelectedUser(val);
		setFrmData({ ...frmData, userId: val?.value });
		if (val) loadBookedParticipants(frmData.productId, val.value);
		else setParticipant(null);
	};

	const onCreateContactSuccessful = (e) => {
		ddlUsers.current.focus();
		setSelectedUser(e);
		setFrmData({ ...frmData, userId: e.id });
	};

	const closeForm = () => {
		setVisible(false);
		setFrmData({});
		setSelectedUser(null);
		setErrors({});
	};

	const Option = (props) => SelectOption(props);

	const renderParticipantNotice = () => {
		if (participant) {
			if (participant.availabilityId !== frmData.sessionId) {
				return 'User is not a member of selected session!';
			} else {
				if (participant.completedParticipation) {
					return 'User has already passed the course!';
				}

				return 'User has already been a member of selected session!';
			}
		} else {
			return 'User is not a member of this course. Unable to tracking!';
		}
	};

	return (
		<React.Fragment>
			<ContactForm
				ref={frmUser}
				onContactSave={(e) => onCreateContactSuccessful(e)}
				contact={(data) => {
					setFrmData({ ...frmData, userId: data.id });
					setSelectedUser({ value: data.id, label: data.name, email: data.email, phone: data.phone });
				}}
			/>

			<Sidebar visible={visible} style={{ overflowY: 'auto' }} className='p-sidebar-md' position='right' blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
				<div className='p-d-flex p-justify-between '>
					<h2 className='p-margin-top-10'>{header}</h2>
					<Button label='' icon='pi pi-times' className='p-button-secondary' onClick={closeForm} />
				</div>

				<div className='p-sidebar-line p-mb-3'></div>

				<div className='p-grid p-fluid form-group'>
					<div className='p-col-12'>
						<label>
							Course: <strong>{courseProduct?.name}</strong>
						</label>
						{/* {!productId &&
                            <React.Fragment>
                                <label className="p-label">* Course</label>
                                <Dropdown value={frmData.productId} options={courses} filter dataKey="value" onChange={e => onCourseChange(e.value)} placeholder="Select course"/>
                                <div className="p-form-error">{errors.productId}</div>
                            </React.Fragment>
                        } */}
					</div>
					<div className='p-col-12'>
						<label className='p-label'>* Session</label>
						<Dropdown value={frmData.sessionId} options={sessions} filter dataKey='value' onChange={(e) => onSessionChange(e.value)} placeholder='Select session' />
						<div className='p-form-error'>{errors.sessionId}</div>
					</div>
					<div className='p-col-12'>
						<label className='p-label'>* Schedule</label>
						<Dropdown value={frmData.scheduleId} options={schedules} filter dataKey='value' onChange={(e) => setFrmData({ ...frmData, scheduleId: e.value })} placeholder='Select schedule' />
						<div className='p-form-error'>{errors.scheduleId}</div>
					</div>

					<div className='p-col-12'>
						<Fieldset legend='* User'>
							<div className='p-grid p-fluid form-group'>
								<div className='p-col-12'>
									<div className='p-grid'>
										{selectedUser?.value && (
											<div className='p-col-12'>
												<strong style={{ color: '#cc0000' }}>{renderParticipantNotice()}</strong>
											</div>
										)}
										{!props.userId && (
											<React.Fragment>
												<div className='p-col-12'>
													<AsyncSelect
														ref={ddlUsers}
														loadOptions={loadUsers}
														onChange={(e) => onUserChange(e)}
														placeholder='Type your search'
														components={{ Option }}
														value={selectedUser}
														isClearable
													/>
												</div>
												{/* <div className="p-col-3">
                                                <Button label="New User" icon="pi-md-person-add" className="p-button-info" onClick={(e) => frmUser.current.openForm()}/>
                                            </div> */}
											</React.Fragment>
										)}
									</div>
									<div className='p-form-error'>{errors.userId}</div>
								</div>

								{selectedUser?.value && (
									<React.Fragment>
										<div className='p-fieldset-line p-py-0'></div>
										<div className='p-col-12'>
											<Fieldset legend='User Information'>
												<div className='p-grid form-group'>
													<div className='p-col-4'>Name:</div>
													<div className='p-col-8'>
														<strong>{selectedUser.label}</strong>
													</div>
													<div className='p-col-4'>Email:</div>
													<div className='p-col-8'>
														<strong>{selectedUser.email}</strong>
													</div>
													<div className='p-col-4'>Phone:</div>
													<div className='p-col-8'>
														<strong>{selectedUser.phone}</strong>
													</div>
												</div>
											</Fieldset>
										</div>
									</React.Fragment>
								)}
							</div>
						</Fieldset>
					</div>
				</div>

				<div className='p-sidebar-line p-my-3'></div>

				<div className='p-grid'>
					<div className='p-col-12 p-d-flex p-justify-between'>
						<Button label='Cancel' icon='pi-md-close' className='p-button-secondary' onClick={closeForm} />
						<Button label='Save' icon='pi pi-check' onClick={() => handleSave()} />
					</div>
				</div>
			</Sidebar>
		</React.Fragment>
	);
});
