import axios from 'axios';
import { API_BASE_URL } from '../../constants';

export function getPageStyles(simple){
    return axios.get(`${API_BASE_URL}/scp/api/page-style/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}

export function savePageStyle(data){
    return axios.post(`${API_BASE_URL}/scp/api/page-style`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function removePageStyleSetting(id){
    return axios.delete(`${API_BASE_URL}/scp/api/page-style/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}