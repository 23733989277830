import React, { forwardRef, useImperativeHandle, useState } from "react";
import moment from "moment";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { getAllUsers } from "../../constituent-management/contact/UserServices";
import AsyncSelect from 'react-select/async';
import SelectOption from "../../core/components/contact/SelectionOption.js";
import { Fieldset } from "primereact/fieldset";
import { updateUserLogForApplicationRecord } from "./ApplicationService";

export const ApplicationRecordByUserLogForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("User Log")
    const [visible, setVisible] = useState(false)
    const [application, setApplication] = useState({})
    const [record, setRecord] = useState({})
    const [frmData, setFrmData] = useState({})
    const [userSelected, setUserSelected] = useState(null)
    const [errors, setErrors] = useState({})
    
    useImperativeHandle(ref, () => ({
        openForm(data){
            setApplication(data&&data.application?data.application:{})
            setRecord(data?data:{})
            setUserSelected(data&&data.user?{value: data.user.id, label: data.user.name}:null)
            setFrmData(popularFormData(data))
            setVisible(true)
        },
        closeForm(){
            closeForm()
        }
    }))

    const popularFormData = (data) => {
        return {
            id: data ? data.id : '',
            userId: data&&data.user ? data.user.id : null
        }
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setUserSelected(null)
        setErrors({})
    }

    const handleSave = () => {
        setErrors({})

        updateUserLogForApplicationRecord(frmData.id, frmData.userId)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable)
                    props.refreshTable()

                closeForm()
                showSuccessNotify('Action submitted')
            } else {
                if(res.errorCode === 400){
                    showErrorNotify('Cannot perform action')
                    setErrors(res.errorObj)
                }else{
                    showErrorNotify(res.errorMessage)
                }
            }
        })
    }

    const loadUserOptions = (searchTerm, callback) => {
        getAllUsers(searchTerm)
        .then(res => { 
            callback (res)
        })
    }

    const Option = props => SelectOption(props)

    return(
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid p-fluid form-group">
                <div className="p-col-12">
                    <Fieldset legend="Basic Information">
                        <div className="p-grid">
                            <div className="p-col-12"><span className="column-title w-25">Application:</span> <strong>{application.name}</strong></div>
                            <div className="p-col-12"><span className="column-title w-25">Log on:</span> <strong>{record.date && moment(record.date).format('DD/MM/YYYY hh:mm A')}</strong></div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <Fieldset legend="User Log">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <AsyncSelect
                                    value={userSelected}
                                    loadOptions={loadUserOptions} onChange={(data) => (setFrmData({...frmData, userId: data?.value}, setUserSelected(data)))}
                                    placeholder='Type your search'
                                    components={{ Option }}
                                />
                                <div className="p-form-error">{errors.userLogId}</div>
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={closeForm}/>
                    <Button label="Submit" icon="pi pi-check" onClick={() => handleSave()}/>
                </div>
            </div>
        </Sidebar>
    )
})
