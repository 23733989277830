import React, { useState, useEffect, useRef } from "react";
import {DataTable} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from "../../core/service/NotificationService";
import { useRouteMatch } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";
import { APP_FEP, PROMOTION_COUPON_TYPE } from "../../constants";
import moment from "moment";
import { getPagePromotionCoupons, removePromotionCoupon } from "./PromotionService";
import { PromotionCouponForm } from "./PromotionCouponForm";
import { GeneratingCouponExchangeForm } from "./GeneratingCouponExchangeForm";
import classNames from "classnames";

export const PromotionCouponListView = (props) => {
    const match = useRouteMatch()

    const [dataList, setDataList] = useState([])
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: 'id',
        sortOrder: -1
    })

    const [filter, setFilter] = useState({app: APP_FEP})
    const [isLoadTable, setLoadTable] = useState(false);

    const frmCoupon = useRef(null)
    const frmCouponExchange = useRef(null)

    useEffect(() => {
        setFilter({...filter, type: props.type})
        setLoadTable(true)
    }, [props.type])

    useEffect(()=>{
        if(isLoadTable)
            loadPageCoupons()
    },[isLoadTable])

    const loadPageCoupons = () => {
        getPagePromotionCoupons(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const tblHeader = (
        <TableHeader title={props.type===PROMOTION_COUPON_TYPE.coupon_exchange.value?'Product Exchange Coupons':'Vouchers'} 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={
                props.type===PROMOTION_COUPON_TYPE.coupon_exchange.value?
                <Button className="p-button-success" label="Generate Exchange Coupon" icon="pi-md-plus" onClick={() => frmCouponExchange.current.openForm()}/>
                :
                <Button className="p-button-success" label="Create Coupon" icon="pi-md-plus" onClick={() => onAddOrEditCoupon(null)}/>
            }
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
        />
    );

    const onAddOrEditCoupon = (data) => {
        frmCoupon.current.openForm(data, [], PROMOTION_COUPON_TYPE.coupon.value)
    }

    const onRemove = (id) => {
        showConfirmNotify({
            accept: () => handelRemoveCoupon(id)
        })
    }

    const handelRemoveCoupon = (id) => {
        removePromotionCoupon(id).then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>{props.type===PROMOTION_COUPON_TYPE.coupon_exchange.value?'Exchange Coupons':'Vouchers'}</BreadcrumbsItem>

            <PromotionCouponForm ref={frmCoupon} refreshTable={() => setLoadTable(true)}/>
            <GeneratingCouponExchangeForm ref={frmCouponExchange} refreshTable={() => setLoadTable(true)}/>

            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1 className="p-w-bold p-m-0">{props.type===PROMOTION_COUPON_TYPE.coupon_exchange.value?'Product Exchange Coupons':'Vouchers'}</h1>
                </div>

                <DataTable emptyMessage="There's no record found" header={tblHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column field="id" header="ID" sortable={true}/>
                    <Column field="code" header="Code" sortable={true}/>
                    <Column field="used" header="Used" sortable={true}/>
                    <Column field="usageLimit" header="Limit q.ty" sortable={true} body={(rowData) => rowData.usageLimit?rowData.usageLimit:"Unlimited"} />
                    <Column field="validFrom" header="Start date" sortable={true} body={(rowData) => rowData.validFrom? moment(rowData.validFrom).format('MM-DD-YYYY hh:mm A'):''}/>
                    <Column field="validTo" header="End date" sortable={true} body={(rowData) => rowData.validTo? moment(rowData.validTo).format('MM-DD-YYYY hh:mm A'):''} />
                    {/* <Column field="valid" header="Status" bodyClassName="p-c" body={(rowData) => <span className={classNames('status', {'active': rowData.valid, 'inactive': !rowData.valid})}>{rowData.valid?"Valid":"Invalid"}</span>}/> */}
                    {props.type===PROMOTION_COUPON_TYPE.coupon_exchange.value && 
                    <Column field="refProductName" header="Product" body={(rowData) => {
                        return (
                            <React.Fragment>
                            <div>{rowData.refProductName}</div>
                            {rowData.refProductVariantName && <div className="p-margin-top-5">- Variant: {rowData.refProductVariantName}</div>}
                            </React.Fragment>
                        )
                    }}/>
                    }
                    <Column style={{textAlign: 'right', width: '13em'}} bodyClassName="tc-actions"
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Remove', icon: 'pi pi-trash', command: () => onRemove(rowData.id)}
                            ]
                            return(
                                props.type===PROMOTION_COUPON_TYPE.coupon_exchange.value?
                                <Button label="Remove" icon="pi pi-trash" className="p-button-danger" onClick={() => onRemove(rowData.id)}/>
                                :
                                <SplitButton className="p-button-constrast p-l" label="Edit" icon="pi pi-pencil" model={actionItems}  onClick={() => onAddOrEditCoupon(rowData)}></SplitButton>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    )
}