import React, { Component } from 'react';
import { Button } from 'primereact/button'
import moment from 'moment';
import './ToDoTask.css'
import { NOTE_STATUSES } from '../../../constants';

export default class ToDoTask extends Component {
    render() {
        let myTask = this.props.myTask;

        let className = 'p-margin-bottom-10 p-padding-left-10 p-priority-' + myTask.priority;
        return (
            <li key={this.props.key} className={className} style={{ borderBottom: '1px solid #d0c9c9', }}>
                <div className="p-grid">
                    <div className="p-col-9 p-padding-top-10">
                        {/* <Checkbox value={myTask.id} onChange={(e) => this.onChangeCkh(e)} checked={this.state.arrNoteChks.indexOf(myTask.id)!==-1 ? true : false} /> */}
                        <span>{myTask.title} ({NOTE_STATUSES.find(x => x.value===myTask.status).label})</span>
                        <div className="p-padding-top-10">
                            Category: {myTask.category ? (myTask.category.substring(0, 1).toUpperCase() + myTask.category.substring(1)) : ''}
                            {myTask.departmentId ? <span> | Department: {this.props.deptName}</span>: ''}
                        </div>
                        {myTask.dueDate ?
                            <span>
                                Due on: {moment(myTask.dueDate).format('MM-DD-YYYY hh:mm A')}
                            </span>
                            : ''
                        }
                    </div>
                    <div className="p-col-3" style={{ textAlign: 'right' }}>
                        <Button icon="pi pi-pencil" className="p-button-warning" tooltip="Edit Note" tooltipOptions={{ position: 'top' }} onClick={(e) => this.props.onAddOrEdit()} style={{ height: '2em', width: '2em' }} />
                        <Button icon="pi pi-search" className="p-button-info" tooltip="View Note" tooltipOptions={{ position: 'top' }} onClick={(e) => this.props.viewFormDialog()} style={{ height: '2em', width: '2em' }} />
                    </div>
                </div>

            </li>
        )
    }
}



