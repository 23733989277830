import React, { Component } from 'react';
import { MembershipTemplateServices } from "./MembershipTemplateServices";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { showNotification } from '../../core/service/NotificationService';
import { Dropdown } from 'primereact/dropdown';

export class MoveMembershipTemplateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            formHeader: 'Move To Template',
            visible: false,
            errors: {}
        }
        this.membershipTemplateServices = new MembershipTemplateServices();
    }

    action = (id, parentId) => {
        this.setState({
            data: {
                id: id,
                parentId: parentId,
                moveId: ''
            },
            visible: true
        },() => {
            this.loadListMembershipTemplate();
        });
    }

    handleSaveMembershipTemplate = () => {
        this.membershipTemplateServices.moveMembershipTemplate(this.state.data)
        .then(res => {
            if(!res.errorCode){
                this.props.refreshData();
                this.hideForm();
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        });
    }

    loadListMembershipTemplate = () => {
        this.membershipTemplateServices.getListMembershipTemplates(true)
        .then(res => {
            if(!res.errorCode){
                let tempArr = [];
                if(res){
                    tempArr = res.filter(template => template.value !== parseInt(this.state.data.parentId))
                }
                this.setState({ membershipTemplates: tempArr })
            }
        })
    }

    hideForm = () => {
        this.setState({ visible: false })
    }

    render() {
        const footer = (
            <React.Fragment>
                <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handleSaveMembershipTemplate()}/>
                <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.hideForm}/>
            </React.Fragment>
        );

        return (
            <Sidebar dismissable={false} visible={this.state.visible} style={{overflowY: 'auto', width: '35em'}} position="right" blockScroll={true} onHide={this.hideForm}>
                <h2>{this.state.formHeader}</h2>

                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <label className="p-label">Membership Type</label>
                        <Dropdown value={this.state.data.moveId} options={this.state.membershipTemplates} onChange={(e) => this.setState({ data: { ...this.state.data, moveId: e.value } })} />
                        <div className="p-form-error">{this.state.errors.moveParent} </div>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        {footer}
                    </div>
                </div>
            </Sidebar>
        );
    }
}