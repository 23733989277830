import React, { Component } from "react";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { Fieldset } from "primereact/fieldset";
import { Dropdown } from "primereact/dropdown";
import { CONTENT_TITLE_ALIGNMENT, CONTENT_TITLE_ALIGNMENTS, CONTENT_LAYOUT_COLUMN, CONTENT_LAYOUT_COLUMNS, CONTENT_LAYOUT_COLUMN_TYPE, CONTENT_COMPONENT_TYPE } from "../../constants";
import { RadioButton } from "primereact/radiobutton";
import { SplitButton } from "primereact/splitbutton";
import { ComponentContentForm } from "./ComponentContentForm";
import { ColorPicker } from 'primereact/colorpicker'
import { OrderList } from "primereact/orderlist";

export class ContentSectionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formHeader: "New Section",
            data: {},
            errors: {}
        }
    }

    popularFormData = (data) => {
        this.setState({
            formHeader: data ? 'Edit Section' : 'New Section',
            data: {
                idx: data ? data.idx : '',
                name: data && data.name ? data.name : '',
                title: data && data.title ? data.title : '',
                titleAlignment: data ? data.titleAlignment : CONTENT_TITLE_ALIGNMENT.left,
                backgroundImage: data&&data.backgroundImage ? data.backgroundImage: '',
                backgroundColor: data&&data.backgroundColor ? data.backgroundColor.replace('#', '') : '',
                // primaryTextColor: data&&data.primaryTextColor ? data.primaryTextColor.replace('#', '') : '',
                // secondaryTextColor: data&&data.secondaryTextColor ? data.secondaryTextColor.replace('#', '') : '',
                contentLayoutType: data ? data.contentLayoutType : CONTENT_LAYOUT_COLUMN.one_column,
                contentLayoutColumn: data ? data.contentLayoutColumn : CONTENT_LAYOUT_COLUMN_TYPE.one_col_12,
                col1Components: data&&data.col1Components ? data.col1Components : [],
                col2Components: data&&data.col2Components ? data.col2Components : [],
                col3Components: data&&data.col3Components ? data.col3Components : [],
            },
            visible: true,
            errors: {}
        });
    }

    handleSave = () => {
        if(!this.state.data.name){
            this.setState({
                errors: { name: 'Field is required' }
            })
            return
        }
        this.props.onSubmit(this.state.data)
        this.onHide()
    }

    onContentLayoutChange = (e) => {
        let layoutColumn;
        if(e.value === CONTENT_LAYOUT_COLUMN.two_columns){
            layoutColumn = CONTENT_LAYOUT_COLUMN_TYPE.two_col_6_6;
        }else if(e.value === CONTENT_LAYOUT_COLUMN.three_columns){
            layoutColumn = CONTENT_LAYOUT_COLUMN_TYPE.three_col_4_4_4;
        }
        this.setState({
            data: {...this.state.data,
                contentLayoutType: e.value,
                contentLayoutColumn: layoutColumn
            }
        })
    }

    layoutContentColumnTemplate = () => {
        let colClassName1 = 'p-md-12';
        let colClassName2 = 'p-md-12';
        let colClassName3 = 'p-md-12';

        if(this.state.data.contentLayoutType === CONTENT_LAYOUT_COLUMN.two_columns){
            colClassName1 = 'p-md-6';
            colClassName2 = 'p-md-6';
        }else if(this.state.data.contentLayoutType === CONTENT_LAYOUT_COLUMN.three_columns){
            colClassName1 = 'p-md-4';
            colClassName2 = 'p-md-4';
            colClassName3 = 'p-md-4';
        }

        return(
            <div className="p-grid">
                <div className={"p-col-12 " + (colClassName1?colClassName1:'')}>
                    {this.columnContentTemplate(1)}
                </div>
                {this.state.data.contentLayoutType !== CONTENT_LAYOUT_COLUMN.one_column && 
                    <React.Fragment>
                        <div className={"p-col-12 " + (colClassName2?colClassName2:'')}>
                            {this.columnContentTemplate(2)}
                        </div>
                        {this.state.data.contentLayoutType === CONTENT_LAYOUT_COLUMN.three_columns &&
                            <div className={"p-col-12 " + (colClassName3?colClassName3:'')}>
                                {this.columnContentTemplate(3)}
                            </div>
                        }        
                    </React.Fragment>
                }
            </div>
        )
    }

    columnContentTemplate = (colNumber) => {
        let items = [
            {label: CONTENT_COMPONENT_TYPE.free_text.label, command: (e) => this.onAddOrEditComponent(CONTENT_COMPONENT_TYPE.free_text.value, colNumber)},
            // {label: CONTENT_COMPONENT_TYPE.slider.value, command: (e) => this.onAddOrEditComponent(CONTENT_COMPONENT_TYPE.slider.value, colNumber)},
            {label: CONTENT_COMPONENT_TYPE.weather_widget.label, command: (e => this.onAddOrEditComponent(CONTENT_COMPONENT_TYPE.weather_widget.value, colNumber))},
            {label: CONTENT_COMPONENT_TYPE.product_widget.label, command: (e => this.onAddOrEditComponent(CONTENT_COMPONENT_TYPE.product_widget.value, colNumber))},
            // {label: CONTENT_COMPONENT_TYPE.speaker.value, command: (e) => this.onAddOrEditComponent(CONTENT_COMPONENT_TYPE.speaker.value, colNumber)},
            // {label: CONTENT_COMPONENT_TYPE.event_ticket.value, command: (e) => this.onAddOrEditComponent(CONTENT_COMPONENT_TYPE.event_ticket.value, colNumber)},
        ];

        let componentArr;
        if(colNumber === 1) componentArr = this.state.data.col1Components?this.state.data.col1Components:[];
        else if(colNumber === 2) componentArr = this.state.data.col2Components?this.state.data.col2Components:[];
        else if(colNumber === 3) componentArr = this.state.data.col3Components?this.state.data.col3Components:[];

        const tmpHeader = (
            <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                <SplitButton label="Add Component" icon="pi pi-plus" className="p-button-constrast p-float-right" model={items}></SplitButton>
                {/* <Button label="Add section" icon="pi pi-plus" className="p-button-constrast btn-text-sm p-float-left" style={{ width: 'auto' }} onClick={() => this.contentSectionForm.popularFormData()} />
                <h3 className="fle-datatable-header-title p-margin-top-0 p-margin-bottom-0">Content Sections</h3> */}
            </div>
        )

        return(
            <Fieldset legend={"Column " + colNumber + " Content"}>
                <div className="p-grid">
                    <div className="p-col-12">
                        
                    </div>
                    <div className="p-col-12">
                        <OrderList header={tmpHeader} dragdrop={true} responsive={true} listStyle={{height: 'auto'}}
                            value={componentArr} itemTemplate={(e) => this.componentTemplate(e, colNumber)}
                            onChange={(e) => this.onColumnComponentChange(e.value, colNumber)}/>
                    </div>
                </div>
            </Fieldset>
        )
    }

    componentTemplate = (item, colNumber) => {
        let data;
        switch(item.type){
            case CONTENT_COMPONENT_TYPE.free_text.value:
                data = item.cpnFreeText;
            break;
            case CONTENT_COMPONENT_TYPE.slider.value:
                data = item.cpnSlider;
            break;
            case CONTENT_COMPONENT_TYPE.weather_widget.value:
                data = item.cpnWeather;
            break;
            case CONTENT_COMPONENT_TYPE.product_widget.value:
                data = item.cpnProduct;
            break;
            case CONTENT_COMPONENT_TYPE.speaker.value:
                data = item.cpnSpeaker;
            break;
            case CONTENT_COMPONENT_TYPE.event_ticket.value:
                data = item.cpnEventTicket;
            break;
            
            default: break;
        }

        return(
            <div className="p-clearfix">
                <div className="p-d-flex p-ai-center p-jc-between">
                    <div>{CONTENT_COMPONENT_TYPE[item.type] && CONTENT_COMPONENT_TYPE[item.type].label}</div>
                    <div>
                        <Button className="p-button-info btn-sm" icon="pi pi-pencil" onClick={() => this.onAddOrEditComponent(item.type, colNumber, item.idx, data)}/>
                        <Button className="p-button-danger btn-sm" icon="pi pi-trash" onClick={() => this.onRemoveComponent(colNumber, item.idx)}/>
                    </div>
                </div>
            </div>
        )
    }

    onColumnComponentChange = (value, colNumber) => {
        let lstValue = []

        if(value.length>0){
            value.forEach((item, idx) => {
                if(item){
                    item.idx = idx
                    lstValue.push(item)
                }
            });
        }

        this.setState({
            data: {...this.state.data,
                col1Components: colNumber === 1 ? lstValue : this.state.data.col1Components,
                col2Components: colNumber === 2 ? lstValue : this.state.data.col2Components,
                col3Components: colNumber === 3 ? lstValue : this.state.data.col3Components
            }
        })
    }

    onAddOrEditComponent = (type, colNumber, index, data) => {
        this.setState({
            tmpComponent: {...data,
                type: type,
                colNumber: colNumber,
                index: index
            }
        })
        this.componentForm.popularFormData(type, data)
    }

    onSubmitComponent = (data) => {
        let tempComponent = this.state.tmpComponent;
        let tempArr = [];
        if(tempComponent.colNumber === 1){
            tempArr = this.state.data.col1Components?[...this.state.data.col1Components]:[]
        }else if(tempComponent.colNumber === 2){
            tempArr = this.state.data.col2Components?[...this.state.data.col2Components]:[]
        }else if(tempComponent.colNumber === 3){
            tempArr = this.state.data.col3Components?[...this.state.data.col3Components]:[]
        }
        
        if(tempComponent.index || tempComponent.index === 0){
            tempArr.map((item, idx) => {
                if(idx === tempComponent.index){
                    switch(tempComponent.type){
                        case CONTENT_COMPONENT_TYPE.free_text.value:
                            item.cpnFreeText = data;
                        break;
                        case CONTENT_COMPONENT_TYPE.slider.value:
                            item.cpnSlider = data;
                        break;
                        case CONTENT_COMPONENT_TYPE.weather_widget.value:
                            item.cpnWeather = data;
                        break;
                        case CONTENT_COMPONENT_TYPE.product_widget.value:
                            item.cpnProduct = data;
                        break;
                        case CONTENT_COMPONENT_TYPE.speaker.value:
                            item.cpnSpeaker = data;
                        break;
                        case CONTENT_COMPONENT_TYPE.event_ticket.value:
                            item.cpnEventTicket = data;
                        break;
                        default: break;
                    }
                }
            })
        }else{
            let tempData = { idx: tempArr.length, type: tempComponent.type };
            switch(tempComponent.type){
                case CONTENT_COMPONENT_TYPE.free_text.value:
                    tempData.cpnFreeText = data;
                break;
                case CONTENT_COMPONENT_TYPE.slider.value:
                    tempData.cpnSlider = data;
                break;
                case CONTENT_COMPONENT_TYPE.weather_widget.value:
                    tempData.cpnWeather = data;
                break;
                case CONTENT_COMPONENT_TYPE.product_widget.value:
                    tempData.cpnProduct = data;
                break;
                case CONTENT_COMPONENT_TYPE.speaker.value:
                    tempData.cpnSpeaker = data;
                break;
                case CONTENT_COMPONENT_TYPE.event_ticket.value:
                    tempData.cpnEventTicket = data;
                break;
                default: break;
            }
            tempArr.push(tempData);
        }
        
        this.setState({
            data: {...this.state.data,
                col1Components: tempComponent.colNumber === 1 ? tempArr : this.state.data.col1Components,
                col2Components: tempComponent.colNumber === 2 ? tempArr : this.state.data.col2Components,
                col3Components: tempComponent.colNumber === 3 ? tempArr : this.state.data.col3Components
            }
        })
    }

    onRemoveComponent = (colNumber, index) => {
        let tempArr;
        if(colNumber === 1){
            tempArr = this.state.data.col1Components
        }else if(colNumber === 2){
            tempArr = this.state.data.col2Components
        }else if(colNumber === 3){
            tempArr = this.state.data.col3Components
        }
        tempArr = tempArr.filter((item, idx) => idx !== index);
        this.setState({
            data: {...this.state.data,
                col1Components: colNumber === 1 ? tempArr : this.state.data.col1Components,
                col2Components: colNumber === 2 ? tempArr : this.state.data.col2Components,
                col3Components: colNumber === 3 ? tempArr : this.state.data.col3Components
            }
        })
    }

    layoutColumnTemplate = (numColumn = 2, property) => {
        if(numColumn === 2) 
            return (
                <div className="p-grid">
                    <div className="p-col-12 p-md-4">
                        <RadioButton inputId={"rdCol66"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.two_col_6_6} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.two_col_6_6} />
                        <label htmlFor={"rdCol66"+property}><img src={require("./layout-column/2-col-6-6.jpg")} alt="Column 6-6" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <RadioButton inputId={"rdCol48"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.two_col_4_8} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.two_col_4_8} />
                        <label htmlFor={"rdCol48"+property}><img src={require("./layout-column/2-col-4-8.jpg")} alt="Column 4-8" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <RadioButton inputId={"rdCol84"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.two_col_8_4} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.two_col_8_4} />
                        <label htmlFor={"rdCol84"+property}><img src={require("./layout-column/2-col-8-4.jpg")} alt="Column 8-4" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                </div>
            )
        else if(numColumn === 3)
            return(
                <div className="p-grid">
                    <div className="p-col-12 p-md-3">
                        <RadioButton inputId={"rdCol444"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.three_col_4_4_4} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.three_col_4_4_4} />
                        <label htmlFor={"rdCol444"+property}><img src={require("./layout-column/3-col-4-4-4.jpg")} alt="Column 4-4-4" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                    <div className="p-col-12 p-md-3">
                        <RadioButton inputId={"rdCol336" +property}value={CONTENT_LAYOUT_COLUMN_TYPE.three_col_3_3_6} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.three_col_3_3_6} />
                        <label htmlFor={"rdCol336"+property}><img src={require("./layout-column/3-col-3-3-6.jpg")} alt="Column 3-3-6" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                    <div className="p-col-12 p-md-3">
                        <RadioButton inputId={"rdCol633"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.three_col_6_3_3} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.three_col_6_3_3} />
                        <label htmlFor={"rdCol633"+property}><img src={require("./layout-column/3-col-6-3-3.jpg")} alt="Column 6-3-3" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                    <div className="p-col-12 p-md-3">
                        <RadioButton inputId={"rdCol363"+property} value={CONTENT_LAYOUT_COLUMN_TYPE.three_col_3_6_3} onChange={(e) => this.setState({data: {...this.state.data, [property]: e.value}})} checked={this.state.data[property] === CONTENT_LAYOUT_COLUMN_TYPE.three_col_3_6_3} />
                        <label htmlFor={"rdCol363"+property}><img src={require("./layout-column/3-col-3-6-3.jpg")} alt="Column 6-3-3" style={{width: 120, maxWidth: '100%'}} /></label>
                    </div>
                </div>
            )
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    render() {
        return (
            <React.Fragment>
                <ComponentContentForm ref={el => this.componentForm = el} 
                    onSubmit={(data) => this.onSubmitComponent(data)}
                />

                <Sidebar visible={this.state.visible} style={{overflowY: 'auto'}} className="p-sidebar-lg" position="right" dismissable={false} blockScroll={true} showCloseIcon={false}>
                    <div className="p-d-flex p-justify-between ">
                        <h2 className="p-margin-top-10">{this.state.formHeader}</h2>
                        <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={this.onHide}/>
                    </div>

                    <div className="p-sidebar-line p-mb-3"></div>

                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label className="p-label">* Name (for manage)</label>
                            <InputText value={this.state.data.name} onChange={(e) => this.setState({data: {...this.state.data, name: e.target.value}})} />
                            <div className="p-form-error">{this.state.errors.name}</div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label className="p-label">Title (for display, leave blank if doesn't want to show)</label>
                            <InputText value={this.state.data.title} onChange={(e) => this.setState({data: {...this.state.data, title: e.target.value}})} />
                            <div className="p-form-error">{this.state.errors.title}</div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label className="p-label">Title Alignment</label>
                            <Dropdown value={this.state.data.titleAlignment} options={CONTENT_TITLE_ALIGNMENTS} onChange={(e) => this.setState({data: {...this.state.data, titleAlignment: e.value}})} />
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label className="p-label">Background Image Url</label>
                            <InputText value={this.state.data.backgroundImage} onChange={(e) => this.setState({data: {...this.state.data, backgroundImage: e.target.value}})} />
                        </div>
                        <div className="p-col-12 p-md-6">
                            <label className="p-label">Background Color</label>
                            <div className="p-inputgroup">
                                <InputText value={this.state.data.backgroundColor} onChange={(e) => this.setState({data: {...this.state.data, backgroundColor: e.target.value}})} />
                                <ColorPicker value={this.state.data.backgroundColor} onChange={(e) => this.setState({data: {...this.state.data, backgroundColor: e.value}})} />
                            </div>
                        </div>
                        {/* <div className="p-col-12">
                            <label className="p-label">Primary Text Color</label>
                            <div className="p-inputgroup">
                                <InputText value={this.state.data.primaryTextColor} onChange={(e) => this.setState({data: {...this.state.data, primaryTextColor: e.target.value}})} />
                                <ColorPicker value={this.state.data.primaryTextColor} onChange={(e) => this.setState({data: {...this.state.data, primaryTextColor: e.value}})} />
                            </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Secondary Text Color</label>
                            <div className="p-inputgroup">
                                <InputText value={this.state.data.secondaryTextColor} onChange={(e) => this.setState({data: {...this.state.data, secondaryTextColor: e.target.value}})} />
                                <ColorPicker value={this.state.data.secondaryTextColor} onChange={(e) => this.setState({data: {...this.state.data, secondaryTextColor: e.value}})} />
                            </div>
                        </div> */}
                        <div className="p-col-12">
                            <label className="p-label">Content Layout</label>
                            <Dropdown value={this.state.data.contentLayoutType} options={CONTENT_LAYOUT_COLUMNS} onChange={(e) => this.onContentLayoutChange(e)} />
                        </div>
                        {this.state.data.contentLayoutType !== CONTENT_LAYOUT_COLUMN.one_column &&
                            <React.Fragment>
                                <div className="p-col-12">
                                    <label className="p-label">Content Layout Column Type</label>
                                    {this.state.data.contentLayoutType === CONTENT_LAYOUT_COLUMN.two_columns && 
                                        this.layoutColumnTemplate(2, 'contentLayoutColumn')
                                    }
                                    {this.state.data.contentLayoutType === CONTENT_LAYOUT_COLUMN.three_columns && 
                                        this.layoutColumnTemplate(3, 'contentLayoutColumn')
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </div>

                    {this.layoutContentColumnTemplate()}

                    <div className="p-sidebar-line p-mb-3"></div>

                    <div className="p-grid">
                        <div className="p-col-12 p-d-flex p-jc-between">
                            <Button label="Cancel" icon="pi pi-times" className="p-button-secondary" onClick={() => this.onHide()}/>
                            <Button label="Submit" icon="pi pi-check"  onClick={() => this.handleSave()}/>
                        </div>
                    </div>
                </Sidebar>
            </React.Fragment>
        );
    }
}
