import axios from 'axios';
import { API_BASE_URL } from '../../constants';

export class MembershipTemplateServices {
    getPageMembershipTemplates(parentId, page, size, sortField, sortOrder) {
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField==='frequency'?'period':sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/memberships/templates/page`, {
            params: {parentId, page, size, sort }
        }).then(res => res.data).catch(error => console.log(error));
    }

    getMembershipTemplate(id, parentId) {
        return axios.get(`${API_BASE_URL}/scp/api/memberships/templates/${id}`, {
            params: {parentId}
        }).then(res => res.data).catch(error => console.log(error));
    }

    saveMembershipTemplate(form){
        return axios.post(`${API_BASE_URL}/scp/api/memberships/templates`, form).then(res => res.data).catch(error => console.log(error));
    }

    saveMembershipTemplateLevel(parentId, form){
        return axios.post(`${API_BASE_URL}/scp/api/memberships/templates/${parentId}/levels`, form).then(res => res.data).catch(error => console.log(error));
    }

    getListMembershipTemplates(isSimple) {
        return axios.get(`${API_BASE_URL}/scp/api/memberships/templates/list`, {params: {isSimple}}).then(res => res.data).catch(error => console.log(error));
    }

    removeMembershipTemplate(id) {
        return axios.delete(`${API_BASE_URL}/scp/api/memberships/templates/${id}`).then(res => res.data).catch(error => console.log(error));
    }

    moveMembershipTemplate(data){
        let querystring = require('querystring');
        return axios.post(`${API_BASE_URL}/scp/api/memberships/templates/move`, 
            querystring.stringify({ 
                id: data.id,
                moveId: data.moveId
            })
        ).then(res => res.data).catch(error => console.log(error));
    }

    saveMembershipTemplateRule(parentId, form){
        return axios.post(`${API_BASE_URL}/scp/api/memberships/template_levels/${parentId}/rules`, form).then(res => res.data).catch(error => console.log(error));
    }

    removeMembershipTemplateRule(parentId, id){
        return axios.delete(`${API_BASE_URL}/scp/api/memberships/template_levels/${parentId}/rules/${id}`).then(res => res.data).catch(error => console.log(error));
    }

    saveMembershipTemplatePrice(parentId, form){
        return axios.post(`${API_BASE_URL}/scp/api/memberships/template_levels/${parentId}/prices`, form).then(res => res.data).catch(error => console.log(error));
    }

    removeMembershipTemplatePrice(parentId, id){
        return axios.delete(`${API_BASE_URL}/scp/api/memberships/template_levels/${parentId}/prices/${id}`).then(res => res.data).catch(error => console.log(error));
    }

    getMembershipTemplatePrice(priceId) {
        return axios.get(`${API_BASE_URL}/scp/api/memberships/template_prices/${priceId}`).then(res => res.data).catch(error => console.log(error));
    }

    saveMembershipTemplatePriceSetting(priceId, form){
        return axios.post(`${API_BASE_URL}/scp/api/memberships/template_prices/${priceId}/price_setting`, form).then(res => res.data).catch(error => console.log(error));
    }

    removeMembershipTemplatePriceSetting(priceSettingId) {
        return axios.delete(`${API_BASE_URL}/scp/api/memberships/template_price_setting/${priceSettingId}`).then(res => res.data).catch(error => console.log(error));
    }
}