import axios from "axios";
import { API_BASE_URL, SCP_PATH, MOCK_BASE_URL } from "../../constants";

const BASE_URL = API_BASE_URL + SCP_PATH + "/membership-mgt/category";

export function getPageMembershipCategories(page, size, sortField, sortOrder) {
  const order = sortOrder === 0 ? "asc" : sortOrder === -1 ? "desc" : "";
  const sort = sortField ? `${sortField},${order}` : "";
  return (
    axios
      .post(`${BASE_URL}/page`,
        {
          page: page,
          size: size,
          sort: sort,
        })
      .then((res) => res.data)
      // .then((res) => console.log(res.data))
      .catch((error) => console.log(error))
  );
}

export function saveMembershipCategory(data) {
  return (data.id ? axios.put(`${BASE_URL}/update`, data) : axios.post(`${BASE_URL}/create`, data))
    .then((res) => res.data)
    .catch((error) => console.log(error));
}

export function removeMembershipCategory(id) {
  return axios
    .delete(`${BASE_URL}/${id}`)
    .then((res) => res.data)
    .catch((error) => console.log(error));
}

export function getMembershipCategory(id) {
  return axios
    .get(`${BASE_URL}/${id}`)
    .then((res) => res.data)
    .catch((error) => console.log(error));
}
