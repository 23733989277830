import React, { Component } from 'react';
import { UserServices } from '../contact/UserServices';
import { showNotification } from '../../core/service/NotificationService';
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import "../../constituent-management/contact/UserDetails.css";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import EventEmitter from 'eventemitter3';
import { AvatarForm } from "../contact/AvatarForm.js";
import { FieldWrapper } from "../../core/components/FieldWrapper.js";
import { Fieldset } from "primereact/fieldset";
import { GENDERS, NAME_TITLES, RANGE_BIRTHDAY } from "../../constants.js";
import { RadioButton } from "primereact/radiobutton";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { Checkbox } from "primereact/checkbox";
import IntlTelInput from "react-intl-tel-input";
import { getListGrades } from '../../scp/tenant-configuration/ConfigruationServices';
import { Password } from "primereact/password";
import { NotifyChangeTab } from "../../core/NotifyChangeTab";
import { Dialog } from "primereact/dialog";
import TaskList from '../task/TaskList'
import TodoList from '../todo/TodoList';
import { NoteServices } from '../note/NoteServices'
import { DepartmentServices } from '../../scp/departments/DepartmentServices';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { titleCaseText } from "../../core/service/CommonService";

const formEvent = new EventEmitter();
const highlightChanged = (a, b) => {
    let flag = false;
    if (typeof a === 'object') {
        if (Array.isArray(a)) {
            flag = a.length !== b.length
        }
    } else {
        flag = ((a || b) && a !== b);
    }
    return flag ? ' input-highlight' : '';
}

const highlightDateChanged = (a, b) => {
    const data = new Date(a);
    const termData = new Date(b);
    return ((a || b) && a !== b && data.getTime() !== termData.getTime()) ? " input-highlight" : "";
}

const highlightLabelChanged = (a, b) => {
    return a !== b ? ' labels-highlight' : '';
}

export default class UserDetails extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            editButtonLabel: "Edit",
            activeTabIndex: 0,
            showTabChangePrompt: false,
            viewMode: false,
            isDirty: false,
            changeVisible: false,
            user: {},
            sec: {
                id: '',
                password: '',
                passcode: ''
            },
            currentTabIndex: 0,
            userFormDialogVisible: false,
            cellPhoneValue: '',
            homePhoneValue: '',
            workPhoneValue: '',
            tmpCellPhoneValue: '',
            tmpHomePhoneValue: '',
            tmpWorkPhoneValue: '',
            birthdayValue: null,
            errors: {
                pwd: '',
                passcode: '',
            },
            tmpUser: {},
            tmpPassword: '',
            tmpPasscode: '',
            dataList: [],

            // avatar
            avatarFormDialogVisible: false,

            // address
            address: {},
            addCellPhoneValue: '',
            addHomePhoneValue: '',
            addWorkPhoneValue: '',

            tmpAddCellPhoneValue: '',
            tmpAddHomePhoneValue: '',
            tmpAddWorkPhoneValue: '',

            userAddresses: [],
            countries: [],
            countryStates: [],

            // Employment
            employmentInfo: {},

            myTasks : []
        }
        this.noteServices = new NoteServices();
        this.userServices = new UserServices();
        this.departmentServices = new DepartmentServices();
    }

    componentWillMount() {
        formEvent.on('CHANGE_SAVED', this.onChangeSaved);
        formEvent.on('RELOAD', this.reloadUser);
    }

    componentWillUnmount() {
        formEvent.removeListener('CHANGE_SAVED', this.onChangeSaved);
        formEvent.removeListener('RELOAD', this.reloadUser);
    }

    componentDidMount() {
        this.getUserDetails();
        this.getTasks();
        this.getListDepartmentRole();
    }

    reloadUser = () => {
        this.userServices.getUser(localStorage.getItem('user_id'))
            .then(res => {
                if (!res.errorCode) {
                    this.popularUserInfo(res);
                }
            });
    }

    handlePopularTasks = () => {
        this.getTasks();
    }

    getTasks = () => {
        this.noteServices.getMyTasks()
        .then(data => {
            this.setState({
                myTasks: data
            });
        })
    }

    getListDepartmentRole = () => {
        let deptUsers = localStorage.getItem('depts');
        
        if(deptUsers && deptUsers !== 'undefined'){
            let deptsData = JSON.parse(deptUsers);
            this.setState({ dataList : deptsData})
        }
    }

    getListGrades = () => {
        getListGrades()
            .then(res => {
                if (!res.errorCode) {
                    if (res) {
                        this.setState({ arrGrades: res });
                    }
                }
            });
    }

    getUserDetails = () => {
        this.userServices.getUser(localStorage.getItem('user_id'))
            .then(res => {
                if (!res.errorCode) {
                    this.popularUserInfo(res);
                    this.getListGrades();
                } else {
                    showNotification('error', 'Error Message', res.errorMessage);
                }
            });
    }

    buildPhoneObj = (status, value, countryData) => {
        let phoneValue = "";
        if (value !== "") {
            if (value.indexOf("+" + countryData.dialCode) !== -1) {
                phoneValue = value.substr(("+" + countryData.dialCode).length);
            } else {
                phoneValue = value;
            }
        }
        let phoneObj = {
            phoneNumber: phoneValue,
            dialCode: countryData.dialCode,
            countryCode: countryData.iso2.toUpperCase(),
            phoneValid: status
        }

        return phoneObj;
    }

    onChangePhone = (status, value, countryData, type) => {
        let phoneObj = this.buildPhoneObj(status, value, countryData);
        switch (type) {
            case 'cellPhone':
                this.setState({
                    user: {
                        ...this.state.user,
                        cellPhone: phoneObj.phoneNumber ? phoneObj.countryCode + "|" + phoneObj.dialCode + "|" + phoneObj.phoneNumber : ''
                    },
                    cellPhoneValue: phoneObj.phoneNumber,
                    tmpCellPhoneValue: phoneObj.phoneNumber,
                    cellPhoneCodeValue: phoneObj.countryCode.toLowerCase(),
                    isDirty: true
                });
                break;
            case 'homePhone':
                this.setState({
                    user: {
                        ...this.state.user,
                        homePhone: phoneObj.phoneNumber ? phoneObj.countryCode + "|" + phoneObj.dialCode + "|" + phoneObj.phoneNumber : ''
                    },
                    homePhoneValue: phoneObj.phoneNumber,
                    tmpHomePhoneValue: phoneObj.phoneNumber,
                    homePhoneCodeValue: phoneObj.countryCode.toLowerCase(),
                    isDirty: true
                });
                break;
            case 'workPhone':
                this.setState({
                    user: {
                        ...this.state.user,
                        workPhone: phoneObj.phoneNumber ? phoneObj.countryCode + "|" + phoneObj.dialCode + "|" + phoneObj.phoneNumber : ''
                    },
                    workPhoneValue: phoneObj.phoneNumber,
                    tmpWorkPhoneValue: phoneObj.phoneNumber,
                    workPhoneCodeValue: phoneObj.countryCode.toLowerCase(),
                    isDirty: true
                });
                break;
            case 'userPhone':
                this.setState({
                    user: {
                        ...this.state.user,
                        phone: phoneObj.phoneNumber ? phoneObj.countryCode + "|" + phoneObj.dialCode + "|" + phoneObj.phoneNumber : '',
                        phoneValue: phoneObj.phoneNumber
                    },
                });
                break;
            default:
                break;
        }
    }

    onBirthdayChange = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                birthday: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : ''
            },
            birthdayValue: e.value,
            isDirty: true
        });
    }

    popularUserInfo = (data) => {
        this.setState({
            viewMode: true,
            user: data ? data : {},
            tmpUser: data ? data : {},
            sec: {
                ...this.sec,
                id: data.id,
                username: data.username,
                email: data.email,
                secondaryEmail: data.secondaryEmail,
                passcode: data.passcode
            },
            employmentInfo: data && data.extraInfo && data.extraInfo.employmentInfo ? data.extraInfo.employmentInfo : {},
            birthdayValue: data && data.birthday ? moment(data.birthday, 'YYYY-MM-DD').toDate() : '',
            companySinceValue: data && data.companySince ? moment(data.companySince, 'YYYY-MM-DD').toDate() : '',
            // addEmePhoneValue: data && data.emePhoneObj ? (data.emePhoneObj.countryCode!=='US' ? ('+' + data.emePhoneObj.dialCode) : '') + data.emePhoneObj.phoneNumber: '',
            // emePhoneCodeValue: data && data.emePhoneObj ? data.emePhoneObj.countryCode.toLowerCase() : '',
            // addEmeAltPhoneValue: data && data.emeAltPhoneObj ? (data.emeAltPhoneObj.countryCode!=='US' ? ('+' + data.emeAltPhoneObj.dialCode) : '') + data.emeAltPhoneObj.phoneNumber: '',
            // emeAltPhoneCodeValue: data && data.emeAltPhoneObj ? data.emeAltPhoneObj.countryCode.toLowerCase() : '',
            cellPhoneValue: data && data.cellPhoneObj ? (data.cellPhoneObj.countryCode !== 'US' ? ('+' + data.cellPhoneObj.dialCode) : '') + data.cellPhoneObj.phoneNumberFormated : '',
            cellPhoneCodeValue: data && data.cellPhoneObj ? data.cellPhoneObj.countryCode.toLowerCase() : '',
            homePhoneValue: data && data.homePhoneObj ? (data.homePhoneObj.countryCode !== 'US' ? ('+' + data.homePhoneObj.dialCode) : '') + data.homePhoneObj.phoneNumberFormated : '',
            homePhoneCodeValue: data && data.homePhoneObj ? data.homePhoneObj.countryCode.toLowerCase() : '',
            workPhoneValue: data && data.workPhoneObj ? (data.workPhoneObj.countryCode !== 'US' ? ('+' + data.workPhoneObj.dialCode) : '') + data.workPhoneObj.phoneNumberFormated : '',
            workPhoneCodeValue: data && data.workPhoneObj ? data.workPhoneObj.countryCode.toLowerCase() : '',
            errors: {}
        });
    }

    onCancelViewMode = () => {
        this.setState({
            changeVisible: this.state.isDirty ? true : false,
            viewMode: this.state.isDirty ? false : true,
            editButtonLabel: "Edit"
        });
    }

    onChangeSaved = () => {
        this.setState({
            isDirty: false,
            viewMode: true,
            editButtonLabel: "Edit"
        });
    }

    onEditMode = () => {
        this.setState({
            viewMode: false,
            isDirty: false,
            showLockedDialog: false
        });
    }

    onTabChange = (e) => {
        this.setState({
            tabIndexWillSwitch: e.index
        });
        this.setState({
            currentTabIndex: e.index,
            isDirty: false,
            viewMode: true
        });
    }

    onDiscardChange = () => {
        let data = this.state.tmpUser;
        formEvent.emit('CHANGE_SAVED', this.onChangeSaved);
        this.setState({
            user: data,
            isDirty: false,
            changeVisible: false,
            viewMode: true,
            cellPhoneValue: data && data.cellPhoneObj ? data.cellPhoneObj.phoneNumber : '',
            cellPhoneCodeValue: data && data.cellPhoneObj ? data.cellPhoneObj.countryCode.toLowerCase() : '',
            homePhoneValue: data && data.homePhoneObj ? data.homePhoneObj.phoneNumber : '',
            homePhoneCodeValue: data && data.homePhoneObj ? data.homePhoneObj.countryCode.toLowerCase() : '',
            workPhoneValue: data && data.workPhoneObj ? data.workPhoneObj.phoneNumber : '',
            workPhoneCodeValue: data && data.workPhoneObj ? data.workPhoneObj.countryCode.toLowerCase() : '',
        });
    }

    discardTab = () => {
        this.onDiscardChange();
        this.setState({
            showTabChangePrompt: false,
            // currentTabIndex: this.state.tabIndexWillSwitch
        });
    }

    resetChange = (keyField) => {
        this.setState({
            user: {
                ...this.state.user,
                [keyField]: this.state.tmpUser[keyField] ? this.state.tmpUser[keyField] : ''
            },
            birthdayValue: new Date(this.state.tmpUser.birthday),
            companySinceValue: new Date(this.state.tmpUser.companySince)
        });
    }

    //#region Basic Information
    handleSaveUserInfo = () => {
        this.setState({ errors: {} })
        String.prototype.replaceAll = function (search, replacement) {
            var target = this;
            return target.replace(new RegExp(search, 'g'), replacement);
        };
        this.userServices.saveUserInfo(this.state.user)
            .then(res => {
                if (!res.errorCode) {
                    formEvent.emit("CHANGE_SAVED");
                    formEvent.emit("RELOAD");
                    this.setState({
                        sec: {
                            ...this.state.sec,
                            secondaryEmail: res.secondaryEmail
                        }
                    })
                    showNotification('success', 'Success Message', 'Action submitted');
                } else {
                    showNotification('error', 'Error Message', res.errorMessage);
                    if (res.errorCode === 400)
                        this.setState({ errors: res.errorObj });
                }
            });
    }

    onCompanySinceChange = (e) => {
        this.setState({
            user: {
                ...this.state.user,
                companySince: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : ''
            },
            companySinceValue: e.value,
            isDirty: true
        });
    }

    //#region Security
    handleSaveSecurityInfo = () => {
        if(this.state.sec.password == this.state.tmpPassword){
            this.setState({ errors: { sec: {} } });

            this.userServices.saveNewPassword(this.state.sec)
                .then(res => {
                    if (!res.errorCode) {
                        this.setState({
                            user: {
                                ...this.state.user,
                                username: res.username,
                                email: res.email,
                                secondaryEmail: res.secondaryEmail,
                            },
                            isDirty: false
                        })
                        showNotification('success', 'Success Message', 'Action submitted');
                    } else {
                        showNotification('error', 'Error Message', res.errorMessage);
                        if (res.errorCode === 400)
                            this.setState({ errors: res.errorObj });
                    }
                })
        }else{
            this.setState({ 
                errors: {
                    ...this.state.errors,
                    pwd:"Password does not match!"
                }
            })
        }
        
    }

    handleSaveAuthorization = () => {
        if(this.state.sec.passcode == this.state.tmpPasscode){
            this.setState({ errors: { sec: {} } });

            this.userServices.saveNewPasscode(this.state.sec)
                .then(res => {
                    if (!res.errorCode) {
                        this.setState({
                            user: {
                                ...this.state.user,
                                username: res.username,
                                email: res.email,
                                secondaryEmail: res.secondaryEmail,
                            },
                            isDirty: false
                        })
                        showNotification('success', 'Success Message', 'Action submitted');
                    } else {
                        showNotification('error', 'Error Message', res.errorMessage);
                        if (res.errorCode === 400)
                            this.setState({ errors: res.errorObj });
                    }
                })
        }else{
            this.setState({ 
                errors: {
                    ...this.state.errors,
                    passcode:"Passcode does not match!"
                }
            })
        }
    }
    //#endregion

    saveChangeTab = () => {
        switch (this.state.currentTabIndex) {
            case 0: // tab Information
                this.handleSaveUserInfo();
                break;
            case 1: // tab Security
                this.handleSaveSecurityInfo();
                break;
            default: break;
        }
        this.setState({
            currentTabIndex: this.state.tabIndexWillSwitch,
            showTabChangePrompt: false,
            isDirty: false,
            viewMode: true
        });
    }

    render(){
        const asterik = <span style={{ color: "red" }}>*</span>
        return (
            <div className="p-grid">
                <BreadcrumbsItem to='/profile'>Profile</BreadcrumbsItem>

                <NotifyChangeTab
                    visible={this.state.showTabChangePrompt}
                    saveChangeTab={() => this.saveChangeTab()}
                    discardTab={() => this.discardTab()}
                    cancelChangeTab={() => this.setState({ showTabChangePrompt: false })}
                />

                <AvatarForm
                    data={this.state.user}
                    visible={this.state.avatarFormDialogVisible}
                    hideFormDialog={() => this.setState({ avatarFormDialogVisible: false })}
                    refreshData={(e) => this.setState({ user: e })}
                />

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <div style={{display: 'inline-block', verticalAlign:'middle'}}>
                            <img src={this.state.user.avatar ? this.state.user.avatar : 'null'} width="45" height="45" />
                            <span className="pi pi-camera" style={{ fontSize: "1.2em", cursor: "pointer", position: "absolute", top: 25, right: -3, background: "#0000008f", color: "#ffffff", borderRadius: 20, padding: 3 }} onClick={() => this.setState({ avatarFormDialogVisible: true })}></span>
                        </div>
                        <h1 style={{display: 'inline-block'}} className="p-margin-top-0 p-margin-bottom-0 p-margin-left-10">{this.state.user != null ? this.state.user.name : ''}</h1>
                    </div>
                </div>

                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8 p-sm-6">
                            <TabView activeIndex={this.state.currentTabIndex} onTabChange={this.onTabChange}>
                                <TabPanel header="Information">
                                    <Dialog visible={this.state.changeVisible} contentStyle={styles.dialogContent} responsive={true}
                                        modal={true} dismissableMask={true} showHeader={false} onHide={() => this.setState({ visible: false })}
                                        footer={<React.Fragment>
                                            <Button label="Discard Changes" icon="pi-md-close" onClick={() => this.onDiscardChange()} />
                                            <Button label="Review Changes" className="p-button-secondary" onClick={() => this.setState({ changeVisible: false })} />
                                        </React.Fragment>}
                                    >
                                        The changes have not been saved!
                                    </Dialog>
                                <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <Fieldset legend="Basic Information" >
                                                <div className="p-grid p-fluid form-group">
                                                    {!this.state.viewMode ?
                                                        <React.Fragment>
                                                            <div className="p-col-12 p-md-6">
                                                                <label className="p-label">Username: </label>
                                                                <div className="p-w-bold">{this.state.user.username ? this.state.user.username : 'N/A'}</div>
                                                            </div>
                                                            <div className="p-col-12 p-md-6">
                                                                <label className="p-label">Contact type: </label>
                                                                <div className="p-w-bold">{this.state.tmpUser.type ? (this.state.tmpUser.type.substring(0, 1).toUpperCase() + this.state.tmpUser.type.substring(1)) : ''}</div>
                                                            </div>
                                                            <FieldWrapper col="p-col-12 p-md-6" keyField="title" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.title, this.state.tmpUser.title)}>
                                                                <label className="p-label">Title: </label>
                                                                <React.Fragment>
                                                                    <Dropdown options={NAME_TITLES} value={this.state.user.title} onChange={(e) => this.setState({ user: { ...this.state.user, title: e.value } })} style={{ "width": "100%" }} />
                                                                </React.Fragment>
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12 p-md-6" keyField="nickname" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.nickname, this.state.tmpUser.nickname)}>
                                                                <label className="p-label">Nickname: </label>
                                                                <React.Fragment>
                                                                    <InputText value={this.state.user.nickname} onChange={(e) => this.setState({ user: { ...this.state.user, nickname: titleCaseText(e.target.value) }, isDirty: true })} />
                                                                    <span className="p-form-error">{this.state.errors.nickname}</span>
                                                                </React.Fragment>
                                                            </FieldWrapper>
                                                            <div className="p-col-12">
                                                                <div className="p-grid">
                                                                    <FieldWrapper col="p-col-12 p-md-4" keyField="firstName" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.firstName, this.state.tmpUser.firstName)}>
                                                                        <label className="p-label">{asterik} First Name: </label>
                                                                        <React.Fragment>
                                                                            <InputText value={this.state.user.firstName} onChange={(e) => this.setState({ user: { ...this.state.user, firstName: titleCaseText(e.target.value) }, isDirty: true })} />
                                                                            <span className="p-form-error">{this.state.errors.firstName}</span>
                                                                        </React.Fragment>
                                                                    </FieldWrapper>
                                                                    <FieldWrapper col="p-col-12 p-md-4" keyField="middleName" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.middleName, this.state.tmpUser.middleName)}>
                                                                        <label className="p-label">Middle Name: </label>
                                                                        <React.Fragment>
                                                                            <InputText value={this.state.user.middleName} onChange={(e) => this.setState({ user: { ...this.state.user, middleName: titleCaseText(e.target.value) }, isDirty: true })} />
                                                                        </React.Fragment>
                                                                    </FieldWrapper>
                                                                    <FieldWrapper col="p-col-12 p-md-4" keyField="lastName" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.lastName, this.state.tmpUser.lastName)}>
                                                                        <label className="p-label">{asterik} Last Name: </label>
                                                                        <React.Fragment>
                                                                            <InputText value={this.state.user.lastName} onChange={(e) => this.setState({ user: { ...this.state.user, lastName: titleCaseText(e.target.value) }, isDirty: true })} />
                                                                            <span className="p-form-error">{this.state.errors.lastName}</span>
                                                                        </React.Fragment>
                                                                    </FieldWrapper>
                                                                </div>
                                                            </div>
                                                            <FieldWrapper col="p-col-12" keyField="email" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.email, this.state.tmpUser.email)}>
                                                                <label className="p-label">Primary Email: </label>
                                                                {this.state.user.username ?
                                                                    <div className="p-w-bold">{this.state.user.email}</div>
                                                                    : <React.Fragment>
                                                                        <InputText value={this.state.user.email} onChange={(e) => this.setState({ user: { ...this.state.user, email: e.target.value }, isDirty: true })} />
                                                                        <span className="p-form-error">{this.state.errors.email}</span>
                                                                    </React.Fragment>}
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12" keyField="secondaryEmail" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.secondaryEmail, this.state.tmpUser.secondaryEmail)}>
                                                                <label className="p-label">Secondary Email: </label>
                                                                <React.Fragment>
                                                                    <InputText value={this.state.user.secondaryEmail} onChange={(e) => this.setState({ user: { ...this.state.user, secondaryEmail: e.target.value }, isDirty: true })} />
                                                                    <span className="p-form-error">{this.state.errors.secondaryEmail}</span>
                                                                </React.Fragment>
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12 p-md-6" keyField="gender" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.gender, this.state.tmpUser.gender)}>
                                                                <label className="p-label">Gender: </label>
                                                                <Dropdown options={GENDERS.filter(gender => gender.value !== "Any")} value={this.state.user !== null ? this.state.user.gender : "Any"} onChange={(e) => this.setState({ user: { ...this.state.user, gender: e.value }, isDirty: true })} style={{ "width": "100%" }} />
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12 p-md-6" keyField="birthday" resetChange={this.resetChange} highlight={highlightDateChanged(this.state.user.birthday, this.state.tmpUser.birthday)}>
                                                                <label className="p-label">{asterik} Date of birth: </label>
                                                                <React.Fragment>
                                                                    <MaskedCalendar value={this.state.birthdayValue} showIcon={true} onChange={(e) => this.onBirthdayChange(e)} monthNavigator={true} yearNavigator={true} yearRange={RANGE_BIRTHDAY} dateFormat="mm-dd-yy" />
                                                                    {/* <DropdownCalendar value={this.state.birthdayValue} showIcon={true} onChange={(e) => this.onBirthdayChange(e)} monthNavigator={true} yearNavigator={true} /> */}
                                                                    <span className="p-form-error">{this.state.errors.birthday}</span>
                                                                </React.Fragment>
                                                            </FieldWrapper>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <div className="p-col-12 p-md-6">
                                                                <label className="p-label">Username: </label>
                                                                <div className="p-w-bold">{this.state.user.username ? this.state.user.username : 'N/A'}</div>
                                                            </div>
                                                            <div className="p-col-12  p-md-6">
                                                                <label className="p-label">Contact type: </label>
                                                                <div className="p-w-bold">{this.state.tmpUser.type ? (this.state.tmpUser.type.substring(0, 1).toUpperCase() + this.state.tmpUser.type.substring(1)) : ''}</div>
                                                            </div>
                                                            <FieldWrapper col="p-col-12 p-md-6" keyField="title">
                                                                <label className="p-label">Title: </label>
                                                                <div>{this.state.user.title ? this.state.user.title : 'Not use'}</div>
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12 p-md-6" keyField="nickname">
                                                                <label className="p-label">Nickname </label>
                                                                <div>{this.state.user.nickname}</div>
                                                            </FieldWrapper>
                                                            <div className="p-col-12">
                                                                <div className="p-grid">
                                                                    <FieldWrapper col="p-col-12 p-md-4" keyField="firstName" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.firstName, this.state.tmpUser.firstName)}>
                                                                        <label className="p-label">First Name: </label>
                                                                        <div>{this.state.user.firstName}</div>
                                                                    </FieldWrapper>
                                                                    <FieldWrapper col="p-col-12 p-md-4" keyField="middleName" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.middleName, this.state.tmpUser.middleName)}>
                                                                        <label className="p-label">Middle Name: </label>
                                                                        <div>{this.state.user.middleName}</div>
                                                                    </FieldWrapper>
                                                                    <FieldWrapper col="p-col-12 p-md-4" keyField="lastName" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.lastName, this.state.tmpUser.lastName)}>
                                                                        <label className="p-label">Last Name: </label>
                                                                        <div>{this.state.user.lastName}</div>
                                                                    </FieldWrapper>
                                                                </div>
                                                            </div>
                                                            <FieldWrapper col="p-col-12" keyField="email">
                                                                <label className="p-label">Primary Email: </label>
                                                                <div><a href={this.state.user.email ? ("mailto:" + this.state.user.email) : "#"}>{this.state.user.email}</a></div>
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12" keyField="secondaryEmail">
                                                                <label className="p-label">Secondary Email: </label>
                                                                <div><a href={this.state.user.secondaryEmail ? ("mailto:" + this.state.user.secondaryEmail) : "#"}>{this.state.user.secondaryEmail}</a></div>
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12 p-md-6" keyField="gender" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.gender, this.state.tmpUser.gender)}>
                                                                <label className="p-label">Gender: </label>
                                                                <div>{GENDERS.find(gender => gender.value === this.state.user.gender) ? GENDERS.find(gender => gender.value === this.state.user.gender).label : ''}</div>
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12 p-md-6" keyField="birthday" resetChange={this.resetChange} highlight={highlightDateChanged(this.state.user.birthday, this.state.tmpUser.birthday)}>
                                                                <label className="p-label">{!this.state.viewMode ? '*' : ''} Date of birth: </label>
                                                                <div>{moment(this.state.user.birthday).format("MM-DD-YYYY")}</div>
                                                            </FieldWrapper>
                                                        </React.Fragment>
                                                    }
                                                    <div className="p-col-12">
                                                        <label className="p-label">{this.state.user.phonePrimary === 'cellPhone' && !this.state.viewMode ? '*' : ''} Cell Phone</label>
                                                        <div className={"p-grid" + (this.state.viewMode ? " p-hide" : '')}>
                                                            <div className="p-col-12 p-md-6 p-sm-8">
                                                                <IntlTelInput autoPlaceholder={false} format={true} defaultCountry={this.state.cellPhoneCodeValue} value={this.state.cellPhoneValue} onPhoneNumberChange={(s, v, c) => this.onChangePhone(s, v, c, 'cellPhone')} onSelectFlag={(v, c, n, s) => this.onChangePhone(s, v, c, 'cellPhone')} css={['intl-tel-input', 'p-inputtext  p-component']} style={{ width: "100%" }} />
                                                            </div>
                                                            <div className="p-col-12 p-md-6 p-sm-4">
                                                                <RadioButton value={'cellPhone'} inputId="rb1" onChange={e => this.setState({ user: { ...this.state.user, phonePrimary: e.value } })} checked={this.state.user.phonePrimary === 'cellPhone' ? true : false} />
                                                                <span className={highlightLabelChanged(this.state.user.phonePrimary, this.state.tmpUser.phonePrimary)}>primary?</span>
                                                            </div>
                                                        </div>
                                                        <span className="p-form-error">{this.state.errors.cellPhone}</span>
                                                        <div className={!this.state.viewMode ? " p-hide" : ""}>{this.state.cellPhoneValue} {this.state.user.phonePrimary === "cellPhone" ? "(primary)" : ""}</div>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">{this.state.user.phonePrimary === 'homePhone' && !this.state.viewMode ? '*' : ''} Home Phone</label>
                                                        <div className={"p-grid" + (this.state.viewMode ? " p-hide" : '')}>
                                                            <div className="p-col-12 p-md-6 p-sm-8">
                                                                <IntlTelInput autoPlaceholder={false} format={true} defaultCountry={this.state.homePhoneCodeValue} value={this.state.homePhoneValue} onPhoneNumberChange={(s, v, c) => this.onChangePhone(s, v, c, 'homePhone')} onSelectFlag={(v, c, n, s) => this.onChangePhone(s, v, c, 'homePhone')} css={['intl-tel-input', 'p-inputtext  p-component']} style={{ width: "100%" }} />
                                                            </div>
                                                            <div className="p-col-12 p-md-6 p-sm-4">
                                                                <RadioButton value={'homePhone'} inputId="rb2" onChange={e => this.setState({ user: { ...this.state.user, phonePrimary: e.value }, isDirty: true })} checked={this.state.user.phonePrimary === 'homePhone' ? true : false} />
                                                                <span className={highlightLabelChanged(this.state.user.phonePrimary, this.state.tmpUser.phonePrimary)}>primary?</span>
                                                            </div>
                                                        </div>
                                                        <span className="p-form-error">{this.state.errors.homePhone}</span>
                                                        <div className={!this.state.viewMode ? " p-hide" : ""}>{this.state.homePhoneValue} {this.state.user.phonePrimary === "homePhone" ? "(primary)" : ""}</div>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <label className="p-label">{this.state.user.phonePrimary === 'workPhone' && !this.state.viewMode ? '*' : ''} Work Phone</label>
                                                        <div className={"p-grid" + (this.state.viewMode ? " p-hide" : '')}>
                                                            <div className="p-col-12 p-md-6 p-sm-8">
                                                                <IntlTelInput autoPlaceholder={false} format={true} defaultCountry={this.state.workPhoneCodeValue} value={this.state.workPhoneValue} onPhoneNumberChange={(s, v, c) => this.onChangePhone(s, v, c, 'workPhone')} onSelectFlag={(v, c, n, s) => this.onChangePhone(s, v, c, 'workPhone')} css={['intl-tel-input', 'p-inputtext  p-component']} style={{ width: "100%" }} />
                                                            </div>
                                                            <div className="p-col-12 p-md-6 p-sm-4">
                                                                <RadioButton value={'workPhone'} inputId="rb3" onChange={e => this.setState({ user: { ...this.state.user, phonePrimary: e.value }, isDirty: true })} checked={this.state.user.phonePrimary === 'workPhone' ? true : false} />
                                                                <span className={highlightLabelChanged(this.state.user.phonePrimary, this.state.tmpUser.phonePrimary)}>primary?</span>
                                                            </div>
                                                        </div>
                                                        <span className="p-form-error">{this.state.errors.workPhone}</span>
                                                        <div className={!this.state.viewMode ? " p-hide" : ""}>{this.state.workPhoneValue} {this.state.user.phonePrimary === "workPhone" ? "(primary)" : ""}</div>
                                                    </div>
                                                </div>
                                            </Fieldset>
                                        </div>
                                        <div className="p-col-12 p-md-6">
                                            {this.state.membershipInfo ? 
                                                <Fieldset legend="Membership Information" >
                                                    <div className="p-grid p-fluid">
                                                        <div className="p-col-12 p-md-6">
                                                            <div className="p-grid">
                                                                <div className="p-col-12">
                                                                    <label className="p-label">Type</label>
                                                                    <div>{this.state.membershipInfo.type}</div>
                                                                </div>
                                                                <div className="p-col-12">
                                                                    <label className="p-label">Standing</label>
                                                                    <div>{this.state.membershipInfo.status}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-12 p-md-6">
                                                            <div className="p-grid p-fluid">
                                                                <div className="p-col-12">
                                                                    <label className="p-label">Date Join</label>
                                                                    <div>{moment(this.state.membershipInfo.purchase).format('MM-DD-YYYY')}</div>
                                                                </div>
                                                                <div className="p-col-12">
                                                                    <label className="p-label">End Date</label>
                                                                    <div>{moment(this.state.membershipInfo.end).format('MM-DD-YYYY')}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.membershipInfo.usageType === 'trial' ?
                                                            <div className="p-col-12">
                                                                <div className="p-grid p-fluid">
                                                                    <div className="p-col-12 p-md-6">
                                                                        <label className="p-label">Max scan</label>
                                                                        <div>{this.state.membershipInfo.maxScan}</div>
                                                                    </div>
                                                                    <div className="p-col-12 p-md-6">
                                                                        <label className="p-label">End Date</label>
                                                                        <div>{this.state.membershipInfo.scanCount}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                </Fieldset>
                                                : ''
                                            }
                                            <Fieldset legend="Company Information" >
                                                <div className="p-grid p-fluid form-group">
                                                    {!this.state.viewMode ?
                                                        <React.Fragment>
                                                            <FieldWrapper col="p-col-12" keyField="companyName" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.companyName, this.state.tmpUser.companyName)}>
                                                                <label className="p-label">Name: </label>
                                                                <React.Fragment>
                                                                    <InputText value={this.state.user.companyName} onChange={(e) => this.setState({ user: { ...this.state.user, companyName: titleCaseText(e.target.value) }, isDirty: true })} />
                                                                </React.Fragment>
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12" keyField="companyPosition" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.companyPosition, this.state.tmpUser.companyPosition)}>
                                                                <label className="p-label">Position: </label>
                                                                <React.Fragment>
                                                                    <InputText value={this.state.user.companyPosition} onChange={(e) => this.setState({ user: { ...this.state.user, companyPosition: titleCaseText(e.target.value) }, isDirty: true })} />
                                                                </React.Fragment>
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12" keyField="companySince" resetChange={this.resetChange} highlight={highlightDateChanged(this.state.user.companySince, this.state.tmpUser.companySince)}>
                                                                <label className="p-label">Start from: </label>
                                                                <React.Fragment>
                                                                    <MaskedCalendar value={this.state.companySinceValue} showIcon={true} onChange={(e) => this.onCompanySinceChange(e)} />
                                                                    <div className="p-form-error">{this.state.errors.companySince}</div>
                                                                </React.Fragment>
                                                            </FieldWrapper>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <FieldWrapper col="p-col-12" keyField="companyName" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.companyName, this.state.tmpUser.companyName)}>
                                                                <label className="p-label">Name: </label>
                                                                <div>{this.state.user.companyName}</div>
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12" keyField="companyPosition" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.companyPosition, this.state.tmpUser.companyPosition)}>
                                                                <label className="p-label">Position: </label>
                                                                <div>{this.state.user.companyPosition}</div>
                                                            </FieldWrapper>
                                                            <FieldWrapper col="p-col-12" keyField="companySince" resetChange={this.resetChange} highlight={highlightDateChanged(this.state.user.companySince, this.state.tmpUser.companySince)}>
                                                                <label className="p-label">Start from: </label>
                                                                <div>{this.state.user.companySince ? moment(this.state.user.companySince).format('MM-DD-YYYY') : ''}</div>
                                                            </FieldWrapper>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </Fieldset>
                                            <Fieldset legend="Staff Information" >
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="cbStaff" disabled={true} checked={this.state.user.staff} onChange={(e) => this.setState({ user: { ...this.state.user, staff: e.checked }, isDirty: true })} />
                                                        <label htmlFor="cbStaff">Is staff?</label>
                                                    </div>
                                                    {this.state.user.staff ?
                                                        <div className="p-col-12">
                                                            <label>Staff type:</label>
                                                            <RadioButton value="staff_full_time" className="p-margin-left-20" disabled={true} onChange={(e) => this.setState({ user: { ...this.state.user, staffType: e.value }, isDirty: true })} checked={this.state.user.staffType === 'staff_full_time'} />
                                                            <label className="p-radiobutton-label">Full-time</label>
                                                            <RadioButton value="staff_part_time" className="p-margin-left-20" disabled={true} onChange={(e) => this.setState({ user: { ...this.state.user, staffType: e.value }, isDirty: true })} checked={this.state.user.staffType === 'staff_part_time'} />
                                                            <label className="p-radiobutton-label">Part-time</label>
                                                        </div>
                                                        : ''
                                                    }
                                                </div>
                                            </Fieldset>
                                            <Fieldset legend="Others Information" >
                                                <div className="p-grid p-fluid form-group">
                                                    <FieldWrapper col="p-col-12" keyField="grade" resetChange={this.resetChange} highlight={highlightChanged(this.state.user.grade, this.state.tmpUser.grade)}>
                                                        <label className="p-label">Grade: </label>
                                                        {!this.state.viewMode ?
                                                            <Dropdown options={this.state.arrGrades} value={this.state.user.grade} onChange={(e) => this.setState({ user: { ...this.state.user, grade: e.value }, isDirty: true })} style={{ "width": "100%" }} />
                                                            : <div>{this.state.user.grade} </div>
                                                        }
                                                    </FieldWrapper>
                                                </div>
                                            </Fieldset>
                                        </div>
                                        
                                        <div className="p-col-12" style={{ textAlign: "right" }}>
                                            {!this.state.viewMode ?
                                                <React.Fragment>
                                                    <Button label="Save Information" icon="pi pi-save" iconPos="left" onClick={(e) => this.handleSaveUserInfo(e)} style={{ width: "auto" }} />
                                                    <Button label="Cancel Edit" icon="pi pi-lock" iconPos="left" onClick={() => this.onCancelViewMode()} />
                                                </React.Fragment>
                                                :
                                                <Button label={this.state.editButtonLabel} icon="pi pi-lock-open" onClick={this.onEditMode} iconPos="left" style={{ width: "auto" }} />
                                            }
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header="Security">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <Fieldset legend="Authentication Information" >
                                                <div className="p-grid p-fluid form-group">
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">* New Password: </label>
                                                        <Password value={this.state.sec.password} onChange={(e) => this.setState({ sec: { ...this.state.sec, password: e.target.value }, isDirty: true, viewMode: false })} feedback={false} />
                                                        <div className="p-form-error">{this.state.errors.sec ? this.state.errors.sec.password : ''}</div>
                                                        <div className="p-form-error">{this.state.errors.pwd}</div>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">* Confirm Password: </label>
                                                        <Password value={this.state.tmpPassword} onChange={(e) => this.setState({ tmpPassword: e.target.value , isDirty: true, viewMode: false })} feedback={false} />
                                                        <div className="p-form-error">{this.state.errors.sec ? this.state.errors.sec.tmpPassword : ''}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12" style={{ textAlign: "right" }}>
                                                    <Button label="Save" icon="pi pi-save" iconPos="left" onClick={this.handleSaveSecurityInfo} style={{ width: "auto" }} />
                                                </div>
                                            </Fieldset>
                                            
                                            <Fieldset legend="Authorization Information" >
                                                <div className="p-grid p-fluid form-group">

                                                    {this.state.sec.passcode == null ?
                                                        <div className="p-col-12 p-md-12">
                                                            <p style={{color:'red'}}>Passcode has not been set</p>
                                                        </div>
                                                        :
                                                        ''
                                                    }
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">* New Passcode: </label>
                                                        <Password value={null} onChange={(e) => this.setState({ sec: { ...this.state.sec, passcode: e.target.value }, isDirty: true, viewMode: false })} feedback={false} />
                                                        <div className="p-form-error">{this.state.errors.sec ? this.state.errors.sec.passcode : ''}</div>
                                                        <div className="p-form-error">{this.state.errors.passcode}</div>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <label className="p-label">* Confirm Passcode: </label>
                                                        <Password value={null} onChange={(e) => this.setState({ tmpPasscode: e.target.value , isDirty: true, viewMode: false })} feedback={false} />
                                                        <div className="p-form-error">{this.state.errors.sec ? this.state.errors.tmpPasscode : ''}</div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12" style={{ textAlign: "right" }}>
                                                    <Button label="Save" icon="pi pi-save" iconPos="left" onClick={this.handleSaveAuthorization} style={{ width: "auto" }} />
                                                </div>
                                            </Fieldset>
                                        </div>
                                        
                                    </div>
                                </TabPanel>
                                <TabPanel header="Departments">
                                    <DataTable lazy={true} value={this.state.dataList}
                                        responsive={true}
                                    >
                                        <Column field="name" header="Name"/>
                                        <Column field="roleName" header="Role"/>
                                    </DataTable>
                                </TabPanel>
                            </TabView>
                        </div>
                        <div className="p-col-12 p-md-4 p-sm-6">
                            {/* <TabView>
                                <TabPanel header="Tasks"></TabPanel>
                            </TabView> */}
                            <div className="p-col-12">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div>
                                        <TabView >
                                            <TabPanel header="My Tasks">
                                                <TaskList type='contact' myTasks={this.state.myTasks} popularTasks={this.handlePopularTasks}/>
                                            </TabPanel>
                                            <TabPanel header="My Todos">
                                                <TodoList popularTasks={() => this.handlePopularTasks}/>
                                            </TabPanel>
                                        </TabView>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const styles = {
    dialogContent: {
        maxHeight: '50px',
        minHeight: '50px',
        overflow: 'hidden',
    }
}