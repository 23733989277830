import React, {Component} from "react";
import { InputText } from "primereact/inputtext";
import { CampaignServices } from "./CampaignServices";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { showNotification } from '../../core/service/NotificationService';
import { InputSwitch } from "primereact/inputswitch";
import { replaceSpaceAndUpperCase, titleCaseText } from "../../core/service/CommonService";

export class CampaignForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            campaign: {
                id: '',
                code: '',
                name: '',
                active: false,
            },
            errors: {}
        }
        this.campaignServices = new CampaignServices();
    }

    action = (data) => {
        this.setState ({
            campaign:{
                id: data ? data.id : '',
                code: data ? data.code : '',
                name: data ? data.name : '',
                active: data ? data.active : true,
            },
            visible: true,
            formHeader: data ? 'Edit Campaign' : 'New Campaign',
            errors: {}
        })
    }

    handleSaveForm = () =>{
        //Start Form Validate
        let errors = {};
        let campaign = this.state.campaign;
        let code = campaign.code;
        if(code === '') errors["code"] = "Field is required";
        else if(code.trim().indexOf(" ") >= 0) errors["code"] = "Value can not contain 'SPACE' character";
        else if(code.trim().length > 255) errors["code"] = "Value greater than the maximum length of 255 characters";

        let name = campaign.name;
        if(name === '') errors["name"] = "Field is required";
        else if(name.trim().length > 255) errors["name"] = "Value greater than the maximum length of 255 characters";

        if(Object.keys(errors).length !== 0 && errors.constructor === Object){
            this.setState({errors: errors});
            return;
        }
        //End Form Validate & Start API Call
        this.setState({errors: {}})

        this.campaignServices.saveCampaign(this.state.campaign)
        .then(res => {
            if(!res.errorCode){
                if(this.props.refreshTable)
                    this.props.refreshTable();
                if(this.props.campaign)
                    this.props.campaign(res);
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj})
            }
        })
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    render(){
        return (
            <div>
                <Sidebar visible={this.state.visible} style={{overflowY: 'auto', width:'25em'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.onHide}>
                    <h2>{this.state.formHeader}</h2>
                    
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label className="p-label">* Code</label>
                            <InputText keyfilter={/[^\s]/} value={this.state.campaign.code} onChange={(e) => this.setState({campaign: {...this.state.campaign, code: replaceSpaceAndUpperCase(e.target.value)}})} />
                            <span className="p-form-error">{this.state.errors.code}</span>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">* Name</label>
                            <InputText value={this.state.campaign.name} onChange={(e) => this.setState({campaign: {...this.state.campaign, name: titleCaseText(e.target.value)}})} />
                            <span className="p-form-error">{this.state.errors.name}</span>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Active?</label>
                            <InputSwitch onLabel="Active" offLabel="InActive" checked={this.state.campaign.active} onChange={(e) => this.setState({ campaign: { ...this.state.campaign, active: e.target.value } })} style={{marginTop: '6px'}} />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handleSaveForm()}/>
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                        </div>
                    </div>
                </Sidebar>
            </div>
        );
    }
}