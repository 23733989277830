import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import KcUserService from '../../service/KcUserService'

const AuthRoute = ({ component: Component, redirectTo, ...rest}) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                KcUserService.isAuthenticated() ? (
                    <Component type={rest.type} key={rest.key} {...props} />
                ) : (
                    <Redirect to={{
                        pathname: redirectTo,
                        state: { from: props.location }
                    }}
                    />
                )
            )}
        />
    )
}

AuthRoute.propTypes = {
    authenticated: PropTypes.bool,
    component: PropTypes.func.isRequired,
}

AuthRoute.defaultProps = {
    authenticated: false,
    redirectTo: "/"
}

export default AuthRoute