import { takeLatest, call, put } from 'redux-saga/effects'
import {
  ANSWER_REQUESTING,
  ANSWER_REQUEST_SUCCESS,
  ANSWER_REQUEST_FAILED,
  ANSWER_DETAIL_REQUESTING,
  ANSWER_DETAIL_REQUEST_SUCCESS,
  ANSWER_DETAIL_REQUEST_FAILED
} from '../constants/answer';
import { getAnswers, getAnswer, executeActions } from '../services/answer';
import moment from 'moment';

function* getAnswersFlow({id}) {
  try {
    let data = yield call(getAnswers, id);
    yield put({
      type: ANSWER_REQUEST_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: ANSWER_REQUEST_FAILED,
      error
    })
  }
}

function* getAnswerDetailFlow({id, answerId}) {
  try {
    let data = yield call(getAnswer, answerId);
    data.groups = data.groups.map(group => {
      group.questions = group.questions.map(question => {
        switch(question.answerType) {
          case 'CHECKBOX':
            if (question.userAnswerIds.length > 0) {
              question.value = question.userAnswerIds.map(userAnswerId => {
                const answer = question.answers.find(answer => answer.id === userAnswerId);
                return answer.answer;
              });
            }
            break;
          case 'RADIOBUTTON':
          case 'DROPDOWN':
            if (question.userAnswerIds.length > 0) {
              question.value = question.userAnswerIds.map(userAnswerId => {
                const answer = question.answers.find(answer => answer.id === userAnswerId);
                return answer.answer;
              })[0];
            }
            break;
          case 'SINGLE_DATE':
            question.value = moment(question.userAnswer).format('MM/DD/YYYY');
            break;    
          case 'DATETIME_12':
            question.value = moment(question.userAnswer).format('MM/DD/YYYY HH:mm:ss A');
            break;  
          case 'DATETIME_24':
            question.value = moment(question.userAnswer).format('MM/DD/YYYY HH:mm:ss');
            break;  
          case 'TIME_12':
            question.value = moment(question.userAnswer).format('HH:mm:ss A');
            break;  
          case 'TIME_24':
            question.value = moment(question.userAnswer).format('HH:mm:ss');
            break;
          case 'MULTIPLE_DATE':
          case 'RANGE_DATE':
          default:
            question.value = question.userAnswer;
        }
        return question;
      })
      return group;
    })
    yield put({
      type: ANSWER_DETAIL_REQUEST_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: ANSWER_DETAIL_REQUEST_FAILED,
      error
    })
  }
}


function* formDetaileWatcher() {
  yield takeLatest(ANSWER_REQUESTING, getAnswersFlow);
  yield takeLatest(ANSWER_DETAIL_REQUESTING, getAnswerDetailFlow);
}

export default formDetaileWatcher;
