import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import {Button} from 'primereact/button'
import { ProgressBar } from 'primereact/progressbar';
import { moneyFormat} from '../../core/service/CommonService';
import { PRODUCT_CLASSIFICATION, PRICING_MODE, DEPOSIT_TYPE, PAYMENT_STATE } from '../../constants';
import classNames from 'classnames';
import { Divider } from '../../core/components/divider/Divider';
import { OrderContext } from './OrderContext';
import { useHistory } from 'react-router-dom';

export const OrderCompleted = (props) => {
    const history = useHistory()
    const orderContext = useContext(OrderContext)

    const order = orderContext.order
    const invoice = orderContext.invoice
    const loading = orderContext.processCheckoutLoading
    const pricingMode = order.pricingMode

    const error = orderContext.processOrderError
    const errorCode = orderContext.processOrderErrorCode

    return(
        <React.Fragment>
            <div className="content-section">
                <div className="container">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card">
                                {loading?
                                <React.Fragment>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-text-center">
                                            <h2 className="p-mt-6 p-mb-4">PLEASE WAIT! ORDER IS BEING PROCESSING...</h2>
                                        </div>
                                    </div>
                                    <div className="p-grid p-justify-center">
                                        <div className="p-col-12 p-md-6">
                                            <ProgressBar mode="indeterminate" style={{height: '20px'}}></ProgressBar>
                                            <p className="p-mt-4 p-text-center p-size-16">Please don't close the browser</p>
                                        </div>
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {error && 
                                    <React.Fragment>
                                        <div className="p-grid">
                                            <div className="p-col-12 p-text-center">
                                                <h2 className="p-mt-6 p-mb-4">ORDER PROCESSING FAIL!</h2>
                                                <i className="pi pi-times-circle" style={{fontSize: '4em', color: 'red'}}></i>        
                                            </div>
                                        </div>
                                        <div className="p-grid p-justify-center">
                                            <div className="p-col-12 p-md-6">
                                                <p className="p-mt-4 p-text-center p-size-16">{error}</p>
                                                <p className="p-mt-4 p-text-center">
                                                    {errorCode===404 ?
                                                    <Button label="Back to Booking Monitoring" icon="pi pi-arrow-circle-left" onClick={() => history.push('/monitoring/booking')} />
                                                    :
                                                    <Button label="Back to Review" icon="pi pi-arrow-circle-left" onClick={() => orderContext.setStepProcess(1)}/>
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    }

                                    {order && !error &&
                                    <div className="p-grid">
                                        <div className="p-col-12 p-text-center p-d-flex p-justify-center p-ai-center p-pt-4">
                                            <i className="pi pi-check-circle p-mr-3" style={{fontSize: '2em', color: 'green'}}></i>
                                            <h2 className="p-m-0">ORDER COMPLETED</h2>
                                        </div>
                                        <div className="p-col-12 p-text-center">
                                            <Button label="Close and back to booking monitoring" onClick={() => history.push('/monitoring/booking')}/>
                                        </div>
                                        <div className="p-col-12"><Divider /></div>
                                        <div className="p-col-12">
                                            <div className="p-grid">
                                                <div className="p-col-12"><span className="p-size-20 p-text-bold">Order Summary</span></div>
                                                <div className="p-col-12 p-lg-4">
                                                    <div className="p-grid">
                                                        <div className="p-col-12"><span>Name : {order.billFirstName} {order.billLastName}</span></div>
                                                        <div className="p-col-12"><span>Email : {order.billEmail}</span></div>
                                                        <div className="p-col-12"><span>Phone : {order.billPhone}</span></div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-md-6 p-lg-4">
                                                    <div className="p-grid">
                                                        {invoice && 
                                                        <div className="p-col-12">
                                                            <span>{order.paymentState===PAYMENT_STATE.completed.value?'Receipt':'Invoice'} Number #: <span className="p-text-bold">{invoice.id}</span></span>
                                                        </div>
                                                        }
                                                        <div className="p-col-12">
                                                            <span>Order Number #: <span className="p-text-bold">{order.orderNumber}</span></span>
                                                        </div>
                                                        <div className="p-col-12">
                                                            <span>Status : <span className="p-text-bold">{PAYMENT_STATE[order.paymentState].label}</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-md-6 p-lg-4">
                                                    <div className="p-grid">
                                                        <div className="p-col-12 p-py-0">
                                                            <p className="p-d-flex p-jc-between p-size-16"><span>Sub Total:</span> <span>{moneyFormat(order.itemsTotal)}</span></p>
                                                            {pricingMode===PRICING_MODE.included_tax && 
                                                            <p className="p-d-flex p-jc-between"><span>Sub Total Without Tax:</span> <span>{moneyFormat(order.itemsTotal - order.taxTotal)}</span></p>
                                                            }
                                                            {order.adjustTotal!==0 && 
                                                            <p className="p-d-flex p-jc-between"><span>Discount:</span> <span>{moneyFormat(order.adjustTotal)}</span></p>
                                                            }
                                                            
                                                            {order.mapTaxes && Object.keys(order.mapTaxes).length>0 && 
                                                                Object.values(order.mapTaxes).map(t => {
                                                                    return (
                                                                    <p className="p-d-flex p-jc-between"><span>{t.name}<span className="p-text-italic">({t.rate}%)</span> :</span> <span>{moneyFormat(t.total)}</span></p>
                                                                    )
                                                                })
                                                            }
                                                            <Divider className="p-mt-1 p-mb-2"/>
                                                            <p className="p-d-flex p-jc-between p-size-16 p-text-bold"><span>Order Total:</span> <span>{moneyFormat(order.total)}</span></p>
                                                            {order.pledgedDepositTotal>0 &&
                                                            <React.Fragment>
                                                                <p className="p-d-flex p-jc-between"><span>Security Deposit:</span> <span>{moneyFormat(order.pledgedDepositTotal)}</span></p>
                                                                <Divider className="p-mt-1 p-mb-2"/>
                                                            </React.Fragment>
                                                            }
                                                            
                                                            {order.paymentState!==PAYMENT_STATE.pending.value && 
                                                            <p className="p-d-flex p-jc-between p-text-bold p-size-16"><span>Checkout Total:</span> <span>{moneyFormat(order.checkoutTotal)}</span></p>
                                                            }

                                                            <p className="p-d-flex p-jc-between p-text-bold p-size-16"><span>Paid:</span> <span>{moneyFormat(order.paidTotal)}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-col-12"><Divider/></div>
                                        <div className="p-col-12">
                                            <div className="p-datatable p-component p-datatable-responsive p-margin-bottom-20">
                                                <div className="p-datatable-wrapper">
                                                    <table style={{width: '100%'}}>
                                                        <thead className="p-datatable-thead">
                                                            <tr>
                                                                <th style={{width: '40%'}}>Product</th>
                                                                <th className="" style={{width: '10%'}}>Q.ty</th>
                                                                <th className="" style={{width: '15%'}}>Price</th>
                                                                <th className="" style={{width: '20%'}}>Description</th>
                                                                <th className="" style={{width: '15%'}}>Checkout</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="p-datatable-tbody">
                                                            {order.items && order.items.map((item, itemIdx) => {
                                                                let tmpProduct = item.product;

                                                                return(
                                                                    <React.Fragment key={item.id}>
                                                                        <tr className="p-datatable-row" style={{backgroundColor: 'rgb(245, 245, 245)'}}>
                                                                            <td>
                                                                                <span className="p-column-title">Product</span>
                                                                                <div className="p-d-inline-block">
                                                                                    <div className="p-text-bold">{item.productName}{item.productVariantName&&('- ['+item.productVariantName+']')} </div>
                                                                                    
                                                                                    {item.autoRecurring ? 
                                                                                    <div className="p-mt-2 p-mb-1">
                                                                                        - Subscription in every {item.subscriptionInfo.period} {item.subscriptionInfo.periodType}{item.subscriptionInfo.period>1?'s':''}
                                                                                    </div>
                                                                                    :
                                                                                    item.rentalInfo && 
                                                                                        <div className="p-mt-2 p-mb-1">
                                                                                            - Rent in {item.rentalInfo.rentalQty} {item.rentalInfo.rentalUnit}{item.rentalInfo.rentalQty>1?'s':''}
                                                                                        </div>
                                                                                    }    
                                                                                </div>
                                                                            </td>
                                                                            <td className="">
                                                                                <span className="p-column-title">Q.ty</span>
                                                                                {(![PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) || !item.pricedByChildren) && 
                                                                                    item.quantity
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <span className="p-column-title">Price</span>
                                                                                <div className="p-d-flex p-flex-column">
                                                                                    <span>{[PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) && item.pricedByChildren?'':moneyFormat(item.total)}</span>
                                                                                    {item.deposit&&
                                                                                    <span className="p-size-12 p-text-italic">(Deposit {moneyFormat(item.depositTotal)})</span>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <span className="p-column-title p-size-16">Description</span>
                                                                                <div className="p-d-inline-block">
                                                                                    {item.deposit && <div className="p-my-2">- Deposit as {DEPOSIT_TYPE[item.depositType].label}</div>}
                                                                                    {item.scheduleInfo &&
                                                                                        <React.Fragment>
                                                                                            {item.deposit && <div className="p-mb-1">- Schedule:</div>
                                                                                            }
                                                                                            <div className={classNames("p-mb-1", {'p-ml-3': item.deposit})}>
                                                                                                Start: {moment(item.scheduleInfo.start).format('MM/DD/YYYY hh:mm A')}
                                                                                            </div>
                                                                                            <div className={classNames("p-mb-1", {'p-ml-3': item.deposit})}>
                                                                                                End: {moment(item.scheduleInfo.end).format('MM/DD/YYYY hh:mm A')}
                                                                                            </div>        
                                                                                        </React.Fragment>
                                                                                    }
                                                                                    
                                                                                </div>
                                                                            </td>
                                                                            <td className="p-text-bold p-size-14">
                                                                                <span className="p-column-title">Checkout</span>
                                                                                {moneyFormat(item.deposit?item.depositType===DEPOSIT_TYPE.installment.value?item.checkout:item.itemBasePriceTotalFinal + item.depositTotal:item.itemBasePriceTotalFinal)}
                                                                            </td>
                                                                        </tr>
                                                                        {item.childItems && item.childItems.map((childItem, childItemIdx) => {
                                                                            return(
                                                                                <tr className="p-datatable-row">
                                                                                    <td className="">
                                                                                        <span className="p-column-title p-pl-sm-6">Product</span>
                                                                                        <div className="p-ml-lg-6 p-d-inline-block">
                                                                                            <div className="p-text-bold">{childItem.productName}{childItem.productVariantName&&('- ['+childItem.productVariantName+']')}</div>
                                                                                            {childItem.rentalInfo && 
                                                                                                <div className="p-mt-2 p-mb-1">
                                                                                                    - Rent in {childItem.rentalInfo.rentalQty} {childItem.rentalInfo.rentalUnit}{childItem.rentalInfo.rentalQty>1?'s':''}
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="">
                                                                                        <span className="p-column-title p-pl-6">Q.ty</span>
                                                                                        {childItem.quantity}
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="p-column-title p-pl-6">Price</span>
                                                                                        {moneyFormat(childItem.total)}
                                                                                    </td>
                                                                                    <td>
                                                                                        <span className="p-column-title p-pl-6 p-size-16">Description</span>
                                                                                        {childItem.scheduleInfo &&
                                                                                            <div className="p-d-inline-block">
                                                                                                <div className="p-mb-1">
                                                                                                    Start: {moment(childItem.scheduleInfo.start).format('MM/DD/YYYY hh:mm A')}
                                                                                                </div>
                                                                                                <div className="p-mt-2">
                                                                                                    End: {moment(childItem.scheduleInfo.end).format('MM/DD/YYYY hh:mm A')}
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </React.Fragment>
                                }
                            </div>
                        </div>
                        
                        {/* {(!loading && !error) && 
                        <div className="p-col-12 p-text-center">
                            <Button label="Back to Products" icon="pi pi-arrow-circle-left" onClick={() => redirectToProduct()} />
                        </div>
                        } */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withNamespaces('message')(OrderCompleted);
