import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import './NotFound.css';
import { withNamespaces, Trans } from 'react-i18next';

const NotFound = (props) => {
    const { t } = props;
    const trans = (path) => t(path, { framework: "react-i18next" });
    return (
        <div className="exception-body notfound-body">
            <div className="exception-container">
                <Trans i18nKey="not_found.not_found">
                <h1 className="exception-title">Not Found</h1>
                </Trans>
                <Trans i18nKey="not_found.not_found_message">
                    <p>
                    The page you requested is not found.
                    </p>
                </Trans>
                <Link to="/">
                    <Button label={trans("not_found.go_to_dashboard")} icon="pi pi-arrow-left" />
                </Link>
            </div>
        </div>
    );
}

export default withNamespaces('message')(NotFound)