/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { SplitButton } from "primereact/splitbutton";
import React, { useEffect, useRef, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch, useParams, useHistory } from "react-router-dom";
import {
  getPageMembershipTypes,
  removeMembershipType,
} from "./MembershipTypeService";
import { getMembershipCategory } from "./MembershipCategoryService";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";
import {
  showErrorNotify,
  showSuccessNotify,
} from "../../core/service/NotificationService";
import { MembershipCategoryForm } from "./MembershipCategoryForm";
import { MembershipTypeForm } from "./MembershipTypeForm";

const MembershipCategoryDetail = () => {
  const match = useRouteMatch();
  const { id } = useParams();
  const history = useHistory();

  const parent = history.location.state.parent

  const [category, setCategory] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [menuExpandedRows, setMenuExpandedRows] = useState();
  const [pageable, setPageable] = useState({
    page: 0,
    rows: 10,
    total: 0,
    sortField: "",
    sortOrder: -1,
  });

  const [isLoadTable, setLoadTable] = useState(true);
  const [isLoadCategory, setLoadCategory] = useState(true);

  const frmType = useRef(null);
  const frmCategory = useRef(null);

  useEffect(() => {
    if (isLoadCategory) loadMembershipCategory();
  }, [isLoadCategory]);

  useEffect(() => {
    if (isLoadTable) loadPageMembershipTypes();
  }, [isLoadTable]);

  const loadPageMembershipTypes = () => {
    getPageMembershipTypes(
      parent.id,
      pageable.page,
      pageable.rows,
      pageable.sortField,
      pageable.sortOrder
    ).then((res) => {
      setDataList(res.content);
      setPageable({
        ...pageable,
        total: res.totalElements,
        page: res.pageable.pageNumber,
        rows: res.pageable.pageSize,
      });
      setLoadTable(false);
    });
  };

  const loadMembershipCategory = () => {
    getMembershipCategory(id).then((res) => {
      setCategory(res);
      setLoadCategory(false);
    });
  };

  const onPage = (e) => {
    setPageable({ ...pageable, page: e.page });
    setLoadTable(true);
  };

  const onSort = (e) => {
    setPageable({
      ...pageable,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    setLoadTable(true);
  };

  const onChangePageLength = (e) => {
    setPageable({ ...pageable, rows: e, page: 0 });
    setLoadTable(true);
  };

  const deleteType = (id) => {
    removeMembershipType(id).then((res) => {
      if (!res.errorCode) {
        setLoadTable(true);
        showSuccessNotify("Remove membership type successfully!");
      } else {
        showErrorNotify("Cannot perform action");
      }
    });
  };

  const tblMenuHeader = (
    <TableHeader
      title="Membership Management"
      pageReport={getTablePageReport(
        pageable.page,
        pageable.rows,
        pageable.total
      )}
      actionTemplate={
        <Button
          className="p-button p-component blue-btn"
          label="Create Membership Type"
          icon="pi-md-plus"
          onClick={() => frmType.current.openForm(null, parent.id)}
        />
      }
      changePageLength={(e) => onChangePageLength(e)}
      refresh={() => setLoadTable(true)}
    />
  );

  return (
    <div className="p-grid">
      <BreadcrumbsItem to={match.url}>Membership Management</BreadcrumbsItem>
      <MembershipCategoryForm
        ref={frmCategory}
        refreshTable={() => setLoadCategory(true)}
      />
      <MembershipTypeForm
        ref={frmType}
        refreshTable={() => setLoadTable(true)}
      />
      <div className="p-col-12">
        <div className="p-d-flex p-justify-between p-align-center">
          <h1>
            Membership Category: <br />
            {category && category.name}
          </h1>
          <div>
            <i
              className="pi pi-pencil btn"
              onClick={() => frmCategory.current.openForm(category)}
              style={{ fontSize: "1.3rem" }}
            ></i>
            <i
              className="pi pi-arrow-left btn"
              onClick={() => history.push(`/membership`)}
              style={{ fontSize: "1.4rem" }}
            ></i>
          </div>
        </div>

        <DataTable
          emptyMessage="There's no operating booking order found"
          header={tblMenuHeader}
          value={dataList}
          lazy={true}
          loading={isLoadTable}
          paginator={true}
          first={pageable.page * pageable.rows}
          rows={pageable.rows}
          totalRecords={pageable.total}
          sortField={pageable.sortField}
          sortOrder={pageable.sortOrder}
          onPage={onPage}
          onSort={onSort}
          responsive={true}
          expandedRows={menuExpandedRows}
          dataKey="id"
          onRowToggle={(e) => setMenuExpandedRows(e.data)}
        >
          <Column field="code" header="Code" sortable={true} />
          <Column field="name" header="Name" sortable={true} />
          <Column field="type" header="Type" />
          <Column field="price" header="Price" sortable={true} />
          <Column
            field="active"
            header="Active"
            body={(rowData) => (rowData.active ? "YES" : "NO")}
          />
          <Column
            field="action"
            header="Action"
            style={{ width: "13em" }}
            bodyClassName="tc-actions"
            body={(rowData) => {
              const actionItems = [
                {
                  label: "Remove",
                  icon: "pi pi-times",
                  command: () => deleteType(rowData.id),
                },
              ];
              return (
                <SplitButton
                  label="Edit"
                  icon="pi pi-pencil"
                  model={actionItems}
                  className="p-button-constrast p-l"
                  onClick={() => frmType.current.openForm(rowData, parent.id)}
                />
              );
            }}
          />
        </DataTable>
      </div>
    </div>
  );
};

MembershipCategoryDetail.propTypes = {};

export default MembershipCategoryDetail;
