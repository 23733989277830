import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { showloading, stoploading } from "../../core/service/LoadingService";
import { saveDoor } from "./DoorService";
import { Dropdown } from "primereact/dropdown";
import { getListAssets } from "../../pim/asset/AssetServices";
import { APP_FEP } from "../../constants";


export const DoorForm = forwardRef((props, ref) => {
  const [header, setHeader] = useState("New Content")
  const [visible, setVisible] = useState(false)
  const [assets, setAssets] = useState([])
  const [frmData, setFrmData] = useState({})
  const [errors, setErrors] = useState({})
  
  useImperativeHandle(ref, () => ({
    openForm(e){
      setHeader((e?'Edit':'New') + ' Door')
      setVisible(true)
      setFrmData(popularFormData(e))
      loadAssets()
    },
    closeForm(){
      closeForm()
    }
  }))

  const popularFormData = (data) => {
    return {
      id: data ? data.id : null,
      assetId: data?.asset?.id,
      name: data ? data.name??'' : '',
      position: data ? data.position??'' : '',
      ipAddress: data ? data.ipAddress??'' : '',
      port: data ? data.port??'' : '',
      username: data ? data.username??'' : '',
      password: data ? data.password??'' : '',
    }
  }

  const closeForm = () => {
    setVisible(false)
    setFrmData({})
    setErrors({})
  }

  const loadAssets = () => {
    getListAssets({app: APP_FEP}).then(res => setAssets(res))
  }

  const handleSave = () => {
    showloading()
    setErrors({})

    saveDoor(frmData)
    .then(res => {
      if (!res.errorCode) {
        if(props.refreshTable)
          props.refreshTable()

        closeForm()
        showSuccessNotify('Action submitted');
      } else {
        showErrorNotify('Cannot perform action');
        if(res.errorCode === 400)
          setErrors(res.errorObj)
      }
    })
    .finally(()=> stoploading())
  }

  return(
      <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
        <div className="p-d-flex p-justify-between ">
          <h2 className="p-margin-top-10">{header}</h2>
          <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
        </div>

        <div className="p-sidebar-line p-mb-3"></div>

        <div className="p-grid p-fluid form-group">
          <div className="p-col-12">
            <label className="p-label">* Asset</label>
            <Dropdown value={frmData.assetId} options={assets} filter onChange={(e) => setFrmData({...frmData, assetId: e.value})} />
            <div className="p-form-error">{errors.asset}</div>
          </div>
          <div className="p-col-12">
            <label className="p-label">* Name</label>
            <InputText value={frmData.name} onChange={(e) => setFrmData({...frmData, name: e.target.value})} />
            <div className="p-form-error">{errors.name}</div>
          </div>
          <div className="p-col-12">
            <label className="p-label">* Position</label>
            <InputText value={frmData.position} keyfilter="pnum" onChange={(e) => setFrmData({...frmData, position: e.target.value})} placeholder="Enter the number" />
            <div className="p-form-error">{errors.position}</div>
          </div>
          <div className="p-col-12">
            <label className="p-label">IP Address</label>
            <InputText value={frmData.ipAddress} keyfilter={/[^\s]/} onChange={(e) => setFrmData({...frmData, ipAddress: e.target.value})} />
            <div className="p-form-error">{errors.ipAddress}</div>
          </div>
          <div className="p-col-12">
            <label className="p-label">Port</label>
            <InputText value={frmData.port} keyfilter="pnum" onChange={(e) => setFrmData({...frmData, port: e.target.value})} />
          </div>
          <div className="p-col-12">
            <label className="p-label">Username</label>
            <InputText value={frmData.username} onChange={(e) => setFrmData({...frmData, username: e.target.value})} />
          </div>
          <div className="p-col-12">
            <label className="p-label">Password</label>
            <InputText value={frmData.password} onChange={(e) => setFrmData({...frmData, password: e.target.value})} />
          </div>
        </div>

        <div className="p-sidebar-line p-my-3"></div>

        <div className="p-grid">
          <div className="p-col-12 p-d-flex p-justify-between">
            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={closeForm}/>
            <Button label="Submit" icon="pi pi-check" onClick={() => handleSave()}/>
          </div>
        </div>
    </Sidebar>
  )
})
