import React, { Component } from 'react';
import moment from 'moment';
import { ParticipantServices } from '../../constituent-management/participant/ParticipantServices';
import { ProgramServices } from './ProgramServices';
import { CampaignServices } from '../../scp/campaign/CampaignServices';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showNotification } from '../../core/service/NotificationService';
import { Sidebar } from 'primereact/sidebar';
import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

export class EnrollParticipantForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {
				fromProgramId: '',
				toProgramId: '',
				campaignId: '',
				fromCourseId: '',
				toCourseId: '',
				participants: [],
			},
			participants: [],
			isChkAllPart: false,
			visible: false,
			formHeader: 'Enrol To Class',
			errors: {},
		};
		this.participantServices = new ParticipantServices();
		this.programServices = new ProgramServices();
		this.campaignServices = new CampaignServices();
	}

	action = (fromProgramId, fromCourseId) => {
		this.setState(
			{
				data: {
					fromProgramId: fromProgramId,
					toProgramId: '',
					campaignId: '',
					fromCourseId: fromCourseId,
					toCourseId: '',
					participants: [],
				},
				visible: true,
				errors: {},
			},
			() => {
				this.getListParticipants();
			}
		);
	};

	componentDidMount() {
		this.loadAllPrograms();
		this.loadAllCampaigns();
	}

	handleSave = () => {
		this.setState({ errors: {} });
		showloading();
		this.participantServices.enrollParticipants(this.state.data).then((res) => {
			stoploading();
			if (!res.errorCode) {
				this.onHide();
				showNotification('success', 'Success Message', 'Action submitted');
			} else {
				showNotification('error', 'Error Message', 'Cannot perform action');
				if (res.errorCode === 400) this.setState({ errors: res.errorObj });
			}
		});
	};

	loadAllPrograms = () => {
		this.programServices.getListAllPrograms().then((res) => {
			this.setState({ programs: res ? res : [] });
		});
	};

	loadAllCampaigns = () => {
		this.campaignServices.getListCampaign().then((res) => {
			this.setState({ campaigns: res ? res : [] });
		});
	};

	loadAllCoursesByProgram = () => {
		this.programServices.getListProgramCourses(this.state.data.toProgramId, this.state.data.campaignId).then((res) => {
			let tempArr = res ? res : [];
			tempArr = tempArr.filter((part) => part.value !== this.state.data.fromCourseId);
			this.setState({ courses: tempArr });
		});
	};

	getListParticipants = () => {
		this.participantServices.getParticipants({ childRefId: this.state.data.fromCourseId }).then((res) => {
			if (!res.errorCode) {
				this.setState({
					participants: res ? res : [],
				});
			}
		});
	};

	onChangeProgram = (e) => {
		this.setState(
			{
				data: {
					...this.state.data,
					toProgramId: e.value,
				},
			},
			() => {
				if (this.state.data.toProgramId) this.loadAllCoursesByProgram();
			}
		);
	};

	onChangeCampaign = (e) => {
		this.setState(
			{
				data: {
					...this.state.data,
					campaignId: e.value,
					courseId: '',
				},
			},
			() => {
				if (this.state.data.toProgramId) this.loadAllCoursesByProgram();
			}
		);
	};

	onCheckAllPart = (e) => {
		let tempArr = [...this.state.data.participants];
		this.state.participants.map((part) => {
			if (e.checked) {
				if (!tempArr.includes(part.id)) {
					tempArr.push(part.id);
				}
			} else {
				tempArr.splice(tempArr.indexOf(part.id), 1);
			}
		});
		this.setState({
			data: {
				...this.state.data,
				participants: tempArr,
			},
			isChkAllPart: e.checked,
		});
	};

	onCheckPart = (e) => {
		let tempArr = [...this.state.data.participants];
		if (e.checked) {
			if (!tempArr.includes(e.value)) tempArr.push(e.value);
		} else {
			tempArr.splice(tempArr.indexOf(e.value), 1);
		}
		this.setState({
			data: {
				...this.state.data,
				participants: tempArr,
			},
			isChkAllPart: false,
		});
	};

	onHide = () => {
		this.setState({ visible: false });
	};

	render() {
		return (
			<Sidebar visible={this.state.visible} dismissable={false} style={{ overflowY: 'auto' }} className='p-sidebar-md' position='right' blockScroll={true} onHide={this.onHide}>
				<h2>{this.state.formHeader}</h2>

				<div className='p-grid p-fluid form-group'>
					<div className='p-col-12'>
						<label className='p-label'>* To Program</label>
						<Dropdown value={this.state.data.toProgramId} options={this.state.programs} onChange={(e) => this.onChangeProgram(e)} style={{ width: '100%' }} />
						<div className='p-form-error'>{this.state.errors.program}</div>
					</div>
					<div className='p-col-12'>
						<label className='p-label'>Campaign</label>
						<Dropdown value={this.state.data.campaignId} options={this.state.campaigns} onChange={(e) => this.onChangeCampaign(e)} style={{ width: '100%' }} />
					</div>
					<div className='p-col-12'>
						<label className='p-label'>* To Course</label>
						<Dropdown
							value={this.state.data.toCourseId}
							options={this.state.courses}
							onChange={(e) => this.setState({ data: { ...this.state.data, toCourseId: e.value } })}
							style={{ width: '100%' }}
						/>
						<div className='p-form-error'>{this.state.errors.course}</div>
					</div>
					<div className='p-col-12'>
						<Fieldset legend='Participants'>
							<table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: 'none' }}>
								<thead>
									<tr>
										<td className='p-padding-all-5' style={{ border: '1px solid #e6e6e6' }}>
											<Checkbox checked={this.state.isChkAllPart} onChange={(e) => this.onCheckAllPart(e)} />
										</td>
										<td className='p-padding-all-5 p-c' style={{ border: '1px solid #e6e6e6' }}>
											<strong>Name</strong>
										</td>
										<td className='p-padding-all-5 p-c' style={{ border: '1px solid #e6e6e6' }}>
											<strong>Day of bith</strong>
										</td>
										<td className='p-padding-all-5 p-c' style={{ border: '1px solid #e6e6e6' }}>
											<strong>Age</strong>
										</td>
									</tr>
								</thead>
								<tbody>
									{this.state.participants.map((part, idx) => {
										return (
											<tr key={idx}>
												<td className='p-padding-all-5' style={{ border: '1px solid #e6e6e6' }}>
													<Checkbox value={part.id} checked={this.state.data.participants.includes(part.id)} onChange={(e) => this.onCheckPart(e)} />
												</td>
												<td className='p-padding-all-5' style={{ border: '1px solid #e6e6e6' }}>
													{part.name}
												</td>
												<td className='p-padding-all-5' style={{ border: '1px solid #e6e6e6' }}>
													{moment(part.birthday).format('MM-DD-YYYY')}
												</td>
												<td className='p-padding-all-5' style={{ border: '1px solid #e6e6e6' }}>
													{part.ageString}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</Fieldset>
					</div>
				</div>
				<div className='p-grid'>
					<div className='p-col-12 p-r p-margin-top-30 p-line-top'>
						<Button label='Submit' icon='pi pi-check' disabled={this.state.data.participants.length === 0 ? true : false} onClick={(e) => this.handleSave()} />
						<Button label='Cancel' icon='pi-md-close' className='p-button-secondary' onClick={this.onHide} />
					</div>
				</div>
			</Sidebar>
		);
	}
}
