import React, {Component} from 'react';
import {MembershipTemplateServices} from "./MembershipTemplateServices";

import {Fieldset} from 'primereact/fieldset';
import {Spinner} from 'primereact/spinner';
import {Button} from "primereact/button";
import {Dropdown} from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';
import { showNotification } from '../../core/service/NotificationService';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { replaceSpaceAndUpperCase, titleCaseText } from "../../core/service/CommonService";

export class MembershipTemplatePriceForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            templateLevel: {},
            price: {},
            errors: {}
        }

        this.membershipTemplateServices = new MembershipTemplateServices();
    }

    popularFormData = (parent, data) => {
        this.setState({
            templateLevel: parent,
            price: {
                id: data?data.id:'',
                name: data?data.name:'',
                draft: data?data.draft:false,
                period: data?data.period:'1',
                periodType: data?data.periodType:'monthly',
                price: data?data.price:0,
                online: data?data.online:true,
                weight: data?data.weight:0
            },
            errors: {}
        });

    }

    resetFormData = () => {
        this.popularFormData(this.state.templateLevel, null);
    }

    handleSaveMembershipPrice = () => {
        this.membershipTemplateServices.saveMembershipTemplatePrice(this.state.templateLevel.id, this.state.price).then(res => {
            if(!res.errorCode){
                this.resetFormData();
                this.props.reloadTable(res);
                this.props.hideFormDialog();
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        });
    }

    onHide = () =>{
        this.props.hideFormDialog();
        this.setState({
            errors: {}
        });
    }

    render(){
        const periodType = [
            { label: 'Month', value: 'monthly' },
            { label: 'Year', value: 'yearly' }
        ];

        return (
            <Sidebar visible={this.props.visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.props.hideFormDialog}>
                <h2>{this.props.header}</h2>

                <div className="p-grid">
                    <div className="p-col-12">
                        <Fieldset legend="Information">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <label className="p-label">* Name(for display)</label>
                                    <InputText value={this.state.price.name} onChange={(e) => this.setState({ price: { ...this.state.price, name: titleCaseText(e.target.value) } })} />
                                    <div className="p-form-error">{this.state.errors.name} </div>
                                </div>
                                
                                <div className="p-col-12">
                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.price.draft} onChange={(e) => this.setState({ price: { ...this.state.price, draft: e.target.value } })} />
                                    <label className="p-margin-left-10">Is draft payment?</label>
                                </div>
                                <div className="p-col-12">
                                    <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.price.online} onChange={(e) => this.setState({ price: { ...this.state.price, online: e.target.value } })} />
                                    <label className="p-margin-left-10">Is online?</label>
                                </div>
                                <div className="p-col-12 p-md-4">
                                    <label className="p-label">Weight (for order)</label>
                                    <InputText value={this.state.price.weight} keyfilter="int" onChange={(e) => this.setState({price: {...this.state.price, weight: e.target.value}})} />
                                    <div className="p-form-error">{this.state.errors.weight}</div>
                                </div>
                            </div>
                        </Fieldset>
                        <Fieldset legend="Price">
                            <div className="p-grid p-fluid">
                                <div className="p-col-12">
                                    <label className="p-label">Initial Fee</label>
                                    <Spinner value={this.state.price.price} min={0} step={0.25} onChange={(e) => this.setState({price: {...this.state.price, price: e.target.value}})} />
                                    <div className="p-form-error">{this.state.errors.price} </div>
                                </div>
                            </div>
                        </Fieldset>

                        <Fieldset legend="Frequency Information">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12">
                                    <label className="p-label">Period</label>
                                    <Spinner value={this.state.price.period} min={1} onChange={(e) => this.setState({ price: { ...this.state.price, period: e.target.value } })} />
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Period Type</label>
                                    <Dropdown id="periodType" value={this.state.price.periodType} options={periodType} onChange={(e) => this.setState({ price: { ...this.state.price, periodType: e.value } })} style={{ width: '100%' }} placeholder="Select a period type" />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Submit" icon="pi pi-check" onClick={this.handleSaveMembershipPrice}/>
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                    </div>
                </div>
            </Sidebar>
        );
    }
}