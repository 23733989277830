import axios from "axios"
import { API_BASE_URL, SCP_PATH } from "../../../../constants";
import querystring from "querystring";
import moment from "moment";
import { converObjetToFormData } from "../../../../core/service/CommonService";

const DOCUMENT_SERVICE_URL = API_BASE_URL + SCP_PATH + "/identity-documents"
export class IdentityDocumentServices {
    
    getPageIdentityDocument(page, size, sortField, sortOrder, userId){
        const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
        const sort = sortField ? `${sortField},${order}`: "";
        return axios.get(`${API_BASE_URL}/scp/api/identity-document/page/` + userId,{
            params: {page, size, sort}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    getListIdentityDocument(active){
        return axios.get(`${API_BASE_URL}/scp/api/identity-document`,{
            params: {active}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    getIdentityDocument(id){
        return axios.get(`${API_BASE_URL}/scp/api/identity-document/${id}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveIdentityDocument(data, userId){
        return axios.post(`${API_BASE_URL}/scp/api/identity-document/` + userId, {
            userId : userId,
            docType : data.docType,
            docNumber : data.docNumber,
            expiredDate: moment(new Date(data.expiredDate)).format("YYYY-MM-DD")
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    updateIdentityDocument(data) {
        return axios.put(`${API_BASE_URL}/scp/api/identity-document/` + data.id, {
            userId: data.userId,
            docType: data.docType,
            docNumber: data.docNumber,
            expiredDate: moment(new Date(data.expiredDate)).format("YYYY-MM-DD")
        })
            .then(res => res.data).catch(error => console.log(error));
    }

    deleteIdentityDocument(data) {
        return axios.delete(`${API_BASE_URL}/scp/api/identity-document/` + data.id)
            .then(res => res.data).catch(error => console.log(error));
    }

    actionToPrograms(id, ids){
        return axios.post(`${API_BASE_URL}/scp/api/identity-document/${id}/action/programs`,
            querystring.stringify({ids: ids})
        ).then(res => res.data).catch(error => console.log(error));
    }
}

export function getListIdentityDocuments (filter) {
    return axios.post(`${DOCUMENT_SERVICE_URL}/list`, filter)
    .then(res => res.data).catch(error => console.log(error))
}

export function saveIdentityDocument(data){
    let formData = new FormData()
    for (var key in data) {
        converObjetToFormData(formData, key, data[key])
    }

    return axios.post(`${DOCUMENT_SERVICE_URL}`, formData,
        {headers: { 'Content-Type': 'multipart/form-data' }}
    ).then(res => res.data).catch(error => console.log(error))
}

export function removedentityDocument(id){
    return axios.delete(`${DOCUMENT_SERVICE_URL}/${id}`).then(res => res.data).catch(error => console.log(error))
}