import React, {Component} from 'react';
import {MembershipTemplateServices} from './MembershipTemplateServices';
// import {ProductServices} from '../product/ProductServices';

import {TabView,TabPanel} from 'primereact/tabview';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Link} from 'react-router-dom';

import {MembershipTemplateLevelForm} from './MembershipTemplateLevelForm';
import {MembershipTemplateRuleForm} from './MembershipTemplateRuleForm';
import {MembershipTemplatePriceForm} from './MembershipTemplatePriceForm';
import { showNotification } from '../../core/service/NotificationService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { SplitButton } from 'primereact/splitbutton';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { MembershipTemplatePriceSettingForm } from './MembershipTemplatePriceSettingForm';
import moment from 'moment';
import { CHANNEL_TYPE } from '../../constants';

export class MembershipTemplateLevelDetails extends Component{
    constructor(props){
        super(props);
        this.state = {
            templateName: '',
            templateLevel: {},
            prices: [],
            priceFormHeader: 'Add Price Setting',
            priceFormVisible: false,
            rules: [],
            ruleFormHeader: 'Add Rule',
            ruleFormVisible: false,
            addons: []
        }

        // this.productServices = new ProductServices();
        this.membershipTemplateServices = new MembershipTemplateServices();
    }

    componentDidMount(){
        showloading();
        this.getMembershipTemplateLevel();
        this.getMembershipTemplate();
    }

    getMembershipTemplateLevel = () => {
        this.membershipTemplateServices.getMembershipTemplate(this.props.match.params.lid, this.props.match.params.id).then(res => {
            stoploading();
            if(!res.errorCode){
                this.setState({
                    templateLevel: {
                        ...res,
                        applyTax: res && res.product ? res.product.applyTax : false
                    },
                    rules: res.rules,
                    prices: res.prices,
                    addons: res.product.addons
                },()=> {
                    this.frmMembershipTemplateLevel.popularFormData(this.state.templateLevel);
                });
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        });
    }

    getMembershipTemplate = () => {
        this.membershipTemplateServices.getMembershipTemplate(this.props.match.params.id).then(res => {
            if(!res.errorCode){
                this.setState({
                    templateName: res.name
                });
                stoploading();
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        });
    }

    tblPriceActionTemplate = (rowData, column) => {
        let dropActions = [
            {   label: 'Edit Price', icon: 'pi pi-pencil',
                command: (e) => { this.addOrEditPriceSetting(rowData) }
            },
            {   label: 'Delete Price', icon: 'pi pi-times',
                command: (e) => { this.deleteNotify.delete(rowData.id) }
            }
        ];
        return <SplitButton label="View" className="p-button-constrast" model={dropActions} tooltip={'View Price Setting'} tooltipOptions={{position: "top"}} onClick={() => this.priceSettingForm.action(rowData.id)}/>
    }

    addOrEditPriceSetting = (data) => {
        this.frmTemplateLevelPrice.popularFormData(this.state.templateLevel, data);
        this.setState({
            priceFormHeader: (data?'Edit': 'Add') + ' Price Option',
            priceFormVisible:true
        });
    }

    updateTblPriceSetting = (data, removeId) => {
        let tmpDatas = [...this.state.prices];
        let idx = -1; 

        if(data){
            idx = this.state.prices.findIndex((el) => el.id === data.id);
        }else{
            idx = this.state.prices.findIndex((el) => el.id === removeId);
        }

        if(idx !== -1){
            if(data){
                tmpDatas.splice(idx, 1, data);
            }else{
                tmpDatas.splice(idx, 1);
            }
        }else{
            tmpDatas.push(data);
        }

        this.setState({prices: tmpDatas});
    }

    removePriceSetting = (id) => {
        this.membershipTemplateServices.removeMembershipTemplatePrice(this.state.templateLevel.id, id).then(res => {
            if(!res.errorCode){
                this.updateTblPriceSetting(null, id);
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        });
    }

    tblRuleActionTemplate = (rowData, column) => {
        let dropActions = [
            {   label: 'Delete Rule', icon: 'pi pi-times',
                command: (e) => { this.deleteNotify.delete(rowData.id) }
            }
        ];
        return <SplitButton label="Edit" className="p-button-constrast" model={dropActions} tooltip={'Edit Rule'} tooltipOptions={{position: "top"}} onClick={() => this.addOrEditRuleSetting(rowData)}/>
    }

    addOrEditRuleSetting = (data) => {
        this.frmTemplateLevelRule.popularFormData(this.state.templateLevel, data);
        this.setState({
            ruleFormHeader: (data?'Edit ': 'Add') + ' Rule',
            ruleFormVisible:true
        });
    }

    updateTblRuleSetting = (data, removeId) => {
        let tmpDatas = [...this.state.rules];
        let idx = -1; 

        if(data){
            idx = this.state.rules.findIndex((el) => el.id === data.id);
        }else{
            idx = this.state.rules.findIndex((el) => el.id === removeId);
        }

        if(idx !== -1){
            if(data){
                tmpDatas.splice(idx, 1, data);
            }else{
                tmpDatas.splice(idx, 1);
            }
        }else{
            tmpDatas.push(data);
        }

        this.setState({rules: tmpDatas});
    }

    removeRuleSetting = (id) => {
        this.membershipTemplateServices.removeMembershipTemplateRule(this.state.templateLevel.id, id).then(res => {
            if(!res.errorCode){
                this.updateTblRuleSetting(null, id);
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        });
    }

    tblAddonActionTemplate = (rowData, column) => {
        let dropActions = [
            {   label: 'Delete Addon', icon: 'pi pi-times',
                command: (e) => { this.deleteNotify.delete(rowData.id) }
            }
        ];
        return <SplitButton label="Edit" className="p-button-constrast" model={dropActions} tooltip={'Edit Addon'} tooltipOptions={{position: "top"}} onClick={() => this.frmTemplateLevelAddon.openForm(this.state.templateLevel.product, rowData, 'addon')}/>
    }

    updateTblAddons = (data, removeId) => {
        let tmpDatas = [...this.state.addons];
        let idx = -1; 

        if(data){
            idx = this.state.addons.findIndex((el) => el.id === data.id);
        }else{
            idx = this.state.addons.findIndex((el) => el.id === removeId);
        }

        if(idx !== -1){
            if(data){
                tmpDatas.splice(idx, 1, data);
            }else{
                tmpDatas.splice(idx, 1);
            }
        }else{
            tmpDatas.push(data);
        }

        this.setState({addons: tmpDatas});
    }

    // removeTemplateLevelAddon = (id) => {
    //     this.productServices.removeProductAssociation(this.state.templateLevel.product.id, id).then(res => {
    //         if(!res.errorCode){
    //             this.updateTblAddons(null, id);
    //             showNotification('success', 'Success Message', 'Action Submitted');
    //         }else{
    //             showNotification('error', 'Error Message', res.errorMessage);
    //         }
    //     });
    // }

    render(){
        let tblRuleHeader = 
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                {this.state.templateLevel.type==='Individual' && this.state.rules.length===1?'':<Button label="Create Rule" icon="pi pi-plus" className="p-button-constrast" style={{'float':'left'}} onClick={(e) => this.addOrEditRuleSetting(null)}/>}
                <h3 className="fle-datatable-header-title">Rules</h3>
            </div>;

        let tblPriceHeader = 
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <Button label="Create Price Option" icon="pi pi-plus" className="p-button-constrast" style={{'float':'left'}} onClick={(e) => this.addOrEditPriceSetting(null)}/>
                <h3 className="fle-datatable-header-title">Prices</h3>
            </div>;

        let tblAddonHeader = 
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <Button label="Create Addon" icon="pi pi-plus" className="p-button-constrast" style={{'float':'left'}} onClick={(e) => this.frmTemplateLevelAddon.openForm(this.state.templateLevel.product, null, 'addon')}/>
                <h3 className="fle-datatable-header-title">Addons</h3>
            </div>;

        return(
            <div className="p-grid">
                <BreadcrumbsItem to="/membership_templates">Membership Types</BreadcrumbsItem>
                <BreadcrumbsItem to={`/membership_templates/${this.props.match.params.id}`}>{this.state.templateName}</BreadcrumbsItem>
                <BreadcrumbsItem to={this.props.match.url}>{this.state.templateLevel.name}</BreadcrumbsItem>

                
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            Membership Level: {this.state.templateLevel.name}
                            <div className="p-toolbar-group-right">
                                <Link to={`/membership_templates/${this.props.match.params.id}`}>
                                    <Button tooltip="Back to Level" tooltipOptions={{position: "top"}} icon="pi pi-caret-left" className="p-button-primary"></Button>
                                </Link>
                            </div>
                        </h1>
                    </div>
                    <TabView activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.setState({activeTabIndex: e.index})} renderActiveOnly={false}>
                        <TabPanel header="Information">
                            <MembershipTemplateLevelForm ref={(el) => this.frmMembershipTemplateLevel = el}
                                refreshData={(e) => this.setState({templateLevel: e})}
                                parent={this.props.match.params.id}
                                data={this.state.templateLevel}
                                submitButtonVisible={true}
                            />
                        </TabPanel>
                        <TabPanel header="Price Options">
                            <div className="p-grid">
                                <MembershipTemplatePriceForm ref={(el) => this.frmTemplateLevelPrice = el} header={this.state.priceFormHeader}
                                    visible={this.state.priceFormVisible} 
                                    hideFormDialog={(e) => this.setState({priceFormVisible: false})}
                                    reloadTable={(e) => this.updateTblPriceSetting(e)} />

                                <DeleteNotification ref={el => this.deleteNotify=el}
                                    accessDelete={(e) => this.removePriceSetting(e)}
                                />

                                <MembershipTemplatePriceSettingForm ref={el => this.priceSettingForm=el} 
                                    refreshTable={() => this.getMembershipTemplateLevel()}
                                />

                                <div className="p-col-12">
                                    <DataTable lazy={true} header={tblPriceHeader} value={this.state.prices}>
                                        <Column field="name" header="Name"/>
                                        <Column field="draft" header="Is Draft" body={rowData => rowData.draft?'YES':'NO'}/>
                                        <Column field="price" header="Current Fee" 
                                            body={rowData => {
                                                let price=rowData.price, start='x', end='x';
                                                if(rowData.priceSetting){
                                                    price = rowData.priceSetting.price;
                                                    start = rowData.priceSetting.start ? moment(rowData.priceSetting.start).format('MM-DD-YYYY hh:mm A') :'x'
                                                    end = rowData.priceSetting.end ? moment(rowData.priceSetting.end).format('MM-DD-YYYY hh:mm A') :'x'
                                                }
                                                return ('$' + price + ' (' + start + ' - '+ end +')' )
                                            }}
                                        />
                                        <Column field="period" header="Period"/>
                                        <Column field="periodType" header="Period Type" body={(rowData) => rowData.periodTypeName}/>
                                        <Column field="online" header="Online" body={rowData => rowData.online?'YES':'NO'}/>
                                        <Column field="weight" header="Weight"/>
                                        <Column header="" className="tc-actions" body={this.tblPriceActionTemplate} style={{textAlign:'right',width:'10em'}}/>
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Rules">
                            <div className="p-grid">
                                <MembershipTemplateRuleForm ref={(el) => this.frmTemplateLevelRule = el} header={this.state.ruleFormHeader}
                                    visible={this.state.ruleFormVisible} 
                                    hideFormDialog={(e) => this.setState({ruleFormVisible: false})}
                                    reloadTable={(e) => this.updateTblRuleSetting(e)} />

                                <DeleteNotification ref={el => this.deleteNotify=el}
                                    accessDelete={(e) => this.removeRuleSetting(e)}
                                />

                                <div className="p-col-12">
                                    <DataTable lazy={true} header={tblRuleHeader} value={this.state.rules}>
                                        <Column field="personTypeName" header="Person Type"/>
                                        <Column field="quantity" header="Quantity" body={(rowData) => {return (rowData.quantity===0?'Unlimited':rowData.quantity)}}/>
                                        <Column field="ageRange" header="Age require"/>
                                        <Column field="required" header="Member selection required" body={rowData => rowData.required?'YES':'NO'}/>
                                        <Column header="" className="tc-actions" body={this.tblRuleActionTemplate} style={{textAlign:'right',width:'10em'}}/>
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Addons">
                            <div className="p-grid">
                                <DeleteNotification ref={el => this.deleteNotify=el}
                                    accessDelete={(e) => this.removeTemplateLevelAddon(e)}
                                />

                                <div className="p-col-12">
                                    <DataTable lazy={true} header={tblAddonHeader} value={this.state.addons}>
                                        <Column header="Name" body={rowData => <Link to={`/product-management/addon/${rowData.associationProduct.id}`} className="nav-link" >{rowData.associationProduct.name}</Link>}/>
                                        <Column header="Price" body={rowData => rowData.unitPrice?('$'+rowData.unitPrice):('$'+rowData.associationProduct.price01)}/>
                                        <Column header="Association method" 
                                            body={rowData => {
                                                if(rowData.included){
                                                    return "Included"
                                                }else{
                                                    return "Optional";
                                                }
                                            }}/>
                                        <Column header="Association Q.ty" 
                                            body={rowData => {
                                                if(rowData.included){
                                                    return rowData.quantity;
                                                }else{
                                                    if(rowData.allowInputQty)
                                                        return 'Max q.ty can purchase is ' + rowData.quantity;
                                                    else
                                                        return rowData.quantity + ' per selection';
                                                }
                                            }}/>
                                        <Column header="" className="tc-actions" body={this.tblAddonActionTemplate} style={{textAlign:'right',width:'10em'}}/>
                                    </DataTable>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        );
    }
}
