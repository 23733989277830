import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import React, { useState, useEffect, useRef } from 'react';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { TaxForm } from '../../pim/tax/TaxForm';
import { getPageTaxs, getTaxes, removeTax } from '../../pim/tax/TaxSerivce';
import { saveScpConfig } from './ConfigruationServices';
import { PRICING_MODE } from '../../constants';
import { RadioButton } from 'primereact/radiobutton';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { SplitButton } from 'primereact/splitbutton';

export const TenantTaxSetting = (props) => {
    const taxForm = useRef(null)

    const [dataConfig, setDataConfig] = useState({})
    const [pageable, setPageble] = useState({})
    const [dataList, setDataList] = useState([])
    const [isLoaded, setLoaded] = useState(true)
    const [taxes, setTaxes] = useState([])

    useEffect(() =>{
        setDataConfig(props.data)
    },[props.data])

    const handleSaveConfig = () => {
        saveScpConfig(dataConfig, 'tax')
        .then(res => {
            if(!res.errorCode){
                if(props.onSave){
                    props.onSave({pricingMode: res.pricingMode, defaultTax: res.defaultTax})
                }
                showSuccessNotify('Action Submitted');
            }else{
                showErrorNotify(res.errorMessage);
            }
        })
    }

    useEffect(() =>{
        if(isLoaded){
            loadPageTaxs()
            loadTaxes()
            setLoaded(false)
        }
    },[isLoaded])

    const loadTaxes = () => {
        getTaxes()
        .then(data => {
            setTaxes(data)
        })
    }

    const loadPageTaxs = () => {
        getPageTaxs(pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageble({
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize,
            })
        })
    }

    const onPage = (e) =>{
        setPageble({
            page: e.page
        })
        setLoaded(true)
    }

    const onSort = (e) =>{
        setPageble({
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoaded(true)
    }

    const onAddOrEdit = (data) => {
        taxForm.current.openForm(data)
    }

    const actionTemplate = (rowData) => {
        let dropActions = [
            {
                label: 'Remove',
                icon: 'pi pi-trash',
                command: (e) => deleteTax(rowData.id)
            }
        ];

        return <SplitButton label="Edit" icon="pi pi-pencil" className="p-button-constrast" model={dropActions} onClick={(e) => onAddOrEdit(rowData)}/>
    }

    const deleteTax = (id) => {
        showConfirmNotify({
            accept: () => handleDeleteTax(id)
        })
    }

    const handleDeleteTax = (id) => {
        removeTax(id).then(res => {
            if (!res.errorCode) {
                setLoaded(true)
                showSuccessNotify('Action Submitted')
            } else {
                showErrorNotify('Cannot perform action');
            }
        });
    }

    const tblTaxHeader = (
        <TableHeader
            actionTemplate={<Button className="p-button-success" label="Create tax" icon="pi-md-plus" onClick={() => onAddOrEdit()}/>}
            showLength={false}
            refresh={() => setLoaded(true)}
        />
    )

    return(
        <div className="p-grid">
            <TaxForm ref={taxForm}
                refreshData={() => {loadPageTaxs(); loadTaxes()}}
            />
            <div className="p-col-12 p-lg-5">
                <Fieldset legend="Tax Setting">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label className="p-w-bold p-display-inline-block">Pricing mode: </label>
                            <RadioButton value={PRICING_MODE.normal.value} className="p-margin-left-20" onChange={(e) => setDataConfig({...dataConfig, pricingMode: e.value})} checked={dataConfig.pricingMode===PRICING_MODE.normal.value}/>
                            <label className="p-radiobutton-label">{PRICING_MODE.normal.label}</label>
                            <RadioButton value={PRICING_MODE.included_tax.value} className="p-margin-left-20" onChange={(e) => setDataConfig({...dataConfig, pricingMode: e.value})} checked={dataConfig.pricingMode===PRICING_MODE.included_tax.value} />
                            <label className="p-radiobutton-label">{PRICING_MODE.included_tax.label}</label>

                        </div>
                        <div className="p-col-12 p-md-8">
                            <label className="p-label">Default Tax</label>
                            <Dropdown value={dataConfig.defaultTax} options={taxes} showClear onChange={(e) => setDataConfig({...dataConfig, defaultTax: e.value})} />
                        </div>
                    </div>
                    <div className="p-grid p-mt-3">
                        <div className="p-col-12">
                            <Button label="Save" icon="pi pi-save" onClick={() => handleSaveConfig()} />
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div className="p-col-12 p-lg-7">
                <Fieldset legend="List of Taxes">
                    <DataTable
                        lazy={true} paginator={true} value={dataList}
                        first={pageable.page * pageable.rows}
                        header={tblTaxHeader} onPage={onPage} onSort={onSort}
                        rows={pageable.rows} totalRecords={pageable.total}
                        sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                        responsive={true}
                    >
                        <Column field="id" header="ID" sortable />
                        <Column field="name" header="Name" sortable />
                        <Column field="rate01" header="Rate" body={rowData => rowData.rate01 + "%"} sortable />
                        <Column body={actionTemplate} headerStyle={{width: '10em'}} bodyClassName="tc-actions" />
                    </DataTable>
                </Fieldset>
            </div>
        </div>
    )
}