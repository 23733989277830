import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import classNames from 'classnames';
import { moneyFormat, getTablePageReport, getCurrentUserId } from '../../core/service/CommonService';
import { exportOrders, getPageOrders } from './OrderService';

import { APP_FEP, PAYMENT_STATE, ORDER_STATE, DATETIME_FORMAT_DISPLAY, PRODUCT_TYPE, HR_RESOURCE_TYPE } from '../../constants';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { MultiSelect } from 'primereact/multiselect';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { Dropdown } from 'primereact/dropdown';
import { exportInvoices } from '../invoice/InvoiceServices';
import fileDownload from 'js-file-download';
import { showErrorNotify, showWarnNotify } from '../../core/service/NotificationService';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { getListHRs } from '../../crm/human_resource/HRServices';
import { ROLES_CONFIG } from '../../roles';


const ORDER_STATE_TYPE = {
	cart: { ...ORDER_STATE.cart },
	new: { ...ORDER_STATE.new_order },
	viewed: { ...ORDER_STATE.viewed },
	canceled: { ...ORDER_STATE.canceled },
};

const VIEW_TYPE_LIST = {
	order: { value: 'order', label: 'order' },
};

const defaultFilter = {
	app: APP_FEP,
	paymentStates: [],
	orderStates: [ORDER_STATE_TYPE.new.value, ORDER_STATE_TYPE.viewed.value, ORDER_STATE_TYPE.canceled.value],
	idOrNumber: '',
	invoiceNumber: '',
	buyer: '',
};

const ORDER_TYPE = {
	membership: { value: 'membership', label: 'Membership' },
	booking: { value: 'booking', label: 'Booking' },
	course: { value: 'course', label: 'Course' },
};

export const ORDER_PAYMENT_STATE = {
	pending: { value: 'pending', label: 'Pending' },
	awaiting: { value: 'awaiting', label: 'Partial payment' },
	completed: { value: 'completed', label: 'Completed' },
	cancelled: { value: 'cancelled', label: 'Cancelled' },
};

export const Orders = () => {
	const match = useRouteMatch();
	const history = useHistory();

	const [dataList, setDataList] = useState([]);
	const [filter, setFilter] = useState(defaultFilter);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'checkoutCompletedAt',
		sortOrder: -1,
	});

	const [dateFromValue, setDateFromValue] = useState(null);
	const [minDate, setMinDate] = useState(null);
	const [dateToValue, setDateToValue] = useState(null);
	const [maxDate, setMaxDate] = useState(null);
	const [isLoadTable, setLoadTable] = useState(false);
	const [viewType, setViewType] = useState(VIEW_TYPE_LIST.order.value);
	const [vendorIds, setVendorIds] = useState([]);

	const isLimitByVendor = !isScpAdmin() && hasRole([ROLES_CONFIG.TRAINING_VIEW_BY_VENDOR_R]);

	useEffect(() => {
		async function limitByVendor() {
			if (isLimitByVendor) {
				let userId = getCurrentUserId();
				if (userId) {
					let resVendors = await getListHRs({ userIds: userId ? [userId] : null, type: HR_RESOURCE_TYPE.vendor.value });
					setVendorIds(resVendors && resVendors.length > 0 ? resVendors.map((v) => v.value) : []);
					setLoadTable(true);
				}
			} else {
				setLoadTable(true);
			}
		}
		limitByVendor();
	}, []);

	useEffect(() => {
		if (isLoadTable) loadPageOrders();
	}, [isLoadTable]);

	const loadPageOrders = () => {
		let tmpFilter = { ...filter };
		if (isLimitByVendor) {
			if (!vendorIds || vendorIds.length === 0) {
				setDataList([]);
				setPageable({ ...pageable, total: 0, page: 0, rows: 10 });
				setLoadTable(false);
				showWarnNotify('No vendor');
				return;
			}
			tmpFilter.vendorIds = vendorIds;
		}

		getPageOrders(tmpFilter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				setDataList(res.content);
				setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
			})
			.finally(() => setLoadTable(false));
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const onDateFromChange = (e) => {
		let tmpMaxDate = maxDate ? moment(maxDate) : '';
		let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
		let isCurrentValueAfterMaxDate = currentValue && tmpMaxDate && currentValue.isAfter(tmpMaxDate);

		setFilter({ ...filter, dateFrom: currentValue ? currentValue.format(moment.HTML5_FMT.DATE) : '', dateTo: !isCurrentValueAfterMaxDate ? filter.dateTo : '' });
		setDateFromValue(e.value);
		setMinDate(moment(e.value).isValid() ? e.value : null);
		setDateToValue(!isCurrentValueAfterMaxDate ? dateToValue : '');
		setMaxDate(!isCurrentValueAfterMaxDate ? maxDate : null);
	};

	const onDateToChange = (e) => {
		let tmpMinDate = minDate ? moment(minDate) : '';
		let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
		let isCurrentValueBeforeMinDate = currentValue && tmpMinDate && currentValue.isBefore(tmpMinDate);

		setFilter({ ...filter, dateFrom: !isCurrentValueBeforeMinDate ? filter.dateFrom : '', dateTo: currentValue ? currentValue.format(moment.HTML5_FMT.DATE) : '' });
		setDateFromValue(!isCurrentValueBeforeMinDate ? dateFromValue : '');
		setMinDate(!isCurrentValueBeforeMinDate ? minDate : null);
		setDateToValue(e.value);
		setMaxDate(moment(e.value).isValid() ? e.value : null);
	};

	const onFilterKeyPress = (e) => {
		if (e.key === 'Enter') {
			setLoadTable(true);
		}
	};

	const applyFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setLoadTable(true);
	};

	const resetFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setFilter(defaultFilter);
		setLoadTable(true);
	};

	const onOrderTypeChange = (value) => {
		let tmpProductTypes = [];
		let isCourseProgram = null;
		if (value) {
			switch (value) {
				case ORDER_TYPE.membership.value:
					tmpProductTypes = [PRODUCT_TYPE.membership.value];
					break;
				case ORDER_TYPE.booking.value:
					tmpProductTypes = [PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value];
					break;
				case ORDER_TYPE.course.value:
					tmpProductTypes = [PRODUCT_TYPE.program.value];
					isCourseProgram = true;
					break;
				default:
					break;
			}
		}
		setFilter({ ...filter, productTypes: tmpProductTypes, isCourse: isCourseProgram, orderTypeSelected: value });
	};

	const handleExport = () => {
		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = `rpt__${viewType}_${suf}.xlsx`;

		exportOrders(filter).then((res) => {
			if (!res.errorCode) {
				fileDownload(res, fileName);
			} else {
				showErrorNotify(res.errorMessage);
			}
		});

		console.log('handle refresh');
	};

	const tblOrderHeader = (
		<TableHeader
			title='Orders'
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
			actionTemplate={<Button label='Export' icon='pi pi-download' disabled={dataList.length === 0} onClick={() => handleExport()} />}
		/>
	);

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>Orders</BreadcrumbsItem>

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1 className='p-w-bold'>Orders</h1>

					<Fieldset legend='Filters'>
						<div className='p-grid p-fluid form-group'>
							<div className='p-col-12 p-md-6'>
								<div className='p-grid'>
									<div className='p-col-12'>
										<div className='p-grid'>
											<div className='p-col-12 p-md-6'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>ID/Code:</span>
													<InputText value={filter.idOrNumber} onKeyUp={onFilterKeyPress} onChange={(e) => setFilter({ ...filter, idOrNumber: e.target.value })} />
												</div>
											</div>
											<div className='p-col-12 p-md-6'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Invoice/Receipt Number:</span>
													<InputText
														keyfilter={/^[0-9]*$/}
														value={filter.invoiceNumber}
														onKeyUp={onFilterKeyPress}
														onChange={(e) => setFilter({ ...filter, invoiceNumber: e.target.value ? parseInt(e.target.value) : '' })}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Buyer:</span>
											<InputText value={filter.buyerName} onKeyUp={onFilterKeyPress} onChange={(e) => setFilter({ ...filter, buyer: e.target.value })} placeholder='Name/email/phone' />
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Type:</span>
											<Dropdown value={filter.orderTypeSelected} options={Object.values(ORDER_TYPE)} showClear onChange={(e) => onOrderTypeChange(e.value)} />
										</div>
									</div>
								</div>
							</div>
							<div className='p-col-12 p-md-6'>
								<div className='p-grid'>
									<div className='p-col-12'>
										<div className='p-grid'>
											<div className='p-col-12 p-md-6'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Date From:</span>
													<MaskedCalendar value={dateFromValue} maxDate={maxDate} onChange={(e) => onDateFromChange(e)} showIcon={true} dateFormat='dd/mm/yy' />
												</div>
											</div>
											<div className='p-col-12 p-md-6'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Date To:</span>
													<MaskedCalendar value={dateToValue} minDate={minDate} onChange={(e) => onDateToChange(e)} showIcon={true} dateFormat='dd/mm/yy' />
												</div>
											</div>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-grid'>
											<div className='p-col-12 p-md-6'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Order State:</span>
													<MultiSelect
														value={filter.orderStates}
														options={Object.values(ORDER_STATE_TYPE)}
														onChange={(e) => setFilter({ ...filter, orderStates: e.value })}
														style={{ minWidth: '12em' }}
														filter={true}
														placeholder='All'
													/>
												</div>
											</div>
											<div className='p-col-12 p-md-6'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Payment State:</span>
													<MultiSelect
														value={filter.paymentStates}
														options={Object.values(ORDER_PAYMENT_STATE)}
														onChange={(e) => setFilter({ ...filter, paymentStates: e.value })}
														style={{ minWidth: '12em' }}
														filter={true}
														placeholder='All'
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='p-grid'>
							<div className='p-col-12' style={{ textAlign: 'center' }}>
								<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={() => applyFilter()} />
								<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' onClick={() => resetFilter()} />
							</div>
						</div>
					</Fieldset>
				</div>

				<DataTable
					emptyMessage="There's no order"
					header={tblOrderHeader}
					value={dataList}
					lazy={true}
					loading={isLoadTable}
					paginator={true}
					first={pageable.page * pageable.rows}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					onPage={onPage}
					onSort={onSort}
					responsive={true}
				>
					<Column
						header='ID-Code'
						field='id'
						bodyClassName='p-c'
						style={{ width: '10em' }}
						sortable={true}
						body={(rowData) => {
							if (isScpAdmin() || hasRole([ROLES_CONFIG.REPORT_ORDER_R])) {
								return (
									<Button
										label={rowData.id + ' - ' + rowData.orderNumber}
										icon='pi pi-search'
										className='p-button-info'
										tooltip='View order'
										tooltipOptions={{ position: 'top' }}
										onClick={() => history.push(`/orders/${rowData.id}`)}
									/>
								);
							}
							return rowData.id + ' - ' + rowData.orderNumber;
						}}
					/>
					<Column
						header='Buyer'
						field='billEmail'
						sortable={true}
						bodyClassName='p-nowrap'
						style={{ width: '18em' }}
						body={(rowData) => (
							<React.Fragment>
								<div>{rowData.billName + ' - [' + rowData.billEmail + ']'}</div>
								{rowData.billPhone && <div>{rowData.billPhone}</div>}
							</React.Fragment>
						)}
					/>
					{/* <Column header="Buyer" field="billEmail" sortable={true} bodyClassName="p-nowrap" style={{width: '18em'}}
                        body={(rowData) => {
                            return (
                                <Tippy
                                    content={<ContactDetailsCard data={rowData}/>}
                                    animation="fade"
                                    placement="right"
                                    trigger="mouseenter focus"
                                    interactive={true}
                                    arrow={true}
                                    theme="light"
                                    maxWidth= 'none'
                                    duration={[350, 200]}>
                                    <button className="Tippy-Button">
                                    {rowData.customerName + ' - [' + rowData.customerEmail + ']'}
                                    </button>
                                </Tippy>
                            );
                            }} /> */}
					<Column header='Total' field='total' body={(rowData) => moneyFormat(rowData.total, rowData.currency)} bodyClassName='p-r p-w-bold' sortable={true} style={{ width: '7em' }} />
					{/* <Column
						header='Security  Deposit'
						field='pledgedDepositTotal'
						body={(rowData) => (rowData.pledgedDepositTotal > 0 ? moneyFormat(rowData.pledgedDepositTotal, rowData.currency) : '')}
						bodyClassName='p-r p-w-bold'
						sortable={true}
						style={{ width: '8em' }}
					/> */}
					<Column header='Paid' field='paidTotal' body={(rowData) => moneyFormat(rowData.paidTotal, rowData.currency)} bodyClassName='p-r p-w-bold' sortable={true} style={{ width: '7em' }} />
					<Column
						header='Payment State'
						field='paymentState'
						body={(rowData) => <span className={classNames('status', rowData.paymentState)}>{ORDER_PAYMENT_STATE[rowData.paymentState].label}</span>}
						bodyClassName='p-c'
						sortable={true}
						style={{ width: '8em' }}
					/>
					<Column
						header='Order State'
						field='orderState'
						body={(rowData) => <span className={classNames('status', rowData.orderState)}>{ORDER_STATE[rowData.orderState].label}</span>}
						bodyClassName='p-c'
						sortable={true}
						style={{ textAlign: 'center', width: '6em' }}
					/>
					<Column
						header='Created At'
						field='checkoutCompletedAt'
						headerStyle={{ width: '10em' }}
						bodyClassName='p-nowrap p-c'
						sortable={true}
						body={(rowData) => rowData.checkoutCompletedAt && moment(rowData.checkoutCompletedAt).format(DATETIME_FORMAT_DISPLAY)}
					/>
				</DataTable>
			</div>
		</div>
	);
};
