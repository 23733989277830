import React, { forwardRef, useEffect, useImperativeHandle, useState} from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { showErrorNotify, showSuccessNotify } from "../../core/service/NotificationService";
import { getListTaxons } from "../taxon/TaxonServices";
import { APP_FEP, TAXONOMY_TYPE } from "../../constants";
import { getListInventories } from "../inventory/InventoryService";
import { transferAsset } from "./AssetServices";
import { Dropdown } from "primereact/dropdown";

export const TransferAssetForm = forwardRef((props, ref) => {

    const [branches, setBranchs] = useState([]);
    const [inventories, setInventories] = useState([]);
    const [formHeader, setFormHeader] = useState('Transfer Asset')
    const [assetIds, setAssetIds] = useState([])
    const [transferType, setTransferType] = useState(null)
    const [branchId, setBranchId] = useState(null)
    const [invertoryId, setInvertoryId] = useState(null)
    const [visible, setVisible] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(()=>{
        if(branchId){
            loadInventories(branchId)
        }else{
            setInventories([])
        }
        setInvertoryId(null)
    },[branchId])

    useImperativeHandle(ref, ()=>({
        openForm(dataList){
            loadBranches()
            setAssetIds(dataList)
            setBranchId(null)
            setInvertoryId(null)
            setTransferType(null)
            setVisible(true)
            setErrors({})
        }   
    }))

    const handleTransfer = () => {
        transferAsset(transferType, branchId, invertoryId, assetIds)
        .then(res => {
            if(!res.errorCode){
                if(props.reloadTable){
                    props.reloadTable()
                }
                onHide()
                showSuccessNotify('Action submitted');
            }else{
                if (res.errorCode === 400)  setErrors(res.errorObj);
                showErrorNotify('Cannot perform action');
            }
        })
    }

    const loadBranches = () => {
        getListTaxons({type: TAXONOMY_TYPE.branch.value}, true).then(res => setBranchs(res));
    }

    const loadInventories = (branchId) => {
        getListInventories({app: APP_FEP, branchId: branchId}, true)
        .then(res => setInventories(res))
    }

    const TRANSFER_PURPOSE_TYPE = {
        origin: {label: 'Original Inventory', value: 'origin'},
        current: {label: 'Current Inventory', value: 'current'},
    }

    const onHide = () => {
        setVisible(false)
    }

    return (
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} dismissable={false} onHide={()=>onHide()}>
            <h2>{formHeader}</h2>
            
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <label>Purpose type</label>
                    <Dropdown value={transferType} options={Object.values(TRANSFER_PURPOSE_TYPE)} onChange={(e) => setTransferType(e.value)} />
                    <div className="p-form-error">{errors.type}</div>
                </div>
                <div className="p-col-12">
                    <label>Branch</label>
                    <Dropdown value={branchId} options={branches} onChange={(e) => setBranchId(e.value)} />
                    <div className="p-form-error">{errors.branchId}</div>
                </div>
                <div className="p-col-12">
                    <label>Inventory</label>
                    <Dropdown value={invertoryId} options={inventories} onChange={(e) => setInvertoryId(e.value)} />
                    <div className="p-form-error">{errors.inventoryId}</div>
                </div>
            </div>
            <div className="p-grid">
                <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                    <Button label="Save" icon="pi pi-check" onClick={()=> handleTransfer()}/>
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={()=> onHide()}/>
                </div>
            </div>
        </Sidebar>
    )
}) 

