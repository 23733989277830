import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import React, { useEffect, useRef, useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch } from 'react-router-dom';
import {
	APP_FEP,
	CLUBHOUSE_ACTIVITY_CODE,
	HR_RESOURCE_TYPE,
	OBJECT_TYPE,
	PRODUCT_CLASSIFICATION,
	PRODUCT_TYPE,
	SOCIAL_SUPPORT_ACTIVITY_CODE,
	TAXONOMY_TYPE,
	TRAINING_COURSE_ACTIVITY_CODE,
	TRAINING_COURSE_DIPLOMA_CODE,
} from '../../constants';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getCurrentUserId, getTablePageReport } from '../../core/service/CommonService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showErrorNotify, showSuccessNotify, showConfirmNotify, showWarnNotify } from '../../core/service/NotificationService';
import { getDeparmentByCode, getListDepartments } from '../../scp/departments/DepartmentServices';
import { getListTaxons } from '../taxon/TaxonServices';
import { ProductForm } from './ProductForm';
import { exportProduct, getPageProducts, removeProduct } from './ProductServices';
import classNames from 'classnames';
import { getListHRs } from '../../crm/human_resource/HRServices';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { updateTVPanel } from '../panel/PanelService';
import { ROLES_CONFIG } from '../../roles';
import moment from 'moment';
import { getListAssets } from '../asset/AssetServices';
import { MultiSelect } from 'primereact/multiselect';

const fileDownload = require('js-file-download');


const PRODUCT_TYPES = [
	PRODUCT_TYPE.program,
	PRODUCT_TYPE.rental,
	//PRODUCT_TYPE.membership,
	//PRODUCT_TYPE.session,
	//PRODUCT_TYPE.ticket,
	//PRODUCT_TYPE.addon,
	//PRODUCT_TYPE.donation
];

export const ProductListView = (props) => {
	const isCourse = props.extra1;
	const departmentCode = props.extra2;
	const productClassification = isCourse && props.extra2 === TRAINING_COURSE_DIPLOMA_CODE ? PRODUCT_CLASSIFICATION.combo.value : PRODUCT_CLASSIFICATION.simple.value;

	const match = useRouteMatch();
	const frmProduct = useRef(null);
	const [filter, setFilter] = useState({ app: APP_FEP });
	const [data, setData] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: '',
		sortOrder: -1,
	});
	const [isLoadTable, setLoadTable] = useState(false);
	const [branches, setBranches] = useState([]);
	const [departments, setDeparments] = useState([]);
	const [categories, setCategories] = useState([]);
	const [locations, setLocations] = useState([]);
	const [departmentId, setDepartmentId] = useState(null);
	const [vendorIds, setVendorIds] = useState([]);
	const [vendors, setVendors] = useState([]);
	const [assets, setAssets] = useState([]);

	const isLimitByVendor = !isScpAdmin() && hasRole([ROLES_CONFIG.TRAINING_VIEW_BY_VENDOR_R]);

	useEffect(() => {
		loadAllBranchs();
		loadAllLocations();
		loadAssets();
		if (isCourse) {
			loadVendors();
		}
		if (departmentCode) {
			async function loadDeparmentByCode() {
				const resDepartment = await getDeparmentByCode(departmentCode === TRAINING_COURSE_DIPLOMA_CODE ? TRAINING_COURSE_ACTIVITY_CODE : departmentCode);
				if (resDepartment?.errorCode) {
					showErrorNotify(resDepartment.errorMessage);
					return;
				}

				if (isLimitByVendor) {
					let userId = getCurrentUserId();
					if (userId) {
						let resVendors = await getListHRs({ userIds: [userId], type: HR_RESOURCE_TYPE.vendor.value });
						setVendorIds(resVendors ? resVendors.map((hr) => hr.value) : []);
					}
				}
				setDepartmentId(resDepartment.id);
				setFilter({ ...filter, departmentIds: [resDepartment.id], classifications: productClassification ? [productClassification] : null });
				setLoadTable(true);
			}
			loadDeparmentByCode();
		} else {
			loadAllDepartments();
			setLoadTable(true);
		}
	}, [props.extra2]);

	useEffect(() => {
		if (isLoadTable) loadPageProducts();
	}, [isLoadTable]);

	useEffect(() => {
		setFilter({ ...filter, categoryIds: [] });
		loadAllCategories(filter.departmentIds ? filter.departmentIds[0] : null);
	}, [filter.departmentIds]);

	useEffect(() => {
		if (isLimitByVendor) {
			loadVendors();
		}
	}, [vendorIds]);

	useEffect(() => {
		if (productClassification === PRODUCT_CLASSIFICATION.simple.value) {
			loadAssets();
			setFilter({ ...filter, assetIds: [] });
		}
	}, [filter.branchIds]);

	const loadPageProducts = async () => {
		let tmpFilter = { ...filter };
		if (isCourse) {
			if (isLimitByVendor) {
				if (!vendorIds || vendorIds.length === 0) {
					setData(0);
					setPageable({ ...pageable, total: 0, page: 0, rows: 10 });
					setLoadTable(false);
					showWarnNotify('No vendor');
					return;
				}
				tmpFilter.hrIds = filter.hrIds?.length > 0 ? [...filter.hrIds] : vendorIds;
			}
			tmpFilter.types = productClassification !== PRODUCT_CLASSIFICATION.combo.value ? [PRODUCT_TYPE.program.value] : null;
			tmpFilter.isCourse = true;
		} else {
			if (!tmpFilter.types || tmpFilter.types.length === 0) {
				tmpFilter.types = [PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value];
			}
			tmpFilter.isCourse = false;
		}
		showloading();

		getPageProducts(tmpFilter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				if (res) {
					setData(res.content);
					setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
				}
			})
			.finally(() => {
				setLoadTable(false);
				stoploading();
			});
	};

	const onPage = (e) => {
		setPageable({
			...pageable,
			page: e.page,
		});
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const loadAllBranchs = () => {
		getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.branch.value }).then((res) => setBranches(res));
	};

	const loadAllDepartments = () => {
		getListDepartments({ externalRef: false }).then((res) => setDeparments(res));
	};

	const loadAllCategories = (deptId) => {
		if (!deptId) {
			setCategories([]);
			return;
		}

		getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.product.value], departmentIds: [deptId] }).then((res) => setCategories(res));
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const loadAllLocations = () => {
		getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.location.value }).then((res) => setLocations(res));
	};

	const loadVendors = () => {
		getListHRs({ type: HR_RESOURCE_TYPE.vendor.value, includeIds: vendorIds }).then((res) => setVendors(res));
	};

	const loadAssets = () => {
		getListAssets({ branchIds: filter.branchIds }).then((res) => setAssets(res));
	};

	const handleUpdatePanel = () => {
		showloading();
		try {
			updateTVPanel()
				.then((res) => {
					stoploading();
					if (!res.errorCode) {
						showSuccessNotify('Tv Panel Updated');
					} else {
						showErrorNotify(res.errorCode);
					}
				})
				.catch((error) => {
					showErrorNotify('Update Fail');
					console.log(error);
					stoploading();
				});
		} catch (error) {
			showErrorNotify('Update Fail');
			stoploading();
		}
	};

	const deleteProduct = (id) => {
		showConfirmNotify({
			message: 'Are you sure to remove this product? This action can not be undo.',
			accept: () => handleRemoveProduct(id),
		});
	};

	const handleRemoveProduct = (id) => {
		removeProduct(id).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorCode !== 409 ? res.errorMessage : "Can't remove because it's used in another product!");
			}
		});
	};

	const handleExport = () => {
		if (!data || data.length === 0) {
			showWarnNotify('No data!');
			return;
		}

		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = 'rpt_training_course_' + suf + '.xlsx';

		let tmpFilter = { ...filter };
		if (isCourse) {
			if (isLimitByVendor) {
				if (!vendorIds || vendorIds.length === 0) {
					showWarnNotify('No data');
					return;
				}
				tmpFilter.hrIds = vendorIds;
			}
			tmpFilter.types = productClassification !== PRODUCT_CLASSIFICATION.combo.value ? [PRODUCT_TYPE.program.value] : null;
			tmpFilter.isCourse = true;
		} else {
			if (!tmpFilter.types || tmpFilter.types.length === 0) {
				tmpFilter.types = [PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value];
			}
			tmpFilter.isCourse = false;
		}

		showloading();
		try {
			exportProduct(tmpFilter)
				.then((res) => {
					if (!res.errorCode) {
						fileDownload(res, fileName);
					} else {
						showErrorNotify(res.errorMessage);
					}
				})
				.finally(() => stoploading());
		} catch (error) {
			showErrorNotify('Error system!');
			stoploading();
		}
	};

	const applyFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setLoadTable(true);
	};

	const resetFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setFilter({ app: APP_FEP, searchValue: '', departmentIds: departmentId ? [departmentId] : [], classifications: productClassification ? [productClassification] : null });
		setLoadTable(true);
	};

	// const createProductItems = [
	//     { label: 'Create Variable Activity', icon: '', command: (e) => frmProduct.current.openForm(PRODUCT_CLASSIFICATION.variable.value)},
	//     { label: 'Create Combo Activity', icon: '', command: (e) => frmProduct.current.openForm(PRODUCT_CLASSIFICATION.combo.value)},
	// ]

	const renderActivityName = () => {
		if (departmentCode) {
			switch (departmentCode) {
				case SOCIAL_SUPPORT_ACTIVITY_CODE:
					return 'Social Support Activities';
				case CLUBHOUSE_ACTIVITY_CODE:
					return 'Clubhouse Activities';
				case TRAINING_COURSE_ACTIVITY_CODE:
					return 'Training Courses';
				case TRAINING_COURSE_DIPLOMA_CODE:
					return 'Programme';
				default:
					break;
			}
		}
		return isCourse ? 'Training Courses' : 'Activities';
	};

	const onUpdatePanel = () => {
		showConfirmNotify({
			accept: () => handleUpdatePanel(),
			message: 'do you want to update panel?',
		});
	};

	const onAddOrEdit = (classification, id) => {
		if (isCourse) {
			if (isLimitByVendor) {
				if (!vendorIds || vendorIds.length === 0) {
					showWarnNotify('No vendor!');
					return;
				}
			}
		}
		frmProduct.current.openForm(classification, id);
	};

	const tblHeader = () => {
		let tmpWrite = isWrite();
		return (
			<TableHeader
				title={renderActivityName()}
				actionTemplate={
					<React.Fragment>
						{(() => {
							if (isCourse) {
								if (departmentCode && departmentCode === TRAINING_COURSE_DIPLOMA_CODE) {
									return tmpWrite && <Button label='Create Programme' icon='pi pi-plus' className='p-button-constrast' onClick={() => onAddOrEdit(productClassification)} />;
								} else {
									return (
										<React.Fragment>
											{tmpWrite && <Button label='Create Training Course' icon='pi pi-plus' className='p-button-constrast p-mb-1' onClick={() => onAddOrEdit(productClassification)} />}
											{(isScpAdmin() || hasRole([ROLES_CONFIG.TRAINING_COURSE_EXPORT_R])) && (
												<Button label='Export' icon='pi pi-download' className='p-button-constrast p-mb-1' disabled={!data || data.length === 0} onClick={() => handleExport()} />
											)}
										</React.Fragment>
									);
								}
							}
							return tmpWrite && <Button label={'Create Activity'} icon='pi pi-plus' className='p-button-constrast' onClick={() => onAddOrEdit(productClassification)} />;
						})()}
						{/* <Button
							label={'Create ' + (isCourse ? (departmentCode && departmentCode === TRAINING_COURSE_DIPLOMA_CODE ? 'Programme' : 'Training Course') : 'Activity')}
							icon='pi pi-plus'
							className='p-button-constrast'
							onClick={() => onAddOrEdit(productClassification)}
						/> */}
						{(departmentCode === SOCIAL_SUPPORT_ACTIVITY_CODE || departmentCode === CLUBHOUSE_ACTIVITY_CODE) && (
							<Button label={'Update Tv Panel'} icon='pi pi-refresh' className='p-button-constrast' onClick={() => onUpdatePanel()} />
						)}
					</React.Fragment>
				}
				pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
				changePageLength={(e) => onChangePageLength(e)}
				refresh={() => setLoadTable(true)}
			/>
		);
	};

	const isWrite = () => {
		let flag = isScpAdmin();
		if (departmentCode) {
			switch (departmentCode) {
				case SOCIAL_SUPPORT_ACTIVITY_CODE:
					flag = isScpAdmin() || hasRole([ROLES_CONFIG.ACTIVITY_SOCIAL_SUPPORT_MGT_W]);
					break;
				case CLUBHOUSE_ACTIVITY_CODE:
					flag = isScpAdmin() || hasRole([ROLES_CONFIG.ACTIVITY_CLUBHOUSE_MGT_W]);
					break;
				case TRAINING_COURSE_ACTIVITY_CODE:
					flag = isScpAdmin() || hasRole([ROLES_CONFIG.TRAINING_COURSE_MGT_W]);
					break;
				case TRAINING_COURSE_DIPLOMA_CODE:
					flag = isScpAdmin() || hasRole([ROLES_CONFIG.TRAINING_DIPLOMA_MGT_W]);
					break;
				default:
					break;
			}
		}
		return flag;
	};

	const isDelete = () => {
		let flag = isScpAdmin();
		if (departmentCode) {
			switch (departmentCode) {
				case SOCIAL_SUPPORT_ACTIVITY_CODE:
					flag = isScpAdmin() || hasRole([ROLES_CONFIG.ACTIVITY_SOCIAL_SUPPORT_MGT_D]);
					break;
				case CLUBHOUSE_ACTIVITY_CODE:
					flag = isScpAdmin() || hasRole([ROLES_CONFIG.ACTIVITY_CLUBHOUSE_MGT_D]);
					break;
				case TRAINING_COURSE_ACTIVITY_CODE:
					flag = isScpAdmin() || hasRole([ROLES_CONFIG.TRAINING_COURSE_MGT_D]);
					break;
				case TRAINING_COURSE_DIPLOMA_CODE:
					flag = isScpAdmin() || hasRole([ROLES_CONFIG.TRAINING_DIPLOMA_MGT_D]);
					break;
				default:
					break;
			}
		}
		return flag;
	};

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>{renderActivityName()}</BreadcrumbsItem>

			<ProductForm ref={frmProduct} isCourse={isCourse} departmentId={departmentId} vendorIds={vendorIds} refreshTable={() => setLoadTable(true)} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1 className='p-w-bold'>{renderActivityName()}</h1>
					<Fieldset legend='Filters'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12 p-md-6'>
								<div className='p-grid'>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Name/Code:</span>
											<InputText value={filter.searchValue} onChange={(e) => setFilter({ ...filter, searchValue: e.target.value })} />
										</div>
									</div>
									{!isCourse && (
										<div className='p-col-12'>
											<div className='p-inputgroup'>
												<span className='p-inputgroup-addon'>Type:</span>
												<Dropdown
													value={filter.types && filter.types.length > 0 ? filter.types[0] : null}
													options={PRODUCT_TYPES}
													showClear
													onChange={(e) => setFilter({ ...filter, types: e.value ? [e.value] : [] })}
												/>
											</div>
										</div>
									)}
									{(isScpAdmin() || hasRole([ROLES_CONFIG.TRAINING_COURSE_EXPORT_R])) && isCourse && (
										<div className='p-col-12'>
											<div className='p-inputgroup'>
												<span className='p-inputgroup-addon'>Vendor:</span>
												<Dropdown
													value={filter.hrIds && filter.hrIds.length > 0 ? filter.hrIds[0] : null}
													options={vendors}
													showClear
													filter
													onChange={(e) => setFilter({ ...filter, hrIds: e.value ? [e.value] : [] })}
												/>
											</div>
										</div>
									)}
									{branches && branches.length > 1 && (
										<div className='p-col-12'>
											<div className='p-inputgroup'>
												<span className='p-inputgroup-addon'>Branch:</span>
												<Dropdown
													value={filter.branchIds && filter.branchIds.length > 0 ? filter.branchIds[0] : null}
													options={branches}
													showClear
													onChange={(e) => setFilter({ ...filter, branchIds: e.value ? [e.value] : [] })}
												/>
											</div>
										</div>
									)}
								</div>
							</div>
							<div className='p-col-12 p-md-6'>
								<div className='p-grid'>
									{/* <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Classification:</span>
                                            <Dropdown value={filter.classifications&&filter.classifications.length>0?filter.classifications[0]:null} options={Object.values(PRODUCT_CLASSIFICATION)} showClear onChange={e => setFilter({...filter, classifications: e.value?[e.value]:[]})} />
                                        </div>
                                    </div> */}
									{!departmentCode && (
										<div className='p-col-12'>
											<div className='p-inputgroup'>
												<span className='p-inputgroup-addon'>Activity Type:</span>
												<Dropdown
													value={filter.departmentIds && filter.departmentIds.length > 0 ? filter.departmentIds[0] : null}
													options={departments}
													showClear
													onChange={(e) => setFilter({ ...filter, departmentIds: e.value ? [e.value] : [] })}
												/>
											</div>
										</div>
									)}
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Category:</span>
											<Dropdown
												value={filter.categoryIds && filter.categoryIds.length > 0 ? filter.categoryIds[0] : null}
												options={categories}
												showClear
												onChange={(e) => setFilter({ ...filter, categoryIds: e.value ? [e.value] : [] })}
											/>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Venue:</span>
											<Dropdown
												value={filter.locationIds && filter.locationIds.length > 0 ? filter.locationIds[0] : null}
												options={locations}
												showClear
												onChange={(e) => setFilter({ ...filter, locationIds: e.value ? [e.value] : [] })}
											/>
										</div>
									</div>
									{productClassification === PRODUCT_CLASSIFICATION.simple.value && (
										<div className='p-col-12'>
											<div className='p-inputgroup'>
												<span className='p-inputgroup-addon'>Asset:</span>
												<MultiSelect value={filter.assetIds} options={assets ?? []} filter onChange={(e) => setFilter({ ...filter, assetIds: e.value })} />
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className='p-grid'>
							<div className='p-col-12 p-c'>
								<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={() => applyFilter()} />
								<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' onClick={() => resetFilter()} />
							</div>
						</div>
					</Fieldset>
				</div>

				<DataTable
					emptyMessage="There's no record found"
					lazy={true}
					paginator={true}
					value={data}
					loading={isLoadTable}
					first={pageable.page * pageable.rows}
					header={tblHeader()}
					onPage={onPage}
					onSort={onSort}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					responsive={true}
				>
					<Column header='Code' field='code' sortable={true} />
					<Column header='Name' field='name' sortable={true} />
					{/* <Column header="Class" field="classification" sortable={true} body={(rowData) => PRODUCT_CLASSIFICATION[rowData.classification] && PRODUCT_CLASSIFICATION[rowData.classification].label}/> */}
					{!isCourse && <Column header='Type' field='type' sortable={true} body={(rowData) => rowData.type && PRODUCT_TYPE[rowData.type] && PRODUCT_TYPE[rowData.type].label} />}
					<Column header='Weight' field='weight' sortable headerStyle={{ width: '10em' }} />
					<Column
						header='Show In Store'
						field='show'
						style={{ width: '10em' }}
						bodyClassName='p-c'
						body={(rowData) => <span className={classNames('status', { active: rowData.show, inactive: !rowData.show })}>{rowData.show ? 'Yes' : 'No'}</span>}
					/>
					{/* <Column header="Active" field="active" body={(rowData) => rowData.active ? 'Yes' : 'No'}/> */}
					{(isWrite() || isDelete()) && (
						<Column
							header=''
							className='tc-actions'
							style={{ width: '12em' }}
							body={(rowData) => {
								let tmpWrite = isWrite();
								let tmpDelete = isDelete();
								if (tmpWrite && tmpDelete) {
									const actionItems = [{ label: 'Remove', icon: 'pi pi-times', command: (e) => deleteProduct(rowData.id) }];
									return (
										<SplitButton label='Edit' model={actionItems} icon='pi pi-pencil' className='p-button-constrast p-l' onClick={() => onAddOrEdit(rowData.classification, rowData.id)}></SplitButton>
									);
								}
								if (tmpWrite) {
									return <Button label='Edit' icon='pi pi-pencil' className='p-button-constrast' onClick={() => onAddOrEdit(rowData.classification, rowData.id)} />;
								}
								if (tmpDelete) {
									return <Button label='Remove' icon='pi pi-times' className='p-button-danger' onClick={() => deleteProduct(rowData.id)} />;
								}
							}}
						/>
					)}
				</DataTable>
			</div>
		</div>
	);
};
