import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import moment, { HTML5_FMT } from 'moment';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { titleCaseText, getTimeZone } from '../../core/service/CommonService';
import { Dropdown } from 'primereact/dropdown';
import { ASSET_SCHEDULE_STATUS, SCHEDULE_USE_FOR_TYPE, APP_FEP } from '../../constants';
import { deleteSchedule, saveSchedule, closeSchedule, pickupSchedule } from './SchedulesService';
import { getListAssets } from '../asset/AssetServices';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { Divider } from '../../core/components/divider/Divider';
import { Checkbox } from 'primereact/checkbox';
import { getListProducts, getProductAvailabilities } from '../product/ProductServices';
import { Message } from 'primereact/message';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';

export const AssetScheduleForm = forwardRef((props, ref) => {
	const history = useHistory();

	const timezone = getTimeZone();

	const [header, setHeader] = useState('Add Schedule');
	const [visible, setVisible] = useState(false);
	const [branchId, setBranchId] = useState(null);
	const [schedule, setSchedule] = useState({});
	const [frmData, setFrmData] = useState({});
	const [selectedAsset, setSelectedAsset] = useState({});
	const [isAllowSelectAsset, setAllowSelectAsset] = useState(false);
	const [assets, setAssets] = useState([]);
	const [products, setProducts] = useState([]);
	const [productAvailabilities, setProductAvailabilities] = useState([]);
	const [errors, setErrors] = useState({});

	const useForOptions = Object.values(SCHEDULE_USE_FOR_TYPE).concat([{ value: null, label: 'Other' }]);

	const isWrite = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_DETAIL_W]);
	const isDelete = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_DETAIL_D]);

	useImperativeHandle(ref, () => ({
		openForm(e, branchId, asset, inventoryId) {
			setHeader((e ? 'Edit' : 'Add') + ' Schedule');
			setVisible(true);
			setBranchId(branchId);
			setFrmData(popularFormData(e, asset));

			if (e) setSchedule(e);

			loadAllAssets(branchId, inventoryId);

			if (e || inventoryId) {
				setAllowSelectAsset(true);
			} else {
				setAllowSelectAsset(false);
				setSelectedAsset(asset);
			}
		},
		closeForm() {
			closeForm();
		},
	}));

	const popularFormData = (e, asset) => {
		return {
			id: e ? e.id : '',
			assetId: e ? e.assetId : asset ? asset.id : null,

			title: e ? e.title : '',

			useForType: e ? e.useForType : null,
			productId: e ? e.productId : null,
			availabilityId: e ? e.availabilityId : null,

			dateRange: e && moment(e.start).format(HTML5_FMT.DATE) !== moment(e.end).format(HTML5_FMT.DATE) ? true : false,
			startDate: e ? moment(e.start).format(HTML5_FMT.DATE) : null,
			startDateValue: e ? moment(e.start, HTML5_FMT.DATE).toDate() : null,
			endDate: e ? moment(e.end).format(HTML5_FMT.DATE) : null,
			endDateValue: e ? moment(e.end, HTML5_FMT.DATE).toDate() : null,
			startTime: e ? moment(e.start).format(HTML5_FMT.TIME) : null,
			startTimeValue: e ? moment(e.start).toDate() : null,
			endTime: e ? moment(e.end).format(HTML5_FMT.TIME) : null,
			endTimeValue: e ? moment(e.end).toDate() : null,

			recurring: e ? e.recurring : false,
			recurringInfo: e ? e.recurringInfo : {},

			status: e ? e.status : ASSET_SCHEDULE_STATUS.in_scheduled.value,
		};
	};

	const loadAllAssets = (branchId, inventoryId) => {
		getListAssets({ branchIds: branchId ? [branchId] : [], inventoryId: inventoryId ? inventoryId : null }, true).then((res) => {
			setAssets(res);
		});
	};

	useEffect(() => {
		if ([SCHEDULE_USE_FOR_TYPE.program.value, SCHEDULE_USE_FOR_TYPE.rental.value].includes(frmData.useForType)) {
			const filter = { app: APP_FEP, branchIds: [branchId], types: [frmData.useForType] };
			loadAllProducts(filter);
		} else {
			setProducts([]);
		}
	}, [frmData.useForType]);

	const loadAllProducts = (filter) => {
		getListProducts(filter, true).then((res) => {
			setProducts(res);

			if (res && res.findIndex((p) => p.value === frmData.productId) === -1) setFrmData({ ...frmData, productId: null });
		});
	};

	useEffect(() => {
		if ([SCHEDULE_USE_FOR_TYPE.program.value].includes(frmData.useForType) && frmData.productId) {
			const filter = { availableByCurrent: frmData.id ? false : true };
			loadAllProductAvailabilities(frmData.productId, filter);
		} else {
			setProductAvailabilities([]);
		}
	}, [frmData.productId]);

	const loadAllProductAvailabilities = (productId, filter) => {
		getProductAvailabilities(productId, filter, false).then((res) => {
			let availabilities = [];
			if (res && res.length > 0) {
				res.forEach((e) => {
					let label = '';
					if (e.startDate === e.endDate) {
						label = moment(e.startDate).format('MM/DD/YYYY') + ' ' + moment(e.startTime, HTML5_FMT.TIME).format('hh:mm A') + ' - ' + moment(e.endTime, HTML5_FMT.TIME).format('hh:mm A');
					} else {
						label =
							moment(e.startDate).format('MM/DD/YYYY') +
							' ' +
							moment(e.startTime, HTML5_FMT.TIME).format('hh:mm A') +
							' - ' +
							moment(e.endDate).format('MM/DD/YYYY') +
							' ' +
							moment(e.endTime, HTML5_FMT.TIME).format('hh:mm A');
					}

					availabilities.push({ value: e.id, label: label });
				});
			}
			setProductAvailabilities(availabilities);

			if (availabilities.findIndex((a) => a.value === frmData.availabilityId) === -1) setFrmData({ ...frmData, availabilityId: null });
		});
	};

	const action = (assetId, data, inventoryId) => {
		console.log(data);
		this.setState({
			assetId: assetId ? assetId : data && data.assetId ? data.assetId : '',
			inventoryId: inventoryId ? inventoryId : '',
			schedule: {
				id: data ? data.id : '',
				title: data ? data.title : '',
				startDate: data ? moment(data.start).format('YYYY-MM-DD') : '',
				startValue: data ? moment(data.start, 'YYYY-MM-DD').toDate() : '',

				endDate: data ? moment(data.end).format('YYYY-MM-DD') : '',
				endValue: data ? moment(data.end, 'YYYY-MM-DD').toDate() : '',

				startTime: data ? moment(data.start).format('HH:mm') : '',
				startTimeValue: data ? moment(data.start).toDate() : '',

				endTime: data ? moment(data.end).format('HH:mm') : '',
				endTimeValue: data ? moment(data.end).toDate() : '',
				status: data ? data.status : ASSET_SCHEDULE_STATUS.in_scheduled.value,
				recurring: data ? data.recurring : false,
				recurringInfo: data ? data.recurringInfo : {},
				memberList: data ? data.memberList : [],
			},
			requireAsset: !assetId && (!data || !data.assetId),
			visible: true,
			formHeader: data ? 'Edit Schedule' : 'New Schedule',
			errors: {},
		});
	};

	const closeForm = () => {
		setVisible(false);
		setFrmData({});
		setErrors({});
	};

	const handleSaveSchedule = () => {
		showloading();
		setErrors({});

		let tmpData = { ...frmData };
		if (tmpData.useForType !== SCHEDULE_USE_FOR_TYPE.program.value) {
			tmpData.startDate = tmpData.startDateValue && moment(tmpData.startDateValue).isValid() ? moment(tmpData.startDateValue).format(HTML5_FMT.DATE) : '';
			tmpData.endDate = tmpData.endDateValue && moment(tmpData.endDateValue).isValid() ? moment(tmpData.endDateValue).format(HTML5_FMT.DATE) : '';
			tmpData.startTime = tmpData.startTimeValue && moment(tmpData.startTimeValue).isValid() ? moment(tmpData.startTimeValue).format(HTML5_FMT.TIME) : '';
			tmpData.endTime = tmpData.endTimeValue && moment(tmpData.endTimeValue).isValid() ? moment(tmpData.endTimeValue).format(HTML5_FMT.TIME) : '';
		}

		saveSchedule(tmpData).then((res) => {
			if (!res.errorCode) {
				if (props.reloadSchedule) props.reloadSchedule();

				closeForm();
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
				if (res.errorCode === 400) setErrors(res.errorObj);
			}

			stoploading();
		});
	};

	const onDateChange = (e, key) => {
		switch (key) {
			case 'start':
				setFrmData({ ...frmData, startDateValue: e });
				break;
			case 'end':
				setFrmData({ ...frmData, endDateValue: e });
				break;
			default:
				break;
		}
	};

	const onDateBlur = (e, key) => {
		switch (key) {
			case 'start':
				if (!frmData.startDateValue || !moment(frmData.startDateValue).isValid()) setFrmData({ ...frmData, startDateValue: null });
				break;
			case 'end':
				if (!frmData.endDateValue || !moment(frmData.endDateValue).isValid()) setFrmData({ ...frmData, endDateValue: null });
				break;
			default:
				break;
		}
	};

	const onTimeChange = (e, key) => {
		switch (key) {
			case 'start':
				setFrmData({ ...frmData, startTimeValue: e });
				break;
			case 'end':
				setFrmData({ ...frmData, endTimeValue: e });
				break;
			default:
				break;
		}
	};

	const onTimeBlur = (key) => {
		switch (key) {
			case 'start':
				if (!frmData.startTimeValue || !moment(frmData.startTimeValue).isValid()) setFrmData({ ...frmData, startTimeValue: null });
				break;
			case 'end':
				if (!frmData.endTimeValue || !moment(frmData.endTimeValue).isValid()) setFrmData({ ...frmData, endTimeValue: null });
				break;
			default:
				break;
		}
	};

	const removeSchedule = (id) => {
		showConfirmNotify({
			message: 'Are you sure to remove this schedule. This action can not be undo?',
			accept: () => handleRemoveSchedule(id),
		});
	};

	const handleRemoveSchedule = (id) => {
		showloading();

		deleteSchedule(frmData.assetId, frmData.id).then((res) => {
			if (!res.errorCode) {
				if (!res.errorCode) {
					if (props.reloadSchedule) props.reloadSchedule();

					closeForm();
					showSuccessNotify('Action submitted');
				} else {
					showErrorNotify(res.errorMessage);
				}
			}

			stoploading();
		});
	};

	const actionTemplate = (schedule) => {
		if (isWrite) {
			const strNow = timezone ? moment().tz(timezone).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm');

			const now = moment(strNow);
			const start = moment(schedule.start);
			const end = moment(schedule.end);

			let isExpired = false;

			if (now.isAfter(end)) {
				isExpired = true;
			}
			if (schedule.status !== ASSET_SCHEDULE_STATUS.ended.value) {
				if (schedule.status !== ASSET_SCHEDULE_STATUS.using.value) {
					if (!isExpired) {
						return <Button label='Assign' icon='pi pi-upload' className='p-button-constrast' onClick={() => handleAssignAsset(schedule)} />;
					} else {
						return <Button label='Close' icon='pi pi-times' className='p-button-constrast' onClick={() => closeAssetSchedule(schedule.id)} />;
					}
				} else {
					return <Button label='Close' icon='pi pi-download' className='p-button-constrast' onClick={() => closeAssetSchedule(schedule.id)} />;
				}
			}
		}
	};

	const handleAssignAsset = (data) => {
		if ([ASSET_SCHEDULE_STATUS.pending.value, ASSET_SCHEDULE_STATUS.processing.value].includes(data.status)) {
			showConfirmNotify({
				message: 'The order related with asset schedule has not been paid completely. Must process on order first!',
				acceptLabel: 'Yes, go to the order',
				accept: () => history.push(`/orders/${data.orderId}`),
			});
		} else {
			const strNow = timezone ? moment().tz(timezone).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm');

			const now = moment(strNow);
			const start = moment(data.start);
			const end = moment(data.end);

			if (!now.isAfter(end)) {
				if (now.isBefore(start)) {
					showConfirmNotify({
						message: "Current doesn't match with schedule time. Are you sure to do this?",
						accept: () => assignAsset(data.id),
					});
				} else {
					assignAsset(data.id);
				}
			}
		}
	};

	const assignAsset = (scheduleId) => {
		pickupSchedule(scheduleId).then((res) => {
			if (!res.errorCode) {
				if (props.reloadSchedule) props.reloadSchedule();

				closeForm();

				showSuccessNotify('Asset has been in use');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const closeAssetSchedule = (id) => {
		closeSchedule(id).then((res) => {
			if (!res.errorCode) {
				if (props.reloadSchedule) props.reloadSchedule();

				closeForm();
				showSuccessNotify('Schedule has been closed');
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	return (
		<Sidebar visible={visible} style={{ overflowY: 'auto' }} className='p-sidebar-md' position='right' blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
			<div className='p-d-flex p-justify-between '>
				<h2 className='p-margin-top-10'>{header}</h2>
				<Button label='' icon='pi pi-times' className='p-button-secondary' onClick={closeForm} />
			</div>

			<div className='p-sidebar-line p-mb-3'></div>

			{frmData.id && (
				<div className='p-grid'>
					<div className='p-col-12 p-d-flex p-ai-center p-jc-between'>
						<label>
							Current status: <span className={classNames('status', schedule.status)}>{ASSET_SCHEDULE_STATUS[schedule.status] && ASSET_SCHEDULE_STATUS[schedule.status].label}</span>
						</label>
						{actionTemplate(schedule)}
					</div>

					<div className='p-col-12'>
						<div className='p-hr p-py-0'></div>
					</div>
				</div>
			)}

			<div className='p-grid p-fluid form-group'>
				<div className='p-col-12'>
					{isAllowSelectAsset ? (
						<React.Fragment>
							<label className='p-label'>* Asset</label>
							<Dropdown options={assets} value={frmData.assetId} filter={true} onChange={(e) => setFrmData({ ...frmData, assetId: e.value })} />
						</React.Fragment>
					) : (
						<label className='p-label dark p-size-16'>Asset: {selectedAsset.name}</label>
					)}
					<div className='p-form-error'>{errors.asset}</div>
					{errors.existScheduled && <Message severity='error' text={errors.existScheduled} className='p-mt-2' />}
				</div>

				<div className='p-col-12'>
					<label className='p-label'>* Title</label>
					<InputText value={frmData.title} onChange={(e) => setFrmData({ ...frmData, title: titleCaseText(e.target.value) })} />
					<div className='p-form-error'>{errors.title}</div>
				</div>

				<div className='p-col-12'>
					<label className='p-label'>Use For</label>
					<Dropdown value={frmData.useForType} options={useForOptions} onChange={(e) => setFrmData({ ...frmData, useForType: e.value })} />
				</div>

				{[SCHEDULE_USE_FOR_TYPE.program.value, SCHEDULE_USE_FOR_TYPE.rental.value].includes(frmData.useForType) && (
					<div className='p-col-12'>
						<label className='p-label'>* {frmData.useForType === SCHEDULE_USE_FOR_TYPE.program.value ? 'Activity' : 'Facility'}</label>
						<Dropdown value={frmData.productId} options={products} onChange={(e) => setFrmData({ ...frmData, productId: e.value })} placeholder='Select an option' />
						<div className='p-form-error'>{errors.product}</div>
					</div>
				)}

				{frmData.useForType === SCHEDULE_USE_FOR_TYPE.program.value && (
					<div className='p-col-12'>
						<label className='p-label'>* Session</label>
						<Dropdown value={frmData.availabilityId} options={productAvailabilities} onChange={(e) => setFrmData({ ...frmData, availabilityId: e.value })} placeholder='Select a session' />
						<div className='p-form-error'>{errors.availability}</div>
					</div>
				)}

				{frmData.useForType !== SCHEDULE_USE_FOR_TYPE.program.value && (
					<React.Fragment>
						<div className='p-col-12 p-pb-0'>
							<Divider align='left' type='dashed' className='p-mt-1 p-mb-0'>
								<b>* Schedule</b>
							</Divider>
						</div>

						<div className='p-col-12'>
							<Checkbox inputId='cbIsRange' checked={frmData.dateRange} onChange={(e) => setFrmData({ ...frmData, dateRange: e.checked })} />
							<label htmlFor='cbIsRange' className='p-margin-left-10'>
								Is date range?
							</label>
						</div>

						<div className='p-col-12 p-pb-0'>
							<div className='p-grid p-fluid'>
								<div className='p-col-6'>
									<label className='p-label'>* {frmData.dateRange ? 'Start Date' : 'Date'}</label>
									<MaskedCalendar
										value={frmData.startDateValue}
										showIcon={true}
										onChange={(e) => onDateChange(e.value, 'start')}
										onBlur={(e) => onDateBlur(e.target.value, 'start')}
										dateFormat='dd-mm-yy'
									/>
									<span className='p-form-error'>{errors.startDate}</span>
								</div>
								{frmData.dateRange && (
									<div className='p-col-6'>
										<label className='p-label'>* End Date</label>
										<MaskedCalendar
											value={frmData.endDateValue}
											showIcon={true}
											onChange={(e) => onDateChange(e.value, 'end')}
											onBlur={(e) => onDateBlur(e.target.value, 'end')}
											dateFormat='dd-mm-yy'
										/>
										<span className='p-form-error'>{errors.endDate}</span>
									</div>
								)}
							</div>
						</div>

						<div className='p-col-12 p-pb-0'>
							<div className='p-grid p-fluid'>
								<div className='p-col-6'>
									<label className='p-label'>* Start Time</label>
									<MaskedCalendar
										value={frmData.startTimeValue}
										showIcon={true}
										onChange={(e) => onTimeChange(e.value, 'start')}
										onBlur={(e) => onTimeBlur(e.target.value, 'start')}
										hourFormat='12'
										timeOnly={true}
									/>
									<span className='p-form-error'>{errors.startTime}</span>
								</div>
								<div className='p-col-6'>
									<label className='p-label'>* End Time</label>
									<MaskedCalendar
										value={frmData.endTimeValue}
										showIcon={true}
										onChange={(e) => onTimeChange(e.value, 'end')}
										onBlur={(e) => onTimeBlur(e.target.value, 'end')}
										hourFormat='12'
										timeOnly={true}
									/>
									<span className='p-form-error'>{errors.endTime}</span>
								</div>
							</div>
						</div>
					</React.Fragment>
				)}

				{errors.existScheduled && (
					<div className='p-col-12'>
						<Message severity='error' text={errors.existScheduled} />
					</div>
				)}

				{frmData.recurring && (
					<div className='p-col-12'>
						<Divider align='left' type='dashed' className='p-mt-2 p-mb-3'>
							<b>Subscription Info</b>
						</Divider>

						<label className='p-label'>
							Recurring in every {frmData.recurringInfo.rentalQty} {frmData.recurringInfo.rentalUnit}
						</label>
					</div>
				)}
			</div>

			<div className='p-sidebar-line p-my-3'></div>

			<div className='p-grid'>
				<div className='p-col-12 p-d-flex p-justify-between'>
					<Button label='Cancel' icon='pi-md-close' className='p-button-secondary' onClick={closeForm} />
					<div>
						{![ASSET_SCHEDULE_STATUS.using.value, ASSET_SCHEDULE_STATUS.ended.value].includes(frmData.status) && (
							<React.Fragment>
								{isDelete && frmData.id && <Button label='Delete' icon='pi-md-trash' className='p-button-danger' onClick={(e) => removeSchedule(frmData.id)} />}
								{isWrite && <Button label='Save' icon='pi pi-save' className='p-ml-3' onClick={() => handleSaveSchedule()} />}
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
		</Sidebar>
	);
});
