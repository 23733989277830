import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState, useEffect } from "react";

export default function ErrorRecords({ data, isLoading }) {
  const reasonTemplate = (rowData) => {
    let reasons = ``;
    Object.keys(rowData.errors).map((props) => {
      switch (props) {
        case "identity-document-work_permit":
          return (reasons += " work permit input error,");
        case "identity-document-fin":
          return (reasons += " fin input error,");
        case "identity-document-passport":
          return (reasons += "passport input error,");
        case "membership":
          return (reasons += " membership input error,");
        case "user":
          return (reasons += " user input error,");
        default:
          return reasons;
      }
    });
    return reasons.slice(0, -1);
  };
  return (
    <DataTable
      value={data.filter((e) => e.errors && e.errors.length > 0)}
      paginator={true}
      rows={10}
      responsive={true}
      header="Error Records"
      loading={isLoading}
    >
      <Column
        field="id"
        header="Order"
        sortable={true}
        body={(rowData) => rowData.userData.id}
      />
      <Column
        field="fullName"
        header="FullName"
        sortable={true}
        body={(rowData) => rowData.userData.fullName}
      />
      <Column
        field="workPermission"
        header="Work Permit No."
        body={(rowData) => rowData.userData.workPermission}
      />
      <Column
        field="passportNumber"
        header="Passport Number"
        body={(rowData) => rowData.userData.passportNumber}
      />
      <Column
        field="email"
        header="Email"
        body={(rowData) => rowData.userData.email}
      />
      <Column
        field="phone"
        header="Phone"
        body={(rowData) => rowData.userData.phone}
      />
      <Column
        field="gender"
        header="Gender"
        sortable={true}
        body={(rowData) => rowData.userData.gender}
      />
      <Column
        field="reason"
        header="Reason"
        sortable={true}
        body={(rowData) => reasonTemplate(rowData)}
      />
    </DataTable>
  );
}
