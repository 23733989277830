import axios from "axios";
import { PIM_BASE_URL, PIM_PATH } from "../../constants";

const PIM_ORDER_URL = PIM_BASE_URL + PIM_PATH + "/orders";

export function getPageOrders(filterRequest, page, size, sortField, sortOrder) {
    const sortDirection = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    var sortColumn = sortField;
    const sort = sortField&&sortDirection?`${sortColumn},${sortDirection}`:'';
    
    return axios.post(`${PIM_ORDER_URL}/page`, filterRequest, {
        params: {page, size, sort }
    }).then(res => res.data).catch(error => console.log(error));
}

export function  getOrderDetails(id){
    return axios.get(`${PIM_ORDER_URL}/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function completeOrder(id, params){
    return axios.post(`${PIM_ORDER_URL}/${id}/complete`, params)
    .then(res => res.data).catch(error => console.log(error));
}

export function cancelOrder(id, reason){
    return axios.post(`${PIM_ORDER_URL}/${id}/cancel`, null, {params: {reason}})
    .then(res => res.data).catch(error => console.log(error));
}

export function convertToPostPay(id){
    return axios.post(`${PIM_ORDER_URL}/${id}/convert-to-postpay`)
    .then(res => res.data).catch(error => console.log(error));
}

export function pickUpOrder(id){
    return axios.post(`${PIM_ORDER_URL}/${id}/pick-up`)
    .then(res => res.data).catch(error => console.log(error));
}

export function pickUpOrderItem(id, oid){
    return axios.post(`${PIM_ORDER_URL}/${oid}/${id}/item/pick-up`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getOrderItem(orderId, id){
    return axios.get(`${PIM_ORDER_URL}/item/${id}`, {params: {orderId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function exportOrders(filter){
    return axios.post(`${PIM_ORDER_URL}/export`, filter, {responseType: 'arraybuffer'}
    ).then(res => res.data).catch(error => console.log(error));
}


export function transferParticipantSchedule(participantId, availabilityId){
    return axios.post(`${PIM_ORDER_URL}/transfer-participant`, null, {params: {id: participantId, availabilityId: availabilityId}})
    .then(res => res.data).catch(error => console.log(error));
}