import moment from 'moment';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import React, { useEffect, useRef, useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch } from 'react-router-dom';
import { ALIAS_APPLICATION_VOLUNTEER, APPLICATION_COMPONENTT_TYPE, DATETIME_FORMAT_DISPLAY } from '../../constants';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { showConfirmNotify, showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { ApplicationRecordByUserLogForm } from './ApplicationRecordByUserLogForm';
import ApplicationRecordForm from './ApplicationRecordForm';
import { deleteApplicationRecord, exportApplicationRecords, getApplicationByAlias, getPageApplicationRecord } from './ApplicationService';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';


const fileDownload = require('js-file-download');

export const VolunteerApplicationRecordListView = (props) => {
	const match = useRouteMatch();

	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'id',
		sortOrder: -1,
	});
	const [filter, setFilter] = useState({
		fieldNames: ['volunteer_first_name', 'volunteer_last_name', 'volunteer_email'],
	});
	const [dateFromValue, setDateFromValue] = useState(null);
	const [dateToValue, setDateToValue] = useState(null);
	const [minDateValue, setMinDateValue] = useState(null);
	const [maxDateValue, setMaxDateValue] = useState(null);

	const [isLoadTable, setLoadTable] = useState(false);

	const applicationRecordForm = useRef(null);
	const applicationRecordByUserLogForm = useRef(null);

	const isWrite = isScpAdmin() || hasRole([ROLES_CONFIG.VOLUNTEER_MGT_W]);
	const isDelete = isScpAdmin() || hasRole([ROLES_CONFIG.VOLUNTEER_MGT_D]);

	useEffect(() => {
		loadApplication();
	}, []);

	useEffect(() => {
		if (isLoadTable) {
			loadPageVolunteerApplicationRecords();
		}
	}, [isLoadTable]);

	const loadPageVolunteerApplicationRecords = () => {
		if (filter.applicationIds && filter.applicationIds.length > 0) {
			getPageApplicationRecord(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder).then((res) => {
				setDataList(res.content);
				setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
				setLoadTable(false);
			});
		} else {
			showErrorNotify('No Volunteer Application');
			setLoadTable(false);
		}
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const loadApplication = () => {
		getApplicationByAlias(ALIAS_APPLICATION_VOLUNTEER).then((res) => {
			if (!res.errorCode) {
				setFilter({ ...filter, applicationIds: [res.id] });
				setLoadTable(true);
			}
		});
	};

	const handleRemove = (id) => {
		deleteApplicationRecord(id).then((res) => {
			if (!res.errorCode) {
				loadPageVolunteerApplicationRecords();
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const handleExport = () => {
		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = 'rpt_volunteer_record_' + suf + '.xlsx';

		exportApplicationRecords(filter).then((res) => {
			if (!res.errorCode) {
				fileDownload(res, fileName);
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const actionTemplate = (rowData) => {
		let items = [];
		if (isWrite) {
			items.push({ label: 'Link to', icon: 'pi pi-pencil', command: () => applicationRecordByUserLogForm.current.openForm(rowData) });
		}
		if (isDelete) {
			items.push({ label: 'Remove', icon: 'pi pi-trash', command: () => onRemoveRecord(rowData.id) });
		}
		if (items.length > 0) {
			return <SplitButton label='View' icon='pi pi-search' className='p-button-constrast p-l' model={items} onClick={() => applicationRecordForm.current.openForm(rowData.id)} />;
		}
		return <Button label='View' icon='pi pi-search' className='p-button-constrast p-l' model={items} onClick={() => applicationRecordForm.current.openForm(rowData.id)} />;
	};

	const onRemoveRecord = (id) => {
		showConfirmNotify({
			accept: () => handleRemove(id),
		});
	};

	const onDateFromChange = (value) => {
		let currentValueToDate = value && moment(value).isValid() ? moment(value) : '';
		let tmpMaxDate = maxDateValue ? moment(maxDateValue) : '';
		let isCurrentValueAfterMaxDate = currentValueToDate && tmpMaxDate && currentValueToDate.isAfter(tmpMaxDate);
		if (currentValueToDate) {
			currentValueToDate.set({ h: 0, m: 0, s: 0 });
		}
		setFilter({
			...filter,
			dateFrom: currentValueToDate ? currentValueToDate.format(moment.HTML5_FMT.DATE) : '',
			dateTo: !isCurrentValueAfterMaxDate ? filter.dateTo : '',
		});
		setDateFromValue(value);
		setDateToValue(!isCurrentValueAfterMaxDate ? dateToValue : '');
		setMinDateValue(moment(value).isValid() ? value : '');
		setMaxDateValue(!isCurrentValueAfterMaxDate ? maxDateValue : '');
	};

	const onDateToChange = (value) => {
		let currentValueToDate = moment(value).isValid() ? moment(value) : '';
		let tmpMinDate = minDateValue ? moment(minDateValue) : '';
		let isCurrentValueBeforeMinDate = currentValueToDate && tmpMinDate && currentValueToDate.isBefore(tmpMinDate);
		if (currentValueToDate) {
			currentValueToDate.set({ h: 23, m: 59, s: 59 });
		}
		setFilter({
			...filter,
			dateFrom: !isCurrentValueBeforeMinDate ? filter.dateFrom : '',
			dateTo: currentValueToDate ? currentValueToDate.format(moment.HTML5_FMT.DATE) : '',
		});
		setDateFromValue(!isCurrentValueBeforeMinDate ? dateFromValue : '');
		setDateToValue(value);
		setMinDateValue(!isCurrentValueBeforeMinDate ? minDateValue : '');
		setMaxDateValue(moment(value).isValid() ? value : '');
	};

	const clearFilter = () => {
		setFilter({ ...filter, fieldValue: '', dateFrom: '', dateTo: '' });
		setDateFromValue(null);
		setDateToValue(null);
		setMinDateValue(null);
		setMaxDateValue(null);
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const tblHeader = (
		<TableHeader
			title='Volunteer Records'
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => loadPageVolunteerApplicationRecords()}
			actionTemplate={<Button className='p-button-constrast' label='Export' icon='pi pi-download' disabled={dataList.length === 0} onClick={() => handleExport()} />}
		/>
	);

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>Volunteer Records</BreadcrumbsItem>

			<ApplicationRecordForm ref={applicationRecordForm} refreshTable={() => loadPageVolunteerApplicationRecords()} />
			<ApplicationRecordByUserLogForm ref={applicationRecordByUserLogForm} refreshTable={() => loadPageVolunteerApplicationRecords()} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<Fieldset legend='Filter'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12 p-md-6'>
								<div className='p-inputgroup'>
									<span className='p-inputgroup-addon'>Search: </span>
									<InputText value={filter.fieldValue} onChange={(e) => setFilter({ ...filter, fieldValue: e.target.value })} placeholder='Type search value (first name, last name, email)' />
								</div>
							</div>
							<div className='p-col-12 p-md-6'>
								<div className='p-grid'>
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Date From: </span>
											<MaskedCalendar value={dateFromValue} onChange={(e) => onDateFromChange(e.value)} showIcon={true} dateFormat='dd-mm-yy' />
										</div>
									</div>
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Date To: </span>
											<MaskedCalendar value={dateToValue} onChange={(e) => onDateToChange(e.value)} showIcon={true} dateFormat='dd-mm-yy' />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='p-grid p-margin-top-10'>
							<div className='p-col-12 p-c'>
								<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={() => loadPageVolunteerApplicationRecords()} />
								<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' onClick={() => clearFilter()} />
							</div>
						</div>
					</Fieldset>
				</div>
			</div>

			<div className='p-col-12'>
				<DataTable
					emptyMessage="There's no record found"
					header={tblHeader}
					value={dataList}
					lazy={true}
					loading={isLoadTable}
					paginator={true}
					first={pageable.page * pageable.rows}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					onPage={onPage}
					onSort={onSort}
					responsive={true}
				>
					{/* <Column field="id" header="ID" /> */}
					<Column
						header='First Name'
						body={(rowData) => {
							let tmpFirstName;
							let tmpFieldValues = rowData.fieldValues ? rowData.fieldValues : [];
							for (const tmpValue of tmpFieldValues) {
								let tmpField = tmpValue.field;

								if (tmpField && tmpValue.value) {
									if (tmpField.name === 'volunteer_first_name') {
										tmpFirstName = tmpValue.value;
									}
								}
							}
							return tmpFirstName;
						}}
					/>
					<Column
						header='Last Name'
						body={(rowData) => {
							let tmpLastName;
							let tmpFieldValues = rowData.fieldValues ? rowData.fieldValues : [];
							for (const tmpValue of tmpFieldValues) {
								let tmpField = tmpValue.field;

								if (tmpField && tmpValue.value) {
									if (tmpField.name === 'volunteer_last_name') {
										tmpLastName = tmpValue.value;
									}
								}
							}
							return tmpLastName;
						}}
					/>
					<Column
						header='Email'
						body={(rowData) => {
							let tmpEmail;
							let tmpFieldValues = rowData.fieldValues ? rowData.fieldValues : [];
							for (const tmpValue of tmpFieldValues) {
								let tmpField = tmpValue.field;

								if (tmpField && tmpValue.value) {
									if (tmpField.name === 'volunteer_email') {
										tmpEmail = tmpValue.value;
									}
								}
							}
							return tmpEmail;
						}}
					/>
					<Column header='Linked to' body={(rowData) => (rowData.user ? rowData.user.name : 'N/A')} />
					<Column header='Membership' body={(rowData) => (rowData.membership ? rowData.membership.name : 'N/A')} />
					{/* <Column header="Description" 
                        body={rowData => {
                            let tmpValue = ''

                            let tmpPersonalInfos = rowData.personalInfo

                            rowData.application.contents && rowData.application.contents.map((content, idxContent) => {
                                return content.components && content.components.map((component, idxComponent) => {
                                    if(component.type===APPLICATION_COMPONENTT_TYPE.input_field.value){
                                        let tmpPersonalSet = component.personalSet
                                        let tmpPersonalField = tmpPersonalSet&&tmpPersonalSet.personalField?tmpPersonalSet.personalField:{}
                                        let tmpCustomSet = component.customSet

                                        let tmpPersonalValue = tmpPersonalInfos&&tmpPersonalInfos[idxContent]&&tmpPersonalInfos[idxContent].personalInfos&&tmpPersonalInfos[idxContent].personalInfos[idxComponent]&&tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                                            ? tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                                            : {}
                                            if(tmpPersonalField.useTitle) tmpValue += tmpPersonalValue?tmpPersonalValue.title??'':''
                                            if(tmpPersonalField.useFirstName) tmpValue += ' '+(tmpPersonalValue?tmpPersonalValue.firstName??'':'')
                                            if(tmpPersonalField.useLastName) tmpValue += ' '+(tmpPersonalValue?tmpPersonalValue.lastName??'':'')
                                    }
                                })
                            })
                            return tmpValue
                        }}
                    /> */}
					<Column field='date' header='Log on' body={(rowData) => (rowData.date ? moment(rowData.date).format(DATETIME_FORMAT_DISPLAY) : 'No Date')} />
					<Column header='Actions' body={actionTemplate} className='tc-actions' style={{ textAlign: 'center', width: '12em', overlay: 'visible' }} />
				</DataTable>
			</div>
		</div>
	);
};
