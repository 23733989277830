import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Sidebar } from "primereact/sidebar";
import { showNotification, showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { Spinner } from "primereact/spinner";
import { getMenuItems, saveMenuItem } from "./MenuServices";
import { getPageContents } from "../content/PageContentService";
import { MENU_TYPE, PAGE_PREDEFINED_URL_OPTIONS } from "../../constants";

export const MenuItemForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("New Menu")
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [errors, setErrors] = useState({})

    const [menuId, setMenuId] = useState()
    const [menuItems, setMenuItems] = useState([])
    const [pageContents, setPageContents] = useState([])
    
    useImperativeHandle(ref, () => ({
        openForm(mId, e){
            setHeader((e?'Edit':'New') + ' Menu')
            setVisible(true)
            setFrmData(popularFormData(e))
            setMenuId(mId)
            loadMenuItems(mId, e?e.id:null)
        },
        closeForm(){
            closeForm()
        }
    }))

    useEffect(() => {
        loadPublicPages()
    }, [])

    const popularFormData = (data) => {
        return {
            id: data ? data.id : null,
            menuId: menuId,
            parentId: data ? data.parentId : null,
            type: data ? data.type : MENU_TYPE.PUBLIC_PAGE.value,
            title: data && data.title ? data.title : '',
            query: data && data.query ? data.query : '',        
            url: data && data.url ? data.url : '',
            pageContentId: data ? data.pageContentId : null,
            enabled: data && data.enabled,
            weight: data ? data.weight : 0
        }
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setErrors({})
    }

    const loadPublicPages = () => {
        getPageContents(true).then(res => {
            setPageContents(res?res:[])
        })
    }

    const loadMenuItems = (mId, id) => {
        getMenuItems(mId, true).then(res => {
            let array = res?res:[]
            array = array.filter(m => m.value!==id);
            array.unshift({value: null, label: 'None'})

            setMenuItems(array)
            // let tempArr = res?res:[];
            // if(frmData.id) {
            //     let tempChildren = [];
            //     tempChildren = this.toChildrenId(frmData, tempChildren);
            //     tempArr = tempArr.filter(m => (m.value !== frmData.id && !tempChildren.includes(m.value)));
            // }
            // this.setState({ menuItems: tempArr })
        })
    }

    const toChildrenId = (menu, array) => {
        let self = this;
        menu.children.forEach(m =>{
            array.push(m.id);
            if(m.children && m.children.length > 0)
                self.toChildrenId(m, array);
        });
        return array;
    }

    const handleSaveMenu = () => {
        setErrors({})
        saveMenuItem(menuId, frmData)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable)
                    props.refreshTable()

                closeForm()
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }
        })
    }

    return(
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid p-fluid form-group">
                <div className="p-col-12" >
                    <label className="p-label">* Title</label>
                    <InputText value={frmData.title} onChange={e => setFrmData({...frmData, title: e.target.value})} />
                    <span className="p-form-error">{errors.title}</span>
                </div>
                <div className="p-col-12" >
                    <label className="p-label">Sub of menu</label>
                    <Dropdown value={frmData.parentId} options={menuItems} onChange={e => setFrmData({...frmData, parentId: e.value})}/>
                </div>
                <div className="p-col-12">
                    <label className="p-label">* Type</label>
                    <Dropdown value={frmData.type}  options={Object.values(MENU_TYPE)} onChange={e => setFrmData({...frmData, type: e.value})} />
                </div>
                
                {frmData.type!==MENU_TYPE.BLANK.value && 
                <div className="p-col-12" >
                    {frmData.type === MENU_TYPE.LINK.value &&
                    <React.Fragment>
                        <label className="p-label">* Url</label>
                        <InputText value={frmData.url} onChange={e => setFrmData({...frmData, url: e.target.value})} />
                    </React.Fragment>
                    }
                    {frmData.type === MENU_TYPE.PUBLIC_PAGE.value &&
                    <React.Fragment>
                        <label className="p-label">* Page</label>
                        <Dropdown value={frmData.pageContentId} options={pageContents} onChange={e => setFrmData({...frmData, pageContentId: e.value})} />
                    </React.Fragment>
                    }
                    {frmData.type === MENU_TYPE.PREDEFINE_PAGE.value &&
                    <React.Fragment>
                        <label className="p-label">* Url</label>
                        <Dropdown value={frmData.url} options={PAGE_PREDEFINED_URL_OPTIONS} onChange={e => setFrmData({...frmData, url: e.value})} />
                    </React.Fragment>
                    }
                    <span className="p-form-error">{errors.url}</span>
                </div>
                }

                {frmData.type!==MENU_TYPE.PUBLIC_PAGE.value && frmData.type!==MENU_TYPE.BLANK.value && 
                <div className="p-col-12">
                    <label className="p-label">Query</label>
                    <InputText value={frmData.query} onChange={e => setFrmData({...frmData, query: e.target.value})} />
                </div>
                }
                
                <div className="p-col-12">
                    <label className="p-label">Weight (for order)</label>
                    <InputText value={frmData.weight} keyfilter="int" onChange={e => setFrmData({...frmData, weight: e.target.value})} />
                </div>
                <div className="p-col-12">
                    <InputSwitch inputId="enabled" checked={frmData.enabled} onChange={e => setFrmData({...frmData, enabled: e.value})} />
                    <label htmlFor="enabled" className="p-margin-left-10">Active? </label>
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={closeForm}/>
                    <Button label="Submit" icon="pi pi-check" onClick={() => handleSaveMenu()}/>
                </div>
            </div>
        </Sidebar>
    )
})