import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import CKEditor from 'ckeditor4-react';
import { createZoomMeeting, deleteZoomMeeting, getMeetingParticipants} from "./EventSessionService";
import { Dropdown } from "primereact/dropdown";
import { EVENT_ZOOM_MEETING_TYPE } from "../../constants";
import { Fieldset } from "primereact/fieldset";
import { showloading, stoploading } from "../../core/service/LoadingService";
import { ConfirmNotification } from "../../core/components/ConfirmNotification";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getListLiveAccounts } from "./LiveAccountService";
CKEditor.editorUrl = "/assets/ckeditor/ckeditor.js";

export const MeetingForm = (props) => {
    const confirmNotify = useRef(null)
    
    const [zoomMeeting, setZoomMeeting] = useState(props.sessionData&&props.sessionData.zoomMeeting?props.sessionData.zoomMeeting:{})
    const [frmData, setFrmData] = useState({type: null, liveAccountId: null})
    const [liveAccounts, setLiveAccounts] = useState([])
    const [meetingParticipants, setMeetingParticipants] = useState([])
    const [visibleMeetingParticipants, setVisibileMeetingParticipants] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(()=>{
        loadListLiveAccounts()
    },[])

    const loadListLiveAccounts = () => {
        getListLiveAccounts(true).then(res => setLiveAccounts(res?res:[]))
    }

    const handleCreateZoomMeeting = () => {
        showloading();
        setErrors({})

        let zoomData = {
            eventSessionId: props.sessionData.id,
            type: frmData.type, 
            liveAccountId: frmData.liveAccountId
        }

        createZoomMeeting(zoomData)
        .then(res => {
            if(!res.errorCode) {
                setZoomMeeting(res.zoomMeeting?res.zoomMeeting:{})
                if(props.reloadData)
                    props.reloadData()
                showSuccessNotify("Meeting has been created!")
            }else{
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }
            stoploading()
        })
    }
    
    const handleRemoveMeeting = () => {
        if(zoomMeeting.meetingId) {
            showloading();

            deleteZoomMeeting(props.sessionData.id, zoomMeeting.meetingId)
            .then(data => {
                if(!data.errorCode) {
                    setZoomMeeting({})
                    setFrmData({...frmData, type: null, liveAccountId: null})
                    if(props.reloadData)
                        props.reloadData()
                    // setVisibileMeetingParticipants(false)
                    showSuccessNotify("Meeting has been removed!")
                } else {
                    showErrorNotify(data.errorMessage, "Error");
                }
                stoploading()
            })
        } else {
            showErrorNotify("No meeting found");
        }
    }

    const handleViewMeetingParticipants = () => {
        getMeetingParticipants(props.sessionData.id)
        .then(data => {
            if(!data.errorCode) {
                setMeetingParticipants(data&&data.participants ? data.participants : [])
                setVisibileMeetingParticipants(true)
            } else {
                showErrorNotify(data.errorMessage, "Error");
            }
        })
    }

    const onRemoveZoomMeeting = () => {
        showConfirmNotify({
            accept: () => handleRemoveMeeting()
        })
    }

    const closeMeetingParticipantLog = () => {
        setVisibileMeetingParticipants(false)
    }

    return(
        <React.Fragment>
            <ConfirmNotification ref={confirmNotify}
                submitConfirm={() =>  handleRemoveMeeting()}
                message="Are you sure you want to delete this zoom meeting?"
            />

            {/* <Sidebar visible={visibleMeetingParticipants} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeMeetingParticipantLog}>
                <div className="p-d-flex p-justify-between ">
                    <h2 className="p-margin-top-10">Meeting Participants</h2>
                    <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeMeetingParticipantLog}/>
                </div>

                <div className="p-sidebar-line p-mb-3"></div>

                <div className="p-grid">
                    <div className="p-col-12">
                        <DataTable value={meetingParticipants} responsive={true} header="Meeting Participant Logs" >
                            <Column field="name" header="Name" sortable={true}/>
                            <Column field="user_email" header="Email" sortable={true} />
                            <Column field="join_time" header="Join Time" body={(rowData) => rowData.join_time && moment(rowData.join_time).format("MM-DD-YYYY HH:mm")} sortable={true}/>
                            <Column field="leave_time" header="Leave Time" body={(rowData) => rowData.leave_time && moment(rowData.leave_time).format("MM-DD-YYYY HH:mm")} sortable={true}/>
                            <Column field="duration" header="Duration" sortable={true}></Column>
                        </DataTable>
                    </div>
                </div>

                <div className="p-sidebar-line p-my-3"></div>

                <div className="p-grid">
                    <div className="p-col-12">
                        <Button label="Delete Meeting" icon="pi pi-times" className="p-button-danger" style={{width: 'auto'}} onClick={() => confirmNotify.current.confirm()}></Button>
                        <Button label="See Participants" icon="pi pi-search" className="p-button-secondary" style={{width: 'auto'}} onClick={() => handleViewMeetingParticipants()}></Button>
                    </div>
                </div>
            </Sidebar> */}
            
            <div className="p-grid">
                <div className="p-col-12">
                    {zoomMeeting && Object.keys(zoomMeeting).length > 0 ?
                    <Fieldset legend="Meeting Info">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                Meeting Type: <strong>{EVENT_ZOOM_MEETING_TYPE[zoomMeeting.type]&&EVENT_ZOOM_MEETING_TYPE[zoomMeeting.type].label}</strong>
                            </div>
                            <div className="p-col-12">
                                Meeting Id: <strong>{zoomMeeting.meetingId}</strong>
                            </div>
                            <div className="p-col-12">
                                Password: <strong>{zoomMeeting.password}</strong>
                            </div>
                            <div className="p-col-12">
                                Host Account: <strong>{zoomMeeting.liveAccount&&zoomMeeting.liveAccount.name}</strong>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-mt-3 p-d-flex p-justify-between">
                                <Button label="Delete Meeting" icon="pi pi-times" className="p-button-danger" onClick={() => onRemoveZoomMeeting()}></Button>
                                {/* <Button label="See Participants" icon="pi pi-search" onClick={() => handleViewMeetingParticipants()}></Button> */}
                            </div>
                        </div>
                    </Fieldset>
                    :
                    <Fieldset legend="Create meeting">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                <label>* Meeting Type</label>
                                <Dropdown value={frmData.type} options={Object.values(EVENT_ZOOM_MEETING_TYPE)} onChange={(e) => setFrmData({...frmData, type: e.value})}/>
                                <div className="p-form-error">{errors.type}</div>
                            </div>
                            <div className="p-col-12">
                                <label>* Available Host Account</label>
                                <Dropdown value={frmData.liveAccountId} options={liveAccounts} onChange={(e) => setFrmData({...frmData, liveAccountId: e.value})} placeholder="Choose a host account"/>
                                <div className="p-form-error">{errors.liveAccountId}</div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-mt-3">
                                <Button label="Create Meeting" icon="pi pi-check"  onClick={() => handleCreateZoomMeeting()}></Button>
                            </div>
                        </div>
                    </Fieldset>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}
