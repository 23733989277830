import React, { Component } from "react";
import classNames from "classnames";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { showNotification } from '../../core/service/NotificationService';
import { CONTENT_COMPONENT_TYPE, APP_FEP, TAXONOMY_TYPE } from "../../constants";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { getSliders } from "../slider/SliderServices";
import { Fieldset } from "primereact/fieldset";
import { Checkbox } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import CKEditor from 'ckeditor4-react';
import { getEvents } from "../../scp/event/EventService";
import { getListDepartments } from "../../scp/departments/DepartmentServices";
import { getListTaxons } from "../../pim/taxon/TaxonServices";
CKEditor.editorUrl = "/assets/ckeditor/ckeditor.js";

const VIEW_TYPE = {
    carousel: {value: 'carousel', label: 'Carousel'},
    grid: {value: 'grid', label: 'Grid'},
    list: {value: 'list', label: 'List'}
}

const IMAGE_STYLE = {
    square: {value: 'square', label: 'Square'},
    circle: {value: 'circle', label: 'Circle'}
}

export class ComponentContentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            departments: [],
            categories: [],
            events: [],
            errors: {}
        }
    }

    componentDidMount(){
        this.loadAllDepartments();
        this.loadSliders()
        this.loadEvents()
    }

    popularFormData = (type, data) => {
        // let tmpItem = data&&data.itemTemplate ? data.itemTemplate : {};
        this.setState({
            formHeader: data ? 'Edit Component' : 'New Component',
            type: type,
            data: data?data:this.defaultFormData(type),
            // {
            //     title: data&&data.title ? data.title : '',
            //     content: data&&data.content ? data.content : '',
            //     sliderId: data ? data.sliderId : '',
            //     viewType: data ? data.viewType : VIEW_TYPE.list,
            //     viewTop: data ? data.viewTop : '10',
            //     itemTemplate: { ...tmpItem,
            //         imageStyle: tmpItem&&tmpItem.imageStyle ? tmpItem.imageStyle : IMAGE_STYLE.square,
            //     }
            // },
            visible: true,
            errors: {}
        });

        if(type===CONTENT_COMPONENT_TYPE.product_widget.value && data && data.departmentId){
            this.loadAllCategories(data.departmentId)
        }
    }

    defaultFormData = (type) => {
        switch (type){
            case CONTENT_COMPONENT_TYPE.free_text.value:
                return {
                    title: '',
                    content: ''
                }
            case CONTENT_COMPONENT_TYPE.slider.value:
                return {
                    title: '',
                    sliderId: null
                }
            case CONTENT_COMPONENT_TYPE.weather_widget.value:
                return {
                    title: '',
                    locationName: '',
                    locationLat: '',
                    locationLon: '' 
                }
            case CONTENT_COMPONENT_TYPE.product_widget.value:
                return {
                    title: '',
                    departmentId: null,
                    categoryId: null,
                    viewType: VIEW_TYPE.carousel.value,
                    numOfItem: '3' 
                }
            default:
                return {}
        }
    }

    handleSave = () => {
        if(this.state.type===CONTENT_COMPONENT_TYPE.product_widget.value){
            let error = {}

            if(!this.state.data.departmentId)
                error.departmentId = 'Field is required'

            if(Object.keys(error).length>0){
                this.setState({errors: error})
                return
            }
        }
        
        this.props.onSubmit(this.state.data);
        this.onHide();
    }

    loadSliders = () => {
        getSliders(true)
        .then(res => {
            this.setState({ sliders: res?res:[]});
        });
    }

    loadAllDepartments = () => {
        getListDepartments({externalRef: false}).then(res => this.setState({departments: res?res:[]}))
    }

    loadAllCategories = (deptId) => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.product.value], departmentIds: [deptId]}).then(res => this.setState({categories: res?res:[]}))
    }

    loadEvents = () => {
        getEvents(true)
        .then(res => {
            this.setState({ events: res?res:[]});
        });
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    onDepartmentChange = (e) => {
        this.setState({
            data: {...this.state.data, departmentId: e}
        }, () => this.loadAllCategories(e))
    }

    render() {
        return (
            <Sidebar visible={this.state.visible} style={{overflowY: 'auto'}} className="p-sidebar-lg" position="right" blockScroll={true} dismissable={false} showCloseIcon={false}>
                <div className="p-d-flex p-justify-between ">
                    <h2 className="p-margin-top-10">{this.state.formHeader}</h2>
                    <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={this.onHide}/>
                </div>

                <div className="p-sidebar-line p-mb-3"></div>

                <h3>Type: {CONTENT_COMPONENT_TYPE[this.state.type] && CONTENT_COMPONENT_TYPE[this.state.type].label}</h3>

                <div className="p-grid p-fluid">
                    <div className="p-col-12">
                        <label className="p-label">Title (for display, leave blank if doesn't want to show)</label>
                        <InputText value={this.state.data.title} onChange={(e) => this.setState({data: {...this.state.data, title: e.target.value}})} />
                        <div className="p-form-error">{this.state.errors.title}</div>
                    </div>
                    {(()=>{
                        if(this.state.type){
                            switch(this.state.type){
                                case CONTENT_COMPONENT_TYPE.free_text.value:
                                    return(
                                        <div className="p-col-12">
                                            <label className="p-label">Content</label>
                                            <div className="p-form-error">{this.state.errors.content}</div>
                                            <CKEditor data={this.state.data.content} onChange={(e) => this.setState({data: {...this.state.data, content: e.editor.getData()}})} />
                                        </div>
                                    )
                                case CONTENT_COMPONENT_TYPE.slider.value:
                                    return(
                                        <div className="p-col-12">
                                            <label className="p-label">Slider</label>
                                            <Dropdown value={this.state.data.sliderId} options={this.state.sliders} onChange={(e) => this.setState({data: {...this.state.data, sliderId: e.value}})} />
                                        </div>
                                    )
                                case CONTENT_COMPONENT_TYPE.weather_widget.value:
                                    return (
                                        <React.Fragment>
                                            <div className="p-col-12">
                                                <label className="p-label">Location Name</label>
                                                <InputText value={this.state.data.locationName} onChange={(e) => this.setState({data: {...this.state.data, locationName: e.target.value}})}/>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Latitude</label>
                                                <InputText value={this.state.data.locationLat} onChange={(e) => this.setState({data: {...this.state.data, locationLat: e.target.value}})}/>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Longtitude</label>
                                                <InputText value={this.state.data.locationLon} onChange={(e) => this.setState({data: {...this.state.data, locationLon: e.target.value}})}/>
                                            </div>
                                        </React.Fragment>
                                    )
                                case CONTENT_COMPONENT_TYPE.product_widget.value:
                                    return (
                                        <React.Fragment>
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">* Show by department</label>
                                                <Dropdown value={this.state.data.departmentId} options={this.state.departments} onChange={(e) => this.onDepartmentChange(e.value)} filter={true} style={{ width: '100%' }} placeholder="Select a department" />
                                                <span className="p-form-error">{this.state.errors.department}</span>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">Show by category</label>
                                                <Dropdown value={this.state.data.categoryId} options={this.state.categories} onChange={(e) => this.setState({data: {...this.state.data, categoryId: e.value}})} filter={true} showClear={true} style={{ width: '100%' }} placeholder="Select a category" />
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">View type</label>
                                                <Dropdown value={this.state.data.viewType} options={Object.values(VIEW_TYPE)} onChange={(e) => this.setState({data: {...this.state.data, viewType: e.value}})} style={{ width: '100%' }}/>
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <label className="p-label">{this.state.data.viewType!==VIEW_TYPE.list.value?'Num of item per row':'View top of number'}</label>
                                                <InputText value={this.state.data.numOfItem} keyfilter={/^[0-9]*$/} onChange={(e) => this.setState({data: {...this.state.data, numOfItem: e.target.value}})}/>
                                            </div>
                                        </React.Fragment>
                                    )
                                case CONTENT_COMPONENT_TYPE.speaker.value:
                                    return <React.Fragment>
                                        <div className="p-col-12">
                                            <label className="p-label">View Type</label>
                                            <SelectButton value={this.state.data.viewType} options={Object.values(VIEW_TYPE)} onChange={(e) => this.setState({data: {...this.state.data, viewType: e.value?e.value:this.state.data.viewType}})} />
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">View Top</label>
                                            <InputText value={this.state.data.viewTop} keyfilter="num" onChange={(e) => this.setState({data: {...this.state.data, viewTop: e.target.value}})} />
                                        </div>
                                        <div className="p-col-12">
                                            <Fieldset legend="Item Setting">
                                                <div className="p-grid">
                                                    <div className="p-col-12">
                                                        <label className="p-label">Image Style</label>
                                                        <SelectButton value={this.state.data.itemTemplate.imageStyle} options={Object.values(IMAGE_STYLE)} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, imageStyle: e.value?e.value:this.state.data.itemTemplate.imageStyle}} })} />
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckShowName" checked={this.state.data.itemTemplate.showName} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, showName: e.checked}} })} />
                                                        <label htmlFor="ckShowName">Show Name?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckShowCareer" checked={this.state.data.itemTemplate.showCareer} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, showCareer: e.checked}} })} />
                                                        <label htmlFor="ckShowCareer">Show Career?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckShowBio" checked={this.state.data.itemTemplate.showBio} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, showBio: e.checked}} })} />
                                                        <label htmlFor="ckShowBio">Show Bio?</label>
                                                    </div>
                                                </div>
                                            </Fieldset>
                                        </div>
                                    </React.Fragment>
                                case CONTENT_COMPONENT_TYPE.event_ticket.value:
                                    return <React.Fragment>
                                        <div className="p-col-12">
                                            <label className="p-label">View Type</label>
                                            <SelectButton value={this.state.data.viewType} options={Object.values(VIEW_TYPE)} onChange={(e) => this.setState({data: {...this.state.data, viewType: e.value?e.value:this.state.data.viewType}})} />
                                        </div>
                                        <div className="p-col-12">
                                            <label className="p-label">View Top</label>
                                            <InputText value={this.state.data.viewTop} keyfilter="num" onChange={(e) => this.setState({data: {...this.state.data, viewTop: e.target.value}})} />
                                        </div>
                                        <div className="p-col-12">
                                            <Fieldset legend="Item Setting">
                                                <div className="p-grid">
                                                    <div className="p-col-12">
                                                        <label className="p-label">By Event</label>
                                                        <Dropdown value={this.state.data.itemTemplate.eventId} options={this.state.events} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, eventId: e.value}} })} />
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckShowName" checked={this.state.data.itemTemplate.showName} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, showName: e.checked}} })} />
                                                        <label htmlFor="ckShowName">Show Name?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckShowTitle" checked={this.state.data.itemTemplate.showTitle} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, showTitle: e.checked}} })} />
                                                        <label htmlFor="ckShowTitle">Show Title?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckShowDescription" checked={this.state.data.itemTemplate.showDescription} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, showDescription: e.checked}} })} />
                                                        <label htmlFor="ckShowDescription">Show Description?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckShowImage" checked={this.state.data.itemTemplate.showImage} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, showImage: e.checked}} })} />
                                                        <label htmlFor="ckShowImage">Show Image?</label>
                                                    </div>
                                                    {this.state.data.itemTemplate.showImage &&
                                                        <div className="p-col-12">
                                                            <label className="p-label">Image Style</label>
                                                            <SelectButton value={this.state.data.itemTemplate.imageStyle} options={Object.values(IMAGE_STYLE)} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, imageStyle: e.value?e.value:this.state.data.itemTemplate.imageStyle}} })} />
                                                        </div>
                                                    }
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckShowFilter" checked={this.state.data.itemTemplate.showFilter} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, showFilter: e.checked}} })} />
                                                        <label htmlFor="ckShowFilter">Show Filter?</label>
                                                    </div>
                                                    <div className="p-col-12">
                                                        <Checkbox inputId="ckShowSearch" checked={this.state.data.itemTemplate.showSearch} onChange={(e) => this.setState({data: {...this.state.data, itemTemplate: {...this.state.data.itemTemplate, showSearch: e.checked}} })} />
                                                        <label htmlFor="ckShowSearch">Show Search?</label>
                                                    </div>
                                                </div>
                                            </Fieldset>
                                        </div>
                                    </React.Fragment>
                                default: break;
                            }
                        }
                    })()}
                </div>

                <div className="p-sidebar-line p-my-3"></div>

                <div className="p-grid">
                    <div className="p-col-12 p-d-flex p-jc-between">
                        <Button label="Cancel" icon="pi pi-times" className="p-button-secondary" onClick={() => this.onHide()}/>
                        <Button label="Submit" icon="pi pi-check"  onClick={() => this.handleSave()}/>
                    </div>
                </div>
            </Sidebar>
        );
    }
}
