import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'primereact/button';
import './ErrorPage.css';
import { withNamespaces, Trans } from 'react-i18next';

export const ErrorPage = (props) => {
    const { t } = props;
    const trans = (path) => t(path, { framework: "react-i18next" });
    return (
        <div className="exception-body error-body">
            <div className="exception-container">
                <Trans i18nKey="error_page.error">
                    <h1 className="exception-title">Error</h1>
                </Trans>
                <Trans i18nKey="error_page.error_message">
                    <p>
                        An error has occured! Please contact the system administrator.
                    </p>
                </Trans>
                <Link to="/">
                    <Button label={trans("error_page.go_to_dashboard")} icon="pi pi-arrow-left" />
                </Link>
            </div>
        </div>
    );
}

export default withNamespaces('message')(ErrorPage);