import axios from "axios";
import {PAYMENT_BASE_URL} from "../../../constants";

const PAYMENT_TRANSACTION_URL = PAYMENT_BASE_URL + "/transactions"

export function getPageTransaction(filter, page, size, sortField, sortOrder){
    const order = sortOrder===0 ? 'asc' : sortOrder===-1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : ''
    return axios.get(`${PAYMENT_TRANSACTION_URL}/payments/page`,{
        params: {...filter, page, size, sort}
    }).then(res => res.data).catch(error => console.log(error));
}

export function getTransaction(id){
    return axios.get(`${PAYMENT_TRANSACTION_URL}/payments/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function saveTransactionStatus(id, status, params){
    return axios.post(`${PAYMENT_TRANSACTION_URL}/payments/${id}/${status}`, params)
    .then(res => res.data).catch(error => console.log(error));
}

export function getInvoiceTransactions(invoiceId){
    return axios.get(`${PAYMENT_TRANSACTION_URL}/payments/list-by-invoice/${invoiceId}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getListOrderByTransaction(transId){
    return axios.get(`${PAYMENT_TRANSACTION_URL}/payments/orders`, {params: {id: transId}})
    .then(res => res.data).catch(error => console.log(error));
}

export function getListTransactions(filter){
    return axios.post(`${PAYMENT_TRANSACTION_URL}/list`, filter)
    .then(res => res.data).catch(error => console.log(error));
}