import React, {Component} from 'react';
import {MembershipTemplateServices} from "./MembershipTemplateServices";

import {TabView,TabPanel} from 'primereact/tabview';
import {Dialog} from 'primereact/dialog';
import {Fieldset} from 'primereact/fieldset';
import {Spinner} from 'primereact/spinner';
import {Button} from "primereact/button";
import {Dropdown} from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';
import { showNotification } from '../../core/service/NotificationService';
import { Checkbox } from 'primereact/checkbox';

export class MembershipTemplateRuleForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            templateLevel: {},
            rule: {},
            errors: {}
        }

        this.membershipTemplateServices = new MembershipTemplateServices();
    }

    popularFormData = (parent, data) => {
        this.setState({
            templateLevel: parent,
            rule: {
                id: data?data.id:'',
                personType: data?data.personType:'adult',
                quantity: data?data.quantity:(parent.type==='Individual'?1:0),
                minAge: data?data.minAge:0,
                maxAge: data?data.maxAge:0,
                required: data?data.required:true,
                requireAtLeast: data?data.requireAtLeast:''
            },
            errors: {}
        });

    }

    resetFormData = () => {
        this.popularFormData(this.state.templateLevel, null);
    }

    handleSaveMembershipRule = () => {
        this.membershipTemplateServices.saveMembershipTemplateRule(this.state.templateLevel.id, this.state.rule).then(res => {
            if(!res.errorCode){
                this.resetFormData();
                this.props.reloadTable(res);
                this.props.hideFormDialog();
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        });
    }

    onHide = () =>{
        this.props.hideFormDialog();
        this.setState({
            errors: {}
        });
    }

    render(){
        const personTypes = [
            {value:"adult", label:"Adult"},
            {value:"student", label:"Student"},
            {value:"teen", label:"Teen"},
            {value:"youth", label:"Youth"},
            {value:"child", label:"Child"},
            {value:"infant", label:"Infant"},
            {value:"senior", label:"Senior"}
        ];

        return (
            <Sidebar visible={this.props.visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.props.hideFormDialog}>
                <h2>{this.props.header}</h2>

                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <label className="p-label">Person Type</label>
                                <Dropdown id="personType" value={this.state.rule.personType} options={personTypes} onChange={(e) => this.setState({rule: {...this.state.rule, personType:e.value}})} style={{width:'100%'}} placeholder="Select a Person Type"/>
                            </div>
                            {this.state.templateLevel.type==='Family'?
                                <div className="p-col-12">
                                    <label className="p-label" htmlFor="quantity">Quantity (0 as unlimited)</label>
                                    <Spinner id="quantity" value={this.state.rule.quantity} step={1} onChange={(e) => this.setState({rule: {...this.state.rule, quantity: e.target.value}})} />
                                    <div className="p-form-error">{this.state.errors.quantity} </div>
                                </div>
                                :''
                            }
                            <div className="p-col-12">
                                <Fieldset legend="Age require">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-6">
                                            <label>Min Age</label>
                                            <Spinner value={this.state.rule.minAge} min="0" onChange={(e) => this.setState({rule: {...this.state.rule, minAge: e.target.value}})}/>
                                            <div className="p-form-error">{this.state.errors.minAge} </div>
                                        </div>
                                        <div className="p-col-6">
                                            <label>Max Age (0 as unlimited)</label>
                                            <Spinner value={this.state.rule.maxAge} min="0" onChange={(e) => this.setState({rule: {...this.state.rule, maxAge: e.target.value}})}/>
                                            <div className="p-form-error">{this.state.errors.maxAge} </div>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            {this.state.templateLevel.type==='Family'?
                                <React.Fragment>
                                    <div className="p-col-12">
                                        <Checkbox inputId="cbRuleRequired" checked={this.state.rule.required} onChange={(e) => this.setState({rule: { ...this.state.rule, required: e.checked}})} />
                                        <label htmlFor="cbRuleRequired" className="p-checkbox-label">Require select member?</label>
                                    </div>
                                    {this.state.rule.required?
                                        <div className="p-col-12">
                                            <label className="p-label" htmlFor="quantity">Require at least (leave blank to require all slot member)</label>
                                            <Spinner value={this.state.rule.requireAtLeast} step={1} max={this.state.rule.quantity} onChange={(e) => this.setState({rule: {...this.state.rule, requireAtLeast: e.target.value}})} />
                                            <div className="p-form-error">{this.state.errors.requireAtLeast} </div>
                                        </div>
                                    :''}
                                </React.Fragment>
                                :''
                            }
                        </div>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Submit" icon="pi pi-check" onClick={this.handleSaveMembershipRule}/>
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                    </div>
                </div>
            </Sidebar>
        );
    }
}