import axios from 'axios';
import { API_BASE_URL, MOCK_BASE_URL} from "../../constants";
import querystring from "querystring";
import { Component } from 'react';

export default class MembershipService extends Component {

    getPageMembership(isMockData = true, filter, page, size, sortField, sortOrder) {
        if(isMockData) {
            return axios.get(`${MOCK_BASE_URL}/scp/api/membership/page`)
            .then(res => {
                return res.data
            }).catch(error => console.log(error));
        } else {
            const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
            const sort = sortField ? `${sortField},${order}` : '';
    
            if(filter != null){
                return axios.get(`${API_BASE_URL}/scp/api/memberships/pageWithNewFilter`, {
                    params: { ...filter, page, size, sort }
                }).then(res => {
                    return res.data
                }).catch(error => console.log(error));
            }else {
                return axios.get(`${API_BASE_URL}/scp/api/memberships/page`, {
                    params: {page, size, sort }
                }).then(res => {
                    return res.data
                }).catch(error => console.log(error));
            }
        }
    }

    saveMembership(form) {
        return axios.post(`${API_BASE_URL}/scp/api/memberships`, form
        ).then(res => res.data)
    }

    checkDocumentNumberDupplicate(isMockData = true, id){
        if(isMockData) {
            return axios.get(`${MOCK_BASE_URL}/scp/api/membership/check-duplicate/${id}`)
            .then(res => {
                return res.data.isDuplicate
            }).catch(error => console.log(error));
        } else {
            return axios.get(`${API_BASE_URL}/scp/api/memberships/check-duplicate/${id}`)
            .then(res => {return res.data.isDuplicate})
            .catch(error => console.log(error));
        }
        
    }

    updateMembershipDocumentNumber(id, docNum, isMockData = true){
        if (isMockData) {
            return axios.put(`${MOCK_BASE_URL}/scp/api/membership/receive-payment/verify/${id}`, {docNumber: docNum})
                .then(res => {
                    return res.data
                }).catch(error => console.log(error));
        } else {
            return axios.put(`${API_BASE_URL}/scp/api/memberships/receive-payment/verify/${id}`, {docNumber: docNum})
                .then(res => { 
                    return res;
                })
                .catch(error => console.log(error));
        }
    }

    updateMembershipDocumentStatus(id, status = "paid", isMockData = true) {
        if (isMockData) {
            return axios.put(`${MOCK_BASE_URL}/scp/api/membership/receive-payment/payment-status/${id}`, { paymentStatus: status })
                .then(res => {
                    return res.data
                }).catch(error => console.log(error));
        } else {
            return axios.put(`${API_BASE_URL}/scp/api/memberships/receive-payment/payment-status/${id}`, { paymentStatus: status })
                .then(res => {
                    return res;
                })
                .catch(error => console.log(error));
        }
    }

    updateMembershipVerifyStatus(id, status = true, isMockData = true) {
        if (isMockData) {
            return axios.put(`${MOCK_BASE_URL}/scp/api/membership/receive-payment/update-verify/${id}`, { verifyStatus: status })
                .then(res => {
                    return res.data
                }).catch(error => console.log(error));
        } else {
            return axios.put(`${API_BASE_URL}/scp/api/memberships/receive-payment/update-verify/${id}`, { verifyStatus: status })
                .then(res => {
                    return res;
                })
                .catch(error => console.log(error));
        }
    }
}


