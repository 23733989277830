import React, { useState, useEffect, useRef } from "react";
import {DataTable} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from "../../core/service/NotificationService";
import { useRouteMatch, useHistory } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";
import { getPageMembershipMembers, removeMembershipMember, changeMembershipCardStatus } from "./MembershipServices";
import classNames from "classnames";
import { MembershipMemberForm } from "./MembershipMemberForm";
import { showloading, stoploading } from "../../core/service/LoadingService";
import { MEMBERSHIP_CARD_STATUS } from "../../constants";
import { MembershipCardForm } from "./MembershipCardForm";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";

export const MembershipMemberListView = (props) => {
    const match = useRouteMatch()
    const history = useHistory()

    const [dataList, setDataList] = useState([])
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: 'id',
        sortOrder: -1
    })
    const [filter, setFilter] = useState({membershipIds: props.membershipId?[props.membershipId]:[]})
    const [isLoadTable, setLoadTable] = useState(true);
    const frmMembershipMember = useRef(null)
    const frmMembershipCard = useRef(null)

    const isViewOnly = props.isViewOnly ? true : false

    useEffect(()=>{
        if(isLoadTable)
            loadPageMembershipMembers()
    },[isLoadTable])

    const loadPageMembershipMembers = () => {
        getPageMembershipMembers(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const deleteMember = (id) => {
        showConfirmNotify({
            accept: () => handleRemoveMember(id)
        })
    }

    const handleRemoveMember = (id) => {
        showloading()

        removeMembershipMember(id)
        .then(res => {
            if (!res.errorCode) {
                setLoadTable(true)
                showSuccessNotify('Successfully Deleted');
            } else {
                showErrorNotify('Cannot perform action');
            }

            stoploading()
        });
    }

    const changeMemberCardStatus = (id, status) => {
        showConfirmNotify({
            accept: () => handleChangeCardStatus(id, status)
        })
    }

    const handleChangeCardStatus = (id, status) => {
        showloading()

        changeMembershipCardStatus(id, status)
        .then(res => {
            if (!res.errorCode) {
                setLoadTable(true)
                showSuccessNotify('Action submitted!');
            } else {
                showErrorNotify('Cannot perform action');
            }

            stoploading()
        });
    }

    const onAddOrEditMembershipCard = (memberId, activeCard) => {
        frmMembershipCard.current.openForm(props.membershipId, memberId, activeCard)
    }

    const cardTemplate = (rowData) => {
        const activeCard = rowData.activeCard

        if(activeCard){
            return (
                <React.Fragment>
                    <div className="p-mb-1">Code: {activeCard.code}</div>
                    <div className="p-mb-1">Number: {activeCard.number}</div>
                    <div className="p-mb-2">Status: <span className="status">{MEMBERSHIP_CARD_STATUS[activeCard.status].label}</span></div>
                    {(()=>{
                        if(!isViewOnly){
                            return (
                                activeCard.status!==MEMBERSHIP_CARD_STATUS.cancel.value ?
                                <div className="p-mt-1">
                                    <Button icon="pi pi-pencil" label="Edit" tooltip="Edit Member Card" tooltipOptions={{position: 'top'}} className="p-button-info btn-text-sm p-mr-2" onClick={() => onAddOrEditMembershipCard(rowData.id, rowData.activeCard)}/>
                                    <Button icon="pi pi-times" label="Cancel" tooltip="Cancel Member Card" tooltipOptions={{position: 'top'}} className="p-button-danger btn-text-sm p-mr-2" onClick={() => changeMemberCardStatus(activeCard.id, MEMBERSHIP_CARD_STATUS.cancel.value)}/>
                                    {(()=>{
                                        if(activeCard.status===MEMBERSHIP_CARD_STATUS.not_printed.value)
                                            return (
                                                <Button icon="pi pi-print" label="Print" tooltip="Print Member Card" tooltipOptions={{position: 'top'}} className="p-button-warning btn-text-sm" onClick={() => changeMemberCardStatus(activeCard.id, MEMBERSHIP_CARD_STATUS.printed.value)}/>
                                            )
                                        else if(activeCard.status===MEMBERSHIP_CARD_STATUS.printed.value)
                                            return (
                                                <Button icon="pi pi-send" label="Assign" tooltip="Assign Member Card" tooltipOptions={{position: 'top'}} className="p-button-warning btn-text-sm" onClick={() => changeMemberCardStatus(activeCard.id, MEMBERSHIP_CARD_STATUS.assigned.value)}/>
                                            )
                                    })()}
                                    
                                </div>    
                                :
                                <div className="p-mt-1">
                                    <Button icon="pi pi-plus" label="Create another Card" tooltip="Create another Card" tooltipOptions={{position: 'top'}} className="p-button-info btn-text-sm" onClick={() => onAddOrEditMembershipCard(rowData.id, null)}/>
                                </div> 
                            )
                        }
                    })()}
                    
                </React.Fragment>
            )
        }else{
            if(!isViewOnly)
                return (
                    <div>
                        <Button icon="pi pi-plus" label="Create Member Card" tooltip="Create Member Card" tooltipOptions={{position: 'top'}} className="p-button-info btn-text-sm" onClick={() => onAddOrEditMembershipCard(rowData.id, null)}/>
                    </div>
                )
        }
        
    }

    const tblHeader = (
        <TableHeader title={!props.membershipId?'Membership Members':'Members'}
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={!isViewOnly &&
                <Button className="p-button-success" label="Add Member" icon="pi-md-plus" onClick={() => frmMembershipMember.current.openForm(props.membershipId)}/>
            }
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    );

    const clearFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setFilter({...filter,
            membershipName: '',
            memberName: '',
        })
        setLoadTable(true)
    }

    return (
        <div className="p-grid">
            {!props.membershipId &&
                <BreadcrumbsItem to={match.url}>Memberships</BreadcrumbsItem>
            }

            <MembershipMemberForm ref={frmMembershipMember}
                refreshTable={() => setLoadTable(true)}
            />

            <MembershipCardForm ref={frmMembershipCard}
                refreshTable={() => setLoadTable(true)}
            />

            <div className="p-col-12">
                <div className="card card-w-title">
                {!props.membershipId && 
                    <h1 className="p-w-bold p-m-0">Membership Members</h1>
                }
                    <Fieldset legend="Filters">
                        <div className="p-grid p-fluid">
                            {!props.membershipId && 
                                <div className="p-col-12 p-md-6">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">Membership Category:</span>
                                        <InputText value={filter.membershipName} onChange={e => setFilter({...filter, membershipName: e.target.value})} />
                                    </div>
                                </div>
                            }
                            <div className="p-col-12 p-md-6">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">Member Name:</span>
                                    <InputText value={filter.memberName} onChange={e => setFilter({...filter, memberName: e.target.value})} />
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => loadPageMembershipMembers()} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={() => clearFilter()} />
                            </div>
                        </div>
                    </Fieldset>
                </div>

                <DataTable emptyMessage="There's no record found" header={tblHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column header="ID" field="id" bodyClassName="p-c" style={{width:'10em'}} sortable={true}/>
                    <Column header="Name" field="user.name"/>
                    <Column header="Email" field="user.email"/>
                    <Column header="Phone" field="user.phone"/>
                    <Column header="Card" body={cardTemplate}/>
                    {!isViewOnly &&
                    <Column style={{textAlign: 'right', width: '5em'}} bodyClassName="tc-actions p-c"
                        body={(rowData) => {
                            return(
                                <React.Fragment>
                                    {!rowData.owner && <Button icon="pi pi-trash" tooltip="Remove member" tooltipOptions={{position: 'top'}} className="p-button-danger" onClick={() => deleteMember(rowData.id)}/>}
                                </React.Fragment>
                            )
                        }}
                    />
                    }
                    
                </DataTable>
            </div>
        </div>
    )
}
