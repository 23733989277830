import axios from 'axios';
import { API_BASE_URL } from '../../constants';

export class ProgramServices {
    getPagePrograms(filter, page, size, sortField, sortOrder) {
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/programs/page`, {
            params: {...filter, page, size, sort }
        }).then(res => res.data).catch(error => console.log(error));
    }

    getListAllPrograms(search){
        return axios.get(`${API_BASE_URL}/scp/api/programs/list`, {params: {search: search}}).then(res => res.data).catch(error => console.log(error));
    }

    getProgram(id, type) {
        return axios.get(`${API_BASE_URL}/scp/api/programs/${id}/${type}`).then(res => res.data).catch(error => console.log(error));
    }
    
    saveProgram(form){
        return axios.post(`${API_BASE_URL}/scp/api/programs`, form).then(res => res.data)
    }

    saveProgramWizard(form){
        return axios.post(`${API_BASE_URL}/scp/api/programs/wizard`, form).then(res => res.data)
    }

    saveNotificationSettings(form){
        return axios.post(`${API_BASE_URL}/scp/api/programs/save-notification-settings`, form).then(res => res.data)
    }

    acquireFormLock(formKey, id){
        return axios.get(`${API_BASE_URL}/scp/api/form/lock/${formKey}/${id}`).then(res => res.data);
    }

    saveBasicInformation(form){
        return axios.post(`${API_BASE_URL}/scp/api/programs/save-basic-information`, form).then(res => res.data);
    }

    saveScheduleSettings(form){
        return axios.post(`${API_BASE_URL}/scp/api/programs/save-schedule-settings`, form).then(res => res.data);
    }

    savePriceSettings(form){
        return axios.post(`${API_BASE_URL}/scp/api/programs/save-price-settings`, form).then(res => res.data)
    }

    saveRegistrationSettings(form){
        return axios.post(`${API_BASE_URL}/scp/api/programs/save-registration-settings`, form).then(res => res.data)
    }

    saveTalents(form){
        return axios.post(`${API_BASE_URL}/scp/api/programs/save-talents`, form).then(res => res.data)
    }

    saveAttributeSettings(id, attributes){
        let queryString = require('querystring');
        return axios.post(`${API_BASE_URL}/scp/api/programs/save-attribute-settings`, 
            queryString.stringify({
                id: id,
                attributes: JSON.stringify(attributes)
            })
        ).then(res => res.data).catch(error => console.log(error));
    }

    saveCourseDataTemplateSettings(form){
        return axios.post(`${API_BASE_URL}/scp/api/programs/save-course-data-template`, form).then(res => res.data)
    }

    getPageProgramCourses(programId, filter, page, size, sortField, sortOrder) {
        let qs = require('qs');
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        // return axios.get(`${API_BASE_URL}/scp/api/programs/${programId}/courses/page`, {
        //     params: {...filter, page, size, sort }
        // }).then(res => res.data).catch(error => console.log(error));
        return axios.get(`${API_BASE_URL}/scp/api/programs/${programId}/courses/page?` + qs.stringify({...filter, page, size, sort })).then(res => res.data).catch(error => console.log(error));
    }

    getListProgramCourses(progId, campaignId){
        return axios.get(`${API_BASE_URL}/scp/api/programs/${progId}/courses/list`, {params: {campaignId: campaignId}}).then(res => res.data).catch(error => console.log(error));
    }

    getListAllCourses(){
        return axios.get(`${API_BASE_URL}/scp/api/programs/courses/list`).then(res => res.data).catch(error => console.log(error));
    }

    getListCourseProducts(){
        return axios.get(`${API_BASE_URL}/scp/api/programs/course_products/list`).then(res => res.data).catch(error => console.log(error));
    }

    getTalentsLevels = (id) => {
        return axios.get(`${API_BASE_URL}/scp/api/programs/${id}/talents/levels`).then(res => res.data).catch(error => console.log(error));
    }

    getTalentsLevelsOfCourse = (pid, cid) => {
        return axios.get(`${API_BASE_URL}/scp/api/programs/${pid}/courses/${cid}/talents`).then(res => res.data).catch(error => console.log(error));
    }

    updateStatusProgram(id, status){
        let queryString = require("querystring");
        return axios.post(`${API_BASE_URL}/scp/api/programs/${id}/update_status`,
            queryString.stringify({
                status: status
            })
        ).then(res => res.data).catch(error => console.log(error));
    }

    updateStatusCourses(id, coursesIds, status){
        let queryString = require("querystring");
        return axios.post(`${API_BASE_URL}/scp/api/programs/${id}/courses/update_status`,
            queryString.stringify({
                courses: coursesIds,
                status: status
            })
        ).then(res => res.data).catch(error => console.log(error));
    }

    removeProgram(id){
        return axios.delete(`${API_BASE_URL}/scp/api/programs/${id}`).then(res => res.data).catch(error => console.log(error));
    }

    cloneProgram(form){
        return axios.post(`${API_BASE_URL}/scp/api/programs/clone`, form).then(res => res.data);
    }

    copyProgram(form){
        return axios.post(`${API_BASE_URL}/scp/api/programs/copy`, form).then(res => res.data);
    }

    getUserFindTrackingProgram(data){
        return axios.post(`${API_BASE_URL}/scp/api/programs/usage-tracking`, data).then(res => res.data).catch(error => console.log(error));
    }

    getTreePrograms(campaignId) {
        return axios.get(`${API_BASE_URL}/scp/api/programs/tree`, {params: {campaign: campaignId}}).then(res => res.data).catch(error => console.log(error));
    }

    getCalendars(){
        return axios.get(`${API_BASE_URL}/scp/api/simple-calendars/list`).then(res => res.data).catch(error => console.log(error));
    }

    getProgramToCancel(programId){
        return axios.get(`${API_BASE_URL}/scp/api/programs/cancel`, {params: {id: programId}}).then(res => res.data).catch(error => console.log(error));
    }

    cancelProgram(programId){
        return axios.post(`${API_BASE_URL}/scp/api/programs/cancel`, null, {params: {id: programId}}).then(res => res.data).catch(error => console.log(error));
    }
}
