export function parameterizeBody(body, dataObj) {
    return Object.keys(body).map(key => parameterizeValue(key, body[key], dataObj))
        .reduce((body, pair) => {
            return { ...body, ...pair };
        }, {});
}

function parameterizeValue(key, value, dataObj) {
    for (let key of Object.keys(dataObj)) {
        if (typeof value === 'object') {
            value = parameterizeBody(value, dataObj);
            return { [key]: value };
        } else if (value === `{{${key}}}`) {
            value = dataObj[key];
            return { [key]: value };
        } else if (typeof value === 'string' && value.includes(`{{${key}}}`)) {
            const pattern = new RegExp(`{{${key}}}`, 'g');
            value = value.replace(pattern, dataObj[key]);
        }
    }
    return { [key]: value };
}

export function parameterizeUrl(url, dataObj) {
    for (let key of Object.keys(dataObj)) {
        const pattern = new RegExp(`{{${key}}}`, 'g');
        url = url.replace(pattern, dataObj[key]);
    }
    return url;
}

export function flattenObject(ob) {
	let toReturn = {};
	for (let i in ob) {
		if (!ob.hasOwnProperty(i)) continue;
		
		if ((typeof ob[i]) == 'object') {
			let flatObject = flattenObject(ob[i]);
			for (let x in flatObject) {
				if (!flatObject.hasOwnProperty(x)) continue;
				
				toReturn[i + '.' + x] = flatObject[x];
			}
		} else {
			toReturn[i] = ob[i];
		}
	}
	return toReturn;
}