import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useRouteMatch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import moment from 'moment';
import { DATETIME_FORMAT_DISPLAY, OBJECT_TYPE } from '../constants';
import { pimExportListChangeLog, pimGetPageChangeLogs, scpExportAllChangeLog, scpExportListChangeLog, scpGetListChangeLogs } from './AuditLogServices';
import { TableHeader } from '../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../core/service/CommonService';
import { showloading, stoploading } from '../core/service/LoadingService';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import { MaskedCalendar } from '../core/components/calendar/MaskedCalendar';
import fileDownload from 'js-file-download';
import { showErrorNotify } from '../core/service/NotificationService';
import { SplitButton } from 'primereact/splitbutton';
import { AuditLogPopup } from './AuditLogPopup';

export const AuditLogListView = (props) => {
	const match = useRouteMatch();

	const [type, setType] = useState(props.type);
	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
	});
	const [filter, setFilter] = useState({});
	const [isLoadTable, setLoadTable] = useState(true);
	const [dateFromValue, setDateFromValue] = useState(null);
	const [minDate, setMinDate] = useState(null);
	const [dateToValue, setDateToValue] = useState(null);
	const [maxDate, setMaxDate] = useState(null);

	const auditLogPopup = useRef(null);

	useEffect(() => {
		setType(props.type);
		setLoadTable(true);
	}, [props.type]);

	useEffect(() => {
		if (isLoadTable) {
			loadListLogs();
		}
	}, [isLoadTable]);

	const loadListLogs = () => {
		let tmpFilter = { ...filter, isCourseProgram: props.extra1 };

		scpGetListChangeLogs(type, tmpFilter)
			.then((res) => {
				setDataList(res);
			})
			.finally(() => {
				setLoadTable(false);
			});
	};

	const handleExport = async () => {
		if (!dataList || dataList.length === 0) {
			showErrorNotify('No data!');
			return;
		}

		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName;
		let tmpFilter = { ...filter, isCourseProgram: props.extra1 };

		switch (type) {
			case OBJECT_TYPE.contact:
				fileName = 'rpt_contact_log';
				break;
			case OBJECT_TYPE.membership:
				fileName = 'rpt_membership_log';
				break;
			case OBJECT_TYPE.participant:
				if (props.extra1) {
					fileName = 'rpt_enrollment_log';
				} else {
					fileName = 'rpt_participant_log';
				}
				break;
			case OBJECT_TYPE.participant_attendance:
				fileName = 'rpt_attendance_log';
				break;
			case OBJECT_TYPE.product:
				if (props.extra1) {
					fileName = 'rpt_training_log';
				} else {
					fileName = 'rpt_product_log';
				}
				break;
			case OBJECT_TYPE.product_session:
				fileName = 'rpt_session_log';
				break;
			default:
				break;
		}

		fileName = fileName + '_' + suf + '.xlsx';

		showloading();

		scpExportListChangeLog(type, tmpFilter)
			.then((res) => {
				if (!res.errorCode) {
					fileDownload(res, fileName);
				} else {
					showErrorNotify(res.errorMessage);
				}
			})
			.finally(() => {
				stoploading();
			});
	};

	const handleExportAll = async () => {
		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = 'rpt_logs_' + suf + '.xlsx';

		let tmpFilter = { ...filter, isCourseProgram: props.extra1 };

		showloading();

		scpExportAllChangeLog(tmpFilter)
			.then((res) => {
				if (!res.errorCode) {
					fileDownload(res, fileName);
				} else {
					showErrorNotify(res.errorMessage);
				}
			})
			.finally(() => {
				stoploading();
			});
	};

	const onPage = (e) => {
		setPageable({
			...pageable,
			page: e.page,
		});
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
	};

	const onDateFromChange = (e) => {
		let tmpMaxDate = maxDate ? moment(maxDate) : '';
		let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
		let isCurrentValueAfterMaxDate = currentValue && tmpMaxDate && currentValue.isAfter(tmpMaxDate);

		setFilter({ ...filter, dateFrom: currentValue ? currentValue.format(moment.HTML5_FMT.DATE) : '', dateTo: !isCurrentValueAfterMaxDate ? filter.dateTo : '' });
		setDateFromValue(e.value);
		setMinDate(moment(e.value).isValid() ? e.value : null);
		setDateToValue(!isCurrentValueAfterMaxDate ? dateToValue : '');
		setMaxDate(!isCurrentValueAfterMaxDate ? maxDate : null);
	};

	const onDateToChange = (e) => {
		let tmpMinDate = minDate ? moment(minDate) : '';
		let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
		let isCurrentValueBeforeMinDate = currentValue && tmpMinDate && currentValue.isBefore(tmpMinDate);

		setFilter({ ...filter, dateFrom: !isCurrentValueBeforeMinDate ? filter.dateFrom : '', dateTo: currentValue ? currentValue.format(moment.HTML5_FMT.DATE) : '' });
		setDateFromValue(!isCurrentValueBeforeMinDate ? dateFromValue : '');
		setMinDate(!isCurrentValueBeforeMinDate ? minDate : null);
		setDateToValue(e.value);
		setMaxDate(moment(e.value).isValid() ? e.value : null);
	};

	const applyFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setLoadTable(true);
	};

	const resetFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setFilter({});
		setDateFromValue(null);
		setDateToValue(null);
		setMaxDate(null);
		setMinDate(null);
		setLoadTable(true);
	};

	const renderHeaderColumnObjectName = (
		<React.Fragment>
			{(() => {
				switch (type) {
					case OBJECT_TYPE.contact:
						return 'Contact';
					case OBJECT_TYPE.membership:
						return 'Membership';
					case OBJECT_TYPE.participant:
						return 'Participant';
					case OBJECT_TYPE.participant_attendance:
						return 'Trainee';
					case OBJECT_TYPE.product:
						if (props.extra1) {
							return 'Course';
						}
						return 'Product';
					case OBJECT_TYPE.product_session:
						return 'Session';
					default:
						return 'Object';
				}
			})()}
		</React.Fragment>
	);

	const renderHeader = (
		<React.Fragment>
			{(() => {
				switch (type) {
					case OBJECT_TYPE.contact:
						return 'Contact';
					case OBJECT_TYPE.membership:
						return 'Membership';
					case OBJECT_TYPE.participant:
						return 'Enrollment';
					case OBJECT_TYPE.participant_attendance:
						return 'Attendance';
					case OBJECT_TYPE.product:
						if (props.extra1) {
							return 'Training';
						}
						return 'Product';
					case OBJECT_TYPE.product_session:
						if (props.extra1) {
							return 'Session';
						}
						return 'Availability';
					default:
						return 'Object';
				}
			})()}
		</React.Fragment>
	);

	const tblHeader = (
		<TableHeader
			title={<span>{renderHeader} Logs</span>}
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			actionTemplate={
				<React.Fragment>
					{(() => {
						let items = [{ label: 'Export All', icon: 'pi pi-download', command: () => handleExportAll() }];
						return <SplitButton className='p-button-success' label='Export' icon='pi pi-download' model={items} onClick={() => handleExport()} />;
					})()}
				</React.Fragment>
			}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
		/>
	);

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>{renderHeader} Logs</BreadcrumbsItem>

			<AuditLogPopup ref={auditLogPopup} />

			<div className='p-col-12'>
				<Fieldset legend='Filters'>
					<div className='p-grid p-fluid'>
						<div className='p-col-12 p-md-6'>
							<div className='p-inputgroup'>
								<span className='p-inputgroup-addon'>Date From:</span>
								<MaskedCalendar value={dateFromValue} maxDate={maxDate} onChange={(e) => onDateFromChange(e)} showIcon={true} dateFormat='dd/mm/yy' />
							</div>
						</div>
						<div className='p-col-12 p-md-6'>
							<div className='p-inputgroup'>
								<span className='p-inputgroup-addon'>Date To:</span>
								<MaskedCalendar value={dateToValue} minDate={minDate} onChange={(e) => onDateToChange(e)} showIcon={true} dateFormat='dd/mm/yy' />
							</div>
						</div>
					</div>
					<div className='p-grid p-margin-top-10'>
						<div className='p-col-12 p-c'>
							<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={applyFilter} />
							<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' onClick={resetFilter} />
						</div>
					</div>
				</Fieldset>
			</div>
			<div className='p-col-12'>
				<DataTable
					emptyMessage="There's no record found"
					header={tblHeader}
					value={dataList}
					loading={isLoadTable}
					paginator={true}
					rows={pageable.rows}
					responsive={true}
					first={pageable.page * pageable.rows}
					onPage={onPage}
					onSort={onSort}
				>
					<Column header='ID' headerStyle={{ width: '12em' }} body={(rowData) => rowData.objectId} />
					<Column header={renderHeaderColumnObjectName} body={(rowData) => rowData?.objectName} />
					<Column header='Author' body={(rowData) => rowData.author?.name} />
					<Column header='Action Type' field='typeName' headerStyle={{ width: '10em' }} />
					<Column header='Commit On' headerStyle={{ width: '15em' }} body={(rowData) => rowData.commitOn && moment(rowData.commitOn, 'YYYY-MM-DD HH:mm').format(DATETIME_FORMAT_DISPLAY)} />
					<Column
						headerStyle={{ width: '10em' }}
						body={(rowData) => {
							if (rowData.typeName === 'UPDATE')
								return <Button label='View' icon='pi pi-search' onClick={() => auditLogPopup.current.openForm(rowData.objectId, rowData.objectType, rowData.commitId)} />;
						}}
					/>
				</DataTable>
			</div>
		</div>
	);
};
