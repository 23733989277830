import React, {Component} from 'react';
import { Sidebar } from 'primereact/sidebar';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { SelectButton } from "primereact/selectbutton";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showNotification } from '../../core/service/NotificationService';
import { ParticipantServices } from '../../constituent-management/participant/ParticipantServices';
import { ProgramServices } from './ProgramServices';
import { CampaignServices } from '../../scp/campaign/CampaignServices';
import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'primereact/spinner';

export class MoveParticipantToClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            participant: {},
            data: {
                programId: '',
                campaignId: '',
                courseId: '',
                moveType: 'current',
                otherType: 'waiting'
            },
            visible: false,
            formHeader: 'Move Participant To Class',
            errors: {}
        }
        this.participantServices = new ParticipantServices();
        this.programServices = new ProgramServices();
        this.campaignServices = new CampaignServices();
    }

    action = (participant) => {
        this.setState({
            participant: participant,
            data: {
                programId: '',
                campaignId: '',
                courseId: '',
                moveType: 'current',
                otherType: 'waiting'
            },
            visible: true,
            errors: {}
        })
    }

    componentDidMount(){
        this.loadAllPrograms();
        this.loadAllCampaigns();
    }

    handleSave = () => {
        this.setState({ errors: {} });
        showloading();
        this.participantServices.moveParticipantToClass(this.state.participant.id, this.state.data)
        .then(res => {
            stoploading();
            if(!res.errorCode){
                this.props.refreshTable();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        })
    }

    loadAllPrograms = () => {
        this.programServices.getListAllPrograms()
        .then(res => {
            this.setState({programs: res ? res : []})
        });
    }

    loadAllCampaigns = () => {
        this.campaignServices.getListCampaign().then(res => {this.setState({campaigns: res ? res : []})});
    }

    loadAllCoursesByProgram = () => {
        this.programServices.getListProgramCourses(this.state.data.programId, this.state.data.campaignId)
        .then(res =>{
            this.setState({courses: res ? res : []});
        });
    }

    onChangeProgram = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                programId: e.value
            }
        },() => {
            if(this.state.data.programId)
                this.loadAllCoursesByProgram();
        })
    }

    onChangeCampaign = (e) => {
        this.setState({
            data: {
                ...this.state.data,
                campaignId: e.value,
                courseId: ''
            }
        },() => {
            if(this.state.data.programId)
                this.loadAllCoursesByProgram();
        })
    }

    onHide = () => {
        this.setState({ visible: false })
    }

    render(){
        const optionButtons = [
            {label: 'Current class', value: 'current'},
            {label: 'Other class', value: 'other'}
        ];

        return (
            <Sidebar visible={this.state.visible} dismissable={false} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.onHide}>
                <h2>{this.state.formHeader}</h2>

                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12">
                        <label className="p-label">Name: {this.state.participant.name}</label>
                    </div>
                    <div className="p-col-12">
                        <SelectButton value={this.state.data.moveType} options={optionButtons} onChange={(e) => this.setState({data: {...this.state.data, moveType: e.value} })} />
                    </div>
                    {this.state.data.moveType === 'other' ? 
                        <React.Fragment>
                            <div className="p-col-12">
                                <label className="p-label">* To Program</label>
                                <Dropdown value={this.state.data.programId} options={this.state.programs} onChange={(e) => this.onChangeProgram(e)} style={{width: "100%"}} />
                                <div className="p-form-error">{this.state.errors.program}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Campaign</label>
                                <Dropdown value={this.state.data.campaignId} options={this.state.campaigns} onChange={(e) => this.onChangeCampaign(e)} style={{width: "100%"}} />
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* To Course</label>
                                <Dropdown value={this.state.data.courseId} options={this.state.courses} onChange={(e) => this.setState({data: {...this.state.data, courseId: e.value}})} style={{width: "100%"}} />
                                <div className="p-form-error">{this.state.errors.course}</div>
                            </div>
                            <div className="p-col-12">
                                <label>Move as:</label>
                                <RadioButton value="waiting" className="p-margin-left-20" onChange={(e) => this.setState({data: {...this.state.data, otherType: e.value}})} checked={this.state.data.otherType === 'waiting'} />
                                <label className="p-radiobutton-label">waiting</label>
                                <RadioButton value="actual" className="p-margin-left-20" onChange={(e) => this.setState({data: {...this.state.data, otherType: e.value}})} checked={this.state.data.otherType === 'actual'} />
                                <label className="p-radiobutton-label">actual</label>
                            </div>
                            {this.state.data.otherType === 'waiting' ? 
                                <div className="p-col-12">
                                    <label className="p-label">* Priority</label>
                                    <Spinner value={this.state.data.priority} onChange={(e) => this.setState({data: {...this.state.data, priority: e.value}})} />
                                    <div className="p-form-error">{this.state.errors.priority}</div>
                                </div>
                                : ''
                            }
                        </React.Fragment>
                        : ''
                    }
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handleSave()}/>
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                    </div>
                </div>
            </Sidebar>
        );
    }
}