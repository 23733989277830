import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { DATE_FORMAT_DISPLAY, OBJECT_TYPE, TIME_FORMAT_DISPLAY } from '../../constants';
import { getTablePageReport } from '../../core/service/CommonService';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { AttendanceForm } from './AttendanceForm';
import { getPageAttendances, removeAttendee } from './AttendanceService';
import { getListSchedules } from '../../pim/object-schedule/ScheduleServices';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { getProduct } from '../../pim/product/ProductServices';

export const AttendanceListViewForm = forwardRef((props, ref) => {
	const [header, setHeader] = useState('View Attendance');
	const [visible, setVisible] = useState(false);
	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'id',
		sortOrder: -1,
	});
	const [filter, setFilter] = useState({});
	const [participant, setParticipant] = useState(null);
	const [courseProduct, setCourseProduct] = useState(null);
	const [courseSessions, setCourseSessions] = useState([]);
	const [sessions, setSessions] = useState([]);
	const [schedules, setSchedules] = useState([]);

	const [isLoadTable, setLoadTable] = useState(false);

	const frmAttendance = useRef(null);

	useImperativeHandle(ref, () => ({
		async openForm(participant) {
			if (participant) {
				const course = await getProduct(participant.refId);

				let tmpSessions = [];

				if (course) {
					if (course?.availabilities.length > 0) {
						course.availabilities.forEach((s) => {
							tmpSessions.push(s);
						});
					}
					let tmpSchedules = [];
					let tmpSelectedSession = tmpSessions.find((s) => s.id === participant.availabilityId);

					if (tmpSelectedSession?.objSchedules.length > 0) {
						tmpSelectedSession.objSchedules.forEach((s) => {
							tmpSchedules.push(s);
						});
					}
					setSchedules(tmpSchedules.map((s) => ({ value: s.id, label: renderScheduleLabel(s), date: s.startDate })));

					setCourseProduct(course);
					setCourseSessions(tmpSessions);
					setSessions(tmpSessions.map((s) => ({ value: s.id, label: s.name })));
				}

				setParticipant(participant);

				setFilter({
					productIds: [participant.refId],
					userIds: participant?.user.id ? [participant.user.id] : null,
					sessionIds: [participant.availabilityId],
				});
				setLoadTable(true);
				setVisible(true);
			}
		},
		closeForm() {
			closeForm();
		},
	}));

	const onSessionChange = (val) => {
		let tmpSchedules = [];

		if (val) {
			const tmpSession = courseSessions.find((s) => s.id === val);

			if (tmpSession?.objSchedules.length > 0) {
				tmpSession.objSchedules.forEach((s) => {
					tmpSchedules.push(s);
				});
			}
		}

		setSchedules(tmpSchedules.map((s) => ({ value: s.id, label: renderScheduleLabel(s), date: s.startDate })));
		setFilter({
			...filter,
			sessionIds: val && [val],
			scheduleIds: null,
		});
		setLoadTable(true);
	};

	const onScheduleChange = (val) => {
		setFilter({ ...filter, scheduleIds: val && [val] });
		setLoadTable(true);
	};

	const renderScheduleLabel = (s) => {
		return (
			moment(s.startDate).format(DATE_FORMAT_DISPLAY) +
			(s.startDate !== s.endDate ? ' -> ' + moment(s.endDate).format(DATE_FORMAT_DISPLAY) : '') +
			' | ' +
			moment(s.startTime, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY) +
			' - ' +
			moment(s.endTime, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY)
		);
	};

	useEffect(() => {
		if (isLoadTable) {
			loadPageAttendences();
		}
	}, [isLoadTable]);

	const closeForm = () => {
		setVisible(false);
		setFilter({});
		setCourseProduct(null);
		setCourseSessions([]);
		setSessions([]);
		setSchedules([]);
	};

	const loadPageAttendences = () => {
		getPageAttendances(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				setDataList(res.content);
				setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
			})
			.catch((e) => showErrorNotify('System error!'))
			.finally(() => setLoadTable(false));
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const handleRemoveAttendee = (id) => {
		removeAttendee(id).then((res) => {
			if (!res.errorCode) {
				if (props.onRefresh) props.onRefresh();
				setLoadTable(true);
				showSuccessNotify('Action submitted!');
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	const onRemoveAttendee = (id) => {
		showConfirmNotify({
			accept: () => handleRemoveAttendee(id),
		});
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const onAddAttendance = () => {
		frmAttendance.current.openForm(courseProduct, filter.sessionIds?.length > 0 && filter.sessionIds[0], filter.scheduleIds?.length > 0 && filter.scheduleIds[0]);
	};

	const tblHeader = (
		<TableHeader
			title='Attendances'
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			actionTemplate={<Button className='p-button-success' label='Tracking Attendee' icon='pi-md-plus' onClick={() => onAddAttendance()} />}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
		/>
	);

	return (
		<React.Fragment>
			<AttendanceForm
				ref={frmAttendance}
				onRefresh={() => {
					setLoadTable(true);
					if (props.onRefresh) props.onRefresh();
				}}
				userId={participant?.user.id}
			/>

			<Sidebar visible={visible} style={{ overflowY: 'auto' }} className='p-sidebar-lg' position='right' blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
				<div className='p-d-flex p-justify-between '>
					<h2 className='p-margin-top-10'>{header}</h2>
					<Button label='' icon='pi pi-times' className='p-button-secondary' onClick={closeForm} />
				</div>

				<div className='p-sidebar-line p-mb-3'></div>

				<div className='p-grid'>
					<div className='p-col-12'>
						<Fieldset legend='Filter'>
							<div className='p-grid p-fluid form-group'>
								<div className='p-col-12'>Course: {participant?.refName}</div>
								<div className='p-col-12'>
									<div className='p-inputgroup'>
										<span className='p-inputgroup-addon'>Session:</span>
										<Dropdown
											value={filter.sessionIds?.length > 0 ? filter.sessionIds[0] : null}
											options={sessions}
											dataKey='value'
											showClear
											filter
											onChange={(e) => onSessionChange(e.value)}
											placeholder='All sessions'
										/>
									</div>
								</div>
								<div className='p-col-12'>
									<div className='p-inputgroup'>
										<span className='p-inputgroup-addon'>Schedule:</span>
										<Dropdown
											value={filter.scheduleIds?.length > 0 ? filter.scheduleIds[0] : null}
											options={schedules}
											dataKey='value'
											showClear
											filter
											onChange={(e) => onScheduleChange(e.value)}
											placeholder='All schedules'
										/>
									</div>
								</div>
							</div>
						</Fieldset>
					</div>

					<div className='p-col-12'>
						<DataTable
							emptyMessage="There's no record found"
							header={tblHeader}
							value={dataList}
							lazy={true}
							loading={isLoadTable}
							paginator={true}
							first={pageable.page * pageable.rows}
							rows={pageable.rows}
							totalRecords={pageable.total}
							sortField={pageable.sortField}
							sortOrder={pageable.sortOrder}
							onPage={onPage}
							onSort={onSort}
							responsive={true}
						>
							<Column header='ID' field='id' bodyClassName='p-c' style={{ width: '10em' }} sortable={true} />
							<Column field='date' header='Date' sortable={true} body={(rowData) => rowData.date && moment(rowData.date).format(DATE_FORMAT_DISPLAY)} />
							<Column field='timeIn' header='Time In' sortable={true} body={(rowData) => rowData.timeIn && moment(rowData.timeIn, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY)} />
							<Column field='timeOut' header='Time Out' sortable={true} body={(rowData) => rowData.timOut && moment(rowData.timeOut, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY)} />
							<Column
								bodyClassName='tc-actions p-r'
								headerStyle={{ width: '10em' }}
								body={(rowData) => <Button label='Remove' className='p-button-danger' icon='pi pi-trash' onClick={() => onRemoveAttendee(rowData.id)} />}
							/>
						</DataTable>
					</div>
				</div>

				<div className='p-sidebar-line p-my-3'></div>

				<div className='p-grid'>
					<div className='p-col-12 p-d-flex p-justify-between'>
						<Button label='Close' icon='pi-md-close' className='p-button-secondary' onClick={closeForm} />
					</div>
				</div>
			</Sidebar>
		</React.Fragment>
	);
});
