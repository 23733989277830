import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../../constants';

const TEMPLATE_BATCH_SERVICE_URL = API_BASE_URL + SCP_PATH + '/template-batch';

export function getTemplateBatch(objType, objId, isCourse) {
	return axios
		.get(`${TEMPLATE_BATCH_SERVICE_URL}`, { params: { objType, objId, isCourse } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function uploadTemplateBatch(objType, objId, isCourse, file) {
	const formData = new FormData();
	formData.append('file', file);

	return axios
		.post(`${TEMPLATE_BATCH_SERVICE_URL}/upload`, formData, { params: { objType, objId, isCourse }, headers: { 'Content-Type': 'multipart/form-data' } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function dowloadTemplateBatch(objType, objId, isCourse) {
	return axios
		.post(`${TEMPLATE_BATCH_SERVICE_URL}/download`, null, {
			params: { objType, objId, isCourse },
			responseType: 'arraybuffer',
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}
