import React, { forwardRef, useEffect, useImperativeHandle, useState} from "react";
import moment from 'moment';
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { getListTaxons } from "../../pim/taxon/TaxonServices";
import { APP_FEP, TAXONOMY_TYPE } from "../../constants";
import { Dropdown } from "primereact/dropdown";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { Checkbox } from "primereact/checkbox";

const weekday = {
    mon: {value: 'mon', label: 'Monday'},
    tue: {value: 'tue', label: 'Tuesday'},
    wed: {value: 'wed', label: 'Wednesday'},
    thu: {value: 'thu', label: 'Thursday'},
    fri: {value: 'fri', label: 'Friday'},
    sat: {value: 'sat', label: 'Saturday'},
    sun: {value: 'sun', label: 'Sunday'}
}

export const BranchOperatingHoursForm = forwardRef((props, ref) => {

    const [data, setData] = useState({weekdays: []})
    const [branchs, setBranchs] = useState([])
    const [branchId, setBranchId] = useState(null)
    const [dayOfWeek, setDayOfWeek] = useState(null)
    const [startTimeValue, setStartTimeValue] = useState(null)
    const [minTime, setMinTime] = useState(null)
    const [endTimeValue, setEndTimeValue] = useState(null)
    const [maxTime, setMaxTime] = useState(null)
    const [formHeader, setFormHeader] = useState('Add Tax')
    const [visible, setVisible] = useState(false)
    const [errors, setErrors] = useState({})

    useImperativeHandle(ref, ()=>({
        openForm(branchId, day, timeData){
            setData({
                branchId: branchId,
                weekdays: day?[day]:(!branchId?Object.keys(weekday):[]),
                start: timeData?timeData.s:null,
                end: timeData?timeData.e:null,
                overwrite: false
            })
            setBranchId(branchId)
            setDayOfWeek(day)
            setStartTimeValue(timeData?moment(timeData.s,moment.HTML5_FMT.TIME).toDate():null)
            setMinTime(timeData?moment(timeData.s,moment.HTML5_FMT.TIME).toDate():null)
            setEndTimeValue(timeData?moment(timeData.e,moment.HTML5_FMT.TIME).toDate():null)
            setMaxTime(timeData?moment(timeData.e,moment.HTML5_FMT.TIME).toDate():null)
            setFormHeader(timeData?'Edit Time':'Add Time')
            setVisible(true)
            setErrors({})
        },
        onError(errors){
            setErrors(errors)
        }
    }))

    useEffect(() => {
        loadBranchs()
    },[])

    const handelSave = () =>{
        setErrors({})
        let tmpErrors = {}
        if(!data.branchId){
            tmpErrors.branchId = 'Required';
        }
        if(!data.start){
            tmpErrors.start = 'Required';
        }else if(!moment(data.start, moment.HTML5_FMT.TIME).isValid()){
            tmpErrors.start = 'Invalid time';
        }
        if(!data.end){
            tmpErrors.end = 'Required';
        }else if(!moment(data.end, moment.HTML5_FMT.TIME).isValid()){
            tmpErrors.end = 'Invalid time';
        }
        if(!dayOfWeek && data.weekdays.length===0){
            tmpErrors.weekdays = 'Required';
        }
        if(Object.keys(tmpErrors).length>0){
            setErrors(tmpErrors)
            return
        }
        let flag = props.onSave(data);
        if(flag === false) return;
        onHide()
    }

    const loadBranchs = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.branch.value})
        .then(res => {
            setBranchs(res)
        })
    }

    const onStartTimeChange = (e) => {
        let tmpMaxTime = maxTime ? moment(maxTime) : '';
        let minTimeToCompare = moment(e.value).isValid() ? moment(e.value) : '';
        let flag = (minTimeToCompare && tmpMaxTime && minTimeToCompare.isAfter(tmpMaxTime));

        setData({...data,
            start: minTimeToCompare?minTimeToCompare.format('HH:mm'):'',
            end: !flag ? data.end : ''
        })
        setStartTimeValue(e.value)
        setMinTime(moment(e.value).isValid() ? e.value : '')
        setEndTimeValue(!flag ? endTimeValue : '')
        setMaxTime(!flag ? maxTime : '')
    }

    const onEndTimeChange = (e) => {
        let tmpMinTime = minTime ? moment(minTime) : '';
        let maxTimeToCompare = moment(e.value).isValid() ? moment(e.value) : '';
        let flag = (maxTimeToCompare && tmpMinTime && maxTimeToCompare.isBefore(tmpMinTime));

        setData({...data,
            start: !flag ? data.start : '',
            end: maxTimeToCompare?maxTimeToCompare.format('HH:mm'):''
        })
        setStartTimeValue(!flag ? startTimeValue : '')
        setMinTime(!flag ? minTime :'')
        setEndTimeValue(e.value)
        setMaxTime(moment(e.value).isValid() ? e.value : '')
    }

    const onWeekdayChange = (e) => {
        let weekdays = [...data.weekdays];
    
        if(e.checked) weekdays.push(e.value);
        else weekdays.splice(weekdays.indexOf(e.value), 1);
    
        setData({...data, weekdays})
    }

    const onHide = () => {
        setVisible(false)
    }

    return (
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} dismissable={false} showCloseIcon={false} onHide={()=>onHide()}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{formHeader}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={onHide}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>
            
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    {branchId ?
                        <label className="p-label">Branch: {branchs.find(b => b.value===branchId)&&branchs.find(b => b.value===branchId).label}</label>
                        :
                        <React.Fragment>
                            <label className="p-label">* Branch</label>
                            <Dropdown value={data.branchId} options={branchs} onChange={(e) => setData({...data, branchId: e.value})} />
                            <div className="p-form-error">{errors.branchId}</div>
                        </React.Fragment>
                    }
                </div>
                <div className="p-col-12">
                    <label className="p-label">{!dayOfWeek&&'*'} Day of week: {dayOfWeek && weekday[dayOfWeek].label}</label>
                    {!dayOfWeek &&
                            <div className="p-margin-top-10">
                                <Checkbox inputId="cbMon" value={weekday.mon.value} onChange={e => onWeekdayChange(e)} checked={data.weekdays.includes(weekday.mon.value)}></Checkbox>
                                <label htmlFor="cbMon" className="p-checkbox-label p-margin-right-15">Mon</label>
                                <Checkbox inputId="cbTue" value={weekday.tue.value} onChange={e => onWeekdayChange(e)} checked={data.weekdays.includes(weekday.tue.value)}></Checkbox>
                                <label htmlFor="cbTue" className="p-checkbox-label p-margin-right-15">Tue</label>
                                <Checkbox inputId="cbWed" value={weekday.wed.value} onChange={e => onWeekdayChange(e)} checked={data.weekdays.includes(weekday.wed.value)}></Checkbox>
                                <label htmlFor="cbWed" className="p-checkbox-label p-margin-right-15">Wed</label>
                                <Checkbox inputId="cbThu" value={weekday.thu.value} onChange={e => onWeekdayChange(e)} checked={data.weekdays.includes(weekday.thu.value)}></Checkbox>
                                <label htmlFor="cbThu" className="p-checkbox-label p-margin-right-15">Thu</label>
                                <Checkbox inputId="cbFri" value={weekday.fri.value} onChange={e => onWeekdayChange(e)} checked={data.weekdays.includes(weekday.fri.value)}></Checkbox>
                                <label htmlFor="cbFri" className="p-checkbox-label p-margin-right-15">Fri</label>
                                <Checkbox inputId="cbSat" value={weekday.sat.value} onChange={e => onWeekdayChange(e)} checked={data.weekdays.includes(weekday.sat.value)}></Checkbox>
                                <label htmlFor="cbSat" className="p-checkbox-label p-margin-right-15">Sat</label>
                                <Checkbox inputId="cbSun" value={weekday.sun.value} onChange={e => onWeekdayChange(e)} checked={data.weekdays.includes(weekday.sun.value)}></Checkbox>
                                <label htmlFor="cbSun" className="p-checkbox-label p-margin-right-15">Sun</label>
                            </div>  
                    }
                    <div className="p-form-error">{errors.weekdays}</div>
                </div>
                <div className="p-col-12 p-md-6">
                    <label className="p-label">* Start Time</label>
                    <MaskedCalendar value={startTimeValue} showIcon={true} showTime={true} onChange={e => onStartTimeChange(e)} hourFormat="12" timeOnly={true} />
                    <div className="p-form-error">{errors.start}</div>
                </div>
                <div className="p-col-12 p-md-6">
                    <label className="p-label">* End Time</label>
                    <MaskedCalendar value={endTimeValue} showIcon={true} showTime={true} onChange={e => onEndTimeChange(e)} hourFormat="12" timeOnly={true} />
                    <div className="p-form-error">{errors.end}</div>
                </div>
                {!dayOfWeek &&
                    <div className="p-col-12">
                        <Checkbox inputId="cbOverwrite" onChange={e => setData({...data, overwrite: e.checked})} checked={data.overwrite}></Checkbox>
                        <label htmlFor="cbOverwrite" className="p-checkbox-label p-margin-right-15">Overwrite by selected day(s)?</label>
                    </div>
                }
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={onHide}/>
                    <Button label="Submit" icon="pi pi-save" onClick={() => handelSave()}/>
                </div>
            </div>
        </Sidebar>
    )
}) 

