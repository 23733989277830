import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { Link, useRouteMatch } from 'react-router-dom';
import { SplitButton } from 'primereact/splitbutton';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport, isInValidDateTime } from '../../core/service/CommonService';
import {
	APP_FEP,
	CONDITION_APPLY_FOR_TYPE,
	CONDITION_APPLY_FOR_TYPES,
	DEFAULT_RULE_DISCOUNT,
	DEFAULT_RULE_FILTER,
	PROMOTION_COUPON_TYPE,
	PROMOTION_RULE_TYPE,
	PROMOTION_RULE_TYPES,
	PROMOTION_TYPE,
	RULE_CONDITION_TYPE,
	TAXONOMY_TYPE,
	PAYMENT_GATEWAY,
	PAYMENT_GATEWAYS,
	PRODUCT_TYPE,
} from '../../constants';
import {
	exportPromotionCoupon,
	getPagePromotionCoupons,
	getPagePromotionRules,
	getPagePromotions,
	getPromotion,
	removePromotionCoupon,
	removePromotionRule,
	savePromotionRule,
	savePromotionRulePriority,
} from './PromotionService';
import { PromotionForm } from './PromotionForm';
import { hasRole } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';
import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';
import classNames from 'classnames';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { RuleFilters } from './RuleFilters';
import RuleDiscounts from './RuleDiscounts';
import RuleConditions from './RuleConditions';
import { parseMessage2 } from '../../core/utils/TranslationUtils';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { getListTaxons } from '../taxon/TaxonServices';
import { getListProducts } from '../product/ProductServices';
import { PromotionCouponForm } from './PromotionCouponForm';
import { getListDepartments } from '../../scp/departments/DepartmentServices';
import { Chips } from 'primereact/chips';
import { GeneratingCouponForm } from './GeneratingCouponForm';
import fileDownload from 'js-file-download';
import { showloading, stoploading } from '../../core/service/LoadingService';

export const PromotionDetails = (props) => {
	const match = useRouteMatch();

	const [promotion, setPromotion] = useState({});
	const [dataListRule, setDataListRule] = useState([]);
	const [pageableRule, setPageableRule] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'priority',
		sortOrder: 1,
	});
	const [isLoadTableRule, setLoadTableRule] = useState(true);
	const [tabIndex, setTabIndex] = useState(0);
	const [products, setProducts] = useState([]);
	const [categories, setCategories] = useState([]);
	const [branches, setBranches] = useState([]);
	const [departments, setDeparments] = useState([]);
	const [memberships, setMemberships] = useState([]);
	const [frmData, setFrmData] = useState({});
	const [errors, setErrors] = useState({});

	const [dataListCoupon, setDataListCoupon] = useState([]);
	const [pageableCoupon, setPageableCoupon] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: '',
		sortOrder: 1,
	});
	const [isLoadTableCoupon, setLoadTableCouopn] = useState(true);

	const frmPromotion = useRef(null);
	const frmPromotionCoupon = useRef(null);
	const frmGenerateCoupon = useRef(null);

	useEffect(() => {
		loadPromotion();
		loadCategories();
		loadProducts();
		loadBranches();
		loadDepartments();
		loadMemberships();
	}, []);

	useEffect(() => {
		if (isLoadTableRule) loadPageRules();
	}, [isLoadTableRule]);

	useEffect(() => {
		if (isLoadTableCoupon) loadPageCoupons();
	}, [isLoadTableCoupon]);

	const loadPromotion = () => {
		getPromotion(props.match.params.id).then((res) => setPromotion(res ? res : {}));
	};

	const loadCategories = () => {
		getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.category.value }, true).then((res) => setCategories(res ? res : []));
	};

	const loadProducts = () => {
		let filter = {
			app: APP_FEP,
			types: [PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value],
		};
		getListProducts(filter, true).then((res) => setProducts(res ? res : []));
	};

	const loadBranches = () => {
		getListTaxons({ type: TAXONOMY_TYPE.branch.value }, true).then((res) => setBranches(res));
	};

	const loadDepartments = () => {
		getListDepartments({ active: true }).then((res) => setDeparments(res));
	};

	const loadMemberships = () => {
		getListProducts({ app: APP_FEP, types: [PRODUCT_TYPE.membership.value] }).then((res) => {
			setMemberships(res ? res : []);
		});
	};

	const isValidPromotion = () => {
		return promotion ? promotion.active && isInValidDateTime(promotion.startAt, promotion.endAt, true) : false;
	};

	//#region Promotion list
	const loadPageRules = () => {
		getPagePromotionRules(props.match.params.id, pageableRule.page, pageableRule.rows, pageableRule.sortField, pageableRule.sortOrder).then((res) => {
			setDataListRule(res.content);
			setPageableRule({
				...pageableRule,
				total: res.totalElements,
				page: res.pageable.pageNumber,
				rows: res.pageable.pageSize,
			});
			setLoadTableRule(false);
		});
	};

	const onPageRule = (e) => {
		setPageableRule({
			...pageableRule,
			page: e.page,
		});
		setLoadTableRule(true);
	};

	const onSortRule = (e) => {
		setPageableRule({
			...pageableRule,
			sortField: e.sortField,
			sortOrder: e.sortOrder,
		});
		setLoadTableRule(true);
	};

	const onChangePageRuleLength = (e) => {
		setPageableRule({
			...pageableRule,
			rows: e,
			page: 0,
		});
		setLoadTableRule(true);
	};

	const tblRuleHeader = (
		<TableHeader
			title=''
			pageReport={getTablePageReport(pageableRule.page, pageableRule.rows, pageableRule.total)}
			actionTemplate={<Button className='p-button-success' label='Create Promotion' icon='pi-md-plus' onClick={() => onAddOrEditRule(null)} />}
			changePageLength={(e) => onChangePageRuleLength(e)}
			refresh={() => setLoadTableRule(true)}
		/>
	);

	const isValidRuleStatus = (data) => {
		return isValidPromotion() && data.active && isInValidDateTime(data.validFrom, data.validTo, true) && (!data.usageLimit || data.used < data.usageLimit);
	};

	const popularRuleFormData = (data, promotionType) => {
		if (promotionType === PROMOTION_TYPE.promotion.value) {
			let applyForTypes = [];

			if (data && data.type === PROMOTION_RULE_TYPE.product_bogo) {
				applyForTypes = [...CONDITION_APPLY_FOR_TYPES];
				if (data.quantityCondition && data.quantityCondition.acrossQuantity) {
					applyForTypes.splice(applyForTypes.indexOf(applyForTypes.find((i) => i.value === CONDITION_APPLY_FOR_TYPE.same_product)), 1);
				}
			}

			let conditions = data && data.conditions ? data.conditions : [];
			conditions.length > 0 &&
				conditions.map((c) => {
					if (c.conditionType === RULE_CONDITION_TYPE.times) {
						let sValues = new Array(2);
						c.values.length > 0 &&
							c.values.map((time, idx) => {
								sValues[idx] = moment(time, moment.HTML5_FMT.TIME).toDate();
							});
						c.sValues = sValues;
					}
				});
			return {
				id: data ? data.id : '',
				name: data ? data.name : '',
				nameDisplay: data ? data.nameDisplay : '',
				type: data ? data.type : null,
				priority: data ? data.priority : dataListRule.length,
				usageLimit: data && data.usageLimit ? data.usageLimit + '' : null,
				validFrom: data && data.validFrom ? data.validFrom : promotion.startAt ? promotion.startAt : '',
				validTo: data && data.validTo ? data.validTo : promotion.endAt ? promotion.endAt : '',
				exclusive: data ? data.exclusive : false,
				active: data ? data.active : true,
				startDateValue: data && data.validFrom ? moment(data.validFrom, 'YYYY-MM-DD HH:mm').toDate() : promotion.startAt ? moment(promotion.startAt, 'YYYY-MM-DD HH:mm').toDate() : '',
				endDateValue: data && data.validTo ? moment(data.validTo, 'YYYY-MM-DD HH:mm').toDate() : promotion.endAt ? moment(promotion.endAt, 'YYYY-MM-DD HH:mm').toDate() : '',
				acrossQuantity: data ? data.acrossQuantity : false,
				conditionMatchAll: data ? data.conditionMatchAll : true,
				applyForTypes: applyForTypes,
				filters: data && data.filters && data.filters.length > 0 ? data.filters : [{ ...DEFAULT_RULE_FILTER }],
				discounts: data && data.discounts && data.discounts.length > 0 ? data.discounts : [{ ...DEFAULT_RULE_DISCOUNT }],
				conditions: conditions,
				applyBankOrCreditCard: data ? data.applyBankOrCreditCard : false,
				paymentGateway: data && data.bankOrCreditCardLimit && data.bankOrCreditCardLimit.paymentGateway ? data.bankOrCreditCardLimit.paymentGateway : PAYMENT_GATEWAY.MIDTRANS,
				bankOrCreditCardPromo: data && data.bankOrCreditCardPromo ? data.bankOrCreditCardPromo : '',
				paymentChannelLimits: data && data.bankOrCreditCardLimit && data.bankOrCreditCardLimit.paymentChannelLimits ? data.bankOrCreditCardLimit.paymentChannelLimits : [],
				creditCardLimits: data && data.bankOrCreditCardLimit && data.bankOrCreditCardLimit.creditCardLimits ? data.bankOrCreditCardLimit.creditCardLimits : [],
				// showDiscountBarOnMenu: data?data.showDiscountBarOnMenu:false,
				// showDiscountBarOnProduct: data?data.showDiscountBarOnProduct:false,
				// showDiscountBarFlying: data?data.showDiscountBarFlying:false,
				// discountBarContent: data&&data.discountBarContent?data.discountBarContent:'',
				// trans: {
				//     nameDisplay: data && data.trans && data.trans.nameDisplay?data.trans.nameDisplay:{},
				//     discountBarContent: data && data.trans && data.trans.discountBarContent?data.trans.discountBarContent:{}
				// },
				// discountBarBackgroundColor: data && data.discountBarBackgroundColor?data.discountBarBackgroundColor.replace('#', '') : localStorage.getItem('background_color'),
				// discountBarTextColor: data && data.discountBarTextColor?data.discountBarTextColor.replace('#', '') : localStorage.getItem('text_color'),
				// image: data && data.image ? data.image : {}
			};
		} else {
			return {
				id: data ? data.id : '',
				name: data ? data.name : '',
				nameDisplay: data ? data.nameDisplay : '',
				type: PROMOTION_RULE_TYPE.manual_order_discount.value,
				validFrom: data && data.validFrom ? data.validFrom : '',
				validTo: data && data.validTo ? data.validTo : '',
				exclusive: data ? data.exclusive : false,
				active: data ? data.active : true,
				startDateValue: data && data.validFrom ? moment(data.validFrom, 'YYYY-MM-DD HH:mm').toDate() : '',
				endDateValue: data && data.validTo ? moment(data.validTo, 'YYYY-MM-DD HH:mm').toDate() : '',
				discounts: data && data.discounts.length > 0 ? data.discounts : [{ ...DEFAULT_RULE_DISCOUNT }],
			};
		}
	};

	const handleSaveRule = () => {
		setErrors({});

		let tmpData = { ...frmData };
		if (tmpData.applyBankOrCreditCard) {
			tmpData.bankOrCreditCardLimit = {
				paymentGateway: tmpData.paymentGateway,
				paymentChannelLimits: tmpData.paymentChannelLimits,
				creditCardLimits: tmpData.creditCardLimits,
			};
		} else {
			tmpData.bankOrCreditCardLimit = null;
		}

		savePromotionRule(props.match.params.id, tmpData, frmData.priority).then((res) => {
			if (!res.errorCode) {
				let tmpRules = promotion.rules ? [...promotion.rules] : [];
				if (frmData.id) {
					tmpRules.splice(
						tmpRules.findIndex((r) => r.id === frmData.id),
						1,
						res
					);
				} else {
					tmpRules.push(res);
				}
				setPromotion({ ...promotion, rules: tmpRules });
				loadPageRules();
				setTabIndex(0);
				showSuccessNotify('Action Submitted');
			} else {
				showErrorNotify('Cannot perform action');
				if (res.errorCode === 400) setErrors(res.errorObj);
			}
		});
	};

	const handleRemoveRule = (id) => {
		removePromotionRule(props.match.params.id, id).then((res) => {
			if (!res.errorCode) {
				let tmpRules = promotion.rules ? [...promotion.rules] : [];
				tmpRules = tmpRules.filter((r) => r.id !== id);
				setPromotion({ ...promotion, rules: tmpRules });
				loadPageRules();
				if (id === frmData.id) {
					setFrmData(popularRuleFormData(), promotion.type);
				}
				showSuccessNotify('Action Submitted');
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	const onAddOrEditRule = (data) => {
		setFrmData(popularRuleFormData(data, promotion.type));
		setTabIndex(1);
	};

	const onRemoveRule = (id) => {
		showConfirmNotify({
			accept: () => handleRemoveRule(id),
		});
	};

	const onRowReorder = (e) => {
		setDataListRule(e.value);
		let arrayId = [];
		e.value.map((item) => {
			arrayId.push(item.id);
		});
		savePromotionRulePriority(arrayId, props.match.params.id);
	};

	const ruleActionTemplate = (rowData) => {
		const actionItems = [{ label: 'Remove', icon: 'pi pi-trash', command: () => onRemoveRule(rowData.id) }];
		return <SplitButton className='p-button-constrast p-l' label='Edit' icon='pi pi-pencil' model={actionItems} onClick={() => onAddOrEditRule(rowData)}></SplitButton>;
	};

	const onRuleStartDateChange = (e) => {
		setFrmData({ ...frmData, validFrom: e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '', startDateValue: e.value ? e.value : '' });
	};

	const onRuleEndDateChange = (e) => {
		setFrmData({ ...frmData, validTo: e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '', endDateValue: e.value ? e.value : '' });
	};
	//#endregion

	//#region Coupon
	const loadPageCoupons = () => {
		getPagePromotionCoupons({ promotionId: props.match.params.id }, pageableCoupon.page, pageableCoupon.rows, pageableCoupon.sortField, pageableCoupon.sortOrder).then((res) => {
			setDataListCoupon(res.content);
			setPageableCoupon({
				...pageableCoupon,
				total: res.totalElements,
				page: res.pageable.pageNumber,
				rows: res.pageable.pageSize,
			});
			setLoadTableCouopn(false);
		});
	};

	const onPageCoupon = (e) => {
		setPageableCoupon({
			...pageableCoupon,
			page: e.page,
		});
		setLoadTableRule(true);
	};

	const onSortCoupon = (e) => {
		setPageableCoupon({
			...pageableCoupon,
			sortField: e.sortField,
			sortOrder: e.sortOrder,
		});
		setLoadTableRule(true);
	};

	const onChangePageCouponLength = (e) => {
		setPageableRule({
			...pageableCoupon,
			rows: e,
			page: 0,
		});
		setLoadTableRule(true);
	};

	const tblCouponHeader = (
		<TableHeader
			title='Coupons'
			pageReport={getTablePageReport(pageableRule.page, pageableRule.rows, pageableRule.total)}
			actionTemplate={
				<React.Fragment>
					<Button className='p-button-success p-margin-bottom-5' label='Create Coupon' icon='pi-md-plus' onClick={() => onAddOrEditCoupon(null)} />
					<Button className='p-button-success p-margin-bottom-5' label='Generate Bulk' icon='pi-md-plus' onClick={() => frmGenerateCoupon.current.openForm(PROMOTION_COUPON_TYPE.voucher.value)} />
					<Button className='p-button-success p-margin-bottom-5' label='Export' icon='pi pi-download' onClick={() => handleExport()} disabled={!dataListCoupon || dataListCoupon.length === 0} />
				</React.Fragment>
			}
			changePageLength={(e) => onChangePageCouponLength(e)}
			refresh={() => setLoadTableCouopn(true)}
		/>
	);

	const isValidCouponStatus = (data) => {
		return isValidPromotion() && data.active && isInValidDateTime(data.validFrom, data.validTo, true) && (!data.usageLimit || data.used < data.usageLimit);
	};

	const handleRemoveCoupon = (id) => {
		removePromotionCoupon(id).then((res) => {
			if (!res.errorcode) {
				loadPageCoupons();
				showSuccessNotify('Action Submitted');
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	const handleExport = () => {
		if (!dataListCoupon || dataListCoupon.length === 0) {
			showErrorNotify('No data!');
		}

		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = `rpt__coupon_${suf}.xlsx`;

		showloading();

		exportPromotionCoupon({ promotionId: promotion.id })
			.then((res) => {
				if (!res.errorCode) {
					fileDownload(res, fileName);
				} else {
					showErrorNotify(res.errorMessage);
				}
			})
			.finally(() => stoploading());
	};

	const onRemoveCoupon = (id) => {
		showConfirmNotify({
			accept: () => handleRemoveCoupon(id),
		});
	};

	const onAddOrEditCoupon = (data) => {
		frmPromotionCoupon.current.openForm(data, promotion.rules ? promotion.rules : [], PROMOTION_COUPON_TYPE.voucher.value);
	};

	const couponActionTemplate = (rowData) => {
		let isRoleWritePromotion = hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_W]);
		let isRoleDeletePromotion = hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_D]);
		if (isRoleWritePromotion && isRoleWritePromotion) {
			const actionItems = [{ label: 'Remove', icon: 'pi pi-trash', command: () => onRemoveCoupon(rowData.id) }];
			return <SplitButton className='p-button-constrast p-l' label='Edit' icon='pi pi-pencil' model={actionItems} onClick={() => onAddOrEditCoupon(rowData)}></SplitButton>;
		} else {
			if (isRoleWritePromotion)
				return <Button icon='pi pi-pencil' tooltip='Edit' tooltipOptions={{ position: 'top' }} className='p-button-constrast p-button-warning' onClick={() => onAddOrEditCoupon(rowData)} />;
			if (isRoleDeletePromotion)
				return <Button icon='pi-md-close' tooltip='Remove' tooltipOptions={{ position: 'top' }} className='p-button-constrast p-button-danger' onClick={() => onRemoveCoupon(rowData.id)} />;
		}
	};
	//#endregion

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to='/promotions'>Campaign List</BreadcrumbsItem>
			<BreadcrumbsItem to={match.url}>{promotion.name}</BreadcrumbsItem>

			<PromotionForm ref={frmPromotion} refreshData={(data) => setPromotion(data)} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1>
						{promotion.name}
						<div className='p-toolbar-group-right'>
							<Link to={`/promotions`}>
								<Button icon='pi pi-bars' className='p-button-text' tooltip='Return' tooltipOptions={{ position: 'top' }}></Button>
							</Link>
							{hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_W]) && (
								<Button
									icon='pi pi-pencil'
									className='btn-custom-default'
									tooltip='Edit Campaign'
									tooltipOptions={{ position: 'top' }}
									style={{ marginRight: '.25em' }}
									onClick={() => frmPromotion.current.openForm(promotion)}
								/>
							)}
						</div>
					</h1>
					<div className='p-grid p-fluid'>
						{promotion.type === PROMOTION_TYPE.promotion.value && <div className='p-col-12 p-md-4'>Allow Coupon: {promotion.allowCoupon ? 'Yes' : 'No'}</div>}
						<div className='p-col-12 p-md-4'>Status: {isValidPromotion() ? 'Valid' : 'Invalid'}</div>
					</div>
				</div>
			</div>

			<div className='p-col-12'>
				<TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}>
					<TabPanel header='Promotion List' leftIcon='pi pi-list'>
						<DataTable
							emptyMessage="There's no record found"
							header={tblRuleHeader}
							value={dataListRule}
							lazy={true}
							loading={isLoadTableRule}
							paginator={true}
							first={pageableRule.page * pageableRule.rows}
							rows={pageableRule.rows}
							totalRecords={pageableRule.total}
							sortField={pageableRule.sortField}
							sortOrder={pageableRule.sortOrder}
							onPage={onPageRule}
							onSort={onSortRule}
							responsive={true}
							reorderableColumns
							onRowReorder={onRowReorder}
						>
							<Column rowReorder style={{ width: '4em' }} />
							<Column field='id' header='ID' sortable={true} />
							<Column field='name' header='Name' sortable={true} />
							{promotion.type === PROMOTION_TYPE.promotion.value && [
								<Column field='type' header='Type' body={(rowData) => PROMOTION_RULE_TYPE[rowData.type] && PROMOTION_RULE_TYPE[rowData.type].label} sortable={true} />,
								<Column field='exclusive' header='Exclusive' body={(rowData) => (rowData.exclusive ? 'Yes' : 'No')} />,
							]}
							<Column field='valid' header='Status' body={(rowData) => (isValidRuleStatus(rowData) ? 'Valid' : 'Invalid')} />
							<Column body={ruleActionTemplate} style={{ textAlign: 'right', width: '13em' }} bodyClassName='tc-actions' />
						</DataTable>
					</TabPanel>
					<TabPanel
						headerClassName={classNames({ 'p-hide': !hasRole([ROLES_CONFIG.PROMOTION_MANAGEMENT_W]) })}
						leftIcon='pi pi-plus'
						rightIcon='pi pi-pencil'
						className='p-padding-none'
						header={
							<span>
								{frmData.id ? 'Add' : <b>Add</b>} or {!frmData.id ? 'Update' : <b>Update</b>}
							</span>
						}
					>
						<Fieldset legend='Header Information' className='p-fieldset-padding-sm'>
							<div className='p-grid p-fluid form-group'>
								{promotion.type === PROMOTION_TYPE.promotion.value && (
									<div className='p-col-12 p-md-3'>
										<div className='p-grid'>
											<div className='p-col-12'>
												<label className='p-label'>* Type </label>
												<Dropdown value={frmData.type} options={PROMOTION_RULE_TYPES} onChange={(e) => setFrmData({ ...frmData, type: e.value })} placeholder='Select discount type' />
												<div className='p-form-error'>{errors.type}</div>
											</div>
										</div>
									</div>
								)}
								<div className='p-col-12 p-md-4'>
									<div className='p-grid'>
										<div className='p-col-12'>
											<label className='p-label'>* Title (Manage)</label>
											<InputText value={frmData.name} onChange={(e) => setFrmData({ ...frmData, name: e.target.value })} />
											<div className='p-form-error'>{errors.name}</div>
										</div>
										<div className='p-col-12'>
											<label className='p-label'>Discount Label (Display)</label>
											<InputText value={frmData.nameDisplay} onChange={(e) => setFrmData({ ...frmData, nameDisplay: e.target.value })} />
											<div className='p-form-error'>{errors.label}</div>
										</div>
									</div>
								</div>
								<div className='p-col-12 p-md-3 p-md-offset-1'>
									<div className='p-grid'>
										<div className='p-col-12'>
											<InputSwitch checked={frmData.active} onChange={(e) => setFrmData({ ...frmData, active: e.target.value })} />
											<label className='p-margin-left-10 p-margin-right-30'>Active?</label>
										</div>
										{/* <div className="p-col-12">
                                            <InputSwitch checked={frmData.applyBankOrCreditCard} onChange={(e) => setFrmData({...frmData, applyBankOrCreditCard: e.target.value})} />
                                            <label className="p-margin-left-10 p-margin-right-30">Only apply by bank or credit card limit?</label>
                                        </div> */}
										<div className='p-col-12'>
											<InputSwitch checked={frmData.exclusive} onChange={(e) => setFrmData({ ...frmData, exclusive: e.target.value })} />
											<label className='p-margin-left-10'>Apply this promotion if matched and ignore all others?</label>
										</div>
									</div>
								</div>
							</div>
						</Fieldset>
						{frmData.applyBankOrCreditCard && (
							<Fieldset legend='Bank/Credit Card Setting'>
								<div className='p-grid'>
									<div className='p-col-12 p-md-3 p-size-13'>
										<h2 className='p-margin-top-10'>Bank/Creditcard limit setup</h2>
										<p>Limit payment channel and credit card using for discount.</p>
										<p>Note : Setting maybe difference depend on Payment Gateway using.</p>
									</div>
									<div className='p-col-12 p-md-9'>
										<div className='p-border-gray' style={{ padding: '7.5px' }}>
											<div className='p-grid p-fluid'>
												{/* <div className="p-col-12 p-md-3">
                                                    <label className="p-label">Payment Gateway</label>
                                                    <Dropdown value={frmData.paymentGateway} options={PAYMENT_GATEWAYS} onChange={e => setFrmData({...frmData, paymentGateway: e.value})} />
                                                </div> */}
												<div className='p-col-12'>
													<label className='p-label'>Bank/Creditcard Promo:</label>
													<InputText value={frmData.bankOrCreditCardPromo} onChange={(e) => setFrmData({ ...frmData, bankOrCreditCardPromo: e.target.value })} />
												</div>
												<div className='p-col-12'>
													<label className='p-label'>Payment Channel Limits</label>
													<Chips
														value={frmData.paymentChannelLimits ? frmData.paymentChannelLimits : []}
														allowDuplicate={false}
														onChange={(e) => setFrmData({ ...frmData, paymentChannelLimits: e.value })}
													/>
												</div>
												<div className='p-col-12'>
													<label className='p-label'>Credit Card Limits (in case using credit card channel)</label>
													<Chips value={frmData.creditCardLimits ? frmData.creditCardLimits : []} allowDuplicate={false} onChange={(e) => setFrmData({ ...frmData, creditCardLimits: e.value })} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</Fieldset>
						)}
						{frmData.type && (
							<React.Fragment>
								{frmData.type !== PROMOTION_RULE_TYPE.cart_adjustment.value && frmData.type !== PROMOTION_RULE_TYPE.manual_order_discount.value && (
									<Fieldset className='p-fieldset-no-legend p-fieldset-padding-sm p-margin-top-20'>
										<div className='p-grid'>
											<div className='p-col-12 p-md-3 p-size-13'>
												<h2 className='p-margin-top-10'>Filter {frmData.type === PROMOTION_RULE_TYPE.product_bxgy.value ? 'Buy' : ''}</h2>
												<p>
													{frmData.type === PROMOTION_RULE_TYPE.product_bxgy.value
														? 'Choose buy products. (products/categories) Example : For Buy X get Y scenarios, choose X here.'
														: 'Choose what gets discount (products/ branches/ departments/ categories)'}
												</p>
												<p>Note : You can also exclude products/ branches/ departments/ categories.</p>
											</div>
											<div className='p-col-12 p-md-9'>
												<div className='p-border-gray' style={{ padding: '7.5px' }}>
													<RuleFilters
														errorFilter={errors.filter ? errors.filter : ''}
														data={frmData.filters}
														categories={categories}
														products={products}
														branches={branches}
														departments={departments}
														memberships={memberships}
														onChange={(e) => setFrmData({ ...frmData, filters: e })}
													/>
												</div>
											</div>
										</div>
									</Fieldset>
								)}

								<Fieldset className='p-fieldset-no-legend p-fieldset-padding-sm p-margin-top-20'>
									<div className='p-grid'>
										<div className='p-col-12 p-md-3 p-size-13'>
											<h2 className='p-margin-top-10'>Discount</h2>
											<p>
												{(() => {
													switch (frmData.type) {
														case PROMOTION_RULE_TYPE.cart_adjustment.value:
														case PROMOTION_RULE_TYPE.product_adjustment.value:
														case PROMOTION_RULE_TYPE.manual_order_discount.value:
															return 'Select discount type and its value (percentage/amount/fixed price)';
														// case PROMOTION_RULE_TYPE.product:
														//     return '';
														case PROMOTION_RULE_TYPE.product_bxgx.value:
															return '';
														case PROMOTION_RULE_TYPE.product_bxgy.value:
															return '';
														default:
															break;
													}
												})()}
											</p>
										</div>
										<div className='p-col-12 p-md-9'>
											<div className='p-border-gray' style={{ padding: '7.5px' }}>
												<RuleDiscounts
													errorDiscount={errors ? errors : ''}
													data={frmData.discounts}
													acrossQuantity={frmData.acrossQuantity}
													type={frmData.type}
													products={products}
													onChange={(e) => setFrmData({ ...frmData, discounts: e })}
													onUpdateAcrossQuantity={(e) => setFrmData({ ...frmData, acrossQuantity: e })}
												/>
											</div>
										</div>
									</div>
								</Fieldset>

								<Fieldset className='p-fieldset-no-legend p-fieldset-padding-sm p-margin-top-20'>
									<div className='p-grid'>
										<div className='p-col-12 p-md-3 p-size-13'>
											<h2 className='p-margin-top-10'>Rules (Optional)</h2>
											<p>Include additional conditions (If necessary)</p>
										</div>
										<div className='p-col-12 p-md-9'>
											{frmData.type !== PROMOTION_RULE_TYPE.manual_order_discount && (
												<Fieldset legend='Rule condition' className='p-fieldset-no-legend p-fieldset-padding-sm p-margin-top-5'>
													<RuleConditions
														errorCondition={errors ? errors : ''}
														data={frmData.conditions}
														conditionMatchAll={frmData.conditionMatchAll}
														onChange={(e) => setFrmData({ ...frmData, conditions: e })}
														onUpdateConditionMatchAll={(e) => setFrmData({ ...frmData, conditionMatchAll: e })}
													/>
												</Fieldset>
											)}
											<Fieldset legend='Rule limit' className='p-fieldset-no-legend p-fieldset-padding-sm p-margin-top-10'>
												<div className='p-grid p-fluid form-group'>
													<div className='p-col-12 p-md-2'>
														<label className='p-label p-w-normal'>Usage limit</label>
														<InputText keyfilter='int' value={frmData.usageLimit} className='p-frames' onChange={(e) => setFrmData({ ...frmData, usageLimit: e.target.value })} />
														<span className='p-form-error'>
															{errors.usageLimit && <div>{parseMessage2(errors.usageLimit.code, errors.usageLimit.info ? { count: errors.usageLimit.info[0] } : {})}</div>}
														</span>
													</div>
													<div className='p-col-12 p-md-3 p-md-offset-1'>
														<label className='p-label p-w-normal'>Valid From</label>
														<MaskedCalendar value={frmData.startDateValue} className='p-frames' onChange={(e) => onRuleStartDateChange(e)} showIcon={true} hourFormat='12' showTime={true} />
														{/* <span className="p-form-error">{this.state.errors.validFrom && <div>{parseMessage(trans, this.state.errors.validFrom.code, this.state.errors.validFrom.info?{count: this.state.errors.validFrom.info[0]}:{})}</div>}</span> */}
													</div>
													<div className='p-col-12 p-md-3'>
														<label className='p-label p-w-normal'>Valid to</label>
														<MaskedCalendar value={frmData.endDateValue} className='p-frames' onChange={(e) => onRuleEndDateChange(e)} showIcon={true} hourFormat='12' showTime={true} />
														<span className='p-form-error'>{errors.validTo}</span>
														{/* <span className="p-form-error">{this.state.errors.validTo && <div>{parseMessage(trans, this.state.errors.validTo.code, this.state.errors.validTo.info?{count: this.state.errors.validTo.info[0]}:{})}</div>}</span> */}
													</div>
												</div>
											</Fieldset>
										</div>
									</div>
								</Fieldset>
								<br />
							</React.Fragment>
						)}
						<div className='p-grid'>
							<div className='p-col-12 p-margin-top-20'>
								<Button label='Save' icon='pi pi-save' style={{ float: 'right' }} iconPos='left' onClick={() => handleSaveRule()} />
							</div>
						</div>
					</TabPanel>
					<TabPanel headerClassName={!promotion || !promotion.allowCoupon ? 'p-hide' : ''} header='Coupons'>
						<PromotionCouponForm ref={frmPromotionCoupon} refreshTable={() => loadPageCoupons()} promoId={props.match.params.id} />
						<GeneratingCouponForm ref={frmGenerateCoupon} onRefresh={() => loadPageCoupons()} promotionId={promotion?.id} />

						<div className='p-col-12'>
							<DataTable
								emptyMessage="There's no record found"
								header={tblCouponHeader}
								value={dataListCoupon}
								lazy={true}
								loading={isLoadTableCoupon}
								paginator={true}
								first={pageableCoupon.page * pageableCoupon.rows}
								rows={pageableCoupon.rows}
								totalRecords={pageableCoupon.total}
								sortField={pageableCoupon.sortField}
								sortOrder={pageableCoupon.sortOrder}
								onPage={onPageCoupon}
								onSort={onSortCoupon}
							>
								<Column field='id' header='ID' sortable={true} />
								<Column field='code' header='Code' sortable={true} />
								<Column header='Promotion' body={(rowData) => rowData.promotionRule && rowData.promotionRule.name} />
								<Column field='used' header='Used' sortable={true} />
								<Column field='usageLimit' header='Limit q.ty' sortable={true} body={(rowData) => (rowData.usageLimit ? rowData.usageLimit : 'Unlimited')} />
								<Column field='validFrom' header='Start date' sortable={true} body={(rowData) => (rowData.validFrom ? moment(rowData.validFrom).format('MM-DD-YYYY hh:mm A') : '')} />
								<Column field='validTo' header='End date' sortable={true} body={(rowData) => (rowData.validTo ? moment(rowData.validTo).format('MM-DD-YYYY hh:mm A') : '')} />
								<Column field='valid' header='Status' body={(rowData) => (isValidCouponStatus(rowData) ? 'Valid' : 'Invalid')} />
								<Column body={couponActionTemplate} style={{ textAlign: 'right', width: '13em' }} bodyClassName='tc-actions' />
							</DataTable>
						</div>
					</TabPanel>
				</TabView>
			</div>
		</div>
	);
};
