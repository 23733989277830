import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { showloading, stoploading } from "../../core/service/LoadingService";
import { RadioButton } from "primereact/radiobutton";
import { PAYMENT_GATEWAY, PAYMENT_METHOD, PAYMENT_STATE } from "../../constants";
import { InputTextarea } from "primereact/inputtextarea";
import { cancelInvoice, completeInvoice } from "./InvoiceServices";
import { Dialog } from "primereact/dialog";
import { moneyFormat } from "../../core/service/CommonService";
import { cancelOrder } from "../order/OrderService";

const ObjectType = {
  order: {value: 'order', label: 'Order'}, invoice: {value: 'invoice', label: 'Invoice'}
}

const ActionType = {
  complete: {value: 'complete', label: 'Complete'}, cancel: {value: 'cancel', label: 'Cancel'}
}

const TIMES_PAYMENT_ACTION = {
  two_times: 'two_times',
  three_times: 'three_times'
}

export const OrderOrInvoicePaymentActionForm = forwardRef((props, ref) => {
  const [header, setHeader] = useState("Order/Invoice")
  const [dialogPromoText, setDialogPromoText] = useState('')
  const [visible, setVisible] = useState(false)
  const [frmData, setFrmData] = useState({})
  const [objType, setObjType] = useState()
  const [actionType, setActionType] = useState()
  const [invoice, setInvoice] = useState({})
  const [order, setOrder] = useState({})
  const [errors, setErrors] = useState({})
  const [isLoading, setLoading] = useState(true)
  
  useImperativeHandle(ref, () => ({
    openForm(objectType, actionType, orderData, invoiceData){
      let tmpHeader = ActionType[actionType].label + " " + ObjectType[objectType].label
      let tmpPromoText = `You're to ${actionType} ${objectType}. This action cannot be undone!`

      setFrmData({
        gateway: PAYMENT_GATEWAY.ONSITE,
        method: PAYMENT_METHOD.cash.value,
        isPartialPayment: false,
        amount: null,
        transactionId: '',
        reason: ''
      })
      setOrder(orderData?orderData:{})
      setInvoice(invoiceData?invoiceData:{})
      setHeader(tmpHeader)
      setDialogPromoText(tmpPromoText)

      setActionType(actionType)
      setObjType(objectType)
      setVisible(true)
      setErrors({})
      setLoading(false)
    },
    closeForm(){
      closeForm()
    }
  }))

  const closeForm = () => {
    setLoading(false)
    setVisible(false)
    setInvoice({})
    setFrmData({})
    setErrors({})
  }

  const handleCompleteInvoice = () => {
    showloading();
    setLoading(true)
    
    completeInvoice(invoice.id, frmData.gateway, frmData.method, frmData.isPartialPayment, frmData.amount, frmData.transactionId, frmData.reason)
    .then(res => {
      if(!res.errorCode){
        if(props.invoice)
          props.invoice(res.invoice)
        if(props.order)
          props.order(res.order)
        closeForm()
        showSuccessNotify('Action submitted!');
      }else{
        if(res.errorCode===400) setErrors(res.errorObj)
        showErrorNotify(res.errorMessage);
      }
    })
    .finally(() => {
      setLoading(false)
      stoploading()
    });
  }

  const handleCancelInvoice = () => {
    showloading();
    setLoading(true)
    
    cancelInvoice(invoice.id, frmData.reason)
    .then(res => {
      if(!res.errorCode){
        if(props.invoice)
          props.invoice(res.invoice)
        closeForm()
        showSuccessNotify('Action submitted!');
      }else{
        if(res.errorCode===400) setErrors(res.errorObj)
        showErrorNotify(res.errorMessage);
      }
    })
    .finally(() => {
      setLoading(false)
      stoploading()
    });
  }

  const handleCancelOrder = () => {
    showloading();
    setLoading(true)
    
    cancelOrder(order.id, frmData.reason)
    .then(res => {
      if (!res.errorCode) {
        if(props.order)
          props.order(res)
        closeForm()
        showSuccessNotify('Action submitted!')
      } else {
        if(res.errorCode===400) setErrors(res.errorObj)
        showErrorNotify(res.errorMessage);
      }
    })
    .finally(() => {
      setLoading(false)
      stoploading()
    });
  }

  const onChangeNumberTimeToPayment = (value) => {
    let tmpAmount = 0
    if(value===TIMES_PAYMENT_ACTION.two_times){
      tmpAmount = Math.ceil(invoice.checkout/2)
    }else if(value===TIMES_PAYMENT_ACTION.three_times){
      tmpAmount = Math.ceil(invoice.checkout/3)
    }
    setFrmData({...frmData, 
      numberTimeToPayment: value,
      amount: tmpAmount
    })
  }

  const onDialogConfirm = () => {
    if(objType===ObjectType.order.value){
      if(actionType===ActionType.complete.value){
        handleCompleteInvoice()
      }else if(actionType===ActionType.cancel.value){
        handleCancelOrder()
      }
    }else if(objType===ObjectType.invoice.value){
      if(actionType===ActionType.complete.value){
        handleCompleteInvoice()
      }else if(actionType===ActionType.cancel.value){
        handleCancelInvoice()
      }
    }
  }

  const dialogActionFooter = (
    <div>
      {isLoading 
        ? <Button label="Submit" icon="pi pi-spin pi-spinner" disabled={isLoading} />
        : <Button label="Submit" icon="pi pi-check" onClick={() => onDialogConfirm()} />
      }
      <Button label="Cancel" icon="pi-md-close" onClick={() => closeForm()} />
    </div>
  );

  return(
    <Dialog header={header} footer={dialogActionFooter} visible={visible} style={{width: '50vw'}} modal={true} onHide={() => closeForm()}>
      <div className="p-grid p-fluid form-group">
        {objType===ObjectType.invoice.value &&
          actionType===ActionType.cancel.value && order.paymentState===PAYMENT_STATE.awaiting.value &&
            <div className="p-col-12 p-size-16 p-w-bold" style={{color: '#a00303'}}>
              The order has paid. Are you sure to take this action!
            </div>
        }
        <div className="p-col-12 p-size-16 p-w-bold" style={{color: '#a00303'}}>
            {dialogPromoText}
        </div>
        {actionType===ActionType.complete.value && 
          <React.Fragment>
            <div className="p-col-12">
                <label className="p-label">Select one of the payment types below:</label>
            </div>
            <div className="p-col-12">
              <div className="p-py-2">
                <RadioButton inputId="rdPaymentType0" className="p-mr-2" onChange={(e) => setFrmData({...frmData, isPartialPayment: false})} checked={frmData.isPartialPayment === false} />
                <label htmlFor="rdPaymentType0">Full payment <strong>({moneyFormat(invoice.checkout)})</strong></label>
              </div>
              {(()=>{
                let total = invoice.checkout
                let paid = invoice.paid;
                let isLastPay = Math.ceil(total/paid)<2
                if(!isLastPay){
                  return <div className="p-py-2">
                      <RadioButton inputId="rdPartial" className="p-mr-2" onChange={(e) => setFrmData({...frmData, isPartialPayment: true})} checked={frmData.isPartialPayment === true} />
                      <label htmlFor="rdPartial">Partial payment (The rest will be transferred to another invoice)</label>
                  </div>
                }
              })()}
            </div>
            {frmData.isPartialPayment &&
              <div className="p-col-12">
                <label className="p-label">* Amount to pay:</label>
                {/* <InputText keyfilter="money" value={frmData.amount} onChange={(e) => setFrmData({...frmData, amount: e.target.value})}/> */}
                <div className="p-mt-1">
                  {(()=>{
                    let total = invoice.checkout
                    let paid = invoice.paid;
                    let isPay3Time = Math.ceil(total/paid)>=3
                    let isPay2Time = Math.ceil(total/paid)>=2 && Math.ceil(total/paid)<3

                    if(isPay3Time){
                      return <React.Fragment>
                        <RadioButton inputId="rd2TimeToPayment" checked={frmData.numberTimeToPayment===TIMES_PAYMENT_ACTION.two_times} onChange={e => onChangeNumberTimeToPayment(TIMES_PAYMENT_ACTION.two_times)} />
                        <label htmlFor="rd2TimeToPayment">2 times payment ({moneyFormat(Math.ceil(invoice.checkout/2))})</label>
                        <RadioButton inputId="rd3TimeToPayment" className="p-ml-3" checked={frmData.numberTimeToPayment===TIMES_PAYMENT_ACTION.three_times} onChange={e => onChangeNumberTimeToPayment(TIMES_PAYMENT_ACTION.three_times)} />
                        <label htmlFor="rd3TimeToPayment">3 times payment ({moneyFormat(Math.ceil(invoice.checkout/3))})</label> 
                      </React.Fragment>
                    }
                    if(isPay2Time){
                      return <React.Fragment>
                          <RadioButton inputId="rd2TimeToPayment" checked={frmData.numberTimeToPayment===TIMES_PAYMENT_ACTION.two_times} onChange={e => onChangeNumberTimeToPayment(TIMES_PAYMENT_ACTION.two_times)} />
                          <label htmlFor="rd2TimeToPayment">2 times payment ({moneyFormat(Math.ceil(invoice.checkout/2))})</label>
                        </React.Fragment>
                    }
                  })()}
                </div>
                <div className="p-form-error">{errors.amount}</div>
              </div>
            }
            <div className="p-col-12">
                <label className="p-label">Select one of the payment options below:</label>
            </div>
            <div className="p-col-12">
              <div className="p-py-2">
                  <RadioButton inputId="rdPaymentMethod0" className="p-mr-2" onChange={(e) => setFrmData({...frmData, method: PAYMENT_METHOD.cash.value})} checked={frmData.method === PAYMENT_METHOD.cash.value} />
                  <label htmlFor="rdPaymentMethod0">{PAYMENT_METHOD.cash.label}</label>
              </div>
              <div className="p-py-2">
                  <RadioButton inputId="rdPaymentMethod1" className="p-mr-2" onChange={(e) => setFrmData({...frmData, method: PAYMENT_METHOD.bank_transfer.value})} checked={frmData.method === PAYMENT_METHOD.bank_transfer.value} />
                  <label htmlFor="rdPaymentMethod1">{PAYMENT_METHOD.bank_transfer.label}</label>
              </div>
            </div>
            {frmData.method===PAYMENT_METHOD.bank_transfer.value &&
              <div className="p-col-12">
                  <label className="p-label">* Transaction Number:</label>
                  <InputText value={frmData.transactionId} onChange={e => setFrmData({...frmData, transactionId: e.target.value})} />
                  <div className="p-form-error">{errors.transactionId}</div>
              </div>
            }
          </React.Fragment>
        }
        <div className="p-col-12">
          <label className="p-label">Please input a reason:</label>
          <InputTextarea rows={4} value={frmData.reason} onChange={(e) => setFrmData({...frmData, reason: e.target.value })} placeholder="Enter a reason" />
          <span className="p-form-error">{errors.reason}</span>
        </div>
      </div>
    </Dialog>
  )
})
