import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getInvoice, sendInvoiceMailRemind } from './InvoiceServices';
import { Fieldset } from 'primereact/fieldset';
import { getListTransactions } from '../../scp/accounting/transaction/TransactionServices';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Button } from 'primereact/button';
import {
	PAYMENT_STATE,
	APP_FEP,
	PAYMENT_METHOD,
	PRICING_MODE,
	PRODUCT_CLASSIFICATION,
	DEPOSIT_TYPE,
	ASSET_SCHEDULE_STATUS,
	INVOICE_PURPOSE,
	DATETIME_FORMAT_DISPLAY,
	DATE_FORMAT_DISPLAY,
	ORDER_ITEM_STATUS,
} from '../../constants';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { moneyFormat } from '../../core/service/CommonService';
import { getOrderDetails } from '../order/OrderService';
import { getSchedule } from '../asset-schedule/SchedulesService';
import { OrderOrInvoicePaymentActionForm } from './OrderOrInvoicePaymentActionForm';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';
import { InvoiceRefundForm } from './InvoiceRefundForm';


export const InvoiceDetails = (props) => {
	const [invoice, setInvoice] = useState({ order: {}, orderItem: {} });
	const [transactions, setTransactions] = useState([]);
	const [order, setOrder] = useState({});
	const [assetSchedule, setAssetSchedule] = useState({});

	const invoiceActionFrom = useRef(null);
	const refundForm = useRef(null);

	const ActionType = {
		complete: { value: 'complete', label: 'Complete Invoice' },
		cancel: { value: 'cancel', label: 'Cancel Invoice' },
	};

	useEffect(() => {
		loadInvoice();
		loadTransactions();
	}, []);

	const loadInvoice = () => {
		showloading();
		getInvoice(props.match.params.id)
			.then((res) => {
				if (!res.errorCode) {
					setInvoice(res);

					const tmpOrder = res.order;

					if (res.purpose === INVOICE_PURPOSE.refund.value && res.orderItem) {
						tmpOrder.items = [res.orderItem];
					}

					setOrder(tmpOrder);

					if (res.assetScheduleId) {
						loadAssetSchedule(res.assetScheduleId);
					}
				} else {
					showErrorNotify('No result');
				}
			})
			.finally(stoploading());
	};

	const loadTransactions = () => {
		getListTransactions({ app: APP_FEP, invoiceIds: [props.match.params.id] }).then((res) => setTransactions(res ? res : []));
	};

	const loadOrder = (orderId) => {
		getOrderDetails(orderId).then((res) => {
			if (!res.errorCode) {
				setOrder(res ? res : {});
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const loadAssetSchedule = (assetScheduleId) => {
		getSchedule(assetScheduleId).then((res) => {
			if (!res.errorCode) {
				setAssetSchedule(res ? res : {});
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const sendRemindEmail = () => {
		showConfirmNotify({
			message: invoice.remind ? 'This invoice had already send to customer. Are you sure you want to proceed?' : '',
			accept: () => handleSendInvoiceRemindEmail(invoice.id),
		});
	};

	const handleSendInvoiceRemindEmail = (id) => {
		sendInvoiceMailRemind(id).then((res) => {
			if (!res.errorCode) {
				showSuccessNotify('Email sent successfully!');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const openActionDialog = (action) => {
		invoiceActionFrom.current.openForm('invoice', action, null, invoice);
	};

	const onRefund = () => {
		refundForm.current.openForm(invoice.id, invoice.total);
	};

	const onRefreshInvoice = (invoiceDta) => {
		setInvoice(invoiceDta);
		loadTransactions();
	};

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={`/invoices`}>Invoice-Receipt</BreadcrumbsItem>
			<BreadcrumbsItem to={`/invoices/${props.match.params.id}`}>
				{invoice.status === PAYMENT_STATE.awaiting.value || invoice.status === PAYMENT_STATE.pending.value
					? 'Invoice'
					: invoice.purpose === INVOICE_PURPOSE.donation_charge.value
					? 'Donation'
					: 'Receipt'}{' '}
				#{props.match.params.id}
			</BreadcrumbsItem>

			<OrderOrInvoicePaymentActionForm ref={invoiceActionFrom} invoice={(data) => onRefreshInvoice(data)} />

			<InvoiceRefundForm ref={refundForm} refreshData={(data) => onRefreshInvoice(data)} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1>
						{invoice.status === PAYMENT_STATE.awaiting.value ? 'Invoice' : invoice.purpose === INVOICE_PURPOSE.donation_charge.value ? 'Donation' : 'Receipt'} Details
						<div className='p-toolbar-group-right'>
							<Link to={`/invoices`}>
								<Button icon='pi pi-bars' className='p-button-text' tooltip='Return' tooltipOptions={{ position: 'top' }}></Button>
							</Link>
						</div>
					</h1>
					<div className='p-grid'>
						<div className='p-col-12 p-md-3'>
							<label>
								No. #: <span className='p-w-bold'>{invoice.id}</span>
							</label>
						</div>
						{/* <div className="p-col-12 p-md-6">
                            <label>Desc.: <span className="p-w-bold">{invoice.description}</span></label>
                        </div> */}

						{invoice.order && (
							<div className='p-col-12 p-md-3'>
								<label>
									Order ID-Code:{' '}
									<span className='p-w-bold'>
										{invoice.order.id} - {invoice.order.orderNumber}
									</span>
								</label>
							</div>
						)}
						{invoice.gateway && (
							<div className='p-col-12 p-md-3'>
								<label>
									Gateway: <span className='p-w-bold'>{invoice.gateway}</span>
								</label>
							</div>
						)}
						{invoice.method && (
							<div className='p-col-12 p-md-3'>
								<label>
									Method: <span className='p-w-bold'>{PAYMENT_METHOD[invoice.method] && PAYMENT_METHOD[invoice.method].label}</span>
								</label>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<div className='p-grid'>
						<div className='p-col-12 p-md-4'>
							<Fieldset legend='Basic Information'>
								<p>
									<span className='info-title'>Payor Name:</span>
									<span>{invoice.payorName}</span>
								</p>
								<p>
									<span className='info-title'>Payor Email:</span>
									<span>{invoice.payorEmail}</span>
								</p>
								<p>
									<span className='info-title'>Payor Phone:</span>
									<span>{invoice.payorPhone}</span>
								</p>
								<p>
									<span className='info-title'>Status:</span>
									<span>{PAYMENT_STATE[invoice.status] && PAYMENT_STATE[invoice.status].label}</span>
								</p>
								{invoice.paymentCompletedAt && (
									<p>
										<span className='info-title'>Completed At:</span>
										<span>{moment(invoice.paymentCompletedAt).format(DATETIME_FORMAT_DISPLAY)}</span>
									</p>
								)}
								<p>
									<span className='info-title'>Description:</span>
									<span>{invoice.description}</span>
								</p>
								{invoice.note && (
									<p>
										<span className='info-title'>Note:</span>
										<span>{invoice.note}</span>
									</p>
								)}
							</Fieldset>
						</div>
						<div className='p-col-12 p-md-4'>
							<Fieldset legend='Price Details'>
								{invoice.purpose === INVOICE_PURPOSE.order_charge.value ? (
									<React.Fragment>
										{invoice.status === PAYMENT_STATE.awaiting.value || invoice.status === PAYMENT_STATE.pending.value ? (
											<React.Fragment>
												<p className='p-size-16'>
													<span className='info-title'>Checkout:</span>
													<span>{moneyFormat(invoice.checkout)}</span>
												</p>
												<p className='p-size-16'>
													<span className='info-title'>Paid:</span>
													<span>{moneyFormat(invoice.paid)}</span>
												</p>
											</React.Fragment>
										) : (
											<p className='p-size-16'>
												<span className='info-title'>Paid:</span>
												<span>{moneyFormat(invoice.checkout)}</span>
											</p>
										)}
										<div className='p-hr p-padding-top-0'></div>
										<p className='p-size-16'>
											<span className='info-title'>Order total:</span>
											{moneyFormat(order.total)}
										</p>
									</React.Fragment>
								) : (
									<p className='p-size-16'>
										<span className='info-title'>{invoice.purpose === INVOICE_PURPOSE.refund.value ? 'Refund amount' : 'Checkout'}:</span>
										<span>{moneyFormat(invoice.checkout)}</span>
									</p>
								)}
							</Fieldset>
						</div>
						<div className='p-col-12 p-md-4'>
							<Fieldset legend='Actions'>
								<div className='p-grid'>
									{invoice.status !== PAYMENT_STATE.cancelled.value && (
										<React.Fragment>
											{invoice.purpose === INVOICE_PURPOSE.refund.value ? (
												(invoice.status === PAYMENT_STATE.pending.value || invoice.status === PAYMENT_STATE.awaiting.value) && (
													<React.Fragment>
														{(isScpAdmin() || hasRole([ROLES_CONFIG.INVOICE_DETAIL_W])) && <Button label='Refund' icon='pi-md-check' className='p-button-success' onClick={() => onRefund()} />}
														{(isScpAdmin() || hasRole([ROLES_CONFIG.INVOICE_DETAIL_D])) && (
															<Button label='Cancel' icon='pi-md-close' className='p-button-danger' onClick={() => openActionDialog(ActionType.cancel.value)} />
														)}
													</React.Fragment>
												)
											) : (
												<React.Fragment>
													<div className='p-col-12'>
														{invoice.status === PAYMENT_STATE.awaiting.value && (
															<React.Fragment>
																{(isScpAdmin() || hasRole([ROLES_CONFIG.INVOICE_DETAIL_W])) && (
																	<Button label='Complete Invoice' icon='pi-md-check' className='p-button-success' onClick={() => openActionDialog(ActionType.complete.value)} />
																)}
																{(isScpAdmin() || hasRole([ROLES_CONFIG.INVOICE_DETAIL_D])) && (
																	<Button label='Cancel' icon='pi-md-close' className='p-button-danger' onClick={() => openActionDialog(ActionType.cancel.value)} />
																)}
															</React.Fragment>
														)}
													</div>
													<div className='p-col-12'>
														<hr />
													</div>
													{invoice.status === PAYMENT_STATE.awaiting.value && (
														<div className='p-col-12'>
															<Button label='Resend mail' icon='pi pi-envelope' onClick={() => sendRemindEmail()} />
														</div>
													)}
												</React.Fragment>
											)}
										</React.Fragment>
									)}
									{invoice.status === PAYMENT_STATE.cancelled.value && <div className='p-col-12'>Status: {PAYMENT_STATE.cancelled.label}</div>}
								</div>
							</Fieldset>
						</div>
					</div>
				</div>
			</div>
			{transactions.length > 0 && (
				<div className='p-col-12'>
					<div className='card card-w-title'>
						<h1>Transactions</h1>

						<DataTable value={transactions} responsive={true}>
							<Column header='Gateway' field='gateway' />
							<Column header='Method' field='method' body={(rowData) => PAYMENT_METHOD[rowData.method] && PAYMENT_METHOD[rowData.method].label} />
							<Column header='Type' field='type' />
							<Column header='Trans Id' field='transId' />
							<Column header='Amount' field='amount' bodyClassName='p-r' body={(rowData) => moneyFormat(rowData.amount)} />
							<Column header='Paid At' field='completedAt' bodyClassName='p-r' body={(rowData) => rowData.completedAt && moment(rowData.completedAt).format(DATETIME_FORMAT_DISPLAY)} />
						</DataTable>
					</div>
				</div>
			)}

			{invoice.orderId && (
				<div className='p-col-12'>
					<div className='card card-w-title'>
						<h1>{invoice.purpose !== INVOICE_PURPOSE.refund.value ? 'Ordered Items' : 'Refunded Items '}</h1>

						<div className='p-datatable p-component'>
							<div className='p-datatable-wrapper'>
								<table style={{ width: '100%' }}>
									<thead className='p-datatable-thead'>
										<tr>
											<th>Product</th>
											<th style={{ width: '5%' }}>Q.ty</th>
											<th style={{ width: '8%' }}>Unit Price</th>
											<th style={{ width: '8%' }}>Discount</th>
											{order.pricingMode === PRICING_MODE.normal.value && <th style={{ width: '8%' }}>Tax</th>}
											<th style={{ width: '9%' }}>Total</th>
											<th style={{ width: '10%' }}>Deposit</th>
											<th style={{ width: '10%' }}>Checkout</th>
											<th style={{ width: '20%' }}>Description</th>
										</tr>
									</thead>
									<tbody className='p-datatable-tbody'>
										{order.items &&
											order.items.map((item) => {
												let tmpProduct = item.product;

												return (
													<React.Fragment key={item.id}>
														<tr key={item.id} className='p-datatable-row' style={{ backgroundColor: '#f0f0f0' }}>
															<td>
																<div className='p-w-bold'>
																	{item.productName}
																	{item.productVariantName && ' - [' + item.productVariantName + ']'}
																</div>
																{item.autoRecurring ? (
																	<div className='p-margin-10-0'>
																		- Subscription in every {item.subscriptionInfo.period} {item.subscriptionInfo.periodType}
																		{item.subscriptionInfo.period > 1 ? 's' : ''}
																	</div>
																) : (
																	item.rentalInfo && (
																		<div className='p-margin-10-0'>
																			- Rent in {item.rentalInfo.rentalQty} {item.rentalInfo.rentalUnit}
																			{item.rentalInfo.rentalQty > 1 ? 's' : ''}
																		</div>
																	)
																)}

																{item.status === ORDER_ITEM_STATUS.canceled && (
																	<div className='p-margin-10-0'>
																		<strong>[Cancelled]</strong> - {item.note}
																	</div>
																)}
															</td>
															<td className='p-c'>
																{(![PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) || !item.pricedByChildren) && item.quantity}
															</td>
															<td className='p-r'>
																{[PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) && item.pricedByChildren
																	? ''
																	: moneyFormat(item.unitPrice)}
															</td>
															<td className='p-r'>{moneyFormat(item.adjustmentTotal)}</td>
															{order.pricingMode === PRICING_MODE.normal.value && (
																<td className='p-r'>
																	{[PRODUCT_CLASSIFICATION.combo.value, PRODUCT_CLASSIFICATION.configuration.value].includes(tmpProduct.classification) && item.pricedByChildren
																		? ''
																		: moneyFormat(item.taxPrice)}
																</td>
															)}
															<td className='p-r'>{moneyFormat(item.totalFinal)}</td>
															<td className='p-r'>
																{item.deposit && moneyFormat(item.depositTotal)}
																{item.deposit && <div className='p-size-12 italic'>(As {DEPOSIT_TYPE[item.depositType].label})</div>}
															</td>
															<td className='p-r'>{moneyFormat(item.checkout)}</td>
															<td>
																{item.scheduleInfo && (
																	<React.Fragment>
																		<div className='p-mb-1 p-ml-3'>Start: {moment(item.scheduleInfo.start).format(DATETIME_FORMAT_DISPLAY)}</div>
																		<div className='p-mt-2 p-ml-3'>End: {moment(item.scheduleInfo.end).format(DATETIME_FORMAT_DISPLAY)}</div>
																	</React.Fragment>
																)}
															</td>
														</tr>
														{item.childItems &&
															item.childItems.map((childItem) => {
																const childProduct = childItem.product;

																return (
																	<tr key={childItem.id} className='p-datatable-row' style={{ backgroundColor: '#fff' }}>
																		<td className=''>
																			<div className='p-padding-left-30'>
																				{childItem.productName}
																				{childItem.productVariantName && ' - [' + childItem.productVariantName + ']'}
																			</div>
																			{childItem.rentalInfo && (
																				<div className='p-margin-10-0 p-padding-left-30'>
																					- Rent in {childItem.rentalInfo.rentalQty} {childItem.rentalInfo.rentalUnit}
																					{childItem.rentalInfo.rentalQty > 1 ? 's' : ''}
																				</div>
																			)}
																		</td>
																		<td className='p-c'>{childItem.quantity}</td>
																		<td className='p-r'>{moneyFormat(childItem.total)}</td>
																		<td className='p-r'>{moneyFormat(childItem.adjustmentTotal)}</td>
																		{order.pricingMode === PRICING_MODE.normal.value && <td className='p-r'>{moneyFormat(childItem.taxPrice)}</td>}
																		<td className='p-r'>{moneyFormat(childItem.totalFinal)}</td>
																		<td className='p-r'></td>
																		<td className='p-r'></td>
																		<td>
																			{childItem.scheduleInfo && (
																				<React.Fragment>
																					<div className='p-mb-1 p-ml-3'>
																						Start: {moment(childItem.scheduleInfo.start).format(childProduct.courseProgram ? DATE_FORMAT_DISPLAY : DATETIME_FORMAT_DISPLAY)}
																					</div>
																					<div className='p-mt-2 p-ml-3'>
																						End: {moment(childItem.scheduleInfo.end).format(childProduct.courseProgram ? DATE_FORMAT_DISPLAY : DATETIME_FORMAT_DISPLAY)}
																					</div>
																				</React.Fragment>
																			)}
																		</td>
																	</tr>
																);
															})}
													</React.Fragment>
												);
											})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			)}
			{invoice.assetScheduleId && (
				<div className='p-col-12'>
					<div className='card card-w-title'>
						<h1>Asset</h1>

						<DataTable value={[assetSchedule]} responsive={true}>
							<Column field='asset.name' header='Asset' />
							<Column field='status' header='Status' body={(rowData) => ASSET_SCHEDULE_STATUS[rowData.status] && ASSET_SCHEDULE_STATUS[rowData.status].label} />
							<Column field='start' header='Start' body={(rowData) => rowData.start && moment(rowData.start).format(DATETIME_FORMAT_DISPLAY)} />
							<Column field='end' header='End' body={(rowData) => rowData.end && moment(rowData.end).format(DATETIME_FORMAT_DISPLAY)} />
						</DataTable>
					</div>
				</div>
			)}
		</div>
	);
};
