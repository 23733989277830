import React, {} from 'react';

import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import moment from 'moment';

export const ScheduleTimes = (props) => {
    const defaultTime = {sVal: null, eVal: null}
    const times = props.value?.length > 0 ? props.value : [defaultTime]
    
    const addTime = () => {
        let lstTimes = [...times];
        lstTimes.push({...defaultTime});
        props.onChange(lstTimes);
    }

    const removeTime = (idx) => {
        let lstTimes = [...times];
        lstTimes.splice(idx, 1);
        props.onChange(lstTimes);
    }

    const onElementChange = (idx, key, value) => {
        let lstTimes = [...times];
        let timeItem = lstTimes[idx];
        
        switch(key){
            case 'start':
                timeItem.sVal=value;
                break;
            case 'end':
                timeItem.eVal=value;
                break;
            default:
                break;
        }
            
        lstTimes.splice(idx, 1, timeItem);
        props.onChange(lstTimes);
    }

    const onElementBlur = (idx, key) => {
        let lstTimes = [...times];
        let timeItem = lstTimes[idx];

        switch(key){
            case 'start':
                if(!timeItem.sVal || !moment(timeItem.sVal).isValid())
                    timeItem.sVal = null
                break;
            case 'end':
                if(!timeItem.eVal || !moment(timeItem.eVal).isValid())
                    timeItem.eVal = null
                break;
            default: break;
        }

        lstTimes.splice(idx, 1, timeItem);
        props.onChange(lstTimes);
    }

    return (
        <div className="p-grid">
            <div className="p-col-12 p-md-8">
                {times?.length>0 && times.map((time, idx) => {
                    return (
                        <div key={"time_"+idx} className="p-margin-bottom-10">
                            <Fieldset className="p-fieldset-no-legend p-fieldset-padding-sm p-padding-bottom-0">
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-5">
                                        <label className="p-label">* Start Time</label>
                                        <MaskedCalendar value={time.sVal} showIcon={true} onChange={(e) => onElementChange(idx, 'start', e.value)} onBlur={(e) => onElementBlur(idx, 'start', e.target.value)} hourFormat="12" timeOnly={true}/>
                                        <span className="p-form-error">{props.errors['startTime_'+idx]}</span>
                                    </div>
                                    <div className="p-col-5">
                                        <label className="p-label">* End Time</label>
                                        <MaskedCalendar value={time.eVal} showIcon={true} onChange={(e) => onElementChange(idx, 'end', e.value)} onBlur={(e) => onElementBlur(idx, 'end', e.target.value)} hourFormat="12" timeOnly={true}/>
                                        <span className="p-form-error">{props.errors['endTime_'+idx]}</span>
                                    </div>
                                    
                                    {times.length>1 &&
                                    <div className="p-col-2 p-c">
                                        <Button icon="pi-md-close" className="p-button-danger btn-xs" style={{top: '15px'}} onClick={() => removeTime(idx)}/>
                                    </div>
                                    }
                                    {props.errors[idx] && 
                                    <div className="p-form-error">{props.errors[idx]}</div>
                                    }
                                </div>
                            </Fieldset>
                        </div>                           
                    );
                })} 
            </div>
            <div className="p-col-12 p-md-4">
                <Button label="Add time" icon="pi pi-plus" className="p-button-success btn-text-sm" iconPos="left" onClick={() => addTime()}/>
            </div>
        </div>
    )
}