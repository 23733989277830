import React, { useState, useEffect, useRef, useId } from 'react';
import moment from 'moment';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getCurrentUserId, getTablePageReport } from '../../core/service/CommonService';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { getListProducts, getProductAvailabilities } from '../../pim/product/ProductServices';
import {
	APP_FEP,
	DATE_FORMAT_DISPLAY,
	HR_RESOURCE_TYPE,
	OBJECT_TYPE,
	PRODUCT_CLASSIFICATION,
	PRODUCT_TYPE,
	TIME_FORMAT_DISPLAY,
	TRAINING_COURSE_ACTIVITY_CODE,
	TRAINING_COURSE_DIPLOMA_CODE,
} from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { getListAttendanceToCheckin, getPageAttendances, removeAttendee, updateAttendanceCheckedIn } from './AttendanceService';
import { AttendanceForm } from './AttendanceForm';
import { showConfirmNotify, showErrorNotify, showSuccessNotify, showWarnNotify } from '../../core/service/NotificationService';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { getListHRs } from '../../crm/human_resource/HRServices';
import { getDeparmentByCode } from '../departments/DepartmentServices';
import classNames from 'classnames';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { ROLES_CONFIG } from '../../roles';

export const AttendancesCheckin = () => {
	const match = useRouteMatch();
	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
	});

	const [products, setProducts] = useState([]);
	const [courseProducts, setCourseProducts] = useState([]);

	const [diplomas, setDiplomas] = useState([]);
	const [courses, setCourses] = useState([]);
	const [sessions, setSessions] = useState([]);
	const [courseSessions, setCourseSessions] = useState([]);

	const [schedules, setSchedules] = useState([]);

	const [filter, setFilter] = useState({});
	const [isLoadTable, setLoadTable] = useState(false);
	const [limitCourseIds, setLimitCourseIds] = useState([]);
	const [vendors, setVendors] = useState([]);

	const frmAttendance = useRef(null);

	const isLimitByVendor = !isScpAdmin() && hasRole([ROLES_CONFIG.TRAINING_VIEW_BY_VENDOR_R]);

	const isWrite = isScpAdmin() || hasRole([ROLES_CONFIG.TRAINING_ATTENDANCE_MGT_W]);
	const isDelete = isScpAdmin() || hasRole([ROLES_CONFIG.TRAINING_ATTENDANCE_MGT_D]);

	useEffect(() => {
		async function loadDataToFilter() {
			let vendorIds = [];

			if (isLimitByVendor) {
				const userId = getCurrentUserId();

				if (userId) {
					const resVendors = await getListHRs({ userIds: userId ? [userId] : null, type: HR_RESOURCE_TYPE.vendor.value });

					if (!resVendors || resVendors.length === 0) {
						return;
					}
					vendorIds = resVendors.map((v) => v.value);
					setVendors(resVendors);
				}
			}

			const resDepartment = await getDeparmentByCode(TRAINING_COURSE_ACTIVITY_CODE);

			const productFilter = {
				app: APP_FEP,
				departmentIds: resDepartment ? [resDepartment.id] : null,
				hrIds: vendorIds,
				isCourse: true,
			};

			const resProducts = await getListProducts(productFilter, false);

			setProducts(resProducts);

			const tmpDiplomaProducts = resProducts.filter((p) => p.classification === PRODUCT_CLASSIFICATION.combo.value);
			const tmpCourseProducts = resProducts.filter((p) => p.classification === PRODUCT_CLASSIFICATION.simple.value);

			setDiplomas(tmpDiplomaProducts.length > 0 ? tmpDiplomaProducts.map((p) => ({ value: p.id, label: p.name })) : []);
			setCourses(tmpCourseProducts.length > 0 ? tmpCourseProducts.map((p) => ({ value: p.id, label: p.name })) : []);
			setCourseProducts(tmpCourseProducts);
			setLimitCourseIds(tmpCourseProducts.length > 0 ? tmpCourseProducts.map((p) => p.id) : []);
		}

		loadDataToFilter();
	}, []);

	const onDiplomaChange = (val) => {
		let tmpCourses = [];

		if (val) {
			const tmpDiploma = products.find((p) => p.id === val);

			tmpDiploma &&
				tmpDiploma.associatedGroups.forEach((g) => {
					g.associatedProducts.forEach((p) => {
						tmpCourses.push(p.associatedProduct);
					});
				});
		} else {
			tmpCourses = products.filter((p) => p.classification === PRODUCT_CLASSIFICATION.simple.value);
		}

		setCourses(tmpCourses.map((c) => ({ value: c.id, label: c.name })));
		setCourseProducts(tmpCourses);
		setCourseSessions([]);
		setSessions([]);
		setSchedules([]);

		setFilter({
			...filter,
			diplomaIds: val && [val],
			productIds: null,
			sessionIds: null,
			scheduleIds: null,
		});
	};

	const onCourseChange = (val) => {
		let tmpSessions = [];

		if (val) {
			const tmpCourse = products.find((c) => c.id === val);

			if (tmpCourse?.availabilities.length > 0) {
				tmpCourse.availabilities.forEach((s) => {
					tmpSessions.push(s);
				});
			}
		}

		setCourseSessions(tmpSessions);
		setSessions(tmpSessions.map((s) => ({ value: s.id, label: s.name })));
		setSchedules([]);

		setFilter({
			...filter,
			productIds: val && [val],
			sessionIds: null,
			scheduleIds: null,
		});
	};

	const onSessionChange = (val) => {
		let tmpSchedules = [];

		if (val) {
			const tmpSession = courseSessions.find((s) => s.id === val);

			if (tmpSession?.objSchedules.length > 0) {
				tmpSession.objSchedules.forEach((s) => {
					tmpSchedules.push(s);
				});
			}
		}

		setSchedules(tmpSchedules.map((s) => ({ value: s.id, label: renderScheduleLabel(s) })));
		setFilter({
			...filter,
			sessionIds: val && [val],
			scheduleIds: null,
		});
	};

	const renderScheduleLabel = (s) => {
		return (
			moment(s.startDate).format(DATE_FORMAT_DISPLAY) +
			(s.startDate !== s.endDate ? ' -> ' + moment(s.endDate).format(DATE_FORMAT_DISPLAY) : '') +
			' | ' +
			moment(s.startTime, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY) +
			' - ' +
			moment(s.endTime, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY)
		);
	};

	const clearFilter = () => {
		setFilter({});
		onDiplomaChange(null);
		setDataList([]);
		setPageable({
			...pageable,
			page: 0,
			total: 0,
		});
	};

	useEffect(() => {
		if (isLoadTable) loadPageAttendences();
	}, [isLoadTable]);

	const loadPageAttendences = () => {
		let tmpFitler = { ...filter };
		if (isLimitByVendor) {
			if (!vendors || vendors.length === 0) {
				setDataList([]);
				setPageable({ ...pageable, total: 0, page: 0, rows: 10 });
				setLoadTable(false);
				showWarnNotify('No vendor');
				return;
			}
			if (!tmpFitler.courseIds || tmpFitler.courseIds.length === 0) {
				tmpFitler.courseIds = limitCourseIds;
			}
		}

		getListAttendanceToCheckin(filter)
			.then((res) => {
				if (!res.errorCode) {
					setDataList(res);
					setPageable({ ...pageable, total: res?.length, page: 0 });
				} else {
					showErrorNotify(res.errorMessage);
				}
			})
			.catch((e) => showErrorNotify('System error!'))
			.finally(() => setLoadTable(false));
	};

	const onUpdateCheckedIn = (id, checkedIn, anotherSession) => {
		showloading();

		updateAttendanceCheckedIn(id, checkedIn).then((res) => {
			if (!res.errorCode) {
				let tmpList = [...dataList];
				let idx = tmpList.findIndex((a) => a.id === id);

				if (idx !== -1) {
					if (!checkedIn && anotherSession) {
						tmpList.splice(idx, 1);
					} else {
						tmpList[idx].checkedIn = checkedIn;
					}
				}

				setDataList(tmpList);
				showSuccessNotify('Action submitted!');
			} else {
				showErrorNotify('Cannot perform action');
			}

			stoploading();
		});
	};

	const onScheduleChange = (val) => {
		setFilter({ ...filter, scheduleIds: val && [val] });
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
	};

	const onAddAttendance = () => {
		if (!filter.productIds?.length > 0) {
			showErrorNotify('Must select one course to adding attendance');
			return;
		}

		const courseProduct = products.find((p) => p.id === filter.productIds[0]);

		frmAttendance.current.openForm(courseProduct, filter.sessionIds?.length > 0 && filter.sessionIds[0], filter.scheduleIds?.length > 0 && filter.scheduleIds[0]);
	};

	const tblHeader = (
		<TableHeader
			title='Attendance'
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			actionTemplate={isWrite && <Button className='p-button-success' label='Tracking Attendee' icon='pi-md-plus' onClick={() => onAddAttendance()} />}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
		/>
	);

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>Attendances</BreadcrumbsItem>

			<AttendanceForm ref={frmAttendance} courseProducts={courseProducts} onRefresh={() => setLoadTable(true)} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1 className='p-w-bold p-m-0'>Attendances</h1>
					<Fieldset legend='Filters'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12 p-md-6'>
								<div className='p-fluid'>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Diploma:</span>
											<Dropdown
												value={filter.diplomaIds?.length > 0 ? filter.diplomaIds[0] : null}
												options={diplomas}
												dataKey='value'
												showClear
												filter
												onChange={(e) => onDiplomaChange(e.value)}
												placeholder='Select diploma'
											/>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Attendance Name/Email:</span>
											<InputText value={filter.attendanceName} onChange={(e) => setFilter({ ...filter, attendanceName: e.target.value })} />
										</div>
									</div>
								</div>
							</div>
							<div className='p-col-12 p-md-6'>
								<div className='p-fluid'>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>* Course:</span>
											<Dropdown
												value={filter.productIds?.length > 0 ? filter.productIds[0] : null}
												options={courses}
												dataKey='value'
												showClear
												filter
												onChange={(e) => onCourseChange(e.value)}
												placeholder='Select course'
											/>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>* Session:</span>
											<Dropdown
												value={filter.sessionIds?.length > 0 ? filter.sessionIds[0] : null}
												options={sessions}
												dataKey='value'
												showClear
												filter
												onChange={(e) => onSessionChange(e.value)}
												placeholder='Select session'
											/>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>* Schedule:</span>
											<Dropdown
												value={filter.scheduleIds?.length > 0 ? filter.scheduleIds[0] : null}
												options={schedules}
												dataKey='value'
												showClear
												filter
												onChange={(e) => onScheduleChange(e.value)}
												placeholder='Select schedule'
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='p-grid'>
							<div className='p-col-12 p-c'>
								<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={() => loadPageAttendences()} />
								<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Reset' onClick={() => clearFilter()} />
							</div>
						</div>
					</Fieldset>
				</div>

				<DataTable
					emptyMessage="There's no record found"
					header={tblHeader}
					value={dataList}
					lazy={false}
					loading={isLoadTable}
					paginator
					first={pageable.page * pageable.rows}
					rows={pageable.rows}
					totalRecords={pageable.total}
					// sortField={pageable.sortField} sortOrder={pageable.sortOrder}
					onPage={onPage}
					responsive={true}
				>
					<Column style={{ width: '10em' }} header='User ID' field='userId' sortable body={(rowData) => rowData.user?.id} />
					<Column style={{ width: '20em' }} header='Trainee' field='name' sortable body={(rowData) => rowData.user?.name} />
					<Column style={{ width: '20em' }} header='Email' field='email' sortable body={(rowData) => rowData.user?.email} />

					<Column
						style={{ width: '25em' }}
						header='Schedule'
						field='date'
						sortable
						body={(rowData) => {
							return (
								<React.Fragment>
									<div className='p-margin-bottom-5'>Date: {rowData.date && moment(rowData.date).format(DATE_FORMAT_DISPLAY)}</div>
									<div>
										Time: {rowData.timeIn && moment(rowData.timeIn, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY)} -{' '}
										{rowData.timeOut && moment(rowData.timeOut, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY)}
									</div>
								</React.Fragment>
							);
						}}
					/>

					{/* <Column field="date" header="Date" sortable body={(rowData) => rowData.date && moment(rowData.date).format(DATE_FORMAT_DISPLAY)}/>
                    <Column field="timeIn" header="Time In" sortable body={(rowData) => rowData.timeIn && moment(rowData.timeIn, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY)}/>
                    <Column field="timeOut" header="Time Out" sortable body={(rowData) => rowData.timeOut && moment(rowData.timeOut, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY)}/> */}
					<Column
						field='checkedIn'
						header='Check-in'
						sortable
						className='p-c'
						body={(rowData) => (
							<div>
								<span className={classNames('status', { active: rowData.checkedIn, inactive: !rowData.checkedIn })}>{rowData.checkedIn ? 'Checked in' : 'Not yet'}</span>
								{rowData.anotherSession && <div>From another session</div>}
							</div>
						)}
					/>
					<Column header='Trainer' body={(rowData) => rowData?.createdBy?.name} />
					{(isWrite || isDelete) && (
						<Column
							bodyClassName='tc-actions p-r'
							headerStyle={{ width: '15em' }}
							body={(rowData) => {
								if (isWrite) {
									return (
										<Button
											label={rowData.checkedIn ? 'Clear checked-in' : 'Check-in'}
											className={rowData.checkedIn ? 'p-button-danger' : 'p-button-constrast'}
											icon={rowData.checkedIn ? 'pi pi-times' : 'pi pi-check'}
											onClick={() => onUpdateCheckedIn(rowData.id, !rowData.checkedIn, rowData.anotherSession)}
										/>
									);
								}
							}}
						/>
					)}
				</DataTable>
			</div>
		</div>
	);
};
