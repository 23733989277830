import EventEmitter from 'eventemitter3';

export const notificationEvent = new EventEmitter();

export function showNotification(severity, summary, detail) {
  notificationEvent.emit('NOTIFY', {severity, summary, detail});
}

export function showUserNotification(message) {
  notificationEvent.emit('USER_NOTIFICATION', message);
}

export function showInfoNotify(detail, title = '') {
  notificationEvent.emit('NOTIFY', {severity: 'info', summary: title, detail});
}

export function showWarnNotify(detail, title = '') {
  notificationEvent.emit('NOTIFY', {severity: 'warn', summary: title, detail});
}

export function showErrorNotify(detail, title = 'Error Message') {
  notificationEvent.emit('NOTIFY', {severity: 'error', summary: title, detail});
}

export function showSuccessNotify(detail, title = 'Success Message') {
  notificationEvent.emit('NOTIFY', {severity: 'success', summary: title, detail});
}
export function showInfoNotifySticky(detail, title = '') {
  notificationEvent.emit('NOTIFY', {sticky: true, severity: 'info', summary: title, detail});
}

export function showWarnNotifySticky(detail, title = '') {
  notificationEvent.emit('NOTIFY', {sticky: true, severity: 'warn', summary: title, detail});
}

export function showErrorNotifySticky(detail, title = '') {
  notificationEvent.emit('NOTIFY', {sticky: true, severity: 'error', summary: title, detail});
}

export function showSuccessNotifySticky(detail, title = '') {
  notificationEvent.emit('NOTIFY', {sticky: true, severity: 'success', summary: title, detail});
}

export function showConfirmNotify(dialogContent){
  notificationEvent.emit('CONFIRM_NOTIFY', dialogContent)
}