import React, {Component} from "react";
import { ProgramServices } from "../../crm/program/ProgramServices";
import { Sidebar } from "primereact/sidebar";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { CampaignServices } from "./CampaignServices";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showNotification } from '../../core/service/NotificationService';

export class CampaignActionForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            campaign: {},
            programNodes: [],
            expandedKeys: [],
            action: 'active',
            chkAll: false,
            programChks: {},
            actionPrograms: [],
            visible: false,
            formHeader: 'Enable Courses',
            errors: {}
        }
        this.programServices = new ProgramServices();
        this.campaignServices = new CampaignServices();
    }

    action = (data) => {
        this.setState({
            campaign: data,
            expandedKeys: [],
            programNodes: [],
            action: 'active',
            programChks: {},
            actionPrograms: [],
            visible: true
        },() => this.getTreePrograms());
    }

    handleSubmitForm = () => {
        showloading();
        this.campaignServices.actionToPrograms(this.state.campaign.id, this.state.actionPrograms).then(res => {
            stoploading();
            if(!res.errorCode){
                this.getTreePrograms();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    getTreePrograms = () => {
        showloading();
        this.programServices.getTreePrograms(this.state.campaign.id)
        .then(res => {
            stoploading();
            this.setState({
                programNodes: res
            },() => { // checked course is active
                let state = this;
                let tempChkObj = this.state.programChks;
                let tempArrToAction = [...this.state.actionPrograms];
                let numEleNode = 0, numEleNodeChk = 0;
                this.state.programNodes.map(parent => {
                    let parentId = parent.data.id;
                    numEleNode += (parent.children.length + 1);
                    parent.children.map(child => {
                        if(child.data.active){
                            if(!tempChkObj[parentId]) tempChkObj[parentId] = [];
                            state.excuteList(true, tempChkObj[parentId], child.data.id);
                            state.excuteList(true, tempArrToAction, child.data.id);
                        }
                    })
                })
                Object.entries(tempChkObj).map(([key, item])=> {
                    numEleNodeChk += (tempChkObj[key].length + 1) // length of key +1(key)
                })
                this.setState({
                    chkAll: numEleNode===numEleNodeChk ? true : false,
                    programChks: tempChkObj,
                    actionPrograms: tempArrToAction
                })
            });
        });
    }

    onCheckProgram = (e, parentId) => {
        let state = this;
        let value = e.value;
        let tempTreeNodes = [...this.state.programNodes];
        let tempChkObj = this.state.programChks;
        let tempArrToAction = [...this.state.actionPrograms];
        let numEleNode = 0, numEleNodeChk = 0;
        tempTreeNodes.map(parent => {
            numEleNode += (parent.children.length + 1); //parent + child
        })
        if(parentId){
            tempTreeNodes.map(parent => {
                if(parent.data.id === parentId){
                    parent.children.map(child => {
                        if(child.data.id === value){
                            if(!tempChkObj[parentId]) tempChkObj[parentId] = [];
                            state.excuteList(e.checked, tempChkObj[parentId], value);
                            state.excuteList(e.checked, tempArrToAction, value);
                        }
                    })
                }
            })
        }else{
            tempTreeNodes.map(parent => {
                if(parent.data.id === value){
                    let tempArr = [];
                    parent.children.map(child => {
                        state.excuteList(e.checked, tempArr, child.data.id);
                        state.excuteList(e.checked, tempArrToAction, child.data.id);
                    })
                    tempChkObj = {...tempChkObj,
                        [value]: tempArr
                    }
                }
            })
        }
        Object.entries(tempChkObj).map(([key, item])=> {
            numEleNodeChk += (tempChkObj[key].length + 1) // length of key +1(key)
        })
        this.setState({
            chkAll: numEleNode===numEleNodeChk ? true : false,
            programChks: tempChkObj,
            actionPrograms: tempArrToAction
        })
    }

    excuteList = (excute, list, value) => {
        if(excute){
            if(!list.includes(value)){
                list.push(value)
            }
        }else{
            list.splice(list.indexOf(value), 1);
        }
    }

    onCheckAll = (chkAll) => {
        let tempTreeNodes = [...this.state.programNodes];
        let tempChkObj = this.state.programChks;
        let tempArrToAction = [...this.state.actionPrograms];
        let state = this;
        tempTreeNodes.map(parent => {
            let tempArr = [];
            parent.children.map(child => {
                state.excuteList(chkAll, tempArr, child.data.id);
                state.excuteList(chkAll, tempArrToAction, child.data.id);
            })
            tempChkObj = {...tempChkObj,
                [parent.data.id]: tempArr
            }
        })
        this.setState({
            chkAll: chkAll,
            programChks: tempChkObj,
            actionPrograms: tempArrToAction
        })
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    render(){
        return (
                <Sidebar visible={this.state.visible} style={{overflowY: 'auto', width:'60em'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.onHide}>
                    <h2>{this.state.formHeader}</h2>
                    
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-6">
                            <label>Campaign: <span className="p-w-bold">{this.state.campaign.code} - {this.state.campaign.name}</span></label>
                        </div>
                        <div className="p-col-12">
                            <TreeTable value={this.state.programNodes} header={<h3 className="p-margin-none">Courses</h3>} expandedKeys={this.state.expandedKeys}
                                onToggle={(e) => this.setState({expandedKeys: e.value})} style={{marginTop: '.5em'}}
                            >
                                <Column field="name" header="Name" expander/>
                                <Column field="code" header="Code"/>
                                <Column field="active" header="Status" body={(rowData, column) => {return rowData.data.type === 'course' ? (rowData.data.active ? 'Active' : 'Inactive') : ''}}/>
                                <Column field="" style={{textAlign:'center',width:'4em'}}
                                    header={ <Checkbox checked={this.state.chkAll} onChange={(e) => this.onCheckAll(e.checked)} /> }
                                    body={(node) => {
                                        let parent = node.data.parentId;
                                        let data = node.data;
                                        return (
                                            <React.Fragment>
                                                {parent ? 
                                                    <Checkbox value={node.data.id} checked={this.state.actionPrograms.includes(node.data.id)} onChange={(e) => this.onCheckProgram(e, parent)} />
                                                : <Checkbox value={node.data.id} onChange={(e) => this.onCheckProgram(e, parent)} 
                                                    checked={this.state.programChks[data.id] && node.children && this.state.programChks[data.id].length===node.children.length ? true: false } />
                                                    }
                                            </React.Fragment>
                                        );
                                    }}
                                />
                            </TreeTable>
                        </div>
                        
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handleSubmitForm()}/>
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                        </div>
                    </div>
                </Sidebar>
        );
    }
}