import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { saveGroup } from "./GroupServices";
import { showErrorNotify, showSuccessNotify} from '../../core/service/NotificationService';
import { Sidebar } from "primereact/sidebar";
import { titleCaseText } from "../../core/service/CommonService";
import { UPLOAD_TYPE, ATTRIBUTE_VALUE_LIST_TYPE, MAX_FILE_SIZE } from "../../constants";
import { Chips } from "primereact/chips";
import { Fieldset } from "primereact/fieldset";
import PhoneInput from "react-phone-number-input";
import { Dropdown } from "primereact/dropdown";
import { getListAttributeValueList } from "../../scp/attribute/AttributeServices";

export const GroupForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("New Content")
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [errors, setErrors] = useState({})
    const [businessTypes, setBusinessTypes] = useState([])

    const inputUpload = useRef(null)
    
    let defaultCountry = localStorage.getItem('defaultCountry')
    defaultCountry = defaultCountry && defaultCountry!=='null' && defaultCountry!=='undefined' ? defaultCountry : null

    useImperativeHandle(ref, () => ({
        openForm(data, type){
            setHeader(data?'Edit Group':'New Group')
            setVisible(true)
            setFrmData(popularFormData(data, type))
        },
        closeForm(){
            closeForm()
        }
    }))

    useEffect(() => {
        loadCompanyBusinessTypes()
    }, [])

    const loadCompanyBusinessTypes = () => {
        getListAttributeValueList(ATTRIBUTE_VALUE_LIST_TYPE.company_business_type.value, false)
        .then(res => {
            let tmpValues = []
            if(res && res.length>0){
                tmpValues = res[0].values.map(v => ({value: v, label: v}))
            }
            setBusinessTypes(tmpValues)
        })
    }

    const popularFormData = (data, type) => {
        return {
            id: data ? data.id : '',
            type: data ? data.type : type,
            name: data&&data.name ? data.name : '',
            contactTitle: data&&data.contactTitle?data.contactTitle:'',
            contactName: data&&data.contactName ? data.contactName : '',
            contactEmail: data&&data.contactEmail ? data.contactEmail : '',
            contactPhone: data&&data.contactPhone ? data.contactPhone : '',
            url: data&&data.url ? data.url : '',
            imageInfo: data&&data.imageInfo&&Object.keys(data.imageInfo).length>0?data.imageInfo:null,
            uploadType: data&&data.imageInfo&&data.imageInfo.fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value,
            externalImageLink: data&&data.imageInfo?(data.imageInfo.fileName?'':data.imageInfo.url):'',
            businessType: data&&data.businessType?data.businessType:null
        }
    }

    const handleSave = () => {
        setErrors({})

        if(frmData.file && frmData.uploadType===UPLOAD_TYPE.upload_file.value){
            let fileSize = frmData.file.size
            if(fileSize > MAX_FILE_SIZE){
                setErrors({image: 'Size not allowed (maximum: 100MB)'})
                showErrorNotify('Cannot perform action');
                return
            }
        }

        saveGroup(frmData)
        .then(res => {
            if(!res.errorCode){
                if (props.refreshTable) {
                    props.refreshTable();
                } 
                if(props.refreshData) {
                    props.refreshData(res);
                }
                closeForm()
                showSuccessNotify('Action submitted');
            }else{
                showErrorNotify('Cannot perform action');
                if(res.errorCode===400)
                    setErrors(res.errorObj);
            }
        });
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setErrors({})
    }

    return (
        <Sidebar visible={visible} position="right" className="p-sidebar-md" onHide={(e) => closeForm()} blockScroll={true} showCloseIcon={false} dismissable={true} style={{overflowY: 'auto'}}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid p-fluid form-group">
                <div className="p-col-12">
                    <Fieldset legend="Basic Information">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <label className="p-label">* Name</label>
                                <InputText value={frmData.name} onChange={(e) => setFrmData({...frmData, name: titleCaseText(e.target.value)})} />
                                <span className="p-form-error">{errors.name}</span>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Url</label>
                                <InputText value={frmData.url} onChange={(e) => setFrmData({...frmData, url: e.target.value})} />
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Logo</label>
                                <div className="p-inputgroup">
                                    {(!frmData.uploadType || frmData.uploadType===UPLOAD_TYPE.external_url.value) ?
                                        <React.Fragment>
                                            <InputText value={frmData.externalImageLink} onChange={(e) => setFrmData({...frmData, externalImageLink: e.target.value})} />
                                            <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.upload_file.value})} />
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {frmData.imageInfo && frmData.imageInfo.fileName &&
                                                <span className="p-inputgroup-addon"><img src={frmData.imageInfo.url} alt={frmData.imageInfo.fileName} style={{width: 25, height: 25}} /></span>
                                            }
                                            <input ref={inputUpload} className="p-inputtext p-component" type="file" accept="image/png, image/jpg, image/jpeg" onChange={(e) => setFrmData({...frmData, file: e.target.files[0]})} />
                                            <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.external_url.value, file: null})} />
                                        </React.Fragment> 
                                    }
                                </div>
                                <div className="p-form-error">{errors.image}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Business Type</label>
                                <Dropdown value={frmData.businessType} options={businessTypes} onChange={(e) => setFrmData({...frmData, businessType: e.value})} placeholder="Select business type"/>
                            </div>
                        </div>    
                    </Fieldset>    
                </div>
                <div className="p-col-12">
                    <Fieldset legend="Contact Information">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <label className="p-label">Contact Title</label>
                                <InputText value={frmData.contactTitle} onChange={(e) => setFrmData({...frmData, contactTitle: titleCaseText(e.target.value)})} />
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Contact Name</label>
                                <InputText value={frmData.contactName} onChange={(e) => setFrmData({...frmData, contactName: titleCaseText(e.target.value)})} />
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Contact Email</label>
                                <InputText value={frmData.contactEmail} keyfilter="email" onChange={(e) => setFrmData({...frmData, contactEmail: e.target.value})} />
                                <div className="p-form-error">{errors.contactEmail}</div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Contact Phone</label>
                                <PhoneInput value={frmData.contactPhone} defaultCountry={defaultCountry} onChange={(e) => setFrmData({...frmData, contactPhone: e})} className="p-component p-inputtext p-phoneinput" style={{width: "100%"}}/>
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => closeForm()} />
                    <Button label="Submit" icon="pi pi-check" onClick={()=> handleSave()} />
                </div>
            </div>
        </Sidebar>
    )
})