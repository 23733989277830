import { CURRENCY_OBJECT, DATETIME_FORMAT_DISPLAY, ORDER_ITEM_STATUS } from '../../constants';
import moment, { HTML5_FMT } from 'moment-timezone';
import { formatMoney } from 'accounting-js';

export function getRootDomain() {
	const url = window.location.href;
	const urlArr = url.split('/');
	return urlArr[0] + '//' + urlArr[2];
}

export function replaceSpaceAndUpperCase(data) {
	let str = data.toUpperCase().replace(/ /g, '_');
	return str;
}

export function titleCaseText(data) {
	let str;
	if (data) str = data.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
	return str;
}

export function upperCaseField(data) {
	let str = data.toUpperCase();
	return str;
}

export function getRandomString(length) {
	var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var result = '';
	for (var i = 0; i < length; i++) {
		result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
	}
	return result;
}

export function getTablePageReport(page, rows, total) {
	if (total > 0) {
		let first = page * rows + 1;
		let last = page * rows + rows;
		if (last > total) last = total;
		return `Showing ${first} to ${last} of ${total} entries`;
	}
	return '';
}

export function translateOrderItemStatus(trans, status) {
	switch (status) {
		case ORDER_ITEM_STATUS.pending:
			return trans('res.mgt.delivery_pending');
		case ORDER_ITEM_STATUS.waiting:
			return trans('res.mgt.waiting');
		case ORDER_ITEM_STATUS.processing:
			return trans('res.mgt.dish_processing');
		case ORDER_ITEM_STATUS.completed:
			return trans('res.mgt.completed');
		case ORDER_ITEM_STATUS.canceled:
			return trans('res.mgt.canceled');
		default:
			return '';
	}
}

export function getCurrencySymbol(currency) {
	const currencyObj = CURRENCY_OBJECT[currency];
	return currencyObj ? currencyObj.symbol : '$';
}

export function getDecimalPlaces(currency) {
	const currencyObj = CURRENCY_OBJECT[currency];
	return currencyObj ? currencyObj.decimal : '2';
}

export function moneyFormat(amount, currency = localStorage.getItem('currency')) {
	const currencyObj = CURRENCY_OBJECT[currency];
	return formatMoney(amount, { symbol: currencyObj ? currencyObj.symbol : '$', format: '%s%v', precision: currencyObj ? currencyObj.decimal : '2', thousand: ',', decimal: '.' });
}

export function numberSuffixOf(num) {
	let j = num % 10,
		k = num % 100;
	if (j === 1 && k !== 11) {
		return num + 'st';
	}
	if (j === 2 && k !== 12) {
		return num + 'nd';
	}
	if (j === 3 && k !== 13) {
		return num + 'rd';
	}
	return num + 'th';
}

export function converObjetToFormData(formData, objKey, objValue) {
	if (objValue || objValue === 0 || objValue === false) {
		if (typeof objValue === 'object') {
			if (objValue instanceof Array) {
				let i = 0;
				objValue.map((item) => {
					converObjetToFormData(formData, objKey + '[' + i + ']', item);
					i++;
				});
			} else if (objValue instanceof File) {
				formData.append(objKey, objValue);
			} else if (Object.keys(objValue).length > 0) {
				for (const [keyObjData, valueObjData] of Object.entries(objValue)) {
					converObjetToFormData(formData, objKey + '.' + keyObjData, valueObjData);
				}
			}
		} else {
			formData.append(objKey, objValue);
		}
	}

	return formData;
}

export function phoneNumberFormat(phoneObj) {
	String.prototype.splice = function (idx, rem, str) {
		return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
	};
	if (phoneObj.countryCode) {
		console.log(phoneObj);
		switch (phoneObj.countryCode) {
			case 'SG':
				return '+' + phoneObj.dialCode + '-' + phoneObj.phoneNumber.splice(4, 0, '-');
			default:
				return '+' + phoneObj.dialCode + phoneObj.phoneNumber;
		}
	}
}

export function getTimeZone() {
	const tz = localStorage.getItem('timezone');

	if (tz && tz !== 'null' && tz !== 'undefined') return tz;

	return null;
}

export function getAppConfig() {
	const config = localStorage.getItem('appConfig');

	if (config && config !== 'null' && config !== 'undefined') return JSON.parse(config);

	return null;
}

export function getDefaultCountry() {
	let defaultCountry = localStorage.getItem('defaultCountry');
	defaultCountry = defaultCountry && defaultCountry !== 'null' && defaultCountry !== 'undefined' ? defaultCountry : null;

	return defaultCountry;
}

export function getCurrentUserId() {
	const userId = localStorage.getItem('user_id');
	return userId && userId !== 'undefined' && userId !== 'null' ? parseInt(userId) : null;
}

export function isInValidDateTime(strStart, strEnd, includeTime) {
	const fmt = `YYYY-MM-DD${includeTime ? ' HH:mm' : ''}`;
	const strNow = moment.tz(getTimeZone()).format(fmt);

	const now = moment(strNow);
	const start = strStart ? moment(strStart) : null;
	const end = strEnd ? moment(strEnd) : null;

	if (!start || !end) return true;

	if (start && !end) return now.isSameOrAfter(start);

	if (!start && end) return now.isSameOrBefore(end);

	return now.isSameOrAfter(start) && now.isSameOrBefore(end);
}
