import React, { Component } from 'react';
import { MembershipTemplateServices } from "./MembershipTemplateServices";
import { Fieldset } from 'primereact/fieldset';
import { InputText } from "primereact/inputtext";
import { Spinner } from 'primereact/spinner';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';

import {GLAccountServices} from "../../scp/accounting/gl_accounts/GLAccountServices";
import {showNotification} from '../../core/service/NotificationService';
import { Checkbox } from 'primereact/checkbox';
import { replaceSpaceAndUpperCase, titleCaseText, upperCaseField } from "../../core/service/CommonService";

export class MembershipTemplateLevelForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templateLevel: this.props.data==null?{}:this.props.data,
            errors: {}
        }
        this.membershipTemplateServices = new MembershipTemplateServices();
        this.glAccountServices = new GLAccountServices();
    }

    componentDidMount(){
        this.loadAllGLAccounts();
    }

    loadAllGLAccounts = () =>{
        this.glAccountServices.getAccountList(null, 'Membership').then(res => {this.setState({glAccounts: res})})
    }

    popularFormData = (data) => {
        this.setState({
            templateLevel: {
                id: data ? data.id : '',
                name: data ? data.name : '',
                description: data ? data.description : '',
                subTitle: data ? data.subTitle : '',
                code: data ? data.code : '',
                type: data ? data.type : 'Individual',
                glAccount: data ? data.glAccount : '123',
                joinPrice: data ? data.joinPrice : '0.00',
                joiningFeeGlAccount: data ? data.joiningFeeGlAccount : '0.00',
                applyTax: data ? data.applyTax : false,
                active: data ? data.active : true,
                weight: data ? data.weight : 0,
                online: true
            }, 
            errors: {}
        });
    }

    resetForm = () => {
        this.popularFormData(null);
    }

    handleSaveMembershipTemplateLevel = () => {
        this.membershipTemplateServices.saveMembershipTemplateLevel(this.props.parent, this.state.templateLevel).then(res => {
            if(!res.errorCode){
                if (!this.state.templateLevel.id) {
                    this.props.reloadTable();
                    this.resetForm();
                    this.props.hideFormDialog();
                } else {
                    this.props.refreshData(res);
                }
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        });
    }

    render() {
        const membershipTypes = [
            { label: 'Individual', value: 'Individual' },
            // { label: 'Family', value: 'Family' }
            { label: 'Group', value: 'Group' }
        ];

        return (
            <div className="p-grid">
                <div className="p-col-12 p-md-12">
                    <Fieldset legend="Basic Information">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <label className="p-label">Type</label>
                                <Dropdown id="type" value={this.state.templateLevel.type} options={membershipTypes} onChange={(e) => this.setState({ templateLevel: { ...this.state.templateLevel, type: e.value } })} style={{ width: '100%' }} placeholder="Select a type" />
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">* Name</label>
                                <InputText value={this.state.templateLevel.name} onChange={(e) => this.setState({ templateLevel: { ...this.state.templateLevel, name: titleCaseText(e.target.value) } })} />
                                <div className="p-form-error">{this.state.errors.name} </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label className="p-label">Code</label>
                                <InputText value={this.state.templateLevel.code} onChange={(e) => this.setState({ templateLevel: { ...this.state.templateLevel, code: upperCaseField(e.target.value) } })} />
                                <div className="p-form-error">{this.state.errors.code} </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label className="p-label">Sub title</label>
                                <InputText value={this.state.templateLevel.subTitle} onChange={(e) => this.setState({ templateLevel: { ...this.state.templateLevel, subTitle: e.target.value } })} />
                                <div className="p-form-error">{this.state.errors.subTitle} </div>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Description</label>
                                <InputTextarea id="description" rows={3} cols={100} value={this.state.templateLevel.description} placeholder="Description" onChange={(e) => this.setState({ templateLevel: { ...this.state.templateLevel, description: e.target.value } })} />
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label className="p-label">Join Price ($)</label>
                                <Spinner value={this.state.templateLevel.joinPrice} step={0.25} onChange={(e) => this.setState({ templateLevel: { ...this.state.templateLevel, joinPrice: e.target.value } })} />
                                <div className="p-form-error">{this.state.errors.joinPrice} </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <label className="p-label">Weight (for order)</label>
                                <InputText value={this.state.templateLevel.weight} keyfilter="int" onChange={(e) => this.setState({templateLevel: {...this.state.templateLevel, weight: e.target.value}})} />
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Active?</label>
                                <InputSwitch onLabel="Yes" offLabel="No" checked={this.state.templateLevel.active} onChange={(e) => this.setState({ templateLevel: { ...this.state.templateLevel, active: e.target.value } })} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12 p-md-5" 
                style={{display: "none"}}
                >
                    <Fieldset legend="Price Information">
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-12">
                                <label className="p-label">* Subscription GL Account</label>
                                <Dropdown value={this.state.templateLevel.glAccount} options={this.state.glAccounts} showClear={true} filter={true} onChange={(e) => this.setState({templateLevel: { ...this.state.templateLevel, glAccount: e.value}})} placeholder="Please select" style={{width: "100%"}} />
                                <div className="p-form-error">{this.state.errors.glAccount}</div>
                            </div>
                            
                            <div className="p-col-12">
                                <label className="p-label">Join Price GL Account</label>
                                <Dropdown value={this.state.templateLevel.joiningFeeGlAccount} options={this.state.glAccounts} showClear={true} filter={true} onChange={(e) => this.setState({templateLevel: { ...this.state.templateLevel, joiningFeeGlAccount: e.value}})} placeholder="Please select" style={{width: "100%"}} />
                            </div>
                            <div className="p-col-12">
                                <Checkbox inputId="cbApplyTax" checked={this.state.templateLevel.applyTax} onChange={(e) => (this.setState({templateLevel: { ...this.state.templateLevel, applyTax: e.checked}}))} />
                                <label htmlFor="cbApplyTax" className="p-margin-left-5">Apply tax?</label>
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className={"p-col-12" + (!this.props.submitButtonVisible ? ' p-hide' : '')}>
                    <Button label="Save Information" icon="pi pi-save" iconPos="left" style={{ 'float': 'right' }} onClick={this.handleSaveMembershipTemplateLevel} />
                </div>
            </div>
        );
    }
}
