import React, { Component } from "react";
import { Button } from "primereact/button";
import { FieldWrapper } from "../../core/components/FieldWrapper";
import { Fieldset } from "primereact/fieldset";
import { Dialog } from "primereact/dialog";
import MembershipService from "./MembershipService";
import { showErrorNotify, showSuccessNotify } from "../../core/service/NotificationService";

const STATUS = {
    PAID: "paid",
    PENDING: "pending"
}

class PaymentInformationDialog extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            display: false,
        };

        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.membershipService = new MembershipService();
    }

    openDialog = (data) => {
        this.setState({
            data: data,
            display: true,
        })
        console.log(data);
    }

    onClick() {
        this.setState({
            display: true,
        });
    }

    onHide() {
        this.setState({
            display: false,
        });
    }

    onSubmit = () => {
        this.membershipService.updateMembershipDocumentStatus(this.state.data.id, STATUS.PAID, false)
        .then(res => {
            if(res.status == "200"){
                this.props.onSubmit(this.state.data.id)
                this.onHide()
                showSuccessNotify('Action submitted', "Success");
            }else{
                showErrorNotify('Cannot perform action', "Error");
            }
        })
        
    }

    renderFooter(name) {
        return (
            <div>
                <Button
                    label="Submit"
                    icon="pi pi-check"
                    onClick={() => this.onSubmit()}
                    autoFocus
                />
                <Button
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={() => this.onHide(name)}
                    className="p-button-text"
                />
            </div>
        );
    }

    render() {
        return (
            <div>
                <Dialog
                    header="Payment Information"
                    footer={this.renderFooter()}
                    visible={this.state.display}
                    style={{ width: "30vw" }}
                    onHide={() => this.onHide()}
                >
                    {this.state.data &&
                        <Fieldset legend="Basic Information">
                            <div className="p-grid p-fluid form-group">
                                <FieldWrapper
                                    col="p-col-12 p-md-12"
                                    keyField="name"
                                >
                                    <label className="p-label">Fullname : </label>
                                    <div className="p-w-bold">{`${this.state.data.user.firstName} ${this.state.data.user.lastName}`}</div>
                                </FieldWrapper>
                                <FieldWrapper
                                    col="p-col-12 p-md-12"
                                    keyField="Membership"
                                >
                                    <label className="p-label">Membership Type: </label>
                                    <div className="p-w-bold">{this.state.data.membership.name}</div>
                                </FieldWrapper>
                                <FieldWrapper
                                    col="p-col-12 p-md-12"
                                    keyField="price"
                                >
                                    <label className="p-label">Price: </label>
                                    <div className="p-w-bold">S${this.state.data.membership.price}</div>
                                </FieldWrapper>
                                <div className="p-col-12 p-md-12">
                                    <label className="p-label">Duration: </label>
                                    <div className="p-w-bold">{this.state.data.duration} {this.state.data.unit}</div>

                                </div>
                                <FieldWrapper
                                    col="p-col-12 p-md-12"
                                    keyField="payment"
                                >
                                    <label className="p-label">
                                        Payment Method:
                                    </label>
                                    <div className="p-w-bold">Pay Directly</div>
                                </FieldWrapper>
                            </div>
                        </Fieldset>
                    }
                </Dialog>
            </div>
        );
    }
}

export default PaymentInformationDialog;
