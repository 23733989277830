import axios from 'axios';
import { PAYMENT_BASE_URL } from '../../constants';

export function getTenentPayments(simple) {
    return axios.get(`${PAYMENT_BASE_URL}/tenant-payment/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}

export function saveTenantPayment(data) {
    return axios.post(`${PAYMENT_BASE_URL}/tenant-payment`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function removeTenantPayment(id) {
    return axios.delete(`${PAYMENT_BASE_URL}/tenant-payment/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getOnlinePaymentGateway(id) {
    return axios.get(`${PAYMENT_BASE_URL}/tenant-payment/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function createHitPayPayment(gateWayId, orderNumber, appOrigin, data){
    return axios.post(`${PAYMENT_BASE_URL}/hitpay/${gateWayId}/create-payment`, data, {params: {gateWayId, orderNumber, appOrigin}})
    .then(res => res.data).catch(error => console.log(error));
}