export const minLengthValidator = (value, minLength, errorMsg = null) => {
    const msg = errorMsg || `Field length must be greater than ${minLength}`;
    return value.length >= minLength ? null : msg;
}

export const maxLengthValidator = (value, maxLength, errorMsg = null) => {
    const msg = errorMsg || `Field length must be less than ${maxLength}`;
    return value.length <= maxLength ? null : msg;
}

export const requiredValidator = (value, errorMsg = null) => {
    const msg = errorMsg || 'This field is required';
    return value === null || value === undefined || value === '' ? msg : null;	
}

export const notEmptyValidator = (value, errorMsg = null) => {
    const msg = errorMsg || `This field must be empty`;
    return value.toString().trim() !== '' ? null : msg;	
}

export const emailValidator = (value, errorMsg = null) => {
    const msg = errorMsg || 'This email is in correct format (ex. john@doe.com)';
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase()) ? null : msg;
}

export const minValidator = (value, min, errorMsg = null) => {
    const msg = errorMsg || `This field must be greater or equals ${min}`;
    return +value >= min ? null : msg;
}

export const maxValidator = (value, max, errorMsg = null) => {
    const msg = errorMsg || `This field must be less or equals ${max}`;
    return +value <= max ? null : msg;
}

export const rangeValidator = (value, [min, max], errorMsg = null) => {
    const msg = errorMsg || `This field must be from ${min} to ${max}`;
    return +value >= min && +value <= max ? null : msg;
}

export const patternValidator = (value, pattern, errorMsg) => {
    const msg = errorMsg || 'This field does not meet pattern';
    return pattern.test(String(value)) ? null : msg;
}

export const isValidEmail = (value) => {
    if(!value) return false;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase())
}

export default {
    minLengthValidator,
    maxLengthValidator,
    requiredValidator,
    notEmptyValidator,
    emailValidator,
    minValidator,
    maxValidator,
    rangeValidator,
    patternValidator
};
