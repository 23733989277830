import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { DOCUMENT_TYPE } from "../../constants";
import { FieldWrapper } from "../../core/components/FieldWrapper";
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import MembershipService from "./MembershipService";

class WpInformationDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            display: false,
            pressCheckDuplicate: false,
            docNumber: "",
            isDuplicate: false
        };

        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.membershipService = new MembershipService();
    }

    openDialog = (data) => {
        let workPermitRecord = data ? data.identityDocument : null
        let workPermitNum = workPermitRecord !== null ? workPermitRecord.docNumber : ''
        this.setState({
            data: data,
            docNumber: workPermitNum,
            display: true
        })
    }

    onClick() {
        this.setState({
            display: true,
        });
    }

    onVerify = () => {
        this.membershipService.updateMembershipDocumentNumber(this.state.data.id, this.state.docNumber, false).then(res => {
            if(res.status == "200"){
                showSuccessNotify("Verified successfully", "Success");
                this.membershipService.updateMembershipVerifyStatus(this.state.data.id, true, false).then(resp => {
                    if (resp.status == "200") {
                        showSuccessNotify("Verified successfully", "Success");
                    } else {
                        showErrorNotify("Update verify status failed", "Error");
                    }
                })
            }else{
                showErrorNotify("Verified failed", "Error");
            }
        }).then(() => {
            this.setState({
                display: false,
                pressCheckDuplicate: false,
                isDuplicate: false
            }, () => {
                this.props.onVerify(this.state.data.id)
            });
        })
    }

    onHide(name) {
        this.setState({
            display: false,
            pressCheckDuplicate: false,
            isDuplicate: false
        });
    }

    renderFooter() {
        return (
            <div>
                <Button
                    label="Verify"
                    icon="pi pi-check"
                    onClick={() => this.onVerify()}
                    autoFocus
                    disabled={!this.state.pressCheckDuplicate || this.state.isDuplicate}
                />
                <Button
                    label="Cancel"
                    icon="pi pi-times"
                    onClick={() => this.onHide()}
                    className="p-button-text"
                />
            </div>
        );
    }

    checkDocumentNumberDupplicate(id) {
        this.membershipService.checkDocumentNumberDupplicate(false , id).then(res=>{
            this.setState({
                ...this.state,
                isDuplicate: res,
                pressCheckDuplicate: true
            })
        });
    }

    render() {
        return (
            <Dialog
                header="WP Information"
                visible={this.state.display}
                style={{
                    width: "50vw",
                    minWidth: "400px",
                    maxWidth: "600px",
                }}
                footer={this.renderFooter()}
                onHide={() => this.onHide()}
            >
                {this.state.data &&
                    <Fieldset legend="Basic Information">
                        <div className="p-grid p-fluid form-group">
                            <FieldWrapper
                                col="p-col-12 p-md-12"
                                keyField="name"
                            >
                                <label className="p-label">Fullname: </label>
                                <div className="p-w-bold">
                                    {this.state.data.user.name}
                                </div>
                            </FieldWrapper>
                            <div className="p-col-12 p-md-12 p-grid">
                                <FieldWrapper
                                    col="p-col-12 p-md-4"
                                    keyField="WP Number"
                                >
                                    <label className="p-label">
                                        {" "}
                                        WP Numbers:{" "}
                                    </label>
                                    <InputText value={this.state.docNumber} onChange={(e)=>{this.setState({docNumber : e.target.value})}}/>
                                </FieldWrapper>
                                <div className="p-col-12 p-md-7 p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <a
                                            href={`/monitoring/merge-duplicate/${this.state.data.id}`}
                                            className={this.state.pressCheckDuplicate && this.state.isDuplicate
                                                ? "p-button blue-btn p-d-flex p-align-center ": "p-hide"}
                                        >Merge Duplicate</a>
                                    </div>

                                    <div className="p-col-12 p-md-6">
                                        <Button
                                            label="Check Duplicate"
                                            onClick={() => {
                                                this.checkDocumentNumberDupplicate(this.state.data.id);
                                            }}
                                        ></Button>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-1">
                                    <i
                                        className="pi pi-check"
                                        style={{
                                            color: "green",
                                            fontWeight: "bold",
                                            fontSize: "2em",
                                            display:
                                                this.state
                                                    .pressCheckDuplicate &&
                                                !this.state.isDuplicate
                                                    ? "block"
                                                    : "none",
                                        }}
                                    ></i>
                                </div>
                                <div
                                    className="p-col-12 p-md-6"
                                    style={{
                                        display:
                                            this.state
                                                .pressCheckDuplicate &&
                                            this.state.isDuplicate
                                                ? "block"
                                                : "none",
                                    }}
                                >
                                    <p
                                        style={{
                                            fontWeight: "bold",
                                            color: "red",
                                        }}
                                    >
                                        Duplicate found!
                                    </p>
                                </div>
                            </div>

                            <FieldWrapper
                                col="p-col-12 p-md-12"
                                keyField="lastName"
                            >
                                <label className="p-label"> Expired At: </label>
                                <div className="p-w-bold">
                                    {moment(this.state.data.identityDocument.expiredDate).format(
                                        "YYYY-MM-DD"
                                    )}
                                </div>
                            </FieldWrapper>
                        </div>
                    </Fieldset>
                }
            </Dialog>
        );
    }
}

export default WpInformationDialog;