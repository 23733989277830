import axios from "axios";
import { PIM_BASE_URL, PIM_PATH } from "../../constants";

const PIM_PRODUCT_OPTIONS_URL = PIM_BASE_URL + PIM_PATH + "/product-options";

export function getPageProductOptions(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.post(`${PIM_PRODUCT_OPTIONS_URL}/page`, filter, {
        params: {page, size, sort}
    }).then(res => {
        return res.data
    }).catch(error => console.log(error));
}

export function getListProductOptions(filter, simple=true){
    return axios.post(`${PIM_PRODUCT_OPTIONS_URL}/list`, filter, {params: {simple}}).then(res => {
        return res.data
    }).catch(error => console.log(error));
}

export function saveProductOption(data) {
    return axios.post(`${PIM_PRODUCT_OPTIONS_URL}`, data)
        .then(res => res.data).catch(error => console.log(error));
}

export function removeProductOption(id){
    return axios.delete(`${PIM_PRODUCT_OPTIONS_URL}/${id}`)
        .then(res => res.data).catch(error => console.log(error));
}

export function removeProductOptionValue(optId, id){
    return axios.delete(`${PIM_PRODUCT_OPTIONS_URL}/${optId}/values/${id}`)
        .then(res => res.data).catch(error => console.log(error));
}