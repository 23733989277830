import axios from 'axios';
import { PIM_BASE_URL, PIM_PATH } from '../../constants';

const PIM_TAX_SERVICE = PIM_BASE_URL + PIM_PATH + '/taxs'

export function getPageTaxs(page, size, sortField, sortOrder){
    let order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    let sort = sortField ? `${sortField},${order}` : '';

    return axios.get(`${PIM_TAX_SERVICE}/page`, {
        params: {page, size, sort}
    }).then(res => { return res.data }).catch(error => console.log(error));
}

export function getTaxes(simple = true) {
    return axios.get(`${PIM_TAX_SERVICE}/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}


export function saveTax(data) {
    return axios.post(`${PIM_TAX_SERVICE}/save`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function removeTax(id) {
    return axios.delete(`${PIM_TAX_SERVICE}/remove/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}