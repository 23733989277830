import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Sidebar } from "primereact/sidebar";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { showSuccessNotify } from "../../core/service/NotificationService";
import PhoneInput from "react-phone-number-input";

let schema = yup.object().shape({
  //basic info
  fullName: yup.string(),
  firstName: yup.string(),
  lastName: yup.string(),
  gender: yup.string(),
  dateOfBirth: yup.string(),
  nationality: yup.string(),

  //membership info
  memberId: yup.string(),
  memberCategory: yup.string(),
  memberType: yup.string(),
  expireAt: yup.string(),
  status: yup.string(),

  //communication details
  email: yup.string(),
  address: yup.string(),
  phone: yup.string(),

  //Identity documents
  passportNumber: yup
    .number()
    .transform((curr, origin) => (origin === "" ? null : curr))
    .typeError("Please input only number character!")
    .nullable(),
  passportExpiry: yup.string(),
  workPermission: yup
    .number()
    .transform((curr, origin) => (origin === "" ? null : curr))
    .typeError("Please input only number character!")
    .nullable(),
  workPermissionExpiry: yup.string(),
  fin: yup
    .number()
    .transform((curr, origin) => (origin === "" ? null : curr))
    .typeError("Please input only number character!")
    .nullable(),
  nric: yup
    .number()
    .transform((curr, origin) => (origin === "" ? null : curr))
    .typeError("Please input only number character!")
    .nullable(),
});

export const ImportMemberForm = forwardRef(({ updateMember }, ref) => {
  const [header, setHeader] = useState("Member");
  const [visible, setVisible] = useState(false);

  const {
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // console.log(errors);

  useImperativeHandle(ref, () => ({
    openForm(data, mode) {
      setHeader(`${mode === "edit" ? "Edit" : ""} Member`);
      setVisible(true);
      initFormData(data);
    },
    closeForm() {
      closeForm();
    },
  }));

  const closeForm = () => {
    setVisible(false);
    reset({});
    clearErrors();
  };

  const initFormData = (data) => {
    const formatData = {
      id: data?.id ? data.id : null,
      //basic info
      fullName: data?.fullName ? data.fullName : "",
      firstName: data?.firstName ? data.firstName : "",
      lastName: data?.lastName ? data.lastName : "",
      gender: data?.gender ? data.gender : "",
      dateOfBirth: data?.dateOfBirth ? data?.dateOfBirth : "",
      nationality: data?.nationality ? data.nationality : "",
      //membership info
      memberId: data?.memberId ? data.memberId : "",
      memberCategory: data?.memberCategory ? data.memberCategory : "",
      memberType: data?.memberType ? data.memberType : "",
      expireAt: data?.expireAt ? data?.expireAt : "",
      status: data?.status ? data.status : "Inactive",
      renewed: data?.renewed ? data.renewed : "Inactive",
      paymentStatus: data?.paymentStatus ? data.paymentStatus : "Pending",
      // communication details
      email: data?.email ? data.email : "",
      address: data?.address ? data.address : "",
      phone: data?.phone ? data.phone.toString() : "",
      // Identity documents
      passportNumber: data?.passportNumber ? data.passportNumber : null,
      passportExpiry: data?.passportExpiry ? data?.passportExpiry : "",
      workPermission: data?.workPermission ? data.workPermission : null,
      workPermissionExpiry: data?.workPermissionExpiry
        ? data?.workPermissionExpiry
        : "",
      fin: data?.fin ? data.fin : null,
      nric: data?.nric ? data.nric : null,
    };
    reset(formatData);
    // console.log('phone', data?.phone ? data.phone : '');
  };

  const handleUpdateMember = (data) => {
    // console.log(data);
    updateMember(data);
    closeForm();
    showSuccessNotify("Update member successfully!");
  };

  return (
    <Sidebar
      visible={visible}
      style={{ overflowY: "auto" }}
      className="p-sidebar-lg p-max-width-80"
      position="right"
      blockScroll={true}
      showCloseIcon={false}
      dismissable={true}
      onHide={closeForm}
    >
      <div className="p-d-flex p-justify-between ">
        <h2 className="p-margin-top-10">{header}</h2>
        <Button
          label=""
          icon="pi pi-times"
          className="p-button-secondary"
          onClick={closeForm}
        />
      </div>

      <div className="p-sidebar-line p-mb-3"></div>

      <form className="form-group" onSubmit={handleSubmit(handleUpdateMember)}>
        <div className="p-grid p-fluid">
          {/* Basic information */}
          <div className="p-col-6">
            <h3>Basic information</h3>
            <div className="p-col-12 ">
              <label className="p-label">Full Name</label>
              <Controller
                name="fullName"
                control={control}
                defaultValue=""
                render={({ field }) => <InputText {...field} />}
              />
              <span className="p-form-error">{errors.fullName?.message}</span>
            </div>
            <div className="p-col-12 p-grid p-pr-0">
              <div className="p-col-6">
                <label className="p-label">First Name</label>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">
                  {errors.firstName?.message}
                </span>
              </div>
              <div className="p-col-6 p-pr-0">
                <label className="p-label">Last Name</label>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">{errors.lastName?.message}</span>
              </div>
            </div>
            <div className="p-col-12">
              <label className="p-label">Gender</label>
              <Controller
                name="gender"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div className="p-grid p-mt-2" {...field}>
                    <div className="p-col-6 p-d-flex p-align-center">
                      <RadioButton
                        value="Male"
                        name="gender"
                        onChange={(e) => setValue("gender", e.value)}
                        checked={getValues("gender") === "Male"}
                      />
                      <span>Male</span>
                    </div>
                    <div className="p-col-6 p-d-flex p-align-center">
                      <RadioButton
                        value="Female"
                        name="gender"
                        onChange={(e) => setValue("gender", e.value)}
                        checked={getValues("gender") === "Female"}
                      />
                      <span>Female</span>
                    </div>
                  </div>
                )}
              />

              <span className="p-form-error">{errors.gender?.message}</span>
            </div>

            <div className="p-col-12 p-grid p-pr-0">
              <div className="p-col-6">
                <label className="p-label">Date of Birth</label>
                <Controller
                  name="dateOfBirth"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">
                  {errors.dateOfBirth?.message}
                </span>
              </div>
              <div className="p-col-6 p-pr-0">
                <label className="p-label">Nationality</label>
                <Controller
                  name="nationality"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">
                  {errors.nationality?.message}
                </span>
              </div>
            </div>
          </div>
          {/* Membership infomation  */}
          <div className="p-col-6">
            <h3>Membership information</h3>
            <div className="p-col-12 ">
              <label className="p-label">Member ID</label>
              <Controller
                name="memberId"
                control={control}
                defaultValue=""
                render={({ field }) => <InputText {...field} />}
              />
              <span className="p-form-error">{errors.memberId?.message}</span>
            </div>
            <div className="p-col-12 p-grid p-pr-0">
              <div className="p-col-6 p-pr-0">
                <label className="p-label">Member Type</label>
                <Controller
                  name="memberType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">
                  {errors.memberType?.message}
                </span>
              </div>
              <div className="p-col-6">
                <label className="p-label">Expire at</label>
                <Controller
                  name="expireAt"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">{errors.expireAt?.message}</span>
              </div>
            </div>

            <div className="p-col-12 p-grid p-pr-0">
              <div className="p-col-6">
                <label className="p-label">Status</label>
                <Controller
                  name="status"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputSwitch
                      {...field}
                      className="p-mt-3"
                      checked={getValues("status") === "Active"}
                      onChange={(e) =>
                        setValue("status", e.value ? "Active" : "Inactive")
                      }
                    />
                  )}
                />
                <span className="p-ml-3">
                  {getValues("status") ? "Active" : "Inactive"}
                </span>
                <span className="p-form-error">{errors.status?.message}</span>
              </div>
              <div className="p-col-6">
                <label className="p-label">Membership Renewed</label>
                <Controller
                  name="renewed"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputSwitch
                      {...field}
                      className="p-mt-3"
                      checked={getValues("renewed") === "Active"}
                      onChange={(e) =>
                        setValue("renewed", e.value ? "Active" : "Inactive")
                      }
                    />
                  )}
                />
                <span className="p-ml-3">
                  {getValues("renewed") ? "Active" : "Inactive"}
                </span>
                <span className="p-form-error">{errors.renewed?.message}</span>
              </div>
            </div>
            <div className="p-col-6">
              <label className="p-label">Payment Status</label>
              <Controller
                name="paymentStatus"
                control={control}
                defaultValue=""
                render={({ field }) => <InputText {...field} disabled={true} />}
              />
              <span className="p-form-error">{errors.paymentStatus?.message}</span>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="p-grid p-fluid p-mt-4">
          {/* Communication details */}
          <div className="p-col-6">
            <h3>Communication details</h3>
            <div className="p-col-12 p-grid">
              <div className="p-col-12 ">
                <label className="p-label">Email</label>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">{errors.email?.message}</span>
              </div>
            </div>
            <div className="p-col-12 p-grid">
              <div className="p-col-12">
                <label className="p-label">Address</label>
                <Controller
                  name="address"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">{errors.address?.message}</span>
              </div>
            </div>
            <div className="p-col-12 p-grid">
              <div className="p-col-12">
                <label className="p-label">Phone</label>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      className="p-component p-inputtext p-phoneinput"
                    />
                  )}
                />
                <span className="p-form-error">{errors.phone?.message}</span>
              </div>
            </div>
          </div>
          {/* Identity documents  */}
          <div className="p-col-6">
            <h3>Identity documents</h3>
            <div className="p-col-12 p-grid p-pr-0">
              <div className="p-col-6">
                <label className="p-label">Passport Number</label>
                <Controller
                  name="passportNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">
                  {errors.passportNumber?.message}
                </span>
              </div>
              <div className="p-col-6 p-pr-0">
                <label className="p-label">Passport Expiry</label>
                <Controller
                  name="passportExpiry"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">
                  {errors.passportExpiry?.message}
                </span>
              </div>
            </div>
            <div className="p-col-12 p-grid p-pr-0">
              <div className="p-col-6">
                <label className="p-label">Work Permission</label>
                <Controller
                  name="workPermission"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">
                  {errors.workPermission?.message}
                </span>
              </div>
              <div className="p-col-6 p-pr-0">
                <label className="p-label">Work Permission Expiry</label>
                <Controller
                  name="workPermissionExpiry"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">
                  {errors.workPermissionExpiry?.message}
                </span>
              </div>
            </div>
            <div className="p-col-12 p-grid p-pr-0">
              <div className="p-col-6">
                <label className="p-label">FIN</label>
                <Controller
                  name="fin"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">{errors.fin?.message}</span>
              </div>
              <div className="p-col-6 p-pr-0">
                <label className="p-label">NRIC No.</label>
                <Controller
                  name="nric"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputText {...field} />}
                />
                <span className="p-form-error">{errors.nric?.message}</span>
              </div>
            </div>
          </div>
        </div>

        <div className=" p-col-12 p-sidebar-line p-my-3"></div>

        <div className="p-col-12 p-d-flex p-justify-end">
          <Button
            label="Save"
            style={{ width: "6rem" }}
            type="submit"
            className="blue-btn"
          />
          <Button
            label="Cancel"
            onClick={closeForm}
            style={{ width: "6rem" }}
            type="button"
            className="yellow-btn"
          />
        </div>
      </form>
    </Sidebar>
  );
});

// import React, { forwardRef, useState, useImperativeHandle } from "react";
// import { Button } from "primereact/button";
// import { InputText } from "primereact/inputtext";
// import { InputSwitch } from "primereact/inputswitch";
// import { Sidebar } from "primereact/sidebar";
// import { Calendar } from "primereact/calendar";
// import { Dropdown } from "primereact/dropdown";
// import { RadioButton } from "primereact/radiobutton";
// import { Controller, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";

// import {
//   showSuccessNotify,
//   showErrorNotify,
// } from "../../core/service/NotificationService";

// let schema = yup.object().shape({
//   //basic info
//   fullName: yup.string().required("Please input full name!"),
//   firstName: yup.string().required("Please input first name!"),
//   lastName: yup.string().required("Please input last name!"),
//   gender: yup.string().required("Please select gender!"),
//   dateOfBirth: yup.string().required("Please input date of birth!").nullable(),
//   nationality: yup.string().required("Please input nationality!"),

//   //membership info
//   memberId: yup.string().required("Please input member ID!"),
//   memberCategory: yup.string().required("Please select member category!"),
//   memberType: yup.string().required("Please select member type!"),
//   expireAt: yup.string().required("Please input expire date!").nullable(),
//   status: yup.string().required(),

//   //communication details
//   email: yup.string().required("Please input email!"),
//   address: yup.string().required("Please input address!"),
//   phone: yup.string().required("Please input phone!"),

//   //Identity documents
//   passportNumber: yup
//     .number()
//     .transform((curr, origin) => (origin === "" ? null : curr))
//     .typeError("Please input only number character!")
//     .required("Please input passport number!")
//     .nullable(),
//   passportExpiry: yup
//     .string()
//     .required("Please input passport expiry date!")
//     .nullable(),
//   workPermission: yup
//     .number()
//     .transform((curr, origin) => (origin === "" ? null : curr))
//     .typeError("Please input only number character!")
//     .required("Please input word permission number!")
//     .nullable(),
//   workPermissionExpiry: yup
//     .string()
//     .required("Please input work permission expiry date!")
//     .nullable(),
//   fin: yup
//     .number()
//     .transform((curr, origin) => (origin === "" ? null : curr))
//     .typeError("Please input only number character!")
//     .required("Please input FIN number!")
//     .nullable(),
//   nric: yup
//     .number()
//     .transform((curr, origin) => (origin === "" ? null : curr))
//     .typeError("Please input only number character!")
//     .required("Please input NRIC number!")
//     .nullable(),
// });

// const countryOptions = [
//   { label: "New York", value: "NY" },
//   { label: "Rome", value: "RM" },
//   { label: "London", value: "LDN" },
//   { label: "Istanbul", value: "IST" },
//   { label: "Paris", value: "PRS" },
// ];

// const categoryOptions = [
//   { label: "MEMBERSHIP_CATE01", value: "01" },
//   { label: "MEMBERSHIP_CATE02", value: "02" },
//   { label: "MEMBERSHIP_CATE03", value: "03" },
// ];
// const typeOptions = [
//   { label: "MEMBERSHIP_TYPE01", value: "01" },
//   { label: "MEMBERSHIP_TYPE02", value: "02" },
//   { label: "MEMBERSHIP_TYPE03", value: "03" },
// ];

// export const ImportMemberForm = forwardRef(({ updateMember }, ref) => {
//   const [header, setHeader] = useState("Member");
//   const [visible, setVisible] = useState(false);

//   const {
//     handleSubmit,
//     clearErrors,
//     reset,
//     formState: { errors },
//     control,
//     setValue,
//     getValues,
//   } = useForm({
//     resolver: yupResolver(schema),
//   });

//   console.log(errors);

//   useImperativeHandle(ref, () => ({
//     openForm(data, mode) {
//       setHeader(`${mode === "edit" ? "Edit" : ""} Member`);
//       setVisible(true);
//       initFormData(data);
//     },
//     closeForm() {
//       closeForm();
//     },
//   }));

//   const closeForm = () => {
//     setVisible(false);
//     reset({});
//     clearErrors();
//   };

//   const initFormData = (data) => {
//     // console.log(data);
//     const createDate = (str) => {
//       try {
//         const dateParts = str.split("/");
//         return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
//       } catch (err) {
//         return "";
//       }
//     };
//     const formatData = {
//       id: data?.id ? data.id : null,
//       //basic info
//       fullName: data?.fullName ? data.fullName : "",
//       firstName: data?.firstName ? data.firstName : "",
//       lastName: data?.lastName ? data.lastName : "",
//       gender: data?.gender ? data.gender : "",
//       dateOfBirth: data?.dateOfBirth ? createDate(data.dateOfBirth) : "",
//       nationality: data?.nationality ? data.nationality : "",
//       //membership info
//       memberId: data?.memberId ? data.memberId : "",
//       memberCategory: data?.memberCategory ? data.memberCategory : "",
//       memberType: data?.memberType ? data.memberType : "",
//       expireAt: data?.expireAt ? createDate(data.expireAt) : "",
//       status: data?.status ? data.status : "Inactive",
//       // communication details
//       email: data?.email ? data.email : "",
//       address: data?.address ? data.address : "",
//       phone: data?.phone ? data.phone : "",
//       // Identity documents
//       passportNumber: data?.passportNumber ? data.passportNumber : null,
//       passportExpiry: data?.passportExpiry
//         ? createDate(data.passportExpiry)
//         : "",
//       workPermission: data?.workPermission ? data.workPermission : null,
//       workPermissionExpiry: data?.workPermissionExpiry
//         ? createDate(data.workPermissionExpiry)
//         : "",
//       fin: data?.fin ? data.fin : null,
//       nric: data?.nric ? data.nric : null,
//     };
//     reset(formatData);
//   };

//   const handleUpdateMember = (data) => {
//     // console.log(data);
//     updateMember(data);
//     closeForm();
//     showSuccessNotify("Update member successfully!");
//   };

//   return (
//     <Sidebar
//       visible={visible}
//       style={{ overflowY: "auto" }}
//       className="p-sidebar-lg p-max-width-80"
//       position="right"
//       blockScroll={true}
//       showCloseIcon={false}
//       dismissable={true}
//       onHide={closeForm}
//     >
//       <div className="p-d-flex p-justify-between ">
//         <h2 className="p-margin-top-10">{header}</h2>
//         <Button
//           label=""
//           icon="pi pi-times"
//           className="p-button-secondary"
//           onClick={closeForm}
//         />
//       </div>

//       <div className="p-sidebar-line p-mb-3"></div>

//       <form className="form-group" onSubmit={handleSubmit(handleUpdateMember)}>
//         <div className="p-grid p-fluid">
//           {/* Basic information */}
//           <div className="p-col-6">
//             <h3>Basic information</h3>
//             <div className="p-col-12 ">
//               <label className="p-label">* Full Name</label>
//               <Controller
//                 name="fullName"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => <InputText {...field} />}
//               />
//               <span className="p-form-error">{errors.fullName?.message}</span>
//             </div>
//             <div className="p-col-12 p-grid p-pr-0">
//               <div className="p-col-6">
//                 <label className="p-label">First Name</label>
//                 <Controller
//                   name="firstName"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => <InputText {...field} />}
//                 />
//                 <span className="p-form-error">
//                   {errors.firstName?.message}
//                 </span>
//               </div>
//               <div className="p-col-6 p-pr-0">
//                 <label className="p-label">Last Name</label>
//                 <Controller
//                   name="lastName"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => <InputText {...field} />}
//                 />
//                 <span className="p-form-error">{errors.lastName?.message}</span>
//               </div>
//             </div>
//             <div className="p-col-12">
//               <label className="p-label">Gender</label>
//               <Controller
//                 name="gender"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => (
//                   <div className="p-grid p-mt-2" {...field}>
//                     <div className="p-col-6 p-d-flex p-align-center">
//                       <RadioButton
//                         value="Male"
//                         name="gender"
//                         onChange={(e) => setValue("gender", e.value)}
//                         checked={getValues("gender") === "Male"}
//                       />
//                       <span>Male</span>
//                     </div>
//                     <div className="p-col-6 p-d-flex p-align-center">
//                       <RadioButton
//                         value="Female"
//                         name="gender"
//                         onChange={(e) => setValue("gender", e.value)}
//                         checked={getValues("gender") === "Female"}
//                       />
//                       <span>Female</span>
//                     </div>
//                   </div>
//                 )}
//               />

//               <span className="p-form-error">{errors.gender?.message}</span>
//             </div>

//             <div className="p-col-12 p-grid p-pr-0">
//               <div className="p-col-6">
//                 <label className="p-label">Date of Birth</label>
//                 <Controller
//                   name="dateOfBirth"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => (
//                     <Calendar {...field} showIcon={true} />
//                   )}
//                 />
//                 <span className="p-form-error">
//                   {errors.dateOfBirth?.message}
//                 </span>
//               </div>
//               <div className="p-col-6 p-pr-0">
//                 <label className="p-label">Nationality</label>
//                 <Controller
//                   name="nationality"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => (
//                     <Dropdown
//                       {...field}
//                       options={countryOptions}
//                       filter={true}
//                       filterBy="label"
//                     />
//                   )}
//                 />
//                 <span className="p-form-error">
//                   {errors.nationality?.message}
//                 </span>
//               </div>
//             </div>
//           </div>
//           {/* Membership infomation  */}
//           <div className="p-col-6">
//             <h3>Membership infomation</h3>
//             <div className="p-col-12 ">
//               <label className="p-label">* Member ID</label>
//               <Controller
//                 name="memberId"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => <InputText {...field} />}
//               />
//               <span className="p-form-error">{errors.memberId?.message}</span>
//             </div>
//             <div className="p-col-12 p-grid p-pr-0">
//               <div className="p-col-6">
//                 <label className="p-label">Member Category</label>
//                 <Controller
//                   name="memberCategory"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => (
//                     <Dropdown {...field} options={categoryOptions} />
//                   )}
//                 />
//                 <span className="p-form-error">
//                   {errors.memberCategory?.message}
//                 </span>
//               </div>
//               <div className="p-col-6 p-pr-0">
//                 <label className="p-label">Member Type</label>
//                 <Controller
//                   name="memberType"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => (
//                     <Dropdown {...field} options={typeOptions} />
//                   )}
//                 />
//                 <span className="p-form-error">
//                   {errors.memberType?.message}
//                 </span>
//               </div>
//             </div>
//             <div className="p-col-6">
//               <label className="p-label">Expire at</label>
//               <Controller
//                 name="expireAt"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => <Calendar {...field} showIcon={true} />}
//               />
//               <span className="p-form-error">{errors.expireAt?.message}</span>
//             </div>
//             <div className="p-col-6">
//               <label className="p-label">Status</label>
//               <Controller
//                 name="status"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => (
//                   <InputSwitch
//                     {...field}
//                     className="p-mt-3"
//                     checked={getValues("status") === "Active"}
//                     onChange={(e) =>
//                       setValue("status", e.value ? "Active" : "Inactive")
//                     }
//                   />
//                 )}
//               />
//               <span className="p-ml-3">Active</span>
//               <span className="p-form-error">{errors.status?.message}</span>
//             </div>
//           </div>
//         </div>
//         {/*  */}
//         <div className="p-grid p-fluid p-mt-4">
//           {/* Communication details */}
//           <div className="p-col-6">
//             <h3>Communication details</h3>
//             <div className="p-col-12 ">
//               <label className="p-label">* Email</label>
//               <Controller
//                 name="email"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => <InputText {...field} />}
//               />
//               <span className="p-form-error">{errors.email?.message}</span>
//             </div>
//             <div className="p-col-12">
//               <label className="p-label">* Address</label>
//               <Controller
//                 name="address"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => <InputText {...field} />}
//               />
//               <span className="p-form-error">{errors.address?.message}</span>
//             </div>
//             <div className="p-col-12">
//               <label className="p-label">* Phone</label>
//               <Controller
//                 name="phone"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => <InputText {...field} />}
//               />
//               <span className="p-form-error">{errors.phone?.message}</span>
//             </div>
//           </div>
//           {/* Identity documents  */}
//           <div className="p-col-6">
//             <h3>Identity documents</h3>
//             <div className="p-col-12 p-grid p-pr-0">
//               <div className="p-col-6">
//                 <label className="p-label">Passport Number</label>
//                 <Controller
//                   name="passportNumber"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => <InputText {...field} />}
//                 />
//                 <span className="p-form-error">
//                   {errors.passportNumber?.message}
//                 </span>
//               </div>
//               <div className="p-col-6 p-pr-0">
//                 <label className="p-label">Passport Expiry</label>

//                 <Controller
//                   name="passportExpiry"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => (
//                     <Calendar {...field} showIcon={true} />
//                   )}
//                 />
//                 <span className="p-form-error">
//                   {errors.passportExpiry?.message}
//                 </span>
//               </div>
//             </div>
//             <div className="p-col-12 p-grid p-pr-0">
//               <div className="p-col-6">
//                 <label className="p-label">Work Permission</label>
//                 <Controller
//                   name="workPermission"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => <InputText {...field} />}
//                 />
//                 <span className="p-form-error">
//                   {errors.workPermission?.message}
//                 </span>
//               </div>
//               <div className="p-col-6 p-pr-0">
//                 <label className="p-label">Work Permission Expiry</label>
//                 <Controller
//                   name="workPermissionExpiry"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => (
//                     <Calendar {...field} showIcon={true} />
//                   )}
//                 />
//                 <span className="p-form-error">
//                   {errors.workPermissionExpiry?.message}
//                 </span>
//               </div>
//             </div>
//             <div className="p-col-12 p-grid p-pr-0">
//               <div className="p-col-6">
//                 <label className="p-label">FIN</label>
//                 <Controller
//                   name="fin"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => <InputText {...field} />}
//                 />
//                 <span className="p-form-error">{errors.fin?.message}</span>
//               </div>
//               <div className="p-col-6 p-pr-0">
//                 <label className="p-label">NRIC No.</label>
//                 <Controller
//                   name="nric"
//                   control={control}
//                   defaultValue=""
//                   render={({ field }) => <InputText {...field} />}
//                 />
//                 <span className="p-form-error">{errors.nric?.message}</span>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className=" p-col-12 p-sidebar-line p-my-3"></div>

//         <div className="p-col-12 p-d-flex p-justify-end">
//           <Button label="Save" style={{ width: "6rem" }} type="submit" className="blue-btn"/>
//           <Button
//             label="Cancel"
//             onClick={closeForm}
//             style={{ width: "6rem"}}
//             type="button"
//             className="yellow-btn"
//           />
//         </div>
//       </form>
//     </Sidebar>
//   );
// });
