import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import { InputText } from "primereact/inputtext";
import { saveIdentityDocument } from "./IdentityDocumentServices";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { showErrorNotify, showSuccessNotify } from '../../../../core/service/NotificationService';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { IDENTITY_DOCUMENT_TYPE, MAX_FILE_SIZE } from "../../../../constants";

export const IdentityDocumentForm = forwardRef((props, ref)=>{
    const [header, setHeader] = useState("New Identity Document")
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [type, setType] = useState(null)
    const [errors, setErrors] = useState({})

    const documentFrontImage = useRef(null)
    const documentBackImage = useRef(null)

    useImperativeHandle(ref, () => ({
        openForm(type, data){
            setHeader(data&&data.id?'Edit Identity Document':'New Identity Document')
            setVisible(true)
            setFrmData(popularFormData(type, data))
            setType(type)
        },
        closeForm(){
            closeForm()
        }
    }))

    const popularFormData = (type, data) => {
        return {
            id: data ? data.id : null,
            userId: data && data.userId ? data.userId : props.userId,
            docType: data && data.docType ? data.docType : type,
            docNumber: data && data.docNumber ? data.docNumber : '',
            expiredDate: data && data.expiredDate ? data.expiredDate : '',
            expiredDateValue: data && data.expiredDate ? moment(data.expiredDate).toDate() : null,
            active: true
        }
    }

    const handleSave = () => {
        setErrors({})

        let totalSize = 0
        if(frmData.frontImage){
            totalSize += frmData.frontImage.size
        }
        if(frmData.backImage){
            totalSize += frmData.backImage.size
        }

        if(totalSize > MAX_FILE_SIZE){
            showErrorNotify({image: 'Total size not allowed (maximum: 100MB)'})
            return
        }

        saveIdentityDocument(frmData)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable)
                    props.refreshTable()
                if(props.refreshData)
                    props.refreshData(res)
                closeForm()
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }
        });
    }

    const onFrmDataChange = (property, value) => {
        setFrmData({...frmData,
            [property]:  property==='expiredDate' ? (value?(moment(value).isValid()?moment(value).format(moment.HTML5_FMT.DATE):'Invalid date'):'') : value,
            expiredDateValue: property==='expiredDate' ? value : frmData.expiredDateValue
        })
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setType(null)
        setErrors({})
    }

    return(
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid p-fluid form-group">
                <div className="p-col-12">
                    <label className="p-label">* Document Number</label>
                    <InputText value={frmData.docNumber} onChange={(e) => onFrmDataChange('docNumber', e.target.value)} placeholder={frmData.docType===IDENTITY_DOCUMENT_TYPE.work_permit.value&&'Eg. x xxxxxxxx'} />
                    <span className="p-form-error">{errors.docNumber}</span>
                </div>
                {!type &&
                    <div className="p-col-12">
                        <label className="p-label">* Document Type</label>
                        <Dropdown value={frmData.docType} onChange={(e) => onFrmDataChange('docType', e.value)} options={Object.values(IDENTITY_DOCUMENT_TYPE)} />
                        <span className="p-form-error">{errors.docType}</span>
                    </div>
                }
                {(!frmData.docType || frmData.docType!==IDENTITY_DOCUMENT_TYPE.fin.value) && 
                    <div className="p-col-12">
                        <label className="p-label" >Expired Date</label>
                        <Calendar value={frmData.expiredDateValue} dateFormat="dd/mm/yy" onChange={(e) => onFrmDataChange('expiredDate', e.value)} showIcon />
                        <span className="p-form-error">{errors.expiredDate}</span>
                    </div>
                }
                {frmData.docType===IDENTITY_DOCUMENT_TYPE.work_permit.value &&
                    <div className="p-col-12">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <label className="p-label">Front image</label>
                                <input ref={documentFrontImage} type="file" className="p-inputtext p-component" accept="image/png, image/jpg, image/jpeg" onChange={(e) => onFrmDataChange('frontImage', e.target.files[0])}/>
                                <span className="p-form-error">{errors.frontImage}</span>
                            </div>
                            <div className="p-col-12">
                                <label className="p-label">Back image</label>
                                <input ref={documentBackImage} type="file" className="p-inputtext p-component" accept="image/png, image/jpg, image/jpeg" onChange={(e) => onFrmDataChange('backImage', e.target.files[0])}/>
                                <span className="p-form-error">{errors.backImage}</span>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={()=> closeForm()}/>
                    <Button label="Submit" icon="pi pi-check" onClick={() => handleSave()}/>
                </div>
            </div>
        </Sidebar>
    )
})