import axios from "axios";
import { API_BASE_URL, SCP_PATH } from "../../constants";

export class SimpleEventServices {
    getSimpleEvents(filter, page, size, sortField, sortOrder) {
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}${SCP_PATH}/simple-event`, {
            params: { ...filter, page, size, sort }
        }).then(res => {
            return res.data
        }).catch(error => console.log(error));
    }

    getPageSimpleEvents(filter, page, size, sortField, sortOrder) {
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}${SCP_PATH}/simple-event/page`, {
            params: { ...filter, page, size, sort }
        }).then(res => {
            return res.data
        }).catch(error => console.log(error));
    }

    getPageSimpleEventsOnCourse(id , busy, page){
        return axios.get(`${API_BASE_URL}${SCP_PATH}/simple-event/event-page?size=5`, {
            params:{id, busy, page}
        }).then(res => {return res.data}).catch(error => console.log(error));
    }

    getPageBusySimpleEventsOnCourse(id , busy, page){
        return axios.get(`${API_BASE_URL}${SCP_PATH}/simple-event/event-page`, {
            params:{id, busy, page}
        }).then(res => {return res.data}).catch(error => console.log(error));
    }

    getSimpleEventSchedule() {
        return axios.get(`${API_BASE_URL}${SCP_PATH}/simple-event/schedule`).then(res => {return res.data})
    }

    getOneSimpleEvent(id) {
        return axios.get(`${API_BASE_URL}${SCP_PATH}/simple-event/${id}`)
        .then(res => res.data)
        .catch(error => console.log(error));
    }

    saveSimpleEvent(data) {
        return axios.post(`${API_BASE_URL}${SCP_PATH}/simple-event/save`, data)
        .then(res => res)
        .catch(error => console.log(error));
    }
    
    removeSimpleEvent(id) {
        return axios.delete(`${API_BASE_URL}${SCP_PATH}/simple-event/remove/${id}`)
        .then(res => res)
        .catch(error => console.log(error));
    }

    changeSimpleEvent(id,data) {
        return axios.put(`${API_BASE_URL}${SCP_PATH}/simple-event/edit/${id}`,data)
        .then(res => res)
    }

    getAllUsers(searchValue){
        let querystring = require("querystring");
        return axios.post(`${API_BASE_URL}${SCP_PATH}/simple-event/search`,
            querystring.stringify({
                search: searchValue
            })
        )
        .then(res => res.data).catch(error => console.log(error));
    }

    getListCourses(){
        return axios.get(`${API_BASE_URL}${SCP_PATH}/programs/select-group`)
        .then(res => res.data)
    }
}