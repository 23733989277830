import React, {useState, useEffect} from 'react';
import moment, { HTML5_FMT } from 'moment';
import { TABLE_PAGING_LENGTH, PRODUCT_TYPE, APP_FEP, VARIANT_OPTION_TYPE, PRICE_APPLY_TYPE, PRODUCT_PRICE_UNIT, PRODUCT_CLASSIFICATION } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { saveAssociatedProduct, removeAssociatedProduct, getListProducts, saveProductVariant, removeProductVariant } from './ProductServices';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { SplitButton } from 'primereact/splitbutton';
import { InputSwitch } from 'primereact/inputswitch';
import { Spinner } from 'primereact/spinner';
import { ProductPrices } from './ProductPrice';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';

export const ProductVariant = (props) => {
    const [frmVisible, setFrmVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [errors, setErrors] = useState({prices: {}})
    const [data, setData] = useState(props.value)
    const [tblLength, setTblLength] = useState(10)

    useEffect(() => {
        if(data && data.length>0){
            if(props.classification===PRODUCT_CLASSIFICATION.simple.value){
                addOrEditItem(data[0])
            }
        }
    }, [data])

    const addOrEditItem = (e) => {
        setFrmData({
            id: e?e.id: '',
            variantName: e?e.variantName:'',
            prices: e?e.prices:[{applyFor: PRICE_APPLY_TYPE.normal.value, price: '', unit: PRODUCT_PRICE_UNIT.min.value, quantity: 1}],
            weight: e?e.weight:0,
            tracked: e?e.tracked:false,
            stockInput: 0,
            total: e?e.total:0,
            onHand: e?e.onHand:0,
            onHold: e?e.onHold:0,
            onSold: e?e.onSold:0,
            optionValueIds: e?e.optionValueIds:new Array(props.variantOptions.length).fill(0)
        })
        setFrmVisible(true)
    }

    const closeForm = () => {
        setFrmVisible(false)
        setFrmData({prices: []})
        setErrors({prices: {}})
    }

    const deleteItem = (id) => {
        removeProductVariant(props.productId, id)
        .then(res => {
            if(!res.errorCode){
                let tmpData = [...data];
                const dataIdx = tmpData.findIndex(e => e.id===id);
                if(dataIdx!==-1)
                    tmpData.splice(dataIdx, 1);
                
                setData(tmpData)

                showSuccessNotify('Action submitted')
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const submitSaveItem = () => {
        showloading()

        saveProductVariant(props.productId, frmData)
        .then(res => {
            if(!res.errorCode){
                showSuccessNotify('Action submitted!')

                let tmpData = [...data];
                if(!frmData.id)
                    tmpData.push(res);
                else{
                    const dataIdx = tmpData.findIndex(e => e.id===frmData.id);
                    if(dataIdx!==-1)
                        tmpData.splice(dataIdx, 1, res);
                }

                setData(tmpData)

                if(props.classification===PRODUCT_CLASSIFICATION.variable.value)
                    closeForm();
            }else{
                showErrorNotify(res.errorMessage)
                if(res.errorCode===400)
                    setErrors(res.errorObj)
            }
        })
        .finally(stoploading)
    }

    const onOptionValueChange = (v, i) => {
        let optionValueIds = [...frmData.optionValueIds]

        optionValueIds.fill(v.id, i, i+1)

        setFrmData({...frmData, optionValueIds: optionValueIds})
    }

    const tblHeader = (
        <TableHeader title="Variants"
            changePageLength={(e) => setTblLength(e)}
            showRefresh={false}/>
    )

    return (
        <div className="p-grid">
            {!frmVisible && props.classification===PRODUCT_CLASSIFICATION.variable.value && 
            <div className="p-col-12">
                <Button label="Add product variant" icon="pi pi-plus" className="p-button-success btn-text-sm" iconPos="left" onClick={() => addOrEditItem()}/>
            </div>
            }

            {frmVisible && 
            <div className="p-col-12">
                <Fieldset legend={props.classification===PRODUCT_CLASSIFICATION.variable.value?'Variant Setup':'Inventory Setup'}>
                    <div className="p-grid">
                        {props.classification===PRODUCT_CLASSIFICATION.variable.value && 
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Information">
                                    <div className="p-grid p-fluid form-group">
                                        {props.variantOptionType===VARIANT_OPTION_TYPE.custom.value?
                                        <React.Fragment>
                                            <div className="p-col-12">
                                                <label className={classNames('p-label', {'inline': frmData.id})}>* Variant name{frmData.id?':':''}</label> <strong>{frmData.id?frmData.variantName:''}</strong>
                                                {!frmData.id && 
                                                <React.Fragment>
                                                    <InputText value={frmData.variantName} onChange={(e) => setFrmData({...frmData, variantName: e.target.value})}/>
                                                    <div className="p-form-error">{errors.variantName}</div>
                                                </React.Fragment>
                                                }
                                            </div>
                                        </React.Fragment>
                                        :
                                        props.variantOptions.length>0 && props.variantOptions.map((el, idx) => {
                                            return <div className="p-col-12">
                                                <label className={classNames('p-label', {'inline': frmData.id})}>{el.name}{frmData.id?':':''}</label> <strong>{frmData.id?(el.values.find(x => x.id===frmData.optionValueIds[idx])?el.values.find(x => x.id===frmData.optionValueIds[idx]).name:''):''}</strong>
                                                {!frmData.id &&
                                                    <React.Fragment>
                                                        <Dropdown value={el.values.find(x => x.id===frmData.optionValueIds[idx])} options={el.values} disabled={frmData.id?true:false} onChange={e => onOptionValueChange(e.value, idx)} dataKey="id" optionLabel="name" placeholder={el.noneLabel}/>
                                                        <div className="p-form-error">{errors["optValue_"+idx]}</div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        })
                                        }
                                        <div className="p-col-6">
                                            <label className="p-label">Weight (for order)</label>
                                            <InputText value={frmData.weight} keyfilter="int" onChange={e => setFrmData({...frmData, weight: e.target.value})}/>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend={props.pricedByVariant?'Price':'Extra Price'}>
                                    <ProductPrices value={frmData.prices} type={props.type} errors={errors.prices?errors.prices:{}} onChange={(e) => setFrmData({...frmData, prices: e})}/>
                                </Fieldset>
                            </div>
                        </React.Fragment>
                        }

                        {[PRODUCT_TYPE.ticket.value, PRODUCT_TYPE.session.value, PRODUCT_TYPE.addon.value].includes(props.type) && 
                        <React.Fragment>
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Stock setting">
                                    <div className="p-grid p-fluid form-group">
                                        <div className="p-col-12">
                                            <Checkbox checked={frmData.tracked} onChange={(e) => setFrmData({...frmData, tracked: e.checked})} />
                                            Inventory stock tracking?
                                        </div>
                                        {frmData.tracked && 
                                        <div className="p-col-12">
                                            <label className="p-label">Stock input q.ty</label>
                                            <Spinner value={frmData.stockInput} step={1} onChange={(e) => setFrmData({...frmData, stockInput: e.target.value})} />
                                            <span className="p-form-error">{errors.stockInput}</span>
                                        </div>
                                        }
                                    </div>
                                </Fieldset>
                            </div>
                            {frmData.id && 
                            <div className="p-col-12 p-md-6">
                                <Fieldset legend="Stock details">
                                    <div className="p-grid">
                                        {frmData.tracked?
                                            <React.Fragment>
                                                <div className="p-col-6">
                                                    <label className="p-label p-margin-bottom-5">Total q.ty: {frmData.total}</label>
                                                </div>
                                                <div className="p-col-6">
                                                    <label className="p-label p-margin-bottom-5">Q.ty on hand: {frmData.onHand}</label>
                                                </div>
                                            </React.Fragment>
                                            :
                                            ''
                                        }
                                        <div className="p-col-6">
                                            <label className="p-label p-margin-bottom-5">Q.ty on hold: {frmData.onHold}</label>
                                        </div>
                                        <div className="p-col-6">
                                            <label className="p-label p-margin-bottom-5">Q.ty on sold: {frmData.onSold}</label>
                                        </div>
                                    </div>
                                </Fieldset>
                            </div>
                            }
                        </React.Fragment>
                        }
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Button label="Submit" className="btn-text-sm p-button-success" onClick={() => submitSaveItem()}/>
                            {props.classification===PRODUCT_CLASSIFICATION.variable.value && 
                            <Button label="Cancel" className="btn-text-sm p-button-default" onClick={() => closeForm()}/>
                            }
                        </div>
                    </div>
                </Fieldset>
            </div>
            }

            {props.classification===PRODUCT_CLASSIFICATION.variable.value && 
            <div className="p-col-12">
                <DataTable paginator={true} value={data} 
                    header={tblHeader} rows={tblLength} responsive={true}
                >
                    <Column header="Variant Name" field="variantName"/>
                    <Column header="" className="tc-actions" style={{textAlign: 'center', width: '15%' }} 
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Remove', icon: 'pi pi-times', command: (e) => deleteItem(rowData.id)}
                            ]
                            return(
                                <SplitButton label="Edit" model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => addOrEditItem(rowData)}></SplitButton>
                            )
                        }}
                    />
                </DataTable>
            </div>
            }
        </div>
    )
}