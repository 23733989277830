import axios from "axios";
import { API_BASE_URL, SCP_PATH } from "../../constants";

const BASE_URL = API_BASE_URL + SCP_PATH + '/memberships';

const userData = {
  id: 1,
  name: "Tran Tu Hao",
  firstName: "Tu Hao",
  lastName: "Tran",
  email: "trantuhao@gmail.com",
  birthday: "21/04/1996",
  phone: "0123456789",
  workPermitNumber: "654329531",
  passport: "654329531",
  nationality: "vietnam",
  gender: "male",
};

const duplicateData = [
  {
    id: 2,
    memberId: 124,
    name: "username 1",
    firstName: "username",
    lastName: "1",
    email: "username1@gmail.com",
    birthday: "21/04/1996",
    phone: "0123456789",
    workPermitNumber: "654329531",
    passport: "654329531",
    nationality: "vietnam",
    gender: "male",
  },
  {
    id: 3,
    memberId: 125,
    name: "username 2",
    firstName: "username",
    lastName: "2",
    email: "username2@gmail.com",
    birthday: "21/04/1996",
    phone: "0123456789",
    workPermitNumber: "654329531",
    passport: "654329531",
    nationality: "usa",
    gender: "male",
  },
  {
    id: 4,
    memberId: 126,
    name: "username 3",
    firstName: "username",
    lastName: "3",
    email: "username3@gmail.com",
    birthday: "21/04/1996",
    phone: "0123456789",
    workPermitNumber: "654329531",
    passport: "654329531",
    nationality: "singapore",
    gender: "male",
  },
  {
    id: 5,
    memberId: 127,
    name: "username 4",
    firstName: "username",
    lastName: "4",
    email: "username4@gmail.com",
    birthday: "21/04/1996",
    phone: "0123456789",
    workPermitNumber: "654329531",
    passport: "654329531",
    nationality: "vietnam",
    gender: "male",
  },
];

export function getDuplicateUser(id) {
  return (
    axios
      .get(`${BASE_URL}/all-duplicate/${id}`)
      .then((res) => res.data)
      .catch((error) => console.log(error))
  );
}

export function getUserById(id) {
  return axios
    .get(`${BASE_URL}/${id}`)
    .then((res) => res.data)
    .catch((error) => console.log(error));
}

export function updateMainUser(data) {
  return axios.put(`${BASE_URL}/merge-duplicate/${data.id}`, data)
    .then((res) => res.data)
    .catch((error) => console.log(error))
}
