import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { getPageMembershipCard, removeMembershipCard, saveMembershipCard } from "./CardService";
import { showErrorNotify, showSuccessNotify } from "../../core/service/NotificationService";
import { getTablePageReport } from "../../core/service/CommonService";
import { Dialog } from "primereact/dialog";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Fieldset } from "primereact/fieldset";
import { useRouteMatch } from "react-router";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

const statusEnum = {
  NOT_ASSIGNED: "not_assigned",
  ASSIGNED: "assigned",
  LOST: "lost",
};

export default function CardMgt() {
  const match = useRouteMatch();

  const defaultFilter = {
    number: "",
    status: null,
  };
  const statusTypeOption = [
    { label: "Assigned", value: statusEnum.ASSIGNED },
    { label: "Not-assigned", value: statusEnum.NOT_ASSIGNED },
    { label: "Lost", value: statusEnum.LOST },
  ];

  const [filter, setFilter] = useState(defaultFilter);

  const [listCard, setListCard] = useState([]);
  const [cardNumberInput, setCardNumberInput] = useState(``);
  const [pageable, setPageable] = useState({
    page: 0,
    rows: 10,
    total: 0,
    sortField: "id",
    sortOrder: -1,
  });
  const [isLoadTable, setLoadTable] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [deleteCard, setDeleteCard] = useState("");

  useEffect(() => {
    if (isLoadTable) loadCard();
  }, [isLoadTable]);

  const Tag = (value) => {
    let icon = "";
    let color = "#000";
    let bgColor = "#fff";
    let inText = ``;
    if (value === statusEnum.LOST) {
      color = "#FAAD14";
      bgColor = "#FFFBE6";
      icon = "pi-question";
      inText = `lost`;
    }
    if (value === statusEnum.NOT_ASSIGNED) {
      color = "#1890FF";
      bgColor = "#E6F7FF";
      icon = "pi-user-plus";
      inText = `not assigned`;
    }
    if (value === statusEnum.ASSIGNED) {
      color = "#77C41A";
      bgColor = "#F6FFED";
      icon = "pi-check-circle";
      inText = `assigned`;
    }

    return (
      <div
        className="p-d-flex p-align-center p-justify-center"
        style={{
          border: `1px solid ${color}ba`,
          borderRadius: "5px",
          width: 150,
          padding: "3px 15px",
          margin: "auto",
          color: `${color}`,
          background: `${bgColor}`,
        }}
      >
        <i className={`pi ${icon} p-mr-2`}></i>
        <span>{inText}</span>
      </div>
    );
  };

  const tagTemplate = (rowData) => Tag(rowData.status);

  const loadCard = () => {
    getPageMembershipCard(pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder, filter)
    .then((res) => {
      setListCard(res.content);
      setPageable({
        ...pageable,
        total: res.totalElements,
        page: res.pageable.pageNumber,
        rows: res.pageable.pageSize,
      });
      setLoadTable(false);
      handleAddNewCard(res.content)
    });
  };

  const handleAddNewCard = (list) =>
    setListCard([
      ...list,
      {
        number: null,
        status: null,
        createdAt: null,
        updatedAt: null,
      },
    ]);

  const tableHeader = (
    <TableHeader
      refresh={() => setLoadTable(true)}
      title="Cards Management"
      changePageLength={(e) => onChangePageLength(e)}
      pageReport={getTablePageReport(
        pageable.page,
        pageable.rows,
        pageable.total
      )}
    />
  );

  const cardNumberTemplate = (rowData) =>
    rowData.number ? (
      rowData.number
    ) : (
      <InputText
        type="text"
        value={cardNumberInput}
        onChange={(e) => setCardNumberInput(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === `Enter`) {
            setCardNumberInput(``);
            save();
          }
        }}
      />
    );

  const save = () => {
    setLoadTable(true);
    saveMembershipCard(
      {
        number: cardNumberInput,
      },
      () => {
        setLoadTable(false);
      }
    );
  };

  const onPage = (e) => {
    setPageable({ ...pageable, page: e.page });
    setLoadTable(true);
  };

  const onSort = (e) => {
    setPageable({
      ...pageable,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    setLoadTable(true);
  };

  const onChangePageLength = (e) => {
    setPageable({ ...pageable, rows: e, page: 0 });
    setLoadTable(true);
  };

  const applyFilter = () => {
    setPageable({ ...pageable, page: 0 });
    setLoadTable(true);
  };

  const resetFilter = () => {
    setPageable({ ...pageable, page: 0 });
    setFilter(defaultFilter);
    setLoadTable(true);
  };

  const handleDeleteCard = () => {
    removeMembershipCard(deleteCard).then((res) => {
      if (!res.errorCode) {
        setLoadTable(true);
        showSuccessNotify('Delete membership card successfully!');
        handleClose();
      } else {
        showErrorNotify('Cannot perform action');
      }
    });
  }

  const handleClose = () => setShowModal(false);
  const handleShow = (id) => {
    setShowModal(true);
    setDeleteCard(id);
  };

  const footer = (
    <>
      <Button
        icon="pi pi-trash"
        label="Yes"
        onClick={handleDeleteCard}
        style={{ background: "red", color: "white" }}
      />
      <Button label="No" icon="pi pi-times" onClick={handleClose} />
    </>
  );

  return (
    <div className="p-grid">
      <BreadcrumbsItem to={match.url}>Card Management</BreadcrumbsItem>

      <div className="p-col-12">
        <div className="card card-w-title p-mb-5">
          <h1 className="p-w-bold p-m-0">Card Management</h1>
          <Fieldset legend="Filters">
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-md-6">
                <div className="p-grid">
                  <div className="p-col-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Card number:</span>
                      <InputNumber
                        value={filter.number ? parseInt(filter.number) : null}
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            number: e.target.value.toString(),
                          })
                        }
                        format={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-col-12 p-md-6">
                <div className="p-grid">
                  <div className="p-col-12">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Card status:</span>
                      <Dropdown
                        options={statusTypeOption}
                        value={filter.status}
                        onChange={(e) =>
                          setFilter({ ...filter, status: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-c">
                <Button
                  className="blue-btn"
                  icon="pi pi-filter"
                  iconPos="left"
                  label="Search"
                  onClick={() => applyFilter()}
                />
                <Button
                  className="yellow-btn"
                  icon="pi pi-trash"
                  iconPos="left"
                  label="Clear"
                  onClick={() => resetFilter()}
                />
              </div>
            </div>
          </Fieldset>
        </div>

        <DataTable
          value={listCard}
          style={{ textAlign: "center" }}
          lazy={true}
          loading={isLoadTable}
          paginator={true}
          first={pageable.page * pageable.rows}
          rows={pageable.rows}
          totalRecords={pageable.total}
          sortField={pageable.sortField}
          sortOrder={pageable.sortOrder}
          header={tableHeader}
          onPage={onPage}
          onSort={onSort}
          responsive={true}
        >
          <Column header="Card Number" body={cardNumberTemplate} />
          <Column field="status" header="Status" body={tagTemplate} />
          <Column
            header="Created At"
            body={(rowData) => moment(rowData.createdAt).format(`DD/MM/YYYY`)}
          />
          <Column
            field="updatedAt"
            header="Updated At"
            body={(rowData) =>
              rowData.updatedAt
                ? moment(rowData.updatedAt).format(`DD/MM/YYYY`)
                : null
            }
          />
          <Column
            header="Action"
            body={(rowData) => rowData.id && (
              <> 
                <Dialog
                  header="Confirmation"
                  visible={showModal}
                  style={{ color: "black" }}
                  modal={true}
                  onHide={handleClose}
                  footer={footer}
                >
                  Are you sure to delete this card ?
                </Dialog>
                <Button
                  className="p-button-danger"
                  label="Delete"
                  icon="pi pi-trash"
                  onClick={() => handleShow(rowData.id)}
                  tooltipOptions={{ position: "left" }}
                />
              </>
            )}
          />
        </DataTable>
      </div>
    </div>
  );
}
