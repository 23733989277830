import React, { Component } from 'react';
import { exportParticipants, getPageParticipants, participantCheckin, ParticipantServices, removeParticipant } from './ParticipantServices';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Fieldset } from 'primereact/fieldset';
import { MultiSelect } from 'primereact/multiselect';
import { TABLE_PAGING_LENGTH, PARTICIPANT_STATUS, APP_FEP, PRODUCT_TYPE, DATE_FORMAT_DISPLAY, TIME_FORMAT_DISPLAY } from '../../constants';
import { SplitButton } from 'primereact/splitbutton';
import { showErrorNotify, showNotification, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { ConfirmNotification } from '../../core/components/ConfirmNotification';
import { ParticipantTransferForm } from './ParticipantTransferForm';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';
import { getListProducts, getProductAvailabilities } from '../../pim/product/ProductServices';
import { Calendar } from 'primereact/calendar';
import { ViewLogApplicationForm } from '../../scp/application/ViewLogApplicationForm';
import classNames from 'classnames';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport, getTimeZone } from '../../core/service/CommonService';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';

const fileDownload = require('js-file-download');

const CHECK_IN_STATUS = {
	checked_in: { value: true, label: 'Checked in' },
	not_check_in: { value: false, label: 'Not check in yet' },
};

const BOOKING_STATUS = {
	[PARTICIPANT_STATUS.pending.value]: { ...PARTICIPANT_STATUS.pending },
	[PARTICIPANT_STATUS.processing.value]: { ...PARTICIPANT_STATUS.processing },
	[PARTICIPANT_STATUS.completed.value]: { ...PARTICIPANT_STATUS.completed },
	[PARTICIPANT_STATUS.canceled.value]: { ...PARTICIPANT_STATUS.canceled },
};

export class Participant extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dataList: [],
			rows: 10,
			page: 0,
			total: 0,
			sortField: 'id',
			sortOrder: -1,
			filter: { statuses: [PARTICIPANT_STATUS.completed.value], isCourseProgram: false },
			products: [],
		};
		this.participantServices = new ParticipantServices();
	}

	componentDidMount() {
		this.loadPageParticipants();
		this.loadProducts();
	}

	handleCheckin = (data) => {
		if ([PARTICIPANT_STATUS.pending.value, PARTICIPANT_STATUS.processing.value].includes(data.status)) {
			showConfirmNotify({
				message: 'The order related with participant has not been paid completely. Must process on order first!',
				acceptLabel: 'Yes, go to the order',
				accept: () => this.props.history.push(`/orders/${data.orderId}`),
			});
		} else {
			participantCheckin(data.id).then((res) => {
				if (!res.errorCode) {
					if (res.inCompletedLog) {
						this.viewLogForm.openForm(data.id);
						showErrorNotify('Please complete the application log as required to continue!');
					} else {
						showSuccessNotify('Checked-in');
						this.loadPageParticipants();
					}
				} else {
					showErrorNotify(res.errorMessage);
				}
			});
		}
	};

	loadPageParticipants() {
		getPageParticipants(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder).then((res) => {
			this.setState({
				dataList: res.content,
				total: res.totalElements,
				page: res.pageable.pageNumber,
				rows: res.pageable.pageSize,
			});
		});
	}

	onPage = (e) => {
		this.setState(
			{
				page: e.page,
			},
			() => this.loadPageParticipants()
		);
	};

	onSort = (e) => {
		this.setState(
			{
				sortField: e.sortField,
				sortOrder: e.sortOrder,
			},
			() => this.loadPageParticipants()
		);
	};

	loadProducts = () => {
		getListProducts({ app: APP_FEP, types: [PRODUCT_TYPE.program.value, PRODUCT_TYPE.session.value], isCourse: false }).then((res) => {
			this.setState({ products: res ? res : [] });
		});
	};

	loadProductAvailabilities = () => {
		if (this.state.filter.refId) {
			getProductAvailabilities(this.state.filter.refId, { isAvailableByCurrent: false }, false).then((res) => {
				this.setState({ availabilities: res ? res : [] });
			});
		} else {
			this.setState({ availabilities: [] });
		}
	};

	handleUnregister = (ids) => {
		if (!ids || ids.length === 0) return;
		this.participantServices.unregister(ids).then((res) => {
			if (!res.errorCode) {
				this.loadPageParticipants();
				showNotification('success', 'Success Message', 'Action submitted');
			} else {
				showNotification('error', 'Error Message', 'Cannot perform action');
			}
		});
	};

	handleExport = () => {
		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = 'rpt_participant_' + suf + '.xlsx';

		exportParticipants(this.state.filter).then((res) => {
			if (!res.errorCode) {
				fileDownload(res, fileName);
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	handleRemove = (participantId) => {
		removeParticipant(participantId).then((res) => {
			if (!res.errorCode) {
				this.loadPageParticipants();
				showNotification('success', 'Success Message', 'Action submitted');
			} else {
				showNotification('error', 'Error Message', 'Cannot perform action');
			}
		});
	};

	onRemove = (data) => {
		const timezone = getTimeZone();
		const now = parseInt(moment.tz(moment(), timezone).format('YYYYMMDDHHmm'));
		const start = parseInt(moment(`${data.start}${data.type === PRODUCT_TYPE.program && data.courseProgram ? ' 00:00' : ''}`).format('YYYYMMDDHHmm'));
		const end = parseInt(moment(`${data.end}${data.type === PRODUCT_TYPE.program && data.courseProgram ? ' 23:59' : ''}`).format('YYYYMMDDHHmm'));

		let isSubmit = false;
		let act;

		if (now >= start && now <= end) {
			isSubmit = data.status === 'pending' ? true : false;
			act = 'cancel';
		} else if (now < start) {
			isSubmit = true;
			act = 'cancel';
		} else if (now > end) {
			isSubmit = true;
			act = 'remove';
		}

		const msg = isSubmit ? `You're attempting to ${act} the booking. This action can not be undo. Do you want to continue?` : 'Not able to remove/cancel the booking due to it is ongoing.';

		if (isSubmit) {
			showConfirmNotify({
				message: msg,
				accept: () => this.handleRemove(data.id),
			});
		} else {
			showErrorNotify(msg, 'Notification');
		}
	};

	onProductChange = (e) => {
		this.setState(
			{
				filter: { ...this.state.filter, refId: e.value, refIds: e.value ? [e.value] : null, productAvailabilityIds: [] },
			},
			() => {
				this.loadProductAvailabilities();
			}
		);
	};

	onDateChange = (e) => {
		this.setState({
			filter: {
				...this.state.filter,
				dateValue: e.value,
				dateFrom: e && e.value[0] ? moment(e.value[0]).format(moment.HTML5_FMT.DATE) : '',
				dateTo: e && e.value[1] ? moment(e.value[1]).format(moment.HTML5_FMT.DATE) : '',
				productAvailabilityIds: [],
			},
		});
	};

	renderListAvailability = () => {
		let { availabilities, filter } = this.state;

		let tmpArr = [];
		if (filter.dateValue && filter.dateValue.length > 0) {
			availabilities &&
				availabilities.map((availability) => {
					let tmpStartDate = moment(availability.startDate);
					let tmpEndDate = moment(availability.endDate);

					let tmpSelectedStartDate = moment(moment(filter.dateValue[0]).format(DATE_FORMAT_DISPLAY));
					let tmpSelectedEndDate = moment(moment(filter.dateValue[1]).format(DATE_FORMAT_DISPLAY));

					if (tmpSelectedStartDate.isValid() && tmpSelectedEndDate.isValid()) {
						if (tmpStartDate.isSameOrAfter(tmpSelectedStartDate) && tmpEndDate.isSameOrBefore(tmpSelectedEndDate)) {
							tmpArr.push(availability);
						}
					} else if (tmpSelectedStartDate.isValid()) {
						if (tmpStartDate.isSameOrAfter(tmpSelectedStartDate) && tmpEndDate.isSameOrBefore(tmpSelectedStartDate)) {
							tmpArr.push(availability);
						}
					}
				});
		} else {
			tmpArr = availabilities ? [...availabilities] : [];
		}

		let tmpItems = tmpArr.map((item) => {
			let startDateFmt = moment(item.startDate).format(DATE_FORMAT_DISPLAY);
			let endtDateFmt = moment(item.endDate).format(DATE_FORMAT_DISPLAY);
			let startTimeFmt = moment(item.startTime, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY);
			let endTimeFmt = moment(item.endTime, moment.HTML5_FMT.TIME).format(TIME_FORMAT_DISPLAY);

			return {
				value: item.id,
				label: item.dateFrom === item.dateTo ? startDateFmt + ' ' + startTimeFmt + ' - ' + endTimeFmt : startDateFmt + ' ' + startTimeFmt + ' - ' + endtDateFmt + ' ' + endTimeFmt,
			};
		});
		return tmpItems;
	};

	clearFilter = () => {
		this.setState(
			{
				filter: {
					...this.state.filter,
					name: '',
					email: '',
					statuses: [PARTICIPANT_STATUS.completed.value],
					refId: null,
					refIds: null,
					dateValue: '',
					dateFrom: '',
					dateTo: '',
					productAvailabilityIds: [],
					orderIdNumber: '',
					checkin: null,
					assetName: '',
				},
			},
			() => this.loadPageParticipants()
		);
	};

	applyFilter = () => {
		this.setState(
			{
				page: 0,
			},
			() => this.loadPageParticipants()
		);
	};

	actionTemplate = (rowData) => {
		if (rowData.status !== PARTICIPANT_STATUS.canceled.value) {
			// const timezone = getTimeZone();
			// const strNow = timezone ? moment().tz(timezone).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm');

			// const now = moment(strNow);
			// const end = moment(rowData.end);

			// let isExpired = false;

			// if (now.isAfter(end)) isExpired = true;

			if (rowData.status === PARTICIPANT_STATUS.completed.value && !rowData.checkin) {
				// let items = [
				// {label: 'View Log', icon: 'pi pi-search', command: () => this.viewLogForm.openForm(rowData.id)},
				// {label: 'Remove', icon: 'pi pi-trash', command: () => this.onRemove(rowData.id)},
				// ]

				// if(rowData.availabilityId && !isExpiredSchedule){
				//     items.push({label: 'Transfer', icon: 'pi pi-arrow-right p-size-18', command: () => transferForm.current.openForm(rowData.id)})
				// }

				// return <SplitButton label="Check-in" className="p-button-constrast p-l" model={items} onClick={() => this.handleCheckin(rowData)} />
				let tmpHtmlActions = [];
				if (isScpAdmin() || hasRole([ROLES_CONFIG.REPORT_PARTICIPANT_W])) {
					tmpHtmlActions.push(<Button label='Check-in' className='p-button-constrast' onClick={() => this.handleCheckin(rowData)} />);
				}
				if (isScpAdmin() || hasRole([ROLES_CONFIG.REPORT_PARTICIPANT_D])) {
					tmpHtmlActions.push(<Button label='Remove' className='p-button-danger p-margin-top-5' onClick={() => this.onRemove(rowData)} />);
				}

				if (tmpHtmlActions.length > 0) {
					return tmpHtmlActions;
				}
			} else {
				// return <Button label="View Log" className="p-button-constrast p-l" onClick={() => this.viewLogForm.openForm(rowData.id)} />
				if (isScpAdmin() || hasRole([ROLES_CONFIG.REPORT_PARTICIPANT_D])) return <Button label='Remove' className='p-button-danger' onClick={() => this.onRemove(rowData)} />;
			}
		} else {
			return rowData.note;
		}
	};

	onRedirectTo = (to) => {
		this.props.history.push(to);
	};

	onChangePageLength = (l) => {
		this.setState(
			{
				rows: l,
				page: 0,
			},
			() => {
				this.applyFilter();
			}
		);
	};

	applyBulkAction = () => {
		let selectedPartIds = this.state.selectedParticipants ? this.state.selectedParticipants.map((p) => p.id) : [];
		this.handleUnregister(selectedPartIds);
		this.setState({ selectedParticipants: [] });
	};

	render() {
		const tblHeader = (
			<TableHeader
				title='Participations'
				pageReport={getTablePageReport(this.state.page, this.state.rows, this.state.total)}
				changePageLength={(e) => this.onChangePageLength(e)}
				refresh={() => this.loadPageParticipants()}
				actionTemplate={<Button className='p-button-constrast' label='Export' icon='pi pi-download' disabled={this.state.dataList.length === 0} onClick={() => this.handleExport()} />}
			/>
		);

		return (
			<div className='p-grid'>
				<BreadcrumbsItem to={`${this.props.match.url}`}>Participations</BreadcrumbsItem>

				<ParticipantTransferForm ref={(el) => (this.participantTransfer = el)} refreshTable={() => this.loadPageParticipants()} />

				<ConfirmNotification ref={(el) => (this.confirmNotify = el)} submitConfirm={(id) => this.handleUnregister([id])} message='Are you sure you want to unregister?' />

				<ViewLogApplicationForm ref={(el) => (this.viewLogForm = el)} />

				<div className='p-col-12'>
					<Fieldset legend='Filters'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12 p-md-6'>
								<div className='p-grid'>
									<div className='p-col-12 p-lg-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Name:</span>
											<InputText value={this.state.filter.name} onChange={(e) => this.setState({ filter: { ...this.state.filter, name: e.target.value } })} />
										</div>
									</div>
									<div className='p-col-12 p-lg-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Email:</span>
											<InputText value={this.state.filter.email} onChange={(e) => this.setState({ filter: { ...this.state.filter, email: e.target.value } })} />
										</div>
									</div>
									<div className='p-col-12 p-lg-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Status:</span>
											<MultiSelect
												options={Object.values(BOOKING_STATUS)}
												value={this.state.filter.statuses}
												onChange={(e) => this.setState({ filter: { ...this.state.filter, statuses: e.value } })}
											/>
										</div>
									</div>
									<div className='p-col-12 p-lg-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Check-in status:</span>
											<Dropdown
												value={this.state.filter.checkin}
												options={Object.values(CHECK_IN_STATUS)}
												showClear
												onChange={(e) => this.setState({ filter: { ...this.state.filter, checkin: e.value } })}
											/>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Facility Name:</span>
											<InputText value={this.state.filter.assetName} onChange={(e) => this.setState({ filter: { ...this.state.filter, assetName: e.target.value } })} />
										</div>
									</div>
								</div>
							</div>
							<div className='p-col-12 p-md-6'>
								<div className='p-grid'>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Date Range:</span>
											<Calendar value={this.state.filter.dateValue} onChange={(e) => this.onDateChange(e)} showIcon={true} selectionMode='range' dateFormat='dd-mm-yy' />
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-grid'>
											<div className='p-col-12 p-md-6 p-padding-bottom-0'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Product:</span>
													<Dropdown value={this.state.filter.refId} options={this.state.products} showClear onChange={(e) => this.onProductChange(e)} />
												</div>
											</div>
											<div className='p-col-12 p-md-6 p-padding-bottom-0'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Availability:</span>
													<Dropdown
														value={this.state.filter.productAvailabilityIds && this.state.filter.productAvailabilityIds.length > 0 ? this.state.filter.productAvailabilityIds[0] : null}
														options={this.renderListAvailability()}
														showClear
														onChange={(e) => this.setState({ filter: { ...this.state.filter, productAvailabilityIds: e.value ? [e.value] : [] } })}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>ID/Code Order:</span>
											<InputText value={this.state.filter.orderIdNumber} onChange={(e) => this.setState({ filter: { ...this.state.filter, orderIdNumber: e.target.value } })} />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='p-grid p-margin-top-10'>
							<div className='p-col-12 p-c'>
								<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={this.applyFilter} />
								<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' onClick={this.clearFilter} />
							</div>
						</div>
					</Fieldset>
				</div>
				{/* <div className="p-col-12">
                    <Panel>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-4">
                                <div className="p-inputgroup">
                                    <Dropdown value={this.state.bulkAction?this.state.bulkAction:''} options={bulkActionItems} onChange={(e) => this.setState({ bulkAction: e.value })} />
                                    <Button label="Apply" disabled={!this.state.bulkAction} onClick={() => this.applyBulkAction()} />
                                </div>            
                            </div>
                        </div>
                    </Panel>
                </div> */}
				<div className='p-col-12'>
					<DataTable
						lazy={true}
						paginator={true}
						value={this.state.dataList}
						header={tblHeader}
						first={this.state.page * this.state.rows}
						onPage={this.onPage}
						onSort={this.onSort}
						rows={this.state.rows}
						totalRecords={this.state.total}
						sortField={this.state.sortField}
						sortOrder={this.state.sortOrder}
						responsive={true}
						selection={this.state.selectedParticipants}
						onSelectionChange={(e) => this.setState({ selectedParticipants: e.value })}
					>
						{/* <Column selectionMode="multiple" style={{width: '4em'}} /> */}
						<Column header='ID' field='id' sortable={true} headerStyle={{ width: '10em' }} bodyClassName='p-c' />
						<Column
							header='Name'
							field='user.firstName,user.lastName'
							body={(rowData) =>
								rowData.user && (
									<React.Fragment>
										<div>{rowData.user.name}</div>
										{rowData.user.email && <div className='p-mt-1'>{rowData.user.email}</div>}
									</React.Fragment>
								)
							}
							sortable={true}
						/>
						<Column header='Product Name' field='refName' sortable={true} />
						<Column
							header='Booking Status'
							field='status'
							className='p-c'
							body={(rowData) => <span className={classNames('status', rowData.status)}>{PARTICIPANT_STATUS[rowData.status] && PARTICIPANT_STATUS[rowData.status].label}</span>}
						/>
						<Column
							header='Check-in'
							field='checkin'
							className='p-c'
							body={(rowData) =>
								rowData.status !== PARTICIPANT_STATUS.canceled.value ? (
									<span className={classNames('status', { active: rowData.checkin, inactive: !rowData.checkin })}>{rowData.checkin ? 'Checked in' : 'Not yet'}</span>
								) : (
									''
								)
							}
						/>
						<Column header='Facility' field='asset.name' />
						<Column
							header='Schedule'
							field='start'
							sortable={true}
							body={(rowData) => {
								if (rowData.start || rowData.end) {
									const startDate = moment(rowData.start).format(DATE_FORMAT_DISPLAY);
									const endDate = moment(rowData.end).format(DATE_FORMAT_DISPLAY);
									const startTime = moment(rowData.start).format(TIME_FORMAT_DISPLAY);
									const endTime = moment(rowData.end).format(TIME_FORMAT_DISPLAY);
									return (
										<React.Fragment>
											<div className='p-margin-bottom-5'>Date: {startDate === endDate ? startDate : startDate + ' - ' + endDate}</div>
											{!rowData.courseProgram && <div>Time: {startTime + ' - ' + endTime}</div>}
										</React.Fragment>
									);
								}
							}}
						/>
						{(isScpAdmin() || hasRole([ROLES_CONFIG.REPORT_PARTICIPANT_W]) || hasRole([ROLES_CONFIG.REPORT_PARTICIPANT_D])) && (
							<Column body={this.actionTemplate} headerStyle={{ width: '12em' }} bodyClassName='tc-actions' />
						)}
					</DataTable>
				</div>
			</div>
		);
	}
}
