import axios from 'axios';
import {API_BASE_URL} from "../../constants";

export class TaskServices {

    getNote(id){
        return axios.get(`${API_BASE_URL}/scp/api/notes/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }

    getListNotes(id, type){
        return axios.get(`${API_BASE_URL}/scp/api/notes/list`, {params: {id, type}})
            .then(res => res.data).catch(error => console.log(error));
    }

    saveNote(data){
        return axios.post(`${API_BASE_URL}/scp/api/notes/save`, data)
            .then(res => res.data).catch(error => console.log(error));
    }

    saveNoteActivity(id, data){
        return axios.post(`${API_BASE_URL}/scp/api/notes/${id}/activities`, data)
            .then(res => res.data).catch(error => console.log(error));
    }

    getListNoteActivities(id){
        return axios.get(`${API_BASE_URL}/scp/api/notes/${id}/activities/list`)
            .then(res => res.data).catch(error => console.log(error));
    }

    getMyTasks() {
        return axios.get(`${API_BASE_URL}/scp/api/notes/my-tasks`)
        .then(res => res.data).catch(error => console.log(error));
    }

    getMySections() {
        return axios.get(`${API_BASE_URL}/scp/api/notes/my-sections`)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveTask(data){
        return axios.post(`${API_BASE_URL}/scp/api/notes/save`, data)
            .then(res => res.data).catch(error => console.log(error));
    }

}