import React, { useEffect, useState, useRef } from "react";

import _ from "lodash";

import XLSX from "xlsx";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FileUpload } from "primereact/fileupload";
import { SplitButton } from "primereact/splitbutton";

import { ImportMemberForm } from "./ImportMemberForm";
import {
  showErrorNotify,
  showSuccessNotify,
} from "../../core/service/NotificationService";

import "./ImportMember.css";
import { saveAllRecords } from "./ImportMemberService";
import ErrorRecords from "./ErrorRecords";

export default function ImportMember() {
  const [importedData, setImportedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorRecords, setErrorRecords] = useState([]);
  const [addedSuccess, setAddedSuccess] = useState(0);
  const [addedFailed, setAddedFailed] = useState(0);
  const [addedTotal, setAddedTotal] = useState(0);
  const [saving, setSaving] = useState(false);

  const frmMember = useRef(null);

  useEffect(() => {
    if (importedData.length !== 0) {
      setIsLoading(false);
    }
  }, [importedData]);

  const handleChange = (e) => {
    const files = e.files;
    setIsLoading(true);
    if (files && files[0]) handleImport(files[0]);
  };

  const handleImport = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      const formattedData = handleFormatData(data);
      setImportedData(formattedData);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  const handleFormatData = (data) => {
    console.log(data);
    return data.slice(2, data.length).map((arrInfo, index) => ({
      id: index + 1,

      //basic info
      fullName: arrInfo[1],
      firstName: arrInfo[2],
      lastName: arrInfo[3],
      gender: arrInfo[4],
      dateOfBirth: arrInfo[5],
      nationality: arrInfo[6],

      // communication details
      email: arrInfo[7],
      address: arrInfo[9],
      phone: arrInfo[8],

      // Identity documents
      passportNumber: arrInfo[12],
      passportExpiry: arrInfo[13],
      workPermission: arrInfo[14],
      workPermissionExpiry: arrInfo[15],
      fin: arrInfo[16],
      nric: arrInfo[17],

      //membership info
      memberId: arrInfo[18],
      memberCategory: arrInfo[19],
      memberType: arrInfo[20],
      status: arrInfo[21],
      expiredAt: arrInfo[22],
      renewed: arrInfo[23],
      paymentStatus: arrInfo[24],
    }));
  };

  const handleSave = () => {
    setSaving(true);
    setIsLoading(true)
    if (importedData.length === 0) {
      showErrorNotify("Please select a file");
      return;
    }
    saveAllRecords(importedData)
      .then((res) => {
        if (res != null && !res.errorCode) {
          setErrorRecords(res.errorMap);
          setAddedSuccess(res.successRow);
          setAddedFailed(res.failRow);
          setAddedTotal(res.totalRow);
          setSaving(false);
          setImportedData([]);
          showSuccessNotify("Submitted successfully");
        } else {
          showErrorNotify("Submit failed");
        }
      }).then(() => setIsLoading(false))
      .catch((e) => {
        console.log(e);
        showErrorNotify("Submit failed");
      });
  };

  const handleCloseFile = () => {
    setImportedData([]);
  };

  const tableHeader = (
    <div className="p-grid p-justify-between p-align-center">
      <FileUpload
        customUpload={true}
        onSelect={handleChange}
        uploadHandler={handleSave}
        onRemove={handleCloseFile}
        onClear={handleCloseFile}
        chooseLabel="Upload"
        uploadLabel="Submit"
        className={`${
          importedData.length === 0 ? "bg-none" : ""
        } file-upload-btn`}
      />
    </div>
  );

  const updateMember = (data) => {
    const index = importedData.findIndex((item) => item.id === data.id);
    if (index !== -1) {
      let newImportData = _.cloneDeep(importedData);
      newImportData[index] = data;
      setImportedData(newImportData);
    }
  };

  const removeMember = (id) => {
    const newImportData = importedData.filter((item) => item.id !== id);
    setImportedData(newImportData);
    showSuccessNotify("Delete member successfully!");
  };

  return (
    <>
      <ImportMemberForm ref={frmMember} updateMember={updateMember} />
      <DataTable
        value={importedData}
        emptyMessage="Import file csv/excel"
        paginator={true}
        rows={20}
        responsive={true}
        header={tableHeader}
        loading={isLoading}
      >
        <Column field="id" header="Order" sortable={true} />
        <Column field="fullName" header="FullName" sortable={true} />
        <Column field="workPermission" header="Work Permit No." />
        <Column field="passportNumber" header="Passport Number" />
        <Column field="email" header="Email" />
        <Column field="phone" header="Phone" />
        <Column field="gender" header="Gender" sortable={true} />
        <Column
          field="action"
          header="Action"
          style={{ width: "13em" }}
          bodyClassName="tc-actions"
          body={(rowData) => {
            const actionItems = [
              {
                label: "Edit",
                icon: "pi pi-md-pencil",
                command: () => frmMember.current.openForm(rowData, "edit"),
              },
              {
                label: "Remove",
                icon: "pi pi-times",
                command: () => removeMember(rowData.id),
              },
            ];
            return (
              <SplitButton
                label="View"
                model={actionItems}
                className="p-button-constrast p-l"
                icon="pi pi-eye"
                onClick={() => frmMember.current.openForm(rowData, "view")}
              />
            );
          }}
        />
      </DataTable>
      <p>
        {addedSuccess !== 0 &&
          `Records added: ${addedSuccess} / ${addedTotal} - Failed: ${addedFailed}`}
      </p>
      {errorRecords.length !== 0 && (
        <ErrorRecords
          data={errorRecords}
          isLoading={saving}
          setIsLoading={setSaving}
        />
      )}
    </>
  );
}
