import axios from 'axios';
import {API_BASE_URL} from "../../constants";

export class TalentServices {

    getTalents(page, size, sortField, sortOrder){
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}/scp/api/talents`, {
            params: {page, size, sort}
        }).then(res => {return res.data}).catch(error => console.log(error));
    }

    getTreeTalents(){
        return axios.get(`${API_BASE_URL}/scp/api/talents/tree`).then(res => res.data).catch(error => console.log(error));
    }
    
    getTalent(key){
        return axios.get(`${API_BASE_URL}/scp/api/talents/${key}`).then(res => {return res.data}).catch(error => console.log(error));
    }

    saveTalent(form){
        return axios.post(`${API_BASE_URL}/scp/api/talents/tree`, form)
            .then(res => res.data).catch(error => console.log(error));
    }

    addLevel(talentKey, form){
        return axios.put(`${API_BASE_URL}/scp/api/talents/tree/addlevel/`, form,{
            params: {talentKey}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    saveLevel(talentKey, form){
        return axios.post(`${API_BASE_URL}/scp/api/talents/tree/updatelevel/`, form, {
            params: {talentKey}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    deleteLevel(talentKey, value){
        return axios.delete(`${API_BASE_URL}/scp/api/talents/tree/level/`, {
            params: {talentKey, value}
        })
            .then(res => res.data).catch(error => console.log(error));
    }

    addSkill(talentKey,value, form){
        return axios.post(`${API_BASE_URL}/scp/api/talents/tree/skill/`,form, {
            params: {talentKey, value}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    saveSkill(talentKey,levelKey,form){
        return axios.put(`${API_BASE_URL}/scp/api/talents/tree/skill/`, form, {
            params: {talentKey, levelKey}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    getSkill(talentKey, value){
        return axios.get(`${API_BASE_URL}/scp/api/talents/tree/skill/`, {
            params: {talentKey, value}
        })
        .then(res => res.data).catch(error => console.log(error));
    }

    deleteSkill(talentKey, levelValue, skillValue){
        return axios.delete(`${API_BASE_URL}/scp/api/talents/tree/level/skill/`, {
            params: {talentKey, levelValue, skillValue}
        })
            .then(res => res.data).catch(error => console.log(error));
    }

    deleteTalent(key){
        return axios.delete(`${API_BASE_URL}/scp/api/talents/`, {
            params: {key}
        })
            .then(res => res.data).catch(error => console.log(error));
    }

    getTalentsLevels(){
        return axios.get(`${API_BASE_URL}/scp/api/talents/levels`)
        .then(res => {return res.data}).catch(error => console.log(error));
    }

    getTalentsLevelsSkills(){
        return axios.get(`${API_BASE_URL}/scp/api/talents/levels/skills`)
        .then(res => {return res.data}).catch(error => console.log(error));
    }

    getDepartments(key){
        return axios.get(`${API_BASE_URL}/scp/api/talents/departments/${key}`)
        .then(res => {return res.data}).catch(error => console.log(error));
    }

}