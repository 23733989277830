import axios from "axios";
import { PIM_BASE_URL, PIM_PATH } from "../../constants";

const PIM_SCHEDULES_URL = PIM_BASE_URL + PIM_PATH + "/schedules";


export function getPageSchedules(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.post(`${PIM_SCHEDULES_URL}/page`, filter, {
        params: {page, size, sort}
    }).then(res => res.data).catch(error => console.log(error));
}

export function getListSchedules(filter, simple=true){
    return axios.post(`${PIM_SCHEDULES_URL}/list`, filter, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}

export function saveSchedule(data) {
    return axios.post(`${PIM_SCHEDULES_URL}`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function removeSchedule(id, objId, objType) {
    return axios.delete(`${PIM_SCHEDULES_URL}/${id}`, {params: {objId, objType}})
    .then(res => res.data).catch(error => console.log(error));
}