import React, { useState, useEffect } from 'react';
import moment, { HTML5_FMT } from 'moment';
import { TABLE_PAGING_LENGTH, TIME_SERIES_REPEAT_TYPE, PRODUCT_TYPE, APP_FEP, TAXONOMY_TYPE, HR_RESOURCE_TYPE, DATE_FORMAT_DISPLAY, PARTICIPANT_STATUS } from '../../constants';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getPageProductAvailabilities, saveProductAvailability, removeProductAvailability } from './ProductServices';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { SplitButton } from 'primereact/splitbutton';
import { InputText } from 'primereact/inputtext';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { getListTaxons } from '../taxon/TaxonServices';
import { getListAssets } from '../asset/AssetServices';
import { Message } from 'primereact/message';
import Select from 'react-dropdown-select';
import { AvailabilityScheduleView } from './AvailabilityScheduleView';
import { ScheduleTimes } from './ScheduleTimes';
import { getListParticipants } from '../../constituent-management/participant/ParticipantServices';

const CREATE_TYPE = {
	manually: 0,
	autoDaily: 1,
};

const VIEW_TYPE = {
	availability: 0,
	availability_schedule: 1,
};

export const ProductAvailability = (props) => {
	const isCourse = props.isCourseProgram;
	const [viewType, setViewType] = useState(VIEW_TYPE.availability);
	const [selectedAvailability, setSelectedAvailability] = useState(null);

	const [frmVisible, setFrmVisible] = useState(false);
	const [frmData, setFrmData] = useState({});

	const [assetCategories, setAssetCategories] = useState([]);
	const [assets, setAssets] = useState([]);
	const presenters = props.presenters ? props.presenters : [];
	const memberships = props.membershipProducts && props.membershipProducts.length > 0 ? props.membershipProducts : [];

	const [errors, setErrors] = useState({});
	const [filter, setFilter] = useState({});
	const [data, setData] = useState([]);
	const [isLoadTable, setLoadTable] = useState(false);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'startDate',
		sortOrder: -1,
	});

	useEffect(() => {
		loadAllAssetCategories();
	}, []);

	useEffect(() => {
		if (props.productId) {
			setFilter({ ...filter, availableByCurrent: true });

			setLoadTable(true);
		} else setLoadTable(false);
	}, [props.productId]);

	useEffect(() => {
		if (isLoadTable && props.productId) {
			loadPageProductAvailabilities();
		}
	}, [isLoadTable]);

	useEffect(() => {
		if (frmData.assetCategoryId) {
			loadAllAssetByCategory(frmData.assetCategoryId);
		} else {
			setAssets([]);
		}
	}, [frmData.assetCategoryId]);

	const loadPageProductAvailabilities = () => {
		getPageProductAvailabilities(props.productId, filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder).then((res) => {
			if (res) {
				setData(res.content);
				setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
				setLoadTable(false);
			}
		});
	};

	const onPage = (e) => {
		setPageable({
			...pageable,
			page: e.page,
		});
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({
			...pageable,
			sortField: e.sortField,
			sortOrder: e.sortOrder,
		});
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const onFilterOnlyShowAvailableChange = (e) => {
		setFilter({ ...filter, availableByCurrent: e });
		setPageable({
			...pageable,
			page: 0,
		});

		setLoadTable(true);
	};

	const loadAllAssetCategories = () => {
		getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.asset.value] }).then((res) => setAssetCategories(res));
	};

	const loadAllAssetByCategory = (categoryId) => {
		if (!categoryId) {
			setAssets([]);
			return;
		}

		let branchIds = props.branchId ? [props.branchId] : [];

		getListAssets({ app: APP_FEP, branchIds: branchIds, categoryId: categoryId, active: true }).then((res) => {
			setAssets(res);

			if (res && res.findIndex((a) => a.value === frmData.assignAssetId) === -1) setFrmData({ ...frmData, assignAssetId: null });
		});
	};

	const addOrEditItem = (e) => {
		setFrmData({
			id: e ? e.id : null,
			courseProgram: isCourse,
			name: e ? e.name : '',
			createType: e ? e.createType : CREATE_TYPE.manually,
			repeatType: e ? e.repeatType : TIME_SERIES_REPEAT_TYPE.daily,
			repeatValues: e ? e.schedules : [],
			dateRange: e && e.startDate !== e.endDate ? true : false,
			startDate: e ? e.startDate : null,
			endDate: e ? e.endDate : null,
			startTime: e ? e.startTime : null,
			endTime: e ? e.endTime : null,
			startDateValue: e && e.startDate ? moment(e.startDate, HTML5_FMT.DATE).toDate() : null,
			endDateValue: e && e.endDate ? moment(e.endDate, HTML5_FMT.DATE).toDate() : null,
			startTimeValue: e && e.startTime ? moment(e.startTime, HTML5_FMT.TIME).toDate() : null,
			endTimeValue: e && e.endTime ? moment(e.endTime, HTML5_FMT.TIME).toDate() : null,
			minQty: e && e.minQty ? e.minQty : '',
			maxQty: e && e.maxQty ? e.maxQty : '',
			assetCategoryId: e && e.assetCategoryId ? e.assetCategoryId : null,
			assignAssetId: e && e.assignAssetId ? e.assignAssetId : null,
			presenterIds: e && e.presenters ? e.presenters.map((p) => p.id) : [],
			membershipLimitIds: e ? e.membershipLimitIds : [],
			createAssetSchedule: !e ? true : false,
			multiShifts: isCourse ? true : e ? e.multiShifts : false,
			shiftSchedules: e ? e.shiftSchedules : [],
			shiftSchedulesValue:
				e?.shiftSchedules?.length > 0 ? e.shiftSchedules.map((e) => ({ sVal: moment(e.s, HTML5_FMT.TIME).toDate(), eVal: moment(e.e, HTML5_FMT.TIME).toDate() })) : [{ sVal: null, eVal: null }],
		});
		setFrmVisible(true);
	};

	const closeForm = () => {
		setFrmVisible(false);
		setFrmData({});
		setErrors({});
	};

	const handleRemoveAvailability = async (id) => {
		showloading();

		let tmpParticipants = await getListParticipants({
			refIds: [props.productId],
			productAvailabilityIds: [id],
			statuses: [PARTICIPANT_STATUS.incomplete.value, PARTICIPANT_STATUS.pending.value, PARTICIPANT_STATUS.processing.value, PARTICIPANT_STATUS.completed.value],
		});

		if (tmpParticipants && tmpParticipants.length > 0) {
			showErrorNotify('This session already has participants, please remove participants first to perform this action!');
			stoploading();
			return;
		}

		removeProductAvailability(props.productId, id)
			.then((res) => {
				if (!res.errorCode) {
					setLoadTable(true);
					showSuccessNotify('Action submitted');
				} else {
					showErrorNotify(res.errorMessage);
				}
			})
			.finally(() => stoploading());
	};

	const onDeleteAvailability = (id) => {
		showConfirmNotify({
			accept: () => handleRemoveAvailability(id),
			message: 'Are you sure you want to continue?',
		});
	};

	const submitSaveItem = () => {
		showloading();

		let tmpData = { ...frmData };
		tmpData.startDate = tmpData.startDateValue && moment(tmpData.startDateValue).isValid() ? moment(tmpData.startDateValue).format(HTML5_FMT.DATE) : '';
		tmpData.endDate = tmpData.endDateValue && moment(tmpData.endDateValue).isValid() ? moment(tmpData.endDateValue).format(HTML5_FMT.DATE) : '';

		if (isCourse) {
			tmpData.shiftSchedules = tmpData.shiftSchedulesValue.map((t) => ({
				s: t.sVal && moment(t.sVal).isValid() ? moment(t.sVal).format(HTML5_FMT.TIME) : '',
				e: t.eVal && moment(t.eVal).isValid() ? moment(t.eVal).format(HTML5_FMT.TIME) : '',
			}));
		} else {
			tmpData.startTime = tmpData.startTimeValue && moment(tmpData.startTimeValue).isValid() ? moment(tmpData.startTimeValue).format(HTML5_FMT.TIME) : '';
			tmpData.endTime = tmpData.endTimeValue && moment(tmpData.endTimeValue).isValid() ? moment(tmpData.endTimeValue).format(HTML5_FMT.TIME) : '';
		}

		saveProductAvailability(props.productId, tmpData)
			.then((res) => {
				if (!res.errorCode) {
					showSuccessNotify('Action submitted!');
					setLoadTable(true);
					closeForm();
				} else {
					showErrorNotify(res.errorMessage);
					if (res.errorCode === 400) setErrors(res.errorObj);
				}
			})
			.finally(stoploading);
	};

	const onCreateTypeChange = (e) => {
		setFrmData({
			...frmData,
			createType: e,
			dateRange: e === CREATE_TYPE.autoDaily ? true : false,
		});
	};

	const onWeekDaysChange = (e) => {
		let selectedValues = [...frmData.repeatValues];

		if (e.checked) selectedValues.push(e.value);
		else selectedValues.splice(selectedValues.indexOf(e.value), 1);

		setFrmData({ ...frmData, repeatValues: selectedValues });
	};

	const onDateChange = (e, key) => {
		switch (key) {
			case 'start':
				setFrmData({ ...frmData, startDateValue: e });
				break;
			case 'end':
				setFrmData({ ...frmData, endDateValue: e });
				break;
			default:
				break;
		}
	};

	const onDateBlur = (e, key) => {
		switch (key) {
			case 'start':
				if (!frmData.startDateValue || !moment(frmData.startDateValue).isValid()) setFrmData({ ...frmData, startDateValue: null });
				break;
			case 'end':
				if (!frmData.endDateValue || !moment(frmData.endDateValue).isValid()) setFrmData({ ...frmData, endDateValue: null });
				break;
			default:
				break;
		}
	};

	const onTimeChange = (e, key) => {
		switch (key) {
			case 'start':
				setFrmData({ ...frmData, startTimeValue: e });
				break;
			case 'end':
				setFrmData({ ...frmData, endTimeValue: e });
				break;
			default:
				break;
		}
	};

	const onTimeBlur = (key) => {
		switch (key) {
			case 'start':
				if (!frmData.startTimeValue || !moment(frmData.startTimeValue).isValid()) setFrmData({ ...frmData, startTimeValue: null });
				break;
			case 'end':
				if (!frmData.endTimeValue || !moment(frmData.endTimeValue).isValid()) setFrmData({ ...frmData, endTimeValue: null });
				break;
			default:
				break;
		}
	};

	const onChangepresenters = (values) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		setFrmData({ ...frmData, presenterIds: arrValues });
	};

	const onChangeMemberships = (values) => {
		let arrValues = [];
		if (values) arrValues = values.map((e) => e.value);

		setFrmData({ ...frmData, membershipLimitIds: arrValues });
	};

	const tblHeader = <TableHeader title='Sessions' pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)} changePageLength={(e) => onChangePageLength(e)} showRefresh={false} />;

	const viewAvailabilitySchedule = (data) => {
		setSelectedAvailability(data);
		setViewType(VIEW_TYPE.availability_schedule);
		setFrmVisible(false);
	};

	return (
		<React.Fragment>
			{viewType === VIEW_TYPE.availability ? (
				<div className='p-grid'>
					{!frmVisible && (
						<div className='p-col-12'>
							<Button label='Add activity sessions' icon='pi pi-plus' className='p-button-success btn-text-sm' iconPos='left' onClick={() => addOrEditItem()} />
						</div>
					)}
					{frmVisible && (
						<div className='p-col-12 p-pb-0'>
							<Fieldset legend='Session setup'>
								<div className='p-grid'>
									{isCourse ? (
										<div className='p-col-12 p-pb-0'>
											<div className='p-grid p-fluid form-group'>
												<div className='p-col-12'>
													<label className='p-label'>* Session name</label>
													<InputText value={frmData.name} onChange={(e) => setFrmData({ ...frmData, name: e.target.value })} />
													<span className='p-form-error'>{errors.name}</span>
												</div>
												<div className='p-col-12'>
													<label className='p-label'>* Trainers</label>
													<Select
														multi
														values={presenters && frmData.presenterIds && frmData.presenterIds.length > 0 ? presenters.filter((x1) => frmData.presenterIds.some((x2) => x2 === x1.value)) : []}
														options={presenters}
														onChange={(values) => onChangepresenters(values)}
														style={{ width: '100%' }}
														placeholder='Select trainer'
														noDataRenderer={() => {
															return <span className='p-c p-padding-10-0'>No Data</span>;
														}}
													/>
													<div className='p-form-error'>{errors.presenters}</div>

													{frmData.id ? (
														<React.Fragment>
															<i>Note: If you change trainer(s). You should look at the schedule to assign trainer manually!</i>
														</React.Fragment>
													) : (
														<div className='p-margin-top-5'>
															<RadioButton
																inputId='rdAutoSetPresenterSchedule'
																value={false}
																onChange={(e) => setFrmData({ ...frmData, manualSetPresenterForSchedule: e.value })}
																checked={!frmData.manualSetPresenterForSchedule}
															/>
															<label htmlFor='rdAutoSetPresenterSchedule' className='p-radiobutton-label'>
																Automatically set up trainer(s) for schedules
															</label>
															<RadioButton
																inputId='rdManualSetPresenterSchedule'
																value={true}
																className='p-margin-left-20'
																onChange={(e) => setFrmData({ ...frmData, manualSetPresenterForSchedule: e.value })}
																checked={frmData.manualSetPresenterForSchedule}
															/>
															<label htmlFor='rdManualSetPresenterSchedule' className='p-radiobutton-label'>
																Manually set up the trainer(s) later for schedules
															</label>
														</div>
													)}
													<div></div>
												</div>
												<div className='p-col-12'>
													<label className='p-label'>Membership limit</label>
													<Select
														multi
														values={
															memberships && frmData.membershipLimitIds && frmData.membershipLimitIds.length > 0
																? memberships.filter((x1) => frmData.membershipLimitIds.some((x2) => x2 === x1.value))
																: []
														}
														options={memberships}
														onChange={(values) => onChangeMemberships(values)}
														style={{ width: '100%' }}
														placeholder='Select membership type'
														noDataRenderer={() => {
															return <span className='p-c p-padding-10-0'>No Data</span>;
														}}
													/>
												</div>
											</div>
										</div>
									) : (
										<div className='p-col-12'>
											<label className='p-label'>Trainer</label>
											<Select
												multi
												values={presenters && frmData.presenterIds && frmData.presenterIds.length > 0 ? presenters.filter((x1) => frmData.presenterIds.some((x2) => x2 === x1.value)) : []}
												options={presenters}
												onChange={(values) => onChangepresenters(values)}
												style={{ width: '100%' }}
												placeholder='Select trainer'
												noDataRenderer={() => {
													return <span className='p-c p-padding-10-0'>No Data</span>;
												}}
											/>
										</div>
									)}

									{!frmData.id && (
										<React.Fragment>
											<div className='p-col-12 p-mt-1'>
												<label className='p-w-bold p-display-inline-block' style={{ width: '150px' }}>
													{isCourse ? (!frmData.id ? 'Create schedule for' : 'Schedule for') : 'Creating for'}:
												</label>
												<RadioButton value={CREATE_TYPE.manually} className='p-margin-left-20' onChange={(e) => onCreateTypeChange(e.value)} checked={frmData.createType === CREATE_TYPE.manually} />
												<label className='p-radiobutton-label'>Single session</label>
												<RadioButton value={CREATE_TYPE.autoDaily} className='p-margin-left-20' onChange={(e) => onCreateTypeChange(e.value)} checked={frmData.createType === CREATE_TYPE.autoDaily} />
												<label className='p-radiobutton-label'>Recurrence sessions</label>
											</div>

											{frmData.createType === CREATE_TYPE.autoDaily && (
												<React.Fragment>
													<div className='p-col-12'>
														<label className='p-w-bold p-display-inline-block' style={{ width: '150px' }}>
															{isCourse ? 'Schedule repeat on:' : 'Repeat on:'}
														</label>
														<RadioButton
															value={TIME_SERIES_REPEAT_TYPE.daily}
															className='p-margin-left-20'
															onChange={(e) => setFrmData({ ...frmData, repeatType: e.value })}
															checked={frmData.repeatType === TIME_SERIES_REPEAT_TYPE.daily}
														/>
														<label className='p-radiobutton-label'>Every day</label>
														<RadioButton
															value={TIME_SERIES_REPEAT_TYPE.weekDay}
															className='p-margin-left-20'
															onChange={(e) => setFrmData({ ...frmData, repeatType: e.value })}
															checked={frmData.repeatType === TIME_SERIES_REPEAT_TYPE.weekDay}
														/>
														<label className='p-radiobutton-label'>Specific day in week</label>
													</div>
													{frmData.repeatType === TIME_SERIES_REPEAT_TYPE.weekDay && (
														<div className='p-col-12'>
															<label className='p-w-bold p-display-inline-block' style={{ width: '150px' }}>
																Day repeat:
															</label>
															<Checkbox inputId='cbMon' className='p-margin-left-20' value='mon' onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('mon')}></Checkbox>
															<label htmlFor='cbMon' className='p-checkbox-label p-margin-right-15'>
																Mon
															</label>
															<Checkbox inputId='cbTue' value='tue' onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('tue')}></Checkbox>
															<label htmlFor='cbTue' className='p-checkbox-label p-margin-right-15'>
																Tue
															</label>
															<Checkbox inputId='cbWed' value='wed' onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('wed')}></Checkbox>
															<label htmlFor='cbWed' className='p-checkbox-label p-margin-right-15'>
																Wed
															</label>
															<Checkbox inputId='cbThu' value='thu' onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('thu')}></Checkbox>
															<label htmlFor='cbThu' className='p-checkbox-label p-margin-right-15'>
																Thu
															</label>
															<Checkbox inputId='cbFri' value='fri' onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('fri')}></Checkbox>
															<label htmlFor='cbFri' className='p-checkbox-label p-margin-right-15'>
																Fri
															</label>
															<Checkbox inputId='cbSat' value='sat' onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('sat')}></Checkbox>
															<label htmlFor='cbSat' className='p-checkbox-label p-margin-right-15'>
																Sat
															</label>
															<Checkbox inputId='cbSun' value='sun' onChange={onWeekDaysChange} checked={frmData.repeatValues.includes('sun')}></Checkbox>
															<label htmlFor='cbSun' className='p-checkbox-label'>
																Sun
															</label>
															<div className='p-form-error' style={{ paddingLeft: '100px' }}>
																{errors.repeatValues}
															</div>
														</div>
													)}
												</React.Fragment>
											)}
										</React.Fragment>
									)}

									{((!frmData.id && frmData.createType === CREATE_TYPE.manually && !isCourse) || (frmData.id && !isCourse)) && (
										<div className='p-col-12'>
											<Checkbox inputId='cbIsRange' checked={frmData.dateRange} onChange={(e) => setFrmData({ ...frmData, dateRange: e.checked })} />
											<label htmlFor='cbIsRange' className='p-margin-left-10'>
												Is date range?
											</label>
										</div>
									)}

									{(!frmData.id || (frmData.id && !isCourse)) && (
										<React.Fragment>
											<div className='p-col-12 p-pb-0'>
												<div className='p-grid p-fluid form-group'>
													<div className='p-col-6 p-md-4'>
														<label className='p-label'>* {frmData.dateRange ? 'Start Date' : 'Date'}</label>
														<MaskedCalendar
															value={frmData.startDateValue}
															showIcon={true}
															onChange={(e) => onDateChange(e.value, 'start')}
															onBlur={(e) => onDateBlur(e.target.value, 'start')}
															dateFormat='dd-mm-yy'
														/>
														<span className='p-form-error'>{errors.startDate}</span>
													</div>
													{frmData.dateRange && (
														<div className='p-col-6 p-md-4'>
															<label className='p-label'>* End Date</label>
															<MaskedCalendar
																value={frmData.endDateValue}
																showIcon={true}
																onChange={(e) => onDateChange(e.value, 'end')}
																onBlur={(e) => onDateBlur(e.target.value, 'end')}
																dateFormat='dd-mm-yy'
															/>
															<span className='p-form-error'>{errors.endDate}</span>
														</div>
													)}
												</div>
											</div>
											<div className='p-col-12 p-pb-0'>
												{isCourse ? (
													<ScheduleTimes value={frmData.shiftSchedulesValue} errors={errors.shiftSchedules ?? {}} onChange={(value) => setFrmData({ ...frmData, shiftSchedulesValue: value })} />
												) : (
													<div className='p-grid p-fluid form-group'>
														<div className='p-col-6 p-md-4'>
															<label className='p-label'>* Start Time</label>
															<MaskedCalendar
																value={frmData.startTimeValue}
																showIcon={true}
																onChange={(e) => onTimeChange(e.value, 'start')}
																onBlur={(e) => onTimeBlur(e.target.value, 'start')}
																hourFormat='12'
																timeOnly={true}
															/>
															<span className='p-form-error'>{errors.startTime}</span>
														</div>
														<div className='p-col-6 p-md-4'>
															<label className='p-label'>* End Time</label>
															<MaskedCalendar
																value={frmData.endTimeValue}
																showIcon={true}
																onChange={(e) => onTimeChange(e.value, 'end')}
																onBlur={(e) => onTimeBlur(e.target.value, 'end')}
																hourFormat='12'
																timeOnly={true}
															/>
															<span className='p-form-error'>{errors.endTime}</span>
														</div>
													</div>
												)}
											</div>
										</React.Fragment>
									)}

									<div className='p-col-12 p-pb-0'>
										<div className='p-grid p-fluid form-group'>
											<div className='p-col-6 p-md-4'>
												<label className='p-label'>Min q.ty</label>
												<InputText value={frmData.minQty} keyfilter='int' onChange={(e) => setFrmData({ ...frmData, minQty: e.target.value })} />
												<span className='p-form-error'>{errors.minQty}</span>
											</div>
											<div className='p-col-6 p-md-4'>
												<label className='p-label'>Max q.ty (leave blank as unlimited)</label>
												<InputText value={frmData.maxQty} keyfilter='int' showIcon={true} onChange={(e) => setFrmData({ ...frmData, maxQty: e.target.value })} />
												<span className='p-form-error'>{errors.maxQty}</span>
											</div>
										</div>
									</div>

									{(!frmData.id || (frmData.id && !isCourse)) && (
										<div className='p-col-12 p-pb-0'>
											<div className='p-grid p-fluid form-group'>
												<div className='p-col-6 p-md-4'>
													<label className='p-label'>{isCourse && frmData.createAssetSchedule && '*'} Facility Category</label>
													<Dropdown
														value={frmData.assetCategoryId}
														options={assetCategories}
														showClear={true}
														onChange={(e) => setFrmData({ ...frmData, assetCategoryId: e.value })}
														placeholder='Select a facility category'
													/>
													<div className='p-form-error'>{errors?.assetCategory}</div>
												</div>
												<div className='p-col-6 p-md-4'>
													<label className='p-label'>{isCourse && frmData.createAssetSchedule && '*'} Facility Assign</label>
													<Dropdown
														value={frmData.assignAssetId}
														options={assets}
														showClear={true}
														showIcon={true}
														onChange={(e) => setFrmData({ ...frmData, assignAssetId: e.value })}
														placeholder='Select facility to assign'
													/>
													<div className='p-form-error'>{errors?.assignAsset}</div>
												</div>
												{!frmData.id && isCourse && (
													<div className='p-col-12'>
														<Checkbox
															inputId='cbCreateAssetSchedule'
															onChange={(e) => setFrmData({ ...frmData, createAssetSchedule: e.checked })}
															checked={frmData.createAssetSchedule === true}
														></Checkbox>
														<label htmlFor='cbCreateAssetSchedule' className='p-checkbox-label'>
															Also create schedule for selected facility? <i>(Note: If not selected, you must schedule the facility manually!)</i>
														</label>
													</div>
												)}

												{errors.existScheduled && (
													<div className='p-col-12 p-md-8'>
														<Message severity='error' text={errors.existScheduled} />
													</div>
												)}
											</div>
										</div>
									)}
								</div>
								<div className='p-grid p-mt-3'>
									<div className='p-col-12'>
										<Button label='Submit' className='btn-text-sm p-button-success' onClick={() => submitSaveItem()} />
										<Button label='Cancel' className='btn-text-sm p-button-default' onClick={() => closeForm()} />
									</div>
								</div>
							</Fieldset>
						</div>
					)}

					<div className='p-col-12 p-mt-5'>
						<Checkbox inputId='cbShowOnlyAvailable' onChange={(e) => onFilterOnlyShowAvailableChange(e.checked)} checked={filter.availableByCurrent}></Checkbox>
						<label htmlFor='cbShowOnlyAvailable' className='p-checkbox-label'>
							Only show available sessions?
						</label>
					</div>
					<div className='p-col-12'>
						<DataTable
							lazy={true}
							paginator={true}
							value={data}
							loading={isLoadTable}
							first={pageable.page * pageable.rows}
							header={tblHeader}
							onPage={onPage}
							onSort={onSort}
							rows={pageable.rows}
							totalRecords={pageable.total}
							sortField={pageable.sortField}
							sortOrder={pageable.sortOrder}
							responsive={true}
							emptyMessage='No content available!'
						>
							{isCourse && <Column header='Name' field='name' sortable={true} />}

							<Column
								header='Date'
								field='startDate'
								sortable={true}
								body={(rowData) => moment(rowData.startDate).format(DATE_FORMAT_DISPLAY) + (rowData.startDate !== rowData.endDate ? ' - ' + moment(rowData.endDate).format(DATE_FORMAT_DISPLAY) : '')}
							/>
							{!isCourse && <Column header='Start Time' field='startTime' sortable={true} body={(rowData) => moment(rowData.startTime, HTML5_FMT.TIME).format('hh:mm A')} />}

							{!isCourse && <Column header='End Time' field='endTime' sortable={true} body={(rowData) => moment(rowData.endTime, HTML5_FMT.TIME).format('hh:mm A')} />}

							{/* <Column header="Min Q.ty" field="minQty" sortable={true}/>
                        <Column header="Max Q.ty" field="maxQty" sortable={true}/> */}
							{isCourse && (
								<Column
									header='Trainer'
									body={(rowData) => {
										if (rowData.presenters) return rowData.presenters.map((p) => p.name).join(', ');
									}}
								/>
							)}

							{isCourse && (
								<Column
									header='Schedules'
									body={(rowData) => {
										if (rowData.createType === CREATE_TYPE.autoDaily) {
											if (rowData.repeatType === TIME_SERIES_REPEAT_TYPE.weekDay) {
												return rowData.schedules && rowData.schedules.join(', ');
											} else {
												return 'Daily';
											}
										}
									}}
								/>
							)}

							{!isCourse && <Column header='Assign Facility' field='asset.name' />}

							<Column
								header=''
								className='tc-actions'
								style={{ textAlign: 'center', width: '15%' }}
								body={(rowData) => {
									const actionItems = [{ label: 'Remove', icon: 'pi pi-times', command: (e) => onDeleteAvailability(rowData.id) }];
									return (
										<React.Fragment>
											{isCourse && <Button icon='pi pi-calendar' label='Schedule' className='p-button-warning p-m-1' onClick={() => viewAvailabilitySchedule(rowData)} />}
											<SplitButton label='Edit' model={actionItems} icon='pi pi-pencil' className='p-button-constrast' onClick={() => addOrEditItem(rowData)}></SplitButton>
										</React.Fragment>
									);
								}}
							/>
						</DataTable>
					</div>
				</div>
			) : (
				<AvailabilityScheduleView
					availability={selectedAvailability}
					goBack={() => {
						setViewType(VIEW_TYPE.availability);
						setSelectedAvailability(null);
						setLoadTable(true);
					}}
					presenters={presenters.filter((p1) => selectedAvailability.presenters.map((p2) => p2.id).includes(p1.value))}
				/>
			)}
		</React.Fragment>
	);
};
