import React, { forwardRef, useState, useImperativeHandle, useRef } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { showloading, stoploading } from "../../core/service/LoadingService";
import { MEMBERSHIP_CARD_STATUS } from "../../constants";
import { printMembershipCard, saveMembershipCard } from "./MembershipServices";
import { showSuccessNotify, showErrorNotify } from "../../core/service/NotificationService";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import classNames from "classnames";

export const MembershipCardForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState('Create Member Card')
    const [visible, setVisible] = useState(false)
    const [membershipId, setMembershipId] = useState(null)
    const [frmData, setFrmData] = useState({})
    const [errors, setErrors] = useState({})

    const inputCode = useRef(null)

    useImperativeHandle(ref, () => ({
        openForm(msId, memberId, data){
            setMembershipId(msId)
            setFrmData({
                id: data?data.id:null,
                memberId: memberId,
                code: data?data.code:'',
                number: memberId,
                status: data?data.status:MEMBERSHIP_CARD_STATUS.printed.value
            })
            setHeader((data?'Edit':'Create') + ' Member Card')
            setVisible(true)
        }
    }))

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setErrors({})
    }

    const handleSaveMembershipCard = () => {
        showloading()

        saveMembershipCard(frmData)
        .then(res => {
            if(!res.errorCode){
                if(props.refreshTable)
                    props.refreshTable()
                
                if(props.refreshData)
                    props.refreshData(res)

                closeForm()
                showSuccessNotify('Card has been saved!');
            }else{
                if(res.errorCode===400) setErrors(res.errorObj);
                showErrorNotify(res.errorMessage);
            }
            stoploading()
        })
    }

    const handlePrintMemberCard = () => {
        showloading()

        printMembershipCard(membershipId)
        .then(res => {
            if (!res.errorCode) {
                inputCode.current.element.focus()
                showSuccessNotify('Card print request has been sent.');
            } else {
                showErrorNotify('Error on printing card!');
            }
        }).finally(()=>stoploading())
    }

    return (
        <Sidebar visible={visible} position="right" className="p-sidebar-md" style={{ overflowY: "auto"}} blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>
            
            <div className="p-sidebar-line p-mb-3"></div>
            
            <div className="p-grid p-fluid form-group">
                <div className="p-col-12">
                    <label>Card Number: <strong>{frmData.memberId}</strong></label>
                </div>
                <div className="p-col-12">
                    <label className="p-label">Card Code</label>
                    <InputText ref={inputCode} value={frmData.code} keyfilter={/[^\s]/} onChange={(e) => setFrmData({...frmData, code: e.target.value})}/>
                    <div className="p-form-error">{errors.code}</div>
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>
            
            <div className="p-grid">
                {membershipId &&
                    <div className="p-col-12 p-md-4">
                        <Button icon="pi pi-print" label="Print Card" tooltip="Request print membership card" tooltipOptions={{position: 'top'}} className="p-button-info" onClick={() => handlePrintMemberCard()}/>
                    </div>
                }
                <div className={classNames("p-col-12 p-r", {"p-md-8": membershipId?true:false})}>
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => closeForm()} />
                    <Button label="Save" icon="pi pi-save" onClick={() => handleSaveMembershipCard()} />
                </div>
            </div>
        </Sidebar>
    )
})
