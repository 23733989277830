import React from 'react';
import ReactDOM from 'react-dom';
import 'babel-polyfill';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'tippy.js/themes/light.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom'
import { Main } from './Main';
import ScrollToTop from './core/layout/ScrollToTop';
import './core/interceptors/interceptors';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import message_en from "./core/translations/en/message.json";
import 'react-sortable-tree/style.css';
import { ConfirmDialog } from './core/components/notification/ConfirmDialog';
import Notification from './core/components/notification/Notification';
import KcUserService from './service/KcUserService';

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
        en: {
            message: message_en
        },
    },
});

const renderApp = () => ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <BrowserRouter>
            <ScrollToTop>
                <Main></Main>
                <Notification />
                <ConfirmDialog/>
            </ScrollToTop>
        </BrowserRouter>
    </I18nextProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

KcUserService.initKeycloak(renderApp);