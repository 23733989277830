import React, {Component} from "react";
import { Button } from "primereact/button";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import "./doorcontrol.scss";

export class DoorControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount(){
    }

    openCloseDoor = (door) => {
        // if(doorOpened == true){
        //     //API to close door
        // }
        // else if(doorOpened == false){
        //     //API to open door
        // }
        fetch('https://4u7nwtr381.execute-api.us-east-1.amazonaws.com/prod/esb/v1/signup', {
            method: 'POST',
            body: JSON.stringify({
              transaction: {
                channel: "m1",
              },
              service: {
                service_id: "08776667776",
              }
            }),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }).then(response => response.json())
            .then(responseJson => {
              if (responseJson.success == true) {
                console.log("success");
              }
              else {
                console.log("fail");
              }
            }).catch(error => {
              console.error(error);
            });
    }

    render(){
        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Door Control</BreadcrumbsItem>
                {/* <div className="p-col-12"> */}
                    <div className="p-grid p-margin-top-10">
                        <div className="p-col-1">
                        </div>
                        <div className="p-col-2 p-margin-left-30 p-margin-right-30 p-margin-top-20 text-center card">
                        {/* this.handleSaveConfig('general')}  */}
                            <Button label="Open" onClick={() => this.openCloseDoor(1)} />
                        </div>
                        <div className="p-col-2 p-margin-left-30 p-margin-right-30 p-margin-top-20 card">
                            <Button label="Open" onClick={() => this.openCloseDoor(1)} />
                        </div>
                        <div className="p-col-2 p-margin-left-30 p-margin-right-30 p-margin-top-20 card">
                            <Button label="Open" onClick={() => this.openCloseDoor(1)} />
                        </div>
                        <div className="p-col-2 p-margin-left-30 p-margin-right-30 p-margin-top-20 card">
                            <Button label="Open" onClick={() => this.openCloseDoor(1)} />
                        </div>
                        <div className="p-col-1">
                        </div>

                        <div className="p-col-2 p-margin-left-30 p-margin-right-30 p-margin-top-20 text-center card">
                            <Button label="Open" onClick={() => this.openCloseDoor(1)} />
                        </div>
                        <div className="p-col-2 p-margin-left-30 p-margin-right-30 p-margin-top-20 card">
                            <Button label="Open" onClick={() => this.openCloseDoor(1)} />
                        </div>
                        <div className="p-col-2 p-margin-left-30 p-margin-right-30 p-margin-top-20 card">
                            <Button label="Open" onClick={() => this.openCloseDoor(1)} />
                        </div>
                        <div className="p-col-2 p-margin-left-30 p-margin-right-30 p-margin-top-20 card">
                            <Button label="Open" onClick={() => this.openCloseDoor(1)} />
                        </div>
                    </div>                 
                </div>
            // </div>
        );
    }
}
  {/* <TabView>
    <TabPanel header="System">
        <div className="p-grid">
            <div className="p-col-12 p-md-6">
                <Fieldset legend="General Setting">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label className="p-label">Backend Domain</label>
                            <InputText value={this.state.data.scpBEDomain} onChange={(e) => this.setState({data: {...this.state.data, scpBEDomain: e.target.value}})} />
                            <div className="p-form-error">{this.state.errors.scpBEDomain}</div>
                        </div>
                    </div>
                </Fieldset>
            </div>
        </div>
    </TabPanel>
</TabView> */}