import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import {DataTable} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from "../../core/service/NotificationService";
import { useRouteMatch, useHistory } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport, moneyFormat } from "../../core/service/CommonService";
import { exportMembershipList, getPageMemberships, removeMembership } from "./MembershipServices";
import classNames from "classnames";
import { MembershipForm } from "./MembershipForm";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { MultiSelect } from "primereact/multiselect";
import { APP_FEP, DATETIME_FORMAT_DISPLAY, IDENTITY_DOCUMENT_TYPE, MEMBERSHIP_STATUS, PRODUCT_PARTICIPANT_TYPE, PRODUCT_TYPE } from "../../constants";
import { updateMembershipStatus} from './MembershipServices';
import { hasRole, isScpAdmin } from "../../core/security/auth";
import { ROLES_CONFIG } from "../../roles";
import { SplitButton } from "primereact/splitbutton";
import { ExtendMembershipForm } from "./ExtendMembershipForm";
import { getListProducts } from "../../pim/product/ProductServices";
import { Dropdown } from "primereact/dropdown";


const fileDownload = require('js-file-download');

const VERIFIED_OPTION = [
    {value: true, label: 'Yes'},
    {value: false, label: 'No'}
]

export const StudentMembershipListView = () => {
    const match = useRouteMatch()
    const history = useHistory()
    const [dataList, setDataList] = useState([])
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: 'id',
        sortOrder: -1
    })
    const [filter, setFilter] = useState({
        statuses: [MEMBERSHIP_STATUS.Pending.value, MEMBERSHIP_STATUS.Active.value, MEMBERSHIP_STATUS.Expired.value, MEMBERSHIP_STATUS.Block.value],
        participantTypes: [PRODUCT_PARTICIPANT_TYPE.student.value]
    })
    const [isLoadTable, setLoadTable] = useState(true);
    const frmMembership = useRef(null)
    const frmExtendMembership = useRef(null)
    const [products, setProducts] = useState([])

    useEffect(()=>{
        if(isLoadTable)
            loadPageMembership()
    },[isLoadTable])

    useEffect(()=>{
        loadListProduct()
    },[])

    const handleExport = () => {
        let suf = moment().format('YYYYMMDDHHmmss');
        let fileName = 'rpt_membership_student_' + suf + '.xlsx';

        exportMembershipList(filter)
        .then(res=>{
            if (!res.errorCode) {
                fileDownload(res, fileName);
            } else {
                showErrorNotify(res.errorMessage);
            }
        })
    }

    const loadPageMembership = () => {
        getPageMemberships(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const loadListProduct = () =>{
        getListProducts({app: APP_FEP, types: [PRODUCT_TYPE.membership.value], participantTypes: [PRODUCT_PARTICIPANT_TYPE.student.value]})
        .then( res => {
            if(res) {
                setProducts(res)
            }
        })
    }

    const tblHeader = () => {
        return (
            <TableHeader title="Student Memberships" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={
                <React.Fragment>
                    {(isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_STUDENT_MGT_W])) && 
                        <Button className="p-button-success" label="Create" icon="pi-md-plus" onClick={() => frmMembership.current.openForm(null)}/>
                    }
                    {(isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_STUDENT_MGT_R])) && 
                        <Button className="p-button-success" label="Export" icon="pi pi-download" onClick={() => handleExport()}/>
                    }
                </React.Fragment>
            }           
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
        )
    }

    const onDateFromChange = (e) => {
        let maxDate = filter.maxDate ? moment(filter.maxDate) : '';
        let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
        let isCurrentValueAfterMaxDate = (currentValue && maxDate && currentValue.isAfter(maxDate));

        setFilter({
            ...filter,
            dateFrom: currentValue?currentValue.format(moment.HTML5_FMT.DATE):'',
            dateFromValue: e.value,
            minDate: moment(e.value).isValid() ? e.value : '',
            dateTo: !isCurrentValueAfterMaxDate ? filter.dateTo : '',
            dateToValue: !isCurrentValueAfterMaxDate ? filter.dateToValue : '',
            maxDate: !isCurrentValueAfterMaxDate ? filter.maxDate : ''
        })
    }

    const onDateToChange = (e) => {
        let minDate = filter.minDate ? moment(filter.minDate) : '';
        let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
        let isCurrentValueBeforeMinDate = (currentValue && minDate && currentValue.isBefore(minDate));

        setFilter({
            ...filter,
            dateFrom: !isCurrentValueBeforeMinDate ? filter.dateFrom : '',
            dateFromValue: !isCurrentValueBeforeMinDate ? filter.dateFromValue : '',
            minDate: !isCurrentValueBeforeMinDate ? filter.minDate :'',
            dateTo: currentValue?currentValue.format(moment.HTML5_FMT.DATE):'',
            dateToValue: e.value,
            maxDate: moment(e.value).isValid() ? e.value : ''
        })
    }

    const clearFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setFilter({...filter,
            name: '',
            ownerName: '',
            dateFrom: '',
            dateFromValue: '',
            minDate: null,
            dateTo: '',
            dateToValue: '',
            maxDate: null,
            statuses: [MEMBERSHIP_STATUS.Pending.value, MEMBERSHIP_STATUS.Active.value, MEMBERSHIP_STATUS.Expired.value, MEMBERSHIP_STATUS.Block.value],
            identityDocumentNumber: ''
        })
        setLoadTable(true)
    }

    const handleUpdateStatus = (status, membershipId) => {
        updateMembershipStatus(membershipId, status)
        .then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify("Student membership has been update!")
            }else{
                showErrorNotify(res.errorMessage);
            }
        })
    }

    const handleRemoveMembership = (membershipId, isRemoveMembershipAndUserOwner=false) => {
        removeMembership(membershipId, isRemoveMembershipAndUserOwner)
        .then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify("Student membership has been removed!")
            }else{
                showErrorNotify(res.errorMessage);
            }
        })
    }

    const onRemoveMembership = (id) => {
        showConfirmNotify({
            modelActions: [
                {label: 'Remove Student Membership and Owner', icon: 'pi large pi-times', command:()=>  handleRemoveMembership(id, true)},
                {label: 'Remove', icon: 'pi large pi-times', command:()=>  handleRemoveMembership(id)}
            ],
            message: 'Are you sure want to remove this membership student!'
        })
    }

    const onExtendMembershipUsage = (membership) => {
        frmExtendMembership.current.openForm(membership)
    }

    const onKeyPrressEnter = (e) => {
        if (e.key === 'Enter') {
            setLoadTable(true)
        }
    }

    const actionTemplate = (membership) => {
        return (
            <div className="p-c">
                {(isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_STUDENT_MGT_W])) && 
                    <Button label="Edit" className="p-button-info p-mr-1" onClick={() => frmMembership.current.openForm(membership)}/>
                }
                {(isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_STUDENT_MGT_R, ROLES_CONFIG.MEMBERSHIP_STUDENT_MGT_W])) 
                    && ![MEMBERSHIP_STATUS.Canceled.value, MEMBERSHIP_STATUS.Pending.value].includes(membership.status) && 
                    <React.Fragment>
                        {membership.status===MEMBERSHIP_STATUS.Active.value && 
                            <Button label="Block" className="p-button-warning p-mr-1" onClick={() => handleUpdateStatus(MEMBERSHIP_STATUS.Block.value, membership.id)}/>
                        }
                        {membership.status===MEMBERSHIP_STATUS.Block.value && 
                            <Button label="Unblock" className="p-button-success p-mr-1" onClick={() => handleUpdateStatus(MEMBERSHIP_STATUS.Active.value, membership.id)}/>
                        }
                        {membership.status!==MEMBERSHIP_STATUS.Block.value && !membership.orderId && 
                            <Button label="Renew" className="p-button-success p-mr-1" onClick={() => onExtendMembershipUsage(membership)}/>
                        }
                    </React.Fragment>
                }
                {(isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_STUDENT_MGT_D])) && 
                    <Button label="Remove" className="p-button-danger p-m-1" onClick={() => onRemoveMembership(membership.id)}/>
                }
            </div>
        )
    }

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Student Memberships</BreadcrumbsItem>

            <MembershipForm ref={frmMembership}
                participantTypes={[PRODUCT_PARTICIPANT_TYPE.student.value]}
                refreshTable={() => setLoadTable(true)}
            />

            <ExtendMembershipForm ref={frmExtendMembership} 
                reloadTable={()=> setLoadTable(true)}
            />

            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1 className="p-w-bold p-m-0">Student Memberships</h1>
                    <Fieldset legend="Filters">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Category:</span>
                                            <MultiSelect value={filter.templateIds?filter.templateIds:[]} options={products} onChange={e => setFilter({...filter, templateIds: e.value})} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Owner Name/Email:</span>
                                            <InputText value={filter.ownerName} onKeyUp={onKeyPrressEnter} onChange={e => setFilter({...filter, ownerName: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-6">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">FIN/Passport:</span>
                                                    <InputText value={filter.identityDocumentNumber} onKeyUp={onKeyPrressEnter} onChange={e => setFilter({...filter, identityDocumentNumber: e.target.value, identityDocumentTypes: e.target.value?[IDENTITY_DOCUMENT_TYPE.fin.value, IDENTITY_DOCUMENT_TYPE.passport.value]:[]})} />
                                                </div>        
                                            </div>
                                            <div className="p-col-12 p-md-6">
                                                <div className="p-inputgroup">
                                                    <span className="p-inputgroup-addon">Verified:</span>
                                                    <Dropdown value={filter.isVerified} options={VERIFIED_OPTION} showClear onChange={e => setFilter({...filter, isVerified: e.value})} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">From:</span>
                                            <MaskedCalendar value={filter.dateFromValue} maxDate={filter.maxDate} onChange={(e) => onDateFromChange(e)} showIcon={true} dateFormat="dd/mm/yy" />
                                        </div>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">To:</span>
                                            <MaskedCalendar value={filter.dateToValue} minDate={filter.minDate} onChange={(e) => onDateToChange(e)} showIcon={true} dateFormat="dd/mm/yy" />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Status:</span>
                                            <MultiSelect value={filter.statuses?filter.statuses:[]} options={Object.values(MEMBERSHIP_STATUS)} onChange={e => setFilter({...filter, statuses: e.value})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => loadPageMembership()} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={() => clearFilter()} />
                            </div>
                        </div>
                    </Fieldset>
                </div>

                <DataTable emptyMessage="There's no record found" header={tblHeader()} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column header="ID" field="id" bodyClassName="p-c" style={{width:'10em'}} sortable={true}
                        body={rowData => (isScpAdmin() || hasRole([ROLES_CONFIG.MEMBERSHIP_STUDENT_DETAI_R]))
                            ? <Button label={rowData.id} icon="pi pi-search" className="p-button-info" tooltip='View membership' tooltipOptions={{position: "top"}} onClick={() => history.push(`/student-memberships/${rowData.id}`)}/>
                            : rowData.id
                        }
                    />
                    <Column header="Owner" field="user.firstName" bodyClassName="p-nowrap" style={{width: '18em'}} sortable={true}
                        body={(rowData) => rowData.owner && 
                            <React.Fragment>
                                <div>{rowData.owner.name}</div>
                                <div className="p-mt-1">[{rowData.owner.email}]</div>
                                {rowData.owner.phone && <div className="p-mt-1">{rowData.owner.phone}</div>}
                            </React.Fragment> 
                        }
                    />
                    <Column field="name" header="Student Membership" sortable={true} />
                    <Column field="type" header="Type" headerStyle={{width: '8em'}} bodyClassName="p-c" body={(rowData) => <span className="status">{rowData.type}</span>}/>
                    <Column field="status" header="Status" headerStyle={{width: '8em'}} bodyClassName="p-c" body={(rowData) => <span className={classNames('status', 'ms-'+rowData.status)}>{rowData.status}</span>}/>
                    <Column field="verified" header="Verification" bodyClassName="p-c"
                        body={(rowData) => {
                            let tmpFIN = rowData.owner&&rowData.owner.fin?rowData.owner.fin:null
                            let tmpPassport = rowData.owner&&rowData.owner.passport?rowData.owner.passport:null
                            return(
                                <React.Fragment>
                                    {tmpFIN && <div className="p-mb-1"><span title="FIN number">FIN</span>: {tmpFIN.docNumber}</div>}
                                    {tmpPassport && <div  className="p-mb-1"><span title="Passport number">PP</span>: {tmpPassport.docNumber}</div>}
                                    <div>
                                        <span className={classNames('status', {'ms-Active': rowData.verified, 'ms-Block': !rowData.verified })}>{!rowData.verified?'NOT YET':'VERIFIED'}</span>
                                    </div>
                                </React.Fragment>
                            )}
                        }
                    />
                    <Column field="joinDate" header="Join Date" bodyClassName="p-r" body={(rowData) => rowData.joinDate && moment(rowData.joinDate).format(DATETIME_FORMAT_DISPLAY)} sortable={true}/>
                    <Column field="end" header="End Date" bodyClassName="p-r" body={(rowData) => rowData.joinDate && moment(rowData.end).format(DATETIME_FORMAT_DISPLAY)} sortable={true}/>
                    <Column bodyClassName="tc-actions p-r" headerStyle={{width: '13em'}} body={(rowData) => actionTemplate(rowData)}/>
                </DataTable>
            </div>
        </div>
    )
}
