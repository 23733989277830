const CONSTITUENT2 = 'SCP_CONSTITUENT_';
export const ROLES_CONFIG = {
	ACCESS_CONTROL_M: 'SCP_ACCESS_CONTROL_MANAGE',

	//Dashboard and Reports
	// DAR: 'SCP_DAR',
	// DAR_DASHBOARD: 'SCP_DAR_DASHBOARD',
	DAR_DASHBOARD_R: 'SCP_DAR_DASHBOARD_READ',
	// DAR_RERPORT: 'SCP_DAR_REPORT',
	DAR_RERPORT_ORDER: 'SCP_DAR_REPORT_ORDER',
	DAR_RERPORT_ORDER_R: 'SCP_DAR_REPORT_ORDER_READ',
	// DAR_RERPORT_TRANSACTION: 'SCP_DAR_REPORT_TRANSACTION',
	// DAR_RERPORT_TRANSACTION_R: 'SCP_DAR_REPORT_TRANSACTION_READ',
	// DAR_RERPORT_RUN: 'SCP_DAR_REPORT_RUN',
	DAR_DASHBOARD_RERPORT_R: 'SCP_DAR_DASHBOARD_REPORT_READ',
	DAR_DASHBOARD_OPERATING_R: 'SCP_DAR_DASHBOARD_OPEATING_READ',
	DAR_RERPORT_R: 'SCP_DAR_REPORT_READ',
	DAR_RERPORT_INVOICE_R: 'SCP_DAR_REPORT_INVOICE_READ',

	//Frontdesk
	// FRONTDESK: 'SCP_FRONTDESK',
	// FRONTDESK_SCAN: 'SCP_FRONTDESK_SCAN',
	// FRONTDESK_BOOKING: 'SCP_FRONTDESK_BOOKING',
	// FRONTDESK_BOOKING_PROGRAM: 'SCP_FRONTDESK_BOOKING_PROGRAM',
	// FRONTDESK_BOOKING_PROGRAM_R: 'SCP_FRONTDESK_BOOKING_PROGRAM_READ',
	// FRONTDESK_BOOKING_RESIDENCE: 'SCP_FRONTDESK_BOOKING_RESIDENCE',
	// FRONTDESK_BOOKING_RESIDENCE_R: 'SCP_FRONTDESK_BOOKING_RESIDENCE_READ',
	// FRONTDESK_INVOICE: 'SCP_FRONTDESK_INVOICE',
	FRONTDESK_MONITORING_R: 'SCP_FRONTDESK_MONITORING_READ',
	FRONTDESK_BOOKING_R: 'SCP_FRONTDESK_BOOKING_READ',
	FRONTDESK_MEMBERSHIP_R: 'SCP_FRONTDESK_MEMBERSHIP_READ',

	//Constituent
	// CONSTITUENT: 'SCP_CONSTITUENT',
	// CONSTITUENT_CONTACT: 'SCP_CONSTITUENT_CONTACT',
	// CONSTITUENT_CONTACT_MERGE: 'SCP_CONSTITUENT_CONTACT_MERGE',
	// CONSTITUENT_CONTACT_HOUSEHOLD: 'SCP_CONSTITUENT_CONTACT_HOUSEHOLD',
	// CONSTITUENT_CONTACT_COMPANY: 'SCP_CONSTITUENT_CONTACT_COMPANY',
	// CONSTITUENT_MEMBERSHIP: 'SCP_CONSTITUENT_MEMBERSHIP',
	// CONSTITUENT_INTEREST: 'SCP_CONSTITUENT_INTEREST',
	// CONSTITUENT_SKILL: 'SCP_CONSTITUENT_SKILL',
	CONSTITUENT_CONTACT_R: 'SCP_CONSTITUENT_CONTACT_READ',
	CONSTITUENT_CONTACT_W: 'SCP_CONSTITUENT_CONTACT_WRITE',
	CONSTITUENT_CONTACT_D: 'SCP_CONSTITUENT_CONTACT_DELETE',
	CONSTITUENT_COMPANY_R: 'SCP_CONSTITUENT_COMPANY_READ',
	CONSTITUENT_COMPANY_W: 'SCP_CONSTITUENT_COMPANY_WRITE',
	CONSTITUENT_COMPANY_D: 'SCP_CONSTITUENT_COMPANY_DELETE',
	CONSTITUENT_CONTACT_LOG_R: 'SCP_CONSTITUENT_CONTACT_LOG_READ',

	//Campaigns
	CAMPAIGN: 'SCP_CAMPAIGN',

	//Product
	// PRODUCT: 'SCP_PRODUCT',
	// PRODUCT_MEMBERSHIP: 'SCP_PRODUCT_MEMBERSHIP',
	// PRODUCT_DONATION: 'SCP_PRODUCT_DONATION',
	// PRODUCT_PROGRAM: 'SCP_PRODUCT_PROGRAM',
	// PRODUCT_PROGRAM_DETAIL: 'SCP_PRODUCT_PROGRAM_DETAILS',
	// PRODUCT_PROGRAM_DETAIL_R: 'SCP_PRODUCT_PROGRAM_DETAILS_READ',
	// PRODUCT_PROGRAM_COPY: 'SCP_PRODUCT_PROGRAM_COPY',
	// PRODUCT_PROGRAM_BUDGET: 'SCP_PRODUCT_PROGRAM_BUDGET',
	// PRODUCT_PROGRAM_CATEGORY: 'SCP_PRODUCT_PROGRAM_CATEGORY',
	// PRODUCT_RESIDENCE: 'SCP_PRODUCT_RESIDENCE',
	// PRODUCT_RESIDENCE_BOOKING: 'SCP_PRODUCT_RESIDENCE_BOOKING',
	// PRODUCT_RESIDENCE_ORDER: 'SCP_PRODUCT_RESIDENCE_ORDER',
	// PRODUCT_RESIDENCE_MANAGEMENT: 'SCP_PRODUCT_RESIDENCE_MGT',
	// PRODUCT_RESIDENCE_WORKFLOW: 'SCP_PRODUCT_RESIDENCE_WORKFLOW',
	// PRODUCT_SURVEY: 'SCP_PRODUCT_SURVEY',
	// PRODUCT_MERCHANDISE: 'SCP_PRODUCT_MERCHANDISE',
	// PRODUCT_LOCKERS: 'SCP_PRODUCT_LOCKERS',

	//Financial
	// FINANCIAL: 'SCP_FINANCIAL',
	FINANCIAL_DISCOUNT_R: 'SCP_FINANCIAL_DISCOUNT_READ',
	// FINANCIAL_BUDGET: 'SCP_FINANCIAL_BUDGET',

	//Accounting
	// ACCOUNTING: 'SCP_ACCOUNTING',
	// ACCOUNTING_GLA: 'SCP_ACCOUNTING_GLA',
	// ACCOUNTING_PA: 'SCP_ACCOUNTING_PA',

	//Templates
	// TEMPLATES: 'SCP_TEMPLATE',
	TEMPLATE_EMAIL_R: 'SCP_TEMPLATE_EMAIL_READ',

	//Properties
	// PROPERTIY: 'SCP_PROPERTY',
	// PROPERTIY_FIELDSET: 'SCP_PROPERTY_FIELDSET',
	// PROPERTIY_LOCATION: 'SCP_PROPERTY_LOCATION',
	// PROPERTIY_ASSET: 'SCP_PROPERTY_ASSET',

	//System
	SYSTEM: 'SCP_SYSTEM',
	SYSTEM_VIEW_R: 'SCP_SYSTEM_VIEW_READ',
	SYSTEM_CONFIGURATION_R: 'SCP_SYSTEM_CONFIGURATION_READ',
	SYSTEM_SMTP: 'SCP_SYSTEM_SMTP',
	SYSTEM_ACL: 'SCP_SYSTEM_ACL',
	SYSTEM_ACL_USER: 'SCP_SYSTEM_ACL_USER',
	SYSTEM_ACL_ROLE: 'SCP_SYSTEM_ACL_ROLE',
	SYSTEM_IAE: 'SCP_SYSTEM_IAE',
	SYSTEM_IAE_IMPORT: 'SCP_SYSTEM_IAE_IMPORT',
	SYSTEM_IAE_IMPORT_CONTACT: 'SCP_SYSTEM_IAE_IMPORT_CONTACT',
	SYSTEM_IAE_IMPORT_COURSE: 'SCP_SYSTEM_IAE_IMPORT_COURSE',
	SYSTEM_APPAPI: 'SCP_SYSTEM_APPAPI',

	ORDERS_R: 'SCP_ORDERS_READ',
	FRONTDESK_V: 'SCP_FRONTDESK_VIEW',
	FD_MEMBER_SCAN: 'SCP_FD_MEMBER_SCAN',
	FD_PROGRAM_ORDER_R: 'SCP_FD_PROGRAM_ORDER_READ',
	FD_PROGRAM_ORDER_W: 'SCP_FD_PROGRAM_ORDER_WRITE',
	TRANSACTION_R: 'SCP_TRANSACTION_READ',
	TRANSACTION_DEAIL_R: 'SCP_TRANSACTION_DETAIL_READ',
	TRANSACTION_DEAIL_W: 'SCP_TRANSACTION_DETAIL_WRITE',
	TRANSACTION_DEAIL_D: 'SCP_TRANSACTION_DETAIL_DELETE',
	REPORT_R: 'SCP_REPORT_READ',
	REPORT_W: 'SCP_REPORT_WRITE',
	REPORT_D: 'SCP_REPORT_DELETE',

	ASSET_R: 'SCP_ASSET_READ',
	ASSET_W: 'SCP_ASSET_WRITE',
	CMS_V: 'SCP_CMS_VIEW',

	// PROGRAM_V: 'SCP_PROGRAM_VIEW',
	// PROGRAM_A: 'SCP_PROGRAM_ADD',
	// PROGRAM_U: 'SCP_PROGRAM_UPDATE',
	// PROGRAM_D: 'SCP_PROGRAM_DELETE',

	// HOTEL_V: 'SCP_HOTEL_VIEW',

	IMPORT_EXPORT_V: 'SCP_IE_VIEW',
	IMPORT_V: 'SCP_IMPORT_VIEW',
	EXPORT_V: 'SCP_EXPORT_VIEW',

	// ACCOUNTING_V: 'SCP_ACCOUNTING_VIEW',

	ADMIN: 'SCP_ADMIN',
	SUPER_ADMIN: 'SCP_SUPER_ADMIN',

	// CAMPAIGN_R: 'SCP_CAMPAIGN_READ',
	// CAMPAIGN_W: 'SCP_CAMPAIGN_WRITE',
	// CAMPAIGN_D: 'SCP_CAMPAIGN_DELETE',

	CONTACT_R: CONSTITUENT2 + 'CONTACT_READ',
	CONTACT_W: CONSTITUENT2 + 'CONTACT_WRITE',
	CONTACT_DETAIL_R: CONSTITUENT2 + 'CONTACT_DETAIL_READ',
	CONTACT_DETAIL_W: CONSTITUENT2 + 'CONTACT_DETAIL_WRITE',
	CONTACT_INCOME_ADJUST_REQ: CONSTITUENT2 + 'CONTACT_INCOME_ADJUST_REQUEST',
	CONTACT_INCOME_ADJUST_AUTH: CONSTITUENT2 + 'CONTACT_INCOME_ADJUST_AUTHORIZE',

	GROUP_R: CONSTITUENT2 + 'GROUP_READ',
	GROUP_W: CONSTITUENT2 + 'GROUP_WRITE',
	GROUP_DETAIL_R: CONSTITUENT2 + 'GROUP_DETAIL_READ',
	GROUP_DETAIL_W: CONSTITUENT2 + 'GROUP_DETAIL_WRITE',
	GROUP_DETAIL_D: CONSTITUENT2 + 'GROUP_DETAIL_DELETE',
	GROUP_INCOME_ADJUST_REQ: CONSTITUENT2 + 'GROUP_INCOME_ADJUST_REQUEST',
	GROUP_INCOME_ADJUST_AUTH: CONSTITUENT2 + 'GROUP_INCOME_ADJUST_AUTHORIZE',

	INTERESTS_R: CONSTITUENT2 + 'INTERESTS_READ',
	INTERESTS_D: CONSTITUENT2 + 'INTERESTS_DELETE',
	INTERESTS_W: CONSTITUENT2 + 'INTERESTS_WRITE',

	SKILLS_R: CONSTITUENT2 + 'SKILLS_READ',
	SKILLS_W: CONSTITUENT2 + 'SKILLS_WRITE',
	SKILLS_D: CONSTITUENT2 + 'SKILLS_DELETE',
	SKILLS_DETAIL_R: CONSTITUENT2 + 'SKILLS_DETAIL_READ',
	SKILLS_DETAIL_W: CONSTITUENT2 + 'SKILLS_DETAIL_WRITE',

	SYSTEM_CONFIG_W: 'SCP_SYSTEM_CONFIG_WRITE',
	APP_CONFIG_W: 'SCP_APP_CONFIG_WRITE',

	//promotion management
	PROMOTION: 'SCP_PROMOTION',
	PROMOTION_MANAGEMENT: 'SCP_PROMOTION_MANAGEMENT',
	PROMOTION_MANAGEMENT_R: 'SCP_PROMOTION_MANAGEMENT_READ',
	PROMOTION_MANAGEMENT_W: 'SCP_PROMOTION_MANAGEMENT_WRITE',
	PROMOTION_MANAGEMENT_D: 'SCP_PROMOTION_MANAGEMENT_DELETE',

	// membership
	MEMBERSHIP_MGT_R: 'SCP_MEMBERSHIP_MGT_READ',
	MEMBERSHIP_MGT_W: 'SCP_MEMBERSHIP_MGT_WRITE',
	MEMBERSHIP_MGT_D: 'SCP_MEMBERSHIP_MGT_DELETE',
	MEMBERSHIP_STUDENT_MGT_R: 'SCP_MEMBERSHIP_STUDENT_MGT_READ',
	MEMBERSHIP_STUDENT_MGT_W: 'SCP_MEMBERSHIP_STUDENT_MGT_WRITE',
	MEMBERSHIP_STUDENT_MGT_D: 'SCP_MEMBERSHIP_STUDENT_MGT_DELETE',
	MEMBERSHIP_DETAI_R: 'SCP_MEMBERSHIP_DETAIL_READ',
	MEMBERSHIP_DETAI_W: 'SCP_MEMBERSHIP_DETAIL_WRITE',
	MEMBERSHIP_DETAI_D: 'SCP_MEMBERSHIP_DETAIL_DELETE',
	MEMBERSHIP_STUDENT_DETAI_R: 'SCP_MEMBERSHIP_STUDENT_DETAIL_READ',
	MEMBERSHIP_STUDENT_DETAI_W: 'SCP_MEMBERSHIP_STUDENT_DETAIL_WRITE',
	MEMBERSHIP_STUDENT_DETAI_D: 'SCP_MEMBERSHIP_STUDENT_DETAIL_DELETE',
	MEMBERSHIP_CATEGORY_MGT_R: 'SCP_MEMBERSHIP_CATEGORY_MGT_READ',
	MEMBERSHIP_CATEGORY_MGT_W: 'SCP_MEMBERSHIP_CATEGORY_MGT_WRITE',
	MEMBERSHIP_CATEGORY_MGT_D: 'SCP_MEMBERSHIP_CATEGORY_MGT_DELETE',
	MEMBERSHIP_PRODUCT_MGT_R: 'SCP_MEMBERSHIP_PRODUCT_MGT_READ',
	MEMBERSHIP_STUDENT_PRODUCT_MGT_R: 'SCP_MEMBERSHIP_STUDENT_PRODUCT_MGT_READ',
	MEMBERSHIP_IMPORT_R: 'SCP_MEMBERSHIP_IMPORT_READ',
	MEMBERSHIP_SEARCH_R: 'SCP_MEMBERSHIP_SEARCH_READ',
	MEMBERSHIP_SEARCH_DETAI_R: 'SCP_MEMBERSHIP_SEARCH_DETAIL_READ',
	MEMBERSHIP_EXT_SEARCH_R: 'SCP_MEMBERSHIP_EXT_SEARCH_READ',
	MEMBERSHIP_EXT_SEARCH_DETAIL_R: 'SCP_MEMBERSHIP_EXT_SEARCH_DETAIL_READ',
	MEMBERSHIP_LOG_R: 'SCP_MEMBERSHIP_LOG_READ',

	// product
	PRODUCT_MGT_R: 'SCP_PRODUCT_MGT_READ',
	PRODUCT_OPTION_MGT_R: 'SCP_PRODUCT_OPTION_MGT_READ',
	PRODUCT_OPTION_MGT_W: 'SCP_PRODUCT_OPTION_MGT_WRITE',
	PRODUCT_OPTION_MGT_D: 'SCP_PRODUCT_OPTION_MGT_DELETE',
	PRODUCT_CATEGORY_MGT_R: 'SCP_PRODUCT_CATEGORY_MGT_READ',
	PRODUCT_CATEGORY_MGT_W: 'SCP_PRODUCT_CATEGORY_MGT_WRITE',
	PRODUCT_CATEGORY_MGT_D: 'SCP_PRODUCT_CATEGORY_MGT_DELETE',

	ACTIVITY_SOCIAL_SUPPORT_MGT_R: 'SCP_PRODUCT_SOCIAL_SUPPORT_MGT_READ',
	ACTIVITY_SOCIAL_SUPPORT_MGT_W: 'SCP_PRODUCT_SOCIAL_SUPPORT_MGT_WRITE',
	ACTIVITY_SOCIAL_SUPPORT_MGT_D: 'SCP_PRODUCT_SOCIAL_SUPPORT_MGT_DELETE',
	ACTIVITY_CLUBHOUSE_MGT_R: 'SCP_PRODUCT_CLUBHOUSE_MGT_READ',
	ACTIVITY_CLUBHOUSE_MGT_W: 'SCP_PRODUCT_CLUBHOUSE_MGT_WRITE',
	ACTIVITY_CLUBHOUSE_MGT_D: 'SCP_PRODUCT_CLUBHOUSE_MGT_DELETE',

	// department
	DEPARTMENT_MGT_R: 'SCP_DEPARTMENT_MGT_READ',

	// asset
	ASSET_MGT_R: 'SCP_ASSET_MGT_READ',
	ASSET_MGT_W: 'SCP_ASSET_MGT_WRITE',
	ASSET_MGT_D: 'SCP_ASSET_MGT_DELETE',
	ASSET_DETAIL_R: 'SCP_ASSET_DETAIL_READ',
	ASSET_DETAIL_W: 'SCP_ASSET_DETAIL_WRITE',
	ASSET_DETAIL_D: 'SCP_ASSET_DETAIL_DELETE',
	ASSET_CATEGORY_MGT_R: 'SCP_ASSET_CATEGORY_MGT_READ',
	ASSET_CATEGORY_MGT_W: 'SCP_ASSET_CATEGORY_MGT_WRITE',
	ASSET_CATEGORY_MGT_D: 'SCP_ASSET_CATEGORY_MGT_DELETE',
	ASSET_REPORT_R: 'SCP_ASSET_REPORT_READ',
	ASSET_REPORT_W: 'SCP_ASSET_REPORT_WRITE',
	ASSET_REPORT_D: 'SCP_ASSET_REPORT_DELETE',
	ASSET_DOOR_R: 'SCP_ASSET_DOOR_MGT_READ',
	ASSET_DOOR_W: 'SCP_ASSET_DOOR_MGT_WRITE',
	ASSET_DOOR_D: 'SCP_ASSET_DOOR_MGT_DELETE',

	// inventory
	INVENTORY_MGT_R: 'SCP_INVENTORY_MGT_READ',

	//property
	PROPERTY_VIEW_R: 'SCP_PROPERTY_VIEW_READ',
	PROPERTY_ATTRIBUTE_R: 'SCP_PROPERTY_ATTRIBUTE_READ',
	PROPERTY_ATTRIBUTE_FIELD_MGT_R: 'SCP_PROPERTY_ATTRIBUTE_FIELD_MGT_READ',
	PROPERTY_ATTRIBUTE_GROUP_MGT_R: 'SCP_PROPERTY_ATTRIBUTE_GROUP_MGT_READ',
	PROPERTY_ATTIRBUTE_LIST_VALUE_MGT_R: 'SCP_PROPERTY_ATTIRBUTE_LIST_VALUE_MGT_READ',
	PROPERTY_APPLICATION_MGT_R: 'SCP_PROPERTY_APPLICATION_MGT_READ',
	PROPERTY_APPLICATION_RECORD_MGT_R: 'SCP_PROPERTY_APPLICATION_RECORD_MGT_READ',
	PROPERTY_VOLUNTEER_APPLICATION_RECORD_MGT_R: 'SCP_PROPERTY_VOLUNTEER_APPLICATION_RECORD_MGT_READ',
	PROPERTY_SUPPORT_CASE_APPLICATION_RECORD_MGT_R: 'SCP_PROPERTY_SUPPORT_CASE_APPLICATION_RECORD_MGT_READ',

	// taxonomy
	TAXONOMY_BRANCH_MGT_R: 'SCP_TAXONOMY_BRANCH_MGT_READ',
	TAXONOMY_BRANCH_MGT_W: 'SCP_TAXONOMY_BRANCH_MGT_WRITE',
	TAXONOMY_BRANCH_MGT_D: 'SCP_TAXONOMY_BRANCH_MGT_DELETE',
	TAXONOMY_LOCATION_MGT_R: 'SCP_TAXONOMY_LOCATION_MGT_READ',
	TAXONOMY_LOCATION_MGT_W: 'SCP_TAXONOMY_LOCATION_MGT_WRITE',
	TAXONOMY_LOCATION_MGT_D: 'SCP_TAXONOMY_LOCATION_MGT_DELETE',

	//volunteer
	VOLUNTEER_MGT_R: 'SCP_VOLUNTEER_MGT_READ',
	VOLUNTEER_MGT_W: 'SCP_VOLUNTEER_MGT_WRITE',
	VOLUNTEER_MGT_D: 'SCP_VOLUNTEER_MGT_DELETE',
	VOLUNTEER_IMPORT_R: 'SCP_VOLUNTEER_IMPORT_READ',
	VOLUNTEER_IMPORT_W: 'SCP_VOLUNTEER_IMPORT_WRITE',

	// care-support
	CARE_SUPPORT_R: 'SCP_CARE_SUPPORT_READ',
	CARE_SUPPORT_W: 'SCP_CARE_SUPPORT_WRITE',
	CARE_SUPPORT_D: 'SCP_CARE_SUPPORT_DELETE',
	CARE_SUPPORT_REPORT_R: 'SCP_CARE_REPORT_READ',
	CARE_SUPPORT_IMPORT_R: 'SCP_CARE_IMPORT_READ',
	CARE_SUPPORT_IMPORT_W: 'SCP_CARE_IMPORT_WRITE',

	// report
	REPORT_VIEW_R: 'SCP_REPORT_VIEW_READ',
	REPORT_ORDER_R: 'SCP_REPORT_ORDER_READ',
	REPORT_ORDER_W: 'SCP_REPORT_ORDER_WRITE',
	REPORT_INVOICE_R: 'SCP_REPORT_INVOICE_READ',
	REPORT_DONATION_R: 'SCP_REPORT_DONATION_READ',
	REPORT_PARTICIPANT_R: 'SCP_REPORT_PARTICIPANT_READ',
	REPORT_PARTICIPANT_W: 'SCP_REPORT_PARTICIPANT_WRITE',
	REPORT_PARTICIPANT_D: 'SCP_REPORT_PARTICIPANT_DELETE',
	REPORT_IMPORT_PARTICIPANT_R: 'SCP_REPORT_IMPORT_PARTICIPANT_READ',
	REPORT_IMPORT_PARTICIPANT_W: 'SCP_REPORT_IMPORT_PARTICIPANT_WRITE',
	REPORT_PREDEFINE_R: 'SCP_REPORT_PREDEFINE_READ',
	REPORT_RUN_R: 'SCP_REPORT_RUN_READ',

	// training
	TRAINING_VIEW_R: 'SCP_TRAINING_VIEW_READ',
	TRAINING_COURSE_MGT_R: 'SCP_TRAINING_COURSE_MGT_READ',
	TRAINING_COURSE_MGT_W: 'SCP_TRAINING_COURSE_MGT_WRITE',
	TRAINING_COURSE_MGT_D: 'SCP_TRAINING_COURSE_MGT_DELETE',
	TRAINING_PARTICIPANT_MGT_R: 'SCP_TRAINING_PARTICIPANT_MGT_READ',
	TRAINING_PARTICIPANT_LOG_R: 'SCP_TRAINING_PARTICIPANT_LOG_READ',
	TRAINING_IMPORT_PARTICIPANT_R: 'SCP_TRAINING_IMPORT_PARTICIPANT_READ',
	TRAINING_IMPORT_PARTICIPANT_W: 'SCP_TRAINING_IMPORT_PARTICIPANT_WRITE',
	TRAINING_IMPORT_SESSION_R: 'SCP_TRAINING_IMPORT_SESSION_READ',
	TRAINING_IMPORT_SESSION_W: 'SCP_TRAINING_IMPORT_SESSION_WRITE',
	TRAINING_PARTICIPANT_MGT_D: 'SCP_TRAINING_PARTICIPANT_MGT_DELETE',
	TRAINING_ATTENDANCE_MGT_R: 'SCP_TRAINING_ATTENDANCE_MGT_READ',
	TRAINING_ATTENDANCE_MGT_W: 'SCP_TRAINING_ATTENDANCE_MGT_WRITE',
	TRAINING_ATTENDANCE_MGT_D: 'SCP_TRAINING_ATTENDANCE_MGT_DELETE',
	TRAINING_ATTENDANCE_LOG_R: 'SCP_TRAINING_ATTENDANCE_LOG_READ',
	TRAINING_REPORT_R: 'SCP_TRAINING_REPORT_READ',
	TRAINING_DIPLOMA_MGT_R: 'SCP_TRAINING_DIPLOMA_MGT_READ',
	TRAINING_DIPLOMA_MGT_W: 'SCP_TRAINING_DIPLOMA_MGT_WRITE',
	TRAINING_DIPLOMA_MGT_D: 'SCP_TRAINING_DIPLOMA_MGT_DELETE',
	TRAINING_VIEW_BY_VENDOR_R: 'SCP_TRAINING_VIEW_BY_VENDOR_READ',
	TRAINING_COURSE_EXPORT_R: 'SCP_TRAINING_COURSE_EXPORT_READ',
	TRAINING_COURSE_LOG_R: 'SCP_TRAINING_COURSE_LOG_READ',
	TRAINING_SESSION_LOG_R: 'SCP_TRAINING_SESSION_LOG_READ',

	VENDOR_MGT_R: 'SCP_HUMAN_RESOURCE_VENDOR_MGT_READ',
	VENDOR_MGT_W: 'SCP_HUMAN_RESOURCE_VENDOR_MGT_WRITE',
	VENDOR_MGT_D: 'SCP_HUMAN_RESOURCE_VENDOR_MGT_DELETE',
	TRAINER_MGT_R: 'SCP_HUMAN_RESOURCE_TRAINER_MGT_READ',
	TRAINER_MGT_W: 'SCP_HUMAN_RESOURCE_TRAINER_MGT_WRITE',
	TRAINER_MGT_D: 'SCP_HUMAN_RESOURCE_TRAINER_MGT_DELETE',
	SPEAKER_MGT_R: 'SCP_HUMAN_RESOURCE_SPEAKER_MGT_READ',
	SPEAKER_MGT_W: 'SCP_HUMAN_RESOURCE_SPEAKER_MGT_WRITE',
	SPEAKER_MGT_D: 'SCP_HUMAN_RESOURCE_SPEAKER_MGT_DELETE',

	// wish-list
	WISH_LIST_MGT_R: 'SCP_WISH_LIST_MGT_READ',

	// invoice
	INVOICE_DETAIL_R: 'SCP_INVOICE_DETAIL_READ',
	INVOICE_DETAIL_W: 'SCP_INVOICE_DETAIL_WRITE',
	INVOICE_DETAIL_D: 'SCP_INVOICE_DETAIL_DELETE',
};
