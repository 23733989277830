import React from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Fieldset } from 'primereact/fieldset';
import moment from 'moment';
import {
	ALIAS_APPLICATION_VOLUNTEER,
	APPLICATION_COMPONENTT_TYPE,
	APPLICATION_RECORD_STATUS,
	APPLICATION_TYPE,
	APPLICATION_VALUE_TYPE,
	ATTRIBUTE_FIELD_TYPE,
	DATETIME_FORMAT_DISPLAY,
	DATE_FORMAT_DISPLAY,
	TIME_FORMAT_DISPLAY,
} from '../../constants';
import { updateStatusApplicationRecord, getApplicationRecord, answerApplicationRecord, downloadFileFromFieldValueRecord, getApplications, signForApplicationRecord } from './ApplicationService';
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';
import { ApplicationToLogForm } from './ApplicationToLogForm';
import { ApplicationRecordListViewForm } from './ApplicationRecordListViewForm';
import classNames from 'classnames';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';

const fileDownload = require('js-file-download');

const REPLY_ACTION = {
	reply: { label: 'Reply', value: 'reply' },
	reject: { label: 'Reject', value: 'reject' },
};

const ALIAS_APPLICATION_SUPPORT_CASE = 'support-case-application';
export default class ApplicationRecordForm extends React.Component {
	constructor() {
		super();

		this.state = {
			participant: {},
			assetSchedule: {},
			application: {},
			record: {},
			mapCustomFields: {},
			mapWaiverFields: {},
			answer: {},
			dependentApplications: [],
			formHeader: 'Application Record',
			visible: false,
			errors: {},
		};
	}

	openForm = async (id) => {
		let resRecord = await getApplicationRecord(id);
		if (resRecord.errorCode) {
			showErrorNotify('Error!');
		}
		let tmpApplication = resRecord.application ? resRecord.application : null;
		let tmpRecord = resRecord.applicationRecord ? resRecord.applicationRecord : null;
		let tmpAnswer = { id: tmpRecord.id };

		let tmpDependentApplications = [];
		if (tmpApplication) {
			let resDependentApplications = await getApplications({ requiredApplicationIds: [tmpApplication.id] }, false);
			if (resDependentApplications) {
				for (const tmpApp of resDependentApplications) {
					let tmpRequires = tmpApp.requiredValues ? [...tmpApp.requiredValues] : [];
					let isMeetRequirement = false;
					if (tmpRequires) {
						for (const tmpRequiredItem of tmpRequires) {
							if (
								tmpRequiredItem.type === APPLICATION_VALUE_TYPE.app_record_status.value &&
								tmpRequiredItem.applicationId === tmpApplication.id &&
								(tmpApplication.type === APPLICATION_TYPE.normal.value || !tmpRequiredItem.status || tmpRequiredItem.status === tmpRecord.status)
							) {
								isMeetRequirement = true;
							}
						}
					}
					if (isMeetRequirement && !tmpDependentApplications.some((app) => app.id === tmpApp.id)) {
						tmpDependentApplications.push(tmpApp);
					}
				}
			}
		}

		this.setState({
			application: tmpApplication ? tmpApplication : {},
			record: resRecord.applicationRecord ? resRecord.applicationRecord : {},
			mapCustomFields: resRecord.mapCustomFields ? resRecord.mapCustomFields : {},
			mapWaiverFields: resRecord.mapWaiverFields ? resRecord.mapWaiverFields : {},
			answer: tmpAnswer,
			dependentApplications: tmpDependentApplications,
			visible: true,
		});
	};

	handleApproveOrReject = (status) => {
		updateStatusApplicationRecord(this.state.record.id, status).then((res) => {
			if (!res.errorCode) {
				if (this.props.refreshTable) {
					this.props.refreshTable();
				}
				this.closeForm();
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	handleAnswer = () => {
		let tmpData = { ...this.state.answer };
		tmpData.type = !this.state.replyAction || this.state.replyAction === REPLY_ACTION.reply.value ? APPLICATION_RECORD_STATUS.approved.value : APPLICATION_RECORD_STATUS.rejected.value;

		answerApplicationRecord(tmpData).then((res) => {
			if (!res.errorCode) {
				if (this.props.refreshTable) {
					this.props.refreshTable();
				}
				this.closeForm();
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
				if (res.errorCode === 400) this.setState({ errors: res.errorObj });
			}
		});
	};

	handleDownloadValue = (valueId, valueLabel, valueName) => {
		let tmpUser = this.state.record.user ? this.state.record.user : {};

		let ext = valueName ? valueName.substring(valueName.lastIndexOf('.')) : '';
		let fileName = (tmpUser.name ? tmpUser.name.replaceAll(' ', '_') : '') + ' [' + (valueLabel ? valueLabel.replaceAll(' ', '_') : '') + ']' + ext;
		downloadFileFromFieldValueRecord(this.state.record.id, valueId).then((res) => {
			if (!res.errorCode) {
				fileDownload(res, fileName);
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	handleSignRecord = (isSign) => {
		signForApplicationRecord(this.state.record.id, isSign).then((res) => {
			if (!res.errorCode) {
				this.setState({ record: { ...this.state.record, isSign: isSign } });
				if (this.props.refreshTable) {
					this.props.refreshTable();
				}
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	onDenpentApplicationTakeAction = (alias) => {
		this.logForm.openForm(alias);
		this.setState({ visible: false });
	};

	onDenpentApplicationView = (applicationId) => {
		this.listViewForm.openForm(applicationId);
		this.setState({ visible: false });
	};

	closeForm = () => {
		this.setState({
			visible: false,
			data: {},
			application: {},
			participant: {},
			fieldValues: [],
			errors: {},
		});
	};

	isWrite = () => {
		let flag = isScpAdmin();
		if (this.state.application?.alias === ALIAS_APPLICATION_SUPPORT_CASE) {
			flag = isScpAdmin() || hasRole([ROLES_CONFIG.CARE_SUPPORT_W]);
		} else if (this.state.application?.alias === ALIAS_APPLICATION_VOLUNTEER) {
			flag = isScpAdmin() || hasRole([ROLES_CONFIG.VOLUNTEER_MGT_W]);
		}
		return flag;
	};

	isDelete = () => {
		let flag = isScpAdmin();
		if (this.state.application?.alias === ALIAS_APPLICATION_SUPPORT_CASE) {
			flag = isScpAdmin() || hasRole([ROLES_CONFIG.CARE_SUPPORT_D]);
		} else if (this.state.application?.alias === ALIAS_APPLICATION_VOLUNTEER) {
			flag = isScpAdmin() || hasRole([ROLES_CONFIG.VOLUNTEER_MGT_D]);
		}
		return flag;
	};

	render() {
		let application = this.state.application;
		let record = this.state.record;
		let tmpPersonalInfos = record.personalInfo;
		let mapCustomFields = this.state.mapCustomFields;
		let mapWaiverFields = this.state.mapWaiverFields;
		let participant = record.participant ? record.participant : {};
		let assetSchedule = record.assetSchedule ? record.assetSchedule : {};
		let tmpAnswer = this.state.answer;
		let dependentApplications = this.state.dependentApplications;
		let errors = this.state.errors;

		let isCareSupportApplication = application.alias === ALIAS_APPLICATION_SUPPORT_CASE;

		let isWrite = this.isWrite();
		let isDelete = this.isDelete();

		return (
			<React.Fragment>
				<ApplicationToLogForm
					ref={(el) => (this.logForm = el)}
					parentId={record?.id}
					reloadTable={() => {
						if (this.props.refreshTable) this.props.refreshTable();
					}}
					backToForm={() => this.setState({ visible: true })}
				/>
				<ApplicationRecordListViewForm
					ref={(el) => (this.listViewForm = el)}
					parentId={record?.id}
					parentStatus={record?.status}
					appParentType={application.type}
					backToForm={() => this.setState({ visible: true })}
				/>

				<Sidebar
					dismissable={true}
					visible={this.state.visible}
					style={{ overflowY: 'auto' }}
					className='p-sidebar-md'
					position='right'
					blockScroll={true}
					showCloseIcon={false}
					onHide={this.closeForm}
				>
					<div className='p-d-flex p-justify-between '>
						<h2 className='p-margin-top-10'>{this.state.formHeader}</h2>
						<Button label='' icon='pi pi-times' className='p-button-secondary' onClick={this.closeForm} />
					</div>

					<div className='p-sidebar-line p-mb-3'></div>

					<div className='p-grid p-fluid'>
						{(application.type === APPLICATION_TYPE.approval.value || application.type === APPLICATION_TYPE.answer.value) && (
							<div className='p-col-12'>
								{(!record.status || record.status === APPLICATION_RECORD_STATUS.pending.value) && <Message severity='info' className='p-size-18' text='New' />}
								{record.status === APPLICATION_RECORD_STATUS.processing.value && (
									<Message severity='info' className='p-size-18' text={isCareSupportApplication ? 'On-hold' : APPLICATION_RECORD_STATUS.processing.label} />
								)}
								{record.status === APPLICATION_RECORD_STATUS.approved.value && (
									<Message
										severity='success'
										className='p-size-18'
										text={application.type === APPLICATION_TYPE.answer.value ? 'Answered' : isCareSupportApplication ? 'Accepted' : APPLICATION_RECORD_STATUS.approved.label}
									/>
								)}
								{record.status === APPLICATION_RECORD_STATUS.closed.value && <Message severity='warn' className='p-size-18' text={APPLICATION_RECORD_STATUS.closed.label} />}
								{record.status === APPLICATION_RECORD_STATUS.rejected.value && (
									<Message severity='error' className='p-size-18' text={APPLICATION_RECORD_STATUS.rejected.label} style={{ width: 'auto' }} />
								)}
							</div>
						)}
					</div>

					<Fieldset legend='Information'>
						<div className='p-grid'>
							{isCareSupportApplication && (
								<div className='p-col-12'>
									<label>
										<span className='column-title w-25'>Call ID:</span> <strong>{record.id}</strong>
									</label>
								</div>
							)}
							<div className='p-col-12'>
								<label>
									<span className='column-title w-25'>Created by:</span> <strong>{record?.user?.name}</strong>
								</label>
							</div>
							<div className='p-col-12'>
								<label>
									<span className='column-title w-25'>Application: </span>
									<strong>{application.name}</strong>
								</label>
							</div>
							{assetSchedule && assetSchedule.id && (
								<React.Fragment>
									<div className='p-col-12'>
										<label>
											<span className='column-title w-25'>Asset: </span>
											<strong>{assetSchedule.asset && assetSchedule.asset.name}</strong>
										</label>
									</div>
									<div className='p-col-12'>
										<label>
											<span className='column-title w-25'>Date: </span>
											<strong>
												{moment(assetSchedule.start).format(DATETIME_FORMAT_DISPLAY)} - {moment(assetSchedule.end).format(DATETIME_FORMAT_DISPLAY)}
											</strong>
										</label>
									</div>
								</React.Fragment>
							)}
							{participant && participant.id && (
								<div className='p-col-12'>
									<label>
										<span className='column-title w-25'>Participant: </span>
										<strong>{participant.user && participant.user.name}</strong>
									</label>
								</div>
							)}
							{application && application.ratingGroup && (
								<div className='p-col-12'>
									<label>
										<span className='column-title w-25'>Voters: </span>
										<strong>
											{record.objectRatings &&
												record.objectRatings
													.map((or) => {
														if (or.customer) return or.customer.name;
													})
													.join(', ')}
										</strong>
									</label>
								</div>
							)}
							<div className='p-col-12'>
								<label>
									<span className='column-title w-25'>Log on : </span>
									<strong>{record.date && moment(record.date).format(DATETIME_FORMAT_DISPLAY)}</strong>
								</label>
							</div>
							{application.type === APPLICATION_TYPE.approval.value && (record.status === APPLICATION_RECORD_STATUS.approved.value || record.status === APPLICATION_RECORD_STATUS.closed.value) && (
								<div className='p-col-12'>
									<label>
										<span className='column-title w-25'>Case : </span>
										<strong>{record.isSign ? 'Yes' : 'No'}</strong>
									</label>
								</div>
							)}
						</div>
					</Fieldset>
					{dependentApplications && dependentApplications.length > 0 && (
						<Fieldset legend='Actions'>
							{dependentApplications.map((dependentApplication) => {
								return (
									<div className='p-grid'>
										<div className='p-col-12 p-md-7'>{dependentApplication.name}</div>
										<div className='p-col-12 p-md-5 p-r'>
											{(application.type === APPLICATION_TYPE.normal.value || (application.type === APPLICATION_TYPE.approval.value && record.status !== APPLICATION_RECORD_STATUS.pending.value)) && (
												<Button label='View' onClick={() => this.onDenpentApplicationView(dependentApplication.id)} style={{ width: 'auto' }} />
											)}
											{isWrite &&
												(application.type === APPLICATION_TYPE.normal.value ||
													(application.type === APPLICATION_TYPE.approval.value &&
														(record.status === APPLICATION_RECORD_STATUS.approved.value || record.status === APPLICATION_RECORD_STATUS.processing.value))) && (
													<Button label='Take action' onClick={() => this.onDenpentApplicationTakeAction(dependentApplication.alias)} style={{ width: 'auto' }} />
												)}
										</div>
									</div>
								);
							})}
						</Fieldset>
					)}
					<Fieldset legend='Record'>
						<div className='p-grid'>
							{(() => {
								if (isWrite) {
									if (application.type === APPLICATION_TYPE.approval.value) {
										let htmlButton = [];
										if (!record.status || record.status === APPLICATION_RECORD_STATUS.pending.value) {
											htmlButton.push(
												<Button
													label={isCareSupportApplication ? 'Accept' : 'Approve'}
													className='p-button-success'
													onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.approved.value)}
												/>
											);
											htmlButton.push(<Button label='Reject' className='p-button-danger' onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.rejected.value)} />);
										} else if (record.status === APPLICATION_RECORD_STATUS.approved.value) {
											// if(!record.isSign) htmlButton.push(<Button label="Case" className="p-button-info" onClick={() => this.handleSignRecord(true)} />)
											htmlButton.push(<Button label='On-hold' className='p-button-info' onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.processing.value)} />);
											htmlButton.push(<Button label='Close' className='p-button-warning' onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.closed.value)} />);
											htmlButton.push(<Button label='Reject' className='p-button-danger' onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.rejected.value)} />);
										}
										if (record.status === APPLICATION_RECORD_STATUS.processing.value) {
											htmlButton.push(
												<Button
													label={isCareSupportApplication ? 'Accept' : 'Approve'}
													className='p-button-success'
													onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.approved.value)}
												/>
											);
											htmlButton.push(<Button label='Close' className='p-button-warning' onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.closed.value)} />);
											htmlButton.push(<Button label='Reject' className='p-button-danger' onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.rejected.value)} />);
										}
										// if(record.status!==APPLICATION_RECORD_STATUS.approved.value){
										//     htmlButton.push(<Button label="Approve" className="p-button-success" onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.approved.value)} />)
										// }
										// if(record.status!==APPLICATION_RECORD_STATUS.closed.value){
										//     htmlButton.push(<Button label="Close" className="p-button-warning" onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.closed.value)} />)
										// }
										// if(record.status!==APPLICATION_RECORD_STATUS.rejected.value){
										//     htmlButton.push(<Button label="Reject" className="p-button-danger" onClick={() => this.handleApproveOrReject(APPLICATION_RECORD_STATUS.rejected.value)} />)
										// }
										if (record.status !== APPLICATION_RECORD_STATUS.closed.value && record.status !== APPLICATION_RECORD_STATUS.rejected.value) {
											if (isCareSupportApplication) {
												return (
													<div className='p-col-12'>
														<div className='p-grid'>
															{!record.isSign && (
																<div className='p-col-12 p-lg-3 p-sm-3'>
																	<Button label='Case' className='p-button-info' onClick={() => this.handleSignRecord(true)} />
																</div>
															)}
															<div className={classNames('p-col-12 p-text-right', { 'p-lg-9 p-sm-9 ': !record.isSign })}>{htmlButton}</div>
															<div className='p-col-12 p-py-0'>
																<div className='p-hr'></div>
															</div>
														</div>
													</div>
												);
											}
											return (
												<React.Fragment>
													<div className='p-col-12 p-r'>{htmlButton}</div>
													<div className='p-col-12 p-py-0'>
														<div className='p-hr'></div>
													</div>
												</React.Fragment>
											);
										}
									}
								}
							})()}

							<div className='p-col-12 p-pb-0'>
								{application.contents &&
									application.contents.map((content, idxContent) => {
										return (
											content.components &&
											content.components.map((component, idxComponent) => {
												if (component.type === APPLICATION_COMPONENTT_TYPE.input_field.value) {
													let tmpPersonalSet = component.personalSet;
													let tmpPersonalField = tmpPersonalSet && tmpPersonalSet.personalField ? tmpPersonalSet.personalField : {};
													let tmpCustomSet = component.customSet;
													let tmpWaiverSet = component.waiverSet;
													let tmpGuadianPersonalSet = tmpWaiverSet && tmpWaiverSet.requiredGuardian ? tmpWaiverSet.guardianPersonalSet : {};
													let tmpGuardianPersonalField = tmpGuadianPersonalSet && tmpGuadianPersonalSet.personalField ? tmpGuadianPersonalSet.personalField : {};

													let tmpPersonalValue =
														tmpPersonalInfos &&
														tmpPersonalInfos[idxContent] &&
														tmpPersonalInfos[idxContent].personalInfos &&
														tmpPersonalInfos[idxContent].personalInfos[idxComponent] &&
														tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
															? tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
															: {};

													let tmpCustomFieldValue = tmpCustomSet && mapCustomFields[tmpCustomSet.id] ? mapCustomFields[tmpCustomSet.id] : {};
													let tmpWaiverFieldValue = tmpWaiverSet && mapWaiverFields[tmpWaiverSet.id] ? mapWaiverFields[tmpWaiverSet.id] : {};
													let tmpGuardianPersonalValue =
														tmpPersonalInfos &&
														tmpPersonalInfos[idxContent] &&
														tmpPersonalInfos[idxContent].personalInfos &&
														tmpPersonalInfos[idxContent].personalInfos[idxComponent] &&
														tmpPersonalInfos[idxContent].personalInfos[idxComponent].guardianPersonalInfo
															? tmpPersonalInfos[idxContent].personalInfos[idxComponent].guardianPersonalInfo
															: {};

													return (
														<div key={application.id + '_' + '_' + idxContent} className='p-grid'>
															{tmpPersonalSet && (
																<React.Fragment>
																	{tmpPersonalSet.label && (
																		<div className='p-col-12'>
																			<span className='p-size-16 p-w-bold'>{tmpPersonalSet.label}</span>
																		</div>
																	)}

																	<div className='p-col-12 p-pb-0'>
																		{tmpPersonalValue &&
																			Object.keys(tmpPersonalValue).map((key) => {
																				let tmpLabel;
																				let tmpValue;
																				if (key === 'title') {
																					tmpLabel = tmpPersonalField['titleNameLabel'];
																					tmpValue = tmpPersonalValue[key];
																				}
																				if (key === 'firstName') {
																					tmpLabel = tmpPersonalField['firstNameLabel'];
																					tmpValue = tmpPersonalValue[key];
																				}
																				if (key === 'middleName') {
																					tmpLabel = tmpPersonalField['middleNameLabel'];
																					tmpValue = tmpPersonalValue[key];
																				}
																				if (key === 'lastName') {
																					tmpLabel = tmpPersonalField['lastNameLabel'];
																					tmpValue = tmpPersonalValue[key];
																				}
																				if (key === 'email') {
																					tmpLabel = tmpPersonalField['emailLabel'];
																					tmpValue = tmpPersonalValue[key];
																				}
																				if (key === 'gender') {
																					tmpLabel = tmpPersonalField['genderLabel'];
																					tmpValue = tmpPersonalValue[key];
																				}
																				if (key === 'birthday') {
																					tmpLabel = tmpPersonalField['birthdayLabel'];
																					tmpValue = tmpPersonalValue[key] && moment(tmpPersonalValue[key]).format('MM/DD/YYYYY');
																				}
																				if (key === 'phone') {
																					tmpLabel = tmpPersonalField['phoneLabel'];
																					tmpValue = tmpPersonalValue[key];
																				}
																				if (key === 'passportNRIC') {
																					tmpLabel = tmpPersonalField['passportNRICLabel'];
																					tmpValue = tmpPersonalValue[key];
																				}
																				if (key === 'nationalityCode') {
																					tmpLabel = tmpPersonalField['nationalityLabel'];
																					tmpValue = tmpPersonalValue['nationalityName'];
																				}
																				if (key === 'registerType') {
																					tmpLabel = tmpPersonalField['registerTypeLabel'];
																					tmpValue = tmpPersonalValue[key];
																				}

																				if (tmpLabel && tmpValue)
																					return (
																						<div className='p-grid'>
																							<div className='p-col-5'>{tmpLabel}: </div>
																							<div className='p-col-7'>{tmpValue}</div>
																						</div>
																					);
																			})}
																	</div>

																	{tmpPersonalField.useAddress && (
																		<div className='p-col-12 p-pb-0'>
																			<div className='p-grid'>
																				<div className='p-col-12 p-pb-0'>
																					<span className='p-size-16 p-w-bold'>Address</span>
																				</div>
																				<div className='p-col-12 p-pb-0'>
																					{tmpPersonalValue &&
																						Object.keys(tmpPersonalValue).map((key) => {
																							let tmpLabel;
																							let tmpValue;
																							if (key === 'address1') {
																								tmpLabel = 'Address 1';
																								tmpValue = tmpPersonalValue[key];
																							}
																							if (key === 'address2') {
																								tmpLabel = 'Address 2';
																								tmpValue = tmpPersonalValue[key];
																							}
																							if (key === 'city') {
																								tmpLabel = 'City';
																								tmpValue = tmpPersonalValue[key];
																							}
																							if (key === 'zipCode') {
																								tmpLabel = 'Zip Code';
																								tmpValue = tmpPersonalValue[key];
																							}
																							if (key === 'countryCode') {
																								tmpLabel = 'Country';
																								tmpValue = tmpPersonalValue['countryName'];
																							}
																							if (key === 'stateCode') {
																								tmpLabel = 'State/Province';
																								tmpValue = tmpPersonalValue['stateName'];
																							}
																							if (key === 'region') {
																								tmpLabel = 'Region';
																								tmpValue = tmpPersonalValue['region'];
																							}
																							if (tmpLabel && tmpValue)
																								return (
																									<div className='p-grid'>
																										<div className='p-col-5'>{tmpLabel}: </div>
																										<div className='p-col-7'>{tmpValue}</div>
																									</div>
																								);
																						})}
																				</div>
																			</div>
																		</div>
																	)}

																	{tmpPersonalField.useEmerContact && (
																		<div className='p-col-12 p-pb-0'>
																			<div className='p-grid'>
																				<div className='p-col-12 p-pb-0'>
																					<span className='p-size-16 p-w-bold'>Emergency Contact</span>
																				</div>
																				<div className='p-col-12 p-pb-0'>
																					{tmpPersonalValue &&
																						Object.keys(tmpPersonalValue).map((key) => {
																							let tmpLabel;
																							let tmpValue;
																							if (key === 'emeContactName') {
																								tmpLabel = tmpPersonalField['emerContactNameLabel'];
																								tmpValue = tmpPersonalValue[key];
																							}
																							if (key === 'emeContactPhone') {
																								tmpLabel = tmpPersonalField['emerContactPhoneLabel'];
																								tmpValue = tmpPersonalValue[key];
																							}
																							if (key === 'emeContactEmail') {
																								tmpLabel = tmpPersonalField['emerContactEmailLabel'];
																								tmpValue = tmpPersonalValue[key];
																							}
																							if (tmpLabel && tmpValue)
																								return (
																									<div className='p-grid'>
																										<div className='p-col-5'>{tmpLabel}: </div>
																										<div className='p-col-7'>{tmpValue}</div>
																									</div>
																								);
																						})}
																				</div>
																			</div>
																		</div>
																	)}
																</React.Fragment>
															)}

															{tmpCustomSet && (
																<div className='p-col-12 p-pb-0'>
																	<div className='p-grid'>
																		{tmpCustomSet.label && (
																			<div className='p-col-12 p-pb-0'>
																				<span className='p-size-16 p-w-bold'>{tmpCustomSet.label}</span>
																			</div>
																		)}

																		<div className='p-col-12 p-pb-0'>
																			{tmpCustomFieldValue &&
																				Object.values(tmpCustomFieldValue)
																					.sort(function (a, b) {
																						if (a.id < b.id) return -1;
																						else return 1;
																					})
																					.map((item) => {
																						let tmpField = item.field;
																						return (
																							<div className='p-grid'>
																								<div className='p-col-12 p-md-5'>{item.fieldLabel}: </div>
																								{/* <div className="p-col-12 p-md-7">{item.value}</div> */}
																								<div className='p-col-12 p-md-7'>
																									{(() => {
																										if (item.storeType === 'object') {
																											let tmpValue = item.value;
																											for (const fielItem of tmpCustomSet.poolOfFields) {
																												let tmpField = fielItem.field;
																												if (item.fieldId === tmpField.id) {
																													if (tmpField.type === ATTRIBUTE_FIELD_TYPE.user.value) {
																														let userValue = tmpValue ? JSON.parse(tmpValue) : null;
																														tmpValue = userValue?.name;
																													}
																												}
																											}
																											return tmpValue;
																										} else {
																											if (tmpField) {
																												switch (tmpField.type) {
																													case ATTRIBUTE_FIELD_TYPE.date.value:
																														return item.value && moment(item.value).format(DATE_FORMAT_DISPLAY);
																													case ATTRIBUTE_FIELD_TYPE.time.value:
																														return item.value && moment(item.value, 'HH:mm').format(TIME_FORMAT_DISPLAY);
																													case ATTRIBUTE_FIELD_TYPE.date_time.value:
																														return item.value && moment(item.value).format(DATETIME_FORMAT_DISPLAY);
																													default:
																														break;
																												}
																											}
																											return item.value;
																										}
																									})()}
																								</div>
																								{item.storeType === 'file' && item.value && (
																									<div className='p-col-12 p-md-7 p-md-offset-5'>
																										<Button label='Download' onClick={() => this.handleDownloadValue(item.id, item.fieldLabel, item.value)} />
																									</div>
																								)}
																							</div>
																						);
																					})}
																		</div>
																	</div>
																</div>
															)}
															{tmpWaiverSet && (
																<div className='p-col-12 p-pb-0'>
																	<div className='p-grid'>
																		{tmpWaiverSet.label && (
																			<div className='p-col-12 p-pb-0'>
																				<span className='p-size-16 p-w-bold'>{tmpWaiverSet.label}</span>
																			</div>
																		)}

																		<div className='p-col-12 p-pb-0'>
																			{tmpWaiverFieldValue &&
																				Object.values(tmpWaiverFieldValue).map((item) => {
																					let partAnswerObj = item && item.storeType === 'boolean' ? JSON.parse(item.value) : {};
																					return (
																						<div className='p-grid'>
																							<div className='p-col-5'>{item.fieldLabel}: </div>
																							<div className='p-col-7'>{partAnswerObj.accepted}</div>
																						</div>
																					);
																				})}
																		</div>
																	</div>
																</div>
															)}

															{tmpWaiverSet && tmpWaiverSet.requiredGuardian && tmpGuardianPersonalValue && Object.keys(tmpGuardianPersonalValue).length > 0 && (
																<div className='p-col-12 p-pb-0'>
																	<div className='p-grid'>
																		{tmpGuadianPersonalSet.label && (
																			<div className='p-col-12 p-pb-0'>
																				<span className='p-size-16 p-w-bold'>{tmpGuadianPersonalSet.label}</span>
																			</div>
																		)}

																		<div className='p-col-12 p-pb-0'>
																			{Object.keys(tmpGuardianPersonalValue).map((key) => {
																				let tmpLabel;
																				let tmpValue;
																				if (key === 'firstName') {
																					tmpLabel = tmpGuardianPersonalField['firstNameLabel'];
																					tmpValue = tmpGuardianPersonalValue[key];
																				}
																				if (key === 'lastName') {
																					tmpLabel = tmpGuardianPersonalField['lastNameLabel'];
																					tmpValue = tmpGuardianPersonalValue[key];
																				}
																				if (key === 'email') {
																					tmpLabel = tmpGuardianPersonalField['EmailLabel'];
																					tmpValue = tmpGuardianPersonalValue[key];
																				}
																				if (key === 'gender') {
																					tmpLabel = tmpGuardianPersonalField['genderLabel'];
																					tmpValue = tmpGuardianPersonalValue[key];
																				}
																				if (key === 'bithday') {
																					tmpLabel = tmpGuardianPersonalField['birthdayLabel'];
																					tmpValue = tmpGuardianPersonalValue[key] && moment(tmpPersonalValue[key]).format('MM/DD/YYYYY');
																				}
																				if (key === 'phone') {
																					tmpLabel = tmpGuardianPersonalField['phoneLabel'];
																					tmpValue = tmpGuardianPersonalValue[key];
																				}
																				if (key === 'passportNRIC') {
																					tmpLabel = tmpGuardianPersonalField['passportNRICLabel'];
																					tmpValue = tmpGuardianPersonalValue[key];
																				}
																				if (key === 'nationalityCode') {
																					tmpLabel = tmpGuardianPersonalField['nationalityLabel'];
																					tmpValue = tmpGuardianPersonalValue['nationalityName'];
																				}
																				if (key === 'registerType') {
																					tmpLabel = tmpGuardianPersonalField['registerTypeLabel'];
																					tmpValue = tmpGuardianPersonalValue[key];
																				}
																				if (key === 'emeContactName') {
																					tmpLabel = tmpGuardianPersonalField['emerContactNameLabel'];
																					tmpValue = tmpGuardianPersonalValue[key];
																				}
																				if (key === 'emeContactPhone') {
																					tmpLabel = tmpGuardianPersonalField['emerContactPhoneLabel'];
																					tmpValue = tmpGuardianPersonalValue[key];
																				}
																				if (key === 'emeContactEmail') {
																					tmpLabel = tmpGuardianPersonalField['emerContactEmailLabel'];
																					tmpValue = tmpGuardianPersonalValue[key];
																				}
																				if (tmpLabel && tmpValue)
																					return (
																						<div className='p-grid'>
																							<div className='p-col-5'>{tmpLabel}: </div>
																							<div className='p-col-7'>{tmpValue}</div>
																						</div>
																					);
																			})}
																		</div>
																	</div>
																</div>
															)}
														</div>
													);
												}
											})
										);
									})}
							</div>
						</div>
					</Fieldset>

					{(() => {
						let tmpPersonalField = null;
						application.contents &&
							application.contents.map((content, idxContent) => {
								content.components &&
									content.components.map((component, idxComponent) => {
										if (component.type === APPLICATION_COMPONENTT_TYPE.input_field.value) {
											let tmpPersonalSet = component.personalSet;
											tmpPersonalField = tmpPersonalSet ? tmpPersonalSet.personalField : null;
										}
									});
							});

						if (application.type === APPLICATION_TYPE.answer.value && tmpPersonalField && Object.keys(tmpPersonalField).length > 0) {
							return (
								<React.Fragment>
									<Fieldset legend='Reply Actions'>
										<div className='p-grid p-fluid'>
											<div className='p-col-12'>
												<SelectButton
													value={this.state.replyAction ? this.state.replyAction : REPLY_ACTION.reply.value}
													options={Object.values(REPLY_ACTION)}
													onChange={(e) => this.setState({ replyAction: e.value ? e.value : this.state.replyAction })}
												></SelectButton>
											</div>
											{(!this.state.replyAction || this.state.replyAction === REPLY_ACTION.reply.value) && (
												<React.Fragment>
													{!tmpPersonalField.useEmail && (
														<div className='p-col-12'>
															<label className='p-lable'>* Email</label>
															<InputText value={tmpAnswer.email} keyfilter='email' onChange={(e) => this.setState({ answer: { ...tmpAnswer, email: e.target.value } })} />
															<div className='p-form-error'>{errors.email}</div>
														</div>
													)}
													<div className='p-col-12'>
														<label className='p-lable'>* Content</label>
														<InputTextarea rows={5} value={tmpAnswer.content} onChange={(e) => this.setState({ answer: { ...tmpAnswer, content: e.target.value } })} />
														<div className='p-form-error'>{errors.content}</div>
													</div>
												</React.Fragment>
											)}
											{this.state.replyAction === REPLY_ACTION.reject.value && (
												<div className='p-col-12'>
													<label className='p-lable'>* Reason</label>
													<InputTextarea rows={5} value={tmpAnswer.content} onChange={(e) => this.setState({ answer: { ...tmpAnswer, content: e.target.value } })} />
													<div className='p-form-error'>{errors.content}</div>
												</div>
											)}
										</div>
										<div className='p-grid p-margin-top-10'>
											<div className='p-col-12 p-r'>
												<Button label='Submit' onClick={() => this.handleAnswer()} />
											</div>
										</div>
									</Fieldset>
								</React.Fragment>
							);
						}
					})()}

					<div className='p-sidebar-line p-my-3'></div>

					<div className='p-grid'>
						<div className='p-col-12 p-r'>
							<Button label='Close' icon='pi-md-close' className='p-button-secondary' onClick={() => this.closeForm()} />
						</div>
					</div>
				</Sidebar>
			</React.Fragment>
		);
	}
}
