import axios from 'axios';
import { API_BASE_URL, PIM_BASE_URL, PIM_PATH } from '../../constants';
import { converObjetToFormData } from '../../core/service/CommonService';

const PIM_PROMOTION_URL = PIM_BASE_URL + PIM_PATH + '/promotions';

const configHeader = {
	headers: { 'Content-Type': 'multipart/form-data' },
};

//#region Promotion header
export function getPagePromotions(filter, page, size, sortField, sortOrder) {
	const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
	const sort = sortField ? `${sortField},${order}` : '';
	return axios
		.get(`${PIM_PROMOTION_URL}/page`, {
			params: { ...filter, page, size, sort },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getPromotion(id) {
	return axios
		.get(`${PIM_PROMOTION_URL}/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function savePromotion(form, priority) {
	return axios
		.post(`${PIM_PROMOTION_URL}`, form, { params: { priority: priority } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removePromotion(id) {
	return axios
		.delete(`${PIM_PROMOTION_URL}/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function savePromotionPriority(listId) {
	return axios
		.post(`${PIM_PROMOTION_URL}/promotion_priority`, listId)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}
//#endregion

//#region Promotion Rule
export function getPagePromotionRules(promoId, page, size, sortField, sortOrder) {
	const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
	const sort = sortField ? `${sortField},${order}` : '';
	return axios
		.get(`${PIM_PROMOTION_URL}/${promoId}/rules/page`, {
			params: { page, size, sort },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function savePromotionRule(promoId, form, priority) {
	let formData = new FormData();

	for (var key in form) {
		converObjetToFormData(formData, key, form[key]);
	}

	return axios
		.post(`${PIM_PROMOTION_URL}/${promoId}/rules`, formData, { params: { priority: priority } }, configHeader)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removePromotionRule(promoId, id) {
	return axios
		.delete(`${PIM_PROMOTION_URL}/${promoId}/rules/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getListManualPromotionRules(channel) {
	return axios
		.get(`${PIM_PROMOTION_URL}/rules/manual-list`, { params: { channel } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function savePromotionRulePriority(listId, promoId) {
	return axios
		.post(`${PIM_PROMOTION_URL}/promotion_rule_priority/${promoId}`, listId)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function uploadFlyingImage(Id, files, index) {
	let formData = new FormData();
	formData.append('id', Id);
	if (index === 0 || index) formData.append('index', index);
	for (var i = 0; i < files.length; i++) {
		formData.append('files', files[i]);
	}
	return axios
		.post(`${API_BASE_URL}/scp/api/promotions/rule/images`, formData, configHeader)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getListPromotionRules(filter, simple = true) {
	return axios
		.post(`${PIM_PROMOTION_URL}/rules/list`, filter, { params: { simple } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}
//#endregion

//#region Promotion Coupon
export function getPagePromotionCoupons(filter, page, size, sortField, sortOrder) {
	const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
	const sort = `${sortField},${order}`;
	return axios
		.get(`${PIM_PROMOTION_URL}/coupons/page`, {
			params: { ...filter, page, size, sort },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function generatingCouponCode() {
	return axios
		.get(`${PIM_PROMOTION_URL}/coupons/generating_code`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function savePromotionCoupon(form) {
	return axios
		.post(`${PIM_PROMOTION_URL}/coupons`, form)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function generatingCoupon(data) {
	return axios
		.post(`${PIM_PROMOTION_URL}/coupons/generating`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function generatingCouponExchange(data) {
	return axios
		.post(`${PIM_PROMOTION_URL}/coupon-exchange/manual-generating`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removePromotionCoupon(id) {
	return axios
		.delete(`${PIM_PROMOTION_URL}/coupons/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function exportPromotionCoupon(filter) {
	return axios
		.post(`${PIM_PROMOTION_URL}/coupons/export`, filter, { responseType: 'arraybuffer' })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}
//#endregion
