import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { showConfirmNotify, showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { removeSessionJoinCode, saveSessionJoinCode, getListSessionJoinCode, assignJoiningCodeForParticipant } from './EventSessionService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';

export const EventSessionJoiningCode = (props) => {
    const [dataList, setDataList] = useState([])
    const [code, setCode] = useState('')
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if(props.sessionId){
            setErrors({})
            loadListSessionJoinCode()
        }
    }, [props.sessionId])

    const loadListSessionJoinCode = () => {
        getListSessionJoinCode(props.sessionId)
        .then(res => setDataList(res?res:[]))
    }

    const assignCodeForParticipant = () => {
        showloading()
        setErrors({})

        assignJoiningCodeForParticipant(props.sessionId)
        .then(res => {
            if(!res.errorCode){
                if(res.hasAssign)
                    loadListSessionJoinCode(props.sessionId)

                if(res.assignMsg)
                    setErrors({assignMsg: res.assignMsg})

                showSuccessNotify('Action submitted!')
            }else{
                showErrorNotify(res.errorMessage)
            }
            stoploading()
        })
    }

    const handleSaveJoiningCode = () => {
        showloading()
        setErrors({})

        saveSessionJoinCode(props.sessionId, {joinCode: code})
        .then(res => {
            if(!res.errorCode){
                setCode('')
                let tmpList = [...dataList]
                tmpList.push(res)
                setDataList(tmpList)
                showSuccessNotify('Action submitted!')
            }else{
                if(res.errorCode===400)
                    setErrors(res.errorObj)
                showErrorNotify(res.errorMessage)
            }
            stoploading()
        })
    }

    const deleteItem = (id) => {
        showConfirmNotify({
            accept: () => handleDeleteItem(id)
        })
    }

    const handleDeleteItem = (id) => {
        removeSessionJoinCode(props.sessionId, id)
        .then(res => {
            if(!res.errorCode){
                const removeIdx = dataList.findIndex(i => i.id===id);
                let tmpList = [...dataList]
                tmpList.splice(removeIdx, 1)
                setDataList(tmpList)
                showSuccessNotify('Action submitted!')
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const tblHeader = (
        <TableHeader title="List Codes"
            showLength={false}
            showRefresh={false}
        />
    )

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <Fieldset legend="Assign code tool">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <p className="p-mb-2">Use to auto assign code for participants who already join to this session but didn't get code.</p>
                            <Button label="Assign code for participant" className="p-button-success" iconPos="left" onClick={() => assignCodeForParticipant()}/>
                            <div className="p-form-error p-mt-2">{errors.assignMsg}</div>
                        </div>
                    </div>
                </Fieldset>
                
            </div>

            <div className="p-col-12 p-pb-0">
                <Fieldset legend="Joining code setup">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label className="p-label">* Enter Code (press Enter or click button save to create)</label>
                            <div className="p-inputgroup">
                                <InputText value={code} keyfilter={/[^\s]/} onChange={(e) => setCode(e.target.value)} onKeyPress={(e) => {if(e.key==='Enter') handleSaveJoiningCode()}} placeholder="Input code..."/>
                                <Button label="Save" icon="pi pi-save" onClick={() => handleSaveJoiningCode()} />
                            </div>
                            <span className="p-form-error">{errors.joinCode}</span>
                        </div>
                    </div>
                </Fieldset>
            </div>

            <div className="p-col-12 p-mt-4">
                <DataTable lazy={false} paginator={true} value={dataList} header={tblHeader}
                    rows={10} totalRecords={dataList?dataList.length+1:0}
                    responsive={true}
                    emptyMessage="No content available!"
                >
                    <Column header="Code" field="joinCode" sortable={true} />
                    <Column header="Used" field="used" sortable={true} 
                        body={(rowData) => rowData.used?'Assigned':'Not use'}
                    />
                    <Column header="" className="tc-actions" style={{textAlign: 'center', width: '15%' }}
                        body={(rowData) => !rowData.used && <Button icon="pi pi-trash" className="p-button-danger" onClick={() => deleteItem(rowData.id)}></Button>
                        }
                    />
                </DataTable>
            </div>
        </div>
    )
}