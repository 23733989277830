import React, {Component} from "react";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import { GroupServices } from "./GroupServices.js";
import { UserServices } from '../contact/UserServices';
import AsyncSelect from 'react-select/async';
import SelectOption from "../../core/components/contact/SelectionOption.js";
import { GROUP_MEMBER_RELATIONSHIP_TYPES } from "../../constants.js";
import { showNotification } from "../../core/service/NotificationService.js";
import { Sidebar } from "primereact/sidebar";
import { ContactForm } from "../contact/ContactForm.js";

export class MemberForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            data: {
                user: {}
            },
            visible: false,
            errors: {}
        }
        this.groupServices = new GroupServices();
        this.userServices = new UserServices();
    }

    popularFormData = (data) => {
        this.setState({
            data: data,
            userSelected: null,
            formHeader:data.id ? 'Edit Member' :'New Member',
            visible: true,
            errors: {}
        });
    }

    handleSave = () => {
        let tmpData = {...this.state.data}
        tmpData.user = null;

        this.setState({ errors: {} });

        this.groupServices.saveGroupMember(tmpData)
        .then(res => {
            if(!res.errorCode){
                if(this.props.refreshTable)
                    this.props.refreshTable();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400)
                this.setState({errors: res.errorObj});
            }
        });
    }

    loadUserOptions = (searchTerm, callback) => {
        this.userServices.getAllUsers(searchTerm, 'group', this.state.data.groupId)
        .then(res => { 
            callback (res)
        })
    }

    createNewContact = (data) => {
        this.setState({
            data: {...this.state.data,
                userId: data.id
            },
            userSelected: {label: data.name, value: data.id}
        })
    }

    onHide = () => {
        this.setState({ visible: false });
    }

    render(){
        const footer = (
            <React.Fragment>
                <Button label="Save" icon="pi pi-check" onClick={this.handleSave}/>
                <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
            </React.Fragment>
        );

        const Option = props => SelectOption(props)

        return (
            <div>
                <ContactForm ref={el => this.userForm=el}
                    contact={(data) => this.createNewContact(data) }
                />

                <Sidebar visible={this.state.visible} position="right" style={{width:'35em'}} onHide={this.onHide}>
                    <h2>{this.state.formHeader}</h2>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12">
                            <label className="p-label">{!this.state.data.id ? '*' : ''} Member</label>
                            {!this.state.data.id ? 
                                <React.Fragment>
                                    <div className="p-inputgroup">
                                        <AsyncSelect className="p-dropdown p-component p-dropdown-async"
                                            value={this.state.userSelected}
                                            loadOptions={this.loadUserOptions} onChange={(data) => this.setState({data: {...this.state.data, userId: data.value}, userSelected: data})}
                                            placeholder='Type your search'
                                            components={{ Option }}
                                        />
                                        <Button label="New" icon="pi-md-person-add" onClick={(e) => this.userForm.openForm()} />
                                    </div>
                                    <div className="p-form-error">{this.state.errors.user}</div>
                                </React.Fragment>
                                : <div><b>{this.state.data.user.name}</b></div>
                            }
                        </div>
                        {/* <div className="p-col-12">
                            <label className="p-label">* Role</label>
                            <Dropdown options={GROUP_MEMBER_RELATIONSHIP_TYPES} value={this.state.data.memberRole} onChange={(e) => this.setState({data: {...this.state.data, memberRole: e.value}})} style={{width: "100%"}} placeholder="Select role" />
                            <div className="p-form-error">{this.state.errors.role}</div>
                        </div> */}
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            {footer}
                        </div>
                    </div>
                </Sidebar>
            </div>
        );
    }
}