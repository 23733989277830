import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../../constants';

const SCP_BASE_URL = API_BASE_URL + SCP_PATH

export function getPageWishList(filter, page, size, sortField, sortOrder) {
  const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
  const sort = sortField ? `${sortField},${order}`: "";
  return axios.post(`${SCP_BASE_URL}/wish-list/page`, filter, {
      params: {
      page: page,
      size: size,
      sort: sort
    }
  }).then(res => res.data).catch(error => console.log(error));
}

export function sendEmailByWishList(id){
  return axios.post(`${SCP_BASE_URL}/wish-list/${id}/send-email`).then(res => res.data).catch(error => console.log(error));
}