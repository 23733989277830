import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../../constants';

const SCP_BASE_URL = API_BASE_URL + SCP_PATH

export function getPageBlockContents(page, size, sortField, sortOrder) {
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${SCP_BASE_URL}/block-contents/page`, {
        params: {
        page: page,
        size: size,
        sort: sort
        }
    }).then(res => res.data).catch(error => console.log(error));
}

export function getListBlockContents(simple){
    return axios.get(`${SCP_BASE_URL}/block-contents/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}

export function saveBlockContent(data){
    return axios.post(`${SCP_BASE_URL}/block-contents`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function removeBlockContent(id){
    return axios.delete(`${SCP_BASE_URL}/block-contents/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}
