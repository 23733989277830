import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'primereact/button';
import './AccessDenied.css';
import { withNamespaces, Trans } from 'react-i18next';

const AccessDenied = (props) => {
    const { t } = props;
    const trans = (path) => t(path, { framework: "react-i18next" });
    return (
        <div className="exception-body accessdenied-body">
            <div className="exception-container">
                <Trans i18nKey="access_denied.denied">
                    <h1 className="exception-title">Denied</h1>
                </Trans>
                <Trans i18nKey="access_denied.no_privileges">
                    <p>
                        You do not have the necessary privileges to access this page.
                    </p>
                </Trans>
                <Link to="/">
                    <Button label={trans("access_denied.go_to_dashboard")} icon="pi pi-arrow-left" />
                </Link>
            </div>
        </div>
    );
}

export default withNamespaces('message')(AccessDenied);