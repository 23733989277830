import { takeLatest, call, put, select } from 'redux-saga/effects'
import {
  COMPLETE_FORM_REQUESTING,
  COMPLETE_FORM_REQUEST_SUCCESS,
  COMPLETE_FORM_REQUEST_FAILED,
  START_PROCESSING_FORM,
  MARK_STEP_DONE_REQUESTING,
  MARK_STEP_DONE_REQUEST_SUCCESS,
  MARK_STEP_DONE_REQUEST_FAILED,
  TOGGLE_WORKFLOW,
  MARK_CHECKPOINT_DONE_REQUESTING,
  MARK_CHECKPOINT_DONE_REQUEST_SUCCESS,
  MARK_CHECKPOINT_DONE_REQUEST_FAILED
} from '../constants/workflow';
import { 
  completeForm, 
  markStepDone,
  getWorkflowOfFormIntance, 
  exchange as performHttpRequest,
  markCheckpointDone
} from '../services/answer';
import { showNotification } from "../../../core/service/NotificationService.js";
import { history } from '../../../core/history';
import { parameterizeBody, flattenObject, parameterizeUrl } from '../util/parameterize';

const getWorkflowFromState = (state) => state.workflow.workflow;
const getAnswerFromState = (state) => state.answers.data;
const getFormDataFromState = (state) => state.workflow.formData;

function* completeFormFlow({ id }) {
  try {
    let data = yield call(completeForm, id);
    yield put({
      type: COMPLETE_FORM_REQUEST_SUCCESS,
      data
    });
    yield put({
      type: TOGGLE_WORKFLOW,
      showWorkflow: false
    });
  } catch (error) {
    yield put({
      type: COMPLETE_FORM_REQUEST_FAILED,
      error
    });
    showNotification('error', 'Error Message', 'Failed to complete form');
  }
}

function* startProcessingFormFlow({ formInstanceId }) {
  try {
    let data = yield call(getWorkflowOfFormIntance, formInstanceId);
    const answer = yield select(getAnswerFromState);
    yield put({
      type: TOGGLE_WORKFLOW,
      workflow: data.workflow,
      formInstanceId: data.id,
      showWorkflow: true,
      activeStep: data.currentStep,
      formData: createFormObj(answer.groups),
      finishedCheckPoints: data.finishedCheckPoints
    });
  } catch (error) {
  }
}

function createFormObj(groups) {
  const userData = {};
  groups.map(group => group.questions.filter(q => q.answerType !== 'TEXT' && q.answerType !== 'EMPTY')
        .map(question => {
          if (question.fieldName) {
            userData[question.fieldName] = question.value;
          }
        }));
  return userData;
}

// function createFormObj(groups) {
//   const userData = {};
//   groups.map(group => group.questions.filter(q => q.answerType !== 'TEXT').map(question => {
//     if (question.fieldName) {
//       const paths = question.fieldName.split('.');
//       if (paths.length < 2) {
//         userData[question.fieldName] = question.value;
//       } else {
//         let fieldObj = userData;
//         for (let i = 0; i < paths.length - 1; i++) {
//           let fieldName = paths[i];
//           if (!fieldObj[fieldName]) {
//             fieldObj[fieldName] = {};
//           }
//           fieldObj = fieldObj[fieldName];
//         }
//         fieldObj[paths[paths.length - 1]] = question.value;
//       }
//     }
//   }));
//   return userData;
// }

function* markStepDoneFlow({ formInstanceId, step }) {
  try {
    const workflow = yield select(getWorkflowFromState);
    let formResponse = {};
    let formData = {};
    if (step < workflow.length && workflow[step] && workflow[step].url) {
      formData = yield select(getFormDataFromState);
      const config = {
        url: parameterizeUrl(workflow[step].url, formData),
        method: workflow[step].method,
        data: workflow[step].requestBody ? parameterizeBody(workflow[step].requestBody, formData) : null
      }
      formResponse = yield call(performHttpRequest, config); 
    }
    if (step < workflow.length - 1) {
      let res = yield call(markStepDone, formInstanceId, step + 1);
      yield put({
        type: MARK_STEP_DONE_REQUEST_SUCCESS,
        res
      });
      const scopeObj = {
        formData: formData,
        formResponse: flattenObject(formResponse)
      }
      let currentStep = workflow[step];
      let pathInNextStep = parameterizeUrl(currentStep.pathInNextStep, scopeObj);
      history.push(pathInNextStep);
    } else {
      let data = yield call(completeForm, formInstanceId);
      yield put({
        type: COMPLETE_FORM_REQUEST_SUCCESS,
        data
      });
      yield put({
        type: TOGGLE_WORKFLOW,
        showWorkflow: false
      });  
    }
  } catch (error) {
    console.error(error)
    yield put({
      type: MARK_STEP_DONE_REQUEST_FAILED,
      error
    });
    showNotification('error', 'Error Message', 'Failed to complete step!');
  }
}

function* markCheckpointDoneFlow({ formInstanceId, checkpointId }) {
  try {
    yield call(markCheckpointDone, formInstanceId, checkpointId);
    yield put({
      type: MARK_CHECKPOINT_DONE_REQUEST_SUCCESS
    });
    yield call(startProcessingFormFlow, {formInstanceId})
  } catch (error) {
    yield put({
      type: MARK_CHECKPOINT_DONE_REQUEST_FAILED,
      error
    });
    showNotification('error', 'Error Message', 'Failed to mark checkpoint done!');
  }
}

function* workflowWatcher() {
  yield takeLatest(COMPLETE_FORM_REQUESTING, completeFormFlow);
  yield takeLatest(START_PROCESSING_FORM, startProcessingFormFlow);
  yield takeLatest(MARK_STEP_DONE_REQUESTING, markStepDoneFlow);
  yield takeLatest(MARK_CHECKPOINT_DONE_REQUESTING, markCheckpointDoneFlow);
}

export default workflowWatcher;
