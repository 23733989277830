import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../../constants';
import qs from 'qs'
import { converObjetToFormData } from '../../core/service/CommonService';

const CONFIG_FORMDATA_HEADER = {
    headers: { 'Content-Type': 'multipart/form-data' }
}

const APPLICATION_SERVICE_URL = API_BASE_URL+SCP_PATH+'/application'
//#region APPLICATION
export function getPageApplication(filter, page, size, sortField, sortOrder) {
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.get(`${APPLICATION_SERVICE_URL}/page`, {
        params: { ...filter, page, size, sort }
    }).then(res => {return res.data}).catch(error => console.log(error));
}

export function getApplication(id) {
    return axios.get(`${APPLICATION_SERVICE_URL}/${id}`).then(res => {return res.data}).catch(error => console.log(error));
}

export function getApplicationByAlias(alias) {
    return axios.get(`${APPLICATION_SERVICE_URL}/get_by_alias`, {params: {alias}}).then(res => {return res.data}).catch(error => console.log(error));
}

export function getApplications(filter, isSimple=true) {
    return axios.get(`${APPLICATION_SERVICE_URL}/list`, {
        params: {...filter, isSimple}, paramsSerializer: params => {return qs.stringify(params)}
    }).then(res => {return res.data}).catch(error => console.log(error));
}

export function saveApplication(data) {
    return axios.post(`${APPLICATION_SERVICE_URL}`, data)
    .then(res => {return res.data}).catch(error => console.log(error));
}

export function deleteApplication(id) {
    return axios.delete(`${APPLICATION_SERVICE_URL}/${id}`)
    .then(res => {return res.data}).catch(error => console.log(error));
}

export function viewLog(participantId, assetScheduleId){
    return axios.get(`${APPLICATION_SERVICE_URL}/log-view`,{params: {participantId,assetScheduleId }}
    ).then(res => res.data).catch(error => console.log(error));
}

export function getApplicationToLog(alias, participantId, assetScheduleId) {
    return axios.get(`${APPLICATION_SERVICE_URL}/log`, {
        params: {alias, participantId, assetScheduleId}
    }).then(res => res.data).catch(error => console.log(error))
}

export function logApplication(data) {
    let formData = new FormData()
    for (var key in data) {
        converObjetToFormData(formData, key, data[key])
    }
    return axios.post(`${APPLICATION_SERVICE_URL}/log`, formData, {headers: { 'Content-Type': 'multipart/form-data' }})
    .then(res => res.data).catch(error => console.log(error))
}

export function uploadFormTemplateForApplication(id, file) {
    let formData = new FormData();
    formData.append("file", file)
    return axios.post(`${APPLICATION_SERVICE_URL}/${id}/template-upload`, formData, {headers: { 'Content-Type': 'multipart/form-data' }})
    .then(res => {return res.data}).catch(error => console.log(error));
}

export function downloadFormTemplateForApplication(id){
    return axios.post(`${APPLICATION_SERVICE_URL}/${id}/template-download`, null, { responseType: 'arraybuffer'})
    .then(res => {return res.data}).catch(error => console.log(error));
}
//#endregion

//#region APPLICATION RECORD
export function getPageApplicationRecord(filter, page, size, sortField, sortOrder) {
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.get(`${APPLICATION_SERVICE_URL}/record/page`, {
        params: { ...filter, page, size, sort }, paramsSerializer: params => {return qs.stringify(params)} 
    }).then(res => {return res.data}).catch(error => console.log(error));
}

export function getApplicationRecord(id) {
    return axios.get(`${APPLICATION_SERVICE_URL}/record/${id}`)
    .then(res => {return res.data}).catch(error => console.log(error));
}

export function deleteApplicationRecord(id) {
    return axios.delete(`${APPLICATION_SERVICE_URL}/record/${id}`)
    .then(res => {return res.data}).catch(error => console.log(error));
}

export function updateStatusApplicationRecord(id, status) {
    return axios.post(`${APPLICATION_SERVICE_URL}/record/update-status`, null, {params: {id, status}})
    .then(res => {return res.data}).catch(error => console.log(error));
}

export function answerApplicationRecord(data) {
    return axios.post(`${APPLICATION_SERVICE_URL}/record/answer`, null, {params: {...data}})
    .then(res => {return res.data}).catch(error => console.log(error));
}

export function downloadFileFromFieldValueRecord(recordId, valueId){
    return axios.get(`${APPLICATION_SERVICE_URL}/record/${recordId}/value/download`, {params: {valueId}, responseType: 'arraybuffer'})
    .then(res => {return res.data}).catch(error => console.log(error));
}

export function exportApplicationRecords(filter){
    return axios.post(`${APPLICATION_SERVICE_URL}/record/export`, filter, {responseType: 'arraybuffer'}
    ).then(res => res.data).catch(error => console.log(error));
}

export function updateUserLogForApplicationRecord(recordId, userId){
    return axios.post(`${APPLICATION_SERVICE_URL}/record/update-user`, null, {params: {id: recordId, userId: userId}}
    ).then(res => res.data).catch(error => console.log(error));
}

export function importSupportCaseApplicationRecord(data){
    const formData = new FormData();
    formData.append('file', data);

    return axios.post(`${APPLICATION_SERVICE_URL}/record/import/support-case`, formData, CONFIG_FORMDATA_HEADER)
    .then(res => res.data).catch(error => console.log(error));
}

export function signForApplicationRecord(recordId, isSign){
    return axios.post(`${APPLICATION_SERVICE_URL}/record/${recordId}/sign`, null, {params: {isSign}}
    ).then(res => res.data).catch(error => console.log(error));
}

export function exportApplicationRecordToPdf(recordId){
    return axios.post(`${APPLICATION_SERVICE_URL}/record/${recordId}/pdf`, null, {responseType: 'arraybuffer'})
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion

