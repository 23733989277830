export const errorStatusMsg = {
	400: 'Bad Request',
	401: 'Unauthorized',
	403: 'Forbidden',
	404: 'Not Found',
	405: 'Method Not Allowed',
	408: 'Request Timeout',
	500: 'Internal Server Error',
	501: 'Not Implemented',
	502: 'Bad Gateway',
	503: 'Service Unavailable',
	504: 'Gateway Timeout',
};

export const errorMsg = {
	UNKNOWN: 'Unknown error!',
	HANDLE_REQUEST: 'Could not process your request!',
	FETCH_DATA: 'Failed to fetch data!',
	SOMETHING_WRONG: 'Something went wrong... Please try again!',
};

export const ERROR_RESPONSE = {
	errorCode: 503,
	errorMessage: errorStatusMsg[503],
};
