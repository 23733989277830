import React, {Component} from "react";
import { saveScpConfig, getConfig, getTimezones } from "./ConfigruationServices";
import { TabView, TabPanel } from "primereact/tabview";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { AttributeServices, getListAttributeSets } from "../attribute/AttributeServices";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { MailTemplateServices } from "../../mail-management/mail-template/MailTemplateServices";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { renderErrors } from "../../core/service/ErrorService";
import { getCountries, getStatesByCountry } from "./CountrySevices";
import { MailAccountsService } from "../../mail-management/mail-account/MailAccountsService";
import { Checkbox } from "primereact/checkbox";
import TenantPageSetting from "./TenantPageSetting";
import TenantPayment from "./TenantPayment";
import { CURRENCIES, PASSWORD_RESET_MODE, PRODUCT_TYPE, ATTRIBUTE_VALUE_LIST_TYPE } from "../../constants";
import { showSuccessNotify, showErrorNotify } from "../../core/service/NotificationService";
import { getApplications } from "../application/ApplicationService";
import { TenantOperatingHour } from "./TenantOperatingHour";
import { getTaxes } from "../../pim/tax/TaxSerivce";
import { TenantTaxSetting } from "./TenantTaxSetting";
import Select from 'react-dropdown-select';
import { TenantNotification } from "./TenantNotification";

export class Configuration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            address: {},
            paymentConfig: {},
            pageConfig: {},
            accountConfig: {
                passwordRecoverMode: PASSWORD_RESET_MODE.resetPassword.value,
            },
            membershipConfig: {},
            programConfig: {},
            tmpApplicationLog: {},
            errors: {}
        }

        this.attributeServices = new AttributeServices();
        this.mailTemplateServices = new MailTemplateServices();
        this.mailAccountServices = new MailAccountsService();
    }

    componentDidMount(){
        this.getConfig();
        this.getMailTemplates();
        this.loadCountries();
        this.loadMailAccounts();
        this.loadTimezone();
        this.getListAttrLists();
        this.getListAttributeSet('personal');
        // this.getListAttributeSet('custom_field');
        // this.getListAttributeSet('newsletter');
        this.getListAttributeSet('survey');
        this.getListAttributeSet('custom_field');
        // this.getListGLAccounts();
        // this.getListGLAccounts('Cash');
        // this.getListGLAccounts('Receivable');
        // this.getListGLAccounts('Program');
        // this.getListGLAccounts('Membership');
        // this.getListActiveCampaign();
        this.getListApplications();
        this.loadTaxes()
    }

    handleSaveConfig = (type) => {
        this.setState({errors: {}});
        let data = this.state.data;
        data.paymentConfig = this.state.paymentConfig;
        data.pageConfig = this.state.pageConfig;
        data.address = this.state.address;
        data.accountConfig = this.state.accountConfig;
        data.programConfig = this.state.programConfig;
        data.membershipConfig = this.state.membershipConfig;

        saveScpConfig(data, type)
        .then(res => {
            if(!res.errorCode){
                showSuccessNotify('Action Submitted');
            }else{
                if(res.errorCode===400) this.setState({errors: res.errorObj});
                showErrorNotify(res.errorMessage);
            }
        })
    }

    getConfig = () => {
        getConfig()
        .then(res => {
            if(!res.errorCode){
                if(res.defaultCountry)
                    this.loadCountryStates(res.defaultCountry);

                this.setState({
                    data: res,
                    address: res.address?res.address:{},
                    paymentConfig: res.paymentConfig?res.paymentConfig:null,
                    pageConfig: res.pageConfig?res.pageConfig:null,
                    accountConfig: res.accountConfig?res.accountConfig:{passwordRecoverMode: PASSWORD_RESET_MODE.resetPassword.value},
                    membershipConfig: res.membershipConfig?res.membershipConfig:{},
                    programConfig: res.programConfig?res.programConfig:{}
                });
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    getListAttrLists = () => {
        this.attributeServices.getListAttributeValueList(ATTRIBUTE_VALUE_LIST_TYPE.normal.value, true)
        .then(res => {
            if(res){
                this.setState({ attrLists: res })
            }
        })
    }

    getListAttributeSet = (type) => {
        getListAttributeSets(type)
        .then(res => {
            if(type==='personal'){
                this.setState({personalFieldSets: res});
            }else{
                // res.unshift({value:null, label: 'Not use'});
                switch(type){
                    case 'custom_field':
                        this.setState({customFieldSets: res});
                        break;
                    case 'newsletter':
                        this.setState({newLetterFieldSets: res});
                        break;
                    case 'survey':
                        this.setState({surveyFieldSets: res});
                        break;
                    default: break;
                }
            }
        });
    }

    getListGLAccounts = (type) => {
        this.glAccountServices.getAccountList(null, type)
        .then(res => {
            res.unshift({value:null, label: 'Not use'});
            this.setState({ glAccounts: res });

            switch(type){
                case 'Cash':
                    this.setState({ cashGLAccounts: res });
                    break;
                case 'Receivable':
                    this.setState({ receivableGLAccounts: res });
                    break;
                case 'Program':
                    this.setState({ programGLAccounts: res });
                    break;
                case 'Membership':
                    this.setState({ membershipGLAccounts: res });
                    break;
                default: break;
            }
        })
    }

    loadCountries = () => {
        getCountries()
        .then(res => {
            this.setState({ countries: res });
        });
    }

    loadCountryStates = (cId) => {
        getStatesByCountry(cId)
        .then(res => {
            this.setState({countryStates: res});
        });
    }

    getMailTemplates = () => {
        this.mailTemplateServices.getListSimpleMailTemplates()
        .then(res => {
            if(res){
                let tempMailTemplateArr = res;
                let tempRemindMailTemplateArr = res.filter(m => m.useFor === 'remind');
                let tempPaymentMailTemplateArr = res.filter(m => m.useFor === 'payment');
                let tempRecoverUsernameMailTemplateArr = res.filter(m => m.useFor === 'recover_username');
                let tempResetPaswordMailTemplateArr = res.filter(m => m.useFor === 'reset_password');
                let tempVerifyAccountMailTemplateArr = res.filter(m => m.useFor === 'verify_account');
                let tempMembershipMailTemplateArr = res.filter(m => m.useFor === 'membership');
                let tempProgramRegMailTemplateArr = res.filter(m => m.useFor === 'order_complete');
                // let tempZoomAdminMailTemplateArr = res.filter(m => m.useFor === 'zoom_admin');
                // let tempZoomProcessingRequestMailTemplateArr = res.filter(m => m.useFor === 'zoom_processing_request');
                // let tempZoomAccessRequestMailTemplateArr = res.filter(m => m.useFor === 'zoom_access_request');

                tempMailTemplateArr.unshift({value:null, label: 'Not use'});
                tempRemindMailTemplateArr.unshift({value:null, label: 'Not use'});
                tempPaymentMailTemplateArr.unshift({value:null, label: 'Not use'});
                tempRecoverUsernameMailTemplateArr.unshift({value:null, label: 'Not use'});
                tempResetPaswordMailTemplateArr.unshift({value:null, label: 'Not use'});
                tempVerifyAccountMailTemplateArr.unshift({value:null, label: 'Not use'});
                tempMembershipMailTemplateArr.unshift({value:null, label: 'Not use'});
                tempProgramRegMailTemplateArr.unshift({value:null, label: 'Not use'});
                // tempZoomAdminMailTemplateArr.unshift({value:null, label: 'Not use'});
                // tempZoomProcessingRequestMailTemplateArr.unshift({value:null, label: 'Not use'});
                // tempZoomAccessRequestMailTemplateArr.unshift({value:null, label: 'Not use'});

                this.setState({ 
                    mailTemplates: tempMailTemplateArr,
                    remindMailTemplates: tempRemindMailTemplateArr,
                    paymentMailTemplates: tempPaymentMailTemplateArr,
                    recoverUsernameMailTemplates: tempRecoverUsernameMailTemplateArr,
                    resetPasswordMailTemplates: tempResetPaswordMailTemplateArr,
                    verifyAccountMailTemplates: tempVerifyAccountMailTemplateArr,
                    membershipMailTemplates: tempMembershipMailTemplateArr,
                    programRegCompletedMailTemplates: tempProgramRegMailTemplateArr,
                    // zoomAdminMailTemplates: tempZoomAdminMailTemplateArr,
                    // zoomProcessingRequestMailTemplates: tempZoomProcessingRequestMailTemplateArr,
                    // zoomAccessRequestMailTemplates: tempZoomAccessRequestMailTemplateArr,
                });
            }
        })
    }

    loadMailAccounts = () => {
        this.mailAccountServices.getSimpleMailAccounts()
        .then(res => {
            if(res){
                this.setState({ mailAccounts: res })
            }
        })
    }

    onChangeCountry = (e) => {
        this.setState({
            data: { ...this.state.data, 
                defaultCountry: e.value,
                defaultState: ''
            }
        });
        this.loadCountryStates(e.value);
    }

    // getListActiveCampaign = () => {
    //     this.CampaignServices.getListCampaign().then(res => {
    //         this.setState({
    //             listActiveCampaign: res
    //         })
    //     })
    // }

    
    loadTimezone = () => {
        getTimezones().then(res => {
            this.setState({ timezones: res?res:[] })
        })
    }

    getListApplications = () => {
        getApplications(null, true)
        .then(data => {
            this.setState({ applicationOptions: data?data:[]})
        })
    }

    loadTaxes = () => {
        getTaxes(true)
        .then(data => {
            this.setState({ taxes: data})
        })
    }

    onChangeApplicationLog = (values) => {
        this.setState({
            tmpApplicationLog: {...this.state.tmpApplicationLog,
                values: values.map(e => (e.value))
            }
        })
    }

    onAddApplicationLog = () => {
        let tmpType = this.state.tmpApplicationLog.type
        let tmpValues = this.state.tmpApplicationLog.values?this.state.tmpApplicationLog.values:[]
        this.setState({
            programConfig: {...this.state.programConfig,
                applicationLogs: {...this.state.programConfig.applicationLogs,
                    [tmpType]: tmpValues
                }
            },
            tmpApplicationLog: {
                type: null,
                values: []
            }
        })
    }

    onEditApplicationLog = (key) => {
        let tmpValues = this.state.programConfig.applicationLogs[key]
        this.setState({
            tmpApplicationLog: {
                type: key,
                values: tmpValues
            }
        })
    }

    onRemoveApplicationLog = (key) => {
        delete this.state.programConfig.applicationLogs[key]
    }

    render(){
        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Configurations</BreadcrumbsItem>
                
                <div className="p-col-12">
                    <TabView>
                        <TabPanel header="System">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    <Fieldset legend="General Setting">
                                        <div className="p-grid p-fluid">
                                            {/* <div className="p-col-12">
                                                <Checkbox inputId="ckCustomDomain" checked={this.state.data.useCustomDomain} onChange={(e) => this.setState({data: {...this.state.data, useCustomDomain: e.checked}})} />
                                                <label htmlFor="ckCustomDomain"><strong>Use Custom Domain?</strong></label>
                                            </div> */}
                                            <div className="p-col-12">
                                                <label className="p-label">Backend Domain</label>
                                                <InputText value={this.state.data.scpBEDomain} onChange={(e) => this.setState({data: {...this.state.data, scpBEDomain: e.target.value}})} />
                                                <div className="p-form-error">{this.state.errors.scpBEDomain}</div>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Frontend Domain</label>
                                                <InputText value={this.state.data.scpFEDomain} onChange={(e) => this.setState({data: {...this.state.data, scpFEDomain: e.target.value}})} />
                                                <div className="p-form-error">{this.state.errors.scpFEDomain}</div>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Currency </label>
                                                <Dropdown value={this.state.data.currency} options={CURRENCIES} onChange={(e) => this.setState({data: {...this.state.data, currency: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Timezone </label>
                                                <Dropdown value={this.state.data.timezone} options={this.state.timezones} filter={true} onChange={(e) => this.setState({data: {...this.state.data, timezone: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Default Country</label>
                                                <Dropdown options={this.state.countries} value={this.state.data.defaultCountry} showClear={true} filter={true} onChange={(e) => this.onChangeCountry(e)} />                                
                                            </div>
                                            {this.state.countryStates && this.state.countryStates.length > 0 &&
                                                <div className="p-col-12">
                                                    <label className="p-label">Default State </label>
                                                    <Dropdown options={this.state.countryStates} value={this.state.data.defaultState} showClear={true} filter={true} onChange={(e) => this.setState({data: {...this.state.data, defaultState: e.value}})} />                                
                                                </div>
                                            }
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Address">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">Address 1</label>
                                                <InputText value={this.state.address.address1} onChange={(e) => this.setState({address: {...this.state.address, address1: e.target.value}})} />
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Address 2</label>
                                                <InputText value={this.state.address.address2} onChange={(e) => this.setState({address: {...this.state.address, address2: e.target.value}})} />
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">City</label>
                                                <InputText value={this.state.address.city} onChange={(e) => this.setState({address: {...this.state.address, city: e.target.value}})} />
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Zipcode</label>
                                                <InputText value={this.state.address.zipCode} onChange={(e) => this.setState({address: {...this.state.address, zipCode: e.target.value}})} />
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <Fieldset legend="General Attribute Pick List">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">User's register type pick list</label>
                                                <Dropdown options={this.state.attrLists} value={this.state.data.registerTypePickList} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, registerTypePickList: e.value}})}/>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">HR skill pick list</label>
                                                <Dropdown options={this.state.attrLists} value={this.state.data.hrSkillPickList} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, hrSkillPickList: e.value}})}/>
                                            </div>
                                            {/* <div className="p-col-12 p-margin-top-10">
                                                <label className="p-label">Grade</label>
                                                <Dropdown options={this.state.attrLists} value={this.state.data.gradePickList} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, grade: e.value}})} />
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Classification</label>
                                                <Dropdown options={this.state.attrLists} value={this.state.data.classificationSource} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, classificationSource: e.value}})} />
                                            </div> */}
                                            {/* <div className="p-col-12">
                                                <label className="p-label">Communication Preferences Set</label>
                                                <MultiSelect options={this.state.attrSetsNewLetter} value={this.state.dataSystem.communicationPreferences} onChange={(e) => this.setState({dataSystem: {...this.state.dataSystem, communicationPreferences: e.value}})} />
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Priority Level Use </label>
                                                <Dropdown options={lstPriority} value={this.state.dataSystem.priorityLevelUse} onChange={(e) => this.setState({dataSystem: {...this.state.dataSystem, priorityLevelUse: e.value}})} />                                
                                            </div> */}
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Survey Setting">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <Checkbox inputId="ckUseSurvey" checked={this.state.data.useSurvey} onChange={(e) => this.setState({data: {...this.state.data, useSurvey: e.checked}})} />
                                                <label htmlFor="ckUseSurvey"><strong>Use Survey Feature?</strong></label>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Survey Field Set</label>
                                                <Dropdown value={this.state.data.surveySetId} options={this.state.surveyFieldSets} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, surveySetId: e.value}})} placeholder="Pick a Survey Set Data"/>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Donation Setting">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <Checkbox inputId="ckUseDonation" checked={this.state.data.useDonation} onChange={(e) => this.setState({data: {...this.state.data, useDonation: e.checked}})}/>
                                                <label htmlFor="ckUseDonation"><strong>Use Donation?</strong></label>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Donation Field Set</label>
                                                <Dropdown value={this.state.data.donationSetId} options={this.state.customFieldSets} showClear={true} onChange={(e) => this.setState({data: {...this.state.data, donationSetId: e.value}})} placeholder="Not use"/>
                                            </div>
                                            {/* <div className="p-col-12">
                                                <label className="p-label">Donation GL Account </label>
                                                <Dropdown options={this.state.glAccounts} value={this.state.dataSystem.donationGLAccount} filter={true} onChange={(e) => this.setState({dataSystem: {...this.state.dataSystem, donationGLAccount: e.value}})} />                                
                                            </div> */}
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Contact Application">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">Contact Application</label>
                                                <Dropdown value={this.state.data.contactApplicationId} options={this.state.applicationOptions} showClear onChange={(e) => this.setState({data: {...this.state.data, contactApplicationId: e.value}})}/>
                                            </div>
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Others">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">Google captcha key (reCAPTCHA v2)</label>
                                                <InputText value={this.state.data.googleCaptchaKey} onChange={(e) => this.setState({data: {...this.state.data, googleCaptchaKey: e.target.value}})}/>
                                            </div>
                                        </div>
                                    </Fieldset>
                                </div>                                
                            </div>
                            <div className="p-grid p-margin-top-10">
                                <div className="p-col-12">
                                    <Button label="Save" icon="pi pi-save" iconPos="left" onClick={() => this.handleSaveConfig('general')} />
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Payment Setting">
                            <TenantPayment data={this.state.paymentConfig} onSave={(e) => this.setState({payment: e})} />
                        </TabPanel>
                        <TabPanel header="Tax">
                            <TenantTaxSetting data={this.state.data} onSave={(e) => this.setState({data: {...this.state.data, pricingMode: e.pricingMode, defaultTax: e.defaultTax}})} />
                        </TabPanel>
                        <TabPanel header="Page Setting">
                            <TenantPageSetting data={this.state.pageConfig} onSave={(e) => this.setState({pageConfig: e})} />
                        </TabPanel>
                        <TabPanel header="Reg. Setting">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    <Fieldset legend="User Account Setting">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">Personal Field Set</label>
                                                <Dropdown value={this.state.accountConfig.personalSetId} options={this.state.personalFieldSets} showClear={true} onChange={(e) => this.setState({accountConfig: {...this.state.accountConfig, personalSetId: e.value}})} />
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Verify Account Mail Template</label>
                                                <Dropdown value={this.state.accountConfig.accountVerifyTemplate} options={this.state.verifyAccountMailTemplates} onChange={(e) => this.setState({accountConfig: {...this.state.accountConfig, accountVerifyTemplate: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Recover Username Mail Template</label>
                                                <Dropdown value={this.state.accountConfig.recoverUsernameTemplate} options={this.state.recoverUsernameMailTemplates} onChange={(e) => this.setState({accountConfig: {...this.state.accountConfig, recoverUsernameTemplate: e.value}})} />                                
                                                <div className="p-form-error">{renderErrors(this.state.errors.mailTemplateRecoverUsername)}</div>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Reset Password Mode</label>
                                                <Dropdown value={this.state.accountConfig.passwordRecoverMode} options={Object.values(PASSWORD_RESET_MODE)} onChange={(e) => this.setState({accountConfig: {...this.state.accountConfig, passwordRecoverMode: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Recover Password Mail Template</label>
                                                <Dropdown value={this.state.accountConfig.resetPasswordTemplate} options={this.state.resetPasswordMailTemplates} onChange={(e) => this.setState({accountConfig: {...this.state.accountConfig, resetPasswordTemplate: e.value}})} />                                
                                            </div>
                                        </div>
                                    </Fieldset>
                                    {/* <Fieldset legend="Membership Setting">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">Join Price ($)</label>
                                                <Spinner value={this.state.programConfig.membershipJoinPrice} min="0" step="0.25" onChange={(e) => this.setState({programConfig: {...this.state.programConfig, membershipJoinPrice: e.target.value}})} />
                                                <div className="p-form-error">{this.state.errors.programRegFEDomain}</div>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Join Price GL Account</label>
                                                <Dropdown value={this.state.programConfig.membershipJoinPriceGLAccount} options={this.state.membershipGLAccounts} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, membershipJoinPriceGLAccount: e.target.value}})} />
                                                <div className="p-form-error">{this.state.errors.programRegFEDomain}</div>
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Membership Mail Template</label>
                                                <Dropdown value={this.state.data.membershipCompletedTemplate} options={this.state.membershipMailTemplates} onChange={(e) => this.setState({data: {...this.state.data, membershipCompletedTemplate: e.value}})} />                                
                                            </div>
                                        </div>
                                    </Fieldset> */}

                                    {/* <Fieldset legend="Mail Templates">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">Default Mail Template</label>
                                                <Dropdown value={this.state.data.mailTemplateDefault} options={this.state.mailTemplates} onChange={(e) => this.setState({data: {...this.state.data, mailTemplateDefault: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Mail Template Remind </label>
                                                <Dropdown value={this.state.data.mailTemplateRemind} options={this.state.remindMailTemplates} onChange={(e) => this.setState({data: {...this.state.data, mailTemplateRemind: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Mail Template Payment</label>
                                                <Dropdown value={this.state.data.mailTemplatePayment} options={this.state.paymentMailTemplates} onChange={(e) => this.setState({data: {...this.state.data, mailTemplatePayment: e.value}})} />                                
                                            </div>
                                        </div>
                                    </Fieldset> */}
                                    
                                    {/* <Fieldset legend="GL Account">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">Default Cash Account GL</label>
                                                <Dropdown value={this.state.programConfig.defaultCashAccountGL} options={this.state.cashGLAccounts} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, defaultCashAccountGL: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Default Account Receivable GL</label>
                                                <Dropdown value={this.state.programConfig.defaultReceivableAccountGL} options={this.state.receivableGLAccounts} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, defaultReceivableAccountGL: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Default Staff Revenue GL</label>
                                                <Dropdown value={this.state.programConfig.defaultStaffRevenueGL} options={this.state.programGLAccounts} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, defaultStaffRevenueGL: e.value}})} />                                
                                            </div>
                                        </div>
                                    </Fieldset> */}
                                    {/* <Fieldset legend="Discount">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">Global Full-time Staff Discount (%)</label>
                                                <Spinner value={this.state.programConfig.globalFullTimeStaffDiscount} min="0" max="100" onChange={(e) => this.setState({programConfig: {...this.state.programConfig, globalFullTimeStaffDiscount: e.target.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Global Part-time Staff Discount (%)</label>
                                                <Spinner value={this.state.programConfig.globalPartTimeStaffDiscount} min="0" max="100" onChange={(e) => this.setState({programConfig: {...this.state.programConfig, globalPartTimeStaffDiscount: e.target.value}})} />                                
                                            </div>
                                        </div>
                                    </Fieldset> */}
                                    
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <Fieldset legend="Program Setting">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">Personal Field Set</label>
                                                <Dropdown value={this.state.programConfig.personalSetId} options={this.state.personalFieldSets} showClear={true} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, personalSetId: e.value}})} />
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Reg. Success Mail Template</label>
                                                <Dropdown value={this.state.programConfig.mailTemplateRegSuccess} options={this.state.programRegCompletedMailTemplates} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, mailTemplateRegSuccess: e.value}})} />                                
                                            </div>
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="General Application Log Setting">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-9">
                                                        <div className="p-grid">
                                                            <div className="p-col-12">
                                                                <label className="p-label">Product Type</label>
                                                                <Dropdown value={this.state.tmpApplicationLog.type} options={[PRODUCT_TYPE.program, PRODUCT_TYPE.rental]} onChange={(e) => this.setState({tmpApplicationLog: {...this.state.tmpApplicationLog, type: e.value}})} />                                
                                                            </div>    
                                                            <div className="p-col-12">
                                                                <label className="p-label">Application Log</label>
                                                                {/* <MultiSelect value={this.state.programConfig.applicationLogs} options={this.state.applicationOptions} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, applicationLogs: e.value}})} />                                 */}
                                                                {(()=>{
                                                                    let tmpValues = this.state.applicationOptions&&this.state.tmpApplicationLog&&this.state.tmpApplicationLog.values ? this.state.applicationOptions.filter(x1 => this.state.tmpApplicationLog.values.some(x2 => x2 === x1.value)) : []
                                                                    return (
                                                                        <Select multi
                                                                            values={tmpValues}
                                                                            options={this.state.applicationOptions} onChange={(values) => this.onChangeApplicationLog(values)}
                                                                            style={{ width: '100%' }} placeholder="Not use"
                                                                            noDataRenderer={() => { return (<span className="p-c p-padding-10-0">No Data</span>) }}
                                                                        />
                                                                    )
                                                                })()}
                                                            </div>        
                                                        </div>
                                                    </div>
                                                    <div className="p-col-12 p-md-3">
                                                        <Button label="Submit" disabled={!this.state.tmpApplicationLog.type} onClick={() => this.onAddApplicationLog()} />
                                                    </div>     
                                                </div>
                                            </div>
                                            {this.state.programConfig.applicationLogs && Object.keys(this.state.programConfig.applicationLogs).length>0  &&
                                                <React.Fragment>
                                                    <div className="p-col-12"><hr/></div>
                                                    <div className="p-col-12">
                                                        {Object.keys(this.state.programConfig.applicationLogs).map(key =>{
                                                            let tmpValues = this.state.applicationOptions&&this.state.programConfig.applicationLogs&&this.state.programConfig.applicationLogs[key] ? this.state.applicationOptions.filter(x1 => this.state.programConfig.applicationLogs[key].some(x2 => x2 === x1.value)) : []
                                                            return <div className="p-grid">
                                                                <div className="p-col-12 p-md-3">{PRODUCT_TYPE[key] && PRODUCT_TYPE[key].label}</div>
                                                                <div className="p-col-12 p-md-6">{tmpValues.map(item => item.label).join(', ')}</div>
                                                                <div className="p-col-12 p-md-3">
                                                                    <Button icon="pi pi-pencil" onClick={() => this.onEditApplicationLog(key)} />
                                                                    <Button icon="pi pi-times" className="p-button-danger" onClick={() => this.onRemoveApplicationLog(key)} />
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </Fieldset>                               
                                    {/* <Fieldset legend="Mail Templates">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">Reg Success Mail Template</label>
                                                <Dropdown value={this.state.programConfig.mailTemplateRegSuccess} options={this.state.programRegCompletedMailTemplates} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, mailTemplateRegSuccess: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Scholarship Approve Mail Template</label>
                                                <Dropdown value={this.state.programConfig.mailTemplateScholarshipApprove} options={this.state.mailTemplates} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, mailTemplateScholarshipApprove: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Scholarship Decline Mail Template</label>
                                                <Dropdown value={this.state.programConfig.mailTemplateScholarshipDecline} options={this.state.mailTemplates} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, mailTemplateScholarshipDecline: e.value}})} />                                
                                            </div>
                                        </div>
                                    </Fieldset>
                                    <Fieldset legend="Program Notify Mail Templates">
                                        <div className="p-grid p-fluid">
                                            <div className="p-col-12">
                                                <label className="p-label">Mail Template Notify When Full</label>
                                                <Dropdown value={this.state.programConfig.mailTemplateNotifyWhenFull} options={this.state.mailTemplates} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, mailTemplateNotifyWhenFull: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Mail Template Notify When Wailist Full</label>
                                                <Dropdown value={this.state.programConfig.mailTemplateNotifyWhenWaitlistFull} options={this.state.mailTemplates} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, mailTemplateNotifyWhenWaitlistFull: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Mail Template Notify Every Registration</label>
                                                <Dropdown value={this.state.programConfig.mailTemplateNotifyEveryRegistration} options={this.state.mailTemplates} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, mailTemplateNotifyEveryRegistration: e.value}})} />                                
                                            </div>
                                            <div className="p-col-12">
                                                <label className="p-label">Mail Template Registration Reached Threshold</label>
                                                <Dropdown value={this.state.programConfig.mailTemplateRegReachedThreshold} options={this.state.mailTemplates} onChange={(e) => this.setState({programConfig: {...this.state.programConfig, mailTemplateRegReachedThreshold: e.value}})} />                                
                                            </div>
                                        </div>
                                    </Fieldset> */}
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <Button label="Save" icon="pi pi-save" iconPos="left" onClick={() => this.handleSaveConfig('regConfig')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Operating Hours">
                            <TenantOperatingHour data={this.state.data} onSave={(data) => this.setState({data: {...this.state.data, branchHours: data.branchHours, dayOffs: data.dayOffs, timeBlockUnit: data.timeBlockUnit, timeBlockValue: data.timeBlockValue}})}/>
                        </TabPanel>
                        <TabPanel header="Notification">
                            <TenantNotification data={this.state.data.notificationConfig} onSave={(data) => this.setState({notificationConfig: data})} />
                        </TabPanel>
                        {/* <TabPanel header="Document">
                            <div className="p-grid">
                                <div className="p-col-12 p-md-6">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12">
                                            <label className="p-label">Type</label>
                                            <Dropdown value={this.state.dataDocumnet.type} options={this.state.attrLists} onChange={(e) => this.setState({dataDocumnet: {...this.state.dataDocumnet, type: e.value}})} />                                
                                        </div>
                                    </div>
                                </div>
                                <div className="p-col-12 p-r">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-6">
                                            <Button label="Save Document Configurations" icon="pi pi-save" iconPos="left" onClick={(e) => this.handleSaveDocumentConfig()} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel> */}
                    </TabView>
                </div>
            </div>
        );
    }
}
