import axios from 'axios';
import { API_BASE_URL } from '../../constants';

export function getPagePageContent(filter, page, size, sortField, sortOrder){
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${API_BASE_URL}/scp/api/contents/page`,{
        params: {...filter, page, size, sort}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function getPageContents(simple){
    return axios.get(`${API_BASE_URL}/scp/api/contents/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}

export function savePageContent(data){
    return axios.post(`${API_BASE_URL}/scp/api/contents`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function removePageContent(id){
    return axios.delete(`${API_BASE_URL}/scp/api/contents/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}