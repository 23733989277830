import axios from 'axios';
import { API_BASE_URL } from '../../../constants';

export class SearchServices {

    search(searchValue){
        return axios.get(`${API_BASE_URL}/scp/api/search`, {params: {value: searchValue}})
        .then(res => res.data).catch(error => console.log(error));
    }

}