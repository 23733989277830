import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { useRouteMatch } from 'react-router-dom';
import { SplitButton } from 'primereact/splitbutton';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { DoorForm } from './DoorForm';
import { getPageDoors, removeDoor } from './DoorService';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';

export const DoorListView = () => {
	const match = useRouteMatch();

	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: '',
		sortOrder: 0,
	});

	const [isLoadTable, setLoadTable] = useState(true);

	const frmDoor = useRef(null);

	const isWrite = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_DOOR_W]);
	const isDelete = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_DOOR_D]);

	useEffect(() => {
		if (isLoadTable) loadPageDoors();
	}, [isLoadTable]);

	const loadPageDoors = () => {
		getPageDoors(pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				setDataList(res.content);
				setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
			})
			.finally(() => setLoadTable(false));
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const tblHeader = (
		<TableHeader
			title='Doors'
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			actionTemplate={isWrite && <Button className='p-button-success' label='Create Door' icon='pi-md-plus' onClick={() => frmDoor.current.openForm(null)} />}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
		/>
	);

	const deleteDoor = (id) => {
		removeDoor(id).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Action Submitted');
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>Doors</BreadcrumbsItem>

			<DoorForm ref={frmDoor} refreshTable={() => setLoadTable(true)} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1 className='p-w-bold p-m-0'>Doors</h1>
				</div>

				<DataTable
					emptyMessage="There's no record found"
					header={tblHeader}
					value={dataList}
					lazy={true}
					loading={isLoadTable}
					paginator={true}
					first={pageable.page * pageable.rows}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					onPage={onPage}
					onSort={onSort}
					responsive={true}
				>
					<Column field='id' header='ID' sortable />
					<Column field='name' header='Name' sortable />
					<Column field='ipAddress' header='IP Address' />
					<Column field='port' header='Port' />
					<Column field='asset.name' header='Facility' />
					<Column field='position' header='Position' sortable />
					<Column
						bodyClassName='tc-actions'
						body={(rowData) => {
							if (isWrite && isDelete) {
								let actionItems = [{ label: 'Remove', icon: 'pi pi-times', command: () => deleteDoor(rowData.id) }];
								return <SplitButton label='Edit' model={actionItems} icon='pi pi-pencil' className='p-button-constrast' onClick={() => frmDoor.current.openForm(rowData)}></SplitButton>;
							}
							if (isWrite) {
								return <Button label='Edit' icon='pi pi-pencil' className='p-button-constrast' onClick={() => frmDoor.current.openForm(rowData)} />;
							}
							if (isDelete) {
								return <Button label='Remove' icon='pi pi-trash' className='p-button-danger' onClick={() => deleteDoor(rowData.id)} />;
							}
						}}
					/>
				</DataTable>
			</div>
		</div>
	);
};
