import React, { useState, useEffect, useRef } from "react";
import {DataTable} from 'primereact/datatable';
import {Button} from 'primereact/button';
import {Column} from 'primereact/column';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from "../core/service/NotificationService";
import { useRouteMatch } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { TableHeader } from "../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../core/service/CommonService";
import { DiplomaForm } from "./DiplomaForm";
import { getPageDiploma, removeDiploma } from "./DiplomaServices";

export const DiplomaLisView = () => {
    const match = useRouteMatch()

    const [dataList, setDataList] = useState([])
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: 'id',
        sortOrder: -1
    })
    const [filter, setFilter] = useState({})
    
    const [isLoadTable, setLoadTable] = useState(true);

    const frmDiploma = useRef(null)

    useEffect(()=>{
        if(isLoadTable)
            loadPageDiplomas()
    },[isLoadTable])

    const loadPageDiplomas = () => {
        getPageDiploma(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const handleRemoveDiploma = (id) => {
        removeDiploma(id).then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }

    const onRemoveDiploma = (id) => {
        showConfirmNotify({
            accept: () => handleRemoveDiploma(id)
        })
    }

    const tblHeader = (
        <TableHeader title="Diplomas" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={<Button className="p-button-success" label="Create Programme" icon="pi-md-plus" onClick={() => frmDiploma.current.openForm(null)}/>}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    );

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Programme</BreadcrumbsItem>

            <DiplomaForm ref={frmDiploma} refreshTable={() => setLoadTable(true)}/>

            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1 className="p-w-bold p-m-0">Diplomas</h1>
                </div>

                <DataTable emptyMessage="There's no record found" header={tblHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                >
                    <Column field="id" header="ID" sortable />
                    <Column field="name" header="Name" sortable />
                    <Column style={{textAlign: 'right', width: '13em'}} bodyClassName="tc-actions"
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Remove', icon: 'pi pi-times', command: () => onRemoveDiploma(rowData.id)}
                            ]
                            return(
                                <SplitButton label="Edit" model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => frmDiploma.current.openForm(rowData)}></SplitButton>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    )
}