import axios from "axios";
import { API_BASE_URL, SCP_PATH } from "../../constants";

export class SimpleCalendarServices {
    getListSimpleCalendars(){
        return axios.get(`${API_BASE_URL}/scp/api/simple-calendars/list`)
        .then(res => res.data).catch(error => console.log(error));
    }

    getSimpleCalendarById(id){
        return axios.get(`${API_BASE_URL}/scp/api/simple-calendars/calendar`, id)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveSimpleCalendars(data){
        return axios.post(`${API_BASE_URL}/scp/api/simple-calendars/save`, data)
        .then(res => res.data).catch(error => console.log(error))
    }

    getProgram(){
        return axios.get(`${API_BASE_URL}/scp/api/programs/simpleDTO-programs`)
        .then(res =>res.data).catch(error => console.log(error))
    }
}