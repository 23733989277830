import React, {useState, useRef, useEffect} from 'react';
import {Button} from 'primereact/button';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { SliderForm } from './SliderForm';
import { SliderItemForm } from './SliderItemForm';
import { getPageSliders, removeSlider, removeSliderItem } from '../slider/SliderServices';
import { useRouteMatch } from 'react-router-dom';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { SplitButton } from 'primereact/splitbutton';

export const SliderListView = () => {
    const match = useRouteMatch()

    const [dataList, setDataList] = useState([])
    const [expandedRows, setExpandedRows] = useState()
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: 'id',
        sortOrder: -1
    })
    
    const [isLoadTable, setLoadTable] = useState(true);

    const frmSlider = useRef(null)
    const frmSliderItem = useRef(null)

    useEffect(()=>{
        if(isLoadTable)
            loadPageSliders()
    },[isLoadTable])

    const loadPageSliders = () => {
        getPageSliders(pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const tblHeader = (
        <TableHeader title="Sliders" 
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            actionTemplate={
                <Button className="p-button-success" label="Create Slider" icon="pi-md-plus" onClick={() => frmSlider.current.openForm(null)}/>}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    );

    const rowExpansionTemplate = (data) => {
        let tblItem = (
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <Button icon="pi pi-plus" label="Add Item" className="p-float-left p-button-constrast" onClick={() => frmSliderItem.current.openForm(data.id)} style={{width: 'auto'}} />
                <h3 className="fle-datatable-header-title" style={{marginTop: 0, marginBottom: 0}}>List of {data.name}</h3>
            </div>
        )
        return(
            <DataTable value={data.slides} header={tblItem} >
                <Column field="name" header="Name"/>
                <Column field="weight" header="Weight" />
                <Column field="active" header="Active" body={rowData => rowData.active?'Yes':'No'} />
                <Column header="" bodyClassName="tc-actions" bodyStyle={{textAlign: 'right', minWidth: '5%' }}
                    body={(rowData) => 
                        <React.Fragment>
                            <Button icon="pi pi-pencil" className="p-button-info btn-sm" onClick={() => frmSliderItem.current.openForm(data.id, rowData)}/>
                            <Button icon="pi pi-times" className="p-button-danger btn-sm" onClick={() => deleteSliderItem(rowData.id)}/>
                        </React.Fragment>
                    }
                />
            </DataTable>
        )
    }

    const deleteSlider = (id) => {
        removeSlider(id)
        .then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }

    const deleteSliderItem = (id) => {
        removeSliderItem(id).then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Action Submitted')
            }else{
                showErrorNotify('Cannot perform action')
            }
        })
    }

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Sliders</BreadcrumbsItem>

            <SliderForm ref={frmSlider} refreshTable={() => setLoadTable(true)}/>
            <SliderItemForm ref={frmSliderItem} refreshTable={() => setLoadTable(true)}/>

            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1 className="p-w-bold p-m-0">Sliders</h1>
                </div>

                <DataTable emptyMessage="There's no operating booking order found" header={tblHeader} 
                    value={dataList} lazy={true} loading={isLoadTable}
                    paginator={true} first={pageable.page * pageable.rows} rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    onPage={onPage} onSort={onSort}
                    responsive={true}
                    expandedRows={expandedRows} dataKey="id"
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate}
                >
                    <Column expander style={{ width: '3.5em' }} />
                    <Column field="name" header="Title" />
                    <Column style={{textAlign: 'right', width: '13em'}} bodyClassName="tc-actions"
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Remove', icon: 'pi pi-times', command: (e) => deleteSlider(rowData.id)}
                            ]
                            return(
                                <SplitButton label="Edit" model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => frmSlider.current.openForm(rowData)}></SplitButton>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    )
}