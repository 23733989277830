import React, { forwardRef, useState, useEffect, useImperativeHandle, useRef } from 'react';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Fieldset } from 'primereact/fieldset';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { DOCUMENT_TYPE } from '../../constants';
import AsyncSelect from 'react-select/async';
import { getAllUsers, getUser } from '../contact/UserServices';
import { ContactForm } from '../contact/ContactForm';
import SelectOption from '../../core/components/contact/SelectionOption';
import { addMembershipMember } from './MembershipServices';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { getListIdentityDocuments } from '../contact/identity-document/components/IdentityDocumentServices';

export const MembershipMemberForm = forwardRef((props, ref) => {
	const [header, setHeader] = useState('Add Membership Member');
	const [visible, setVisible] = useState(false);
	const [frmData, setFrmData] = useState({});
	const [errors, setErrors] = useState({});

	const ddlUsers = useRef(null);
	const frmUser = useRef(null);

	const [user, setUser] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [workPermitDocument, setWorkPermitDocument] = useState({});
	const [passportDocument, setPassportDocument] = useState({});

	useImperativeHandle(ref, () => ({
		openForm(membershipId) {
			setFrmData({ membershipId: membershipId });
			setVisible(true);
		},
	}));

	const loadUsers = (searchTerm, callback) => {
		getAllUsers(searchTerm).then((res) => {
			callback(res);
		});
	};

	const getUserDetails = async (id) => {
		getUser(id).then((res) => {
			setSelectedUser(res ? res : null);

			if (res) {
				loadUserIdentityDocuments(id);
			}
		});
	};

	const onCreateContactSuccessful = (e) => {
		ddlUsers.current.focus();
		setUser({ value: e.id, label: e.name });
		setSelectedUser(e);
		setFrmData({ ...frmData, userId: e.id });
		setWorkPermitDocument({});
		setPassportDocument({});
	};

	const loadUserIdentityDocuments = async (userId) => {
		const documents = await getListIdentityDocuments({ userId: userId, types: [DOCUMENT_TYPE.work_permit, DOCUMENT_TYPE.passport], active: true });

		if (documents && documents.length > 0) {
			let workPermit = documents.find((d) => d.docType === DOCUMENT_TYPE.work_permit);
			let passport = documents.find((d) => d.docType === DOCUMENT_TYPE.passport);

			if (workPermit && workPermit.expiredDate) workPermit.expiredDateValue = moment(workPermit.expiredDate).format('MM/DD/YYYY');

			if (passport && passport.expiredDate) passport.expiredDateValue = moment(workPermit.expiredDate).format('MM/YYYY');

			setWorkPermitDocument(workPermit ? workPermit : {});
			setPassportDocument(passport ? passport : {});
		}
	};

	const closeForm = () => {
		setVisible(false);
		setFrmData({});
		setUser(null);
		setSelectedUser(null);
		setWorkPermitDocument({});
		setPassportDocument({});
		setErrors({});
	};

	const onUserChange = (val) => {
		setUser(val);
		if (val) getUserDetails(val.value);
		setFrmData({ ...frmData, userId: val ? val.value : null });
	};

	const onExpiredDateChange = (val, type) => {
		switch (type) {
			case DOCUMENT_TYPE.work_permit:
				let workPermit = { ...workPermitDocument };
				workPermit.expiredDate = val ? (moment(val, 'MM/DD/YYYY').isValid() ? moment(val, 'MM/DD/YYYY').format('YYYY-MM-DD') : null) : null;
				workPermit.expiredDateValue = val;
				setWorkPermitDocument(workPermit);
				break;
			case DOCUMENT_TYPE.passport:
				let passport = { ...passportDocument };
				passport.expiredDate = val ? (moment(val, 'MM/YYYY').isValid() ? moment(val, 'MM/YYYY').format('YYYY-MM-DD') : null) : null;
				passport.expiredDateValue = val;
				setPassportDocument(passport);
				break;
			default:
				break;
		}
	};

	const handleSaveMembershipMember = () => {
		showloading();

		let tmpData = { ...frmData };
		tmpData.workPermitDoc = { ...workPermitDocument };
		tmpData.passportDoc = { ...passportDocument };

		addMembershipMember(tmpData).then((res) => {
			if (!res.errorCode) {
				if (props.refreshTable) props.refreshTable();
				closeForm();
				showSuccessNotify('Members has been added!');
			} else {
				if (res.errorCode === 400) setErrors(res.errorObj);
				showErrorNotify(res.errorMessage);
			}
			stoploading();
		});
	};

	const Option = (props) => SelectOption(props);

	return (
		<React.Fragment>
			<ContactForm ref={frmUser} onContactSave={(e) => onCreateContactSuccessful(e)} />

			<Sidebar visible={visible} position='right' className='p-sidebar-md' style={{ overflowY: 'auto' }} blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
				<div className='p-d-flex p-justify-between '>
					<h2 className='p-margin-top-10'>{header}</h2>
					<Button label='' icon='pi pi-times' className='p-button-secondary' onClick={closeForm} />
				</div>

				<div className='p-sidebar-line p-mb-3'></div>

				<div className='p-grid'>
					<div className='p-col-12'>
						<Fieldset legend='Member'>
							<div className='p-grid p-fluid form-group'>
								<div className='p-col-12'>
									<div className='p-grid'>
										<div className='p-col-9'>
											<AsyncSelect ref={ddlUsers} loadOptions={loadUsers} onChange={(e) => onUserChange(e)} placeholder='Type your search' components={{ Option }} value={user} isClearable />
										</div>
										<div className='p-col-3'>
											<Button label='New User' icon='pi-md-person-add' className='p-button-info' onClick={(e) => frmUser.current.openForm()} />
										</div>
									</div>
									<div className='p-form-error'>{errors.userId}</div>
								</div>

								{selectedUser && selectedUser.id ? (
									<React.Fragment>
										<div className='p-fieldset-line p-py-0'></div>
										<div className='p-col-12'>
											<Fieldset legend='Member information'>
												<div className='p-grid form-group'>
													<div className='p-col-4'>Name:</div>
													<div className='p-col-8'>
														<strong>{selectedUser.name}</strong>
													</div>
													<div className='p-col-4'>Email:</div>
													<div className='p-col-8'>
														<strong>{selectedUser.email}</strong>
													</div>
													<div className='p-col-4'>Phone:</div>
													<div className='p-col-8'>
														<strong>{selectedUser.phone}</strong>
													</div>
												</div>
											</Fieldset>
										</div>
									</React.Fragment>
								) : (
									''
								)}
							</div>
						</Fieldset>
						{selectedUser && selectedUser.id && (
							<Fieldset legend='Document'>
								<div className='p-grid p-fluid form-group'>
									<div className='p-col-12 p-md-6'>
										<label className='p-label'>Work Permit Number</label>
										<InputText value={workPermitDocument.docNumber} onChange={(e) => setWorkPermitDocument({ ...workPermitDocument, docNumber: e.target.value })} />
										<div className='p-form-error'>{errors.workPermitNumber}</div>
									</div>
									<div className='p-col-12 p-md-6'>
										<label className='p-label'>Expired Date</label>
										<InputMask
											mask='99/99/9999'
											value={workPermitDocument.expiredDateValue}
											slotChar='DD/MM/YYYY'
											placeholder='DD/MM/YYYY'
											onChange={(e) => onExpiredDateChange(e.value, DOCUMENT_TYPE.work_permit)}
										></InputMask>
										<div className='p-form-error'>{errors.workPermitExpiredDate}</div>
									</div>
									<div className='p-col-12 p-md-6'>
										<label className='p-label'>Passport Number</label>
										<InputText value={passportDocument.docNumber} onChange={(e) => setPassportDocument({ ...passportDocument, docNumber: e.target.value })} />
										<div className='p-form-error'>{errors.passportNumber}</div>
									</div>
									<div className='p-col-12 p-md-6'>
										<label className='p-label'>Expired Date</label>
										<InputMask
											mask='99/9999'
											value={passportDocument.expiredDateValue}
											slotChar='MM/YYYY'
											placeholder='MM/YYYY'
											onChange={(e) => onExpiredDateChange(e.value, DOCUMENT_TYPE.passport)}
										></InputMask>
										<div className='p-form-error'>{errors.passportExpiredDate}</div>
									</div>
								</div>
							</Fieldset>
						)}
					</div>
				</div>

				<div className='p-sidebar-line p-my-3'></div>

				<div className='p-grid'>
					<div className='p-col-12 p-r p-margin-top-30 p-line-top'>
						<Button label='Cancel' icon='pi-md-close' className='p-button-secondary' onClick={() => closeForm()} />
						<Button label='Save' icon='pi pi-save' onClick={() => handleSaveMembershipMember()} />
					</div>
				</div>
			</Sidebar>
		</React.Fragment>
	);
});
