import React, { Component } from 'react';
import { DepartmentServices }  from './DepartmentServices';
import { UserServices } from '../../constituent-management/contact/UserServices';
import AsyncSelect from "react-select/async";
import SelectOption from '../../core/components/contact/SelectionOption';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { showNotification } from '../../core/service/NotificationService';
import { Dropdown } from 'primereact/dropdown';
import { RoleServices } from '../../scp/access-management/role/RoleServices';
import { InputSwitch } from 'primereact/inputswitch';
import { ContactForm } from '../../constituent-management/contact/ContactForm';

export class MemberDepartmentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            departmentMember: {},
            formHeader: 'New Member',
            visible: false,
            roles: [],
            errors: {}
        }

        this.departmentServices = new DepartmentServices();
        this.userServices = new UserServices();
        this.roleServices = new RoleServices();
    }

    action = (data) => {
        this.setState({
            departmentMember: {
                id: data ? data.id : '',
                memberId: data && data.member ? data.member.memberId : '',
                role: data && data.role ? data.role.id : '',
                locked: data ? data.locked: false
            },
            visible: true,
            memberSelection: null,
            formHeader: data ? 'Edit Member' : 'New Member',
            errors: {}
        });
        this.loadListRoles();
    }

    handleSaveForm = () => {
        this.setState({ errors: {} })
        this.departmentServices.saveDepartmentMember(this.state.id, this.state.departmentMember)
        .then(res => {
            if(!res.errorCode){
                this.props.refreshTable();
                this.hideForm();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode === 400) this.setState({errors: res.errorObj})
            }
        })
    }

    loadListRoles = () => {
        this.roleServices.getListSimpoleRoles(this.state.id)
        .then(res => {
            if(!res.errorCode){
                this.setState({ roles: res ? res : [] });
            }
        });
    }

    loadListUsers = (searchTerm, callback) => {
        this.userServices.getAllUsers(searchTerm)
        .then(res => {
            callback (res) 
        });
    }

    createNewContact = (data) => {
        this.setState({
            departmentMember: {...this.state.departmentMember,
                memberId: data.id
            },
            memberSelection: {label: data.name, value: data.id}
        })
    }

    hideForm = () => {
        this.setState({ visible: false })
    }

    render() {

        const Option = props => SelectOption(props);

        return (
            <React.Fragment>
                <ContactForm ref={el => this.userForm=el}
                    contact={(data) => this.createNewContact(data) }
                />

                <Sidebar visible={this.state.visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.hideForm}>
                    <h2>{this.state.formHeader}</h2>

                    <div className="p-grid p-fluid form-group">
                        {!this.state.departmentMember.id ? 
                            <div className="p-col-12">
                                <label className="p-label">* Member</label>
                                <div className="p-inputgroup">
                                    <AsyncSelect className="p-dropdown p-component p-dropdown-async"
                                        value={this.state.memberSelection}
                                        loadOptions={this.loadListUsers} onChange={(e) => this.setState({departmentMember: {...this.state.departmentMember, memberId: e.value}, memberSelection: e}) }
                                        placeholder="Type your search"
                                        components={{ Option }}
                                    />
                                    <Button label="New" icon="pi-md-person-add" onClick={(e) => this.userForm.openForm('department')} />
                                </div>
                                <div className="p-form-error">{this.state.errors.member}</div>
                            </div>
                            : ''
                        }
                        <div className="p-col-12">
                            <label className="p-label">Role {this.state.roles.length === 0 ? <span className="p-form-error">(department has no roles)</span> : ''}</label>
                            <Dropdown value={this.state.departmentMember.role} options={this.state.roles} onChange={(e) => this.setState({ departmentMember: { ...this.state.departmentMember, role: e.value } })} style={{ width: '100%' }} />
                            <div className="p-form-error">{this.state.errors.role}</div>
                        </div>
                        <div className="p-col-12">
                            <InputSwitch checked={this.state.departmentMember.locked} onChange={(e) => this.setState({ departmentMember: { ...this.state.departmentMember, locked: e.value } })} />
                            <label className="p-margin-left-10">Locked?</label>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            <Button label="Submit" icon="pi pi-check" onClick={(e) => this.handleSaveForm()} />
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.hideForm} />
                        </div>
                    </div>
                </Sidebar>    
            </React.Fragment>
        )
    }
}