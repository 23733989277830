import React, {forwardRef, useImperativeHandle, useState} from "react";
import Avatar from "react-avatar-edit";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { showNotification } from '../../core/service/NotificationService';
import { ToggleButton } from 'primereact/togglebutton';
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { saveUserAvatar } from "./UserServices";

export const AvatarForm = forwardRef((props, ref) => {

    const [user, setUser] = useState({})
    const [imgCamera, setImgCamera] = useState('')
    const [isCamera, setIsCamera] = useState(false)
    const [visible, setVisible] = useState(false)

    useImperativeHandle(ref, () => ({
        openForm(data){
            setUser(data)
            setVisible(true)
        }
    }))

    const handleSave = () => {
        saveUserAvatar({id: user.id, avatar: user.avatar})
        .then(res => {
            if(!res.errorCode){
                if(props.refresh){
                    props.refresh(user.avatar);
                }
                onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    const onCrop = (image) => {
        setUser({...user, avatar: image})
    }

    const onTakePhoto = (dataUri) => {
        setImgCamera(dataUri)
        setIsCamera(false)
    }

    const onHide = () => {
        setVisible(false)
    }

    const renderFooter = (
        <div>
            <Button label="Update" icon="pi pi-check" className="p-button-success" onClick={() => handleSave()}/>
            <Button label="Cancel" icon="pi-md-close" onClick={() => onHide()}/>
        </div>  
    )

    return (
        <Dialog dismissableMask={false} header="Avatar Editor" footer={renderFooter} visible={visible} baseZIndex={1000000} width="700px" modal={true} onHide={onHide}>
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12">
                            <ToggleButton onLabel="Close Camera" offLabel="Use Camera" onIcon="pi-md-close" offIcon="pi pi-camera" checked={isCamera} onChange={(e) => setIsCamera(e.value)} style={{width: "auto"}} />
                        </div>
                    
                        {!isCamera ? 
                            <React.Fragment>
                                <div className="p-md-6 p-col-12">
                                    <Avatar width={300} height={200}
                                        imageWidth={300}
                                        src={imgCamera}
                                        onCrop={onCrop} 
                                        // onClose={onClose}
                                    />
                                </div>
                                <div className="p-md-6 p-col-12 p-text-center" >
                                    {user.avatar && <img src={user.avatar} alt="Preview" />}
                                </div>
                            </React.Fragment>
                            : <Camera onTakePhoto={(dataUri) => onTakePhoto(dataUri)} 
                                isImageMirror={true} isDisplayStartCameraError = {true}
                            />
                        }
                    </div>
                </div>
            </div>
        </Dialog>
    );
})