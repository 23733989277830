import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import { getInvoice, sendInvoiceMailRemind } from '../invoice/InvoiceServices';
import { Fieldset } from 'primereact/fieldset';
import { getListTransactions } from '../../scp/accounting/transaction/TransactionServices';
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Button } from 'primereact/button';
import { PAYMENT_STATE, APP_FEP, PAYMENT_METHOD, DATETIME_FORMAT_DISPLAY} from '../../constants';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { moneyFormat } from '../../core/service/CommonService';

export const DonationDetails = (props) => {
    const [invoice, setInvoice] = useState({order: {}, orderItem: {}});
    const [transactions, setTransactions] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() =>{
        loadInvoice();
        loadTransactions()
    },[])

    const loadInvoice = () => {
        showloading();
        getInvoice(props.match.params.id).then(res => {
            if(!res.errorCode){
                setInvoice(res)
            }else{
                showErrorNotify('No result');
            }
        }).finally(stoploading());
    }

    const loadTransactions = () => {
        getListTransactions({app: APP_FEP, invoiceIds: [props.match.params.id]})
        .then(res => setTransactions(res?res:[]) )
    }

    const sendRemindEmail = () => {
        showConfirmNotify({
            message: invoice.remind?'This invoice had already send to customer. Are you sure you want to proceed?':'',
            accept: () => handleSendInvoiceRemindEmail(invoice.id)
        })
    }

    const handleSendInvoiceRemindEmail = (id) => {
        sendInvoiceMailRemind(id)
        .then(res => {
            if(!res.errorCode){
                showSuccessNotify('Email sent successfully!');
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={`/donations`}>Donation</BreadcrumbsItem>
            <BreadcrumbsItem to={`/donations/${props.match.params.id}`}>Donation #{props.match.params.id}</BreadcrumbsItem>

            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1> 
                        Donation Details 
                        <div className="p-toolbar-group-right">
                            <Link to={`/donations`}>
                                <Button icon="pi pi-bars" className="p-button-text" tooltip="Return" tooltipOptions={{ position: 'top' }} ></Button>
                            </Link>
                        </div>
                    </h1>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-3">
                            <label>No. #: <span className="p-w-bold">{invoice.id}</span></label>
                        </div>
                        {invoice.gateway &&
                            <div className="p-col-12 p-md-3">
                                <label>Gateway: <span className="p-w-bold">{invoice.gateway}</span></label>
                            </div>
                        }
                        {invoice.method &&
                            <div className="p-col-12 p-md-3">
                                <label>Method: <span className="p-w-bold">{PAYMENT_METHOD[invoice.method] && PAYMENT_METHOD[invoice.method].label}</span></label>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="p-col-12">
                <div className="card card-w-title">
                    <div className="p-grid">
                        <div className="p-col-12 p-md-4">
                            <Fieldset legend="Basic Information">
                                {(()=>{
                                    let tmpDonateInfo = invoice.donateInfo
                                    let isAnonymous = tmpDonateInfo && tmpDonateInfo.isAnonymous
                                    let isPersonal = tmpDonateInfo && tmpDonateInfo.isPersonal
                                    if(!tmpDonateInfo) return ''
                                    if(isAnonymous) return <p><span className="info-title">Payor Name:</span><span>[Anonymous]</span></p>
                                    if(!isAnonymous){
                                        if(isPersonal){
                                            return <React.Fragment>
                                                <p><span className="info-title">Payor Name:</span><span>{invoice.payorName}</span></p>
                                                <p><span className="info-title">Payor Email:</span><span>{invoice.payorEmail}</span></p>
                                                <p><span className="info-title">Payor Phone:</span><span>{invoice.payorPhone}</span></p>
                                                <p><span className="info-title">NRIC/FIN:</span><span> {tmpDonateInfo.nricFin}</span></p>
                                                <p><span className="info-title">Address:</span><span>{tmpDonateInfo.address}</span></p>
                                            </React.Fragment>
                                        }else{
                                            return <React.Fragment>
                                                <p><span className="info-title">Company Name:</span><span>{tmpDonateInfo.companyName}</span></p>
                                                <p><span className="info-title">Contact Person:</span><span>{invoice.payorPhone}</span></p>
                                                <p><span className="info-title">Contact Number:</span><span>{tmpDonateInfo.companyNumber}</span></p>
                                                <p><span className="info-title">Email:</span><span>{invoice.payorEmail}</span></p>
                                                <p><span className="info-title">UEN Number:</span><span>{tmpDonateInfo.uenNumber}</span></p>
                                                <p><span className="info-title">Address:</span><span>{tmpDonateInfo.address}</span></p>
                                            </React.Fragment>
                                        }
                                    }
                                })()}
                                <p><span className="info-title">Status:</span><span>{PAYMENT_STATE[invoice.status] && PAYMENT_STATE[invoice.status].label}</span></p>
                                {invoice.paymentCompletedAt &&
                                    <p><span className="info-title">Completed At:</span><span>{moment(invoice.paymentCompletedAt).format(DATETIME_FORMAT_DISPLAY) }</span></p>
                                }
                                <p><span className="info-title">Description:</span><span>{invoice.description}</span></p>
                                {invoice.note &&
                                    <p><span className="info-title">Note:</span><span>{invoice.note}</span></p>
                                }
                            </Fieldset>
                        </div>
                        <div className="p-col-12 p-md-4">
                            <Fieldset legend="Price Details">
                                <p className="p-size-16"><span className="info-title">Checkout:</span><span>{moneyFormat(invoice.checkout)}</span></p>
                            </Fieldset>
                        </div>
                    </div>
                </div>
            </div>
            {transactions.length>0 &&
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>Transactions</h1>

                        <DataTable value={transactions} responsive={true}>
                            <Column header="Gateway" field="gateway"/>
                            <Column header="Method" field="method" body={rowData => PAYMENT_METHOD[rowData.method] && PAYMENT_METHOD[rowData.method].label} />
                            <Column header="Trans Id" field="transId"/>
                            <Column header="Amount" field="amount" bodyClassName="p-r" body={rowData => moneyFormat(rowData.amount)} />
                            <Column header="Paid At" field="completedAt" bodyClassName="p-r" body={rowData => rowData.completedAt&&moment(rowData.completedAt).format(DATETIME_FORMAT_DISPLAY)} />
                        </DataTable>
                    </div>
                </div>
            }
        </div>
    )

}