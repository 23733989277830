import axios from 'axios';
import { PIM_BASE_URL, PIM_PATH } from '../../constants';
import { converObjetToFormData } from '../../core/service/CommonService';

const configFormDataHeader = {
	headers: { 'Content-Type': 'multipart/form-data' },
};

const PIM_PRODUCTS_URL = PIM_BASE_URL + PIM_PATH + '/products';

export function getPageProducts(filter, page, size, sortField, sortOrder) {
	const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
	const sort = sortField ? `${sortField},${order}` : '';
	return axios
		.post(`${PIM_PRODUCTS_URL}/page`, filter, {
			params: { page, size, sort },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getListProducts(filter, simple = true) {
	return axios
		.post(`${PIM_PRODUCTS_URL}/list`, filter, { params: { simple } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getProduct(id) {
	return axios
		.get(`${PIM_PRODUCTS_URL}/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function saveProduct(data) {
	let formData = new FormData();
	for (var key in data) {
		converObjetToFormData(formData, key, data[key]);
	}
	return axios
		.post(`${PIM_PRODUCTS_URL}`, formData, configFormDataHeader)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function saveProductContent(data) {
	let formData = new FormData();
	for (var key in data) {
		converObjetToFormData(formData, key, data[key]);
	}
	return axios
		.post(`${PIM_PRODUCTS_URL}/content`, formData, configFormDataHeader)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function saveProductAsset(data) {
	return axios
		.post(`${PIM_PRODUCTS_URL}/asset`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removeProduct(id) {
	return axios
		.delete(`${PIM_PRODUCTS_URL}/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removeProductTeaseImage(id) {
	return axios
		.delete(`${PIM_PRODUCTS_URL}/${id}/teaser-image`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function exportProduct(filter) {
	return axios
		.post(`${PIM_PRODUCTS_URL}/export`, filter, { responseType: 'arraybuffer' })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getPageProductAvailabilities(productId, filter, page, size, sortField, sortOrder) {
	const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
	const sort = sortField ? `${sortField},${order}` : '';
	return axios
		.post(`${PIM_PRODUCTS_URL}/${productId}/availabilities/page`, filter, {
			params: { page, size, sort },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function saveProductAvailability(productId, data) {
	return axios
		.post(`${PIM_PRODUCTS_URL}/${productId}/availabilities`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removeProductAvailability(productId, id) {
	return axios
		.delete(`${PIM_PRODUCTS_URL}/${productId}/availabilities/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function importAvailability(file, isCourse) {
	const formData = new FormData();
	formData.append('file', file);

	return axios
		.post(`${PIM_PRODUCTS_URL}/availabilities/import`, formData, { params: { isCourse }, headers: { 'Content-Type': 'multipart/form-data' } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function saveGroupAssociation(productId, data) {
	return axios
		.post(`${PIM_PRODUCTS_URL}/${productId}/group-association`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function saveAssociatedProduct(productId, data) {
	return axios
		.post(`${PIM_PRODUCTS_URL}/${productId}/associated`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removeGroupAssociation(productId, id) {
	return axios
		.delete(`${PIM_PRODUCTS_URL}/${productId}/group-association/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function updateWeightGroupAssociation(productId, data) {
	return axios
		.post(`${PIM_PRODUCTS_URL}/${productId}/group-association//update-weight`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function updateWeightProductAssociated(productId, groupId, data) {
	return axios
		.post(`${PIM_PRODUCTS_URL}/${productId}/group-association/${groupId}/associated/update-weight`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removeAssociatedProduct(productId, id) {
	return axios
		.delete(`${PIM_PRODUCTS_URL}/${productId}/associated/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function saveProductVariant(prodId, data) {
	return axios
		.post(`${PIM_PRODUCTS_URL}/${prodId}/variants`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removeProductVariant(prodId, id) {
	return axios
		.delete(`${PIM_PRODUCTS_URL}/${prodId}/variants/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getProductAvailabilities(productId, filter, simple = true) {
	return axios
		.get(`${PIM_PRODUCTS_URL}/${productId}/availabilities/list`, { params: { ...filter, simple } })
		.then((res) => {
			return res.data;
		})
		.catch((error) => console.log(error));
}

export function saveProductCustomField(id, data) {
	return axios
		.post(`${PIM_PRODUCTS_URL}/attribute/field`, data, {
			params: { id },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function changeProductSetField(id, setId, setName, numDisplay) {
	return axios
		.post(`${PIM_PRODUCTS_URL}/attribute/change-field-set`, null, {
			params: { id, setId, setName, numDisplay },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getBookingProducts(filter) {
	let tmpFilter = { ...filter, isShow: true, isActive: true, isStore: true };
	return axios
		.post(`${PIM_PRODUCTS_URL}/list`, tmpFilter)
		.then((res) => {
			return res.data;
		})
		.catch((error) => console.log(error));
}
