import React, { useState, useEffect, useRef } from 'react';
import { getListTaxons } from '../../pim/taxon/TaxonServices';
import {
	APP_FEP,
	ASSET_SCHEDULE_STATUS,
	ASSET_SCHEDULE_USE_FOR,
	ASSET_TYPE,
	DATETIME_FORMAT_DISPLAY,
	DATE_FORMAT_DISPLAY,
	HR_RESOURCE_TYPE,
	ORDER_STATE,
	PRODUCT_CLASSIFICATION,
	PRODUCT_PRICE_UNIT,
	PRODUCT_TYPE,
	TAXONOMY_TYPE,
} from '../../constants';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { ViewLogApplicationForm } from '../../scp/application/ViewLogApplicationForm';
import { ReturnAssetForm } from '../../frontdesk/operating/ReturnAssetForm';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getCurrentUserId, getTablePageReport, getTimeZone, moneyFormat } from '../../core/service/CommonService';
import { checkinAsset, closeSchedule, deleteSchedule, exportAssetSchedule, getPageSchedules, pickupSchedule } from '../asset-schedule/SchedulesService';
import { showConfirmNotify, showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { SplitButton } from 'primereact/splitbutton';
import classNames from 'classnames';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { getListProducts } from '../product/ProductServices';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { getListHRs } from '../../crm/human_resource/HRServices';
import { getListAssets } from './AssetServices';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { MultiSelect } from 'primereact/multiselect';
import { ROLES_CONFIG } from '../../roles';

const fileDownload = require('js-file-download');

const PRODUCT_FILTER_TYPE = {
	activity: { value: 'program', label: 'Scheduled Activity' },
	facility: { value: 'rental', label: 'Facility' },
	course: { value: 'course_program', label: 'Training Course' },
};

export const FacilityReport = () => {
	const [viewDate, setViewDate] = useState([new Date()]);
	const [viewBranch, setViewBranch] = useState(null);
	const [branches, setBranches] = useState({});
	const [pageable, setPageable] = useState({ page: 0, rows: 10, total: 0, sortField: 'start', sortOrder: -1 });
	const [dataList, setDataList] = useState([]);
	const [isLoadTable, setLoadTable] = useState(false);
	const [products, setProducts] = useState([]);
	const [vendors, setVendors] = useState([]);
	const [assets, setAssets] = useState([]);
	const [limitVendorIds, setLimitVendorIds] = useState([]);
	const [isLimitVendorByUser, setLimitVendorByUser] = useState(false);
	const [viewByProductType, setViewByProductType] = useState(null);
	const [isViewByRangeDate, setViewByRangeDate] = useState(false);
	const [isLoadProduct, setLoadProduct] = useState(false);
	const [isLoadVendor, setLoadVendor] = useState(false);

	const timezone = getTimeZone();

	const [filter, setFilter] = useState({
		statuses: [
			ASSET_SCHEDULE_STATUS.pending.value,
			ASSET_SCHEDULE_STATUS.processing.value,
			ASSET_SCHEDULE_STATUS.in_scheduled.value,
			ASSET_SCHEDULE_STATUS.using.value,
			ASSET_SCHEDULE_STATUS.ended.value,
		],
		dateFrom: moment(new Date()).format('YYYY-MM-DD'),
		dateTo: moment(new Date()).format('YYYY-MM-DD'),
		dateFromValue: moment(new Date()).toDate(),
		dateToValue: moment(new Date()).toDate(),
		minDate: moment(new Date()).toDate(),
		maxDate: moment(new Date()).toDate(),
	});

	const history = useHistory();

	const viewLogForm = useRef(null);
	const returnAssetForm = useRef(null);

	const isWrite = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_REPORT_W]);
	const isDelete = isScpAdmin() || hasRole([ROLES_CONFIG.ASSET_REPORT_D]);

	useEffect(() => {
		async function loadData() {
			let resBranches = await getListTaxons({ app: APP_FEP, type: TAXONOMY_TYPE.branch.value });
			if (!resBranches) {
				resBranches = [];
			}
			resBranches.unshift({ value: null, label: 'All' });

			let tmpListLimitVendorIds = [];
			if (!isScpAdmin()) {
				let userId = getCurrentUserId();
				if (userId) {
					let resVendorsByCurrentUser = await getListHRs({ userIds: [userId], type: HR_RESOURCE_TYPE.vendor.value });
					if (resVendorsByCurrentUser?.length > 0) {
						tmpListLimitVendorIds = resVendorsByCurrentUser.map((hr) => hr.value);

						setLimitVendorByUser(true);
						setLimitVendorIds(tmpListLimitVendorIds);
						setFilter({ ...filter, vendorIds: tmpListLimitVendorIds });
					}
				}
			}

			loadAssets();

			setBranches(resBranches);
			setLoadTable(true);
			setLoadProduct(true);
			setLoadVendor(true);
		}
		loadData();
	}, []);

	useEffect(() => {
		if (isLoadTable) {
			loadPageSchedules();
		}
	}, [isLoadTable]);

	useEffect(() => {
		if (isLoadProduct) {
			loadProducts();
		}
	}, [isLoadProduct]);

	useEffect(() => {
		if (isLoadVendor) {
			loadVendors();
		}
	}, [isLoadVendor]);

	const loadPageSchedules = () => {
		let tmpFilter = { ...filter };
		if (isLimitVendorByUser) {
			tmpFilter.productTypes = [PRODUCT_TYPE.program.value];
			tmpFilter.isCourse = true;
			tmpFilter.vendorIds = limitVendorIds;
		}

		getPageSchedules(tmpFilter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				setDataList(res.content);
				setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
			})
			.finally(() => setLoadTable(false));
	};

	const loadProducts = () => {
		let tmpFilter = {
			app: APP_FEP,
			types: [PRODUCT_TYPE.program.value, PRODUCT_TYPE.rental.value],
			classifications: [PRODUCT_CLASSIFICATION.simple.value],
			branchIds: filter.branchId ? [filter.branchId] : [],
			hrIds: filter.vendorIds,
		};
		if (viewByProductType) {
			switch (viewByProductType) {
				case PRODUCT_FILTER_TYPE.activity.value:
				case PRODUCT_FILTER_TYPE.course.value:
					tmpFilter.types = [PRODUCT_TYPE.program.value];
					if (viewByProductType === PRODUCT_FILTER_TYPE.course.value) {
						tmpFilter.isCourse = true;
					} else {
						tmpFilter.isCourse = false;
					}
					break;
				case PRODUCT_FILTER_TYPE.facility.value:
					tmpFilter.types = [PRODUCT_TYPE.rental.value];
					break;
				default:
					break;
			}
		}
		if (isLimitVendorByUser) {
			if (!limitVendorIds || limitVendorIds.length === 0) {
				setProducts([]);
				return;
			}
			if (filter.vendorIds?.length > 0) {
				let tmpVendorIds = filter.vendorIds.filter((v1) => limitVendorIds.some((v2) => v2 === v1));
				if (tmpVendorIds.length === 0) {
					setProducts([]);
					return;
				}
				tmpFilter.hrIds = tmpVendorIds;
				tmpFilter.isCourse = true;
			}
		}

		getListProducts(tmpFilter)
			.then((res) => setProducts(res ?? []))
			.finally(() => setLoadProduct(false));
	};

	const loadVendors = () => {
		getListHRs({ type: HR_RESOURCE_TYPE.vendor.value, includeIds: limitVendorIds })
			.then((res) => setVendors(res))
			.finally(() => setLoadVendor(false));
	};

	const loadAssets = () => {
		getListAssets({ branchIds: filter.branchId ? [filter.branchId] : [] }).then((res) => setAssets(res));
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const clearFilter = () => {
		setFilter({
			...filter,
			orderIdNumber: '',
			invoiceNumber: '',
			buyer: '',
			branchId: null,
			productTypes: [],
			productIds: [],
			vendorIds: [],
			assetIds: [],
			dateFrom: moment(new Date()).format('YYYY-MM-DD'),
			dateTo: moment(new Date()).format('YYYY-MM-DD'),
			dateFromValue: moment(new Date()).toDate(),
			dateToValue: moment(new Date()).toDate(),
			minDate: moment(new Date()).toDate(),
			maxDate: moment(new Date()).toDate(),
		});
		setViewByProductType(null);
		setViewByRangeDate(false);
		setLoadTable(true);
	};

	const colBookingTypeTemplate = (data) => {
		switch (data.useForType) {
			case ASSET_SCHEDULE_USE_FOR.training_course:
				return 'Training Course';
			case ASSET_SCHEDULE_USE_FOR.program:
				return 'Scheduled Activity';
			default:
				return 'Customer Order';
		}
	};

	const colBookingTitleTemplate = (data) => {
		switch (data.useForType) {
			case ASSET_SCHEDULE_USE_FOR.training_course:
			case ASSET_SCHEDULE_USE_FOR.program:
				return (
					<React.Fragment>
						<div>{data.productName}</div>
						{data.useForType === ASSET_SCHEDULE_USE_FOR.training_course && <div>- {data.availabilityName ?? 'Unknow'}</div>}
					</React.Fragment>
				);
			default:
				return <div>{`${data.orderId} - ${data.orderNumber}`}</div>;
		}
	};

	const colBuyerTemplate = (data) => {
		switch (data.useForType) {
			case ASSET_SCHEDULE_USE_FOR.training_course:
				return data.vendor && <div>{data.vendor.name}</div>;
			case ASSET_SCHEDULE_USE_FOR.program:
				return '';
			default:
				return (
					<React.Fragment>
						<div>{data.buyerName}</div>
						{data.buyerPhone && <div>{data.buyerPhone}</div>}
					</React.Fragment>
				);
		}
	};

	const colActionTemplate = (rowData) => {
		const strNow = timezone ? moment().tz(timezone).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm');

		const now = moment(strNow);
		const start = moment(rowData.start);
		const end = moment(rowData.end);

		let isExpired = false;
		let isPast = false;

		if (now.isAfter(end)) isExpired = true;
		if (now.isBefore(start)) isPast = true;

		const assetType = rowData.asset?.type;

		if (assetType === ASSET_TYPE.equipment.value) {
			return (
				<React.Fragment>
					{isWrite && rowData.status !== ASSET_SCHEDULE_STATUS.using.value && !isExpired && (
						<Button label='Assign' className='p-button-constrast p-margin-bottom-5' onClick={() => handleAssignAsset(rowData)} />
					)}

					{rowData.status === ASSET_SCHEDULE_STATUS.using.value
						? isWrite && <Button label='Return' className='p-button-constrast p-margin-bottom-5' onClick={() => returnAssetForm.current.openForm(rowData)} />
						: isDelete && <Button label='Remove' className='p-button-danger' onClick={() => onRemove(rowData)} />}
				</React.Fragment>
			);

			// let actionItems = [{ label: 'View Log', icon: 'pi pi-search', command: () => viewLogForm.current.openForm(null, rowData.id) }];

			// if (isPast) {
			// 	actionItems.push({ label: 'Remove', icon: 'pi pi-times', command: () => onRemove(rowData) });
			// }

			// if (rowData.status !== ASSET_SCHEDULE_STATUS.using.value) {
			// 	if (!isExpired) {
			// 		return <SplitButton label='Assign' className='p-button-constrast p-l' model={actionItems} onClick={() => handleAssignAsset(rowData)} />;
			// 	} else {
			// 		return <Button label='Close' className='p-button-danger' onClick={() => closeExpiredAssetSchedule(rowData.id)} />;
			// 	}
			// } else {
			// 	return <SplitButton label='Return' className='p-button-constrast p-l' model={actionItems} onClick={() => returnAssetForm.current.openForm(rowData)} />;
			// }
		} else {
			return (
				<React.Fragment>
					{rowData.status === ASSET_SCHEDULE_STATUS.in_scheduled.value && !rowData.checkin && !isExpired && isWrite && (
						<Button label='Check-in' className='p-button-constrast p-margin-bottom-5' onClick={() => handleCheckin(rowData.id, true)} />
					)}

					{rowData.status === ASSET_SCHEDULE_STATUS.using.value
						? isWrite && <Button label='Close' className='p-button-danger' onClick={() => closeExpiredAssetSchedule(rowData.id)} />
						: isDelete && <Button label='Remove' className='p-button-danger' onClick={() => onRemove(rowData)} />}
				</React.Fragment>
			);
		}
	};

	const handleAssignAsset = (data) => {
		if ([ASSET_SCHEDULE_STATUS.pending.value, ASSET_SCHEDULE_STATUS.processing.value].includes(data.status)) {
			showConfirmNotify({
				message: 'The order related with asset schedule has not been paid completely. Must process on order first!',
				acceptLabel: 'Yes, go to the order',
				accept: () => history.push(`/orders/${data.orderId}`),
			});
		} else {
			const strNow = timezone ? moment().tz(timezone).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm');

			const now = moment(strNow);
			const start = moment(data.start);
			const end = moment(data.end);

			if (!now.isAfter(end)) {
				if (now.isBefore(start)) {
					showConfirmNotify({
						message: "Current doesn't match with schedule time. Are you sure to do this?",
						accept: () => assignAsset(data.id),
					});
				} else {
					assignAsset(data.id);
				}
			}
		}
	};

	const assignAsset = (scheduleId) => {
		pickupSchedule(scheduleId).then((res) => {
			if (!res.errorCode) {
				if (res.inCompletedLog) {
					viewLogForm.current.openForm(null, scheduleId);
					showErrorNotify('Please complete the application log as required to continue!');
				} else {
					setLoadTable(true);
					showSuccessNotify('Asset has been assigned');
				}
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	const closeExpiredAssetSchedule = (id) => {
		closeSchedule(id).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Schedule has been closed');
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	const handleCheckin = (id, isCheckin) => {
		checkinAsset(id, isCheckin).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	const handleExport = () => {
		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = `rpt_asset-schedule_${suf}.xlsx`;

		showloading();

		exportAssetSchedule(filter)
			.then((res) => {
				if (!res.errorCode) {
					fileDownload(res, fileName);
				} else {
					showErrorNotify(res.errorMessage);
				}
			})
			.finally(() => stoploading());
	};

	const handleRemove = (assetId, id) => {
		deleteSchedule(assetId, id).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify('Cannot perform action');
			}
		});
	};

	const onRemove = (data) => {
		const timezone = getTimeZone();
		const now = parseInt(moment.tz(moment(), timezone).format('YYYYMMDDHHmm'));
		const start = parseInt(moment(data.start).format('YYYYMMDDHHmm'));
		const end = parseInt(moment(data.end).format('YYYYMMDDHHmm'));

		let isSubmit = false;
		let act;

		if (data.orderId) {
			if (now >= start && now <= end) {
				isSubmit = data.status === 'pending' ? true : false;
				act = 'cancel';
			} else if (now < start) {
				isSubmit = true;
				act = 'cancel';
			} else if (now > end) {
				isSubmit = true;
				act = 'remove';
			}
		} else {
			isSubmit = true;
			act = 'remove';
		}

		const msg = isSubmit ? `You're attempting to ${act} the schedule. This action can not be undo. Do you want to continue?` : 'Not able to remove/cancel the schedule due to it is ongoing.';

		if (isSubmit) {
			showConfirmNotify({
				message: msg,
				accept: () => handleRemove(data.asset.id, data.id),
			});
		} else {
			showErrorNotify(msg, 'Notification');
		}
	};

	const onViewDateChange = (dates) => {
		let tmpStart = dates && dates.length > 0 ? dates[0] : '';
		let tmpEnd = dates && dates.length > 1 ? dates[1] : '';
		setFilter({
			...filter,
			dateFrom: tmpStart && moment(tmpStart).isValid() ? moment(tmpStart).format('YYYY-MM-DD') : '',
			dateTo: tmpEnd && moment(tmpEnd).isValid() ? moment(tmpEnd).format('YYYY-MM-DD') : '',
		});
		setViewDate(dates);
		setLoadTable(true);
	};

	const onDateFromChange = (e) => {
		let maxDate = filter.maxDate ? moment(filter.maxDate) : '';
		let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
		let isCurrentValueAfterMaxDate = currentValue && maxDate && currentValue.isAfter(maxDate);

		setFilter({
			...filter,
			dateFrom: currentValue ? currentValue.format(moment.HTML5_FMT.DATE) : '',
			dateFromValue: e.value,
			minDate: moment(e.value).isValid() ? e.value : '',
			dateTo: !isViewByRangeDate ? (currentValue ? currentValue.format(moment.HTML5_FMT.DATE) : '') : !isCurrentValueAfterMaxDate ? filter.dateTo : '',
			dateToValue: !isViewByRangeDate ? e.value : !isCurrentValueAfterMaxDate ? filter.dateToValue : '',
			maxDate: !isViewByRangeDate ? (moment(e.value).isValid() ? e.value : '') : !isCurrentValueAfterMaxDate ? filter.maxDate : '',
		});
	};

	const onDateToChange = (e) => {
		let minDate = filter.minDate ? moment(filter.minDate) : '';
		let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
		let isCurrentValueBeforeMinDate = currentValue && minDate && currentValue.isBefore(minDate);

		setFilter({
			...filter,
			dateFrom: !isCurrentValueBeforeMinDate ? filter.dateFrom : '',
			dateFromValue: !isCurrentValueBeforeMinDate ? filter.dateFromValue : '',
			minDate: !isCurrentValueBeforeMinDate ? filter.minDate : '',
			dateTo: currentValue ? currentValue.format(moment.HTML5_FMT.DATE) : '',
			dateToValue: e.value,
			maxDate: moment(e.value).isValid() ? e.value : '',
		});
	};

	const onProducTypeChange = (e) => {
		let tmpFilter = { ...filter, isCourse: null };
		if (e.value) {
			let tmpType = e.value;
			switch (tmpType) {
				case PRODUCT_FILTER_TYPE.activity.value:
					tmpFilter.productTypes = [PRODUCT_TYPE.program.value];
					tmpFilter.isCourse = false;
					tmpFilter.vendorIds = [];
					break;
				case PRODUCT_FILTER_TYPE.facility.value:
					tmpFilter.productTypes = [PRODUCT_TYPE.rental.value];
					tmpFilter.vendorIds = [];
					break;
				case PRODUCT_FILTER_TYPE.course.value:
					tmpFilter.productTypes = [PRODUCT_TYPE.program.value];
					tmpFilter.isCourse = true;
					break;
				default:
					break;
			}
		} else {
			tmpFilter.productTypes = [];
		}
		setFilter(tmpFilter);
		setViewByProductType(e.value);
		setLoadProduct(true);
	};

	const onCheckboxRangeDateChange = (e) => {
		if (!e.checked) {
			setFilter({
				...filter,
				dateTo: filter.dateFrom,
				dateToValue: filter.dateFrom && moment(filter.dateFrom).isValid() ? moment(filter.dateFrom).toDate() : '',
				maxDate: filter.dateFrom && moment(filter.dateFrom).isValid() ? moment(filter.dateFrom).toDate() : '',
			});
		}
		setViewByRangeDate(e.checked);
	};

	const onVendorChange = (e) => {
		setFilter({ ...filter, vendorIds: e.value ? [e.value] : null });
		setLoadProduct(true);
	};

	const onBranchChange = (e) => {
		setFilter({ ...filter, branchId: e.value });
		setLoadProduct(true);

		loadAssets();
	};

	const tblHeader = (
		<TableHeader
			title='Asset renting schedules'
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
			actionTemplate={<Button label='Export' icon='pi pi-download' disabled={dataList.length === 0} onClick={() => handleExport()} />}
		/>
	);

	return (
		<div className='p-grid'>
			<ViewLogApplicationForm ref={viewLogForm} />

			<ReturnAssetForm ref={returnAssetForm} reloadTable={() => setLoadTable(true)} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<Fieldset legend='Filter'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12 p-padding-none'>
								<div className='p-grid'>
									<div className='p-col-12'>
										<Checkbox inputId='ckRangDate' checked={isViewByRangeDate} onChange={(e) => onCheckboxRangeDateChange(e)} />
										<label htmlFor='ckRangDate'> View by date range?</label>
									</div>
									<div className='p-col-12 p-md-6'>
										<div className='p-grid'>
											{isViewByRangeDate ? (
												<React.Fragment>
													<div className='p-col-12 p-lg-6'>
														<div className='p-inputgroup'>
															<span className='p-inputgroup-addon p-text-bold'>Date From:</span>
															<MaskedCalendar value={filter.dateFromValue} maxDate={filter.maxDate} onChange={(e) => onDateFromChange(e)} showIcon={true} dateFormat='dd/mm/yy' />
														</div>
													</div>
													<div className='p-col-12 p-lg-6'>
														<div className='p-inputgroup'>
															<span className='p-inputgroup-addon p-text-bold'>Date To:</span>
															<MaskedCalendar value={filter.dateToValue} minDate={filter.minDate} onChange={(e) => onDateToChange(e)} showIcon={true} dateFormat='dd/mm/yy' />
														</div>
													</div>
												</React.Fragment>
											) : (
												<div className='p-col-12'>
													<div className='p-inputgroup'>
														<span className='p-inputgroup-addon p-text-bold'>By date:</span>
														<MaskedCalendar value={filter.dateFromValue} onChange={(e) => onDateFromChange(e)} showIcon={true} dateFormat='dd/mm/yy' />
													</div>
												</div>
											)}
										</div>
									</div>
									{branches && Object.values(branches).length > 2 && (
										<div className='p-col-12 p-md-6 '>
											<div className='p-inputgroup'>
												<span className='p-inputgroup-addon p-text-bold'>View Branch:</span>
												<Dropdown value={filter.branchId} options={branches} onChange={(e) => onBranchChange(e)} />
											</div>
										</div>
									)}
								</div>
							</div>
							<div className='p-col-12 p-padding-none'>
								<div className='p-grid'>
									<div className='p-col-12 p-md-6'>
										<div className='p-grid'>
											{(isScpAdmin() || !isLimitVendorByUser) && (
												<div className='p-col-12'>
													<div className='p-inputgroup'>
														<span className='p-inputgroup-addon p-text-bold'>Booking Type:</span>
														<Dropdown value={viewByProductType} options={Object.values(PRODUCT_FILTER_TYPE)} showClear={true} onChange={(e) => onProducTypeChange(e)} />
													</div>
												</div>
											)}
											{(isScpAdmin() || !isLimitVendorByUser || (isLimitVendorByUser && limitVendorIds?.length > 1)) &&
												(!viewByProductType || viewByProductType === PRODUCT_FILTER_TYPE.course.value) && (
													<div className='p-col-12'>
														<div className='p-inputgroup'>
															<span className='p-inputgroup-addon p-text-bold'>Vendor:</span>
															<Dropdown value={filter.vendorIds?.length > 0 ? filter.vendorIds[0] : null} options={vendors} showClear={true} filter onChange={(e) => onVendorChange(e)} />
														</div>
													</div>
												)}
											<div className='p-col-12'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon p-text-bold'>Product:</span>
													<Dropdown
														value={filter.productIds?.length > 0 ? filter.productIds[0] : null}
														options={products}
														showClear={true}
														onChange={(e) => setFilter({ ...filter, productIds: e.value ? [e.value] : null })}
													/>
												</div>
											</div>
											<div className='p-col-12'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon p-text-bold'>Asset:</span>
													<MultiSelect value={filter.assetIds} options={assets} filter onChange={(e) => setFilter({ ...filter, assetIds: e.value })} />
												</div>
											</div>
										</div>
									</div>
									<div className='p-col-12 p-md-6'>
										<div className='p-grid'>
											<div className='p-col-12'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon p-text-bold'>Order Id/Code:</span>
													<InputText
														value={filter.orderIdNumber}
														onChange={(e) => setFilter({ ...filter, orderIdNumber: e.target.value })}
														onKeyUp={(e) => e && e.key === 'Enter' && setLoadTable(true)}
													/>
												</div>
											</div>
											<div className='p-col-12'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon p-text-bold'>Buyer:</span>
													<InputText
														value={filter.buyer}
														onChange={(e) => setFilter({ ...filter, buyer: e.target.value })}
														onKeyUp={(e) => e && e.key === 'Enter' && setLoadTable(true)}
														placeholder='Name/email/phone'
													/>
												</div>
											</div>
											<div className='p-col-12'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon p-text-bold'>Invoice/Receipt Number:</span>
													<InputText
														value={filter.invoiceNumber}
														keyfilter={/^[0-9]*$/}
														onChange={(e) => setFilter({ ...filter, invoiceNumber: e.target.value })}
														onKeyUp={(e) => e && e.key === 'Enter' && setLoadTable(true)}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='p-grid'>
							<div className='p-col-12 p-c'>
								<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={() => setLoadTable(true)} />
								<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' onClick={() => clearFilter()} />
							</div>
						</div>
					</Fieldset>
				</div>
			</div>

			<div className='p-col-12'>
				<DataTable
					emptyMessage="There's no record found"
					header={tblHeader}
					value={dataList}
					lazy={true}
					loading={isLoadTable}
					paginator={true}
					first={pageable.page * pageable.rows}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					onPage={onPage}
					onSort={onSort}
					responsive={true}
				>
					<Column header='Booking From' body={(rowData) => colBookingTypeTemplate(rowData)} />
					<Column header='Course/Activity/Order' body={(rowData) => colBookingTitleTemplate(rowData)} />
					<Column header='Vendor/Buyer' body={(rowData) => colBuyerTemplate(rowData)} />
					<Column field='asset.name' header='Asset' />
					<Column
						field='status'
						header='Status'
						className='p-c'
						body={(rowData) => <span className={classNames('status', rowData.status)}>{ASSET_SCHEDULE_STATUS[rowData.status] && ASSET_SCHEDULE_STATUS[rowData.status].label}</span>}
					/>
					<Column
						header='Schedule'
						body={(rowData) => {
							if (rowData.start || rowData.end) {
								const startDate = moment(rowData.start).format(DATE_FORMAT_DISPLAY);
								const endDate = moment(rowData.end).format(DATE_FORMAT_DISPLAY);
								const startTime = moment(rowData.start).format('hh:mm A');
								const endTime = moment(rowData.end).format('hh:mm A');
								return (
									<React.Fragment>
										<div className='p-margin-bottom-5'>Date: {startDate === endDate ? startDate : startDate + ' - ' + endDate}</div>
										<div>Time: {startTime + ' - ' + endTime}</div>
									</React.Fragment>
								);
							}
						}}
					/>
					<Column
						header='Check-in'
						field='checkin'
						className='p-c'
						body={(rowData) => <span className={classNames('status', { active: rowData.checkin, inactive: !rowData.checkin })}>{rowData.checkin ? 'Checked in' : 'Not yet'}</span>}
					/>
					{(isWrite || isDelete) && <Column body={colActionTemplate} headerStyle={{ width: '12em' }} bodyClassName='tc-actions' />}
				</DataTable>
			</div>
		</div>
	);
};
