import axios from "axios";
import { API_BASE_URL, SCP_PATH } from "../../constants";

const BASE_URL = API_BASE_URL + SCP_PATH + "/memberships";

export const saveAllRecords = (listRecord) => {
  console.log(listRecord);
  return axios
    .post(`${BASE_URL}/import`, listRecord)
    .then((res) => res.data)
    .catch((error) => console.log(error));
};
