import React, { forwardRef, useState, useImperativeHandle } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import {
  showSuccessNotify,
  showErrorNotify,
} from "../../core/service/NotificationService";
import { saveMembershipType } from "./MembershipTypeService";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useParams } from "react-router";

let schema = yup.object().shape({
  code: yup.string().required("Please input code!"),
  name: yup.string().required("Please input name!"),
  type: yup.string().required("Please select type!"),
  price: yup
    .number()
    .min(0, "Cannot less than 0")
    .transform((curr, origin) => (origin === "" ? null : curr))
    .typeError("Please input only number character!")
    .required("Please input price!")
    .nullable(),
});

const typeOptions = [
  { label: "Individual", value: "Individual" },
  { label: "Group", value: "Group" },
];

export const MembershipTypeForm = forwardRef((props, ref) => {
  const [header, setHeader] = useState("New Membership Type");
  const [visible, setVisible] = useState(false);

  const {
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useImperativeHandle(ref, () => ({
    openForm(data, parent) {
      setHeader((data ? "Edit" : "New") + " Membership Type");
      setVisible(true);
      initFormData(data, parent);
    },
    closeForm() {
      closeForm();
    },
  }));

  const closeForm = () => {
    setVisible(false);
    reset({});
    clearErrors();
  };

  const initFormData = (data, parent) => {
    const formatData = {
      id: data?.id ? data.id : null,
      parentId : parent ? parent : null,
      name: data?.name ? data.name : "",
      code: data?.code ? data.code : "",
      price: data?.price ? data.price : null,
      type: data?.type ? data.type : "",
      active: data?.active ? data.active : false,
    };
    reset(formatData);
  };

  const handleSaveMembershipType = (data) => {
    saveMembershipType(data).then((res) => {
      if (!res.errorCode) {
        if (props.refreshTable) props.refreshTable();

        closeForm();
        showSuccessNotify("Save membership type successfully!");
      } else {
        showErrorNotify("Cannot perform action");
        // if (res.errorCode === 400) setErrors(res.errorObj);
      }
    });
  };

  return (
    <Sidebar
      visible={visible}
      style={{ overflowY: "auto" }}
      className="p-sidebar-md"
      position="right"
      blockScroll={true}
      showCloseIcon={false}
      dismissable={true}
      onHide={closeForm}
    >
      <div className="p-d-flex p-justify-between ">
        <h2 className="p-margin-top-10">{header}</h2>
        <Button
          label=""
          icon="pi pi-times"
          className="p-button-secondary"
          onClick={closeForm}
        />
      </div>

      <div className="p-sidebar-line p-mb-3"></div>

      <form
        className="p-grid p-fluid form-group"
        onSubmit={handleSubmit(handleSaveMembershipType)}
      >
        <div className="p-col-12">
          <label className="p-label">* Code</label>
          <Controller
            name="code"
            control={control}
            defaultValue=""
            render={({ field }) => <InputText {...field} />}
          />
          <span className="p-form-error">{errors.code?.message}</span>
        </div>
        <div className="p-col-12">
          <label className="p-label">* Name</label>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => <InputText {...field} />}
          />
          <span className="p-form-error">{errors.name?.message}</span>
        </div>
        <div className="p-col-12">
          <label className="p-label">* Type</label>
          <Controller
            name="type"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Dropdown {...field} options={typeOptions} />
            )}
          />
          <span className="p-form-error">{errors.type?.message}</span>
        </div>
        <div className="p-col-12">
          <label className="p-label">* Price</label>
          <Controller
            name="price"
            control={control}
            defaultValue=""
            render={({ field }) => <InputNumber {...field} />}
          />
          <span className="p-form-error">{errors.price?.message}</span>
        </div>
        <div className="p-col-12 p-d-flex p-align-center">
          <Controller
            name="active"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <InputSwitch
                {...field}
                checked={getValues("active")}
                onChange={(e) => setValue("active", e.value)}
              />
            )}
          />
          <span className="p-ml-3">Active</span>
          <span className="p-ml-3 p-form-error">{errors.active?.message}</span>
        </div>

        <div className="p-col-12 p-sidebar-line p-my-3"></div>

        <div className="p-col-12 p-d-flex p-justify-end">
          <Button label="Save" style={{ width: "6rem" }} type="submit" className="blue-btn"/>
          <Button
            label="Cancel"
            onClick={closeForm}
            style={{ width: "6rem", backgroundColor: "#f0b323" }}
            type="button"
            className="yellow-btn"
          />
        </div>
      </form>
    </Sidebar>
  );
});
