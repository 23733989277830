import React, {Component} from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { ParticipantServices } from '../../constituent-management/participant/ParticipantServices';
import { showNotification } from '../../core/service/NotificationService';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';

export class CancelParticipantForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            participant: {},
            data: {},
            formHeader: 'Cancel The Participant',
            visible: false,
            errors: {}
        }
        this.participantServices = new ParticipantServices();
    }

    action = (participant) => {
        this.setState({
            participant: participant,
            data: {
                reason: '',
                note: ''
            },
            visible: true,
            errors: {}
        })
    }

    handleSumit = () => {
        this.setState({ errors: {} });

        this.participantServices.cancelParticipant(this.state.participant.id, this.state.data)
        .then(res => {
            if(!res.errorCode){
                if(this.props.refreshTable)
                    this.props.refreshTable();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        })
    }

    onHide = () => {
        this.setState({ visible: false})
    }

    render(){

        return (
            <Sidebar visible={this.state.visible} dismissable={false} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.onHide}>
                <h2>{this.state.formHeader}</h2>

                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12">
                        <label className="p-label">Name: {this.state.participant.name}</label>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">* Reason</label>
                        <InputText value={this.state.data.reason} onChange={(e) => this.setState({data: {...this.state.data, reason: e.target.value}})} />
                        <div className="p-form-error">{this.state.errors.reason}</div>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">* Note</label>
                        <InputTextarea rows={5} cols={30} value={this.state.data.note} onChange={(e) => this.setState({data: {...this.state.data, note: e.target.value}})} />
                        <div className="p-form-error">{this.state.errors.note}</div>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Submit" icon="pi pi-check" onClick={this.handleSumit}/>
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                    </div>
                </div>
            </Sidebar>
        )
    }
}