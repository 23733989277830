import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../constants';
import { converObjetToFormData } from '../core/service/CommonService';
import qs from 'qs'

const DIPLOMA_SERVIEW_ULR = API_BASE_URL + SCP_PATH

const configFormDataHeader = {
    headers: { 'Content-Type': 'multipart/form-data' }
}

export function getPageDiploma(filter, page, size, sortField, sortOrder) {
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${DIPLOMA_SERVIEW_ULR}/diploma/page`, {
        params: {...filter, page, size, sort}
    }).then(res => res.data).catch(error => console.log(error));
}

export function getListDiplomas(filter ,simple=true){
    return axios.get(`${DIPLOMA_SERVIEW_ULR}/diploma/list`, {params: {...filter, simple}, paramsSerializer: params => {return qs.stringify(params)}})
    .then(res => res.data).catch(error => console.log(error));
}

export function saveDiploma(data){
    let formData = new FormData()
    for (var key in data) {
        converObjetToFormData(formData, key, data[key])
    }
    return axios.post(`${DIPLOMA_SERVIEW_ULR}/diploma`, formData, configFormDataHeader)
    .then(res => res.data).catch(error => console.log(error));
}

export function removeDiploma(id){
    return axios.delete(`${DIPLOMA_SERVIEW_ULR}/diploma/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getPageDiplomaByUser(filter, page, size, sortField, sortOrder) {
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.post(`${DIPLOMA_SERVIEW_ULR}/diploma/users/page`, filter, {
        params: {page, size, sort}
    }).then(res => res.data).catch(error => console.log(error));
}

export function exporUserDiploma(filter){
    return axios.post(`${DIPLOMA_SERVIEW_ULR}/diploma/users/export`, filter, {responseType: 'arraybuffer'})
    .then(res => res.data).catch(error => console.log(error));
}
