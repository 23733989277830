import React, { Component } from 'react';
import moment from 'moment';
import { ReportServices } from './ReportServices';
import { ProgramServices } from '../../crm/program/ProgramServices';
import { GLAccountServices } from '../accounting/gl_accounts/GLAccountServices';
import { CampaignServices } from '../campaign/CampaignServices';
import { Fieldset } from 'primereact/fieldset';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';

import { UserServices } from '../../constituent-management/contact/UserServices';
import AsyncSelect from 'react-select/async';
import SelectOption from "../../core/components/contact/SelectionOption.js";
import { PickList } from 'primereact/picklist';
import { showNotification } from '../../core/service/NotificationService';
import { ROLES_CONFIG } from '../../roles';
import { hasRole } from "../../core/security/auth";
import { showloading, stoploading } from '../../core/service/LoadingService';

export class CRMReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            report: {
                type: '',
                startDate: '',                
                endDate: '',
                paymentStatus: ['completed'],
                orderPaymentState: ['completed'],
                refId: '',
                campaignId: '',
                childRefIds: [],
                participantStatus: ['COMPLETED'],
                orderColumns: [],
                transColumns: [],
                participantColumns: [],
                creatorId: '',
                selectedProgram: null
            },
            programs: [],
            campaigns: [],
            rptSourceColumns: [],
            rptTargetColumns: [],
            startDateValue: '',
            endDateValue: '',
            glAccountTree: [],
            error: {}
        }

        this.reportServices = new ReportServices();
        this.programServices = new ProgramServices();
        this.accountServices = new GLAccountServices();
        this.campaignServices = new CampaignServices();
        this.userServices = new UserServices();
    }

    componentDidMount(){
        showloading();
        Promise.all([this.programServices.getListAllPrograms(), this.campaignServices.getListCampaign()])
            .then(result => {
                stoploading();
                let programs = result[0];
                let campaigns = result[1];
                this.setState({
                    programs: programs,
                    campaigns: campaigns
                });
            });
        // this.getListProgram();
        // this.loadAllCampaigns();
    }

    getTreeSelectAccount = () => {
        this.accountServices.getTreeSelectAccount().then(res => this.setState({glAccountTree: res}));
    }

    getListProgram = () => {
        this.programServices.getListAllPrograms().then(res => this.setState({programs: res}));
    }

    loadAllCampaigns = () => {
        this.campaignServices.getListCampaign().then(res => {this.setState({campaigns: res ? res : []})});
    }

    getListProgramCourse = (progId, campaignId) => {
        this.programServices.getListProgramCourses(progId,campaignId).then(res => this.setState({lstCourses: res}));
    }

    getListReportColumns = (type, selectedColumns) => {
        let sources = [];
        let targets = [];

        if(type && type!=='gl_account'){
            this.reportServices.getListReportColumns(type)
            .then(res => {
                if(res.length>0){
                    res.forEach(e => {
                        if(selectedColumns && selectedColumns.includes(e.value)){
                            targets.push(e);
                        }else{
                            sources.push(e);
                        }
                    });
                }
                this.setState({
                    rptSourceColumns: sources,
                    rptTargetColumns: targets
                });
            });
        }else{
            this.setState({
                rptSourceColumns: [],
                rptTargetColumns: []
            });
        }
    }

    rptColumnTemplate = (e) => {
        return (
            <div className="p-clearfix">
                <label>{e.label}</label>
            </div>
        );
    }

    onTypeChange = (e) => {
        this.setState({
            report: {
                ...this.state.report, 
                type: e.value
            },
            rptTargetColumns: []
        });

        if(e.value!=='gl_account')
            this.getListReportColumns(e.value);
        else
            this.setState({rptSourceColumns: []});
    }

    onProgramChange = (e) => {
        this.setState({
            report: {
                ...this.state.report,
                campaignId: '',
                refId: e.value
            }
        },() => {
            if(this.state.report.refId)
                this.loadAllCampaigns();
        });
    }

    onChangeCampaign = (e) => {
        this.setState({
            report: {
                ...this.state.report,
                campaignId: e.value,
                childRefIds: ''
            }
        },() => {
            if(this.state.report.refId){
                this.getListProgramCourse(this.state.report.refId, e.value);
            }
        });
        
    }

    onStartDateChange = (e) => {
        this.setState({
            report: {...this.state.report, startDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '')},
            startDateValue: e.value
        });
    }

    onEndDateChange = (e) => {
        this.setState({
            report: {...this.state.report, endDate: (e.value ? moment(e.value).format('YYYY-MM-DD HH:mm') : '')},
            endDateValue: e.value
        });
    }

    exportReport = () => {
        var report = {...this.state.report};
        var error = {};
        var hasError = false;
        let rptTargetColumns = [...this.state.rptTargetColumns];
        let rptSelectedColumns = [];

        rptTargetColumns.forEach(e => {
            rptSelectedColumns.push(e.value);
        });

        switch(report.type){
            case 'order':
                if(report.orderPaymentState.length===0){
                    hasError=true;
                    error.orderPaymentState = 'Please select at least one value!';
                }
                break;
            case 'transaction':
                if(report.paymentStatus.length===0){
                    hasError=true;
                    error.paymentStatus = 'Please select at least one value!';
                }
                break;
            case 'program_participant':
                if(!report.refId){
                    hasError=true;
                    error.refId = 'Please select value!';
                }
                    
                if(report.childRefIds.length===0){
                    hasError=true;
                    error.childRefIds = 'Please select at least one value!';
                }

                if(report.participantStatus.length===0){
                    hasError=true;
                    error.participantStatus = 'Please select at least one value!';
                }
                break;
            default:
                break;
        }

        if(report.type && report.type!=='gl_account' && this.state.rptTargetColumns.length===0){
            hasError = true;
            error.rptColumns = 'Please select at least one value!';
        }
            
        if(hasError){
            this.setState({error: error});
            showNotification('error', 'Error Message', 'Bad Request!');
        }else{
            var fileDownload = require('js-file-download');
            var suf = moment().format('YYYYMMDDHHmmss');
            var fileName = 'rpt_' + this.state.report.type + '_' + suf + '.xlsx';

            switch(report.type){
                case 'order': report.orderColumns = rptSelectedColumns; break;
                case 'transaction': report.transColumns = rptSelectedColumns; break;
                case 'program_participant': report.participantColumns = rptSelectedColumns; break;
                default: break;
            }

            this.reportServices.exportReport(report).then(res => {
                if(res && res.status===200){
                    showNotification('success', 'Success Message', 'Report was generated!');
                    this.setState({error: {}});
                    fileDownload(res.data, fileName);
                }else{
                    showNotification('error', 'Error Message', res?res.status.message:'Report generating fail! No result');
                }
            });
        }
    }

    loadListUsers = (searchTerm, callback) => {
        this.userServices.getAllUsers(searchTerm)
        .then(res => {
            callback (res) 
        });
    }

    render() { 
        const reportTypes = [
            // {label: 'GL Accounting', value: 'gl_account'},
            // {label: 'Transaction', value: 'transaction'},
            {label: 'Order', value: 'order'},
            {label: 'Program Participant', value: 'program_participant'},
        ];

        const lstPaymentStatus = [
            // {value: 'processing', label: 'Processing'},
            {value: 'completed', label: 'Completed'},
            {value: 'pending', label: 'Pending'},
            {value: 'cancel', label: 'Canceled'}
        ];

        const lstOrderPaymentStates = [
            {value: 'pending', label: 'Pending'},
            {value: 'awaiting', label: 'Awaiting Payment'},
            {value: 'completed', label: 'Completed'}
        ];

        const lstParticipantStatus = [
            {value: 'PENDING', label: 'Pending'},
            {value: 'PROCESSING', label: 'Processing'},
            {value: 'COMPLETED', label: 'Completed'},
            {value: 'CANCELED', label: 'Canceled'}
        ];

        const Option = props => SelectOption(props)

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            Report Generation
                        </h1>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-5">
                            <Fieldset legend="Report Type">
                                <div className="p-grid form-group">
                                    <div className="p-col-12">
                                        <Dropdown value={this.state.report.type} options={reportTypes} onChange={(e) => this.onTypeChange(e)} style={{width: '100%'}} placeholder="Select Report Type"/>
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                        {this.state.report.type?
                            <div className="p-col-12 p-md-7">
                                <Fieldset legend="Report Filters">
                                    <div className="p-grid p-fluid form-group">
                                        {this.state.report.type!=='program_participant'?
                                            <>
                                                <div className="p-col-12 p-md-6">
                                                    <label>From Date</label>
                                                    <Calendar dateFormat="mm-dd-yy" value={this.state.startDateValue} onChange={this.onStartDateChange} showIcon={true}/>
                                                    <span className="p-form-error">{this.state.error.startDate}</span>
                                                </div>,
                                                <div className="p-col-12 p-md-6">
                                                    <label>To Date</label>
                                                    <Calendar dateFormat="mm-dd-yy" value={this.state.endDateValue} onChange={this.onEndDateChange} showIcon={true}/>
                                                    <span className="p-form-error">{this.state.error.endDate}</span>
                                                </div>
                                            </>:''
                                        }
                                        {this.state.report.type==='transaction'?
                                            <>
                                                <div className="p-col-12">
                                                    <label className="p-label">Status</label>
                                                    <MultiSelect value={this.state.report.paymentStatus} options={lstPaymentStatus} onChange={(e) => this.setState({report: {...this.state.report, paymentStatus: e.value}})} style={{width: '100%'}}/>
                                                    <span className="p-form-error">{this.state.error.paymentStatus}</span>
                                                </div>
                                            </>:''
                                        }
                                        {this.state.report.type==='order'?
                                            <>
                                                <div className="p-col-12">
                                                    <label className="p-label">Payment State</label>
                                                    <MultiSelect value={this.state.report.orderPaymentState} options={lstOrderPaymentStates} onChange={(e) => this.setState({report: {...this.state.report, orderPaymentState: e.value}})} style={{width: '100%'}}/>
                                                    <span className="p-form-error">{this.state.error.orderPaymentState}</span>
                                                </div>,
                                                <div className="p-col-12">
                                                    <label className="p-label">Employee</label>
                                                    <AsyncSelect ref={el => this.select=el}
                                                        loadOptions={this.loadListUsers} 
                                                        onChange={(e) => this.setState({report: {...this.state.report, creatorId: e.value}})}
                                                        placeholder='Type your search'
                                                        components={{ Option }}
                                                        value={this.state.report.creatorIdValue}
                                                        isClearable
                                                    />
                                                </div>
                                            </>:''
                                        }
                                        {this.state.report.type==='program_participant'?
                                            <>
                                                <div className="p-col-12">
                                                    <label className="p-label">Program</label>
                                                    <Dropdown value={this.state.report.refId} options={this.state.programs} filter={true} filterBy='label,value' onChange={(e) => this.onProgramChange(e)} style={{width: '100%'}} placeholder="Please choose a program"/>
                                                    <span className="p-form-error">{this.state.error.refId}</span>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Campaign</label>
                                                    <Dropdown value={this.state.report.campaignId} options={this.state.campaigns} filter={true} filterBy='label,value' onChange={(e) => this.onChangeCampaign(e)} style={{width: "100%"}} />
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Course</label>
                                                    <MultiSelect value={this.state.report.childRefIds} options={this.state.lstCourses} filter={true} onChange={(e) => this.setState({report: {...this.state.report, childRefIds: e.value}})} style={{width: '100%'}}/>
                                                    <span className="p-form-error">{this.state.error.childRefIds}</span>
                                                </div>
                                                <div className="p-col-12">
                                                    <label className="p-label">Status</label>
                                                    <MultiSelect value={this.state.report.participantStatus} options={lstParticipantStatus} onChange={(e) => this.setState({report: {...this.state.report, participantStatus: e.value}})} style={{width: '100%'}}/>
                                                    <span className="p-form-error">{this.state.error.participantStatus}</span>
                                                </div>
                                            </>:''
                                        }
                                    </div>
                                </Fieldset>
                            </div>
                            :''
                        }
                        {this.state.report.type && this.state.report.type!=='gl_account'?
                            // <div className="p-col-12">
                            //     <Fieldset legend="Report columns">
                            //         <div className="p-grid p-fluid">
                            //             <div className="p-col-12">
                            //                 <MultiSelect style={{width: '100%'}} options={this.state.rptSourceColumns} value={this.state.rptTargetColumns} itemTemplate={this.rptColumnTemplate} 
                            //                 onChange={(e) => this.setState({rptTargetColumns: e.value})}/>
                            //                 <span className="p-form-error">{this.state.error.rptColumns}</span>
                            //             </div>
                            //         </div>
                            //     </Fieldset>
                            // </div>
                            <div className="p-col-12">
                                <Fieldset legend="Report columns">
                                    <div className="p-grid p-fluid">
                                        <div className="p-col-12">
                                            <div className="p-form-error">{this.state.error.rptColumns}</div>
                                                <PickList source={this.state.rptSourceColumns} target={this.state.rptTargetColumns} onChange={(e) => this.setState({rptSourceColumns: e.source, rptTargetColumns: e.target})} itemTemplate={this.rptColumnTemplate}
                                                    sourceHeader="Available" targetHeader="Selected" responsive={true}
                                                    sourceStyle={{height: '500px'}} targetStyle={{height: '500px'}}
                                                />
                                            <div className="p-form-error">{this.state.error.rptColumns}</div>
                                        </div>
                                        
                                    </div>
                                </Fieldset>
                            </div>
                            :''
                        }
                        <div className="p-col-12">
                            <Button disabled={this.state.report.type || hasRole([ROLES_CONFIG.REPORT_R]) ?false:true} label="Export" icon="pi pi-save" iconPos="left" onClick={() => this.exportReport()} className="p-button-success"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}