import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Fieldset } from "primereact/fieldset";
import React, { useEffect, useRef, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch } from "react-router-dom";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { DeleteNotification } from "../../core/components/DeleteNotification";
import { getTablePageReport } from "../../core/service/CommonService";
import { showNotification, showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { deleteDocument, getPageDocument, updatePublicDocument, uploadDocument } from "./DocumentServices";
import { showloading, stoploading } from "../../core/service/LoadingService";
import { MAX_FILE_SIZE } from "../../constants";

export const DocumentListView = () =>{
    const match = useRouteMatch()

    const deleteNotify = useRef(null)
    const inputUpload = useRef(null)

    const [dataList, setDataList] = useState([])
    const [pageable, setPageable] = useState({        
        page: 0,
        rows: 10,
        total: 0,
        sortField: 'id',
        sortOrder: -1
    })
    
    const [isLoadTable, setLoadTable] = useState(true);
    const [uploadData, setUploadData] = useState({});
    const [errors, setErrors] = useState({});

    useEffect(()=>{
        if(isLoadTable)
            loadPageDocument()
    },[isLoadTable])

    const handleUpload = () => {
        setErrors({})
        if(uploadData.file){
            let fileSize = uploadData.file.size
            if(fileSize > MAX_FILE_SIZE){
                setErrors({file: 'Size not allowed (maximum: 100MB)'})
                return
            }
        } 
        
        uploadDocument(uploadData)
        .then(res => {
            if(!res.errorCode){
                loadPageDocument()
                setUploadData({file: null})
                inputUpload.current.value=''
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
                if(res.errorCode===400) setErrors(res.errorObj)
            }
        })
    }

    const handleDelete = (e) =>{
        showloading()
        deleteDocument(e)
        .then(res => {
            if(!res.errorCode){
                loadPageDocument()
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
            stoploading()
        });
    }

    const loadPageDocument = () => {
        getPageDocument(pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            setDataList(res.content)
            setPageable({...pageable,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
            setLoadTable(false)
        })
    };

    const onPage = (e) =>{
        setPageable({...pageable,
            page: e.page
        })
        setLoadTable(true);
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true);
    };

    const changeStatusPublic = (id, e) => {
        updatePublicDocument(id, e.value)
        .then(res => {
            if(!res.errorCode){
                loadPageDocument()
                showSuccessNotify('Action submitted')
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


    const actionTemplate = (rowData) => {
        return (
            <div>
                <Button tooltip="Copy Url" tooltipOptions={{position: "top"}} icon="pi pi-copy" className="p-button-warning" onClick={() => navigator.clipboard.writeText(rowData.url)}/>
                <Button tooltip="Delete Document" tooltipOptions={{position: "top"}} icon="pi-md-close" className="p-button-danger" onClick={() => deleteNotify.current.delete(rowData.id)} />
            </div>
        );
    }

    const tblHeader = (
        <TableHeader title="Documents" 
            // actionTemplate={<Button disabled={!hasRole([ROLES_CONFIG.ASSET_W])} label="Create Document" className="p-button-constrast" icon="pi pi-plus" style={{'float':'left'}} onClick={(e) => documentForm.current.actionForm()}/>}
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
            />
    );

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Documents</BreadcrumbsItem>

            <DeleteNotification ref={deleteNotify}
                accessDelete={(e) => handleDelete(e)}
            />

            <div className="p-col-12">
                <Fieldset legend="Upload Action">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-6">
                            <div className="p-inputgroup">
                                <input ref={inputUpload} className="p-component p-inputtext" type="file" onChange={(e) => setUploadData({...uploadData, file: e.target.files[0]})} />
                                <Button label="Upload" icon="pi pi-upload" onClick={() => handleUpload()}/>
                            </div>
                            <div className="p-form-error">{errors.file}</div>
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div className="p-col-12">
                <DataTable lazy={true} paginator={true} value={dataList}
                    header={tblHeader}
                    first={pageable.page * pageable.rows}
                    rows={pageable.rows} totalRecords={pageable.total}
                    onPage={onPage}  onSort={onSort}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    selectionMode="single" responsive={true}
                >
                    <Column field="id" header="ID" sortable={true} headerStyle={{width: '10em'}} />
                    <Column field="name" header="File Name" sortable={true} />
                    <Column field="size" header="File Size" sortable={true} body={rowData => formatBytes(rowData.size)} />
                    <Column field="created" header="Upload on" sortable={true} body={rowData => rowData.created && moment(rowData.created).format('MM/DD/YYYY')} />
                    {/* <Column header="Public" body={actionTemplagePublic} /> */}
                    <Column header="Url" body={rowData => rowData.url} />
                    <Column body={actionTemplate} style={{textAlign:'center',width:'12em'}} />
                </DataTable>
            </div>
        </div>
    );
}
