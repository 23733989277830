import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../../constants';

const SCP_BASE_URL = API_BASE_URL + SCP_PATH;

export function getPageMenus(page, size, sortField, sortOrder) {
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${SCP_BASE_URL}/menus/page`, {
        params: {
        page: page,
        size: size,
        sort: sort
        }
    }).then(res => res.data).catch(error => console.log(error));
}

export function getMenus(simple){
    return axios.get(`${SCP_BASE_URL}/menus/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}

export function saveMenu(data){
    return axios.post(`${SCP_BASE_URL}/menus`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function removeMenu(id){
    return axios.delete(`${SCP_BASE_URL}/menus/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function saveMenuItem(menuId, data){
    return axios.post(`${SCP_BASE_URL}/menus/${menuId}/items`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function getMenuItems(menuId, simple){
    return axios.get(`${SCP_BASE_URL}/menus/${menuId}/items`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}

export function removeMenuItem(menuId, id){
    return axios.delete(`${SCP_BASE_URL}/menus/${menuId}/items/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}
