import React, {Component} from 'react';
import { ParticipantServices } from '../../constituent-management/participant/ParticipantServices';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showNotification } from '../../core/service/NotificationService';
import { ProgramServices } from './ProgramServices';
import { CampaignServices } from '../../scp/campaign/CampaignServices';
import { Sidebar } from 'primereact/sidebar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export class TransferParticipantForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            participant: {},
            programId: '',
            campaignId: '',
            courseId: '',
            formHeader: 'Transfer Participant',
            errors: {}
        }
        this.participantServices = new ParticipantServices();
        this.programServices = new ProgramServices();
        this.campaignServices = new CampaignServices();
    }

    action = (participant) => {
        this.setState({
            participant: participant,
            programId: '',
            courseId: '',
            visible: true,
            errors: {}
        })
    }

    componentDidMount(){
        this.loadAllPrograms();
        this.loadAllCampaigns();
    }

    handleSave = () => {
        this.setState({ 
            confirmVisible: false,
            errors: {} 
        });
        showloading();
        this.participantServices.transferParticipant(this.state.participant.id, this.state.programId, this.state.courseId)
        .then(res => {
            stoploading();
            if(!res.errorCode){
                this.props.refreshTable();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        })
    }

    loadAllPrograms = () => {
        this.programServices.getListAllPrograms()
        .then(res => {
            this.setState({programs: res ? res : []})
        });
    }

    loadAllCampaigns = () => {
        this.campaignServices.getListCampaign().then(res => {this.setState({campaigns: res ? res : []})});
    }

    loadAllCoursesByProgram = () => {
        this.programServices.getListProgramCourses(this.state.programId, this.state.campaignId)
        .then(res =>{
            let tempArr = res ? res : [];
            tempArr = tempArr.filter(part => part.value !== this.state.participant.childRefId);
            this.setState({courses: tempArr});
        });
    }

    onChangeProgram = (e) => {
        this.setState({
            programId: e.value
        },() => {
            if(this.state.programId)
                this.loadAllCoursesByProgram();
        })
    }

    onChangeCampaign = (e) => {
        this.setState({
            campaignId: e.value,
            courseId: ''
        },() => {
            if(this.state.programId)
                this.loadAllCoursesByProgram();
        })
    }

    onHide = () => {
        this.setState({ visible: false })
    }

    render(){
        return(
            <React.Fragment>
                <Dialog visible={this.state.confirmVisible} contentStyle={styles.dialogContent} responsive={true}
                        modal={true} dismissableMask={true} showHeader={false} onHide={(e) => this.setState({ confirmVisible: false })}
                        footer={<React.Fragment>
                            <Button label="Submit" icon="pi-md-check" onClick={(e) => this.handleSave()} />
                            <Button label="Cancel" className="p-button-secondary" onClick={(e) => this.setState({ confirmVisible: false })} />
                        </React.Fragment>}
                    >
                        Are you sure you want to transfer participant!
                    </Dialog>
                <Sidebar visible={this.state.visible} dismissable={false} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.onHide}>
                    <h2>{this.state.formHeader}</h2>

                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-12">
                            <label className="p-label">Name: {this.state.participant.name}</label>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">* To Program</label>
                            <Dropdown value={this.state.programId} options={this.state.programs} onChange={(e) => this.onChangeProgram(e)} style={{width: "100%"}} />
                            <div className="p-form-error">{this.state.errors.program}</div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Campaign</label>
                            <Dropdown value={this.state.campaignId} options={this.state.campaigns} onChange={(e) => this.onChangeCampaign(e)} style={{width: "100%"}} />
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">* To Course</label>
                            <Dropdown value={this.state.courseId} options={this.state.courses} onChange={(e) => this.setState({courseId: e.value})} style={{width: "100%"}} />
                            <div className="p-form-error">{this.state.errors.course}</div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            <Button label="Submit" icon="pi pi-check" onClick={(e) => this.setState({ confirmVisible: true})}/>
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                        </div>
                    </div>
                </Sidebar>
            </React.Fragment>
        )
    }
}

const styles = {
    dialogContent: {
        maxHeight: '50px',
        minHeight: '50px',
        overflow: 'hidden',
    }
}