import { Button } from 'primereact/button';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { SplitButton } from 'primereact/splitbutton';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { getTablePageReport } from '../../core/service/CommonService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from '../../core/service/NotificationService';
import ApplicationForm from './ApplicationForm';
import { deleteApplication, getPageApplication } from './ApplicationService';

export default class ApplicationListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            dataList: [],
            index: 0,
            page: 0,
            rows: 10,
            total: 0,
            sortField: '',
            sortOrder: -1,
            activeIndex: 0,
            filter: {}
        };
    }

    componentDidMount() {
        showloading();
        this.loadPageApplications();
    }

    loadPageApplications = () => {
        getPageApplication(this.state.filter, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
        .then(res => {
            stoploading();

            this.setState({
                dataList: res.content,
                total: res.totalElements,
                loading: false,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            });
        })
    }

    onChangePageLength = (e) => {
        this.setState({
            page: 0,
            rows: e
        }, () => this.loadPageApplications())
    }

    actionTemplate = (rowData) => {
        let dropActions = [
            // { label: 'View Records', icon: 'pi-md-search',
            //     command: (e) => this.props.history.push({pathname: `/applications/records`, state: {applicationId: rowData.id}})
            // },
            { label: 'Remove', icon: 'pi pi-trash', command: (e) => this.removeApplication(rowData.id) }
        ];

        return (
            <SplitButton label="Edit" icon="pi pi-pencil" className="p-button-constrast p-l" model={dropActions} onClick={() => this.onAddOrEdit(rowData)}/>
        );
    };

    onAddOrEdit = (data) => {
        this.applicationForm.popularFormData(data);
    }

    removeApplication = (id) => {
        showConfirmNotify({
            accept: () => this.handleDeleteApplication(id)
        })
    }

    handleDeleteApplication = (id) => {
        deleteApplication(id)
        .then(data => {
            if(!data.errorCode) {
                showSuccessNotify("Action Submitted");
                this.loadPageApplications();
            } else {
                showErrorNotify(data.errorMessage);
            }
        })
    }
    
    onKeyPressEnter = (e) => {
        if(e.key === 'Enter'){
            this.loadPageApplications()
        }
    }

    onPage = (e) =>{
        this.setState({
            page: e.page
        }, () => this.loadPageApplications())

    }

    onSort = (e) => {
        this.setState({
            sortField: e.sortField,
            sortOrder: e.sortOrder
        }, () => this.loadPageApplications())
    }

    render() {
        let tblHeader = (
            <TableHeader title="Applications" 
                actionTemplate={<Button icon="pi pi-plus" label={"Create new application"} className="p-button-constrast" style={{ float: "left" }} onClick={(e) => this.onAddOrEdit()} />}
                pageReport={getTablePageReport(this.state.page, this.state.rows, this.state.total)}
                changePageLength={(e) => this.onChangePageLength(e)}
                refresh={() => this.loadPageApplications()}
            />
        );
        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Application</BreadcrumbsItem>

                <ApplicationForm ref={el => this.applicationForm = el}
                    reloadTable={() => this.loadPageApplications()}
                />
                
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1 className="p-w-bold p-m-0">Application Forms</h1>
                    </div>

                    <DataTable  lazy={true} header={tblHeader} paginator={true}
                        value={this.state.dataList}
                        first={this.state.page * this.state.rows}
                        onPage={this.onPage} onSort={this.onSort}
                        rows={this.state.rows} totalRecords={this.state.total}
                        sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                        responsive={true}
                    >
                        <Column field="name" header="Name" />
                        <Column field="alias" header="Alias" />
                        <Column field="weight" header="Weight" sortable />
                        {/* <Column field="type" header="Type" body={(rowData) => {return( rowData.type === APPLICATION_TYPE.sea_log.value ? APPLICATION_TYPE.sea_log.label : rowData.type)}} /> */}
                        <Column header="Actions" body={this.actionTemplate} className='tc-actions' style={{ textAlign: 'center', width: '12em', overlay: 'visible' }} />
                    </DataTable>
                    
                </div>
            </div>
        );
    }
}
