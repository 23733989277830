import axios from "axios";
import { MAIL_BASE_URL, MAIL_PATH } from "../../constants";

const MAIL_SERVICE_URL = MAIL_BASE_URL + MAIL_PATH;
export class MailAccountsService {

    getMailAccounts(page, size, sortField, sortOrder) {
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${MAIL_SERVICE_URL}/mail-account`, {
            params: {
                page,
                size,
                sort
            }
        }).then(res => res.data).catch(error => console.log(error));
    }

    getMailAccountById(id) {
        return axios.get(`${MAIL_SERVICE_URL}/mail-account/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }

    saveMailAccount(data) {
        return axios.post(`${MAIL_SERVICE_URL}/mail-account/mail`, data)
            .then(res => res.data).catch(error => console.log(error));
    }

    deleteMailAccount(id) {
        return axios.delete(`${MAIL_SERVICE_URL}/mail-account/${id}`)
            .then(res => res.data).catch(error => console.log(error));
    }

    changeMailAccount(id, data) {
        return axios.put(`${MAIL_SERVICE_URL}/mail-account/mail/${id}`, data)
            .then(res => res.data).catch(error => console.log(error));
    }

    getSimpleMailAccounts() {
        return axios.get(`${MAIL_SERVICE_URL}/mail-account/list`)
            .then(res => res.data).catch(error => console.log(error));
    }
}

export function getPageMailAccounts(page, size, sortField, sortOrder) {
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.get(`${MAIL_SERVICE_URL}/mail-account`, {
        params: {page, size,sort}
    }).then(res => res.data).catch(error => console.log(error));
}

export function saveMailAccount(data) {
    return axios.post(`${MAIL_SERVICE_URL}/mail-account/mail`, data)
        .then(res => res.data).catch(error => console.log(error));
}

export function updateMailAccount(id, data) {
    return axios.put(`${MAIL_SERVICE_URL}/mail-account/mail/${id}`, data)
        .then(res => res.data).catch(error => console.log(error));
}

export function removeMailAccount(id) {
    return axios.delete(`${MAIL_SERVICE_URL}/mail-account/${id}`)
        .then(res => res.data).catch(error => console.log(error));
}