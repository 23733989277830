import axios from 'axios';
import { SCP_PATH, API_BASE_URL } from '../../constants';
import querystring from "querystring";

export class ConnectedAppServices {
    getPageAppToken(page, size, sortField, sortOrder){
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}${SCP_PATH}/app-token/pages`,{
            params: {page, size, sort}
        }).then(res => {
            return res.data
        })
        .catch(error => console.log(error));
    }

    saveAppToken(data){
        return axios.post(`${API_BASE_URL}${SCP_PATH}/app-token/save-token`, data)
            .then(res => res.data)
            .catch(error => console.log(error));
    }
    
    deleteAppToken(apiKey){
        return axios.post(`${API_BASE_URL}${SCP_PATH}/app-token/delete`, 
            querystring.stringify({
                apiKey: apiKey
            }))
            .then(res => res.data)
            .catch(error => console.log(error));
    }
}