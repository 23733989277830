import axios from "axios";
import { PIM_BASE_URL, PIM_PATH } from "../../constants";

const PIM_SCHEDULE_URL = PIM_BASE_URL + PIM_PATH + "/asset-schedules";

export function getPageSchedules(filter, page, size, sortField, sortOrder) {
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.post(`${PIM_SCHEDULE_URL}/page`, filter, {
        params: {page, size, sort }
    }).then(res => res.data).catch(error => console.log(error));
}

export function getScheduleList(filter){
    return axios.post(`${PIM_SCHEDULE_URL}/list`, filter)
    .then(res => res.data).catch(error => console.log(error));
}

export function getSchedule(id) {
    return axios.get(`${PIM_SCHEDULE_URL}/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function saveSchedule(data) {
    return axios.post(`${PIM_SCHEDULE_URL}`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function deleteSchedule(aId, scheduleId) {
    return axios.delete(`${PIM_SCHEDULE_URL}/${aId}/schedules/${scheduleId}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function pickupSchedule(scheduleId) {
    return axios.post(`${PIM_SCHEDULE_URL}/${scheduleId}/pick-up`)
    .then(res => res.data).catch(error => console.log(error));
}

export function closeSchedule(scheduleId){
    return axios.post(`${PIM_SCHEDULE_URL}/${scheduleId}/close`)
    .then(res => res.data).catch(error => console.log(error));
}

export function returnAsset(data) {
    return axios.post(`${PIM_SCHEDULE_URL}/return-asset`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function checkinAsset(id, checkin) {
    return axios.post(`${PIM_SCHEDULE_URL}/${id}/checkin`, {}, {params: {checkin}})
    .then(res => res.data).catch(error => console.log(error));
}

export function exportAssetSchedule(filter){
    return axios.post(`${PIM_SCHEDULE_URL}/export`, filter, {responseType: 'arraybuffer'})
    .then(res => res.data).catch(error => console.log(error));
}
