import axios from "axios";
import { API_BASE_URL, SCP_PATH} from "../constants";

export class WebErrorServices {
    getPageWebErrors(page, size, sortField, sortOrder) {
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = sortField ? `${sortField},${order}` : '';
        return axios.get(`${API_BASE_URL}${SCP_PATH}/web-errors/page`, {
            params: { page, size, sort }
        }).then(res => {return res.data}).catch(error => console.log(error));
    }

    getWebError(id) {
        return axios.get(`${API_BASE_URL}${SCP_PATH}/web-errors/${id}`)
        .then(res => {return res.data}).catch(error => console.log(error));
    }

    removeAll() {
        return axios.delete(`${API_BASE_URL}${SCP_PATH}/web-errors/remove_all`)
        .then(res => {return res.data}).catch(error => console.log(error));
    }
}