import React from "react";
import ListUser from "../../core/components/ListUser";
import { getPageUsers } from "../contact/UserServices";

const FAKE_DB = [
  {
    id: 1,
    user: {
      email: "example@gmail.com",
      enabled: true,
      firstName: "Michelle",
      id: 1,
      lastName: "Kim",
      name: "string",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 1,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 2,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 3,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
    duration: 1,
    unit: "year",
    orderId: 1,
    membershipStatus: false,
    membershipPaymentStatus: "pending",
    membershipCardIssued: false,
    verified: false,
  },
  {
    id: 2,
    user: {
      email: "example@gmail.com",
      enabled: true,
      firstName: "Matt",
      id: 2,
      lastName: "Kang",
      name: "string",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 4,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 5,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 6,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
    duration: 1,
    unit: "year",
    orderId: 1,
    membershipStatus: true,
    membershipPaymentStatus: "pending",
    membershipCardIssued: false,
    verified: false,
  },
  {
    id: 3,
    user: {
      email: "example@gmail.com",
      enabled: true,
      firstName: "Jason",
      id: 1,
      lastName: "Price",
      name: "string",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 7,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 8,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 9,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
    duration: 1,
    unit: "year",
    orderId: 3,
    membershipStatus: true,
    membershipPaymentStatus: "pending",
    membershipCardIssued: false,
    verified: false,
  },
];

export const MemberListView = (props) => {
  return (
    <ListUser
      pageName="Members"
      data={FAKE_DB}
      isMembers={true}
      getDataService={getPageUsers}
    />
  );
};
