import axios from 'axios';
import { PIM_BASE_URL, PIM_PATH } from '../../constants';
import { converObjetToFormData } from '../../core/service/CommonService';
const configFormDataHeader = {
    headers: { 'Content-Type': 'multipart/form-data' }
}

const PIM_TAXONS_URL = PIM_BASE_URL + PIM_PATH + '/taxonomies';

export function getPageTaxons(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = sortField ? `${sortField},${order}` : '';
    return axios.post(`${PIM_TAXONS_URL}/page`, filter, {
        params: {page, size, sort}
    }).then(res => {
        return res.data
    }).catch(error => console.log(error));
}

export function getListTaxons(filter, simple=true){
    return axios.post(`${PIM_TAXONS_URL}/list`, filter, {
        params: {simple}
    }).then(res => res.data).catch(error => console.log(error));
}

export function saveTaxon(data){
    let formData = new FormData()
    for (var key in data) {
        converObjetToFormData(formData, key, data[key])
    }
    
    return axios.post(`${PIM_TAXONS_URL}`, formData, configFormDataHeader).then(res => res.data).catch(error => console.log(error));
}

export function getTreeTaxon(filter) {
    return axios.post(`${PIM_TAXONS_URL}/tree`, filter)
    .then(res => res.data).catch(error => console.log(error));
}

export function getTaxon(id){
    return axios.get(`${PIM_TAXONS_URL}/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function removeTaxon(id){
    return axios.delete(`${PIM_TAXONS_URL}/${id}`).then(res => res.data).catch(error => console.log(error));
}