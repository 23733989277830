import React from "react";
import { components } from 'react-select';
import Avatar from 'react-avatar';
import { USER_RELATIONSHIP_TYPES } from "../../../constants";

export function ContactSelectOption(props){
    return (
        <components.Option {...props} >
            <div className="p-d-flex p-ai-center" style={{minHeight: '40px'}}>
                {props.data.avatar ? 
                    <img src={props.data.avatar} width={40}/>
                    : <Avatar maxInitials={2} round={true} textSizeRatio={2} size={40} name={props.data.name}/>
                }
                <div className="p-padding-left-10">
                    {props.data.label && <div className="p-padding-bottom-5 p-w-bold">{props.data.label}</div>}
                    {props.data.email && <div className="p-padding-bottom-5">{props.data.email}</div>}
                    {props.data.phone && <div className="p-padding-bottom-5">{props.data.phone}</div>}
                    {props.data.relation && <div>{USER_RELATIONSHIP_TYPES.find(relation => relation.value === props.data.relation).label}</div>}
                </div>
            </div>
        </components.Option>
    )
}