import React, { forwardRef, useImperativeHandle, useState } from 'react';
import classNames from 'classnames';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { saveLiveAccount, } from './LiveAccountService';
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { Checkbox } from 'primereact/checkbox';
import {Fieldset} from 'primereact/fieldset';
import {InputTextarea} from 'primereact/inputtextarea';
import { LIVE_ACCOUNT_TYPE } from '../../constants';
import { InputSwitch } from 'primereact/inputswitch';
import { showloading, stoploading } from '../../core/service/LoadingService';

export const LiveAccountForm = forwardRef((props, ref) => {

    const [header, setHeader] = useState("Event Live Account")
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({zoomConfig:{}})
    const [seeSecretKey, setSeeSecretKey] = useState(false)
    const [errors, setErrors] = useState({})

    useImperativeHandle(ref, () => ({
        openForm(e){
            setHeader((e?'Edit Event Live Account':'New Event Live Account'))
            setVisible(true)
            setFrmData(popularFormData(e))
            setSeeSecretKey(false)
        },
        closeForm(){
            closeForm()
        }
    }))

    const popularFormData = (data) => {
        return {
            id: data&&data.id ? data.id : null,
            name: data&&data.name ? data.name : '',
            enabled: data&&data.enabled ? true : false,
            type: data&&data.type ? data.type : LIVE_ACCOUNT_TYPE.zoom.value,
            zoomConfig: data&&data.zoomConfig ? data.zoomConfig : {email: '', apiKey: '', apiSecret: '', accessToken: '', leaveUrl: ''}
        }
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({zoomConfig:{}})
        setErrors({})
    }

    const handleSave = () => {
        setErrors({})

        showloading()

        saveLiveAccount(frmData)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable)
                    props.refreshTable()

                closeForm()
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }

            stoploading()
        });
    }

    return (
        <Sidebar visible={visible} className="p-sidebar-md" position="right" onHide={() => closeForm()} blockScroll={true} showCloseIcon={false} style={{overflowY: 'auto'}}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <Fieldset legend="Information">
                        <div className="p-col-12">
                            <label>Type: <strong>{LIVE_ACCOUNT_TYPE.zoom.label}</strong></label>
                            {/* <Dropdown options={Object.values(LIVE_ACCOUNT_TYPE)} value={frmData.type} onChange={(e) => setFrmData({...frmData, type: e.value})} /> */}
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">* Name</label>
                            <InputText value={frmData.name} onChange={(e) => setFrmData({...frmData, name: e.target.value})} />
                            <div className="p-form-error">{errors.name}</div>
                        </div>
                        <div className="p-col-12">
                            <InputSwitch checked={frmData.enabled} onChange={(e) => setFrmData({...frmData, enabled: e.value })} />
                            <label className="p-margin-left-10">Active?</label>
                        </div>
                    </Fieldset>
                </div>
                
                <div className="p-col-12">
                    <Fieldset legend="Account Config">
                        <div className="p-col-12">
                            <label className="p-label">Account Email</label>
                            <InputText className="p-col-11" value={frmData.zoomConfig?frmData.zoomConfig.email:''} onChange={(e) => setFrmData( {...frmData, zoomConfig: {...frmData.zoomConfig, email: e.target.value}})} />
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">API Key</label>
                            <InputText className="p-col-11" value={frmData.zoomConfig?frmData.zoomConfig.apiKey:''} onChange={(e) => setFrmData({...frmData, zoomConfig: {...frmData.zoomConfig, apiKey: e.target.value}})} />
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">API Secret</label>
                            <div className="p-inputgroup">
                                <InputText type={!seeSecretKey ? 'password' : 'text'} className="p-col-11" value={frmData.zoomConfig.apiSecret} onChange={(e) => setFrmData({...frmData, zoomConfig: {...frmData.zoomConfig, apiSecret: e.target.value}})} />
                                <span className="p-inputgroup-addon">
                                    <i style={{fontSize:22, cursor: 'pointer'}} className="pi pi-eye" onClick={() => setSeeSecretKey(!seeSecretKey)}/>
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Access Token</label>
                            <InputTextarea className="p-col-11" value={frmData.zoomConfig?frmData.zoomConfig.accessToken:''} onChange={(e) => setFrmData({...frmData, zoomConfig: {...frmData.zoomConfig, accessToken: e.target.value}})} rows={4} />
                        </div>
                        {/* <div className="p-col-12">
                            <label className="p-label">Leave URL</label>
                            <InputText className="p-col-11" value={frmData.zoomConfig?frmData.zoomConfig.leaveUrl:''} onChange={(e) => setFrmData({...frmData, zoomConfig: {...frmData.zoomConfig, leaveUrl: e.target.value}}) } />
                        </div> */}
                    </Fieldset>
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={() => closeForm()}/>
                    <Button label="Submit" icon="pi pi-check" onClick={(e) => handleSave()}/>
                </div>
            </div>
        </Sidebar>
    );
})
