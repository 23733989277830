import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { getMailTemplates } from '../../mail-management/mail-template/MailTemplateServices';
import { Chips } from 'primereact/chips';
import { isValidEmail } from '../../core/utils/ValidateUtils';
import { showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { saveScpConfig } from './ConfigruationServices';
import { Checkbox } from 'primereact/checkbox';

export const TenantNotification = (props) => {

    const [data, setData] = useState(props.data?props.data:{})
    const [mailTemplates, setMailTempaltes] = useState([])
    const [orderMailTemplates, setOrderMailTempaltes] = useState([])
    const [invoiceMailTemplates, setInvoiceMailTempaltes] = useState([])
    const [waitlistMailTemplates, setWaitListceMailTempaltes] = useState([])
    const [productSessionCancelMailTemplates, setProductSessionCancelMailTempaltes] = useState([])
    const [userBlokingMailTemplates, setUserBlockingMailTempaltes] = useState([])
    const [errors, setErrors] = useState({})

    useEffect(()=>{
        loadMailTemplates()
    },[])
    
    const handleSubmit = ()=>{
        saveScpConfig({notificationConfig: data}, 'notification')
        .then((res) => {
            if(!res.errorCode){
                props.onSave(res.pageConfig);
                showSuccessNotify('Action Submitted');
            }else{
                if(res.errorCode === 400) setErrors(res.errorObj)
                showErrorNotify(res.errorMessage);
            }
        })
        .catch(e => showErrorNotify('System error!'))
    }

    const loadMailTemplates = () => {
        getMailTemplates()
        .then(res => {
            let dataTemplates = res?res:[];
            let tmpOrderMailTemplates = dataTemplates.filter(m => m.useFor === 'order');
            let tmpInvoiceMailTemplates = dataTemplates.filter(m => m.useFor === 'invoice');
            let tmpWaitlistMailTemplates = dataTemplates.filter(m => m.useFor === 'wait_list');
            let tmpProductSessionCancelMailTemplates = dataTemplates.filter(m => m.useFor === 'cancel_product_session');
            let tmpUserBlockingMailTemplates = dataTemplates.filter(m => m.useFor === 'block_user');

            tmpOrderMailTemplates.unshift({value: '', label: 'Not use'});
            tmpInvoiceMailTemplates.unshift({value: '', label: 'Not use'});

            setMailTempaltes(dataTemplates)
            setOrderMailTempaltes(tmpOrderMailTemplates)
            setInvoiceMailTempaltes(tmpInvoiceMailTemplates)
            setWaitListceMailTempaltes(tmpWaitlistMailTemplates)
            setProductSessionCancelMailTempaltes(tmpProductSessionCancelMailTemplates)
            setUserBlockingMailTempaltes(tmpUserBlockingMailTemplates)
        })
    }

    const onAddEmailInput = (property, e) => {
        let tmpArr = data[property]?data[property]:[]

        let isValueValid = isValidEmail(e.value)
        if(!isValueValid){
            setErrors({[property]: 'wrong email format'})
            return
        }
        if(tmpArr.includes(e.value)){
            setErrors({[property]: 'Value is exist'})
            return
        }
        tmpArr.push(e.value)
        setData({...data, [property]: tmpArr})
        setErrors({...errors, [property]: ''})
    }

    const onRemoveInputEmail = (property, e) => {
        let tmpArr = data[property]?data[property]:[]
        let tmpValue = e.value[0]
        tmpArr.splice(tmpArr.indexOf(tmpValue), 1)
        setData({...data, [property]: tmpArr})
    }


    return(
        <div className="p-grid">
            <div className="p-col-12 p-md-6">
                <Fieldset legend="Customer Setting">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <label className="p-label">Order Confirmation Mail Template</label>
                            <div className="p-inputgroup">
                                <Dropdown value={data.mailTemplateOrderComplete} options={orderMailTemplates} onChange={e => setData({...data, mailTemplateOrderComplete: e.value})} />
                                <span className="p-inputgroup-addon">
                                    <Checkbox inputId="ckUseTemplateOrderComplete" checked={data.useMailTemplateOrderComplete} onChange={e => setData({...data, useMailTemplateOrderComplete: e.checked})} />
                                    <label htmlFor="ckUseTemplateOrderComplete">Use?</label>
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Receipt Mail Template</label>
                            <div className="p-inputgroup">
                                <Dropdown value={data.mailTemplateInvoiceComplete} options={invoiceMailTemplates} onChange={e => setData({...data, mailTemplateInvoiceComplete: e.value})} />
                                <span className="p-inputgroup-addon">
                                    <Checkbox inputId="ckUseTemplateInvoiceComplete" checked={data.useMailTemplateInvoiceComplete} onChange={e => setData({...data, useMailTemplateInvoiceComplete: e.checked})} />
                                    <label htmlFor="ckUseTemplateInvoiceComplete">Use?</label>
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Invoice Mail Template</label>
                            <div className="p-inputgroup">
                                <Dropdown value={data.mailTemplateInvoice} options={invoiceMailTemplates} onChange={e => setData({...data, mailTemplateInvoice: e.value})} />
                                <span className="p-inputgroup-addon">
                                    <Checkbox inputId="ckUseTemplateInvoice" checked={data.useMailTemplateInvoice} onChange={e => setData({...data, useMailTemplateInvoice: e.checked})} />
                                    <label htmlFor="ckUseTemplateInvoice">Use?</label>
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Subscription Mail Template</label>
                            <div className="p-inputgroup">
                                <Dropdown value={data.mailTemplateSubscription} options={mailTemplates} onChange={e => setData({...data, mailTemplateSubscription: e.value})} />
                                <span className="p-inputgroup-addon">
                                    <Checkbox inputId="ckUseTemplateSubscription" checked={data.useMailTemplateSubscription} onChange={e => setData({...data, useMailTemplateSubscription: e.checked})} />
                                    <label htmlFor="ckUseTemplateSubscription">Use?</label>
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Subscription Renewal Mail Template</label>
                            <div className="p-inputgroup">
                                <Dropdown value={data.mailTemplateSubscriptionRenew} options={mailTemplates} onChange={e => setData({...data, mailTemplateSubscriptionRenew: e.value})} />
                                <span className="p-inputgroup-addon">
                                    <Checkbox inputId="ckUseTemplateRenewSubscription" checked={data.useMailTemplateSubscriptionRenew} onChange={e => setData({...data, useMailTemplateSubscriptionRenew: e.checked})} />
                                    <label htmlFor="ckUseTemplateRenewSubscription">Use?</label>
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Wait list Mail Template (on Activity Cancelled)</label>
                            <div className="p-inputgroup">
                                <Dropdown value={data.mailTemplateWaitList} options={waitlistMailTemplates} onChange={e => setData({...data, mailTemplateWaitList: e.value})} />
                                <span className="p-inputgroup-addon">
                                    <Checkbox inputId="ckUseMailTemplateWaitList" checked={data.useMailTemplateWaitList} onChange={e => setData({...data, useMailTemplateWaitList: e.checked})} />
                                    <label htmlFor="ckUseMailTemplateWaitList">Use?</label>
                                </span>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <label className="p-label">Product Session Cancellation Mail Template (to Participation)</label>
                            <div className="p-inputgroup">
                                <Dropdown value={data.mailTemplateCancelProductSession} options={productSessionCancelMailTemplates} onChange={e => setData({...data, mailTemplateCancelProductSession: e.value})} />
                                <span className="p-inputgroup-addon">
                                    <Checkbox inputId="ckUseMailTemplateCancelProductSession" checked={data.useMailTemplateCancelProductSession} onChange={e => setData({...data, useMailTemplateCancelProductSession: e.checked})} />
                                    <label htmlFor="ckUseMailTemplateCancelProductSession">Use?</label>
                                </span>
                            </div>
                        </div>

                        <div className="p-col-12"><hr/></div>

                        <div className="p-col-12">
                            <label className="p-label">User Blocking Mail Template</label>
                            <div className="p-inputgroup">
                                <Dropdown value={data.mailTemplateBlockUser} options={userBlokingMailTemplates} onChange={e => setData({...data, mailTemplateBlockUser: e.value})} />
                                <span className="p-inputgroup-addon">
                                    <Checkbox inputId="ckUseMailTemplateCancelProductSession" checked={data.useMailTemplateBlockUser} onChange={e => setData({...data, useMailTemplateBlockUser: e.checked})} />
                                    <label htmlFor="ckUseMailTemplateCancelProductSession">Use?</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div className="p-col-12 p-md-6">
                <Fieldset legend="Staff Setting">
                    <div className="p-grid p-fluid">
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <label className="p-label">When there is new order</label>
                                    <span className="p-inputgroup">
                                        <Dropdown className="p-margin-top-15" value={data.mailTemplateWhenNewOrder} options={orderMailTemplates} onChange={e => setData({...data, mailTemplateWhenNewOrder: e.value})} />
                                        <span className="p-inputgroup-addon">
                                            <Checkbox inputId="ckUseTemplateWhenNewOrder" checked={data.useMailTemplateWhenNewOrder} onChange={e => setData({...data, useMailTemplateWhenNewOrder: e.checked})} />
                                            <label htmlFor="ckUseTemplateWhenNewOrder">Use?</label>
                                        </span>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Email</label>
                                    <Chips value={data.emailWhenNewOrder?data.emailWhenNewOrder:[]} allowDuplicate={false} onAdd={e=> onAddEmailInput('emailWhenNewOrder', e)} onRemove={e => onRemoveInputEmail('emailWhenNewOrder', e)} placeholder="Enter email" />
                                    <div className="p-form-error">{errors.emailWhenNewOrder}</div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12"><hr/></div>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <label className="p-label">When invoice has been paid</label>
                                    <span className="p-inputgroup">
                                        <Dropdown className="p-margin-top-15" value={data.mailTemplateWhenPaidInvoice} options={invoiceMailTemplates} onChange={e => setData({...data, mailTemplateWhenPaidInvoice: e.value})} />
                                        <span className="p-inputgroup-addon">
                                            <Checkbox inputId="ckUseTemplateWhenPaidInvoice" checked={data.useMailTemplateWhenPaidInvoice} onChange={e => setData({...data, useMailTemplateWhenPaidInvoice: e.checked})} />
                                            <label htmlFor="ckUseTemplateWhenPaidInvoice">Use?</label>
                                        </span>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Email</label>
                                    <Chips value={data.emailWhenPaidInvoice?data.emailWhenPaidInvoice:[]} allowDuplicate={false} onAdd={e=> onAddEmailInput('emailWhenPaidInvoice', e)} onRemove={e => onRemoveInputEmail('emailWhenPaidInvoice', e)} placeholder="Enter email" />
                                    <div className="p-form-error">{errors.emailWhenPaidInvoice}</div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12"><hr/></div>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <label className="p-label">When participating in the program</label>
                                    <span className="p-inputgroup">
                                        <Dropdown className="p-margin-top-15" value={data.mailTemplateWhenParticipateProgram} options={orderMailTemplates} onChange={e => setData({...data, mailTemplateWhenParticipateProgram: e.value})} />
                                        <span className="p-inputgroup-addon">
                                            <Checkbox inputId="ckUseTemplateWhenParticipateProgram" checked={data.useMailTemplateWhenParticipateProgram} onChange={e => setData({...data, useMailTemplateWhenParticipateProgram: e.checked})} />
                                            <label htmlFor="ckUseTemplateWhenParticipateProgram">Use?</label>
                                        </span>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Email</label>
                                    <Chips value={data.emailWhenParticipantProgram?data.emailWhenParticipantProgram:[]} allowDuplicate={false} onAdd={e=> onAddEmailInput('emailWhenParticipantProgram', e)} onRemove={e => onRemoveInputEmail('emailWhenParticipantProgram', e)} placeholder="Enter email" />
                                    <div className="p-form-error">{errors.emailWhenParticipantProgram}</div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12"><hr/></div>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <label className="p-label">When rental product</label>
                                    <span className="p-inputgroup">
                                        <Dropdown className="p-margin-top-15" value={data.mailTemplateWhenRentalProduct} options={orderMailTemplates} onChange={e => setData({...data, mailTemplateWhenRentalProduct: e.value})} />
                                        <span className="p-inputgroup-addon">
                                            <Checkbox inputId="ckUseTemplateWhenRentalProduct" checked={data.useMailTemplateWhenRentalProduct} onChange={e => setData({...data, useMailTemplateWhenRentalProduct: e.checked})} />
                                            <label htmlFor="ckUseTemplateWhenRentalProduct">Use?</label>
                                        </span>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Email</label>
                                    <Chips value={data.emailWhenRentalProduct?data.emailWhenRentalProduct:[]} allowDuplicate={false} onAdd={e=> onAddEmailInput('emailWhenRentalProduct', e)} onRemove={e => onRemoveInputEmail('emailWhenRentalProduct', e)} placeholder="Enter email" />
                                    <div className="p-form-error">{errors.emailWhenRentalProduct}</div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12"><hr/></div>
                        <div className="p-col-12">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <label className="p-label">When the subscription has been renewed</label>
                                    <span className="p-inputgroup">
                                        <Dropdown className="p-margin-top-15" value={data.mailTemplateWhenSubscriptionRenewed} options={mailTemplates} onChange={e => setData({...data, mailTemplateWhenSubscriptionRenewed: e.value})} />
                                        <span className="p-inputgroup-addon">
                                            <Checkbox inputId="ckUseTemplateWhenSubscriptionRenewed" checked={data.useMailTemplateWhenSubscriptionRenewed} onChange={e => setData({...data, useMailTemplateWhenSubscriptionRenewed: e.checked})} />
                                            <label htmlFor="ckUseTemplateWhenSubscriptionRenewed">Use?</label>
                                        </span>
                                    </span>
                                </div>
                                <div className="p-col-12">
                                    <label className="p-label">Email</label>
                                    <Chips value={data.emailWhenSubscriptionRenewed?data.emailWhenSubscriptionRenewed:[]} allowDuplicate={false} onAdd={e=> onAddEmailInput('emailWhenSubscriptionRenewed', e)} onRemove={e => onRemoveInputEmail('emailWhenSubscriptionRenewed', e)} placeholder="Enter email" />
                                    <div className="p-form-error">{errors.emailWhenSubscriptionRenewed}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fieldset>
            </div>
            <div className="p-col-12">
                <Button label="Save" icon="pi pi-save"  onClick={() => handleSubmit()} />
            </div>
        </div>
    )
}
