import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Fieldset } from "primereact/fieldset";
import { Card } from "primereact/card";

import { useRouteMatch, useHistory } from "react-router-dom";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import moment from "moment";
import classNames from "classnames";
import { TableHeader } from "../datatable-tools/TableHeader.js";
import {
  exportUsers,
  getPageUsers,
  removeUser,
  sendMailVerifyAccountByKeycloak,
  sendMailVerifyAccountForSystem,
  updateActiveStatus,
  updateStatusBookingBlocking,
  validateBeforeDelete,
} from "../../../constituent-management/contact/UserServices.js";
import { getTablePageReport } from "../../service/CommonService.js";
import { DATE_FORMAT_DISPLAY, USER_ORIGIN } from "../../../constants.js";
import {
  showConfirmNotify,
  showErrorNotify,
  showSuccessNotify,
} from "../../service/NotificationService.js";
import { ContactForm } from "../../../constituent-management/contact/ContactForm.js";
import { hasRole } from "../../security/auth.jsx";
import { ROLES_CONFIG } from "../../../roles.js";
import { showloading, stoploading } from "../../service/LoadingService.js";

const fileDownload = require('js-file-download');


export default function ListUser(props) {
  const { data, pageName, isMembers, getDataService } = props;
  const defaultFilter = {
    viewType: "all",
    origins: [
      USER_ORIGIN.fep_admin.value,
      USER_ORIGIN.fep_customer.value,
      USER_ORIGIN.fep_imported.value,
    ],
    name: "",
    email: "",
    identityDocumentNumber: ""
  };

  const linkForDetail = isMembers ? "members" : "contacts";
  const [dataList, setDataList] = useState([]);
  const [isLoadTable, setLoadTable] = useState(true);

  const [filter, setFilter] = useState(defaultFilter);
  const [pageable, setPageable] = useState({
    page: 0,
    rows: 10,
    total: 0,
    sortField: "id",
    sortOrder: -1,
  });

  const history = useHistory();
  const match = useRouteMatch();

  const [disabledBtnActive, setDisabledBtnActive] = useState(false);

  const frmContact = useRef(null);

  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (isLoadTable) loadPageContacts();
  }, [isLoadTable]);

  const loadPageContacts = () => {
    getDataService(
      filter,
      pageable.page,
      pageable.rows,
      pageable.sortField,
      pageable.sortOrder
    ).then((res) => {
      setPageable({...pageable,
          total: res.totalElements,
          page: res.pageable.pageNumber,
          rows: res.pageable.pageSize
      })
      setDataList(res.content)
      // setDataList(data);
      setLoadTable(false);
    });
  };

  const onPage = (e) => {
    setPageable({ ...pageable, page: e.page });
    setLoadTable(true);
  };

  const onSort = (e) => {
    setPageable({
      ...pageable,
      sortField: e.sortField,
      sortOrder: e.sortOrder,
    });
    setLoadTable(true);
  };

  const onChangePageLength = (e) => {
    setPageable({ ...pageable, rows: e, page: 0 });
    setLoadTable(true);
  };

  const tblHeader = () => {
    let items = [{label: 'Export', icon: 'pi pi-download', disabled: !dataList||dataList.length===0, command:() => handleExport()}]

    return (
      <TableHeader
        title={pageName}
        pageReport={getTablePageReport(
          pageable.page,
          pageable.rows,
          pageable.total
        )}
        actionTemplate={
          <React.Fragment>
            {hasRole([ROLES_CONFIG.CONSTITUENT_CONTACT_W])
              ? <SplitButton
                  className="p-button-constrast"
                  label="Create"
                  icon="pi pi-plus"
                  model={items}
                  onClick={() => frmContact.current.openForm(null)}
                />
              : <Button
                className="p-button-constrast"
                label="Export"
                icon="pi pi-download"
                onClick={() => handleExport()}
              />
            }
          </React.Fragment>
          
        }
        changePageLength={(e) => onChangePageLength(e)}
        refresh={() => setLoadTable(true)}
      />
    )
  }

  const resendVerifyEmail = (username) => {
    sendMailVerifyAccountForSystem(username).then((res) => {
      if (!res.errorCode) {
        showSuccessNotify("Action submitted");
      } else {
        showErrorNotify("Cannot perform action");
      }
    });
  };

  const updateActiveStatusUser = (id, active) => {
    setDisabledBtnActive(true);
    updateActiveStatus(id, active).then((res) => {
      if (!res.errorCode) {
        setDisabledBtnActive(false);
        // loadPageContacts();
        showSuccessNotify("Action submitted");
      } else {
        showErrorNotify("Cannot perform action");
      }
    });
  };

  const handleRemoveUser = (id) => {
    removeUser(id)
    .then(res => {
      if(!res.errorCode){
        loadPageContacts()
        showSuccessNotify("Action submitted");
      }else{
        showErrorNotify(res.errorMessage);
      }
    })
  }

  const handleValidateBeforeDelete = async(id) => {
    let resValidateBeforeDelete = await validateBeforeDelete(id)
    if(resValidateBeforeDelete && resValidateBeforeDelete.errorCode){
      showErrorNotify(resValidateBeforeDelete.errorMessage)
      return
    }
    if(resValidateBeforeDelete.isAccountMembership){
      showConfirmNotify({
        message: 'This account has membership. If you continue the membership will be deleted',
        accept: () => handleRemoveUser(id)
      })
    }else{
      handleRemoveUser(id)
    }
  }

  const handleExport = () => {
    if(dataList.length===0) return
    let suf = moment().format('YYYYMMDDHHmmss');
    let fileName = 'rpt_contact_' + suf + '.xlsx';

    showloading()
    exportUsers(filter)
    .then(res=>{
        if (!res.errorCode) {
            fileDownload(res, fileName);
        } else {
            showErrorNotify(res.errorMessage);
        }
    })
    .finally(()=>{
      stoploading()
    })
  }

  const handelUpdateStatusBookingBlocking = (userId, isBlockBooking) => {
    updateStatusBookingBlocking(userId, isBlockBooking)
    .then(res => {
      if(!res.errorCode){
        setLoadTable(true)
        showSuccessNotify('Action submitted')
      }else{
        showErrorNotify(res.errorMessage)
      }
    })
  }

  const onUpdateStatusBookingBlocking = (userId, isBlockBooking) => {
    showConfirmNotify({
      accept:() => handelUpdateStatusBookingBlocking(userId, isBlockBooking)
    })
  }

  const onRemoveUser = (id) => {
    showConfirmNotify({
      accept: () => handleValidateBeforeDelete(id)
    })
  }

  const applyFilter = () => {
    setPageable({ ...pageable, page: 0 });
    setLoadTable(true);
  };

  const resetFilter = () => {
    setPageable({ ...pageable, page: 0 });
    setFilter(defaultFilter);
    setLoadTable(true);
  };

  const handleSendMailVerifyAccountByKeycloak = (userId) => {
    sendMailVerifyAccountByKeycloak(userId)
    .then(res => {
      if(!res.errorCode){
        showSuccessNotify('Action submitted')
      }else{
        showErrorNotify(res.errorMessage)
      }
    })
  }

  const onKeyprressEnter = (e) => {
    if (e.key === 'Enter') {
      setLoadTable(true)
    }
  }

  return (
    <div className="p-grid">
      <BreadcrumbsItem to={match.url}>{pageName}</BreadcrumbsItem>

      <ContactForm ref={frmContact} refreshTable={() => setLoadTable(true)} />

      <div className="p-col-12">
        <div className="card card-w-title">
          <h1 className="p-w-bold p-m-0">{pageName}</h1>

          <Fieldset legend="Filters">
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <div className="p-grid">
                  <div className="p-col-12 p-md-6">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Name/Username:</span>
                      <InputText
                        value={filter.name}
                        onKeyUp={onKeyprressEnter}
                        onChange={(e) =>
                          setFilter({ ...filter, name: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Email:</span>
                      <InputText
                        value={filter.email}
                        keyfilter="email"
                        onKeyUp={onKeyprressEnter}
                        onChange={(e) =>
                          setFilter({ ...filter, email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="p-col-12 p-md-6">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">Document Number:</span>
                      <InputText value={filter.identityDocumentNumber}onKeyUp={onKeyprressEnter}onChange={(e) =>setFilter({ ...filter, identityDocumentNumber: e.target.value })} placeholder="work permit, passport, fin" />
                    </div>
                  </div>
                </div>
              </div>
              {isMembers && (
                <div className="p-col-12 p-md-6">
                  <div className="p-grid">
                    <div className="p-col-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Member ID:</span>
                        <InputText
                          value={filter.memberId}
                          onChange={(e) =>
                            setFilter({ ...filter, memberId: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="p-col-12">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Phone:</span>
                        <InputText
                          value={filter.phone}
                          onChange={(e) =>
                            setFilter({ ...filter, phone: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) 
              // : (
              //   <div className="p-col-12 p-md-6">
              //     <div className="p-grid">
              //       <div className="p-col-12">
              //         <div className="p-inputgroup">
              //           <span className="p-inputgroup-addon">Nationality:</span>
              //           <InputText
              //             value={filter.memberId}
              //             onChange={(e) =>
              //               setFilter({
              //                 ...filter,
              //                 nationality: e.target.value,
              //               })
              //             }
              //           />
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              //   )
              }
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-c">
                <Button
                  className="blue-btn"
                  icon="pi pi-filter"
                  iconPos="left"
                  label="Search"
                  onClick={() => applyFilter()}
                />
                <Button
                  className="yellow-btn"
                  icon="pi pi-trash"
                  iconPos="left"
                  label="Clear"
                  onClick={() => resetFilter()}
                />
              </div>
            </div>
          </Fieldset>
        </div>
        <div className="p-grid">
          <div className="p-col-12 ">
            <DataTable
              id="tableWithSelectedRowCustomed"
              emptyMessage="There's no record found"
              header={tblHeader()}
              value={dataList}
              lazy={true}
              loading={isLoadTable}
              paginator={true}
              first={pageable.page * pageable.rows}
              rows={pageable.rows}
              totalRecords={pageable.total}
              sortField={pageable.sortField}
              sortOrder={pageable.sortOrder}
              onPage={onPage}
              onSort={onSort}
              responsive={true}
              selectionMode="single"
              selection={selectedRow}
              onSelectionChange={(e) => {
                setSelectedRow(e.value);
              }}
              rowHover
            >
              <Column
                field="id"
                header="ID"
                sortable={true}
                headerStyle={{width:"12em"}}
              />
              {isMembers ? (
                <Column
                  field="id"
                  header="Member ID"
                  sortable={true}
                  style={{ width: "12em" }}
                />
              ) : null}
              <Column
                field="username"
                header="Username"
                sortable={true}
              />
              <Column
                field="firstName"
                header="First Name"
                sortable={true}
              />
              <Column
                field="lastName"
                header="Last Name"
                sortable={true}
              />
              <Column
                field="user.email"
                header="Email"
                sortable={true}
                // style={{ width: "20em" }}
                body={(rowData) => (
                  <a
                    href={
                      rowData.email ? "mailto:" + rowData.email : "#"
                    }
                  >
                    {rowData.email}
                  </a>
                )}
              />
              {/* <Column field="phone" body={rowData => rowData.phone} style={{ width: "16em"}} header="Phone" sortable={false}/> */}
              {/* {isMembers ? null : (
                <Column
                  field="user.nationality"
                  header="Nationality"
                  sortable={true}
                  body={(rowData) => rowData.nationality ? rowData.nationality : "NA"}
                />
              )} */}
              {/* <Column field="birthday" style={{ width: "16em"}} header="Date of birth" bodyClassName="p-c" sortable={true}
                                body={rowData => rowData.birthday ? moment(rowData.birthday).format('MM-DD-YYYY') : ''}
                            /> */}
              {/* <Column field="enabled" header="Verified" sortable={false} bodyClassName="p-c"
                                body={rowData => {
                                    return  (
                                        <div className="p-d-flex p-ai-center p-jc-center">
                                            <span className={classNames('status', {'active': rowData.enabled, 'inactive': !rowData.enabled})}>{rowData.enabled ? 'Yes' : 'No'}</span>
                                            {!rowData.enabled &&
                                                <InputSwitch checked={rowData.enabled} disabled={disabledBtnActive} className="p-ml-3" onChange={(e) => updateActiveStatusUser(rowData.id, e.value)} />
                                            }
                                        </div>
                                    )
                                }}
                            /> */}
              {/* <Column field="createdAt" header="Register Date" bodyClassName="p-r" sortable={true}
                                body={rowData => rowData.createdAt ? moment(rowData.createdAt).format('MM-DD-YYYY') : ''}
                            /> */}
              <Column header="Description" 
                body={rowData =>{
                  if(rowData.requestRemove){
                    return <div>
                      <div className="p-margin-bottom-5">Request account deletion</div>
                      <div>{moment(rowData.dateRequestRemove).format(DATE_FORMAT_DISPLAY)}</div>
                    </div>
                  }
                }}
              />
              <Column
                className="tc-actions"
                header="Actions"
                style={{ overlay: "visible" }}
                headerStyle={{width: "10em"}}
                body={(rowData) => {
                  let actionItems = [{label: 'Send Verification Email', icon: 'pi pi-envelope', command:()=> handleSendMailVerifyAccountByKeycloak(rowData.id)}]
                  if (!rowData.enabled) {
                    // actionItems.push(
                    //   {
                    //     label: "Resend Activation Email",
                    //     icon: "pi pi-envelope",
                    //     command: () => resendVerifyEmail(rowData.username),
                    //   },
                    // );
                  }
                  if(hasRole([ROLES_CONFIG.CONSTITUENT_CONTACT_W])){
                    if(rowData.blockBooking){
                      actionItems.push({label: "Unblock Booking", icon: "pi pi-unlock", command: ()=> onUpdateStatusBookingBlocking(rowData.id, false)})
                    }else{
                      actionItems.push({label: "Block Booking", icon: "pi pi-lock", command: ()=> onUpdateStatusBookingBlocking(rowData.id, true)})
                    }
                  }
                  if(hasRole([ROLES_CONFIG.CONSTITUENT_CONTACT_D])){
                    actionItems.push({label: "Remove", icon: "pi pi-trash", command: ()=> onRemoveUser(rowData.id)})
                  }
                  if(actionItems.length>0){
                    return (
                      <SplitButton
                          label="View"
                          tooltip={`View ${isMembers ? "Member" : "Contact"} Details`} tooltipOptions={{ position: "bottom" }}
                          className="p-button-constrast p-l" model={actionItems}
                          onClick={() => history.push(`/constituent-mgt/${linkForDetail}/${rowData.id}`)}
                        />
                    );
                  }
                  return <Button label="View" className="p-button-constrast" tooltip={`View Contact Details`} tooltipOptions={{ position: "bottom" }} model={actionItems} 
                    onClick={() =>history.push(`/constituent-mgt/${linkForDetail}/${rowData.id}`)}/>
                }}
              />
            </DataTable>
          </div>
          {/* <div className="p-col-12 p-sm-4">
            <Card style={{ display: "block" }}>
              {selectedRow ? (
                <Fieldset legend="Basic Information">
                  <div className="p-grid p-fluid form-group">
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">Member ID: </label>
                      <div className="p-w-bold">{selectedRow.id}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">Gender: </label>
                      <div className="p-w-bold">{selectedRow.gender ? selectedRow.gender : "NA"}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">First Name: </label>
                      <div className="p-w-bold">
                        {selectedRow.firstName}
                      </div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">Last Name: </label>
                      <div className="p-w-bold">
                        {selectedRow.lastName}
                      </div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">Primary Email: </label>
                      <div className="p-w-bold">{selectedRow.email}</div>
                    </FieldWrapper>
                    <FieldWrapper col="p-col-12 p-md-6">
                      <label className="p-label">Phone Number: </label>
                      <div className="p-w-bold">{selectedRow.phone ? selectedRow.phone : "NA"}</div>
                    </FieldWrapper>
                  </div>
                </Fieldset>
              ) : (
                <h3>Please select a row to view detail information</h3>
              )}
            </Card>
          </div> */}
        </div>
      </div>
    </div>
  );
}
