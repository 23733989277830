import axios from 'axios';
import { PIM_BASE_URL, PIM_PATH, SCP_PATH, API_BASE_URL } from '../../constants';

const PIM_ORDER_URL = PIM_BASE_URL + PIM_PATH + '/orders';
const PIM_BOOKING_URL = PIM_BASE_URL + PIM_PATH + '/reg';
const PARTICIPANT_SERVICE_URL = API_BASE_URL + SCP_PATH + '/participants';

export function createFrontDeskOrder(data) {
	return axios
		.post(`${PIM_BOOKING_URL}/frontdesk/scp/create-order`, data)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getFrontDeskOrder(orderNumber) {
	return axios
		.get(`${PIM_BOOKING_URL}/frontdesk/get-operating-order/${orderNumber}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function completeFrontDeskOrderCheckout(orderNumber, paymentMode, method, gateway, note) {
	return axios
		.post(`${PIM_BOOKING_URL}/frontdesk/scp/order/${orderNumber}/process-checkout`, null, {
			params: { paymentMode, method, gateway, note },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function cancelFrontDeskOrder(orderNumber) {
	return axios
		.post(`${PIM_BOOKING_URL}/frontdesk/scp/order/${orderNumber}/cancel`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function completeOrderCheckoutAfterPayment(orderNumber, method, gateway, paymentGatewayId, token, processAfterPayment = true) {
	return axios
		.post(`${PIM_BOOKING_URL}/frontdesk/scp/order/${orderNumber}/process-checkout`, null, {
			params: { method, gateway, paymentGatewayId, token, processAfterPayment },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getOrder(orderId) {
	return axios
		.get(`${PIM_ORDER_URL}/${orderId}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getOrderByNumber(orderNumber) {
	return axios
		.get(`${PIM_ORDER_URL}/get-by-number/${orderNumber}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getOrderItem(orderId, orderItemId) {
	return axios
		.get(`${PIM_ORDER_URL}/item/${orderItemId}`, { params: { orderId } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getProductBookingInfo(productId, orderNumber, orderItemId) {
	return axios
		.get(`${PIM_BOOKING_URL}/get-product-booking-information`, { params: { productId, orderNumber, orderItemId } })
		.then((res) => {
			return res.data;
		})
		.catch((error) => console.log(error));
}

export function getCartOrder(orderNumber, includeDetails = true) {
	return axios
		.get(`${PIM_BOOKING_URL}/get-cart-order`, { params: { orderNumber, includeDetails } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function saveCartItem(data) {
	return axios
		.post(`${PIM_BOOKING_URL}/save-product-booking`, data)
		.then((res) => {
			return res.data;
		})
		.catch((error) => console.log(error));
}

export function updateCartItemQuantity(data) {
	return axios
		.post(`${PIM_BOOKING_URL}/update-cart-item-quantity`, data)
		.then((res) => {
			return res.data;
		})
		.catch((error) => console.log(error));
}

export function removeCartItem(orderNumber, orderItemId) {
	return axios
		.post(`${PIM_BOOKING_URL}/remove-cart-item`, null, { params: { orderNumber, orderItemId } })
		.then((res) => {
			return res.data;
		})
		.catch((error) => console.log(error));
}

export function validateBeforePayment(orderNumber) {
	return axios
		.post(`${PIM_BOOKING_URL}/order/${orderNumber}/validate-before-checkout`, null, { params: { on: orderNumber } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function viewOrderHistoryByBill(email) {
	return axios
		.post(`${PIM_ORDER_URL}/bill/history`, null, { params: { email } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function completePaymentOrder(orderNumber, method, gateway, dataRequest, payFullOrder) {
	return axios
		.post(`${PIM_BOOKING_URL}/order/payment`, dataRequest, {
			params: { orderNumber, method, gateway, payFullOrder },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removeParticipant(participantId) {
	return axios
		.delete(`${PARTICIPANT_SERVICE_URL}/${participantId}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function assignParticipant(data, newAttendee) {
	return axios
		.post(`${PARTICIPANT_SERVICE_URL}/assign`, data, { params: { newAttendee: newAttendee, p: 'frontdesk' } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getParticipantBookingInfo(orderId, orderItemId, participantId) {
	return axios
		.get(`${PARTICIPANT_SERVICE_URL}/get-participant-booking-information`, { params: { oId: orderId, oiId: orderItemId, participantId, p: 'frontdesk' } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function applyPromotionCoupon(orderNumber, couponCode) {
	return axios
		.post(`${PIM_BOOKING_URL}/order/${orderNumber}/apply-promotion-coupon`, null, { params: { couponCode } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function cancelPromotionCoupon(orderNumber, couponCode) {
	return axios
		.post(`${PIM_BOOKING_URL}/order/${orderNumber}/cancel-promotion-coupon`, null, { params: { couponCode } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}
