import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { deleteApplicationRecord, exportApplicationRecords, getApplication, getPageApplicationRecord } from './ApplicationService';
import { showConfirmNotify, showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import moment from 'moment';
import { SplitButton } from 'primereact/splitbutton';
import { Fieldset } from 'primereact/fieldset';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
	ALIAS_APPLICATION_SUPPORT_CASE,
	ALIAS_APPLICATION_VOLUNTEER,
	APPLICATION_RECORD_STATUS,
	APPLICATION_TYPE,
	ATTRIBUTE_FIELD_TYPE,
	DATETIME_FORMAT_DISPLAY,
	DATE_FORMAT_DISPLAY,
	TIME_FORMAT_DISPLAY,
} from '../../constants';
import ApplicationRecordForm from './ApplicationRecordForm';
import { ApplicationToLogForm } from './ApplicationToLogForm';
import { Dropdown } from 'primereact/dropdown';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';
const fileDownload = require('js-file-download');

export const ApplicationRecordListViewForm = forwardRef((props, ref) => {
	const [header, setHeader] = useState('View');
	const [visible, setVisible] = useState(false);
	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'id',
		sortOrder: -1,
	});
	const [filter, setFilter] = useState({});
	const [dateFromValue, setDateFromValue] = useState(null);
	const [dateToValue, setDateToValue] = useState(null);
	const [minDateValue, setMinDateValue] = useState(null);
	const [maxDateValue, setMaxDateValue] = useState(null);
	const [application, setApplication] = useState({});

	const [isLoadTable, setLoadTable] = useState(false);

	const applicationRecordForm = useRef(null);
	const logForm = useRef(null);

	useImperativeHandle(ref, () => ({
		openForm(applicationId) {
			loadApplication(applicationId);
			setFilter({ applicationIds: [applicationId], parentIds: props.parentId ? [props.parentId] : [] });
			setVisible(true);
		},
		closeForm() {
			closeForm();
		},
	}));

	useEffect(() => {
		if (visible && filter.applicationIds) loadPageApplicationRecords();
	}, [filter.applicationIds]);

	useEffect(() => {
		if (visible && isLoadTable) loadPageApplicationRecords();
	}, [isLoadTable]);

	const loadApplication = (applicationId) => {
		getApplication(applicationId).then((res) => setApplication(res));
	};

	const loadPageApplicationRecords = () => {
		getPageApplicationRecord(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				setDataList(res.content);
				setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
			})
			.finally(() => setLoadTable(false));
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const handleRemove = (id) => {
		deleteApplicationRecord(id).then((res) => {
			if (!res.errorCode) {
				loadPageApplicationRecords();
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const handleExport = () => {
		if (dataList.length === 0) return;
		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = 'rpt_record_' + suf + '.xlsx';

		exportApplicationRecords(filter).then((res) => {
			if (!res.errorCode) {
				fileDownload(res, fileName);
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const actionTemplate = (rowData) => {
		let items = [];
		if (isWrite()) {
			items.push({ label: 'Edit', icon: 'pi pi-pencil', command: () => onAddOrEditForm(rowData.id) });
		}
		if (isDelete()) {
			items.push({ label: 'Remove', icon: 'pi pi-trash', command: () => onRemoveRecord(rowData.id) });
		}
		if (
			props.appParentType === APPLICATION_TYPE.normal.value ||
			(props.appParentType === APPLICATION_TYPE.approval.value &&
				(props.parentStatus === APPLICATION_RECORD_STATUS.approved.value || props.parentStatus === APPLICATION_RECORD_STATUS.processing.value))
		) {
			if (items.length > 0) {
				return <SplitButton label='View' icon='pi pi-search' className='p-button-constrast p-l' model={items} onClick={() => onViewRecord(rowData.id)} />;
			}
			return <Button label='View' icon='pi pi-search' className='p-button-constrast p-l' onClick={() => onViewRecord(rowData.id)} />;
		}
		return <Button label='View' icon='pi pi-search' className='p-button-constrast p-l' onClick={() => onViewRecord(rowData.id)} />;
	};

	const onViewRecord = (recordId) => {
		applicationRecordForm.current.openForm(recordId);
	};

	const onRemoveRecord = (id) => {
		showConfirmNotify({
			accept: () => handleRemove(id),
		});
	};

	const onDateFromChange = (value) => {
		let currentValueToDate = value && moment(value).isValid() ? moment(value) : '';
		let tmpMaxDate = maxDateValue ? moment(maxDateValue) : '';
		let isCurrentValueAfterMaxDate = currentValueToDate && tmpMaxDate && currentValueToDate.isAfter(tmpMaxDate);
		if (currentValueToDate) {
			currentValueToDate.set({ h: 0, m: 0, s: 0 });
		}
		setFilter({
			...filter,
			dateFrom: currentValueToDate ? currentValueToDate.format(moment.HTML5_FMT.DATE) : '',
			dateTo: !isCurrentValueAfterMaxDate ? filter.dateTo : '',
		});
		setDateFromValue(value);
		setDateToValue(!isCurrentValueAfterMaxDate ? dateToValue : '');
		setMinDateValue(moment(value).isValid() ? value : '');
		setMaxDateValue(!isCurrentValueAfterMaxDate ? maxDateValue : '');
	};

	const onDateToChange = (value) => {
		let currentValueToDate = moment(value).isValid() ? moment(value) : '';
		let tmpMinDate = minDateValue ? moment(minDateValue) : '';
		let isCurrentValueBeforeMinDate = currentValueToDate && tmpMinDate && currentValueToDate.isBefore(tmpMinDate);
		if (currentValueToDate) {
			currentValueToDate.set({ h: 23, m: 59, s: 59 });
		}
		setFilter({
			...filter,
			dateFrom: !isCurrentValueBeforeMinDate ? filter.dateFrom : '',
			dateTo: currentValueToDate ? currentValueToDate.format(moment.HTML5_FMT.DATE) : '',
		});
		setDateFromValue(!isCurrentValueBeforeMinDate ? dateFromValue : '');
		setDateToValue(value);
		setMinDateValue(!isCurrentValueBeforeMinDate ? minDateValue : '');
		setMaxDateValue(moment(value).isValid() ? value : '');
	};

	const clearFilter = () => {
		setFilter({ ...filter, dateFrom: '', dateTo: '', statuses: [] });
		setDateFromValue(null);
		setDateToValue(null);
		setMinDateValue(null);
		setMaxDateValue(null);
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const onAddOrEditForm = (recordId) => {
		logForm.current.openForm(application.alias, recordId);
		setVisible(false);
	};

	const tblHeader = () => {
		let flag = isWrite();

		let items = [{ label: 'Export', icon: 'pi pi-download', disabled: dataList.length === 0, command: () => handleExport() }];
		return (
			<TableHeader
				title={application.name}
				pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
				changePageLength={(e) => onChangePageLength(e)}
				refresh={() => loadPageApplicationRecords()}
				actionTemplate={
					flag &&
					(props.appParentType === APPLICATION_TYPE.normal.value ||
						(props.appParentType === APPLICATION_TYPE.approval.value &&
							(props.parentStatus === APPLICATION_RECORD_STATUS.approved.value || props.parentStatus === APPLICATION_RECORD_STATUS.processing.value))) ? (
						<SplitButton className='p-button-constrast' label='Create' icon='pi pi-plus' model={items} onClick={() => onAddOrEditForm()} />
					) : (
						<Button className='p-button-constrast' label='Export' icon='pi pi-plus' onClick={() => handleExport()} />
					)
				}
			/>
		);
	};

	const closeForm = () => {
		setFilter({});
		setVisible(false);
		if (props.backToForm) {
			props.backToForm();
		}
	};

	const isWrite = () => {
		let flag = isScpAdmin();
		if (application?.alias === ALIAS_APPLICATION_SUPPORT_CASE) {
			flag = isScpAdmin() || hasRole([ROLES_CONFIG.CARE_SUPPORT_W]);
		} else if (application?.alias === ALIAS_APPLICATION_VOLUNTEER) {
			flag = isScpAdmin() || hasRole([ROLES_CONFIG.VOLUNTEER_MGT_W]);
		}
		return flag;
	};

	const isDelete = () => {
		let flag = isScpAdmin();
		if (application?.alias === ALIAS_APPLICATION_SUPPORT_CASE) {
			flag = isScpAdmin() || hasRole([ROLES_CONFIG.CARE_SUPPORT_D]);
		} else if (application?.alias === ALIAS_APPLICATION_VOLUNTEER) {
			flag = isScpAdmin() || hasRole([ROLES_CONFIG.VOLUNTEER_MGT_D]);
		}
		return flag;
	};

	return (
		<React.Fragment>
			{visible && (
				<ApplicationRecordForm
					ref={applicationRecordForm}
					refreshTable={() => loadPageApplicationRecords()}
					backToForm={() => {
						setVisible(true);
						setLoadTable(true);
					}}
				/>
			)}
			<ApplicationToLogForm
				ref={logForm}
				parentId={props.parentId}
				reloadTable={() => loadPageApplicationRecords()}
				backToForm={() => {
					setVisible(true);
					setLoadTable(true);
				}}
			/>

			<Sidebar visible={visible} style={{ overflowY: 'auto' }} className='p-sidebar-lg' position='right' blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
				<div className='p-d-flex p-justify-between '>
					<h2 className='p-margin-top-10'>{header}</h2>
					<Button label='' icon='pi pi-times' className='p-button-secondary' onClick={closeForm} />
				</div>

				<div className='p-sidebar-line p-mb-3'></div>

				<div className='p-grid'>
					<div className='p-col-12'>
						<Fieldset legend='Filter'>
							<div className='p-grid p-fluid'>
								<div className='p-col-12 p-md-6'>
									<div className='p-inputgroup'>
										<span className='p-inputgroup-addon'>Date From: </span>
										<MaskedCalendar value={dateFromValue} onChange={(e) => onDateFromChange(e.value)} showIcon={true} />
									</div>
								</div>
								<div className='p-col-12 p-md-6'>
									<div className='p-inputgroup'>
										<span className='p-inputgroup-addon'>Date To: </span>
										<MaskedCalendar value={dateToValue} onChange={(e) => onDateToChange(e.value)} showIcon={true} />
									</div>
								</div>
								{application.type === APPLICATION_TYPE.approval.value && (
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Status: </span>
											<Dropdown
												value={filter.statuses && filter.statuses.length > 0 ? filter.statuses[0] : null}
												options={Object.values(APPLICATION_RECORD_STATUS)}
												onChange={(e) => setFilter({ ...filter, statuses: e.value ? [e.value] : [] })}
											/>
										</div>
									</div>
								)}
							</div>
							<div className='p-grid p-margin-top-10'>
								<div className='p-col-12 p-c'>
									<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={() => loadPageApplicationRecords()} />
									<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' onClick={() => clearFilter()} />
								</div>
							</div>
						</Fieldset>
					</div>
					<div className='p-col-12'>
						<DataTable
							emptyMessage="There's no record found"
							header={tblHeader()}
							value={dataList}
							lazy={true}
							loading={isLoadTable}
							paginator={true}
							first={pageable.page * pageable.rows}
							rows={pageable.rows}
							totalRecords={pageable.total}
							sortField={pageable.sortField}
							sortOrder={pageable.sortOrder}
							onPage={onPage}
							onSort={onSort}
							responsive={true}
						>
							<Column field='id' header='Action ID' headerStyle={{ width: '8em' }} sortable />
							<Column header='Call ID' field='parentId' headerStyle={{ width: '8em' }} sortable />
							<Column header='Created by' body={(rowData) => (rowData.user ? rowData.user.name : 'N/A')} />
							<Column
								header='Description'
								body={(rowData) => {
									let tmpDescription = '';
									let tmpFieldValues = rowData.fieldValues ? rowData.fieldValues : [];
									for (let tmpValue of tmpFieldValues) {
										let tmpField = tmpValue.field;

										if (tmpField && tmpValue.value) {
											if (tmpField.name === 'case_group_description') {
												tmpDescription = tmpValue.value;
											}
										}
									}
									return tmpDescription;
								}}
							/>
							{application.type === APPLICATION_TYPE.approval.value && (
								<Column
									field='status'
									header='Status'
									sortable
									body={(rowData) => (APPLICATION_RECORD_STATUS[rowData.status] ? APPLICATION_RECORD_STATUS[rowData.status].label : APPLICATION_RECORD_STATUS.pending.label)}
								/>
							)}
							<Column
								header='Action date'
								body={(rowData) => {
									let tmpDate = '';
									let tmpFieldValues = rowData.fieldValues ? rowData.fieldValues : [];
									for (let tmpValue of tmpFieldValues) {
										let tmpField = tmpValue.field;

										if (tmpField && tmpValue.value) {
											if (tmpField.name === 'case_group_creation_date') {
												if (tmpField.type) {
													switch (tmpField.type) {
														case ATTRIBUTE_FIELD_TYPE.date.value:
															tmpDate = moment(tmpValue.value).format(DATE_FORMAT_DISPLAY);
															break;
														case ATTRIBUTE_FIELD_TYPE.time.value:
															tmpDate = moment(tmpValue.value, 'HH:mm').format(TIME_FORMAT_DISPLAY);
															break;
														case ATTRIBUTE_FIELD_TYPE.date_time.value:
															tmpDate = moment(tmpValue.value, 'yyyy-MM-dd HH:mm').format(DATETIME_FORMAT_DISPLAY);
															break;
														default:
															break;
													}
												}
											}
										}
									}
									if (!tmpDate) {
										tmpDate = rowData.date ? moment(rowData.date).format(DATETIME_FORMAT_DISPLAY) : 'No Date';
									}
									return tmpDate;
								}}
							/>
							<Column header='Actions' body={actionTemplate} className='tc-actions' style={{ textAlign: 'center', width: '12em', overlay: 'visible' }} />
						</DataTable>
					</div>
				</div>

				<div className='p-sidebar-line p-my-3'></div>

				<div className='p-grid'>
					<div className='p-col-12 p-r'>
						<Button label='Close' icon='pi-md-close' className='p-button-secondary' onClick={closeForm} />
					</div>
				</div>
			</Sidebar>
		</React.Fragment>
	);
});
