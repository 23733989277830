import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { RadioButton } from 'primereact/radiobutton';
import { PAYMENT_GATEWAY, PAYMENT_METHOD } from '../../constants';
import { moneyFormat } from '../../core/service/CommonService';
import { Dialog } from 'primereact/dialog';
import { getInvoice, invoiceRefund } from './InvoiceServices';
import { InputTextarea } from 'primereact/inputtextarea';

export const InvoiceRefundForm = forwardRef((props, ref) => {
	const [header, setHeader] = useState('Refund');
	const [invoice, setInvoice] = useState({});
	const [visible, setVisible] = useState(false);
	const [frmData, setFrmData] = useState({});
	const [isLoading, setLoading] = useState(false);

	useImperativeHandle(ref, () => ({
		openForm(invoiceId, amount) {
			setFrmData({
				invoiceId: invoiceId,
				gateway: PAYMENT_GATEWAY.ONSITE,
				method: PAYMENT_METHOD.cash.value,
				amount: amount,
			});
			loadInvoice(invoiceId);
			setVisible(true);
		},
		closeForm() {
			closeForm();
		},
	}));

	const loadInvoice = (invoiceId) => {
		getInvoice(invoiceId).then((res) => setInvoice(res));
	};

	const closeForm = () => {
		setVisible(false);
		setFrmData({
			gateway: PAYMENT_GATEWAY.ONSITE,
			method: PAYMENT_METHOD.cash.value,
		});
		setLoading(false);
	};

	const handleSubmit = () => {
		showloading();
		setLoading(true);

		try {
			invoiceRefund(frmData.invoiceId, frmData.gateway, frmData.method, frmData.transId, frmData.reason)
				.then((res) => {
					if (!res.errorCode) {
						if (props.refreshData) {
							props.refreshData(res);
						}
						closeForm();
						showSuccessNotify('Action submitted');
					} else {
						showErrorNotify(res.errorMessage);
					}
				})
				.finally(() => {
					stoploading();
					setLoading(false);
				});
		} catch (error) {
			stoploading();
			setLoading(false);
			showErrorNotify('System error');
		}
	};

	const dialogActionFooter = (
		<div>
			{isLoading ? <Button label='Submit' icon='pi pi-spin pi-spinner' disabled={isLoading} /> : <Button label='Submit' icon='pi pi-check' onClick={() => handleSubmit()} />}
			<Button label='Cancel' icon='pi-md-close' onClick={() => closeForm()} />
		</div>
	);

	return (
		<Dialog header={header} footer={dialogActionFooter} visible={visible} style={{ width: '50vw' }} modal={true} onHide={() => closeForm()}>
			<div className='p-grid p-fluid form-group'>
				<div className='p-col-12 p-size-16 p-w-bold' style={{ color: '#a00303' }}>
					This action cannot be undone!
				</div>
				<div className='p-col-12'>
					<label className='p-label'>Select one of the payment types below:</label>
				</div>
				<div className='p-col-12'>
					<div className='p-py-2'>
						<RadioButton
							inputId='rdGateway0'
							className='p-mr-2'
							onChange={(e) => setFrmData({ ...frmData, gateway: PAYMENT_GATEWAY.ONSITE, method: PAYMENT_METHOD.cash.value })}
							checked={frmData.gateway === PAYMENT_GATEWAY.ONSITE}
						/>
						<label htmlFor='rdGateway0'>
							ONSITE <strong>({moneyFormat(frmData.amount)})</strong>
						</label>
					</div>
					{/* {invoice.gateway === PAYMENT_GATEWAY.HITPAY && (
						<div className='p-py-2'>
							<RadioButton
								inputId='rdGateway1'
								className='p-mr-2'
								onChange={(e) => setFrmData({ ...frmData, gateway: PAYMENT_GATEWAY.HITPAY, method: PAYMENT_METHOD.card.value })}
								checked={frmData.gateway === PAYMENT_GATEWAY.HITPAY}
							/>
							<label htmlFor='rdGateway1'>
								HITPAY <strong>({moneyFormat(frmData.amount)})</strong>
							</label>
						</div>
					)} */}
				</div>
				{frmData.gateway === PAYMENT_GATEWAY.ONSITE && (
					<React.Fragment>
						<div className='p-col-12'>
							<label className='p-label'>Select one of the payment options below:</label>
						</div>
						<div className='p-col-12'>
							<div className='p-py-2'>
								<RadioButton
									inputId='rdMethod0'
									className='p-mr-2'
									onChange={(e) => setFrmData({ ...frmData, method: PAYMENT_METHOD.cash.value })}
									checked={frmData.method === PAYMENT_METHOD.cash.value}
								/>
								<label htmlFor='rdMethod0'>Cash</label>
							</div>
							<div className='p-py-2'>
								<RadioButton
									inputId='rdMethod1'
									className='p-mr-2'
									onChange={(e) => setFrmData({ ...frmData, method: PAYMENT_METHOD.bank_transfer.value })}
									checked={frmData.method === PAYMENT_METHOD.bank_transfer.value}
								/>
								<label htmlFor='rdMethod1'>Bank transfer</label>
							</div>
						</div>
						{frmData.method === PAYMENT_METHOD.bank_transfer.value && (
							<div className='p-col-12'>
								<label className='p-label'>* Transaction Number:</label>
								<InputText value={frmData.transId} onChange={(e) => setFrmData({ ...frmData, transId: e.target.value })} />
							</div>
						)}
					</React.Fragment>
				)}
				<div className='p-col-12'>
					<label className='p-label'>Please input a reason:</label>
					<InputTextarea rows={4} value={frmData.reason} onChange={(e) => setFrmData({ ...frmData, reason: e.target.value })} placeholder='Enter a reason' />
				</div>
			</div>
		</Dialog>
	);
});
