import React, { Component } from 'react';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { TenantPaymentForm } from './TenantPaymentForm';
import { getTenentPayments, removeTenantPayment } from './TenantPaymentService';
import { PAYMENT_METHOD } from '../../constants';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { saveScpConfig } from './ConfigruationServices';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { SplitButton } from 'primereact/splitbutton';

class TenantPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                onlinePaymentGateway: props.data?props.data.onlinePaymentGateway:'',
                useOnSitePayment: props.data&&props.data.useOnSitePayment,
                onSiteMethods: props.data&&props.data.onSiteMethods?props.data.onSiteMethods:[],
                useOnlinePayment: props.data&&props.data.useOnlinePayment,
            },
            payments: [],
            loadingRefresh: false,
            isDirty: false,
        }
        this.baseState = this.state;
    }

    componentDidMount(){
        this.loadPayments();
    }

    loadPayments = () => {
        this.setState({ loadingRefresh: true });
        getTenentPayments().then(res => {
            let tempArr = res?res:[];
            this.setState({ 
                payments: tempArr,
                paymentSimples: tempArr.map(p => {return {value: p.id, label: p.name}}),
                loadingRefresh: false
            })
        })
    }

    onUseOnsitePaymentChange = (e) => {
        this.setState({
            data: {
                ...this.state.data, 
                useOnSitePayment: e.checked,
                onSiteMethods: []
            }
        })
    }

    onChangeOnsitePaymentMethod = (e) => {
        let onSiteMethods = [...this.state.data.onSiteMethods];

        if(e.checked)
            onSiteMethods.push(e.value);
        else
            onSiteMethods.splice(onSiteMethods.indexOf(e.value), 1);

        this.setState({
            data: {...this.state.data,
                onSiteMethods: onSiteMethods
            }
        });
    }

    handleSave = () => {
        saveScpConfig({paymentConfig: this.state.data}, 'payment')
        .then((res) => {
            if(!res.errorCode){
                showSuccessNotify('Action Submitted');
                this.props.onSave(res.payment);
            }else{
                if(res.errorCode === 400) this.setState({errors: res.errorObj})
                showErrorNotify(res.errorMessage);
            }
        })
    }

    onChangeDicarded = () => {
        this.setState(this.baseState);
        this.loadPaymentGateways();
        this.loadPayments();
    }

    renderHeader = () => {
        return (
            <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                <div style={{ 'float': 'left' }} >
                    <Button icon="pi pi-plus" className="p-button-constrast" label="Create Payment" onClick={(e) => this.paymentForm.popularFormData()}/>
                </div>
                <div className="p-float-right">
                    <Button className="p-button-constrast p-margin-left-15" disabled={this.state.loadingRefresh} icon="pi pi-refresh" tooltip="Refresh" onClick={() => this.loadPayments()} />
                </div>
                <h3 className="fle-datatable-header-title">Payment Gateway List</h3>
            </div>
        );
    }

    actionTemplate = (rowData) => {
        let dropActions = [
            {
                label: 'Remove',
                icon: 'pi pi-trash',
                command: (e) => {
                    this.removePaymentGateway(rowData.id);
                }
            }
        ];
        return <SplitButton label="Edit" icon="pi pi-pencil" className="p-button-constrast" model={dropActions} onClick={(e) => this.paymentForm.popularFormData(rowData)}/>
    }

    removePaymentGateway = (id) => {
        showConfirmNotify({
            accept: () => this.handleRemovePaymentGateway(id)
        })
    }

    handleRemovePaymentGateway = (id) => {
        removeTenantPayment(id).then(res => {
            if (!res.errorCode) {
                this.loadPayments()
                showSuccessNotify('Action Submitted');
            } else {
                showErrorNotify('Cannot perform action');
            }
        });
    }

    render() {
        const tblHeader = (
            <TableHeader
                actionTemplate={<Button className="p-button-success" label="Create gateway" icon="pi-md-plus" onClick={() => this.paymentForm.popularFormData(null)}/>}
                showLength={false}
                refresh={() => this.loadPayments()}
            />
        )

        return (
            <div className="p-grid">
                <TenantPaymentForm ref={el => this.paymentForm=el}
                    refreshData={() => {this.loadPayments()}}
                />

                <div className="p-col-12 p-lg-5">
                    <Fieldset legend="Payment Setting">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">
                                <Checkbox inputId="cbUseOnSitePayment" checked={this.state.data.useOnSitePayment} onChange={(e) => this.onUseOnsitePaymentChange(e)} />                                
                                <label htmlFor="cbUseOnSitePayment">Enable on site payment</label>
                            </div>
                            {this.state.data.useOnSitePayment && 
                                <React.Fragment>
                                    <div className="p-col-12 p-margin-left-15">
                                        <Checkbox inputId="cbUseCash" value={PAYMENT_METHOD.cash.value} checked={this.state.data.onSiteMethods.includes(PAYMENT_METHOD.cash.value)} onChange={(e) => this.onChangeOnsitePaymentMethod(e)} />                                
                                        <label htmlFor="cbUseCash">Enable cash payment method</label>
                                    </div>
                                    <div className="p-col-12 p-margin-left-15">
                                        <Checkbox inputId="cbUseBankTransfer" value={PAYMENT_METHOD.bank_transfer.value} checked={this.state.data.onSiteMethods.includes(PAYMENT_METHOD.bank_transfer.value)} onChange={(e) => this.onChangeOnsitePaymentMethod(e)} />                                
                                        <label htmlFor="cbUseBankTransfer">Enable bank transfer</label>
                                    </div>
                                </React.Fragment>
                            }
                            <div className="p-col-12">
                                <Checkbox inputId="cbUseOnlinePayment" checked={this.state.data.useOnlinePayment} onChange={(e) => {this.setState({data: {...this.state.data, useOnlinePayment: e.checked}})}} />                                
                                <label htmlFor="cbUseOnlinePayment">Enable online payment</label>
                            </div>
                            {this.state.data.useOnlinePayment && 
                            <div className="p-col-12">
                                <label>Online Payment Gateway</label>
                                <Dropdown value={this.state.data.onlinePaymentGateway} options={this.state.paymentSimples} showClear={true} onChange={(e) => {this.setState({data: {...this.state.data, onlinePaymentGateway: e.value}})}} />                                
                            </div>
                            }
                        </div>
                        <div className="p-grid p-mt-3">
                            <div className="p-col-12">
                                <Button label="Save" icon="pi pi-save" iconPos="left" onClick={() => this.handleSave()} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12 p-lg-7">
                    <Fieldset legend="List of Payment Gateway">
                        <DataTable lazy={false}
                            header={tblHeader}
                            value={this.state.payments} responsive={true}
                        >
                            <Column field="name" header="Name" sortable />
                            <Column field="gateway" header="Provider" sortable />
                            <Column field="useMode" header="Using Mode" sortable/>
                            <Column body={this.actionTemplate} headerStyle={{width: '10em'}} bodyClassName="tc-actions" />
                        </DataTable>
                    </Fieldset>
                    
                </div>
            </div>
        );
    }
}

export default TenantPayment;
