import moment from 'moment';
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { SplitButton } from "primereact/splitbutton";
import React, { Component } from "react";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { showloading, stoploading } from '../core/service/LoadingService';
import { showNotification } from '../core/service/NotificationService';
import { WebErrorServices } from "./WebErrorServices.js";

export class WebErrorListView extends Component{
    constructor(props){
        super(props);
        this.state = {
            dataList: [],
            page: 0,
            rows: 10,
            total: 0,
            sortField: 'id',
            sortOrder: -1,
            visibleFormDelete: false
        }
        this.webErrorServices = new WebErrorServices();
    }

    componentDidMount(){
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.loadPageError();
    }

    resize = () => {
        this.setState({changeScreenSize: window.innerWidth <= 600});
    }

    loadPageError = () => {
        this.webErrorServices.getPageWebErrors(this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
        .then(res => {
            this.setState({
                dataList: res.content,
                total: res.totalElements,
                page: res.pageable.pageNumber,
                rows: res.pageable.pageSize
            })
        })
    }

    onPage = (e) => {
        this.setState({
            loading: true,
            page: e.page
        }, () => {
            this.loadPageError();
        });
    }

    onSort = (e) => {
        this.setState({
            loading: true,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        },() => {
            this.loadPageError();
        });
    }

    onChangePageLength = l => {
        this.setState({
            rows: l,
            page: 0
        }, () => {
            this.loadPageError();
        })
    }

    removeAll = () => {
        showloading();
        this.webErrorServices.removeAll()
        .then(res => {
            stoploading();
            if(!res.errorCode){
                this.onHideFormDelete();
                showNotification('success', 'Success Message', 'Action submitted');
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    onHideFormDelete = () => {
        this.setState({ visibleFormDelete: false})
    }

    render(){

        let tableLengthOptions = [
            {
                label: '5',
                command: (e) => {
                    this.onChangePageLength(5);
                }
            },
            {
                label: '10',
                command: (e) => {
                    this.onChangePageLength(10);
                }
            },
            {
                label: '25',
                command: (e) => {
                    this.onChangePageLength(25);
                }
            },
            {
                label: '50',
                command: (e) => {
                    this.onChangePageLength(50);
                }
            },
            {
                label: '100',
                command: (e) => {
                    this.onChangePageLength(100);
                }
            }
        ];

        let listItems = [
            {
                label: 'Clear all logs',
                icon: '',
                command: (e) => {
                    this.setState({ visibleFormDelete: true})
                }
            },
        ]

        var header = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
            <SplitButton className="p-button-constrast" tooltip="Actions" icon="pi-md-list" model={listItems} style={{float: "left"}}></SplitButton>  
            <div style={{ float: "right" }}>
                <SplitButton className="table-control-length p-button-constrast" label="" icon="pi pi-refresh"
                        onClick={() => this.loadPageError()} model={tableLengthOptions}>
                </SplitButton>
            </div>
        </div>;

        let scrollable
        let responsive
        if(this.state.changeScreenSize == true) {
            scrollable = false
            responsive = true
        } else {
            scrollable = true
            responsive = false
        }
        return(
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Web Errors</BreadcrumbsItem>

                <Dialog visible={this.state.visibleFormDelete} contentStyle={styles.dialogContent} responsive={true}
                    modal={true} dismissableMask={true} showHeader={false} onHide={this.onHideFormDelete}
                    footer={<React.Fragment>
                        <Button label="Cancel" icon="pi-md-close" onClick={this.onHideFormDelete} />
                        <Button label="Confirm" icon="pi-md-check" className="p-button-secondary" onClick={this.removeAll} />
                    </React.Fragment>}
                >
                    Do you really want to delete all?
                </Dialog>

                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>Error Listing</h1>
                        <DataTable lazy={true} paginator={true} value={this.state.dataList}
                            first={this.state.page * this.state.rows}
                            header={header} onPage={this.onPage} onSort={this.onSort}
                            rows={this.state.rows} totalRecords={this.state.total}
                            sortField={this.state.sortField} sortOrder={this.state.sortOrder}
                            responsive={responsive}
                            scrollable={scrollable}
                        >
                            <Column header="ID" field="id" sortable={true} style={{width: '10em'}} />
                            <Column header="Code" field="code" sortable={true} style={{width: '8em'}} />
                            <Column header="Request" field="request" sortable={true} style={{width: '25em'}} />
                            <Column header="Created" field="created" sortable={true} style={{width: '12em'}}
                                body={(rowData) => {
                                    return (rowData.created ? moment(rowData.created).format("MM-DD-YYYY hh:mm A") : '');
                                }}
                            />
                            <Column header="" style={{width: '6em'}} className="tc-actions"
                                body={(rowData) => {
                                    return (
                                        <Button label="View" tooltip="View Errors" className="p-button-constrast" onClick={() => this.props.history.push(`/web-errors/${rowData.id}`)}/>
                                    );
                                }}
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    dialogContent: {
        maxHeight: '50px',
        minHeight: '50px',
        overflow: 'hidden',
    },
}