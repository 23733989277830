import axios from 'axios';
import { API_BASE_URL } from '../../constants';

export class ReportServices {
    getPageReports(page, size, sortField, sortOrder){
        const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
        const sort = `${sortField},${order}`;
        return axios.get(`${API_BASE_URL}/scp/api/reports/page`, {
            params: {page, size, sort }
        }).then(res => res.data).catch(error => console.log(error));
    }

    getListReportColumns(type){
        return axios.get(`${API_BASE_URL}/scp/api/reports/columns`, {params: {type}})
        .then(res => res.data).catch(error => console.log(error));
    }

    saveReport(data){
        return axios.post(`${API_BASE_URL}/scp/api/reports`, data)
        .then(res => res.data).catch(error => console.log(error));
    }

    removeReport(id){
        return axios.delete(`${API_BASE_URL}/scp/api/reports/${id}`)
        .then(res => res).catch(error => console.log(error));
    }

    exportReport(form){
        return axios.post(`${API_BASE_URL}/scp/api/reports/export`, form, {
            responseType: 'arraybuffer'}).then(res => res).catch(error => console.log(error));
    }

    saveShareUsers(id,data){
        return axios.post(`${API_BASE_URL}/scp/api/reports/${id}/share-users/`, data)
        .then(res => res).catch(error => console.log(error));
    }

    getShareUsers(id,data){
        return axios.get(`${API_BASE_URL}/scp/api/reports/${id}/shared-users/list`, data)
        .then(res => res.data).catch(error => console.log(error));
    }
}