import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import { getApplicationRecord, getApplicationToLog, logApplication } from "./ApplicationService";
import { APPLICATION_COMPONENTT_TYPE as APPLICATION_COMPONENT_TYPE, ATTRIBUTE_FIELD_SET_TYPE, ATTRIBUTE_FIELD_TYPE, FIELD_DIRECTION_TYPE, FIELD_DISPLAY_TYPE, FIELD_EXPRESSION_TYPE, GENDERS } from "../../constants";
import { getCountries, getStatesByCountry } from "../tenant-configuration/CountrySevices";
import renderHTML from 'react-render-html';
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { RadioButton } from "primereact/radiobutton";
import { MultiSelect } from "primereact/multiselect";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { ScrollPanel } from "primereact/scrollpanel";
import PhoneInput from "react-phone-number-input";
import AsyncSelect from 'react-select/async';
import { getAllUsers } from "../../constituent-management/contact/UserServices";

export const ApplicationToLogForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("New Record")
    const [application, setApplication] = useState({})
    const [contents, setContents] = useState([])
    const [countries, setCountries] = useState([])
    const [countryStatesForPersonal, setCountryStatesForPersonal] = useState([])
    const [countryStatesForGuardian, setCountryStatesForGuardian] = useState([])
    const [step, setStep] = useState(1)
    const [isLoadContent, setLoadContent] = useState(false)
    const [isAllowApplication, setAllowApplication] = useState(true)
    const [requiredApplications, setRequiredApplications] = useState([])
    const [requiredClassifications, setRequiredClassifications] = useState([])
    const [applicationRecord, setApplicationRecord] = useState({})
    const [mapCustomFields, setMapCustomFields] = useState({})
    const [mapWaiverFields, setMapwaiverFields] = useState({})
    const [recordId, setRecordId] = useState(null)
    const [isLoadedApplication, setLoadedApplication] = useState(false)
    const [visible, setVisible] = useState(false)
    const [errors, setErrors] = useState({})

    const defaultCountry = localStorage.getItem('defaultCountry')
    const timezone = localStorage.getItem('timezone')
    
    useImperativeHandle(ref, () => ({
        openForm(alias, appRecordId){
            loadApplication(alias)
            setRecordId(appRecordId)
            setHeader(appRecordId?'Edit Record':'New Record')
            setVisible(true)
        },
        closeForm(){
            closeForm()
        }
    }))

    useEffect(()=>{
        if(isLoadedApplication && recordId) 
            loadApplicationRecord(recordId)
            loadCountries()
    },[isLoadedApplication])

    const handleSubmitAndNext = () => {
        setErrors({})

        let tmpData = {
            id: recordId,
            parentId: props.parentId,
            alias: application.alias,
            step: step,
            contents: contents,
            // captcha: reCaptcha
        }

        logApplication(tmpData)
        .then(res => {
            if(!res.errorCode){
                if(step!==application.contents.length){
                    setStep(step+1)
                }else{
                    if(props.reloadTable) props.reloadTable()
                    showSuccessNotify('Action submitted', 'Success')
                    closeForm()
                }
            }else{
                if(res.errorCode===400) {
                    setErrors(res.errorObj)
                    showErrorNotify('', 'Error Message');
                }else{
                    showErrorNotify(res.errorMessage);
                }
            }
        })
    }

    const loadApplication = (alias) => {
        getApplicationToLog(alias)
        .then(res => {
            if(!res.errorCode) {
                let tmpApplication = res.application?res.application:{}
                let tmpParticipant = res.participant?res.participant:{}
                let tmpAssetSchedule = res.assetSchedule?res.assetSchedule:{}
                let tmpUser = res.user?res.user:{}
                let tmpRequiredApplications = res.requiredApplications?res.requiredApplications:[]
                let tmpRequiredClassifcations = res.requiredClassifications?res.requiredClassifications:[]
                let tmpAllowApplication = res.isAllowApplication?true:false

                let tmpContents = [];
                
                if(tmpApplication.contents) {
                    for (const content of tmpApplication.contents) {
                        let tmpContent = {components: []}
                        if(content.components){
                            for (const component of content.components) {
                                let tmpComponent = {type: component.type, personalInfo: {}, customFieldValues: [], waiverFieldValues: [], guardianPersonalInfo:{}}

                                let tmpPersonalSet = component.personalSet
                                let tmpCustomSet = component.customSet
                                let tmpWaiverSet = component.waiverSet

                                // if(tmpPersonalSet && tmpPersonalSet.personalField && tmpUser){
                                //     let tmpPersonalField = tmpPersonalSet.personalField
                                //     let tmpPersonalData = {}

                                //     if(tmpPersonalField.useFirstName){
                                //         tmpPersonalData.firstName = tmpUser.firstName
                                //     }
                                //     if(tmpPersonalField.useLastName){
                                //         tmpPersonalData.lastName = tmpUser.lastName
                                //     }
                                //     if(tmpPersonalField.useGender){
                                //         tmpPersonalData.gender = tmpUser.gender
                                //     }
                                //     if(tmpPersonalField.useBirthday){
                                //         tmpPersonalData.birthday = tmpUser.birthday
                                //         tmpPersonalData.birthdayValue = tmpUser.birthday?moment(tmpUser.birthday).toDate():null
                                //     }
                                //     if(tmpPersonalField.useEmail){
                                //         tmpPersonalData.email = tmpUser.email
                                //     }
                                //     if(tmpPersonalField.usePhone){
                                //         tmpPersonalData.phone = tmpUser.phone
                                //     }
                                //     tmpComponent.personalInfo = tmpPersonalData
                                // }

                                if(tmpCustomSet){
                                    if(tmpCustomSet.poolOfFields){
                                        for (const customSetField of tmpCustomSet.poolOfFields) {
                                            let tmpField = customSetField.field
                                            let defaultValue = '';
                                            let defaultDateValue = null;
                                            if(tmpField.name==='support_case_informant'){
                                                defaultValue = localStorage.getItem('fullname')
                                            }else if(tmpField.name==='support_case_reporting_date'){
                                                if(tmpField.type===ATTRIBUTE_FIELD_TYPE.date_time.value){
                                                    defaultValue = moment.tz(timezone).format('YYYY-MM-DD HH:mm')
                                                    defaultDateValue = moment(defaultValue, 'YYYY-MM-DD HH:mm').toDate();
                                                }else{
                                                    defaultValue = moment.tz(timezone).format(moment.HTML5_FMT.DATE)
                                                    defaultDateValue = moment(defaultValue, moment.HTML5_FMT.DATE).toDate();
                                                }
                                            }
                                            let item = {
                                                setFieldId: customSetField.id,
                                                setType: ATTRIBUTE_FIELD_SET_TYPE.custom_field,
                                                fieldId: tmpField.id,
                                                fieldName: tmpField.name,
                                                storeType: tmpField.config.storeType??'string',
                                                value: defaultValue,
                                                valueArrays: [],
                                                phoneValue: '',
                                                phoneCodeValue: '',
                                                flagAnswer: customSetField.defaultValue==='true'?true:false,
                                                dateValue: defaultDateValue
                                            }
                                            tmpComponent.customFieldValues.push(item);
                                        }    
                                    }
                                }
                                if(tmpWaiverSet){
                                    if(tmpWaiverSet.poolOfFields){
                                        for (const waiverSetField of tmpWaiverSet.poolOfFields) {
                                            let tmpField = waiverSetField.field
                                            let item = {
                                                setFieldId: waiverSetField.id,
                                                setType: ATTRIBUTE_FIELD_SET_TYPE.waiver,
                                                fieldId: tmpField.id,
                                                fieldName: tmpField.name,
                                                storeType: tmpField.config.storeType,
                                                value: '',
                                                flagAnswer: waiverSetField.defaultValue==='true'?true:false,
                                            }
                                            tmpComponent.waiverFieldValues.push(item);

                                            waiverSetField.readOnly = (tmpField.config.openInDialog||tmpField.config.requiredReadAllToSignWaiver)?true:false
                                        }    
                                    }
                                }
                                tmpContent.components.push(tmpComponent)
                            }
                        }
                        tmpContents.push(tmpContent)
                    }
                }
                setApplication(tmpApplication)
                setContents(tmpContents)
                setRequiredApplications(tmpRequiredApplications)
                setRequiredClassifications(tmpRequiredClassifcations)
                setAllowApplication(tmpAllowApplication)
                setLoadedApplication(true)
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const loadApplicationRecord = (id) => {
        getApplicationRecord(id)
        .then(res=>{
            if(!res.errorCode){
                let tmpApplication = res.application?res.application:{}
                let tmpRecord = res.applicationRecord?res.applicationRecord:{}
                let tmpMapCustomValues = res.mapCustomFields?res.mapCustomFields:{}
                let tmpMapWaiverValues = res.mapWaiverFields?res.mapWaiverFields:{}
                
                let tmpContents = [...contents]

                let tmpPersonalInfos = tmpRecord.personalInfo?tmpRecord.personalInfo:[]

                tmpApplication.contents && tmpApplication.contents.map((content, idxContent) => {
                    content.components && content.components.map((component, idxComponent) => {
                        let tmpPersonalSet = component.personalSet
                        let tmpCustomSet = component.customSet
                        let tmpWaiverSet = component.waiverSet

                        let tmpPersonalValue = tmpPersonalInfos&&tmpPersonalInfos[idxContent]&&tmpPersonalInfos[idxContent].personalInfos&&tmpPersonalInfos[idxContent].personalInfos[idxComponent]&&tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                            ? tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                            : {}

                        let tmpCustomFieldValue = tmpCustomSet&&tmpMapCustomValues[tmpCustomSet.id]?tmpMapCustomValues[tmpCustomSet.id]:{}
                        let tmpWaiverFieldValue = tmpWaiverSet&&tmpMapWaiverValues[tmpWaiverSet.id]?tmpMapWaiverValues[tmpWaiverSet.id]:{}

                        if(tmpPersonalSet){
                            tmpContents[idxContent].components[idxComponent].personalInfo = tmpPersonalValue
                        }

                        if(tmpCustomSet){
                            tmpContents[idxContent].components[idxComponent].customFieldValues.map(item => {
                                let tmpItemValue = tmpCustomFieldValue[item.fieldName]
                                if(tmpItemValue) item.value = tmpItemValue.value
                            })
                        }
                        
                    })
                })

                setApplicationRecord(tmpRecord)
                setMapCustomFields(tmpMapCustomValues)
                setMapwaiverFields(tmpMapWaiverValues)
                // setContents(tmpContents)
            }
        })
    }

    const loadCountries = () => {
        getCountries().then(res => setCountries(res?res:[]))
    }

    const loadCountryStatesForPersonal = (countryCode) => {
        getStatesByCountry(countryCode).then(res => setCountryStatesForPersonal(res?res:[]))
    }

    const loadCountryStatesForGuardian = (countryCode) => {
        getStatesByCountry(countryCode).then(res => setCountryStatesForGuardian(res?res:[]))
    }

    const loadUsers = (searchTerm, callback) => {
        getAllUsers(searchTerm).then(res => callback (res) );
    }

    const onPersonalFieldChange = (indexContent, indexComponent, property, value) => {
        let tmpArr = [...contents]
        let tmpContent = contents[indexContent]
        let tmpComponent = tmpContent.components[indexComponent]
        let tmpPersonal = tmpComponent.personalInfo?tmpComponent.personalInfo:{}
        if(property==='birthday'){
            let isValidBirthday = value&&moment(value).isValid
            tmpPersonal.birthday = isValidBirthday?moment(value).format(moment.HTML5_FMT.DATE):''
            tmpPersonal.birthdayValue = value
            if(isValidBirthday){
                let isYoung = isYoungPerson(moment(value).format(moment.HTML5_FMT.DATE))
                tmpComponent.requiredParentGuardian = isYoung?true:false
            }
        }else if(property==='countryCode'){
            tmpPersonal.countryCode = value
            tmpPersonal.stateCode = null
            if(value){
                loadCountryStatesForPersonal(value)
            }else{
                setCountryStatesForPersonal([])
            }
        }else{
            tmpPersonal[property] = value
        }

        tmpComponent.personalInfo = tmpPersonal

        tmpArr[indexContent].components[indexComponent] = tmpComponent

        setContents(tmpArr)
    }

    const isYoungPerson = (birthday) =>{
        let isYoung = true

        if(birthday){
            let nowMoment = moment()
            let birthdayMoment = moment(birthday)
            let duration = moment.duration(nowMoment.diff(birthdayMoment))
            let years = duration.years()?duration.years():0
            isYoung = years<18?true:false
        }else{
            isYoung = true
        }

        return isYoung
    }

    const onCustomFieldChange = (indexContent, indexComponent, indexSetField, evenValue, field) =>{
        let tmpArr = [...contents]
        let tmpContent = contents[indexContent]
        let tmpComponent = tmpContent.components[indexComponent]
        let tmpFieldValues = tmpComponent.customFieldValues?[...tmpComponent.customFieldValues]:[]

        onFieldChange(indexSetField, evenValue, field, tmpFieldValues)

        tmpComponent.customFieldValues = tmpFieldValues

        tmpArr[indexContent].components[indexComponent] = tmpComponent

        setContents(tmpArr)
    }

    const onWaiverFieldChange = (indexContent, indexComponent, indexSetField, eventValue, field) =>{
        let tmpArr = [...contents]
        let tmpContent = contents[indexContent]
        let tmpComponent = tmpContent.components[indexComponent]
        let tmpFieldValues = tmpComponent.waiverFieldValues?[...tmpComponent.waiverFieldValues]:[]

        onFieldChange(indexSetField, eventValue, field, tmpFieldValues)

        tmpComponent.waiverFieldValues = tmpFieldValues

        tmpArr[indexContent].components[indexComponent] = tmpComponent

        setContents(tmpArr)
    }

    const onFieldChange = (idx, eventValue, field, fieldValues) => {
        let config = field&&field.config?field.config:{}

        switch(field.type){
            case ATTRIBUTE_FIELD_TYPE.date.value:
                fieldValues[idx].value = eventValue?moment(eventValue).format(moment.HTML5_FMT.DATE):'';
                fieldValues[idx].dateValue = eventValue;
                break;
            case ATTRIBUTE_FIELD_TYPE.time.value:
                fieldValues[idx].value = eventValue?moment(eventValue, moment.HTML5_FMT.TIME).format(moment.HTML5_FMT.TIME):'';
                fieldValues[idx].timeValue = eventValue;
                break;
            case ATTRIBUTE_FIELD_TYPE.date_time.value:
                fieldValues[idx].value = eventValue?moment(eventValue).format('YYYY-MM-DD HH:mm'):'';
                fieldValues[idx].datetimeValue = eventValue;
                break;
            case ATTRIBUTE_FIELD_TYPE.select.value:
                switch(config.displayType){
                    case FIELD_DISPLAY_TYPE.dropdown:
                        if(config.storeType!=='array'){
                            fieldValues[idx].value = eventValue;
                        }else{
                            fieldValues[idx].valueArrays = eventValue;
                        }
                        break;
                    case FIELD_DISPLAY_TYPE.checkbox:
                            let selectedValues = [...fieldValues[idx].valueArrays];
        
                            if(eventValue.checked)
                                selectedValues.push(eventValue.value);
                            else
                                selectedValues.splice(selectedValues.indexOf(eventValue.value), 1);
                    
                            fieldValues[idx].valueArrays = selectedValues;
                        break;
                    case FIELD_DISPLAY_TYPE.radio:
                        fieldValues[idx].value = eventValue;
                        break;
                    default:
                        break;
                }
                break;
            case ATTRIBUTE_FIELD_TYPE.yes_no.value:
                fieldValues[idx].value = eventValue+'';
                fieldValues[idx].flagAnswer = eventValue;
                break;
            case ATTRIBUTE_FIELD_TYPE.waiver.value:
                if(config.displayType===FIELD_DISPLAY_TYPE.checkbox){
                    fieldValues[idx].flagAnswer = eventValue;
                    fieldValues[idx].value = '';
                }else if(config.displayType===FIELD_DISPLAY_TYPE.textbox){
                    fieldValues[idx].value = eventValue;
                }
                else if(config.displayType===FIELD_DISPLAY_TYPE.file){
                    fieldValues[idx].fieldFile = eventValue;
                }
                break;
            case ATTRIBUTE_FIELD_TYPE.file.value:
                fieldValues[idx].file = eventValue;
                break;
            case ATTRIBUTE_FIELD_TYPE.user.value:
                fieldValues[idx].value = eventValue?`{"id": ${eventValue.value}, "name": "${eventValue.label}"}`:null;
                break;
            default:
                fieldValues[idx].value = eventValue;
                break;
        }
    }

    const initializeWaiverToSign = () => {
        const handleScroll = (e) => {
            let tmpContents = application.contents?[...application.contents]:[]

            let element = e.target
            let eleHeight = element.clientHeight
            if (element.scrollTop === e.target.scrollHeight - eleHeight) {
                let eleContainer = element.parentNode.parentNode
                let eleContainerId = eleContainer.getAttribute('id')
                let dataId = eleContainerId.replace('wv_', '')

                tmpContents.map(content => {
                    content.components&&content.components.map(component => {
                        if(component.type === APPLICATION_COMPONENT_TYPE.input_field.value){
                            let waiverSet = component.waiverSet
                            if(waiverSet){
                                waiverSet.poolOfFields && waiverSet.poolOfFields.map(wf => {
                                    if(wf.id === parseInt(dataId)){
                                        wf.readOnly = false
                                    }
                                })
                            }
                        }
                    })
                })
                setApplication({...application, contents: tmpContents})
            }
        }
        
        let tmpContents = application.contents?[...application.contents]:[]

        tmpContents.map((content, index) => {
            if(index === (step-1)){
                content.components&&content.components.map(component => {
                    if(component.type === APPLICATION_COMPONENT_TYPE.input_field.value){
                        let waiverSet = component.waiverSet
                        if(waiverSet){
                            waiverSet.poolOfFields.map(wf => {
                                let contentElement = document.getElementById('wv_'+wf.id)
                                let scrollPanel 
                                if(contentElement){
                                    scrollPanel = contentElement.getElementsByClassName("p-scrollpanel-content")[0]
                                    let eleHeight = scrollPanel.offsetHeight
                                    if(eleHeight <= 150){ // 150: height of content waiver
                                        wf.readOnly = false
                                    }else{
                                        scrollPanel.addEventListener("scroll", handleScroll);
                                        return () => scrollPanel.removeEventListener("scroll", handleScroll);
                                    }
                                }
                            })
                        }    
                    }
                })
            }
        })
        setApplication({...application, contents: tmpContents})
    }

    const onParentGuardianOptionChange = (indexContent, indexComponent, value) => {
        let tmpArr = [...contents]
        let tmpContent = contents[indexContent]
        let tmpComponent = tmpContent.components?tmpContent.components[indexComponent]:[]

        tmpComponent.requiredParentGuardian = value

        tmpArr[indexContent].components[indexComponent] = tmpComponent

        setContents(tmpArr)
    }

    const onGuardianPersonalFieldChange = (indexContent, indexComponent, property, value) => {
        let tmpArr = [...contents]
        let tmpContent = contents[indexContent]
        let tmpComponent = tmpContent.components[indexComponent]
        let tmpPersonal = tmpComponent.guardianPersonalInfo?tmpComponent.guardianPersonalInfo:{}
        if(property==='birthday'){
            tmpPersonal.birthday = value&&moment(value).isValid?moment(value).format(moment.HTML5_FMT.DATE):''
            tmpPersonal.birthdayValue = value
        }else if(property==='countryCode'){
            tmpPersonal.countryCode=value
            tmpPersonal.stateCode=null
            if(value){
                loadCountryStatesForGuardian(value)
            }else{
                setCountryStatesForGuardian([])
            }
        }else{
            tmpPersonal[property] = value
        }

        tmpComponent.guardianPersonalInfo = tmpPersonal

        tmpArr[indexContent].components[indexComponent] = tmpComponent

        setContents(tmpArr)
    }

    const onParentGuardianAgreeChange = (indexContent, indexComponent, value) => {
        let tmpArr = [...contents]
        let tmpContent = contents[indexContent]
        let tmpComponent = tmpContent.components?tmpContent.components[indexComponent]:[]

        tmpComponent.guardianAgreement = value

        tmpArr[indexContent].components[indexComponent] = tmpComponent

        setContents(tmpArr)
    }

    const onOpenToReadWaiver = (indexContent, indexComponent, indexWaiverField, field, value) => {
        // setTmpWaiverToSign({indexContent: indexContent, indexComponent: indexComponent, field: field})
        // waiverForm.current.openForm(indexWaiverField, field, value)
    }

    const onWaiverFormChange = (idx, field, answerValue) =>{
        // onWaiverFieldChange(tmpWaiverToSign.indexContent, tmpWaiverToSign.indexComponent, idx, answerValue, field)
    }
    const handlePrevious = () => {
        setStep(step-1)
    }

    const closeForm = () => {
        setContents([])
        setApplication({})
        setApplicationRecord({})
        setLoadedApplication(false)
        setVisible(false)
        setErrors({})
        if(props.backToForm){
            props.backToForm()
        }
    }

    const PARENT_GUARDIAN_TYPE = {
        above_18: {value: false, label: '18 years of age and above'},
        below_18: {value: true, label: 'Below 18 years of age'}
    }

    return(
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid p-fluid form-group">
                <div className="p-col-12">
                    {application.contents && application.contents.map((content, idxContent) => {
                        if((idxContent+1) === step)
                        return(
                            <React.Fragment key={idxContent}>

                                <div key={idxContent} className="p-grid">
                                    {content.components && content.components.map((component, idxComponent) => {
                                        if(component.type === APPLICATION_COMPONENT_TYPE.free_text.value){
                                            if(component.content){
                                                return <div key={idxComponent} className="p-col-12">{renderHTML(component.content)}</div>
                                            }
                                        }else if(component.type === APPLICATION_COMPONENT_TYPE.input_field.value){
                                            let personalSet = component.personalSet

                                            let tmpComponent = contents.length>0&&contents[idxContent]&&contents[idxContent]&&contents[idxContent].components&&[idxComponent]
                                                ?contents[idxContent].components[idxComponent]
                                                :{}
                                            let tmpPersonalInfo = tmpComponent.personalInfo?tmpComponent.personalInfo:{}
                                            let customSet = component.customSet
                                            let tmpCustomFieldValues = tmpComponent.customFieldValues?tmpComponent.customFieldValues:[]
                                            let waiverSet = component.waiverSet
                                            let tmpWaiverFieldValues = tmpComponent.waiverFieldValues?tmpComponent.waiverFieldValues:[]

                                            let isYoung = false
                                            if(tmpPersonalInfo.birthday&&moment(tmpPersonalInfo.birthday).isValid()){
                                                isYoung = isYoungPerson(tmpPersonalInfo.birthday)
                                            }

                                            return <React.Fragment key={idxComponent}>
                                                {component.type === APPLICATION_COMPONENT_TYPE.input_field.value && 
                                                    <React.Fragment>
                                                        {personalSet && Object.keys(personalSet).length > 0 && 
                                                            <React.Fragment>
                                                                {(()=>{
                                                                    let personalField = personalSet&&personalSet.personalField ? personalSet.personalField : {}
                                                                    let tmpPersonalErrors = errors['component_'+idxComponent]?errors['component_'+idxComponent]:{}

                                                                    return <div className="p-col-12">
                                                                        <div className="p-grid p-fluid">
                                                                            {/* {personalSet.label && 
                                                                            <div className="p-col-12">
                                                                                <span className="p-w-bold p-size-20">{personalSet.label}</span>
                                                                                <Divider className="p-my-1"/>
                                                                            </div>
                                                                            } */}
                                                                            
                                                                            {personalField.useTitleName &&
                                                                                <div className="p-col-12">
                                                                                    <label className="p-label block">{personalField.requiredTitleName && '*'} {personalField.titleNameLabel}</label>
                                                                                    {(()=>{
                                                                                        let tmpTitleArr = personalField.titleNameValues?personalField.titleNameValues.map(value=>{return{value:value, label: value}}):[]
                                                                                        return <Dropdown value={tmpPersonalInfo.title} options={tmpTitleArr} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent ,'title', e.value)} />
                                                                                    })()}
                                                                                    <div className="p-form-error">{tmpPersonalErrors.title}</div>
                                                                                </div>
                                                                            }

                                                                            {personalField.useFirstName &&
                                                                                <div className="p-col-12">
                                                                                    <label className="p-label block">{personalField.requiredFirstName && '*'} {personalField.firstNameLabel?personalField.firstNameLabel:'First Name'}</label>
                                                                                    <InputText value={tmpPersonalInfo.firstName??''} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent ,'firstName', e.target.value)} />
                                                                                    <div className="p-form-error">{tmpPersonalErrors.firstName}</div>
                                                                                </div>
                                                                            }

                                                                            {personalField.useLastName &&
                                                                                <div className="p-col-12">
                                                                                    <label className="p-label block">{personalField.requiredLastName && '*'} {personalField.lastNameLabel?personalField.lastNameLabel:'Last Name'}</label>
                                                                                    <InputText value={tmpPersonalInfo.lastName??''} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'lastName', e.target.value)} />
                                                                                    <div className="p-form-error">{tmpPersonalErrors.lastName}</div>
                                                                                </div>
                                                                            }

                                                                            {personalField.useEmail &&
                                                                                <div className="p-col-12">
                                                                                    <label className="p-label block">{personalField.requiredEmail && '*'} {personalField.emailLabel?personalField.emailLabel:'Email'}</label>
                                                                                    <InputText value={tmpPersonalInfo.email??''} keyfilter="email" onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'email', e.target.value)} />
                                                                                    <div className="p-form-error">{tmpPersonalErrors.email}</div>
                                                                                </div>
                                                                            }

                                                                            {personalField.useGender &&
                                                                                <div className="p-col-12">
                                                                                    <label className="p-label block">{personalField.requiredGender && '*'} {personalField.genderLabel}</label>
                                                                                    <Dropdown value={tmpPersonalInfo.gender} options={GENDERS} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'gender', e.value)} />
                                                                                    <div className="p-form-error">{tmpPersonalErrors.gender}</div>
                                                                                </div>
                                                                            }

                                                                            {personalField.useBirthday &&
                                                                                <div className="p-col-12">
                                                                                    <label className="p-label block">{personalField.requiredBirthday && '*'} {personalField.birthdayLabel} (mm/dd/yyyy)</label>
                                                                                    <Calendar value={tmpPersonalInfo.birthdayValue} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'birthday', e.value)} showIcon={true} dateFormat="mm/dd/yy" mask="99/99/9999" monthNavigator yearNavigator yearRange={(new Date().getFullYear()-120)+":"+(new Date().getFullYear())} />
                                                                                    <div className="p-form-error">{tmpPersonalErrors.birthday}</div>
                                                                                </div>
                                                                            }

                                                                            {personalField.usePhone &&
                                                                                <div className="p-col-12">
                                                                                    <label className="p-label block">{personalField.requiredPhone && '*'} {personalField.phoneLabel}</label>
                                                                                    <PhoneInput value={tmpPersonalInfo.phone??''} defaultCountry={defaultCountry} onChange={(value) => onPersonalFieldChange(idxContent, idxComponent, 'phone', value)} className="p-component p-inputtext" style={{width: "100%"}}/>
                                                                                    <div className="p-form-error">{tmpPersonalErrors.phone}</div>
                                                                                </div>
                                                                            }

                                                                            {personalField.usePassportNRIC &&
                                                                                <div className="p-col-12">
                                                                                    <label className="p-label block">{personalField.requiredPassportNRIC && '*'} {personalField.passportNRICLabel}</label>
                                                                                    <InputText value={tmpPersonalInfo.passportNRIC??''} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'passportNRIC', e.target.value)} />
                                                                                    <div className="p-form-error">{tmpPersonalErrors.passportNRIC}</div>
                                                                                </div>
                                                                            }

                                                                            {personalField.useNationality &&
                                                                                <div className="p-col-12">
                                                                                    <label className="p-label block">{personalField.requiredNationality && '*'} {personalField.nationalityLabel}</label>
                                                                                    <Dropdown value={tmpPersonalInfo.nationalityCode} options={countries} filter onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'nationalityCode', e.target.value)} />
                                                                                    <div className="p-form-error">{tmpPersonalErrors.nationality}</div>
                                                                                </div>
                                                                            }

                                                                            {personalField.useRegisterType &&
                                                                                <div className="p-col-12">
                                                                                    <label className="p-label block">{personalField.requiredRegisterType && '*'} {personalField.registerTypeLabel}</label>
                                                                                    {(()=>{
                                                                                        let valueList = personalField.registerTypeList?personalField.registerTypeList:[]
                                                                                        valueList.map(value => {return {value: value, label: value}})
                                                                                        return <Dropdown value={tmpPersonalInfo.registerType} options={valueList} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'registerType', e.value)} />
                                                                                    })()}
                                                                                    <div className="p-form-error">{tmpPersonalErrors.registerType}</div>
                                                                                </div>
                                                                            }

                                                                            {personalField.useAddress &&
                                                                                <React.Fragment>
                                                                                    {/* <div className="p-col-12 p-mt-3">
                                                                                        <span className="p-w-bold p-size-20">Address</span>
                                                                                    </div> */}
                                                                                    <div className="p-col-12">
                                                                                        <label className="p-label block">* Street Address Line 1</label>
                                                                                        <InputText value={tmpPersonalInfo.address1??''} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'address1', e.target.value)} />
                                                                                        <div className="p-form-error">{tmpPersonalErrors.address1}</div>
                                                                                    </div>
                                                                                    <div className="p-col-12">
                                                                                        <label className="p-label block">Street Address Line 2</label>
                                                                                        <InputText value={tmpPersonalInfo.address2??''} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'address2', e.target.value)} />
                                                                                        <div className="p-form-error">{tmpPersonalErrors.address2}</div>
                                                                                    </div>
                                                                                    <div className="p-col-12">
                                                                                        <label className="p-label block">* City</label>
                                                                                        <InputText value={tmpPersonalInfo.city??''} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'city', e.target.value)} />
                                                                                        <div className="p-form-error">{tmpPersonalErrors.city}</div>
                                                                                    </div>
                                                                                    <div className="p-col-12">
                                                                                        <label className="p-label block">* Zip Code</label>
                                                                                        <InputText value={tmpPersonalInfo.zipCode??''} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'zipCode', e.target.value)} />
                                                                                        <div className="p-form-error">{tmpPersonalErrors.zipCode}</div>
                                                                                    </div>
                                                                                    <div className="p-col-12">
                                                                                        <label className="p-label block">Country</label>
                                                                                        <Dropdown value={tmpPersonalInfo.countryCode} options={countries} filter onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'countryCode', e.target.value)} />
                                                                                    </div>
                                                                                    <div className="p-col-12">
                                                                                        <label className="p-label block">State/Province</label>
                                                                                        <Dropdown value={tmpPersonalInfo.stateCode} options={countryStatesForPersonal} filter onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'stateCode', e.target.value)} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                            {personalField.useEmerContact &&
                                                                                <React.Fragment>
                                                                                    {/* <div className="p-col-12 p-mt-3">
                                                                                        <span className="p-w-bold p-size-20">Emergency</span>
                                                                                    </div> */}
                                                                                    <div className="p-col-12">
                                                                                        <label className="p-label block">{personalField.requiredEmerContact && '*'} {personalField.emerContactNameLabel}</label>
                                                                                        <InputText value={tmpPersonalInfo.emeContactName??''} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'emeContactName', e.target.value)} />
                                                                                        <div className="p-form-error">{tmpPersonalErrors.emeContactName}</div>
                                                                                    </div>
                                                                                    <div className="p-col-12">
                                                                                        <label className="p-label block">{personalField.requiredEmerContact && '*'} {personalField.emerContactPhoneLabel}</label>
                                                                                        <PhoneInput value={tmpPersonalInfo.emeContactPhone??''} defaultCountry={defaultCountry} onChange={(value) => onPersonalFieldChange(idxContent, idxComponent, 'emeContactPhone', value)} className="p-component p-inputtext" style={{width: "100%"}}/>
                                                                                        <div className="p-form-error">{tmpPersonalErrors.emeContactPhone}</div>
                                                                                    </div>
                                                                                    <div className="p-col-12">
                                                                                        <label className="p-label block">{personalField.requiredEmerContact && '*'} {personalField.emerContactEmailLabel}</label>
                                                                                        <InputText keyfilter="email" value={tmpPersonalInfo.emeContactEmail??''} onChange={(e) => onPersonalFieldChange(idxContent, idxComponent, 'emeContactEmail', e.target.value)} />
                                                                                        <div className="p-form-error">{tmpPersonalErrors.emeContactEmail}</div>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            } 
                                                                        </div>
                                                                    </div>
                                                                })()}
                                                            </React.Fragment>
                                                        }

                                                        {customSet && Object.keys(customSet).length>0 &&
                                                            <React.Fragment>
                                                                {(()=>{
                                                                let customFields = customSet.poolOfFields

                                                                return <div className="p-col-12">
                                                                    <div className="p-grid">
                                                                        {/* <div className="p-col-12">
                                                                            <span className="p-w-bold p-size-20">{customSet.label ? customSet.label : 'ADDITIONAL INFORMATION'}</span>
                                                                            <Divider />
                                                                        </div> */}
                                                                        {customFields.map((el, idxSetField) => {
                                                                            let field = el.field;
                                                                            let config = field.config;

                                                                            let tmpFieldValue = tmpCustomFieldValues[idxSetField]
                                                                            let tmpValue = tmpFieldValue?tmpFieldValue.value:''
                                                                            let tmpValueArray = tmpFieldValue?tmpFieldValue.valueArrays:[]
                                                                            let tmpFlagAnswer = tmpFieldValue?tmpFieldValue.flagAnswer:false

                                                                            return <div className="p-col-12">
                                                                                {(()=>{
                                                                                    if(field.type!==ATTRIBUTE_FIELD_TYPE.yes_no.value){
                                                                                        return (
                                                                                            <div className="p-grid p-fluid p-ai-center">
                                                                                                <div className="p-col-12">
                                                                                                    <label className="p-label">{el.required?'* ':''}{field.label}</label>
                                                                                                    {(() => {
                                                                                                        switch(field.type){
                                                                                                            case ATTRIBUTE_FIELD_TYPE.text.value:
                                                                                                                if(config.expressionValidationType === FIELD_EXPRESSION_TYPE.maskString){
                                                                                                                    return <InputMask value={tmpValue} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.target.value, field)} mask={config.expressionValidationInput}></InputMask>;
                                                                                                                }else{
                                                                                                                    return <InputText value={tmpValue} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.target.value, field)} minLength={config.minLength?config.minLength:''} maxLength={config.maxLength?config.maxLength:''}/>;
                                                                                                                }
                                                                                                            case ATTRIBUTE_FIELD_TYPE.email.value:
                                                                                                                return <InputText value={tmpValue} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.target.value, field)} keyfilter="email"/>;
                                                                                                            case ATTRIBUTE_FIELD_TYPE.number.value:
                                                                                                                return <InputText value={tmpValue} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.target.value, field)} keyfilter="int"/>;
                                                                                                            case ATTRIBUTE_FIELD_TYPE.file.value:
                                                                                                                return <input type="file" className="p-component p-inputtext" accept={config.allowTypes&&config.allowTypes.length>0?config.allowTypes.map(t=> '.'+(t.replaceAll('.', ''))).join(','):'*'} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.target.files[0], field)}/>;
                                                                                                            case ATTRIBUTE_FIELD_TYPE.phone.value:
                                                                                                                return <PhoneInput value={tmpValue} defaultCountry={defaultCountry} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e, field)} className="p-component p-inputtext" />
                                                                                                            case ATTRIBUTE_FIELD_TYPE.select.value:
                                                                                                                let valueList = config.dataList?[...config.dataList]:[]
                                                    
                                                                                                                if(config.displayType===FIELD_DISPLAY_TYPE.dropdown){
                                                                                                                    let dataList = [...config.listValues];
                                                    
                                                                                                                    if(config.storeType!=='array'){
                                                                                                                        if(config.useNoneLabel){
                                                                                                                            dataList.unshift({value: '', label: config.noneLabel});
                                                                                                                        }
                                                                                                                        
                                                                                                                        return <Dropdown value={tmpValue?tmpValue:''} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.value, field)} options={dataList} style={{width: '100%'}}/>
                                                                                                                    }else{
                                                                                                                        return <MultiSelect value={tmpValueArray} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.value, field)} options={dataList} style={{width: '100%'}}/>
                                                                                                                    }
                                                                                                                }else{
                                                                                                                    return (
                                                                                                                        <div className="p-grid">
                                                                                                                            {config.directionType === FIELD_DIRECTION_TYPE.vertical &&
                                                                                                                                <React.Fragment>
                                                                                                                                    {config.displayType===FIELD_DISPLAY_TYPE.radio && config.useNoneLabel &&
                                                                                                                                        <div className="p-col-12">
                                                                                                                                            <RadioButton inputId={"rb_"+field.name+"_none"} value="" onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.value, field)} checked={tmpValue===''?true:false}></RadioButton>
                                                                                                                                            <label htmlFor={"rb_"+field.name+"_none"} className="p-checkbox-label p-ml-1">{config.noneLabel}</label>
                                                                                                                                        </div>
                                                                                                                                    }
                                                                                                                                    {valueList.map((v, i) => {
                                                                                                                                        return (
                                                                                                                                            <div key={i} className="p-col-12">
                                                                                                                                                {config.displayType===FIELD_DISPLAY_TYPE.checkbox &&
                                                                                                                                                    <React.Fragment>
                                                                                                                                                        <Checkbox inputId={"cb_"+field.name+"_"+i} value={v} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e, field)} checked={tmpValueArray.includes(v)}></Checkbox>
                                                                                                                                                        <label htmlFor={"cb_"+field.name+"_"+i} className="p-checkbox-label p-ml-1">{v}</label>
                                                                                                                                                    </React.Fragment>
                                                                                                                                                }
                                                                                                                                                {config.displayType===FIELD_DISPLAY_TYPE.radio &&
                                                                                                                                                    <React.Fragment>
                                                                                                                                                        <RadioButton inputId={"rb_"+field.name+"_"+i} value={v} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.value, field)} checked={tmpValue===v?true:false}></RadioButton>
                                                                                                                                                        <label htmlFor={"rb_"+field.name+"_"+i} className="p-checkbox-label p-ml-1">{v}</label>
                                                                                                                                                    </React.Fragment>
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                        )})    
                                                                                                                                    }
                                                                                                                                </React.Fragment>
                                                                                                                            }
                                                                                                                            {config.directionType === FIELD_DIRECTION_TYPE.horizontal &&
                                                                                                                                <div className="p-col-12">
                                                                                                                                    {config.displayType===FIELD_DISPLAY_TYPE.radio && config.useNoneLabel &&
                                                                                                                                        <React.Fragment>
                                                                                                                                            <RadioButton inputId={"rb_"+field.name+"_none"} value="" onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.value, field)} checked={tmpValue===''?true:false}></RadioButton>
                                                                                                                                            <label htmlFor={"rb_"+field.name+"_none"} className="p-checkbox-label p-ml-1 p-mr-3">{config.noneLabel}</label>
                                                                                                                                        </React.Fragment>
                                                                                                                                    }
                                                                                                                                    {valueList.map((v, i) => {
                                                                                                                                        return (
                                                                                                                                            <React.Fragment key={i}>
                                                                                                                                                {config.displayType===FIELD_DISPLAY_TYPE.checkbox &&
                                                                                                                                                    <React.Fragment>
                                                                                                                                                        <Checkbox inputId={"cb_"+field.name+"_"+i} value={v} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e , field)} checked={tmpValueArray.includes(v)}></Checkbox>
                                                                                                                                                        <label htmlFor={"cb_"+field.name+"_"+i} className="p-checkbox-label p-ml-1 p-mr-3">{v}</label>
                                                                                                                                                    </React.Fragment>
                                                                                                                                                }
                                                                                                                                                {config.displayType===FIELD_DISPLAY_TYPE.radio &&
                                                                                                                                                    <React.Fragment>
                                                                                                                                                        <RadioButton inputId={"rb_"+field.name+"_"+i} value={v} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.value, field)} checked={tmpValue===v?true:false}></RadioButton>
                                                                                                                                                        <label htmlFor={"rb_"+field.name+"_"+i} className="p-checkbox-label p-ml-1 p-mr-3">{v}</label>
                                                                                                                                                    </React.Fragment>
                                                                                                                                                }
                                                                                                                                            </React.Fragment>
                                                                                                                                        )
                                                                                                                                    })}        
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    );
                                                                                                                }
                                                                                                            case ATTRIBUTE_FIELD_TYPE.textarea.value:
                                                                                                                return <InputTextarea value={tmpValue} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.target.value, field)} rows={config.rows} autoResize={true}/>;
                                                                                                            case ATTRIBUTE_FIELD_TYPE.date.value:
                                                                                                                let tmpDateValue = tmpValue?moment(tmpValue).toDate():null
                                                                                                                return <Calendar value={tmpDateValue} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.value, field)} showIcon={true} dateFormat="dd/mm/yy" />
                                                                                                            case ATTRIBUTE_FIELD_TYPE.time.value:
                                                                                                                let tmpTimeValue = tmpValue?moment(tmpValue, moment.HTML5_FMT.TIME).toDate():null
                                                                                                                return <Calendar value={tmpTimeValue} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.value, field)} showIcon={true} timeOnly={true} hourFormat="12" />
                                                                                                            case ATTRIBUTE_FIELD_TYPE.date_time.value:
                                                                                                                let tmpDatetimeValue = tmpValue?moment(tmpValue, 'YYYY-MM-DD HH:mm').toDate():null
                                                                                                                return <Calendar value={tmpDatetimeValue} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.value, field)} showIcon={true} dateFormat="dd/mm/yy" showTime={true} hourFormat="12" />
                                                                                                            case ATTRIBUTE_FIELD_TYPE.user.value:
                                                                                                                let tmpUserValue = tmpValue?JSON.parse(tmpValue):null
                                                                                                                let valueSelected = tmpUserValue?{value: tmpUserValue.id, label: tmpUserValue.name}:null
                                                                                                                return <AsyncSelect value={valueSelected} loadOptions={loadUsers} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e, field)} placeholder='Type your search' isClearable />
                                                                                                            default:
                                                                                                                break;
                                                                                                        }
                                                                                                    })()}
                                                                                                    <div className="p-form-error">{errors["component_"+idxComponent+"_fieldValue_"+field.name]}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }else{
                                                                                        return <div className="p-grid p-fluid">
                                                                                            <div className="p-col-12">
                                                                                                {(() => {
                                                                                                    if(config.displayType===FIELD_DISPLAY_TYPE.radio){
                                                                                                        return (
                                                                                                            <React.Fragment>
                                                                                                                <label className="">{field.label}</label>
                                                                                                                <div className="p-grid p-margin-top-0">
                                                                                                                    <div className="p-col-12">
                                                                                                                        <RadioButton inputId={"rb_0_"+field.name} value={true} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.value, field)} checked={tmpFlagAnswer}/>
                                                                                                                        <label htmlFor={"rb_0_"+field.name} className="p-checkbox-label p-ml-1 p-mr-4">Yes</label>
                                                                                                                        <RadioButton inputId={"rb_1_"+field.name} value={false} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.value, field)} checked={!tmpFlagAnswer}/>
                                                                                                                        <label htmlFor={"rb_1_"+field.name} className="p-checkbox-label p-ml-1">No</label>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        );
                                                                                                    }else{
                                                                                                        return (
                                                                                                            <React.Fragment>
                                                                                                                <Checkbox inputId={"cb_"+field.name} onChange={(e) => onCustomFieldChange(idxContent, idxComponent, idxSetField, e.checked, field)} checked={tmpFlagAnswer} />
                                                                                                                <label htmlFor={"cb_"+field.name} className="p-checkbox-label p-ml-1">{field.label}</label>
                                                                                                            </React.Fragment>
                                                                                                        );
                                                                                                    }
                                                                                                })()}
                                                                                                {config.content &&
                                                                                                    <div className="p-margin-top-10 p-size-13">{config.content}</div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    }        
                                                                                })()}
                                                                            </div>
                                                                        })}  
                                                                        </div>
                                                                    </div>
                                                                })()}
                                                            </React.Fragment>
                                                        }

                                                        {waiverSet && Object.keys(waiverSet).length>0 &&
                                                            <React.Fragment>
                                                                {(()=>{
                                                                    let waiverFields = waiverSet.poolOfFields
                                                                    return <div className="p-col-12">
                                                                        <div className="p-grid">
                                                                            {/* <div className="p-col-12">
                                                                                <span className="p-w-bold p-size-20">{waiverSet.label ? waiverSet.label : 'WAIVER'}</span>
                                                                                <Divider />
                                                                            </div> */}
                                                                            {waiverFields.map((el, idx) => {
                                                                                let field = el.field;
                                                                                let config = field.config;

                                                                                let tmpFieldValue = tmpWaiverFieldValues[idx]
                                                                                let tmpValue = tmpFieldValue?tmpFieldValue.value:''
                                                                                let tmpFlagAnswer = tmpFieldValue?tmpFieldValue.flagAnswer:false

                                                                                if(field.type===ATTRIBUTE_FIELD_TYPE.waiver.value){
                                                                                    return <div className="p-col-12">
                                                                                        <div className="p-grid">
                                                                                            <div className="p-col-12">
                                                                                                <label className="p-w-bold">{el.required&&'*'} {field.label}</label>
                                                                                                {config.openInDialog ?
                                                                                                    <Button label="Read waiver to sign" className="p-button-sm p-ml-2" onClick={() => onOpenToReadWaiver(idxContent, idxComponent, idx, field, config.displayType===FIELD_DISPLAY_TYPE.checkbox?tmpFlagAnswer:tmpValue)} style={{width: 'auto'}} />
                                                                                                    :
                                                                                                    <React.Fragment>
                                                                                                        {config.content &&
                                                                                                            <React.Fragment>
                                                                                                                {el.required && config.requiredReadAllToSignWaiver &&
                                                                                                                    <div className="p-mt-2 p-mb-2"><i className="p-form-error">(*) Must read all conditions</i></div>
                                                                                                                }
                                                                                                                <div className="p-mb-2" style={{fontSize: '13px'}}>
                                                                                                                    <ScrollPanel id={'wv_'+el.id} className="scroll-container p-mt-2" style={{ maxHeight: 150}}>
                                                                                                                        {renderHTML(config.content)}
                                                                                                                    </ScrollPanel>
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        }
                                                                                                    </React.Fragment>
                                                                                                }
                                                                                                <div className="p-mt-3">
                                                                                                    {config.displayType===FIELD_DISPLAY_TYPE.checkbox &&
                                                                                                        <Checkbox inputId={"cb_"+field.id} className="p-mr-1" disabled={el.readOnly} onChange={(e) => onWaiverFieldChange(idxContent, idxComponent, idx, e.checked, field)} checked={tmpFlagAnswer}></Checkbox>
                                                                                                    }
                                                                                                    {config.displayType===FIELD_DISPLAY_TYPE.textbox &&
                                                                                                        <InputText value={tmpValue} className="input-sm" onChange={(e) => onWaiverFieldChange(idxContent, idxComponent, idx, e.target.value, field)}/>
                                                                                                    }
                                                                                                    <label htmlFor={"cb_"+field.id}>{config.waiverPromo}</label>
                                                                                                    {config.collectPhysicalWaiver &&
                                                                                                        <React.Fragment>
                                                                                                            <div className="p-margin-10-0"><input type="file" onChange={(e) => onWaiverFieldChange(idxContent, idxComponent, idx, e.target.files[0], field)} /></div>
                                                                                                            {config.waiverTemplateURL &&
                                                                                                                <React.Fragment>
                                                                                                                    <div className="p-margin-10-0">Download waiver link <a href={`${config.waiverTemplateURL}`} className="nav-link" target="_blank" >here</a></div>
                                                                                                                </React.Fragment>
                                                                                                            }
                                                                                                        </React.Fragment>
                                                                                                    }
                                                                                                    <div className="p-my-2 p-error">{errors["component_"+idxComponent+"_fieldValue_"+field.name]}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                })()}
                                                            </React.Fragment>
                                                        }

                                                        {(()=>{
                                                            if(waiverSet && waiverSet.requiredGuardian 
                                                                && (!personalSet || (personalSet && personalSet.personalField && (!personalSet.personalField.useBirthday || (personalSet.personalField.useBirthday && isYoung))))
                                                            ){
                                                                let guardianPersonalSet = waiverSet.guardianPersonalSet
                                                                let guardianPersonalField = guardianPersonalSet.personalField?guardianPersonalSet.personalField:{}
                                                                let tmpGuardianPersonalInfo = tmpComponent.guardianPersonalInfo?tmpComponent.guardianPersonalInfo:{}

                                                                return <React.Fragment>
                                                                    {(!personalSet || (personalSet && personalSet.personalField && !personalSet.personalField.useBirthday)) &&
                                                                        <React.Fragment>
                                                                            {/* <div className="p-col-12"><Divider /></div> */}
                                                                            <div className="p-col-12 p-md-6">
                                                                                    <div className="p-grid p-fluid">
                                                                                        <div className="p-col-12">
                                                                                            <label>* Parent Guardian</label>
                                                                                        </div>
                                                                                        <div className="p-col-12">
                                                                                            <Dropdown value={tmpComponent.requiredParentGuardian} options={Object.values(PARENT_GUARDIAN_TYPE)} onChange={e => onParentGuardianOptionChange(idxContent, idxComponent, e.value)} placeholder="Please select your legal age" />        
                                                                                            <div className="p-form-error">{errors['component_'+idxComponent+'_required_guardian']}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                        </React.Fragment>
                                                                    }
                                                                    <div className="p-col-12">
                                                                        {(()=>{
                                                                            if((tmpComponent.requiredParentGuardian||isYoung) && guardianPersonalField && Object.keys(guardianPersonalField).length>0){
                                                                                let tmpGuardianErrors = errors['component_'+idxComponent+'_guardian']?errors['component_'+idxComponent+'_guardian']:{}

                                                                                return <div className="p-grid">
                                                                                    {/* <div className="p-col-12">
                                                                                        <span className="p-w-bold p-size-20">{guardianPersonalField&&guardianPersonalField.label ? guardianPersonalField.label : 'Parent/Guardian Participant'}</span>
                                                                                        <Divider />
                                                                                    </div> */}
                                                                                    {guardianPersonalField.useTitleName &&
                                                                                        <div className="p-col-12 p-md-6">
                                                                                            <div className="p-grid p-fluid">
                                                                                                <div className="p-col-12">
                                                                                                    <label>{guardianPersonalField.requiredTitleName && '*'} {guardianPersonalField.titleNameLabel}</label>
                                                                                                </div>
                                                                                                <div className="p-col-12">
                                                                                                    {(()=>{
                                                                                                        let tmpTitleArr = guardianPersonalField.titleNameValues?guardianPersonalField.titleNameValues.map(value=>{return{value:value, label: value}}):[]
                                                                                                        return <Dropdown value={tmpGuardianPersonalInfo.title} options={tmpTitleArr} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent ,'title', e.value)} />
                                                                                                    })()}
                                                                                                    <div className="p-form-error">{tmpGuardianErrors.title}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {guardianPersonalField.useFirstName &&
                                                                                        <div className="p-col-12 p-md-6">
                                                                                            <div className="p-grid p-fluid">
                                                                                                <div className="p-col-12">
                                                                                                    <label>{guardianPersonalField.requiredFirstName && '*'} {guardianPersonalField.firstNameLabel?guardianPersonalField.firstNameLabel:'First Name'}</label>
                                                                                                </div>
                                                                                                <div className="p-col-12">
                                                                                                    <InputText value={tmpGuardianPersonalInfo.firstName} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent ,'firstName', e.target.value)} />
                                                                                                    <div className="p-form-error">{tmpGuardianErrors.firstName}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {guardianPersonalField.useLastName &&
                                                                                        <div className="p-col-12 p-md-6">
                                                                                            <div className="p-grid p-fluid">
                                                                                                <div className="p-col-12">
                                                                                                    <label>{guardianPersonalField.requiredLastName && '*'} {guardianPersonalField.lastNameLabel?guardianPersonalField.lastNameLabel:'Last Name'}</label>
                                                                                                </div>
                                                                                                <div className="p-col-12">
                                                                                                    <InputText value={tmpGuardianPersonalInfo.lastName} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'lastName', e.target.value)} />
                                                                                                    <div className="p-form-error">{tmpGuardianErrors.lastName}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {guardianPersonalField.useEmail &&
                                                                                        <div className="p-col-12 p-md-6">
                                                                                            <div className="p-grid p-fluid">
                                                                                                <div className="p-col-12">
                                                                                                    <label>{guardianPersonalField.requiredEmail && '*'} Email</label>
                                                                                                </div>
                                                                                                <div className="p-col-12">
                                                                                                    <InputText value={tmpGuardianPersonalInfo.email} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'email', e.target.value)} />
                                                                                                    <div className="p-form-error">{tmpGuardianErrors.email}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {guardianPersonalField.useGender &&
                                                                                        <div className="p-col-12 p-md-6">
                                                                                            <div className="p-grid p-fluid">
                                                                                                <div className="p-col-12">
                                                                                                    <label>{guardianPersonalField.requiredGender && '*'} {guardianPersonalField.genderLabel}</label>
                                                                                                </div>
                                                                                                <div className="p-col-12">
                                                                                                    <Dropdown value={tmpGuardianPersonalInfo.gender} options={GENDERS} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'gender', e.value)} />
                                                                                                    <div className="p-form-error">{tmpGuardianErrors.gender}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {guardianPersonalField.useBirthday &&
                                                                                        <div className="p-col-12 p-md-6">
                                                                                            <div className="p-grid p-fluid">
                                                                                                <div className="p-col-12">
                                                                                                    <label>{guardianPersonalField.requiredBirthday && '*'} {guardianPersonalField.birthdayLabel}</label>
                                                                                                </div>
                                                                                                <div className="p-col-12">
                                                                                                    <Calendar value={tmpGuardianPersonalInfo.birthdayValue} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'birthday', e.value)} showIcon={true} dateFormat="mm-dd-yy" mask="99-99-9999" monthNavigator yearNavigator yearRange={(new Date().getFullYear()-120)+":"+(new Date().getFullYear())} />
                                                                                                    <div className="p-form-error">{tmpGuardianErrors.birthday}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {guardianPersonalField.usePhone &&
                                                                                        <div className="p-col-12 p-md-6">
                                                                                            <div className="p-grid p-fluid">
                                                                                                <div className="p-col-12">
                                                                                                    <label>{guardianPersonalField.requiredPhone && '*'} {guardianPersonalField.phoneLabel}</label>
                                                                                                </div>
                                                                                                <div className="p-col-12">
                                                                                                    <PhoneInput value={tmpGuardianPersonalInfo.phone} defaultCountry={defaultCountry} onChange={(value) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'phone', value)} className="p-component p-inputtext" style={{width: "100%"}}/>
                                                                                                    <div className="p-form-error">{tmpGuardianErrors.phone}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {guardianPersonalField.usePassportNRIC &&
                                                                                        <div className="p-col-12 p-md-6">
                                                                                            <div className="p-grid p-fluid">
                                                                                                <div className="p-col-12">
                                                                                                    <label>{guardianPersonalField.requiredPassportNRIC && '*'} {guardianPersonalField.passportNRICLabel}</label>
                                                                                                </div>
                                                                                                <div className="p-col-12">
                                                                                                    <InputText value={tmpGuardianPersonalInfo.passportNRIC} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'passportNRIC', e.target.value)} />
                                                                                                    <div className="p-form-error">{tmpGuardianErrors.passportNRIC}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {guardianPersonalField.useNationality &&
                                                                                        <div className="p-col-12 p-md-6">
                                                                                            <div className="p-grid p-fluid">
                                                                                                <div className="p-col-12">
                                                                                                    <label>{guardianPersonalField.requiredNationality && '*'} {guardianPersonalField.nationalityLabel}</label>
                                                                                                </div>
                                                                                                <div className="p-col-12">
                                                                                                    <Dropdown value={tmpGuardianPersonalInfo.nationalityCode} options={countries} filter onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'nationalityCode', e.target.value)} />
                                                                                                    <div className="p-form-error">{tmpGuardianErrors.nationality}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {guardianPersonalField.useRegisterType &&
                                                                                        <div className="p-col-12 p-md-6">
                                                                                            <div className="p-grid p-fluid">
                                                                                                <div className="p-col-12">
                                                                                                    <label>{guardianPersonalField.requiredRegisterType && '*'} {guardianPersonalField.registerTypeLabel}</label>
                                                                                                </div>
                                                                                                <div className="p-col-12">
                                                                                                    {(()=>{
                                                                                                        let valueList = guardianPersonalField.registerTypeList?guardianPersonalField.registerTypeList:[]
                                                                                                        valueList.map(value => {return {value: value, label: value}})
                                                                                                        return <Dropdown value={tmpGuardianPersonalInfo.registerType} options={valueList} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'registerType', e.value)} />
                                                                                                    })()}
                                                                                                    <div className="p-form-error">{tmpGuardianErrors.registerType}</div>
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                    }
                                                                                    {guardianPersonalField.useAddress &&
                                                                                        <React.Fragment>
                                                                                            <div className="p-col-12">
                                                                                                <span className="p-w-bold p-size-20">Address</span>
                                                                                                {/* <Divider /> */}
                                                                                            </div>
                                                                                            <div className="p-col-12 p-md-6">
                                                                                                <div className="p-grid p-fluid">
                                                                                                    <div className="p-col-12">
                                                                                                        <label>* Address 1</label>
                                                                                                    </div>
                                                                                                    <div className="p-col-12">
                                                                                                        <InputText value={tmpGuardianPersonalInfo.address1} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'address1', e.target.value)} />
                                                                                                        <div className="p-form-error">{tmpGuardianErrors.address1}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-md-6">
                                                                                                <div className="p-grid p-fluid">
                                                                                                    <div className="p-col-12">
                                                                                                        <label>Address 2</label>
                                                                                                    </div>
                                                                                                    <div className="p-col-12">
                                                                                                        <InputText value={tmpGuardianPersonalInfo.address2} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'address2', e.target.value)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-md-6">
                                                                                                <div className="p-grid p-fluid">
                                                                                                    <div className="p-col-12">
                                                                                                        <label>* City</label>
                                                                                                    </div>
                                                                                                    <div className="p-col-12">
                                                                                                        <InputText value={tmpGuardianPersonalInfo.city} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'city', e.target.value)} />
                                                                                                        <div className="p-form-error">{tmpGuardianErrors.city}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-md-6">
                                                                                                <div className="p-grid p-fluid">
                                                                                                    <div className="p-col-12">
                                                                                                        <label>* Zip Code</label>
                                                                                                    </div>
                                                                                                    <div className="p-col-12">
                                                                                                        <InputText value={tmpGuardianPersonalInfo.zipCode} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'zipCode', e.target.value)} />
                                                                                                        <div className="p-form-error">{tmpGuardianErrors.zipCode}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-md-6">
                                                                                                <div className="p-grid p-fluid">
                                                                                                    <div className="p-col-12">
                                                                                                        <label>Country</label>
                                                                                                    </div>
                                                                                                    <div className="p-col-12">
                                                                                                        <Dropdown value={tmpGuardianPersonalInfo.countryCode} options={countries} filter onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'countryCode', e.target.value)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-md-6">
                                                                                                <div className="p-grid p-fluid">
                                                                                                    <div className="p-col-12">
                                                                                                        <label>State/Province</label>
                                                                                                    </div>
                                                                                                    <div className="p-col-12">
                                                                                                        <Dropdown value={tmpGuardianPersonalInfo.stateCode} options={countryStatesForGuardian} filter onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'stateCode', e.target.value)} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                    {guardianPersonalField.useEmerContact &&
                                                                                        <React.Fragment>
                                                                                            <div className="p-col-12">
                                                                                                <span className="p-w-bold p-size-20">EMERGENCY</span>
                                                                                                {/* <Divider /> */}
                                                                                            </div>
                                                                                            <div className="p-col-12 p-md-6">
                                                                                                <div className="p-grid p-fluid">
                                                                                                    <div className="p-col-12">
                                                                                                        <label>{guardianPersonalField.requiredEmerContact && '*'} {guardianPersonalField.emerContactNameLabel}</label>
                                                                                                    </div>
                                                                                                    <div className="p-col-12">
                                                                                                        <InputText value={tmpGuardianPersonalInfo.emeContactName} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'emeContactName', e.target.value)} />
                                                                                                        <div className="p-form-error">{tmpGuardianErrors.emeContactName}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-md-6">
                                                                                                <div className="p-grid p-fluid">
                                                                                                    <div className="p-col-12">
                                                                                                        <label>{guardianPersonalField.requiredEmerContact && '*'} {guardianPersonalField.emerContactPhoneLabel}</label>
                                                                                                    </div>
                                                                                                    <div className="p-col-12">
                                                                                                        <PhoneInput value={tmpGuardianPersonalInfo.emeContactPhone} defaultCountry={defaultCountry} onChange={(value) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'emeContactPhone', value)} className="p-component p-inputtext" style={{width: "100%"}}/>
                                                                                                        <div className="p-form-error">{tmpGuardianErrors.emeContactPhone}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="p-col-12 p-md-6">
                                                                                                <div className="p-grid p-fluid">
                                                                                                    <div className="p-col-12">
                                                                                                        <label>{guardianPersonalField.requiredEmerContact && '*'} {guardianPersonalField.emerContactEmailLabel}</label>
                                                                                                    </div>
                                                                                                    <div className="p-col-12">
                                                                                                        <InputText keyfilter="email" value={tmpGuardianPersonalInfo.emeContactEmail} onChange={(e) => onGuardianPersonalFieldChange(idxContent, idxComponent, 'emeContactEmail', e.target.value)} />
                                                                                                        <div className="p-form-error">{tmpGuardianErrors.emeContactEmail}</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    }
                                                                                    <div class="p-col-12">
                                                                                        <label class="p-w-bold">* Waiver Agreement</label>
                                                                                        <div class="p-mt-3">
                                                                                            <Checkbox inputId="ckGuardianAgreement" checked={tmpComponent.guardianAgreement} onChange={e => onParentGuardianAgreeChange(idxContent, idxComponent, e.checked)} />
                                                                                            <label htmlFor="ckGuardianAgreement" className="p-ml-3">My Parent/Guardian have read and agree to this Waiver & Indemnity Agreement</label>
                                                                                            <div className="p-my-2 p-error">{errors['component_'+idxComponent+'_guardian_agreement']}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        })()}
                                                                    </div>    
                                                                </React.Fragment>
                                                            }
                                                        })()}
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                    })}
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-md-6">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={closeForm}/>
                </div>
                <div className="p-col-12 p-md-6 p-r">
                        {step > 1 &&
                            <Button label="Previous" icon="pi pi-arrow-left" iconPos="left" className="" onClick={(e) => handlePrevious()}/>
                        }
                        <Button className="" onClick={(e) => handleSubmitAndNext()}
                            label={application.contents&&application.contents.length>1&&application.contents.length!==step?"Next":"Submit"} 
                            icon={application.contents&&application.contents.length>1&&application.contents.length!==step?"pi pi-arrow-right":"pi pi-check"}
                            iconPos={application.contents&&application.contents.length>1&&application.contents.length!==step?"right":"left"}
                        />
                    </div>
            </div>
        </Sidebar>
    )
})
