import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Link, useRouteMatch } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Button } from 'primereact/button';
import fileDownload from 'js-file-download';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showConfirmNotify, showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import { dowloadFileImportErrors } from '../tool-util/download/DownloadServices';
import { DATETIME_FORMAT_DISPLAY, MAX_FILE_SIZE, OBJECT_TYPE } from '../../constants';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { getPageUploads } from '../tool-util/upload/UploadServices';
import { importParticipant } from '../../constituent-management/participant/ParticipantServices';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';
import { importAvailability } from '../../pim/product/ProductServices';
import { importMemberships } from '../../constituent-management/membership/MembershipServices';
import { getTemplateBatch } from '../template-batch/TemplateBatchServices';
import { uploadTemplateBatch } from '../template-batch/TemplateBatchServices';
import { dowloadTemplateBatch } from '../template-batch/TemplateBatchServices';

export const ImportListView = (props) => {
	const match = useRouteMatch();

	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'created',
		sortOrder: -1,
	});
	const [file, setFile] = useState(null);
	const [errors, setErrors] = useState({});
	const [isLoadTable, setLoadTable] = useState(true);
	const [objectType, setObjectType] = useState(props.type);
	const [isCourseProgram, setCourseProgram] = useState(props.extra1);
	const [templateBatch, setTemplateBatch] = useState(null);
	const [fileTemplate, setFileTemplate] = useState(null);

	const inputUpload = useRef(null);
	const inputTemplateUpload = useRef(null);

	useEffect(() => {
		setObjectType(props.type);
		setCourseProgram(props.extra1);
	}, [props.type, props.extra1]);

	useEffect(() => {
		setLoadTable(true);
		loadTemplateBatch();
	}, [objectType, isCourseProgram]);

	useEffect(() => {
		if (isLoadTable) loadPageUpload();
	}, [isLoadTable]);

	const loadPageUpload = () => {
		getPageUploads({ type: objectType, isCourse: isCourseProgram }, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder).then((res) => {
			setDataList(res.content);
			setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
			setLoadTable(false);
		});
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const handleUpload = async () => {
		showloading();

		if (file) {
			let fileSize = file.size;
			if (fileSize > MAX_FILE_SIZE) {
				setErrors({ file: 'Size not allowed (maximum: 100MB)' });
				showErrorNotify('Cannot perform action');
				stoploading();
				return;
			}
		}

		setErrors({});

		let res = null;

		switch (objectType) {
			case OBJECT_TYPE.participant:
				res = await importParticipant(file, isCourseProgram).finally(() => stoploading());
				break;
			case OBJECT_TYPE.membership:
				res = await importMemberships(file).finally(() => stoploading());
				break;
			case OBJECT_TYPE.product_session:
				res = await importAvailability(file, isCourseProgram).finally(() => stoploading());
				break;
			default:
				break;
		}

		if (!res.errorCode) {
			if (inputUpload && inputUpload.current) inputUpload.current.value = '';
			setFile(null);
			setLoadTable(true);
			showSuccessNotify('Action Submitted');
		} else {
			showErrorNotify('Cannot perform action');
			if (res.errorCode === 400) setErrors(res.errorObj);
		}
	};

	const handleDownloadFileError = (data) => {
		dowloadFileImportErrors(data.id, objectType).then((res) => {
			if (!res.errorCode) {
				fileDownload(res, data.path);
			} else {
				showErrorNotify('No File Found');
			}
		});
	};

	const loadTemplateBatch = async () => {
		getTemplateBatch(objectType, null, isCourseProgram).then((res) => {
			if (!res.errorCode) {
				setTemplateBatch(res);
			} else {
				setTemplateBatch(null);
			}
		});
	};

	const handleUploadTemplateBatch = () => {
		showloading();

		if (fileTemplate) {
			let fileSize = fileTemplate.size;
			if (fileSize > MAX_FILE_SIZE) {
				showErrorNotify('Size not allowed (maximum: 100MB)');
				stoploading();
				return;
			}
		}
		uploadTemplateBatch(objectType, null, isCourseProgram, fileTemplate)
			.then((res) => {
				if (!res.errorCode) {
					setTemplateBatch(res);
					if (inputTemplateUpload && inputTemplateUpload.current) inputTemplateUpload.current.value = '';
					setFileTemplate(null);
					showSuccessNotify('Action Submitted');
				} else {
					showErrorNotify(res.errorMessage);
				}
			})
			.finally(() => stoploading());
	};

	const handleDownloadTemplateBatch = (data) => {
		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = `template-batch_${suf}.xlsx`;

		dowloadTemplateBatch(objectType, null, isCourseProgram).then((res) => {
			if (!res.errorCode) {
				fileDownload(res, fileName);
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const onUploadTemplate = () => {
		if (!templateBatch || !templateBatch.template) {
			handleUploadTemplateBatch();
		} else {
			showConfirmNotify({
				message: 'You will definitely upload a template file. If you continue, the previous template file will be overwritten.!',
				accept: () => handleUploadTemplateBatch(),
			});
		}
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const renderPageHeaderName = (
		<React.Fragment>
			{(() => {
				switch (objectType) {
					case OBJECT_TYPE.participant:
						if (isCourseProgram) {
							return 'Import Enrollment';
						}
						return 'Import Participant';
					case OBJECT_TYPE.membership:
						return 'Import Membership';
					case OBJECT_TYPE.product_session:
						return 'Import Session';
					default:
						return 'Import';
				}
			})()}
		</React.Fragment>
	);

	const tblHeader = (
		<TableHeader
			title={renderPageHeaderName}
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
		/>
	);

	const isWrite = () => {
		switch (objectType) {
			case OBJECT_TYPE.participant:
				if (isCourseProgram) {
					return isScpAdmin() || hasRole([ROLES_CONFIG.TRAINING_IMPORT_PARTICIPANT_W]);
				} else {
					return isScpAdmin() || hasRole([ROLES_CONFIG.REPORT_IMPORT_PARTICIPANT_W]);
				}
			case OBJECT_TYPE.product_session:
				return isScpAdmin() || hasRole([ROLES_CONFIG.TRAINING_IMPORT_SESSION_W]);
			default:
				return isScpAdmin();
		}
	};

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>{renderPageHeaderName}</BreadcrumbsItem>

			<div className='p-col-12'>
				{isWrite() && (
					<React.Fragment>
						<div className='p-padding-10-0'>
							<div className='p-margin-bottom-10'>Note: Please import according to the specified template file!</div>
							<input
								ref={inputUpload}
								type='file'
								className='p-margin-bottom-10'
								accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
								onChange={(e) => setFile(e.target.files[0])}
							/>
							<Button label='Import' icon='pi pi-upload' disabled={!file} onClick={() => handleUpload()} />
							<div className='p-form-error'>{errors.file}</div>
						</div>

						<hr />

						{templateBatch?.template && (
							<div className='p-margin-bottom-10'>
								Download template file here.
								<Button tooltip='Download Template' icon='pi pi-download' className='p-margin-left-10 btn-sm' onClick={() => handleDownloadTemplateBatch()} />
							</div>
						)}
						{isScpAdmin() && (
							<div>
								<div className='p-margin-right-5'>Upload template file here.</div>
								<input
									ref={inputTemplateUpload}
									type='file'
									className='p-margin-bottom-10'
									accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
									onChange={(e) => setFileTemplate(e.target.files[0])}
								/>
								<Button tooltip='Upload Template' icon='pi pi-upload' className='btn-sm' disabled={!fileTemplate} onClick={() => onUploadTemplate()} />
							</div>
						)}
					</React.Fragment>
				)}

				<DataTable
					emptyMessage="There's no record found"
					header={tblHeader}
					value={dataList}
					lazy={true}
					loading={isLoadTable}
					paginator={true}
					first={pageable.page * pageable.rows}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					onPage={onPage}
					onSort={onSort}
					responsive={true}
				>
					<Column header='Creator' body={(rowData) => rowData.user?.name} />
					<Column field='rows' header='No.Rows' />
					<Column field='rowErrors' header='No.Error' />
					<Column field='created' header='Import On' sortable body={(rowData) => <span>{moment(rowData.created).format(DATETIME_FORMAT_DISPLAY)}</span>} />
					<Column
						header='Download Error'
						headerStyle={{ width: '12em' }}
						body={(rowData) => {
							return <Button icon='pi pi-download' onClick={() => handleDownloadFileError(rowData)} />;
						}}
					/>
				</DataTable>
			</div>
		</div>
	);
};
