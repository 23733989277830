import axios from 'axios';
import { useId } from 'react';
import { API_BASE_URL, SCP_PATH } from '../../constants';

const ATTENDANCE_SERVICE_URL = API_BASE_URL + SCP_PATH + '/attendances';

export function getPageAttendances(filter, page, size, sortField, sortOrder) {
	const order = sortOrder === 0 ? 'asc' : sortOrder === -1 ? 'desc' : '';
	const sort = sortField ? `${sortField},${order}` : '';
	return axios
		.post(`${ATTENDANCE_SERVICE_URL}/page`, filter, {
			params: { page, size, sort },
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getListAttendanceToCheckin(filter) {
	return axios
		.post(`${ATTENDANCE_SERVICE_URL}/check-in/list`, filter)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function checkInAttendance(productId, sessionId, userId, scheduleId, date) {
	return axios
		.post(`${ATTENDANCE_SERVICE_URL}/${productId}/${sessionId}/check-in`, null, {
			params: {
				userId: userId,
				scheduleId: scheduleId,
				date: date,
				isScanByCode: false,
			},
		})
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function updateAttendanceCheckedIn(id, checkedIn) {
	return axios
		.post(`${ATTENDANCE_SERVICE_URL}/${id}/save-check-in`, null, { params: { checkedIn } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function removeAttendee(id) {
	return axios
		.delete(`${ATTENDANCE_SERVICE_URL}/${id}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getPageParticipantAttendance(filter, page, size, sortField, sortOrder) {
	const order = sortOrder === 0 ? 'asc' : sortOrder === -1 ? 'desc' : '';
	const sort = sortField ? `${sortField},${order}` : '';
	return axios
		.post(`${ATTENDANCE_SERVICE_URL}/participants/page`, filter, { params: { page, size, sort } })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function getParticipantAttendance(userId) {
	return axios
		.get(`${ATTENDANCE_SERVICE_URL}/participants/${userId}`)
		.then((res) => res.data)
		.catch((error) => console.log(error));
}

export function exportParticipantAttendance(filter) {
	return axios
		.post(`${ATTENDANCE_SERVICE_URL}/participants/export`, filter, { responseType: 'arraybuffer' })
		.then((res) => res.data)
		.catch((error) => console.log(error));
}
