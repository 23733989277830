import React, {Component} from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

export class NotifyChangeTab extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const footerChangeTab = (
            <div>
                <Button label="Save Changes" icon="pi pi-check" onClick={this.props.saveChangeTab}/>
                <Button label="Cancel" onClick={this.props.discardTab} />
                {/* <Button label="Discard Changes" icon="pi-md-close" onClick={this.props.discardTab}/>
                <Button label="Review Changes" className="p-button-secondary" onClick={this.props.cancelChangeTab}/> */}
            </div>
        );

        return (
            <Dialog footer={footerChangeTab} visible={this.props.visible} contentStyle={styles.dialogContent} responsive={true} modal={true} showHeader={false} dismissableMask={true} onHide={this.props.cancelChangeTab}>
                Do you want to save the changes?
            </Dialog>
        );
    }
}

const styles = {
    dialogContent: {
        maxHeight: '50px',
        minHeight: '50px',
        overflow: 'hidden',
    }
}