import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { scpGetListChangeLogs } from './AuditLogServices';
import { showloading, stoploading } from '../core/service/LoadingService';
import { TableHeader } from '../core/components/datatable-tools/TableHeader';
import moment from 'moment';
import { DATETIME_FORMAT_DISPLAY, OBJECT_TYPE } from '../constants';
import { Fieldset } from 'primereact/fieldset';

const VALUE_CHANGE_TYPE = {
	single: 'single',
	list: 'list',
	map: 'map',
	reference: 'reference',
};

const VALUE_CHANGE_ACTION_TYPE = {
	ADDED: 'ADDED',
	CHANGED: 'CHANGED',
	REMOVED: 'REMOVED',
};

export const AuditLogPopup = forwardRef((props, ref) => {
	const [header, setHeader] = useState('Change Log');
	const [objectId, setObjectId] = useState(null);
	const [objectType, setObjectType] = useState(null);
	const [objectName, setObjectName] = useState(null);
	const [commitId, setCommitId] = useState(null);
	const [size, setSize] = useState(100);
	const [dataList, setDataList] = useState([]);
	const [visible, setVisible] = useState(false);
	const [isLoadTable, setLoadTable] = useState(false);

	useImperativeHandle(ref, () => ({
		openForm(objectId, objectType, commitId) {
			setObjectId(objectId);
			setObjectType(objectType);
			setCommitId(commitId);
			setLoadTable(true);
			setVisible(true);
		},
		closeForm() {
			closeForm();
		},
	}));

	useEffect(() => {
		if (isLoadTable) {
			loadLogs();
		}
	}, [isLoadTable]);

	const loadLogs = async () => {
		showloading();

		scpGetListChangeLogs(objectType, { objectId: objectId, commitId: commitId }, size, true)
			.then((res) => {
				setDataList(res);
				setObjectName(res.length > 0 ? res[0].objectName : '');
			})
			.finally(() => {
				setLoadTable(false);
				stoploading();
			});
	};

	const closeForm = () => {
		setObjectId(null);
		setObjectType(null);
		setObjectName(null);
		setCommitId(null);
		setDataList([]);
		setLoadTable(false);
		setVisible(false);
	};

	const onChangePageLength = (value) => {
		setSize(value);
	};

	const tblHeader = <TableHeader title='' changePageLength={(e) => onChangePageLength(e)} refresh={() => setLoadTable(true)} />;

	const renderPropertyName = (propertyName) => {
		if (!objectType || !propertyName) return '';
		switch (objectType) {
			case OBJECT_TYPE.contact:
				return contactPropertyName(propertyName);
			case OBJECT_TYPE.membership:
				return membershipPropertyName(propertyName);
			case OBJECT_TYPE.participant:
				return participantPropertyName(propertyName);
			case OBJECT_TYPE.participant_attendance:
				return attendancePropertyName(propertyName);
			case OBJECT_TYPE.product:
				return productPropertyName(propertyName);
			case OBJECT_TYPE.product_session:
				return sessionPropertyName(propertyName);
			default:
				break;
		}
	};

	const productPropertyName = (propertyName) => {
		switch (propertyName) {
			case 'code':
				return 'Code';
			case 'name':
				return 'Name';
			case 'images':
				return 'Images';
			case 'description':
				return 'Description';
			case 'weight':
				return 'Weight';
			case 'prices':
				return 'Prices';
			case 'membershipType':
				return 'Membership Type';
			case 'price':
				return 'Price';
			case 'applyFor':
				return 'Apply for';
			case 'unit':
				return 'Unit';
			case 'categories':
				return 'Categories';
			case 'category':
				return 'Category';
			case 'displayOnPanel':
				return 'Show on display panel';
			case 'recommended':
				return 'Recommended';
			default:
				break;
		}
	};

	const contactPropertyName = (propertyName) => {
		switch (propertyName) {
			case 'firstName':
				return 'First Name';
			case 'lastName':
				return 'Last Name';
			case 'email':
				return 'Email';
			case 'cellPhone':
				return 'Phone';
			case 'birthday':
				return 'Birthday';
			case 'nationality':
				return 'Nationality';
			case 'primaryAddress':
				return 'Address';
			case 'avatar':
				return 'Avatar';
			case 'blockBooking':
				return 'Block Booking';
			default:
				break;
		}
	};

	const membershipPropertyName = (propertyName) => {
		switch (propertyName) {
			case 'status':
				return 'Status';
			case 'joinDate':
				return 'Join Date';
			case 'endDate':
				return 'End Date';
			case 'verified':
				return 'Verified';
			default:
				break;
		}
	};

	const participantPropertyName = (propertyName) => {
		switch (propertyName) {
			case 'status':
				return 'Status';
			case 'start':
				return 'Start Date';
			case 'end':
				return 'End Date';
			default:
				break;
		}
	};

	const attendancePropertyName = (propertyName) => {
		switch (propertyName) {
			case 'date':
				return 'Date';
			case 'timeIn':
				return 'Time In';
			case 'timeOut':
				return 'Time Out';
			case 'checkedIn':
				return 'Check-in';
			default:
				break;
		}
	};

	const sessionPropertyName = (propertyName) => {
		switch (propertyName) {
			case 'membershipLimitIds':
				return 'Membership Limit';
			case 'minQty':
				return 'Min q.ty';
			case 'maxQty':
				return 'Max q.ty';
			case 'name':
				return 'name';
			default:
				break;
		}
	};

	return (
		<Sidebar visible={visible} style={{ overflowY: 'auto' }} className='p-sidebar-lg' position='right' blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
			<div className='p-d-flex p-justify-between '>
				<h2 className='p-margin-top-10'>{header}</h2>
				<Button label='' icon='pi pi-times' className='p-button-secondary' onClick={closeForm} />
			</div>

			<div className='p-sidebar-line p-mb-3'></div>

			<div className='p-grid'>
				<div className='p-col-12'>
					<Fieldset legend='Basic Information'>
						<div className='p-grid'>
							<div className='p-col-12'>Name: {objectName}</div>
						</div>
					</Fieldset>
				</div>
				<div className='p-col-12'>
					<DataTable header={tblHeader} value={dataList}>
						<Column header='Author' body={(rowData) => rowData.author?.name} />
						<Column header='Action Type' field='typeName' headerStyle={{ width: '10em' }} />
						<Column header='Commit On' headerStyle={{ width: '15em' }} body={(rowData) => rowData.commitOn && moment(rowData.commitOn, 'YYYY-MM-DD HH:mm').format(DATETIME_FORMAT_DISPLAY)} />
						<Column
							header='Changes'
							body={(rowData) => {
								return rowData.changeLogs.map((change, idx) => {
									let propertyName = renderPropertyName(change.propertyName);
									if (propertyName) {
										return (
											<div>
												{(() => {
													if (change.valueType === VALUE_CHANGE_TYPE.single) {
														let tmpValueLeft = change.valueLeft === true ? 'Yes' : change.valueLeft === false ? 'No' : change.valueLeft;
														let tmpValueRight = change.valueRight === true ? 'Yes' : change.valueRight === false ? 'No' : change.valueRight;
														if (change.actionType === VALUE_CHANGE_ACTION_TYPE.CHANGED) {
															if (tmpValueLeft !== tmpValueRight) {
																return (
																	<div className='p-margin-bottom-10'>
																		<b>{propertyName}: </b> {`'${tmpValueLeft ?? 'empty'}' changed to '${tmpValueRight ?? 'empty'}'`}
																	</div>
																);
															}
														} else {
															return (
																<div className='p-margin-bottom-10'>
																	<b>{propertyName}: </b> {change.actionType.toLowerCase()}
																</div>
															);
														}
													} else if (change.valueType === VALUE_CHANGE_TYPE.list) {
														return (
															<div className='p-margin-bottom-10'>
																<div>
																	<b>{propertyName}:</b> {change.actionType?.toLowerCase()}
																</div>
																{change.changeLogs &&
																	change.changeLogs.map((childChange) => {
																		let childPropertyName = renderPropertyName(childChange.propertyName);
																		let tmpChildValueLeft = childChange.valueLeft === true ? 'Yes' : childChange.valueLeft === false ? 'No' : childChange.valueLeft;
																		let tmpChildValueRight = childChange.valueRight === true ? 'Yes' : childChange.valueRight === false ? 'No' : childChange.valueRight;

																		if (childPropertyName) {
																			if (childChange.actionType === VALUE_CHANGE_ACTION_TYPE.CHANGED) {
																				if (tmpChildValueLeft && tmpChildValueRight && tmpChildValueLeft !== tmpChildValueRight) {
																					return (
																						<div className='p-margin-bottom-5 p-margin-top-5 p-margin-left-10'>
																							<b>{childPropertyName}: </b> {(childChange.index === 0 || childChange.index) && <i>(ordinal {childChange.index + 1})</i>}
																							<div>{`'${tmpChildValueLeft}' changed to '${tmpChildValueRight}'`}</div>
																						</div>
																					);
																				}
																			} else {
																				return (
																					<div className='p-margin-bottom-5 p-margin-top-5 p-margin-left-10'>
																						<b>{childPropertyName}: </b>
																						{(childChange.index === 0 || childChange.index) && <i>(ordinal {childChange.index + 1})</i>} {childChange.actionType.toLowerCase()}
																					</div>
																				);
																			}
																		}
																	})}
															</div>
														);
													} else {
														return (
															<div className='p-margin-bottom-10'>
																<b>{propertyName}: </b> changed
															</div>
														);
													}
												})()}
											</div>
										);
									}
								});
							}}
						/>
					</DataTable>
				</div>
			</div>

			<div className='p-sidebar-line p-my-3'></div>

			<div className='p-grid'>
				<div className='p-col-12 p-d-flex p-justify-between'>
					<Button label='Cancel' icon='pi-md-close' className='p-button-secondary' onClick={closeForm} />
				</div>
			</div>
		</Sidebar>
	);
});
