import React, { forwardRef, useState, useImperativeHandle } from "react";
import classNames from "classnames";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Sidebar } from "primereact/sidebar";
import { showNotification, showSuccessNotify, showErrorNotify } from '../../core/service/NotificationService';
import {saveSliderItem} from './SliderServices';
import { MAX_FILE_SIZE, UPLOAD_TYPE } from "../../constants.js";
import { InputTextarea } from "primereact/inputtextarea";
import { showloading, stoploading } from "../../core/service/LoadingService";


export const SliderItemForm = forwardRef((props, ref) => {
    const [header, setHeader] = useState("New Menu")
    const [visible, setVisible] = useState(false)
    const [frmData, setFrmData] = useState({})
    const [errors, setErrors] = useState({})

    const [sliderId, setSliderId] = useState()
    
    useImperativeHandle(ref, () => ({
        openForm(sId, e){
            setHeader((e?'Edit':'New') + ' Menu')
            setVisible(true)
            setFrmData(popularFormData(e))
            setSliderId(sId)
        },
        closeForm(){
            closeForm()
        }
    }))

    const popularFormData = (data) => {
        return {
            id: data ? data.id : null,
            name: data ? data.name : '',
            imageInfo: data&&data.imageInfo&&Object.keys(data.imageInfo).length>0?data.imageInfo:null,
            uploadType: data&&data.imageInfo&&data.imageInfo.fileName?UPLOAD_TYPE.upload_file.value:UPLOAD_TYPE.external_url.value,
            externalImageLink: data&&data.imageInfo? (data.imageInfo.fileName?'':data.imageInfo.url) : '',
            showContent: data ? data.showContent : false,
            title: data && data.title ? data.title : '',
            description: data && data.description ? data.description: '',
            showButton: data && data.showButton ? data.showButton : false,
            buttonText: data && data.buttonText ? data.buttonText : '',
            active: data ? data.active : false,
            link: data && data.link ? data.link : '',
            weight: data && data.weight ? data.weight : 0
        }
    }

    const closeForm = () => {
        setVisible(false)
        setFrmData({})
        setErrors({})
    }

    const handleSaveSliderItem = () => {
        showloading()
        setErrors({})

        if(frmData.file && frmData.uploadType===UPLOAD_TYPE.upload_file.value){
            let fileSize = frmData.file.size
            if(fileSize > MAX_FILE_SIZE){
                setErrors({image: 'Size not allowed (maximum: 100MB)'})
                showErrorNotify('Cannot perform action')
                stoploading()
                return
            }
        }

        saveSliderItem(sliderId, frmData)
        .then(res => {
            if (!res.errorCode) {
                if(props.refreshTable)
                    props.refreshTable();
                closeForm()
                showSuccessNotify('Action submitted');
            } else {
                showErrorNotify('Cannot perform action');
                if(res.errorCode === 400)
                    setErrors(res.errorObj)
            }
        })
        .catch(() => showErrorNotify('System error!'))
        .finally(() => stoploading)
    }

    return (
        <Sidebar visible={visible} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} showCloseIcon={false} dismissable={true} onHide={closeForm}>
            <div className="p-d-flex p-justify-between ">
                <h2 className="p-margin-top-10">{header}</h2>
                <Button label="" icon="pi pi-times" className="p-button-secondary" onClick={closeForm}/>
            </div>

            <div className="p-sidebar-line p-mb-3"></div>

            <div className="p-grid p-fluid form-group">
                <div className="p-col-12">
                    <label className="p-label">* Name (for manage)</label>
                    <InputText value={frmData.name} onChange={e => setFrmData({...frmData, name: e.target.value})} />
                    <span className="p-form-error">{errors.name}</span>
                </div>
                <div className="p-col-12" >
                    <label className="p-label">* Image URL</label>
                    <div className="p-inputgroup">
                        {(!frmData.uploadType || frmData.uploadType===UPLOAD_TYPE.external_url.value) ?
                            <React.Fragment>
                                <InputText value={frmData.externalImageLink} onChange={(e) => setFrmData({...frmData, externalImageLink: e.target.value})} />
                                <Button tooltip="Switch to upload" icon="pi pi-upload" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.upload_file.value})} />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                {frmData.imageInfo && frmData.imageInfo.fileName &&
                                    <span className="p-inputgroup-addon"><img src={frmData.imageInfo.url} alt={frmData.imageInfo.fileName} style={{width: 25, height: 25}} /></span>
                                }
                                <input className="p-inputtext p-component" type="file" accept="image/png, image/jpg, image/jpeg" onChange={(e) => setFrmData({...frmData, file: e.target.files[0]})} />
                                <Button tooltip="Switch to url" icon="pi pi-external-link" style={{borderRadius: 0}} onClick={() => setFrmData({...frmData, uploadType: UPLOAD_TYPE.external_url.value, file: null})} />
                            </React.Fragment> 
                        }
                    </div>
                    <span className="p-form-error">{errors.image}</span>
                </div>
                <div className="p-col-12">
                    <InputSwitch checked={frmData.showContent} onChange={(e) => setFrmData({...frmData, showContent: e.value})} />
                    <label className="p-margin-left-10">Show slide content?</label>
                </div>
                {frmData.showContent && 
                <React.Fragment>
                    <div className="p-col-12">
                        <label className="p-label">Title</label>
                        <InputText value={frmData.title} onChange={(e) => setFrmData({...frmData, title: e.target.value})} />
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Description</label>
                        <InputTextarea value={frmData.description} rows="3" onChange={(e) => setFrmData({...frmData, description: e.target.value})} />
                    </div>
                    <div className="p-col-12">
                        <InputSwitch checked={frmData.showButton} onChange={(e) => setFrmData({...frmData, showButton: e.value})} />
                        <label className="p-margin-left-10">Show Button?</label>
                    </div>
                    {frmData.showButton &&
                    <div className="p-col-12">
                        <label>* Button Text</label>
                        <InputText value={frmData.buttonText} onChange={e => setFrmData({...frmData, buttonText: e.target.value})} />
                        <span className="p-form-error">{errors.buttonText}</span>
                    </div>
                    }
                    
                </React.Fragment>
                }

                <div className="p-col-12" >
                    <label className="p-label">{frmData.showButton&&'* '}Go to url:</label>
                    <InputText value={frmData.link} onChange={e => setFrmData({...frmData, link: e.target.value})} />
                    <span className="p-form-error">{errors.link}</span>
                </div>
                <div className="p-col-12" >
                    <label className="p-label">Position</label>
                    <InputText value={frmData.weight} keyfilter="int" onChange={e => setFrmData({...frmData, weight: e.target.value})} />
                </div>
                <div className="p-col-12" >
                    <InputSwitch checked={frmData.active} onChange={(e) => setFrmData({...frmData, active: e.value})} />
                    <label className="p-margin-left-10">Active?</label>
                </div>
            </div>

            <div className="p-sidebar-line p-my-3"></div>

            <div className="p-grid">
                <div className="p-col-12 p-d-flex p-justify-between">
                    <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={closeForm}/>
                    <Button label="Submit" icon="pi pi-check" onClick={() => handleSaveSliderItem()}/>
                </div>
            </div>
        </Sidebar>
    )
})
