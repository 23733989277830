import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Link, useRouteMatch } from 'react-router-dom';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { getPageProductOptions, removeProductOption } from './ProductOptionServices';
import { ProductOptionForm } from './ProductOptionForm';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Dropdown } from 'primereact/dropdown';
import { TABLE_PAGING_LENGTH, APP_FEP, PRODUCT_OPTION_TYPE } from '../../constants';
import { showSuccessNotify, showErrorNotify, showConfirmNotify } from '../../core/service/NotificationService';
import { SplitButton } from 'primereact/splitbutton';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';

export const ProductOptionListView = () => {
	const match = useRouteMatch();
	const frmOption = useRef(null);
	const [filter, setFilter] = useState({ app: APP_FEP });
	const [data, setData] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: '',
		sortOrder: -1,
	});
	const [isLoadTable, setLoadTable] = useState(true);

	const isWrite = isScpAdmin() || hasRole([ROLES_CONFIG.PRODUCT_OPTION_MGT_W]);
	const isDelete = isScpAdmin() || hasRole([ROLES_CONFIG.PRODUCT_OPTION_MGT_D]);

	useEffect(() => {
		if (isLoadTable) loadPageProductOptions();
	}, [isLoadTable]);

	const loadPageProductOptions = () => {
		showloading();
		getPageProductOptions(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				if (res) {
					setData(res.content);
					setPageable({
						total: res.totalElements,
						loading: false,
						page: res.pageable.pageNumber,
						rows: res.pageable.pageSize,
					});
					setLoadTable(false);
				}
			})
			.finally(stoploading());
	};

	const onPage = (e) => {
		setPageable({
			...pageable,
			loading: true,
			page: e.page,
		});
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({
			...pageable,
			loading: true,
			sortField: e.sortField,
			sortOrder: e.sortOrder,
		});
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const deleteProductOption = (id) => {
		showConfirmNotify({
			accept: () => handleRemoveProductOption(id),
		});
	};

	const handleRemoveProductOption = (id) => {
		removeProductOption(id).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const tblHeader = (
		<TableHeader
			title='Option List'
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			actionTemplate={isWrite && <Button className='p-button-success' label='Create Option' icon='pi-md-plus' onClick={() => frmOption.current.openForm()} />}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
		/>
	);

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>Activity Options</BreadcrumbsItem>

			<ProductOptionForm ref={frmOption} refreshTable={() => setLoadTable(true)} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1 className='p-w-bold p-m-0'>Activity Options</h1>
				</div>

				<DataTable
					lazy={true}
					paginator={true}
					value={data}
					first={pageable.page * pageable.rows}
					header={tblHeader}
					onPage={onPage}
					onSort={onSort}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					responsive={true}
				>
					<Column header='Name' field='name' sortable={true} />
					<Column header='None Label' field='noneLabel' sortable={true} />
					{/* <Column header="Type" field="type" body={(rowData) => PRODUCT_OPTION_TYPE[rowData.type].label} sortable={true}/> */}
					{(isWrite || isDelete) && (
						<Column
							header=''
							className='tc-actions'
							style={{ textAlign: 'center', width: '10%' }}
							body={(rowData) => {
								if (isWrite && isDelete) {
									const actionItems = [{ label: 'Remove', icon: 'pi pi-times', command: (e) => deleteProductOption(rowData.id) }];
									return <SplitButton label='Edit' icon='pi pi-pencil' className='p-button-constrast' onClick={() => frmOption.current.openForm(rowData)} model={actionItems}></SplitButton>;
								}
								if (isWrite) {
									return <Button label='Edit' icon='pi pi-pencil' className='p-button-constrast' onClick={() => frmOption.current.openForm(rowData)} />;
								}
								if (isDelete) {
									return <Button label='Remove' icon='pi pi-times' className='p-button-danger' onClick={() => deleteProductOption(rowData.is)} />;
								}
							}}
						/>
					)}
				</DataTable>
			</div>
		</div>
	);
};
