import React, {Component} from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { showNotification } from '../../core/service/NotificationService';
import { ParticipantServices } from '../../constituent-management/participant/ParticipantServices';
import { Fieldset } from 'primereact/fieldset';

export class ParticipantOrderDetailsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            visible: false,
            formHeader: 'Purchased Details',
        }
        this.participantServices = new ParticipantServices();
    }

    action = (partId) => {
        this.participantServices.getDetailOrderParticipant(partId)
        .then(res => {
            this.setState({
                data: res ? res : {},
                visible: true
            });
            if(res.errorCode){
                showNotification('error', 'Error Message', 'Cannot perform action');
            }
        })
    }

    onHide = () => {
        this.setState({ visible: false })
    }

    render(){
        return (
            <Sidebar visible={this.state.visible} dismissable={false} style={{overflowY: 'auto'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.onHide}>
                <h2>{this.state.formHeader}</h2>

                <Fieldset legend="Information">
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12">
                        <label className="p-label">Unit price: {this.state.data.unitPrice}</label>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Extra price: {this.state.data.extraPrice}</label>
                    </div>
                    {/* <div className="p-col-12">
                        <label className="p-label">Total: {this.state.data.total}</label>
                    </div> */}
                    <div className="p-col-12">
                        <label className="p-label">Addon Total: {this.state.data.addonTotal}</label>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Reg. Price: {this.state.data.regPrice}</label>
                    </div>
                    <div className="p-col-12">
                        <label className="p-label">Checkout: {this.state.data.checkout}</label>
                    </div>
                </div>
                </Fieldset>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Close" icon="pi-md-close" className="p-button-secondary" onClick={this.onHide}/>
                    </div>
                </div>
            </Sidebar>
        )
    }

}