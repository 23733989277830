import React, { Component } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import moment from 'moment';
import { InputSwitch } from 'primereact/inputswitch';
import { SimpleEventServices } from "./SimpleEventService";
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { DeleteNotification } from "../../core/components/DeleteNotification";
import { showNotification } from "../../core/service/NotificationService.js";
import { SimpleCalendarServices } from "../simple-calendar/SimpleCalendarServices";
import { Dropdown } from 'primereact/dropdown';
import Select from 'react-select';
import { titleCaseText } from "../../core/service/CommonService";

export default class SimpleEventScheduleForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedule: {
                calendarId: this.props.calendarId,
                programId: this.props.courseId ? this.props.courseId : '',
            },
            visibleRight: false,
            errors: {},
            start:'',
            end:'',
            option: false,
            formHeader: "Add Event",
            courseId: this.props.courseId ? this.props.courseId : '',
            courseName: this.props.courseName ? this.props.courseName : '',
        };
        this.SimpleEventServices = new SimpleEventServices();
        this.SimpleCalendarServices = new SimpleCalendarServices();
    }

    componentDidMount() {
        Promise.all([this.SimpleCalendarServices.getListSimpleCalendars(), this.SimpleEventServices.getListCourses()]).then(
            result => {
                let lstCalendars = result[0];
                let courses = result[1];
                this.setState({
                    lstCalendars: lstCalendars,
                    courses: courses,
                    schedule: {
                        programId: this.props.courseId
                    },
                })
            }
        );
    }

    getSelectedCourseOption = (cid) => {
        let pin = null;
        if (this.state.courses && this.state.courses.length > 0) {
            let i = 0;
            while(i < this.state.courses.length && ( pin === null || pin === undefined)){
                pin = this.state.courses[i].options.find(e => (e.value === cid))
                i++;
            }
            return pin;
        }
        return null;
        // return data ? data : null;
    }

    findNeedle = (options, needle) => {
        return options.find(e => e.value === needle);
    }

    getDefaultCourseSelected = () => {
        if (this.state !== undefined && this.state.schedule !== undefined && this.state.schedule.programId !== undefined) {
            let result = this.getSelectedCourseOption(this.state.schedule.programId);
            return result;
        }
        return null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.eventId !== this.props.eventId) {
            if (this.props.eventId !== 0)
                this.getOneSimpleEvent();
            else {
                if (this.props.eventId === 0)
                    this.resetFormData(this.props.calendarId);
            }
        }
        if (prevProps.calendarId !== this.props.calendarId) {
            this.setState({
                schedule: {
                    ...this.state.schedule,
                    calendarId: this.props.calendarId
                }
            })
        }
    }

    onHide = () => {
        this.props.hideForm();
        this.setState({
            errors: {}
        });
    }

    resetFormData = (calendarId) => {
        this.setState({
            schedule: {
                id: '',
                title: '',
                start: '',
                end: '',
                calendarId: calendarId,
                programId: this.props.courseId ? this.props.courseId : 0,
                allDay: false,
                busy: false
            },
            start: '',
            end: '',
            errors: {}
        });
    }

    onStartChange = (e) =>{
        this.setState({
            schedule: { ...this.state.schedule, start: moment(e.value).format('YYYY-MM-DD HH:mm')},
            start: e.value
        })

    }

    onEndChange = (e) =>{
        this.setState({
            schedule: { ...this.state.schedule, end: moment(e.value).format('YYYY-MM-DD HH:mm')},
            end: e.value
        })

    }

    reloadTable = () => {
        this.SimpleEventServices.getPageSimpleEvents(this.state.filter,
            this.state.page,
            this.state.rows,
            this.state.sortField,
            this.state.sortOrder)
            .then(res => {
                this.setState({
                    simpleEventList: res.content,  
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                });
            })
    };

    getOneSimpleEvent = () => {
        this.SimpleEventServices.getOneSimpleEvent(this.props.eventId)
            .then(res => {
                this.setState({
                    schedule: {
                        id: res.id,
                        title: res.title,
                        start: new Date(res.start),
                        end: new Date(res.end),
                        calendarId: res.calendarId,
                        programId: res.programId,
                        allDay: res.allDay,
                        busy: res.busy
                    },
                    errors: {},
                    formHeader: 'Edit Event',
                    start: new Date(res.start),
                    end: new Date(res.end),
                });
            });
    }

    // setEventOnCourse =() =>{
    //     debugger
    //     if(this.state.courseId !== ''){
    //         this.setState({
    //             schedule: {
    //                 programId: this.props.courseId,
    //             }
    //         })
    //     }
    // }

    handleSaveSchedule = () => {
        this.SimpleEventServices.saveSimpleEvent(this.state.schedule)
            .then(res => {
                if (res.request.status === 201) {
                    this.onHide();
                    this.props.reloadTable();
                    showNotification('success', 'Success Message', 'Action submitted');
                    this.setState({ errors: {} });
                    this.resetFormData(this.props.calendarId);
                } else if (res.status.code === 400) {
                    showNotification('error', 'Error Message');
                    this.setState({ errors: res.objError });
                } else {
                    this.props.onError();
                }
            });
    }

    handleChangeSchedule = (id) => {
        this.SimpleEventServices.changeSimpleEvent(id, this.state.schedule)
            .then(res => {
                if (res.request.status === 200) {
                    this.onHide();
                    this.props.reloadTable();
                    showNotification('success', 'Success Message', 'Action submitted');
                    this.setState({ errors: {} });
                } else if (res.status.code === 400) {
                    showNotification('error', 'Error Message');
                    this.setState({ errors: res.objError });
                } else {
                    this.props.onError();
                }
            });
    }

    removeSimpleEventSchedule = () => {
        this.SimpleEventServices.removeSimpleEvent(this.props.eventId).then(res => {
            if (res.request.status === 200) {
                this.props.reloadTable();
                this.onHide();
                showNotification('success', 'Success Message', 'Action submitted');
            } else {
                showNotification('error', 'Error Message');
            }
        });
    }

    handlechangeAndAdd = () => {
        this.props.formHeader === 'Add Event' ? this.handleSaveSchedule() : this.handleChangeSchedule(this.props.eventId)
    }

    render() {
        const footer = (
            <React.Fragment>
                <Button
                    label="Submit"
                    icon="pi pi-check"
                    onClick={e => this.handlechangeAndAdd(e)}
                />
                <Button
                    label="Cancel"
                    icon="pi-md-close"
                    className="p-button-secondary"
                    onClick={this.onHide}
                />
                {this.props.formHeader == "Add Event" ?
                    <Button
                        label="Reset"
                        icon="pi-md-refresh"
                        className={this.props.eventId !== 0 ? "p-hide" : "p-button-raised p-button-warning"}
                        onClick={(e) => this.resetFormData(this.props.calendarId)}
                    /> :
                    <Button
                        label="Delete"
                        icon="pi-md-trash"
                        className={this.props.eventId === 0 ? "p-hide" : "p-button-danger"}
                        onClick={(e) => this.deleteNotify.delete(this.props.eventId)}
                    />}
            </React.Fragment>
        );

        return (
            <div>
                <DeleteNotification ref={el => this.deleteNotify = el}
                    accessDelete={(e) => this.removeSimpleEventSchedule(e)}
                />

                <Sidebar
                    visible={this.props.visible}
                    style={{ overflowY: "auto", width: "40em" }}
                    position="right"
                    blockScroll={true}
                    onHide={e => this.props.hideForm()}
                >
                    <h2>{this.props.formHeader}</h2>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Fieldset legend="Event Information">
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12 p-md-12">
                                        <label className="p-label">* Title</label>
                                        <InputText value={this.state.schedule.title} onChange={(e) => this.setState({ schedule: { ...this.state.schedule, title: titleCaseText(e.target.value) } })} />
                                        <div className="p-form-error">{this.state.errors.title}</div>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <label className="p-label">* Start Date</label>
                                        <MaskedCalendar value={this.state.start} showIcon={true} onChange={this.onStartChange} dateFormat="mm-dd-yy" hourFormat="12" showTime={true} hideOnDateTimeSelect={true} />
                                        <span className="p-form-error">{this.state.errors.start}</span>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <label className="p-label">* End Date</label>
                                        <MaskedCalendar value={this.state.end} showIcon={true} onChange={this.onEndChange} dateFormat="mm-dd-yy" hourFormat="12" showTime={true} hideOnDateTimeSelect={true} />
                                        <span className="p-form-error">{this.state.errors.end}</span>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <label className="p-label">Calendar</label>
                                        <Dropdown value={this.state.schedule.calendarId} options={this.state.lstCalendars} showIcon={true} onChange={(e) => this.setState({ schedule: { ...this.state.schedule, calendarId: e.target.value } })} />
                                    </div>
                                    {!this.props.courseId ?
                                        <div className="p-col-12 p-md-12">
                                            <label className="p-label">Course</label>
                                                <Select value={this.getDefaultCourseSelected()} options={this.state.courses} onChange={(e) => this.setState({ schedule: { ...this.state.schedule, programId: e ? e.value : null } })} style={{ width: '100%' }} />
                                        </div> :
                                        <div className="p-col-12 p-md-12">
                                            <label className="p-label">Course</label>
                                            <span>{this.state.courseName}</span>
                                        </div>
                                    }
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">All day</label>
                                        <InputSwitch checked={this.state.schedule.allDay}
                                            onChange={(e) =>
                                                this.setState({ schedule: { ...this.state.schedule, allDay: e.value } })
                                            }
                                        />
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <label className="p-label">Busy</label>
                                        <InputSwitch checked={this.state.schedule.busy} inputId="busy" name="busy"
                                            onChange={e =>
                                                this.setState({ schedule: { ...this.state.schedule, busy: e.value } })
                                            }
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            {footer}
                        </div>
                    </div>
                </Sidebar>
            </div>
        );
    }
}
