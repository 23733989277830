import axios from 'axios';
import { API_BASE_URL, SCP_PATH } from '../../constants';

const LIVE_ACCOUNT_SERVICE_URL = API_BASE_URL + SCP_PATH + '/live-accounts'

//#region ONLINE EVENT LIVE ACCOUNT
export function getPageLiveAccount(page, size, sortField, sortOrder){
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${LIVE_ACCOUNT_SERVICE_URL}/page`,{
        params: {page, size, sort}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function getListLiveAccounts(simple) {
    return axios.get(`${LIVE_ACCOUNT_SERVICE_URL}/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}

export function getAvailableLiveAccounts(sessionId, simple) {
    return axios.get(`${LIVE_ACCOUNT_SERVICE_URL}/available-list`, {
        params: {sessionId, simple}
    }).then(res => res.data).catch(error => console.log(error));
}

export function saveLiveAccount(data){
    return axios.post(`${LIVE_ACCOUNT_SERVICE_URL}`, data).then(res => res.data).catch(error => console.log(error));
}

export function removeLiveAccount(id){
    return axios.delete(`${LIVE_ACCOUNT_SERVICE_URL}/${id}`).then(res => res.data).catch(error => console.log(error));
}
//#endregion