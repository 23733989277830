import React, { Component } from "react";
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { SimpleCalendarServices } from './SimpleCalendarServices';
import { Fieldset } from "primereact/fieldset";
import { showNotification } from "../../core/service/NotificationService";
import { InputSwitch } from 'primereact/inputswitch';
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import moment from 'moment';
import { replaceSpaceAndUpperCase, titleCaseText } from "../../core/service/CommonService";
import { MultiSelect } from 'primereact/multiselect';


export default class SimpleCalendarForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            simpleCalendar: {
                id: null,
                name: '',
                begin: '',
                end: '',
                allowConflict: false,
                programs: []
            },
            programs: [],
            visibleRight: false,
            errors: {},
            option: false,
            formHeader: 'Add Calendar'
        };
        this.SimpleCalendarServices = new SimpleCalendarServices();
    }

    componentDidMount() {
        this.getPrograms();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.simpleCalendarId !== this.props.simpleCalendarId) {
            if (this.props.simpleCalendarId !== 0) {
                this.getSimpleCalendarById();
            } else {
                if (this.props.simpleCalendarId === 0) {
                    this.resetFormData();
                }
            }
        }
    }

    getPrograms() {
        this.SimpleCalendarServices.getProgram()
            .then(res => {
                this.setState({
                    programs: res
                })
            })
    }

    displayDefaulProgram = () => {
        let data = this.state.simpleCalendar.programs ? this.state.simpleCalendar.programs : [];
        let arrayHolder = [];
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                arrayHolder.push(data[i].value)
            }
        }
        return (
            arrayHolder
        )
    }

    onChangeProgram = (data) => {
        let changeProgram = [];
        let programs = this.state.programs;
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                for (let j = 0; j < programs.length; j++) {
                    if (data[i] === programs[j].value) {
                        changeProgram.push(programs[j])
                    }
                }
            }
        }
        this.setState({
            simpleCalendar: {
                ...this.state.simpleCalendar,
                programs: changeProgram
            }
        })
    }

    resetFormData = () => {
        this.setState({
            simpleCalendar: {
                id: null,
                name: '',
                begin: '',
                end: '',
                allowConflict: false,
            },
            errors: {}
        });
    }

    onHide = () => {
        this.props.hideForm();
        this.setState({
            errors: {}
        });
    }

    handleSaveSimpleCalendar = () => {
        this.SimpleCalendarServices.saveSimpleCalendars(this.state.simpleCalendar)
            .then(res => {
                if (!res.errorCode) {
                    this.resetFormData();
                    this.props.reloadTable();
                    showNotification('Succes', 'Success message', 'New Calendar has been created');
                    this.onHide();
                    this.setState({
                        errors: {},
                    })
                } else {
                    showNotification('error', 'Error Message', 'Cannot perform action');
                    if (res.errorCode === 400) this.setState({ errors: res.errorObj });
                }
            })
    }

    getSimpleCalendar = () => {
        this.SimpleCalendarServices.getSimpleCalendarById(this.props.id)
            .then(res => {
                this.setState({
                    simpleCalendar: {
                        id: res.id,
                        name: res.name,
                        begin: new Date(res.begin),
                        end: new Date(res.end),
                        allowConflict: res.allowConflict
                    },
                    errors: {},
                    formHeader: ''
                })
            })
    }

    onBeginDateChange = (e) => {
        this.setState({
            simpleCalendar: {
                ...this.state.simpleCalendar,
                begin: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '',

            },
            beginDateValue: e.value
        });
    }

    onEndDateChange = (e) => {
        this.setState({
            simpleCalendar: {
                ...this.state.simpleCalendar,
                end: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : '',

            },
            endDateValue: e.value
        });
    }

    render() {
        const footer = (
            <React.Fragment>
                <Button
                    label="Submit"
                    icon="pi pi-check"
                    onClick={e => this.handleSaveSimpleCalendar(e)}
                />
                <Button
                    label="Cancel"
                    icon="pi-md-close"
                    className="p-button-secondary"
                    onClick={this.onHide}
                />
            </React.Fragment>
        );
        return (
            <div>
                <Sidebar
                    visible={this.props.visible}
                    style={{ overflowY: "auto", width: "40em" }}
                    position="right"
                    blockScroll={true}
                    onHide={e => this.props.hideForm()}
                >
                    <h2>{this.props.formHeader}</h2>
                    <div className="p-grid">
                        <div className="p-col-12">
                            <Fieldset legend="Calendar Information">
                                <div className="p-grid p-fluid form-group">
                                    <div className="p-col-12 p-md-12">
                                        <label className="p-label">* Name</label>
                                        <InputText value={this.state.simpleCalendar.name} onChange={(e) => this.setState({ simpleCalendar: { ...this.state.simpleCalendar, name: titleCaseText(e.target.value) } })} />
                                        <div className="p-form-error">{this.state.errors.name}</div>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <label className="p-label">Begin</label>
                                        <MaskedCalendar value={this.state.beginDateValue} showIcon={true} onChange={this.onBeginDateChange} dateFormat="mm-dd-yy" />
                                        <span className="p-form-error">{this.state.errors.begin}</span>
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <label className="p-label">End</label>
                                        <MaskedCalendar value={this.state.endDateValue} showIcon={true} onChange={this.onEndDateChange} dateFormat="mm-dd-yy" />
                                        <span className="p-form-error">{this.state.errors.end}</span>
                                    </div>
                                    <div className="p-col-12 p-md-6">
                                        <label className="p-label">Allow Conflict?</label>
                                        <InputSwitch checked={this.state.simpleCalendar.allowConflict}
                                            onChange={(e) =>
                                                this.setState({ simpleCalendar: { ...this.state.simpleCalendar, allowConflict: e.value } })
                                            }
                                        />
                                    </div>
                                </div>
                            </Fieldset>
                            <Fieldset legend="Other Information">
                                <div className="p-col-12">
                                    <label className="p-label">Programs</label>
                                    <MultiSelect value={this.displayDefaulProgram()} options={this.state.programs} onChange={(e) => this.onChangeProgram(e.value)} style={{ width: '100%' }}/>
                                </div>
                            </Fieldset>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            {footer}
                        </div>
                    </div>
                </Sidebar>
            </div>
        )
    }
}
