import axios from 'axios';
import {API_BASE_URL} from '../../../constants';

export class GLAccountServices {
    getTreeAccount() {
        return axios.get(`${API_BASE_URL}/scp/api/gl-accounts/tree`)
        .then(res => res.data).catch(error => console.log(error));
    }

    getTreeSelectAccount(){
        return axios.get(`${API_BASE_URL}/scp/api/gl-accounts/tree-select`)
        .then(res => res.data).catch(error => console.log(error));
    }

    getAccountList(excludedId, type){
        return axios.get(`${API_BASE_URL}/scp/api/gl-accounts/list`, {params: {excludedId, type}})
        .then(res => res.data).catch(error => console.log(error));
    }

    getAccountListByCode(code){
        return axios.get(`${API_BASE_URL}/scp/api/gl-accounts/list/${code}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    getAccount(code){
        return axios.get(`${API_BASE_URL}/scp/api/gl-accounts/${code}`)
        .then(res => res.data).catch(error => console.log(error));
    }

    saveAccount(data){
        return axios.post(`${API_BASE_URL}/scp/api/gl-accounts`, data).then(res => res.data).catch(error => console.log(error));
    }

    removeAccount(code){
        return axios.delete(`${API_BASE_URL}/scp/api/gl-accounts/${code}`).then(res => res.data).catch(error => console.log(error));
    }
}