import axios from "axios";
import { API_BASE_URL, SCP_PATH } from "../../constants";

export class TodoServices {
    getTodoList(id){
        return axios.get(`${API_BASE_URL}${SCP_PATH}/todo/list`, {params: {id: id}})
            .then(res => res.data)
            .catch(error => console.log(error));
    }
    saveTodo(data){
        return axios.post(`${API_BASE_URL}${SCP_PATH}/todo/save`, data)
            .then(res => res.data)
            .catch(error => console.log(error));
    }
}