import React, {Component} from 'react';
import {MembershipTemplateServices} from './MembershipTemplateServices';

import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";
import {Link} from 'react-router-dom';

import {MembershipTemplateForm} from "./MembershipTemplateForm";
import {MembershipTemplateLevelForm} from "./MembershipTemplateLevelForm";
import {showNotification} from '../../core/service/NotificationService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { MoveMembershipTemplateForm } from './MoveMembershipTemplateForm';
import { DeleteNotification } from '../../core/components/DeleteNotification';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { SplitButton } from 'primereact/splitbutton';

export class MembershipTemplateDetails extends Component{
    constructor(props){
        super(props);
        this.state = {
            template: {},
            templateFormVisible: false,
            levels: {
                dataList: [],
                loading: true,
                page: 0,
                rows: 10,
                total: 0,
                sortField: '',
                sortOrder: 0,
            },
            templateLevelFormVisible: false,
        }

        this.membershipTemplateServices = new MembershipTemplateServices();
    }

    componentDidMount(){
        showloading();
        this.getMembershipTemplate(this.props.match.params.id);
    }

    getMembershipTemplate = (id) => {
        this.membershipTemplateServices.getMembershipTemplate(id).then(res => {
            if(!res.errorCode){
                this.setState({
                    template: res
                });
                this.getMembershipLevels();
                stoploading();
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        });
    }

    editMembershipTemplate = (data) => {
        this.frmMembershipTemplate.popularFormData(data);
        this.setState({templateFormVisible: true});
    }

    getMembershipLevels = () => {
        this.membershipTemplateServices.getPageMembershipTemplates(this.props.match.params.id, this.state.page, this.state.rows, this.state.sortField, this.state.sortOrder)
        .then(res => {
            stoploading();
            this.setState({
                levels: {
                    ...this.state.levels,
                    dataList: res.content,
                    total: res.totalElements,
                    loading: false,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                }
            });
        });
    }

    onMsLevelsPage = (e) => {
        this.setState({
            levels: {
                ...this.state.levels,
                loading: true,
                page: e.page
            }
        }, () => {
            this.getMembershipLevels();
        });
    }

    onMsLevelsSort = (e) => {
        this.setState({
            levels: {
                ...this.state.levels,
                loading: true,
                page: 0,
                sortField: e.sortField,
                sortOrder: e.sortOrder
            }
        }, () => {
            this.getMembershipLevels();
        })
    }

    removeMembershipTemplate = (id) => {
        this.membershipTemplateServices.removeMembershipTemplate(id).then(res => {
            if(!res.errorCode){
                this.getMembershipLevels();
                showNotification('success', 'Success Message', 'Action Submitted');
            }else{
                showNotification('error', 'Error Message', res.errorMessage);
            }
        });
    }

    addMembershipTemplateLevel = () => {
        this.frmMembershipTemplateLevel.popularFormData(null);
        this.setState({templateLevelFormVisible: true});
    }

    render(){
        const tblLevelsHeader = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>
                        <Button label="Create Membership Level" icon="pi pi-plus" className="p-button-constrast" style={{'float':'left'}} onClick={this.addMembershipTemplateLevel}/>
                        <h3 className="fle-datatable-header-title">Membership Levels</h3>
                    </div>;

        return(
            <div className="p-grid">
                <BreadcrumbsItem to="/membership_templates">Membership Types</BreadcrumbsItem>
                <BreadcrumbsItem to={this.props.match.url}>{this.state.template.name}</BreadcrumbsItem>

                <MembershipTemplateForm ref={(el) => this.frmMembershipTemplate = el} visible={this.state.templateFormVisible}
                    refreshData={(e) => this.setState({template: e})}
                    hideFormDialog={(e) => this.setState({templateFormVisible: false})}
                />

                <Sidebar visible={this.state.templateLevelFormVisible} style={{overflowY: 'auto', width: '50em'}} position="right" blockScroll={true} onHide={(e) => this.setState({templateLevelFormVisible: false})}>
                    <h2>Add Membership Level</h2>

                    <MembershipTemplateLevelForm ref={(el) => this.frmMembershipTemplateLevel = el}
                        reloadTable={(e) => this.getMembershipLevels()}
                        hideFormDialog={(e) => this.setState({templateLevelFormVisible: false})}
                        parent={this.props.match.params.id}
                        data={null} submitButtonVisible={false}
                    />
                    <div className="p-grid">
                        <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                            <Button label="Submit" icon="pi pi-check" onClick={() => this.frmMembershipTemplateLevel.handleSaveMembershipTemplateLevel()}/>
                            <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={(e) => this.setState({templateLevelFormVisible: false})}/>
                        </div>
                    </div>
                </Sidebar>

                <MoveMembershipTemplateForm ref={el => this.moveMembershipTemplateForm = el} 
                    refreshData={(e) => this.getMembershipTemplate()}
                />

                <DeleteNotification ref={el => this.deleteNotify=el}
                    accessDelete={(e) => this.removeMembershipTemplate(e)}
                />
                
                <div className="p-col-12">
                    <div className="card card-w-title">
                        <h1>
                            Membership Type: {this.state.template.name}
                            <div className="p-toolbar-group-right">
                                <Button tooltip="Edit Membership" tooltipOptions={{position: "top"}} icon="pi-md-edit" onClick={(e) => this.editMembershipTemplate(this.state.template)}></Button>
                                <Link to={`/membership_templates`}>
                                    <Button tooltip="Back to List" tooltipOptions={{position: "top"}} icon="pi pi-bars" className="p-button-primary"></Button>
                                </Link>
                            </div>
                        </h1>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12">Description: {this.state.template.description}</div>
                        </div>
                    </div>

                    <div className="card card-w-title">
                        <DataTable lazy={true} paginator={true} value={this.state.levels.dataList} header={tblLevelsHeader}
                            first={this.state.levels.page * this.state.levels.rows}
                            rows={this.state.levels.rows} totalRecords={this.state.levels.total}
                            onPage={this.onMsLevelsPage} onSort={this.onMsLevelsSort}
                            sortField={this.state.levels.sortField} sortOrder={this.state.levels.sortOrder}
                            selectionMode="single" responsive={true}>
                            <Column field="id" header="ID" sortable={true}/>
                            <Column field="type" header="Type" sortable={true}/>
                            <Column field="name" header="Name" sortable={true}/>
                            <Column field="description" header="Description" sortable={true}/>
                            <Column field="active" header="Actived" body={(rowData) => rowData.active?'Yes':'No'} sortable={true}/>
                            {/* <Column field="weight" header="Weight" sortable={true}/> */}
                            <Column field="code" header="Code" sortable={true}/>
                            <Column header="" className="tc-actions" style={{textAlign:'center',width:'12em', overlay: 'visible'}}
                                body={(rowData) => {
                                    let dropActions = [
                                        {   label: 'Move Level', icon: 'pi-md-swap-horiz',
                                            command: (e) => { this.moveMembershipTemplateForm.action(rowData.id, this.props.match.params.id) }
                                        },
                                        {   label: 'Delete Level', icon: 'pi pi-times',
                                            command: (e) => { this.deleteNotify.delete(rowData.id) }
                                        }
                                    ];
                                    return <SplitButton label="View" className="p-button-constrast" model={dropActions} tooltip={'View Level'} tooltipOptions={{position: "top"}} onClick={() => this.props.history.push(`/membership_templates/${this.state.template.id}/levels/${rowData.id}`)}/>
                                }} 
                            />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}