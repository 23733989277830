import axios from 'axios';
import { API_BASE_URL } from '../../constants';

//#region EVENT
export function getPageEvents(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = `${sortField},${order}`;
    return axios.get(`${API_BASE_URL}/scp/api/events/page`, {
        params: {...filter, page, size, sort }
    }).then(res => res.data).catch(error => console.log(error));
}

export function getEvent(id){
    return axios.get(`${API_BASE_URL}/scp/api/events/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function getEvents(simple){
    return axios.get(`${API_BASE_URL}/scp/api/events/list`, {params: {simple}}).then(res => res.data).catch(error => console.log(error));
}

export function saveEvent(data){
    return axios.post(`${API_BASE_URL}/scp/api/events`, data).then(res => res.data).catch(error => console.log(error));
}

export function removeEvent(id){
    return axios.delete(`${API_BASE_URL}/scp/api/events/${id}`).then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region EVENT SESSION
export function getPageEventSessions(filter, page, size, sortField, sortOrder){
    const order = sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : '';
    const sort = `${sortField},${order}`;
    return axios.get(`${API_BASE_URL}/scp/api/events/sessions/page`, {
        params: {...filter, page, size, sort }
    }).then(res => res.data).catch(error => console.log(error));
}

export function getEventSession(id){
    return axios.get(`${API_BASE_URL}/scp/api/events/sessions/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function getEventSessions(simple, eventId){
    return axios.get(`${API_BASE_URL}/scp/api/events/sessions/list`, {params: {simple, eventId}}).then(res => res.data).catch(error => console.log(error));
}

export function saveEventSession(data){
    return axios.post(`${API_BASE_URL}/scp/api/events/sessions`, data).then(res => res.data).catch(error => console.log(error));
}

export function removeEventSession(id){
    return axios.delete(`${API_BASE_URL}/scp/api/events/sessions/${id}`).then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region ONLINE EVENT LIVE ACCOUNT
export function getPageLiveAccount(page, size, sortField, sortOrder){
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${API_BASE_URL}/scp/api/events/live-account/page`,{
        params: {page, size, sort}
    })
    .then(res => res.data).catch(error => console.log(error));
}


export function getListLiveAccounts(simple) {
    return axios.get(`${API_BASE_URL}/scp/api/events/live-account/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}

export function getAvailableLiveAccounts(sessionId, simple) {
    return axios.get(`${API_BASE_URL}/scp/api/events/live-account/available-list`, {
        params: {sessionId, simple}
    }).then(res => res.data).catch(error => console.log(error));
}

export function saveLiveAccount(data){
    return axios.post(`${API_BASE_URL}/scp/api/events/live-account`, data).then(res => res.data).catch(error => console.log(error));
}

export function removeLiveAccount(id){
    return axios.delete(`${API_BASE_URL}/scp/api/events/live-account/${id}`).then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region  TIME FRAME
export function getPageTimeFrame(page, size, sortField, sortOrder) {
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${API_BASE_URL}/scp/api/events/time-frame/page`,{
        params: { page, size, sort}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function saveTimeFrame(data) {
    return axios.post(`${API_BASE_URL}/scp/api/events/time-frame`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function removeTimeFrame(id) {
    return axios.delete(`${API_BASE_URL}/scp/api/events/time-frame/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getListTimeFrames() {
    return axios.get(`${API_BASE_URL}/scp/api/events/time-frame/list`)
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region ONLINE EVENT SESSION CATEGORY
export function getPageEventCategory(page, size, sortField, sortOrder){
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${API_BASE_URL}/scp/api/events/category/page`,{
        params: {page, size, sort}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function saveEventCategory(data){
    return axios.post(`${API_BASE_URL}/scp/api/events/category`, data).then(res => res.data).catch(error => console.log(error));
}

export function removeEventCategory(id){
    return axios.delete(`${API_BASE_URL}/scp/api/events/category/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function getEventCategories(simple) {
    return axios.get(`${API_BASE_URL}/scp/api/events/category/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region ONLINE EVENT SESSION TAXON CATEGORY
export function getPageTaxonCategory(page, size, sortField, sortOrder){
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${API_BASE_URL}/scp/api/taxon/page`,{
        params: {page, size, sort}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function saveTaxonCategory(data){
    return axios.post(`${API_BASE_URL}/scp/api/taxon`, data).then(res => res.data).catch(error => console.log(error));
}

export function removeTaxonCategory(id){
    return axios.delete(`${API_BASE_URL}/scp/api/taxon/${id}`).then(res => res.data).catch(error => console.log(error));
}

export function getTaxonCategories(simple) {
    return axios.get(`${API_BASE_URL}/scp/api/taxon/list`, {params: {simple}})
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region ONLINE EVENT ZOOM MEETING
export function createZoomMeeting(data) {
    return axios.post(`${API_BASE_URL}/scp/api/events/meeting`, data).then(res => res.data).catch(error => console.log(error));
}

export function deleteZoomMeeting(id, meetingId) {
    return axios.delete(`${API_BASE_URL}/scp/api/events/meeting/${id}/${meetingId}`).then(res => res.data).catch(error => console.log(error));
}

export function getMeetingParticipants(id) {
    return axios.get(`${API_BASE_URL}/scp/api/events/meeting/participant/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}

//#endregion 

//#region FAQ
export function getPageFAQ(page, size, sortField, sortOrder) {
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${API_BASE_URL}/scp/api/events/question/page`,{
        params: { page, size, sort}
    })
    .then(res => res.data).catch(error => console.log(error));
}

export function saveFAQ(data) {
    return axios.post(`${API_BASE_URL}/scp/api/events/question`, data)
    .then(res => res.data).catch(error => console.log(error));
}

export function removeFAQ(id) {
    return axios.delete(`${API_BASE_URL}/scp/api/events/question/${id}`)
    .then(res => res.data).catch(error => console.log(error));
}
//#endregion

//#region SESSION LIKES
export function getSessionLikedList(objId){
    return axios.get(`${API_BASE_URL}/scp/api/events/action-tracking/${objId}/list`)
    .then(res => res.data).catch(error => console.log(error));
}

export function getPageActionTrackings(filter, page, size, sortField, sortOrder){
    const order = sortOrder ===0 ? "asc" : sortOrder===-1 ? "desc" : "";
    const sort = sortField ? `${sortField},${order}`: "";
    return axios.get(`${API_BASE_URL}/scp/api/events/action-tracking/page`, {
        params: {...filter, page, size, sort}
    }).then(res => res.data).catch(error => console.log(error));
}
//#endregion
