import React, {Component} from 'react';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DepartmentServices, getListDepartments } from '../../scp/departments/DepartmentServices';
import { getListGrades } from '../../scp/tenant-configuration/ConfigruationServices';
import { RadioButton } from 'primereact/radiobutton';
import { Spinner } from 'primereact/spinner';
import { ProgramServices } from './ProgramServices';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { showNotification } from '../../core/service/NotificationService';

export default class UsageFindTracking extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: {
                departmentId: '',
                categoryId: '',
                minAgeYear: 0,
                maxAgeYear: 100,
                grade: '',
                weekDay: 'mon'
            },
            resultTracking: '',
            errors: {}
        }
        this.departmentServices = new DepartmentServices();
        this.programServices = new ProgramServices();
    }

    componentDidMount(){
        this.loadAllDepartments();
        this.loadAllGrades();
    }

    loadAllDepartments = () => {
        getListDepartments().then(res => { this.setState({ departments: res ? res : [] })});
    }

    loadAllDeptCategories = (dept) => {
        // getCategories({departmentId: dept}).then(res => { this.setState({ categories: res ? res : [] }) });
    }

    loadAllGrades = () => {
        getListGrades()
            .then(res => {
                this.setState({ grades: res });
            });
    }

    onChangeDepartment = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                departmentId: e.value
            }
        },() => {
            this.loadAllDeptCategories(e.value, false);
        })
    }

    applyFilter = () => {
        this.setState({ errors: { }});
        this.programServices.getUserFindTrackingProgram(this.state.filter)
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    resultTracking: res
                });
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400)
                    this.setState({errors: res.errorObj});
            }
        })
    };

    clearFilter = () => {
        this.setState({
            filter: {
                departmentId: '',
                categoryId: '',
                minAgeYear: 0,
                maxAgeYear: 100,
                grade: '',
                weekDay: ''
            },
            resultTracking: '',
            errors: {}
        });
    };

    render(){
        return(
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Tracking</BreadcrumbsItem>

                <div className="p-col-12">
                    <Fieldset legend="Filters">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-5">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-3">
                                                <label>* Department</label>
                                            </div>
                                            <div className="p-col-12 p-md-9">
                                                <Dropdown value={this.state.filter.departmentId} options={this.state.departments} onChange={e =>this.onChangeDepartment(e)} />
                                                <div className="p-form-error">{this.state.errors.department}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-3">
                                                <label>Category</label>
                                            </div>
                                            <div className="p-col-12 p-md-9">
                                                <Dropdown value={this.state.filter.categoryId} options={this.state.categories} onChange={e =>this.setState({filter: { ...this.state.filter, categoryId: e.value }})} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-3">
                                                <label>Days of week</label>
                                            </div>
                                            <div className="p-col-12 p-md-9">
                                                <span className="p-margin-right-15" >
                                                    <RadioButton inputId="cbMon" value="mon" onChange={(e) => this.setState({filter: {...this.state.filter, weekDay: e.value}})} checked={this.state.filter.weekDay === 'mon'} />
                                                    <label htmlFor="cbMon">Mon</label>
                                                </span>
                                                <span className="p-margin-right-15" >
                                                    <RadioButton inputId="cbTue" value="tue" onChange={(e) => this.setState({filter: {...this.state.filter, weekDay: e.value}})} checked={this.state.filter.weekDay === 'tue'} />
                                                    <label htmlFor="cbTue">Tue</label>
                                                </span>
                                                <span className="p-margin-right-15" >
                                                    <RadioButton inputId="cbWed" value="wed" onChange={(e) => this.setState({filter: {...this.state.filter, weekDay: e.value}})} checked={this.state.filter.weekDay === 'wed'} />
                                                    <label htmlFor="cbWed">Wed</label>
                                                </span>
                                                <span className="p-margin-right-15" >
                                                    <RadioButton inputId="cbThu" value="thu" onChange={(e) => this.setState({filter: {...this.state.filter, weekDay: e.value}})} checked={this.state.filter.weekDay === 'thu'} />
                                                    <label htmlFor="cbThu">Thu</label>
                                                </span>
                                                <span className="p-margin-right-15" >
                                                    <RadioButton inputId="cbFri" value="fri" onChange={(e) => this.setState({filter: {...this.state.filter, weekDay: e.value}})} checked={this.state.filter.weekDay === 'fri'} />
                                                    <label htmlFor="cbFri">Fri</label>
                                                </span>
                                                <span className="p-margin-right-15" >
                                                    <RadioButton inputId="cbSat" value="sat" onChange={(e) => this.setState({filter: {...this.state.filter, weekDay: e.value}})} checked={this.state.filter.weekDay === 'sat'} />
                                                    <label htmlFor="cbSat">Sat</label>
                                                </span>
                                                <span className="p-margin-right-15" >
                                                    <RadioButton inputId="cbSun" value="sun" onChange={(e) => this.setState({filter: {...this.state.filter, weekDay: e.value}})} checked={this.state.filter.weekDay === 'sun'} />
                                                    <label htmlFor="cbSun">Sun</label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-md-2 p-sm-2 p-padding-top-0 p-padding-bottom-0"></div>
                            <div className="p-col-12 p-md-5">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-3">
                                                <label>Age range</label>
                                            </div>
                                            <div className="p-col-12 p-md-9">
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-6">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">From</span>
                                                            <Spinner min="0" max="100" value={this.state.filter.minAgeYear} keyfilter="int" onChange={(e) => this.setState({ filter: { ...this.state.filter, minAgeYear: e.target.value } })} />
                                                        </div>
                                                        <div className="p-form-error">{this.state.errors.minAge}</div>
                                                    </div>
                                                    <div className="p-col-12 p-md-6">
                                                        <div className="p-inputgroup">
                                                            <span className="p-inputgroup-addon">To</span>
                                                            <Spinner min="0" max="100"  value={this.state.filter.maxAgeYear} keyfilter="int" onChange={(e) => this.setState({ filter: { ...this.state.filter, maxAgeYear: e.target.value } })} />
                                                        </div>
                                                        <div className="p-form-error">{this.state.errors.maxAge}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-grid">
                                            <div className="p-col-12 p-md-3">
                                                <label>Grade</label>
                                            </div>
                                            <div className="p-col-12 p-md-9">
                                                <Dropdown value={this.state.filter.grade} options={this.state.grades} onChange={e =>this.setState({filter: { ...this.state.filter, grade: e.value }})} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={this.applyFilter} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={this.clearFilter} />
                            </div>
                        </div>
                    </Fieldset>
                </div>
                <div className="p-col-12">
                    <Fieldset legend="Result">
                        <div className="p-size-24">
                            Result: {this.state.resultTracking}
                        </div>
                    </Fieldset>
                </div>
            </div>
        )
    }

}