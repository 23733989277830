import React, { Component } from "react";
import {TabView,TabPanel} from 'primereact/tabview';
import SimpleEventList from './SimpleEventList';
import SimpleEventSchedule from './SimpleEventSchedule';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

export default class SimpleEventView extends Component {
    render() {
        return (
            <div className="p-grid">
                <BreadcrumbsItem to={this.props.match.url}>Event Calendar</BreadcrumbsItem>

                <div className="p-col-12">
                    <TabView renderActiveOnly={false}>
                        <TabPanel header="Event Schedules" leftIcon="pi pi-calendar">
                            <SimpleEventSchedule/>
                        </TabPanel>
                        <TabPanel header="Event Schedules Lists" leftIcon="pi pi-calendar">
                            <SimpleEventList/>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        );
    }
}

