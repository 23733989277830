import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import React, { useState } from "react";
import { showErrorNotify, showSuccessNotify } from "../../core/service/NotificationService";
import { updateMainUser } from "./MergeDuplicateService";
import "./styles.scss";

const FAKE_DB = [
  {
    id: 1,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Michelle",
      id: 1,
      lastName: "Kim",
      name: "main user",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 1,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 2,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 3,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 2,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Matt",
      id: 2,
      lastName: "Kang",
      name: "string 1",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 4,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 5,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 6,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 3,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Jason",
      id: 1,
      lastName: "Price",
      name: "string 2",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 7,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 8,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 9,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 4,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Michelle",
      id: 1,
      lastName: "Kim",
      name: "string 3",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 1,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 2,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 3,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 5,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Matt",
      id: 2,
      lastName: "Kang",
      name: "string 4",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 4,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 5,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 6,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 6,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Jason",
      id: 1,
      lastName: "Price",
      name: "string 5",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 7,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 8,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 9,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 3,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Jason",
      id: 1,
      lastName: "Price",
      name: "string 6",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 7,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 8,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 9,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 4,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Michelle",
      id: 1,
      lastName: "Kim",
      name: "string 7",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 1,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 2,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 3,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 5,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Matt",
      id: 2,
      lastName: "Kang",
      name: "string 8",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 4,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 5,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 6,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
  {
    id: 6,
    user: {
      email: "example@gmail.com",
      address: "address",
      enabled: true,
      firstName: "Jason",
      id: 1,
      lastName: "Price",
      name: "string 9",
      nationality: "Singapore",
      clientId: 1,
      gender: "Male",
      phone: "0123456789",
      iDocuments: [
        {
          id: 7,
          clientId: 1,
          userId: "1",
          docType: "work_permit",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 8,
          clientId: 1,
          userId: "1",
          docType: "passport",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
        {
          id: 9,
          clientId: 1,
          userId: "1",
          docType: "member_card",
          docNumber: "09875499043",
          expiredAt: "2022-09-12",
        },
      ],
    },
  },
];

const MergeDuplicateStep2 = ({
  goToNextStep,
  backToPreviousStep,
  selectedDuplicate,
  mainUser
}) => {
  const [usernameSelected, setUsernameSelected] = useState(null);
  const [emailSelected, setEmailSelected] = useState(null);
  const [phoneSelected, setPhoneSelected] = useState(null);
  const [addressSelected, setAddressSelected] = useState(null);
  const [nationalitySelected, setNationalitySelected] = useState(null);
  const [workPermitSelected, setWorkPermitSelected] = useState(null);
  const [passportSelected, setPassportSelected] = useState(null);

  const userColumn = (item, index = -1) => {
    const name = item.user.name;
    const email = item.user.email;
    const phone = item.user.phone;
    const address = item.user.address;
    const nationality = item.user.nationality
    let docNumber = [null, null]
    if (item.identityDocument.docType == "work_permit"){
      docNumber[0] = item.identityDocument.docNumber
    }else{
      docNumber[1] = item.identityDocument.docNumber
    }

    return (
      <div className="userColumn ">
        <tr className="headRow">
          <th>{index >= 0 ? `Duplicated user ${index + 1}` : `Main user`}</th>
        </tr>
        <tr>
          <td>
            <RadioButton
              value={name}
              onChange={(e) => setUsernameSelected(e.value)}
              checked={usernameSelected === name}
            />
            <label className="p-radiobutton-label">{name}</label>
          </td>
        </tr>
        <tr>
          <td>
            <RadioButton
              value={email}
              onChange={(e) => setEmailSelected(e.value)}
              checked={emailSelected === email}
            />
            <label className="p-radiobutton-label">{email}</label>
          </td>
        </tr>
        <tr>
          <td>
            {phone && <>
              <RadioButton
                value={phone}
                onChange={(e) => setPhoneSelected(e.value)}
                checked={phoneSelected === phone}
              />
              <label className="p-radiobutton-label">{phone}</label>
            </>}
          </td>
        </tr>
        <tr>
          <td>
            {address && <>
              <RadioButton
                value={address}
                onChange={(e) => setAddressSelected(e.value)}
                checked={addressSelected === address}
              />
              <label className="p-radiobutton-label">{address}</label>
            </>}
          </td>
        </tr>
        <tr>
          <td>
            {nationality && <>
              <RadioButton
                value={nationality}
                onChange={(e) => setNationalitySelected(e.value)}
                checked={nationalitySelected === nationality}
              />
              <label className="p-radiobutton-label">{nationality}</label>
            </>}
          </td>
        </tr>
        <tr>
          <td>
            {docNumber[0] && <>
            <RadioButton
              value={docNumber[0]}
              onChange={(e) => setWorkPermitSelected(e.value)}
              checked={workPermitSelected == docNumber[0]}
            />
            <label className="p-radiobutton-label">
              {docNumber[0]}
            </label>
            </>}
          </td>
        </tr>
        <tr>
          <td>
            {docNumber[1] && <>
            <RadioButton
              value={docNumber[1]}
              onChange={(e) => setPassportSelected(e.value)}
              checked={passportSelected == docNumber[1]}
            />
            <label className="p-radiobutton-label">
              {docNumber[1]}
            </label>
            </>}
          </td>
        </tr>
      </div>
    );
  };
  const renderUserColumn = (list) => {
    return list.map((item, index) => userColumn(item, index));
  };

  const handleUpdateAndGoNext = () => {

    const data = {
      id: mainUser.id,
      name: usernameSelected,
      email: emailSelected,
      phone: phoneSelected,
      address: addressSelected,
      nationality: nationalitySelected,
      workPermitNumber: workPermitSelected,
      passportNumber: passportSelected,
      duplicateIds: selectedDuplicate.map(e => e.id)
    }
    console.log(data);
    updateMainUser(data).then(res => {
      if (!res.errorCode) {
        showSuccessNotify('Merge membership successfully!');
        mainUser = res
      } else {
        showErrorNotify('Cannot perform action');
      }
    }).then(goToNextStep)
  }

  return (
    <div className="step2Container">
      <p>Select information that is different</p>
      <table>
        <div className="userColumn">
          <tr className="headRow">
            <th></th>
          </tr>
          <tr>
            <td>Username</td>
          </tr>
          <tr>
            <td>Email</td>
          </tr>
          <tr>
            <td>Phone</td>
          </tr>
          <tr>
            <td>Address</td>
          </tr>
          <tr>
            <td>Nationality</td>
          </tr>
          <tr>
            <td>Work Permit No.</td>
          </tr>
          <tr>
            <td>Passport</td>
          </tr>
        </div>
        {userColumn(mainUser)}
        <div style={{ overflowX: "auto", display: "flex" }}>
          {renderUserColumn(selectedDuplicate)}
        </div>
      </table>
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <Button
          label="Back"
          onClick={backToPreviousStep}
          style={{ width: "6rem" }}
          className="yellow-btn"
        />
        <Button
          label="Next"
          style={{ width: "6rem" }}
          className="blue-btn"
          onClick={handleUpdateAndGoNext}
        />
      </div>
    </div>
  );
};

export default MergeDuplicateStep2;
