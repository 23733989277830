import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { useRouteMatch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getCurrentUserId, getTablePageReport } from '../../core/service/CommonService';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { exporUserDiploma, getListDiplomas, getPageDiplomaByUser } from '../../diploma/DiplomaServices';
import moment from 'moment';
import { showErrorNotify, showWarnNotify } from '../../core/service/NotificationService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { getPageParticipants } from '../../constituent-management/participant/ParticipantServices';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';
import { getListHRs } from '../../crm/human_resource/HRServices';
import { APP_FEP, DATE_FORMAT_DISPLAY, HR_RESOURCE_TYPE, PARTICIPANT_STATUS, PRODUCT_CLASSIFICATION, TRAINING_COURSE_ACTIVITY_CODE } from '../../constants';
import { getDeparmentByCode } from '../departments/DepartmentServices';
import { getListProducts } from '../../pim/product/ProductServices';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';

const fileDownload = require('js-file-download');

export const TraniningReportListView = () => {
	const match = useRouteMatch();

	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: '',
		sortOrder: 0,
	});
	const [diplomas, setDiplomas] = useState([]);
	const [filter, setFilter] = useState({});
	const [isLoadTable, setLoadTable] = useState(false);
	const [products, setProducts] = useState([]);
	const [courses, setCourses] = useState([]);
	const [sessions, setSessions] = useState([]);
	const [limitCourseIds, setLimitCourseIds] = useState([]);
	const [limitVendorIds, setLimitVendorIds] = useState([]);
	const [vendors, setVendors] = useState([]);

	const isLimitByVendor = !isScpAdmin() && hasRole([ROLES_CONFIG.TRAINING_VIEW_BY_VENDOR_R]);

	useEffect(() => {
		async function loadDataToFilter() {
			let vendorIds = [];

			if (isLimitByVendor) {
				const userId = getCurrentUserId();

				if (userId) {
					const resVendors = await getListHRs({ userIds: userId ? [userId] : null, type: HR_RESOURCE_TYPE.vendor.value });

					if (!resVendors || resVendors.length === 0) {
						showWarnNotify('No vendor');
						return;
					}

					vendorIds = resVendors.map((v) => v.value);
					setLimitVendorIds(vendorIds);
				}
			}

			const resDepartment = await getDeparmentByCode(TRAINING_COURSE_ACTIVITY_CODE);

			const productFilter = {
				app: APP_FEP,
				departmentIds: resDepartment ? [resDepartment.id] : null,
				hrIds: vendorIds,
				isCourse: true,
			};

			const resProducts = await getListProducts(productFilter, false);

			setProducts(resProducts);

			const tmpDiplomaProducts = resProducts.filter((p) => p.classification === PRODUCT_CLASSIFICATION.combo.value);
			const tmpCourseProducts = resProducts.filter((p) => p.classification === PRODUCT_CLASSIFICATION.simple.value);

			setDiplomas(tmpDiplomaProducts.length > 0 ? tmpDiplomaProducts.map((p) => ({ value: p.id, label: p.name })) : []);
			setCourses(tmpCourseProducts.length > 0 ? tmpCourseProducts.map((p) => ({ value: p.id, label: p.name })) : []);
			setLimitCourseIds(tmpCourseProducts.length > 0 ? tmpCourseProducts.map((p) => p.id) : []);

			loadVendors(vendorIds);

			setLoadTable(true);
		}

		loadDataToFilter();
	}, []);

	useEffect(() => {
		if (isLoadTable) loadPageTraining();
	}, [isLoadTable]);

	const loadPageTraining = () => {
		let tmpFilter = { ...filter };

		if (isLimitByVendor) {
			if (!limitVendorIds || limitVendorIds.length === 0) {
				setDataList([]);
				setPageable({ ...pageable, total: 0, page: 0, rows: 10 });
				setLoadTable(false);
				showWarnNotify('No vendor');
				return;
			}

			if (!tmpFilter.refIds || tmpFilter.refIds.length === 0) {
				tmpFilter.refIds = limitCourseIds;
			}
			if (!tmpFilter.vendorIds || tmpFilter.vendorIds.length === 0) {
				tmpFilter.vendorIds = limitVendorIds;
			}
		}

		tmpFilter.isCourseProgram = true;

		if (!filter.statuses || filter.statuses.length === 0) {
			tmpFilter.statuses = [PARTICIPANT_STATUS.pending.value, PARTICIPANT_STATUS.processing.value, PARTICIPANT_STATUS.completed.value];
		}
		getPageParticipants(tmpFilter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				setDataList(res.content);
				setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
			})
			.finally(() => setLoadTable(false));
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const loadDiplomas = () => {
		getListDiplomas().then((res) => setDiplomas(res));
	};

	const loadVendors = (vendorIds) => {
		getListHRs({ type: HR_RESOURCE_TYPE.vendor.value, includeIds: vendorIds }).then((res) => setVendors(res));
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const onFilterChange = (property, value) => {
		setFilter({ ...filter, [property]: value });
	};

	const onKeyPressEnter = (e) => {
		if (e.key === 'Enter') {
			applyFilter();
		}
	};

	const onDiplomaChange = (val) => {
		let tmpCourses = [];

		if (val) {
			const tmpDiploma = products.find((p) => p.id === val);

			tmpDiploma &&
				tmpDiploma.associatedGroups.forEach((g) => {
					g.associatedProducts.forEach((p) => {
						tmpCourses.push(p.associatedProduct);
					});
				});
		} else {
			tmpCourses = products.filter((p) => p.classification === PRODUCT_CLASSIFICATION.simple.value);
		}

		setCourses(tmpCourses.map((c) => ({ value: c.id, label: c.name })));
		setSessions([]);
		setFilter({
			...filter,
			groupRefIds: val ? [val] : [],
			refIds: null,
			productAvailabilityIds: null,
		});
	};

	const onCourseChange = (val) => {
		let tmpSessions = [];

		if (val) {
			const tmpCourse = products.find((c) => c.id === val);

			if (tmpCourse?.availabilities.length > 0) {
				tmpCourse.availabilities.forEach((s) => {
					tmpSessions.push(s);
				});
			}
		}

		setSessions(tmpSessions.map((s) => ({ value: s.id, label: s.name })));
		setFilter({
			...filter,
			refIds: val && [val],
			productAvailabilityIds: null,
			scheduleIds: null,
		});
	};

	const handleExport = () => {
		if (!dataList || dataList.length === 0) {
			showWarnNotify('No data');
			return;
		}

		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = 'rpt_training_report_' + suf + '.xlsx';

		showloading();

		let tmpFilter = {
			...filter,
			diplomaIds: filter.groupRefIds,
			courseIds: filter.refIds,
			sessionIds: filter.productAvailabilityIds,
			nameUser: filter.searchValue,
			statuses: filter.statuses,
			nationality: filter.nationality,
		};

		if (isLimitByVendor) {
			if (!tmpFilter.vendorIds || tmpFilter.vendorIds.length === 0) {
				tmpFilter.vendorIds = limitVendorIds;
			}
		}
		if (!filter.statuses || filter.statuses.length === 0) {
			tmpFilter.statuses = [PARTICIPANT_STATUS.pending.value, PARTICIPANT_STATUS.processing.value, PARTICIPANT_STATUS.completed.value];
		}

		exporUserDiploma(tmpFilter)
			.then((res) => {
				if (!res.errorCode) {
					fileDownload(res, fileName);
				} else {
					showErrorNotify(res.errorMessage);
				}
			})
			.finally(() => stoploading());
	};

	const tblHeader = (
		<TableHeader
			title='Training Report'
			pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
			changePageLength={(e) => onChangePageLength(e)}
			refresh={() => setLoadTable(true)}
			actionTemplate={<Button label='Export' icon='pi pi-download' disabled={!dataList || dataList.length === 0} onClick={() => handleExport()} />}
		/>
	);

	const applyFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setLoadTable(true);
	};

	const resetFilter = () => {
		setPageable({ ...pageable, page: 0 });
		setFilter({
			...filter,
			groupRefIds: [],
			refIds: [],
			productAvailabilityIds: [],
			searchValue: '',
			statuses: [],
			vendorIds: [],
			dateFrom: '',
			dateFromValue: '',
			dateTo: '',
			dateToValue: '',
			minDate: '',
			maxDate: '',
			nationality: '',
		});
		setLoadTable(true);
	};

	const onDateFromChange = (e) => {
		let maxDate = filter.maxDate ? moment(filter.maxDate) : '';
		let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
		let isCurrentValueAfterMaxDate = currentValue && maxDate && currentValue.isAfter(maxDate);

		setFilter({
			...filter,
			dateFrom: currentValue ? currentValue.format(moment.HTML5_FMT.DATE) : '',
			dateFromValue: e.value,
			minDate: moment(e.value).isValid() ? e.value : '',
			dateTo: !isCurrentValueAfterMaxDate ? filter.dateTo : '',
			dateToValue: !isCurrentValueAfterMaxDate ? filter.dateToValue : '',
			maxDate: !isCurrentValueAfterMaxDate ? filter.maxDate : '',
		});
	};

	const onDateToChange = (e) => {
		let minDate = filter.minDate ? moment(filter.minDate) : '';
		let currentValue = moment(e.value).isValid() ? moment(e.value) : '';
		let isCurrentValueBeforeMinDate = currentValue && minDate && currentValue.isBefore(minDate);

		setFilter({
			...filter,
			dateFrom: !isCurrentValueBeforeMinDate ? filter.dateFrom : '',
			dateFromValue: !isCurrentValueBeforeMinDate ? filter.dateFromValue : '',
			minDate: !isCurrentValueBeforeMinDate ? filter.minDate : '',
			dateTo: currentValue ? currentValue.format(moment.HTML5_FMT.DATE) : '',
			dateToValue: e.value,
			maxDate: moment(e.value).isValid() ? e.value : '',
		});
	};

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>Training Report</BreadcrumbsItem>

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<h1 className='p-w-bold p-m-0'>Training Report</h1>
					<Fieldset legend='Filters'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12 p-lg-6'>
								<div className='p-grid'>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Programme:</span>
											<Dropdown
												value={filter.groupRefIds?.length > 0 ? filter.groupRefIds[0] : null}
												options={diplomas}
												filter
												dataKey='value'
												showClear
												placeholder='Select programme'
												onChange={(e) => onDiplomaChange(e.value)}
											/>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Course:</span>
											<Dropdown
												value={filter.refIds?.length > 0 ? filter.refIds[0] : null}
												options={courses}
												dataKey='value'
												showClear
												filter
												onChange={(e) => onCourseChange(e.value)}
												placeholder='Select course'
											/>
										</div>
									</div>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Session:</span>
											<Dropdown
												value={filter.productAvailabilityIds?.length > 0 ? filter.productAvailabilityIds[0] : null}
												options={sessions}
												dataKey='value'
												showClear
												filter
												onChange={(e) => setFilter({ ...filter, productAvailabilityIds: e.value && [e.value] })}
												placeholder='Select session'
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='p-col-12 p-lg-6'>
								<div className='p-grid'>
									<div className='p-col-12'>
										<div className='p-grid'>
											<div className='p-col-12 p-lg-6'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>User Name/Email:</span>
													<InputText value={filter.searchValue ?? ''} onKeyUp={onKeyPressEnter} onChange={(e) => onFilterChange('searchValue', e.target.value)} />
												</div>
											</div>
											<div className='p-col-12 p-lg-6'>
												<div className='p-inputgroup'>
													<span className='p-inputgroup-addon'>Nationality:</span>
													<InputText value={filter.nationality ?? ''} onKeyUp={onKeyPressEnter} onChange={(e) => onFilterChange('nationality', e.target.value)} />
												</div>
											</div>
										</div>
									</div>

									{(!isLimitByVendor || vendors?.length > 1) && (
										<div className='p-col-12'>
											<div className='p-inputgroup'>
												<span className='p-inputgroup-addon'>Vendor:</span>
												<Dropdown
													value={filter.vendorIds?.[0]}
													onKeyUp={onKeyPressEnter}
													options={vendors}
													filter
													showClear
													optionValue='value'
													onChange={(e) => onFilterChange('vendorIds', e.value ? [e.value] : [])}
												/>
											</div>
										</div>
									)}
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Date From:</span>
											<MaskedCalendar value={filter.dateFromValue} maxDate={filter.maxDate} onChange={(e) => onDateFromChange(e)} showIcon={true} dateFormat='dd/mm/yy' />
										</div>
									</div>
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Date To:</span>
											<MaskedCalendar value={filter.dateToValue} minDate={filter.minDate} onChange={(e) => onDateToChange(e)} showIcon={true} dateFormat='dd/mm/yy' />
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='p-grid'>
							<div className='p-col-12 p-c'>
								<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={() => applyFilter()} />
								<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' onClick={() => resetFilter()} />
							</div>
						</div>
					</Fieldset>
				</div>

				<DataTable
					emptyMessage="There's no record found"
					header={tblHeader}
					value={dataList}
					lazy={true}
					loading={isLoadTable}
					paginator={true}
					first={pageable.page * pageable.rows}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					onPage={onPage}
					onSort={onSort}
					responsive={true}
				>
					<Column headerStyle={{ width: '8em' }} header='ID' field='id' sortable />
					<Column
						header='Name'
						body={(rowData) => {
							return (
								<React.Fragment>
									<div className='p-margin-bottom-5'>{rowData?.user?.name}</div>
									<div>{rowData?.user?.email}</div>
								</React.Fragment>
							);
						}}
					/>
					<Column header='Course' field='refName' sortable={true} body={(rowData) => rowData?.refName} />
					<Column
						header='Session'
						field='start'
						sortable={true}
						body={(rowData) => {
							if (rowData.start || rowData.end) {
								const startDate = moment(rowData.start).format(DATE_FORMAT_DISPLAY);
								const endDate = moment(rowData.end).format(DATE_FORMAT_DISPLAY);
								return (
									<React.Fragment>
										<div className='p-margin-bottom-5'>{rowData?.availability?.name}</div>
										<div className='p-margin-bottom-5'>{startDate === endDate ? startDate : startDate + ' - ' + endDate}</div>
									</React.Fragment>
								);
							}
						}}
					/>
					<Column header={'Programme'} field='groupRefName' sortable={true} body={(rowData) => rowData.groupRefName} />
					{(!isLimitByVendor || vendors?.length > 1) && <Column header={'Vendor'} field='vendor' body={(rowData) => rowData?.vendor?.name} />}
				</DataTable>
			</div>
		</div>
	);
};
