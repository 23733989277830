import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import Tippy from '@tippy.js/react';
import { startProcessingForm, completeForm, toggleWorkflow, markStepDone, markCheckpointDone } from '../../form/answer-list/actions/workflow';
import './WorkflowProgress.css';

class WorkflowProgress extends Component {
  render() {
    if (!this.props.showWorkflow) {
      return (<div />);
    }

    // const startStepItemClass = classNames('p-menuitem-text', {
    //   'active-step': this.props.activeStep === 0
    // });

    return (
      <div className="card wp">
        <div className="p-breadcrumb p-component">
          <ul>
            {/* <li className="p-breadcrumb-home">
              <a className="p-menuitem-link">
                <span className={startStepItemClass}>START</span>
              </a>
            </li>
            <li className="p-breadcrumb-chevron pi pi-chevron-right"></li> */}
            {this.props.workflow.map((item, i) => {
              const stepItemClass = classNames('p-menuitem-text', {
                'active-step': this.props.activeStep === i
              });
              return (
                <Fragment key={i}>
                  <Tippy
                    content={this.getItemDescription(i, item.description, item.checkPoints)}
                    placement='bottom-start'
                    theme='light-border'
                    interactive={true}
                  >
                    <li role="menuitem">
                      <Link
                        to={this.props.activeStep === i && item.pathInNextStep ? item.pathInNextStep : '#'}
                        className="p-menuitem-link"
                      >
                        <span className={stepItemClass}>{item.title}</span>
                      </Link>
                    </li>
                  </Tippy>
                  <li className="p-breadcrumb-chevron pi pi-chevron-right"></li>
                </Fragment>
              )
            }
            )}
            <li className="p-breadcrumb-home">
              <a className="p-menuitem-link">
                <span className="p-menuitem-text">END</span>
              </a>
            </li>
          </ul>
        </div>
        <div>
          {this.props.activeStep < this.props.workflow.length - 1
            ? (
              <Button
                label="Next"
                onClick={(e) => this.props.markStepDone(this.props.formInstanceId, this.props.activeStep)}
                disabled={this.canMoveToNextStep()}
              ></Button>
            )
            : (
              <Button
                label="Finish"
                onClick={(e) => this.props.markStepDone(this.props.formInstanceId, this.props.activeStep)}
                disabled={this.canMoveToNextStep()}
              ></Button>
            )
          }
          <Button
            label="Cancel"
            onClick={(e) => this.props.toggleWorkflow()}
          ></Button>
        </div>
      </div>
    );
  }

  getItemDescription = (stepId, description, checkPoints) => (
    <div>
      <div dangerouslySetInnerHTML={{ __html: description }} />
      {this.props.activeStep === stepId
        ? this.activeStepCheckpointTemplate(checkPoints)
        : this.props.activeStep > stepId
          ? this.finishedStepCheckpointTemplate(checkPoints)
          : this.notYetActiveStepTemplate(checkPoints)
      }
    </div>
  )

  markCheckpointFinished = (checkPointId, e) => {
    if (e.checked) {
      this.props.markCheckpointDone(this.props.formInstanceId, checkPointId);
    }
  }

  canMoveToNextStep() {
    const noFinishedCheckPoints = this.props.finishedCheckPoints.length;
    const noCheckPoints = this.props.activeStep < this.props.workflow.length && this.props.workflow[this.props.activeStep].checkPoints ? this.props.workflow[this.props.activeStep].checkPoints.length : 0;
    return noFinishedCheckPoints < noCheckPoints;
  }

  notYetActiveStepTemplate(checkPoints) {
    return (checkPoints && checkPoints.map((checkPoint, i) => {
      return (<div key={i}>
        <p>
          <i class="material-icons">crop_din</i> {checkPoint.description}
        </p>
      </div>);
    }));
  }

  finishedStepCheckpointTemplate(checkPoints) {
    return (checkPoints && checkPoints.map((checkPoint, i) => {
      return (<div key={i}>
        <p>
          <i class="material-icons">done</i> {checkPoint.description}
        </p>
      </div>);
    }));
  }

  activeStepCheckpointTemplate(checkPoints) {
    return (checkPoints && checkPoints.map((checkPoint, i) => {
      return (<div key={i}>
        <Checkbox inputId={`checkpoint${i}`} value={checkPoint.id} checked={this.props.finishedCheckPoints.includes(checkPoint.id)} onChange={e => this.markCheckpointFinished(checkPoint.id, e)} disabled={this.props.finishedCheckPoints.includes(checkPoint.id)}></Checkbox>
        <label htmlFor={`checkpoint${i}`} className="p-checkbox-label">
          {checkPoint.description}
        </label>
      </div>);
    }));
  }
}

const mapStateToProps = (state) => {
  return {
    requesting: state.workflow.requesting,
    success: state.workflow.success,
    error: state.workflow.error,
    showWorkflow: state.workflow.showWorkflow,
    workflow: state.workflow.workflow,
    formInstanceId: state.workflow.formInstanceId,
    activeStep: state.workflow.activeStep,
    finishedCheckPoints: state.workflow.finishedCheckPoints
  }
}

const mapDispatchToProps = dispatch => {
  return {
    startProcessingForm: (formId, formInstanceId) => {
      dispatch(startProcessingForm(formId, formInstanceId))
    },
    toggleWorkflow: () => {
      dispatch(toggleWorkflow())
    },
    markStepDone: (id, step) => {
      dispatch(markStepDone(id, step))
    },
    markCheckpointDone: (formInstanceId, checkPointId) => {
      dispatch(markCheckpointDone(formInstanceId, checkPointId))
    },
    completeForm: (id, groups) => {
      dispatch(completeForm(id, groups))
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkflowProgress));