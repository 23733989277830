import React from 'react'
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom"
import { Menus } from './menu/Menus'
import { SliderListView } from './slider/SliderListView'
import PageContentListView from './content/PageContentListView'
import { DocumentListView } from './document/DocumentListView'
import { BlockContents } from './block-content/BlockContents'

export const CmsManagement = () => {
    const match = useRouteMatch()
    const baseUrl = match.url

    return (
        <Switch>
            <Route path={`${baseUrl}/dynamic-pages`} exact component={PageContentListView} /> 
            <Route path={`${baseUrl}/menus`} component={Menus} />
            <Route path={`${baseUrl}/sliders`} component={SliderListView} />
            <Route path={`${baseUrl}/block-contents`} component={BlockContents} />
            <Route path={`${baseUrl}/documents`} component={DocumentListView} />
            <Redirect from='*' to='/404' />
        </Switch>
    )    
}