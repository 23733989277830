import React, { useRef, useState, useEffect, useContext } from 'react'
import { Fieldset } from 'primereact/fieldset'
import { InputText } from 'primereact/inputtext'
import Select from 'react-dropdown-select'
import { Button } from 'primereact/button'

import { APP_FEP, TAXONOMY_TYPE } from '../../constants';

import { getListProducts, getBookingProducts } from '../../pim/product/ProductServices';
import { getListDepartments } from '../../scp/departments/DepartmentServices';
import { getListTaxons } from '../../pim/taxon/TaxonServices';

import { ProductItem } from './ProductItem';
import { OrderContext } from './OrderContext'

const defaultProductFilter = {
    app: APP_FEP,
    departmentIds: [],
    categoryIds: [],
    branchIds: [],
    searchValue: '',
    startFrom: '',
    startFromValue: null,
    startTo: '',
    startToValue: null,
    timeFrom: null,
    timeTo: null
}

export const ProductListing = () => {
    const orderContext = useContext(OrderContext);

    const [productFilter, setProductFilter] = useState(defaultProductFilter)
    const [isLoadProduct, setLoadProduct] = useState(true)
    const [products, setProducts] = useState([])
    const [departments, setDepartments] = useState([])
    const [categories, setCategories] = useState([])
    const branches = orderContext.mapBranches?Object.values(orderContext.mapBranches):[];

    useEffect(() => {
        loadDepartments()
    }, [])

    useEffect(() => {
        if(isLoadProduct){
            loadProducts()
            setLoadProduct(false)
        }
    }, [isLoadProduct])

    const loadProducts = () => {
        getBookingProducts(productFilter)
        .then(res => setProducts(res))
    }

    const loadDepartments = () => {
        getListDepartments({externalRef: false, active: true}).then(res => setDepartments(res))
    }

    const loadCategories = (deptId) => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.product.value], departmentIds: [deptId], active: true}).then(res => setCategories(res))
    }

    const onChangeFilterDepartment = (values) => {
        setProductFilter({...productFilter, departmentIds: [values[0].value], categoryIds: []})

        if(values && values.length>0) 
            loadCategories(values[0].value) 
        else 
            setCategories([])
    }

    const onChangeFilterSelect = (attr, values) => {
        let arrValues = [];
        if (values)
            arrValues = values.map(e => (e.value))

        setProductFilter({...productFilter, [attr]: arrValues})
    }

    return (
        <div className="card">
            <div className="p-grid">
                <div className="p-col-12">
                    <Fieldset legend="Find Product">
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-8">
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-6">
                                        <InputText value={productFilter.searchValue} onChange={(e) => setProductFilter({...productFilter, searchValue: e.target.value})} onKeyPress={(e) => {if(e.key==='Enter') setLoadProduct(true)}} placeholder="Enter keyword to find product"/>
                                    </div>
                                </div>
                                <div className="p-grid p-fluid p-mt-2">
                                    <div className="p-col-12 p-md-4">
                                        <label className="p-label">Department</label>
                                        <Select 
                                            values={departments && productFilter.departmentIds && productFilter.departmentIds.length>0 ? departments.filter(x1 => productFilter.departmentIds.some(x2 => x2 === x1.value)) : []}
                                            options={departments} onChange={(values) => onChangeFilterDepartment(values)}
                                            style={{ width: '100%' }} placeholder="All Departments"
                                            noDataRenderer={() => { return (<span className="p-c p-padding-10-0">All Departments</span>) }}
                                        />
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <label className="p-label">Categories</label>
                                        <Select multi
                                            values={categories && productFilter.categoryIds && productFilter.categoryIds.length>0 ? categories.filter(x1 => productFilter.categoryIds.some(x2 => x2 === x1.value)) : []}
                                            options={categories} onChange={(values) => onChangeFilterSelect('categoryIds', values)}
                                            style={{ width: '100%' }} placeholder="All Categories"
                                            noDataRenderer={() => { return (<span className="p-c p-padding-10-0">All Categories</span>) }}
                                        />
                                    </div>
                                    {branches.length>1 && 
                                    <div className="p-col-12 p-md-4">
                                        <label className="p-label">Branches</label>
                                        <Select multi
                                            values={branches && productFilter.branchIds && productFilter.branchIds.length>0 ? branches.filter(x1 => productFilter.branchIds.some(x2 => x2 === x1.value)) : []}
                                            options={branches} onChange={(values) => onChangeFilterSelect('branchIds', values)}
                                            style={{ width: '100%' }} placeholder="All Branches"
                                            noDataRenderer={() => { return (<span className="p-c p-padding-10-0">All Branches</span>) }}
                                        />
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="p-col-12 p-lg-4 p-c p-d-flex p-align-end">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <Button icon="pi pi-filter" iconPos="left" label="Apply Filter" onClick={() => setLoadProduct(true)} />
                                        <Button className="p-button-secondary" icon="pi pi-times" iconPos="left" label="Clear filter" onClick={() => setProductFilter(defaultProductFilter)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fieldset>
                </div>
            </div>
            
            <div>
                <div className="p-grid">
                    <div className="p-col-12 p-md-8">
                        <h2>{products.length>0?('Filter Result: ' + products.length + ' Found'):'No result with filter'}</h2>
                    </div>
                </div>
                <div className="p-grid">
                    {products && products.length>0 &&
                        products.map(p => {
                            return (
                                <div key={p.id} className="p-col-12 p-md-6 p-lg-3">
                                    <ProductItem data={p}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}