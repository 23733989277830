import classNames from 'classnames';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { SplitButton } from 'primereact/splitbutton';
import React, { useEffect, useRef, useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch } from 'react-router-dom';
import { ALIAS_APPLICATION_SUPPORT_CASE, APPLICATION_COMPONENTT_TYPE, APPLICATION_RECORD_STATUS, APPLICATION_TYPE, DATETIME_FORMAT_DISPLAY, DATE_FORMAT_DISPLAY } from '../../constants';
import { MaskedCalendar } from '../../core/components/calendar/MaskedCalendar';
import { TableHeader } from '../../core/components/datatable-tools/TableHeader';
import { getTablePageReport } from '../../core/service/CommonService';
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showConfirmNotify, showErrorNotify, showSuccessNotify } from '../../core/service/NotificationService';
import ApplicationRecordForm from './ApplicationRecordForm';
import {
	deleteApplicationRecord,
	exportApplicationRecords,
	exportApplicationRecordToPdf,
	getApplicationByAlias,
	getApplications,
	getPageApplicationRecord,
	signForApplicationRecord,
} from './ApplicationService';
import { ApplicationToLogForm } from './ApplicationToLogForm';
import { hasRole, isScpAdmin } from '../../core/security/auth';
import { ROLES_CONFIG } from '../../roles';

const fileDownload = require('js-file-download');

const CASE_OPTION_TYPE = {
	all: { value: null, label: 'All' },
	yes: { value: true, label: 'Yes' },
	no: { value: false, label: 'No' },
};

const APPLICATION_RECORD_STATUS_OPTION = {
	pending: { value: APPLICATION_RECORD_STATUS.pending.value, label: 'New' },
	approved: { value: APPLICATION_RECORD_STATUS.approved.value, label: 'Accepted' },
	processing: { value: APPLICATION_RECORD_STATUS.processing.value, label: 'On-hold' },
	closed: { value: APPLICATION_RECORD_STATUS.closed.value, label: APPLICATION_RECORD_STATUS.closed.label },
	rejected: { value: APPLICATION_RECORD_STATUS.rejected.value, label: APPLICATION_RECORD_STATUS.rejected.label },
};

export const SupportCaseApplicationRecordListView = (props) => {
	const match = useRouteMatch();

	const [dataList, setDataList] = useState([]);
	const [pageable, setPageable] = useState({
		page: 0,
		rows: 10,
		total: 0,
		sortField: 'id',
		sortOrder: -1,
	});
	const [filter, setFilter] = useState({
		fieldNames: ['support_case_title', 'support_case_description', 'support_case_main_carer'],
	});
	const [dateFromValue, setDateFromValue] = useState(null);
	const [dateToValue, setDateToValue] = useState(null);
	const [minDateValue, setMinDateValue] = useState(null);
	const [maxDateValue, setMaxDateValue] = useState(null);

	const [isLoadTable, setLoadTable] = useState(false);
	const [recordSelected, setRecordSelected] = useState(null);

	const applicationRecordForm = useRef(null);
	const logForm = useRef(null);

	const isWrite = isScpAdmin() || hasRole([ROLES_CONFIG.CARE_SUPPORT_W]);
	const isDelete = isScpAdmin() || hasRole([ROLES_CONFIG.CARE_SUPPORT_D]);

	useEffect(() => {
		async function loadData() {
			let resApplication = await getApplicationByAlias(ALIAS_APPLICATION_SUPPORT_CASE);
			if (!resApplication.errorCode) {
				setFilter({ ...filter, applicationIds: [resApplication.id] });
				setLoadTable(true);
			}
		}
		loadData();
	}, []);

	useEffect(() => {
		if (isLoadTable) {
			loadPageSupportCaseApplicationRecords();
		}
	}, [isLoadTable]);

	const loadPageSupportCaseApplicationRecords = () => {
		getPageApplicationRecord(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
			.then((res) => {
				setDataList(res.content);
				setPageable({ ...pageable, total: res.totalElements, page: res.pageable.pageNumber, rows: res.pageable.pageSize });
			})
			.finally(() => setLoadTable(false));
	};

	const onPage = (e) => {
		setPageable({ ...pageable, page: e.page });
		setLoadTable(true);
	};

	const onSort = (e) => {
		setPageable({ ...pageable, sortField: e.sortField, sortOrder: e.sortOrder });
		setLoadTable(true);
	};

	const handleRemove = (id) => {
		deleteApplicationRecord(id).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const handleExportToExcel = () => {
		if (dataList.length === 0) return;
		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = 'rpt_care_support_record_' + suf + '.xlsx';

		exportApplicationRecords(filter).then((res) => {
			if (!res.errorCode) {
				fileDownload(res, fileName);
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const handleExportToPDF = (recordId) => {
		let suf = moment().format('YYYYMMDDHHmmss');
		let fileName = 'rpt_care_support_record_' + suf + '.pdf';

		showloading();
		exportApplicationRecordToPdf(recordId)
			.then((res) => {
				if (!res.errorCode) {
					fileDownload(res, fileName);
				} else {
					showErrorNotify(res.errorMessage);
				}
			})
			.finally(() => stoploading());
	};

	const handleSign = (recordId, isSign) => {
		signForApplicationRecord(recordId, isSign).then((res) => {
			if (!res.errorCode) {
				setLoadTable(true);
				showSuccessNotify('Action submitted');
			} else {
				showErrorNotify(res.errorMessage);
			}
		});
	};

	const actionTemplate = (rowData) => {
		let items = [{ label: 'Export to PDF', icon: 'pi pi-download', command: () => handleExportToPDF(rowData.id) }];
		if (isWrite) {
			if (rowData.application.type === APPLICATION_TYPE.approval.value && rowData.status === APPLICATION_RECORD_STATUS.approved.value && !rowData.isSign) {
				items.unshift({ label: 'Case', icon: 'pi pi-check-circle', command: () => handleSign(rowData.id, true) });
			}
			items.push({ label: 'Edit', icon: 'pi pi-pencil', command: () => onAddOrEditForm(rowData.id) });
		}
		if (isDelete) {
			items.push({ label: 'Remove', icon: 'pi pi-trash', command: () => onRemoveRecord(rowData.id) });
		}

		return (
			<SplitButton
				label='View'
				icon='pi pi-search'
				className='p-button-constrast p-l'
				model={items}
				onClick={() => {
					applicationRecordForm.current.openForm(rowData.id);
					setRecordSelected(rowData);
				}}
			/>
		);
	};

	const onRemoveRecord = (id) => {
		showConfirmNotify({
			accept: () => handleRemove(id),
		});
	};

	const onDateFromChange = (value) => {
		let currentValueToDate = value && moment(value).isValid() ? moment(value) : '';
		let tmpMaxDate = maxDateValue ? moment(maxDateValue) : '';
		let isCurrentValueAfterMaxDate = currentValueToDate && tmpMaxDate && currentValueToDate.isAfter(tmpMaxDate);
		if (currentValueToDate) {
			currentValueToDate.set({ h: 0, m: 0, s: 0 });
		}
		setFilter({
			...filter,
			dateFrom: currentValueToDate ? currentValueToDate.format(moment.HTML5_FMT.DATE) : '',
			dateTo: !isCurrentValueAfterMaxDate ? filter.dateTo : '',
		});
		setDateFromValue(value);
		setDateToValue(!isCurrentValueAfterMaxDate ? dateToValue : '');
		setMinDateValue(moment(value).isValid() ? value : '');
		setMaxDateValue(!isCurrentValueAfterMaxDate ? maxDateValue : '');
	};

	const onDateToChange = (value) => {
		let currentValueToDate = moment(value).isValid() ? moment(value) : '';
		let tmpMinDate = minDateValue ? moment(minDateValue) : '';
		let isCurrentValueBeforeMinDate = currentValueToDate && tmpMinDate && currentValueToDate.isBefore(tmpMinDate);
		if (currentValueToDate) {
			currentValueToDate.set({ h: 23, m: 59, s: 59 });
		}
		setFilter({
			...filter,
			dateFrom: !isCurrentValueBeforeMinDate ? filter.dateFrom : '',
			dateTo: currentValueToDate ? currentValueToDate.format(moment.HTML5_FMT.DATE) : '',
		});
		setDateFromValue(!isCurrentValueBeforeMinDate ? dateFromValue : '');
		setDateToValue(value);
		setMinDateValue(!isCurrentValueBeforeMinDate ? minDateValue : '');
		setMaxDateValue(moment(value).isValid() ? value : '');
	};

	const clearFilter = () => {
		setFilter({
			...filter,
			dateFrom: '',
			dateTo: '',
			statuses: [],
			isSign: null,
			fieldValue: '',
			includeIds: [],
			signNumberFrom: null,
			signNumberTo: null,
		});
		setDateFromValue(null);
		setDateToValue(null);
		setMinDateValue(null);
		setMaxDateValue(null);
		setLoadTable(true);
	};

	const onChangePageLength = (e) => {
		setPageable({ ...pageable, rows: e, page: 0 });
		setLoadTable(true);
	};

	const onAddOrEditForm = (id) => {
		logForm.current.openForm(ALIAS_APPLICATION_SUPPORT_CASE, id);
	};

	const tblHeader = () => {
		let items = [{ label: 'Export', icon: 'pi pi-download', disabled: dataList.length === 0, command: () => handleExportToExcel() }];
		return (
			<TableHeader
				title='Care Support'
				pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
				changePageLength={(e) => onChangePageLength(e)}
				refresh={() => loadPageSupportCaseApplicationRecords()}
				actionTemplate={
					<React.Fragment>
						{isWrite ? (
							<SplitButton className='p-button-constrast' label='Create' icon='pi pi-plus' model={items} onClick={() => onAddOrEditForm()} />
						) : (
							<Button className='p-button-constrast' label='Export' icon='pi pi-download' onClick={() => handleExportToExcel()} />
						)}
					</React.Fragment>
				}
			/>
		);
	};

	return (
		<div className='p-grid'>
			<BreadcrumbsItem to={match.url}>Care Support</BreadcrumbsItem>

			<ApplicationRecordForm ref={applicationRecordForm} refreshTable={() => loadPageSupportCaseApplicationRecords()} />

			<ApplicationToLogForm ref={logForm} reloadTable={() => loadPageSupportCaseApplicationRecords()} />

			<div className='p-col-12'>
				<div className='card card-w-title'>
					<Fieldset legend='Filter'>
						<div className='p-grid p-fluid'>
							<div className='p-col-12 p-md-6'>
								<div className='p-inputgroup'>
									<span className='p-inputgroup-addon'>Search: </span>
									<InputText
										value={filter.fieldValue}
										onChange={(e) => setFilter({ ...filter, fieldValue: e.target.value })}
										placeholder='Type search value (person assigned, name of MDW, describe the problem)'
									/>
								</div>
							</div>
							<div className='p-col-12 p-md-6'>
								<div className='p-grid'>
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Date From: </span>
											<MaskedCalendar value={dateFromValue} onChange={(e) => onDateFromChange(e.value)} showIcon={true} />
										</div>
									</div>
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Date To: </span>
											<MaskedCalendar value={dateToValue} onChange={(e) => onDateToChange(e.value)} showIcon={true} />
										</div>
									</div>
								</div>
							</div>
							<div className='p-col-12 p-md-6'>
								<div className='p-grid'>
									<div className='p-col-12'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Case Type: </span>
											<Dropdown value={filter.isSign} options={Object.values(CASE_OPTION_TYPE)} onChange={(e) => setFilter({ ...filter, isSign: e.value })} />
										</div>
									</div>
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Call ID: </span>
											<InputText
												value={filter.includeIds && filter.includeIds.length > 0 ? filter.includeIds[0] : ''}
												keyfilter='pnum'
												onChange={(e) => setFilter({ ...filter, includeIds: e.target.value ? [e.target.value] : [] })}
											/>
										</div>
									</div>
									<div className='p-col-12 p-md-6'>
										<div className='p-inputgroup'>
											<span className='p-inputgroup-addon'>Case Number: </span>
											<InputText value={filter.signNumberFrom ?? ''} keyfilter='pnum' onChange={(e) => setFilter({ ...filter, signNumberFrom: e.target.value, signNumberTo: e.target.value })} />
										</div>
									</div>
								</div>
							</div>
							<div className='p-col-12 p-md-6'>
								<div className='p-inputgroup'>
									<span className='p-inputgroup-addon'>Status: </span>
									<MultiSelect value={filter.statuses} options={Object.values(APPLICATION_RECORD_STATUS_OPTION)} onChange={(e) => setFilter({ ...filter, statuses: e.value })} />
								</div>
							</div>
						</div>
						<div className='p-grid p-margin-top-10'>
							<div className='p-col-12 p-c'>
								<Button icon='pi pi-filter' iconPos='left' label='Search' onClick={() => setLoadTable(true)} />
								<Button className='p-button-warning' icon='pi pi-trash' iconPos='left' label='Clear' onClick={() => clearFilter()} />
							</div>
						</div>
					</Fieldset>
				</div>
			</div>

			<div className='p-col-12'>
				<DataTable
					emptyMessage="There's no record found"
					header={tblHeader()}
					value={dataList}
					lazy={true}
					loading={isLoadTable}
					paginator={true}
					first={pageable.page * pageable.rows}
					rows={pageable.rows}
					totalRecords={pageable.total}
					sortField={pageable.sortField}
					sortOrder={pageable.sortOrder}
					onPage={onPage}
					onSort={onSort}
					responsive={true}
					selectionMode='single'
					selection={recordSelected}
					onSelectionChange={(e) => setRecordSelected(e.value)}
				>
					<Column header='Call ID' field='id' headerStyle={{ width: '10em' }} sortable />
					<Column header='Case No.' field='numberSign' headerStyle={{ width: '8em' }} sortable body={(rowData) => (rowData.isSign ? rowData.numberSign : '')} />
					<Column
						header='Name of MDW'
						body={(rowData) => {
							let tmpMainCarer;
							let tmpFieldValues = rowData.fieldValues ? rowData.fieldValues : [];
							for (const tmpValue of tmpFieldValues) {
								let tmpField = tmpValue.field;

								if (tmpField && tmpValue.value) {
									if (tmpField.name === 'support_case_main_carer') {
										tmpMainCarer = tmpValue.value;
									}
								}
							}
							return tmpMainCarer;
						}}
					/>
					<Column
						header='Nature of call'
						body={(rowData) => {
							let tmpTitle;
							let tmpFieldValues = rowData.fieldValues ? rowData.fieldValues : [];
							for (const tmpValue of tmpFieldValues) {
								let tmpField = tmpValue.field;

								if (tmpField && tmpValue.value) {
									if (tmpField.name === 'support_case_title') {
										tmpTitle = tmpValue.value;
									}
								}
							}
							return tmpTitle;
						}}
					/>
					<Column
						header='Status'
						field='status'
						sortable
						headerStyle={{ width: '8em' }}
						body={(rowData) => {
							if (!rowData.status || rowData.status === APPLICATION_RECORD_STATUS.pending.value) {
								return 'New';
							} else if (rowData.status === APPLICATION_RECORD_STATUS.processing.value) {
								return 'On-hold';
							} else if (rowData.status === APPLICATION_RECORD_STATUS.approved.value) {
								return 'Accepted';
							} else {
								return APPLICATION_RECORD_STATUS[rowData.status]?.label;
							}
						}}
					/>
					<Column
						field='date'
						header='Reporting date'
						body={(rowData) => {
							let strReportingDate;
							let tmpFieldValues = rowData.fieldValues ? rowData.fieldValues : [];
							for (const tmpValue of tmpFieldValues) {
								let tmpField = tmpValue.field;

								if (tmpField && tmpValue.value) {
									if (tmpField.name === 'support_case_reporting_date') {
										let tmpReportingDateByDatetime = moment(tmpValue.value, 'YYYY-MM-DD HH:mm');
										let tmpReportingDateByDate = moment(tmpValue.value, moment.HTML5_FMT.DATE);
										if (tmpReportingDateByDatetime.isValid()) {
											strReportingDate = tmpReportingDateByDatetime.format(DATETIME_FORMAT_DISPLAY);
										} else if (tmpReportingDateByDate.isValid()) {
											strReportingDate = tmpReportingDateByDate.format(DATETIME_FORMAT_DISPLAY);
										}
									}
								}
							}
							if (!strReportingDate) {
								strReportingDate = moment(rowData.date, 'YYYY-MM-DD HH:mm').format(DATETIME_FORMAT_DISPLAY);
							}
							return strReportingDate;
						}}
					/>
					<Column
						header='Case officer assigned'
						body={(rowData) => {
							let tmpPersonAssigned;
							let tmpFieldValues = rowData.fieldValues ? rowData.fieldValues : [];
							for (const tmpValue of tmpFieldValues) {
								let tmpField = tmpValue.field;

								if (tmpField && tmpValue.value) {
									if (tmpField.name === 'support_case_person_assign') {
										tmpPersonAssigned = tmpValue.value;
									}
								}
							}
							return tmpPersonAssigned;
						}}
					/>
					<Column header='Actions' body={actionTemplate} className='tc-actions' style={{ textAlign: 'center', width: '12em', overlay: 'visible' }} />
					{/* <Column header="Description" 
                    //    body={rowData => {
                    //        let tmpDescription
                    //        let tmpFieldValues = rowData.fieldValues?rowData.fieldValues:[]
                    //        for (const tmpValue of tmpFieldValues) {
                    //            let tmpField = tmpValue.field
                    //            
                    //            if(tmpField && tmpValue.value){
                    //                // if(tmpField.name==='support_case_type'){
                    //                //     tmpDescription = tmpValue.value
                    //                // }
                    //                if(tmpField.name==='support_case_title'){
                    //                    tmpDescription = tmpValue.value
                    //                }
                    //            }
                    //        }
                    //        return tmpDescription

                    //        // let tmpValue = ''

                    //        // let tmpPersonalInfos = rowData.personalInfo

                    //        // rowData.application.contents && rowData.application.contents.map((content, idxContent) => {
                    //        //     return content.components && content.components.map((component, idxComponent) => {
                    //        //         if(component.type===APPLICATION_COMPONENTT_TYPE.input_field.value){
                    //        //             let tmpPersonalSet = component.personalSet
                    //        //             let tmpPersonalField = tmpPersonalSet&&tmpPersonalSet.personalField?tmpPersonalSet.personalField:{}
                    //        //             let tmpCustomSet = component.customSet

                    //        //             let tmpPersonalValue = tmpPersonalInfos&&tmpPersonalInfos[idxContent]&&tmpPersonalInfos[idxContent].personalInfos&&tmpPersonalInfos[idxContent].personalInfos[idxComponent]&&tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                    //        //                 ? tmpPersonalInfos[idxContent].personalInfos[idxComponent].personalInfo
                    //        //                 : {}
                    //        //                 if(tmpPersonalField.useTitle) tmpValue += tmpPersonalValue?tmpPersonalValue.title??'':''
                    //        //                 if(tmpPersonalField.useFirstName) tmpValue += ' '+(tmpPersonalValue?tmpPersonalValue.firstName??'':'')
                    //        //                 if(tmpPersonalField.useLastName) tmpValue += ' '+(tmpPersonalValue?tmpPersonalValue.lastName??'':'')
                    //        //         }
                    //        //     })
                    //        // })
                    //        // return tmpValue
                    //    }}
                    /> */}
				</DataTable>
			</div>
		</div>
	);
};
