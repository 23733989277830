import React, { useState} from 'react';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import classNames from 'classnames';

export const TableHeader = (props) => {
    const [length, setLength] = useState(10)
    const showLength = props.showLength!==undefined?props.showLength:true
    const showRefresh = props.showRefresh!==undefined?props.showRefresh:true

    const onChangePageLength = (val) => {
        if(val!==length){
            setLength(val)
            props.changePageLength(val)
        }
    }

    const tableLengthOptions = [
        {label: '10', command: (e) => onChangePageLength(10)},
        {label: '25', command: (e) => onChangePageLength(25)},
        {label: '50', command: (e) => onChangePageLength(50)},
        {label: '100', command: (e) => onChangePageLength(100)},
        // {label: 'All', command: (e) => onChangePageLength(0)}
    ]

    return(
        <div className="p-grid p-justify-between">
            <div className="p-col-3 p-l">
                {props.actionTemplate}
            </div>
            <div className="p-col-6 p-c">
                <h3 className={"p-margin-5-0 " + (props.titleClassname?props.titleClassname:'p-size-20')}>{props.title}</h3>
                {props.pageReport&&<span>{props.pageReport}</span>}
            </div>
            <div className="p-col-3 p-r">
                {showLength && 
                <span className="p-text-nowrap">
                    <span>Row per page: </span>
                    <SplitButton className="length-ctrl p-button-constrast p-margin-right-5" label={length===0?'All':`${length}`}
                        model={tableLengthOptions}>
                    </SplitButton>
                </span>
                }
                {showRefresh && <Button icon="pi pi-refresh" className="p-button-constrast" style={{borderRadius: '4px'}} onClick={props.refresh}/>}
            </div>
        </div>
    )
}