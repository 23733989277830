import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { SplitButton } from "primereact/splitbutton";
import React, { useEffect, useRef, useState } from "react";
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useRouteMatch } from "react-router-dom";
import { APP_FEP, PRODUCT_CLASSIFICATION, PRODUCT_TYPE, TAXONOMY_TYPE, PRODUCT_SECONDARY_TYPE, PRODUCT_PARTICIPANT_TYPE } from "../../constants";
import { TableHeader } from "../../core/components/datatable-tools/TableHeader";
import { getTablePageReport } from "../../core/service/CommonService";
import { showloading, stoploading } from '../../core/service/LoadingService';
import { showErrorNotify, showSuccessNotify, showConfirmNotify } from "../../core/service/NotificationService";
import { getListDepartments } from "../../scp/departments/DepartmentServices";
import { getListTaxons } from "../taxon/TaxonServices";
import { MembershipProductForm } from "./MembershipProductForm";
import { getPageProducts, removeProduct } from "../product/ProductServices";
import classNames from "classnames";

const MEMBERSHIP_TYPES = [
    PRODUCT_SECONDARY_TYPE.ms_individual,
    PRODUCT_SECONDARY_TYPE.ms_group
]

export const MembershipProductListView = () => {
    const match = useRouteMatch();
    const frmProduct = useRef(null);
    const [filter, setFilter] = useState({app: APP_FEP, types: [PRODUCT_TYPE.membership.value], participantTypes: [PRODUCT_PARTICIPANT_TYPE.adult.value, PRODUCT_PARTICIPANT_TYPE.youth.value]})
    const [data, setData] = useState([])
    const [pageable, setPageable] = useState({
        page: 0,
        rows: 10,
        total: 0,
        sortField: '',
        sortOrder: -1
    })
    const [isLoadTable, setLoadTable] = useState(true);
    const [branches, setBranches] = useState([])
    const [categories, setCategories] = useState([])

    useEffect(()=>{
        loadAllBranchs()
        loadAllCategories();
    },[])

    useEffect(() => {
        if(isLoadTable)
            loadPageProducts();
    }, [isLoadTable])

    const loadPageProducts = () =>{
        showloading()
        getPageProducts(filter, pageable.page, pageable.rows, pageable.sortField, pageable.sortOrder)
        .then(res => {
            if(res){
                setData(res.content)
                setPageable({...pageable,
                    total: res.totalElements,
                    page: res.pageable.pageNumber,
                    rows: res.pageable.pageSize
                })
                setLoadTable(false)
            }
        })
        .finally(stoploading())
    }

    const onPage = (e) =>{
        setPageable({
            ...pageable,
            page: e.page
        })
        setLoadTable(true)
    }

    const onSort = (e) =>{
        setPageable({...pageable,
            sortField: e.sortField,
            sortOrder: e.sortOrder
        })
        setLoadTable(true);
    }

    const loadAllBranchs = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.branch.value}).then(res => setBranches(res))
    }

    const loadAllCategories = () => {
        getListTaxons({app: APP_FEP, type: TAXONOMY_TYPE.category.value, secondaryTypes: [TAXONOMY_TYPE.membership.value]}).then(res => setCategories(res))
    }

    const onChangePageLength = (e) => {
        setPageable({...pageable,
            rows: e,
            page: 0
        })
        setLoadTable(true)
    };

    const deleteProduct = (id) => {
        showConfirmNotify({
            message: 'Are you sure to remove this activity? This action can not be undo.',
            accept: () => handleRemoveProduct(id)
        })
    }

    const handleRemoveProduct = (id) => {
        removeProduct(id)
        .then(res => {
            if(!res.errorCode){
                setLoadTable(true)
                showSuccessNotify('Action submitted')
            }else{
                showErrorNotify(res.errorMessage)
            }
        })
    }

    const applyFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setLoadTable(true)
    }

    const resetFilter = () => {
        setPageable({...pageable,
            page: 0
        })
        setFilter({app:APP_FEP, searchValue: '', types: [PRODUCT_TYPE.membership.value]})
        setLoadTable(true)
    }

    // const createProductItems = [
    //     { label: 'Create Variable Activity', icon: '', command: (e) => frmProduct.current.openForm(PRODUCT_CLASSIFICATION.variable.value)},
    //     { label: 'Create Combo Activity', icon: '', command: (e) => frmProduct.current.openForm(PRODUCT_CLASSIFICATION.combo.value)},
    // ]

    const tblHeader = (
        <TableHeader title="Membership Products"
            actionTemplate={
                <Button label="Create membership product" icon="pi pi-plus" className="p-button-constrast" onClick={() => frmProduct.current.openForm(PRODUCT_CLASSIFICATION.simple.value)}/>
            }
            pageReport={getTablePageReport(pageable.page, pageable.rows, pageable.total)}
            changePageLength={(e) => onChangePageLength(e)}
            refresh={() => setLoadTable(true)}
        />
    );

    return (
        <div className="p-grid">
            <BreadcrumbsItem to={match.url}>Membership Products</BreadcrumbsItem>

            <MembershipProductForm ref={frmProduct}
                refreshTable={() => setLoadTable(true)}
            />

            <div className="p-col-12">
                <div className="card card-w-title">
                    <h1 className="p-w-bold">Membership Products</h1>
                    <Fieldset legend="Filters">
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Name:</span>
                                            <InputText value={filter.searchValue} onChange={e => setFilter({...filter, searchValue: e.target.value})} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Type:</span>
                                            <Dropdown value={filter.secondaryTypes&&filter.secondaryTypes.length>0?filter.secondaryTypes[0]:null} options={MEMBERSHIP_TYPES} showClear onChange={e => setFilter({...filter, secondaryTypes: e.value?[e.value]:[]})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6">
                                <div className="p-grid">
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Branch:</span>
                                            <Dropdown value={filter.branchIds&&filter.branchIds.length>0?filter.branchIds[0]:null} options={branches} showClear onChange={e => setFilter({...filter, branchIds: e.value?[e.value]:[]})} />
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon">Category:</span>
                                            <Dropdown value={filter.categoryIds&&filter.categoryIds.length>0?filter.categoryIds[0]:null} options={categories} showClear onChange={e => setFilter({...filter, categoryIds: e.value?[e.value]:[]})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-c">
                                <Button icon="pi pi-filter" iconPos="left" label="Search" onClick={() => applyFilter()} />
                                <Button className="p-button-warning" icon="pi pi-trash" iconPos="left" label="Clear" onClick={() => resetFilter()} />
                            </div>
                        </div>
                    </Fieldset>
                </div>

                <DataTable emptyMessage="There's no record found" lazy={true} paginator={true} value={data} loading={isLoadTable}
                    first={pageable.page * pageable.rows}
                    header={tblHeader} onPage={onPage} onSort={onSort}
                    rows={pageable.rows} totalRecords={pageable.total}
                    sortField={pageable.sortField} sortOrder={pageable.sortOrder}
                    responsive={true}
                >
                    <Column header="ID" field="id" sortable={true}/>
                    <Column header="Code" field="code" sortable={true}/>
                    <Column header="Name" field="name" sortable={true} />
                    <Column header="Type" field="secondaryType" sortable={true} body={(rowData) => rowData.type && PRODUCT_SECONDARY_TYPE[rowData.secondaryType] && PRODUCT_SECONDARY_TYPE[rowData.secondaryType].label}/>
                    <Column header="Member Slot" field="slot" sortable={true}/>
                    <Column header="Show In Store" field="show"  style={{width: '10em'}} bodyClassName="p-c" body={(rowData) => <span className={classNames('status', {'active': rowData.show, 'inactive': !rowData.show})}>{rowData.show ? 'Yes' : 'No'}</span>}/>
                    {/* <Column header="Active" field="active" body={(rowData) => rowData.active ? 'Yes' : 'No'}/> */}
                    <Column header="" className="tc-actions" style={{width: '12em' }} 
                        body={(rowData) => {
                            const actionItems = [
                                { label: 'Remove', icon: 'pi pi-times', command: (e) => deleteProduct(rowData.id)}
                            ]
                            return(
                                <SplitButton label="Edit"  model={actionItems} icon="pi pi-pencil" className="p-button-constrast" onClick={() => frmProduct.current.openForm(rowData.classification, rowData.id)}></SplitButton>
                            )
                        }}
                    />
                </DataTable>
            </div>
        </div>
    );
}
