import React, {Component} from "react";
import moment from 'moment';
import {Redirect} from 'react-router-dom';
import { ProgramServices } from "./ProgramServices";
import { ParticipantServices } from "../../constituent-management/participant/ParticipantServices";
import { ProgramAssessmentServices } from "./ProgramAssessmentServices";
import { Sidebar } from "primereact/sidebar";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { showNotification } from '../../core/service/NotificationService';
import { MaskedCalendar } from "../../core/components/calendar/MaskedCalendar";
import { Dropdown } from "primereact/dropdown";

export class QuickAssessmentForm extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            assessment: {
                name: moment(new Date()).format('MM-DD-YYYY'),
                date: moment(new Date()).format('YYYY-MM-DD'),
                type: '',
                attendances: {},
                participantsLevels: {},
                participantsLevelsSkills: {}
            },
            dateValue: moment(new Date(), 'MM-DD-YYYY').toDate(),
            headerForm: 'Take Attendance',
            visible: false,
            participants: [],
            goToDetail: '',
            talents: [],
            participantAllLevels: {},
            attendanceToAll: false,
            participantsLevelsToAllSkills: {},
            errors: {}
        }
        this.programServices = new ProgramServices();
        this.assessmentServices = new ProgramAssessmentServices();
        this.participantServices = new ParticipantServices();
    }

    componentDidMount(){
        this.loadListParticipants();
    }

    actionForm = (type, talents) => {
        let temAttendances = {};
        let temParticipantsLevels = {};
        let temParticipantsLevelsSkills = {};

        this.state.participants.map((part) => {
            if(type === 'attendance'){
                temAttendances[part.id] = false;
            }else {
                temParticipantsLevels[part.id] = {};
                temParticipantsLevelsSkills[part.id] = {};

                talents.map((talent) => {
                    temParticipantsLevels[part.id] = {
                        ...temParticipantsLevels[part.id],
                        [talent.key] : {
                            current: part.talents ? part.talents[talent.key] : '',
                            promote: part.talents ? part.talents[talent.key] : ''
                        }
                    }

                    temParticipantsLevelsSkills[part.id] = {
                        ...temParticipantsLevelsSkills[part.id],
                        [talent.key]: {}
                    }
                    if(type === 'skill'){
                        let arrayLevels = talent.values ? talent.values : [];

                        arrayLevels.map(level => {
                            let arraySkills = level.skills ? level.skills : [];
                            temParticipantsLevelsSkills[part.id][talent.key] = {
                                ...temParticipantsLevelsSkills[part.id][talent.key],
                                [level.value]: {}
                            }
                            arraySkills.map(skill => {
                                temParticipantsLevelsSkills[part.id][talent.key][level.value]= {
                                    ...temParticipantsLevelsSkills[part.id][talent.key][level.value],
                                    [skill.value] : false
                                }
                            })
                        })
                    }
                })
            }
        })
        this.setState({
            assessment: {
                name: moment(new Date()).format('MM-DD-YYYY'),
                date: moment(new Date()).format('YYYY-MM-DD'),
                type: type,
                attendances: temAttendances,
                participantsLevels: temParticipantsLevels,
                participantsLevelsSkills: temParticipantsLevelsSkills
            },
            headerForm: type==='attendance' ? 'Take Attendance' : (type==='level' ? 'Level Recommendation' : 'Skill Assessment'),
            visible: true,
            participantAllLevels: {},
            attendanceToAll: false,
            participantsLevelsToAllSkills: {},
            talents: talents
        });
    }

    handleSaveForm = () => {
        this.setState({ errors: {} });
        this.assessmentServices.saveTakeAssessment(this.props.pId, this.props.cId, this.state.assessment)
        .then(res => {
            if(!res.errorCode){
                // this.setState({ goToDetail: res.id })
                this.hideForm()
                showNotification('success', 'Success Message', 'Action submitted');
                
            }else{
                showNotification('error', 'Error Message', 'Cannot perform action');
                if(res.errorCode===400) this.setState({errors: res.errorObj});
            }
        });
    }

    loadListParticipants = () => {
        this.participantServices.getParticipants({childRefId: this.props.cId})
        .then(res => {
            if(!res.errorCode){
                this.setState({
                    participants: res,
                    attendeeTotal: res.length,
                });
            }else{
                showNotification('error', 'Error Message', res.status.message);
            }
        })
    }

    onChangeDate = (e) => {
        this.setState({
            dateValue: e.value ,
            assessment: {
                ...this.state.assessment, date: e.value ? moment(e.value).format(moment.HTML5_FMT.DATE) : ''
            }
        })
    }

    onCheckAttende = (e) =>{
        this.setState({
            assessment: {
                ...this.state.assessment,
                attendances: {
                    ...this.state.assessment.attendances,
                    [e.value] : e.checked
                }
            },
            attendanceToAll: false
        })
    }

    onCheckAttendanceToAll = (e) => {
        let termAttendaces = {...this.state.assessment.attendances};

        Object.keys(termAttendaces).map((key) => {
            termAttendaces[key] = e.checked
        });
        this.setState({
            assessment:{
                ...this.state.assessment,
                attendances: termAttendaces
            },
            attendanceToAll: e.checked
        })
    }

    onChangeSelectLevel = (id, key, value) => {
        this.setState({
            assessment: {
                ...this.state.assessment,
                participantsLevels: {
                    ...this.state.assessment.participantsLevels,
                    [id] : {
                        ...this.state.assessment.participantsLevels[id],
                        [key] : {
                            ...this.state.assessment.participantsLevels[id][key],
                            // current: '',
                            promote: value
                        }
                    }
                }
            }
        })
    }

    onChangeSelectLevelToAllLevel = (e, key) => {
        let tempParts = {...this.state.assessment.participantsLevels};
        let tempPartAllLevels = {...this.state.participantAllLevels};

        Object.entries(tempParts).map(([itemKeyPart, itemValuePart]) => {
            Object.keys(itemValuePart).map((itemKey) => {
                if(itemKey === key){
                    tempParts[itemKeyPart][itemKey] = {
                        ...tempParts[itemKeyPart][itemKey],
                        promote: e.value
                    }
                    tempPartAllLevels[itemKey] = e.value;
                }
            });
        });
        this.setState({
            assessment:{ ...this.state.assessment,
                participantsLevels: tempParts
            },
            participantAllLevels: tempPartAllLevels
        })
    }

    onCheckSkill = (id, talent, level, skill, e) => {
        let temParticipantsLevelsToAllSkills = !this.state.participantsLevelsToAllSkills[id] ? 
            {   ...this.state.participantsLevelsToAllSkills,
                [id]: { [talent]: {} }
            }
            : {...this.state.participantsLevelsToAllSkills};
        
        if(!e.checked){ //remove check all skill of level
            temParticipantsLevelsToAllSkills = { ...temParticipantsLevelsToAllSkills,
                [id]: { ...temParticipantsLevelsToAllSkills[id],
                    [talent]: { ...temParticipantsLevelsToAllSkills[id][talent],
                        [level]: false
                    }
                }
            }
        }

        this.setState({
            assessment: { ...this.state.assessment,
                participantsLevelsSkills: { ...this.state.assessment.participantsLevelsSkills,
                    [id]: { ...this.state.assessment.participantsLevelsSkills[id],
                        [talent]: { ...this.state.assessment.participantsLevelsSkills[id][talent],
                            [level]: { ...this.state.assessment.participantsLevelsSkills[id][talent][level],
                                [skill]: e.checked
                            }
                        }
                    }
                }
            },
            participantsLevelsToAllSkills: temParticipantsLevelsToAllSkills
        }, () => {
            if(e.checked){ // checked all check also check level
                let temPartAssessment = {...this.state.assessment.participantsLevelsSkills}
                let tempPartLevelsAllSkills = {...this.state.participantsLevelsToAllSkills};
                this.state.talents.map((itemTalent) => {
                    if(itemTalent.key === talent){
                        itemTalent.values.map(itemLevel => {
                            if(itemLevel.value === level){
                                let allSkillsToChecked = true;
                                if(Object.keys(temPartAssessment[id][talent][level]).length === itemLevel.skills.length){
                                    Object.entries(temPartAssessment[id][talent][level]).map(([, itemValue]) => {
                                        if(!itemValue){
                                            allSkillsToChecked = false;
                                        }
                                    })
                                }else{
                                    allSkillsToChecked = false;
                                }
                                if(allSkillsToChecked){
                                    tempPartLevelsAllSkills[id][talent] = { 
                                        ...tempPartLevelsAllSkills[id][talent],
                                        [level]: true
                                    }
                                }
                            }
                        })
                    }
                })
                this.setState({
                    participantsLevelsToAllSkills: tempPartLevelsAllSkills
                })
            }
        })
    }

    onCheckAllLevelSkills = (id, talent, level, e) => {
        let temParticipantsLevelsSkills = {...this.state.assessment.participantsLevelsSkills[id][talent][level]};

        Object.entries(temParticipantsLevelsSkills).map(([keyLevel]) => {
            temParticipantsLevelsSkills[keyLevel] = e.checked;
        })
        
        let temParticipantsLevelsToAllSkills = !this.state.participantsLevelsToAllSkills[id] ? 
            {   ...this.state.participantsLevelsToAllSkills,
                [id]: { [talent]: {} }
            }
            : {...this.state.participantsLevelsToAllSkills};
        
        temParticipantsLevelsToAllSkills = { ...temParticipantsLevelsToAllSkills,
            [id]: { ...temParticipantsLevelsToAllSkills[id],
                [talent]: { ...temParticipantsLevelsToAllSkills[id][talent],
                    [level]: e.checked
                }
            }
        }
        this.setState({
            assessment: { ...this.state.assessment,
                participantsLevelsSkills: { ...this.state.assessment.participantsLevelsSkills,
                    [id]: { ...this.state.assessment.participantsLevelsSkills[id],
                        [talent]: { ...this.state.assessment.participantsLevelsSkills[id][talent],
                            [level]: temParticipantsLevelsSkills
                        }
                    }
                }
            },
            participantsLevelsToAllSkills: temParticipantsLevelsToAllSkills
        })
    }

    hideForm = () => {
        this.setState({ visible: false })
    }

    render(){
        if (this.state.goToDetail) {
            return <Redirect to={`/programs/${this.props.pId}/courses/${this.props.cId}/assessment/${this.state.goToDetail}`}/>
        }

        return (
            <Sidebar visible={this.state.visible} style={{overflowY: 'auto', width: '60em'}} className="p-sidebar-md" position="right" blockScroll={true} onHide={this.hideForm} >
                <h2>{this.state.headerForm}</h2>
                <div className="p-grid">
                    <div className="p-col-12">
                        <Fieldset legend="Basic Information">
                            <div className="p-grid p-fluid form-group">
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">Name</label>
                                    <InputText value={this.state.assessment.name} onChange={(e) => this.setState({assessment: {...this.state.assessment, name: e.target.value}})}/>
                                    <span className="p-form-error">{this.state.errors.name}</span> 
                                </div>
                                <div className="p-col-12 p-md-6">
                                    <label className="p-label">Date</label>
                                    <MaskedCalendar value={this.state.dateValue} dateFormat="mm-dd-yy" maxDate={new Date()} showIcon={true} onChange={(e) => this.onChangeDate(e)}/>
                                    <span className="p-form-error">{this.state.errors.date}</span> 
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                    <div className="p-col-12">
                        <Fieldset legend="Participants">
                            <table cellPadding={0} cellSpacing={0} style={{width: "100%", border: "none"}} >
                                <thead>
                                    <tr>
                                        <td className="p-padding-all-5 p-c" style={{border: "1px solid #e6e6e6"}} ><strong>Name</strong></td>
                                        <td className="p-padding-all-5 p-c" style={{border: "1px solid #e6e6e6"}} ><strong>Day of bith</strong></td>
                                        <td className="p-padding-all-5 p-c" style={{border: "1px solid #e6e6e6"}} ><strong>Age</strong></td>
                                        { this.state.assessment.type !== 'attendance' ?
                                                this.state.talents.map((item, index) =>{
                                                    return(
                                                        <td key={index} className="p-padding-all-5 p-c" style={{border: "1px solid #e6e6e6"}}>
                                                            <strong>{item.name ? item.name : item.key} </strong> &nbsp;&nbsp;
                                                            {this.state.assessment.type === 'level' ?
                                                                <Dropdown value={this.state.participantAllLevels[item.key] ? this.state.participantAllLevels[item.key] : ''} options={item.values} onChange={(e) => this.onChangeSelectLevelToAllLevel(e, item.key)} style={{minWidth: "auto", maxWidth: "100%"}} />                                                        
                                                                : ''
                                                            }
                                                        </td>                                                
                                                    )
                                                })
                                            : <td className="p-padding-5 p-c" style={{border: "1px solid #e6e6e6"}}>
                                                <Checkbox onChange={(e) => this.onCheckAttendanceToAll(e)} checked={this.state.attendanceToAll} />
                                            </td>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.participants.map((part, idx) => {
                                        let maxAgePart = part.ageInMonth;
                                        let colorBorder;
                                        let maxAge;
                                        let talents = this.state.talents;
                                        talents.map((talent) =>{
                                            let temMaxAge = talent.maxAgeYear * 12 + talent.maxAgeMonth
                                            if(!maxAge || maxAge > temMaxAge){ 
                                                maxAge = temMaxAge;
                                            }
                                        })
                                        if(this.state.assessment.type !== 'attendance' && maxAgePart > maxAge){
                                            colorBorder = '1px solid #ce0b0b';
                                        }else{
                                            colorBorder = '1px solid #e6e6e6';
                                        }
                                        return (
                                            <tr key={idx}>
                                                <td className="p-padding-all-5" style={{border: colorBorder}} >{part.name}</td>
                                                <td className="p-padding-all-5" style={{border: colorBorder}} >{moment(part.birthday).format('MM-DD-YYYY')}</td>
                                                <td className="p-padding-all-5" style={{border: colorBorder}} >{part.ageString}</td>
                                                {this.state.assessment.type !== 'attendance' ?
                                                    talents.map((talent, index) =>{
                                                        return(
                                                            <td key={index} className="p-padding-all-5"style={{border: colorBorder, verticalAlign: "initial"}} >
                                                                {this.state.assessment.type === 'level' ?
                                                                    <div className="p-grid">
                                                                        <div className="p-col-6">
                                                                            <div className="p-padding-5-0">Current</div>
                                                                            <div>{this.state.assessment.participantsLevels[part.id][talent.key].current ? this.state.assessment.participantsLevels[part.id][talent.key].current : 'Not yet'}</div>
                                                                        </div>
                                                                        <div className="p-col-6">
                                                                            <div className="p-padding-5-0">Recommendation</div>
                                                                            <Dropdown options={talent.values} onChange={(e) => this.onChangeSelectLevel(part.id, talent.key, e.value)} style={{minWidth: "auto", maxWidth: "100%"}} 
                                                                                value={this.state.assessment.participantsLevels[part.id][talent.key].promote}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    : <ul className="p-padding-none" style={{listStyle: "none"}}>
                                                                        {talent.values ? 
                                                                            talent.values.map((level, idxLevel) => {
                                                                                return(
                                                                                    <li key={idxLevel} className="p-margin-bottom-15" style={{borderBottom: '1px solid #e6e6e6'}}> 
                                                                                        <Checkbox onChange={(e) => this.onCheckAllLevelSkills(part.id, talent.key, level.value, e)} 
                                                                                            checked={this.state.participantsLevelsToAllSkills[part.id] && this.state.participantsLevelsToAllSkills[part.id][talent.key] && this.state.participantsLevelsToAllSkills[part.id][talent.key] ? 
                                                                                                this.state.participantsLevelsToAllSkills[part.id][talent.key][level.value] : false} 
                                                                                        />
                                                                                        {level.label ? level.label : level.value} 
                                                                                        <ul className="p-padding-left-10 p-margin-top-10" style={{listStyle: "none"}}> 
                                                                                            {level.skills ? 
                                                                                                level.skills.map((skill, idxSkill) => {
                                                                                                    return(
                                                                                                        <li key={idxSkill} className="p-margin-bottom-5">
                                                                                                            <Checkbox onChange={(e) => this.onCheckSkill(part.id, talent.key, level.value, skill.value, e)} 
                                                                                                                checked={this.state.assessment.participantsLevelsSkills[part.id] && this.state.assessment.participantsLevelsSkills[part.id][talent.key] && this.state.assessment.participantsLevelsSkills[part.id][talent.key][level.value] ? 
                                                                                                                    this.state.assessment.participantsLevelsSkills[part.id][talent.key][level.value][skill.value] : false} 
                                                                                                            />
                                                                                                            {skill.label ? skill.label : skill.value}
                                                                                                        </li>
                                                                                                    );
                                                                                                })
                                                                                                : ''
                                                                                            }
                                                                                        </ul>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                            : ''
                                                                        }
                                                                    </ul>
                                                                }
                                                            </td>
                                                        );
                                                    })
                                                    : <td className="p-padding-all-5 p-c" style={{width: "3em", border: "1px solid #e6e6e6"}}>
                                                        <Checkbox value={part.id} onChange={(e) => this.onCheckAttende(e)} checked={this.state.assessment.attendances[part.id]} />
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Fieldset>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-r p-margin-top-30 p-line-top">
                        <Button label="Submit" icon="pi pi-check" onClick={() => this.handleSaveForm()} />
                        <Button label="Cancel" icon="pi-md-close" className="p-button-secondary" onClick={this.hideForm} />
                    </div>
                </div>
            </Sidebar>
        );
    }
}